import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import { container } from 'assets/jss/main'

const styles = theme => ({
  publicRoot: {
    color: theme.palette.common.white,
    fontSize: 20,
    minHeight: 'calc(95vh - 80px)',
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    ...container,
  },
})

const Reports = ({ classes }) => (
  <div className={classes.publicRoot}>
    This page has moved. Please contact our support team to get your new Reports link.
  </div>
)

export default withStyles(styles)(Reports)
