import React, { useState } from 'react'

import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Typography,
  FormControl,
  MenuItem,
  TextField,
} from '@material-ui/core'
import range from 'lodash/range'

import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { EmptyQuestionScoreNotification } from 'components/Survey/Wizard/Steps/Notifications/ScoreBasedNotifications'
import {
  useSurveysQuestionsQuery,
  useSurveysUpdateGroupsQuestionScoreNotificationsMutation,
  SurveyScoreNotificationFrequencyEnum,
  SurveyScoreNotificationTypeEnum,
  GroupNode,
  QCategory,
  BenchmarkCodeType,
} from 'generated/graphql'
import { SCORE_TYPE_NOTIFICATION_TYPES_ENUM } from 'utils/constants'

const useStyles = makeStyles(({ spacing }) => ({
  description: {
    marginTop: spacing(3),
  },
  formRow: {
    marginBottom: spacing(4),
    '& >p:first-child': {
      marginBottom: spacing(1),
    },
  },
  questionRow: {
    display: 'flex',
    alignItems: 'center',
  },
  groups: {
    justifyContent: 'flex-start',
  },
}))

const SCORE_TYPE_LABEL = {
  [SurveyScoreNotificationTypeEnum.LOW]: 'Low Score',
  [SurveyScoreNotificationTypeEnum.HIGH]: 'High Score',
}

export const getRanges = (
  choices: [number, number],
  minScore?: null | number,
  maxScore?: null | number,
) => {
  // Default to the statement scale of 0 to 10
  let minValue = 0
  let maxValue = 10
  if (choices.length) {
    minValue = choices[0] || minValue
    maxValue = choices[1] || maxValue
  }
  return [range(minValue, (maxScore || maxValue) + 1), range(minScore || minValue, maxValue + 1)]
}

type Props = {
  surveyUuid: string
  groups: Pick<GroupNode, 'uuid' | 'name'>[]
  notification: EmptyQuestionScoreNotification
  onClose(): void
}
const AddQuestionScoreNotificationDialog: React.FC<Props> = ({
  surveyUuid,
  groups,
  notification: initialNotification,
  onClose,
}) => {
  const classes = useStyles()

  const result = useSurveysQuestionsQuery({ variables: { surveyUuid } })

  const [notification, setNotification] = useState(initialNotification)
  const [
    updateQuestionScoreNotification,
    { loading: isUpdating },
  ] = useSurveysUpdateGroupsQuestionScoreNotificationsMutation()

  const frequencies = Object.keys(
    SurveyScoreNotificationFrequencyEnum,
  ) as SurveyScoreNotificationFrequencyEnum[]

  const scoreTypes = Object.keys(
    SurveyScoreNotificationTypeEnum,
  ) as SurveyScoreNotificationTypeEnum[]

  const onSave = async () => {
    await updateQuestionScoreNotification({
      variables: {
        surveyUuid,
        groupsNotificationsInput: [
          {
            enabled: true,
            notificationUuid: notification.notificationUuid,
            notificationName: notification.notificationName || '',
            scoreType: notification.scoreType || SurveyScoreNotificationTypeEnum.LOW,
            minScore: notification.minScore || 0,
            maxScore: notification.maxScore || 0,
            frequency: notification.frequency || SurveyScoreNotificationFrequencyEnum.DAILY,
            groupUuids: notification.groupUuids,
            statementUuid: notification.statementUuid,
            openEndedQuestionUuid: notification.openEndedQuestionUuid,
          },
        ],
      },
    })
  }

  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle id="email-notification-title">
        {notification.notificationUuid
          ? 'Edit Score Based Notification'
          : 'Add New Score Based Notification'}
        <Typography color="textSecondary" className={classes.description}>
          *Please fill out the required fields. Be specific with your notification name so others on
          your team will understand (ex. “Low Score Recommendation Question”)
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ResponseHandler {...result}>
          {({ questions: surveyQuestions }) => {
            const questions = surveyQuestions.filter(q => q.category !== QCategory.PERSONAL_INFO)
            const statements = questions.filter(q => q.isStatement)
            const openEndedQuestions = questions.filter(q => q.isOpenEnded)

            const statement = statements.find(s => s.uuid === notification.statementUuid)
            const openEndedQuestion = openEndedQuestions.find(
              s => s.uuid === notification.openEndedQuestionUuid,
            )
            let minScoreRange = null
            let maxScoreRange = null
            if (statement) {
              let choices: [number, number]
              if (statement.benchmarkCode === BenchmarkCodeType.NPS_RECOMMEND) {
                choices = [0, 10]
              } else if (statement.choices.length) {
                choices = [statement.choices[0].value || 0, statement.choices[1].value || 0]
              } else {
                choices = [0, 5]
              }
              ;[minScoreRange, maxScoreRange] = getRanges(
                choices,
                notification.minScore,
                notification.maxScore,
              )
            }

            return (
              <>
                <div className={classes.formRow}>
                  <Typography variant="body2" color="textSecondary">
                    NOTIFICATION NAME*:
                  </Typography>
                  <FormControl style={{ width: 400 }} size="small">
                    <TextField
                      autoFocus
                      label="Notification Name"
                      value={notification.notificationName}
                      onChange={({ target: { value } }) =>
                        setNotification({
                          ...notification,
                          notificationName: value,
                        })
                      }
                      fullWidth
                    />
                  </FormControl>
                </div>
                <div className={classes.formRow}>
                  <Typography variant="body2" color="textSecondary">
                    SEND NOTIFICATION WHEN...*:
                  </Typography>
                  <div className={classes.questionRow}>
                    <FormControl style={{ width: 400 }} size="small">
                      <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={value =>
                          value ? (
                            <Typography>{statement?.text}</Typography>
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              Select Question
                            </Typography>
                          )
                        }
                        value={notification.statementUuid}
                        onChange={e => {
                          setNotification({
                            ...notification,
                            statementUuid: (e.target as HTMLInputElement).value as string,
                          })
                        }}
                      >
                        {statements.map(s => (
                          <MenuItem key={s.uuid} value={s.uuid}>
                            {s.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography color="textSecondary">
                      &nbsp;&nbsp;receives a&nbsp;&nbsp;
                    </Typography>
                    <FormControl size="small">
                      <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={value => {
                          if (!value) {
                            return (
                              <Typography variant="body2" color="textSecondary">
                                Score Type
                              </Typography>
                            )
                          }
                          return (
                            <Typography>
                              {SCORE_TYPE_LABEL[value as SurveyScoreNotificationTypeEnum]}
                            </Typography>
                          )
                        }}
                        value={notification.scoreType}
                        onChange={e => {
                          setNotification({
                            ...notification,
                            scoreType: (e.target as HTMLInputElement)
                              .value as SurveyScoreNotificationTypeEnum,
                          })
                        }}
                      >
                        {scoreTypes.map(scoreType => (
                          <MenuItem key={scoreType} value={scoreType}>
                            {SCORE_TYPE_LABEL[scoreType]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography color="textSecondary">&nbsp;&nbsp;between&nbsp;&nbsp;</Typography>
                    <FormControl size="small">
                      <Select
                        variant="outlined"
                        disabled={!notification.statementUuid}
                        displayEmpty
                        renderValue={value =>
                          value !== null ? (
                            <Typography>{value as string}</Typography>
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              Min
                            </Typography>
                          )
                        }
                        value={notification.minScore}
                        onChange={e => {
                          setNotification({
                            ...notification,
                            minScore: ((e.target as HTMLInputElement).value as unknown) as number,
                          })
                        }}
                      >
                        {minScoreRange?.map(score => (
                          <MenuItem key={score} value={score}>
                            {score}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography color="textSecondary">&nbsp;&nbsp;and&nbsp;&nbsp;</Typography>
                    <FormControl size="small">
                      <Select
                        variant="outlined"
                        disabled={!notification.statementUuid}
                        displayEmpty
                        renderValue={value =>
                          value ? (
                            <Typography>{value as string}</Typography>
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              Max
                            </Typography>
                          )
                        }
                        value={notification.maxScore}
                        onChange={e => {
                          setNotification({
                            ...notification,
                            maxScore: ((e.target as HTMLInputElement).value as unknown) as number,
                          })
                        }}
                      >
                        {maxScoreRange?.map(score => (
                          <MenuItem key={score} value={score}>
                            {score}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {openEndedQuestions.length > 0 && (
                  <div className={classes.formRow}>
                    <Typography variant="body2" color="textSecondary">
                      AND SHOW COMMENT FROM:
                    </Typography>
                    <FormControl style={{ width: 400 }} size="small">
                      <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={value =>
                          value ? (
                            <Typography>{openEndedQuestion?.text}</Typography>
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              Select Open Ended Question
                            </Typography>
                          )
                        }
                        value={notification.openEndedQuestionUuid}
                        onChange={e => {
                          setNotification({
                            ...notification,
                            openEndedQuestionUuid: (e.target as HTMLInputElement).value as string,
                          })
                        }}
                      >
                        <MenuItem value={undefined}>None</MenuItem>
                        {openEndedQuestions.map(q => (
                          <MenuItem key={q.uuid} value={q.uuid}>
                            {q.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                <div className={classes.formRow}>
                  <Typography variant="body2" color="textSecondary">
                    FREQUENCY:*
                  </Typography>
                  <FormControl style={{ width: 180 }} size="small">
                    <Select
                      variant="outlined"
                      displayEmpty
                      renderValue={value =>
                        value ? (
                          SCORE_TYPE_NOTIFICATION_TYPES_ENUM[
                            value as SurveyScoreNotificationFrequencyEnum
                          ]
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            Select Frequency
                          </Typography>
                        )
                      }
                      value={notification.frequency}
                      onChange={e => {
                        setNotification({
                          ...notification,
                          frequency: (e.target as HTMLInputElement)
                            .value as SurveyScoreNotificationFrequencyEnum,
                        })
                      }}
                    >
                      {frequencies.map(frequency => (
                        <MenuItem key={frequency} value={frequency}>
                          {SCORE_TYPE_NOTIFICATION_TYPES_ENUM[frequency]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {notification.frequency && (
                    <Typography variant="body2" color="textSecondary">
                      Email is sent at midnight for the preceding{' '}
                      {notification.frequency === SurveyScoreNotificationFrequencyEnum.DAILY
                        ? '24 hours'
                        : '7 days'}
                    </Typography>
                  )}
                </div>
                <div className={classes.formRow}>
                  <Typography variant="body2" color="textSecondary">
                    WHO SHOULD RECEIVE:*
                  </Typography>
                  <CheckboxDropdown
                    size="small"
                    variant="outlined"
                    menuItems={groups.map(group => ({
                      value: group.uuid,
                      text: group.name,
                    }))}
                    selectedItems={(notification.groupUuids || []) as string[]}
                    onChange={(selected: string[]) => {
                      setNotification({
                        ...notification,
                        groupUuids: selected,
                      })
                    }}
                    width={210}
                    emptyLabel="Select Groups"
                    height={43}
                    containerClassName={classes.groups}
                  />
                </div>
              </>
            )
          }}
        </ResponseHandler>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondaryNoBackground">
          Cancel
        </Button>
        <SubmitButton
          disabled={
            isUpdating ||
            !notification.notificationName ||
            !notification.frequency ||
            !notification.groupUuids.length ||
            notification.minScore === null ||
            !notification.maxScore ||
            !notification.statementUuid
          }
          isSubmitting={isUpdating}
          color="primary"
          onClick={async () => {
            await onSave()
            onClose()
          }}
        >
          Save
        </SubmitButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddQuestionScoreNotificationDialog
