import React, { useContext } from 'react'

import { IconButton, Typography } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

import { ReactComponent as TimerIcon } from 'assets/img/timer-icon.svg'
import ActionTooltip from 'components/Blocks/Tooltips/ActionTooltip'
import { getTimeTrendingTimeframes } from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { gaEvent } from 'config/ga'
import { TimeTrendingChartKey, StoreContext } from 'config/LocalStorage'
import { colors } from 'shared/theme'

/**
 * TimeTrending icon that updates local storage state with the enabled/disabled status for
 * a given chart.
 * If surveyUuid is provided, enable/disable timetrending globally for the survey.
 */
type Props = { chartKey: TimeTrendingChartKey; className?: string; surveyUuid?: string }
const TimeTrendingIcon: React.FC<Props> = ({ chartKey, surveyUuid, className }) => {
  const {
    store: { [chartKey]: isChartActive, timeTrendingSettingsBySurvey },
    updateStore,
  } = useContext(StoreContext)
  let isActive = isChartActive
  if (surveyUuid) {
    isActive = timeTrendingSettingsBySurvey[surveyUuid]?.enabled
  }
  return (
    <ActionTooltip
      className={className}
      placement="top"
      title={
        <>
          {isActive ? (
            <>
              <ArrowBack style={{ color: colors.white }} />
              <Typography>Exit Time Trend</Typography>
            </>
          ) : (
            <Typography>Time Trend</Typography>
          )}
        </>
      }
    >
      <IconButton
        onClick={() => {
          if (!isActive) {
            gaEvent({
              action: `openTimeTrending-${chartKey || 'global'}`,
              category: 'Insights',
            })
          }
          if (surveyUuid) {
            const timeframe = timeTrendingSettingsBySurvey[surveyUuid]?.timeframe
            updateStore({
              timeTrendingSettingsBySurvey: {
                ...timeTrendingSettingsBySurvey,
                [surveyUuid]: {
                  enabled: !isActive,
                  // Default to the first option.
                  timeframe: timeframe || getTimeTrendingTimeframes()[0].label,
                },
              },
            })
          } else {
            updateStore({ [chartKey]: !isActive })
          }
        }}
        id={chartKey}
      >
        <TimerIcon fill={isActive ? colors.secondary : colors.navy65} />
      </IconButton>
    </ActionTooltip>
  )
}

export default TimeTrendingIcon
