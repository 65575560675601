import React from 'react'

import { makeStyles } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import Button from 'components/Blocks/CustomButtons/Button'
import { UserDownloadsEnum, useInsightsDownloadLazyQuery } from 'generated/graphql'
import { useDownloadMachine } from 'utils/customHooks'

type Props = {
  surveyUuid: string
  downloadType: UserDownloadsEnum
  title: string
  id: string
  onClick?: () => void
}

const useStyles = makeStyles(theme => ({
  downloadButton: {
    marginLeft: 0,
    paddingLeft: 0,
    '& >span>svg': {
      marginRight: theme.spacing(1),
    },
  },
}))

const DownloadButton: React.FC<Props> = ({ surveyUuid, downloadType, title, id, onClick }) => {
  const classes = useStyles()
  const [downloadFile, { data, error }] = useInsightsDownloadLazyQuery({
    variables: {
      surveyUuid,
      downloadType,
    },
    fetchPolicy: 'network-only',
  })
  const { state, send } = useDownloadMachine(downloadFile, Boolean(data?.success), error)
  return (
    <Button
      color="secondaryNoBackground"
      id={id}
      onClick={() => {
        send('FETCH_DOWNLOAD')
        if (onClick) onClick()
      }}
      disabled={state.matches('loading')}
      className={classes.downloadButton}
    >
      <CloudDownloadIcon />
      {title}
    </Button>
  )
}

export default DownloadButton
