import React from 'react'

import { makeStyles, Grid, Typography } from '@material-ui/core'

import Dialog from 'components/Blocks/Dialogs/Dialog'
import EditableTagsText from 'components/Blocks/EditableTagsText'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import { MERGE_TAGS_URL } from 'utils/documentationConstants'
import { PhoneScriptTagsEnum } from 'utils/generatedEnums'

const useStyles = makeStyles(({ palette, spacing }) => ({
  border: {
    border: `1px solid ${palette.common.navy25}`,
    borderRadius: 3,
  },
  inputRow: {
    marginTop: spacing(4),
  },
  helper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: spacing(),
  },
}))

interface Props {
  text?: null | string
  onClose(): void
}

const PhoneNotificationDialog: React.FC<Props> = ({ text, onClose }) => {
  const classes = useStyles()

  return (
    <Dialog title="Intro Phone Script" onClose={onClose} maxWidth="sm">
      <Typography color="textSecondary">SCRIPT TEXT</Typography>
      <Grid container className={classes.inputRow}>
        <Grid item sm={3}>
          <Typography color="textSecondary">Message: </Typography>
        </Grid>
        <Grid item sm={9} className={classes.border}>
          <EditableTagsText
            disabled
            key="phone-script"
            availableTags={Object.values(PhoneScriptTagsEnum)}
            value={text || ''}
            setValue={() => {}}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={3} />
        <Grid item sm={9}>
          <div className={classes.helper}>
            <Typography variant="body2" color="textSecondary">
              {'Need help understanding {{tags}}?'}
              <ExternalNavLink to={MERGE_TAGS_URL} label=" Learn more >" />
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default PhoneNotificationDialog
