import React from 'react'

import { makeStyles, FormControlLabel, FormControlLabelProps } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(({ palette }) => ({
  preActivated: {
    // When the control is checked and disabled, show the text in navy instead of gray.
    '& >span:nth-child(2)': {
      color: `${palette.common.navy}`,
    },
  },
}))

const CustomFormControlLabel: React.FC<FormControlLabelProps> = props => {
  const classes = useStyles()
  const preActivated = props.control.props.disabled && props.control.props.checked
  return <FormControlLabel {...props} className={cn({ [classes.preActivated]: preActivated })} />
}

export default CustomFormControlLabel
