import React, { useContext, useState } from 'react'

import { makeStyles, Typography, MenuItem, ListItemIcon, Tooltip } from '@material-ui/core'
import ScatterIcon from '@material-ui/icons/BorderAll'
import TableIcon from '@material-ui/icons/Menu'
import isNil from 'lodash/isNil'

import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import ScatterPlotContainer, {
  ScatterDataHandler,
  ChartData,
  CardViewType,
  HierarchyScoresByGroup,
  QuadrantTooltip,
} from 'components/Blocks/Charts/ScatterPlotContainer'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import FilterGroupDropdowns from 'components/Blocks/Filters/FilterGroupDropdowns'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import StandardTooltip from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import { QuadrantLabel } from 'components/Insights/TimeTrending/ScatterPlot'
import TTTableView from 'components/Insights/TimeTrending/TTTableView'
import { getDefaultSelectedSurveys } from 'components/Insights/TimeTrendingSurveyDropdown'
import { gaEvent } from 'config/ga'
import { StoreContext, TimeTrendingSurveysKey } from 'config/LocalStorage'
import { SurveyProductTypeEnum, FilterTypeFragment } from 'generated/graphql'
import { colors } from 'shared/theme'
import { formatTooltipScore } from 'utils'
import {
  PRODUCT_TYPE_TO_PULSE_TYPE,
  ScoreTitleEnum,
  SURVEY_TYPE_TO_KEY_STATEMENT,
} from 'utils/constants'
import { usePulseInTimeTrending } from 'utils/customHooks'
import { getFormattedSurveyDate } from 'utils/dateUtils'
import { SurveyNode } from 'utils/types'

const QUADRANT_TITLES = {
  TOP_LEFT: 'Improving',
  TOP_RIGHT: 'Top Players',
  BOTTOM_LEFT: 'At Risk',
  BOTTOM_RIGHT: 'Monitor',
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  descriptionBlock: {
    width: '80%',
    '& >span': {
      color: palette.common.navy,
    },
  },
  dropdownRow: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: -8,
  },
  viewTypeIcon: {
    color: palette.common.navy65,
    position: 'relative',
    top: 2,
  },
  titleTooltip: {
    padding: spacing(1),
    '& >span': {
      color: palette.common.navy,
    },
  },
}))

export const transformScoresToEcharts = (data: HierarchyScoresByGroup) => {
  return (
    Object.keys(data)
      .map(level1DataType => {
        return {
          name: level1DataType,
          data: Object.keys(data[level1DataType])
            .map(level2DataType => {
              const [survey1Score, survey2Score] = Object.values(
                data[level1DataType][level2DataType],
              )
              // If either of the scores is a <minShowableResults (null) or doesn't exist on a survey (undefined)
              // we don't want to bother plotting it. Return null here and filter nulls from the list afterwards.
              if (isNil(survey1Score) || isNil(survey2Score)) return null
              // Data points are [latest survey score, delta between scores]
              return {
                name: level2DataType,
                value: [survey2Score, survey2Score - survey1Score],
              }
            })
            .filter((score): score is ChartData => score !== null),
        }
      })
      // Ignore groups that have no data
      .filter(score => score.data.length > 0)
  )
}

type Props = {
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  filters: string[]
  visibleFilterTypes: FilterTypeFragment[]
  companyOverallPositive: number
}
const TTScatterAndTableCard: React.FC<Props> = ({
  survey,
  availableSurveys,
  filters,
  visibleFilterTypes,
  companyOverallPositive,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [selectedViewType, setSelectedViewType] = useState(CardViewType.SCATTER)
  const surveysKey = {
    [SurveyProductTypeEnum.EMPLOYEE]: TimeTrendingSurveysKey.EMPLOYEE_DETAILED_BREAKDOWN,
    [SurveyProductTypeEnum.RESIDENT]: TimeTrendingSurveysKey.RESIDENT_DETAILED_BREAKDOWN,
  }[survey.productType]
  // We compare two surveys in this card by choosing (1) the current controls survey
  // and (2) the default most recent from what is selected in time trending detailed breakdown.
  const {
    store: { [surveysKey]: detailedBreakdownSurveyIds },
  } = useContext(StoreContext)
  const selectedSurveys = getDefaultSelectedSurveys(
    survey.uuid,
    availableSurveys.filter(s => detailedBreakdownSurveyIds.includes(s.uuid)),
  )
  // Data on the scatter plot can be grouped by 2 levels. Level2 values are grouped by Level1.
  // Multi-level group by is optional, in which case we only group by Level2.
  // When an organization only has 2 filter types, we default to the single level group by.
  const [level1FilterType, setLevel1FilterType] = useState<FilterTypeFragment | null>(
    visibleFilterTypes.length > 2 ? visibleFilterTypes[0] : null,
  )
  const [level2FilterType, setLevel2FilterType] = useState(
    // TODO: remove this conditional when we validate whether a user should have access to more than 1 filter type
    visibleFilterTypes.length > 1 ? visibleFilterTypes[1] : visibleFilterTypes[0],
  )
  const includesPulse = usePulseInTimeTrending(selectedSurveys.slice(0, 2), surveysKey)
  // If a second survey is deselected, return null and let the detailed breakdown component
  // show the empty state.
  if (selectedSurveys.filter(Boolean).length < 2) {
    return null
  }
  const firstSurveyDate = getFormattedSurveyDate({
    endDate: selectedSurveys[0].endDate!,
    excludeComma: true,
  })
  const secondSurveyDate = getFormattedSurveyDate({
    endDate: selectedSurveys[1].endDate!,
    excludeComma: true,
  })
  const viewTypeToIcon = {
    [CardViewType.SCATTER]: <ScatterIcon className={classes.viewTypeIcon} />,
    [CardViewType.TABLE]: <TableIcon className={classes.viewTypeIcon} />,
  }
  const titleTooltip = includesPulse ? (
    <Typography variant="body2" color="textSecondary" className={classes.titleTooltip}>
      <span>To change the surveys used for this chart:</span> update the surveys selected under
      Detailed Breakdown Over Time.
      <br />
      <br />
      <span>
        To see the Customer Engagement Score changes instead of Key Statement Score changes:
      </span>
      make sure you don't have any pulse surveys selected.
      <br />
    </Typography>
  ) : (
    <Typography variant="body2" color="textSecondary" className={classes.titleTooltip}>
      <span>To change the surveys used for this chart:</span> update the surveys selected under
      Detailed Breakdown Over Time.
    </Typography>
  )
  const titleDescription = {
    [CardViewType.SCATTER]: (
      <span className={classes.descriptionBlock}>
        This chart shows {level2FilterType.namePlural} by % score change and relationship to the
        organization’s overall score. Click on the legend to show/hide {level2FilterType.namePlural}
        {level1FilterType && ` within a specific ${level1FilterType?.name}`}. We’re comparing your
        last two survey scores to determine the time range:{' '}
        <Tooltip title={titleTooltip}>
          <span>
            {firstSurveyDate} to {secondSurveyDate}.
          </span>
        </Tooltip>
      </span>
    ),
    [CardViewType.TABLE]: (
      <span className={classes.descriptionBlock}>
        Note: We're comparing your last two surveys to determine the time frame:{' '}
        <span>{firstSurveyDate}</span> to <span>{secondSurveyDate}</span>.
      </span>
    ),
  }[selectedViewType]

  const scoreName: ScoreTitleEnum | 'Key Statement Score' = includesPulse
    ? 'Key Statement Score'
    : ScoreTitleEnum[survey.productType]
  return (
    <div className={classes.fullRow} id="tt-scatter-snap">
      <SnapshotChartHeader
        title={`${scoreName} Changes By ${level2FilterType.name}`}
        description={titleDescription}
        tooltip={
          <QuadrantTooltip
            title="This chart is divided into four quaderents, to help determine which scores are doing well,
          and which to closely monitor."
            quadrantTitles={QUADRANT_TITLES}
            quadrantDescriptions={{
              TOP_RIGHT: 'High score, and showing improvement.',
              TOP_LEFT: 'Low score, but showing improvement.',
              BOTTOM_RIGHT: 'High score, but declining.',
              BOTTOM_LEFT: 'Low score, and declining.',
            }}
          />
        }
        snapId="tt-scatter-snap"
        screenshotStrategy="svg"
        useBottomPadding={false}
        extraControls={
          <div className={classes.dropdownRow}>
            <FilterGroupDropdowns
              level1FilterType={level1FilterType}
              level2FilterType={level2FilterType}
              setLevel1FilterType={setLevel1FilterType}
              setLevel2FilterType={setLevel2FilterType}
              visibleFilterTypes={visibleFilterTypes}
            />
            <TextDropdown
              value={selectedViewType}
              renderValue={viewType => viewTypeToIcon[viewType as CardViewType]}
              onChange={e => {
                gaEvent({
                  action: 'selectedTimeTrendingTableView',
                  category: 'Insights',
                })
                setSelectedViewType(e.target.value as CardViewType)
              }}
              id="scatter-table-select"
            >
              {Object.values(CardViewType).map(viewType => {
                return (
                  <MenuItem key={viewType} value={viewType}>
                    <ListItemIcon>{viewTypeToIcon[viewType]}</ListItemIcon>
                    <Typography>{viewType}</Typography>
                  </MenuItem>
                )
              })}
            </TextDropdown>
          </div>
        }
      />
      <ScatterDataHandler
        pulseKeyStatementCode={
          SURVEY_TYPE_TO_KEY_STATEMENT[PRODUCT_TYPE_TO_PULSE_TYPE[survey.productType]]
        }
        includesPulse={includesPulse}
        level1FilterType={level1FilterType}
        level2FilterType={level2FilterType}
        selectedSurveys={selectedSurveys}
        filters={filters}
      >
        {({ surveyScoresByGroup }) => {
          if (selectedViewType === CardViewType.TABLE) {
            return (
              <TTTableView
                quadrantTitles={QUADRANT_TITLES}
                data={surveyScoresByGroup}
                scoreName={scoreName}
                // If level1 is missing, display level2 for both
                level1FilterType={level1FilterType || level2FilterType}
                level2FilterType={level2FilterType}
                firstSurveyDate={firstSurveyDate}
                secondSurveyDate={secondSurveyDate}
                companyOverallPositive={companyOverallPositive}
              />
            )
          }
          return (
            <ScatterPlotContainer
              surveyScoresByGroup={surveyScoresByGroup}
              setSelectedViewType={setSelectedViewType}
              scatterPlotTransformFn={transformScoresToEcharts}
              scatterPlotProps={{
                yAxisTitle: `% Change since ${firstSurveyDate}`,
                xAxisTitle: `${secondSurveyDate} ${scoreName}`,
                xAxisCenter: companyOverallPositive,
                yAxisCenter: 0,
                quadrantTitles: QUADRANT_TITLES,
                yAxisAllowLabelsOutOfBounds: true,
                renderTooltip: ({ xVal, yVal, xName, yName, activeQuadrant }) => {
                  return (
                    <StandardTooltip
                      title={yName}
                      rows={[
                        {
                          label: `${scoreName}:`,
                          value: formatTooltipScore(xVal, survey.minShowableResults),
                          color: colors.success,
                        },
                        {
                          label: 'Company overall:',
                          value: formatTooltipScore(
                            companyOverallPositive,
                            survey.minShowableResults,
                          ),
                          color: colors.warning,
                        },
                        {
                          label: 'Score change:',
                          value: <ScoreChangeArrow delta={yVal} />,
                        },
                        {
                          label: `${(level1FilterType || level2FilterType).name}:`,
                          value: xName,
                        },
                      ]}
                      description={
                        <QuadrantLabel
                          quadrantTitles={QUADRANT_TITLES}
                          activeQuadrant={activeQuadrant}
                        />
                      }
                    />
                  )
                },
              }}
            />
          )
        }}
      </ScatterDataHandler>
    </div>
  )
}

export default TTScatterAndTableCard
