import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'

import { HEATMAP_GRADIENT_VALUES } from 'utils/constants'

const useTooltipStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(2),
    minWidth: 500,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  colorKeyRow: {
    paddingRight: theme.spacing(1),
    display: 'flex',
    '& >div': {
      height: 10,
      width: 20,
      marginRight: 1,
    },
  },
  description: {
    paddingLeft: 4,
    marginRight: theme.spacing(2),
  },
}))
const HeatmapInfoTooltip = () => {
  const classes = useTooltipStyles()
  const rowData = [
    {
      title: 'Target:',
      description: 'The group scored higher than the benchmark.',
      colors: [
        HEATMAP_GRADIENT_VALUES['1.00'],
        HEATMAP_GRADIENT_VALUES['0.85'],
        HEATMAP_GRADIENT_VALUES['0.70'],
      ],
    },
    {
      title: 'Monitor:',
      description: 'The group scored in the middle of the benchmark.',
      colors: [
        HEATMAP_GRADIENT_VALUES['0.60'],
        HEATMAP_GRADIENT_VALUES['0.50'],
        HEATMAP_GRADIENT_VALUES['0.40'],
      ],
    },
    {
      title: 'Manage:',
      description: 'The group scored lower than the benchmark.',
      colors: [
        HEATMAP_GRADIENT_VALUES['0.00'],
        HEATMAP_GRADIENT_VALUES['0.15'],
        HEATMAP_GRADIENT_VALUES['0.30'],
      ],
    },
  ]
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Color Key</Typography>
      {rowData.map(({ title, description, colors }) => (
        <div className={classes.row} key={title}>
          <div className={classes.colorKeyRow}>
            {colors.map(color => (
              <div key={color} style={{ backgroundColor: color }} />
            ))}
          </div>
          <Typography>{title}</Typography>
          <Typography color="textSecondary" className={classes.description}>
            {description}
          </Typography>
        </div>
      ))}
    </div>
  )
}

export default HeatmapInfoTooltip
