import React, { useContext } from 'react'

import { useApolloClient } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import groupBy from 'lodash/groupBy'
import querystring from 'query-string'
import { useLocation } from 'react-router-dom'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import {
  InsightsBenchmark,
  InsightsSurvey,
  InsightsTabProps,
} from 'components/Insights/InsightsTypes'
import EmployeeStatements from 'components/Insights/Statements/EmployeeStatements'
import ResidentStatements from 'components/Insights/Statements/ResidentStatements'
import { useTimeTrendingTimeframeSettings } from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import TTStatementsCard from 'components/Insights/TimeTrending/Statements/TTStatementsCard'
import { StoreContext, TimeTrendingChartKey, TimeTrendingSurveysKey } from 'config/LocalStorage'
import {
  useInsightsStatementsWithComparisonsQuery,
  InsightsStatementsWithComparisonsQuery,
  InsightsStatementScoreFragment,
  SurveyTypeEnum,
  CurrentUserDocument,
  CurrentUserQuery,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { TimeTrendingType, RESPONSE_TYPES as RT } from 'utils/constants'
import { useTimeframeSettings } from 'utils/customHooks'
import { getInsightsFieldsForProductType } from 'utils/insightsUtils'
import { SurveyNode } from 'utils/types'

export type Statement = InsightsStatementScoreFragment & {
  children: InsightsStatementScoreFragment[]
}
export type StatementData = {
  stmt: Statement
  children: InsightsStatementScoreFragment[]
  // Allow for dynamically adding child keys>
  [key: string]:
    | number
    | null
    | undefined
    | InsightsStatementScoreFragment
    | InsightsStatementScoreFragment[]
}
export type StatementScores = Array<{
  statement: Statement
  filteredStatement?: InsightsStatementScoreFragment
  data: StatementData[]
}>

// Returns a list of filter value labels from the selected filter value UUIDs.
export const getSelectedFilterValueLabels = (
  filters: CurrentUserQuery['currentUser']['filters'],
  selectedUuids: string[],
) => {
  const allFilterValues = filters.flatMap(f => f.filterValues)
  return allFilterValues.filter(v => selectedUuids.includes(v.uuid)).map(v => v.name)
}

export const setResponseTypeScores = (
  key: string,
  { positive, inconsistent, negative }: InsightsStatementScoreFragment,
) => {
  // Scores can be filtered by a single response type, or by a combination of two types.
  return {
    [`${key}-positive`]: positive,
    [`${key}-inconsistent`]: inconsistent,
    [`${key}-negative`]: negative,
    [`${key}-positive-inconsistent`]: positive + inconsistent,
    [`${key}-inconsistent-negative`]: inconsistent + negative,
    [`${key}-positive-negative`]: positive + negative,
  }
}

export type ResponseTypeKey = keyof ReturnType<typeof setResponseTypeScores>

export const prepareStatements = (
  searchQuery: string,
  statements: InsightsStatementsWithComparisonsQuery['statements'],
  filteredStatements?: InsightsStatementsWithComparisonsQuery['filteredStatements'],
  comparisonStatements?: InsightsStatementsWithComparisonsQuery['comparisonStatements'],
) => {
  let cleanedStatements = statements
  if (searchQuery) {
    cleanedStatements = cleanedStatements.filter(s =>
      s.label.toUpperCase().includes(searchQuery.toUpperCase()),
    )
  }
  if (filteredStatements?.length) {
    // The filtered statements query omits questions that wouldn't show up to participants with those filters.
    // Make sure these questions are omitted from company overall.
    cleanedStatements = cleanedStatements.filter(stmt =>
      filteredStatements.find(filterStmt => filterStmt.code === stmt.code),
    )
  }
  /**
   * Comparison statements come in the form
   * [{ group, statements: [{ statement1 }, { statement2 }]}]
   * Flatten them and group by code so we can add them as children to a specific statement.
   */
  let comparisonStatementsByCode: { [key: string]: InsightsStatementScoreFragment[] } = {}
  if (comparisonStatements?.length) {
    comparisonStatementsByCode = groupBy(
      comparisonStatements.flatMap(data =>
        // The charts expect the "label" of a statement to be the comparison group for comparison scores.
        data.statements.map(stmt => ({ ...stmt, label: data.group })),
      ),
      'code',
    )
  }
  return cleanedStatements
    .map(s => {
      const statementWithChildren = {
        ...s,
        children: comparisonStatementsByCode[s.code]
          ? cloneDeep(comparisonStatementsByCode[s.code])
          : [],
      }
      const expandedData: StatementData = {
        // For tooltip
        stmt: statementWithChildren,
        children: statementWithChildren.children,
        ...setResponseTypeScores('company', s),
        'benchmark-positive': s.benchmarkPositive,
        'benchmark-inconsistent': s.benchmarkInconsistent,
        'benchmark-negative': s.benchmarkNegative,
        'less-than-five': 100,
      }

      const filteredStmt = filteredStatements?.find(stmt => stmt.code === s.code)
      if (filteredStmt) {
        Object.assign(expandedData, setResponseTypeScores('selected-filters', filteredStmt))
      }
      // Add the comparison scores as children
      statementWithChildren.children.forEach((c, idx) => {
        expandedData[`child-${idx}-positive`] = c.positive
        expandedData[`child-${idx}-inconsistent`] = c.inconsistent
        expandedData[`child-${idx}-negative`] = c.negative
      })
      return {
        statement: statementWithChildren,
        filteredStatement: filteredStmt,
        data: [expandedData],
      }
    })
    .filter(s => s !== null)
}

export const getResponseTypes = (selectedResponseTypes: RT[]) => {
  const showAll = selectedResponseTypes.includes(RT.all)
  return [RT.pos, RT.inc, RT.neg]
    .filter(rt => showAll || selectedResponseTypes.includes(rt))
    .map(rt => rt.toLowerCase())
}

type Props = {
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  filters: string[]
  searchQuery?: string
  benchmark: InsightsBenchmark
  selectedResponseTypes: RT[]
  comparisonFilters: string[]
  limit?: number
  // Pass in time trending settings when this component is rendered from pulse snapshot
  timeTrendingChartKey?: TimeTrendingChartKey
  timeTrendingSurveysKey?: TimeTrendingSurveysKey
  timeTrendingType?: InsightsTabProps['timeTrendingType']
}
const StatementsContainer: React.FC<Props> = props => {
  const {
    survey,
    filters,
    searchQuery = '',
    selectedResponseTypes,
    benchmark,
    comparisonFilters,
    limit,
    timeTrendingType,
  } = props
  const client = useApolloClient()
  const { startDate, endDate } = useTimeframeSettings(survey)
  const { currentUser } = client.readQuery({
    query: CurrentUserDocument,
  }) as NonNullable<CurrentUserQuery>
  const location = useLocation()
  // Construct data types input object, which requires a dtCode, from individual filter values.
  const comparisonDataTypes = compact(
    currentUser.filters.map(ft => {
      const groups = ft.filterValues
        .filter(fv => comparisonFilters.includes(fv.uuid))
        .map(fv => String(fv.name))
      if (!groups.length) {
        return null
      }
      return { dtCode: ft.dtCode, groups }
    }),
  )
  const result = useInsightsStatementsWithComparisonsQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      benchmark,
      comparisonDataTypes,
      skipFilteredStatements: filters.length === 0,
      skipComparisonStatements: comparisonFilters.length === 0,
      includeCustom: true,
      startDate,
      endDate,
    },
    fetchPolicy: 'network-only',
  })
  const timeTrendingChartKey =
    props.timeTrendingChartKey ||
    {
      [SurveyProductTypeEnum.RESIDENT]: TimeTrendingChartKey.RESIDENT_STATEMENTS,
      [SurveyProductTypeEnum.EMPLOYEE]: TimeTrendingChartKey.EMPLOYEE_STATEMENTS,
    }[survey.productType]
  const timeTrendingSurveysKey =
    props.timeTrendingSurveysKey ||
    {
      [SurveyProductTypeEnum.RESIDENT]: TimeTrendingSurveysKey.RESIDENT_STATEMENTS,
      [SurveyProductTypeEnum.EMPLOYEE]: TimeTrendingSurveysKey.EMPLOYEE_STATEMENTS,
    }[survey.productType]
  const {
    store: { [timeTrendingChartKey]: showTimeTrending },
  } = useContext(StoreContext)
  const { timeTrendingEnabled: timeframeTimeTrendingEnabled } = useTimeTrendingTimeframeSettings(
    survey,
  )
  if (
    (timeTrendingType === TimeTrendingType.SURVEYS && showTimeTrending) ||
    (timeTrendingType === TimeTrendingType.TIMEFRAME && timeframeTimeTrendingEnabled)
  ) {
    return (
      <TTStatementsCard
        timeTrendingSurveysKey={timeTrendingSurveysKey}
        timeTrendingChartKey={timeTrendingChartKey}
        timeTrendingType={timeTrendingType}
        {...props}
      />
    )
  }
  return (
    <ResponseHandler {...result}>
      {({ statements, filteredStatements, comparisonStatements }) => {
        if (!statements.length) {
          return (
            <EmptyState title="No data" description="No data to display." Icon={ChartUpwardIcon} />
          )
        }
        const filterValueLabels = getSelectedFilterValueLabels(currentUser.filters, filters)
        const { insightsModules } = getInsightsFieldsForProductType(currentUser, survey.productType)
        const Component = {
          [SurveyTypeEnum.TI]: EmployeeStatements,
          [SurveyTypeEnum.PULSE]: EmployeeStatements,
          [SurveyTypeEnum.CUSTOM]: EmployeeStatements,
          [SurveyTypeEnum.MONTHLY]: EmployeeStatements,
          [SurveyTypeEnum.END_OF_EMPLOYMENT]: EmployeeStatements,
          [SurveyTypeEnum.RESIDENT_ENGAGEMENT]: ResidentStatements,
          [SurveyTypeEnum.RESIDENT_PULSE]: EmployeeStatements, // Resident Pulse need to use the employee displayment type
          [SurveyTypeEnum.RESIDENT_END_OF_SERVICE]: ResidentStatements,
          [SurveyTypeEnum.RESIDENT_DISCHARGE]: ResidentStatements,
          [SurveyTypeEnum.RESIDENT_CUSTOM]: ResidentStatements,
          [SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY]: ResidentStatements,
          [SurveyTypeEnum.RESIDENT_START_OF_SERVICE]: ResidentStatements,
        }[survey.type]
        const preparedStatements = prepareStatements(
          searchQuery,
          statements,
          filteredStatements,
          comparisonStatements,
        )
        return (
          <Component
            filters={filters}
            statements={limit ? preparedStatements.slice(0, limit) : preparedStatements}
            filterValueLabels={filterValueLabels}
            responseTypes={getResponseTypes(selectedResponseTypes)}
            survey={survey}
            searchParams={querystring.parse(location.search)}
            benchmark={benchmark}
            insightsModules={insightsModules}
            timeTrendingType={timeTrendingType}
            timeTrendingChartKey={timeTrendingChartKey}
            searchQuery={searchQuery}
            surveyProductType={survey.productType}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default StatementsContainer
