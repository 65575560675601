import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing, palette }) => ({
  cards: {
    paddingBottom: spacing(2),
    display: 'flex',
  },
  card: {
    padding: spacing(2),
    minHeight: 170,
  },
  cardActivity: {
    width: '100%',
    maxWidth: 240,
    marginRight: spacing(2),
  },
  cardsProgress: {
    display: 'flex',
    '& >div': {
      width: '50%',
    },
    '& >div:first-child': {
      marginRight: spacing(2),
    },
  },
  cardDisabled: {
    backgroundColor: palette.common.lightGrey,
  },
  cardTitle: {
    paddingBottom: spacing(),
  },
  inlineContent: {
    display: 'flex',
    alignItems: 'center',
    '& >p:last-child': {
      marginLeft: spacing(),
    },
  },
  marginBottom: {
    marginBottom: spacing(),
  },
  cardProgressCustom: {
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      // Progress Bar
      '& >div:nth-child(3)': {
        marginRight: spacing(2),
      },
    },
    '& >div:first-child': {
      borderBottom: `1px solid ${palette.common.navy15}`,
      marginBottom: spacing(),
      paddingBottom: spacing(),
    },
  },
  cardProgressCombined: {
    width: '100%',
  },
  cardProgressCombinedContent: {
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      // Progress Bar
      '& >div:nth-child(4)': {
        marginRight: spacing(2),
      },
    },
    '& >div:not(:last-child)': {
      borderBottom: `1px solid ${palette.common.navy15}`,
    },
  },
  cardEngagement: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}))

export default useStyles
