import React, { useState } from 'react'

import { Dialog, DialogTitle, Divider, FormControl, Select, MenuItem } from '@material-ui/core'

import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import { useSettingsBulkSetIsActiveMutation } from 'generated/graphql'

type Props = {
  open: boolean
  userIds: string[]
  onClose(): void
}
const BulkSetStatusDialog: React.FC<Props> = ({ open, userIds, onClose }) => {
  const [status, setStatus] = useState('ACTIVE')
  const [bulkSetIsActive] = useSettingsBulkSetIsActiveMutation()
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Set Active Status</DialogTitle>
      <Divider />
      <FormControl variant="filled">
        <Select
          value={status}
          onChange={e => setStatus((e.target as HTMLInputElement).value)}
          displayEmpty={false}
          variant="filled"
          name="resultsSurvey"
        >
          {[
            { value: 'ACTIVE', text: 'Active' },
            { value: 'INACTIVE', text: 'Inactive' },
          ].map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ActionDialogButtons
        autoFocusSubmit
        onSubmit={async () => {
          await bulkSetIsActive({
            variables: { uuidList: userIds, isActive: status === 'ACTIVE' },
          })
          onClose()
        }}
        onClose={onClose}
        submitButtonText="Done"
      />
    </Dialog>
  )
}

export default BulkSetStatusDialog
