import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import CompletionRateBox from 'components/Insights/Snapshot/CompletionRateBox'
import { InsightsResponseRateReportQuery } from 'generated/graphql'
import { displayDate } from 'utils/dateUtils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    margin: spacing(2),
    marginLeft: 32,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& >h6:first-child': {
      marginRight: spacing(),
    },
    '& >svg': {
      color: palette.common.navy65,
    },
    marginBottom: spacing(2),
  },
  boxContainer: {
    display: 'flex',
    '& >div:first-child': {
      marginRight: 50,
    },
  },
  rows: {
    width: 'auto',
  },
  labelAndValue: {
    display: 'flex',
    '&>h6:first-child': {
      minWidth: 130,
    },
  },
}))

type Props = {
  survey: NonNullable<InsightsResponseRateReportQuery['survey']>
  started: number
  submitted: number
  minShowableResults: number
}

const SurveyCompletionRateCard: React.FC<Props> = ({ survey, started, submitted }) => {
  const numCompletedResponses = submitted
  const numIncompleteResponses = started - submitted
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TitleWithInfoTooltip
        title="Responses"
        tooltip={
          <>
            <Typography variant="body2">Completion Rate:</Typography>
            <Typography variant="body2" color="textSecondary">
              Completion rate: Percentage of participants that started, and completed the entire
              survey.
            </Typography>
          </>
        }
      />
      <div className={classes.boxContainer}>
        <CompletionRateBox
          surveyStatus={survey.status}
          numCompletedResponses={numCompletedResponses}
        />
        <div className={classes.rows}>
          {[
            { label: 'Total Completed', value: numCompletedResponses },
            { label: 'Total Incomplete', value: numIncompleteResponses },
            {
              label: 'Completion Rate',
              value:
                numIncompleteResponses + numCompletedResponses &&
                `${Math.round(
                  (numCompletedResponses / (numIncompleteResponses + numCompletedResponses)) * 100,
                )}%`,
            },
            { label: 'Start', value: displayDate(survey.startDate) },
            { label: 'End', value: displayDate(survey.endDate, 'Open Ended') },
          ].map(({ label, value }) => (
            <div key={label} className={classes.labelAndValue}>
              <Typography variant="subtitle1" color="textSecondary">
                {label}:
              </Typography>
              <Typography variant="subtitle1">{value}</Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SurveyCompletionRateCard
