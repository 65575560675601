import React, { useRef, useState, memo, useEffect, ReactElement } from 'react'

import { IconButton, Typography, makeStyles } from '@material-ui/core'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight'
import cn from 'classnames'
import ReactEcharts from 'echarts-for-react'
import isEqual from 'lodash/isEqual'

import { ReactComponent as XAxisLine } from 'assets/img/echarts/x-axis-line.svg'
import { ReactComponent as YAxisLine } from 'assets/img/echarts/y-axis-line.svg'
import { ScoresType } from 'components/Blocks/Charts/ScatterPlotContainer'
import { chartTextStyle } from 'components/Insights/InsightsStyle'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import { SelectedLegends } from 'components/Insights/TimeTrending/TTGroupedScoresChart'
import { colors } from 'shared/theme'
import { groupStringsByLineLength } from 'utils'
import { benchmarkLine, tooltipStyle, zoomTool } from 'utils/echartsHelpers'

export type ScatterQuadrants = {
  TOP_LEFT: string
  TOP_RIGHT: string
  BOTTOM_LEFT: string
  BOTTOM_RIGHT: string
}

const COLORS = [
  '#4A758C',
  '#BCD55A',
  '#F2994A',
  '#2D9CDB',
  '#AA10E0',
  '#8FCDCB',
  '#5113FF',
  '#32B1A9',
  '#DDE14E',
  '#8482FF',
  '#4CAF50',
  '#946DFF',
  '#E8A7FF',
  '#F14A4D',
  '#ACACAC',
  '#620023',
  '#738D9B',
  '#F8A4A6',
  '#0047FF',
  '#DCD0FF',
  '#AFD0E3',
  '#8BCC66',
  '#D2D2D2',
  '#0F3246',
  '#C8E69C',
  '#F8CCA4',
  '#568EAD',
]

const CHART_LEFT_PADDING = 120

const useStyles = makeStyles(({ palette, spacing }) => ({
  quadrantsWrapper: {
    // Turn off pointer events so that events from the chart will bubble up first.
    // We turn on the quadrants hover manually by watching the mousemove event.
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 400,
    paddingTop: 60,
    paddingLeft: CHART_LEFT_PADDING,
    paddingRight: '10%',
  },
  quadrant: {
    width: '50%',
    // Hide the quadrant unless the `activeQuadrant` is activated.
    opacity: 0,
  },
  activeQuadrant: {
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  quadrantLabel: {
    zIndex: 10,
    borderRadius: spacing(1),
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 30,
    paddingRight: 30,
  },
  neutralLabel: {
    backgroundColor: 'rgba(206, 214, 218, 0.40)',
    color: palette.common.navy65,
  },
  neutralQuadrant: {
    backgroundColor: 'rgba(206, 214, 218, 0.30)',
  },
  highRiskLabel: {
    backgroundColor: 'rgb(235, 6, 9, 0.14)',
    color: 'rgb(235, 6, 9)',
  },
  highRiskQuadrant: {
    backgroundColor: 'rgb(235, 6, 9, 0.08)',
  },
  successLabel: {
    backgroundColor: 'rgb(76, 175, 80, 0.14)',
    color: palette.common.success,
  },
  successQuadrant: {
    backgroundColor: 'rgb(76, 175, 80, 0.08)',
  },
  xAxisLine: {
    position: 'absolute',
    bottom: 70,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    '& >p': {
      position: 'absolute',
      background: palette.common.white,
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  },
  yAxisLine: {
    position: 'absolute',
    top: 0,
    left: 40,
    display: 'flex',
    alignItems: 'center',
    height: 460,
    justifyContent: 'center',
    '& >p': {
      transform: 'rotate(-90deg)',
      position: 'absolute',
      whiteSpace: 'nowrap',
      background: palette.common.white,
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  },
  pagination: {
    /** Use absolute positioning so we can fake that the pagination is on the chart */
    position: 'absolute',
    bottom: 0,
    right: '3%',
    display: 'flex',
    alignItems: 'center',
    '& >button': {
      padding: 0,
      color: palette.common.secondary,
    },
  },
}))

export const getQuadrantFromPlotPoint = (
  xAxisVal: number,
  yAxisVal: number,
  xAxisCenter: number,
  yAxisCenter: number,
): keyof ScatterQuadrants => {
  if (xAxisVal < xAxisCenter) {
    if (yAxisVal < yAxisCenter) {
      return 'BOTTOM_LEFT'
    }
    return 'TOP_LEFT'
  }
  if (yAxisVal < yAxisCenter) {
    return 'BOTTOM_RIGHT'
  }
  return 'TOP_RIGHT'
}

export const QuadrantLabel: React.FC<{
  activeQuadrant: keyof ScatterQuadrants | null
  quadrantTitles: ScatterQuadrants
}> = ({ quadrantTitles, activeQuadrant }) => {
  const classes = useStyles()
  if (!activeQuadrant) return null
  const quadrantToClass = {
    TOP_LEFT: classes.neutralLabel,
    TOP_RIGHT: classes.successLabel,
    BOTTOM_LEFT: classes.highRiskLabel,
    BOTTOM_RIGHT: classes.neutralLabel,
  }
  return (
    <span className={cn(classes.quadrantLabel, quadrantToClass[activeQuadrant])}>
      {quadrantTitles[activeQuadrant]}
    </span>
  )
}

export type ScatterTooltipArgs = {
  xVal: number
  yVal: number
  xName: string
  yName: string
  activeQuadrant: keyof ScatterQuadrants
}
type CommonProps = {
  chartScores: ScoresType
  xAxisCenter: number
  yAxisCenter: number
  legendNames: string[]
  maxXAxisScoreDiff: number
  maxYAxisScoreDiff: number
  // Show an x-axis (horizontal) line at the yAxisCenter
  showYAxisCenterLine?: boolean
  useAxisLineDetail?: boolean
  renderTooltip(_: ScatterTooltipArgs): ReactElement
  yAxisAllowLabelsOutOfBounds?: boolean // Useful for "score change" axis when we need to display a negative value.
}
type PlotProps = CommonProps & { updateShowQuadrants(show: boolean): void }
const ScatterPlot: React.FC<PlotProps> = ({
  chartScores,
  xAxisCenter,
  yAxisCenter,
  legendNames,
  maxXAxisScoreDiff,
  maxYAxisScoreDiff,
  updateShowQuadrants,
  showYAxisCenterLine = false,
  useAxisLineDetail = true,
  renderTooltip,
  yAxisAllowLabelsOutOfBounds = false,
}) => {
  // Keep legend selection in state so that we make sure to prevent resetting them when the page changes.
  const [selectedLegends, setSelectedLegends] = useState<SelectedLegends>({})
  const yAxisMin = yAxisCenter - maxYAxisScoreDiff
  const axisLabelFormatter = (val: string, allowLabelsOutOfBounds = false) => {
    // When the furthest point from company overall is far away, it can make the opposite side
    // exceed normal bounds (e.g. Lowest point = 20, Company Overall = 80, the upper bound would be 140).
    // In this case, don't render the axis label.
    if (!allowLabelsOutOfBounds && (Number(val) < 0 || Number(val) > 100)) return ''
    return `${val}%`
  }
  return (
    <ReactEcharts
      opts={{ renderer: 'svg' }} // Useful for selecting DOM elements in UItests
      notMerge
      style={{
        width: '100%',
        height: 530,
      }}
      onEvents={{
        legendselectchanged: ({ selected }: { selected: SelectedLegends }) => {
          // Merge with existing legends, since this event will only contain legends for a single page.
          setSelectedLegends({ ...selectedLegends, ...selected })
        },
        datazoom: (zoomData: { batch: [{ end: number }, { end: number }] }) => {
          // Show quadrants when zoom has been reset.
          updateShowQuadrants(zoomData.batch[0].end === 100 && zoomData.batch[0].end === 100)
        },
      }}
      option={{
        textStyle: chartTextStyle,
        // Add bottom padding between the legend and chart so we can overlay our custom survey axis label.
        grid: { bottom: 130, left: CHART_LEFT_PADDING },
        legend: [
          {
            animation: false,
            center: 'center',
            width: '70%',
            data: legendNames,
            selected: selectedLegends,
            bottom: 0,
            textStyle: chartTextStyle,
            itemGap: 20,
            itemHeight: 12,
            // Hide the echarts pagination so we can render our own
            pageIconSize: 0,
            pageFormatter: () => '',
          },
        ],
        xAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
          axisLine: {
            // Hide the x-axis line at 0 when we're displaying our own y-axis center line.
            show: !showYAxisCenterLine,
          },
          splitNumber: 8,
          axisTick: { show: false },
          axisLabel: {
            formatter: axisLabelFormatter,
          },
          min: xAxisCenter - maxXAxisScoreDiff,
          max: xAxisCenter + maxXAxisScoreDiff,
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
          axisTick: { show: false },
          splitNumber: 6,
          axisLabel: {
            formatter: (val: string) => axisLabelFormatter(val, yAxisAllowLabelsOutOfBounds),
          },
          min: yAxisMin,
          max: yAxisCenter + maxYAxisScoreDiff,
        },
        series: [
          ...chartScores.map(score => ({
            ...score,
            type: 'scatter',
            // Setting animationDuration to 0 turns off the initial loading animation which helps
            // to prevent flashes when selecting legends, but leaves the highlight animation
            // when you hover of a legend (which is still useful)
            animationDuration: 0,
          })),
          benchmarkLine({
            silent: true,
            color: colors.secondary,
            value: xAxisCenter,
            type: 'solid',
            axis: 'xAxis',
            showLabel: true,
            width: 2,
            labelProps: {
              fontSize: 14,
              padding: [0, 0, 0, useAxisLineDetail ? 130 : 0],
              formatter: () => {
                return useAxisLineDetail
                  ? `{score|${xAxisCenter}%} {detail|= Company Overall}`
                  : `{score|${xAxisCenter}%}`
              },
              // How you can apply specific style to one part of the text.
              rich: {
                score: {
                  color: colors.secondary,
                  fontSize: 14,
                },
                detail: {
                  color: colors.navy65,
                  fontSize: 14,
                },
              },
            },
          }),
          showYAxisCenterLine &&
            benchmarkLine({
              silent: true,
              color: colors.secondary,
              value: yAxisCenter,
              type: 'solid',
              axis: 'yAxis',
              showLabel: true,
              width: 2,
              labelProps: {
                fontSize: 14,
                color: colors.secondary,
                formatter: () => `${yAxisCenter}%`,
              },
            }),
          // Fake an x-axis line at the of the bottom of the grid because the default x-axis line renders at 0.
          benchmarkLine({
            silent: true,
            value: yAxisMin,
            type: 'solid',
            color: colors.navy,
          }),
        ],
        color: COLORS,
        toolbox: {
          right: 90,
          feature: {
            dataZoom: zoomTool,
          },
        },
        tooltip: {
          ...tooltipStyle,
          formatter: (tooltipData: {
            componentType: string
            seriesName: string
            name: string
            data: { value: [number, number]; originalValue?: [number, number] }
          }) => {
            if (!tooltipData.data || tooltipData.componentType === 'markLine') return null
            const { seriesName, name, data } = tooltipData
            // If the value was jittered, make sure to use the original value.
            const [xVal, yVal] = data.originalValue || data.value
            return renderEchartTooltip(
              renderTooltip({
                xVal,
                yVal,
                xName: seriesName,
                yName: name,
                activeQuadrant: getQuadrantFromPlotPoint(xVal, yVal, xAxisCenter, yAxisCenter),
              }),
            )
          },
        },
      }}
    />
  )
}

// Memoize the chart so we don't rerender when changing state on the parent
// during the quadrant hovers.
const arePropsEqual = (prevProps: PlotProps, nextProps: PlotProps) => {
  // Only look at the relevant properties that could rerender the chart data.
  return (
    isEqual(prevProps.chartScores, nextProps.chartScores) &&
    isEqual(prevProps.legendNames, nextProps.legendNames)
  )
}

const MemoizedScatterPlot = memo(ScatterPlot, arePropsEqual)

/**
 * This component contains a few important workarounds:
 *
 * 1. Custom quadrant hover. The main problem to solve is that we need to enable mouse events in the chart
 *    (in order to show tooltips), and show a hover overlay over each quadrant of the chart. There is no
 *    native way to do this in Echarts and there is no CSS-only solution. Having one div trigger a display
 *    on a sibling element (e.g. having chart hover trigger the overlay) doesn't work because the quadrant hovers
 *    are more granular than the entire chart div. Instead, we do this manually by
 *      a) overlaying a div over the chart that's split into quadrants
 *      b) disabling pointer events on the overlay
 *      c) watching the mousemove event in a div over the chart (not the overlay),
 *        then calculating which quadrant of the overlay the mouse is in, and setting the hover state.
 *
 * 2. Custom legends. Echarts was cropping the name/icon of legends when rendering horizontally.
 *    To do it custom, we're a) manually slicing / paginating the legends b) memoizing the scatter plot
 *    so the legends don't rerender/lose state when the state of parent changes during hover.
 */
const PlotWithHover: React.FC<CommonProps & {
  legendNames: string[]
  yAxisTitle: string
  xAxisTitle: string
  quadrantTitles: ScatterQuadrants
}> = props => {
  const { yAxisTitle, xAxisTitle, legendNames, quadrantTitles } = props
  const classes = useStyles()
  const chartRef = useRef<null | HTMLDivElement>(null)
  // Keep pagination for legend items so they scroll through them,
  // but always show all of the data points on the chart.
  const [page, setPage] = useState(0)
  useEffect(() => {
    // Reset the page when legend names change.
    setPage(0)
  }, [legendNames])
  const [activeQuadrantHover, setActiveQuadrantHover] = useState<keyof ScatterQuadrants | null>(
    null,
  )
  // Disable the quadrant hovers when zooming since they no longer reflect the correct data.
  const [showQuadrant, setShowQuadrants] = useState(true)
  useEffect(() => {
    if (!showQuadrant) {
      setActiveQuadrantHover(null)
    }
  }, [showQuadrant])
  const setQuadrantHoverFromMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = chartRef.current?.getBoundingClientRect()
    if (!rect || !showQuadrant) return null
    const xPosition = e.clientX - rect.left
    const yPosition = e.clientY - rect.top
    // If mouse not in rect bounds, ignore.
    // Note: these should technichally be percentage based, but it's okay
    // if the hover doesn't start perfectly at the edges.
    const yRectPadding = 40
    const xRectPadding = 80
    if (
      xPosition < xRectPadding ||
      xPosition > rect.width - xRectPadding ||
      yPosition < yRectPadding ||
      yPosition > rect.height - 120
    ) {
      return setActiveQuadrantHover(null)
    }
    const halfwayX = rect.width / 2
    const halfwayY = rect.height / 2 - yRectPadding
    if (xPosition < halfwayX) {
      if (yPosition < halfwayY) {
        return setActiveQuadrantHover('TOP_LEFT')
      }
      return setActiveQuadrantHover('BOTTOM_LEFT')
    }
    if (yPosition < halfwayY) {
      return setActiveQuadrantHover('TOP_RIGHT')
    }
    return setActiveQuadrantHover('BOTTOM_RIGHT')
  }
  // Because we have limited ability to style the echarts legends, the only way we can guarantee it
  // doesn't break is to try to fix the width. The best way to do this is to limit the legend names
  // based on how long they would be. Note: character counts don't map exactly to pixel width,
  // but they are an approximation and we can set the upper bound low so it doesn't break.
  const legendNameGroups = groupStringsByLineLength({
    words: legendNames,
    maxLength: 160,
    paddingPerWord: 6,
  })
  const numPages = legendNameGroups.length
  return (
    <div style={{ position: 'relative' }}>
      <div
        onMouseMove={setQuadrantHoverFromMouseMove}
        onMouseLeave={() => setActiveQuadrantHover(null)}
        ref={chartRef}
      >
        <MemoizedScatterPlot
          {...props}
          updateShowQuadrants={setShowQuadrants}
          legendNames={legendNameGroups[page]}
        />
      </div>
      <div className={classes.quadrantsWrapper}>
        <div style={{ display: 'flex', height: '50%' }}>
          <div
            className={cn(classes.quadrant, classes.neutralQuadrant, {
              [classes.activeQuadrant]: activeQuadrantHover === 'TOP_LEFT',
            })}
          >
            <QuadrantLabel quadrantTitles={quadrantTitles} activeQuadrant={activeQuadrantHover} />
          </div>
          <div
            className={cn(classes.quadrant, classes.successQuadrant, {
              [classes.activeQuadrant]: activeQuadrantHover === 'TOP_RIGHT',
            })}
          >
            <QuadrantLabel quadrantTitles={quadrantTitles} activeQuadrant={activeQuadrantHover} />
          </div>
        </div>
        <div style={{ display: 'flex', height: '50%' }}>
          <div
            className={cn(classes.quadrant, classes.highRiskQuadrant, {
              [classes.activeQuadrant]: activeQuadrantHover === 'BOTTOM_LEFT',
            })}
          >
            <QuadrantLabel quadrantTitles={quadrantTitles} activeQuadrant={activeQuadrantHover} />
          </div>
          <div
            className={cn(classes.quadrant, classes.neutralQuadrant, {
              [classes.activeQuadrant]: activeQuadrantHover === 'BOTTOM_RIGHT',
            })}
          >
            <QuadrantLabel quadrantTitles={quadrantTitles} activeQuadrant={activeQuadrantHover} />
          </div>
        </div>
      </div>
      <div className={classes.xAxisLine}>
        <Typography>{xAxisTitle}</Typography>
        <XAxisLine />
      </div>
      <div className={classes.yAxisLine}>
        <Typography>{yAxisTitle}</Typography>
        <YAxisLine />
      </div>
      {numPages > 1 && (
        <div className={classes.pagination}>
          <IconButton disabled={page === 0} onClick={() => setPage(page - 1)}>
            <ArrowLeft />
          </IconButton>
          <span>
            {page + 1}/{numPages}
          </span>
          <IconButton disabled={page === numPages - 1} onClick={() => setPage(page + 1)}>
            <ArrowRight />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default PlotWithHover
