import React, { useState } from 'react'

import { FetchPolicy } from '@apollo/client'
import { Typography, Tooltip, Collapse, Chip, withStyles, WithStyles } from '@material-ui/core'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import cn from 'classnames'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import SimpleSearch from 'components/Blocks/Search/SimpleSearch'
import { insightsStyle } from 'components/Insights/InsightsStyle'
import { InsightsFilter } from 'config/LocalStorage'
import {
  FilterProductTypePermissionEnum,
  FilterTypeFragment,
  FilterValueFragment,
  SurveyProductTypeEnum,
  useInsightsSurveysFilterValuesQuery,
} from 'generated/graphql'
import { CONTACT_EMAIL } from 'utils/constants'

type ProductType = SurveyProductTypeEnum | FilterProductTypePermissionEnum
/**
 * Component to render a control bar with Survey filters. If you need to include additional controls
 * within the div, pass them as children.
 */
const FILTER_CLOSE_DELAY = 2500

const firstLetter = (word: string) => {
  if (word.length === 0) {
    return 'A'
  }
  return word[0].toUpperCase()
}

export const getFilterKey = (filter: { dtCode: string; valueUuid: string }) => {
  return `${filter.dtCode}-${filter.valueUuid}`
}

interface FTProps extends WithStyles<typeof insightsStyle> {
  filterType: FilterTypeFragment
  selectedFilterType: FilterTypeFragment | null
  surveyProductTypes: ProductType[]
  handleFilterClick(filterType: FilterTypeFragment): void
}
const FilterType: React.FC<FTProps> = ({
  classes,
  filterType,
  selectedFilterType,
  surveyProductTypes,
  handleFilterClick,
}) => {
  const active = selectedFilterType && filterType.dtCode === selectedFilterType.dtCode
  if (
    filterType.insightsProductType === FilterProductTypePermissionEnum.ALL ||
    surveyProductTypes.every(pt => pt === filterType.insightsProductType)
  ) {
    return (
      <Typography
        className={cn(classes.filter, {
          [classes.filterActive]: active,
        })}
        onClick={() => handleFilterClick(filterType)}
        variant="body2"
      >
        {filterType.name} {active ? <ArrowDropUp /> : <ArrowDropDown />}
      </Typography>
    )
  }
  return (
    <Tooltip
      className={classes.filter}
      title={`To learn more about how to filter results by ${filterType.name}, contact ${CONTACT_EMAIL}.`}
    >
      <Typography variant="body2" color="textSecondary">
        {filterType.name} <ArrowDropDown />
      </Typography>
    </Tooltip>
  )
}

interface ShowFilterValuesProps extends WithStyles<typeof insightsStyle> {
  filterType: FilterTypeFragment
  selectedFilters: Array<InsightsFilter>
  surveyUuids: string[]
  toggleFilter(filterType: FilterTypeFragment, filterValue: FilterValueFragment): void
  closeSelectedFilterType(): void
  validFilterValueNames: string[]
  selected: boolean
}
type ShowFilterValuesState = {
  letters: string[]
  chips: FilterValueFragment[]
  numbers: string[]
  searchString: string
  validChipCount: number
}

class ShowFilterValues extends React.Component<ShowFilterValuesProps, ShowFilterValuesState> {
  timeoutId: null | ReturnType<typeof setTimeout>

  constructor(props: ShowFilterValuesProps) {
    super(props)
    this.timeoutId = null
    this.state = {
      letters: [],
      chips: [],
      numbers: [],
      searchString: '',
      validChipCount: 0,
    }
  }

  static getDerivedStateFromProps = (
    props: ShowFilterValuesProps,
    state: ShowFilterValuesState,
  ) => {
    const { searchString } = state
    const letters: string[] = []
    const numbers: string[] = []
    let newSearchString = searchString
    const query = searchString.toUpperCase().trim()
    let validChipCount = 0
    let chips: FilterValueFragment[] = []
    if (props.filterType.filterValues) {
      const { filterValues } = props.filterType
      chips = filterValues.filter(val => {
        const isValid = props.validFilterValueNames.includes(val.name)
        if (isValid) {
          validChipCount += 1
        }
        const keep = isValid && (query === '' || val.name.toUpperCase().includes(query))
        if (keep) {
          const letter = firstLetter(val.name)
          if (/^\d$/.test(letter)) {
            if (!numbers.includes(letter)) {
              numbers.push(letter)
            }
          } else if (!letters.includes(letter)) {
            letters.push(letter)
          }
        }
        return keep
      })
    } else {
      // Filter is closed, clear search
      newSearchString = ''
    }
    return { validChipCount, letters, numbers, chips, searchString: newSearchString }
  }

  onMouseOut = () => {
    this.timeoutId = setTimeout(() => {
      this.timeoutId = null
      this.props.closeSelectedFilterType()
    }, FILTER_CLOSE_DELAY)
  }

  onMouseOver = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
  }

  render() {
    const { classes, filterType, selected, selectedFilters, toggleFilter } = this.props
    const { dtCode } = filterType
    const { letters, numbers, chips, searchString, validChipCount } = this.state

    if (!selected && this.timeoutId) {
      // User manually closed the filter type so clear
      // the setTimeout scheduled to close it
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }

    const isChipActive = (fv: FilterValueFragment) => {
      // TODO: check this
      // When converting to tsx, unify this with the content of toggleFilter inside InsightsContainer.tsx
      const filterObj = {
        id: fv.id,
        index: filterType.index,
        dtCode: filterType.dtCode,
        name: filterType.name,
        valueUuid: fv.uuid,
        valueName: fv.name,
      }
      return Boolean(
        selectedFilters.find(filter => getFilterKey(filter) === getFilterKey(filterObj)),
      )
    }

    const allLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const allNumbers = '0123456789'
    const letterAnchorsAdded: string[] = []
    const shouldLetterAddAnchor = (name: string) => {
      const letter = firstLetter(name)
      if (letterAnchorsAdded.includes(letter)) {
        return false
      }
      letterAnchorsAdded.push(letter)
      return true
    }

    const scrollToLetter = (evt: any, letter: string) => {
      evt.preventDefault()
      evt.stopPropagation()
      const anchor = document.getElementById(`letter${letter}`)
      const box = document.getElementById(`scrolling-chips`)
      if (!anchor || !box) return
      // 233 to account for the distance from top of body
      // - 16 is to make sure full row is visible
      box.scrollTop = anchor.offsetTop - 233 - 16
    }
    const showFilterAndSearch = validChipCount >= 52
    return (
      <Collapse
        in={selected}
        // These events are available on all React Components but not exposed in the Material UI.
        // I spent awhile trying to augment the module, unsuccessfully, so for now just ignore the errors.
        // @ts-ignore
        onMouseEnter={this.onMouseOver}
        // @ts-ignore
        onFocus={this.onMouseOver}
        // @ts-ignore
        onBlur={this.onMouseOut}
        // @ts-ignore
        onMouseLeave={this.onMouseOut}
      >
        {showFilterAndSearch && (
          <div className={classes.chipControls}>
            <div className={classes.filterLetters}>
              {numbers.length > 0 &&
                allNumbers.split('').map(number => {
                  if (numbers.includes(number)) {
                    return (
                      <a
                        key={number}
                        href={`#number${number}`}
                        onClick={evt => scrollToLetter(evt, number)}
                      >
                        {number}
                      </a>
                    )
                  }
                  return <span key={number}>{number}</span>
                })}
              {allLetters.split('').map(letter => {
                if (letters.includes(letter)) {
                  return (
                    <a
                      key={letter}
                      href={`#letter${letter}`}
                      onClick={evt => scrollToLetter(evt, letter)}
                    >
                      {letter}
                    </a>
                  )
                }
                return <span key={letter}>{letter}</span>
              })}
            </div>
            <SimpleSearch
              withSearchAdornment
              theme="light"
              query={searchString}
              handleClose={() => this.setState({ searchString: '' })}
              placeholder={`Search ${filterType.namePlural || ''}`}
              handleSearch={(query: string) => this.setState({ searchString: query })}
              numResults={chips.length}
            />
          </div>
        )}
        <div id="scrolling-chips" className={classes.selectableChips}>
          {dtCode &&
            chips.map(val => (
              <React.Fragment key={val.name}>
                {showFilterAndSearch && shouldLetterAddAnchor(val.name) && (
                  <div className={classes.chipLetterAnchor} id={`letter${firstLetter(val.name)}`}>
                    {firstLetter(val.name)}
                  </div>
                )}
                <Chip
                  label={val.name}
                  onClick={() => toggleFilter(filterType, val)}
                  className={cn(classes.chip, {
                    [classes.chipActive]: isChipActive(val),
                  })}
                />
              </React.Fragment>
            ))}
        </div>
      </Collapse>
    )
  }
}

interface ShowFilterValuesContainerProps extends WithStyles<typeof insightsStyle> {
  filterType: FilterTypeFragment
  selectedFilters: Array<InsightsFilter>
  surveyUuids: string[]
  toggleFilter(filterType: FilterTypeFragment, filterValue: FilterValueFragment): void
  closeSelectedFilterType(): void
  fetchPolicy: FetchPolicy
  selected: boolean
}
const ShowFilterValuesContainer: React.FC<ShowFilterValuesContainerProps> = props => {
  const { surveyUuids, selectedFilters, filterType, fetchPolicy } = props
  const filtersForLimitingChips: string[] = []
  selectedFilters.forEach(fv => {
    if (fv.index < filterType.index) {
      // Only look at filters at a higher level in hiearchy than currentUser
      filtersForLimitingChips.push(fv.valueUuid)
    }
  })
  const result = useInsightsSurveysFilterValuesQuery({
    variables: {
      surveyUuids,
      filters: filtersForLimitingChips,
      forDtCode: filterType.dtCode,
    },
    // Need to refetch these so that when participant filter values are updated the changes reflect
    fetchPolicy,
  })
  return (
    <ResponseHandler {...result}>
      {(data, { loading }) => {
        let validFilterValueNames = filterType.filterValues.map(fv => fv.name)
        if (!loading && data?.insightsSurveysFilterValues) {
          validFilterValueNames =
            data.insightsSurveysFilterValues.find(fvData => fvData.dtCode === filterType.dtCode)
              ?.groups || validFilterValueNames
        }
        return <ShowFilterValues validFilterValueNames={validFilterValueNames} {...props} />
      }}
    </ResponseHandler>
  )
}

interface ShowSelectedValuesProps extends WithStyles<typeof insightsStyle> {
  selectedFilters: Array<InsightsFilter>
  selectedFilterType: FilterTypeFragment | null
  removeFilter(filterType: InsightsFilter): void
  clearFilters(): void
}
const ShowSelectedValues: React.FC<ShowSelectedValuesProps> = ({
  selectedFilters,
  selectedFilterType,
  removeFilter,
  clearFilters,
  classes,
}) => {
  const showSelected = selectedFilters.length > 0
  const showBar =
    selectedFilters.length > 0 && selectedFilterType && Object.keys(selectedFilterType).length > 0
  return (
    <Collapse in={showSelected}>
      <div
        className={cn(classes.selectedCategories, {
          [classes.selectedCategoriesFiltersVisible]: showBar,
        })}
      >
        <div className={classes.selectedCategoriesLabel}>Selected Categories</div>
        <div className={classes.selectedChips}>
          {selectedFilters.map(filter => (
            <Chip
              key={getFilterKey(filter)}
              label={filter.valueName}
              className={classes.chip}
              onDelete={() => removeFilter(filter)}
            />
          ))}
        </div>
        <button type="button" className={classes.clearAllFilters} onClick={clearFilters}>
          Clear all filters
        </button>
      </div>
    </Collapse>
  )
}

interface Props extends WithStyles<typeof insightsStyle> {
  filters: Array<FilterTypeFragment>
  selectedFilters: Array<InsightsFilter>
  surveyUuids: string[]
  surveyProductTypes: ProductType[]
  toggleFilter(filterType: FilterTypeFragment, filterValue: FilterValueFragment): void
  removeFilter(filterType: InsightsFilter): void
  clearFilters(): void
  fetchPolicy: FetchPolicy
}
const FilterControls: React.FC<Props> = ({
  children,
  classes,
  filters,
  selectedFilters,
  surveyUuids,
  surveyProductTypes,
  toggleFilter,
  removeFilter,
  clearFilters,
  fetchPolicy,
}) => {
  const [selectedFilterType, setSelectedFilterType] = useState<FilterTypeFragment | null>(null)
  const handleFilterClick = (filterType: FilterTypeFragment) => {
    if (filterType.dtCode === selectedFilterType?.dtCode) {
      setSelectedFilterType(null)
    } else {
      setSelectedFilterType(filterType)
    }
  }
  return (
    <>
      <div className={classes.controls}>
        <div className={classes.filterControls} id="filters">
          {filters.length > 0 && (
            <Typography
              className={classes.filterByText}
              variant="body2"
              color="textSecondary"
              style={{ fontSize: 14 }}
            >
              Filter By:
            </Typography>
          )}
          {filters.map(filterType => (
            <FilterType
              key={filterType.dtCode}
              filterType={filterType}
              selectedFilterType={selectedFilterType}
              surveyProductTypes={surveyProductTypes}
              handleFilterClick={handleFilterClick}
              classes={classes}
            />
          ))}
        </div>
        {children}
      </div>
      <div className={classes.controlsExpand}>
        {filters
          .filter(
            filterType =>
              selectedFilterType?.filterTypeUuid === filterType.filterTypeUuid &&
              (filterType.insightsProductType === FilterProductTypePermissionEnum.ALL ||
                surveyProductTypes.every(pt => pt === filterType.insightsProductType)),
          )
          .map(filterType => {
            return (
              <ShowFilterValuesContainer
                key={filterType.filterTypeUuid}
                filterType={filterType}
                selectedFilters={selectedFilters}
                toggleFilter={toggleFilter}
                closeSelectedFilterType={() => setSelectedFilterType(null)}
                surveyUuids={surveyUuids}
                fetchPolicy={fetchPolicy}
                classes={classes}
                selected
              />
            )
          })}
        <ShowSelectedValues
          classes={classes}
          selectedFilters={selectedFilters}
          selectedFilterType={selectedFilterType}
          removeFilter={removeFilter}
          clearFilters={clearFilters}
        />
      </div>
    </>
  )
}

// We cannot convert this to hooks until this issue is resolved for the `Chip` component
// https://github.com/mui-org/material-ui/issues/16374#issuecomment-721617297 is resolved
export default withStyles(insightsStyle)(FilterControls)
