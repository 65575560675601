import React from 'react'

import Typography from '@material-ui/core/Typography'
import cn from 'classnames'

import TTBasicStatementChart from 'components/Insights/Blocks/TTBasicStatementChart'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import StatementChangeLabel from 'components/Insights/TimeTrending/Blocks/StatementChangeLabel'
import { ScoreTypeGroups, StatementData } from 'components/Insights/TimeTrending/utils'
import { TimeTrendXAxisLabelData } from 'utils/echartsHelpers'

type Props = {
  statementsData: StatementData[]
  // TODO: make this required once every caller can pass this data in
  companyOverallScores?: ScoreTypeGroups[0]
  xAxisLabelData: TimeTrendXAxisLabelData
  benchmarkName: string
  title?: string
  didSelectFilters: boolean
}
const TTBasicStatementsCard: React.FC<Props> = ({
  statementsData,
  companyOverallScores,
  xAxisLabelData,
  benchmarkName,
  title,
  didSelectFilters,
}) => {
  const classes = useInsightsStyles()
  return (
    <>
      <Typography className={classes.title} variant="subtitle1">
        {title}
      </Typography>
      {statementsData.map(
        (
          { companyOverallScores: statementOverallScores, delta, label, focus, benchmarkPositive },
          index,
        ) => {
          return (
            <div key={label} className={classes.timeTrendingChartRow}>
              <div className={cn(classes.halfWidth, classes.printWidthThird)}>
                <StatementChangeLabel scoreDelta={Number(delta)} title={label} subtitle={focus} />
              </div>
              <TTBasicStatementChart
                xAxisLabelData={xAxisLabelData}
                statementScores={statementOverallScores}
                companyOverallScores={companyOverallScores}
                benchmarkName={benchmarkName}
                showLegend={index === 0}
                benchmarkScore={benchmarkPositive}
                didSelectFilters={didSelectFilters}
              />
            </div>
          )
        },
      )}
    </>
  )
}

export default TTBasicStatementsCard
