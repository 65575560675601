import React, { useState } from 'react'

import Button from 'components/Blocks/CustomButtons/Button'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import UpdatePersonalInfoQuestion from 'components/Survey/Wizard/Steps/Questions/UpdatePersonalInfoQuestion'
import useStyles from 'components/Survey/Wizard/Steps/Summary/styles'
import { useSurveysQuestionsQuery, QCategory } from 'generated/graphql'

type Props = { surveyUuid: string; code: string; onClose(): void }

const UpdatePersonalInfoQuestions: React.FC<Props> = ({ surveyUuid, code, onClose }) => {
  const result = useSurveysQuestionsQuery({ variables: { surveyUuid } })
  return (
    <ResponseHandler {...result}>
      {data => {
        const question = data.questions.find(
          q => q.category === QCategory.PERSONAL_INFO && q.benchmarkCode === code,
        )!
        return (
          <UpdatePersonalInfoQuestion
            updateChoicesOnly
            question={question}
            surveyUuid={surveyUuid}
            onClose={onClose}
          />
        )
      }}
    </ResponseHandler>
  )
}

type SectionProps = {
  personalInfoQuestions: { code: string; name: string }[]
  surveyUuid: string
}

const PersonalInfoQuestionsSection: React.FC<SectionProps> = ({
  personalInfoQuestions,
  surveyUuid,
}) => {
  const classes = useStyles()
  const [openedQuestionCode, setOpenedQuestionCode] = useState('')

  return (
    <>
      <br />
      Personal Info Questions
      {personalInfoQuestions.map(q => (
        <li key={q.code}>
          <span className={classes.mute}>
            {q.name} Question:
            <Button
              noMargins
              onClick={() => setOpenedQuestionCode(q.code)}
              color="secondaryNoBackground"
            >
              Edit Answer Options
            </Button>
          </span>
        </li>
      ))}
      {openedQuestionCode && (
        <UpdatePersonalInfoQuestions
          surveyUuid={surveyUuid}
          code={openedQuestionCode}
          onClose={() => setOpenedQuestionCode('')}
        />
      )}
    </>
  )
}

export default PersonalInfoQuestionsSection
