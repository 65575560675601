import React, { useState } from 'react'

import { Checkbox, Typography, makeStyles, Switch } from '@material-ui/core'
import cn from 'classnames'

import Panel from 'components/Blocks/Accordions/Panel'
import { Group, Survey } from 'components/Settings/Team/Groups/GroupsTypes'

const useStyles = makeStyles(() => ({
  switchesSection: {
    '& >span': {
      display: 'flex',
      alignItems: 'center',
      '& >p': {
        width: 200,
      },
    },
  },
  columns: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxHeight: 240,
    overflow: 'auto',
  },
  surveysSection: {
    '& >span': {
      width: 200,
      display: 'flex',
      alignItems: 'center',
    },
  },
}))

type UpdateGroup = (arg: Partial<Group>) => void
type SProps = {
  group: Group
  field: keyof Group
  label: string
  updateGroup: UpdateGroup
}
const SwitchRow: React.FC<SProps> = ({ group, field, label, updateGroup }) => {
  return (
    <span>
      <Typography>{label}</Typography>
      <Switch
        checked={Boolean(group[field])}
        onChange={() => updateGroup({ [field]: !group[field] })}
      />
      {group[field] ? 'Yes' : 'No'}
    </span>
  )
}

type SurveysProps = { group: Group; updateGroup: UpdateGroup; surveys: Survey[] }
const SurveysSection: React.FC<SurveysProps> = ({ group, updateGroup, surveys }) => {
  const groupSurveyUuids = group.surveys.map(s => s.uuid)
  // A helpful to checkbox to select all the surveys but isn't stored on the backend
  const [allCurrentSurveys, setAllCurrentSurveys] = useState(
    group.surveys.length === surveys.length,
  )
  const classes = useStyles()
  const updateSurvey = (checked: boolean, survey: Survey) => {
    if (checked) {
      updateGroup({
        surveys: group.surveys.filter(s => s.uuid !== survey.uuid),
        allSurveys: false,
      })
    } else {
      updateGroup({ surveys: [...group.surveys, survey] })
    }
    setAllCurrentSurveys(false)
  }
  // Selects all the current surveys
  const updateAllCurrentSurveys = (checked: boolean) => {
    if (checked) {
      updateGroup({ surveys: [], allSurveys: false })
    } else {
      updateGroup({ surveys })
    }
    setAllCurrentSurveys(!checked)
  }
  // Selects all the current surveys and the "future" checkbox which translates to group.allSurveys
  const updateAllSurveys = (checked: boolean) => {
    if (checked) {
      updateGroup({ allSurveys: false, surveys: [] })
    } else {
      updateGroup({ allSurveys: true, surveys })
    }
    setAllCurrentSurveys(!checked)
  }
  return (
    <div className={cn(classes.columns, classes.surveysSection)}>
      <span>
        <Checkbox
          checked={allCurrentSurveys}
          onChange={() => updateAllCurrentSurveys(allCurrentSurveys)}
        />
        All existing surveys
      </span>
      <span>
        <Checkbox
          checked={Boolean(group.allSurveys)}
          onChange={() => updateAllSurveys(Boolean(group.allSurveys))}
        />
        All existing surveys AND future surveys
      </span>
      {surveys.map(s => {
        const checked = groupSurveyUuids.includes(s.uuid)
        return (
          <span key={s.uuid}>
            <Checkbox checked={checked} onChange={() => updateSurvey(checked, s)} />
            {s.name}
          </span>
        )
      })}
    </div>
  )
}

enum PanelEnum {
  GENERAL = 'general',
  SURVEYS = 'surveys',
}

type Props = {
  hasActionPlans: boolean
  hasActionPlansManagement: boolean
  hasCertifications: boolean
  group: Group
  updateGroup: UpdateGroup
  surveys: Survey[]
}
const GeneralSurveyModuleSettings: React.FC<Props> = ({
  hasActionPlans,
  hasActionPlansManagement,
  hasCertifications,
  group,
  updateGroup,
  surveys,
}) => {
  const classes = useStyles()
  const showGeneralPanel = hasActionPlans || hasActionPlansManagement || hasCertifications
  const [expanded, setExpanded] = useState(showGeneralPanel ? PanelEnum.GENERAL : PanelEnum.SURVEYS)
  const switchProps = { group, updateGroup }

  return (
    <>
      {showGeneralPanel && (
        <Panel
          expanded={expanded === PanelEnum.GENERAL}
          onExpandChange={() => setExpanded(PanelEnum.GENERAL)}
          title="General Permissions"
          subtitle="People in this group will have access to:"
        >
          <div className={classes.switchesSection}>
            {hasActionPlans && (
              <SwitchRow label="Use Action Plans" field="canUseActionPlans" {...switchProps} />
            )}
            {hasActionPlansManagement && (
              <SwitchRow
                label="Manage Action Plans"
                field="canManageActionPlans"
                {...switchProps}
              />
            )}
            {/** TODO: reenable when certification hub is ready to handle non-admin users. */}
            {/* {hasCertifications && (
              <>
                <SwitchRow
                  label="Manage Certifications"
                  field="canManageCertifications"
                  {...switchProps}
                />
                <SwitchRow
                  label="View Certification Results"
                  field="canViewCertificationResults"
                  {...switchProps}
                />
              </>
            )} */}
          </div>
        </Panel>
      )}
      <Panel
        id="SurveysPanel"
        expanded={expanded === PanelEnum.SURVEYS}
        onExpandChange={() => setExpanded(PanelEnum.SURVEYS)}
        title="Surveys Access"
        subtitle="Select the surveys accessible to this group:"
      >
        <SurveysSection group={group} updateGroup={updateGroup} surveys={surveys} />
      </Panel>
    </>
  )
}

export default GeneralSurveyModuleSettings
