import React, { Component } from 'react'

import { graphql } from '@apollo/client/react/hoc'
import groupBy from 'lodash/groupBy'
import PropTypes from 'prop-types'

import QueryHandler from 'components/Blocks/Layout/QueryHandler'
import Questions from 'components/Survey/Wizard/Steps/Questions/Questions'
import {
  SurveysSurveyDocument,
  SurveysQuestionsByCategoryDocument,
  SurveysDeleteQuestionDocument,
} from 'generated/graphql'

class QuestionsContainer extends Component {
  state = {
    openUpdateQuestion: false,
    openDeleteQuestion: false,
  }

  handleCloseUpdateQuestion = () => {
    this.setState({
      openUpdateQuestion: false,
      questionUuid: undefined,
    })
  }

  handleOpenDeleteQuestion = questionUuid => {
    this.setState({
      openDeleteQuestion: true,
      questionUuid,
    })
  }

  handleCloseDeleteQuestion = () => {
    this.setState({
      openDeleteQuestion: false,
      questionUuid: undefined,
    })
  }

  handleDeleteQuestion = async () => {
    const { questionUuid } = this.state
    const { survey } = this.props
    const result = await this.props.DeleteQuestion({
      variables: {
        surveyUuid: survey.uuid,
        questionUuid,
      },
      refetchQueries: [
        {
          query: SurveysQuestionsByCategoryDocument,
          variables: {
            surveyUuid: survey.uuid,
          },
        },
        {
          query: SurveysSurveyDocument,
          variables: {
            uuid: survey.uuid,
          },
        },
      ],
    })
    const { errors } = result.data.deleteQuestion
    if (errors) {
      alert(errors)
    } else {
      this.handleCloseDeleteQuestion()
    }
  }

  render() {
    const { openUpdateQuestion, openDeleteQuestion, questionUuid } = this.state
    const {
      handleOpenDeleteQuestion,
      handleCloseDeleteQuestion,
      handleDeleteQuestion,
      props: { survey, ...restProps },
    } = this
    // Why after a survey is created, this code is reached with an empty survey?!
    if (!survey.uuid) {
      return <div>Loading...</div>
    }

    return (
      <QueryHandler
        query={SurveysQuestionsByCategoryDocument}
        variables={{ surveyUuid: survey.uuid }}
      >
        {({ questionsByCategory }) => {
          return (
            <Questions
              survey={survey}
              questions={groupBy(questionsByCategory, 'category')}
              openUpdateQuestion={openUpdateQuestion}
              openDeleteQuestion={openDeleteQuestion}
              handleCloseUpdateQuestion={this.handleCloseUpdateQuestion}
              handleOpenDeleteQuestion={handleOpenDeleteQuestion}
              handleCloseDeleteQuestion={handleCloseDeleteQuestion}
              questionUuid={questionUuid}
              handleDeleteQuestion={handleDeleteQuestion}
              {...restProps}
            />
          )
        }}
      </QueryHandler>
    )
  }
}

QuestionsContainer.propTypes = {
  survey: PropTypes.object.isRequired,
  goNext: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
}

export default graphql(SurveysDeleteQuestionDocument, { name: 'DeleteQuestion' })(
  QuestionsContainer,
)
