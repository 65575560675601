import React from 'react'

import colormap from 'colormap'
import isNil from 'lodash/isNil'
import pako from 'pako'
import xlsx from 'xlsx'

import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import {
  CurrentUserQuery,
  FilterTypeFragment,
  OrganizationFragment,
  SurveyProductTypeEnum,
  UserCoreFragment,
  SurveyTypeEnum,
  ActionPlanSurveyType,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'
import {
  CONTACT_EMAIL,
  MIN_SHOWABLE_RESULTS_CODE,
  SURVEY_TYPE_TO_DEFAULT_LABEL,
  URLS,
  PRODUCT_TYPE_LABELS,
  SURVEY_TYPES_TO_PRODUCT_TYPE,
} from 'utils/constants'
import { MaybeList, Solution } from 'utils/types'

export const getTeaserMessage = (label: string) =>
  `To learn more about using ${label} Surveys, contact ${CONTACT_EMAIL}`

export const pluralize = (word: string, count: number, suffixOrPlural: string = 's') => {
  if (count === 1) {
    return word
  }
  return ['s', 'es'].includes(suffixOrPlural) ? `${word}${suffixOrPlural}` : suffixOrPlural
}

export const getDefaultLandingURL = (user: CurrentUserQuery['currentUser'] | UserCoreFragment) => {
  if (user.accessToSurveyProduct) {
    return URLS.EMPLOYEE_INSIGHTS.DASHBOARD
  }
  if (user.residentAccessToSurveyProduct) {
    return URLS.RESIDENT_INSIGHTS.DASHBOARD
  }
  return URLS.LOGIN
}

export const getSolutionFromProductType = (
  organization: OrganizationFragment,
  productType: SurveyProductTypeEnum,
): Solution => {
  return {
    [SurveyProductTypeEnum.EMPLOYEE]: organization.solution,
    [SurveyProductTypeEnum.RESIDENT]: organization.residentSolution,
  }[productType] as Solution
}

export const getSurveyTypeLabel = (
  surveyType: SurveyTypeEnum | ActionPlanSurveyType,
  includePrefix: boolean = true,
) => {
  const productType = SURVEY_TYPES_TO_PRODUCT_TYPE[surveyType]
  const prefix = PRODUCT_TYPE_LABELS[productType]

  return includePrefix
    ? `${prefix} ${SURVEY_TYPE_TO_DEFAULT_LABEL[surveyType]}`
    : SURVEY_TYPE_TO_DEFAULT_LABEL[surveyType]
}

export const formatScore = (score?: null | number, useRounding: boolean = true) => {
  if (isNil(score)) return null
  if (score === MIN_SHOWABLE_RESULTS_CODE) {
    return null
  }
  return useRounding ? Math.round(score) : score
}

export const formatTooltipScore = (
  score: null | number | undefined,
  minShowableResults: number,
  previousScore?: null | number,
  // When a score has already been converted to a value for display on the chart
  // we need to check independently whether it's a <minShowableResults
  isLessThanMin?: boolean,
): string | React.ReactElement => {
  if (isLessThanMin || isNil(score)) return `<${minShowableResults}`
  if (isNil(previousScore)) {
    return `${Math.round(score)}%`
  }
  return <ScoreChangeArrow delta={score - previousScore} />
}

export const formatTooltipBenchmarkScore = (score?: null | number) => {
  if (!isNil(score)) return `${score}%`
  return 'No Score'
}

export const toggleListWithAllType = <ListType, _>(
  previous: ListType[],
  selected: ListType[],
  allType: ListType,
  numTypes: number,
) => {
  const allWasSelected = previous.includes(allType)
  if (
    selected.length === 0 || // Default to All when none are selected
    (!allWasSelected &&
      (selected.includes(allType) || // All was clicked, drop others
        selected.length === numTypes)) // All types were individually selected
  ) {
    return [allType]
  }
  if (allWasSelected && selected.length > 1) {
    // Drop All when another is selected
    return selected.filter(x => x !== allType)
  }
  return selected
}

export const runDownloadQuery = async (queryFn: () => void) => {
  try {
    await queryFn()
  } catch (e) {
    emitter.emit('ERROR', 'There was an unexpected error. Please try again later')
    return
  }
  emitter.emit(
    'SUCCESS',
    'Your download is being generated! You’ll receive a download link here and via email in a few minutes when it is ready.',
  )
}

/** Used to filter the user filters that appear on the survey */
export const getVisibleFilterTypes = (
  filters: FilterTypeFragment[],
  surveyFilterTypeUuids: string[],
) => {
  return filters.filter(f => surveyFilterTypeUuids.includes(f.filterTypeUuid))
}

// Turns [blue, orange, purple] into "blue, orange and purple"
export const joinWords = (words?: MaybeList) => {
  if (!words) return ''
  if (words.length <= 1) return words.join('')
  const initial = words.slice(0, words.length - 1)
  return `${initial.join(', ')} and ${words[words.length - 1]}`
}

// Group a list of strings into individual sections when they exceed a max
// ex. ("[a, long, and, confusing, sentence]", 5) => "[a long, and confusing, sentence]"
export const groupStringsByLineLength = ({
  words,
  maxLength,
  paddingPerWord,
}: {
  words: string[]
  maxLength: number
  paddingPerWord: number
}) => {
  const groups: string[][] = []
  let group: string[] = []
  for (const word of words) {
    const newGroup = [...group, word]
    const groupSize = newGroup.join('').length + newGroup.length * paddingPerWord
    if (groupSize > maxLength) {
      groups.push(group)
      group = [word]
    } else {
      group.push(word)
    }
  }
  // Add the remaining line if we have outstanding words.
  if (group.length) {
    groups.push(group)
  }
  return groups
}

// Inserts line breaks into a long string when words exceed a max length.
// ex. ("a long and confusing sentence", 5) => "a long\n and confusing\n sentence"
export const splitTextByLineLength = (text: string, maxLength: number) => {
  const words = text.split(' ')
  const lines = []
  let line = ''
  for (const word of words) {
    line += `${word} `
    if (line.length > maxLength) {
      lines.push(line)
      line = ''
    }
  }
  // Add the remaining line if we have outstanding words.
  if (line) {
    lines.push(line)
  }
  return lines.join('\n')
}

export const randomNumberInRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min
}

export const numberWithCommas = (val: number) => {
  // Ex: 3000000 => 3,000,000
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const generateXlsxDownload = <Column extends { name: string; accessor: string }, Row>(
  columns: Column[],
  rows: Row[],
  sheetname: string,
  filename: string,
) => {
  const xlsxData = [
    columns.map(c => c.name),
    ...rows.map(row => columns.map(({ accessor }) => row[accessor as keyof Row])),
  ]
  const worksheet = xlsx.utils.aoa_to_sheet(xlsxData)
  const workbook = xlsx.utils.book_new()
  xlsx.utils.book_append_sheet(workbook, worksheet, sheetname)
  xlsx.writeFile(workbook, filename)
}

export const downloadQR = () => {
  const canvas = document.getElementById('qrcode') as HTMLCanvasElement
  if (!canvas) {
    return
  }
  const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
  const downloadLink = document.createElement('a')
  downloadLink.href = pngUrl
  downloadLink.download = 'qrcode.png'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export const handleMutationResponse = (
  errors?: string[] | null,
  successMessage?: string,
  popupTimeToClose?: number,
) => {
  if (errors) {
    emitter.emit('ERROR', errors, popupTimeToClose)
    return false
  }
  if (successMessage) {
    emitter.emit('SUCCESS', successMessage, popupTimeToClose)
  }
  return true
}

// A typescript version of utils/index.js we can use to slowly type the util functions
// Until we can convert it to index.tsx

type RGBColor = [number, number, number]
export const MAX_VIRIDS_COLORS = 9
export const getViridisColors = (n: number) => {
  const getStackColors = (rgb: RGBColor) => ({
    p: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`,
    i: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.5)`,
    n: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.25)`,
  })
  const viridis: RGBColor[] = colormap({
    colormap: 'viridis',
    nshades: Math.max(10, n + 1),
    format: 'rba',
    alpha: 1,
  })
  // Little trickery if n > 0 we actually want to
  // reverse colors and return equal distribution
  // across the viridis scale to get the best visual effect
  let barColors = viridis
  if (n > 0 && n < MAX_VIRIDS_COLORS) {
    // Pop off the first cuz  that's the purple for company color
    const indexes = [
      [5],
      [5, 9],
      [2, 5, 9],
      [2, 5, 7, 9],
      [2, 4, 5, 7, 9],
      [2, 4, 5, 7, 8, 9],
      [2, 3, 4, 5, 7, 8, 9],
      [2, 3, 4, 5, 6, 7, 8, 9],
    ]
    barColors = indexes[n - 1].map(i => viridis[i])
  }
  return barColors.map(getStackColors)
}

export const getFirstViridisColors = () => {
  return ['#4D195B', '#83688A', '#B6A7BA']
}

// An immutable verion of Array.reverse
export const reverse = <ItemType, _>(array: ItemType[]) => {
  return array.map((_, idx) => array[array.length - 1 - idx])
}

// Assert property can be used to index in object, cast as index type if so.
export function isKeyOf<Obj, _>(
  key: string | number | symbol | undefined,
  obj: Obj,
): key is keyof Obj {
  return key ? key in obj : false
}

export const truncateWithEllipsis = (s: string, limit: number) => {
  if (s.length <= limit) {
    return s
  }
  return `${s.slice(0, limit - 2)}...`
}

const BREAKING_SPACES = /[ \f\n\r\t\v\u2028\u2029]+/
export const splitWordsByLine = (text: string, charactersPerLine: number) => {
  const words = text.split(BREAKING_SPACES)
  const lines = []
  // const characterCount = 0
  let currentLine = ''
  words.forEach(word => {
    const lengthWithWord = currentLine.length + word.length + 1
    if (lengthWithWord > charactersPerLine) {
      lines.push(currentLine.trim())
      currentLine = word
    } else {
      currentLine += ` ${word}`
    }
  })
  if (currentLine.length) {
    lines.push(currentLine.trim())
  }
  return lines
}

/**
 * Taken from: https://stackoverflow.com/a/22373197/9328286
 * This function converts a UTF-8 array into a string.
 * Using a manual function here for a few reasons:
 * 1) To prevent "Maximum Callstack" errors on large data sets (the whole reason we're using compression)
 * 2) To ensure we use Javascript that's compatible on all browsers
 * 3) To avoid including the "text-encoder" library which has a giant bundle size
 * Note: this function is slower than some other algorithms, but it's the best available option
 * that satisfies the above constraints.
 */
/* eslint-disable */
const Utf8ArrayToStr = (array: any) => {
  let out
  let i
  let len
  let c
  let char2
  let char3

  out = ''
  len = array.length
  i = 0
  while (i < len) {
    c = array[i++]
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c)
        break
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++]
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f))
        break
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++]
        char3 = array[i++]
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0),
        )
        break
    }
  }

  return out
}
/* eslint-enable */

export const decodeAndUnzipJSON = <ResultType, _>(result: string) => {
  // Decode base64 (convert ascii to binary)
  const strData = atob((result as unknown) as string)

  // Convert binary string to character-number array
  const charData = strData.split('').map(x => x.charCodeAt(0))

  // Turn number array into byte-array
  const binaryData = new Uint8Array(charData)

  // Pako decompress
  const uncompressed = pako.inflate(binaryData)

  // Convert gunzipped byteArray back to ascii string:
  const jsonString = Utf8ArrayToStr(uncompressed)
  return JSON.parse(jsonString) as ResultType
}

export const getEnumValuesFromNames = (
  names: string[],
  enumChoices: Array<{ value: string; name: string; label: string }>,
) => {
  return enumChoices
    .filter(enumMember => names.includes(enumMember.name))
    .map(enumMember => enumMember.value)
}

export const cleanNewLines = (text: string): string => text.split('\r\n').join('\n')

export const getNpsAbbreviation = (productType: SurveyProductTypeEnum) => {
  return {
    [SurveyProductTypeEnum.EMPLOYEE]: 'eNPS',
    [SurveyProductTypeEnum.RESIDENT]: 'NPS',
  }[productType]
}

export const getNpsLabel = (productType: SurveyProductTypeEnum, includeAbbreviation = true) => {
  let label = {
    [SurveyProductTypeEnum.EMPLOYEE]: `Employee Net Promoter Score`,
    [SurveyProductTypeEnum.RESIDENT]: `Net Promoter Score`,
  }[productType]

  if (includeAbbreviation) {
    label += ` (${getNpsAbbreviation(productType)})`
  }

  return label
}
