import React, { useState } from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import * as EmptyActionPlanImage from 'assets/img/action_plans/empty-action-plan.png'
import AddStatementsDialog from 'components/ActionPlans/AddStatementsDialog'
import Button from 'components/Blocks/CustomButtons/Button'
import { SurveyNodeAP as SurveyNode } from 'utils/types'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  title: {
    marginTop: spacing(3),
    marginBottom: spacing(3),
  },
  addStatementsButton: {
    marginTop: spacing(5),
    marginBottom: spacing(8),
  },
  emptyImageAP: {
    borderRadius: 5,
  },
}))

type Props = {
  surveys: SurveyNode[]
  targetUserUuid?: string
}
const EmptyActionPlan: React.FC<Props> = ({ surveys, targetUserUuid }) => {
  const classes = useStyles()
  const [showAddStatementsDialog, setShowAddStatementsDialog] = useState(false)

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        Welcome to your Action Plan
      </Typography>
      <Typography color="textSecondary">
        Your latest survey {surveys[0].name} just closed, now it’s time to create an action plan.
      </Typography>
      <Button
        id="create-action-plan"
        className={classes.addStatementsButton}
        color="primary"
        onClick={() => {
          setShowAddStatementsDialog(true)
        }}
      >
        Create Action Plan
      </Button>
      <img
        className={classes.emptyImageAP}
        width={400}
        src={EmptyActionPlanImage}
        alt="Empty Action Plan"
      />
      {showAddStatementsDialog && (
        <AddStatementsDialog
          surveys={surveys}
          targetUserUuid={targetUserUuid}
          onClose={() => setShowAddStatementsDialog(false)}
        />
      )}
    </div>
  )
}

export default EmptyActionPlan
