import React, { useState } from 'react'

import { makeStyles, MenuItem, Typography } from '@material-ui/core'
import capitalize from 'lodash/capitalize'
import orderBy from 'lodash/orderBy'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import HorizontalBarChart, {
  BENCHMARK_LINE_TYPES,
  Data as ChartData,
  TooltipSeries,
} from 'components/Blocks/Charts/HorizontalBarChart'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import BarChartSortButton from 'components/Insights/Blocks/BarChartSortButton'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import { NpsTooltip, NPS_COLORS } from 'components/Insights/CustomSurvey/Cards/NpsRecommendCard'
import NpsBreakdownModalCard from 'components/Insights/Discharge/NpsBreakdownModalCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import { getRelevantFilters } from 'components/Insights/Snapshot/DetailedBreakdownCard'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import StandardTooltip from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import { NPS_THRESHOLDS } from 'components/Survey/Wizard/Steps/Questions/NpsQuestions'
import {
  DataTypeCode,
  FilterTypeFragment,
  InsightsNpsGroupScoresByDataTypeQuery,
  NpsGroupsEnum,
  useInsightsNpsGroupScoresByDataTypeQuery,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { formatTooltipScore, getNpsAbbreviation, getNpsLabel } from 'utils'
import { MIN_SHOWABLE_RESULTS, MIN_SHOWABLE_RESULTS_CODE, SORT_OPTIONS } from 'utils/constants'

const useStyles = makeStyles(({ spacing, palette }) => ({
  highlightsContainer: {
    marginTop: spacing(3),
  },
  npsCard: {
    padding: spacing(3),
    borderTop: `1px solid ${palette.common.navy25}`,
  },
}))

type Props = {
  survey: InsightsSurvey
  filters: string[]
  startDate?: string
  endDate?: string
  filterTypes: FilterTypeFragment[]
  isSnapshotNps?: boolean
}

export enum NpsChartType {
  NPS_SCORE = 'NPS_SCORE',
  GROUPS_BREAKDOWN = 'GROUPS_BREAKDOWN',
}

export const getNpsChartTypeLabel = (
  chartType: NpsChartType,
  productType: SurveyProductTypeEnum,
) => {
  return {
    [NpsChartType.NPS_SCORE]: getNpsLabel(productType),
    [NpsChartType.GROUPS_BREAKDOWN]: '% Respondents',
  }[chartType]
}

type NpsGroupData = NonNullable<
  InsightsNpsGroupScoresByDataTypeQuery['insightsNpsGroupScoresByDataType']
>

type HighlightProps = {
  productType: SurveyProductTypeEnum
  groupScores: NpsGroupData
}
type OrderType = SORT_OPTIONS.LOW_TO_HIGH | SORT_OPTIONS.HIGH_TO_LOW
const NpsBreakdownHighlights: React.FC<HighlightProps> = ({ productType, groupScores }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [sortOrder, setSortOrder] = useState<OrderType>(SORT_OPTIONS.LOW_TO_HIGH)
  const [highlights] = getNpsChartData(groupScores, NpsChartType.NPS_SCORE, sortOrder)
  const ORDER_TO_LABEL: { [key in OrderType]: string } = {
    [SORT_OPTIONS.LOW_TO_HIGH]: 'Lowest Scoring Locations',
    [SORT_OPTIONS.HIGH_TO_LOW]: 'Top Scoring Locations',
  }

  const npsAbbr = getNpsAbbreviation(productType)

  return (
    <div className={classes.highlightsContainer}>
      <SnapshotChartHeader
        title="Locations to Focus On"
        description={`These locations’ ${npsAbbr} scores are significantly lower than your top scoring location with an ${npsAbbr} 80 score. Improving these scores, can improve your overall satisfaction score.`}
        extraControls={
          <TextDropdown
            value={sortOrder}
            renderValue={order => `Show: ${ORDER_TO_LABEL[order as OrderType]}`}
            onChange={e => {
              setSortOrder(e.target.value as OrderType)
            }}
          >
            <MenuItem value={SORT_OPTIONS.LOW_TO_HIGH}>
              {ORDER_TO_LABEL[SORT_OPTIONS.LOW_TO_HIGH]}
            </MenuItem>
            <MenuItem value={SORT_OPTIONS.HIGH_TO_LOW}>
              {ORDER_TO_LABEL[SORT_OPTIONS.HIGH_TO_LOW]}
            </MenuItem>
          </TextDropdown>
        }
      />
      <div className={classes.highlights}>
        {highlights.slice(0, 5).map(({ label, score }) => {
          if (!label || score === null || score === undefined) return <div />
          return (
            <div key={label}>
              <Typography variant="h4">
                {npsAbbr} {Math.round(score)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {label}
              </Typography>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const getNpsBreakdownChartData = (
  npsGroupScoresByDataType: NpsGroupData,
  sortedLabels: string[],
) => {
  const promotersData: ChartData[] = []
  const passivesData: ChartData[] = []
  const detractorsData: ChartData[] = []
  npsGroupScoresByDataType
    // Filter lessThanMin scores from the chart
    .filter(groupScore => groupScore.promoters !== MIN_SHOWABLE_RESULTS_CODE)
    .forEach(groupScore => {
      promotersData.push({
        score: groupScore.promoters,
        label: groupScore.label,
        color: NPS_COLORS[NpsGroupsEnum.PROMOTERS],
        lessThanMin: false,
      })
      passivesData.push({
        score: groupScore.passives,
        label: groupScore.label,
        color: NPS_COLORS[NpsGroupsEnum.PASSIVES],
        lessThanMin: false,
      })
      detractorsData.push({
        score: groupScore.detractors,
        label: groupScore.label,
        color: NPS_COLORS[NpsGroupsEnum.DETRACTORS],
        lessThanMin: false,
      })
    })
  return [detractorsData, passivesData, promotersData].map(groupedData =>
    // Order the data based on the NPS score ordering
    orderBy(groupedData, data => sortedLabels.findIndex(label => label === data.label)),
  )
}

const getNpsScores = (npsGroupScoresByDataType: NpsGroupData) => {
  return npsGroupScoresByDataType
    .map(groupScore => ({
      score: Math.round(groupScore.promoters - groupScore.detractors),
      label: groupScore.label,
      color: colors.navy,
      lessThanMin: groupScore.promoters === MIN_SHOWABLE_RESULTS_CODE,
    }))
    .filter(score => !score.lessThanMin) // Filter lessThanMin scores from the chart
}

export const getNpsChartData = (
  npsGroupScoresByDataType: NpsGroupData,
  chartType: NpsChartType,
  currentSort: SORT_OPTIONS,
) => {
  const npsScoreData = getNpsScores(npsGroupScoresByDataType)
  let sortedNpsData
  if (currentSort === SORT_OPTIONS.A_TO_Z) {
    sortedNpsData = orderBy(npsScoreData, 'label', 'asc')
  } else if (currentSort === SORT_OPTIONS.LOW_TO_HIGH) {
    sortedNpsData = orderBy(npsScoreData, 'score', 'asc')
  } else {
    sortedNpsData = orderBy(npsScoreData, 'score', 'desc')
  }
  const sortedLabels = sortedNpsData.map(data => data.label)
  if (chartType === NpsChartType.GROUPS_BREAKDOWN) {
    return getNpsBreakdownChartData(npsGroupScoresByDataType, sortedLabels as string[])
  }
  return [sortedNpsData]
}

type NpsChartProps = {
  chartType: NpsChartType
  currentSort: SORT_OPTIONS
  showHighlights?: boolean
  dtCode: DataTypeCode
} & Props
export const NpsBreakdownChart: React.FC<NpsChartProps> = ({
  startDate,
  endDate,
  filters,
  survey,
  dtCode,
  chartType,
  currentSort,
  showHighlights = true,
}) => {
  const result = useInsightsNpsGroupScoresByDataTypeQuery({
    variables: {
      surveyUuid: survey.uuid,
      dtCode,
      filters,
      startDate,
      endDate,
    },
  })
  const npsAbbr = getNpsAbbreviation(survey.productType)
  return (
    <ResponseHandler {...result}>
      {({ insightsNpsGroupScoresByDataType }) => {
        if (!insightsNpsGroupScoresByDataType) {
          return <div />
        }
        if (insightsNpsGroupScoresByDataType.length < 2) {
          return (
            <EmptyState
              title="Not enough data"
              description="Not enough data to display results."
              Icon={ChartUpwardIcon}
            />
          )
        }
        let chartProps
        const pageSize = 8
        const chartData = getNpsChartData(insightsNpsGroupScoresByDataType, chartType, currentSort)
        if (chartType === NpsChartType.GROUPS_BREAKDOWN) {
          chartProps = {
            chartData,
            chartType: 'bar',
            stackBars: true,
            numXAxisBars: 5,
            barWidth: 20,
            pageSize: pageSize * 3, // multiplies by 3 because of the 3x stacked data
            extraLegends: Object.values(NpsGroupsEnum)
              .sort()
              .map(group => ({
                name: `${capitalize(group)} (${NPS_THRESHOLDS[group][0]}-${
                  NPS_THRESHOLDS[group][1]
                })`,
                color: NPS_COLORS[group],
              })),
          }
        } else {
          chartProps = {
            chartData,
            chartType: 'line',
            xAxisRange: [-100, 100],
            numXAxisBars: 9,
            pageSize,
            extraLegends: [
              {
                color: colors.navy,
                name: `${npsAbbr} Score`,
                icon: `image://${BENCHMARK_LINE_TYPES[1]}`,
              },
            ],
          }
        }
        return (
          <>
            <HorizontalBarChart
              chartWidth="100%"
              {...chartProps}
              useTopLegends
              showSeriesLabel={false}
              paginationWidth={300}
              tooltipFormatter={(series: TooltipSeries) => {
                const score = insightsNpsGroupScoresByDataType.find(
                  d => d.label === series[0].data.name,
                )
                if (!score?.label) return null
                const rows = [
                  {
                    label: `${npsAbbr} Score`,
                    value: `${npsAbbr} ${Math.round(score.promoters - score.detractors)}`,
                  },
                  {
                    label: 'Detractors',
                    value: formatTooltipScore(score.detractors, survey.minShowableResults),
                    color: NPS_COLORS[NpsGroupsEnum.DETRACTORS],
                  },
                  {
                    label: 'Passives',
                    value: formatTooltipScore(score.passives, survey.minShowableResults),
                    color: NPS_COLORS[NpsGroupsEnum.PASSIVES],
                  },
                  {
                    label: 'Promoters',
                    value: formatTooltipScore(score.promoters, survey.minShowableResults),
                    color: NPS_COLORS[NpsGroupsEnum.PROMOTERS],
                  },
                ]
                return (
                  <StandardTooltip
                    title={score.label}
                    description={`Survey: ${survey.name}`}
                    rows={rows}
                  />
                )
              }}
            />
            {showHighlights && insightsNpsGroupScoresByDataType.length > 5 && (
              <NpsBreakdownHighlights
                groupScores={insightsNpsGroupScoresByDataType}
                productType={survey.productType}
              />
            )}
          </>
        )
      }}
    </ResponseHandler>
  )
}

const NpsBreakdownCard: React.FC<Props> = props => {
  const { survey, isSnapshotNps, filterTypes, filters } = props
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [currentSort, setCurrentSort] = useState(SORT_OPTIONS.LOW_TO_HIGH)
  const [modalChartOpen, setModalChartOpen] = useState(false)
  const [chartType, setChartType] = useState(NpsChartType.GROUPS_BREAKDOWN)
  const relevantFilter = getRelevantFilters(filterTypes, filters)[0]

  let description = `
            View ${getNpsAbbreviation(
              survey.productType,
            )} Scores by ${relevantFilter.name.toLowerCase()} to better monitor trends,
            and reach out to specific ${relevantFilter.namePlural?.toLowerCase()} that may need improvement.
            See if any patterns emerge revealing overall strengths or weaknesses
            in your organization.`
  if (survey.hasConfidentialResults) {
    description += ` *${relevantFilter.namePlural} with less than
      ${MIN_SHOWABLE_RESULTS} responses have been hidden to protect confidentiality.`
  }
  const npsAbbr = getNpsAbbreviation(survey.productType)

  return (
    <div id="nps-breakdown-snap" className={isSnapshotNps ? classes.npsCard : ''}>
      <SnapshotChartHeader
        title={`${getNpsLabel(survey.productType, false)} by ${relevantFilter.name}`}
        tooltip={<NpsTooltip productType={survey.productType} />}
        snapId="nps-breakdown-snap"
        description={description}
        onSeeMore={() => setModalChartOpen(true)}
        extraControls={
          <TextDropdown
            id="nps-breakdown-dropdown"
            value={chartType}
            renderValue={type =>
              `Show: ${getNpsChartTypeLabel(type as NpsChartType, survey.productType)}`
            }
            onChange={e => {
              setChartType(e.target.value as NpsChartType)
            }}
          >
            <MenuItem value={NpsChartType.NPS_SCORE}>
              {getNpsChartTypeLabel(NpsChartType.NPS_SCORE, survey.productType)}
            </MenuItem>
            <MenuItem value={NpsChartType.GROUPS_BREAKDOWN}>
              {getNpsChartTypeLabel(NpsChartType.GROUPS_BREAKDOWN, survey.productType)}
            </MenuItem>
          </TextDropdown>
        }
      />
      <div className={classes.relativeContainer}>
        <NpsBreakdownChart
          {...props}
          currentSort={currentSort}
          chartType={chartType}
          dtCode={relevantFilter.dtCode}
        />
        <div className={classes.chartSortButton1}>
          <BarChartSortButton
            currentSort={currentSort}
            handleChangeSort={newSort => setCurrentSort(newSort)}
            sortLabels={{
              [SORT_OPTIONS.A_TO_Z]: 'A to Z',
              [SORT_OPTIONS.LOW_TO_HIGH]: `Low to High (${npsAbbr} Score)`,
              [SORT_OPTIONS.HIGH_TO_LOW]: `High to Low (${npsAbbr} Score)`,
            }}
          />
        </div>
      </div>
      {modalChartOpen && (
        <NpsBreakdownModalCard {...props} onClose={() => setModalChartOpen(false)} />
      )}
    </div>
  )
}

export default NpsBreakdownCard
