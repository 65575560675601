import React from 'react'

import { makeStyles } from '@material-ui/core'

import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsBenchmark, InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import TimeTrendingContainer from 'components/Insights/TimeTrending/TimeTrendingContainer'
import TTGroupedScoresChart from 'components/Insights/TimeTrending/TTGroupedScoresChart'
import {
  getBenchmarkScore,
  getOverallScoreGroups,
  transformGroupedScoresToEcharts,
  groupScoresByLabel,
} from 'components/Insights/TimeTrending/utils'
import { TimeTrendingChartKey, TimeTrendingSurveysKey } from 'config/LocalStorage'
import { useInsightsTimeTrendingDimensionsQuery } from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import { SurveyNode } from 'utils/types'

const useStyles = makeStyles(({ spacing }) => ({
  chart: {
    marginTop: spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

type Props = {
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  filters: string[]
  benchmark: InsightsBenchmark
}
const TTDimensionsCard: React.FC<Props> = ({ survey, availableSurveys, filters, benchmark }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <>
      <div className={classes.fullRow} id="tt-dimensions-snap">
        <SnapshotChartHeader
          title="Dimensions of Trust Over Time"
          tooltip="See how the score for each Dimension of Trust has changed over time. Use the filters at the top of the page to see how scores have changed for a specific group of employees."
          hasTimeTrending
          timeTrendingChartKey={TimeTrendingChartKey.EMPLOYEE_DIMENSIONS}
          snapId="tt-dimensions-snap"
          useBottomPadding={false}
        />
        <TimeTrendingContainer
          chartSurveysKey={TimeTrendingSurveysKey.EMPLOYEE_DIMENSIONS}
          availableSurveys={availableSurveys}
          survey={survey}
          excludePulse
        >
          {({ selectedSurveys }) => (
            <DynamicSurveyQuery
              surveys={selectedSurveys}
              variables={{ benchmark, filters }}
              queryHook={useInsightsTimeTrendingDimensionsQuery}
            >
              {(data, loading) => {
                const surveyScoresByDimension = groupScoresByLabel(data, 'dimensions')
                const dimensionNames = Object.keys(surveyScoresByDimension)
                const dimensionScoreGroups = transformGroupedScoresToEcharts(
                  dimensionNames,
                  surveyScoresByDimension,
                  data.map(({ uuid }) => uuid),
                )
                const companyOverallScoreGroups = getOverallScoreGroups(data)
                return (
                  <div className={classes.chart}>
                    <TTGroupedScoresChart
                      xAxisLabelData={selectedSurveys}
                      groupedScores={dimensionScoreGroups}
                      benchmarkName={benchmark.name}
                      companyOverallScores={companyOverallScoreGroups[0]}
                      benchmarkScore={getBenchmarkScore(data[0].overallIndex)}
                      minShowableResults={selectedSurveys[0].minShowableResults}
                      title="Dimension of Trust"
                      didSelectFilters={!loading && filters.length > 0}
                      surveyScoresByGroup={surveyScoresByDimension}
                      orderedGroupNames={dimensionNames}
                    />
                  </div>
                )
              }}
            </DynamicSurveyQuery>
          )}
        </TimeTrendingContainer>
      </div>
    </>
  )
}

export default TTDimensionsCard
