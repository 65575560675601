import React, { useState, ReactElement } from 'react'

import { Tooltip } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import Button from 'components/Blocks/CustomButtons/Button'
import UpdateQuestionContainer from 'containers/Survey/Wizard/Steps/Questions/UpdateQuestionContainer'
import { QCategory, SurveysQuestionsQuery, SurveysSurveyQuery } from 'generated/graphql'

type Props = {
  survey: SurveysSurveyQuery['survey']
  questionCategory: QCategory
  existingQuestions?: NonNullable<SurveysQuestionsQuery['questions']>
  maxCustomQuestionsReachedTooltip?: string
  showIcon?: boolean
  buttonLabelPrefix?: ReactElement
  buttonLabel?: string
}

const AddCustomQuestionButton: React.FC<Props> = ({
  survey,
  questionCategory,
  existingQuestions,
  maxCustomQuestionsReachedTooltip = '',
  showIcon = false,
  buttonLabelPrefix = <></>,
  buttonLabel = '',
}) => {
  const [isOpenUpdateQuestion, setIsOpenUpdateQuestion] = useState(false)
  if (!survey.allowedCategoriesForCustomQuestions.includes(questionCategory)) {
    return <></>
  }

  return (
    <>
      {isOpenUpdateQuestion && (
        <UpdateQuestionContainer
          question={null}
          surveyUuid={survey.uuid}
          category={questionCategory}
          onClose={() => setIsOpenUpdateQuestion(false)}
          existingQuestions={existingQuestions}
        />
      )}
      <Tooltip title={maxCustomQuestionsReachedTooltip}>
        <span>
          {buttonLabelPrefix}
          {/* wrap button in an outer element so we can still fire the tooltip event when button is disabled */}
          <Button
            id={`addCustomQuestion${survey.type}`}
            onClick={() => setIsOpenUpdateQuestion(true)}
            color="secondaryNoBackground"
            disabled={Boolean(maxCustomQuestionsReachedTooltip)}
            style={{
              padding: 0,
              margin: 0,
            }}
          >
            {showIcon && (
              <>
                <AddCircleIcon /> &nbsp;
              </>
            )}
            {buttonLabel}
          </Button>
        </span>
      </Tooltip>
    </>
  )
}

export default AddCustomQuestionButton
