import React, { useEffect, useState } from 'react'

import SortButton from 'components/Blocks/CustomButtons/SortButton'
import Pagination from 'components/Blocks/Pagination/EnumeratedPagination'
import TTBasicStatementsCard from 'components/Insights/Blocks/TTBasicStatementsCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import {
  InsightsBenchmark,
  InsightsSurvey,
  InsightsTabProps,
} from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { useTimeTrendingTimeframeSettings } from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import TimeTrendingContainer from 'components/Insights/TimeTrending/TimeTrendingContainer'
import {
  getOverallScoreGroups,
  ScoreTypeGroups,
  transformTimeTrendingStatementsToEchartScores,
  getStatementsList,
} from 'components/Insights/TimeTrending/utils'
import { TimeTrendingChartKey, TimeTrendingSurveysKey } from 'config/LocalStorage'
import { useInsightsTimeTrendingStatementsQuery } from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import DynamicTimeframeQuery from 'HOC/DynamicTimeframeQuery'
import { TimeTrendingType } from 'utils/constants'
import { usePulseInTimeTrending } from 'utils/customHooks'
import { TimeTrendXAxisLabelData } from 'utils/echartsHelpers'
import { SurveyNode } from 'utils/types'

enum ORDER_TYPES {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

const STATEMENTS_PER_PAGE = 6

type CProps = {
  statementsData: ReturnType<typeof transformTimeTrendingStatementsToEchartScores>
  xAxisLabelData: TimeTrendXAxisLabelData
  benchmarkName: string
  companyOverallScores: ScoreTypeGroups[0]
}
const ChartContainer: React.FC<CProps> = ({
  statementsData,
  companyOverallScores,
  benchmarkName,
  xAxisLabelData,
}) => {
  const classes = useInsightsStyles()
  const [page, setPage] = useState(0)
  useEffect(() => {
    // When the number of statements are updated from searching, make sure to reset the page to 0.
    setPage(0)
  }, [statementsData])
  const sliceStart = page * STATEMENTS_PER_PAGE
  // When a user searches, there is a brief period when a page can be out of bounds before useEffect has reset it.
  if (sliceStart > statementsData.length) return <div />
  const slicedStatements = statementsData.slice(sliceStart, sliceStart + STATEMENTS_PER_PAGE)
  return (
    <>
      <TTBasicStatementsCard
        statementsData={slicedStatements}
        xAxisLabelData={xAxisLabelData}
        benchmarkName={benchmarkName}
        didSelectFilters={false /** TODO update this */}
        companyOverallScores={companyOverallScores}
      />
      <div className={classes.statementsFooter}>
        {statementsData.length > STATEMENTS_PER_PAGE && (
          <Pagination
            totalPages={Math.ceil(statementsData.length / STATEMENTS_PER_PAGE)}
            currentPage={page}
            onPageChange={newPage => setPage(newPage)}
          />
        )}
      </div>
    </>
  )
}

type Props = {
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  filters: string[]
  benchmark: InsightsBenchmark
  timeTrendingChartKey: TimeTrendingChartKey
  timeTrendingSurveysKey: TimeTrendingSurveysKey
  searchQuery?: string
  timeTrendingType?: InsightsTabProps['timeTrendingType']
}
const TTStatementsCard: React.FC<Props> = ({
  survey,
  availableSurveys,
  filters,
  benchmark,
  timeTrendingChartKey,
  timeTrendingSurveysKey,
  searchQuery,
  timeTrendingType,
}) => {
  const { timeRanges } = useTimeTrendingTimeframeSettings(survey)
  const classes = useInsightsStyles()
  const [selectedOrder, setSelectedOrder] = useState<ORDER_TYPES>(ORDER_TYPES.ASCENDING)
  const includesPulse = usePulseInTimeTrending(availableSurveys, timeTrendingSurveysKey)
  let tooltip = `Compare your scores for each statement from ${survey.name} to any of your past surveys. To the left of each statement, see how much the score changed from the first survey to the most recent one. When you hover on the chart, see how much the score changed from the previous survey.`
  if (includesPulse) {
    tooltip += ' Only statements included in all selected surveys are included.'
  }
  return (
    <div className={classes.fullRow} id="tt-statements-snap">
      <SnapshotChartHeader
        title="Statement scores over time"
        tooltip={tooltip}
        hasTimeTrending
        timeTrendingChartKey={timeTrendingChartKey}
        timeTrendingSurveyUuid={
          timeTrendingType === TimeTrendingType.TIMEFRAME ? survey.uuid : undefined
        }
        snapId="tt-statements-snap"
        screenshotStrategy="html2canvas"
        useBottomPadding={false}
        extraControls={
          <SortButton
            availableOptions={Object.values(ORDER_TYPES)}
            currentOption={selectedOrder}
            onChange={sort => setSelectedOrder(sort as ORDER_TYPES)}
            labelsMap={{
              [ORDER_TYPES.ASCENDING]: 'Low to High',
              [ORDER_TYPES.DESCENDING]: 'High to Low',
            }}
            useBackground={false}
            headerLabel="Score Change:"
          />
        }
      />
      {timeTrendingType === TimeTrendingType.TIMEFRAME ? (
        <DynamicTimeframeQuery
          timeRanges={timeRanges}
          variables={{
            filters,
            surveyUuid: survey.uuid,
          }}
          queryHook={useInsightsTimeTrendingStatementsQuery}
        >
          {data => {
            const statements = getStatementsList(data, [survey], searchQuery)
            return (
              <ChartContainer
                statementsData={transformTimeTrendingStatementsToEchartScores({
                  statements,
                  results: data,
                  order: selectedOrder,
                })}
                xAxisLabelData={timeRanges}
                benchmarkName={benchmark.name}
                companyOverallScores={getOverallScoreGroups(data)[0]}
              />
            )
          }}
        </DynamicTimeframeQuery>
      ) : (
        <TimeTrendingContainer
          chartSurveysKey={timeTrendingSurveysKey}
          availableSurveys={availableSurveys}
          survey={survey}
        >
          {({ selectedSurveys }) => (
            <DynamicSurveyQuery
              surveys={selectedSurveys}
              variables={{ filters, benchmark }}
              queryHook={useInsightsTimeTrendingStatementsQuery}
            >
              {data => {
                const statements = getStatementsList(data, selectedSurveys, searchQuery)
                return (
                  <ChartContainer
                    statementsData={transformTimeTrendingStatementsToEchartScores({
                      statements,
                      results: data,
                      order: selectedOrder,
                    })}
                    xAxisLabelData={selectedSurveys}
                    benchmarkName={benchmark.name}
                    companyOverallScores={getOverallScoreGroups(data)[0]}
                  />
                )
              }}
            </DynamicSurveyQuery>
          )}
        </TimeTrendingContainer>
      )}
    </div>
  )
}

export default TTStatementsCard
