import React, { useContext } from 'react'

import { Card, Tooltip, Typography } from '@material-ui/core'
import cn from 'classnames'
import flatten from 'lodash/flatten'

import useStyles from './styles'

import { ReactComponent as ImproveEmployeeIcon } from 'assets/img/action_plans/improve-employee.svg'
import { ReactComponent as ImproveResidentIcon } from 'assets/img/action_plans/improve-resident.svg'
import { taskStatuses } from 'components/ActionPlans/constants'
import ProgressBar from 'components/ActionPlansMgmt/ProgressBar'
import { getCountsSummary } from 'components/ActionPlansMgmt/utils'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { StoreContext } from 'config/LocalStorage'
import {
  ActionItemsCountsType,
  ActionPlanCountsType,
  SurveyProductTypeEnum,
  useActionPlansMgmtActivityQuery,
} from 'generated/graphql'
import { CONTACT_EMAIL, PRODUCT_TYPE_LABELS } from 'utils/constants'
import { AppliedFilters } from 'utils/types'

const otherLabel = 'All Other Types'

enum PastDays {
  SEVEN_DAYS = 7,
  THIRTY_DAYS = 30,
  SIX_MONTHS = 183,
}

export const UserActivityCard: React.FC<{ filters: AppliedFilters }> = ({ filters }) => {
  const classes = useStyles()
  const {
    store: { selectedFollowFilters, actionPlansSearchQuery },
  } = useContext(StoreContext)
  const variables = {
    pastDays: [PastDays.SEVEN_DAYS, PastDays.THIRTY_DAYS, PastDays.SIX_MONTHS],
    filterValueUuids: flatten(Object.values(filters)),
    selectedFollowFilters,
    searchQuery: actionPlansSearchQuery,
  }
  const result = useActionPlansMgmtActivityQuery({ variables })

  return (
    <ResponseHandler {...result}>
      {({ actionPlansActivity }) => {
        return (
          <Card className={cn(classes.card, classes.cardActivity)}>
            <Typography className={classes.cardTitle} variant="h6">
              User Activity
            </Typography>
            {actionPlansActivity.map(({ pastDays, activityCount }) => (
              <div key={pastDays} className={cn(classes.inlineContent, classes.marginBottom)}>
                <Typography color="textSecondary">
                  Past {pastDays === PastDays.SIX_MONTHS ? `6 months` : `${pastDays} days`}:
                </Typography>
                <Typography>{activityCount}</Typography>
              </div>
            ))}
          </Card>
        )
      }}
    </ResponseHandler>
  )
}

type TooltipProgressBarProps = {
  counts: ActionItemsCountsType
}

const TooltipProgressBar: React.FC<TooltipProgressBarProps> = ({ counts, children }) => {
  const classes = useStyles()
  return (
    <Tooltip
      title={
        <div>
          {taskStatuses.map(({ text, countsAttr }) => (
            <div key={countsAttr} className={classes.inlineContent}>
              <Typography>{text}:</Typography>
              <Typography color="textSecondary">
                {counts[countsAttr as keyof ActionItemsCountsType]}
              </Typography>
            </div>
          ))}
        </div>
      }
    >
      <div>{children}</div>
    </Tooltip>
  )
}

type ProgressCardEnabledWithCustomStatementsProps = {
  productType: SurveyProductTypeEnum
  counts: ActionItemsCountsType
  customStatementCounts: ActionItemsCountsType
}

const ProgressCardEnabledWithCustomStatements: React.FC<ProgressCardEnabledWithCustomStatementsProps> = ({
  productType,
  counts,
  customStatementCounts,
}) => {
  const classes = useStyles()
  const getRow = (
    rowCounts: ActionItemsCountsType,
    theProductType: null | SurveyProductTypeEnum,
  ) => {
    const { percentage } = getCountsSummary(rowCounts)
    return (
      <TooltipProgressBar counts={rowCounts}>
        <Typography variant="h6" style={{ width: '5%' }}>
          {rowCounts.activeStatements}
        </Typography>
        <Typography color="textSecondary" style={{ width: '40%' }}>
          {theProductType ? `${PRODUCT_TYPE_LABELS[theProductType]} Statements` : otherLabel}
        </Typography>
        <div style={{ width: '25%' }}>
          <ProgressBar percentage={percentage} fat />
        </div>
        <Typography style={{ width: '27%' }}>
          {percentage}%{' '}
          <Typography component="span" color="textSecondary">
            Complete
          </Typography>
        </Typography>
      </TooltipProgressBar>
    )
  }
  return (
    <div className={classes.cardProgressCustom}>
      {getRow(counts, productType)}
      {getRow(customStatementCounts, null)}
    </div>
  )
}

type ProgressCardEnabledProps = {
  counts: ActionItemsCountsType
}

const ProgressCardEnabled: React.FC<ProgressCardEnabledProps> = ({ counts }) => {
  const classes = useStyles()
  const { done, total, percentage } = getCountsSummary(counts)
  const getRow = (firstLabel: string | number, secondLabel: string) => (
    <div className={classes.inlineContent}>
      <Typography variant="h6">{firstLabel}</Typography>
      <Typography color="textSecondary">{secondLabel}</Typography>
    </div>
  )
  return (
    <TooltipProgressBar counts={counts}>
      {getRow(counts.activeStatements, 'Active Statements')}
      {getRow(`${percentage}%`, 'Action Items Complete')}
      <div className={cn(classes.inlineContent, classes.marginBottom)}>
        <ProgressBar percentage={percentage} />
        <Typography color="textSecondary">
          {done}/{total}
        </Typography>
      </div>
    </TooltipProgressBar>
  )
}

type ProgressCardDisabledProps = { productType: SurveyProductTypeEnum }

const ProgressCardDisabled: React.FC<ProgressCardDisabledProps> = ({ productType }) => {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.cardTitle} variant="h6">
        {PRODUCT_TYPE_LABELS[productType]} Engagement
      </Typography>
      <div className={classes.cardEngagement}>
        <Typography color="textSecondary">
          {productType === SurveyProductTypeEnum.EMPLOYEE
            ? 'Improve your workplace culture and employee experience with employee surveys and action plans.'
            : 'Improve the culture and customer experience with customer surveys and action plans.'}
          <a href={`mailto:${CONTACT_EMAIL}`}>&nbsp;Learn More.</a>
        </Typography>
        {productType === SurveyProductTypeEnum.EMPLOYEE ? (
          <ImproveEmployeeIcon width={300} />
        ) : (
          <ImproveResidentIcon width={300} />
        )}
      </div>
    </>
  )
}

type ProgressCardProps = {
  productType: SurveyProductTypeEnum
  counts?: null | ActionItemsCountsType
  customStatementCounts?: null | ActionItemsCountsType
  hasCustomActionItems: Boolean
}

export const ProgressCard: React.FC<ProgressCardProps> = ({
  productType,
  counts,
  customStatementCounts,
  hasCustomActionItems,
}) => {
  const classes = useStyles()
  return (
    <Card
      className={cn(classes.card, {
        [classes.cardDisabled]: !counts,
      })}
    >
      {counts ? (
        <>
          <Typography variant="h6" className={classes.cardTitle}>
            Track Progress
          </Typography>
          {customStatementCounts && hasCustomActionItems ? (
            <ProgressCardEnabledWithCustomStatements
              productType={productType}
              counts={counts}
              customStatementCounts={customStatementCounts}
            />
          ) : (
            <ProgressCardEnabled counts={counts} />
          )}
        </>
      ) : (
        <ProgressCardDisabled productType={productType} />
      )}
    </Card>
  )
}

type CombinedProgressCardProps = {
  actionPlansCounts: ActionPlanCountsType
  hasCustomActionItems: Boolean
}

export const CombinedProgressCard: React.FC<CombinedProgressCardProps> = ({
  actionPlansCounts,
  hasCustomActionItems,
}) => {
  const classes = useStyles()
  if (!actionPlansCounts) return <></>
  const getRow = (counts: ActionItemsCountsType, productType: null | SurveyProductTypeEnum) => {
    const { done, total, percentage } = getCountsSummary(counts)
    return (
      <TooltipProgressBar counts={counts}>
        <Typography variant="h6" style={{ width: '5%' }}>
          {counts.activeStatements}
        </Typography>
        <Typography style={{ width: '35%' }} color="textSecondary">
          {productType ? `${PRODUCT_TYPE_LABELS[productType]} Statements` : otherLabel}
        </Typography>
        <Typography style={{ width: '5%' }} color="textSecondary">
          {done}/{total}
        </Typography>
        <div style={{ width: '30%' }}>
          <ProgressBar percentage={percentage} fat />
        </div>
        <Typography variant="body1" style={{ width: '10%' }}>
          {percentage}%
        </Typography>
        <Typography style={{ width: '10%' }} color="textSecondary">
          Complete
        </Typography>
      </TooltipProgressBar>
    )
  }
  return (
    <Card className={cn(classes.card, classes.cardProgressCombined)}>
      <Typography variant="h6" className={classes.cardTitle}>
        Track Progress
      </Typography>
      <div className={classes.cardProgressCombinedContent}>
        {actionPlansCounts.resident &&
          getRow(actionPlansCounts.resident, SurveyProductTypeEnum.RESIDENT)}
        {actionPlansCounts.employee &&
          getRow(actionPlansCounts.employee, SurveyProductTypeEnum.EMPLOYEE)}
        {hasCustomActionItems && actionPlansCounts.custom && getRow(actionPlansCounts.custom, null)}
      </div>
    </Card>
  )
}
