import React from 'react'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import KeyFocusAreasChart from 'components/Insights/Snapshot/KeyFocusAreasChart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  FilterTypeFragment,
  InsightsStatementScoreFragment,
  RankByEnum,
  useInsightsEmployeeFocusAreasQuery,
  InsightsEmployeeFocusAreasQuery,
  DataTypeCode,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { reverse } from 'utils'
import { MIN_SHOWABLE_RESULTS_CODE } from 'utils/constants'

export const getGroupData = (
  scoresByDataTypes: InsightsEmployeeFocusAreasQuery['statement1Groups'],
  filterTypes: FilterTypeFragment[],
) => {
  return scoresByDataTypes
    .map(({ scores, dataType }) => {
      return {
        groups: reverse(scores)
          .filter(score => score.positive !== MIN_SHOWABLE_RESULTS_CODE)
          .slice(0, 2),
        name: filterTypes.find(ft => ft.dtCode === dataType)?.namePlural || '',
      }
    })
    .filter(({ groups }) => Boolean(groups.length))
}

/**
 * Determines the filter type we use to calculate "potential improvement score."
 *
 * Prefer using location. Use the secondary filter type if the user has filtered down or location is unavailable.
 * Opt for an alternate calculation if user has filtered below secondary or secondary is unavailable.
 *  */

export const getImprovementDtCode = (
  filterTypes: FilterTypeFragment[],
  selectedFilterUuids: string[],
  secondaryDtCode: DataTypeCode,
) => {
  const hasLocationFilterType = filterTypes.some(
    // Ignore filter types without enough filter values to compare to others for an improvement.
    ft => ft.dtCode === DataTypeCode.AI_LOCATION && ft.filterValues.length > 2,
  )
  const hasSecondaryFilterType = filterTypes.some(
    ft => ft.dtCode === secondaryDtCode && ft.filterValues.length > 2,
  )
  if (!selectedFilterUuids?.length) {
    if (hasLocationFilterType) {
      return DataTypeCode.AI_LOCATION
    }
    if (hasSecondaryFilterType) {
      return secondaryDtCode
    }
    return null
  }
  const userSelectedFilterTypes = filterTypes.filter(ft =>
    ft.filterValues.some(fv => selectedFilterUuids.includes(fv.uuid)),
  )
  const lowestFilterType = userSelectedFilterTypes[userSelectedFilterTypes.length - 1]
  if (lowestFilterType.dtCode === DataTypeCode.AI_LOCATION && hasSecondaryFilterType) {
    return secondaryDtCode
  }
  return null
}

type Props = {
  visibleFilterTypes: FilterTypeFragment[]
  surveyUuid: string
  surveyProductType: SurveyProductTypeEnum
  filters: string[]
  inconsistentStatements: InsightsStatementScoreFragment[]
  allStatements: InsightsStatementScoreFragment[]
  overallIndexScore: number
  minShowableResults: number
  hasKeyFocusAreaCarousel: boolean
}
const EmployeeKeyFocusAreasCard: React.FC<Props> = ({
  visibleFilterTypes,
  surveyUuid,
  surveyProductType,
  filters,
  inconsistentStatements,
  allStatements,
  overallIndexScore,
  hasKeyFocusAreaCarousel,
  minShowableResults,
}) => {
  const classes = useInsightsStyles()
  // Take the bottom 3 filters
  const bottomIndex = visibleFilterTypes.length >= 3 ? visibleFilterTypes.length - 3 : 0
  const relevantFilters = visibleFilterTypes.slice(bottomIndex, visibleFilterTypes.length)
  // Get the 3 statements to focus on, the inconsistent statements with the largest potential
  const focusStatements = inconsistentStatements
    .map(s => ({ ...s, target: s.positive + s.inconsistent }))
    .sort((a, b) => a.target - b.target)
    .slice(0, 3)
  const improvementDtCode = getImprovementDtCode(
    visibleFilterTypes,
    filters,
    DataTypeCode.DEPARTMENT_NAME,
  )
  const [statement1, statement2, statement3] = focusStatements
  const result = useInsightsEmployeeFocusAreasQuery({
    variables: {
      dtCodes: relevantFilters.map(ft => ft.dtCode),
      surveyUuid,
      filters,
      rankBy: RankByEnum.POSITIVE,
      statement1Codes: statement1 ? [statement1.code] : [],
      statement2Codes: statement2 ? [statement2.code] : [],
      statement3Codes: statement3 ? [statement3.code] : [],
      improvementDtCode,
    },
  })
  return (
    <div className={classes.fullRow} id="key-focus-areas-snap">
      <SnapshotChartHeader
        title="How to improve scores"
        description={`Focus on the employees that represent the biggest opportunity to improve,
        make sure you understand what’s driving their scores, and then address the specific issues they are facing.
        Here’s where to focus:`}
        tooltip={
          <p>
            Here are two ways to look at improving your employee experience and raising scores for
            your three Recommended Focus Area statements (section above) with the lowest percentage
            of positive responses.
            <br />
            <br />
            The chart shows the growth potential if you converted inconsistent statement responses
            to positive responses on your next survey.
            <br />
            <br />
            The ‘Focus On’ section shows the groups with the lowest positive scores on each
            statement. Consider directing extra focus toward these groups in your action planning
            and initiatives to improve the respective statement scores.
          </p>
        }
      />
      <ResponseHandler {...result}>
        {({
          statement1Groups,
          statement2Groups,
          statement3Groups,
          insightsPotentialImprovementScore,
        }) => {
          const statementsScoresData = [statement1Groups, statement2Groups, statement3Groups]
            // Only use the number of statements we have available in bottom statements.
            .slice(0, focusStatements.length)
            .map((scoresByDataTypes, index) => {
              const statement = focusStatements[index]
              return {
                label: statement.label,
                positive: Math.round(statement.positive),
                target: Math.round(statement.positive + statement.inconsistent),
                groupData: getGroupData(scoresByDataTypes, relevantFilters),
              }
            })
          return (
            <KeyFocusAreasChart
              minShowableResults={minShowableResults}
              statementsScoresData={statementsScoresData}
              improvementScoreData={insightsPotentialImprovementScore}
              overallIndexScore={overallIndexScore}
              allStatements={allStatements}
              visibleFilterTypes={visibleFilterTypes}
              improvementFilterTypeName={
                visibleFilterTypes.find(ft => ft.dtCode === improvementDtCode)?.namePlural
              }
              hasKeyFocusAreaCarousel={hasKeyFocusAreaCarousel}
              surveyProductType={surveyProductType}
            />
          )
        }}
      </ResponseHandler>
    </div>
  )
}

export default EmployeeKeyFocusAreasCard
