import React from 'react'

import {
  Card,
  Checkbox,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'

import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import { ConfigAttrEnum } from 'components/Settings/General/OrgSettingsContainer'
import useSettingsStyles from 'components/Settings/General/OrgSettingsStyles'
import {
  BenchmarkConfigInput,
  BenchmarkLevelOfCareType,
  OrganizationFragment,
  OrganizationInput,
} from 'generated/graphql'

type Props = {
  organization: OrganizationFragment
  inputOrg: OrganizationInput
  updateBenchmarkConfig(configAttr: ConfigAttrEnum, updatedConfig: BenchmarkConfigInput): void
  onSubmit(): void
  loading: boolean
  isConfigVisible(loc: BenchmarkLevelOfCareType): boolean
}
const LevelsOfCareTab: React.FC<Props> = ({
  loading,
  inputOrg,
  organization,
  onSubmit,
  updateBenchmarkConfig,
  isConfigVisible,
}) => {
  const classes = useSettingsStyles()

  return (
    <>
      <Card>
        <div className={classes.helpText}>
          <Typography variant="h6" className={classes.title}>
            Levels of Care
          </Typography>
          <br />
          <Typography color="textSecondary">
            Select the levels - or types - of care that you provide below. For senior and family
            surveys, the selection below controls which version of our customer engagement survey is
            used. On our employee survey, you'll need to capture the level of care that your
            employees primarily work in. The levels of care you select will appear as the answer
            options available for the following question: “In which level of care do you work? If
            you work in more than one, please choose the care setting where you most often work.”
            <br />
            <br />
            <i>
              Please complete the Levels of Care section before moving on to the Services section.
            </i>
          </Typography>
        </div>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell />
              <TableCell>Level of Care</TableCell>
              <TableCell>Custom Label</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organization.benchmarkLevelsOfCare.filter(isConfigVisible).map(loc => {
              const locInput = inputOrg.benchmarkLevelsOfCare.find(({ code }) => code === loc.code)
              if (!locInput) return null
              let tooltipMessage = ''
              if (loc.required) {
                tooltipMessage = 'Required, cannot disable'
              } else if (loc.disabled) {
                tooltipMessage = 'Upgrade your current plan to unlock all available levels of care'
              }
              return (
                <TableRow key={locInput.code} hover>
                  <TableCell className={classes.tableCellDisplay}>
                    <Tooltip title={tooltipMessage} placement="top">
                      <div>
                        <Checkbox
                          checked={locInput.checked}
                          disabled={loc.required || loc.disabled}
                          onChange={() =>
                            updateBenchmarkConfig(ConfigAttrEnum.LOC, {
                              ...locInput,
                              checked: !locInput.checked,
                            })
                          }
                          value={loc.code}
                        />
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.tableCellLabel}>{loc.name}</TableCell>
                  <TableCell className={classes.tableCellCustom}>
                    <Tooltip title={loc.disabled ? tooltipMessage : ''} placement="top">
                      <div>
                        <Input
                          fullWidth
                          disabled={loc.disabled}
                          onChange={e =>
                            updateBenchmarkConfig(ConfigAttrEnum.LOC, {
                              ...locInput,
                              customName: e.target.value,
                            })
                          }
                          value={locInput.customName}
                        />
                      </div>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Card>
      <SubmitButton
        buttonType="button"
        onClick={onSubmit}
        className={classes.saveButton}
        isSubmitting={loading}
        right
        color="primary"
      >
        Save
      </SubmitButton>
    </>
  )
}

export default LevelsOfCareTab
