import React from 'react'

import isNil from 'lodash/isNil'

import { colors } from 'shared/theme'
import { pluralize } from 'utils'

const ScoreChangeArrow: React.FC<{
  delta: number | null
  fontSize?: string
  reverseColors?: boolean
  isPercentage?: boolean
  className?: string
}> = ({ delta, fontSize, reverseColors = false, isPercentage = true, className }) => {
  if (isNil(delta)) return <span style={{ color: colors.navy65 }}>N/A</span>
  let color = colors.navy
  if (delta > 0) {
    color = colors.success
  } else {
    color = colors.danger
  }
  // In some circumstances, an increase indicates something negative so we should swap red and green.
  if (reverseColors) {
    color = {
      [colors.success]: colors.danger,
      [colors.danger]: colors.success,
      [colors.navy]: colors.navy,
    }[color]
  }
  if (delta === 0) color = colors.navy65
  return (
    <span className={className} style={{ color, fontSize }}>
      &nbsp;
      {delta !== null ? `${delta >= 0 ? '↑' : '↓'}` : ''}
      &nbsp;
      {Math.round(Math.abs(delta))}
      {isPercentage && pluralize('pt', Math.abs(delta))}
    </span>
  )
}

export default ScoreChangeArrow
