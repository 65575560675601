import React from 'react'

import { Checkbox, DialogContent, Divider, FormControl, Typography } from '@material-ui/core'

import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import GroupRadioButtonSection from 'components/Settings/Team/Groups/GroupRadioButtonSection'
import { Group } from 'components/Settings/Team/Groups/GroupsTypes'

type Props = {
  group: Group
  updateGroup(arg: Partial<Group>): void
  orgHasEmployeeSolution: boolean
  orgHasResidentSolution: boolean
}

const OverallSettings: React.FC<Props> = ({
  group,
  updateGroup,
  orgHasEmployeeSolution,
  orgHasResidentSolution,
}) => {
  return (
    <>
      <Divider />
      <DialogContent>
        <Typography color="textSecondary">People in this group will have access to:</Typography>
        <FormControl component="fieldset">
          {orgHasEmployeeSolution && (
            <FormControlLabel
              control={
                <Checkbox
                  name="Employee Surveys"
                  checked={Boolean(group.accessToSurveyProduct)}
                  onChange={() =>
                    updateGroup({ accessToSurveyProduct: !group.accessToSurveyProduct })
                  }
                />
              }
              label="Employee Surveys"
            />
          )}
          {orgHasResidentSolution && (
            <FormControlLabel
              control={
                <Checkbox
                  name="Customer Surveys"
                  checked={Boolean(group.residentAccessToSurveyProduct)}
                  onChange={() =>
                    updateGroup({
                      residentAccessToSurveyProduct: !group.residentAccessToSurveyProduct,
                    })
                  }
                />
              }
              label="Customer Surveys"
            />
          )}
          {orgHasEmployeeSolution && orgHasResidentSolution && (
            <FormControlLabel
              control={
                <Checkbox
                  name="Analytics"
                  checked={Boolean(group.canUseAnalytics)}
                  onChange={() =>
                    updateGroup({
                      canUseAnalytics: !group.canUseAnalytics,
                    })
                  }
                />
              }
              label="Analytics"
            />
          )}
        </FormControl>
      </DialogContent>
      <GroupRadioButtonSection
        group={group}
        updateGroup={updateGroup}
        title="They will be able to invite others to the group:"
        field="canInviteUsers"
      />
      <GroupRadioButtonSection
        group={group}
        updateGroup={updateGroup}
        title="They will be able to access the data structure of the organization:"
        field="canAccessDataStructure"
      />
      <GroupRadioButtonSection
        group={group}
        updateGroup={updateGroup}
        title="They will be able to manage general organization settings:"
        field="canAccessGeneralSettings"
      />
      <Divider />
    </>
  )
}

export default OverallSettings
