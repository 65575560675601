import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

import { colors } from 'shared/theme'
import { pluralize } from 'utils'

const styles = theme => ({
  container: {
    display: 'flex',
    marginTop: 20,
  },
  arrow: {
    fontSize: 20,
    paddingRight: 12,
    textAlign: 'center',
  },
  title: {
    '& svg': {
      height: '1.6rem',
      marginBottom: -3,
      color: theme.palette.common.navy65,
    },
  },
  subtitle: {
    fontSize: '1.2rem',
  },
  score: {
    fontSize: '1.4rem',
  },
})

const StatementChangeLabel = ({ scoreDelta, arrowColors, classes, title, subtitle }) => {
  let arrowDirection = null
  if (scoreDelta > 0) {
    arrowDirection = 'up'
  } else if (scoreDelta < 0) {
    arrowDirection = 'down'
  }
  // Hides the arrow and maintains constant padding.
  let arrowColor = 'white'
  if (arrowDirection) {
    arrowColor = arrowColors[arrowDirection]
  }
  const text = arrowDirection === 'up' ? '↑' : '↓'
  return (
    <div className={classes.container}>
      <span style={{ color: arrowColor }} className={classes.arrow}>
        {text}
        <br />
        <span className={classes.score}>
          {Math.abs(scoreDelta)}
          {pluralize('pt', Math.abs(scoreDelta))}
        </span>
      </span>
      <div>
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
        <Typography color="textSecondary" className={classes.subtitle}>
          {subtitle}
        </Typography>
      </div>
    </div>
  )
}

StatementChangeLabel.propTypes = {
  scoreDelta: PropTypes.number.isRequired,
  arrowColors: PropTypes.shape({
    up: PropTypes.string.isRequired,
    down: PropTypes.string.isRequired,
  }),
  classes: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.any.isRequired,
}

StatementChangeLabel.defaultProps = {
  arrowColors: { up: colors.success, down: colors.danger },
}

export default withStyles(styles)(StatementChangeLabel)
