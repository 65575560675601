import React from 'react'

import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import { useStyles } from 'components/Survey/Wizard/Steps/Notifications/common'
import IntervalNotifications from 'components/Survey/Wizard/Steps/Notifications/IntervalNotifications'
import ScheduledNotifications from 'components/Survey/Wizard/Steps/Notifications/ScheduledNotifications'
import SurveyControls from 'components/Survey/Wizard/SurveyControls'
import { SurveysSurveyQuery } from 'generated/graphql'

interface Props {
  survey: SurveysSurveyQuery['survey']
  goBack?(): void
  goNext?(uuid?: string): void
}

const Notifications: React.FC<Props> = ({ survey, goBack, goNext }) => {
  const classes = useStyles()

  return (
    <>
      {survey.editable && <SurveyControls survey={survey} />}
      {survey.hasIntervalNotifications ? (
        // We don't need to enforce maxNotifications in Interval Notifications because
        // the user is limited to 5 interval options.
        <IntervalNotifications classes={classes} survey={survey} />
      ) : (
        <ScheduledNotifications
          classes={classes}
          survey={survey}
          maxNotifications={survey.numberOfMaxScheduledNotifications}
        />
      )}
      {/* Components contain the "Back" and "Next" buttons of the wizard, which now only need to be displayed if the survey is editable ( i.e. DRAFT) */}
      {survey.editable && <NavigationButtons goBack={goBack} goNext={goNext} />}
    </>
  )
}

export default Notifications
