import React, { useState } from 'react'

import {
  AppBar,
  Checkbox,
  Dialog,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import cn from 'classnames'
import orderBy from 'lodash/orderBy'

import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import Dropdown from 'components/Blocks/Dropdowns/Dropdown'
import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import {
  OrganizationLanguageFragment,
  SurveysTranslationsQuery,
  SurveysSurveySummaryDocument,
  SurveysSurveyDocument,
  useSurveysUpdateTranslationsMutation,
  useSurveysTranslationsQuery,
} from 'generated/graphql'
import { colors } from 'shared/theme'

const useStyles = makeStyles(({ spacing }) => ({
  appBar: {
    position: 'relative',
    marginBottom: spacing(3),
  },
  content: {
    margin: spacing(2),
  },
  subtitle: {
    width: '60%',
    marginBottom: spacing(3),
  },
  download: {
    display: 'flex',
    alignItems: 'center',
    '& >svg': {
      marginRight: spacing(),
    },
  },
  downloadButton: {
    marginTop: spacing(2),
    marginBottom: spacing(3),
    marginLeft: -spacing(),
    minWidth: 180,
  },
  navButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacing(4),
    '& >div:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  hr: {
    width: '100%',
    borderBottom: `solid 1px ${colors.iceGrey}`,
    marginBottom: spacing(2),
    marginTop: spacing(2),
  },
  tableContainer: {
    display: 'block',
    margin: '2em auto',
    width: '100%',
  },
  flexTable: {
    display: 'flex',
    flexFlow: 'row wrap',
    '&:hover': {
      background: '#F5F5F5',
    },
    '& >div': {
      padding: '0.5em 0.5em',
      borderBottom: `solid 1px ${colors.iceGrey}`,
    },
    '& >div:first-child': { width: '40%', textAlign: 'left' },
  },
  flexCell: {
    width: '48%',
    textAlign: 'left',
  },
  englishText: {
    whiteSpace: 'pre-line',
  },
  header: {
    fontSize: 18,
  },
  row: {
    '&:nth-child(even)': {
      background: colors.navy05,
    },
  },
  rowspan: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  languageDropdown: {
    width: '100%',
    height: 30,
  },
}))

type TableProps = {
  translations: SurveysTranslationsQuery['translations']
  language: OrganizationLanguageFragment
  onlyBlanks: boolean
  onSave(translationsInput: SurveysTranslationsQuery['translations']): void
  loading: boolean
  onClose(): void
}

const TranslationsTable: React.FC<TableProps> = ({
  translations,
  language,
  onlyBlanks,
  onSave,
  loading,
  onClose,
}) => {
  const classes = useStyles()

  const [translationsInput, setTranslationsInput] = useState<
    SurveysTranslationsQuery['translations']
  >(translations)

  return (
    <>
      <div className={classes.tableContainer} role="table">
        <div className={cn(classes.flexTable, classes.header)} role="rowgroup">
          <div className={classes.flexCell} role="columnheader">
            English Text:
          </div>
          <div className={classes.flexCell} role="columnheader">
            {language.name} Translations
          </div>
        </div>
        {translationsInput
          .filter(
            t => t.language.code === language.code && (!onlyBlanks || (onlyBlanks && !t.text)),
          )
          .map(translation => {
            return (
              <div
                key={translation.objectUuid}
                className={cn(classes.flexTable, classes.row)}
                role="rowgroup"
              >
                <div className={cn(classes.flexCell, classes.englishText)} role="cell">
                  {translation.englishText}
                </div>
                <div className={classes.flexCell} role="cell">
                  <TextField
                    fullWidth
                    multiline
                    name="translation"
                    value={translation.text}
                    placeholder={`Add ${language.name} translation`}
                    onChange={e =>
                      setTranslationsInput(
                        translationsInput.map(t =>
                          t.uuid === translation.uuid ? { ...t, text: e.target.value } : t,
                        ),
                      )
                    }
                  />
                </div>
              </div>
            )
          })}
      </div>
      <div className={classes.navButtons}>
        <Button onClick={onClose} color="textSecondaryNoBackground">
          Cancel
        </Button>
        <SubmitButton
          color="primary"
          onClick={() => onSave(translationsInput)}
          isSubmitting={loading}
        >
          Save
        </SubmitButton>
      </div>
    </>
  )
}

interface Props {
  surveyUuid: string
  surveyLanguages: OrganizationLanguageFragment[]
  responseRateByClient: boolean
  onClose(): void
}

const TranslationsContainer: React.FC<Props> = ({
  surveyUuid,
  surveyLanguages,
  responseRateByClient,
  onClose,
}) => {
  const classes = useStyles()

  const [onlyBlanks, setOnlyBlanks] = useState(false)
  const [language, setLanguage] = useState(surveyLanguages[0])

  const result = useSurveysTranslationsQuery({
    variables: { surveyUuid },
    fetchPolicy: 'network-only',
  })

  const [
    updateTranslations,
    { loading: loadingUpdateTranslations },
  ] = useSurveysUpdateTranslationsMutation()

  const onSave = async (translationsInput: SurveysTranslationsQuery['translations']) => {
    const updateResult = await updateTranslations({
      variables: {
        surveyUuid,
        translations: translationsInput.map(t => ({
          uuid: t.uuid,
          objectUuid: t.objectUuid,
          objectType: t.objectType,
          languageCode: t.language.code,
          text: t.text,
        })),
      },
      refetchQueries: [
        {
          query: SurveysSurveySummaryDocument,
          variables: { surveyUuid, responseRateByClient },
        },
        {
          // We're refetching the survey only for the purpose of hasMissingTranslations property
          query: SurveysSurveyDocument,
          variables: { uuid: surveyUuid },
        },
      ],
    })
    if (updateResult.data?.updateTranslations) {
      console.error(updateResult.data.updateTranslations.errors)
    }
    onClose()
  }

  return (
    <Dialog fullScreen open onClose={onClose}>
      <AppBar color="secondary" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" color="inherit">
            Add Translations
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Typography variant="h6">Prepare Translations</Typography>
        <Typography variant="body1" color="textSecondary" className={classes.subtitle}>
          To prepare your translations, you can download the translation file with all the custom
          text needed to be translated. Once you’ve translated all the rows in the file, simply copy
          and paste your translations to the section below. (*Optional: You can also skip this step,
          and go directly to ‘Add Translations’ instead.)
        </Typography>
        <Typography variant="body1" className={classes.download}>
          <CloudDownloadIcon /> Translations File:
        </Typography>
        <Button
          className={classes.downloadButton}
          color="primary"
          onClick={() =>
            window.open(`/backend/translations/download/${surveyUuid}/${language.code}}`)
          }
        >
          Download Translations File
        </Button>
        <div className={classes.hr} />
        <Typography variant="h6">Add Translations</Typography>
        <Typography variant="body1" color="textSecondary" className={classes.subtitle}>
          Copy translations from your completed Excel doc, and paste into the forms below.
        </Typography>
        <div className={classes.actions}>
          <div>
            <Typography variant="body1" color="textSecondary">
              Select translation:&nbsp;
            </Typography>
            <Dropdown
              value={language.code}
              renderValue={languageCode => {
                const langName = surveyLanguages.find(({ code }) => languageCode === code)?.name
                return `English to ${langName}`
              }}
              onChange={e => {
                const selectedLanguage = surveyLanguages.find(
                  l => l.code === (e.target as HTMLInputElement).value,
                )
                if (selectedLanguage) {
                  setLanguage(selectedLanguage)
                }
              }}
            >
              {orderBy(surveyLanguages, 'name', 'asc').map(lang => (
                <MenuItem key={lang.code} value={lang.code}>
                  English to {lang.name}
                </MenuItem>
              ))}
            </Dropdown>
          </div>
          <FormControlLabel
            control={<Checkbox checked={onlyBlanks} onChange={() => setOnlyBlanks(!onlyBlanks)} />}
            id="displayOnlyBlanks"
            label="Show Missing Translations Only"
          />
        </div>
        <ResponseHandler {...result}>
          {({ translations }) => {
            return (
              <TranslationsTable
                translations={translations}
                onSave={onSave}
                language={language}
                onlyBlanks={onlyBlanks}
                loading={loadingUpdateTranslations}
                onClose={onClose}
              />
            )
          }}
        </ResponseHandler>
      </div>
    </Dialog>
  )
}

export default TranslationsContainer
