import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing, palette }) => ({
  textValidator: {
    marginBottom: spacing(2),
  },
  card: {
    padding: spacing(2),
  },
  title: {
    paddingBottom: spacing(),
  },
  saveButton: {
    marginTop: spacing(2),
    marginRight: 0,
  },
  dropdownColumn: {
    paddingRight: spacing(2),
    '& >div': {
      justifyContent: 'flex-start',
    },
  },
  tableHeader: {
    '& >th': {
      paddingLeft: spacing(),
      color: palette.common.navy65,
    },
  },
  tableCellDisplay: {
    width: '10%',
    paddingLeft: spacing(3),
  },
  tableCellLabel: {
    width: '40%',
  },
  tableCellCustom: {
    minWidth: '50%',
  },
  helpText: {
    margin: spacing(3),
  },
  tableCellBorders: {
    '& >td, >th': {
      '&:not(:first-child)': {
        borderRight: `solid 1px ${palette.common.iceGrey}`,
      },
    },
  },
}))

export default useStyles
