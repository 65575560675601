import React, { useState } from 'react'

import { Typography, makeStyles } from '@material-ui/core'

import FormPanel from 'components/Blocks/Accordions/FormPanel'
import Button from 'components/Blocks/CustomButtons/Button'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import UpdateWelcomeMessageDialog from 'components/Survey/Blocks/UpdateWelcomeMessageDialog'
import { SurveysSurveyQuery, SurveyInviteMethodEnum } from 'generated/graphql'
import { SURVEY_TEXT_KEYS } from 'utils/constants'

const useStyles = makeStyles(() => ({
  welcomeMessage: {
    whiteSpace: 'pre-line',
  },
}))

type Props = {
  survey: SurveysSurveyQuery['survey']
}

const WelcomeMessagePanel: React.FC<Props> = ({ survey }) => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)

  const welcomeText = (survey.welcomeMessages.length
    ? survey.welcomeMessages
    : survey.defaultWelcomeMessages
  ).find(wm => wm.key === SURVEY_TEXT_KEYS.WELCOME_TEXT)

  if (
    ![SurveyInviteMethodEnum.SMS_EMAIL, SurveyInviteMethodEnum.SMS_EMAIL_PHONE].includes(
      survey.inviteMethod,
    )
  ) {
    return <></>
  }
  return (
    <FormPanel>
      <GridContainer>
        <ItemGrid sm={4}>
          <Typography variant="h6">Welcome Message</Typography>
          {survey.inviteMethod === SurveyInviteMethodEnum.SMS_EMAIL_PHONE && (
            <Typography color="textSecondary">*This applies to digital surveys only</Typography>
          )}
        </ItemGrid>
        <ItemGrid sm={8}>
          <Typography color="textSecondary" className={classes.welcomeMessage}>
            "{welcomeText?.text}"
            <Button color="secondaryNoBackground" onClick={() => setIsOpen(true)}>
              <Typography> Edit Welcome Message</Typography>
            </Button>
          </Typography>
          {isOpen && (
            <UpdateWelcomeMessageDialog
              surveyUuid={survey.uuid}
              surveyType={survey.type}
              productType={survey.productType}
              welcomeMessagesToUpdate={survey.welcomeMessages}
              defaultWelcomeMessages={survey.defaultWelcomeMessages}
              onClose={() => setIsOpen(false)}
            />
          )}
        </ItemGrid>
      </GridContainer>
    </FormPanel>
  )
}

export default WelcomeMessagePanel
