import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import cn from 'classnames'

import { ReactComponent as ConfirmationCheckIcon } from 'assets/img/confirmation-check-icon.svg'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    position: 'relative',
    cursor: 'pointer',
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 120,
    padding: spacing(2),
    border: `3px solid ${palette.common.iceGrey}`,
    borderRadius: 3,
    '&:hover': {
      borderColor: palette.common.secondary,
    },
    '& div>:first-child': {
      marginRight: spacing(2),
    },
  },
  selected: {
    borderColor: palette.common.secondary,
    backgroundColor: palette.common.lightGrey,
  },
  selectedDisabled: {
    borderColor: palette.common.borderGrey,
    backgroundColor: palette.common.lightGrey,
  },
  confirmationCheck: {
    position: 'absolute',
    marginTop: -spacing(),
    right: -spacing(),
  },
  disabledConfirmationCheck: {
    '& >circle': {
      fill: palette.common.borderGrey,
      stroke: palette.common.borderGrey,
    },
  },
}))

type SelectableCardProps = {
  id: string
  selected: boolean
  Icon: Function
  title: string
  body: string
  onSelect(): void
  disabled?: boolean
}

const SelectableCard: React.FC<SelectableCardProps> = ({
  id,
  selected,
  Icon,
  title,
  body,
  onSelect,
  disabled = false,
}) => {
  const classes = useStyles()

  return (
    <div id={id} className={classes.root}>
      {selected && (
        <ConfirmationCheckIcon
          className={cn({
            [classes.confirmationCheck]: true,
            [classes.disabledConfirmationCheck]: disabled,
          })}
        />
      )}
      <div
        aria-hidden
        className={cn(classes.card, {
          [classes.selected]: selected && !disabled,
          [classes.selectedDisabled]: selected && disabled,
        })}
        onClick={() => onSelect()}
        onKeyDown={() => onSelect()}
      >
        <div>
          <Icon />
        </div>
        <div>
          <Typography variant="body1" color={disabled ? 'textSecondary' : 'textPrimary'}>
            {title}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {body}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default SelectableCard
