import React, { useState, ReactElement } from 'react'

import { makeStyles, Typography, MenuItem } from '@material-ui/core'
import ReactEcharts from 'echarts-for-react'
import isNil from 'lodash/isNil'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import DashedBlueLine from 'assets/img/echarts/dashed-blue-line.png'
import GreenCircleScoreIcon from 'assets/img/green-circle-score-icon.svg'
import OrangeCircleScoreIcon from 'assets/img/orange-circle-score-icon.svg'
import RedCircleScoreIcon from 'assets/img/red-circle-score-icon.svg'
import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import useInsightsStyles, { chartTextStyle } from 'components/Insights/InsightsStyle'
import { InsightsBenchmark, InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import StandardTooltip from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import TimeTrendingContainer from 'components/Insights/TimeTrending/TimeTrendingContainer'
import {
  getBenchmarkScore,
  getOverallScoreGroups,
  getTimeTrendingScoreDelta,
  ScoreTypeGroups,
} from 'components/Insights/TimeTrending/utils'
import { TimeTrendingSurveysKey, TimeTrendingChartKey } from 'config/LocalStorage'
import {
  SurveyProductTypeEnum,
  useInsightsTimeTrendingSnapshotOverviewQuery,
  InsightsTimeTrendingSnapshotOverviewQuery,
} from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import { colors } from 'shared/theme'
import { formatTooltipScore } from 'utils'
import {
  INSIGHTS_COLORS,
  MIN_SHOWABLE_RESULTS,
  PRODUCT_TYPE_TO_PULSE_TYPE,
  ScoreTitleEnum,
  SURVEY_TYPE_TO_KEY_STATEMENT,
} from 'utils/constants'
import { usePulseInTimeTrending } from 'utils/customHooks'
import { getFormattedSurveyDate } from 'utils/dateUtils'
import {
  timetrendXAxis,
  surveysYAxis,
  gridLines,
  surveysTooltip,
  benchmarkLine,
  lineSeriesVars,
  surveysTopLegend,
  TimeTrendXAxisLabelData,
} from 'utils/echartsHelpers'
import { SurveyNode } from 'utils/types'

const useStyles = makeStyles(() => ({
  chartRow: {
    display: 'flex',
  },
}))

type SurveysData = Array<{ uuid: string } & InsightsTimeTrendingSnapshotOverviewQuery>
export enum ChartType {
  INDEX_SCORE = 'indexScore',
  RESPONSE_RATE = 'responseRate',
}

const tooltipFormatter = (
  surveysData: SurveysData,
  xAxisIndex: number,
  surveys: SurveyNode[],
  benchmarkName: string,
  benchmarkScore: { positive: number; inconsistent: number; negative: number },
  chartType: ChartType,
  isBenchmark: boolean,
  minShowableResults: number,
) => {
  if (chartType === ChartType.INDEX_SCORE && isBenchmark) {
    return renderEchartTooltip(
      <StandardTooltip
        title="Benchmark Score"
        description={`Selected Benchmark: ${benchmarkName}`}
        rows={[
          {
            label: 'Positive:',
            value: formatTooltipScore(benchmarkScore.positive, minShowableResults),
            color: colors.success,
          },
          {
            label: 'Neutral:',
            value: formatTooltipScore(benchmarkScore.inconsistent, minShowableResults),
            color: colors.warning,
          },
          {
            label: 'Negative:',
            value: formatTooltipScore(benchmarkScore.negative, minShowableResults),
            color: colors.danger,
          },
        ]}
      />,
    )
  }
  const survey = surveys[xAxisIndex]
  const surveyData = surveysData.find(s => s.uuid === survey.uuid)
  const finished = surveyData?.survey.responseRate.finished
  const total = surveyData?.survey.responseRate.total
  const rate = Math.round(surveyData?.survey.responseRate.rate || 0)

  // Render response rate tooltip
  if (chartType === ChartType.RESPONSE_RATE) {
    return renderEchartTooltip(
      <StandardTooltip
        title={getFormattedSurveyDate({ ...survey, includeDay: true })}
        description={`Survey: ${survey.name}`}
        rows={[
          { label: 'Response Rate:', value: formatTooltipScore(rate, survey.minShowableResults) },
          {
            label: 'Participation:',
            value: `${finished}/${total}`,
          },
        ]}
      />,
    )
  }
  // Render surveys score tooltip
  const getScoreChangeDetail = (accessor: 'positive' | 'inconsistent' | 'negative') => {
    if (xAxisIndex < 1) return null
    return (
      <ScoreChangeArrow
        delta={Math.round(
          getTimeTrendingScoreDelta(
            survey.uuid,
            surveysData.reduce(
              (acc, { uuid, overallIndex }) => ({
                ...acc,
                [uuid]: overallIndex[accessor],
              }),
              {},
            ),
            surveys,
          )!,
        )}
      />
    )
  }
  return renderEchartTooltip(
    <StandardTooltip
      title={getFormattedSurveyDate({ ...survey, includeDay: true })}
      description={`Survey: ${survey.name}`}
      rows={[
        {
          label: 'Positive:',
          value: formatTooltipScore(surveyData?.overallIndex.positive, survey.minShowableResults),
          color: colors.success,
          detail: getScoreChangeDetail('positive'),
        },
        {
          label: 'Neutral:',
          value: formatTooltipScore(
            surveyData?.overallIndex.inconsistent,
            survey.minShowableResults,
          ),
          color: colors.warning,
          detail: getScoreChangeDetail('inconsistent'),
        },
        {
          label: 'Negative:',
          value: formatTooltipScore(surveyData?.overallIndex.negative, survey.minShowableResults),
          color: colors.danger,
          detail: getScoreChangeDetail('negative'),
        },
        {
          label: `${benchmarkName}:`,
          value: formatTooltipScore(benchmarkScore.positive, MIN_SHOWABLE_RESULTS),
        },
      ]}
    />,
  )
}

type ChartProps = {
  chartType: ChartType
  xAxisLabelData: TimeTrendXAxisLabelData
  scoreTypeGroups: ScoreTypeGroups
  benchmarkName?: string
  benchmarkScore?: { positive: number; inconsistent: number; negative: number }
  tooltipFormatter?(series: { dataIndex: number; seriesName: string }): ReactElement | string
  legendNames?: string[]
}
export const Chart: React.FC<ChartProps> = ({
  chartType,
  xAxisLabelData,
  scoreTypeGroups,
  benchmarkName,
  benchmarkScore,
  tooltipFormatter: tooltipFormatterFn,
  legendNames,
}) => {
  let legendData: Array<{ name?: string; icon: string }> = [
    { name: 'Positive', icon: `image://${GreenCircleScoreIcon}` },
    { name: 'Neutral', icon: `image://${OrangeCircleScoreIcon}` },
    { name: 'Negative', icon: `image://${RedCircleScoreIcon}` },
  ]
  legendData = legendData.slice(0, scoreTypeGroups.length)
  if (benchmarkScore) {
    legendData.push({ name: benchmarkName, icon: `image://${DashedBlueLine}` })
  }
  legendData = legendData.map((legend, idx) => ({
    ...legend,
    name: legendNames ? legendNames[idx] : legend.name,
  }))
  return (
    <ReactEcharts
      notMerge
      opts={{ renderer: 'svg' }} // Useful for selecting DOM elements in UItests
      style={{
        width: '100%',
        height: 260,
      }}
      option={{
        grid: { bottom: 35, top: 40, left: 40, right: 40 },
        textStyle: chartTextStyle,
        xAxis: {
          ...timetrendXAxis({
            axisLabelData: xAxisLabelData,
            labelDisplayStrategy: 'every',
            maxLabelLineLength: 15,
            useDateLabel: true,
            margin: 16,
          }),
        },
        yAxis: { ...surveysYAxis({ max: null }), interval: 25 },
        series: [
          ...scoreTypeGroups.map((scores, idx) => ({
            data: scores,
            ...lineSeriesVars,
            name: legendData[idx].name,
          })),
          chartType === ChartType.INDEX_SCORE &&
            benchmarkScore &&
            benchmarkLine({
              value: benchmarkScore.positive,
              color: colors.navy,
              type: 'dashed',
              name: benchmarkName,
              showLabel: true,
            }),
          gridLines({ numCategories: xAxisLabelData.length }),
        ],
        color: INSIGHTS_COLORS,
        tooltip: {
          // Use 'item' trigger so that we can render a tooltip for the benchmark line/label.
          ...surveysTooltip({ trigger: 'item', fixedPosition: true }),
          axisPointer: {
            type: 'cross',
          },
          formatter: (series: { dataIndex: number; seriesName: string }) => {
            // Ignore when the user hovers over the x-axis
            if (series.seriesName !== benchmarkName && isNil(series.dataIndex)) {
              return null
            }
            if (!tooltipFormatterFn) return null
            return tooltipFormatterFn(series)
          },
        },
        legend: [
          {
            ...surveysTopLegend,
            data: legendData,
          },
        ],
      }}
    />
  )
}

type IProps = { scoreDelta: number; scoreName: string }
const InfoBox: React.FC<IProps> = ({ scoreDelta, scoreName }) => {
  const classes = useInsightsStyles()
  let deltaText = 'stayed the same'
  let arrowIcon = <span style={{ color: colors.success }}>↑</span>
  let headerMessage = ''
  if (scoreDelta > 0) {
    deltaText = `increased ${scoreDelta}%`
    headerMessage = 'good'
    if (scoreDelta > 5) {
      headerMessage = 'very good'
    }
  } else if (scoreDelta < 0) {
    arrowIcon = <span style={{ color: colors.danger }}>↓</span>
    deltaText = `decreased ${Math.abs(scoreDelta)}%`
  }
  return (
    <div className={classes.timeTrendingInfoBox}>
      {headerMessage && <Typography variant="h6">{headerMessage}</Typography>}
      <div className={classes.percentageText}>
        {arrowIcon}
        <Typography>{`${Math.abs(scoreDelta)}pts`}</Typography>
      </div>
      <Typography variant="body2" color="textSecondary">
        Over time your {scoreName} has {deltaText}
      </Typography>
    </div>
  )
}

type Props = {
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  filters: string[]
  benchmark: InsightsBenchmark
}
const TTIndexScoreResponseCard: React.FC<Props> = ({
  survey,
  availableSurveys,
  filters,
  benchmark,
}) => {
  const classes = useStyles()
  const { chartKey, surveysKey } = {
    [SurveyProductTypeEnum.EMPLOYEE]: {
      chartKey: TimeTrendingChartKey.EMPLOYEE_INDEX_SCORE,
      surveysKey: TimeTrendingSurveysKey.EMPLOYEE_INDEX_SCORE,
    },
    [SurveyProductTypeEnum.RESIDENT]: {
      chartKey: TimeTrendingChartKey.RESIDENT_INDEX_SCORE,
      surveysKey: TimeTrendingSurveysKey.RESIDENT_INDEX_SCORE,
    },
  }[survey.productType]
  const includesPulse = usePulseInTimeTrending(availableSurveys, surveysKey)
  const scoreName = includesPulse ? 'Key Statement Score' : ScoreTitleEnum[survey.productType]
  const [chartType, setChartType] = useState<ChartType>(ChartType.INDEX_SCORE)
  const chartTypeToName = {
    [ChartType.INDEX_SCORE]: scoreName,
    [ChartType.RESPONSE_RATE]: 'Response Rate',
  }
  let tooltip = `See how your ${chartTypeToName[chartType]} has changed over time. Any surveys with less than ${MIN_SHOWABLE_RESULTS} responses will be hidden. Use the legend to change which scores you see on the chart.`
  if (includesPulse) {
    tooltip +=
      'The Key Statement is "In recommending this facility to your friends and family, how would you rate it overall?" and is included on all Customer Engagement and Pulse surveys.'
  }
  return (
    <>
      <SnapshotChartHeader
        title={`${chartTypeToName[chartType]} over time`}
        tooltip={tooltip}
        hasTimeTrending
        timeTrendingChartKey={chartKey}
        snapId="tt-index-score-snap"
        useBottomPadding={false}
        extraControls={
          <TextDropdown
            displayEmpty
            value={chartType}
            onChange={e => setChartType(e.target.value as ChartType)}
            renderValue={val => chartTypeToName[val as ChartType]}
            label={
              <Typography variant="body2" color="textSecondary">
                Show:&nbsp;
              </Typography>
            }
          >
            {[
              <MenuItem key="indexScore" value={ChartType.INDEX_SCORE}>
                <Typography>{scoreName}</Typography>
              </MenuItem>,
              <MenuItem key="responseRate" value={ChartType.RESPONSE_RATE}>
                <Typography>Response Rate</Typography>
              </MenuItem>,
            ]}
          </TextDropdown>
        }
      />
      <TimeTrendingContainer
        chartSurveysKey={surveysKey}
        availableSurveys={availableSurveys}
        survey={survey}
      >
        {({ selectedSurveys }) => {
          const pulseKeyStatementCode =
            SURVEY_TYPE_TO_KEY_STATEMENT[PRODUCT_TYPE_TO_PULSE_TYPE[survey.productType]]
          return (
            <DynamicSurveyQuery
              surveys={selectedSurveys}
              variables={{
                filters,
                benchmark,
                statementCodes: includesPulse ? [pulseKeyStatementCode] : null,
              }}
              queryHook={useInsightsTimeTrendingSnapshotOverviewQuery}
            >
              {data => {
                let scoreTypeGroups = getOverallScoreGroups(data, false)
                if (chartType === ChartType.RESPONSE_RATE) {
                  // To keep data structures consistent between index score and response rate (which only has one score)
                  // wrap response rate data in an array
                  scoreTypeGroups = [
                    data.map(({ survey: ttSurvey }, surveyIndex) => [
                      surveyIndex,
                      Math.round(ttSurvey.responseRate.rate),
                    ]),
                  ]
                }
                if (!scoreTypeGroups.length) return <div />
                // For some calculations, ignore surveys with <MIN_SHOWABLE_RESULTS responses.
                const validScoreGroups = scoreTypeGroups[0].filter(
                  // scoreGroup[1] is the positive score for every survey
                  scoreGroup => scoreGroup[1] !== null,
                )
                if (validScoreGroups.length < 2) {
                  return (
                    <EmptyState
                      title="Oh Snap!"
                      description={`One of the surveys you selected has fewer than ${MIN_SHOWABLE_RESULTS} responses. Please select another survey or change your filters.`}
                      Icon={ChartUpwardIcon}
                    />
                  )
                }
                const delta =
                  Number(validScoreGroups[validScoreGroups.length - 1][1]) -
                  Number(validScoreGroups[0][1])

                const benchmarkScore = getBenchmarkScore(data[0].overallIndex)
                return (
                  <div className={classes.chartRow}>
                    <InfoBox
                      scoreDelta={Number(delta.toPrecision(2))}
                      scoreName={chartTypeToName[chartType]}
                    />
                    <Chart
                      scoreTypeGroups={scoreTypeGroups}
                      xAxisLabelData={selectedSurveys}
                      benchmarkScore={benchmarkScore}
                      benchmarkName={benchmark.name}
                      chartType={chartType}
                      tooltipFormatter={series => {
                        return tooltipFormatter(
                          data,
                          series.dataIndex,
                          selectedSurveys,
                          benchmark.name,
                          benchmarkScore,
                          chartType,
                          series.seriesName === benchmark.name,
                          selectedSurveys[0].minShowableResults,
                        )
                      }}
                    />
                  </div>
                )
              }}
            </DynamicSurveyQuery>
          )
        }}
      </TimeTrendingContainer>
    </>
  )
}

export default TTIndexScoreResponseCard
