import orderBy from 'lodash/orderBy'

import { InsightsModulesEnum, SurveyProductTypeEnum, CurrentUserQuery } from 'generated/graphql'
import { colors } from 'shared/theme'
import { getSolutionFromProductType } from 'utils'
import {
  SNAPSHOTS,
  COMMENTS,
  STATEMENTS,
  COMPARISONS,
  HEATMAP,
  TAKE_ACTION,
  TESTIMONIALS,
  SORT_OPTIONS,
  PRODUCT_TYPE_CONSTANTS,
  PRODUCT_TYPE_USER_FIELDS,
  ORDER_TYPES,
  INDIVIDUAL_RESULTS,
} from 'utils/constants'

export const scoreToColor = (score: number) => {
  if (score >= 75) {
    return colors.success
  }
  if (score >= 50) {
    return colors.warning
  }
  return colors.danger
}

export const getSortedScores = <ScoreType, _>(scores: ScoreType[], currentSort: SORT_OPTIONS) => {
  return {
    [SORT_OPTIONS.A_TO_Z]: orderBy(scores, 'label'),
    [SORT_OPTIONS.LOW_TO_HIGH]: orderBy(scores, 'positive'),
    [SORT_OPTIONS.HIGH_TO_LOW]: orderBy(scores, 'positive', ORDER_TYPES.DESCENDING),
  }[currentSort]
}

export const getInsightsFieldsForProductType = (
  currentUser: CurrentUserQuery['currentUser'],
  productType: SurveyProductTypeEnum,
) => {
  const { insightsModulesField } = PRODUCT_TYPE_USER_FIELDS[productType]
  return Object.assign(
    {
      solution: getSolutionFromProductType(currentUser.organization, productType),
      insightsModules: currentUser[insightsModulesField],
    },
    PRODUCT_TYPE_CONSTANTS[productType],
  )
}

const INSIGHTS_MODULES_TO_TABS = {
  [InsightsModulesEnum.SNAPSHOTS]: SNAPSHOTS,
  [InsightsModulesEnum.COMMENTS]: COMMENTS,
  [InsightsModulesEnum.STATEMENTS]: STATEMENTS,
  [InsightsModulesEnum.COMPARISONS]: COMPARISONS,
  [InsightsModulesEnum.HEATMAP]: HEATMAP,
  [InsightsModulesEnum.ACTION_PLANS]: TAKE_ACTION,
  [InsightsModulesEnum.TESTIMONIALS]: TESTIMONIALS,
  [InsightsModulesEnum.INDIVIDUAL_RESULTS]: INDIVIDUAL_RESULTS,
}

export const getInsightsPage = (
  uuid: string,
  module: InsightsModulesEnum,
  surveyProductType: SurveyProductTypeEnum,
) => {
  const { insightsUrls } = PRODUCT_TYPE_CONSTANTS[surveyProductType]
  const page = INSIGHTS_MODULES_TO_TABS[module]
  return `${insightsUrls.DASHBOARD}/${uuid}/${page}`
}

export const defaultInsightsPage = (
  surveyUuid: string,
  insightsModules: InsightsModulesEnum[],
  surveyProductType: SurveyProductTypeEnum,
) => {
  let defaultModule = InsightsModulesEnum.SNAPSHOTS
  if (insightsModules.length > 0) {
    // Use the first available insights module in case the user does not have access to snapshots.
    defaultModule = insightsModules[0]
  }
  return getInsightsPage(surveyUuid, defaultModule, surveyProductType)
}
