import React, { useState, SyntheticEvent } from 'react'

import {
  makeStyles,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Paper,
  Tooltip,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import RefreshIcon from '@material-ui/icons/Refresh'
import ScreenShareIcon from '@material-ui/icons/ScreenShare'
import { useHistory } from 'react-router-dom'

import FilterControls from 'components/Blocks/Filters/FilterControls'
import { InsightsFilter } from 'config/LocalStorage'
import { FilterTypeFragment, FilterValueFragment, SurveyProductTypeEnum } from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import { surveyPage, SURVEY_URLS } from 'utils/constants'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  tooltip: {
    backgroundColor: palette.common.navy,
    color: '#FFF',
  },
}))

type Props = {
  surveyUuid: string
  surveyProductType: SurveyProductTypeEnum
  isPublic: boolean
  showRefresh: boolean
  handleRefresh(): void
  handleDownload(): void
  openShareDialog(): void
  downloads: { show: boolean; text: string; disabledTitle?: string }[]
  filters: FilterTypeFragment[]
  selectedFilters: Array<InsightsFilter>
  toggleFilter(filterType: FilterTypeFragment, filterValue: FilterValueFragment): void
  removeFilter(filterObj: InsightsFilter): void
  clearFilters(): void
}

const LockedSurveyControls: React.FC<Props> = ({
  surveyUuid,
  surveyProductType,
  isPublic,
  showRefresh,
  handleRefresh,
  handleDownload,
  openShareDialog,
  downloads,
  filters,
  selectedFilters,
  toggleFilter,
  removeFilter,
  clearFilters,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const showShareScreen = !isPublic && openShareDialog !== null

  return (
    <Paper className={classes.root} square elevation={0}>
      <FilterControls
        filters={filters}
        toggleFilter={toggleFilter}
        removeFilter={removeFilter}
        clearFilters={clearFilters}
        selectedFilters={selectedFilters}
        surveyUuids={[surveyUuid]}
        surveyProductTypes={[surveyProductType]}
        fetchPolicy="network-only"
      >
        <div>
          {(isPublic || showRefresh) && (
            <IconButton onClick={handleRefresh}>
              <Tooltip title="Refresh Data" classes={classes}>
                <RefreshIcon />
              </Tooltip>
            </IconButton>
          )}
          {(showShareScreen || downloads.length) > 0 && (
            <>
              <IconButton
                aria-label="More"
                aria-owns={anchorEl ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={(e: SyntheticEvent) => setAnchorEl(e.currentTarget as HTMLElement)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                  },
                }}
              >
                {downloads.map(d => (
                  <Tooltip title={d.disabledTitle || ''} key={d.text}>
                    <div>
                      {/* Use div child so we can still see the tooltip when menu is disabled */}
                      <MenuItem
                        key={d.text}
                        disabled={Boolean(d.disabledTitle)}
                        onClick={() => {
                          setAnchorEl(null)
                          handleDownload()
                        }}
                      >
                        <ListItemIcon>
                          <CloudDownloadIcon />
                        </ListItemIcon>
                        <ListItemText style={{ paddingLeft: 0 }}>{d.text}</ListItemText>
                      </MenuItem>
                    </div>
                  </Tooltip>
                ))}
                {showShareScreen && (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null)
                      openShareDialog()
                    }}
                  >
                    <ListItemIcon>
                      <ScreenShareIcon />
                    </ListItemIcon>
                    <ListItemText style={{ paddingLeft: 0 }} id="shareReport">
                      Share this report
                    </ListItemText>
                  </MenuItem>
                )}
                {/** When viewing the response rate report as a standalone, allow the user to redirect to Walle
                so they can download the excel version of the report. */}
                {isPublic && (
                  <MenuItem
                    onClick={() => history.push(surveyPage(surveyUuid, SURVEY_URLS.MONITOR))}
                  >
                    <ListItemIcon>
                      <ScreenShareIcon />
                    </ListItemIcon>
                    <ListItemText style={{ paddingLeft: 0 }}>Login to AI</ListItemText>
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </div>
      </FilterControls>
    </Paper>
  )
}

export default withErrorHandler(LockedSurveyControls)
