import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import { formatDistance } from 'date-fns'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import RosterUpload from 'components/Blocks/CustomUpload/RosterUpload'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import {
  ImportTypesEnum,
  SurveysParticipantsUploadQuery,
  useSurveysAddParticipantsUploadMutation,
  useSurveysRemoveParticipantsUploadMutation,
  useSurveysGenerateUploadUrlMutation,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'

const useStyles = makeStyles(({ spacing }) => ({
  uploadTitle: {
    display: 'flex',
    alignItems: 'center',
    '& >p': {
      paddingLeft: spacing(1),
    },
  },
  directions: {
    '& >p': {
      paddingTop: spacing(2),
    },
  },
}))

type Props = {
  surveyUuid: string
  fileName?: string | null
  summary?:
    | null
    | NonNullable<SurveysParticipantsUploadQuery['survey']['participantsUpload']>['summary']
  hasErrors: boolean
  templateUrl: string
  updated?: string
}

const UploadHeader: React.FC<Props> = ({
  surveyUuid,
  fileName,
  summary,
  hasErrors,
  templateUrl,
  updated,
}) => {
  const classes = useStyles()
  const [addParticipantsUpload] = useSurveysAddParticipantsUploadMutation()
  const [removeParticipantsUpload] = useSurveysRemoveParticipantsUploadMutation()
  const [generateUploadUrl] = useSurveysGenerateUploadUrlMutation()

  const handleGenerateUploadUrl = async (newFileName: string, contentType: string) => {
    const { data } = await generateUploadUrl({
      variables: {
        fileName: newFileName,
        contentType,
      },
    })
    return data?.generateUploadUrl?.signedUrl
  }

  const onFileUploaded = async (file: string, path: string) => {
    const result = await addParticipantsUpload({
      variables: {
        importType: ImportTypesEnum.PARTICIPANTS,
        surveyUuid,
        objectPath: path,
      },
    })
    if (result?.data?.addParticipantsUpload) {
      emitter.emit(
        'SUCCESS',
        'Your participants upload is being processed! You’ll receive a notification when the data is ready.',
        50000, // 50 seconds unless the user exits out
      )
    }
  }

  let title = 'Upload Participants File'
  let description = (
    <span>
      Download the template and add your participants information to it. Make sure that you keep the
      column headers from the template and delete any columns you don’t use.
      <br />
      <br />
      Tip: Export your participants from your HRIS or payroll system to Excel, and then either
      rename the columns or copy and paste the data into the template.
    </span>
  )
  if (fileName) {
    title = 'Uploaded Participants'
    if (hasErrors) {
      description = <span>Please fix the errors below and reupload your file.</span>
    } else {
      description = (
        <span>Import another file to add more participants or update your existing ones.</span>
      )
    }
  }
  return (
    <FormPanel expandIcon={EXPAND_ICON.ARROW}>
      <GridContainer>
        <ItemGrid md={9}>
          <div className={classes.uploadTitle}>
            <Typography variant="h6">{title}</Typography>
            {fileName && updated && (
              <Typography variant="body2" color="textSecondary">
                {formatDistance(new Date(updated), new Date())} ago
              </Typography>
            )}
          </div>
          <Typography color="textSecondary">{description}</Typography>
        </ItemGrid>
        <ItemGrid md={9}>
          <RosterUpload
            fileName={fileName}
            templateUrl={templateUrl}
            generateUploadUrl={handleGenerateUploadUrl}
            onFileUploaded={onFileUploaded}
            showCancelButton={!!(fileName && (!summary || !summary.processed))}
            removeUpload={async () => {
              const removeResult = await removeParticipantsUpload({
                variables: {
                  importType: ImportTypesEnum.PARTICIPANTS,
                  surveyUuid,
                },
              })
              return removeResult?.data?.removeParticipantsUpload?.survey?.participantsUpload
                ?.fileName
            }}
            templateHeader="Step 1: Download Participant Template"
            uploadHeader="Step 2: Upload Your File"
            templateDescription={
              <div className={classes.directions}>
                <Typography color="textSecondary">
                  Copy and paste your data into the template, or upload your own file, making sure
                  headers and formatting match.
                </Typography>
              </div>
            }
            uploadDescription={
              <div className={classes.directions}>
                <Typography color="textSecondary">Upload the file. </Typography>
                <Typography color="textSecondary">
                  Your Program Manager will review and create your participant list. Once complete,
                  the information will be formatted in a table below.
                </Typography>
              </div>
            }
          />
        </ItemGrid>
      </GridContainer>
    </FormPanel>
  )
}

export default UploadHeader
