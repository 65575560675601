import React from 'react'

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Redirect, Route, Switch } from 'react-router-dom'

import ContactsUpload from 'components/Settings/Contacts/ContactsUpload'
import FilterValues from 'components/Settings/FilterValues/FilterValues'
import OrgSettingsContainer from 'components/Settings/General/OrgSettingsContainer'
import SurveyPortalContainer from 'components/Settings/SurveyPortal/SurveyPortalContainer'
import TeamPermissions from 'components/Settings/Team/TeamPermissions'
import { SurveyProductTypeEnum } from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import { getSolutionFromProductType } from 'utils'
import { PRODUCT_TYPE_CONSTANTS, URLS } from 'utils/constants'

const OrgSettingsRoutes = ({ currentUser }) => {
  const portalRoutes = Object.values(SurveyProductTypeEnum)
    .filter(productType => getSolutionFromProductType(currentUser.organization, productType))
    .map(productType => (
      <Route
        exact
        key={productType}
        path={PRODUCT_TYPE_CONSTANTS[productType].settingsUrls}
        render={() => <SurveyPortalContainer productType={productType} />}
      />
    ))
  return (
    <>
      <BreadcrumbsItem to="#">Org Settings</BreadcrumbsItem>
      <Switch>
        <Route exact path={URLS.ORG_SETTINGS.GENERAL} component={OrgSettingsContainer} />
        <Route exact path={URLS.ORG_SETTINGS.CONTACTS_UPLOAD} component={ContactsUpload} />
        {portalRoutes}
        <Route path={URLS.ORG_SETTINGS.TEAM} component={TeamPermissions} />
        <Route exact path={URLS.ORG_SETTINGS.FILTER_VALUES} component={FilterValues} />
        <Redirect to={URLS.ORG_SETTINGS.GENERAL} />
      </Switch>
    </>
  )
}

export default withErrorHandler(OrgSettingsRoutes)
