import React, { useState } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import { format } from 'date-fns'

import ActionItemTask from 'components/ActionPlans/ActionItemTask'
import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import { EchartsScore } from 'components/Insights/TimeTrending/utils'
import { ActionPlansActionItemFragment } from 'generated/graphql'
import { colors } from 'shared/theme'
import { SurveyNodeAP as SurveyNode } from 'utils/types'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginTop: spacing(3),
    marginBottom: spacing(3),
    minHeight: 100,
    border: `1px solid ${palette.common.navy25}`,
    borderRadius: 3,
  },
  actionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: palette.common.iceGrey,
    padding: spacing(2),
    '& >div': {
      '& >h5': {
        marginRight: spacing(),
      },
    },
    '& span': {
      color: palette.common.navy65,
    },
  },
  seeActionItems: {
    cursor: 'pointer',
  },
  actionItemContent: {
    width: '100%',
  },
  actionItemsLabel: {
    marginLeft: spacing(2),
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
  tasksContainer: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  chartContainer: {
    marginLeft: spacing(2),
  },
  delta: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(2),
  },
}))

export const getItemCreatedChartValue = (endDates: Date[], itemCreatedAt: Date) => {
  // AP charts show surveys on the x-axis. Their position is given by an integer value, which reflects the order of surveys in the list. We need to draw a vertical line for the date the action plan was created in between survey dates, so we calculate an integer value that reflects the distance between the survey dates (e.g. value of 1.5 would be half way between survey 1 and survey 2)

  // find first survey that has closed after the action item had been added to the plan
  let surveyIndex = endDates.findIndex(endDate => endDate.getTime() > itemCreatedAt.getTime())
  // If there's no closed survey after the action item has been aded to the plan
  if (surveyIndex === -1) {
    return endDates.length - 1
  }
  // If the first survey is the first after the action plan is closed, (e.g. if an old survey was deleted)
  // move the index to 1 so that (index, index - 1) gives us the first two surveys.
  if (surveyIndex === 0) {
    surveyIndex = 1
  }
  const datesDiff = endDates[surveyIndex].getTime() - endDates[surveyIndex - 1].getTime()
  const createdDiff = itemCreatedAt.getTime() - endDates[surveyIndex - 1].getTime()
  return surveyIndex - 1 + Math.round((createdDiff / datesDiff) * 100) / 100
}

const Chart: React.FC<{
  surveys: SurveyNode[]
  positiveScores: EchartsScore[]
  inconsistentScores: EchartsScore[]
  negativeScores: EchartsScore[]
  actionItemCreated: string
}> = ({ surveys, positiveScores, inconsistentScores, negativeScores, actionItemCreated }) => {
  const itemCreatedValue = getItemCreatedChartValue(
    // End date is only undefined for custom surveys, which can't be used here
    surveys.map(({ endDate }) => new Date(endDate!)),
    new Date(actionItemCreated),
  )
  const legendData = [
    { name: 'Positive', icon: 'square' },
    { name: 'Neutral', icon: 'square' },
    { name: 'Negative', icon: 'square' },
  ]
  return (
    <PrintableEchart
      notMerge
      printWidth={300}
      style={{ width: '100%', height: 220, left: -40 }}
      option={{
        grid: { top: 40 },
        textStyle: {
          ...chartTextStyle,
          color: colors.navy65,
        },
        xAxis: {
          type: 'value',
          data: surveys,
          min: 0,
          max: surveys.length - 1,
          splitNumber: surveys.length - 1,
          axisLabel: {
            formatter: (name: string, index: number) => {
              const survey = surveys.find((s, idx) => idx === index)
              if (!survey) return ''
              return format(new Date(survey.endDate!), 'MMM yyyy')
            },
          },
          splitLine: { show: false },
          axisTick: { show: false, alignWithLabel: true },
          axisLine: { show: false },
        },
        yAxis: ['left', 'right'].map(position => ({
          position,
          type: 'value',
          axisTick: { show: false },
          axisLine: { show: false },
          scale: 20,
          min: 0,
          max: 100,
        })),
        legend: {
          data: legendData,
          right: 10,
          textStyle: { ...chartTextStyle, fontSize: 14 },
          itemHeight: 8,
          itemWidth: 12,
          itemGap: 50,
          padding: [0, 80, 0, 0],
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: colors.navy,
          formatter: (params: any) => {
            const surveyIndex = params[0].axisValue
            return surveys.find((survey, index) => index === surveyIndex)?.name
          },
        },
        series: [
          {
            data: positiveScores,
            type: 'line',
            name: legendData[0].name,
            lineStyle: { color: colors.success, width: 3 },
            markLine: {
              data: [{ name: 'Created at', xAxis: itemCreatedValue }],
              label: {
                formatter: () => `Created at ${format(new Date(actionItemCreated), 'MM/dd/yyyy')}`,
              },
              symbol: 'none',
              lineStyle: {
                type: 'solid',
                color: colors.navy65,
                width: 2,
                emphasis: {
                  color: colors.navy,
                  width: 2,
                },
              },
            },
          },
          {
            data: inconsistentScores,
            type: 'line',
            name: legendData[1].name,
            lineStyle: { color: colors.warning, width: 1 },
            symbol: 'none',
          },
          {
            data: negativeScores,
            type: 'line',
            name: legendData[2].name,
            lineStyle: { color: colors.danger, width: 1 },
            symbol: 'none',
          },
        ],
        color: [colors.success, colors.warning, colors.danger],
      }}
    />
  )
}

type Props = {
  actionItem: ActionPlansActionItemFragment
  completedDate: Date
  surveys: SurveyNode[]
  positiveScores: EchartsScore[]
  inconsistentScores: EchartsScore[]
  negativeScores: EchartsScore[]
  delta: number | null
  targetUserUuid?: string
}

const CompletedActionItem: React.FC<Props> = ({
  actionItem,
  completedDate,
  surveys,
  targetUserUuid,
  positiveScores,
  inconsistentScores,
  negativeScores,
  delta,
}) => {
  const classes = useStyles()
  const isCustomStatement = actionItem.statement === null
  // When there's only 1 closed survey, the charts make no sense. In this case, we're defaulting to showing the tasks instead
  const alwaysShowTasks = surveys.length === 1 || isCustomStatement
  const [showTasks, setShowTasks] = useState(alwaysShowTasks)
  return (
    <div className={classes.root}>
      <div className={classes.actionItem}>
        <Typography variant="h6">
          {actionItem.statement?.text || actionItem.customStatement}
          <span> Completed {completedDate && format(completedDate, 'MM/dd/yyyy')}</span>
        </Typography>
        {!alwaysShowTasks && (
          <Typography
            component="div"
            variant="subtitle1"
            color="secondary"
            className={classes.seeActionItems}
            onClick={() => setShowTasks(!showTasks)}
          >
            <div>{showTasks ? 'See Scores' : 'See Action Items'}</div>
          </Typography>
        )}
      </div>
      <div className={classes.actionItemContent}>
        {showTasks ? (
          <>
            <Typography variant="h6" className={classes.actionItemsLabel}>
              Action Items:
            </Typography>
            <ul className={classes.tasksContainer}>
              {actionItem.actionItemTasks.map(task => (
                <li key={task.uuid}>
                  <ActionItemTask
                    editMode={false}
                    task={task}
                    actionItemUuid={actionItem.uuid}
                    targetUserUuid={targetUserUuid}
                  />
                </li>
              ))}
            </ul>
          </>
        ) : (
          <div className={classes.chartContainer}>
            <div className={classes.delta}>
              <Typography>Score Change:</Typography>
              <ScoreChangeArrow delta={delta} />
            </div>
            <Chart
              surveys={surveys}
              positiveScores={positiveScores}
              inconsistentScores={inconsistentScores}
              negativeScores={negativeScores}
              actionItemCreated={actionItem.created}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CompletedActionItem
