import React, { useState } from 'react'

import { makeStyles, Typography, MenuItem, ListItemIcon } from '@material-ui/core'
import ScatterIcon from '@material-ui/icons/BorderAll'
import TableIcon from '@material-ui/icons/Menu'
import isNil from 'lodash/isNil'

import { AnalyticsSurveyNode } from 'components/Analytics/AnalyticsContainer'
import OverlayTableView from 'components/Analytics/OverlayTableView'
import ScatterPlotContainer, {
  ScatterDataHandler,
  ChartData,
  CardViewType,
  HierarchyScoresByGroup,
  QuadrantTooltip,
} from 'components/Blocks/Charts/ScatterPlotContainer'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import FilterGroupDropdowns from 'components/Blocks/Filters/FilterGroupDropdowns'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import StandardTooltip from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import { QuadrantLabel } from 'components/Insights/TimeTrending/ScatterPlot'
import { gaEvent } from 'config/ga'
import { FilterTypeFragment } from 'generated/graphql'
import { colors } from 'shared/theme'
import { formatTooltipScore } from 'utils'
import { getFormattedSurveyDate } from 'utils/dateUtils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  descriptionBlock: {
    width: '80%',
    '& >span': {
      color: palette.common.navy,
    },
  },
  dropdownRow: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: -8,
  },
  viewTypeIcon: {
    color: palette.common.navy65,
    position: 'relative',
    top: 2,
  },
  titleTooltip: {
    padding: spacing(1),
    '& >span': {
      color: palette.common.navy,
    },
  },
}))

const QUADRANT_TITLES = {
  TOP_LEFT: 'Monitor',
  TOP_RIGHT: 'Top Players',
  BOTTOM_LEFT: 'At Risk',
  BOTTOM_RIGHT: 'Monitor',
}

export const transformScoresToEcharts = (data: HierarchyScoresByGroup) => {
  return (
    Object.keys(data)
      .map(level1DataType => {
        return {
          name: level1DataType,
          data: Object.keys(data[level1DataType])
            .map(level2DataType => {
              const [employeeScore, residentSscore] = Object.values(
                data[level1DataType][level2DataType],
              )
              // If either of the scores is a <minShowableResults (null) or doesn't exist on a survey (undefined)
              // we don't want to bother plotting it. Return null here and filter nulls from the list afterwards.
              if (isNil(employeeScore) || isNil(residentSscore)) return null
              return {
                name: level2DataType,
                value: [employeeScore, residentSscore],
              }
            })
            .filter((score): score is ChartData => score !== null),
        }
      })
      // Ignore groups that have no data
      .filter(score => score.data.length > 0)
  )
}

type Props = {
  employeeSurvey: AnalyticsSurveyNode
  residentSurvey: AnalyticsSurveyNode
  filters: string[]
  visibleFilterTypes: FilterTypeFragment[]
  employeeIndexScore: number
  residentIndexScore: number
}
const ScatterAndTableCard: React.FC<Props> = ({
  employeeSurvey,
  residentSurvey,
  filters,
  visibleFilterTypes,
  employeeIndexScore,
  residentIndexScore,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const hasMultipleFilters = visibleFilterTypes.length > 1
  // Scatter plot isn't visible with <2 filters, so default to table view.
  const [selectedViewType, setSelectedViewType] = useState(
    hasMultipleFilters ? CardViewType.SCATTER : CardViewType.TABLE,
  )
  // Data on the scatter plot can be grouped by 2 levels. Level2 values are grouped by Level1.
  // Multi-level group by is optional, in which case we only group by Level2.
  // When an organization only has 2 filter types, we default to the single level group by.
  const [level1FilterType, setLevel1FilterType] = useState<FilterTypeFragment | null>(
    visibleFilterTypes.length > 2 ? visibleFilterTypes[0] : null,
  )
  const [level2FilterType, setLevel2FilterType] = useState(
    // TODO: remove this conditional when we validate whether a user should have access to more than 1 filter type
    visibleFilterTypes.length > 1 ? visibleFilterTypes[1] : visibleFilterTypes[0],
  )
  const employeeSurveyDate = getFormattedSurveyDate({
    endDate: employeeSurvey.endDate!,
    excludeComma: true,
  })
  const residentSurveyDate = getFormattedSurveyDate({
    endDate: residentSurvey.endDate!,
    excludeComma: true,
  })
  const viewTypeToIcon = {
    [CardViewType.SCATTER]: <ScatterIcon className={classes.viewTypeIcon} />,
    [CardViewType.TABLE]: <TableIcon className={classes.viewTypeIcon} />,
  }
  const titleDescription = {
    [CardViewType.SCATTER]: (
      <span className={classes.descriptionBlock}>
        See which {level2FilterType.namePlural} are leading — or falling behind — when you look
        across employee and customer engagement scores.
      </span>
    ),
    [CardViewType.TABLE]: (
      <span className={classes.descriptionBlock}>
        See employee and customer engagement scores for each {level2FilterType.name}.
      </span>
    ),
  }[selectedViewType]
  return (
    <div id="overlay-scatter-snap" className={classes.fullRow}>
      <SnapshotChartHeader
        title="Compare Scores for employees and customers"
        description={titleDescription}
        tooltip={
          <QuadrantTooltip
            title="Hover over graph quadrants to see these labels."
            quadrantTitles={QUADRANT_TITLES}
            quadrantDescriptions={{
              TOP_RIGHT: 'High employee and customer engagement scores',
              TOP_LEFT: 'High customer engagement, low employee engagement.',
              BOTTOM_RIGHT: 'High employee engagement, low customer engagement.',
              BOTTOM_LEFT: 'Low employee and customer engagement scores.',
            }}
          />
        }
        snapId="overlay-scatter-snap"
        screenshotStrategy="svg"
        useBottomPadding={false}
        extraControls={
          hasMultipleFilters ? (
            <div className={classes.dropdownRow}>
              <FilterGroupDropdowns
                level1FilterType={level1FilterType}
                level2FilterType={level2FilterType}
                setLevel1FilterType={setLevel1FilterType}
                setLevel2FilterType={setLevel2FilterType}
                visibleFilterTypes={visibleFilterTypes}
              />
              <TextDropdown
                value={selectedViewType}
                renderValue={viewType => viewTypeToIcon[viewType as CardViewType]}
                onChange={e => {
                  gaEvent({
                    action: 'selectedOverlayTableView',
                    category: 'Analytics',
                  })
                  setSelectedViewType(e.target.value as CardViewType)
                }}
                id="scatter-table-select"
              >
                {Object.values(CardViewType).map(viewType => {
                  return (
                    <MenuItem key={viewType} value={viewType}>
                      <ListItemIcon>{viewTypeToIcon[viewType]}</ListItemIcon>
                      <Typography>{viewType}</Typography>
                    </MenuItem>
                  )
                })}
              </TextDropdown>
            </div>
          ) : (
            undefined
          )
        }
      />
      <ScatterDataHandler
        level1FilterType={level1FilterType}
        level2FilterType={level2FilterType}
        selectedSurveys={[employeeSurvey, residentSurvey]}
        filters={filters}
      >
        {({ surveyScoresByGroup }) => {
          if (selectedViewType === CardViewType.TABLE) {
            return (
              <OverlayTableView
                quadrantTitles={QUADRANT_TITLES}
                data={surveyScoresByGroup}
                // If level1 is missing, display level2 for both
                level1FilterType={level1FilterType || level2FilterType}
                level2FilterType={level2FilterType}
                employeeSurveyDate={employeeSurveyDate}
                residentSurveyDate={residentSurveyDate}
                employeeIndexScore={employeeIndexScore}
                residentIndexScore={residentIndexScore}
              />
            )
          }
          return (
            <ScatterPlotContainer
              surveyScoresByGroup={surveyScoresByGroup}
              setSelectedViewType={setSelectedViewType}
              scatterPlotTransformFn={transformScoresToEcharts}
              scatterPlotProps={{
                yAxisTitle: 'Customer Engagement',
                yAxisCenter: residentIndexScore,
                xAxisTitle: 'Employee Engagement',
                xAxisCenter: employeeIndexScore,
                showYAxisCenterLine: true,
                useAxisLineDetail: false,
                quadrantTitles: QUADRANT_TITLES,
                renderTooltip: ({ xVal, yVal, xName, yName, activeQuadrant }) => {
                  return (
                    <StandardTooltip
                      title={yName}
                      rows={[
                        {
                          label: 'Customer Score:',
                          value: formatTooltipScore(yVal, employeeSurvey.minShowableResults),
                          color: colors.success,
                        },
                        {
                          label: 'Employee Score:',
                          value: formatTooltipScore(xVal, residentSurvey.minShowableResults),
                          color: colors.success,
                        },
                        {
                          label: `${(level1FilterType || level2FilterType).name}:`,
                          value: xName,
                        },
                      ]}
                      description={
                        <QuadrantLabel
                          quadrantTitles={QUADRANT_TITLES}
                          activeQuadrant={activeQuadrant}
                        />
                      }
                    />
                  )
                },
              }}
            />
          )
        }}
      </ScatterDataHandler>
    </div>
  )
}

export default ScatterAndTableCard
