import React from 'react'

import { Typography } from '@material-ui/core'

import { CustomQuestionCardProps } from './types'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import echartTooltipBuilder from 'components/Insights/CustomSurvey/Cards/echartTooltipBuilder'
import useStyles from 'components/Insights/CustomSurvey/styles'
import useInsightsStyles, { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import { splitTextByLineLength } from 'utils'

const MAX_LABEL_LINE_LENGTH = 30

const MultiselectCard: React.FC<CustomQuestionCardProps> = ({
  id,
  question,
  data,
  dataIsFiltered,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }
  return (
    <FormPanel
      id={id}
      expanded
      gutterBottom={false}
      expandIcon={EXPAND_ICON.EXPAND}
      summary={<Typography>{question.text}</Typography>}
    >
      <div id={question.uuid} className={classes.pageBreak}>
        <div className={classes.actions}>
          <ScreenshotButton snapId={question.uuid} />
        </div>
        <PrintableEchart
          notMerge
          printWidth={300}
          option={{
            textStyle: chartTextStyle,
            grid: { bottom: 35, top: 10, left: 200, right: 200 },
            xAxis: {
              min: 0,
              axisTick: { show: false },
              axisLine: { show: false },
            },
            yAxis: {
              type: 'category',
              axisTick: { show: false },
              axisLine: { show: false },
              axisLabel: {
                formatter: (value: string) => splitTextByLineLength(value, MAX_LABEL_LINE_LENGTH),
              },
              data: data.map(d => d.name),
            },
            series: [
              {
                data,
                type: 'bar',
                name: dataIsFiltered ? 'Selected Filters' : 'Company Average',
                barMaxWidth: 30,
                encode: {
                  x: 'score',
                  y: 'name',
                },
              },
            ],
            color: '#3F708B',
            tooltip: {
              formatter: echartTooltipBuilder,
            },
          }}
        />
      </div>
    </FormPanel>
  )
}

export default MultiselectCard
