import React, { SyntheticEvent, useEffect, useState } from 'react'

import debounce from 'lodash/debounce'
import { ValidatorComponentProps } from 'react-material-ui-form-validator'

type Props = {
  InputComponent: React.ElementType
  delayTime?: number
  InputProps?: object
  onUpdate(response: string): void
  multiline?: boolean
  validateChange?(text: string): boolean
} & ValidatorComponentProps

const DebouncedTextInput: React.FC<Props> = ({
  InputComponent,
  onUpdate,
  delayTime,
  value: initialValue,
  validateChange,
  ...restProps
}) => {
  const [value, setValue] = useState(initialValue)
  const [debouncer, setDebouncer] = useState(() => debounce(onUpdate, delayTime || 1000))

  useEffect(() => {
    // debounce returns a function that creates a local copy of its arguments, so the result of the onUpdate function will always be executed with the arguments that are initially passed in.
    // To ensure that the update function uses updated arguments, reset the value of the debounced function when the state of the update function itself changes (e.g. any variables that are within its scope).
    setDebouncer(() => debounce(onUpdate, delayTime || 1000))
  }, [onUpdate, delayTime])

  const onChange = (e: SyntheticEvent) => {
    const { value: eventValue } = e.target as HTMLInputElement
    if (!validateChange || validateChange(eventValue)) {
      setValue(eventValue)
      debouncer(eventValue)
    }
  }

  return <InputComponent value={value} onChange={onChange} {...restProps} />
}

export default DebouncedTextInput
