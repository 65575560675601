import React, { Dispatch, FC, SetStateAction, SyntheticEvent, useRef, useState } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { TextValidator } from 'react-material-ui-form-validator'

import Button from 'components/Blocks/CustomButtons/Button'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import DebouncedTextInput from 'components/Blocks/FormHelpers/DebouncedTextInput'
import Loader from 'components/Blocks/Loader'
import ImagePreviewer from 'components/Images/ImagePreviewer'
import {
  CultureBriefImageType,
  CultureBriefImageTypeEnum,
  useAddCultureBriefImagesMutation,
  useUpdateCultureBriefImagesMutation,
  useDeleteCultureBriefImagesMutation,
} from 'generated/graphql'
import { usePublicConstants } from 'utils/customHooks'

export const CULTURE_BRIEF_MIN_IMAGES = 5
export const CULTURE_BRIEF_MAX_CAPTION_CHARACTERS = 120
// Size in megabytes
export const CULTURE_BRIEF_MAX_IMAGE_SIZE = 9
// Size in pixels
export const CULTURE_BRIEF_IMAGE_MIN_WIDTH = 1080
export const CULTURE_BRIEF_IMAGE_MIN_HEIGHT = 1080
const NEW_IMAGE_PREFIX = 'NEW'

const useStyles = makeStyles(({ spacing, palette }) => ({
  uploadAndPreview: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(1),
    marginBottom: spacing(5),
  },
  imageRow: {
    padding: spacing(),
    margin: spacing(),
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: palette.common.iceGrey65,
  },
  imageRowThumb: {
    // Defined by our designer
    width: 138,
    height: 92,
    '& >img': {
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto',
      display: 'block',
    },
  },
  imageRowCaption: {
    flexGrow: 1,
    marginLeft: spacing(2),
  },
  imageRowCaptionInput: {
    width: '100%',
    backgroundColor: palette.common.white,
  },
  emptyDiv: {
    width: 38,
  },
  deleteIcon: {
    color: palette.common.navy65,
  },
}))

type CultureBriefImageCaptionProps = {
  autosaveDisabled: boolean
  cultureBriefUuid: string
  image: CultureBriefImageType
  index: number
  setImagesToAdd: Dispatch<SetStateAction<Array<CultureBriefImageType>>>
  setImagesToUpdate: Dispatch<SetStateAction<Array<CultureBriefImageType>>>
}

const CultureBriefImageCaption: FC<CultureBriefImageCaptionProps> = ({
  autosaveDisabled,
  cultureBriefUuid,
  image,
  index,
  setImagesToAdd,
  setImagesToUpdate,
}) => {
  const classes = useStyles()
  const [updateImages] = useUpdateCultureBriefImagesMutation()

  const onUpdateImage = (newCaption: string) => {
    const replaceExisting = (
      previousImages: Array<CultureBriefImageType>,
    ): Array<CultureBriefImageType> => {
      return previousImages.map(previousImage =>
        previousImage.uuid === image.uuid
          ? { ...previousImage, caption: newCaption }
          : previousImage,
      )
    }

    if (autosaveDisabled) {
      if (image.uuid.startsWith(NEW_IMAGE_PREFIX)) {
        setImagesToAdd(prevImagesToAdd => replaceExisting(prevImagesToAdd))
      } else {
        setImagesToUpdate(prevImagesToUpdate => {
          // If the image already exists in prevImages, then just replace with updated caption.
          const existingImageToUpdate = prevImagesToUpdate.find(img => img.uuid === image.uuid)
          if (existingImageToUpdate !== undefined) {
            return replaceExisting(prevImagesToUpdate)
          }

          // Otherwise, add a new imageToUpdate
          return [
            ...prevImagesToUpdate,
            {
              uuid: image.uuid,
              url: image.url,
              imageType: image.imageType,
              caption: newCaption,
            },
          ]
        })
      }

      return
    }

    updateImages({
      variables: {
        cultureBriefUuid,
        imagesData: [
          {
            uuid: image.uuid,
            url: image.url,
            imageType: image.imageType,
            caption: newCaption,
          },
        ],
      },
    })
  }

  return (
    <DebouncedTextInput
      id={`${image.imageType}caption${index}`}
      multiline
      rows={3}
      className={classes.imageRowCaptionInput}
      InputComponent={TextValidator}
      value={image.caption}
      validateChange={(text: string) => text.length < CULTURE_BRIEF_MAX_CAPTION_CHARACTERS}
      onUpdate={onUpdateImage}
      name="caption"
      size="small"
      variant="outlined"
      color="secondary"
    />
  )
}

type CultureBriefImagesProps = {
  cultureBriefUuid: string
  isCompletedCultureBrief: boolean
  imageType: CultureBriefImageTypeEnum
  images: Array<CultureBriefImageType>
  setImagesToAdd: Dispatch<SetStateAction<Array<CultureBriefImageType>>>
  setImagesToUpdate: Dispatch<SetStateAction<Array<CultureBriefImageType>>>
  setImagesToRemove: Dispatch<SetStateAction<Array<string>>>
  setError: Dispatch<SetStateAction<string>>
}

const CultureBriefImages: FC<CultureBriefImagesProps> = ({
  cultureBriefUuid,
  isCompletedCultureBrief,
  imageType,
  images,
  setImagesToAdd,
  setImagesToUpdate,
  setImagesToRemove,
  setError,
}) => {
  const classes = useStyles()
  const [previewIndex, setPreviewIndex] = useState<number>()
  const { cultureBriefMaxImages } = usePublicConstants()
  const [saveImages, { loading: loadingSave }] = useAddCultureBriefImagesMutation()
  const [deleteImages, { loading: loadingDelete }] = useDeleteCultureBriefImagesMutation()
  const uploadInputRef = useRef<HTMLInputElement>(null)
  const companyImageType = imageType === CultureBriefImageTypeEnum.COMPANY
  const autosaveDisabled = isCompletedCultureBrief

  const removeImage = async (uuid: string) => {
    if (autosaveDisabled) {
      // If new image, then simply remove from images-to-add
      if (uuid.startsWith(NEW_IMAGE_PREFIX)) {
        setImagesToAdd(prevImagesToAdd => prevImagesToAdd.filter(image => image.uuid !== uuid))
      } else {
        setImagesToRemove(prevImageUuids => [...prevImageUuids, uuid])
      }

      return
    }

    await deleteImages({
      variables: {
        cultureBriefUuid,
        uuids: [uuid],
      },
      update(cache) {
        const normalizedId = cache.identify({ uuid, __typename: 'CultureBriefImageType' })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    })
  }

  const addImages = async (dataUrls: string[]) => {
    if (autosaveDisabled) {
      setImagesToAdd(prevImagesToAdd => {
        const newImages = dataUrls.map((dataUrl, index) => {
          return {
            uuid: `${NEW_IMAGE_PREFIX}_${imageType}_${prevImagesToAdd.length + index}`,
            url: dataUrl,
            imageType,
            caption: '',
          }
        })

        return [...prevImagesToAdd, ...newImages]
      })

      return
    }

    const imagesData = dataUrls.map(dataUrl => ({ url: dataUrl, imageType, caption: '' }))

    saveImages({
      variables: {
        cultureBriefUuid,
        imagesData,
      },
    }).catch(caughtError => {
      setError(caughtError.message)
    })
  }

  const handleImageChange = async (event: SyntheticEvent) => {
    const inputFiles = (event.target as HTMLInputElement).files
    if (inputFiles === null) {
      return
    }

    // Validate quantity
    if (companyImageType) {
      if (images.length + inputFiles.length > cultureBriefMaxImages) {
        setError(`You are only allowed a maximum of ${cultureBriefMaxImages} profile images.`)

        return
      }
    } else if (images.length + inputFiles.length > 1) {
      setError(`You are only allowed a maximum of 1 ${imageType} image.`)

      return
    }

    const inputImages: Array<File> = []
    let index = 0
    while (index < inputFiles.length) {
      const inputFile = inputFiles.item(index)
      if (inputFile !== null) {
        inputImages.push(inputFile)
        index += 1
      } else {
        break
      }
    }

    let totalImagesSize = 0
    const processImage = (inputImage: File) => {
      return new Promise<string>(resolve => {
        // size is returned in bytes, so we convert CULTURE_BRIEF_MAX_IMAGE_SIZE to bytes
        totalImagesSize += inputImage.size
        if (totalImagesSize > CULTURE_BRIEF_MAX_IMAGE_SIZE * 1000000) {
          setError(
            `Your upload is too large. Try using files no larger than ${CULTURE_BRIEF_MAX_IMAGE_SIZE} MB${companyImageType &&
              ', or upload them one-by-one'}.`,
          )

          return resolve()
        }

        const reader = new FileReader()
        reader.onloadend = () => {
          const image = new Image()
          image.src = reader.result as string

          const inputImageData = reader.result as string
          image.onload = () => {
            if (image.width < image.height && imageType === CultureBriefImageTypeEnum.LOGO) {
              setError(`You must use landscape mode only for your company logo.`)

              return resolve()
            }

            if (
              image.width < CULTURE_BRIEF_IMAGE_MIN_WIDTH &&
              image.height < CULTURE_BRIEF_IMAGE_MIN_HEIGHT
            ) {
              setError(
                `Your image file is too small. Try using a larger file that is at least ${CULTURE_BRIEF_IMAGE_MIN_WIDTH}px wide${
                  imageType !== CultureBriefImageTypeEnum.LOGO
                    ? ` (or ${CULTURE_BRIEF_IMAGE_MIN_HEIGHT}px high).`
                    : '.'
                }`,
              )

              return resolve()
            }

            return resolve(inputImageData)
          }
        }

        return reader.readAsDataURL(inputImage)
      })
    }

    const imagesData = await Promise.all(inputImages.map(processImage))
    if (
      imagesData?.length > 0 &&
      imagesData.length === imagesData.filter(i => i !== undefined).length
    ) {
      await addImages(imagesData)
    }
  }

  const imagesEmpty = images.length === 0

  const uploadButtonText = {
    [CultureBriefImageTypeEnum.LOGO]: 'Upload Logo',
    [CultureBriefImageTypeEnum.COMPANY]: 'Upload Images',
    [CultureBriefImageTypeEnum.CEO]: 'Upload Image',
  }[imageType]
  return (
    <>
      <div className={classes.uploadAndPreview}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (uploadInputRef.current !== null) {
              uploadInputRef.current.value = ''
              uploadInputRef.current.click()
            }
          }}
          disabled={
            (companyImageType && images.length >= cultureBriefMaxImages) ||
            (!companyImageType && !imagesEmpty)
          }
        >
          {uploadButtonText}
        </Button>
        <Button
          color="secondaryNoBackground"
          onClick={() => setPreviewIndex(0)}
          disabled={imagesEmpty}
        >
          Preview
        </Button>
      </div>
      <input
        ref={uploadInputRef}
        hidden
        accept="image/png,image/jpg,image/jpeg"
        id={imageType}
        type="file"
        name={imageType}
        onChange={e => handleImageChange(e)}
        multiple={companyImageType}
      />
      {images.map((image, index) => {
        // PM requires that the first profile image cannot be deleted after culture brief completion
        const shouldRemoveDeleteIcon = index === 0 && companyImageType && isCompletedCultureBrief
        return (
          <div key={image.uuid} className={classes.imageRow}>
            <div className={classes.imageRowThumb}>
              <img alt="Culture Brief" src={image.url} />
            </div>
            {companyImageType && (
              <div className={classes.imageRowCaption}>
                <CultureBriefImageCaption
                  autosaveDisabled={autosaveDisabled}
                  cultureBriefUuid={cultureBriefUuid}
                  image={image}
                  index={index}
                  setImagesToAdd={setImagesToAdd}
                  setImagesToUpdate={setImagesToUpdate}
                />
                <Typography color="textSecondary">
                  * A maximum of {CULTURE_BRIEF_MAX_CAPTION_CHARACTERS} characters allowed
                </Typography>
              </div>
            )}
            {shouldRemoveDeleteIcon ? (
              <div className={classes.emptyDiv} />
            ) : (
              <IconButton
                className={classes.deleteIcon}
                color="dangerHover"
                disabled={loadingDelete}
                onClick={() => removeImage(image.uuid)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        )
      })}
      {loadingSave && <Loader />}
      {!imagesEmpty && (
        <ImagePreviewer
          images={images}
          previewIndex={previewIndex}
          setPreviewIndex={setPreviewIndex}
        />
      )}
    </>
  )
}

export default CultureBriefImages
