import React, { useContext } from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import { ReactComponent as MinShowableResultsIcon } from 'assets/img/min-showable-results.svg'
import { AnalyticsSurveyNode } from 'components/Analytics/AnalyticsContainer'
import OverlayDrivingStatements from 'components/Analytics/OverlayDrivingStatements'
import ScatterAndTableCard from 'components/Analytics/OverlayScatterAndTableCard'
import OverlayServicesChart from 'components/Analytics/OverlayServicesChart'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import OverallIndexGuage from 'components/Insights/Snapshot/OverallIndexGauge'
import { getShowReportBy } from 'components/Survey/Wizard/SurveyResponseRateCard'
import { StoreContext } from 'config/LocalStorage'
import {
  BenchmarkNode,
  CurrentUserQuery,
  SurveyProductTypeEnum,
  useAnalyticsOverviewQuery,
  FilterTypeFragment,
  ResponseRateTypeEnum,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { numberWithCommas } from 'utils'
import { PRODUCT_TYPE_TO_OVERLAY_COLORS } from 'utils/constants'

const useStyles = makeStyles(({ palette, spacing }) => ({
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  gaugeAndScore: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    marginLeft: spacing(3),
    '& >p>span': {
      color: palette.common.navy,
    },
  },
  title: {
    textTransform: 'uppercase',
  },
}))

type OSProps = {
  title: string
  score: number
  benchmarkName?: string | null
  benchmarkScore?: number | null
  numInvited: number
  numFinished: number
  color: string
  id: string
}
const OverallScoreCard: React.FC<OSProps> = ({
  title,
  score,
  benchmarkName,
  benchmarkScore,
  numInvited,
  numFinished,
  color,
  id,
}) => {
  const classes = useStyles()
  return (
    <div id={id}>
      <Typography className={classes.title} variant="subtitle1">
        {title}
      </Typography>
      <div className={classes.content}>
        <div className={classes.gaugeAndScore}>
          <OverallIndexGuage
            height={125}
            width={115}
            positive={score}
            inconsistent={100 - score}
            chartColors={[color, colors.iceGrey, colors.iceGrey]}
          />
          <Typography variant="h4" style={{ color }}>
            {score}%
          </Typography>
        </div>
        <div className={classes.description}>
          <Typography color="textSecondary" variant="body2">
            {title}:&nbsp;<span>{score}%</span>
          </Typography>
          {benchmarkName && benchmarkScore !== null && (
            <Typography color="textSecondary" variant="body2">
              {benchmarkName}:&nbsp;<span>{Math.round(benchmarkScore || 0)}%</span>
            </Typography>
          )}
          <Typography color="textSecondary" variant="body2">
            Response Rate:&nbsp;
            <span>
              {Math.round((numFinished / numInvited) * 100)}% ({numberWithCommas(numFinished)}/
              {numberWithCommas(numInvited)})
            </span>
          </Typography>
        </div>
      </div>
    </div>
  )
}

const formatBenchmarkInput = (bm: BenchmarkNode) => ({
  benchmarkUuid: bm.benchmarkUuid,
  benchmarkType: bm.benchmarkType,
  name: bm.name,
})

type Props = {
  employeeSurvey: AnalyticsSurveyNode
  residentSurvey: AnalyticsSurveyNode
  filters: string[]
  visibleFilterTypes: FilterTypeFragment[]
  currentUser: CurrentUserQuery['currentUser']
}
const OverlayReport: React.FC<Props> = ({
  employeeSurvey,
  residentSurvey,
  visibleFilterTypes,
  filters,
  currentUser,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const {
    store: { employeeInsightsBenchmark, residentInsightsBenchmark, responseRateShowReportBy },
  } = useContext(StoreContext)

  const showResidentResponseRateBy = getShowReportBy(
    responseRateShowReportBy,
    residentSurvey.uuid,
    residentSurvey.isDefaultClientBasedResponseRateReport,
  )

  const employeeBenchmark = employeeInsightsBenchmark || employeeSurvey.defaultBenchmark
  const residentBenchmark = residentInsightsBenchmark || residentSurvey.defaultBenchmark
  const result = useAnalyticsOverviewQuery({
    variables: {
      employeeSurveyUuid: employeeSurvey.uuid,
      residentSurveyUuid: residentSurvey.uuid,
      filters,
      employeeBenchmark: employeeBenchmark ? formatBenchmarkInput(employeeBenchmark) : null,
      residentBenchmark: residentBenchmark ? formatBenchmarkInput(residentBenchmark) : null,
      residentResponseRateByClient: showResidentResponseRateBy === ResponseRateTypeEnum.CLIENT,
    },
  })
  return (
    <ResponseHandler {...result} hideStaleData>
      {({
        employeeIndexScore,
        residentIndexScore,
        employeeSurvey: {
          insightsSurvey: { responseRate: employeeResponseRate },
        },
        residentSurvey: {
          insightsSurvey: { responseRate: residentResponseRate, responseRateByParticipant },
        },
      }) => {
        const lessThanMinEmployee =
          employeeResponseRate.finished < employeeSurvey.minShowableResults
        const lessThanMinResident =
          responseRateByParticipant.finished < residentSurvey.minShowableResults
        if (lessThanMinEmployee || lessThanMinResident) {
          const emptyStateDescription = `There isn’t enough data to show this chart. To improve results
            check your filters, and try selecting a different ${[
              lessThanMinEmployee && 'Employee',
              lessThanMinResident && 'Customer',
            ]
              .filter(Boolean)
              .join(' and ')} Engagement Survey.`
          return (
            <EmptyState
              title="Oh Snap!"
              description={emptyStateDescription}
              Icon={MinShowableResultsIcon}
            />
          )
        }
        return (
          <>
            <div className={classes.halfRow}>
              <OverallScoreCard
                title="Employee Engagement Score"
                benchmarkName={employeeBenchmark?.name}
                score={Math.round(employeeIndexScore.positive)}
                benchmarkScore={employeeIndexScore.benchmarkPositive}
                numInvited={employeeResponseRate.total}
                numFinished={employeeResponseRate.finished}
                color={PRODUCT_TYPE_TO_OVERLAY_COLORS[SurveyProductTypeEnum.EMPLOYEE]}
                id="employee-index-score"
              />
              <OverallScoreCard
                title="Customer Engagement Score"
                benchmarkName={residentBenchmark?.name}
                score={Math.round(residentIndexScore.positive)}
                benchmarkScore={residentIndexScore.benchmarkPositive}
                numInvited={residentResponseRate.total}
                numFinished={residentResponseRate.finished}
                color={PRODUCT_TYPE_TO_OVERLAY_COLORS[SurveyProductTypeEnum.RESIDENT]}
                id="resident-index-score"
              />
            </div>
            {visibleFilterTypes.length > 0 && (
              <ScatterAndTableCard
                employeeSurvey={employeeSurvey}
                residentSurvey={residentSurvey}
                filters={filters}
                visibleFilterTypes={visibleFilterTypes}
                employeeIndexScore={Math.round(employeeIndexScore.positive)}
                residentIndexScore={Math.round(residentIndexScore.positive)}
              />
            )}
            <OverlayServicesChart
              employeeSurvey={employeeSurvey}
              residentSurvey={residentSurvey}
              filters={filters}
            />
            <OverlayDrivingStatements
              employeeSurvey={employeeSurvey}
              residentSurvey={residentSurvey}
              filters={filters}
              organization={currentUser.organization}
            />
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default OverlayReport
