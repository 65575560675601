import { format } from 'date-fns'
import jstz from 'jstz'

import {
  SURVEY_DEFAULT_MAX_PERIOD,
  SURVEY_DEFAULT_START_DAYS_FROM_NOW,
  SURVEY_DEFAULT_START_HOUR,
  SURVEY_DEFAULT_START_MINUTE,
  SURVEY_DEFAULT_END_DAYS_FROM_NOW,
  SURVEY_DEFAULT_END_HOUR,
  SURVEY_DEFAULT_END_MINUTE,
} from 'utils/constants'

export const addDays = (startDate: Date, numberOfDays: number) =>
  new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate() + numberOfDays,
    startDate.getHours(),
    startDate.getMinutes(),
    startDate.getSeconds(),
  )

export const formatDateAsString = (date: Date) => {
  return format(date, 'yyyy-MM-dd')
}

export const getDateFromDatetimeString = (dt?: string | null) => {
  return dt ? dt.split(' ')[0] : null
}

export const displayDateTime = (pickedTime?: string | null) => {
  return pickedTime ? format(new Date(pickedTime), 'MM/dd/yyyy h:mm aa') : 'N/A'
}

export const displayDate = (
  pickedTime?: string | null,
  fallbackText: string | undefined = 'N/A',
) => {
  return pickedTime ? format(new Date(pickedTime), 'MM/dd/yyyy') : fallbackText
}

export const surveyDefaultStartDate = () => {
  const d = addDays(new Date(), SURVEY_DEFAULT_START_DAYS_FROM_NOW)
  d.setHours(SURVEY_DEFAULT_START_HOUR, SURVEY_DEFAULT_START_MINUTE)
  return d
}

export const surveyDefaultEndDate = () => {
  const d = addDays(new Date(), SURVEY_DEFAULT_END_DAYS_FROM_NOW)
  d.setHours(SURVEY_DEFAULT_END_HOUR, SURVEY_DEFAULT_END_MINUTE, 0)
  return d
}

export const surveyMaxEndDate = (startDate: Date | string) => {
  const d = addDays(new Date(startDate), SURVEY_DEFAULT_MAX_PERIOD)
  d.setHours(SURVEY_DEFAULT_END_HOUR, SURVEY_DEFAULT_END_MINUTE, 0)
  return d
}

export const getCurrentTimezoneStandard = () => {
  // This library has an all-browser supported method of determining the standard timezone name.
  // Other methods, such as extracting from new Date().toString() do not return a standarized name.
  return jstz.determine().name()
}

export const getReadableTimezoneString = () => {
  // Finds timezone as the string between parens for a date like:
  // "Tue Dec 29 2020 17:07:13 GMT-0500 (Eastern Standard Time)"
  const dateStringMatch = new Date().toString().match(/\((.+)\)/)
  return dateStringMatch ? dateStringMatch[1] : jstz.determine().name()
}

export const getFormattedSurveyDate = ({
  startDate,
  endDate,
  includeDay = false,
  excludeComma = true,
  isTimeRange = false,
  useAbbreviation = false,
}: {
  startDate?: string | null
  endDate?: string | null
  includeDay?: boolean
  excludeComma?: boolean
  isTimeRange?: boolean
  useAbbreviation?: boolean
}) => {
  if (!endDate) return ''
  let dateFormat = 'MMM, yyyy'
  if (includeDay) {
    dateFormat = 'MMM d, yyyy'
  }
  if (excludeComma) {
    dateFormat = dateFormat.replace(/,/g, '')
  }
  if (isTimeRange && startDate) {
    if (useAbbreviation) {
      dateFormat = `MM/yy`
    }
    return `${format(new Date(startDate), dateFormat)} ${useAbbreviation ? '-' : 'to'} ${format(
      new Date(endDate),
      dateFormat,
    )}`
  }
  return format(new Date(endDate), dateFormat)
}
