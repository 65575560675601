import React from 'react'

import { getOperationName } from '@apollo/client/utilities'

import UpdateParticipant from 'components/Survey/Wizard/Steps/Participants/UpdateParticipant'
import {
  useSurveysUpdateParticipantMutation,
  ParticipantInput,
  SurveysParticipantsBySurveyDocument,
  SurveyProductTypeEnum,
  SurveysParticipantsBySurveyQuery,
  SurveyPhoneStatusErrorLevelEnum,
} from 'generated/graphql'
import { ERROR_CODES } from 'utils/apiErrorCodes'
import { Participants } from 'utils/types'

type Props = {
  surveyUuid: string
  missingPhonesErrorLevel: SurveyPhoneStatusErrorLevelEnum
  submitDisabledText?: string
  participant: null | Participants[0]
  onClose(): void
  surveyDataTypeOptions: SurveysParticipantsBySurveyQuery['survey']['dataTypeOptions']
  productType: SurveyProductTypeEnum
}

const UpdateParticipantContainer: React.FC<Props> = props => {
  const [updateParticipant, { loading }] = useSurveysUpdateParticipantMutation()
  return (
    <UpdateParticipant
      {...props}
      loading={loading}
      onSubmit={async (participant: ParticipantInput) => {
        const response = await updateParticipant({
          variables: {
            surveyUuid: props.surveyUuid,
            participantUuid: props.participant?.uuid,
            participant,
          },
          // In case this is an "Add" operation, refetch the last page of participants data.
          refetchQueries: [getOperationName(SurveysParticipantsBySurveyDocument) as string],
        })
        if (response.data?.updateParticipant?.errors?.length) {
          const errors = response.data?.updateParticipant?.errors as string[]
          return errors.map(e => ERROR_CODES[e] || e).join(', ')
        }
        props.onClose()
      }}
    />
  )
}

export default UpdateParticipantContainer
