import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

const style = {
  left: {
    float: 'left!important',
    display: 'block',
  },
  right: {
    margin: '0',
    fontSize: '14px',
    float: 'right!important',
    padding: '15px',
  },
  footer: {
    '@media print': {
      display: 'none',
    },
    bottom: '0',
    borderTop: '1px solid #e7e7e7',
    zIndex: 4,
  },
  container: {
    zIndex: 3,
    position: 'relative',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    '@media (min-width: 768px)': {
      width: '750px',
    },
    '@media (min-width: 992px)': {
      width: '970px',
    },
    '@media (min-width: 1200px)': {
      width: '1170px',
    },
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
  anchor: {
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
    },
  },
}

const Footer = ({ classes }) => {
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left} />
        <p className={classes.right}>
          <a href="http://www.activatedinsights.com" className={classes.anchor}>
            &copy; {1900 + new Date().getYear()} Activated Insights
          </a>
        </p>
      </div>
    </footer>
  )
}

export default withStyles(style)(Footer)
