import {
  card,
  cardHeader,
  defaultFont,
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  roseCardHeader,
  aquaCardHeader,
} from 'assets/jss/main'

const loginCardStyle = {
  card: {
    ...card,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    paddingBottom: '20px',
    transform: 'translate3d(0, 0, 0)',
    transition: 'all 300ms linear',
  },
  cardHeader: {
    ...cardHeader,
    ...defaultFont,
    textAlign: 'center',
    marginTop: '-40px',
    marginBottom: '20px',
  },
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  roseCardHeader,
  aquaCardHeader,
  cardTitle: {
    ...defaultFont,
    color: '#FFFFFF',
    marginTop: '10px',
    marginBottom: '10px',
    fontSize: '2rem',
    textTransform: 'uppercase',
  },
  cardActions: {
    padding: '0',
    display: 'block',
    height: 'auto',
  },
  cardContent: {
    padding: '0px 30px',
    position: 'relative',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  center: {
    textAlign: 'center',
  },
}

export default loginCardStyle
