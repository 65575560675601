import React from 'react'

import { Card, CardContent, Typography, makeStyles } from '@material-ui/core'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import icon from 'assets/img/no_closed_surveys.svg'
import { INSIGHTS as INSIGHTS_URL, URLS } from 'utils/constants'

const useStyles = makeStyles(() => ({
  card: {
    marginTop: 30,
    width: 300,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  title: {
    marginBottom: 10,
  },
  icon: {
    paddingTop: 50,
    paddingBottom: 50,
  },
}))

export enum PageEnum {
  INSIGHTS = 'insights',
  ACTION_PLANS = 'actionPlans',
  ACTION_PLANS_MANAGEMENT = 'actionPlansMgmt',
}

const NoClosedSurveys: React.FC<{ page: PageEnum }> = ({ page }) => {
  const classes = useStyles()
  let url = ''
  let title
  let message
  if (page === PageEnum.INSIGHTS) {
    url = INSIGHTS_URL
    title = 'Insights'
    message = 'Come back after your first survey closes to see your results!'
  } else if (page === PageEnum.ACTION_PLANS_MANAGEMENT) {
    url = URLS.ACTION_PLANS_MANAGEMENT
    title = 'Action Plans Management'
    message = 'Come back after your first survey closes to manage your action plans!'
  } else if (page === PageEnum.ACTION_PLANS) {
    url = URLS.ACTION_PLAN
    title = 'Action Plans'
    message = 'Come back after your first survey closes to create your action plan!'
  }
  return (
    <>
      <BreadcrumbsItem to={url}>{title}</BreadcrumbsItem>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" className={classes.title}>
            No closed surveys yet.
          </Typography>
          <img src={icon} alt="No Closed Surveys" className={classes.icon} />
          <Typography color="textSecondary">{message}</Typography>
        </CardContent>
      </Card>
    </>
  )
}

export default NoClosedSurveys
