import { createStyles, withStyles, InputBase } from '@material-ui/core'

const PlainSelectInput = withStyles(theme =>
  createStyles({
    input: {
      padding: 0,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      fontSize: '1.6rem',
    },
  }),
)(InputBase)

export default PlainSelectInput
