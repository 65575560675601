import React from 'react'

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/ErrorOutline'

import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import Table from 'components/Blocks/Table'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import { useStyles } from 'components/Survey/Wizard/Steps/Notifications/ResponseNotificationsContainer'
import {
  SurveysSurveyResponseNotificationsQuery,
  SurveyResponseNotificationTypeEnum,
  GroupSurveyResponseNotificationInput,
  GroupNode,
} from 'generated/graphql'
import { SURVEY_RESPONSE_NOTIFICATION_TYPES_ENUM } from 'utils/constants'

export const responseNotificationHasError = (notification: GroupSurveyResponseNotificationInput) =>
  notification.enabled && !notification.groupUuids.length

type NotificationInput = {
  enabled: boolean
  notificationType: SurveyResponseNotificationTypeEnum
  groupUuids: string[]
}

type Props = {
  survey: SurveysSurveyResponseNotificationsQuery['survey']
  groups: Pick<GroupNode, 'uuid' | 'name'>[]
  notificationsInput: NotificationInput[]
  setNotificationsInput(notificationsInput: NotificationInput[]): void
  error: string
}

const SurveyResponseNotifications: React.FC<Props> = ({
  survey,
  groups,
  notificationsInput,
  setNotificationsInput,
  error,
}) => {
  const classes = useStyles()

  const notificationTypes = Object.keys(
    SurveyResponseNotificationTypeEnum,
  ) as SurveyResponseNotificationTypeEnum[]

  return (
    <>
      <div className={classes.responseNotifications}>
        <TitleWithInfoTooltip
          title="Response Notifications"
          tooltip={
            <>
              <Typography>Individual Preferences:</Typography>
              <br />
              <Typography color="textSecondary">
                Once the survey is live group members can update their personal preferences, and
                even disable their notifications entirely.
                <br />
                Only the notification types you’ve selected here will be available to group members
                when updating their notification settings.
              </Typography>
            </>
          }
          padIcon={false}
        />
        <Typography color="textSecondary" variant="body1">
          New response notifications let you know how many responses have been collected in a
          specific time frame. Select the notifications that will work best for this survey and
          which group members should receive each type.
        </Typography>
      </div>
      {error && (
        <div className={classes.error}>
          <ErrorIcon />
          <Typography variant="body1">{error} </Typography>
        </div>
      )}
      <br />
      <Table
        pageSize={10}
        bicolor
        withCellsBorder={false}
        usePagination={false}
        columns={['Type', 'Who should receive?'].map(c => ({ label: c }))}
        totalRows={survey.groupsSurveyResponseNotifications.length}
        rows={notificationTypes.map(notificationType => {
          const notification = notificationsInput.find(
            n => n.notificationType === notificationType,
          )!
          return [
            {
              name: 'notificationType',
              value: (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(notification.enabled)}
                        className={notification.enabled ? classes.checked : classes.unchecked}
                        onClick={() => {
                          setNotificationsInput(
                            notificationsInput.map(n =>
                              n.notificationType === notificationType
                                ? { ...n, enabled: !n.enabled }
                                : n,
                            ),
                          )
                        }}
                      />
                    }
                    label={
                      <Typography>
                        {SURVEY_RESPONSE_NOTIFICATION_TYPES_ENUM[notificationType]}
                      </Typography>
                    }
                  />
                </div>
              ),
            },
            {
              name: 'groups',
              value: (
                <CheckboxDropdown
                  rootClassName={
                    error && responseNotificationHasError(notification) ? classes.selectError : ''
                  }
                  menuItems={groups.map(group => ({
                    value: group.uuid,
                    text: group.name,
                  }))}
                  selectedItems={notification.groupUuids || []}
                  onChange={(selected: string[]) => {
                    setNotificationsInput(
                      notificationsInput.map(n =>
                        n.notificationType === notificationType
                          ? {
                              ...n,
                              groupUuids: groups
                                .map(g => g.uuid)
                                .filter(uuid => selected.includes(uuid)),
                            }
                          : n,
                      ),
                    )
                  }}
                  width={210}
                  emptyLabel="Select Groups"
                  useTextDropdown
                  height={43}
                  containerClassName={classes.groups}
                />
              ),
            },
          ]
        })}
      />
    </>
  )
}

export default SurveyResponseNotifications
