import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import ReactDOMServer from 'react-dom/server'

import { colors } from 'shared/theme'

const styles = theme => ({
  container: {
    backgroundColor: '#FFF',
    color: '#000',
    display: 'block',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& >div': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  leftColumn: {
    display: 'inline-block',
    color: colors.navy65,
  },
  rightColumn: {
    display: 'inline-block',
    paddingLeft: 12,
    color: colors.navy,
  },
  divider: {
    borderBottom: `1px solid ${colors.navy25}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

/**
 * Renders groups of rows for an EchartsTooltip
 * Row groups are separated by a divider
 * Left column contains list of [label]
 * Right column contains a list [[value, color]]
 */
const EchartTooltip = ({ point, classes }) => {
  const { data } = point
  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        {data.overall && <div>Company Overall: </div>}
        <div>{point.seriesName}:</div>
      </div>
      <div className={classes.rightColumn}>
        {data.overall && (
          <div>
            {data.overall.value} {`(${data.overall.percent}% of responses)`}
          </div>
        )}
        <div>
          {data.value} {`(${data.percent}% of responses)`}
        </div>
      </div>
      <div className={classes.divider} />
      <div className={classes.leftColumn}>{data.name}</div>
    </div>
  )
}

EchartTooltip.propTypes = {
  point: PropTypes.shape({
    seriesName: PropTypes.string.isRequired,
    data: PropTypes.shape({
      percent: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      overall: PropTypes.shape({
        percent: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
}

const StyledTooltip = withStyles(styles)(EchartTooltip)

const tooltipBuilder = point => {
  // The argument for Echart tooltips is a static HTML string.
  return ReactDOMServer.renderToStaticMarkup(<StyledTooltip point={point} />)
}

export default tooltipBuilder
