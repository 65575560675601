import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import LockIcon from '@material-ui/icons/LockOutlined'
import cn from 'classnames'

import { ReactComponent as OpenSurveyIcon } from 'assets/img/create-open-survey-icon.svg'
import { ReactComponent as UploadParticipantsIcon } from 'assets/img/upload-participants-icon.svg'
import FormPanel from 'components/Blocks/Accordions/FormPanel'
import SelectableCard from 'components/Blocks/Cards/SelectableCard'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import { SurveyInput } from 'components/Survey/Wizard/Steps/Design/Design'
import { SurveyParticipantDataSourceEnum } from 'generated/graphql'
import { CONTACT_EMAIL } from 'utils/constants'

const useStyles = makeStyles(({ spacing, palette }) => ({
  disabledSection: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    '& >p': {
      width: '60%',
    },
  },
  disabledNotice: {
    display: 'flex',
    color: palette.common.danger,
    '& >svg': {
      marginLeft: -3,
      marginRight: spacing(),
    },
    '& >p>span': {
      color: palette.common.danger,
    },
    paddingBottom: spacing(2),
  },
  dataSource: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(3),
    '& >h6': {
      marginRight: spacing(),
    },
    '& svg': {
      marginBottom: -5,
      color: palette.common.navy65,
      '&:hover': {
        color: palette.common.navy,
      },
    },
  },
  disabledDataSource: {
    // Allows us to show a root level tooltip but disable pointer events in the children.
    '&:active': {
      pointerEvents: 'none',
    },
    '& >div': {
      pointerEvents: 'none',
      '& >p, >h6': {
        color: palette.common.navy65,
      },
    },
  },
  dataSourceCards: {
    display: 'flex',
    marginTop: spacing(3),
    marginBottom: spacing(3),
    '& >div': {
      width: '40%',
    },
    '& >div:first-child': {
      marginRight: spacing(2),
    },
  },
}))

type Props = {
  survey: SurveyInput
  updateSurvey(surveyAttrs?: Partial<SurveyInput>): void
}

const DataSource: React.FC<Props> = ({ survey, updateSurvey }) => {
  const classes = useStyles()

  const disableSection = Boolean(survey.uuid)

  return (
    <FormPanel gutterTop gutterHeader={false}>
      <div
        className={cn({
          [classes.disabledDataSource]: disableSection,
        })}
      >
        {disableSection && (
          <div className={classes.disabledNotice}>
            <LockIcon />
            <Typography>
              <span>
                This section is now locked. In order to make edits you'll need to delete this survey
                draft and start over.
              </span>
            </Typography>
          </div>
        )}
        <TitleWithInfoTooltip
          title="Data Source"
          tooltip={
            <>
              <Typography>Survey Participants:</Typography>
              <Typography color="textSecondary">
                Participant contact information will be uploaded directly to a survey. The
                participants you upload will receive the survey based on the notification schedule.
              </Typography>
              <br />
              <Typography>Contacts Database:</Typography>
              <Typography color="textSecondary">
                Contacts will be stored in a centralized organization-wide database either through
                upload or vendor integration. Contacts will receive surveys based on survey logic.
              </Typography>
            </>
          }
          padIcon={false}
        />
        <>
          <Typography color="textSecondary" variant="body2">
            Where should we pull participant data from for this survey?*
          </Typography>
          <div className={classes.dataSourceCards}>
            <SelectableCard
              id="upload-participants"
              disabled={disableSection}
              selected={
                survey.participantDataSource ===
                SurveyParticipantDataSourceEnum.DIRECT_ROSTER_IMPORT
              }
              onSelect={() =>
                !survey.uuid &&
                updateSurvey({
                  participantDataSource: SurveyParticipantDataSourceEnum.DIRECT_ROSTER_IMPORT,
                })
              }
              Icon={UploadParticipantsIcon}
              title="Survey Participants"
              body="Participant contact information will be uploaded directly to a survey. The participants you upload will receive the survey based on the notification schedule.

              "
            />
            <SelectableCard
              id="create-open-link"
              disabled={disableSection}
              selected={
                survey.participantDataSource === SurveyParticipantDataSourceEnum.SAMPLE_POOL
              }
              onSelect={() => {
                updateSurvey({
                  participantDataSource: SurveyParticipantDataSourceEnum.SAMPLE_POOL,
                })
              }}
              Icon={OpenSurveyIcon}
              title="Contacts Database"
              body="Contacts will be stored in a centralized organization-wide database either through upload or vendor integration. Contacts will receive surveys based on survey logic."
            />
          </div>
          <Typography color="textSecondary" variant="body2">
            Need help deciding?{' '}
            <a href={`mailto:${CONTACT_EMAIL}`}>&nbsp;Contact our Support Team</a>
          </Typography>
        </>
      </div>
    </FormPanel>
  )
}

export default DataSource
