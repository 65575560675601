import React from 'react'

import { makeStyles, AppBar, Dialog, IconButton, Typography, Toolbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { SurveysSurveyQuery } from 'generated/graphql'

const useStyles = makeStyles(({ palette }) => ({
  title: {
    flex: '1 1 auto',
    color: palette.common.white,
  },
  iframe: {
    position: 'relative',
    top: 69,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '93%',
    border: 'none',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    zIndex: 9,
  },
}))

type Props = {
  survey: SurveysSurveyQuery['survey']
  onClose(): void
}

const SurveyPreview: React.FC<Props> = ({ survey, onClose }) => {
  const classes = useStyles()

  return (
    <Dialog fullScreen open onClose={onClose}>
      <AppBar color="secondary">
        <Toolbar>
          <Typography className={classes.title} variant="h5">
            Survey Preview
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <iframe
        id="surveyPreviewIframe"
        title="Survey Preview"
        className={classes.iframe}
        src={survey.previewUrl}
      />
    </Dialog>
  )
}

export default SurveyPreview
