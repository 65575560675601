import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import TimelineIcon from '@material-ui/icons/Timeline'

import goalStickerDataUrl from 'assets/img/goal-sticker-base64'
import { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import { ResponsesByDateType, SurveyStatusEnum } from 'generated/graphql'
import { colors } from 'shared/theme'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    padding: spacing(2),
    fontSize: 16,
  },
  scheduledSurvey: {
    textAlign: 'center',
  },
  timelineIcon: {
    width: 60,
    height: 60,
    color: palette.common.secondary,
  },
  description: {
    marginLeft: '15%',
    marginRight: '15%',
  },
}))

type Props = {
  goal?: null | number
  responseData: Array<ResponsesByDateType>
  surveyStatus: SurveyStatusEnum
}

export const transformResponseData = (
  responseData: Array<ResponsesByDateType>,
  splitNumber: number,
) => {
  let splitIndex = 0
  let sumResponses = 0
  // If the number does not divide evenly, use the max for each section and make the last section short
  const numPointsPerSection = Math.ceil((responseData.length - 1) / splitNumber)
  const chartData: Array<{ value: number[]; symbolSize: number }> = []
  responseData.forEach((result, index) => {
    sumResponses += result.responses
    // Only render a point on the split line or the last date
    if (!(index === responseData.length - 1 || index % numPointsPerSection === 0)) {
      return
    }
    chartData.push({
      value: [splitIndex, sumResponses],
      symbolSize: 6,
    })
    splitIndex += 1
  })
  return { chartData, numPointsPerSection }
}

const ResponsesOverTimeChart: React.FC<Props> = ({ goal, responseData, surveyStatus }) => {
  const classes = useStyles()

  const splitNumber = 4
  const { chartData, numPointsPerSection } = transformResponseData(responseData, splitNumber)
  const lastSplitPoint = responseData.length < splitNumber ? responseData.length : splitNumber
  const goalLine = {
    type: 'line',
    markPoint: {
      data: goal ? [{ xAxis: lastSplitPoint, yAxis: goal }] : [],
      symbolSize: [50, 15],
      symbolKeepAspect: true,
      symbolOffset: ['50%', 0],
      symbol: `image://${goalStickerDataUrl}`,
    },
    markLine: {
      symbolSize: 0,
      data: goal
        ? [
            {
              yAxis: goal,
              lineStyle: { type: 'solid', color: '#FF9800', width: 1 },
              label: { show: false },
            },
          ]
        : [],
    },
  }
  return (
    <div className={classes.root}>
      <Typography variant="h6">Responses Over Time</Typography>
      {surveyStatus === SurveyStatusEnum.SCHEDULED ? (
        <div className={classes.scheduledSurvey}>
          <TimelineIcon className={classes.timelineIcon} />
          <Typography color="textSecondary" className={classes.description}>
            Once the survey is live you will be able to track your response rate over time
          </Typography>
        </div>
      ) : (
        <PrintableEchart
          notMerge
          printWidth={300}
          style={{ width: '100%', height: 180 }}
          option={{
            grid: { top: 15, bottom: 50, left: 100, right: '15%' },
            textStyle: chartTextStyle,
            xAxis: {
              type: 'value',
              splitNumber: lastSplitPoint - 1 || 1, // we need a 1 when there's actually a 0,  because otherwise it shows 4 vertical bars for the same day
              axisLabel: {
                formatter: (_: number, index: number) => {
                  let dateIndex = index * numPointsPerSection
                  // On the last split index, use the last date index, otherwise we may go out of bounds.
                  if (index >= splitNumber - 1) {
                    dateIndex = responseData.length - 1
                  }
                  const date = new Date(responseData[dateIndex].date)
                  return `${date.getMonth() + 1}-${date.getUTCDate()}`
                },
              },
              axisTick: { show: false },
              splitLine: { lineStyle: { type: 'dashed' } },
            },
            yAxis: {
              type: 'value',
              boundaryGap: false,
              splitNumber: 2,
              axisTick: { show: false },
              splitLine: { lineStyle: { type: 'dashed' } },
            },
            series: [
              {
                data: chartData,
                type: 'line',
              },
              goalLine,
            ],
            color: [colors.success],
          }}
        />
      )}
    </div>
  )
}

export default ResponsesOverTimeChart
