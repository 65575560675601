import React from 'react'

import { createStyles, makeStyles, Typography, Paper, Grid } from '@material-ui/core'

import { ImportSummaryType } from 'generated/graphql'
import { pluralize } from 'utils'

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    container: {
      marginTop: spacing(3),
    },
    header: {
      padding: spacing(3),
      backgroundColor: palette.common.success,
      '& >p, &>h6': {
        color: palette.common.white,
      },
    },
    summaryData: {
      padding: spacing(3),
      '& >div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  }),
)

type Props = { summary: ImportSummaryType; isUpdating: boolean }
const ParticipantSummary: React.FC<Props> = ({ summary, isUpdating }) => {
  const classes = useStyles()
  if (!summary) return null
  return (
    <div className={classes.container}>
      <Paper className={classes.header}>
        <Typography variant="h6">Participants Ready to Import!</Typography>
        <Typography>
          We’re ready to {isUpdating ? 'update' : ' create'} your participants and add them to this
          survey, but before we do, please make sure the summary looks correct. If you see any
          issues, please correct them in your roster file and reupload it. If everything looks good,
          go ahead and {isUpdating ? 'update' : ' create'} participants!
        </Typography>
      </Paper>
      <Paper className={classes.summaryData}>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="h6">Summary</Typography>
          </Grid>
          <Grid item xs={9}>
            {summary.filters &&
              summary.filters.map(filter => {
                if (!filter || !filter.filterTypeName || !filter.filterValueNames) return null
                return (
                  <div key={filter.filterTypeName}>
                    <Typography variant="body2" color="textSecondary">
                      {`${filter.filterValueNames.length} new
                  ${pluralize(
                    filter.filterTypeName.toLowerCase(),
                    filter.filterValueNames.length,
                  )}`}
                    </Typography>
                  </div>
                )
              })}
            {summary.added && summary.added > 0 ? (
              <Typography variant="body2" color="textSecondary">
                {`${summary.added} new ${pluralize('participant', summary.added)} `}
              </Typography>
            ) : null}
            {summary.updated && summary.updated > 0 ? (
              <Typography variant="body2" color="textSecondary">
                {`${summary.updated} updated ${pluralize('participant', summary.updated)} `}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default ParticipantSummary
