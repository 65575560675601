import React, { useState } from 'react'

import { Tooltip, IconButton, InputAdornment } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { DateTimePicker } from '@material-ui/pickers'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Table from 'components/Blocks/Table'
import AddScheduledNotificationDialog, {
  isValidScheduledNotificationTime,
  getNotificationTimeError,
} from 'components/Survey/Wizard/Steps/Notifications/AddScheduledNotificationDialog'
import {
  useStyles,
  getColumns,
  getNotificationRow,
  NotificationInput,
  Notification,
} from 'components/Survey/Wizard/Steps/Notifications/common'
import NewNotificationButton from 'components/Survey/Wizard/Steps/Notifications/NewNotificationButton'
import UpdateNotificationDialog from 'components/Survey/Wizard/Steps/Notifications/UpdateNotificationDialog'
import { gaEvent } from 'config/ga'
import {
  useSurveysSurveyNotificationsQuery,
  useSurveysResetScheduledNotificationMutation,
  useSurveysUpdateScheduledNotificationMutation,
  useSurveysSendScheduledTestEmailMutation,
  useSurveysSendScheduledTestSmsMutation,
  SurveysScheduledNotificationFragment,
  ScheduledNotificationInput,
  SurveysSurveyQuery,
  SurveyInviteMethodEnum,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'
import { handleMutationResponse } from 'utils'
import { getReadableTimezoneString, formatDateAsString } from 'utils/dateUtils'

const getScheduledNotificationRow = (
  classes: ReturnType<typeof useStyles>,
  productType: SurveyProductTypeEnum,
  surveyStartDate: Date,
  maxScheduledNotificationDate: Date | null,
  notification: SurveysScheduledNotificationFragment,
  surveyMaxSmsNotifications: number,
  setNotificationToUpdate: (notification: Notification) => void,
  setNotificationToDeactivate: (notification: Notification) => void,
  onUpdate: (n: ScheduledNotificationInput) => void,
  disabledDates: string[],
  disabledNewNotifications?: boolean,
  disabledNewSmsNotifications?: boolean,
) => {
  const now = new Date()
  let minDate: null | Date = null
  let maxDate: null | Date = null
  if (notification.active) {
    minDate = now > surveyStartDate ? now : surveyStartDate
    if (maxScheduledNotificationDate) {
      maxDate = now > maxScheduledNotificationDate ? now : maxScheduledNotificationDate
    }
  }
  return [
    {
      name: 'when',
      value: (
        <>
          <DateTimePicker
            format="E – LLL dd, h:mm a"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton className={classes.greyEditIcon}>
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled={!notification.active}
            minDateMessage=""
            maxDateMessage=""
            minDate={minDate}
            maxDate={maxDate}
            value={notification.when}
            shouldDisableDate={(date: Date | null) => {
              if (!date) return false
              return disabledDates.includes(formatDateAsString(date))
            }}
            onChange={(when: Date | null) => {
              if (!when) return
              if (isValidScheduledNotificationTime(when)) {
                onUpdate({ uuid: notification.uuid, when: when.toISOString() })
              } else {
                emitter.emit('ERROR', getNotificationTimeError())
              }
            }}
          />
          {notification.errors && notification.active && !notification.firstSentTimestamp && (
            <Tooltip title={notification.errors}>
              <InfoIcon className={classes.tooltipIcon} />
            </Tooltip>
          )}
        </>
      ),
    },
    ...getNotificationRow(classes, {
      productType,
      notification,
      surveyMaxSmsNotifications,
      setNotificationToUpdate,
      setNotificationToDeactivate,
      handleSubmitInterval: onUpdate,
      disabledNewNotifications,
      disabledNewSmsNotifications,
    }),
  ]
}

type Props = {
  classes: ReturnType<typeof useStyles>
  survey: SurveysSurveyQuery['survey']
  maxNotifications: number
}

const ScheduledNotifications: React.FC<Props> = ({ classes, survey, maxNotifications }) => {
  const [showAddDialog, setShowAddDialog] = useState(false)
  const surveyStartDate = new Date(survey.startDate)
  const maxScheduledNotificationDate = survey.maxScheduledNotificationDate
    ? new Date(survey.maxScheduledNotificationDate)
    : null
  const surveyNotificationsResult = useSurveysSurveyNotificationsQuery({
    variables: { surveyUuid: survey.uuid },
  })
  const [notificationToUpdate, setNotificationToUpdate] = useState<null | Notification>(null)
  const [notificationToDeactivate, setNotificationToDeactivate] = useState<null | Notification>(
    null,
  )

  const [updateScheduledNotification, { loading }] = useSurveysUpdateScheduledNotificationMutation()
  const [resetScheduledNotification] = useSurveysResetScheduledNotificationMutation()
  const [sendTestEmail] = useSurveysSendScheduledTestEmailMutation()
  const [sendTestSms] = useSurveysSendScheduledTestSmsMutation()

  const sendEmail = async (emailAddress: string) => {
    if (!notificationToUpdate) {
      return
    }
    const variables = {
      notificationUuid: notificationToUpdate.uuid,
      emailAddress,
    }
    await sendTestEmail({ variables })
    emitter.emit('SUCCESS', `Test email has been sent successfully.`)
  }

  const sendSms = async (smsNumber: string) => {
    if (!notificationToUpdate) {
      return
    }
    const variables = {
      notificationUuid: notificationToUpdate.uuid,
      smsNumber,
    }
    await sendTestSms({ variables })
    emitter.emit('SUCCESS', `Test SMS has been sent successfully.`)
  }

  const onUpdate = async (notification: NotificationInput) => {
    const result = await updateScheduledNotification({
      variables: {
        surveyUuid: survey.uuid,
        notificationInput: {
          uuid: notificationToUpdate?.uuid,
          ...notification,
        },
      },
    })
    handleMutationResponse(result.data?.updateScheduledNotification?.errors)
  }

  const onReset = async (emailOrSms: 'email' | 'sms') => {
    if (!notificationToUpdate) {
      return
    }
    const result = await resetScheduledNotification({
      variables: {
        notificationUuid: notificationToUpdate.uuid,
        emailOrSms,
      },
    })
    if (result.data?.resetScheduledNotification) {
      setNotificationToUpdate(result.data.resetScheduledNotification.notification)
    }
  }
  return (
    <ResponseHandler {...surveyNotificationsResult}>
      {({ survey: { scheduledNotifications } }) => {
        const disabledDates = scheduledNotifications.map(n => formatDateAsString(new Date(n.when)))
        const disabledNewNotifications =
          scheduledNotifications.filter(n => n.active).length >= maxNotifications
        const disabledNewSmsNotifications =
          scheduledNotifications.filter(n => n.active && n.sms).length >= survey.maxSmsNotifications
        return (
          <FormPanel
            expandIcon={EXPAND_ICON.ARROW}
            headerEnd
            gutterHeader={false}
            gutterBottom
            title="Digital Schedule Notifications"
            helpText={
              <>
                <span>
                  Participants are more likely to complete a survey when you send reminders. You can
                  add more at any time until the survey closes with a max of 1 notification per day,{' '}
                  {survey.maxSmsNotifications} SMS and {maxNotifications} email notifications total.
                </span>
                <i> All times are in {getReadableTimezoneString()}.</i>
              </>
            }
            actions={
              survey.inviteMethod === SurveyInviteMethodEnum.PHONE ? (
                <></>
              ) : (
                <NewNotificationButton
                  disabled={disabledNewNotifications}
                  onClick={() => setShowAddDialog(true)}
                />
              )
            }
          >
            <Table
              pageSize={40}
              bicolor
              withCellsBorder={false}
              usePagination={false}
              columns={getColumns(survey.productType).map(c => ({ label: c }))}
              totalRows={scheduledNotifications.length}
              rows={scheduledNotifications.map(notification =>
                getScheduledNotificationRow(
                  classes,
                  survey.productType,
                  surveyStartDate,
                  maxScheduledNotificationDate,
                  notification,
                  survey.maxSmsNotifications,
                  setNotificationToUpdate,
                  setNotificationToDeactivate,
                  onUpdate,
                  disabledDates.filter(d => d !== formatDateAsString(new Date(notification.when))),
                  disabledNewNotifications,
                  disabledNewSmsNotifications,
                ),
              )}
            />
            {notificationToDeactivate && (
              <ActionDialog
                title="Deactivate Notification?"
                content="Are you sure you want to deactivate notification? This reminder will no longer be sent out."
                submitButtonText="Deactivate"
                onClose={() => setNotificationToDeactivate(null)}
                onSubmit={() => {
                  const attrs = { uuid: notificationToDeactivate.uuid, active: false }
                  onUpdate(attrs)
                  setNotificationToDeactivate(null)
                }}
              />
            )}
            {showAddDialog && (
              <AddScheduledNotificationDialog
                surveyUuid={survey.uuid}
                productType={survey.productType}
                surveyStartDate={surveyStartDate}
                maxScheduledNotificationDate={maxScheduledNotificationDate}
                onClose={() => setShowAddDialog(false)}
                disabledDates={disabledDates}
                disabledNewSmsNotifications={disabledNewSmsNotifications}
                surveyMaxSmsNotifications={survey.maxSmsNotifications}
              />
            )}
            {notificationToUpdate && (
              <UpdateNotificationDialog
                survey={survey}
                notificationToUpdate={notificationToUpdate}
                isSubmitting={loading}
                onClose={() => setNotificationToUpdate(null)}
                onUpdate={args => {
                  gaEvent({
                    action: 'editScheduledNotificationText',
                    category: 'Surveys',
                  })
                  onUpdate(args)
                }}
                sendTestEmail={args => {
                  gaEvent({
                    action: 'sendTestEmailScheduledNotification',
                    category: 'Surveys',
                  })
                  sendEmail(args)
                }}
                sendTestSms={args => {
                  gaEvent({
                    action: 'sendTestSmsScheduledNotification',
                    category: 'Surveys',
                  })
                  sendSms(args)
                }}
                onReset={onReset}
              />
            )}
          </FormPanel>
        )
      }}
    </ResponseHandler>
  )
}

export default ScheduledNotifications
