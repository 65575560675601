import React from 'react'

import throttle from 'lodash/throttle'

import emitter from 'shared/authenticated/emitter'

/**
 * Some components do not respond to the @media print query.
 * PrintableComponents watches for print events and resizes based on supplied prop functions.
 */
export default class PrintableComponent extends React.Component {
  constructor(props) {
    super(props)

    // Throttle the event listeners to avoid resizing when the browser sends multiple events or when we need
    // to manually resize before calling window.print (window.print will fire a second before_print event)
    this.beforePrintSubcription = emitter.addListener(
      'BEFORE_PRINT',
      throttle(props.beforePrint, 5000, { leading: true, trailing: false }),
    )
    this.afterPrintSubcription = emitter.addListener(
      'AFTER_PRINT',
      // Wait for print dialog to finish closing before reverting to original size.
      throttle(() => requestAnimationFrame(props.afterPrint), 5000, {
        leading: true,
        trailing: false,
      }),
    )
  }

  componentWillUnmount() {
    this.beforePrintSubcription.remove()
    this.afterPrintSubcription.remove()
  }

  render() {
    return this.props.children
  }
}
