import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import TimeframeRecommendationScoreCard from 'components/Insights/TimeframeTimeTrending/TimeframeRecommendationScoreCard'
import {
  timeRangeDescription,
  ChartTimeRange,
} from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import StandardTooltip from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import {
  Chart as TTChart,
  ChartType as TTChartType,
} from 'components/Insights/TimeTrending/TTIndexScoreResponseCard'
import { ScoreTypeGroups } from 'components/Insights/TimeTrending/utils'
import { useInsightsTimeTrendingCompletionRateQuery } from 'generated/graphql'
import DynamicTimeframeQuery from 'HOC/DynamicTimeframeQuery'
import { getFormattedSurveyDate } from 'utils/dateUtils'

const useStyles = makeStyles(() => ({
  chartRow: {
    display: 'flex',
  },
}))

type Props = {
  survey: InsightsSurvey
  filters: string[]
  timeRanges: ChartTimeRange[]
}
const TimeframeCompletionRateCard: React.FC<Props> = ({ survey, filters, timeRanges }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <div id="timeframe-completion-rate-snap">
      <SnapshotChartHeader
        title="Total Responses Over Time"
        tooltip="See how the number of responses for each time period compare in the chart. To the left, you can see the total number of responses collected during the time frame."
        snapId="timeframe-completion-rate-snap"
        useBottomPadding={false}
        description={timeRangeDescription(timeRanges)}
      />
      <DynamicTimeframeQuery
        timeRanges={timeRanges}
        variables={{
          filters,
          surveyUuid: survey.uuid,
        }}
        queryHook={useInsightsTimeTrendingCompletionRateQuery}
      >
        {data => {
          const scoreTypeGroups: ScoreTypeGroups = [
            data.map(({ survey: ttSurvey }, surveyIndex) => [
              surveyIndex,
              Math.round(ttSurvey.insightsSurvey.completionRate.started),
            ]),
          ]
          const totalResponses = scoreTypeGroups[0].reduce((sum, score) => sum + (score[1] || 0), 0)
          return (
            <div className={classes.chartRow}>
              <div className={classes.timeTrendingInfoBox}>
                <div className={classes.percentageText}>
                  <Typography>{totalResponses}</Typography>
                </div>
                <Typography variant="body2">TOTAL RESPONSES</Typography>
                <br />
                <Typography variant="body2" color="textSecondary">
                  From{' '}
                  {getFormattedSurveyDate({
                    startDate: timeRanges[0].startDate,
                    endDate: timeRanges[TimeframeRecommendationScoreCard.length - 1].endDate,
                    includeDay: true,
                    isTimeRange: true,
                  })}{' '}
                  your total number of responses was {totalResponses}.
                </Typography>
              </div>
              <TTChart
                scoreTypeGroups={scoreTypeGroups}
                xAxisLabelData={timeRanges}
                chartType={TTChartType.RESPONSE_RATE}
                legendNames={['Number of Responses']}
                tooltipFormatter={series => {
                  const currentScore = scoreTypeGroups[0][series.dataIndex][1]
                  return renderEchartTooltip(
                    <StandardTooltip
                      title={getFormattedSurveyDate({
                        ...timeRanges[series.dataIndex],
                        includeDay: true,
                        isTimeRange: true,
                      })}
                      rows={[
                        {
                          label: 'Responses:',
                          value: String(currentScore),
                          detail: series.dataIndex > 0 && (
                            <ScoreChangeArrow
                              delta={currentScore! - scoreTypeGroups[0][series.dataIndex - 1][1]!}
                              isPercentage={false}
                            />
                          ),
                        },
                      ]}
                    />,
                  )
                }}
              />
            </div>
          )
        }}
      </DynamicTimeframeQuery>
    </div>
  )
}

export default TimeframeCompletionRateCard
