// the following will fix react app for IE
import 'utils/polyfills'
import React from 'react'

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import { render } from 'react-dom'
import { Router } from 'react-router-dom'

import { SENTRY_DSN, RELEASE_VERSION, LOGROCKET_APP_ID } from 'config/config'
import initClient from 'config/configureClient'
import history from 'config/history'
import AppContainer from 'containers/AppContainer'
import 'assets/scss/main.css'
import IS_ENABLED_LOGROCKET from 'utils/logrocket'

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: RELEASE_VERSION,
    dist: 'walle',
    ignoreErrors: ['o.match is not a function'],
    beforeBreadcrumb: breadcrumb => {
      if (breadcrumb.category === 'xhr' && breadcrumb?.data?.url) {
        // Throw away XHR breadcrumbs that are related to:
        // - GraphQL fetches (we're logging breadcrumbs separately with the apollo-link library)
        // - Requests to google analytics; these are never useful for debugging.
        const url = new URL(breadcrumb?.data?.url)
        if (url.pathname === '/graphql/' || url.host === 'www.google-analytics.com') {
          return null
        }
      }
      return breadcrumb
    },
  })
  window.Sentry = Sentry
}

if (IS_ENABLED_LOGROCKET) {
  LogRocket.init(LOGROCKET_APP_ID)
}

// Hot Module strategy from: https://daveceddia.com/hot-reloading-create-react-app/

const renderApp = Component => {
  initClient().then(client => {
    render(
      <ApolloProvider client={client}>
        <Router history={history}>
          <Component />
        </Router>
      </ApolloProvider>,
      document.getElementById('root'),
    )
  })
}
renderApp(AppContainer)

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./containers/AppContainer', () => {
    renderApp(AppContainer)
  })
}
