import React, { useState } from 'react'

import { makeStyles, Typography, MenuItem, ListItemIcon } from '@material-ui/core'
import TableIcon from '@material-ui/icons/Menu'

import { ReactComponent as AnalyticsIcon } from 'assets/img/action_plans/analytics-icon.svg'
import { transformSingleIndexDataToGroupedSurveyScores } from 'components/Blocks/Charts/ScatterPlotContainer'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsBenchmark, InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  ChartTimeRange,
  timeRangeDescription,
} from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import {
  groupResponseRates,
  Highlights as TimeTrendingHighlights,
} from 'components/Insights/TimeTrending/TTDetailedBreakdownCard'
import TTGroupedScoresChart from 'components/Insights/TimeTrending/TTGroupedScoresChart'
import TTTableView from 'components/Insights/TimeTrending/TTTableView'
import {
  getOrderedGroupNames,
  getOverallScoreGroups,
  transformGroupedScoresToEcharts,
  groupScoresByLabel,
} from 'components/Insights/TimeTrending/utils'
import { gaEvent } from 'config/ga'
import {
  useInsightsTimeTrendingScoresByDataTypeQuery,
  FilterTypeFragment,
  BenchmarkCodeType,
} from 'generated/graphql'
import DynamicTimeframeQuery from 'HOC/DynamicTimeframeQuery'
import { SORT_OPTIONS } from 'utils/constants'
import { getFormattedSurveyDate } from 'utils/dateUtils'

const useStyles = makeStyles(({ spacing, palette }) => ({
  chart: {
    marginTop: spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropdownRow: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: -8,
  },
  viewTypeIcon: {
    color: palette.common.secondary,
    '& >g>path': {
      fill: palette.common.secondary,
    },
    position: 'relative',
    top: 2,
  },
  table: {
    marginTop: -spacing(5),
  },
}))

enum CardViewType {
  CHART = 'Line Chart',
  TABLE = 'Table View',
}
type Props = {
  survey: InsightsSurvey
  filters: string[]
  benchmark: InsightsBenchmark
  visibleFilterTypes: FilterTypeFragment[]
  timeRanges: ChartTimeRange[]
  statementCodes?: BenchmarkCodeType[]
  scoreTitle?: string
  tooltipText?: string
  scoreName: 'Core Q' | 'Recommendation Score'
}
const TimeframeDetailedBreakdownCard: React.FC<Props> = ({
  survey,
  filters,
  benchmark,
  visibleFilterTypes,
  timeRanges,
  statementCodes,
  scoreTitle,
  tooltipText,
  scoreName,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [selectedFilter, setSelectedFilter] = useState(visibleFilterTypes[0])
  const [selectedViewType, setSelectedViewType] = useState(CardViewType.CHART)
  const viewTypeToIcon = {
    [CardViewType.CHART]: <AnalyticsIcon className={classes.viewTypeIcon} />,
    [CardViewType.TABLE]: <TableIcon className={classes.viewTypeIcon} />,
  }
  return (
    <div className={classes.fullRow} id="timeframe-detailed-breakdown-snap">
      <SnapshotChartHeader
        title={`${scoreTitle || 'RECOMMENDATION SCORE'} BY ${selectedFilter.name} OVER TIME`}
        tooltip={
          tooltipText ||
          `See how your recommendation score compares for each ${selectedFilter.name}, by time period within the selected time frame.`
        }
        snapId="timeframe-detailed-breakdown-snap"
        useBottomPadding={false}
        description={timeRangeDescription(timeRanges)}
        extraControls={
          <div className={classes.dropdownRow}>
            {visibleFilterTypes.length > 1 && (
              <TextDropdown
                displayEmpty
                value={selectedFilter.dtCode}
                renderValue={filterCode => {
                  return visibleFilterTypes.find(({ dtCode }) => dtCode === filterCode)?.name
                }}
                onChange={e => {
                  const newFilter = visibleFilterTypes.find(
                    ft => ft.dtCode === (e.target.value as string),
                  )
                  if (newFilter) {
                    setSelectedFilter(newFilter)
                  }
                }}
                label={
                  <Typography variant="body2" color="textSecondary">
                    Show:&nbsp;
                  </Typography>
                }
              >
                {visibleFilterTypes.map(ft => {
                  return (
                    <MenuItem key={ft.dtCode} value={ft.dtCode}>
                      <Typography>{ft.name}</Typography>
                    </MenuItem>
                  )
                })}
              </TextDropdown>
            )}
            <TextDropdown
              value={selectedViewType}
              renderValue={viewType => viewTypeToIcon[viewType as CardViewType]}
              onChange={e => {
                gaEvent({
                  action: 'selectedTimeTrendingTimeframeTableView',
                  category: 'Insights',
                })
                setSelectedViewType(e.target.value as CardViewType)
              }}
              id="scatter-table-select"
            >
              {Object.values(CardViewType).map(viewType => {
                return (
                  <MenuItem key={viewType} value={viewType}>
                    <ListItemIcon>{viewTypeToIcon[viewType]}</ListItemIcon>
                    <Typography>{viewType}</Typography>
                  </MenuItem>
                )
              })}
            </TextDropdown>
          </div>
        }
      />
      <DynamicTimeframeQuery
        timeRanges={timeRanges}
        variables={{
          filters,
          dtCode: selectedFilter.dtCode,
          benchmark,
          surveyUuid: survey.uuid,
          statementCodes,
        }}
        queryHook={useInsightsTimeTrendingScoresByDataTypeQuery}
      >
        {(data, loading) => {
          const surveyScoresByGroup = groupScoresByLabel(data, 'insightsScoresByDataType')
          const orderedGroupNames = getOrderedGroupNames(
            Object.keys(surveyScoresByGroup),
            surveyScoresByGroup,
            SORT_OPTIONS.A_TO_Z,
            survey.uuid,
          )
          const responseRateByGroup = groupResponseRates(data)
          const filterValueScoreGroups = transformGroupedScoresToEcharts(
            orderedGroupNames,
            surveyScoresByGroup,
            data.map(({ uuid }) => uuid),
          )
          const companyOverallScoreGroups = getOverallScoreGroups(data)
          if (selectedViewType === CardViewType.TABLE) {
            return (
              <div className={classes.table}>
                <TTTableView
                  data={transformSingleIndexDataToGroupedSurveyScores(
                    data,
                    timeRanges.map(tr => tr.uuid),
                  )}
                  scoreName={scoreName}
                  level1FilterType={selectedFilter}
                  level2FilterType={selectedFilter}
                  firstSurveyDate={getFormattedSurveyDate({
                    endDate: timeRanges[0].endDate,
                  })}
                  secondSurveyDate={getFormattedSurveyDate({
                    endDate: timeRanges[timeRanges.length - 1].endDate,
                  })}
                  companyOverallPositive={30}
                  minRows={orderedGroupNames.length}
                />
              </div>
            )
          }
          return (
            <>
              <div className={classes.chart}>
                <TTGroupedScoresChart
                  xAxisLabelData={timeRanges}
                  groupedScores={filterValueScoreGroups}
                  benchmarkName={benchmark.name}
                  companyOverallScores={companyOverallScoreGroups[0]}
                  minShowableResults={survey.minShowableResults}
                  title={String(selectedFilter.namePlural)}
                  didSelectFilters={!loading && filters.length > 0}
                  surveyScoresByGroup={surveyScoresByGroup}
                  orderedGroupNames={orderedGroupNames}
                />
              </div>
              <TimeTrendingHighlights
                surveyScoresByGroup={surveyScoresByGroup}
                responseRateByGroup={responseRateByGroup}
                filterTypePlural={selectedFilter.namePlural || ''}
                scoreName="Recommendation Score"
              />
            </>
          )
        }}
      </DynamicTimeframeQuery>
    </div>
  )
}

export default TimeframeDetailedBreakdownCard
