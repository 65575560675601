import React, { useState, useRef, MouseEvent as ReactMouseEvent } from 'react'

import { useApolloClient } from '@apollo/client'
import { makeStyles, Typography } from '@material-ui/core'
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined'
import cn from 'classnames'
import { MentionsInput, Mention } from 'react-mentions'
import { NavLink } from 'react-router-dom'

import EmojiPicker from 'components/Blocks/EmojiPicker'
import {
  useActionPlansUpdateActionItemTaskCommentMutation,
  ActionPlansActionPlanDocument,
  ActionPlansActionPlanQuery,
  UserCommentMentionInput,
} from 'generated/graphql'
import { cleanNewLines } from 'utils'
import { emojiRegex } from 'utils/constants'
import { getCurrentTimezoneStandard } from 'utils/dateUtils'

const OPEN = '{{'
const CLOSE = '}}'

type User = {
  id: string
  name: string
}

type Mention = {
  index: number
  user: User
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    height: 80,
    '& >div>ul': {
      borderTop: `1px solid ${palette.common.navy15}`,
      maxHeight: 160,
      overflow: 'scroll',
    },
  },
  postContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    backgroundColor: '#FFF',
    border: `1px solid ${palette.common.navy}`,
    borderRadius: 4,
    padding: spacing(),
  },
  mentionsInput: {
    minHeight: 24,
    width: '100%',
    '& textarea': {
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  mentionsInputEmoji: {
    '& textarea': {
      marginTop: -5,
    },
  },
  userSuggestion: {
    height: 30,
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    borderLeft: `1px solid ${palette.common.navy15}`,
    borderRight: `1px solid ${palette.common.navy15}`,
    borderBottom: `1px solid ${palette.common.navy15}`,
    '&:hover': {
      backgroundColor: '#daf4fa',
      cursor: 'pointer',
    },
  },
  userMention: {
    backgroundColor: palette.common.secondary50,
  },
  adornments: {
    marginLeft: 6,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: spacing(),
      cursor: 'pointer',
      color: palette.common.navy50,
      '&:hover': {
        color: palette.common.navy,
      },
    },
  },
  disabledPostButton: {
    color: palette.common.navy50,
  },
}))

// This function converts comment text as received from the DB to the format react-mentions expects
// React-mentions needs a specific markdown format so it knows which text needs to be highlighted
export const convertPlainTextToTemplateText = (text: string, mentionsList: User[]) => {
  if (!text) return ''
  mentionsList.forEach(user => {
    const atName = `@${user.name}`
    const templatedName = `${OPEN}${user.id}${CLOSE}${OPEN}${user.name}${CLOSE}`
    // eslint-disable-next-line no-param-reassign
    text = text.replace(atName, templatedName)
  })
  return text
}

// This function converts comment from the format of react-mentions to the format the DB expects
export const convertTemplateTextToPlainText = (text: string, mentionsList: User[]) => {
  mentionsList.forEach(user => {
    const atName = `@${user.name}`
    const templatedName = `${OPEN}${user.id}${CLOSE}${OPEN}${user.name}${CLOSE}`
    // eslint-disable-next-line no-param-reassign
    text = text.replace(templatedName, atName)
  })
  return text
}

// This function analyzes the text and pulls mentions information: user's uuids and their indexes
// Example: "Hi @John" => mentions will be {index: 3, uuid: <uuid of Johsn>}
// We will save this information in the backend so next time react-mentions knows what to render
export const getMentionsFromPlainText = (text: string, mentionsList: User[]) => {
  const mentions: UserCommentMentionInput[] = []
  let lastIndex = 0

  mentionsList.forEach(user => {
    const atName = `@${user.name}`
    const index = text.indexOf(atName, lastIndex)
    mentions.push({
      index,
      uuid: user.id,
    })
    lastIndex = index + atName.length + 1
  })
  return mentions
}

// we need to find the cursor index in the templated version (the one for react-mentions), given the cursor index in the plain text
// For this, we transform everything before its position in the plain version into templated version and get the length of that
const getCursorIndex = (text: string, mentions: User[], cursorIndex: number) => {
  const plainText = convertTemplateTextToPlainText(text, mentions)
  const templateVersionSliced = convertPlainTextToTemplateText(
    plainText.slice(0, cursorIndex),
    mentions,
  )
  return templateVersionSliced.length
}

type Props = {
  actionItemTaskUuid: string
  actionItemTaskCommentUuid?: string
  targetUserUuid?: string
  defaultPlainText: string
  setEditMode?(editMode: boolean): void
  defaultMentions: User[]
}

// Explanation of how the @ mentions work:
// Whatever you see on input text for the comment is what's stored in the DB. For example: "Hi @foo from @bar"
// We parse this string to find mentions (user uuids and indexes) and we send them to the backend too in a mentions list
// When backend responds with the comment, it also responds with that mentions list. So the frontend knows to lookup
// the mentions and do whatever parsing is needed:
//  1. Wrapping mentions in <span> for read mode
//  2. Wrapping mentions in a templating syntax for react-mentions
const UpdateComment: React.FC<Props> = ({
  actionItemTaskUuid,
  actionItemTaskCommentUuid,
  targetUserUuid,
  setEditMode,
  defaultPlainText,
  defaultMentions,
}) => {
  const classes = useStyles()

  const client = useApolloClient()
  const usersWithAccess =
    client.readQuery<ActionPlansActionPlanQuery>({
      query: ActionPlansActionPlanDocument,
      variables: { targetUserUuid },
    })?.actionPlan.usersWithAccess || []
  const inputRef = useRef<HTMLTextAreaElement>(null)
  // text is the version that feeds the MentionInput in order to show the highlighted style
  const [text, setText] = useState(
    convertPlainTextToTemplateText(defaultPlainText, defaultMentions),
  )
  const [mentions, setMentions] = useState<User[]>(defaultMentions)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [updateComment] = useActionPlansUpdateActionItemTaskCommentMutation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const containerEl = useRef<HTMLDivElement>(null)

  const savePost = async () => {
    if (!text) return
    const plainText = convertTemplateTextToPlainText(text, mentions)
    const variables = {
      actionItemTaskUuid,
      userUuid: targetUserUuid,
      commentInput: {
        uuid: actionItemTaskCommentUuid,
        text: plainText,
        mentions: getMentionsFromPlainText(plainText, mentions),
      },
      userTimezone: getCurrentTimezoneStandard(),
    }
    await updateComment({ variables })
    setText('')
    setMentions([])
    setEditMode && setEditMode(false)
  }
  return (
    <div className={classes.root} ref={containerEl}>
      <div className={classes.postContainer}>
        <MentionsInput
          inputRef={inputRef}
          placeholder={
            actionItemTaskCommentUuid
              ? 'Edit comment...'
              : 'Add a new comment and use @ to tag a colleague...'
          }
          className={cn(classes.mentionsInput, {
            // Shift the text a bit up if there's an emoji in the text, otherwise the text will look strange
            [classes.mentionsInputEmoji]: text.search(emojiRegex) !== -1,
          })}
          // Using cleanNewLines cuz react-mentions chokes with '\r\n' vs '\n'
          value={cleanNewLines(text)}
          suggestionsPortalHost={containerEl.current as Element}
          onChange={(
            e,
            newValue,
            newPlainTextValue,
            newMentions: { id: string; display: string }[],
          ) => {
            setText(newValue)
            setMentions(
              newMentions.map(m => ({
                id: m.id,
                // we need to strip the "@" from the display name
                name: m.display.slice(1),
              })),
            )
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
              savePost()
            }
          }}
        >
          <Mention
            className={classes.userMention}
            trigger="@"
            data={usersWithAccess.map(u => ({
              id: u.id,
              display: u.name,
            }))}
            renderSuggestion={(suggestion, search, highlightedDisplay) => (
              <Typography className={classes.userSuggestion}>{highlightedDisplay}</Typography>
            )}
            markup={`${OPEN}__id__${CLOSE}${OPEN}__display__${CLOSE}`}
            displayTransform={(id, display) => `@${display}`}
          />
        </MentionsInput>
        <div className={classes.adornments}>
          <SentimentSatisfiedOutlinedIcon
            aria-describedby="emojis"
            fontSize="small"
            onClick={(event: ReactMouseEvent<SVGSVGElement, MouseEvent>) => {
              if (!inputRef.current) return
              setAnchorEl(event.target as HTMLElement)
              const cursorIndex = inputRef.current.selectionStart
              inputRef.current.selectionStart = getCursorIndex(text, mentions, cursorIndex)
              setShowEmojiPicker(true)
            }}
          />
          {showEmojiPicker && (
            <EmojiPicker
              anchorEl={anchorEl}
              onClose={() => {
                inputRef?.current?.focus()
                setShowEmojiPicker(false)
              }}
              onSelectEmoji={(emoji: string) => {
                if (!inputRef.current) return
                const selectionStart = inputRef.current.selectionStart
                const plainText = convertTemplateTextToPlainText(text, mentions)
                const plainTextWithEmoji =
                  plainText.slice(0, selectionStart) + emoji + plainText.slice(selectionStart)

                setText(convertPlainTextToTemplateText(plainTextWithEmoji, mentions))
                setShowEmojiPicker(false)
                // move cursor right after the new inserted emoji and focus the input
                setTimeout(() => {
                  if (!inputRef.current) return
                  // using the timeout because otherwise focus is overriden by Popover and it won't work
                  const newSelectionStart = selectionStart + emoji.length
                  inputRef.current.selectionStart = newSelectionStart
                  inputRef.current.selectionEnd = newSelectionStart
                  inputRef.current.focus()
                }, 100)
              }}
            />
          )}
          <NavLink
            to="#"
            className={cn({ [classes.disabledPostButton]: !text })}
            onClick={savePost}
          >
            <Typography>Post</Typography>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default UpdateComment
