import React, { useState } from 'react'

import { Typography, makeStyles, DialogContent, IconButton, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import Autocomplete from 'components/Blocks/Search/Autocomplete'
import { Group } from 'components/Settings/Team/Groups/GroupsTypes'
import { SettingsGroupUserFragment } from 'generated/graphql'

const useStyles = makeStyles(theme => ({
  container: {
    height: 400,
  },
  input: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  columns: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxHeight: 300,
    overflow: 'auto',
  },
  userTag: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    borderRadius: 8,
    border: `1px solid ${theme.palette.common.navy15}`,
    width: 210,
    '& >div': {
      overflow: 'hidden',
      '&>p': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
    '& >button>span>svg': {
      fontSize: '1.8rem',
    },
  },
  disabled: {
    color: theme.palette.common.navy15,
    paddingRight: 5,
  },
}))

const getUserSuggestions = (users: SettingsGroupUserFragment[], currentUserIds: string[]) => {
  return (
    users
      // Ignore admin and users already added
      .filter(u => !currentUserIds.includes(u.id) && !u.isAdmin)
      .map(u => ({ label: u.email }))
  )
}

type Props = {
  group: Group
  updateGroup(arg: Partial<Group>): void
  users: SettingsGroupUserFragment[]
  protectedUserEmails: string[]
}

const MemberSettings: React.FC<Props> = ({ group, updateGroup, users, protectedUserEmails }) => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const addUser = (emailSelection: string) => {
    const user = users.find(u => u.email === emailSelection)
    if (!user) return
    updateGroup({ users: [...group.users, user] })
    setEmail('')
  }
  const suggestions = getUserSuggestions(
    users,
    group.users.map(u => u.id),
  )
  return (
    <DialogContent dividers className={classes.container}>
      <Typography>
        Add the emails of people you want to add to this group. You can also do this later.
        <br />
        Note: you can only add users that have already been invited.
      </Typography>
      <div className={classes.input}>
        <Autocomplete
          label="Email"
          placeholder="john@company.com"
          suggestions={suggestions}
          value={email}
          onChange={val => setEmail(val)}
          onSelect={(emailSelection: string) => addUser(emailSelection)}
        />
      </div>
      {group.users.length > 0 && <Typography color="textSecondary">People added:</Typography>}
      <div className={classes.columns}>
        {group.users.map(user => (
          <div key={user.email} className={classes.userTag}>
            <div>
              {user.name}
              <br />
              <Typography variant="body2" color="textSecondary">
                {user.email}
              </Typography>
            </div>
            {protectedUserEmails.includes(user.email) ? (
              <Tooltip
                title="This user can not be removed because it is their only group.
                      Reassign the user to another group to allow removing them from this group."
              >
                <CloseIcon className={classes.disabled} />
              </Tooltip>
            ) : (
              <IconButton
                aria-label="Close"
                onClick={() => updateGroup({ users: group.users.filter(u => u !== user) })}
                disabled={protectedUserEmails.includes(user.email)}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    </DialogContent>
  )
}

export default MemberSettings
