import React, { useState } from 'react'

import { makeStyles, Typography, MenuItem } from '@material-ui/core'
import orderBy from 'lodash/orderBy'

import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import BarChartSortButton from 'components/Insights/Blocks/BarChartSortButton'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsBenchmark, InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import TimeTrendingContainer from 'components/Insights/TimeTrending/TimeTrendingContainer'
import TTGroupedScoresChart from 'components/Insights/TimeTrending/TTGroupedScoresChart'
import {
  getBenchmarkScore,
  getOrderedGroupNames,
  getOverallScoreGroups,
  transformGroupedScoresToEcharts,
  groupScoresByLabel,
  SurveyScoreByGroup,
  ScoreBySurvey,
} from 'components/Insights/TimeTrending/utils'
import { TimeTrendingChartKey, TimeTrendingSurveysKey } from 'config/LocalStorage'
import {
  InsightsTimeTrendingScoresByDataTypeQuery,
  SurveyProductTypeEnum,
  useInsightsTimeTrendingScoresByDataTypeQuery,
  FilterTypeFragment,
} from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import {
  PRODUCT_TYPE_TO_PULSE_TYPE,
  ORDER_TYPES,
  ScoreTitleEnum,
  SORT_OPTIONS,
  SURVEY_TYPE_TO_KEY_STATEMENT,
} from 'utils/constants'
import { usePulseInTimeTrending } from 'utils/customHooks'
import { SurveyNode } from 'utils/types'

const useStyles = makeStyles(({ spacing, palette }) => ({
  chart: {
    marginTop: spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  highlights: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderTop: `1px solid ${palette.common.navy15}`,
    paddingTop: 20,
    '& >div': {
      paddingRight: 10,
    },
  },
}))

type DeltasList = Array<{ label: string; delta: number }>
export const filterHighlights = (
  deltasList: DeltasList,
  order: ORDER_TYPES,
  numLargestDeltas: undefined | number = 5,
) => {
  return (
    orderBy(deltasList, 'delta', order)
      // Filter for highlights in the same direction (i.e. index score increase excludes negatives)
      .filter(({ delta }) => (order === ORDER_TYPES.DESCENDING ? delta > 0 : delta < 0))
      .slice(0, numLargestDeltas)
  )
}

/**
 * Returns a list of { filterValueLabel, delta }
 * Delta is difference between values of the first and last survey.
 */
export const getDeltasList = (scoresByGroup: SurveyScoreByGroup) => {
  return Object.keys(scoresByGroup).map(label => {
    // Ignore scores with <minShowableResults responses.
    const scores = Object.values(scoresByGroup[label]).filter((v): v is number => v !== null)
    return {
      label,
      // Default to 0 if the value is NaN.
      delta: scores[scores.length - 1] - scores[0] || 0,
    }
  })
}

// It would be more efficient to combine this with the index score calculation, but this
// allows the transformation functions to be more composable / reusable.
export const groupResponseRates = (
  data: Array<InsightsTimeTrendingScoresByDataTypeQuery & { uuid: string }>,
): SurveyScoreByGroup => {
  const resultMap: SurveyScoreByGroup = {}
  data.forEach(({ insightsScoresByDataType, uuid }) => {
    insightsScoresByDataType.forEach(({ label, count, invitedCount }) => {
      if (!label || !count || !invitedCount) return
      if (!resultMap[label]) {
        resultMap[label] = {} as ScoreBySurvey
      }
      resultMap[label][uuid] = Math.round(count / invitedCount)
    })
  })
  return resultMap
}

type HProps = {
  surveyScoresByGroup: SurveyScoreByGroup
  responseRateByGroup: SurveyScoreByGroup
  filterTypePlural: string
  scoreName: string
}
export const Highlights: React.FC<HProps> = ({
  surveyScoresByGroup,
  responseRateByGroup,
  filterTypePlural,
  scoreName,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const indexScoreDeltas = getDeltasList(surveyScoresByGroup)
  const responseRateDeltas = getDeltasList(responseRateByGroup)
  const highlightData = [
    {
      title: `Biggest ${scoreName} Increases Over Time`,
      highlights: filterHighlights(indexScoreDeltas, ORDER_TYPES.DESCENDING),
    },
    {
      title: 'Biggest Response Rate Increases Over time',
      highlights: filterHighlights(responseRateDeltas, ORDER_TYPES.DESCENDING),
    },
    {
      title: `${scoreName} Decreased Over Time`,
      highlights: filterHighlights(indexScoreDeltas, ORDER_TYPES.ASCENDING),
    },
    {
      title: 'Response Rate Decreased Over Time',
      highlights: filterHighlights(responseRateDeltas, ORDER_TYPES.ASCENDING),
    },
  ].filter(({ highlights }) => highlights.length)
  return (
    <div className={classes.highlights}>
      {highlightData.map(({ title, highlights }) => {
        const { delta: largestDelta } = highlights[0]
        return (
          <div key={title}>
            <Typography color="textSecondary" variant="body2">
              {title.toUpperCase()}
            </Typography>
            <div className={classes.percentageText}>
              <ScoreChangeArrow delta={largestDelta} />
            </div>
            <Typography variant="body2">
              {`${filterTypePlural}: `}
              {highlights.map(({ delta, label }, index) => {
                return (
                  <span key={label}>
                    {label}
                    <ScoreChangeArrow delta={delta} />
                    {index < highlights.length - 1 && ', '}
                  </span>
                )
              })}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}

type Props = {
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  filters: string[]
  benchmark: InsightsBenchmark
  visibleFilterTypes: FilterTypeFragment[]
}
const TTDetailedBreakdownCard: React.FC<Props> = ({
  survey,
  availableSurveys,
  filters,
  benchmark,
  visibleFilterTypes,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [selectedFilter, setSelectedFilter] = useState(visibleFilterTypes[0])
  const [currentSort, setCurrentSort] = useState(SORT_OPTIONS.A_TO_Z)
  const { chartKey, surveysKey } = {
    [SurveyProductTypeEnum.EMPLOYEE]: {
      chartKey: TimeTrendingChartKey.EMPLOYEE_DETAILED_BREAKDOWN,
      surveysKey: TimeTrendingSurveysKey.EMPLOYEE_DETAILED_BREAKDOWN,
    },
    [SurveyProductTypeEnum.RESIDENT]: {
      chartKey: TimeTrendingChartKey.RESIDENT_DETAILED_BREAKDOWN,
      surveysKey: TimeTrendingSurveysKey.RESIDENT_DETAILED_BREAKDOWN,
    },
  }[survey.productType]
  const includesPulse = usePulseInTimeTrending(availableSurveys, surveysKey)
  const pulseKeyStatementCode =
    SURVEY_TYPE_TO_KEY_STATEMENT[PRODUCT_TYPE_TO_PULSE_TYPE[survey.productType]]
  const scoreName = includesPulse ? 'Key Statement scores' : 'scores'
  return (
    <div className={classes.fullRow} id="tt-detailed-breakdown-snap">
      <SnapshotChartHeader
        title={
          includesPulse
            ? 'Detailed Breakdown Over Time — Key Statement Score'
            : 'Detailed Breakdown Over Time'
        }
        tooltip={`See how ${scoreName} have changed across each level of your organization. Use the dropdown on the right to change which level you see in the charts.`}
        hasTimeTrending
        timeTrendingChartKey={chartKey}
        snapId="tt-detailed-breakdown-snap"
        useBottomPadding={false}
        extraControls={
          <TextDropdown
            displayEmpty
            value={selectedFilter.dtCode}
            renderValue={filterCode => {
              return visibleFilterTypes.find(({ dtCode }) => dtCode === filterCode)?.name
            }}
            onChange={e => {
              const newFilter = visibleFilterTypes.find(
                ft => ft.dtCode === (e.target.value as string),
              )
              if (newFilter) {
                setSelectedFilter(newFilter)
              }
            }}
            label={
              <Typography variant="body2" color="textSecondary">
                Show:&nbsp;
              </Typography>
            }
          >
            {visibleFilterTypes.map(ft => {
              return (
                <MenuItem key={ft.dtCode} value={ft.dtCode}>
                  <Typography>{ft.name}</Typography>
                </MenuItem>
              )
            })}
          </TextDropdown>
        }
      />
      <TimeTrendingContainer
        chartSurveysKey={surveysKey}
        availableSurveys={availableSurveys}
        survey={survey}
      >
        {({ selectedSurveys }) => (
          <DynamicSurveyQuery
            surveys={selectedSurveys}
            variables={{
              filters,
              dtCode: selectedFilter.dtCode,
              benchmark,
              statementCodes: includesPulse ? [pulseKeyStatementCode] : null,
            }}
            queryHook={useInsightsTimeTrendingScoresByDataTypeQuery}
          >
            {(data, loading) => {
              const surveyScoresByGroup = groupScoresByLabel(data, 'insightsScoresByDataType')
              const orderedGroupNames = getOrderedGroupNames(
                Object.keys(surveyScoresByGroup),
                surveyScoresByGroup,
                currentSort,
                survey.uuid,
              )
              const responseRateByGroup = groupResponseRates(data)
              const filterValueScoreGroups = transformGroupedScoresToEcharts(
                orderedGroupNames,
                surveyScoresByGroup,
                data.map(({ uuid }) => uuid),
              )
              const companyOverallScoreGroups = getOverallScoreGroups(data)
              return (
                <>
                  <div className={classes.chart}>
                    <TTGroupedScoresChart
                      xAxisLabelData={selectedSurveys}
                      groupedScores={filterValueScoreGroups}
                      benchmarkName={benchmark.name}
                      companyOverallScores={companyOverallScoreGroups[0]}
                      benchmarkScore={getBenchmarkScore(data[0].overallIndex)}
                      minShowableResults={selectedSurveys[0].minShowableResults}
                      title={String(selectedFilter.namePlural)}
                      didSelectFilters={!loading && filters.length > 0}
                      surveyScoresByGroup={surveyScoresByGroup}
                      orderedGroupNames={orderedGroupNames}
                      includesPulse={includesPulse}
                    />
                    <div className={classes.timeTrendingSortButton}>
                      <BarChartSortButton
                        currentSort={currentSort}
                        handleChangeSort={newSort => setCurrentSort(newSort)}
                      />
                    </div>
                  </div>
                  <Highlights
                    surveyScoresByGroup={surveyScoresByGroup}
                    responseRateByGroup={responseRateByGroup}
                    filterTypePlural={selectedFilter.namePlural || ''}
                    scoreName={
                      includesPulse ? 'Key Statement score' : ScoreTitleEnum[survey.productType]
                    }
                  />
                </>
              )
            }}
          </DynamicSurveyQuery>
        )}
      </TimeTrendingContainer>
    </div>
  )
}

export default TTDetailedBreakdownCard
