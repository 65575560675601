import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 948,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '@media print': {
      width: 710,
      padding: 0,
      margin: 0,
      '& *': {
        border: '0',
      },
    },
  },
})

const Page = ({ classes, children }) => <div className={classes.root}>{children}</div>

Page.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default withStyles(styles)(Page)
