import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import StatementsChart from 'components/Insights/Statements/StatementsChart'
import {
  prepareStatements,
  getResponseTypes,
} from 'components/Insights/Statements/StatementsContainer'
import {
  InsightsStatementScoreFragment,
  InsightsModulesEnum,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { RESPONSE_TYPES as RT } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(({ spacing }) => ({
  tooltip: {
    padding: spacing(2),
  },
}))

type CardProps = {
  statement: InsightsStatementScoreFragment & { uuid: string }
} & InsightsTabProps
const OverallRecommendCard: React.FC<CardProps> = ({ statement, survey, ...restProps }) => {
  const classes = useStyles()
  return (
    <>
      <StatementsChart
        survey={survey}
        title="Overall Recommendation Score"
        description="The statement question acts as a benchmark to help your organization measure satisfaction over time. The statement asks is the participant would recommend your facility to others. It can be used as a data point to analyze trends across all locations."
        tooltip={
          <div className={classes.tooltip}>
            <Typography>Recommendation Question:</Typography>
            <Typography color="textSecondary">
              Respondents are given a linear scale rating (1 to 5) to answer the question. Positive
              responses (4-5), Inconsistent responses (3), and Negative responses (1-2).
              Recommendation score is the percentage of satisfied customers who gave a positive
              response to the question.
            </Typography>
            <br />
            <Typography>How we calculate the Statement Score:</Typography>
            <Typography color="textSecondary">
              To calculate the percent satisfied we take the number of positive responses, and
              divide them by the total number of responses.
            </Typography>
          </div>
        }
        surveyProductType={SurveyProductTypeEnum.RESIDENT}
        showFocus={false}
        statements={prepareStatements('', [statement], undefined, undefined)}
        legendAlign="right"
        padRows
        filterValueLabels={[]}
        overallLegendName={
          restProps.filters.length > 0 ? 'Selected Filters' : 'Overall Recommendation Score'
        }
        responseTypes={getResponseTypes([RT.all])}
        insightsModules={[]}
        {...restProps}
      />
      <Typography color="textSecondary">
        To see all statement scores, go to the&nbsp;
        <NavLink
          to={`${getInsightsPage(
            survey.uuid,
            InsightsModulesEnum.STATEMENTS,
            survey.productType,
          )}?initialSort=highToLow`}
        >
          Statements
        </NavLink>
        &nbsp;page.
      </Typography>
    </>
  )
}

export default OverallRecommendCard
