import React from 'react'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  legendItem: {
    fontSize: '1.4rem',
    marginRight: theme.spacing(3),
    '& span': {
      display: 'inline-block',
      width: 12,
      height: 8,
      marginLeft: 6,
    },
  },
}))

type Props = { label?: string | null; color: string }
const StatementsLegendItem: React.FC<Props> = ({ label, color }) => {
  const classes = useStyles()
  return (
    <div className={classes.legendItem}>
      {label}
      <span style={{ backgroundColor: color }} />
    </div>
  )
}

export default StatementsLegendItem
