import React from 'react'

import { makeStyles } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import Button from 'components/Blocks/CustomButtons/Button'
import { useActionPlansDownloadLazyQuery } from 'generated/graphql'
import { useDownloadMachine } from 'utils/customHooks'

type Props = {
  title: string
  id: string
  onClick?: () => void
  targetUserUuid?: string
}

const useStyles = makeStyles(theme => ({
  downloadButton: {
    marginLeft: 0,
    paddingLeft: 0,
    '& >span>svg': {
      marginRight: theme.spacing(1),
    },
  },
}))

const ActionPlansDownloadButton: React.FC<Props> = ({ targetUserUuid, title, id, onClick }) => {
  const classes = useStyles()
  const [downloadFile, { data, error }] = useActionPlansDownloadLazyQuery({
    variables: {
      targetUserUuid,
    },
    fetchPolicy: 'network-only',
  })
  const { state, send } = useDownloadMachine(downloadFile, Boolean(data?.success), error)
  return (
    <Button
      color="secondaryNoBackground"
      id={id}
      onClick={() => {
        send('FETCH_DOWNLOAD')
        if (onClick) onClick()
      }}
      disabled={state.matches('loading')}
      className={classes.downloadButton}
    >
      <CloudDownloadIcon />
      {title}
    </Button>
  )
}

export default ActionPlansDownloadButton
