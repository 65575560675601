import React from 'react'

import { Route } from 'react-router-dom'

import AnonymousLayout from 'shared/anonymous'

const AnonymousLayoutRouteContainer = ({ component: WrappedComponent, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <AnonymousLayout {...matchProps}>
        <WrappedComponent {...matchProps} {...rest} />
      </AnonymousLayout>
    )}
  />
)

export default AnonymousLayoutRouteContainer
