import React from 'react'

import { useApolloClient } from '@apollo/client'
import { makeStyles, Typography } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import capitalize from 'lodash/capitalize'
import { NavLink } from 'react-router-dom'

import * as NpsBreakdownImage from 'assets/img/NPS-score-breakdown.png'
import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import useStyles from 'components/Insights/CustomSurvey/styles'
import useInsightsStyles, { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { NPS_THRESHOLDS } from 'components/Survey/Wizard/Steps/Questions/NpsQuestions'
import { gaEvent } from 'config/ga'
import {
  CurrentUserDocument,
  InsightsDownloadDocument,
  NpsGroupsEnum,
  CurrentUserQuery,
  NpsGroupScoresType,
  InsightsModulesEnum,
  SurveyProductTypeEnum,
  BenchmarkCodeType,
  UserDownloadsEnum,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import {
  runDownloadQuery,
  getNpsLabel,
  getNpsAbbreviation,
  getSolutionFromProductType,
} from 'utils'
import { getInsightsPage } from 'utils/insightsUtils'

export const NPS_COLORS = {
  [NpsGroupsEnum.DETRACTORS]: '#F36A6B',
  [NpsGroupsEnum.PASSIVES]: '#F7D35C',
  [NpsGroupsEnum.PROMOTERS]: '#4DB5AF',
}

const NPS_SNAP = 'npsSnap'

const useNpsStyles = makeStyles(({ palette, spacing }) => ({
  icon: {
    color: palette.common.navy65,
    width: 40,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& >p': {
      width: '80%',
    },
    marginTop: -spacing(3),
    alignItems: 'center',
  },
  gaugeAndScores: {
    display: 'flex',
  },
  detailsBox: {
    width: '100%',
    '& >div': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  questionText: {
    width: '60%',
    '@media print': {
      width: '50%',
    },
    marginRight: spacing(2),
  },
  scoresBox: {
    width: '40%',
    '@media print': {
      width: '50%',
    },
  },
  scoreRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: spacing(),
  },
  scoreRowScore: {
    width: '20%',
  },
  scoreDot: {
    width: 10,
    height: 10,
    marginRight: spacing(),
    borderRadius: '50%',
  },
  detractors: {
    color: NPS_COLORS[NpsGroupsEnum.DETRACTORS],
  },
  passives: {
    color: NPS_COLORS[NpsGroupsEnum.PASSIVES],
  },
  promoters: {
    color: NPS_COLORS[NpsGroupsEnum.PROMOTERS],
  },
  tooltipContainer: {
    margin: spacing(3),
  },
}))

export const NpsTooltip: React.FC<{ productType: SurveyProductTypeEnum }> = ({ productType }) => {
  const classes = useNpsStyles()

  const npsAbbr = getNpsAbbreviation(productType)
  return (
    <div className={classes.tooltipContainer}>
      <Typography>{getNpsLabel(productType)} Question:</Typography>
      <Typography color="textSecondary">
        Respondents are given a linear scale rating (0 to 10) to answer the {npsAbbr} question.
        Detractors (0-6), Passives (7-8), and Promoters (9-10) - showing you the % breakdown of
        customers who are loyal, are neutral, or are unhappy.
      </Typography>
      <br />
      <Typography>How we calculate the {npsAbbr} Score:</Typography>
      <Typography color="textSecondary">
        Subtract % detractors from the % promoters = {npsAbbr} score
      </Typography>
      <br />
      <img width="100%" src={NpsBreakdownImage} alt="Nps Breakdown" />
      <br />
      <br />
      <Typography color="textSecondary">
        The {npsAbbr} score ranges from -100 to 100, (all percentages are rounded up to the nearest
        whole number). A negative {npsAbbr} score lets you know there are more detractors than
        promoters. An {npsAbbr} score of 50 or higher is considered above average. Any score above{' '}
        {npsAbbr} 70 is excellent, and should be celebrated.
      </Typography>
    </div>
  )
}

type NpsGauageProps = {
  npsAbbr: string
  npsScore: number
  detractorsScore: number
  passivesScore: number
  promotersScore: number
}

const NpsGauge: React.FC<NpsGauageProps> = ({
  npsAbbr,
  npsScore,
  detractorsScore,
  passivesScore,
  promotersScore,
}) => {
  // the gauge values are measured as a distance from the 0 point that's why we need to add them this way
  const gaugeValues = {
    [NpsGroupsEnum.DETRACTORS]: detractorsScore,
    [NpsGroupsEnum.PASSIVES]: detractorsScore + passivesScore,
    [NpsGroupsEnum.PROMOTERS]: detractorsScore + passivesScore + promotersScore,
  }

  // Mathematically calculating the position of the percentage labels
  const labelValues = {
    [NpsGroupsEnum.DETRACTORS]: Math.round(gaugeValues[NpsGroupsEnum.DETRACTORS] / 2),
    [NpsGroupsEnum.PASSIVES]: Math.round(
      (gaugeValues[NpsGroupsEnum.DETRACTORS] + gaugeValues[NpsGroupsEnum.PASSIVES]) / 2,
    ),
    [NpsGroupsEnum.PROMOTERS]: Math.round(
      (gaugeValues[NpsGroupsEnum.PASSIVES] + gaugeValues[NpsGroupsEnum.PROMOTERS]) / 2,
    ),
  }

  return (
    <PrintableEchart
      printWidth={250}
      style={{
        width: 400,
        height: 300,
        marginLeft: 20,
        marginRight: 90,
        marginTop: -20,
        marginBottom: -100,
      }}
      opts={{ renderer: 'svg' }} // Useful for selecting DOM elements in UItests
      option={{
        textStyle: chartTextStyle,
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            // Splitting the 180 degrees in 100 chunks so we can place the percentage labels using the calculations above
            splitNumber: 100,
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 12,
                color: [
                  NpsGroupsEnum.DETRACTORS,
                  NpsGroupsEnum.PASSIVES,
                  NpsGroupsEnum.PROMOTERS,
                ].map(group => [gaugeValues[group] / 100, NPS_COLORS[group]]),
              },
            },
            axisLabel: {
              color: colors.navy,
              fontSize: 14,
              distance: -45,
              formatter: (value: number) => {
                const val = {
                  [labelValues[NpsGroupsEnum.DETRACTORS]]: detractorsScore,
                  [labelValues[NpsGroupsEnum.PASSIVES]]: passivesScore,
                  [labelValues[NpsGroupsEnum.PROMOTERS]]: promotersScore,
                }[value]
                if (val === 0) {
                  return '0%'
                }
                return val && `${Math.round(val)}%`
              },
            },
            data: [
              {
                name: `${npsAbbr} ${npsScore}`,
                title: {
                  color: colors.navy,
                  offsetCenter: [0, '-30%'],
                  fontSize: 30,
                },
                detail: {
                  valueAnimation: true,
                  color: colors.navy,
                  offsetCenter: [0, '-40%'],
                  show: false,
                },
              },
            ],
          },
        ],
      }}
    />
  )
}

const getScoreDescription = (score: number) => {
  if (score < 0) {
    return { color: NPS_COLORS[NpsGroupsEnum.DETRACTORS], label: 'Needs Improvement' }
  }
  if (score < 30) {
    return { color: colors.mustardYellow, label: 'Good' }
  }
  if (score < 70) {
    return { color: NPS_COLORS[NpsGroupsEnum.PROMOTERS], label: 'Great' }
  }
  return { color: '#2D9D96', label: 'Excellent' }
}

const getNpsDescription = (productType: SurveyProductTypeEnum) => {
  const word = productType === SurveyProductTypeEnum.EMPLOYEE ? 'employee' : 'customer'
  return `The ${getNpsAbbreviation(
    productType,
  )} question seeks to understand overall ${word} loyalty for your organization.`
}

type NpsRecommendControlsProps = {
  surveyUuid: string
  productType: SurveyProductTypeEnum
  filters: string[]
  hideScreenShotButton?: boolean
}

const NpsRecommendControls: React.FC<NpsRecommendControlsProps> = ({
  surveyUuid,
  productType,
  filters,
  hideScreenShotButton,
}) => {
  const classes = { ...useStyles(), ...useNpsStyles() }
  const client = useApolloClient()
  const currentUser = client.readQuery({ query: CurrentUserDocument }).currentUser as NonNullable<
    CurrentUserQuery
  >['currentUser']
  const npsDownload = Boolean(
    getSolutionFromProductType(currentUser.organization, productType).npsDownload,
  )
  return (
    <div className={classes.actions}>
      {npsDownload && (
        <IconButton
          id="nps-download"
          color="secondaryHover"
          className={classes.icon}
          onClick={() => {
            gaEvent({
              action: `download-NPS`,
              category: 'Insights',
            })
            runDownloadQuery(() =>
              client.query({
                query: InsightsDownloadDocument,
                variables: {
                  surveyUuid,
                  filters,
                  downloadType: UserDownloadsEnum.NPS_EXPORT,
                },
                fetchPolicy: 'network-only',
              }),
            )
          }}
        >
          <CloudDownloadIcon />
        </IconButton>
      )}
      {!hideScreenShotButton && <ScreenshotButton snapId={NPS_SNAP} strategy="html2canvas" />}
    </div>
  )
}

type NpsCardProps = {
  surveyUuid: string
  productType: SurveyProductTypeEnum
  filters: string[]
  npsGroupScores: NpsGroupScoresType
  useFormPanel?: boolean
}
const NpsRecommendCard: React.FC<NpsCardProps> = ({
  surveyUuid,
  productType,
  filters,
  npsGroupScores,
  useFormPanel,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles(), ...useNpsStyles() }
  const { promoters, passives, detractors, label } = npsGroupScores
  const npsScore = Math.round(promoters - detractors)
  const npsAbbr = getNpsAbbreviation(productType)
  const scoreDescription = getScoreDescription(npsScore)

  return (
    <>
      <div id={NPS_SNAP} className={classes.pageBreak}>
        {useFormPanel && (
          <div className={classes.header}>
            {/** Snapshot Chart header displays these buttons as a header.
             * On the form panel, we want to only display them when the panel is expanded */}
            <Typography color="textSecondary">{getNpsDescription(productType)}</Typography>
            <NpsRecommendControls
              surveyUuid={surveyUuid}
              productType={productType}
              filters={filters}
            />
          </div>
        )}
        <div className={classes.gaugeAndScores}>
          <NpsGauge
            npsAbbr={npsAbbr}
            npsScore={npsScore}
            detractorsScore={detractors}
            passivesScore={passives}
            promotersScore={promoters}
          />
          <div className={classes.detailsBox}>
            <Typography style={{ color: scoreDescription.color }}>
              {scoreDescription.label.toUpperCase()}
            </Typography>
            <div>
              <div className={classes.questionText}>
                <Typography>{npsAbbr} Question:</Typography>
                <Typography color="textSecondary">{label}</Typography>
              </div>
              <div className={classes.scoresBox}>
                {Object.values(NpsGroupsEnum)
                  .sort()
                  .map(group => (
                    <div key={group} className={classes.scoreRow} id={`score-row-${group}`}>
                      <div
                        className={classes.scoreDot}
                        style={{
                          backgroundColor: NPS_COLORS[group.toUpperCase() as NpsGroupsEnum],
                        }}
                      />
                      <Typography className={classes.scoreRowScore}>
                        {Math.round(
                          npsGroupScores[
                            group.toLowerCase() as 'promoters' | 'passives' | 'detractors'
                          ],
                        )}
                        %
                      </Typography>
                      <Typography color="textSecondary">
                        {capitalize(group)} ({NPS_THRESHOLDS[group][0]}-{NPS_THRESHOLDS[group][1]})
                      </Typography>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const NpsRecommendCardContainer: React.FC<NpsCardProps & {
  showCommentsLink?: boolean
}> = props => {
  const { productType, showCommentsLink, surveyUuid, useFormPanel, filters } = props
  let commentsSection = null
  const npsAbbr = getNpsAbbreviation(productType)
  if (showCommentsLink) {
    commentsSection = (
      <Typography color="textSecondary">
        To read open ended feedback that answers the follow up {npsAbbr} question: Why did you give
        [Location Name] this score?{' '}
        <NavLink
          to={`${getInsightsPage(
            surveyUuid,
            InsightsModulesEnum.COMMENTS,
            SurveyProductTypeEnum.RESIDENT,
          )}?questionCode=${BenchmarkCodeType.NPS_RECOMMEND_FOLLOWUP}`}
        >
          See Comments
        </NavLink>
      </Typography>
    )
  }

  const title = `Company Overall ${getNpsLabel(productType)}`

  if (useFormPanel) {
    return (
      <FormPanel
        id="nps-card-panel"
        expanded
        removeDetailsTopPadding
        gutterBottom={false}
        expandIcon={EXPAND_ICON.EXPAND}
        summary={
          <SnapshotChartHeader
            title={title}
            tooltip={<NpsTooltip productType={productType} />}
            useBottomPadding={false}
          />
        }
      >
        <NpsRecommendCard {...props} />
        {commentsSection}
      </FormPanel>
    )
  }
  return (
    <>
      <SnapshotChartHeader
        title={title}
        tooltip={<NpsTooltip productType={productType} />}
        description={getNpsDescription(productType)}
        snapId={NPS_SNAP}
        screenshotStrategy="html2canvas"
        extraControls={
          <NpsRecommendControls
            surveyUuid={surveyUuid}
            productType={productType}
            filters={filters}
            hideScreenShotButton
          />
        }
      />
      <NpsRecommendCard {...props} />
      {commentsSection}
    </>
  )
}

export default NpsRecommendCardContainer
