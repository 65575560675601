import {
  OrganizationFragment,
  SolutionDataAccessLevels,
  SolutionType,
  ResidentSolutionType,
  SolutionFieldEnum,
} from 'generated/graphql'

const ACCESS_LEVELS_SCORE_MAP = {
  [SolutionDataAccessLevels.NONE]: 0,
  [SolutionDataAccessLevels.ONE]: 1,
  [SolutionDataAccessLevels.CHOOSE_ONE]: 2,
  [SolutionDataAccessLevels.ALL]: 3,
}

const getEnabledSolutions = (organization: OrganizationFragment) => {
  const solutions = [organization.solution, organization.residentSolution]
  return solutions.filter(solution => Boolean(solution)) as (SolutionType | ResidentSolutionType)[]
}

export const organizationHasChooseOneDataAccess = (organization: OrganizationFragment) => {
  let maxLevel = 0
  getEnabledSolutions(organization).forEach(solution => {
    if (ACCESS_LEVELS_SCORE_MAP[solution.dataAccessLevels] > maxLevel) {
      maxLevel = ACCESS_LEVELS_SCORE_MAP[solution.dataAccessLevels]
    }
  })
  return maxLevel === ACCESS_LEVELS_SCORE_MAP[SolutionDataAccessLevels.CHOOSE_ONE]
}

export const canAssignDataAccess = (organization: OrganizationFragment) => {
  return getEnabledSolutions(organization).some(
    solution => solution.dataAccessLevels !== SolutionDataAccessLevels.NONE,
  )
}

export const orgHasEnabledSolutionField = (
  organization: OrganizationFragment,
  solutionField: SolutionFieldEnum,
): boolean => {
  return organization.enabledSolutionFields.some(
    ({ field, enabled }) => field === solutionField && enabled,
  )
}
