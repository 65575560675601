import React, { ReactElement } from 'react'

import { makeStyles, Typography, Tooltip, TypographyTypeMap } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import cn from 'classnames'

const useStyles = makeStyles(({ palette, spacing }) => ({
  titleAndTooltip: {
    display: 'flex',
    alignItems: 'center',
    '& >:first-child': {
      marginRight: spacing(),
    },
    '& >svg': {
      color: palette.common.navy65,
      '&:hover': {
        color: palette.common.navy,
      },
    },
  },
  spacing: {
    marginBottom: spacing(2),
  },
  padIcon: {
    marginBottom: -3,
  },
}))

type Props = {
  title: string
  tooltip: string | ReactElement
  showInfoIcon?: boolean
  padIcon?: boolean
  withSpacing?: boolean
  variant?: Variant
  color?: TypographyTypeMap['props']['color']
}

const TitleWithInfoTooltip: React.FC<Props> = ({
  title,
  tooltip,
  showInfoIcon = true,
  padIcon = true,
  withSpacing = true,
  variant = 'h6',
  color = 'textPrimary',
}) => {
  const classes = useStyles()
  return (
    <div className={cn(classes.titleAndTooltip, { [classes.spacing]: withSpacing })}>
      <Typography color={color} variant={variant}>
        {title}
      </Typography>
      {showInfoIcon && (
        <Tooltip title={tooltip}>
          <InfoIcon fontSize="small" className={cn({ [classes.padIcon]: padIcon })} />
        </Tooltip>
      )}
    </div>
  )
}

export default TitleWithInfoTooltip
