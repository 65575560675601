import React, { useRef } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import cn from 'classnames'
import { MentionsInput, Mention } from 'react-mentions'

import { gaEvent } from 'config/ga'
import { cleanNewLines } from 'utils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& >div>ul': {
      borderTop: `1px solid ${palette.common.navy15}`,
      maxHeight: 160,
      overflow: 'scroll',
    },
  },
  variant1: {
    padding: spacing(),
    backgroundColor: palette.common.iceGrey50,
    borderRadius: 3,
  },
  variant2: {
    padding: spacing(),
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  tagsInput: {
    maxHeight: 300,
    overflow: 'scroll',
    width: '100%',
    '& textarea': {
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
      // paddingLeft is required so that the tags higlights are vertically aligned with the tags
      paddingLeft: 0,
    },
    fontSize: 16,
    // lineHeight needs to be "normal" otherwise react-mentions' highlights will be misaligned
    lineHeight: 'normal',
  },
  tagSuggestion: {
    height: 30,
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    borderLeft: `1px solid ${palette.common.navy15}`,
    borderRight: `1px solid ${palette.common.navy15}`,
    borderBottom: `1px solid ${palette.common.navy15}`,
    '&:hover': {
      backgroundColor: '#daf4fa',
      cursor: 'pointer',
    },
  },
  tagMention: {
    backgroundColor: palette.common.secondary50,
  },
}))

type Tag = {
  id: string
  value: string
}

const TAG_OPEN = '{{'
const TAG_CLOSE = '}}'
const TAG_TRIGGER = TAG_OPEN

const wrapAsTemplate = (word: string) => `^^${word}^^`
const wrapAsPlainText = (word: string) => `${TAG_OPEN}${word}${TAG_CLOSE}`
const wrapTagAsTemplate = (tag: Tag) => `${wrapAsTemplate(tag.id)}${wrapAsTemplate(tag.value)}`

const transformTags = (tags: string[]) =>
  tags.map((tag, index) => ({
    id: index.toString(),
    value: tag,
  }))

// This function converts comment text as received from the DB to the format react-mentions expects
// React-mentions needs a specific markdown format so it knows which text needs to be highlighted
const convertPlainTextToTemplateText = (text: string, availableTags: string[]) => {
  if (!text) return ''
  transformTags(availableTags).forEach(tag => {
    // The next line is an equivalent of replaceAll, which is not yet supported on all browser versions
    // eslint-disable-next-line no-param-reassign
    text = text.split(wrapAsPlainText(tag.value)).join(wrapTagAsTemplate(tag))
  })
  return text
}

// This function converts comment from the format of react-mentions to the format the DB expects
const convertTemplateTextToPlainText = (text: string, availableTags: string[]) => {
  transformTags(availableTags).forEach(tag => {
    // The next line is an equivalent of replaceAll, which is not yet supported on all browser versions
    // eslint-disable-next-line no-param-reassign
    text = text.split(wrapTagAsTemplate(tag)).join(wrapAsPlainText(tag.value))
  })
  return text
}

const EditableTagsText: React.FC<{
  availableTags: string[]
  disabled?: boolean
  value: string
  setValue(newValue: string): void
  placeholder?: string
  variant?: 'variant1' | 'variant2'
}> = ({
  availableTags,
  value,
  setValue,
  disabled = false,
  placeholder = 'Edit text...',
  variant = 'variant1',
}) => {
  const classes = useStyles()
  const containerEl = useRef<HTMLDivElement>(null)

  return (
    <div className={classes.root} ref={containerEl}>
      <div
        className={cn({
          [classes.variant1]: variant === 'variant1',
          [classes.variant2]: variant === 'variant2',
        })}
      >
        <MentionsInput
          disabled={disabled}
          placeholder={placeholder}
          className={classes.tagsInput}
          // Using cleanNewLines cuz react-mentions chokes with '\r\n' vs '\n'
          value={convertPlainTextToTemplateText(cleanNewLines(value), availableTags)}
          suggestionsPortalHost={containerEl.current as Element}
          onChange={(e, newValue) =>
            setValue(convertTemplateTextToPlainText(newValue, availableTags))
          }
        >
          <Mention
            className={classes.tagMention}
            trigger={TAG_TRIGGER}
            data={availableTags.map((tag, index) => ({ id: index.toString(), display: tag }))}
            renderSuggestion={(suggestion, search, highlightedDisplay) => (
              <Typography className={classes.tagSuggestion}>
                <span>{TAG_OPEN}</span>
                {highlightedDisplay}
                <span>{TAG_CLOSE}</span>
              </Typography>
            )}
            markup={`${wrapAsTemplate('__id__')}${wrapAsTemplate('__display__')}`}
            displayTransform={(id, display) => wrapAsPlainText(display)}
            onAdd={() => {
              gaEvent({
                action: 'addMergeTag',
                category: 'Global',
              })
            }}
          />
        </MentionsInput>
      </div>
    </div>
  )
}

export default EditableTagsText
