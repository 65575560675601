import React, { useState } from 'react'

import {
  Card,
  Checkbox,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import Danger from 'components/Blocks/Typography/Danger'
import { ConfigAttrEnum } from 'components/Settings/General/OrgSettingsContainer'
import useSettingsStyles from 'components/Settings/General/OrgSettingsStyles'
import {
  BenchmarkConfigInput,
  BenchmarkDepartmentType,
  OrganizationFragment,
  OrganizationInput,
} from 'generated/graphql'

type Props = {
  organization: OrganizationFragment
  inputOrg: OrganizationInput
  updateBenchmarkConfig(configAttr: ConfigAttrEnum, updatedConfig: BenchmarkConfigInput): void
  onSubmit(): void
  loading: boolean
  isDeptConfigVisible(loc: BenchmarkDepartmentType): boolean
}
const DepartmentsTab: React.FC<Props> = ({
  loading,
  inputOrg,
  onSubmit,
  organization,
  updateBenchmarkConfig,
  isDeptConfigVisible,
}) => {
  const classes = useSettingsStyles()
  const [errorMessage, setErrorMessage] = useState('')
  return (
    <>
      <Card>
        {/** Departments */}
        <div className={classes.helpText}>
          <Typography variant="h6" className={classes.title}>
            Standard Departments
          </Typography>
          <br />
          <Typography color="textSecondary">
            You&apos;ll also need to capture the standard aging services department for each of your
            employees. Check the box next to each department in your organization. If needed, add a
            custom label to change the name of the department as it will appear on your survey.
            <br />
            <br />
            The standard departments you select will appear as the answer options on your survey to
            the question: &quot;What department or role most closely resembles what you do on a
            day-to-day basis?&quot;
            <br />
            <br />
            Note: To shorten your survey, you can precode standard departments for your employees by
            including the standard department for each employee in the participant list for the
            survey. Please note that to do this you will need the names of the standard departments
            to exactly match the list below.
          </Typography>
        </div>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell />
              <TableCell>Standard Department</TableCell>
              <TableCell>Custom Label</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organization.benchmarkDepartments.filter(isDeptConfigVisible).map(dept => {
              const deptInput = inputOrg.benchmarkDepartments.find(({ code }) => code === dept.code)
              if (!deptInput) return null
              return (
                <TableRow key={deptInput.code} hover>
                  <TableCell className={classes.tableCellDisplay}>
                    <Checkbox
                      checked={deptInput.checked}
                      onChange={() =>
                        updateBenchmarkConfig(ConfigAttrEnum.DEPT, {
                          ...deptInput,
                          checked: !deptInput.checked,
                        })
                      }
                      value={deptInput.code}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCellLabel}>{dept.name}</TableCell>
                  <TableCell className={classes.tableCellCustom}>
                    <Input
                      fullWidth
                      onChange={e =>
                        updateBenchmarkConfig(ConfigAttrEnum.DEPT, {
                          ...deptInput,
                          customName: e.target.value,
                        })
                      }
                      value={deptInput.customName}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {/** Shifts */}
        <div className={classes.helpText}>
          <Typography variant="h6" className={classes.title}>
            Shifts
          </Typography>
          <br />
          <Typography color="textSecondary">
            Your employees will also be asked which shift or times they typically work. Select the
            options that apply to your organization below and add custom labels as needed. The
            shifts you select will appear as the answer options on your survey to the question:
            "What shift or hours do you typically work? If you work many different shifts, please
            choose the option when you work the most."
            <br />
            <br />
            Note: To shorten your survey, you can precode shifts for your employees by including the
            shift for each employee in the roster for the survey. Please note that to do this you
            will need the names of the shifts to exactly match the list below.
          </Typography>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Shift</TableCell>
              <TableCell>Custom Label</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organization.benchmarkShifts.map(shift => {
              const shiftInput = inputOrg.benchmarkShifts.find(({ code }) => code === shift.code)
              if (!shiftInput) return null
              return (
                <TableRow key={shiftInput.code} hover>
                  <TableCell className={classes.tableCellDisplay}>
                    <Checkbox
                      checked={shiftInput.checked}
                      onChange={() =>
                        updateBenchmarkConfig(ConfigAttrEnum.SHIFT, {
                          ...shiftInput,
                          checked: !shiftInput.checked,
                        })
                      }
                      value={shiftInput.code}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCellLabel}>{shift.name}</TableCell>
                  <TableCell className={classes.tableCellCustom}>
                    <Input
                      fullWidth
                      onChange={e =>
                        updateBenchmarkConfig(ConfigAttrEnum.SHIFT, {
                          ...shiftInput,
                          customName: e.target.value,
                        })
                      }
                      value={shiftInput.customName}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Card>
      {errorMessage && <Danger>{errorMessage}</Danger>}
      <SubmitButton
        buttonType="button"
        onClick={() => {
          if (inputOrg.benchmarkShifts.filter(shift => shift.checked).length < 2) {
            setErrorMessage('Select at least two shift options.')
          } else {
            setErrorMessage('')
            onSubmit()
          }
        }}
        className={classes.saveButton}
        isSubmitting={loading}
        right
        color="primary"
      >
        Save
      </SubmitButton>
    </>
  )
}

export default DepartmentsTab
