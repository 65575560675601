import React, { useState } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { makeStyles, Grid, Input, Typography } from '@material-ui/core'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import cn from 'classnames'
import { format } from 'date-fns'
import querystring from 'query-string'
import { useHistory } from 'react-router-dom'

import ActionItemTaskComments from 'components/ActionPlans/ActionItemTaskComments'
import TaskStatusDropdown from 'components/ActionPlans/TaskStatusDropdown'
import Button from 'components/Blocks/CustomButtons/Button'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import TextExpandable from 'components/Blocks/TextExpandable'
import { gaEvent } from 'config/ga'
import {
  ActionPlansActionItemTaskCommentFragment,
  ActionPlansActionItemTaskCommentNodeConnectionFragment,
  ActionPlansActionItemTaskFragment,
  TaskStatusEnum,
  useActionPlansDeleteActionItemTaskMutation,
  useActionPlansUpdateActionItemTaskMutation,
} from 'generated/graphql'
import { doneTaskStatues } from 'utils/actionPlansUtils'
import { getDateFromDatetimeString, formatDateAsString } from 'utils/dateUtils'

const useTaskStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginLeft: spacing(),
    marginRight: spacing(),
  },
  taskInputRoot: {
    paddingTop: 0,
    paddingBottom: 2,
    '& >textarea': {
      fontSize: '1.6rem',
      lineHeight: 1.5, // to match text when not in edit mode
    },
  },
  taskIsDone: {
    color: palette.common.navy50,
    fontStyle: 'italic',
  },
  datePicker: {
    display: 'none',
  },
  commentsIcon: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(),
    cursor: 'pointer',
    maxWidth: 50,
    '& >svg': {
      marginRight: 4,
    },
  },
  inactiveCommentsIcon: {
    color: palette.common.navy50,
  },
}))

type TaskProps = {
  taskIsDone: boolean
  dueDate: null | Date
  text: string
  comments?: ActionPlansActionItemTaskCommentNodeConnectionFragment | null
  showComments: boolean
  setShowComments(showComments: boolean): void
  onChangeText(text: string): void
  onChangeDueDate(dueDate: Date): void
}

const Task: React.FC<TaskProps> = ({
  taskIsDone,
  dueDate,
  text,
  comments,
  showComments,
  setShowComments,
  onChangeText,
  onChangeDueDate,
}) => {
  const classes = useTaskStyles()
  const [openDueDate, setOpenDueDate] = useState(false)
  const [editModeTask, setEditModeTask] = useState(!text)
  const dueDateHasPassed = dueDate && dueDate < new Date()
  const saveText = (newText: string) => {
    if (editModeTask) {
      setEditModeTask(false)
    }
    onChangeText(newText)
  }
  return (
    <div className={classes.root}>
      {!taskIsDone && (editModeTask || !text) ? (
        <Input
          classes={{
            root: classes.taskInputRoot,
          }}
          fullWidth
          multiline
          rowsMax={5}
          autoFocus={editModeTask}
          placeholder="Add Item..."
          defaultValue={text}
          onKeyPress={e => e.key === 'Enter' && saveText((e.target as HTMLInputElement).value)}
          onBlur={e => saveText(e.target.value)}
        />
      ) : (
        <>
          <Typography
            component="div"
            className={cn({
              [classes.taskIsDone]: taskIsDone,
            })}
          >
            <TextExpandable
              text={text}
              characterLimit={400}
              onTextClick={() => !taskIsDone && setEditModeTask(true)}
            />
          </Typography>
          {/* display the due date as read only if the task is done (complete/incomplete) */}
          {taskIsDone && dueDate && `By ${format(dueDate, 'MM/dd/yyyy')}`}
          {!taskIsDone && (
            <>
              <Button color="secondaryNoBackground" noMargins onClick={() => setOpenDueDate(true)}>
                &nbsp;
                {!dueDate && 'Add Due Date'}
                {dueDate && !dueDateHasPassed && `By ${format(dueDate, 'MM/dd/yyyy')}`}
                {dueDate && dueDateHasPassed && <Typography color="error">Past Due</Typography>}
              </Button>
              {openDueDate && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    className={classes.datePicker}
                    open={openDueDate}
                    onClose={() => setOpenDueDate(false)}
                    minDateMessage="Date cannot be before today."
                    disablePast
                    label="Due Date"
                    value={dueDate}
                    margin="normal"
                    onChange={(newDueDate: MaterialUiPickersDate) => {
                      if (newDueDate) {
                        onChangeDueDate(newDueDate)
                      }
                      return null
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}
            </>
          )}
        </>
      )}
      {text && (
        <div
          role="button"
          className={cn(classes.commentsIcon, { [classes.inactiveCommentsIcon]: !showComments })}
          onClick={() => setShowComments(!showComments)}
          onKeyDown={() => setShowComments(!showComments)}
          tabIndex={0}
        >
          <ChatBubbleOutlineIcon fontSize="small" />
          {comments && comments.totalCount > 0 && (
            <Typography variant="body2">{comments.totalCount} &#x2304;</Typography>
          )}
        </div>
      )}
    </div>
  )
}

const useOwnerStyles = makeStyles(({ spacing, palette }) => ({
  owner: {
    marginRight: spacing(3),
    float: 'right',
  },
  pointer: {
    cursor: 'pointer',
  },
  ownerInputRoot: {
    '& >input': {
      fontSize: 12,
      paddingTop: 8,
      paddingBottom: 2,
      color: palette.common.navy65,
    },
  },
}))

type OwnerProps = {
  taskIsDone: boolean
  owner: string
  taskId: string
  editMode: boolean
  onChangeOwner(owner: string): void
  targetUserUuid?: string
}

const Owner: React.FC<OwnerProps> = ({
  taskIsDone,
  owner,
  taskId,
  editMode,
  onChangeOwner,
  targetUserUuid,
}) => {
  const classes = useOwnerStyles()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [editModeOwner, setEditModeOwner] = useState(false)
  const [deleteTask] = useActionPlansDeleteActionItemTaskMutation()
  const saveOwner = (newOwner: string) => {
    setEditModeOwner(false)
    onChangeOwner(newOwner)
  }
  let Component
  if (editMode) {
    Component = (
      <>
        <IconButton color="dangerHover" onClick={() => setOpenDeleteDialog(true)}>
          <DeleteIcon />
        </IconButton>
        {openDeleteDialog && (
          <ActionDialog
            title="Delete Action Item?"
            content="Are you sure? All comments associated with this action item will be deleted forever, this action cannot be undone."
            submitButtonText="Delete"
            onClose={() => setOpenDeleteDialog(false)}
            onSubmit={() => {
              gaEvent({
                action: 'deleteActionItemTask',
                category: 'ActionPlans',
              })
              deleteTask({
                variables: {
                  taskId,
                  userUuid: targetUserUuid,
                },
              })
            }}
          />
        )}
      </>
    )
  } else if (editModeOwner) {
    Component = (
      <Input
        classes={{
          root: classes.ownerInputRoot,
        }}
        fullWidth
        autoFocus
        multiline
        placeholder="Owner Name"
        name="Owner"
        defaultValue={owner}
        onKeyPress={e => e.key === 'Enter' && saveOwner((e.target as HTMLInputElement).value)}
        onBlur={e => saveOwner(e.target.value)}
      />
    )
  } else {
    Component = (
      // eslint-disable-next-line
      <div onClick={() => !taskIsDone && setEditModeOwner(true)}>
        {owner && (
          <Typography
            variant="body2"
            color="textSecondary"
            className={cn({ [classes.pointer]: !taskIsDone })}
          >
            {owner} {!taskIsDone && <>&#x2304;</>}
          </Typography>
        )}
        {!owner && taskIsDone && (
          <Typography color="textSecondary" variant="body2">
            <i>Unassigned</i>
          </Typography>
        )}
        {!owner && !taskIsDone && (
          <Button size="sm" color="secondaryNoBackground" noMargins>
            Add Owner
          </Button>
        )}
      </div>
    )
  }
  return <div className={classes.owner}>{Component}</div>
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    borderTop: `1px solid ${palette.common.navy25}`,
  },
  comparisonRoot: {
    padding: spacing(2),
  },
  comaprisonIcon: {
    color: palette.common.success,
    fontSize: 30,
  },
  statusAndIndex: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  taskAndOwner: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  taskIsDone: {
    color: palette.common.navy50,
    fontStyle: 'italic',
  },
}))

const getVisibleCommentsCount = (
  taskUuid: string,
  urlTaskUuid?: string | string[] | null,
  urlCommentUuid?: string | string[] | null,
  totalComments?: number,
  comments?: Array<ActionPlansActionItemTaskCommentFragment | null | undefined>,
) => {
  // if visibleCommentsCount is null, it means we should display all comments
  let visibleCommentsCount: null | number = null
  if (totalComments && comments) {
    visibleCommentsCount = 2
    if (totalComments <= visibleCommentsCount) {
      visibleCommentsCount = null
    }
    // Check if there is a comment referenced in the URL (via urlCommentUuid and urlTaskUuid)
    if (visibleCommentsCount && urlCommentUuid && urlTaskUuid === taskUuid) {
      const commentIsHidden = !comments
        .slice(0, visibleCommentsCount)
        .find(comment => comment?.uuid === urlCommentUuid)
      // if the referenced comment is not amongst the visible ones, show all comments because we nned to scroll to the exact comment
      if (commentIsHidden) {
        visibleCommentsCount = null
      }
    }
  }
  return visibleCommentsCount
}

type Props = {
  task: ActionPlansActionItemTaskFragment
  actionItemUuid: string
  editMode: boolean
  targetUserUuid?: string
}

const ActionItemTask: React.FC<Props> = ({ task, actionItemUuid, editMode, targetUserUuid }) => {
  const classes = useStyles()
  const history = useHistory()
  const hashParams = querystring.parse(history.location.hash)
  const [showComments, setShowComments] = useState(
    hashParams ? hashParams.task_uuid === task.uuid : false,
  )
  // We need to call getDateFromDatetimeString because for Safari and IE date objects are automatically
  // converted and look like "2021-10-06 00:00:00"
  const dueDate = getDateFromDatetimeString(task.dueDate)
  const [updateTask] = useActionPlansUpdateActionItemTaskMutation()
  const { uuid, status, owner, task: text, comments } = task
  const taskIsDone = doneTaskStatues.includes(status)
  const taskInput = {
    text,
    owner,
    status,
    dueDate,
  }
  const saveTask = () => {
    updateTask({
      variables: {
        actionItemUuid,
        userUuid: targetUserUuid,
        taskUuid: uuid,
        task: {
          ...taskInput,
          dueDate: getDateFromDatetimeString(taskInput.dueDate),
        },
      },
    })
  }

  const onChangeOwner = (newOwner: string) => {
    gaEvent({
      action: 'actionItemChangeOwner',
      category: 'ActionPlans',
    })
    taskInput.owner = newOwner
    saveTask()
  }

  const onChangeStatus = (newStatus: TaskStatusEnum) => {
    taskInput.status = newStatus
    gaEvent({
      action: 'actionItemChangeStatus',
      category: 'ActionPlans',
    })
    saveTask()
  }

  const onChangeText = (newText: string) => {
    taskInput.text = newText
    // Avoid sending a query on focus events unless text has changed.
    if (newText === text) return
    gaEvent({
      action: 'actionItemChangeComment',
      category: 'ActionPlans',
    })
    if (!text) {
      taskInput.status = TaskStatusEnum.NOT_STARTED
    }
    saveTask()
  }

  const onChangeDueDate = (newDueDate: Date) => {
    taskInput.dueDate = formatDateAsString(newDueDate)
    gaEvent({
      action: 'actionItemChangeDueDate',
      category: 'ActionPlans',
    })
    saveTask()
  }

  const visibleCommentsCount = getVisibleCommentsCount(
    uuid,
    hashParams.task_uuid,
    hashParams.comment_uuid,
    comments?.totalCount,
    comments?.edges.map(e => e?.node),
  )

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item sm={2} className={classes.statusAndIndex}>
          <TaskStatusDropdown status={status} onChange={onChangeStatus} disabled={!text} />
        </Grid>
        <Grid item sm={8}>
          <Task
            taskIsDone={taskIsDone}
            dueDate={dueDate ? new Date(dueDate) : null}
            text={text}
            comments={comments}
            showComments={showComments}
            setShowComments={setShowComments}
            onChangeDueDate={onChangeDueDate}
            onChangeText={onChangeText}
          />
        </Grid>
        <Grid item sm={2}>
          <Owner
            taskIsDone={taskIsDone}
            editMode={editMode}
            owner={owner}
            taskId={uuid}
            onChangeOwner={onChangeOwner}
            targetUserUuid={targetUserUuid}
          />
        </Grid>
      </Grid>
      {showComments && comments && (
        <ActionItemTaskComments
          visibleCommentsCount={visibleCommentsCount}
          comments={comments}
          actionItemTaskUuid={uuid}
          targetUserUuid={targetUserUuid}
        />
      )}
    </>
  )
}

export default ActionItemTask
