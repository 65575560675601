import React, { ReactElement } from 'react'

import {
  makeStyles,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core'
import cn from 'classnames'

import { ORDER_TYPES } from 'utils/constants'

// A custom version of the React-Table component. Useful when data is loaded on the backend
// and we have to perform all of the sorting/filtering options on the backend.
// Frontend loaded data should continue to use React-Table.
type Columns<SortBy extends string> = {
  label: string
  helpText?: string
  sortId?: SortBy
  tooltip?: string
}[]

export type Rows = {
  name: string
  value: any
}[][]

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    width: '100%',
  },
  table: {
    borderTop: `1px solid ${palette.common.iceGrey}`,
  },
  bicolor: {
    '&:nth-of-type(odd)': {
      backgroundColor: palette.common.navy05,
    },
  },
  headerCell: {
    paddingLeft: spacing(),
  },
  cellBorder: {
    borderLeft: `1px solid ${palette.common.iceGrey}`,
    borderRight: `1px solid ${palette.common.iceGrey}`,
  },
  pagination: {
    alignItems: 'center',
    '& >div': {
      display: 'flex',
      justifyContent: 'center',
      '& >button': {
        width: '100%',
      },
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  columnHelpText: {
    textTransform: 'none',
  },
}))

interface EnhancedTableHeadProps<SortBy extends string> {
  columns: Columns<SortBy>
  sortBy?: SortBy
  sortAscending?: boolean
  onSort?(sortBy: SortBy): void
  withCellsBorder: boolean
}
const EnhancedTableHead = <SortBy extends string, _>({
  columns,
  sortBy,
  sortAscending,
  onSort,
  withCellsBorder,
}: EnhancedTableHeadProps<SortBy>): ReactElement => {
  const classes = useStyles()
  const sortDirection = sortAscending ? ORDER_TYPES.ASCENDING : ORDER_TYPES.DESCENDING
  const defaultSort = ORDER_TYPES.ASCENDING

  const renderLabel = (column: Columns<SortBy>[0]) => {
    const label = (
      <>
        <span>{column.label}</span>
        {column.helpText && (
          <>
            <br />
            <small className={classes.columnHelpText}>{column.helpText}</small>
          </>
        )}
      </>
    )
    return (
      <Typography color="textSecondary">
        {column.tooltip ? <Tooltip title={column.tooltip}>{label}</Tooltip> : label}
      </Typography>
    )
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <TableCell
            className={cn({ [classes.cellBorder]: withCellsBorder }, classes.headerCell)}
            key={column.label}
            align="left"
            sortDirection={sortBy === column.sortId ? sortDirection : false}
          >
            {column.sortId ? (
              <TableSortLabel
                active={sortBy === column.sortId}
                direction={sortBy === column.sortId ? sortDirection : defaultSort}
                onClick={() => onSort && column.sortId && onSort(column.sortId)}
              >
                {renderLabel(column)}
                {sortBy === column.sortId ? (
                  <span className={classes.visuallyHidden}>
                    {sortAscending ? 'sorted ascending' : 'sorted descending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              renderLabel(column)
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

type PaginationProps = {
  page: number
  setPage(page: number): void
  totalRows: number
  rowsPerPage: number
}
const TablePagination: React.FC<PaginationProps> = ({ page, setPage, rowsPerPage, totalRows }) => {
  const classes = useStyles()
  const totalPages = Math.ceil(totalRows / rowsPerPage)
  return (
    <Grid container className={classes.pagination}>
      <Grid item xs={4}>
        <Button disabled={page === 0} onClick={() => setPage(page - 1)} color="primary">
          Previous
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Typography color="secondary">
          Page {page + 1} of {totalPages || 1}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Button
          disabled={rowsPerPage * (page + 1) >= totalRows}
          onClick={() => setPage(page + 1)}
          color="secondary"
        >
          Next
        </Button>
      </Grid>
    </Grid>
  )
}

type TableProps<SortBy extends string> = {
  columns: Columns<SortBy>
  rows: Rows
  usePagination?: boolean
  withCellsBorder?: boolean
  page?: number
  totalRows: number
  setPage?(page: number): void
  pageSize?: number
  bicolor?: boolean
  sortBy?: SortBy
  setSortBy?(sortBy: SortBy): void
  sortAscending?: boolean
  setSortAscending?(ascending: boolean): void
}
const EnhancedTable = <SortBy extends string, _>({
  columns,
  totalRows,
  rows,
  usePagination = true,
  withCellsBorder = true,
  page = 0,
  setPage,
  sortBy,
  setSortBy,
  sortAscending = true,
  setSortAscending,
  bicolor = false,
  pageSize = 25,
}: TableProps<SortBy>): ReactElement => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            withCellsBorder={withCellsBorder}
            columns={columns}
            sortBy={sortBy}
            sortAscending={sortAscending}
            onSort={newSortBy => {
              if (setPage) {
                setPage(0)
              }
              if (setSortBy) {
                setSortBy(newSortBy)
              }
              if (setSortAscending) {
                setSortAscending(newSortBy === sortBy ? !sortAscending : true)
              }
            }}
          />
          <TableBody>
            {rows.slice(pageSize * page, pageSize * (page + 1)).map((row, index) => {
              return (
                <TableRow
                  className={cn({ [classes.bicolor]: bicolor })}
                  hover
                  tabIndex={-1}
                  key={index}
                >
                  {row.map(({ value, name }) => (
                    <TableCell
                      className={cn({ [classes.cellBorder]: withCellsBorder })}
                      key={name}
                      component="td"
                      scope="row"
                      padding="default"
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {usePagination && setPage && (
        <TablePagination
          totalRows={totalRows}
          rowsPerPage={pageSize}
          page={page}
          setPage={setPage}
        />
      )}
    </div>
  )
}

export default EnhancedTable
