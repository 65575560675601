import React, { useState } from 'react'

import {
  makeStyles,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { format } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import Errors from 'components/Blocks/FormHelpers/Errors'
import { getDisabledSmsNotificationsMessage } from 'components/Survey/Wizard/Steps/Notifications/common'
import { gaEvent } from 'config/ga'
import {
  useSurveysUpdateScheduledNotificationMutation,
  ScheduledNotificationInput,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'
import { handleMutationResponse } from 'utils'
import { DEFAULT_USER_TIMEZONE } from 'utils/constants'
import { formatDateAsString, getReadableTimezoneString } from 'utils/dateUtils'

const useStyles = makeStyles(({ spacing }) => ({
  description: {
    marginTop: spacing(3),
  },
  checkboxes: {
    display: 'flex',
    '& div': {
      marginRight: spacing(5),
    },
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  when: {
    marginBottom: spacing(6),
  },
}))

const NOTIFICATION_WINDOW_START = 9
const NOTIFICATION_WINDOW_END = 17
export const isValidScheduledNotificationTime = (date: Date) => {
  // ScheduledNotifications must be set between 9 and 17 (EST) so people don't get disturbed with SMS
  // messages at late hours
  const dateInEST = utcToZonedTime(date, DEFAULT_USER_TIMEZONE)
  return (
    (dateInEST.getHours() < NOTIFICATION_WINDOW_END ||
      (dateInEST.getMinutes() === 0 && dateInEST.getHours() === NOTIFICATION_WINDOW_END)) &&
    dateInEST.getHours() >= NOTIFICATION_WINDOW_START
  )
}

export const getNotificationTimeError = () => {
  const now = new Date()

  const startInCurrentTz = zonedTimeToUtc(
    new Date(now.setHours(NOTIFICATION_WINDOW_START)),
    DEFAULT_USER_TIMEZONE,
  )
  const endInCurrentTz = zonedTimeToUtc(
    new Date(now.setHours(NOTIFICATION_WINDOW_END)),
    DEFAULT_USER_TIMEZONE,
  )

  const timeFormat = 'K aaa'
  return `Please change your notification time. Notifications must be set between ${format(
    startInCurrentTz,
    timeFormat,
  )}
  and ${format(endInCurrentTz, timeFormat)} (${getReadableTimezoneString()})`
}

type Props = {
  surveyUuid: string
  productType: SurveyProductTypeEnum
  surveyStartDate: Date
  maxScheduledNotificationDate: Date | null
  onClose(): void
  disabledDates: string[]
  disabledNewSmsNotifications: boolean
  surveyMaxSmsNotifications: number
}

const AddScheduledNotificationDialog: React.FC<Props> = ({
  surveyUuid,
  productType,
  surveyStartDate,
  maxScheduledNotificationDate,
  onClose,
  disabledDates,
  disabledNewSmsNotifications,
  surveyMaxSmsNotifications,
}) => {
  const classes = useStyles()
  const [when, setWhen] = useState<Date | null>(null)
  const [sms, setSms] = useState(false)
  const [workEmail, setWorkEmail] = useState(false)
  const [personalEmail, setPersonalEmail] = useState(false)
  const [updateScheduledNotification, { loading }] = useSurveysUpdateScheduledNotificationMutation()

  const onAdd = async (notification: ScheduledNotificationInput) => {
    gaEvent({
      action: 'addScheduledNotification',
      category: 'Surveys',
    })
    const result = await updateScheduledNotification({
      variables: {
        surveyUuid,
        notificationInput: {
          uuid: null,
          ...notification,
        },
      },
    })
    handleMutationResponse(result.data?.updateScheduledNotification?.errors)
  }

  const now = new Date()
  const minDate = now > surveyStartDate ? now : surveyStartDate
  const isDisabledSelectedWhen = () => {
    return when && disabledDates.includes(formatDateAsString(when))
  }
  return (
    <Dialog open>
      <DialogTitle id="email-notification-title">
        Schedule New Notification
        <Typography color="textSecondary" className={classes.description}>
          Schedule when the notification should be sent to participants and choose what type of
          notification to send.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.when}>
          <DateTimePicker
            format="E, LLL dd h:mm a"
            minDateMessage="Date and time cannot be before survey's start date"
            maxDateMessage="Date and time cannot be after survey's end date"
            minDate={minDate}
            maxDate={maxScheduledNotificationDate}
            shouldDisableDate={(date: Date | null) => {
              if (!date) return false
              const isDisabled = disabledDates.includes(formatDateAsString(date))
              if (isDisabled && formatDateAsString(date) === formatDateAsString(minDate)) {
                // Don't disable minDate (which will actually be today) even though it is among already scheduled notifications dates.
                // Instead, we show an error if the user tries to select the current date when there is already a notification scheduled.
                return false
              }
              return isDisabled
            }}
            id="when"
            label="Notification Time*"
            value={when}
            onChange={(date: Date | null) => {
              if (!date) return
              if (isValidScheduledNotificationTime(date)) {
                setWhen(date)
              } else {
                emitter.emit('ERROR', getNotificationTimeError())
              }
            }}
          />
          {isDisabledSelectedWhen() && (
            <Errors
              errors={[
                'There is already a notification set on this day, please choose another date.',
              ]}
            />
          )}
        </div>
        <Typography variant="body2" color="textSecondary">
          NOTIFICATION TYPE:
        </Typography>
        <div className={classes.checkboxes}>
          {productType === SurveyProductTypeEnum.EMPLOYEE && (
            <div className={classes.checkbox}>
              <Typography variant="body2" color="textSecondary">
                WORK EMAIL
              </Typography>{' '}
              <Checkbox checked={workEmail} onClick={() => setWorkEmail(!workEmail)} />
            </div>
          )}
          <div className={classes.checkbox}>
            <Typography variant="body2" color="textSecondary">
              {productType === SurveyProductTypeEnum.RESIDENT ? 'EMAIL' : 'PERSONAL EMAIL'}
            </Typography>
            <Checkbox checked={personalEmail} onClick={() => setPersonalEmail(!personalEmail)} />
          </div>
          <div className={classes.checkbox}>
            <Typography variant="body2" color="textSecondary">
              SMS
            </Typography>{' '}
            <Tooltip
              placement="top"
              title={
                disabledNewSmsNotifications
                  ? getDisabledSmsNotificationsMessage(surveyMaxSmsNotifications)
                  : ''
              }
            >
              <span>
                <Checkbox
                  disabled={disabledNewSmsNotifications}
                  checked={sms}
                  onClick={() => setSms(!sms)}
                />
              </span>
            </Tooltip>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondaryNoBackground">
          Cancel
        </Button>
        <SubmitButton
          disabled={!when || isDisabledSelectedWhen()}
          isSubmitting={loading}
          color="primary"
          onClick={() => {
            onAdd({ when: when?.toISOString(), sms, workEmail, personalEmail, active: true })
            onClose()
          }}
        >
          Save
        </SubmitButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddScheduledNotificationDialog
