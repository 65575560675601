import React from 'react'

import { makeStyles, Checkbox, Switch, Typography, Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { format } from 'date-fns'

import IconButton from 'components/Blocks/CustomButtons/IconButton'
import {
  SurveysIntervalNotificationFragment,
  SurveysScheduledNotificationFragment,
  IntervalNotificationInput,
  ScheduledNotificationInput,
  SurveyProductTypeEnum,
} from 'generated/graphql'

// Constant set by PMs
export const getDisabledSmsNotificationsMessage = (maxSmsNotifications: number) =>
  `You can only send ${maxSmsNotifications} SMS notifications per survey`

export const useStyles = makeStyles(({ palette, spacing }) => ({
  selectInput: {
    '&:after, &:before': {
      borderBottom: '0',
    },
  },
  notificationText: {
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    display: 'flex',
    alignItems: 'center',
    '& >p': {
      width: 90,
    },
  },
  tooltipIcon: {
    color: palette.error.main,
    display: 'inline-block',
    marginBottom: `-${spacing()}px`,
  },
  checkboxError: {
    color: palette.error.main,
  },
  greyEditIcon: {
    color: palette.common.grey,
  },
}))

export type Notification =
  | SurveysScheduledNotificationFragment
  | SurveysIntervalNotificationFragment

export type NotificationInput = IntervalNotificationInput | ScheduledNotificationInput

export const getNotificationRow = (
  classes: ReturnType<typeof useStyles>,
  notificationRowInput: {
    productType: SurveyProductTypeEnum
    notification: Notification
    surveyMaxSmsNotifications: number
    setNotificationToUpdate: (notification: Notification) => void
    setNotificationToDeactivate: (notification: Notification) => void
    handleSubmitScheduled?: (attrs: Partial<ScheduledNotificationInput>) => void
    handleSubmitInterval?: (attrs: Partial<IntervalNotificationInput>) => void
    disabledNewNotifications?: boolean
    disabledNewSmsNotifications?: boolean
    disableActivateNotification?: boolean
  },
) => {
  const {
    productType,
    notification,
    setNotificationToUpdate,
    setNotificationToDeactivate,
    handleSubmitScheduled,
    handleSubmitInterval,
    disabledNewNotifications,
    disabledNewSmsNotifications,
    surveyMaxSmsNotifications,
  } = notificationRowInput
  const isInterval = Boolean(handleSubmitInterval)
  const handleSubmit = handleSubmitScheduled || handleSubmitInterval
  if (!handleSubmit) {
    return []
  }

  let disableActivateNotification = notificationRowInput.disableActivateNotification
  if (disableActivateNotification === undefined) {
    disableActivateNotification = !notification.active && disabledNewNotifications
  }

  const disableActivateNotificationDueToMaxSms =
    !notification.active && notification.sms && disabledNewSmsNotifications
  let disableActivateNotificationMessage = ''
  if (disableActivateNotification) {
    disableActivateNotificationMessage = 'You have reached the limit for scheduling notifications.'
  } else if (disableActivateNotificationDueToMaxSms) {
    disableActivateNotificationMessage = getDisabledSmsNotificationsMessage(
      surveyMaxSmsNotifications,
    )
  }
  const dateFormat = 'E, LLL dd h:mm a'
  const disabled = !notification.active
  const rowValid = notification.workEmail || notification.personalEmail || notification.sms
  const rows = []
  if (productType === SurveyProductTypeEnum.RESIDENT) {
    rows.push({
      name: 'email',
      value: (
        <Checkbox
          checked={notification.personalEmail}
          disabled={disabled}
          onClick={() =>
            handleSubmit({ uuid: notification.uuid, personalEmail: !notification.personalEmail })
          }
        />
      ),
    })
  } else if (productType === SurveyProductTypeEnum.EMPLOYEE) {
    rows.push({
      name: 'workEmail',
      value: (
        <Checkbox
          checked={notification.workEmail}
          disabled={disabled}
          classes={{
            root: notification.active && !rowValid ? classes.checkboxError : undefined,
          }}
          onClick={() =>
            handleSubmit({ uuid: notification.uuid, workEmail: !notification.workEmail })
          }
        />
      ),
    })
    rows.push({
      name: 'personalEmail',
      value: (
        <Checkbox
          checked={notification.personalEmail}
          disabled={disabled}
          onClick={() =>
            handleSubmit({ uuid: notification.uuid, personalEmail: !notification.personalEmail })
          }
          classes={{
            root: notification.active && !rowValid ? classes.checkboxError : undefined,
          }}
        />
      ),
    })
  }
  return [
    ...rows,
    {
      name: 'sms',
      value: (
        <Tooltip
          placement="top"
          title={
            notification.active && !notification.sms && disabledNewSmsNotifications
              ? getDisabledSmsNotificationsMessage(surveyMaxSmsNotifications)
              : ''
          }
        >
          <span>
            <Checkbox
              checked={notification.sms}
              disabled={disabled || (!notification.sms && disabledNewSmsNotifications)}
              onClick={() => handleSubmit({ uuid: notification.uuid, sms: !notification.sms })}
              classes={{
                root: notification.active && !rowValid ? classes.checkboxError : undefined,
              }}
            />
          </span>
        </Tooltip>
      ),
    },
    {
      name: 'notificationText',
      value: (
        <div className={classes.notificationText}>
          <Typography color={disabled ? 'textSecondary' : 'textPrimary'}>
            Edit Notification Text
          </Typography>
          &nbsp;&nbsp;
          {!disabled && (
            <IconButton
              color="secondaryHover"
              onClick={() => !disabled && setNotificationToUpdate(notification)}
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
      ),
    },
    {
      name: 'status',
      value: (
        <div className={classes.active}>
          {isInterval ? (
            <Tooltip
              placement="top"
              title={
                !disabled && notification.firstSentTimestamp
                  ? format(new Date(notification.firstSentTimestamp), dateFormat)
                  : ''
              }
            >
              <Typography color={disabled ? 'textSecondary' : 'textPrimary'}>
                {disabled && 'Deactivated'}
                {!disabled &&
                  (notification.firstSentTimestamp
                    ? format(new Date(notification.firstSentTimestamp), dateFormat)
                    : 'Active')}
              </Typography>
            </Tooltip>
          ) : (
            <Typography color={disabled ? 'textSecondary' : 'textPrimary'}>
              {disabled && 'Deactivated'}
              {!disabled &&
                ((notification.firstSentTimestamp &&
                  format(new Date(notification.firstSentTimestamp), dateFormat)) ||
                  'Active')}
            </Typography>
          )}
          {!notification.firstSentTimestamp && (
            <Tooltip placement="top" title={disableActivateNotificationMessage}>
              <span>
                <Switch
                  checked={notification.active}
                  disabled={disableActivateNotification || disableActivateNotificationDueToMaxSms}
                  onChange={() =>
                    notification.active
                      ? setNotificationToDeactivate(notification)
                      : handleSubmit({ uuid: notification.uuid, active: true })
                  }
                />
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },
  ]
}

export const getColumns = (productType: SurveyProductTypeEnum) => {
  if (productType === SurveyProductTypeEnum.RESIDENT) {
    return ['when', 'email', 'sms', 'notification text', 'status']
  }
  if (productType === SurveyProductTypeEnum.EMPLOYEE) {
    return ['when', 'work email', 'personal email', 'sms', 'notification text', 'status']
  }
  return []
}
