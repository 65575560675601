import React, { useState } from 'react'

import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Tooltip,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import PropTypes from 'prop-types'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import Button from 'components/Blocks/CustomButtons/Button'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import AddCustomQuestionButton from 'components/Survey/Blocks/AddCustomQuestionButton'
import AddOptionalAndCustomButtons from 'components/Survey/Blocks/AddOptionalAndCustomButtons'
import { QuestionsAccordion, QuestionsByFocusAccordion } from 'components/Survey/Blocks/Questions'
import WelcomeMessagePanel from 'components/Survey/Blocks/WelcomeMessagePanel'
import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import MissingTranslationsWarning from 'components/Survey/Wizard/Steps/Questions/MissingTranslationsWarning'
import SurveyControls from 'components/Survey/Wizard/SurveyControls'
import { QCategory, SurveyTypeEnum } from 'generated/graphql'
import { pluralize } from 'utils'
import { QUESTION_FOCUSES } from 'utils/constants'

export const QUESTION_CATEGORIES_TO_LABEL = {
  STATEMENTS: 'Statements',
  DEMOGRAPHICS: 'Demographics',
  SENIOR_CARE_DEMOGRAPHICS: 'Aging Services Standard Demographics',
  OPEN_ENDED: 'Open-Ended Questions',
  OTHER: 'Other', // for custom surveys
}

const questionsCountByType = questionsByFocus => {
  let questionsNo
  if (questionsByFocus.length === 0) {
    questionsNo = 0
  } else if (questionsByFocus.length === 1) {
    questionsNo = questionsByFocus[0].questions.length
  } else {
    questionsNo = questionsByFocus.reduce((count, gq) => count + gq.questions.length, 0)
  }
  return `${questionsNo} ${pluralize('question', questionsNo)}`
}

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
    '& svg': {
      height: 18,
      marginLeft: theme.spacing(),
      marginBottom: -4,
      color: theme.palette.common.navy65,
    },
  },
  questionsByFocusCount: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  focusAnswerScale: {
    marginTop: theme.spacing(4),
    fontStyle: 'italic',
    fontWeight: '100',
  },
  accordionRow: {
    paddingLeft: 22,
    paddingTop: 14,
    paddingBottom: 14,
    borderTop: `1px solid ${theme.palette.common.navy25}`,
    '& >p': {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.common.navy65,
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const QuestionsSummary = ({ gq, categoryKey, statementsText, openEndedText }) => {
  const classes = useStyles()
  return (
    <GridContainer>
      <ItemGrid sm={4}>
        <Typography variant="h6">{QUESTION_CATEGORIES_TO_LABEL[categoryKey]}</Typography>
        <Typography className={classes.questionsByFocusCount} color="textSecondary">
          {questionsCountByType(gq.questionsByFocus)}
        </Typography>
      </ItemGrid>
      <ItemGrid sm={8}>
        {/* We can put these back in the database once we are sure of format and types of fields. */}
        {categoryKey === QCategory.STATEMENTS && (
          <>
            <Typography color="textSecondary">{statementsText}</Typography>
            <Typography className={classes.focusAnswerScale} color="textSecondary">
              Answer scale: Almost always untrue, Often untrue, Sometimes untrue/Sometimes true,
              Often true, Almost always true.
            </Typography>
          </>
        )}
        {categoryKey === QCategory.DEMOGRAPHICS && (
          <>
            <Typography color="textSecondary">
              Collect demographic information about your employees to ensure that your workplace
              experience is consistent across all employees - regardless of their gender, age, race,
              sexual orientation, ability level, tenure, work status, and more. This information is
              required to be certified as a Great Place to Work to ensure that your workplace is
              truly great for ALL employees.
            </Typography>
            <Typography className={classes.focusAnswerScale} color="textSecondary">
              Answer scale: Multiple-choice
            </Typography>
          </>
        )}
        {categoryKey === QCategory.SENIOR_CARE_DEMOGRAPHICS && (
          <>
            <Typography color="textSecondary">
              Capture department or role, level of care or type of service, and location in order to
              be able to compare your results to other similar organizations in your industry.
            </Typography>
            <Typography className={classes.focusAnswerScale} color="textSecondary">
              Answer scale: Multiple-choice
            </Typography>
          </>
        )}
        {categoryKey === 'OPEN_ENDED' && (
          <>
            <Typography color="textSecondary">{openEndedText}</Typography>
            <Typography className={classes.focusAnswerScale} color="textSecondary">
              Answer scale: Free form text
            </Typography>
          </>
        )}
      </ItemGrid>
    </GridContainer>
  )
}

const AccordionComponent = ({
  surveyType,
  surveyUuid,
  gq,
  categoryKey,
  handleOpenDeleteQuestion,
}) => {
  if (!gq.questionsByFocus.length) {
    return null
  }
  if (gq.questionsByFocus.length > 1 && surveyType === SurveyTypeEnum.TI) {
    return (
      <QuestionsByFocusAccordion
        surveyUuid={surveyUuid}
        handleOpenDeleteQuestion={handleOpenDeleteQuestion}
        category={gq.category}
        questionsByFocus={gq.questionsByFocus}
        focusTitleMap={QUESTION_FOCUSES}
      />
    )
  }
  return (
    <QuestionsAccordion
      surveyUuid={surveyUuid}
      key={categoryKey}
      gq={gq}
      handleOpenDeleteQuestion={handleOpenDeleteQuestion}
    />
  )
}

const getSurveyTypeConstants = surveyType => {
  switch (surveyType) {
    case SurveyTypeEnum.TI:
      return {
        title: 'TRUST INDEX SURVEY',
        tooltipText: `The Trust Index survey is used for Great Place to Work certification and gives a
        comprehensive view of workplace culture. This flagship assessment from Great Place to
        Work defines a great workplace as one where employees trust the people they work for,
        have pride in what they do, and enjoy the people they work with.`,
        statementsText: `The following statements measure workplace culture, focusing on five areas:
        credibility, respect, fairness, pride, and camaraderie.`,
        openEndedText: `Employees can share additional feedback on workplace culture by leaving
        comments. These comments are available in our Analyze, Engagement, and
        Culture solutions.`,
      }
    case SurveyTypeEnum.PULSE:
      return {
        title: 'PULSE SURVEY',
        tooltipText: `Pulse Surveys can tell you how employees are feeling about key statements without having
        to do a full Trust Index Survey. They are faster and typically more targeted to a subset of employees.`,
        statementsText: (
          <span>
            We recommend including 3-5 statements on each pulse survey. Here are the statements we
            suggest including:
            <br />
            <br />
            1. Your driving statement — the best for gauging overall sentiment.
            <br />
            2. The top statement from your moveable middle. <br />
            3. The required statement on all pulse surveys to see trends over time.
          </span>
        ),
        openEndedText: `Ask your employees if they have noticed any changes since your last survey.
        Feel free to add additional open-ended questions or edit this one!`,
      }
    default:
      return null
  }
}

const Questions = ({
  questions,
  goBack,
  goNext,
  openDeleteQuestion,
  handleOpenDeleteQuestion,
  handleCloseDeleteQuestion,
  survey,
  handleDeleteQuestion,
}) => {
  const classes = useStyles()
  const [isOpenTranslationsDialog, setIsOpenTranslationsDialog] = useState(false)

  // Some survey types should display a category even when there are no questions.
  const allowedCategories = {
    [SurveyTypeEnum.TI]: [
      QCategory.STATEMENTS,
      QCategory.DEMOGRAPHICS,
      QCategory.SENIOR_CARE_DEMOGRAPHICS,
      QCategory.OPEN_ENDED,
    ],
    [SurveyTypeEnum.PULSE]: [QCategory.STATEMENTS, QCategory.OPEN_ENDED],
    [SurveyTypeEnum.CUSTOM]: [QCategory.OTHER],
  }[survey.type]
  const { title, tooltipText, statementsText, openEndedText } = getSurveyTypeConstants(survey.type)
  return (
    <>
      <SurveyControls
        survey={survey}
        isOpenTranslationsDialog={isOpenTranslationsDialog}
        setIsOpenTranslationsDialog={setIsOpenTranslationsDialog}
        showPreview
        showTranslations
      />
      <Typography className={classes.title} variant="subtitle1">
        {title}
        <Tooltip title={tooltipText}>
          <InfoIcon />
        </Tooltip>
      </Typography>
      <WelcomeMessagePanel survey={survey} />
      {allowedCategories.map((categoryKey, index) => {
        // Use an empty mock object when there are no questions for a given category.
        const gq = questions[categoryKey] ? questions[categoryKey][0] : { questionsByFocus: [] }
        if (!gq.questionsByFocus.length) {
          return null
        }
        return (
          <FormPanel
            key={categoryKey}
            expandIcon={EXPAND_ICON.ARROW}
            removeDetailsPadding
            gutterBottom={index === allowedCategories - 1}
            summary={
              <QuestionsSummary
                classes={classes}
                gq={gq}
                categoryKey={categoryKey}
                statementsText={statementsText}
                openEndedText={openEndedText}
              />
            }
          >
            <AccordionComponent
              surveyType={survey.type}
              surveyUuid={survey.uuid}
              gq={gq}
              categoryKey={categoryKey}
              handleOpenDeleteQuestion={handleOpenDeleteQuestion}
            />
            <div className={classes.accordionRow}>
              {survey.type === SurveyTypeEnum.TI && (
                <AddCustomQuestionButton
                  survey={survey}
                  questionCategory={categoryKey}
                  showIcon
                  buttonLabel={
                    categoryKey === QCategory.STATEMENTS
                      ? 'Add a custom statement'
                      : 'Add a custom question'
                  }
                />
              )}
              {survey.type === SurveyTypeEnum.PULSE && (
                <AddOptionalAndCustomButtons
                  survey={survey}
                  questionCategory={categoryKey}
                  selectedQuestions={Object.values(gq.questionsByFocus).flatMap(
                    group => group.questions,
                  )}
                />
              )}
            </div>
          </FormPanel>
        )
      })}
      {openDeleteQuestion && (
        <Dialog open fullWidth>
          <DialogTitle>Delete Question?</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this question?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteQuestion} color="secondaryNoBackground">
              Cancel
            </Button>
            <Button onClick={handleDeleteQuestion} variant="contained" color="danger">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div className={classes.footer}>
        {survey.hasMissingTranslations ? (
          <MissingTranslationsWarning onOpen={() => setIsOpenTranslationsDialog(true)} />
        ) : (
          <div />
        )}
        <NavigationButtons goBack={goBack} goNext={goNext} />
      </div>
    </>
  )
}

Questions.propTypes = {
  survey: PropTypes.object.isRequired,
  goNext: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
}

export default Questions
