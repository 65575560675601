import { TaskStatusEnum } from 'generated/graphql'

export enum ActionPlanTabs {
  AP = 'currentActionPlan',
  COMPLETED_STATEMENTS = 'completedStatements',
}

export enum AddStatementsSteps {
  CHOOSE_STATEMENTS = 'chooseStatements',
  PICK_STATEMENTS_STATE = 'pickState',
}

export const taskStatuses = [
  {
    value: TaskStatusEnum.NOT_STARTED,
    text: 'Not Started',
    countsAttr: 'notStarted',
  },
  {
    value: TaskStatusEnum.IN_PROGRESS,
    text: 'In Progress',
    countsAttr: 'inProgress',
  },
  {
    value: TaskStatusEnum.ON_HOLD,
    text: 'On Hold',
    countsAttr: 'onHold',
  },
  {
    value: TaskStatusEnum.COMPLETE,
    text: 'Complete',
    countsAttr: 'complete',
  },
  {
    value: TaskStatusEnum.INCOMPLETE,
    text: 'Later',
    countsAttr: 'incomplete',
  },
]

export const PIE_COLORS = {
  NOT_STARTED: '#d6dee2',
  IN_PROGRESS: '#52d9d0',
  ON_HOLD: '#ffeb67',
  COMPLETE: '#2ba141',
  INCOMPLETE: '#f27800',
}
