import React from 'react'

import { Tooltip, TooltipProps, makeStyles } from '@material-ui/core'
import shadows from '@material-ui/core/styles/shadows'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.common.navy,
    color: theme.palette.common.white,
    padding: '4px 8px',
    fontSize: '1.4rem',
    maxWidth: 500,
    boxShadow: shadows[2],
  },
  tooltipHover: {
    display: 'flex',
    '& >p': {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  },
}))

/** Used for tooltips that provide info over an action button */
const ActionTooltip: React.FC<TooltipProps> = props => {
  const { title, ...restProps } = props
  const classes = useStyles()
  return (
    <Tooltip
      classes={{ tooltip: classes.root }}
      {...restProps}
      title={<div className={classes.tooltipHover}>{title}</div>}
    />
  )
}

export default ActionTooltip
