import React, { useState, useContext } from 'react'

import { makeStyles, Tabs, Tab, Chip, Typography } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import StarIconUnfilled from '@material-ui/icons/StarBorder'
import capitalize from 'lodash/capitalize'
import orderBy from 'lodash/orderBy'

import Button from 'components/Blocks/CustomButtons/Button'
import SortButton from 'components/Blocks/CustomButtons/SortButton'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import SearchPopup from 'components/Blocks/Search/SearchPopup'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import NewSurveyButton from 'components/Survey/Dashboard/NewSurveyButton'
import SurveyCard, { SurveyNode } from 'components/Survey/Dashboard/SurveyCard'
import SurveyFilterSelect, { SurveyFilterTab } from 'components/Survey/Dashboard/SurveyFilterSelect'
import { LocalStore, defaultStore, StoreContext } from 'config/LocalStorage'
import { SurveyTypeEnum } from 'generated/graphql'
import { getSurveyTypeLabel, pluralize, toggleListWithAllType } from 'utils'
import { ORDER_TYPES } from 'utils/constants'

const useStyles = makeStyles(({ palette, spacing }) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${palette.common.navy25}`,
    '& >button': {
      height: 34,
    },
  },
  searchPaperWrapper: {
    position: 'absolute',
    padding: `0 ${spacing(2)}`,
    right: spacing(2),
  },
  searchButton: {
    display: 'flex',
    alignItems: 'center',
    '& >svg': {
      marginLeft: 10,
    },
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: spacing(),
    paddingBottom: spacing(),
  },
  controls: {
    display: 'flex',
    position: 'relative',
  },
  appliedFiltersRow: {
    display: 'flex',
    marginBottom: spacing(2),
    alignItems: 'center',
    '& >p': {
      marginRight: spacing(),
    },
  },
  clearFiltersButton: {
    marginLeft: 0,
    paddingLeft: spacing(),
  },
  toggleChipActive: {
    color: palette.common.white,
    marginRight: spacing(),
    background: palette.common.secondary,
    '&:focus': {
      backgroundColor: palette.common.secondary,
    },
    '&:hover': {
      backgroundColor: palette.common.secondary20,
    },
  },
  title: {
    marginBottom: spacing(2),
  },
  emptyStateIcon: {
    color: palette.common.navy65,
    height: 50,
    width: 50,
  },
}))

type Surveys = (undefined | null | SurveyNode)[]
export enum SURVEY_ORDER_TYPES {
  MOST_RECENT_START_DATE = 'startDate',
  MOST_RECENT_END_DATE = 'endDate',
  A_TO_Z = 'aToZ',
  Z_TO_A = 'zToA',
}

export const ALL_SURVEYS = 'all'

export const getFilteredSurveys = (
  surveys: Surveys,
  filterSettings: LocalStore['surveyDashboardFilterSettings'],
  currentSort: SURVEY_ORDER_TYPES,
  searchQuery: string,
) => {
  let filteredSurveys = surveys
  if (!filterSettings[SurveyFilterTab.SURVEY_TYPE].includes(ALL_SURVEYS)) {
    filteredSurveys = filteredSurveys.filter(
      s => s && filterSettings[SurveyFilterTab.SURVEY_TYPE].includes(s.type),
    )
  }
  if (!filterSettings[SurveyFilterTab.STATUS].includes(ALL_SURVEYS)) {
    filteredSurveys = filteredSurveys.filter(
      s => s && filterSettings[SurveyFilterTab.STATUS].includes(s.status),
    )
  }
  if (searchQuery) {
    filteredSurveys = filteredSurveys.filter(s =>
      s?.name.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }
  let sort: string = currentSort
  let order = ORDER_TYPES.DESCENDING
  if (currentSort === SURVEY_ORDER_TYPES.A_TO_Z) {
    sort = 'name'
    order = ORDER_TYPES.ASCENDING
  } else if (currentSort === SURVEY_ORDER_TYPES.Z_TO_A) {
    sort = 'name'
    order = ORDER_TYPES.DESCENDING
  }
  return orderBy(filteredSurveys, sort, order)
}

interface Props {
  surveys: Surveys
  surveyTypesAllowedToAdd: Array<SurveyTypeEnum>
  favoriteSurveyIds: string[]
}

export const SurveyDashboard: React.FC<Props> = ({
  surveys: initialSurveys,
  surveyTypesAllowedToAdd,
  favoriteSurveyIds,
}) => {
  const tabs: Array<'All' | 'Favorites'> = ['All', 'Favorites']
  const [currentTab, setCurrentTab] = useState(tabs[0])
  const classes = useStyles()
  const {
    store: { surveyDashboardFilterSettings },
    updateStore,
  } = useContext(StoreContext)
  const [searchQuery, setSearchQuery] = useState('')
  const availableSorts = Object.values(SURVEY_ORDER_TYPES)
  const [currentSort, setCurrentSort] = useState(availableSorts[0])

  // copying surveys because it's bug prone to alter surveys and then counting on it to be the same on a second render
  const surveys = getFilteredSurveys(
    initialSurveys,
    surveyDashboardFilterSettings,
    currentSort,
    searchQuery,
  )

  const sortLabelsMap = {
    [SURVEY_ORDER_TYPES.A_TO_Z]: 'A to Z',
    [SURVEY_ORDER_TYPES.Z_TO_A]: 'Z to A',
    [SURVEY_ORDER_TYPES.MOST_RECENT_START_DATE]: 'Most recent Start Date',
    [SURVEY_ORDER_TYPES.MOST_RECENT_END_DATE]: 'Most recent End Date',
  }
  const appliedFilters = [
    ...surveyDashboardFilterSettings[SurveyFilterTab.SURVEY_TYPE].map(value => ({
      value,
      key: SurveyFilterTab.SURVEY_TYPE,
    })),
    ...surveyDashboardFilterSettings[SurveyFilterTab.STATUS].map(value => ({
      value,
      key: SurveyFilterTab.STATUS,
    })),
  ].filter(appliedFilter => appliedFilter.value !== ALL_SURVEYS)
  let activeSurveys = surveys
  if (currentTab === 'Favorites') {
    activeSurveys = surveys.filter(s => s && favoriteSurveyIds.includes(s.uuid))
  }
  return (
    <>
      <div className={classes.header}>
        <Tabs value={tabs.indexOf(currentTab)} onChange={(e, value) => setCurrentTab(tabs[value])}>
          {tabs.map(tabName => (
            <Tab key={tabName} label={tabName} />
          ))}
        </Tabs>
        <NewSurveyButton surveyTypesAllowedToAdd={surveyTypesAllowedToAdd} />
      </div>
      <div className={classes.subHeader}>
        <Typography variant="body1" color="textSecondary">
          {activeSurveys.length} {pluralize('Survey', activeSurveys.length)}
        </Typography>
        <div className={classes.controls}>
          <SurveyFilterSelect />
          <SortButton
            availableOptions={availableSorts}
            currentOption={currentSort}
            onChange={val => setCurrentSort(val as SURVEY_ORDER_TYPES)}
            labelsMap={sortLabelsMap}
          />
          <SearchPopup
            searchQuery={searchQuery}
            handleSearch={(query: string) => setSearchQuery(query)}
          />
        </div>
      </div>
      {appliedFilters.length > 0 && (
        <div className={classes.appliedFiltersRow}>
          <Typography>{appliedFilters.length} Filters:</Typography>
          {appliedFilters.map(({ value, key }) => {
            const onDelete = () => {
              const existingList = surveyDashboardFilterSettings[key]
              const updatedList = existingList.filter(val => val !== value)
              return updateStore({
                surveyDashboardFilterSettings: {
                  ...surveyDashboardFilterSettings,
                  // Last arg "maxItems" is arbitrary/unused since we're only removing here
                  // and it would be complex to get the correct number in this component.
                  [key]: toggleListWithAllType(existingList, updatedList, ALL_SURVEYS, 10),
                },
              })
            }
            let label
            if (key === SurveyFilterTab.SURVEY_TYPE) {
              const surveyType = value as SurveyTypeEnum
              label = getSurveyTypeLabel(surveyType)
            } else if (key === SurveyFilterTab.STATUS) {
              label = capitalize(value)
            }
            return (
              <Chip
                className={classes.toggleChipActive}
                onClick={onDelete}
                onDelete={onDelete}
                key={value}
                label={label}
              />
            )
          })}
          <Button
            color="secondaryNoBackground"
            className={classes.clearFiltersButton}
            onClick={() =>
              updateStore({
                surveyDashboardFilterSettings: defaultStore.surveyDashboardFilterSettings,
              })
            }
          >
            Clear All
          </Button>
        </div>
      )}
      <Typography variant="h5" className={classes.title}>
        {currentTab}
      </Typography>
      {activeSurveys.length === 0 &&
        (currentTab === 'Favorites' ? (
          <EmptyState
            title="Add surveys to your favorites"
            description="Add any survey to your favorites page to quickly find later."
            Icon={StarIconUnfilled}
            iconClassName={classes.emptyStateIcon}
          />
        ) : (
          <EmptyState
            title="No results found"
            description="Update your filters for better results."
            Icon={BlockIcon}
            iconClassName={classes.emptyStateIcon}
          />
        ))}
      <GridContainer>
        {activeSurveys.map(
          survey =>
            survey && (
              <SurveyCard
                key={survey.uuid}
                survey={survey}
                isFavorite={favoriteSurveyIds.includes(survey.uuid)}
              />
            ),
        )}
      </GridContainer>
    </>
  )
}

export default SurveyDashboard
