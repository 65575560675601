import React from 'react'

import { Card, Switch, Typography } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import useSettingsStyles from 'components/Settings/General/OrgSettingsStyles'
import { OrganizationInput } from 'generated/graphql'

type Props = {
  inputOrg: OrganizationInput
  setInputOrg(organization: OrganizationInput): void
  onSubmit(): void
  loading: boolean
}
const SetupTab: React.FC<Props> = ({ inputOrg, loading, setInputOrg, onSubmit }) => {
  const classes = useSettingsStyles()
  return (
    <ValidatorForm instantValidate onSubmit={() => onSubmit()}>
      <>
        <Card className={classes.card}>
          <Typography variant="h6" className={classes.title}>
            Initial Set Up
          </Typography>
          <GridContainer>
            <ItemGrid sm={8}>
              <TextValidator
                className={classes.textValidator}
                name="name"
                type="text"
                fullWidth
                value={inputOrg.name}
                label="Organization Name"
                placeholder="Organization Name"
                onChange={e =>
                  setInputOrg({ ...inputOrg, name: (e.target as HTMLInputElement).value })
                }
                validators={['required']}
                errorMessages={['Please provide a name for your organization']}
              />
            </ItemGrid>
          </GridContainer>
          {[
            ['seniorLiving', 'Does your organization provide senior living facilities?'],
            [
              'atHome',
              'Does your organization primarily provide care to seniors who do not live in your buildings?',
            ],
            ['isCcrc', 'Does your organization include CCRC or LifePlan communities?'],
            ['multipleLocations', 'Does your organization have more than one location?'],
          ].map(([orgAttr, label]) => (
            <div key={orgAttr}>
              <FormControlLabel
                control={
                  <Switch
                    name={orgAttr}
                    checked={Boolean(inputOrg[orgAttr as keyof OrganizationInput])}
                    onChange={() =>
                      setInputOrg({
                        ...inputOrg,
                        [orgAttr]: !inputOrg[orgAttr as keyof OrganizationInput],
                      })
                    }
                    color="secondary"
                  />
                }
                label={label}
              />
            </div>
          ))}
        </Card>
        <SubmitButton className={classes.saveButton} isSubmitting={loading} right color="primary">
          Save
        </SubmitButton>
      </>
    </ValidatorForm>
  )
}

export default SetupTab
