import React, { useState, useContext } from 'react'

import { Tab, Tabs, Popover, makeStyles, Typography, Checkbox } from '@material-ui/core'
import FilterIcon from '@material-ui/icons/FilterList'
import capitalize from 'lodash/capitalize'
import flatten from 'lodash/flatten'

import Button from 'components/Blocks/CustomButtons/Button'
import { ALL_SURVEYS } from 'components/Survey/Dashboard/SurveyDashboard'
import { StoreContext, defaultStore } from 'config/LocalStorage'
import { SurveyStatusEnum, SurveyProductTypeEnum } from 'generated/graphql'
import { getSurveyTypeLabel, toggleListWithAllType } from 'utils'
import { getSurveyTypesByProductType } from 'utils/constants'

const useStyles = makeStyles(({ palette, spacing }) => ({
  header: {
    padding: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >p': {
      textTransform: 'uppercase',
    },
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  box: {
    padding: spacing(2),
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  checkboxRow: {
    display: 'flex',
    alignItems: 'center',
    '& >span': {
      paddingLeft: 0,
    },
  },
}))

export enum SurveyFilterTab {
  SURVEY_TYPE = 'Survey Type',
  STATUS = 'Status',
}

const SurveyFilterSelect: React.FC = () => {
  const classes = useStyles()
  const tabNames: SurveyFilterTab[] = [SurveyFilterTab.SURVEY_TYPE, SurveyFilterTab.STATUS]
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const [activeTab, setActiveTab] = useState<SurveyFilterTab>(tabNames[0])
  const {
    updateStore,
    store: { surveyDashboardFilterSettings },
  } = useContext(StoreContext)

  const tabContentData: {
    [key in SurveyFilterTab]: {
      pluralName: string
      sections: Array<{
        title?: string
        options: Array<{ label: string; value: string }>
      }>
    }
  } = {
    [SurveyFilterTab.SURVEY_TYPE]: {
      sections: [
        {
          title: 'Employee',
          options: getSurveyTypesByProductType(SurveyProductTypeEnum.EMPLOYEE).map(surveyType => ({
            label: getSurveyTypeLabel(surveyType),
            value: surveyType,
          })),
        },
        {
          title: 'Resident',
          options: getSurveyTypesByProductType(SurveyProductTypeEnum.RESIDENT).map(surveyType => ({
            label: getSurveyTypeLabel(surveyType),
            value: surveyType,
          })),
        },
      ],
      pluralName: 'Survey Types',
    },
    [SurveyFilterTab.STATUS]: {
      sections: [
        {
          options: Object.values(SurveyStatusEnum).map(value => ({
            label: capitalize(value),
            value,
          })),
        },
      ],
      pluralName: 'Statuses',
    },
  }
  return (
    <>
      <Button
        color="textSecondaryNoBackground"
        onClick={e => setAnchorEl(e.target as Element)}
        id="overlay-services-filter"
      >
        <span>Filter</span>
        <FilterIcon />
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Tabs
          value={tabNames.indexOf(activeTab)}
          onChange={(e, value) => setActiveTab(tabNames[value])}
        >
          {tabNames.map(tabName => {
            if (!tabName) return <></>
            return <Tab key={tabName} label={tabName} />
          })}
        </Tabs>
        <div className={classes.header}>
          <Typography variant="h6">{activeTab}</Typography>
          <Button
            color="secondaryNoBackground"
            onClick={() =>
              updateStore({
                surveyDashboardFilterSettings: defaultStore.surveyDashboardFilterSettings,
              })
            }
          >
            Reset Filters
          </Button>
        </div>
        <div className={classes.box}>
          <div className={classes.checkboxRow}>
            <Checkbox
              checked={surveyDashboardFilterSettings[activeTab].includes(ALL_SURVEYS)}
              onClick={() =>
                updateStore({
                  surveyDashboardFilterSettings: {
                    ...surveyDashboardFilterSettings,
                    [activeTab]: [ALL_SURVEYS],
                  },
                })
              }
            />
            <Typography>All {tabContentData[activeTab].pluralName}</Typography>
          </div>
        </div>
        <div className={classes.box}>
          {tabContentData[activeTab].sections.map((section, idx) => {
            return (
              <div key={activeTab + idx}>
                {section.title && <Typography>{section.title}</Typography>}
                {section.options.map(option => {
                  const isChecked = surveyDashboardFilterSettings[activeTab].includes(option.value)
                  return (
                    <div key={option.label} className={classes.checkboxRow}>
                      <Checkbox
                        checked={isChecked}
                        onClick={() => {
                          let listToUpdate = surveyDashboardFilterSettings[activeTab]
                          if (isChecked) {
                            listToUpdate = listToUpdate.filter(opt => opt !== option.value)
                          } else {
                            listToUpdate = [...listToUpdate, option.value]
                          }
                          const numOptions = flatten(
                            tabContentData[activeTab].sections.map(sec => sec.options),
                          ).length
                          updateStore({
                            surveyDashboardFilterSettings: {
                              ...surveyDashboardFilterSettings,
                              [activeTab]: toggleListWithAllType(
                                surveyDashboardFilterSettings[activeTab],
                                listToUpdate,
                                ALL_SURVEYS,
                                numOptions,
                              ),
                            },
                          })
                        }}
                      />
                      <Typography>{option.label}</Typography>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </Popover>
    </>
  )
}

export default SurveyFilterSelect
