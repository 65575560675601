import React, { useState, useEffect } from 'react'

import { Typography, IconButton, makeStyles, Tooltip, Paper, Grid } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import snakeCase from 'lodash/snakeCase'

import TablePanel from 'components/Blocks/Accordions/TablePanel'
import Button from 'components/Blocks/CustomButtons/Button'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import SimpleSearch from 'components/Blocks/Search/SimpleSearch'
import DownloadButton from 'components/Insights/Blocks/DownloadButton'
import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import DeleteParticipantContainer from 'components/Survey/Wizard/Steps/Participants/DeleteParticipantContainer'
import EmployeeUploadHeader from 'components/Survey/Wizard/Steps/Participants/EmployeeUploadHeader'
import ParticipantUploadErrors from 'components/Survey/Wizard/Steps/Participants/ParticipantUploadErrors'
import ParticipantUploadSummary from 'components/Survey/Wizard/Steps/Participants/ParticipantUploadSummary'
import UpdateParticipantContainer from 'components/Survey/Wizard/Steps/Participants/UpdateParticipantContainer'
import SurveyControls from 'components/Survey/Wizard/SurveyControls'
import { gaEvent } from 'config/ga'
import {
  ImportTypesEnum,
  useSurveysIngestParticipantsUploadMutation,
  useSurveysParticipantsBySurveyQuery,
  SurveysSurveyQuery,
  SurveyStatusEnum,
  useSurveysParticipantsUploadQuery,
  UserDownloadsEnum,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'
import { CONTACT_EMAIL, SURVEY_TYPE_TO_DEFAULT_LABEL } from 'utils/constants'
import { Participants } from 'utils/types'

const useStyles = makeStyles(({ spacing }) => ({
  addButton: {
    marginLeft: 'auto',
  },
  navButtons: {
    marginTop: spacing(2),
  },
  tableSummary: {
    padding: spacing(3),
  },
}))

type Props = {
  survey: SurveysSurveyQuery['survey']
  goBack?(): void
  goNext?(uuid?: string): void
}

const EmployeeParticipants: React.FC<Props> = ({ survey, goBack, goNext }) => {
  const { status, uuid: surveyUuid } = survey
  const pageSize = 20
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [sortBy, setSortBy] = useState('survey_code')
  const [sortDescending, setSortDescending] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [updateDialogIsOpen, setUpdateDialogIsOpen] = useState(false)
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)
  const [editParticipantId, setEditParticipantId] = useState<string | null>(null)

  const [ingestParticipantsUpload] = useSurveysIngestParticipantsUploadMutation({
    variables: { surveyUuid, importType: ImportTypesEnum.PARTICIPANTS },
  })

  const uploadQueryVars = { surveyUuid }
  const uploadResult = useSurveysParticipantsUploadQuery({
    variables: uploadQueryVars,
    fetchPolicy: 'network-only',
  })

  const surveyParticipantQueryVars = {
    surveyUuid,
    pageSize,
    page,
    sortDescending,
    sortBy,
    search: searchQuery,
  }
  const result = useSurveysParticipantsBySurveyQuery({
    variables: surveyParticipantQueryVars,
    // Don't use the cache in case an admin updates the participant file.
    // Additionally, queries are cached with variables on the backend
    fetchPolicy: 'network-only',
  })

  let preventParticipantDeletionMessage = ''
  let disableActionSamplePoolMessage = ''
  if ([SurveyStatusEnum.LIVE, SurveyStatusEnum.CLOSED].includes(status)) {
    preventParticipantDeletionMessage = `Participants can't be deleted when a survey is live or closed. Contact ${CONTACT_EMAIL} if you need assistance."`
  } else if (survey.usesSamplePoolDataSource) {
    disableActionSamplePoolMessage = `Participant cannot be modified from surveys that source data from a sample pool. Contact ${CONTACT_EMAIL} if you need assistance.`
    preventParticipantDeletionMessage = disableActionSamplePoolMessage
  }

  useEffect(() => {
    // If there were errors we need to fetch the errors to display to the user.
    emitter.addListener('PARTICIPANT-FILE-PARSE-FAILED', uploadResult.refetch)
    // If we successfully parsed the file, fetch the summary.
    emitter.addListener('PARTICIPANT-FILE-PARSE-SUCCEEDED', uploadResult.refetch)
    // If we successfully ingested the file, fetch the participants and updated summary.
    emitter.addListener('PARTICIPANT-INGEST-SUCCEEDED', () => {
      uploadResult.refetch(uploadQueryVars)
      result.refetch(surveyParticipantQueryVars)
    })
    return function cleanup() {
      emitter.removeAllListeners('PARTICIPANT-FILE-PARSE-FAILED')
      emitter.removeAllListeners('PARTICIPANT-FILE-PARSE-SUCCEEDED')
      emitter.removeAllListeners('PARTICIPANT-INGEST-SUCCEEDED')
    }
  }, [result, surveyParticipantQueryVars, uploadQueryVars, uploadResult])

  const ingestParticipantsUploadHandler = async () => {
    const theResult = await ingestParticipantsUpload()
    if (theResult.data?.ingestParticipantsUpload) {
      emitter.emit(
        'SUCCESS',
        `Participants from your file are being created or updated.
        You’ll receive a notification when the data is processed.`,
        50000, // 50 seconds unless the user exits out
      )
    }
  }

  const getParticipantsData = (participants: Participants) =>
    participants.map((p, idx) => ({
      ...p,
      actions: (
        <div>
          <IconButton
            onClick={() => {
              setEditParticipantId(p.uuid)
              setUpdateDialogIsOpen(true)
            }}
          >
            <EditIcon id={`edit-participant-${idx}`} />
          </IconButton>
          {preventParticipantDeletionMessage ? (
            <Tooltip title={preventParticipantDeletionMessage}>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              onClick={() => {
                setEditParticipantId(p.uuid)
                setDeleteDialogIsOpen(true)
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      ),
    }))

  return (
    <ResponseHandler {...result}>
      {({ participantsBySurvey, survey: participantsSurvey }, { loading }) => {
        const { participants } = participantsBySurvey
        const templateUrl = participantsSurvey.participantTemplateUrl
        return (
          <ResponseHandler {...uploadResult}>
            {({ survey: { participantsUpload } }) => {
              const { errors, fileName, summary, updated } = participantsUpload || {
                errors: [],
              }
              const onCreationStep = !errors.length && summary && !summary.processed
              let goNextLabel = 'Next'
              if (onCreationStep) {
                goNextLabel =
                  participants.length > 0 ? 'Update Participants' : 'Create Participants'
              }
              const goNextFn = onCreationStep ? ingestParticipantsUploadHandler : goNext
              const hasErrors = errors.length > 0
              return (
                <>
                  <SurveyControls survey={survey} />
                  {survey.usesSamplePoolDataSource ? (
                    <>
                      <Paper className={classes.tableSummary}>
                        <Typography>
                          Participant Uploads for {SURVEY_TYPE_TO_DEFAULT_LABEL[survey.type]}{' '}
                          Surveys are handled directly by the Customer Team.
                        </Typography>
                        <br />
                        <DownloadButton
                          title="Download Participant Import Errors"
                          id="dowload-participant-import-errors"
                          downloadType={UserDownloadsEnum.PARTICIPANT_FAILURE_REPORT_EXPORT}
                          surveyUuid={surveyUuid}
                        />
                      </Paper>
                    </>
                  ) : (
                    <EmployeeUploadHeader
                      updated={updated}
                      hasErrors={hasErrors}
                      fileName={fileName}
                      summary={summary}
                      templateUrl={templateUrl}
                      surveyUuid={surveyUuid}
                    />
                  )}
                  {hasErrors && (
                    <ParticipantUploadErrors errors={errors} templateUrl={templateUrl} />
                  )}
                  {summary && !summary.processed && !hasErrors && (
                    <ParticipantUploadSummary
                      summary={summary}
                      isUpdating={participants.length > 0}
                    />
                  )}
                  {participants.length > 0 && (
                    <TablePanel
                      title="Survey Participants"
                      actions={
                        // Participant cannot be added directly on sample-pool based surveys
                        !survey.usesSamplePoolDataSource ? (
                          <Button
                            id="add-more-participants"
                            className={classes.addButton}
                            onClick={() => {
                              setEditParticipantId(null)
                              setUpdateDialogIsOpen(true)
                            }}
                            color="secondaryNoBackground"
                          >
                            Add more Participants
                          </Button>
                        ) : (
                          <div />
                        )
                      }
                      helpText={
                        status === SurveyStatusEnum.LIVE
                          ? ''
                          : `Please review the participants in the table below for accuracy.
                        You can make edits to participants until your survey launches and
                        add new participants up until your survey closes.`
                      }
                      gutterBottom
                      reactTableProps={{
                        columns: [
                          {
                            Header: 'Survey Code',
                            accessor: 'surveyCode',
                          },
                          {
                            Header: 'Work Email',
                            accessor: 'workEmail',
                          },
                          {
                            Header: 'Personal Email',
                            accessor: 'personalEmail',
                          },
                          {
                            Header: 'Mobile Phone',
                            accessor: 'mobilePhone',
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                          },
                        ],
                        loading: Boolean(loading),
                        data: getParticipantsData(participants),
                        pageSize,
                        // Sorting is done on the backend by overriding the "onSortedChange"
                        // so we don't actually want the table to sort frontend.
                        // We should probably look at using a custom table when a table moves
                        // pagination backend..but for now it's easier to use this lib and keep design consistent.
                        manual: true,
                        resizable: false,
                        page,
                        pages: Math.ceil(participantsBySurvey.total / pageSize),
                        onPageChange: (newPage: number) => setPage(newPage),
                        showPageJump: false,
                        onSortedChange: (sortList: { desc: boolean; id: string }[]) => {
                          gaEvent({
                            action: 'sortParticipantsTable',
                            category: 'Surveys',
                          })
                          setSortDescending(sortList[0].desc)
                          setSortBy(snakeCase(sortList[0].id))
                        },
                      }}
                    >
                      <Paper>
                        <Grid container className={classes.tableSummary}>
                          <Grid item xs={3}>
                            <Typography variant="h6">Summary</Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography>{survey.responseRate.total} Participants</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <SimpleSearch
                              withSearchAdornment
                              query={searchQuery}
                              handleSearch={(val: string) => {
                                gaEvent({
                                  action: 'searchParticipantsTable',
                                  category: 'Surveys',
                                })
                                setSearchQuery(val)
                                setPage(0)
                              }}
                              debounceTime={1000}
                              autoFocus={false}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </TablePanel>
                  )}
                  <div className={classes.navButtons}>
                    <NavigationButtons
                      goBack={goBack}
                      goNext={goNextFn}
                      goNextLabel={goNextLabel}
                    />
                  </div>
                  {updateDialogIsOpen && (
                    <UpdateParticipantContainer
                      submitDisabledText={disableActionSamplePoolMessage}
                      onClose={() => setUpdateDialogIsOpen(false)}
                      surveyUuid={surveyUuid}
                      missingPhonesErrorLevel={survey.missingPhonesErrorLevel}
                      surveyDataTypeOptions={participantsSurvey.dataTypeOptions}
                      participant={
                        editParticipantId
                          ? participants.find(p => p.uuid === editParticipantId) || null
                          : null
                      }
                      productType={survey.productType}
                    />
                  )}
                  {deleteDialogIsOpen && editParticipantId && (
                    <DeleteParticipantContainer
                      onClose={() => setDeleteDialogIsOpen(false)}
                      participantUuid={editParticipantId}
                    />
                  )}
                </>
              )
            }}
          </ResponseHandler>
        )
      }}
    </ResponseHandler>
  )
}

export default EmployeeParticipants
