import React, { useState, useEffect } from 'react'

import { makeStyles, Typography, List, ListItem } from '@material-ui/core'
import groupBy from 'lodash/groupBy'
import { NavLink } from 'react-router-dom'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Pagination from 'components/Blocks/Pagination/EnumeratedPagination'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import {
  InsightsBenchmark,
  InsightsSurvey,
  InsightsTabProps,
} from 'components/Insights/InsightsTypes'
import StatementsChart, { sortStatements } from 'components/Insights/Statements/StatementsChart'
import { StatementScores } from 'components/Insights/Statements/StatementsContainer'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import {
  InsightsModulesEnum,
  SurveyProductTypeEnum,
  useInsightsStatementsCommentsQuery,
  BenchmarkCodeType,
} from 'generated/graphql'
import { RESIDENT_RESULTS_GROUP_LABELS, SORT_OPTIONS } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(({ spacing }) => ({
  commentsSubtitle: {
    paddingTop: spacing(),
    paddingBottom: spacing(2),
  },
  seeMore: {
    paddingLeft: spacing(2),
  },
  noStatements: {
    marginTop: spacing(12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noComments: {
    textAlign: 'center',
    paddingLeft: spacing(3),
    paddingTop: spacing(3),
  },
}))

type CProps = {
  filters: string[]
  surveyUuid: string
  group: string
  searchQuery?: string
  minShowableResults: number
  hasAccessToComments: boolean
}
const CommentsSection: React.FC<CProps> = ({
  surveyUuid,
  group,
  filters,
  searchQuery,
  minShowableResults,
  hasAccessToComments,
}) => {
  const classes = useStyles()
  const pageSize = 5
  const questionMap: { [key: string]: string } = {
    [RESIDENT_RESULTS_GROUP_LABELS.OVERALL_SATISFACTION]: '',
    [RESIDENT_RESULTS_GROUP_LABELS.MANAGEMENT]: BenchmarkCodeType.LEADERSHIP_COMMENTS,
    [RESIDENT_RESULTS_GROUP_LABELS.DINING]: BenchmarkCodeType.DINING_COMMENTS,
    [RESIDENT_RESULTS_GROUP_LABELS.PERSONAL_CARE]: BenchmarkCodeType.CAREGIVING_COMMENTS,
    [RESIDENT_RESULTS_GROUP_LABELS.LICENSED_NURSING]: BenchmarkCodeType.LICENSED_NURSING_COMMENTS,
    [RESIDENT_RESULTS_GROUP_LABELS.OTHER_SERVICES]: BenchmarkCodeType.SERVICE_AREA_COMMENTS,
    [RESIDENT_RESULTS_GROUP_LABELS.CUSTOM]: '',
  }
  const questionCode = questionMap[group]
  const skip = !questionCode || !hasAccessToComments
  const commentsResult = useInsightsStatementsCommentsQuery({
    variables: {
      surveyUuid,
      filters,
      questionCode,
      page: 0,
      pageSize,
    },
    skip,
  })
  if (skip) return null
  return (
    <ResponseHandler {...commentsResult}>
      {({ comments }) => {
        // Check that this question is not filtered by the user's search query
        if (
          searchQuery &&
          !comments.question?.textDisplay?.toUpperCase().includes(searchQuery.toUpperCase())
        ) {
          return <div />
        }
        if (!comments.totalComments) {
          return (
            <>
              <Typography variant="subtitle1">{comments.question?.textDisplay}</Typography>
              <Typography className={classes.noComments} color="textSecondary" component="div">
                There are either no comments available for selected filters or your filters resulted
                in less than {minShowableResults} participants.
              </Typography>
            </>
          )
        }
        return (
          <>
            <Typography variant="subtitle1">{comments.question?.textDisplay}</Typography>
            <Typography color="textSecondary" className={classes.commentsSubtitle}>
              Short Answer Responses: ({comments.totalComments})
            </Typography>
            <List component="ol">
              {comments.comments.map((comment, idx) => (
                <ListItem key={idx}>
                  <Typography color="textSecondary">
                    {idx + 1}. {comment?.text}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <NavLink
              to={`${getInsightsPage(
                surveyUuid,
                InsightsModulesEnum.COMMENTS,
                SurveyProductTypeEnum.RESIDENT,
              )}?questionCode=${questionCode}`}
            >
              <Typography className={classes.seeMore}>See more</Typography>
            </NavLink>
          </>
        )
      }}
    </ResponseHandler>
  )
}

type Props = {
  statements: StatementScores
  filterValueLabels: string[]
  responseTypes: string[]
  filters: string[]
  searchParams: { initialSort?: SORT_OPTIONS }
  benchmark: InsightsBenchmark
  insightsModules: string[]
  timeTrendingChartKey: TimeTrendingChartKey
  searchQuery?: string
  survey: InsightsSurvey
  timeTrendingType?: InsightsTabProps['timeTrendingType']
}
const ResidentStatements: React.FC<Props> = props => {
  const { statements, searchParams, searchQuery, ...restProps } = props
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [currentSort, setCurrentSort] = useState(
    searchParams.initialSort || SORT_OPTIONS.HIGH_TO_LOW,
  )
  const [page, setPage] = useState(0)
  useEffect(() => {
    // When the number of statements are updated from searching, make sure to reset the page to 0.
    setPage(0)
  }, [statements.length])
  sortStatements(
    statements,
    currentSort,
    props.responseTypes,
    Boolean(props.filterValueLabels.length),
  )
  const statementsByGroup = groupBy(statements, s => s.statement.residentResultsGroup)
  const groupKeys = Object.keys(statementsByGroup)
  const keysOrdering = [...Object.values(RESIDENT_RESULTS_GROUP_LABELS), 'null']

  groupKeys.sort((el1, el2) => keysOrdering.indexOf(el1) - keysOrdering.indexOf(el2))

  // When a user searches, there is a brief period when a page can be out of bounds before useEffect has reset it.
  if (page >= groupKeys.length) return null
  const groupKey = groupKeys[page]
  return (
    <>
      <div className={classes.statementsSection}>
        <Typography>STATEMENTS</Typography>
        <Typography color="textSecondary">
          Here are the scores for all of the statements on the survey, grouped by topic.
        </Typography>
      </div>
      {!statementsByGroup[groupKey].length ? (
        <Typography variant="subtitle1" color="textSecondary" className={classes.noStatements}>
          No statements match your search query.
        </Typography>
      ) : (
        <div className={classes.statementsSection}>
          <StatementsChart
            surveyProductType={SurveyProductTypeEnum.RESIDENT}
            showFocus={false}
            statements={statementsByGroup[groupKey]}
            title={groupKey}
            currentSort={currentSort}
            onSortChange={newSort => setCurrentSort(newSort)}
            {...restProps}
          />
          <CommentsSection
            group={groupKey}
            filters={props.filters}
            surveyUuid={props.survey.uuid}
            searchQuery={searchQuery}
            minShowableResults={props.survey.minShowableResults}
            hasAccessToComments={props.insightsModules.includes(InsightsModulesEnum.COMMENTS)}
          />
        </div>
      )}
      <div className={classes.statementsFooter}>
        <Pagination
          totalPages={groupKeys.length}
          currentPage={page}
          onPageChange={(newPage: number) => setPage(newPage)}
        />
      </div>
    </>
  )
}

export default ResidentStatements
