import React, { memo, useState, ReactElement, ReactNode, useEffect } from 'react'

import {
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'
import ArrowIcon from '@material-ui/icons/ArrowDropDownCircle'
import ExpandMore from '@material-ui/icons/ExpandMore'
import cn from 'classnames'
import { v4 as uuidv4 } from 'uuid'

import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
  gutterTop: {
    marginTop: `${spacing(2)}px !important`,
  },
  gutterBottom: {
    marginBottom: `${spacing(2)}px !important`,
  },
  focus: {
    borderLeft: `4px solid ${palette.common.secondary}`,
    zIndex: 1,
  },
  summary: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  icon: {
    color: palette.common.secondary,
  },
  title: {
    marginBottom: spacing(2),
  },
  titleTopMargin: {
    paddingTop: spacing(3),
  },
  header: {
    marginBottom: spacing(2),
  },
  headerEnd: {
    alignItems: 'flex-end',
  },
  gutterHeader: {
    marginBottom: spacing(3),
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
  actions: {
    display: 'flex',
    '& >*': {
      marginLeft: 'auto',
    },
    '& >div:last-child': {
      paddingRight: spacing(3),
    },
  },
  details: {
    display: 'block',
    padding: spacing(3),
  },
  noDetailsPadding: {
    padding: 0,
    '& $title': {
      paddingLeft: spacing(3),
    },
  },
  noDetailsTopPadding: {
    paddingTop: 0,
  },
  footNote: {
    color: palette.common.navy65,
    marginTop: spacing(3),
  },
}))

export enum EXPAND_ICON {
  ARROW = 'arrow',
  EXPAND = 'expand',
}

export type Props = {
  id?: string
  summary?: ReactElement
  title?: string | ReactElement | null
  helpText?: ReactElement | string
  actions?: ReactElement
  children?: ReactNode | ReactNode[]
  footNote?: string
  gutterTop?: boolean
  gutterBottom?: boolean
  gutterHeader?: boolean
  headerEnd?: boolean
  interactive?: boolean
  removeDetailsPadding?: boolean
  removeDetailsTopPadding?: boolean
  extraClassName?: string
  expanded?: {}
  expandIcon?: EXPAND_ICON
  loadOnlyExpanded?: boolean
  square?: boolean
  onExpandedEvent?(): void
}

const FormPanel: React.FC<Props> = ({
  expandIcon = EXPAND_ICON.ARROW,
  id = '',
  summary = null,
  title = '',
  helpText = '',
  actions = null,
  children = <></>,
  footNote = '',
  gutterTop = false,
  gutterBottom = false,
  gutterHeader = true,
  headerEnd = false,
  removeDetailsPadding = false,
  removeDetailsTopPadding = false,
  interactive = true,
  extraClassName = '',
  expanded: defaultExpanded = false,
  loadOnlyExpanded = false,
  square = false,
  onExpandedEvent = () => {},
}) => {
  const classes = useStyles()
  const [uuid] = useState(uuidv4())
  const [focus, setFocus] = useState(false) // tracks when user hovers on panel
  const [userExpanded, setUserExpanded] = useState(false) // tracks when expands panel with a click
  const [componentExpanded, setComponentExpanded] = useState(Boolean(defaultExpanded) || !summary)

  const componentExpandedEvent = (e: CustomEvent) => {
    if (e.detail !== uuid) {
      // We know that a user has expanded before so safe to set expanded to false
      setUserExpanded(false)
    } else {
      // this condition makes sure the event handler is only called for the current event listener
      // this will trigger the query for getting first page in case of Comments
      onExpandedEvent()
    }
  }

  useEffect(() => {
    if (interactive) {
      window.addEventListener('formPanelExpanded', componentExpandedEvent as EventListener, false)
    }
    return () => {
      if (interactive) {
        window.removeEventListener('formPanelExpanded', componentExpandedEvent as EventListener)
      }
    }
    // eslint-disable-next-line
  }, [])

  const addFocus = () => {
    if (interactive) {
      setFocus(true)
    }
  }

  const sendExpandedEvent = () => {
    window.dispatchEvent(new CustomEvent('formPanelExpanded', { detail: uuid }))
  }

  const removeFocus = () => {
    if (interactive) {
      setFocus(false)
    }
  }

  const handleChange = (e: any, didExpand: boolean) => {
    setUserExpanded(didExpand)
    setComponentExpanded(didExpand)
    if (didExpand) {
      sendExpandedEvent()
    }
  }

  let ExpandIcon = null
  if (expandIcon === EXPAND_ICON.ARROW) {
    ExpandIcon = ArrowIcon
  } else if (expandIcon === EXPAND_ICON.EXPAND) {
    ExpandIcon = ExpandMore
  }

  return (
    <Accordion
      id={id}
      square={square}
      expanded={componentExpanded || userExpanded}
      className={cn(
        classes.root,
        {
          [classes.focus]: focus || userExpanded,
          [classes.gutterTop]: gutterTop,
          [classes.gutterBottom]: gutterBottom,
        },
        extraClassName,
      )}
      elevation={focus ? 10 : 0}
      onFocus={addFocus}
      onBlur={removeFocus}
      onMouseOver={addFocus}
      onMouseLeave={removeFocus}
      onChange={handleChange}
    >
      {summary && (
        <AccordionSummary
          className={classes.summary}
          expandIcon={ExpandIcon && <ExpandIcon className={classes.icon} />}
        >
          {summary}
        </AccordionSummary>
      )}
      <AccordionDetails
        className={cn(classes.details, {
          [classes.noDetailsPadding]: removeDetailsPadding,
          [classes.noDetailsTopPadding]: removeDetailsTopPadding,
        })}
      >
        {title && (
          <Typography
            className={cn(classes.title, {
              [classes.titleTopMargin]: removeDetailsPadding,
            })}
            variant="h6"
          >
            {title}
          </Typography>
        )}
        {(helpText || actions) && (
          <GridContainer className={cn(classes.header, { [classes.headerEnd]: headerEnd })}>
            {helpText && (
              <ItemGrid md={actions ? 6 : 12}>
                <Typography
                  component="div"
                  className={cn({ [classes.gutterHeader]: gutterHeader })}
                  color="textSecondary"
                >
                  {helpText}
                </Typography>
              </ItemGrid>
            )}
            <ItemGrid className={classes.actions} md={helpText ? 6 : 12}>
              {actions}
            </ItemGrid>
          </GridContainer>
        )}
        {/* For slow loading components, this allows us to set the body to only load when expanded.
          Especially helpful for custom surveys due to higher number of open-ended comments and word clouds */}
        {(!loadOnlyExpanded || componentExpanded) && (
          <>
            {children}
            {footNote && <Typography className={classes.footNote}>{footNote}</Typography>}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
export default memo(FormPanel)
