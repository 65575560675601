import React, { useState } from 'react'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Typography,
  Select,
  Tooltip,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ErrorIcon from '@material-ui/icons/ErrorOutline'

import Button from 'components/Blocks/CustomButtons/Button'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import Table from 'components/Blocks/Table'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import Danger from 'components/Blocks/Typography/Danger'
import AddQuestionScoreNotificationDialog from 'components/Survey/Wizard/Steps/Notifications/AddQuestionScoreNotificationDialog'
import { useStyles } from 'components/Survey/Wizard/Steps/Notifications/ResponseNotificationsContainer'
import {
  SurveysSurveyResponseNotificationsQuery,
  GroupNode,
  SurveyScoreNotificationFrequencyEnum,
  SurveyScoreNotificationTypeEnum,
  GroupSurveyQuestionScoreNotificationInput,
  useSurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation,
  SurveyTypeEnum,
} from 'generated/graphql'
import { SCORE_TYPE_NOTIFICATION_TYPES_ENUM, NOTIFICATION_SCORE_TYPE_LABEL } from 'utils/constants'

export type EmptyAverageScoreNotification = {
  scoreType?: null | SurveyScoreNotificationTypeEnum
  enabled?: Boolean
  frequency?: null | SurveyScoreNotificationFrequencyEnum
  groupUuids: string[]
}

// Mimics GroupSurveyQuestionScoreNotificationInput but makes keys nullable
export type EmptyQuestionScoreNotification = {
  notificationUuid?: null | string
  notificationName?: null | string
  scoreType?: null | SurveyScoreNotificationTypeEnum
  minScore?: null | number
  maxScore?: null | number
  enabled?: Boolean
  frequency: null | SurveyScoreNotificationFrequencyEnum
  groupUuids: string[]
  statementUuid: string
  openEndedQuestionUuid?: null | string
}

const EMPTY_QUESTION_SCORE_NOTIFICATION = {
  notificationUuid: '',
  notificationName: '',
  enabled: false,
  scoreType: null,
  frequency: null,
  minScore: null,
  maxScore: null,
  statementUuid: '',
  openEndedQuestionUuid: '',
  groupUuids: [],
}

export const scoreNotificationHasError = (
  notification: EmptyAverageScoreNotification | GroupSurveyQuestionScoreNotificationInput,
) => {
  if (!notification.enabled) {
    return false
  }
  return !notification.frequency || !notification.groupUuids.length
}

const FREQUENCIES = Object.keys(
  SurveyScoreNotificationFrequencyEnum,
) as SurveyScoreNotificationFrequencyEnum[]

type Props = {
  survey: SurveysSurveyResponseNotificationsQuery['survey']
  groups: Pick<GroupNode, 'uuid' | 'name'>[]
  averageScoreNotificationsInput: EmptyAverageScoreNotification[]
  setAverageScoreNotificationsInput(notificationsInput: EmptyAverageScoreNotification[]): void
  questionScoreNotificationsInput: GroupSurveyQuestionScoreNotificationInput[]
  setQuestionScoreNotificationsInput(
    notificationsInput: GroupSurveyQuestionScoreNotificationInput[],
  ): void
  error: string
}

const ScoreBasedNotifications: React.FC<Props> = ({
  survey,
  groups,
  averageScoreNotificationsInput,
  setAverageScoreNotificationsInput,
  questionScoreNotificationsInput,
  setQuestionScoreNotificationsInput,
  error,
}) => {
  const classes = useStyles()

  const [selectedQestionScoreNotification, setSelectedQestionScoreNotification] = useState<
    null | GroupSurveyQuestionScoreNotificationInput | EmptyQuestionScoreNotification
  >(null)

  const [notificationUuidToDelete, setNotificationUuidToDelete] = useState<
    undefined | null | string
  >(null)

  const [
    deleteNotification,
    { loading: isDeleting },
  ] = useSurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation()

  const getNotificationRow = (
    notification: EmptyAverageScoreNotification | GroupSurveyQuestionScoreNotificationInput,
  ) => {
    // Narrow down the union type of the notification by checking which property exists.
    const isQuestionNotification = 'minScore' in notification

    let averageScoreNotification: undefined | EmptyAverageScoreNotification
    let questionScoreNotification: undefined | GroupSurveyQuestionScoreNotificationInput

    if (isQuestionNotification) {
      // Remove casting when we upgrade ts to at least 4.0
      questionScoreNotification = notification as GroupSurveyQuestionScoreNotificationInput
    } else {
      averageScoreNotification = notification
    }

    const setFunction = isQuestionNotification
      ? setQuestionScoreNotificationsInput
      : setAverageScoreNotificationsInput

    const existingNotifications = isQuestionNotification
      ? questionScoreNotificationsInput
      : averageScoreNotificationsInput

    const notificationInput = isQuestionNotification
      ? questionScoreNotificationsInput.find(
          n => n.notificationUuid === questionScoreNotification?.notificationUuid,
        )
      : averageScoreNotificationsInput.find(
          n => n.scoreType === averageScoreNotification?.scoreType,
        )

    return [
      {
        name: 'scoreType',
        value: (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(notification.enabled)}
                  className={notification.enabled ? classes.checked : classes.unchecked}
                  onClick={() =>
                    setFunction(
                      existingNotifications.map(n =>
                        n === notificationInput ? { ...n, enabled: !n.enabled } : n,
                      ),
                    )
                  }
                />
              }
              label={
                <TitleWithInfoTooltip
                  withSpacing={false}
                  variant="subtitle1"
                  title={
                    (isQuestionNotification
                      ? questionScoreNotification?.notificationName
                      : NOTIFICATION_SCORE_TYPE_LABEL[
                          averageScoreNotification?.scoreType as SurveyScoreNotificationTypeEnum
                        ]) || ''
                  }
                  tooltip={
                    <>
                      {isQuestionNotification ? (
                        <>
                          <Typography>{questionScoreNotification?.notificationName}</Typography>
                          <Typography color="textSecondary">
                            We will send a notification{' '}
                            {questionScoreNotification &&
                              SCORE_TYPE_NOTIFICATION_TYPES_ENUM[
                                questionScoreNotification.frequency
                              ]}{' '}
                            when the question, "
                            {
                              (questionScoreNotification as GroupSurveyQuestionScoreNotificationInput & {
                                statementText: string
                              }).statementText
                            }
                            " receives a{' '}
                            {questionScoreNotification &&
                              NOTIFICATION_SCORE_TYPE_LABEL[
                                questionScoreNotification.scoreType
                              ]}{' '}
                            between {questionScoreNotification?.minScore} and{' '}
                            {questionScoreNotification?.maxScore}.
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography>
                            {
                              NOTIFICATION_SCORE_TYPE_LABEL[
                                averageScoreNotification?.scoreType as SurveyScoreNotificationTypeEnum
                              ]
                            }
                          </Typography>
                          <Typography color="textSecondary">
                            {averageScoreNotification?.scoreType ===
                            SurveyScoreNotificationTypeEnum.LOW
                              ? 'A low survey score occurs when the average score across all linear scale questions is less than or equal to 2.99. All linear scale questions are on a 5-pt scale.'
                              : 'A high survey score occurs when the average score across all linear scale questions is greater than or equal to 4.00. All linear scale questions are on a 5-pt scale.'}
                          </Typography>
                        </>
                      )}
                    </>
                  }
                />
              }
            />
          </div>
        ),
      },
      {
        name: 'frequency',
        value: (
          <FormControl style={{ width: 180 }}>
            <Select
              displayEmpty
              renderValue={value =>
                value ? (
                  SCORE_TYPE_NOTIFICATION_TYPES_ENUM[value as SurveyScoreNotificationFrequencyEnum]
                ) : (
                  <Typography variant="body2" color="secondary">
                    {error && scoreNotificationHasError(notification) ? (
                      <Danger>Select Frequency</Danger>
                    ) : (
                      'Select Frequency'
                    )}
                  </Typography>
                )
              }
              value={notification.frequency}
              onChange={e => {
                setFunction(
                  existingNotifications.map(n =>
                    n === notificationInput
                      ? {
                          ...n,
                          frequency: (e.target as HTMLInputElement)
                            .value as SurveyScoreNotificationFrequencyEnum,
                        }
                      : n,
                  ),
                )
              }}
            >
              {FREQUENCIES.map(frequency => (
                <MenuItem key={frequency} value={frequency}>
                  {SCORE_TYPE_NOTIFICATION_TYPES_ENUM[frequency]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ),
      },
      {
        name: 'groups',
        value: (
          <div className={classes.groupsContainer}>
            <CheckboxDropdown
              rootClassName={
                error && scoreNotificationHasError(notification) ? classes.selectError : ''
              }
              menuItems={groups.map(group => ({
                value: group.uuid,
                text: group.name,
              }))}
              selectedItems={notification.groupUuids || []}
              onChange={(selected: string[]) => {
                setFunction(
                  existingNotifications.map(n =>
                    n === notificationInput
                      ? {
                          ...n,
                          groupUuids: groups
                            .map(g => g.uuid)
                            .filter(uuid => selected.includes(uuid)),
                        }
                      : n,
                  ),
                )
              }}
              width={210}
              emptyLabel="Select Groups"
              useTextDropdown
              containerClassName={classes.groups}
            />
            {isQuestionNotification && (
              <>
                <IconButton
                  color="secondaryHover"
                  onClick={() => {
                    return (
                      questionScoreNotification &&
                      setSelectedQestionScoreNotification(questionScoreNotification)
                    )
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="dangerHover"
                  onClick={() => {
                    setNotificationUuidToDelete(questionScoreNotification?.notificationUuid)
                  }}
                >
                  <Tooltip title="Delete Notification">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </div>
        ),
      },
    ]
  }

  const rows: { name: string; value: any }[][] = []
  if (
    [SurveyTypeEnum.RESIDENT_DISCHARGE, SurveyTypeEnum.RESIDENT_END_OF_SERVICE].includes(
      survey.type,
    )
  ) {
    averageScoreNotificationsInput.forEach(n => rows.push(getNotificationRow(n)))
  }
  questionScoreNotificationsInput.forEach(n => {
    rows.push(getNotificationRow(n))
  })

  return (
    <>
      <div className={classes.responseNotifications}>
        <TitleWithInfoTooltip
          title="Score Based Notifications"
          tooltip={
            <>
              <Typography>Score Based Notifications:</Typography>
              <br />
              <Typography color="textSecondary">
                Let your team know when a survey is completed with a Score Based Notification. Once
                the survey is live group members can update their personal preferences, and even
                disable their notifications entirely. Score Based Notifications are a great way to
                see data in real time, and take immediate action.
              </Typography>
            </>
          }
          padIcon={false}
        />
        <div className={classes.scoresHeader}>
          <Typography color="textSecondary" variant="body1">
            Get an email notification whenever a new response is submitted with a high or low score.
            Select the frequency and which group members should receive each type. You can also
            create notifications based on responses to specific questions by selecting the ‘New
            Score Based Notification’ button.
          </Typography>
          {rows.length > 0 && (
            <Button
              onClick={() => setSelectedQestionScoreNotification(EMPTY_QUESTION_SCORE_NOTIFICATION)}
              color="secondaryNoBackground"
            >
              <AddCircleIcon />
              &nbsp;New Score Based Notification
            </Button>
          )}
        </div>
      </div>
      {error && (
        <div className={classes.error}>
          <ErrorIcon />
          <Typography variant="body1">{error} </Typography>
        </div>
      )}
      <br />
      {rows.length > 0 ? (
        <Table
          pageSize={100}
          bicolor
          withCellsBorder={false}
          usePagination={false}
          columns={['Type', 'Frequency', 'Who should receive?'].map(c => ({ label: c }))}
          totalRows={rows.length}
          rows={rows}
        />
      ) : (
        <Button
          className={classes.newNotificationButton}
          onClick={() => setSelectedQestionScoreNotification(EMPTY_QUESTION_SCORE_NOTIFICATION)}
        >
          <AddIcon />
          &nbsp;New Score Based Notification
        </Button>
      )}
      {selectedQestionScoreNotification && (
        <AddQuestionScoreNotificationDialog
          surveyUuid={survey.uuid}
          notification={selectedQestionScoreNotification}
          groups={groups}
          onClose={() => {
            setSelectedQestionScoreNotification(null)
          }}
        />
      )}
      {notificationUuidToDelete && (
        <ActionDialog
          title="Are you sure?"
          content="Are you sure you want to delete the notification? This action cannot be undone."
          submitButtonText="Delete"
          onClose={() => setNotificationUuidToDelete(null)}
          isSubmitting={isDeleting}
          onSubmit={() => {
            deleteNotification({
              variables: { notificationUuid: notificationUuidToDelete },
              update(cache) {
                const normalizedId = cache.identify({
                  uuid: notificationUuidToDelete,
                  __typename: 'GroupsSurveyQuestionScoreNotificationType',
                })
                cache.evict({ id: normalizedId })
                cache.gc()
              },
            })
            setNotificationUuidToDelete(null)
          }}
        />
      )}
    </>
  )
}

export default ScoreBasedNotifications
