import React, { useState } from 'react'

import UpdateQuestion from 'components/Survey/Wizard/Steps/Questions/UpdateQuestion'
import {
  SurveysSurveyDocument,
  SurveysQuestionsByCategoryDocument,
  SurveysQuestionsDocument,
  useSurveysUpdateCustomQuestionMutation,
  QCategory,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'

const getDefaultQuestion = question => {
  if (question) {
    return {
      uuid: question.uuid,
      text: question.text || '',
      index: question.index,
      kind: question.kind,
      choices: question.choices.map(c => ({
        uuid: c.uuid,
        text: c.text,
      })),
    }
  }
  return {
    uuid: null,
    text: '',
    index: null,
    kind: null,
    choices: [
      {
        uuid: null,
        text: '',
      },
    ],
  }
}

const UpdateQuestionContainer = ({
  surveyUuid,
  question: initialQuestion,
  existingQuestions,
  category,
  onClose,
}) => {
  const questionHasEditableChoices = category === QCategory.DEMOGRAPHICS

  const [question, setQuestion] = useState(getDefaultQuestion(initialQuestion))
  const [errors, setErrors] = useState(null)
  const [updateCustomQuestion, { loading }] = useSurveysUpdateCustomQuestionMutation()

  const onChangeQuestion = text => setQuestion({ ...question, text })

  const onAddChoice = () =>
    setQuestion({
      ...question,
      choices: [
        ...question.choices,
        {
          uuid: null,
          text: '',
        },
      ],
    })

  const onDeleteChoice = index =>
    setQuestion({ ...question, choices: question.choices.filter((q, idx) => index !== idx) })

  const onChangeChoice = (newChoiceText, index) => {
    setQuestion({
      ...question,
      choices: question.choices.map((choice, idx) =>
        index === idx ? { ...choice, text: newChoiceText } : choice,
      ),
    })
  }

  const onUpdateQuestion = async index => {
    setErrors(null)
    try {
      const result = await updateCustomQuestion({
        variables: {
          surveyUuid,
          question: {
            questionUuid: question.uuid,
            text: question.text,
            choices: questionHasEditableChoices ? question.choices : [],
            index,
            category,
            kind: question.kind,
          },
        },
        refetchQueries: [
          {
            query: SurveysQuestionsByCategoryDocument,
            variables: {
              surveyUuid,
            },
          },
          {
            query: SurveysQuestionsDocument,
            variables: {
              surveyUuid,
            },
          },
          {
            query: SurveysSurveyDocument,
            variables: {
              uuid: surveyUuid,
            },
          },
        ],
      })
      if (result.data.updateCustomQuestion.errors) {
        setErrors(result.data.updateCustomQuestion.errors)
      } else {
        onClose()
      }
    } catch (exc) {
      emitter.emit('ERROR')
    }
  }
  return (
    <UpdateQuestion
      question={question}
      isSubmitting={loading}
      errors={errors}
      questionHasEditableChoices={questionHasEditableChoices}
      onChangeQuestion={onChangeQuestion}
      onChangeChoice={onChangeChoice}
      onAddChoice={onAddChoice}
      onDeleteChoice={onDeleteChoice}
      onUpdateQuestion={onUpdateQuestion}
      onClose={onClose}
      existingQuestions={existingQuestions}
    />
  )
}

export default UpdateQuestionContainer
