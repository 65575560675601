import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import RosterUpload from 'components/Blocks/CustomUpload/RosterUpload'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import {
  ImportTypesEnum,
  useSurveysGenerateUploadUrlMutation,
  useSurveysAddParticipantsUploadMutation,
  useSurveysRemoveParticipantsUploadMutation,
  SurveyTypeEnum,
} from 'generated/graphql'

const useStyles = makeStyles(({ spacing }) => ({
  note: {
    display: 'inline',
  },
  directions: {
    '& >p': {
      paddingTop: spacing(2),
    },
  },
}))

type Props = {
  surveyUuid: string
  clientsFileName?: null | string
  representativesFileName?: null | string
  clientTemplateUrl?: null | string
  participantTemplateUrl: string
  surveyType: SurveyTypeEnum
}

const ResidentUploadHeader: React.FC<Props> = ({
  surveyUuid,
  clientsFileName,
  representativesFileName,
  clientTemplateUrl,
  participantTemplateUrl,
  surveyType,
}) => {
  const classes = useStyles()

  const [generateUploadUrl] = useSurveysGenerateUploadUrlMutation()
  const [addParticipantsUpload] = useSurveysAddParticipantsUploadMutation()
  const [removeParticipantsUpload] = useSurveysRemoveParticipantsUploadMutation()

  return (
    <>
      <FormPanel
        expanded={!clientsFileName}
        expandIcon={EXPAND_ICON.ARROW}
        gutterBottom
        summary={
          <Typography variant="h5">
            Add Resident/Patient Data
            {surveyType === SurveyTypeEnum.RESIDENT_DISCHARGE && ' (skip if using SFTP)'}
          </Typography>
        }
      >
        {clientTemplateUrl && (
          <ItemGrid md={9} id="clients-upload">
            {surveyType === SurveyTypeEnum.RESIDENT_DISCHARGE && (
              <Typography color="textSecondary">
                Note: If you have SFTP setup for uploading participant data, you can ignore this
                step.
              </Typography>
            )}
            <Typography color="textSecondary">
              Use our Excel template to upload your residents/patients data. Once filled out, upload
              the file here, and your Program Manager will review your data and complete the
              process.
            </Typography>
            <RosterUpload
              fileName={clientsFileName}
              templateUrl={clientTemplateUrl}
              inputName="clientsUploadInput"
              generateUploadUrl={async (uploadFileName: string, contentType: string) => {
                const { data: uploadData } = await generateUploadUrl({
                  variables: {
                    fileName: uploadFileName,
                    contentType,
                  },
                })
                return uploadData?.generateUploadUrl?.signedUrl
              }}
              onFileUploaded={(file: string, path: string) => {
                addParticipantsUpload({
                  variables: {
                    importType: ImportTypesEnum.CLIENTS,
                    surveyUuid,
                    objectPath: path,
                  },
                })
              }}
              removeUpload={async () => {
                const removeResult = await removeParticipantsUpload({
                  variables: {
                    importType: ImportTypesEnum.CLIENTS,
                    surveyUuid,
                  },
                })
                return removeResult?.data?.removeParticipantsUpload?.survey?.clientsUpload?.fileName
              }}
              templateHeader="Step 1: Download Resident/Patient Template"
              uploadHeader="Step 2: Upload Your File"
              templateDescription={
                <div className={classes.directions}>
                  <Typography color="textSecondary">
                    Copy and paste your data into the template, or upload your own file, making sure
                    headers and formatting match. <br />
                    <br />
                    Be sure to include all residents/patients. There is a column in which you’ll
                    indicate whether or not they should receive a survey themselves.
                  </Typography>
                </div>
              }
              uploadDescription={
                <div className={classes.directions}>
                  <Typography color="textSecondary">
                    Upload the file. <br />
                    <br />
                    Your Program Manager will review and create your participant list. Once complete
                    the information will be formatted in a table below.
                  </Typography>
                </div>
              }
            />
          </ItemGrid>
        )}
      </FormPanel>
      <FormPanel
        expandIcon={EXPAND_ICON.ARROW}
        expanded={Boolean(clientsFileName && !representativesFileName)}
        gutterBottom
        summary={
          <Typography variant="h5">
            Add Family or Fiduciary Data&nbsp;
            <Typography color="textSecondary" className={classes.note}>
              (*Optional)
            </Typography>
          </Typography>
        }
      >
        <ItemGrid md={9} id="representatives-upload">
          <Typography color="textSecondary">
            If there are family members or fiduciaries you’d like to include in the survey, use our
            Family Template to input their data.
          </Typography>
          <RosterUpload
            disableUpload={!clientsFileName}
            fileName={representativesFileName}
            inputName="representativesUploadInput"
            templateUrl={participantTemplateUrl}
            generateUploadUrl={async (uploadFileName: string, contentType: string) => {
              const { data: uploadData } = await generateUploadUrl({
                variables: {
                  fileName: uploadFileName,
                  contentType,
                },
              })
              return uploadData?.generateUploadUrl?.signedUrl
            }}
            onFileUploaded={(file: string, path: string) => {
              addParticipantsUpload({
                variables: {
                  importType: ImportTypesEnum.PARTICIPANTS,
                  surveyUuid,
                  objectPath: path,
                },
              })
            }}
            removeUpload={async () => {
              const removeResult = await removeParticipantsUpload({
                variables: {
                  importType: ImportTypesEnum.PARTICIPANTS,
                  surveyUuid,
                },
              })
              return removeResult?.data?.removeParticipantsUpload?.survey?.participantsUpload
                ?.fileName
            }}
            templateHeader="Step 1: Download Family Member or Fiduciary Template"
            uploadHeader="Step 2: Upload Your File"
            templateDescription={
              <div className={classes.directions}>
                <Typography color="textSecondary">
                  Copy and paste your data into the template, or upload your own file, making sure
                  headers and formatting match.
                  <br />
                  <br />
                  Each participant will need a Senior ID to match them to the right Client. Learn
                  more{' '}
                  <ExternalNavLink
                    to="https://activatedinsights.force.com/help/s/article/what-is-a-client-ID"
                    label="here"
                  />
                  .
                </Typography>
              </div>
            }
            uploadDescription={
              <div className={classes.directions}>
                <Typography color="textSecondary">
                  Upload the file. <br />
                  <br />
                  Your Program Manager will review and create your family member or fiduciary list.
                  Once complete, the information will be formated in a table below.
                </Typography>
              </div>
            }
          />
        </ItemGrid>
      </FormPanel>
    </>
  )
}

export default ResidentUploadHeader
