import React, { SyntheticEvent } from 'react'

import { Dialog, DialogContent, DialogTitle, Typography, makeStyles } from '@material-ui/core'

import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'left',
  },
  content: {
    textAlign: 'left',
  },
}))

type Props = {
  title: string
  content: string
  submitButtonText: string
  cancelButtonText?: string
  isSubmitting?: boolean
  submitButtonColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'primaryNoBackground'
    | 'secondaryNoBackground'
    | 'successNoBackground'
    | 'warningNoBackground'
    | 'dangerNoBackground'
    | 'textSecondaryNoBackground'
  onClose?(): void
  onSubmit: (event: SyntheticEvent) => void
}

const ActionDialog: React.FC<Props> = ({
  onSubmit,
  onClose,
  title,
  content,
  submitButtonText,
  isSubmitting = false,
  submitButtonColor = 'primary',
  cancelButtonText = 'Cancel',
}) => {
  const classes = useStyles()
  return (
    <Dialog
      open
      onClose={onClose || onSubmit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div className={classes.title}>
          <Typography variant="h5">{title}</Typography>
        </div>
      </DialogTitle>
      <DialogContent id="alert-dialog-description">
        <Typography className={classes.content} variant="body1" color="textSecondary">
          {content}
        </Typography>
      </DialogContent>
      <ActionDialogButtons
        onClose={onClose}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        submitButtonColor={submitButtonColor}
        submitButtonText={submitButtonText}
        cancelButtonText={cancelButtonText}
      />
    </Dialog>
  )
}

export default ActionDialog
