import React from 'react'

import EditIcon from '@material-ui/icons/Edit'
import { NavLink } from 'react-router-dom'

import Button from 'components/Blocks/CustomButtons/Button'
import TranslationsContainer from 'components/Survey/Wizard/Steps/Questions/Translations/TranslationsContainer'
import { OrganizationLanguageFragment } from 'generated/graphql'

interface Props {
  surveyUuid: string
  surveyLanguages: OrganizationLanguageFragment[]
  openControl: 'link' | 'button'
  responseRateByClient: boolean
  isOpenTranslationsDialog?: boolean
  setIsOpenTranslationsDialog(isOpen: boolean): void
}

const UpdateTranslationsButton: React.FC<Props> = ({
  openControl,
  surveyLanguages,
  surveyUuid,
  responseRateByClient,
  isOpenTranslationsDialog,
  setIsOpenTranslationsDialog,
}) => {
  return (
    <>
      {openControl === 'link' && (
        <NavLink id="editTranslations" to="#" onClick={() => setIsOpenTranslationsDialog(true)}>
          Edit Translations
        </NavLink>
      )}
      {openControl === 'button' && (
        <Button
          id="editTranslations"
          onClick={() => setIsOpenTranslationsDialog(true)}
          color="textSecondaryNoBackground"
        >
          Edit Translations&nbsp;
          <EditIcon />
        </Button>
      )}
      {isOpenTranslationsDialog && (
        <TranslationsContainer
          surveyLanguages={surveyLanguages}
          surveyUuid={surveyUuid}
          responseRateByClient={responseRateByClient}
          onClose={() => setIsOpenTranslationsDialog(false)}
        />
      )}
    </>
  )
}

export default UpdateTranslationsButton
