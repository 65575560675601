import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'
import countBy from 'lodash/countBy'

import Button from 'components/Blocks/CustomButtons/Button'
import QueryHandler from 'components/Blocks/Layout/QueryHandler'
import GroupedSelect from 'components/Insights/Blocks/GroupedSelect'
import { NPS_QUESTION_CODES } from 'components/Survey/Wizard/Steps/Questions/NpsQuestions'
import {
  useSurveysBulkSetQuestionMutation,
  QCategory,
  QResidentFocus,
  SurveysQuestionsByCategoryDocument,
  SurveysQuestionsDocument,
  SurveysAvailableSurveyQuestionsDocument,
} from 'generated/graphql'
import {
  MARKETING_ADDON_QUESTION_FOCUSES,
  RESIDENT_QUESTION_FOCUSES,
  QUESTION_FOCUSES,
} from 'utils/constants'

const useStyles = makeStyles(() => ({
  anchorButton: {
    padding: 0,
    margin: 0,
  },
}))

const getQuestionLabel = (question, questionsOccurrences) => {
  // In Resident Surveys, some questions have the same text but different answer options, so we need a way to differentiate those.
  // An example would be "When were you born?" which can be a question for Seniors or for their Contacts
  if (question.filters) {
    if (question.filters.participantTypes && questionsOccurrences[question.text] > 1) {
      if (question.filters.participantTypes.includes('Client')) {
        return `${question.text} (Seniors)`
      }
      if (question.filters.participantTypes.includes('Representative')) {
        return `${question.text} (Contacts)`
      }
    }
  }
  return question.text
}

const AddOrRemoveOptionalQuestionsContainer = ({
  survey,
  questionCategory,
  selectedQuestions,
  buttonText,
  limit,
  buttonLabelPrefix,
}) => {
  const classes = useStyles()

  const [bulkSetQuestions] = useSurveysBulkSetQuestionMutation()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const onSubmit = async (selectedQuestionUuids, sectionQuestionUuids) => {
    const unselectedQuestionUuids = sectionQuestionUuids.filter(
      q => !selectedQuestionUuids.includes(q),
    )
    const result = await bulkSetQuestions({
      variables: {
        surveyUuid: survey.uuid,
        selectedQuestionUuids,
        unselectedQuestionUuids,
      },
      refetchQueries: [
        {
          query: SurveysQuestionsDocument,
          variables: {
            surveyUuid: survey.uuid,
          },
        },
        {
          query: SurveysQuestionsByCategoryDocument,
          variables: {
            surveyUuid: survey.uuid,
          },
        },
      ],
    })
    const { errors } = result.data.bulkSetSurveyQuestion
    if (errors) {
      alert(errors)
    }
  }

  const closePopup = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  if (!survey.allowedCategoriesForOptionalQuestions) {
    return <></>
  }
  return (
    <>
      {buttonLabelPrefix}
      <Button
        color="secondaryNoBackground"
        onClick={e => {
          setOpen(true)
          setAnchorEl(e.target)
        }}
        className={classes.anchorButton}
      >
        {buttonText}
      </Button>
      {open ? (
        <QueryHandler
          query={SurveysAvailableSurveyQuestionsDocument}
          variables={{ surveyUuid: survey.uuid }}
          fetchPolicy="network-only"
        >
          {({ availableSurveyQuestions }) => {
            // Include non-required survey type questions that are not custom questions
            const questions = availableSurveyQuestions.filter(
              q =>
                !q.required &&
                !q.isCustom &&
                !q.dependsOn &&
                q.category === questionCategory &&
                // NPS gets it's own Accordion Component
                !NPS_QUESTION_CODES.includes(q.benchmarkCode),
            )
            const questionIds = questions.map(q => q.uuid)
            const questionsOccurrences = countBy(questions.map(q => q.text))
            return (
              <GroupedSelect
                multiple
                maxSelectedItems={limit}
                anchorEl={anchorEl}
                items={questions
                  .filter(q => !MARKETING_ADDON_QUESTION_FOCUSES.includes(q.residentFocus))
                  .map(q => {
                    let subgroup = ''
                    if (q.focus) {
                      subgroup = QUESTION_FOCUSES[q.focus]
                    } else if (q.residentFocus) {
                      // Requirement from Product -- use 'Other' rather than 'Demographics'.
                      subgroup =
                        q.category === QCategory.STATEMENTS &&
                        q.residentFocus === QResidentFocus.DEMOGRAPHICS
                          ? 'Other'
                          : RESIDENT_QUESTION_FOCUSES[q.residentFocus]
                    }
                    return {
                      ...q,
                      subgroup,
                      group: questionCategory === QCategory.STATEMENTS ? 'Statements' : 'Questions',
                    }
                  })}
                selectedItems={selectedQuestions
                  .map(q => q.uuid)
                  .filter(id => questionIds.includes(id))}
                onMultiselectChange={newQuestions => onSubmit(newQuestions, questionIds)}
                getItemValue={item => item.uuid}
                getItemLabel={question => getQuestionLabel(question, questionsOccurrences)}
                onClose={closePopup}
                showAlphaList={false}
              />
            )
          }}
        </QueryHandler>
      ) : null}
    </>
  )
}

export default AddOrRemoveOptionalQuestionsContainer
