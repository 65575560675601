import React from 'react'

import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(({ spacing }) => ({
  dialogTitle: {
    paddingTop: 0,
    paddingBottom: spacing(3),
    paddingLeft: spacing(3),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    padding: spacing(5),
    paddingTop: 0,
  },
}))

type Props = {
  title?: string
  onClose(): void
} & Partial<DialogProps>

const CustomDialog: React.FC<Props> = ({
  title,
  onClose,
  maxWidth = 'md',
  children,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open
      maxWidth={maxWidth}
      fullWidth
      aria-labelledby="dialog-title"
      onClose={onClose}
      {...restProps}
    >
      <DialogTitle id="dialog-title" className={classes.dialogTitle}>
        <div className={classes.title}>
          <Typography color="textSecondary" variant="h6">
            {title}
          </Typography>
          <IconButton aria-label="Close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content} id="dialog">
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default CustomDialog
