import React from 'react'

import { makeStyles, DialogContent, DialogTitle, Divider, Typography } from '@material-ui/core'
import capitalize from 'lodash/capitalize'

import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import { Group } from 'components/Settings/Team/Groups/GroupsTypes'
import { FilterTypeFragment } from 'generated/graphql'
import { pluralize, joinWords } from 'utils'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    textAlign: 'left',
  },
  warningIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.common.navy25,
  },
  permissionsList: {
    maxHeight: 200,
    overflow: 'scroll',
  },
  memberList: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& >span': {
      display: 'inline-block',
      width: 150,
    },
  },
}))

type Props = {
  group: Group
  filters?: NonNullable<FilterTypeFragment>[]
  onClose(): void
  onConfirm(): void
  title: string
  confirmType: 'group' | 'user'
}

const ConfirmGroup: React.FC<Props> = ({
  group,
  onClose,
  onConfirm,
  title,
  filters,
  confirmType,
}) => {
  const classes = useStyles()
  const numSurveys = group.surveys.length
  const numModules = group.insightsModules.length
  const residentsNumModules = group.residentInsightsModules.length
  const productModuleNames = [
    { field: group.accessToSurveyProduct, name: 'Employee Survey' },
    { field: group.residentAccessToSurveyProduct, name: 'Customer Survey' },
  ]
    .filter(({ field }) => field)
    .map(({ name }) => name)
  return (
    <>
      <Divider />
      <DialogTitle id="group-dialog-title" className={classes.dialogTitle}>
        Are you sure you want to {group.uuid ? 'update' : 'create'} this {confirmType}?
      </DialogTitle>
      <DialogContent id="group-dialog-description">
        <Typography color="textSecondary" component="div" variant="subtitle1">
          {title}
          <ul className={classes.permissionsList}>
            <li>
              Access {pluralize('Module', productModuleNames.length)}:{' '}
              {joinWords(productModuleNames)}
            </li>
            {filters && filters.length > 0 && (
              <>
                <li>
                  Access to the following data:
                  <ul>
                    {filters.map(ft => (
                      <li key={ft.filterTypeUuid}>
                        {ft.accessToAll ? `All ${ft.namePlural}` : ft.namePlural}
                        <ul>
                          {ft.filterValues.map(fv => (
                            <li key={fv.uuid}>{fv.name}</li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              </>
            )}
            {group.canAccessDataStructure && <li>Access Organization Data Structure</li>}
            {group.canUseActionPlans && <li>Use Action Plans</li>}
            {group.canManageActionPlans && <li>Manage Action Plans</li>}
            {group.canCreateSurveys && <li>Create Employee Surveys</li>}
            {group.canAccessSurveySettings && <li>Access Employee Survey Settings</li>}
            {group.canManageCertifications && <li>Manage Certifications</li>}
            {group.canViewCertificationResults && <li>View Certification Results</li>}
            {group.residentCanCreateSurveys && <li>Create Customer Surveys</li>}
            {group.residentCanAccessSurveySettings && <li>Access Customer Survey Settings</li>}
            {group.canUseAnalytics && <li>Use Analytics</li>}
            {group.canInviteUsers && <li>Invite others to the group</li>}
            {group.allSurveys && <li>See results from all current and future surveys</li>}
            {!group.allSurveys && group.surveys.length > 0 && (
              <li>
                See results from the following {pluralize('survey', numSurveys)}:
                <ul>
                  {group.surveys.map(s => (
                    <li key={s.uuid}>{s.name}</li>
                  ))}
                </ul>
              </li>
            )}
            {numModules > 0 && (
              <li>
                Access the following Employee Insights {pluralize('tab', numModules + 1)}:
                <ul>
                  {group.insightsModules.map(tab => (
                    <li key={tab}>{capitalize(tab)}</li>
                  ))}
                </ul>
              </li>
            )}
            {residentsNumModules > 0 && (
              <li>
                Access the following Customer Insights {pluralize('tab', residentsNumModules + 1)}:
                <ul>
                  {group.residentInsightsModules.map(tab => (
                    <li key={`resident${tab}`}>{capitalize(tab)}</li>
                  ))}
                </ul>
              </li>
            )}
            {group.canAccessLonelinessResults && (
              <li>See loneliness results on customer surveys</li>
            )}
          </ul>
          {group.users.length > 0 && `Members (${group.users.length}):`}
          <br />
          <div className={classes.memberList}>
            {group.users.map(u => (
              <span key={u.email}>{u.name}</span>
            ))}
          </div>
        </Typography>
      </DialogContent>
      <ActionDialogButtons
        autoFocusSubmit
        onSubmit={onConfirm}
        onClose={onClose}
        submitButtonText={group.uuid ? 'Update' : 'Create'}
      />
    </>
  )
}
export default ConfirmGroup
