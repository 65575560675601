import React from 'react'

import { useApolloClient } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles, AppBar, Button, Tab, Tabs, Typography } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useLocation, Link, Redirect, Route, Switch, useHistory } from 'react-router-dom'

import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import Page from 'components/Blocks/Layout/Page'
import Loader from 'components/Blocks/Loader'
import StatusTag from 'components/Blocks/Tags/StatusTag'
import ResidentParticipants from 'components/Survey/ResidentWizard/ResidentParticipants'
import Distribution from 'components/Survey/Wizard/Steps/Distribution/Distribution'
import MonitorContainer from 'components/Survey/Wizard/Steps/Monitor/MonitorContainer'
import Notifications from 'components/Survey/Wizard/Steps/Notifications/Notifications'
import ResponseNotificationsContainer from 'components/Survey/Wizard/Steps/Notifications/ResponseNotificationsContainer'
import EmployeeParticipants from 'components/Survey/Wizard/Steps/Participants/EmployeeParticipants'
import Summary from 'components/Survey/Wizard/Steps/Summary/Summary'
import {
  useSurveysUpdateSurveyStatusMutation,
  SurveysSurveyQuery,
  SurveyStatusEnum,
  SurveyProductTypeEnum,
  CurrentUserDocument,
  SurveyDistributionTypeEnum,
} from 'generated/graphql'
import { URLS, surveyPage, SURVEY_URLS } from 'utils/constants'
import { defaultInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(({ spacing, palette }) => ({
  appBar: {
    '@media print': {
      display: 'none',
    },
  },
  appBarHeader: {
    margin: 21,
    justifyContent: 'space-between',
    display: 'flex',
  },
  appBarSubheader: {
    fontStyle: 'italic',
    fontWeight: 100,
  },
  appBarNavigation: {
    margin: 0,
    width: '100%',
    borderTop: `1px solid ${palette.common.navy25}`,
  },
  tabComponent: {
    marginTop: spacing(2),
    '@media print': {
      width: 650,
    },
  },
  tabOverrideRoot: {
    whiteSpace: 'nowrap',
    minWidth: 0,
    fontSize: '1.4rem',
  },
  statusTagWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& >button': {
      height: 34,
      marginLeft: spacing(2),
    },
  },
  toggleStatusButton: {
    marginTop: -2,
    marginRight: 20,
  },
  loader: {
    marginRight: 20,
    marginTop: -7,
    height: 40,
  },
}))

enum LockedWizardStepsEnum {
  MONITOR = 'monitor',
  SUMMARY = 'summary',
  PARTICIPANTS = 'participants',
  NOTIFICATIONS = 'notifications',
  RESPONSE_NOTIFICATIONS = 'response_notifications',
  DISTRIBUTION = 'distribution',
}

const getTabs = (
  isAdmin: boolean,
  survey: SurveysSurveyQuery['survey'],
  isOpenLinkSurvey: boolean,
) => {
  const tabs = [
    {
      name: LockedWizardStepsEnum.DISTRIBUTION,
      path: surveyPage(survey.uuid, SURVEY_URLS.DISTRIBUTION),
      url: URLS.SURVEYS.DISTRIBUTION,
      component: Distribution,
      label: 'Distribution',
      validStatuses: [SurveyStatusEnum.SCHEDULED, SurveyStatusEnum.LIVE],
      show: isOpenLinkSurvey,
    },
    {
      name: LockedWizardStepsEnum.MONITOR,
      path: surveyPage(survey.uuid, SURVEY_URLS.MONITOR),
      url: URLS.SURVEYS.MONITOR,
      component: MonitorContainer,
      label: 'Monitor Responses',
      validStatuses: [SurveyStatusEnum.SCHEDULED, SurveyStatusEnum.LIVE, SurveyStatusEnum.CLOSED],
      show: true,
    },
    {
      name: LockedWizardStepsEnum.SUMMARY,
      path: surveyPage(survey.uuid, SURVEY_URLS.SUMMARY),
      url: URLS.SURVEYS.SUMMARY,
      component: Summary,
      label: 'Survey Info',
      validStatuses: [SurveyStatusEnum.SCHEDULED, SurveyStatusEnum.LIVE, SurveyStatusEnum.CLOSED],
      show: true,
    },
    {
      name: LockedWizardStepsEnum.PARTICIPANTS,
      path: surveyPage(survey.uuid, SURVEY_URLS.PARTICIPANTS),
      url: URLS.SURVEYS.PARTICIPANTS,
      component:
        survey.productType === SurveyProductTypeEnum.EMPLOYEE
          ? EmployeeParticipants
          : ResidentParticipants,
      label: 'Participants',
      validStatuses: [SurveyStatusEnum.SCHEDULED, SurveyStatusEnum.LIVE],
      show: !isOpenLinkSurvey,
    },
    {
      name: LockedWizardStepsEnum.NOTIFICATIONS,
      path: surveyPage(survey.uuid, SURVEY_URLS.NOTIFICATIONS),
      url: URLS.SURVEYS.NOTIFICATIONS,
      component: Notifications,
      label: 'Participant Notifications',
      validStatuses: [SurveyStatusEnum.SCHEDULED, SurveyStatusEnum.LIVE],
      show: !isOpenLinkSurvey,
    },
    {
      name: LockedWizardStepsEnum.RESPONSE_NOTIFICATIONS,
      path: surveyPage(survey.uuid, SURVEY_URLS.RESPONSE_NOTIFICATIONS),
      url: URLS.SURVEYS.RESPONSE_NOTIFICATIONS,
      component: ResponseNotificationsContainer,
      label: 'Response Notifications',
      validStatuses: [SurveyStatusEnum.SCHEDULED, SurveyStatusEnum.LIVE],
      show:
        isAdmin &&
        (survey.hasSurveyResponseNotifications ||
          survey.hasAverageScoreNotifications ||
          survey.hasQuestionScoreNotifications),
    },
  ]
  return tabs.filter(tab => tab.show && tab.validStatuses.includes(survey.status))
}

type Props = {
  survey: SurveysSurveyQuery['survey']
}

const LockedSurvey: React.FC<Props> = ({ survey }) => {
  const classes = useStyles()
  const client = useApolloClient()
  const currentUser = client.readQuery({ query: CurrentUserDocument }).currentUser
  const location = useLocation()
  const history = useHistory()
  const [updateStatus, { loading }] = useSurveysUpdateSurveyStatusMutation()

  const isOpenLinkSurvey = survey.distributionType === SurveyDistributionTypeEnum.OPEN

  const tabs = getTabs(Boolean(currentUser.isAdmin), survey, isOpenLinkSurvey)

  return (
    <Page>
      <BreadcrumbsItem to={surveyPage(survey.uuid, SURVEY_URLS.PARTICIPANTS)}>
        {survey.name}
      </BreadcrumbsItem>
      <AppBar className={classes.appBar} position="static" color="default">
        <div className={classes.appBarHeader}>
          <div>
            <Typography variant="h4">{survey.name}</Typography>
            <Typography className={classes.appBarSubheader} color="textSecondary">
              {survey.associatedSurvey && `Pulse of ${survey.associatedSurvey.name}`}
            </Typography>
          </div>
          <div className={classes.statusTagWrapper}>
            {!survey.endDate &&
              [SurveyStatusEnum.CLOSED, SurveyStatusEnum.LIVE].includes(survey.status) &&
              (loading ? (
                <div className={classes.loader}>
                  <Loader size={22} />
                </div>
              ) : (
                <Button
                  className={classes.toggleStatusButton}
                  onClick={() =>
                    updateStatus({
                      variables: {
                        surveyUuid: survey.uuid,
                        status:
                          survey.status === SurveyStatusEnum.CLOSED
                            ? SurveyStatusEnum.LIVE
                            : SurveyStatusEnum.CLOSED,
                      },
                    })
                  }
                  color="secondary"
                >
                  {survey.status === SurveyStatusEnum.CLOSED && 'Reopen Survey'}
                  {survey.status === SurveyStatusEnum.LIVE && 'Close Survey'}
                </Button>
              ))}
            <StatusTag status={survey.status} isOpenLinkSurvey={isOpenLinkSurvey} />
            {(survey.status === SurveyStatusEnum.CLOSED ||
              (survey.status === SurveyStatusEnum.LIVE && !survey.endDate)) && (
              <SubmitButton
                buttonType="button"
                onClick={() => {
                  history.push(
                    defaultInsightsPage(
                      survey.uuid,
                      currentUser.insightsModules,
                      survey.productType,
                    ),
                  )
                }}
              >
                View Results
              </SubmitButton>
            )}
          </div>
        </div>
        <GridContainer alignItems="center" className={classes.appBarNavigation}>
          <ItemGrid sm={12}>
            <Tabs value={location.pathname} variant="fullWidth" scrollButtons="auto">
              {tabs.map(tab => (
                <Tab
                  style={{ pointerEvents: 'auto' }}
                  key={tab.name}
                  classes={{ root: classes.tabOverrideRoot }}
                  label={tab.label}
                  value={tab.path}
                  component={Link}
                  to={tab.path}
                />
              ))}
            </Tabs>
          </ItemGrid>
        </GridContainer>
      </AppBar>
      <div className={classes.tabComponent}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Switch>
            {tabs.map(tab => (
              <Route
                exact
                key={tab.name}
                path={tab.url}
                render={() => <tab.component currentUser={currentUser} survey={survey} />}
              />
            ))}
            {/* Second case is for when unscheduling the survey. */}
            <Redirect to={tabs[0] ? tabs[0].path : surveyPage(survey.uuid, SURVEY_URLS.SUMMARY)} />
          </Switch>
        </MuiPickersUtilsProvider>
      </div>
    </Page>
  )
}

export default LockedSurvey
