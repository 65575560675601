import React, { ReactElement, useContext, useEffect } from 'react'

import { Checkbox, Typography, makeStyles, MenuItem } from '@material-ui/core'

import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import { getValidTimeTrendingSurveys } from 'components/Insights/TimeTrending/utils'
import { StoreContext, TimeTrendingSurveysKey } from 'config/LocalStorage'
import { useUpdateEffect } from 'utils/customHooks'
import { SurveyNode } from 'utils/types'

const MAX_SURVEYS = 4

const useStyles = makeStyles(theme => ({
  selectOptionLabel: {
    padding: 10,
    borderBottom: `1px solid ${theme.palette.common.navy25}`,
    '& >span': {
      float: 'right',
      fontSize: 14,
      color: theme.palette.common.navy65,
    },
  },
  multiSelectRow: {
    backgroundColor: `${theme.palette.common.white} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.common.secondary20} !important`,
    },
  },
}))

const getDropdownItems = (
  availableSurveys: SurveyNode[],
  selectedSurveys: SurveyNode[],
  controlsSurvey: InsightsSurvey,
  classes: ReturnType<typeof useStyles> & ReturnType<typeof useInsightsStyles>,
) => {
  // List of surveys with a string label separating the years
  const dropdownItems: ReactElement[] = []
  let currentYear: number | null = null
  availableSurveys
    // Omit the controls survey from the dropdown options, it's already displayed.
    .filter(survey => survey.uuid !== controlsSurvey.uuid)
    .forEach((survey, idx) => {
      const surveyYear = new Date(survey.endDate!).getFullYear()
      if (surveyYear !== currentYear) {
        currentYear = surveyYear
        dropdownItems.push(
          <Typography key={`${survey.uuid}year`} className={classes.selectOptionLabel}>
            {currentYear}
            {idx === 0 && availableSurveys.length - 2 >= MAX_SURVEYS && (
              <span className={classes.italics}>*Select up to {MAX_SURVEYS} surveys max</span>
            )}
          </Typography>,
        )
      }
      const checked = selectedSurveys.includes(survey)
      const disabled = selectedSurveys.length - 1 === MAX_SURVEYS && !checked
      dropdownItems.push(
        <MenuItem
          key={survey.uuid}
          value={survey.uuid}
          classes={{
            root: classes.multiSelectRow,
          }}
          disabled={disabled}
        >
          <Checkbox checked={checked} disabled={disabled} />
          <Typography>{survey.name}</Typography>
        </MenuItem>,
      )
    })
  return dropdownItems
}

export const getDefaultSelectedSurveys = (
  controlsSurveyUuid: string,
  availableSurveys: SurveyNode[],
) => {
  // Use controls survey
  const controlsSurveyIndex = availableSurveys.findIndex(s => s.uuid === controlsSurveyUuid)
  // If a survey is selected that is not valid for time trending while the dropdown is still active, reset the controls.
  if (controlsSurveyIndex === -1) {
    return []
  }
  if (availableSurveys.length === 1) {
    return availableSurveys
  }
  // Try to prepend the previous survey
  if (controlsSurveyIndex < availableSurveys.length - 1) {
    return [availableSurveys[controlsSurveyIndex + 1], availableSurveys[controlsSurveyIndex]]
  }
  // append the following survey
  return [availableSurveys[controlsSurveyIndex], availableSurveys[controlsSurveyIndex - 1]]
}

type Props = {
  surveys: SurveyNode[]
  controlsSurvey: InsightsSurvey
  chartSurveysKey: TimeTrendingSurveysKey
  excludePulse: boolean
}
const TimeTrendingSurveyDropdown: React.FC<Props> = ({
  surveys,
  controlsSurvey,
  chartSurveysKey,
  excludePulse,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }
  const {
    store: { [chartSurveysKey]: surveyIds },
    updateStore,
  } = useContext(StoreContext)

  const availableSurveys = getValidTimeTrendingSurveys(surveys, excludePulse)

  useUpdateEffect(() => {
    // Reset surveys to defaults when insights controls survey changes
    updateStore({
      [chartSurveysKey]: getDefaultSelectedSurveys(controlsSurvey.uuid, availableSurveys).map(
        s => s.uuid,
      ),
    })
  }, [updateStore, controlsSurvey.uuid])
  // Save initial default values to the store
  useEffect(() => {
    if (!surveyIds?.length) {
      updateStore({
        [chartSurveysKey]: getDefaultSelectedSurveys(controlsSurvey.uuid, availableSurveys).map(
          s => s.uuid,
        ),
      })
    }
    // eslint-disable-next-line
  }, [])
  const selectedSurveys = availableSurveys.filter(survey => surveyIds.includes(survey.uuid))
  const updateSelectedSurveys = (newSurveysUuids: string[]) => {
    // If one of the selected surveys is undefined, the user has
    // selected a "label". In this case, return without action.
    if (!newSurveysUuids.every(Boolean)) return
    // Make sure to include controls survey since it's not rendered in the dropdown.
    updateStore({ [chartSurveysKey]: [...newSurveysUuids, controlsSurvey.uuid] })
  }

  return (
    <TextDropdown
      id={chartSurveysKey}
      label={
        <>
          <Typography variant="body2" color="textSecondary">
            Compare:
          </Typography>
          <Typography variant="body2">&nbsp;{controlsSurvey.name}&nbsp;</Typography>
          <Typography variant="body2" color="textSecondary">
            to:&nbsp;
          </Typography>
        </>
      }
      multiple
      value={selectedSurveys.filter(s => s.uuid !== controlsSurvey.uuid).map(s => s.uuid)}
      displayEmpty
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      renderValue={selectedUuids => {
        const currentDropdownSurveys = availableSurveys.filter(s =>
          (selectedUuids as string[]).includes(s.uuid),
        )
        if (currentDropdownSurveys.length === 0) {
          return 'Select Surveys'
        }
        return `${currentDropdownSurveys.length} Selected`
      }}
      onChange={e => updateSelectedSurveys(e.target.value as string[])}
    >
      {getDropdownItems(availableSurveys, selectedSurveys, controlsSurvey, classes)}
    </TextDropdown>
  )
}

export default TimeTrendingSurveyDropdown
