import React, { useEffect, useState } from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import cn from 'classnames'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import { ReactComponent as AmbassadorsIcon } from 'assets/img/insights/testimonials/ambassadors-icon.svg'
import * as AmbassadorsImage from 'assets/img/insights/testimonials/ambassadors.png'
import { ReactComponent as MarketingIcon } from 'assets/img/insights/testimonials/marketing-icon.svg'
import * as MarketingImage from 'assets/img/insights/testimonials/marketing.png'
import { ReactComponent as SocialMediaIcon } from 'assets/img/insights/testimonials/social-media-icon.svg'
import * as SocialMediaImage from 'assets/img/insights/testimonials/social-media.png'
import SortButton from 'components/Blocks/CustomButtons/SortButton'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Pagination from 'components/Blocks/Pagination/EnumeratedPagination'
import SearchPopup from 'components/Blocks/Search/SearchPopup'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import ImageBanner from 'components/Insights/Testimonials/ImageBanner'
import Testimonial from 'components/Insights/Testimonials/Testimonial'
import { gaEvent } from 'config/ga'
import {
  useInsightsCommentsQuery,
  useInsightsTestimonialsMediaUrlQuery,
  BenchmarkCodeType,
} from 'generated/graphql'
import { URLS } from 'utils/constants'
import { useTimeframeSettings } from 'utils/customHooks'

const useStyles = makeStyles(({ spacing, palette }) => ({
  spacing: {
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    paddingBottom: spacing(3),
    paddingTop: spacing(3),
  },
  headerText: {
    flex: 1, // IE11
  },
  imageBanners: {
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: spacing(2),
  },
  searchPaperWrapper: {
    height: 50,
    padding: `0 ${spacing(2)}px`,
    position: 'absolute',
    bottom: -45,
    right: 0,
    zIndex: 3,
  },
  testimonials: {
    borderTop: `1px solid ${palette.common.navy25}`,
  },
  testimonialsBar: {
    marginBottom: spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  testimonialsBarControls: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '10%',
    position: 'relative',
    justifyContent: 'space-between',
    '& button:last-child': {
      color: palette.common.navy65,
    },
  },
  commentsPagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const Testimonials: React.FC<InsightsTabProps> = ({ survey, filters }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const { startDate, endDate } = useTimeframeSettings(survey)
  const pageSize = 20
  const [searchQuery, setSearchQuery] = useState('')
  const [page, setPage] = useState(0)
  const availableSorts = ['newest', 'oldest']
  const [currentSort, setCurrentSort] = useState('newest')
  useEffect(() => {
    setPage(0)
  }, [searchQuery])

  const variables = {
    surveyUuid: survey.uuid,
    questionCode: BenchmarkCodeType.TESTIMONIALS_REVIEW,
    pageSize,
    page,
    filters,
    sortBy: currentSort,
    searchWords: [searchQuery],
    startDate,
    endDate,
  }
  const result = useInsightsCommentsQuery({ variables })
  const mediaUrlResult = useInsightsTestimonialsMediaUrlQuery()
  return (
    <>
      <div className={classes.spacing}>
        <BreadcrumbsItem to={URLS.RESIDENT_INSIGHTS.TESTIMONIALS}>Testimonials</BreadcrumbsItem>

        <div className={classes.headerText}>
          <Typography className={classes.title} variant="subtitle1">
            CUSTOMER TESTIMONIALS FOR MARKETING
          </Typography>
          <Typography color="textSecondary" className={classes.description}>
            Not sure what to do with all these great comments? Here are some helpful tips showing
            how you can showcase them.
          </Typography>
        </div>
        <div className={classes.imageBanners}>
          <ImageBanner
            Icon={AmbassadorsIcon}
            title="Cultivate your Ambassadors"
            subtitle="Invite residents who give raving testimonials to join for special lunches, tours, Q&A."
            dialogContent={
              <>
                <p>
                  1. Read your testimonials and look at the residents and family members who shared
                  their names. Invite them to a special “ambassadors” group for their community,
                  where they are invited to Zoom calls to learn about each other and their community
                  as well as invited to special marketing events.
                </p>
                <p>
                  2. Download testimonials and comments to excel and analyze them to better
                  understand your customer journey and what your customers value about your
                  services.
                </p>
                <p>Example poster:</p>
              </>
            }
            dialogImage={AmbassadorsImage}
          />
          <ResponseHandler {...mediaUrlResult}>
            {({ insightsTestimonialsMediaUrl }) => {
              return (
                <ImageBanner
                  Icon={SocialMediaIcon}
                  title="Post on Social Media"
                  subtitle="Post your favorite testimonials on your socials, along with quote-relevant photos of
          your community and resident events."
                  dialogContent={
                    <>
                      <p>
                        1. Incorporate customer testimonial quotes into your social media post
                        schedule.
                      </p>
                      <p>
                        2. Download images to include{' '}
                        <ExternalNavLink to={insightsTestimonialsMediaUrl} label="HERE" />.
                      </p>
                      <p>Example Facebook post:</p>
                    </>
                  }
                  dialogImage={SocialMediaImage}
                />
              )
            }}
          </ResponseHandler>
          <ImageBanner
            Icon={MarketingIcon}
            title="Incorporate into Marketing Assets"
            subtitle="Showcase testimonials on your website as well as in letters, brochures, and other assets."
            dialogContent={
              <>
                <p>
                  1. Add testimonials to your website. Don’t just limit yourself to a testimonials
                  page - add them to your homepage and throughout your site.
                </p>
                <p>
                  2. Review the testimonials you’ve received and identify good case study
                  opportunities. Reach out to the person who left the testimonial to create a video
                  or written case study that you can share with prospective clients.
                </p>
                <p>
                  3. Add testimonials to your ads and other publicity materials to help prospective
                  clients understand what it’s like to live in your community.
                </p>
              </>
            }
            dialogImage={MarketingImage}
          />
        </div>
      </div>
      <div className={cn(classes.testimonials, classes.spacing)}>
        <div className={classes.testimonialsBar}>
          <Typography className={classes.title} variant="subtitle1">
            TESTIMONIALS
          </Typography>
          <div className={classes.testimonialsBarControls}>
            <SearchPopup
              searchQuery={searchQuery}
              handleSearch={(val: string) => {
                gaEvent({
                  action: 'searchTestimonials',
                  category: 'Insights',
                })
                setSearchQuery(val)
              }}
              showButtonText={false}
            />
            <SortButton
              size="small"
              availableOptions={availableSorts}
              currentOption={currentSort}
              onChange={sort => {
                gaEvent({
                  action: 'sortTestimonials',
                  category: 'Insights',
                })
                setCurrentSort(sort)
              }}
              labelsMap={{
                newest: 'Newest to Oldest',
                oldest: 'Oldest to Newest',
              }}
            />
          </div>
        </div>
        <ResponseHandler {...result}>
          {({ insightsComments }) => {
            const showPagination = insightsComments.totalComments > pageSize
            if (!insightsComments) return <div />
            return (
              <div>
                {insightsComments.comments.map((comment, idx) => {
                  return <Testimonial key={idx} comment={comment} index={idx} />
                })}
                {showPagination && (
                  <div className={classes.commentsPagination}>
                    <Pagination
                      totalPages={Math.ceil(insightsComments.totalComments / pageSize)}
                      currentPage={page}
                      onPageChange={(newPage: number) => {
                        gaEvent({
                          action: 'changePageTestimonials',
                          category: 'Insights',
                        })
                        setPage(newPage)
                      }}
                    />
                  </div>
                )}
              </div>
            )
          }}
        </ResponseHandler>
      </div>
    </>
  )
}

export default Testimonials
