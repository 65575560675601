import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'

import App from 'components/App'
import { StoreContext, useStore } from 'config/LocalStorage'
import withErrorHandler from 'HOC/withErrorHandler'

const AppContainer: React.FC<RouteComponentProps> = props => {
  const { store, updateStore } = useStore()
  return (
    <StoreContext.Provider value={{ store, updateStore }}>
      <App {...props} />
    </StoreContext.Provider>
  )
}

export default withErrorHandler(withRouter(AppContainer))
