import React from 'react'

import groupBy from 'lodash/groupBy'

import QueryHandler from 'components/Blocks/Layout/QueryHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import TTEmployeeExperienceCard from 'components/Insights/TimeTrending/Snapshot/TTEmployeeExperienceCard'
import TimeTrendingContainer from 'components/Insights/TimeTrending/TimeTrendingContainer'
import { TimeTrendingChartKey, TimeTrendingSurveysKey } from 'config/LocalStorage'
import { useInsightsCultureQuery, InsightsCultureChangeDocument } from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import { formatScore } from 'utils'

/**
 * scoreGroups is of format: [
 *  [[survey0, highTrust.score], [survey1, highTrust.score]]
 *  [[survey0, wantsMore.score], [survey1, wantsMore.score]]
 * ]
 * highlights is of format: [
 *  {
 *      label: 'High Trust',
 *      delta: 4,
 *      demographics: [
 *        [demographicType]: [{ change, value }]
 *      ]
 *  }
 * ]
 */

export const employeeExperienceProps = (cultureData, highlightsData) => {
  const scoreGroups = []
  const cultureDataMap = {
    highTrust: { label: 'High Trust' },
    checkedOut: { label: 'Checked Out' },
    wantsMore: { label: 'Wants More' },
    highRisk: { label: 'High Risk' },
  }
  Object.keys(cultureDataMap).forEach((key, cultureIndex) => {
    cultureData.forEach((result, surveyIndex) => {
      const { label } = cultureDataMap[key]
      if (!scoreGroups[cultureIndex]) {
        scoreGroups[cultureIndex] = { label, scores: [] }
      }
      const score = formatScore(result.culture[key].score)
      scoreGroups[cultureIndex].scores.push([surveyIndex, score])
    })
    // Filter missing scores when calculating delta.
    const scores = scoreGroups[cultureIndex].scores.filter(([_, score]) => score !== null)
    if (scores.length) {
      cultureDataMap[key].delta = scores[scores.length - 1][1] - scores[0][1]
    } else {
      cultureDataMap[key].delta = 0
    }
    cultureDataMap[key].demographics = groupBy(highlightsData[key], 'demographic')
  })
  return { scoreGroups, highlightsMap: cultureDataMap }
}

const TTEmployeeExperienceContainer = ({ filters, availableSurveys, survey, benchmark }) => {
  const classes = useInsightsStyles()
  // Note: this should be merged into a visual component, but we're going to wait until there's a new design.
  // and we can write a typescript file from scratch.
  return (
    <div className={classes.fullRow} id="tt-culture-snap">
      <SnapshotChartHeader
        title="Employee Experience Over Time"
        tooltip="These are the top scoring statements -
    they had the most positive scores - from the most recent survey you’ve selected.
    See how they have changed over time."
        useBottomPadding={false}
        hasTimeTrending
        timeTrendingChartKey={TimeTrendingChartKey.EMPLOYEE_CULTURE}
        snapId="tt-culture-snap"
      />
      <TimeTrendingContainer
        chartSurveysKey={TimeTrendingSurveysKey.EMPLOYEE_CULTURE}
        availableSurveys={availableSurveys}
        survey={survey}
        excludePulse
      >
        {({ selectedSurveys }) => (
          <QueryHandler
            query={InsightsCultureChangeDocument}
            variables={{
              surveyUuid1: selectedSurveys[0].uuid,
              surveyUuid2: selectedSurveys[selectedSurveys.length - 1].uuid,
              filters,
            }}
          >
            {data => {
              return (
                <DynamicSurveyQuery
                  surveys={selectedSurveys}
                  queryHook={useInsightsCultureQuery}
                  variables={{ filters, benchmark }}
                >
                  {cultureData => {
                    return (
                      <TTEmployeeExperienceCard
                        selectedSurveys={selectedSurveys}
                        {...employeeExperienceProps(cultureData, data.cultureChange)}
                        firstHighTrustScore={cultureData[0].culture.highTrust.score}
                      />
                    )
                  }}
                </DynamicSurveyQuery>
              )
            }}
          </QueryHandler>
        )}
      </TimeTrendingContainer>
    </div>
  )
}

export default TTEmployeeExperienceContainer
