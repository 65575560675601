import React from 'react'

import {
  ListSubheader,
  ListItemText,
  makeStyles,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core'
import cn from 'classnames'

import { taskStatuses } from 'components/ActionPlans/constants'
import { TaskStatusEnum } from 'generated/graphql'

const useStyles = makeStyles(({ spacing, palette }) => ({
  statusContainer: {
    width: '90%',
    borderRadius: 5,
  },
  statusSelect: {
    height: 20,
    maxWidth: 100,
    marginRight: spacing(2),
    color: '#FFF',
    '& svg': {
      marginRight: -spacing(),
    },
  },
  disabledIcon: {
    '& svg': {
      display: 'none',
    },
  },
  whiteIcon: {
    '& svg': {
      color: palette.common.white,
    },
  },
  navyIcon: {
    '& svg': {
      color: palette.common.navy,
    },
  },
  dropdrownControl: {
    marginLeft: spacing(2),
    width: 150,
    height: 32,
  },
  rootStatus: {
    fontSize: 12,
    textTransform: 'uppercase',
    paddingLeft: 5,
    '&.MuiSelect-filled': {
      paddingRight: 0,
    },
  },
  rootStatusNotStartedDisabled: {
    color: palette.common.navy50,
    backgroundColor: palette.common.iceGrey,
    '&:hover,&:focus': {
      backgroundColor: palette.common.iceGrey,
    },
  },
  rootStatusNotStarted: {
    color: palette.common.navy,
    backgroundColor: palette.common.iceGrey,
    '&:hover,&:focus': {
      backgroundColor: palette.common.iceGrey,
    },
  },
  rootStatusInProgress: {
    color: palette.common.navy,
    backgroundColor: '#CEECEA',
    '&:hover,&:focus': {
      backgroundColor: '#CEECEA',
    },
  },
  rootStatusOnHold: {
    color: palette.common.navy,
    backgroundColor: '#FFED49',
    '&:hover,&:focus': {
      backgroundColor: '#FFED49',
    },
  },
  rootStatusComplete: {
    color: palette.common.white,
    backgroundColor: '#53AF58',
    '&:hover,&:focus': {
      backgroundColor: '#53AF58',
    },
  },
  rootStatusIncomplete: {
    color: palette.common.navy,
    backgroundColor: '#FFD19A',
    '&:hover,&:focus': {
      backgroundColor: '#FFD19A',
    },
  },
}))

type Props = {
  status?: TaskStatusEnum | null
  onChange(status: TaskStatusEnum): void
  disabled: boolean
}

const TaskStatusDropdown: React.FC<Props> = ({ status, onChange, disabled }) => {
  const classes = useStyles()
  let selectClassName = status === TaskStatusEnum.COMPLETE ? classes.whiteIcon : classes.navyIcon
  if (disabled) {
    selectClassName = classes.disabledIcon
  }
  return (
    <div className={classes.statusContainer}>
      <FormControl variant="filled" className={classes.dropdrownControl}>
        <Select
          id="apStatusDropdown"
          disabled={disabled}
          className={cn(classes.statusSelect, selectClassName)}
          classes={{
            root: cn(classes.rootStatus, {
              [classes.rootStatusNotStartedDisabled]:
                disabled && status === TaskStatusEnum.NOT_STARTED,
              [classes.rootStatusNotStarted]: !disabled && status === TaskStatusEnum.NOT_STARTED,
              [classes.rootStatusInProgress]: status === TaskStatusEnum.IN_PROGRESS,
              [classes.rootStatusOnHold]: status === TaskStatusEnum.ON_HOLD,
              [classes.rootStatusComplete]: status === TaskStatusEnum.COMPLETE,
              [classes.rootStatusIncomplete]: status === TaskStatusEnum.INCOMPLETE,
            }),
          }}
          value={status}
          onChange={e => onChange(e.target.value as TaskStatusEnum)}
          renderValue={selected => taskStatuses.find(s => s.value === selected)?.text}
        >
          <ListSubheader>Update Status:</ListSubheader>
          {taskStatuses.map(apStatus => {
            return (
              <MenuItem key={apStatus.value} value={apStatus.value}>
                <ListItemText>{apStatus.text}</ListItemText>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

export default TaskStatusDropdown
