import React from 'react'

import { Card, Typography, makeStyles } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import cn from 'classnames'
import { QRCodeCanvas } from 'qrcode.react'
import { NavLink } from 'react-router-dom'

import CopyTextInput from 'components/Blocks/CopyTextInput'
import Button from 'components/Blocks/CustomButtons/Button'
import { SurveysSurveyQuery } from 'generated/graphql'
import { downloadQR } from 'utils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  section: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
  header: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
  },
  table: {
    borderTop: `2px solid ${palette.common.borderGrey}`,
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    '& >p': {
      textTransform: 'uppercase',
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
  },
  tableRow: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F7F7F7',
    borderTop: `1px solid ${palette.common.borderGrey}`,
  },
  copyLinkButton: {
    marginRight: 0,
    paddingRight: 0,
  },
  download: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadIcon: {
    color: palette.common.navy65,
    marginRight: spacing(),
  },
  qrcode: {
    display: 'none',
  },
}))

type Props = {
  survey: SurveysSurveyQuery['survey']
}
const Distribution: React.FC<Props> = ({ survey }) => {
  const classes = useStyles()

  return (
    <Card>
      <div className={cn(classes.section, classes.header)}>
        <Typography variant="h6">Distribution</Typography>
        <br />
        <Typography color="textSecondary">
          Copy and paste this survey link to share your survey. You can paste the link in an email
          or text message. Now you can easily share the survey with participants, anyone with the
          link can access this survey. The link will work as long as the survey is live.
        </Typography>
        <br />
        <Typography variant="h6">Summary</Typography>
      </div>
      <div className={classes.table}>
        <div className={cn(classes.section, classes.tableHeader)}>
          <Typography color="textSecondary">Link</Typography>
          <Typography color="textSecondary">Actions</Typography>
        </div>
        <div className={cn(classes.section, classes.tableRow)}>
          <CopyTextInput
            CopyIconElement={
              <Button className={classes.copyLinkButton} color="secondaryNoBackground">
                Copy Link
              </Button>
            }
            value={survey.surveyPortalUrl}
          />
        </div>
      </div>
      <div className={cn(classes.section, classes.header)}>
        <div className={classes.download}>
          <CloudDownloadIcon className={classes.downloadIcon} />
          <NavLink to="#" onClick={downloadQR}>
            Download QR Code
          </NavLink>
        </div>
        <div className={classes.qrcode}>
          <QRCodeCanvas
            id="qrcode"
            value={survey.surveyPortalUrl}
            size={290}
            level="H"
            includeMargin
          />
        </div>
      </div>
    </Card>
  )
}

export default Distribution
