import React, { useReducer, Dispatch, useEffect } from 'react'

import defaultsDeep from 'lodash/defaultsDeep'

import { SurveyFilterTab } from 'components/Survey/Dashboard/SurveyFilterSelect'
import {
  ActionPlansSortByEnum,
  BenchmarkNode,
  FilterProductTypePermissionEnum,
  ResponseRateTypeEnum,
} from 'generated/graphql'
import { FOLLOW_STATUS } from 'utils/constants'

// Keys which maintain the surveys selected for a given time trending component
export enum TimeTrendingSurveysKey {
  RESIDENT_INDEX_SCORE = 'residentIndexScoreTTSurveyIds',
  RESIDENT_DETAILED_BREAKDOWN = 'residentDetailedBreakdownTTSurveyIds',
  RESIDENT_SERVICES = 'residentServicesTTSurveyIds',
  RESIDENT_SNAPSHOT_STATEMENTS = 'residentSnapshotStatementsTTSurveyIds',
  RESIDENT_KEY_DEMOGRAPHICS = 'residentKeyDemographicsTTSurveyIds',
  RESIDENT_LONELINESS = 'residentLonelinessTTSurveyIds',
  RESIDENT_STATEMENTS = 'residentStatementsTabTTSurveyIds',
  EMPLOYEE_INDEX_SCORE = 'employeeIndexScoreTTSurveyIds',
  EMPLOYEE_DETAILED_BREAKDOWN = 'employeeDetailedBreakdownTTSurveyIds',
  EMPLOYEE_DIMENSIONS = 'employeeDimensionsTTSurveyIds',
  EMPLOYEE_SNAPSHOT_STATEMENTS = 'employeeStatementsTTSurveyIds',
  EMPLOYEE_KEY_DEMOGRAPHICS = 'employeeKeyDemographicsTTSurveyIds',
  EMPLOYEE_CULTURE = 'employeeCultureTTSurveyIds',
  EMPLOYEE_STATEMENTS = 'employeeStatementsTabTTSurveyIds',
}
// TODO: refactor to group chart and survey keys together under one key
// Keys which maintain whether a certain chart has enabled/disabled time trending
export enum TimeTrendingChartKey {
  RESIDENT_INDEX_SCORE = 'residentIndexScoreTT',
  RESIDENT_DETAILED_BREAKDOWN = 'residentDetailedBreakdownTT',
  RESIDENT_SERVICES = 'residentServicesTT',
  RESIDENT_SNAPSHOT_STATEMENTS = 'residentSnapshotStatementsTT',
  RESIDENT_KEY_DEMOGRAPHICS = 'residentKeyDemographicsTT',
  RESIDENT_LONELINESS = 'residentLonelinessTT',
  RESIDENT_STATEMENTS = 'residentStatementsTabTT',
  EMPLOYEE_STATEMENTS = 'employeeStatementsTabTT',
  EMPLOYEE_INDEX_SCORE = 'employeeIndexScoreTT',
  EMPLOYEE_DETAILED_BREAKDOWN = 'employeeDetailedBreakdownTT',
  EMPLOYEE_DIMENSIONS = 'employeeDimensionsTT',
  EMPLOYEE_SNAPSHOT_STATEMENTS = 'employeeStatementsTT',
  EMPLOYEE_KEY_DEMOGRAPHICS = 'employeeKeyDemographicsTT',
  EMPLOYEE_CULTURE = 'employeeCultureTT',
}

export type InsightsFilter = {
  id: string
  index: number
  dtCode: string
  name: string
  insightsProductType: FilterProductTypePermissionEnum
  valueUuid: string
  valueName: string
}

export type TimeframeSettings = {
  timeframe?: string | null
  customStartDate?: Date | null
  customEndDate?: Date | null
}

export type LocalStore = {
  currentUserId: string | null
  currentOrganizationUuid: string | null
  lastActionPlanPathname: string | null
  lastInsightsPathname: string | null
  insightsFilters: InsightsFilter[]
  employeeInsightsBenchmark: BenchmarkNode | null
  lastResidentInsightsPathname: string | null
  residentInsightsFilters: InsightsFilter[]
  residentInsightsBenchmark: BenchmarkNode | null
  timeTrendingSurveyIds: string[]
  customSurveyQuestionIds: string[]
  timeframeSettingsBySurvey: { [key: string]: TimeframeSettings }
  selectedFollowFilters: string[]
  actionPlansSearchQuery: string
  actionPlansSortBy: ActionPlansSortByEnum
  actionPlansSortAscending: boolean
  publicMonitorSurveyUuid: string | null
  responseRateGroupByFilterType1Code: { [surveyUuid: string]: string | null }
  responseRateGroupByFilterType2Code: { [surveyUuid: string]: string | null }
  responseRateShowReportBy: { [surveyUuid: string]: ResponseRateTypeEnum }
  // For survey types that show time trending globally, save an `enabled` setting per survey.
  // These surveys also need to save a `timeframe` setting that's specific to time-trending.
  timeTrendingSettingsBySurvey: { [surveyUuid: string]: { enabled: boolean; timeframe: string } }
  // Making more precise static types requires getting rid of abstraction in the component
  // because of the limitations of https://github.com/microsoft/TypeScript/issues/33591
  surveyDashboardFilterSettings: {
    [key in SurveyFilterTab]: string[]
  }
} & { [key in TimeTrendingSurveysKey]: string[] } &
  { [key in TimeTrendingChartKey]: boolean }

export const defaultStore: LocalStore = {
  currentUserId: null,
  currentOrganizationUuid: null,
  lastActionPlanPathname: null,
  lastInsightsPathname: null,
  insightsFilters: [],
  employeeInsightsBenchmark: null,
  lastResidentInsightsPathname: null,
  residentInsightsFilters: [],
  residentInsightsBenchmark: null,
  timeTrendingSurveyIds: [],
  customSurveyQuestionIds: ['All'],
  timeframeSettingsBySurvey: {},
  selectedFollowFilters: [FOLLOW_STATUS.all],
  actionPlansSortBy: ActionPlansSortByEnum.OWNER,
  actionPlansSortAscending: false,
  actionPlansSearchQuery: '',
  publicMonitorSurveyUuid: null,
  responseRateGroupByFilterType1Code: {},
  responseRateGroupByFilterType2Code: {},
  responseRateShowReportBy: {},
  surveyDashboardFilterSettings: {
    [SurveyFilterTab.SURVEY_TYPE]: ['all'],
    [SurveyFilterTab.STATUS]: ['all'],
  },
  [TimeTrendingSurveysKey.RESIDENT_INDEX_SCORE]: [],
  [TimeTrendingSurveysKey.RESIDENT_DETAILED_BREAKDOWN]: [],
  [TimeTrendingSurveysKey.RESIDENT_SERVICES]: [],
  [TimeTrendingSurveysKey.RESIDENT_SNAPSHOT_STATEMENTS]: [],
  [TimeTrendingSurveysKey.RESIDENT_KEY_DEMOGRAPHICS]: [],
  [TimeTrendingSurveysKey.RESIDENT_LONELINESS]: [],
  [TimeTrendingSurveysKey.RESIDENT_STATEMENTS]: [],
  [TimeTrendingSurveysKey.EMPLOYEE_STATEMENTS]: [],
  [TimeTrendingSurveysKey.EMPLOYEE_INDEX_SCORE]: [],
  [TimeTrendingSurveysKey.EMPLOYEE_DETAILED_BREAKDOWN]: [],
  [TimeTrendingSurveysKey.EMPLOYEE_SNAPSHOT_STATEMENTS]: [],
  [TimeTrendingSurveysKey.EMPLOYEE_KEY_DEMOGRAPHICS]: [],
  [TimeTrendingSurveysKey.EMPLOYEE_DIMENSIONS]: [],
  [TimeTrendingSurveysKey.EMPLOYEE_CULTURE]: [],
  [TimeTrendingChartKey.RESIDENT_INDEX_SCORE]: false,
  [TimeTrendingChartKey.RESIDENT_DETAILED_BREAKDOWN]: false,
  [TimeTrendingChartKey.RESIDENT_SERVICES]: false,
  [TimeTrendingChartKey.RESIDENT_SNAPSHOT_STATEMENTS]: false,
  [TimeTrendingChartKey.RESIDENT_KEY_DEMOGRAPHICS]: false,
  [TimeTrendingChartKey.RESIDENT_LONELINESS]: false,
  [TimeTrendingChartKey.RESIDENT_STATEMENTS]: false,
  [TimeTrendingChartKey.EMPLOYEE_STATEMENTS]: false,
  [TimeTrendingChartKey.EMPLOYEE_INDEX_SCORE]: false,
  [TimeTrendingChartKey.EMPLOYEE_DETAILED_BREAKDOWN]: false,
  [TimeTrendingChartKey.EMPLOYEE_SNAPSHOT_STATEMENTS]: false,
  [TimeTrendingChartKey.EMPLOYEE_KEY_DEMOGRAPHICS]: false,
  [TimeTrendingChartKey.EMPLOYEE_DIMENSIONS]: false,
  [TimeTrendingChartKey.EMPLOYEE_CULTURE]: false,
  timeTrendingSettingsBySurvey: {},
}

const STORE_ACCESS_KEY = 'store'

export const getStore = (): LocalStore => {
  const storeStringified = localStorage.getItem(STORE_ACCESS_KEY)
  // If store is loaded for the first time, make sure to set to the default.
  // Note: local storage return values are default stringified, so we need to check the string 'null'
  if (storeStringified === 'null' || storeStringified === null) {
    updateStore(defaultStore)
    return defaultStore
  }
  // When we deploy a new version of the store, users will need to receive default values.
  // Check for any keys not set and apply the defaults.
  const store = JSON.parse(storeStringified)
  return defaultsDeep(store, defaultStore)
}

export const updateStore = (store: LocalStore) => {
  localStorage.setItem(STORE_ACCESS_KEY, JSON.stringify(store))
}

export const resetStore = () => {
  updateStore(defaultStore)
}

const reducer = (oldStore: LocalStore, newStore: Partial<LocalStore>) => {
  return { ...oldStore, ...newStore }
}

// This function will be overwritten when useStore is rendered, cast it now so we can
// maintain the correct type.
const initialUpdateStore: Dispatch<Partial<LocalStore>> = () => {}
export const StoreContext = React.createContext<{
  store: LocalStore
  updateStore: typeof initialUpdateStore
}>({
  store: defaultStore,
  updateStore: initialUpdateStore,
})

export const useStore = () => {
  const [storeContext, updateStoreContext] = useReducer(reducer, getStore())
  useEffect(() => {
    // Keep local storage in sync with React Context
    updateStore(storeContext)
  }, [storeContext])
  return { store: storeContext, updateStore: updateStoreContext }
}
