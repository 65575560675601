import React, { useEffect, useRef, useState, RefObject } from 'react'

import { useApolloClient } from '@apollo/client'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import cn from 'classnames'
import { format } from 'date-fns'
import orderBy from 'lodash/orderBy'
import querystring from 'query-string'
import { useLocation } from 'react-router-dom'

import UpdateComment from 'components/ActionPlans/UpdateComment'
import Button from 'components/Blocks/CustomButtons/Button'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import {
  useActionPlansDeleteActionItemTaskCommentMutation,
  ActionPlansActionItemTaskCommentNodeConnectionFragment,
  CurrentUserDocument,
} from 'generated/graphql'
import { reverse } from 'utils'
import { ORDER_TYPES } from 'utils/constants'

const COMMENTS_MAX_HEIGHT = 600

const useCommentStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    paddingBottom: spacing(2),
    display: 'flex',
    '& >div:nth-child(1)': {
      width: '80%',
    },
    '& >div:nth-child(2)': {
      width: '20%',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  lastComment: {
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  comment: {
    paddingBottom: spacing(3),
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: spacing(),
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  highlight: {
    color: palette.common.secondary,
  },
}))

// The function transforms @ mentions to highlighted spans
// Example: "Hello @John!" => "Hello <span className={...}>@John</span>!"
export const convertTextToMarkupArray = (
  mentions: { index: number; name: string }[],
  text: string,
  className: string,
) => {
  if (!text) return []
  const arr = []
  let firstPart
  let secondPart
  let span
  let displayName
  orderBy(mentions, 'index', ORDER_TYPES.DESCENDING).forEach((m, idx) => {
    // we need to start replacing from the last mention to the first one, to achieve the replacement in one for loop
    // if we start replacing from the first mention, it's gonna fail on the indexes
    displayName = `@${m.name}`
    firstPart = text.substr(0, m.index)
    span = (
      <span key={idx} className={className}>
        {displayName}
      </span>
    )
    secondPart = text.substr(m.index + displayName.length)

    if (secondPart) arr.unshift(secondPart)
    arr.unshift(span)
    // eslint-disable-next-line no-param-reassign
    text = firstPart
  })
  if (text) arr.unshift(text)
  return arr
}

type CommentProps = {
  isLast: boolean
  comment: NonNullable<
    NonNullable<
      NonNullable<ActionPlansActionItemTaskCommentNodeConnectionFragment['edges']>[0]
    >['node']
  >
  actionItemTaskUuid: string
  targetUserUuid?: string
  userUuidFromUrl?: string[] | string | null
  commentUuidFromUrl?: string[] | string | null
  taskRef: RefObject<HTMLDivElement>
}

const Comment: React.FC<CommentProps> = ({
  isLast,
  comment,
  actionItemTaskUuid,
  targetUserUuid,
  userUuidFromUrl,
  commentUuidFromUrl,
  taskRef,
}) => {
  const classes = useCommentStyles()
  const client = useApolloClient()
  const currentUser = client.readQuery({ query: CurrentUserDocument }).currentUser
  const commentRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // scroll window to the comment specified in the URL if any
    if (comment.uuid === commentUuidFromUrl) {
      // userUuidFromUrl is the user to whichthe action plan belongs
      // we need to check if it's the current user's plan.
      //  if it is, the current page is Take Action in Insights and we need to scroll in perfectScrollbarContainer
      //  if it's notm the current page is Manage Action Plans and we need to scroll in the dialog in which the AP opens
      const isMyPlan = currentUser.uuid === userUuidFromUrl
      // perfectScrollbarContainer is one of the outermost divs of the app, the parent of PerfectScrollbar
      const id = isMyPlan ? 'perfectScrollbarContainer' : 'dialog'
      const container = document.getElementById(id)
      if (container && taskRef.current && commentRef.current) {
        if (taskRef.current.scrollHeight > COMMENTS_MAX_HEIGHT) {
          // if the comments section is scrollable, we need to offset the out scroll and the inner scroll
          container.scrollTop = taskRef.current.offsetTop
          if (isMyPlan) {
            // eslint-disable-next-line no-param-reassign
            taskRef.current.scrollTop = commentRef.current.offsetTop
          } else {
            // in the dialog we need to subtract the offset of the dialog and of the task's
            // eslint-disable-next-line no-param-reassign
            taskRef.current.scrollTop =
              commentRef.current.offsetTop - container.offsetTop - taskRef.current.offsetTop
          }
        } else {
          // but if it's not, it's enough if we just scroll the outer one
          container.scrollTop = commentRef.current.offsetTop
          if (!isMyPlan) {
            container.scrollTop -= container.offsetTop
          }
        }
      }
    }
  }, [currentUser, userUuidFromUrl, commentUuidFromUrl, commentRef, taskRef, comment.uuid])
  const [deleteComment] = useActionPlansDeleteActionItemTaskCommentMutation()
  const [editMode, setEditMode] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const characterLimit = 150
  let displayText = comment.text
  const [showPartialText, setShowPartialText] = useState(displayText.length > characterLimit)
  if (showPartialText) {
    if (displayText) {
      displayText = `${displayText.substring(0, characterLimit)}...`
    } else {
      displayText = ''
    }
  }
  // When comments are created, the backend saves the updated date milliseconds after the created date.
  // In order to make sure that the difference between updated and created dates reflects an actual user edit
  // — and not this millisecond difference — check that the difference between the dates exceeds a reasonable threshold, like 0.5 seconds.
  const timestampOffset = 500
  const wasCommentEdited =
    new Date(comment.updated).getTime() - new Date(comment.created).getTime() > timestampOffset
  return (
    <div className={classes.root} ref={commentRef}>
      <div className={cn(classes.comment, { [classes.lastComment]: !isLast })}>
        {editMode ? (
          <UpdateComment
            actionItemTaskUuid={actionItemTaskUuid}
            actionItemTaskCommentUuid={comment.uuid}
            targetUserUuid={targetUserUuid}
            setEditMode={setEditMode}
            defaultPlainText={comment.text}
            defaultMentions={orderBy(comment.mentions, 'index', ORDER_TYPES.ASCENDING).map(
              m => m.user,
            )}
          />
        ) : (
          <>
            <div className={classes.commentHeader}>
              <Typography variant="body2">{comment.user.name}</Typography>&nbsp;
              <Typography variant="body2" color="textSecondary">
                {`| ${format(new Date(comment.updated), 'MMM dd')} at ${format(
                  new Date(comment.updated),
                  'h:mm aa',
                )}`}
                {wasCommentEdited && <>&nbsp;(edited)</>}
              </Typography>
            </div>
            <Typography>
              {convertTextToMarkupArray(
                comment.mentions.map(m => ({ index: m.index, name: m.user.name })),
                displayText,
                classes.highlight,
              )}
              {showPartialText && (
                <Button color="secondaryNoBackground" noMargins>
                  <Typography onClick={() => setShowPartialText(false)}>&nbsp;Read More</Typography>
                </Button>
              )}
            </Typography>
          </>
        )}
      </div>
      <div className={classes.actions}>
        {currentUser?.id === comment.user.id && (
          <>
            <IconButton color="secondaryHover" onClick={() => setEditMode(!editMode)}>
              <EditIcon />
            </IconButton>
            <IconButton color="dangerHover" onClick={() => setOpenDeleteDialog(true)}>
              <DeleteIcon />
            </IconButton>
            {openDeleteDialog && (
              <ActionDialog
                title="Delete comment?"
                content="This action can’t be undone. Are you sure you want to delete your comment?"
                submitButtonText="Delete"
                onClose={() => setOpenDeleteDialog(false)}
                onSubmit={() =>
                  deleteComment({
                    variables: {
                      actionItemTaskCommentUuid: comment.uuid,
                      userUuid: targetUserUuid,
                    },
                  })
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    backgroundColor: palette.common.iceGrey,
    maxHeight: COMMENTS_MAX_HEIGHT,
    overflow: 'auto',
    paddingTop: spacing(4),
    '& >div:nth-child(2)': {
      paddingLeft: spacing(),
      paddingRight: spacing(),
      paddingBottom: spacing(3),
    },
  },
  newPost: {
    width: '80%',
  },
}))

type Props = {
  comments: ActionPlansActionItemTaskCommentNodeConnectionFragment
  visibleCommentsCount: number | null
  actionItemTaskUuid: string
  targetUserUuid?: string
}

const ActionItemTaskComments: React.FC<Props> = ({
  comments,
  visibleCommentsCount,
  actionItemTaskUuid,
  targetUserUuid,
}) => {
  const classes = useStyles()
  const taskRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const hashParams = querystring.parse(location.hash)
  // reverse the comments since they come sorted by ID from the backend
  let commentNodes = reverse(comments?.edges.map(e => e?.node))

  const [commentsCount, setCommentsCount] = useState(visibleCommentsCount)
  if (commentsCount) {
    // commentsCount can be null, case in which we display all comments and don't do any slicing
    commentNodes = commentNodes.slice(0, commentsCount)
  }

  return (
    <Grid container className={classes.root} ref={taskRef}>
      <Grid item sm={2} />
      <Grid item sm={10}>
        <div className={classes.newPost}>
          <UpdateComment
            actionItemTaskUuid={actionItemTaskUuid}
            targetUserUuid={targetUserUuid}
            defaultPlainText=""
            defaultMentions={[]}
          />
        </div>
        {commentNodes?.map(
          (comment, idx) =>
            comment && (
              <Comment
                key={comment.uuid}
                taskRef={taskRef}
                userUuidFromUrl={hashParams.user_uuid}
                commentUuidFromUrl={hashParams.comment_uuid}
                isLast={idx + 1 === commentNodes.length}
                comment={comment}
                actionItemTaskUuid={actionItemTaskUuid}
                targetUserUuid={targetUserUuid}
              />
            ),
        )}
        {commentsCount && (
          <Button color="secondaryNoBackground" noMargins onClick={() => setCommentsCount(null)}>
            View All Comments
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default ActionItemTaskComments
