import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core'
import Pusher from 'pusher-js'

import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import { PUSHER_APP_KEY } from 'config/config'
import emitter from 'shared/authenticated/emitter'

const useStyles = makeStyles(({ palette }) => ({
  downloadUrl: {
    color: palette.common.navy,
  },
}))

type Props = {
  userId: string
}

const Subscriptions: React.FC<Props> = ({ userId }) => {
  const classes = useStyles()
  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: 'us2',
      forceTLS: true,
      authEndpoint: '/backend/pusher-auth/',
      // Solves https://github.com/pusher/pusher-js/issues/392
      disableStats: true,
    })
    const channel = pusher.subscribe(`private-${userId}`)
    channel.bind('download-finished', data => {
      const body = (
        <>
          Your {data.downloadType} is ready!
          <br />
          Click{' '}
          <ExternalNavLink className={classes.downloadUrl} to={data.downloadUrl} label="HERE" /> to
          download it.
        </>
      )
      emitter.emit('SUCCESS', body, 50000 /** time before notification closes */)
    })
    channel.bind('participant-file-import-failed', () => {
      emitter.emit(
        'ERROR',
        `We had a problem importing your participants file.
        Ensure it is in the right format and not password protected.`,
      )
    })
    channel.bind('participant-file-parse-failed', () => {
      emitter.emit(
        'ERROR',
        'We had a problem processing your participants file. Please check the errors, fix your file, and then reupload it.',
      )
      emitter.emit('PARTICIPANT-FILE-PARSE-FAILED')
    })
    channel.bind('participant-file-parse-succeeded', () => {
      emitter.emit(
        'SUCCESS',
        'We successfully processed your participants file! Please check the summary and then create your participants.',
      )
      emitter.emit('PARTICIPANT-FILE-PARSE-SUCCEEDED')
    })
    channel.bind('participant-ingest-succeeded', () => {
      emitter.emit('SUCCESS', 'Participants have been added to your survey!')
      emitter.emit('PARTICIPANT-INGEST-SUCCEEDED')
    })
    channel.bind('participant-process-failed', () => {
      emitter.emit(
        'ERROR',
        `We had an internal problem processing your participants file.
          Please contact your program manager if the problem persists.
        `,
      )
    })
  }, [classes.downloadUrl, userId])
  return null
}

export default Subscriptions
