import React, { ReactElement } from 'react'

import {
  Typography,
  FormControl,
  FilledInput,
  MenuItem,
  Checkbox,
  ListItemText,
  ListSubheader,
  Select,
  Input,
  makeStyles,
  Tooltip,
  OutlinedInput,
  SelectProps,
} from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  containerLeftMargin: {
    '& >*': {
      marginLeft: spacing(2),
    },
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  menuItem: {
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: `${palette.common.secondary20} !important`,
    },
  },
  textDropdownInput: {
    padding: 0,
  },
  emptyTextDropdownInput: {
    color: palette.common.secondary,
  },
}))

type Props = {
  label?: string
  width?: number | string
  height?: number | string
  containerClassName?: string
  rootClassName?: string
  selectClassName?: string
  labelClassName?: string
  emptyLabel?: string
  id?: string
  header?: string
  disabled?: boolean
  menuItems: Array<{
    value: string
    text?: string
    isRequired?: boolean
    isDisabled?: boolean
    isDivider?: boolean
    tooltipMessage?: string | ReactElement
  }>
  selectedItems: string[]
  onChange(selectedValues: string[]): void
  // Display the dropdown component as text rather than an input field.
  useTextDropdown?: boolean
  withLeftMargin?: boolean
  size?: 'small' | 'medium'
} & Omit<SelectProps, 'onChange'>
const CheckboxDropdown: React.FC<Props> = ({
  onChange,
  menuItems,
  selectedItems,
  label,
  width = 150,
  height = 32,
  rootClassName,
  selectClassName,
  labelClassName,
  containerClassName,
  emptyLabel,
  id,
  header,
  useTextDropdown,
  variant,
  disabled = false,
  withLeftMargin = false,
  size = 'medium',
}) => {
  const classes = useStyles()
  let input = <FilledInput />
  if (useTextDropdown) {
    input = (
      <Input
        inputProps={{
          className: cn({
            [classes.textDropdownInput]: true,
            [classes.emptyTextDropdownInput]: selectedItems.length === 0,
          }),
        }}
        disableUnderline
      />
    )
  } else if (variant === 'outlined') {
    input = <OutlinedInput />
  }
  return (
    <div
      className={cn(classes.container, containerClassName, {
        [classes.containerLeftMargin]: withLeftMargin,
      })}
    >
      {label && (
        <Typography className={labelClassName} variant="body1" color="textSecondary">
          {label}
        </Typography>
      )}
      <FormControl
        variant={variant}
        className={classes.formContainer}
        style={{ width, height }}
        id={id}
        size={size}
      >
        <Select
          disabled={disabled}
          classes={{
            root: rootClassName,
          }}
          className={selectClassName}
          value={selectedItems}
          variant={variant}
          displayEmpty={Boolean(emptyLabel)}
          input={input}
          onChange={e => onChange(e.target.value as string[])}
          name="responseTypes"
          renderValue={selected => {
            const items = menuItems.filter(m => (selected as string[]).includes(m.value))
            // In case the label is given as "text" instead of "value"
            const itemLabels = items.map(i => i.text || i.value)
            if (itemLabels.length === 0) {
              if (useTextDropdown) return emptyLabel
              return (
                <Typography color="textSecondary" variant="body2">
                  {emptyLabel}
                </Typography>
              )
            }
            return itemLabels.join(', ')
          }}
          multiple
        >
          {header && <ListSubheader>{header}</ListSubheader>}
          {menuItems.map(({ value, text, isRequired, isDisabled, isDivider, tooltipMessage }) => {
            const classNames = [classes.menuItem]
            if (isDivider) classNames.push(classes.divider)
            const MenuItemComponent = (
              <MenuItem
                key={value}
                value={value}
                id={text?.split(' ').join('-')}
                className={cn(classNames)}
                disabled={isDisabled}
              >
                <Checkbox checked={selectedItems.includes(value)} disabled={isRequired} />
                <ListItemText primary={text || value} />
              </MenuItem>
            )
            // Rendering a tooltip above a menu item component has weird effects on
            // its select behavior, so only render it when available.
            if (tooltipMessage) {
              return (
                <Tooltip key={value} title={tooltipMessage}>
                  <div>{MenuItemComponent}</div>
                </Tooltip>
              )
            }
            return MenuItemComponent
          })}
        </Select>
      </FormControl>
    </div>
  )
}

export default CheckboxDropdown
