import React from 'react'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import withStyles from '@material-ui/core/styles/withStyles'
import cn from 'classnames'

import loginCardStyle from './LoginCardStyle'

const LoginCard = ({
  classes,
  headerColor,
  plainCard,
  cardTitle,
  content,
  footer,
  footerAlign,
  customCardClass,
}) => {
  const plainCardClasses = cn({
    [` ${classes.cardPlain}`]: plainCard,
    [` ${customCardClass}`]: customCardClass !== undefined,
  })
  return (
    <Card className={classes.card + plainCardClasses}>
      <CardHeader
        classes={{
          root: `${classes.cardHeader} ${classes[`${headerColor}CardHeader`]}`,
          title: classes.cardTitle,
        }}
        title={cardTitle}
      />
      <CardContent className={classes.cardContent}>{content}</CardContent>
      {footer !== undefined ? (
        <CardActions className={`${classes.cardActions} ${classes[footerAlign]}`}>
          {footer}
        </CardActions>
      ) : null}
    </Card>
  )
}

export default withStyles(loginCardStyle)(LoginCard)
