import React from 'react'

import { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import { colors } from 'shared/theme'

const barWidth = 40
const yAxis = {
  type: 'value',
  min: 0,
  max: 100,
  axisTick: { show: false },
  axisLine: { show: false },
  axisLabel: { show: false },
}

type PotentialProps = {
  currentScore: number
  improvementScore: number
}
export const FocusAreaPotentialImprovementChart: React.FC<PotentialProps> = ({
  currentScore,
  improvementScore,
}) => {
  return (
    <PrintableEchart
      notMerge
      printWidth={300}
      style={{
        width: 120,
        height: 250,
      }}
      option={{
        textStyle: chartTextStyle,
        xAxis: {
          type: 'category',
          axisLine: { show: false },
        },
        yAxis,
        series: [
          {
            data: [currentScore],
            barWidth,
            type: 'bar',
            stack: 'chart1',
            itemStyle: {
              color: colors.success,
            },
            label: {
              show: true,
              formatter: ({ data }: { data: number }) => `${Math.round(data)}%`,
              color: colors.white,
              position: 'insideTop',
            },
          },
          {
            data: [improvementScore - currentScore],
            barWidth,
            type: 'bar',
            stack: 'chart1',
            itemStyle: {
              color: '#CED6DA',
            },
            label: {
              show: true,
              formatter: ({ data }: { data: number }) => `${Math.round(data)}%`,
              color: colors.navy65,
              position: 'insideBottom',
            },
          },
        ],
      }}
    />
  )
}

export const FocusAreaImprovedChart: React.FC<{ score: number }> = ({ score }) => {
  return (
    <PrintableEchart
      notMerge
      printWidth={300}
      style={{
        width: 120,
        height: 250,
      }}
      option={{
        textStyle: chartTextStyle,
        // Use a "value" x-axis so we can insert a line + arrow after the bar chart.
        xAxis: {
          type: 'value',
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false },
        },
        yAxis,
        series: [
          // Center the bar chart by giving it a coordinate in the middle of 3 fake coordinates.
          {
            data: [[0, 0]],
            type: 'bar',
          },
          {
            data: [[0.5, score]],
            barWidth,
            type: 'bar',
            stack: 'chart1',
            itemStyle: {
              color: colors.success,
            },
            label: {
              show: true,
              formatter: ({ data }: { data: [number, number] }) => `${Math.round(data[1])}%`,
              color: colors.white,
              position: 'insideTop',
            },
            markLine: {
              silent: true,
              // Remove the dot at the bottom of the arrow
              symbol: ['none', 'arrow'],
              // Mark the line 85% of the way through the chart.
              data: [
                [
                  {
                    coord: [0.85, 0],
                    label: { show: false },
                  },
                  { coord: [0.85, score] },
                ],
              ],
            },
          },
          {
            data: [[1, 0]],
            type: 'bar',
          },
        ],
      }}
    />
  )
}
