// There's a bunch of issues using <span onClick/> prefer to replace that rather than appease the linter.
/* eslint-disable */
import React, { useState } from 'react'

import Button from 'components/Blocks/CustomButtons/Button'

type Props = {
  text: string
  characterLimit: number
  readMoreLabel?: string
  readLessLabel?: string
  hasReadLess?: boolean
  onTextClick?(): void
}

const TextExpandable: React.FC<Props> = ({
  text,
  characterLimit,
  onTextClick = () => {},
  readMoreLabel = 'Read More',
  readLessLabel = 'Read Less',
  hasReadLess = true,
}) => {
  const textIsLongerThanLimit = text.length > characterLimit
  const readLessLabelDisplay = hasReadLess && textIsLongerThanLimit ? readLessLabel : ''
  const [showPartialText, setShowPartialText] = useState(textIsLongerThanLimit)
  let displayText = text
  if (showPartialText) {
    if (displayText) {
      displayText = `${displayText.substring(0, characterLimit)}...`
    } else {
      displayText = ''
    }
  }
  return (
    <div>
      <span onClick={() => onTextClick()}>{displayText}</span>{' '}
      <Button
        color="secondaryNoBackground"
        noMargins
        size="sm"
        onClick={() => setShowPartialText(!showPartialText)}
      >
        {showPartialText ? readMoreLabel : readLessLabelDisplay}
      </Button>
    </div>
  )
}

export default TextExpandable
