import React, { useState } from 'react'

import { makeStyles, InputAdornment, Typography, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { RowInfo } from 'react-table'

import TablePanel from 'components/Blocks/Accordions/TablePanel'
import Button from 'components/Blocks/CustomButtons/Button'
import DebouncedTextInput from 'components/Blocks/FormHelpers/DebouncedTextInput'
import { Group, Survey } from 'components/Settings/Team/Groups/GroupsTypes'
import UpdateGroupDialog from 'components/Settings/Team/Groups/UpdateGroupDialog'
import {
  SettingsGroupFragment,
  SettingsGroupUserFragment,
  CurrentUserQuery,
  SolutionFieldEnum,
} from 'generated/graphql'
import { URLS } from 'utils/constants'
import { orgHasEnabledSolutionField } from 'utils/solution'

const useStyles = makeStyles(theme => ({
  actionsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 10,
    marginLeft: 20,
    '& >div': {
      display: 'flex',
    },
  },
  searchIcon: {
    color: theme.palette.common.navy65,
  },
  searchField: {
    marginLeft: 20,
    marginRight: 10,
  },
}))

type Props = {
  groups: SettingsGroupFragment[]
  surveys: Survey[]
  currentUser: CurrentUserQuery['currentUser']
  users: SettingsGroupUserFragment[]
  updateGroup(arg: Partial<Group>): Promise<boolean>
  searchQuery: string
  updateSearchQuery(val: string): void
}

const Groups: React.FC<Props> = ({
  groups,
  surveys,
  currentUser,
  users,
  updateGroup,
  searchQuery,
  updateSearchQuery,
}) => {
  const classes = useStyles()
  const [updateGroupDialogOpen, setUpdateGroupDialog] = useState(false)
  const [editGroupUuid, setEditGroupUuid] = useState<string | null>(null)
  const columns = [
    {
      Header: 'NAME',
      accessor: 'name',
    },
    {
      Header: 'MEMBERS',
      accessor: 'users',
      Cell: (row: { value: Partial<CurrentUserQuery['currentUser']>[] }) => row.value.length,
    },
    {
      Header: 'CREATED BY',
      accessor: 'createdBy',
      Cell: (row: { value?: Partial<CurrentUserQuery['currentUser']> }) =>
        row.value && row.value.name,
    },
  ]
  return (
    <>
      <BreadcrumbsItem to={URLS.ORG_SETTINGS.GROUPS}>Groups</BreadcrumbsItem>
      <TablePanel
        interactive={false}
        clickable
        reactTableProps={{
          data: groups,
          columns,
          defaultPageSize: 50,
          getTdProps: (finalState: any, rowInfo?: RowInfo) => ({
            onClick: () => {
              if (!rowInfo || rowInfo.original.isAdmin) {
                return
              }
              setEditGroupUuid(rowInfo.original.uuid || null)
              setUpdateGroupDialog(true)
            },
          }),
        }}
        actions={
          <div className={classes.actionsRow}>
            <div>
              <Typography variant="h6">Groups</Typography>
              <DebouncedTextInput
                InputComponent={TextField}
                delayTime={500}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
                name="groupSearch"
                className={classes.searchField}
                value={searchQuery}
                onUpdate={val => updateSearchQuery(val)}
              />
            </div>
            {!orgHasEnabledSolutionField(
              currentUser.organization,
              SolutionFieldEnum.ADMIN_INVITES_ONLY,
            ) && (
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    setEditGroupUuid(null)
                    setUpdateGroupDialog(true)
                  }}
                >
                  Create Group
                </Button>
              </div>
            )}
          </div>
        }
      />
      {updateGroupDialogOpen && (
        <UpdateGroupDialog
          groupToEdit={groups.find(g => g.uuid === editGroupUuid)}
          onClose={() => {
            setEditGroupUuid(null)
            setUpdateGroupDialog(false)
          }}
          surveys={surveys}
          currentUser={currentUser}
          users={users}
          updateGroup={updateGroup}
          existingGroups={groups}
        />
      )}
    </>
  )
}

export default Groups
