import React from 'react'

import ReactEcharts from 'echarts-for-react'

import { PIE_COLORS } from 'components/ActionPlans/constants'
import { TaskStatusEnum } from 'generated/graphql'

interface Props {
  counts: { [key in TaskStatusEnum]: number }
  height?: number
  width?: number
}

const ProgressPie: React.FC<Props> = ({ counts, height = 100, width = 100 }) => {
  const total =
    counts.NOT_STARTED + counts.IN_PROGRESS + counts.ON_HOLD + counts.COMPLETE + counts.INCOMPLETE
  return (
    <ReactEcharts
      notMerge
      style={{
        height,
        width,
      }}
      option={{
        color: [
          PIE_COLORS.NOT_STARTED,
          PIE_COLORS.IN_PROGRESS,
          PIE_COLORS.ON_HOLD,
          PIE_COLORS.COMPLETE,
          PIE_COLORS.INCOMPLETE,
        ],
        series: [
          {
            name: 'Progress',
            type: 'pie',
            silent: true,
            radius: ['75%', '100%'],
            label: {
              show: false,
            },
            data:
              total === 0
                ? [{ value: counts.NOT_STARTED, name: 'Not Started' }]
                : [
                    { value: counts.NOT_STARTED, name: 'Not Started' },
                    { value: counts.IN_PROGRESS, name: 'In Progress' },
                    { value: counts.ON_HOLD, name: 'On Hold' },
                    { value: counts.COMPLETE, name: 'Complete' },
                    { value: counts.INCOMPLETE, name: 'Later' },
                  ],
          },
        ],
      }}
    />
  )
}

export default ProgressPie
