import React from 'react'

import { makeStyles, IconButton, Typography, Tooltip } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import isNil from 'lodash/isNil'

import TablePanel from 'components/Blocks/Accordions/TablePanel'
import { HierarchyScoresByGroup } from 'components/Blocks/Charts/ScatterPlotContainer'
import ActionTooltip from 'components/Blocks/Tooltips/ActionTooltip'
import {
  getQuadrantFromPlotPoint,
  ScatterQuadrants,
} from 'components/Insights/TimeTrending/ScatterPlot'
import { quadrantToColor, tableSortMethod } from 'components/Insights/TimeTrending/TTTableView'
import { FilterTypeFragment } from 'generated/graphql'
import { generateXlsxDownload } from 'utils'

const useStyles = makeStyles(({ spacing, palette }) => ({
  tablePanel: {
    marginTop: spacing(3),
  },
  table: {
    height: 600,
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: spacing(2),
  },
  downloadIcon: {
    color: palette.common.navy65,
  },
}))

type Row = {
  level1FilterTypeName?: string
  level2FilterTypeName: string
  employeeScore: number | string
  residentScore: number | string
  quadrantTitle: string
}
type Props = {
  quadrantTitles: ScatterQuadrants
  level1FilterType: FilterTypeFragment
  level2FilterType: FilterTypeFragment
  employeeSurveyDate: string
  residentSurveyDate: string
  employeeIndexScore: number
  residentIndexScore: number
  data: HierarchyScoresByGroup
}
const OverlayTableView: React.FC<Props> = ({
  quadrantTitles,
  level1FilterType,
  level2FilterType,
  employeeSurveyDate,
  residentSurveyDate,
  data,
  employeeIndexScore,
  residentIndexScore,
}) => {
  const isMultiIndex = level1FilterType !== level2FilterType
  const classes = useStyles()
  const rowStyle = { height: 52, display: 'flex', alignItems: 'center' }
  // Include "name" property for the download.
  const columns = [
    {
      Header: `${level2FilterType.name}`,
      name: `${level2FilterType.name}`,
      accessor: 'level2FilterTypeName',
      style: rowStyle,
    },
    {
      Header: (
        <Tooltip title={`${employeeSurveyDate} Trust Index Score`}>
          <span>{employeeSurveyDate} TIS</span>
        </Tooltip>
      ),
      name: `${employeeSurveyDate} TIS`,
      accessor: 'employeeScore',
      style: rowStyle,
      sortMethod: tableSortMethod,
    },
    {
      Header: (
        <Tooltip title={`${residentSurveyDate} Customer Engagement Score`}>
          <span>{residentSurveyDate} CES</span>
        </Tooltip>
      ),
      name: `${residentSurveyDate} CES`,
      accessor: 'residentScore',
      style: rowStyle,
      sortMethod: tableSortMethod,
    },
    {
      Header: 'Status',
      name: 'Status',
      accessor: 'quadrantTitle',
      style: rowStyle,
      Cell: (row: { value: string }) => (
        <Typography
          style={{
            color:
              quadrantToColor[
                Object.keys(quadrantTitles).find(
                  key => quadrantTitles[key as keyof ScatterQuadrants] === row.value,
                ) as keyof ScatterQuadrants
              ],
          }}
        >
          {row.value}
        </Typography>
      ),
    },
  ]
  if (isMultiIndex) {
    columns.unshift({
      Header: `${level1FilterType.name} Name`,
      accessor: 'level1FilterTypeName',
      style: rowStyle,
      name: `${level1FilterType.name} Name`,
    })
  }
  const rows: Row[] = []
  Object.keys(data).forEach(level1FilterTypeName => {
    Object.keys(data[level1FilterTypeName]).forEach(level2FilterTypeName => {
      const surveyScores = Object.values(data[level1FilterTypeName][level2FilterTypeName])
      const employeeScore = surveyScores[0]
      const residentScore = surveyScores[1]
      let quadrantTitle: string = 'N/A'
      if (!isNil(employeeScore) && !isNil(residentScore)) {
        quadrantTitle =
          quadrantTitles[
            getQuadrantFromPlotPoint(
              employeeScore,
              residentScore,
              employeeIndexScore,
              residentIndexScore,
            )
          ]
      }
      const row: Row = {
        level2FilterTypeName,
        employeeScore: isNil(employeeScore) ? '–' : employeeScore,
        residentScore: isNil(residentScore) ? '–' : residentScore,
        // Use title instead of quadrant so that it's displayed properly in the download.
        quadrantTitle,
      }
      if (isMultiIndex) {
        row.level1FilterTypeName = level1FilterTypeName
      }
      rows.push(row)
    })
  })
  return (
    <div className={classes.tablePanel}>
      <TablePanel
        title={
          <div className={classes.titleRow}>
            {level2FilterType.name} Scores
            <ActionTooltip
              title={
                <>
                  <CloudDownloadIcon />
                  <Typography>Download to Excel</Typography>
                </>
              }
              placement="top"
            >
              <IconButton
                onClick={() =>
                  generateXlsxDownload(
                    columns,
                    rows,
                    'Overay Report Breakdown',
                    `Overlay-Report-Breakdown-${employeeSurveyDate}-${residentSurveyDate}.xlsx`,
                  )
                }
                id="overlay-table-download"
              >
                <CloudDownloadIcon className={classes.downloadIcon} />
              </IconButton>
            </ActionTooltip>
          </div>
        }
        gutterBottom
        interactive={false}
        reactTableProps={{
          columns,
          data: rows,
          showPagination: false,
          extraClassname: classes.table,
          // Avoid truncating results by specifying the number of rows that will appear.
          defaultPageSize: rows.length,
          minRows: 10,
        }}
      />
    </div>
  )
}

export default OverlayTableView
