import React, { useState } from 'react'

import {
  makeStyles,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  Tooltip,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import NotificationIcon from '@material-ui/icons/NotificationImportantOutlined'

import Panel from 'components/Blocks/Accordions/Panel'
import Danger from 'components/Blocks/Typography/Danger'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import {
  SurveyResponseNotificationTypeEnum,
  SurveyScoreNotificationTypeEnum,
  SurveyScoreNotificationFrequencyEnum,
  CurrentUserSurveyResponseNotificationsQuery,
} from 'generated/graphql'
import {
  SURVEY_RESPONSE_NOTIFICATION_TYPES_ENUM,
  NOTIFICATION_SCORE_TYPE_LABEL,
  SCORE_TYPE_NOTIFICATION_TYPES_ENUM,
} from 'utils/constants'

const useStyles = makeStyles(({ palette, spacing }) => ({
  notificationRow: {
    display: 'flex',
    alignItems: 'center',
    '& >div': {
      marginRight: spacing(2),
    },
    '& >svg': {
      color: palette.common.navy65,
      '&:hover': {
        color: palette.common.navy,
      },
    },
  },
  checked: {
    color: palette.common.secondary,
  },
  unchecked: {
    color: palette.common.navy65,
  },
  subtitle: {
    marginTop: spacing(),
    marginBottom: spacing(4),
    width: '70%',
  },
}))

type SurveyResultNotifications = NonNullable<
  CurrentUserSurveyResponseNotificationsQuery['currentUser']['userResultNotifications']
>[0]

type ResponseNotificationsInput = {
  surveyUuid: string
  notificationTypes: NonNullable<SurveyResultNotifications['surveyResponseNotificationTypes']>
}[]

type AverageScoreNotificationsInput = {
  surveyUuid: string
  averageScoreNotifications: NonNullable<SurveyResultNotifications['averageScoreNotifications']>
}[]

type QuestionScoreNotificationsInput = {
  surveyUuid: string
  questionScoreNotifications: NonNullable<SurveyResultNotifications['questionScoreNotifications']>
}[]

const FREQUENCIES = Object.keys(
  SurveyScoreNotificationFrequencyEnum,
) as SurveyScoreNotificationFrequencyEnum[]

type Props = {
  surveys: {
    uuid: string
    name: string
  }[]
  userHasSurveyResponseNotifications: boolean
  setUserHasSurveyResponseNotifications(userHasSurveyResponseNotifications: boolean): void
  responseNotificationsInput: ResponseNotificationsInput
  setResponseNotificationsInput(notificationsInput: ResponseNotificationsInput): void
  averageScoreNotificationsInput: AverageScoreNotificationsInput
  setAverageScoreNotificationsInput(notificationsInput: AverageScoreNotificationsInput): void
  questionScoreNotificationsInput: QuestionScoreNotificationsInput
  setQuestionScoreNotificationsInput(notificationsInput: QuestionScoreNotificationsInput): void
}

const UserNotificationsSurveyResponses: React.FC<Props> = ({
  surveys,
  userHasSurveyResponseNotifications,
  setUserHasSurveyResponseNotifications,
  responseNotificationsInput,
  setResponseNotificationsInput,
  averageScoreNotificationsInput,
  setAverageScoreNotificationsInput,
  questionScoreNotificationsInput,
  setQuestionScoreNotificationsInput,
}) => {
  const classes = useStyles()
  const [expandedSurveyUuid, setExpandedSurveyUuid] = useState('')

  // This condition happens when the user doesn't have any draft/scheduled/live surveys
  if (responseNotificationsInput.length === 0) {
    return (
      <EmptyState
        withBackground
        withLargeDescription
        title="No Available Surveys"
        Icon={NotificationIcon}
        description="Currently you do not have surveys available for survey response notifications. Once you have an open-ended, non-anonymous survey you will be able to edit your notification preferences here."
      />
    )
  }

  const allSurveyResponseNotificationTypes = Object.keys(
    SurveyResponseNotificationTypeEnum,
  ) as SurveyResponseNotificationTypeEnum[]

  const scoreTypes = Object.keys(
    SurveyScoreNotificationTypeEnum,
  ) as SurveyScoreNotificationTypeEnum[]

  return (
    <div>
      <Typography variant="h4">Settings</Typography>
      <FormControlLabel
        control={
          <Switch
            name="Notifications"
            checked={userHasSurveyResponseNotifications}
            onChange={() =>
              setUserHasSurveyResponseNotifications(!userHasSurveyResponseNotifications)
            }
          />
        }
        label="Notifications for survey responses"
      />
      <Typography color="textSecondary" className={classes.subtitle}>
        If this setting is turned off, you will not receive any email notifications for survey
        responses.
      </Typography>
      {surveys.map(survey => {
        const surveyResponseNotificationTypes = responseNotificationsInput.find(
          n => n.surveyUuid === survey.uuid,
        )?.notificationTypes

        const averageScoreNotifications = averageScoreNotificationsInput.find(
          n => n.surveyUuid === survey.uuid,
        )?.averageScoreNotifications

        const questionScoreNotifications = questionScoreNotificationsInput.find(
          n => n.surveyUuid === survey.uuid,
        )?.questionScoreNotifications

        if (
          !surveyResponseNotificationTypes &&
          !averageScoreNotifications &&
          !questionScoreNotifications
        ) {
          return <></>
        }

        return (
          <Panel
            key={survey.uuid}
            title={survey.name}
            elevation={0}
            expanded={expandedSurveyUuid === survey.uuid}
            onExpandChange={() =>
              setExpandedSurveyUuid(expandedSurveyUuid === survey.uuid ? '' : survey.uuid)
            }
          >
            <div>
              {surveyResponseNotificationTypes && (
                <div>
                  <Typography color="textSecondary">New Response Notifications</Typography>
                  {allSurveyResponseNotificationTypes.map(notificationType => {
                    const isEnabled = Boolean(
                      surveyResponseNotificationTypes.includes(notificationType),
                    )
                    return (
                      <div key={notificationType}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!userHasSurveyResponseNotifications}
                              checked={isEnabled && userHasSurveyResponseNotifications}
                              className={isEnabled ? classes.checked : classes.unchecked}
                              onClick={() => {
                                setResponseNotificationsInput(
                                  responseNotificationsInput.map(n => {
                                    if (n.surveyUuid !== survey.uuid) {
                                      return n
                                    }
                                    const newNotificationTypes = isEnabled
                                      ? n.notificationTypes.filter(nt => nt !== notificationType)
                                      : [...n.notificationTypes, notificationType]

                                    return {
                                      ...n,
                                      notificationTypes: newNotificationTypes,
                                    }
                                  }),
                                )
                              }}
                            />
                          }
                          label={
                            <Typography>
                              {SURVEY_RESPONSE_NOTIFICATION_TYPES_ENUM[notificationType]}
                            </Typography>
                          }
                        />
                      </div>
                    )
                  })}
                </div>
              )}
              {((averageScoreNotifications && averageScoreNotifications.length > 0) ||
                (questionScoreNotifications && questionScoreNotifications.length > 0)) && (
                <div>
                  <br />
                  <Typography color="textSecondary">Score Based Notifications</Typography>
                  {averageScoreNotifications &&
                    scoreTypes?.map(scoreType => {
                      const notification = averageScoreNotifications.find(
                        n => n.scoreType === scoreType,
                      )
                      const isEnabled = notification?.enabled || false
                      return (
                        <div key={scoreType} className={classes.notificationRow}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={!userHasSurveyResponseNotifications}
                                checked={isEnabled && userHasSurveyResponseNotifications}
                                className={isEnabled ? classes.checked : classes.unchecked}
                                onClick={() => {
                                  setAverageScoreNotificationsInput(
                                    averageScoreNotificationsInput.map(nInput => {
                                      if (nInput.surveyUuid !== survey.uuid) {
                                        return nInput
                                      }
                                      let newAverageScoreNotifications
                                      if (notification) {
                                        // If a user notification exists for the curret scoreType, we need to toggle enabled
                                        newAverageScoreNotifications = averageScoreNotifications.map(
                                          n =>
                                            n.scoreType === scoreType
                                              ? {
                                                  ...n,
                                                  enabled: !isEnabled,
                                                }
                                              : n,
                                        )
                                      } else {
                                        // If a user notification doesn't exist for the curret scoreType, we need to create one and append it to existing ones
                                        newAverageScoreNotifications = [
                                          ...averageScoreNotifications,
                                          {
                                            enabled: true,
                                            scoreType,
                                            frequency: SurveyScoreNotificationFrequencyEnum.DAILY,
                                          },
                                        ]
                                      }
                                      return {
                                        surveyUuid: survey.uuid,
                                        averageScoreNotifications: newAverageScoreNotifications,
                                      }
                                    }),
                                  )
                                }}
                              />
                            }
                            label={
                              <Typography>
                                {
                                  NOTIFICATION_SCORE_TYPE_LABEL[
                                    scoreType as SurveyScoreNotificationTypeEnum
                                  ]
                                }{' '}
                                recap:
                              </Typography>
                            }
                          />
                          <FormControl style={{ width: 160 }}>
                            <Select
                              displayEmpty
                              renderValue={value =>
                                value ? (
                                  SCORE_TYPE_NOTIFICATION_TYPES_ENUM[
                                    value as SurveyScoreNotificationFrequencyEnum
                                  ]
                                ) : (
                                  <Typography variant="body2" color="secondary">
                                    {notification?.frequency ? (
                                      'Select Frequency'
                                    ) : (
                                      <Danger>Select Frequency</Danger>
                                    )}
                                  </Typography>
                                )
                              }
                              value={notification?.frequency}
                              onChange={e => {
                                const frequency = (e.target as HTMLInputElement)
                                  .value as SurveyScoreNotificationFrequencyEnum
                                setAverageScoreNotificationsInput(
                                  averageScoreNotificationsInput.map(nInput => {
                                    if (nInput.surveyUuid !== survey.uuid) {
                                      return nInput
                                    }
                                    let newAverageScoreNotifications
                                    if (notification) {
                                      // If a user notification exists for the curret scoreType, we need to change its frequency
                                      newAverageScoreNotifications = averageScoreNotifications.map(
                                        n =>
                                          n.scoreType === scoreType
                                            ? {
                                                ...n,
                                                frequency,
                                              }
                                            : n,
                                      )
                                    } else {
                                      // If a user notification doesn't exist for the curret scoreType, we need to create one and append it to existing ones
                                      newAverageScoreNotifications = [
                                        ...averageScoreNotifications,
                                        {
                                          enabled: false,
                                          scoreType,
                                          frequency,
                                        },
                                      ]
                                    }
                                    return {
                                      surveyUuid: survey.uuid,
                                      averageScoreNotifications: newAverageScoreNotifications,
                                    }
                                  }),
                                )
                              }}
                            >
                              {FREQUENCIES.map(frequency => (
                                <MenuItem key={frequency} value={frequency}>
                                  {SCORE_TYPE_NOTIFICATION_TYPES_ENUM[frequency]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Tooltip
                            title={
                              <div>
                                <Typography>
                                  {
                                    NOTIFICATION_SCORE_TYPE_LABEL[
                                      scoreType as SurveyScoreNotificationTypeEnum
                                    ]
                                  }
                                </Typography>
                                <Typography color="textSecondary">
                                  We will send a recap notification{' '}
                                  {notification?.frequency &&
                                    SCORE_TYPE_NOTIFICATION_TYPES_ENUM[notification.frequency]}{' '}
                                  when the survey receives a {scoreType} Average Score of a{' '}
                                  {scoreType === SurveyScoreNotificationTypeEnum.LOW &&
                                    '2.99 or below'}
                                  {scoreType === SurveyScoreNotificationTypeEnum.HIGH &&
                                    '4.00 or above'}
                                  .
                                </Typography>
                              </div>
                            }
                          >
                            <InfoIcon fontSize="small" />
                          </Tooltip>
                        </div>
                      )
                    })}
                  {questionScoreNotifications?.map(notification => {
                    const isEnabled = notification.enabled || false
                    return (
                      <div
                        key={notification.groupNotification.uuid}
                        className={classes.notificationRow}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!userHasSurveyResponseNotifications}
                              checked={isEnabled && userHasSurveyResponseNotifications}
                              className={isEnabled ? classes.checked : classes.unchecked}
                              onClick={() => {
                                setQuestionScoreNotificationsInput(
                                  questionScoreNotificationsInput.map(nInput => {
                                    if (nInput.surveyUuid !== survey.uuid) {
                                      return nInput
                                    }
                                    return {
                                      surveyUuid: survey.uuid,
                                      questionScoreNotifications: nInput.questionScoreNotifications.map(
                                        n =>
                                          n.groupNotification.uuid ===
                                          notification.groupNotification.uuid
                                            ? { ...n, enabled: !n.enabled }
                                            : n,
                                      ),
                                    }
                                  }),
                                )
                              }}
                            />
                          }
                          label={
                            <Typography>
                              {notification.groupNotification.notificationName}:
                            </Typography>
                          }
                        />
                        <FormControl style={{ width: 160 }}>
                          <Select
                            displayEmpty
                            renderValue={value =>
                              value ? (
                                SCORE_TYPE_NOTIFICATION_TYPES_ENUM[
                                  value as SurveyScoreNotificationFrequencyEnum
                                ]
                              ) : (
                                <Typography variant="body2" color="secondary">
                                  {notification.frequency ? (
                                    'Select Frequency'
                                  ) : (
                                    <Danger>Select Frequency</Danger>
                                  )}
                                </Typography>
                              )
                            }
                            value={notification.frequency}
                            onChange={e => {
                              setQuestionScoreNotificationsInput(
                                questionScoreNotificationsInput.map(nInput => {
                                  if (nInput.surveyUuid !== survey.uuid) {
                                    return nInput
                                  }
                                  return {
                                    surveyUuid: survey.uuid,
                                    questionScoreNotifications: nInput.questionScoreNotifications.map(
                                      n =>
                                        n.groupNotification.uuid ===
                                        notification.groupNotification.uuid
                                          ? {
                                              ...n,
                                              frequency: (e.target as HTMLInputElement)
                                                .value as SurveyScoreNotificationFrequencyEnum,
                                            }
                                          : n,
                                    ),
                                  }
                                }),
                              )
                            }}
                          >
                            {FREQUENCIES.map(frequency => (
                              <MenuItem key={frequency} value={frequency}>
                                {SCORE_TYPE_NOTIFICATION_TYPES_ENUM[frequency]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Tooltip
                          title={
                            <div>
                              <Typography>
                                {notification.groupNotification.notificationName}
                              </Typography>
                              <Typography color="textSecondary">
                                We will send a notification{' '}
                                {notification.frequency &&
                                  SCORE_TYPE_NOTIFICATION_TYPES_ENUM[notification.frequency]}{' '}
                                when the question, "{notification.groupNotification.statement.text}"
                                receives a{' '}
                                {
                                  NOTIFICATION_SCORE_TYPE_LABEL[
                                    notification.groupNotification.scoreType
                                  ]
                                }{' '}
                                between {notification.groupNotification.minScore} and{' '}
                                {notification.groupNotification.maxScore}.
                              </Typography>
                            </div>
                          }
                        >
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </Panel>
        )
      })}
    </div>
  )
}

export default UserNotificationsSurveyResponses
