import React from 'react'

import { makeStyles, Card, CardContent, Typography } from '@material-ui/core'

import { ReactComponent as Icon } from 'assets/img/no_closed_surveys.svg'

const useStyles = makeStyles(() => ({
  card: {
    marginTop: 30,
    width: 300,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  title: {
    marginBottom: 10,
  },
  icon: {
    paddingTop: 50,
    paddingBottom: 50,
  },
}))

const NoCompletedItems = () => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" className={classes.title}>
          Come back soon!
        </Typography>
        <Icon />
        <Typography color="textSecondary">
          After you've started working on your action plan come here to see completed statements and
          how your scores have changed!
        </Typography>
      </CardContent>
    </Card>
  )
}

export default NoCompletedItems
