import React, { useState } from 'react'

import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import useActionPlansStyles from './styles'

import { AddStatementsSteps } from 'components/ActionPlans/constants'
import StatementsStateStep from 'components/ActionPlans/StatementsStateStep'
import StatementsStep from 'components/ActionPlans/StatementsStep'
import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import { gaEvent } from 'config/ga'
import { useActionPlansAddActionPlanStatementsMutation } from 'generated/graphql'
import { SurveyNodeAP as SurveyNode } from 'utils/types'

type Props = {
  surveys: SurveyNode[]
  targetUserUuid?: string
  onClose(): void
}

const AddStatementsDialog: React.FC<Props> = ({ surveys, targetUserUuid, onClose }) => {
  const classes = useActionPlansStyles()
  const [statementUuids, setStatementUuids] = useState<string[]>([])
  const [includeDefaultActionItems, setIncludeDefaultActionItems] = useState<null | boolean>(null)
  const [addStatements, { loading }] = useActionPlansAddActionPlanStatementsMutation()
  const [currentStep, setStep] = useState(0)
  const steps = [AddStatementsSteps.CHOOSE_STATEMENTS, AddStatementsSteps.PICK_STATEMENTS_STATE]

  const handleAddStatements = () => {
    if (includeDefaultActionItems === null) {
      return
    }
    gaEvent({
      action: 'actionItemAddSurveyStatement',
      category: 'ActionPlans',
    })
    const variables = {
      userUuid: targetUserUuid,
      statementUuids,
      includeDefaultActionItems,
    }
    addStatements({ variables })
  }

  const isFirstStep = currentStep === 0
  const isLastStep = currentStep + 1 === steps.length

  const goBack = () => {
    if (isFirstStep) {
      onClose()
    } else {
      setStep(currentStep - 1)
    }
  }

  const goNext = () => {
    if (isLastStep) {
      handleAddStatements()
      onClose()
    } else {
      setStep(currentStep + 1)
    }
  }

  return (
    <Dialog open fullWidth onClose={onClose} maxWidth="md" aria-labelledby="statements-dialog">
      <DialogTitle id="statements-dialog" className={classes.dialogTitle}>
        <div className={classes.title}>
          <Typography variant="h4">
            {steps[currentStep] === AddStatementsSteps.CHOOSE_STATEMENTS
              ? 'Step 1: Pick Your Statements'
              : 'Step 2: Select Format'}
          </Typography>
        </div>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent id="statements-dialog-content">
        {steps[currentStep] === AddStatementsSteps.CHOOSE_STATEMENTS && (
          <StatementsStep
            targetUserUuid={targetUserUuid}
            surveys={surveys}
            statementUuids={statementUuids}
            setStatementUuids={setStatementUuids}
          />
        )}
        {steps[currentStep] === AddStatementsSteps.PICK_STATEMENTS_STATE && (
          <StatementsStateStep
            includeDefaultActionItems={includeDefaultActionItems}
            setIncludeDefaultActionItems={setIncludeDefaultActionItems}
          />
        )}
      </DialogContent>
      <ActionDialogButtons
        onClose={goBack}
        submitDisabled={
          (steps[currentStep] === AddStatementsSteps.CHOOSE_STATEMENTS &&
            statementUuids.length === 0) ||
          (steps[currentStep] === AddStatementsSteps.PICK_STATEMENTS_STATE &&
            includeDefaultActionItems === null)
        }
        onSubmit={goNext}
        cancelButtonText="Back"
        submitButtonText={isLastStep ? 'Save' : 'Next'}
        isSubmitting={loading}
      />
    </Dialog>
  )
}

export default AddStatementsDialog
