import React, { ReactElement } from 'react'

import { makeStyles, Grid, Typography } from '@material-ui/core'
import cn from 'classnames'

import { ReactComponent as ExpiresSoonIcon } from 'assets/img/certification_hub/expire-soon-graphic.svg'
import Button from 'components/Blocks/CustomButtons/Button'
import { gaEvent } from 'config/ga'
import { colors } from 'shared/theme'

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    padding: spacing(3),
    margin: spacing(3),
    borderRadius: 3,
    background: palette.common.navy05,
    display: 'flex',
    '& >div': {
      '& >p': {
        paddingTop: spacing(),
      },
      '& >button': {
        marginTop: spacing(2),
        marginLeft: 0,
      },
    },
  },
  imageBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& >p': {
      color: palette.common.danger,
    },
  },
}))

type Props = {
  title: string
  description: string
  buttonLabel: string
  image?: ReactElement
  imageText?: string
  backgroundColor?: string
  url: string
}
const CertificationHubBanner: React.FC<Props> = ({
  title,
  description,
  buttonLabel,
  imageText,
  backgroundColor = colors.navy05,
  image = <ExpiresSoonIcon />,
  url,
}) => {
  const classes = useStyles()
  return (
    <Grid
      className={cn({
        [classes.container]: true,
      })}
      style={{ backgroundColor }}
    >
      <Grid item sm={8}>
        <Typography variant="h5">{title}</Typography>
        <Typography color="textSecondary">{description}</Typography>
        <Button
          onClick={() => {
            gaEvent({
              action: `clickBannerButton${buttonLabel}`.replace(/\s/g, ''),
              category: 'CertificationHub',
            })
            window.open(url, '_blank')
          }}
        >
          {buttonLabel}
        </Button>
      </Grid>
      <Grid item sm={4} className={classes.imageBlock}>
        {image}
        {imageText && <Typography>{imageText}</Typography>}
      </Grid>
    </Grid>
  )
}

export default CertificationHubBanner
