import React from 'react'

import cloneDeep from 'lodash/cloneDeep'

import CustomQuestion, {
  CustomQuestionType,
} from 'components/Survey/Wizard/Steps/Questions/CustomQuestion'
import {
  QKind,
  QuestionChoiceInput,
  QuestionInput,
  useSurveysUpdateCustomQuestionMutation,
} from 'generated/graphql'
import { LINEAR_QUESTION_CHOICE_OPTIONS } from 'utils/constants'

export const getQuestionInputVars = (question?: CustomQuestionType): QuestionInput =>
  question
    ? {
        questionUuid: question.uuid,
        index: question.index,
        kind: question.kind,
        text: question.text,
        choices:
          question.choices?.map(({ uuid, text, value }) => ({
            uuid,
            text,
            value,
          })) || [],
      }
    : {
        questionUuid: null,
        index: null,
        kind: QKind.SHORT_ANSWER,
        text: '',
        choices: [],
      }

type Props = {
  surveyUuid: string
  question: CustomQuestionType
  index: number
  handleAddQuestion(question: CustomQuestionType): void
  handleDeleteQuestion(questionUuid: string): void
}

const CustomQuestionContainer: React.FC<Props> = ({ surveyUuid, question, ...restProps }) => {
  const [updateCustomQuestion] = useSurveysUpdateCustomQuestionMutation()

  const handleUpdateQuestion = (questionAttrs: Partial<QuestionInput>) => {
    const newQuestion = Object.assign(cloneDeep(question), questionAttrs)
    updateCustomQuestion({
      variables: {
        surveyUuid,
        question: getQuestionInputVars(newQuestion),
      },
      // we're using the optimistic response because without it, if one update is triggered and the server takes a while
      // to process it, an immediate second update can follow up but with the wrong data, since the first update hasn't returned an update from the server
      optimisticResponse: {
        __typename: 'Mutation',
        updateCustomQuestion: {
          __typename: 'UpdateCustomQuestion',
          errors: null,
          question: {
            ...newQuestion,
            choices: (newQuestion.choices || []).map(c => ({
              ...c,
              __typename: 'QuestionChoiceType',
            })),
            __typename: 'QuestionType',
          },
        },
      },
    })
  }

  const handleDeleteQuestionChoice = (choiceUuid: string) => {
    handleUpdateQuestion({
      choices: question.choices?.filter(({ uuid }) => uuid !== choiceUuid),
    })
  }

  const handleAddNewQuestionChoice = (choiceText: string) => {
    const choices = question.choices || []
    handleUpdateQuestion({
      choices: [
        ...choices,
        {
          uuid: null,
          text: choiceText,
        },
      ],
    })
  }

  const handleUpdateQuestionChoices = (choices: QuestionChoiceInput[]) => {
    handleUpdateQuestion({
      choices,
    })
  }

  const handleQuestionTextChange = (text: string) => {
    handleUpdateQuestion({ text })
  }

  const handleQuestionKindChange = (kind: QKind) => {
    const kindsWithChoices = [QKind.MULTIPLE_CHOICE, QKind.MULTISELECT, QKind.LINEAR]
    const oldKindHasChoices = kindsWithChoices.includes(question.kind)
    const newKindHasChoices = kindsWithChoices.includes(kind)

    let choices: QuestionChoiceInput[] | undefined = question.choices || []
    if (question.kind !== QKind.LINEAR && kind === QKind.LINEAR) {
      choices = LINEAR_QUESTION_CHOICE_OPTIONS.map(value => ({
        text: '',
        value,
      }))
    } else if (!oldKindHasChoices && newKindHasChoices) {
      choices = [{ text: '', uuid: null }]
    } else if (oldKindHasChoices && !newKindHasChoices) {
      choices = []
    }
    return handleUpdateQuestion({
      kind,
      choices,
    })
  }
  return (
    <CustomQuestion
      question={question}
      handleQuestionTextChange={handleQuestionTextChange}
      handleUpdateQuestionChoices={handleUpdateQuestionChoices}
      handleAddNewQuestionChoice={handleAddNewQuestionChoice}
      handleDeleteQuestionChoice={handleDeleteQuestionChoice}
      handleQuestionKindChange={handleQuestionKindChange}
      {...restProps}
    />
  )
}

export default CustomQuestionContainer
