import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import { QuestionText } from 'components/Survey/Blocks/Questions'
import { SurveysQuestionsQuery, BenchmarkCodeType } from 'generated/graphql'

const useStyles = makeStyles(({ spacing, palette }) => ({
  questionsList: {
    borderTop: `1px solid ${palette.common.navy25}`,
    backgroundColor: palette.common.navy05,
    padding: spacing(2),
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
  },
  question: {
    marginBottom: spacing(),
  },
}))

type Props = {
  questions: SurveysQuestionsQuery['questions']
  surveyUuid: string
}

const PersonalInfoQuestions: React.FC<Props> = ({ questions, surveyUuid }) => {
  const classes = useStyles()
  return (
    <FormPanel
      removeDetailsPadding
      expandIcon={EXPAND_ICON.ARROW}
      square
      summary={
        <GridContainer>
          <ItemGrid sm={4}>
            <Typography variant="h6">Personal Info</Typography>
          </ItemGrid>
          <ItemGrid sm={8}>
            <Typography color="textSecondary">
              When using a shared link for your survey, including these additional personal info
              questions will help collect more accurate data.
            </Typography>
            <br />
            <br />
            <Typography color="textSecondary" variant="body2">
              <i>Answer: Multiple Choice</i>
            </Typography>
          </ItemGrid>
        </GridContainer>
      }
    >
      <div className={classes.questionsList}>
        {questions.map((question, index) => {
          return (
            <div key={question.uuid} className={classes.question}>
              <QuestionText
                index={index + 1}
                question={question}
                category={question.category}
                surveyUuid={surveyUuid}
                handleOpenDeleteQuestion={null}
                existingQuestions={null}
              />
              {question.benchmarkCode === BenchmarkCodeType.CLIENT_NAME && (
                <i>
                  <Typography color="textSecondary">
                    (This question is only asked when a family member is taking the survey).
                  </Typography>
                </i>
              )}
            </div>
          )
        })}
        <br />
        <Typography color="textSecondary">
          You may edit the question text and answer options for these questions by selecting the
          pencil icon.
        </Typography>
      </div>
    </FormPanel>
  )
}

export default PersonalInfoQuestions
