import React, { useContext } from 'react'

import { FilledInput, FormControl, MenuItem, Select, Typography } from '@material-ui/core'

import useInsightsStyles from 'components/Insights/InsightsStyle'
import { getTimeTrendingTimeframes } from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { StoreContext } from 'config/LocalStorage'

// Using `uuid` in order to match keys across time trending, but for purposes here
// we need the id to be reproducible.
export type TimeRange = { startDate: string; endDate: string; uuid: string }

// Used specifically to select timeframes with time trending.
type Props = {
  surveyUuid: string
}
const TimeTrendingTimeframeDropdown: React.FC<Props> = ({ surveyUuid }) => {
  const classes = useInsightsStyles()
  const {
    store: { timeTrendingSettingsBySurvey },
    updateStore,
  } = useContext(StoreContext)
  return (
    <>
      <Typography>Time frame:</Typography>
      <FormControl variant="filled" style={{ maxWidth: 150 }} id="timeframe-selector">
        <Select
          value={timeTrendingSettingsBySurvey[surveyUuid]?.timeframe}
          className={classes.customDropdownSelect}
          onChange={e => {
            const ttSurveySettings = timeTrendingSettingsBySurvey[surveyUuid] || {}
            updateStore({
              timeTrendingSettingsBySurvey: {
                ...timeTrendingSettingsBySurvey,
                [surveyUuid]: {
                  ...ttSurveySettings,
                  timeframe: e.target.value as string,
                },
              },
            })
          }}
          input={<FilledInput />}
        >
          {getTimeTrendingTimeframes().map(({ label }) => {
            return (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  )
}

export default TimeTrendingTimeframeDropdown
