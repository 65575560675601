import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import times from 'lodash/times'

import DashedBlueLine from 'assets/img/echarts/dashed-blue-line.png'
import SolidBlueLine from 'assets/img/echarts/solid-blue-line.png'
import ZoomInIcon from 'assets/img/echarts/zoom-in-icon.png'
import ZoomResetIcon from 'assets/img/echarts/zoom-reset-icon.png'
import { chartTextStyle } from 'components/Insights/InsightsStyle'
import { ECHARTS_TOOLTIP_WIDTH } from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import { colors } from 'shared/theme'
import { ORDER_TYPES } from 'utils/constants'
import { getFormattedSurveyDate } from 'utils/dateUtils'

export const zoomTool = {
  icon: {
    zoom: `image://${ZoomInIcon}`,
    back: `image://${ZoomResetIcon}`,
  },
}

export const getBenchmarkLegendData = (companyLegendName: string, benchmarkName: string) => [
  { name: companyLegendName, icon: `image://${SolidBlueLine}` },
  { name: benchmarkName, icon: `image://${DashedBlueLine}` },
]

export const lineSeriesVars = {
  type: 'line',
  lineStyle: { width: 2.5 },
  symbolSize: 7,
}

const CHART_BOUND_PADDING = 10
export const surveysYAxis = ({
  splitNumber = null,
}: {
  splitNumber?: number | null
  max?: number | null
}) => ({
  type: 'value',
  boundaryGap: false,
  // Hide `splitLine` to prevent rendering horizontal grid lines on every split.
  splitLine: { show: false },
  // The number of yAxis labels shown
  splitNumber,
  axisTick: { show: false },
  axisLine: { show: false },
  min: ({ min }: { min: number }) => {
    const minBound = min - CHART_BOUND_PADDING
    return Math.round(minBound > 0 ? minBound : 0)
  },
  max: ({ max }: { max: number }) => {
    const maxBound = max + CHART_BOUND_PADDING
    return Math.round(maxBound < 100 ? maxBound : 100)
  },
})

// Format a label to span two lines before truncating.
// Consult test file for use cases.
export const formatLongAxisLabel = ({
  label,
  maxLabelLineLength = 8,
}: {
  label: string
  maxLabelLineLength?: number
}) => {
  const words = label.split(' ')
  const lines = ['', '']
  let lineIndex = 0
  let wordIndex = 0
  while (wordIndex < words.length) {
    const word = words[wordIndex]
    // If current line plus the new word will be too long,
    // move to the next line or truncate and break.
    if (lines[lineIndex].length + word.length > maxLabelLineLength) {
      // If the word is longer than the entire label or we are on the second line,
      // truncate and return.
      if (word.length > maxLabelLineLength || lineIndex === 1) {
        lines[lineIndex] = `${(lines[lineIndex] + word).slice(0, maxLabelLineLength - 3)}...`
        break
      }
      lineIndex += 1
      continue
    }
    lines[lineIndex] += `${word} `
    wordIndex += 1
  }
  return lines
    .filter(l => l.length) // Ignore lines with no text
    .map(l => l.trim()) // Remove whitespace
    .join('\n')
}

export type TimeTrendXAxisLabelData = Array<{
  uuid: string
  minShowableResults: number
  name?: string
  startDate?: string
  endDate?: string | null
}>
export const timetrendXAxis = ({
  axisLabelData,
  labelDisplayStrategy = 'firstLast',
  maxLabelLineLength = 8,
  useDateLabel = false,
  margin = 8,
}: {
  axisLabelData: TimeTrendXAxisLabelData
  labelDisplayStrategy?: 'firstLast' | 'every'
  maxLabelLineLength?: number
  useDateLabel?: boolean
  margin?: number
}) => ({
  type: 'category',
  boundaryGap: false,
  data: axisLabelData.map(d => {
    return useDateLabel ? getFormattedSurveyDate({ ...d, useAbbreviation: true }) : d.name
  }),
  axisLabel: {
    margin,
    formatter: (name: string, index: number) => {
      const labelText = formatLongAxisLabel({ label: name, maxLabelLineLength })
      if (
        labelDisplayStrategy === 'firstLast' &&
        (index === 0 || index === axisLabelData.length - 1)
      ) {
        return labelText
      }
      if (labelDisplayStrategy === 'every') {
        return labelText
      }
      return ''
    },
  },
  axisTick: { show: false },
  axisLine: { show: false },
})

// Grid lines are not drawn when the x-axis is of type "category". Manually draw them instead.
export const gridLines = ({ numCategories }: { numCategories: number }) => ({
  type: 'line',
  animation: false,
  markLine: {
    silent: true,
    symbolSize: 0,
    data: [
      // Draw the x-axis grid line.
      {
        yAxis: 0,
        lineStyle: { type: 'solid', color: '#ccc' },
        label: { show: false },
      },
      // Draw vertical grid lines on category boundaries.
      ...times(numCategories, index => ({
        xAxis: index,
        lineStyle: { type: 'solid', color: '#ccc' },
        label: { show: false },
      })),
    ],
  },
})

export const surveysBottomLegend = {
  left: 0,
  bottom: 0,
  textStyle: { ...chartTextStyle, fontSize: 12 },
  itemHeight: 2,
  itemWidth: 12,
  // up, right, down, left
  padding: [0, 0, 0, 100],
}

export const surveysTopLegend = {
  align: 'right',
  top: 12,
  right: '5%',
  width: '100%',
  textStyle: {
    ...chartTextStyle,
    fontSize: 12,
  },
  itemWidth: 20,
  itemHeight: 10,
  itemGap: 20,
}

// In order to render a custom style label on the right side of the grid,
// draw an invisible line with a label at the end.
export const chartLabels = ({
  labels,
  minLabelOverlap = 6,
}: {
  labels: Array<{ value?: number | null; color: string }>
  minLabelOverlap?: number
}) => {
  // Order labels from highest to lowest, ignoring null/undefined values.
  const orderedLabels = orderBy(labels, 'value', ORDER_TYPES.DESCENDING).filter(
    label => label.value || label.value === 0,
  ) as Array<{ value: number; color: string }>
  // Only display labels that won't overlap.
  const validLabels = orderedLabels.splice(0, 1)
  orderedLabels.forEach(label => {
    if (validLabels.every(current => Math.abs(current.value - label.value) > minLabelOverlap)) {
      validLabels.push(label)
    }
  })
  return {
    type: 'line',
    markLine: {
      symbolSize: 0,
      data: validLabels.map(({ value, color }) => ({
        yAxis: value,
        lineStyle: { opacity: 0 },
        label: { normal: { color } },
      })),
    },
  }
}

export const tooltipStyle = {
  backgroundColor: '#FFF',
  borderColor: colors.navy25,
  borderWidth: 1,
  padding: 0,
  textStyle: chartTextStyle,
}

export const surveysTooltip = ({
  overrideDefaultPosition = true,
  trigger = 'axis',
  fixedPosition = false,
}) => ({
  trigger,
  ...tooltipStyle,
  position(pos: [number, number], params: {}, dom: {}, rect: {}, size: { viewSize: number[] }) {
    if (!overrideDefaultPosition) {
      return null
    }
    // Tooltip x-position: fixed on the right if mouse hovering on the left, and vice versa.
    const isMouseOnLeft = pos[0] < size.viewSize[0] / 2
    if (fixedPosition) {
      const xAdjustment = isMouseOnLeft ? 30 : -(ECHARTS_TOOLTIP_WIDTH + 30)
      // Fix the y-position
      return [pos[0] + xAdjustment, '10%']
    }
    // Set y-position next to the mouse
    const object = { top: pos[1] + 25 }
    if (isMouseOnLeft) {
      return { ...object, left: 5 }
    }
    return { ...object, right: 5 }
  },
})

export const benchmarkLine = ({
  value,
  name,
  color,
  type = 'solid',
  axis = 'yAxis',
  width = 1,
  showLabel = false,
  opacity = 1.0,
  labelProps = {},
  silent = false,
}: {
  value?: number | null
  name?: string
  color?: string
  type?: string
  axis?: string
  width?: number
  opacity?: number
  showLabel?: boolean
  silent?: boolean
  labelProps?: {
    padding?: number[]
    fontSize?: number
    color?: string
    position?: string
    formatter?: ((val: string) => string | string[]) | null
    rich?: { [key: string]: { [key: string]: string | number } }
  }
}) => {
  if (isNil(value)) return null
  return {
    animation: false,
    type: 'line',
    name,
    // Include a data point for the value so that the min and max calculations include the benchmark.
    data: [[0, value]],
    // Hide the point on the chart.
    symbolSize: 0,
    markLine: {
      name,
      silent,
      symbolSize: 0,
      lineStyle: { color, width, type, opacity },
      label: {
        ...labelProps,
        show: showLabel,
      },
      data: [
        {
          [axis]: value,
        },
        {
          [axis]: value,
        },
      ],
    },
  }
}
