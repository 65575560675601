import React, { ReactElement, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { FilledInput, makeStyles, MenuItem, Paper, Typography } from '@material-ui/core'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import OverlayReport from 'components/Analytics/OverlayReport'
import OverlayTeaser from 'components/Analytics/OverlayTeaser'
import PrintButton from 'components/Blocks/CustomButtons/PrintButton'
import Dropdown from 'components/Blocks/Dropdowns/Dropdown'
import FilterControls from 'components/Blocks/Filters/FilterControls'
import Page from 'components/Blocks/Layout/Page'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { toggleFilter, removeFilter, clearFilters } from 'components/Insights/InsightsContainer'
import { gaEvent } from 'config/ga'
import { InsightsFilter } from 'config/LocalStorage'
import {
  CurrentUserDocument,
  CurrentUserQuery,
  SurveyStatusEnum,
  SurveyTypeEnum,
  useAnalyticsSurveysQuery,
  AnalyticsSurveysQuery,
  FilterTypeFragment,
  FilterValueFragment,
  SolutionFieldEnum,
  SurveyProductTypeEnum,
  useInsightsSurveysFilterValuesQuery,
} from 'generated/graphql'
import { PRODUCT_TYPE_LABELS, PRODUCT_TYPE_TO_OVERLAY_COLORS } from 'utils/constants'
import { orgHasEnabledSolutionField } from 'utils/solution'

export type AnalyticsSurveyNode = NonNullable<
  NonNullable<AnalyticsSurveysQuery['surveys']['edges'][0]>['node']
>

const useStyles = makeStyles(({ palette, spacing }) => ({
  surveyDropdownRow: {
    height: 50,
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    display: 'flex',
    borderBottom: `1px solid ${palette.common.navy25}`,
    background: palette.common.navy,
    '& >div': {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      '& >p': {
        color: 'white',
      },
    },
  },
  bulletPoint: {
    width: 12,
    height: 12,
    marginRight: 12,
    borderRadius: 12,
  },
}))

type CProps = {
  employeeSurveys: AnalyticsSurveyNode[]
  residentSurveys: AnalyticsSurveyNode[]
  currentUser: CurrentUserQuery['currentUser']
  children(props: {
    employeeSurvey: AnalyticsSurveyNode
    residentSurvey: AnalyticsSurveyNode
    filters: string[]
    visibleFilterTypes: FilterTypeFragment[]
  }): ReactElement
}
const AnalyticsControls: React.FC<CProps> = ({
  employeeSurveys,
  residentSurveys,
  currentUser,
  children,
}) => {
  const classes = useStyles()
  const [employeeSurvey, setEmployeeSurvey] = useState(employeeSurveys[0])
  const [residentSurvey, setResidentSurvey] = useState(residentSurveys[0])
  const [selectedFilters, setSelectedFilters] = useState<InsightsFilter[]>([])
  const result = useInsightsSurveysFilterValuesQuery({
    variables: { surveyUuids: [employeeSurvey.uuid, residentSurvey.uuid], filters: [] },
  })
  return (
    <ResponseHandler {...result}>
      {({ insightsSurveysFilterValues }) => {
        // Only show filter types that have at least one filter value that exists on both surveys.
        const visibleDtCodes = insightsSurveysFilterValues
          .filter(fvData => fvData.groups.length > 0)
          .map(fvData => fvData.dtCode)
        // Limit to the current user's access.
        const visibleFilterTypes = currentUser.filters.filter(ft =>
          visibleDtCodes.includes(ft.dtCode),
        )
        return (
          <>
            <FilterControls
              filters={visibleFilterTypes}
              selectedFilters={selectedFilters}
              surveyUuids={[employeeSurvey.uuid, residentSurvey.uuid]}
              surveyProductTypes={[employeeSurvey.productType, residentSurvey.productType]}
              toggleFilter={(filterType: FilterTypeFragment, filterValue: FilterValueFragment) => {
                gaEvent({
                  action: 'toggleAnalyticsFilter',
                  category: 'Analytics',
                })
                toggleFilter(filterType, filterValue, selectedFilters, setSelectedFilters)
              }}
              removeFilter={(filterObj: InsightsFilter) =>
                removeFilter(filterObj, selectedFilters, setSelectedFilters)
              }
              clearFilters={() => clearFilters(setSelectedFilters)}
              fetchPolicy="cache-first"
            >
              <PrintButton />
            </FilterControls>
            <div className={classes.surveyDropdownRow}>
              {[
                {
                  productType: SurveyProductTypeEnum.EMPLOYEE,
                  surveyUuid: employeeSurvey.uuid,
                  surveys: employeeSurveys,
                  setSurvey: setEmployeeSurvey,
                },
                {
                  productType: SurveyProductTypeEnum.RESIDENT,
                  surveyUuid: residentSurvey.uuid,
                  surveys: residentSurveys,
                  setSurvey: setResidentSurvey,
                },
              ].map(({ surveyUuid, surveys, setSurvey, productType }) => {
                const title = PRODUCT_TYPE_LABELS[productType]
                return (
                  <div key={title}>
                    <div
                      className={classes.bulletPoint}
                      style={{ background: PRODUCT_TYPE_TO_OVERLAY_COLORS[productType] }}
                    />
                    <Typography>{title} Scores:&nbsp;</Typography>
                    <Dropdown
                      useDarkStyle
                      value={surveyUuid}
                      onChange={e => {
                        const newSurvey = surveys.find(
                          s => s.uuid === (e.target as HTMLInputElement).value,
                        )
                        if (!newSurvey) return
                        gaEvent({
                          action: `overlaySelectSurvey${productType}`,
                          category: 'Analytics',
                        })
                        setSurvey(newSurvey)
                      }}
                      displayEmpty
                      renderValue={selectedUuid => surveys.find(s => s.uuid === selectedUuid)?.name}
                      input={<FilledInput />}
                      id={`overlay-${title}-survey-select`}
                    >
                      {surveys.map(s => (
                        <MenuItem key={s.uuid} value={s.uuid}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </div>
                )
              })}
            </div>
            {children({
              employeeSurvey,
              residentSurvey,
              filters: selectedFilters.map(fv => fv.valueUuid),
              visibleFilterTypes,
            })}
          </>
        )
      }}
    </ResponseHandler>
  )
}

type Props = {}
const AnalyticsContainer: React.FC<Props> = () => {
  const client = useApolloClient()
  const { currentUser } = client.readQuery({ query: CurrentUserDocument }) as NonNullable<
    CurrentUserQuery
  >
  const surveysResult = useAnalyticsSurveysQuery()
  if (!orgHasEnabledSolutionField(currentUser.organization, SolutionFieldEnum.ENGAGEMENT_OVERLAY)) {
    return (
      <OverlayTeaser teaserInfoText="Get next-level insights across Employee and Customer feedback when you use our platform for your employee and customer surveys." />
    )
  }
  return (
    <>
      <BreadcrumbsItem to="#">Analytics</BreadcrumbsItem>
      <ResponseHandler {...surveysResult}>
        {({ surveys: surveyNodes }) => {
          const surveys = surveyNodes?.edges
            .map(e => e?.node)
            // Cast surveys as NonNull because Graphene Relay type issues prevent us from generating it
            .filter((survey): survey is AnalyticsSurveyNode => survey !== null)
            .filter(
              survey =>
                survey.status === SurveyStatusEnum.CLOSED &&
                survey.responseRate.finished >= survey.minShowableResults,
            )

          const employeeSurveys = surveys.filter(s => s.type === SurveyTypeEnum.TI)
          const residentSurveys = surveys.filter(s => s.type === SurveyTypeEnum.RESIDENT_ENGAGEMENT)
          if (employeeSurveys.length === 0 || residentSurveys.length === 0) {
            return (
              <OverlayTeaser
                teaserInfoText={`
          In order to compare both Customer and Employee results,
          we need one more survey to close. Once it does, you will be
          able to see:
          `}
                isWaitingTeaser
              />
            )
          }
          return (
            <Page>
              <Paper>
                <AnalyticsControls
                  employeeSurveys={employeeSurveys}
                  residentSurveys={residentSurveys}
                  currentUser={currentUser}
                >
                  {props => <OverlayReport {...props} currentUser={currentUser} />}
                </AnalyticsControls>
              </Paper>
            </Page>
          )
        }}
      </ResponseHandler>
    </>
  )
}

export default AnalyticsContainer
