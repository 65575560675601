import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import LockIcon from '@material-ui/icons/LockOutlined'
import cn from 'classnames'

import { ReactComponent as OpenSurveyIcon } from 'assets/img/create-open-survey-icon.svg'
import { ReactComponent as UploadParticipantsIcon } from 'assets/img/upload-participants-icon.svg'
import FormPanel from 'components/Blocks/Accordions/FormPanel'
import SelectableCard from 'components/Blocks/Cards/SelectableCard'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import { SurveyInput } from 'components/Survey/Wizard/Steps/Design/Design'
import FilterValueQuestions, {
  REQUIRED_FT_CODES,
} from 'components/Survey/Wizard/Steps/Design/FilterValueQuestions'
import {
  SurveyDistributionTypeEnum,
  CurrentUserQuery,
  FilterTypeReportingTypeEnum,
} from 'generated/graphql'
import { CONTACT_EMAIL } from 'utils/constants'

const useStyles = makeStyles(({ spacing, palette }) => ({
  disabledSection: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    '& >p': {
      width: '60%',
    },
  },
  disabledNotice: {
    display: 'flex',
    color: palette.common.danger,
    '& >svg': {
      marginLeft: -3,
      marginRight: spacing(),
    },
    '& >p>span': {
      color: palette.common.danger,
    },
    paddingBottom: spacing(2),
  },
  waysToShare: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(3),
    '& >h6': {
      marginRight: spacing(),
    },
    '& svg': {
      marginBottom: -5,
      color: palette.common.navy65,
      '&:hover': {
        color: palette.common.navy,
      },
    },
  },
  disabledWaysToShare: {
    // Allows us to show a root level tooltip but disable pointer events in the children.
    '&:active': {
      pointerEvents: 'none',
    },
    '& >div': {
      pointerEvents: 'none',
      '& >p, >h6': {
        color: palette.common.navy65,
      },
    },
  },
  waysToShareCards: {
    display: 'flex',
    marginTop: spacing(3),
    marginBottom: spacing(3),
    '& >div': {
      width: '40%',
    },
    '& >div:first-child': {
      marginRight: spacing(2),
    },
  },
}))

type Props = {
  currentUser: CurrentUserQuery['currentUser']
  survey: SurveyInput
  updateSurvey(surveyAttrs?: Partial<SurveyInput>): void
}

const WaysToShare: React.FC<Props> = ({ currentUser, survey, updateSurvey }) => {
  const classes = useStyles()

  const availableFilterTypes = currentUser.organization.activeFilterTypes
  let surveyFilterTypeSettings = survey.filterTypeSettings.map(ft => ({
    // Dropping the __typename from surveyFilterTypeSettings
    dtCode: ft.dtCode,
    reportingType: ft.reportingType,
  }))
  const disableSection = Boolean(survey.uuid)

  return (
    <FormPanel gutterTop gutterHeader={false}>
      <div
        className={cn({
          [classes.disabledWaysToShare]: disableSection,
        })}
      >
        {disableSection && (
          <div className={classes.disabledNotice}>
            <LockIcon />
            <Typography>
              <span>
                This section is now locked. In order to make edits you'll need to delete this survey
                draft and start over.
              </span>
            </Typography>
          </div>
        )}
        <TitleWithInfoTooltip
          title="Ways to Share"
          tooltip={
            <>
              <Typography>Upload Participants:</Typography>
              <Typography color="textSecondary">
                Bulk upload participant’s information using our Excel Template, or manually upload
                individual’s information once the survey is live. We will automatically send the
                participant a survey link by email and/or text. You can schedule notifications, and
                see your response rate based on how many participants were invited to the survey.
              </Typography>
              <br />
              <Typography>Create Open Survey Link:</Typography>
              <Typography color="textSecondary">
                Once the open survey link is created, share the survey link with anyone without
                having to upload them to our system. Copy and paste the link anywhere, and use in
                the best way that fits your organization. The link will remain open, as long as the
                survey is live.
              </Typography>
            </>
          }
          padIcon={false}
        />
        <>
          <Typography color="textSecondary" variant="body2">
            How would you like to send this survey?*
          </Typography>
          <div className={classes.waysToShareCards}>
            <SelectableCard
              id="upload-participants"
              disabled={disableSection}
              selected={survey.distributionType === SurveyDistributionTypeEnum.CLOSED}
              onSelect={() =>
                !survey.uuid &&
                updateSurvey({ distributionType: SurveyDistributionTypeEnum.CLOSED })
              }
              Icon={UploadParticipantsIcon}
              title="Upload Participants"
              body="Upload participants' contact information and we will automatically send the survey by email and/or text."
            />
            <SelectableCard
              id="create-open-link"
              disabled={disableSection}
              selected={survey.distributionType === SurveyDistributionTypeEnum.OPEN}
              onSelect={() => {
                // If we are on survey creation (and therefore, no survey filter type settings have yet been created)
                surveyFilterTypeSettings = availableFilterTypes
                  .filter(ft => REQUIRED_FT_CODES.includes(ft.dtCode))
                  .map(ft => ({
                    dtCode: ft.dtCode,
                    reportingType: FilterTypeReportingTypeEnum.SELF_REPORTED,
                  }))
                updateSurvey({
                  distributionType: SurveyDistributionTypeEnum.OPEN,
                  filterTypeSettings: surveyFilterTypeSettings,
                })
              }}
              Icon={OpenSurveyIcon}
              title="Create Open Survey Link"
              body="Easily copy and paste the survey link to share with participants right away."
            />
          </div>
          <Typography color="textSecondary" variant="body2">
            Need help deciding?{' '}
            <a href={`mailto:${CONTACT_EMAIL}`}>&nbsp;Contact our Support Team</a>
          </Typography>
          {survey.distributionType === SurveyDistributionTypeEnum.OPEN && (
            <FilterValueQuestions
              availableFilterTypes={availableFilterTypes}
              surveyFilterTypeSettings={surveyFilterTypeSettings}
              updateSurvey={updateSurvey}
              disableSection={disableSection}
            />
          )}
        </>
      </div>
    </FormPanel>
  )
}

export default WaysToShare
