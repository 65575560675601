import React from 'react'

import { hot } from 'react-hot-loader/root'
import { Switch, Redirect, Route } from 'react-router-dom'

import ActionPlanContainer from 'components/ActionPlans/ActionPlanContainer'
import ActionPlansMgmt from 'components/ActionPlansMgmt/ActionPlansMgmt'
import AnalyticsContainer from 'components/Analytics/AnalyticsContainer'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import CertificationHubRoutes from 'components/CertificationHub/CertificationHubRoutes'
import InformLettersRoutes from 'components/InformLetters/InformLettersRoutes'
import Reports from 'components/Reports'
import OrgSettingsRoutes from 'components/Routes/OrgSettingsRoutes'
import SurveyRoutes from 'components/Routes/SurveyRoutes'
import MonitorContainer from 'components/Survey/Wizard/Steps/Monitor/MonitorContainer'
import ReactGA from 'config/ga'
import AnonymousRoute from 'containers/Routes/AnonymousRoutesContainer'
import AuthenticatedRoute from 'containers/Routes/AuthenticatedRouteContainer'
import DownloadContainer from 'containers/Routes/DownloadContainer'
import InsightsRoutesContainer from 'containers/Routes/InsightsRoutesContainer'
import { usePublicConstantsQuery } from 'generated/graphql'
import { withMuiTheme } from 'shared/theme'
import { logout } from 'utils/authService'
import { URLS, ORG_SETTINGS, INSIGHTS, CERTIFICATION_HUB } from 'utils/constants'
import { PublicConstantsContext } from 'utils/customHooks'

const App: React.FC = () => {
  const constantsResult = usePublicConstantsQuery()
  return (
    <ResponseHandler {...constantsResult}>
      {({ publicConstants }) => {
        return (
          <PublicConstantsContext.Provider value={publicConstants}>
            <Route
              path="/"
              render={({ location }) => {
                if (ReactGA) {
                  ReactGA.pageview(location.pathname + location.search)
                }
                return null
              }}
            />

            <Switch>
              <AnonymousRoute
                exact
                path={URLS.LOGIN}
                component={() => {
                  window.location.replace(URLS.LOGIN)
                  return null
                }}
              />
              // for backwards compabitility with pre-keycloak routes // redirect to appropriate
              URLs if the old URLs are used
              <AnonymousRoute
                exact
                path={URLS.OLD_LOGIN}
                component={() => {
                  window.location.replace(URLS.LOGIN)
                  return null
                }}
              />
              <AnonymousRoute
                exact
                path={URLS.OLD_LOGOUT}
                component={() => {
                  logout()
                  return null
                }}
              />
              <AnonymousRoute
                exact
                path={URLS.MONITOR.REPORT}
                component={MonitorContainer}
                isPublic
              />
              <AnonymousRoute exact path={URLS.MONITOR.REPORTS} component={Reports} isPublic />
              <AuthenticatedRoute path={URLS.SURVEYS.DASHBOARD} component={SurveyRoutes} />
              <AuthenticatedRoute path={ORG_SETTINGS} component={OrgSettingsRoutes} />
              <AuthenticatedRoute path={INSIGHTS} component={InsightsRoutesContainer} />
              <AuthenticatedRoute path={URLS.ANALYTICS} component={AnalyticsContainer} />
              <AuthenticatedRoute path={CERTIFICATION_HUB} component={CertificationHubRoutes} />
              <AuthenticatedRoute path={URLS.INFORM_LETTERS} component={InformLettersRoutes} />
              <AuthenticatedRoute path={URLS.ACTION_PLAN} component={ActionPlanContainer} />
              <AuthenticatedRoute path={URLS.ACTION_PLANS_MANAGEMENT} component={ActionPlansMgmt} />
              <AuthenticatedRoute path={URLS.DOWNLOAD} component={DownloadContainer} />
              <Redirect to={URLS.LOGIN} />
            </Switch>
          </PublicConstantsContext.Provider>
        )
      }}
    </ResponseHandler>
  )
}

export default hot(withMuiTheme(App))
