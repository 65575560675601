import React from 'react'

import Section, { SectionsEnum } from 'components/Survey/Wizard/Steps/Summary/Section'
import useStyles from 'components/Survey/Wizard/Steps/Summary/styles'
import { WizardStepsEnum } from 'components/Survey/WizardContainer'
import { FilterValuesSectionType } from 'generated/graphql'
import { pluralize } from 'utils'
import { SUPPORT_EMAIL } from 'utils/constants'

type Props = {
  filterValues: FilterValuesSectionType
  goToStep(stepName: WizardStepsEnum): void
  errors: { [key: string]: boolean }
  editable: boolean
}

const FilterValuesSection: React.FC<Props> = ({ filterValues, goToStep, errors, editable }) => {
  const classes = useStyles()

  return (
    <Section
      editable={editable}
      goToStep={() => goToStep(WizardStepsEnum.DESIGN)}
      title={SectionsEnum.FILTER_VALUES}
      ready={filterValues.ready}
      warningsNumber={filterValues.warnings.length}
      locked
    >
      <ul>
        <li className={errors.MISSING_FILTER_VALUE_MAPPING ? classes.error : undefined}>
          {filterValues.mappedFilterValuesCount}/{filterValues.totalFilterValuesCount}{' '}
          <span className={errors.MISSING_FILTER_VALUE_MAPPING ? undefined : classes.mute}>
            Filter {pluralize('Value', filterValues.mappedFilterValuesCount)} mapped.{' '}
            {errors.MISSING_FILTER_VALUE_MAPPING && (
              <a href={`mailto:${SUPPORT_EMAIL}`}>Contact your Program Manager</a>
            )}
          </span>
        </li>
      </ul>
    </Section>
  )
}

export default FilterValuesSection
