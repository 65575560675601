import React, { useState, useEffect } from 'react'

import Pagination from 'components/Blocks/Pagination/EnumeratedPagination'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import {
  InsightsBenchmark,
  InsightsSurvey,
  InsightsTabProps,
} from 'components/Insights/InsightsTypes'
import StatementsChart, { sortStatements } from 'components/Insights/Statements/StatementsChart'
import { StatementScores } from 'components/Insights/Statements/StatementsContainer'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import { SurveyProductTypeEnum } from 'generated/graphql'
import { SORT_OPTIONS } from 'utils/constants'

const STATEMENTS_PER_PAGE = 6

type Props = {
  statements: StatementScores
  filterValueLabels: string[]
  responseTypes: string[]
  survey: InsightsSurvey
  filters: string[]
  searchParams: { initialSort?: SORT_OPTIONS }
  benchmark: InsightsBenchmark
  insightsModules: string[]
  timeTrendingChartKey: TimeTrendingChartKey
  surveyProductType: SurveyProductTypeEnum
  timeTrendingType?: InsightsTabProps['timeTrendingType']
}
const EmployeeStatements: React.FC<Props> = props => {
  const { statements, searchParams, ...restProps } = props
  const classes = useInsightsStyles()
  const [page, setPage] = useState(0)
  const [currentSort, setCurrentSort] = useState(
    searchParams.initialSort || SORT_OPTIONS.HIGH_TO_LOW,
  )
  useEffect(() => {
    // When the number of statements are updated from searching, make sure to reset the page to 0.
    setPage(0)
  }, [statements.length])
  sortStatements(
    statements,
    currentSort,
    props.responseTypes,
    Boolean(props.filterValueLabels.length),
  )
  const sliceStart = page * STATEMENTS_PER_PAGE
  // When a user searches, there is a brief period when a page can be out of bounds before useEffect has reset it.
  if (sliceStart > statements.length) return null
  const slicedStatements = statements.slice(sliceStart, sliceStart + STATEMENTS_PER_PAGE)
  return (
    <div className={classes.statementsSection}>
      <StatementsChart
        {...restProps}
        statements={slicedStatements}
        currentSort={currentSort}
        onSortChange={newSort => setCurrentSort(newSort)}
        title="Scores By Statement"
      />
      <div className={classes.statementsFooter}>
        {statements.length > STATEMENTS_PER_PAGE && (
          <Pagination
            totalPages={Math.ceil(statements.length / STATEMENTS_PER_PAGE)}
            currentPage={page}
            onPageChange={(newPage: number) => setPage(newPage)}
          />
        )}
      </div>
    </div>
  )
}

export default EmployeeStatements
