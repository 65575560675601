const getKey = (uuid: string) => `ai-report-${uuid}`

export const setPersistedPassword = (reportUuid: string, password: string) => {
  const passwordObject = {
    password,
    expireAt: new Date().getTime() + 1000 * 60 * 60 * 24,
  }
  localStorage.setItem(getKey(reportUuid), JSON.stringify(passwordObject))
}

export const getPersistedPassword = (reportUuid: string) => {
  let passwordObject
  const savedPasswordJSON = localStorage.getItem(getKey(reportUuid)) || ''
  try {
    passwordObject = JSON.parse(savedPasswordJSON)
  } catch (err) {
    passwordObject = {}
  }

  if (passwordObject) {
    const { password, expireAt } = passwordObject
    if (new Date().getTime() < expireAt) return password
  }
  return null
}
