import React, { SyntheticEvent, useEffect, useState } from 'react'

import { Checkbox, makeStyles, MenuItem, Tooltip, Typography } from '@material-ui/core'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import Errors from 'components/Blocks/FormHelpers/Errors'
import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import {
  DataTypeCode,
  FilterTypeFragment,
  OnlineReviewSiteEnum,
  OrganizationFilterValueFragment,
  OrganizationFilterValuesDocument,
  useOrganizationUpdateFilterValueMutation,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'
import {
  FILTER_ADDRESS_MAX_LENGTH,
  FILTER_CITY_MAX_LENGTH,
  FILTER_NAME_MAX_LENGTH,
  FILTER_NICKNAME_MAX_LENGTH,
  US_STATES,
} from 'utils/constants'
import { verifyUrl, verifyZipcode } from 'utils/validations'

const useStyles = makeStyles(({ palette, spacing }) => ({
  textValidator: {
    marginBottom: 30,
    minWidth: 150,
  },
  selectValidator: {
    marginBottom: 30,
    minWidth: 250,
  },
  center: {
    textAlign: 'center',
  },
  checkboxLabels: {
    color: palette.common.navy65,
    paddingBottom: spacing(3),
  },
}))

const emptyFilterValue: OrganizationFilterValueFragment = {
  uuid: '',
  archived: false,
  name: '',
  nickname: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: null,
  postalCode: '',
  website: '',
  phoneNumber: '',
  isRental: false,
  hasEntranceFee: false,
  onlineReviewSites: [],
  usNewsLocationId: '',
  usNewsLocationName: '',
  medicareProviderNumber: '',
  ncalIdNumber: '',
  hasMissingRequiredValues: false,
  logo: '',
  __typename: 'FilterValueType',
}

type Props = {
  filterType: FilterTypeFragment
  filterValue: null | OrganizationFilterValueFragment
  hasStandardFilterValueChoices: boolean
  unusedStandardFilterValueChoices: string[]
  solutionOnlineReviewSites?: OnlineReviewSiteEnum[] | null
  participatesInUsNews: boolean
  collectCmsAndNcalNumbers: boolean
  isGptwEnabled: boolean
  onClose(): void
  setFilterValue(filterValue: null | OrganizationFilterValueFragment): void
}
const UpdateFilterValue: React.FC<Props> = ({
  filterType,
  filterValue: initialFilterValue,
  hasStandardFilterValueChoices,
  unusedStandardFilterValueChoices,
  solutionOnlineReviewSites,
  participatesInUsNews,
  collectCmsAndNcalNumbers,
  isGptwEnabled,
  onClose,
  setFilterValue,
}) => {
  const classes = useStyles()
  const [errors, setErrors] = useState(null)
  const [updateFilterValue, { loading }] = useOrganizationUpdateFilterValueMutation()
  useEffect(() => {
    ValidatorForm.addValidationRule('isZipCode', value => verifyZipcode(value, false))
    ValidatorForm.addValidationRule('isValidUrl', value => verifyUrl(value))
  })
  const filterValue = initialFilterValue || emptyFilterValue
  const enabledGoogleReviews = solutionOnlineReviewSites?.some(
    site => site === OnlineReviewSiteEnum.GOOGLE,
  )
  const externalId = filterValue.onlineReviewSites?.find(
    ({ reviewSiteName }) => reviewSiteName === OnlineReviewSiteEnum.GOOGLE,
  )?.externalId
  const previewLink = `https://search.google.com/local/writereview?placeid=${externalId}`

  const isAddingFilterValue =
    filterValue.uuid === emptyFilterValue.uuid ||
    unusedStandardFilterValueChoices.includes(filterValue.name)

  const handleClearForm = () => {
    setErrors(null)
    setFilterValue(null)
  }

  const handleUpdate = async () => {
    setErrors(null)
    const newFilterValue = cloneDeep(filterValue)
    // TODO: The operand of a 'delete' operator must be optional. ts(2790)
    delete newFilterValue.archived
    delete newFilterValue.__typename
    delete newFilterValue.hasMissingRequiredValues
    if (newFilterValue.onlineReviewSites) {
      // eslint-disable-next-line no-param-reassign
      newFilterValue.onlineReviewSites.forEach(site => delete site.__typename)
    }

    const result = await updateFilterValue({
      variables: {
        filterTypeUuid: filterType.filterTypeUuid,
        filterValue: newFilterValue,
      },
      refetchQueries: [
        {
          query: OrganizationFilterValuesDocument,
          variables: { dtCode: filterType.dtCode },
        },
      ],
      update(cache) {
        cache.evict({ fieldName: 'currentCertification' })
        cache.gc()
      },
    })
    const responseErrors = result.data?.updateFilterValue?.errors
    if (!isNil(responseErrors) && responseErrors.length > 0) {
      if (responseErrors.includes('FILTER_VALUE_ALREADY_EXISTS')) {
        responseErrors.length = 0
        responseErrors.push(`${filterType.name} "${newFilterValue.name}" already exists`)
      }
      setErrors(errors)
      emitter.emit('ERROR', responseErrors)
    } else {
      emitter.emit(
        'SUCCESS',
        `${filterType.namePlural} ${isAddingFilterValue ? 'Added' : 'Updated'}`,
      )
      onClose()
    }
  }

  const handleOnSetFilterValue = (name: string, value: any) =>
    setFilterValue({
      ...filterValue,
      [name]: value,
    })

  const requireStateAndZip = isGptwEnabled && filterType.dtCode === DataTypeCode.AI_LOCATION
  const isDisabled =
    filterValue.name === '' ||
    (requireStateAndZip && (!filterValue.state || filterValue.postalCode === ''))

  const handleChangeInput = (e: SyntheticEvent) =>
    handleOnSetFilterValue(
      (e.target as HTMLInputElement).name,
      (e.target as HTMLInputElement).value,
    )

  return (
    <GridContainer justify="center">
      <ItemGrid sm={8}>
        <ValidatorForm refs="form" instantValidate onSubmit={handleUpdate}>
          <FormPanel
            expandIcon={EXPAND_ICON.ARROW}
            interactive={false}
            gutterBottom
            title={`${isAddingFilterValue ? 'Add' : 'Update'} ${filterType.name}`}
          >
            <Errors errors={errors} />
            {hasStandardFilterValueChoices ? (
              <SelectValidator
                className={classes.selectValidator}
                name="name"
                label={`Select ${filterType.name}*`}
                value={filterValue.name}
                onChange={handleChangeInput}
                validators={['required']}
                errorMessages={[`${filterType.name} is required`]}
                inputProps={{ autoFocus: isAddingFilterValue }}
                disabled={!isAddingFilterValue}
              >
                <MenuItem disabled>{`Choose ${filterType.name}`}</MenuItem>
                {isAddingFilterValue ? (
                  unusedStandardFilterValueChoices.map(sc => (
                    <MenuItem key={sc} value={sc}>
                      {sc}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={filterValue.name} value={filterValue.name}>
                    {filterValue.name}
                  </MenuItem>
                )}
              </SelectValidator>
            ) : (
              <TextValidator
                className={classes.textValidator}
                name="name"
                type="text"
                fullWidth
                value={filterValue.name}
                label={`${filterType.name} Name*`}
                onChange={handleChangeInput}
                validators={['required', `maxStringLength:${FILTER_NAME_MAX_LENGTH}`]}
                errorMessages={[
                  `${filterType.name} name is required`,
                  `${filterType.name} length should be less than ` +
                    `${FILTER_NAME_MAX_LENGTH} characters`,
                ]}
                inputProps={{ autoFocus: true }}
              />
            )}
            <TextValidator
              className={classes.textValidator}
              name="nickname"
              type="text"
              fullWidth
              value={filterValue.nickname}
              label="Display Nickname"
              onChange={handleChangeInput}
              validators={[`maxStringLength:${FILTER_NICKNAME_MAX_LENGTH}`]}
              errorMessages={[
                `Nickname length should be less than ${FILTER_NICKNAME_MAX_LENGTH} characters`,
              ]}
              inputProps={{ autoFocus: hasStandardFilterValueChoices && !isAddingFilterValue }}
            />
            {filterType.isLocation && (
              <>
                <TextValidator
                  className={classes.textValidator}
                  name="addressLine1"
                  type="text"
                  fullWidth
                  value={filterValue.addressLine1}
                  label="Address Line 1"
                  onChange={handleChangeInput}
                  validators={[`maxStringLength:${FILTER_ADDRESS_MAX_LENGTH}`]}
                  errorMessages={[
                    `Address length should be less than ${FILTER_ADDRESS_MAX_LENGTH} characters`,
                  ]}
                />
                <TextValidator
                  className={classes.textValidator}
                  name="addressLine2"
                  type="text"
                  fullWidth
                  value={filterValue.addressLine2}
                  label="Address Line 2"
                  validators={[`maxStringLength:${FILTER_ADDRESS_MAX_LENGTH}`]}
                  onChange={handleChangeInput}
                  errorMessages={[
                    `Address length should be less than ${FILTER_ADDRESS_MAX_LENGTH} characters`,
                  ]}
                />
                <TextValidator
                  className={classes.textValidator}
                  name="city"
                  type="text"
                  fullWidth
                  value={filterValue.city}
                  label="City"
                  onChange={handleChangeInput}
                  validators={[`maxStringLength:${FILTER_CITY_MAX_LENGTH}`]}
                  errorMessages={[
                    `City length should be less than ${FILTER_CITY_MAX_LENGTH} characters`,
                  ]}
                />
                <SelectValidator
                  className={classes.textValidator}
                  name="state"
                  label={`Select State${requireStateAndZip ? '*' : ''}`}
                  value={filterValue.state ?? ''}
                  onChange={handleChangeInput}
                  validators={requireStateAndZip ? ['required'] : undefined}
                  errorMessages={[`State selection is required`]}
                >
                  <MenuItem disabled>Choose State</MenuItem>
                  {US_STATES.map((state, idx) => (
                    <MenuItem key={idx} value={state.abbreviation}>
                      {state.name}
                    </MenuItem>
                  ))}
                </SelectValidator>
                <br />
                <TextValidator
                  className={classes.textValidator}
                  name="postalCode"
                  type="text"
                  fullWidth
                  value={filterValue.postalCode}
                  label={`Zip Code${requireStateAndZip ? '*' : ''}`}
                  onChange={handleChangeInput}
                  validators={requireStateAndZip ? ['required', 'isZipCode'] : ['isZipCode']}
                  errorMessages={
                    requireStateAndZip
                      ? ['Zip code is required', 'Invalid Zip Code']
                      : ['Invalid Zip Code']
                  }
                />
                <TextValidator
                  className={classes.textValidator}
                  name="website"
                  type="text"
                  fullWidth
                  value={filterValue.website}
                  label="Website"
                  onChange={handleChangeInput}
                  validators={['isValidUrl']}
                  errorMessages={['Invalid Web Site']}
                />
                <TextValidator
                  className={classes.textValidator}
                  name="phoneNumber"
                  type="text"
                  fullWidth
                  value={filterValue.phoneNumber}
                  label="Phone Number"
                  onChange={handleChangeInput}
                />
                <div className={classes.checkboxLabels}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.hasEntranceFee}
                        onClick={() => {
                          const update = {
                            ...filterValue,
                            hasEntranceFee: !filterValue.hasEntranceFee,
                          }
                          // Prevent enabling both CCRC fields
                          if (update.isRental) {
                            update.isRental = false
                          }
                          setFilterValue(update)
                        }}
                      />
                    }
                    label="Entrance fee Life Plan Community/CCRC"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.isRental}
                        onClick={() => {
                          const update = {
                            ...filterValue,
                            isRental: !filterValue.isRental,
                          }
                          // Prevent enabling both CCRC fields
                          if (update.isRental) {
                            update.hasEntranceFee = false
                          }
                          setFilterValue(update)
                        }}
                      />
                    }
                    label="Rental Life Plan Community/CCRC"
                  />
                </div>
                {collectCmsAndNcalNumbers && (
                  <>
                    <TextValidator
                      className={classes.textValidator}
                      name="medicareProviderNumber"
                      type="text"
                      fullWidth
                      value={filterValue.medicareProviderNumber}
                      label="Medicare Provider Number"
                      onChange={handleChangeInput}
                    />
                    <TextValidator
                      className={classes.textValidator}
                      name="ncalIdNumber"
                      type="text"
                      fullWidth
                      value={filterValue.ncalIdNumber}
                      label="NCAL ID Number"
                      onChange={handleChangeInput}
                    />
                  </>
                )}
                {enabledGoogleReviews && (
                  <>
                    <TextValidator
                      className={classes.textValidator}
                      name="googlePlaceId"
                      type="text"
                      fullWidth
                      value={externalId}
                      label="Google Place ID"
                      // Hook into existing logic that expects {name, value}. This
                      // will need to be updated when we have multiple review sites.
                      onChange={evt => {
                        const newExternalId = (evt.target as HTMLInputElement).value
                        const value = newExternalId
                          ? [
                              {
                                reviewSiteName: OnlineReviewSiteEnum.GOOGLE,
                                externalId: newExternalId.trim(),
                              },
                            ]
                          : []
                        handleOnSetFilterValue('onlineReviewSites', value)
                      }}
                    />
                    {externalId && (
                      <Typography>
                        Review link preview:&nbsp;&nbsp;
                        <ExternalNavLink to={previewLink} label={previewLink} />
                      </Typography>
                    )}
                  </>
                )}
                <br />
                {participatesInUsNews && (
                  <>
                    <Tooltip
                      title="Contact your program manager if changes are needed."
                      placement="left"
                    >
                      <TextValidator
                        className={classes.textValidator}
                        name="usNewsLocationId"
                        type="text"
                        fullWidth
                        value={filterValue.usNewsLocationId}
                        label="US News Location ID"
                        disabled
                      />
                    </Tooltip>
                    <Tooltip
                      title="Contact your program manager if changes are needed."
                      placement="left"
                    >
                      <TextValidator
                        className={classes.textValidator}
                        name="usNewsLocationName"
                        type="text"
                        fullWidth
                        value={filterValue.usNewsLocationName}
                        label="US News Location Name"
                        disabled
                      />
                    </Tooltip>
                  </>
                )}
              </>
            )}
          </FormPanel>
          <SubmitButton isSubmitting={loading} color="primary" disabled={isDisabled} right>
            {isAddingFilterValue ? 'Add' : 'Save'}
          </SubmitButton>
          <Button
            right
            color="secondaryNoBackground"
            onClick={() => {
              handleClearForm()
              onClose()
            }}
          >
            Back
          </Button>
          *Required
        </ValidatorForm>
      </ItemGrid>
    </GridContainer>
  )
}

export default UpdateFilterValue
