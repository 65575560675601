import React from 'react'

import {
  Chip,
  FilledInput,
  FormControl,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core'
import upperFirst from 'lodash/upperFirst'

import Button from 'components/Blocks/CustomButtons/Button'
import Pagination from 'components/Blocks/Pagination/EnumeratedPagination'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import WordCloudChart from 'components/Insights/WordCloudChart'
import {
  NPS_GROUP_TO_LABEL,
  NPS_THRESHOLDS,
} from 'components/Survey/Wizard/Steps/Questions/NpsQuestions'
import { gaEvent } from 'config/ga'
import {
  InsightsCommentsQuery,
  InsightsCommentsTopCommentsQuery,
  NpsGroupsEnum,
} from 'generated/graphql'

const useStyles = makeStyles(({ palette, spacing }) => ({
  questionCommentsRoot: {
    width: '90%',
    margin: '0 auto',
  },
  commentsMostCommonWords: {
    marginLeft: spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  commentsWordCloud: {
    margin: '16px auto',
  },
  commentsControls: {
    marginLeft: spacing(3),
    marginRight: spacing(3),
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
  },
  commentsSubControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  commentsSelect: {
    height: 32,
  },
  commentsLabel: {
    marginRight: spacing(),
    marginLeft: -spacing(),
  },
  filterTitleRow: {
    display: 'flex',
    alignItems: 'center',
  },
  filterChips: {
    '& >div:first-child': {
      marginLeft: 0,
    },
    marginBottom: spacing(2),
  },
  commentsChip: {
    margin: spacing(),
    marginRight: spacing(),
    backgroundColor: palette.common.secondary,
    color: palette.common.white,
    '& svg': {
      color: palette.common.white,
    },
    '&:hover,&:focus:hover': {
      backgroundColor: palette.common.secondary,
    },
    '&:focus': {
      backgroundColor: palette.common.secondary,
    },
  },
  commentsCommentsList: {},
  commentText: {
    paddingTop: spacing(0.5),
  },
  commentsPagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const FILTER_BY_ALL = 'All'
interface Props {
  minShowableResults: number
  comments: NonNullable<InsightsCommentsQuery['insightsComments']>['comments']
  topComments: NonNullable<InsightsCommentsTopCommentsQuery['insightsComments']>['comments']
  totalComments: number
  page: number
  pageSize: number
  onPageChange(response: number): void
  searchWords: string[]
  onSearchWord(word: string): void
  removeSearchWord(word: string): void
  clearSearchWords(): void
  enhancedComments: boolean
  sortBy?: string | null
  setSortBy?: (sort: string) => void
  filterBy?: null | NpsGroupsEnum
  setFilterBy?: (filterBy: null | NpsGroupsEnum) => void
  isNpsQuestion?: boolean
}

const Comments: React.FC<Props> = ({
  minShowableResults,
  comments,
  topComments,
  enhancedComments,
  sortBy,
  setSortBy,
  totalComments,
  page,
  pageSize,
  onPageChange,
  searchWords,
  onSearchWord,
  removeSearchWord,
  clearSearchWords,
  filterBy,
  setFilterBy,
  isNpsQuestion = false,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const showPagination = totalComments > pageSize
  if (!totalComments) {
    return (
      <Typography
        style={{ textAlign: 'center', paddingLeft: 24 }}
        color="textSecondary"
        component="div"
      >
        There are either no comments available for selected filters or your filters resulted in less
        than {minShowableResults} participants.
      </Typography>
    )
  }
  return (
    <div className={classes.questionCommentsRoot}>
      <div className={classes.commentsWordCloud}>
        {enhancedComments && (
          <>
            <WordCloudChart
              handleWordClick={onSearchWord}
              searchWords={searchWords}
              comments={topComments}
            />
            {searchWords.length > 0 && (
              <div className={classes.filterTitleRow}>
                <Typography>FILTER BY MOST COMMON WORDS</Typography>
                <Button color="secondaryNoBackground" onClick={() => clearSearchWords()}>
                  Clear All Filters
                </Button>
              </div>
            )}
            <div className={classes.filterChips}>
              {searchWords.map(word => (
                <Chip
                  className={classes.commentsChip}
                  key={word}
                  label={upperFirst(word)}
                  onDelete={() => removeSearchWord(word)}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <div className={classes.commentsControls}>
        <div className={classes.commentsSubControls}>
          <Typography className={classes.commentsLabel} variant="body2" color="textSecondary">
            {totalComments} {totalComments === 1 ? 'reply' : 'replies'}
          </Typography>
        </div>
        {enhancedComments && setSortBy && sortBy && (
          <div className={classes.commentsSubControls}>
            <Typography className={classes.commentsLabel} variant="body2" color="textSecondary">
              Sort By:
            </Typography>
            <FormControl variant="filled" className={classes.commentsSelect}>
              <Select
                className=""
                variant="filled"
                value={sortBy}
                onChange={e => {
                  gaEvent({
                    action: 'sortComments',
                    category: 'Insights',
                  })
                  setSortBy((e.target as HTMLInputElement).value)
                }}
                input={<FilledInput style={{ maxWidth: 200 }} />}
                displayEmpty={false}
              >
                <MenuItem value="-score">Higher Overall Scores First</MenuItem>
                <MenuItem value="score">Lower Overall Scores First</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        {isNpsQuestion && (
          <div className={classes.commentsSubControls}>
            <Typography className={classes.commentsLabel} variant="body2" color="textSecondary">
              Filter By:
            </Typography>
            <FormControl variant="filled" className={classes.commentsSelect}>
              <Select
                id="nps-filter"
                variant="filled"
                value={filterBy || ''}
                renderValue={group => NPS_GROUP_TO_LABEL[group as NpsGroupsEnum] || FILTER_BY_ALL}
                onChange={e => {
                  if (!setFilterBy) {
                    return
                  }
                  const value = (e.target as HTMLInputElement).value
                  if (value === FILTER_BY_ALL) {
                    setFilterBy(null)
                    return
                  }
                  setFilterBy(value as NpsGroupsEnum)
                }}
                input={<FilledInput style={{ maxWidth: 200 }} />}
                displayEmpty
              >
                <MenuItem value={FILTER_BY_ALL}>All</MenuItem>
                {[NpsGroupsEnum.PROMOTERS, NpsGroupsEnum.PASSIVES, NpsGroupsEnum.DETRACTORS].map(
                  npsGroup => {
                    const [start, end] = NPS_THRESHOLDS[npsGroup]
                    return (
                      <MenuItem key={npsGroup} value={npsGroup}>
                        {NPS_GROUP_TO_LABEL[npsGroup]} ({start}-{end})
                      </MenuItem>
                    )
                  },
                )}
              </Select>
            </FormControl>
          </div>
        )}
      </div>
      <List className={classes.commentsCommentsList} component="ol">
        {comments.map((c, idx) => (
          <ListItem key={idx} className={classes.commentText}>
            <ListItemText>
              {page * pageSize + idx + 1}. {c && c.text}
            </ListItemText>
          </ListItem>
        ))}
      </List>
      {showPagination && (
        <div id="comments-pagination" className={classes.commentsPagination}>
          <Pagination
            totalPages={Math.ceil(totalComments / pageSize)}
            currentPage={page}
            onPageChange={(newPage: number) => onPageChange(newPage)}
          />
        </div>
      )}
    </div>
  )
}
export default Comments
