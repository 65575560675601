import React from 'react'

import { Typography, makeStyles, IconButton } from '@material-ui/core'
import { format } from 'date-fns'

import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import TextExpandable from 'components/Blocks/TextExpandable'
import { InsightsCommentsQuery } from 'generated/graphql'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginBottom: spacing(2),
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'auto',
  },
  text: {
    maxWidth: '70%',
  },
  filterValuesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterValues: {
    marginTop: spacing(4),
    marginBottom: spacing(4),
    marginRight: spacing(2),
    display: 'flex',
  },
  filterValue: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: 8,
    paddingLeft: 8,
    marginRight: spacing(),
    borderRadius: 5,
    color: palette.common.navy,
    backgroundColor: palette.common.iceGrey,
    textTransform: 'uppercase',
  },
}))

type Props = {
  comment: NonNullable<InsightsCommentsQuery['insightsComments']>['comments'][0]
  index: number
}

const Testimonial: React.FC<Props> = ({ comment, index }) => {
  const classes = useStyles()
  const snapId = `testimonial${index}`
  return (
    <div className={classes.root} id={snapId}>
      <div className={classes.header}>
        <Typography variant="subtitle1">{comment.author || 'Anonymous'}</Typography>
        <div className={classes.header}>
          {comment.submitted && (
            <Typography variant="body2" color="textSecondary">
              {format(new Date(comment.submitted), 'MM/dd/yyyy')}
            </Typography>
          )}
          <IconButton size="small">
            <ScreenshotButton snapId={snapId} />
          </IconButton>
        </div>
      </div>
      <Typography color="textSecondary" className={classes.text}>
        <TextExpandable text={comment.text} characterLimit={200} />
      </Typography>
      {comment.filterValues && (
        <div className={classes.filterValuesContainer}>
          <div className={classes.filterValues}>
            {comment.filterValues?.map(name => (
              <div key={name} className={classes.filterValue}>
                {name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Testimonial
