import React from 'react'

import { makeStyles, MenuItem, Select, Typography } from '@material-ui/core'

import PlainSelectInput from 'components/ActionPlans/PlainSelectInput'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import {
  useActionPlanUpdateActionPlanSurveyMutation,
  useInsightsOverallIndexQuery,
  ActionPlanSurveyType,
} from 'generated/graphql'
import { getSurveyTypeLabel } from 'utils'
import { MIN_SHOWABLE_RESULTS_CODE } from 'utils/constants'
import { SurveyNodeAP as SurveyNode } from 'utils/types'

const useSelectorStyles = makeStyles(({ spacing }) => ({
  root: {
    marginBottom: spacing(3),
  },
  selectRoot: {
    width: '100%',
  },
  score: {
    display: 'flex',
    alignItems: 'center',
    '& >p:first-child': {
      marginRight: spacing(),
    },
  },
}))

type SelectorProps = {
  surveys: SurveyNode[]
  surveyUuid?: string
  surveyType: ActionPlanSurveyType
}

const SurveySelector: React.FC<SelectorProps> = ({ surveys, surveyUuid, surveyType }) => {
  const classes = useSelectorStyles()
  const result = useInsightsOverallIndexQuery({
    variables: { surveyUuid: surveyUuid || surveys[0].uuid },
  })
  const [updateActionPlan] = useActionPlanUpdateActionPlanSurveyMutation()
  return (
    <div className={classes.root}>
      <Select
        className={classes.selectRoot}
        value={surveyUuid}
        onChange={e => {
          updateActionPlan({
            variables: {
              actionPlanInput: {
                surveyUuid: (e.target as HTMLInputElement).value,
                surveyType,
              },
            },
          })
        }}
        displayEmpty={false}
        input={<PlainSelectInput />}
        name="survey"
      >
        {surveys.map(s => (
          <MenuItem key={s.uuid} value={s.uuid}>
            {s.name}
          </MenuItem>
        ))}
      </Select>
      <div className={classes.score}>
        <Typography color="textSecondary">{getSurveyTypeLabel(surveyType)} Score:</Typography>
        <ResponseHandler {...result}>
          {({ insightsOverallIndex: { positive } }) => {
            return (
              <Typography variant="subtitle1">
                {positive === MIN_SHOWABLE_RESULTS_CODE ? 'N/A' : `${Math.round(positive)}%`}
              </Typography>
            )
          }}
        </ResponseHandler>
      </div>
    </div>
  )
}

export default SurveySelector
