import React from 'react'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ palette }) => ({
  footer: {
    '@media print': {
      display: 'none',
    },
    bottom: 0,
    borderTop: '1px solid #e7e7e7',
    zIndex: 4,
  },
  container: {
    paddingRight: 15,
    paddingLeft: 15,
    marginRight: 'auto',
    marginLeft: 'auto',
    zIndex: 3,
    position: 'relative',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
  anchor: {
    margin: 0,
    fontSize: 14,
    float: 'right',
    padding: 15,
    textDecoration: 'none',
    backgroundColor: 'transparent',
    color: palette.common.navy,
  },
}))

const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <a
          href="http://www.activatedinsights.com"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.anchor}
        >
          &copy; {new Date().getFullYear()} Activated Insights
        </a>
      </div>
    </footer>
  )
}

export default Footer
