import React, { useState, SyntheticEvent } from 'react'

import { InputAdornment, Typography } from '@material-ui/core'
import ArrowIcon from '@material-ui/icons/ArrowForward'
import TextsmsIcon from '@material-ui/icons/Textsms'
import cn from 'classnames'
import InputMask from 'react-input-mask'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import IconButton from 'components/Blocks/CustomButtons/IconButton'
import { useStyles } from 'components/Survey/Wizard/Steps/Notifications/SendTestEmail'

interface Props {
  sendTestSms(phoneNumber: string): void
}

const SMSInput = (props: any) => <InputMask {...props} mask="999-999-9999" maskChar=" " />

const SendTestMessage: React.FC<Props> = ({ sendTestSms }) => {
  const classes = useStyles()
  const [isValid, setIsValid] = useState(false)
  const [value, setValue] = useState('')

  return (
    <>
      <Typography variant="body2" color="textSecondary" className={classes.firstTitle}>
        SEND OUT A TEST
      </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.secondTitle}>
        We recommend you send yourself a text to make sure the notification is correct.{' '}
        <i>*(Optional)</i>
      </Typography>
      <ValidatorForm
        instantValidate
        className={classes.sendMessage}
        onSubmit={() => sendTestSms(value)}
      >
        <TextValidator
          id="sendTestSms"
          name="sendTestSms"
          helperText=""
          value={value}
          label="SMS Number"
          placeholder="XXX-XXX-XXXX"
          onChange={(e: SyntheticEvent) => setValue((e.target as HTMLInputElement).value)}
          margin="normal"
          validators={['required', 'matchRegexp:^\\d\\d\\d-\\d\\d\\d-\\d\\d\\d\\d$']}
          errorMessages={['Value Required', 'Enter 10 digit number']}
          validatorListener={isValidVal => setIsValid(isValidVal)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextsmsIcon className={classes.adornment} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  type="submit"
                  color="secondaryHover"
                  className={cn(classes.submitButton, {
                    [classes.submitButtonWhenValid]: isValid,
                  })}
                >
                  <ArrowIcon />
                </IconButton>
              </InputAdornment>
            ),
            inputComponent: SMSInput,
          }}
        />
      </ValidatorForm>
    </>
  )
}

export default SendTestMessage
