import React, { useContext, useState } from 'react'

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import cn from 'classnames'
import isNil from 'lodash/isNil'

import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import OverallIndexGuageAndScore from 'components/Insights/Snapshot/OverallIndexGaugeAndScore'
import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import DesignSection from 'components/Survey/Wizard/Steps/Summary/DesignSection'
import FilterValuesSection from 'components/Survey/Wizard/Steps/Summary/FilterValuesSection'
import NotificationsSection from 'components/Survey/Wizard/Steps/Summary/NotificationsSection'
import ParticipantsSection from 'components/Survey/Wizard/Steps/Summary/ParticipantsSection'
import QuestionsSection from 'components/Survey/Wizard/Steps/Summary/QuestionsSection'
import useStyles from 'components/Survey/Wizard/Steps/Summary/styles'
import TranslationsSection from 'components/Survey/Wizard/Steps/Summary/TranslationsSection'
import SurveyControls from 'components/Survey/Wizard/SurveyControls'
import SurveyResponseRateCard, {
  getShowReportBy,
} from 'components/Survey/Wizard/SurveyResponseRateCard'
import { WizardStepsEnum } from 'components/Survey/WizardContainer'
import { StoreContext } from 'config/LocalStorage'
import KeyStatementContainer from 'containers/Survey/Wizard/Steps/Summary/KeyStatementContainer'
import {
  CurrentUserQuery,
  InsightsModulesEnum,
  SurveysSurveyDocument,
  SurveysSurveySummaryDocument,
  SurveysSurveyQuery,
  SurveyTypeEnum,
  SurveyStatusEnum,
  useSurveysUpdateSurveyStatusMutation,
  useSurveysSurveySummaryQuery,
  ResponseRateTypeEnum,
  SurveyDistributionTypeEnum,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import {
  surveyPage,
  PULSE_SURVEY_TYPES,
  SURVEY_TYPE_TO_KEY_STATEMENT,
  ScoreTitleEnum,
  SURVEY_URLS,
} from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

type HeaderProps = {
  canSchedule: boolean
}

const SurveyHeader: React.FC<HeaderProps> = ({ canSchedule }) => {
  const classes = useStyles()
  const summaryTitle = canSchedule ? 'Survey is ready to Launch' : 'Survey is not ready to Launch'
  const summarySubtitle = canSchedule
    ? 'All checks passed, cleared for take off!'
    : 'Please correct the issues below to get your survey ready.'

  return (
    <ListItem
      ContainerProps={{
        className: cn(classes.header, { [classes.surveyReadyToLaunch]: canSchedule }),
      }}
    >
      <ListItemText
        classes={{ primary: classes.headerPrimary, secondary: classes.headerSecondary }}
        primary={summaryTitle}
        secondary={summarySubtitle}
      />
      <ListItemSecondaryAction>
        <ListItemIcon>{canSchedule ? <CheckIcon /> : <WarningIcon />}</ListItemIcon>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

type Props = {
  currentUser: CurrentUserQuery['currentUser']
  survey: SurveysSurveyQuery['survey']
  goBack?(): void
  goToStep?(stepName: WizardStepsEnum): void
}

const Summary: React.FC<Props> = ({ currentUser, survey, goBack, goToStep = () => {} }) => {
  const classes = useStyles()
  const [showConfirmNoNotifications, setShowConfirmNoNotifications] = useState(false)

  const {
    store: { responseRateShowReportBy },
  } = useContext(StoreContext)

  const showReportBy =
    survey &&
    getShowReportBy(
      responseRateShowReportBy,
      survey.uuid,
      survey.isDefaultClientBasedResponseRateReport,
    )
  const responseRateByClient = showReportBy === ResponseRateTypeEnum.CLIENT

  const result = useSurveysSurveySummaryQuery({
    variables: {
      surveyUuid: survey.uuid,
      responseRateByClient,
    },
    fetchPolicy: 'network-only',
  })
  const [updateSurveyStatus, { loading }] = useSurveysUpdateSurveyStatusMutation()
  const toggleSurveyStatus = () => {
    let newStatus = SurveyStatusEnum.SCHEDULED
    if (survey.status === SurveyStatusEnum.SCHEDULED) {
      newStatus = SurveyStatusEnum.DRAFT
    }
    updateSurveyStatus({
      variables: {
        surveyUuid: survey.uuid,
        status: newStatus,
      },
      refetchQueries: [
        {
          query: SurveysSurveyDocument,
          variables: { uuid: survey.uuid },
        },
        {
          query: SurveysSurveySummaryDocument,
          variables: { surveyUuid: survey.uuid, responseRateByClient },
        },
      ],
    })
  }

  return (
    <ResponseHandler {...result}>
      {({ surveySummary }) => {
        const {
          surveyPortalUrl,
          canSchedule,
          canUnschedule,
          numFinishedResponses,
          numTotalResponses,
          numLocationsMissingRequiredData,
          overallIndexScore,
          design,
          filterValues,
          questions,
          translations,
          participants,
          notifications,
          settings,
        } = surveySummary
        const { editable } = survey
        const errors: { [key: string]: boolean } = {}
        const warnings: { [key: string]: boolean } = {}
        if (editable) {
          ;[
            design,
            questions,
            translations,
            participants,
            notifications,
            filterValues,
            settings,
          ].forEach(section => {
            if (!section) {
              return
            }
            if (section && section.errors) {
              section.errors.forEach(e => (errors[e] = true))
            }
            if (section && section.warnings) {
              section.warnings.forEach(w => (warnings[w] = true))
            }
          })
        }
        return (
          <>
            {editable && (
              <>
                <SurveyControls survey={survey} showPreview showTranslations />
                <Typography variant="subtitle1">SUMMARY</Typography>
              </>
            )}
            {survey.status === SurveyStatusEnum.CLOSED &&
              !isNil(numFinishedResponses) &&
              !isNil(numTotalResponses) &&
              !isNil(overallIndexScore) && (
                <Paper square elevation={0}>
                  <GridContainer>
                    <ItemGrid sm={6}>
                      <SurveyResponseRateCard
                        surveyUuid={survey.uuid}
                        surveyType={survey.type}
                        minShowableResults={survey.minShowableResults}
                        isResidentSurvey={survey.productType === SurveyProductTypeEnum.RESIDENT}
                        isDefaultClientBasedResponseRateReport={
                          survey.isDefaultClientBasedResponseRateReport
                        }
                        finished={numFinishedResponses}
                        total={numTotalResponses}
                        startDate={design.startDate}
                        endDate={design.endDate}
                        seeMoreUrl={surveyPage(survey.uuid, SURVEY_URLS.MONITOR)}
                        isOpenLinkSurvey={
                          survey.distributionType === SurveyDistributionTypeEnum.OPEN
                        }
                      />
                    </ItemGrid>
                    <ItemGrid sm={6}>
                      {PULSE_SURVEY_TYPES.includes(survey.type) && survey.associatedSurvey ? (
                        <KeyStatementContainer
                          surveyUuid={survey.uuid}
                          associatedSurveyUuid={survey.associatedSurvey.uuid}
                          lessThanMin={numFinishedResponses < survey.minShowableResults}
                          seeMoreUrl={getInsightsPage(
                            survey.uuid,
                            InsightsModulesEnum.SNAPSHOTS,
                            survey.productType,
                          )}
                          keyStatementCode={
                            SURVEY_TYPE_TO_KEY_STATEMENT[
                              survey.type as SurveyTypeEnum.PULSE | SurveyTypeEnum.RESIDENT_PULSE
                            ]
                          }
                          minShowableResults={survey.minShowableResults}
                        />
                      ) : (
                        <div className={classes.statementScore}>
                          <OverallIndexGuageAndScore
                            overallIndexScore={overallIndexScore}
                            title={ScoreTitleEnum[survey.productType]}
                          />
                        </div>
                      )}
                    </ItemGrid>
                  </GridContainer>
                </Paper>
              )}
            <List
              className={cn({
                [classes.surveyReadyToLaunch]: canSchedule,
                [classes.lockedSurveyList]: !editable,
              })}
            >
              {editable && <SurveyHeader canSchedule={canSchedule} />}
              <DesignSection
                survey={survey}
                editable={editable}
                goToStep={goToStep}
                surveyPortalUrl={surveyPortalUrl}
                design={design}
                settings={settings}
                errors={errors}
              />
              {filterValues && (
                <FilterValuesSection
                  filterValues={filterValues}
                  goToStep={goToStep}
                  errors={errors}
                  editable={editable}
                />
              )}
              <QuestionsSection
                currentUser={currentUser}
                survey={survey}
                editable={editable}
                goToStep={goToStep}
                questions={questions}
                settings={settings}
                responseRateByClient={responseRateByClient}
                onRefetch={result.refetch}
                errors={errors}
                warnings={warnings}
              />
              {translations && survey.languages.length > 0 && (
                <TranslationsSection
                  survey={survey}
                  editable={editable}
                  goToStep={goToStep}
                  translations={translations}
                  responseRateByClient={responseRateByClient}
                  errors={errors}
                />
              )}
              {participants && (
                <ParticipantsSection
                  survey={survey}
                  currentUser={currentUser}
                  editable={editable}
                  goToStep={goToStep}
                  participants={participants}
                  numLocationsMissingRequiredData={numLocationsMissingRequiredData}
                  errors={errors}
                  warnings={warnings}
                />
              )}
              {notifications && settings && (
                <NotificationsSection
                  editable={editable}
                  goToStep={goToStep}
                  notifications={notifications}
                  showConfirmNoNotifications={showConfirmNoNotifications}
                  setShowConfirmNoNotifications={setShowConfirmNoNotifications}
                  surveyPortalUrl={surveyPortalUrl}
                  toggleSurveyStatus={toggleSurveyStatus}
                  errors={errors}
                  warnings={warnings}
                />
              )}
            </List>
            {[SurveyStatusEnum.DRAFT, SurveyStatusEnum.SCHEDULED].includes(survey.status) && (
              <NavigationButtons
                goBack={goBack}
                goNext={
                  warnings.NO_NOTIFICATIONS
                    ? () => setShowConfirmNoNotifications(true)
                    : toggleSurveyStatus
                }
                goNextLabel={survey.status === SurveyStatusEnum.DRAFT ? 'Launch' : 'Unschedule'}
                goNextDisabled={
                  (survey.status === SurveyStatusEnum.DRAFT && !canSchedule) ||
                  (survey.status === SurveyStatusEnum.SCHEDULED && !canUnschedule)
                }
                isSubmitting={loading}
              />
            )}
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default Summary
