import React, { useState, useEffect, SyntheticEvent, useCallback } from 'react'

import { makeStyles } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { QRCodeCanvas } from 'qrcode.react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Editor } from 'react-draft-wysiwyg'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import Errors from 'components/Blocks/FormHelpers/Errors'
import Page from 'components/Blocks/Layout/Page'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import { downloadQR } from 'utils'
import { SURVEY_CODE_MIN_LENGTH, SURVEY_CODE_MAX_LENGTH } from 'utils/constants'

const useStyles = makeStyles(({ palette, spacing }) => ({
  helpTextEditor: {
    minHeight: '200px',
    borderRadius: '2px',
    border: '1px solid #F1F1F1',
    marginBottom: spacing(8),
  },
  download: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadIcon: {
    color: palette.common.navy65,
    marginRight: spacing(),
  },
  qrcode: {
    display: 'none',
  },
}))

type Props = {
  defaultSlug: string
  defaultSurveyCodeName: string
  defaultSurveyCodeHelpText: string
  handleSubmit(organization: object): void
  surveyHost: string
  pageTitle: string
  pageUrl: string
  errors: string[]
  loading: boolean
} & RouteComponentProps

const SurveyPortal: React.FC<Props> = ({
  defaultSlug,
  defaultSurveyCodeName,
  defaultSurveyCodeHelpText,
  handleSubmit,
  surveyHost,
  pageTitle,
  pageUrl,
  errors,
  loading,
}) => {
  const classes = useStyles()
  const [slug, setSlug] = useState(defaultSlug)
  const [surveyCodeName, setSurveyCodeName] = useState(defaultSurveyCodeName)
  const [surveyCodeHelpText, setSurveyCodeHelpText] = useState(defaultSurveyCodeHelpText)
  const contentBlock = htmlToDraft(surveyCodeHelpText || '')
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))
  useEffect(() => {
    setSurveyCodeHelpText(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState])
  const getPortalUrl = useCallback(
    (newSlug: string): string => {
      return `${surveyHost}/${newSlug || '<your-choice>'}`
    },
    [surveyHost],
  )

  const [portalUrl, setPortalUrl] = useState(getPortalUrl(slug))
  useEffect(() => {
    setPortalUrl(getPortalUrl(slug))
  }, [slug, getPortalUrl])

  const onSubmit = () => {
    handleSubmit({
      slug,
      surveyCodeName,
      surveyCodeHelpText,
    })
  }

  return (
    <Page>
      <BreadcrumbsItem to={pageUrl}>{pageTitle}</BreadcrumbsItem>
      <ValidatorForm instantValidate={false} onSubmit={onSubmit}>
        <FormPanel gutterBottom expandIcon={EXPAND_ICON.ARROW} title="Survey Portal Settings">
          <Errors errors={errors} />
          <TextValidator
            name="slug"
            type="text"
            fullWidth
            helperText={`Your surveys will be at: ${portalUrl}`}
            value={slug}
            label="Your Unique Survey Url"
            placeholder=""
            onChange={(e: SyntheticEvent) => setSlug((e.target as HTMLInputElement).value)}
            validators={['required', 'matchRegexp:^[a-z0-9-]*$']}
            errorMessages={[
              'Please provide a short value',
              'Value can only be lower case letters, numbers, and dashes (-)',
            ]}
          />
          <TextValidator
            id="surveyCodeName"
            name="surveyCodeName"
            type="text"
            fullWidth
            value={surveyCodeName}
            label="Survey Code Name"
            placeholder="Survey Code Name"
            onChange={(e: SyntheticEvent) =>
              setSurveyCodeName((e.target as HTMLInputElement).value)
            }
            margin="normal"
            validators={[
              'required',
              `minStringLength:${SURVEY_CODE_MIN_LENGTH}`,
              `maxStringLength:${SURVEY_CODE_MAX_LENGTH}`,
            ]}
            errorMessages={[
              'Survey Code Name is required',
              `Survey Code Name must be longer than ${SURVEY_CODE_MIN_LENGTH} characters`,
              `Survey Code Name must be shorter than ${SURVEY_CODE_MAX_LENGTH} characters`,
            ]}
          />
          <Editor
            editorState={editorState}
            editorClassName={classes.helpTextEditor}
            onEditorStateChange={setEditorState}
            stripPastedStyles
            placeholder="Survey Code Help Text"
            toolbar={{
              options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'remove', 'colorPicker'],
              inline: { inDropdown: true },
              textAlign: {
                options: ['left', 'center', 'justify'],
              },
              link: { inDropdown: true },
            }}
          />
          <TitleWithInfoTooltip
            variant="body1"
            title="Generate a QR code for your Survey"
            tooltip="A QR code is a quick and easy way to share your survey. Once the code is generated, participants can use the camera on their mobile device to scan the QR code, and open the survey."
            color="textSecondary"
          />
          <div className={classes.download}>
            <CloudDownloadIcon className={classes.downloadIcon} />
            <NavLink to="#" onClick={downloadQR}>
              Download QR Code
            </NavLink>
          </div>
          <div className={classes.qrcode}>
            <QRCodeCanvas id="qrcode" value={portalUrl} size={290} level="H" includeMargin />
          </div>
        </FormPanel>
        <SubmitButton isSubmitting={loading} right color="primary">
          Save
        </SubmitButton>
      </ValidatorForm>
    </Page>
  )
}

export default withRouter(SurveyPortal)
