import React, { useState, SyntheticEvent } from 'react'

import { makeStyles, Button, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core'
import cn from 'classnames'

import { ReactComponent as SortIcon } from 'assets/img/sort_icon.svg'

const useStyles = makeStyles(({ palette, spacing }) => ({
  background: {
    backgroundColor: palette.common.iceGrey,
  },
  buttonContent: {
    color: palette.common.navy65,
    '& >svg': {
      marginLeft: 10,
    },
  },
  headerLabel: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.common.navy25}`,
    '& >span': {
      float: 'right',
      fontSize: 12,
      color: palette.common.navy65,
    },
  },
}))

type Props = {
  currentOption: string
  availableOptions: string[]
  labelsMap: { [key: string]: string }
  onChange(newSort: string): void
  size?: 'small' | 'medium' | 'large'
  useBackground?: boolean
  headerLabel?: string
}

const SortButton: React.FC<Props> = ({
  currentOption,
  availableOptions,
  labelsMap,
  onChange,
  size = 'medium',
  useBackground = true,
  headerLabel,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)

  return (
    <>
      <Button
        size={size}
        className={cn({ [classes.background]: useBackground })}
        onClick={(e: SyntheticEvent) => setAnchorEl(e.currentTarget)}
        id="sort-button"
      >
        <Typography className={classes.buttonContent}>
          Sort
          <SortIcon />
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {headerLabel && <Typography className={classes.headerLabel}>{headerLabel}</Typography>}
        {availableOptions.map(option => (
          <MenuItem
            key={option}
            selected={option === currentOption}
            onClick={() => {
              setAnchorEl(null)
              onChange(option)
            }}
            id={`option-${option}`}
          >
            <ListItemText primary={labelsMap[option]} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SortButton
