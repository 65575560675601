import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles(({ palette }) => ({
  warning: {
    display: 'flex',
    alignItems: 'center',
    '& >svg': {
      color: palette.common.warning,
    },
  },
}))

type Props = {
  onOpen(): void
}

const MissingTranslationsWarning: React.FC<Props> = ({ onOpen }) => {
  const classes = useStyles()

  return (
    <div className={classes.warning}>
      <WarningIcon />
      <Typography variant="body1">
        You must add translations for the custom text you’ve created.{' '}
        <NavLink to="#" onClick={onOpen}>
          Get started here
        </NavLink>
      </Typography>
    </div>
  )
}

export default MissingTranslationsWarning
