import React, { useState, useRef, ReactElement, SyntheticEvent, useEffect } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  makeStyles,
  Checkbox,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
  Tooltip,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SearchIcon from '@material-ui/icons/Search'
import cn from 'classnames'

import PlainSelectInput from 'components/ActionPlans/PlainSelectInput'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import {
  useInsightsRankedStatementsQuery,
  ActionPlansActionPlanDocument,
  ActionPlansActionPlanQuery,
  InsightsRankedStatementsQuery,
  RankByEnum,
  QFocus,
  QResidentFocus,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { actionItemIsDone } from 'utils/actionPlansUtils'
import { RANKED_STATEMENTS_LIMIT, SUPPORT_EMAIL } from 'utils/constants'
import { SurveyNodeAP as SurveyNode } from 'utils/types'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    marginTop: spacing(2),
    marginLeft: spacing(3),
  },
  subtitle: {
    width: '60%',
  },
  searchRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacing(2),
  },
  searchIcon: {
    marginBottom: -10,
    marginRight: 10,
  },
  searchInput: {
    width: 200,
  },
  statementsRoot: {
    marginTop: spacing(4),
  },
  statement: {
    display: 'flex',
    marginBottom: spacing(5),
  },
  checked: {
    color: palette.common.secondary,
  },
  unchecked: {
    color: palette.common.navy65,
  },
  focus: {
    color: palette.common.navy65,
  },
  scores: {
    display: 'flex',
    '& div': {
      marginRight: spacing(2),
    },
  },
  positive: {
    color: palette.common.success,
  },
  inconsistent: {
    color: palette.common.warning,
  },
  negative: {
    color: palette.common.danger,
  },
  seeAllStatements: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
}))

const StatementLabel: React.FC<{ showTooltip: boolean; label: ReactElement }> = ({
  showTooltip,
  label,
}) => {
  return showTooltip ? (
    <Tooltip
      title={`This statement is already on your plan. If you’d like to see our recommended action items for it, please contact ${SUPPORT_EMAIL}`}
    >
      <div>{label}</div>
    </Tooltip>
  ) : (
    <div>{label}</div>
  )
}

type StatementsListProps = {
  surveyUuid: string
  statements: InsightsRankedStatementsQuery['statements']
  targetUserUuid?: string
  statementUuids?: string[]
  setStatementUuids(statementUuids: string[]): void
}

const StatementsList: React.FC<StatementsListProps> = ({
  surveyUuid,
  statements,
  targetUserUuid,
  statementUuids = [],
  setStatementUuids,
}) => {
  const classes = useStyles()
  const client = useApolloClient()
  const actionPlan = client.readQuery<ActionPlansActionPlanQuery>({
    query: ActionPlansActionPlanDocument,
    variables: { targetUserUuid },
  })?.actionPlan

  if (!actionPlan) return <></>
  // Statements that are done (visible in the Completed Statements tab) can be selected again to work on
  // Statements from the same survey can't be selected again
  const disabledActionItems = actionPlan.actionItems
    .filter(ai => !actionItemIsDone(ai) || ai.statement?.survey.uuid === surveyUuid)
    .map(ai => ai.statement?.code)
  return (
    <Grid container spacing={1} className={classes.statementsRoot}>
      {statements.map(statement => {
        const checked = statementUuids.includes(statement.uuid)
        const isDisabled = disabledActionItems.includes(statement.code)
        return (
          <Grid item sm={6} key={statement.uuid} className={classes.statement}>
            <div>
              <Checkbox
                checked={checked || isDisabled}
                className={checked ? classes.checked : classes.unchecked}
                disabled={isDisabled}
                onClick={() =>
                  setStatementUuids(
                    statementUuids.includes(statement.uuid)
                      ? statementUuids.filter(uuid => uuid !== statement.uuid)
                      : [...statementUuids, statement.uuid],
                  )
                }
              />
            </div>
            <div>
              <StatementLabel
                showTooltip={isDisabled}
                label={
                  <Typography component="div" className={cn({ [classes.focus]: isDisabled })}>
                    {statement.label}
                    <span className={classes.focus}>
                      {' '}
                      &mdash; {statement.residentResultsGroup?.toUpperCase() || statement.focus}
                    </span>
                  </Typography>
                }
              />
              <div className={classes.scores}>
                <div className={classes.positive}>{Math.round(statement.positive)}%</div>
                <div className={classes.inconsistent}>{Math.round(statement.inconsistent)}%</div>
                <div className={classes.negative}>{Math.round(statement.negative)}%</div>
              </div>
            </div>
          </Grid>
        )
      })}
    </Grid>
  )
}

type Props = {
  surveys: SurveyNode[]
  statementUuids?: string[]
  targetUserUuid?: string
  setStatementUuids(statementUuids: string[]): void
}

const StatementsStep: React.FC<Props> = ({
  surveys,
  statementUuids,
  targetUserUuid,
  setStatementUuids,
}) => {
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>(null)
  const [statementSearch, setStatementSearch] = useState('')
  const [showAllStatements, setShowAllStatements] = useState(false)
  const [survey, setSurvey] = useState(surveys[0])
  // Match the property used to rank statements with the charts in insights.
  const rankBy =
    survey.productType === SurveyProductTypeEnum.EMPLOYEE
      ? RankByEnum.INCONSISTENT
      : RankByEnum.NEGATIVE
  const variables = {
    surveyUuid: survey.uuid,
    rankBy,
    includeCustom: true,
  }

  const result = useInsightsRankedStatementsQuery({ variables })

  useEffect(() => {
    if (showAllStatements) {
      // scroll to all statements
      const container = document.getElementById('statements-dialog-content')
      if (container && ref.current) {
        container.scrollTop = ref.current.offsetTop - container.offsetTop
      }
    }
  }, [showAllStatements])

  return (
    <div id="statements-step" className={classes.root}>
      <div>
        <Typography variant="h5">Your Top Five Statements to Focus On:</Typography>
        <Typography component="div" color="textSecondary" className={classes.subtitle}>
          Here are your {RANKED_STATEMENTS_LIMIT} STATEMENTS with the greatest potential for
          improvement. Select the ones that you want to focus on, and we will create an action plan
          just for you. Statements are from your:{' '}
          <Select
            value={survey.uuid}
            onChange={e => {
              // Changing the survey should clear any selected statements (PM requirement)
              setStatementUuids([])
              const selectedSurvey = surveys.find(
                s => s.uuid === (e.target as HTMLInputElement).value,
              )
              selectedSurvey && setSurvey(selectedSurvey)
            }}
            displayEmpty={false}
            variant="filled"
            input={<PlainSelectInput />}
            name="survey"
          >
            {surveys.map(s => (
              <MenuItem key={s.uuid} value={s.uuid}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </Typography>
      </div>
      <ResponseHandler {...result}>
        {({ statements }) => (
          <>
            <div id="top-statements">
              <StatementsList
                surveyUuid={survey.uuid}
                targetUserUuid={targetUserUuid}
                statements={statements
                  // Filter custom statements out from the top suggestions
                  .filter(
                    s => s.focus !== QFocus.CUSTOM && s.residentFocus !== QResidentFocus.CUSTOM,
                  )
                  .slice(0, RANKED_STATEMENTS_LIMIT)}
                statementUuids={statementUuids}
                setStatementUuids={setStatementUuids}
              />
            </div>
            <Typography
              ref={ref}
              id="toggle-statements"
              component="div"
              variant="subtitle1"
              color="secondary"
              className={classes.seeAllStatements}
              onClick={() => setShowAllStatements(!showAllStatements)}
            >
              <div>{showAllStatements ? 'Hide' : 'See'} All Statements </div>
              {showAllStatements ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Typography>
            {showAllStatements && (
              <>
                <div className={classes.searchRoot}>
                  <Typography component="div" color="textSecondary">
                    (Showing All) {survey.name} Statements:
                  </Typography>
                  <div>
                    <SearchIcon className={classes.searchIcon} />
                    <Input
                      id="search-statements"
                      className={classes.searchInput}
                      onChange={(e: SyntheticEvent) =>
                        setStatementSearch((e.target as HTMLInputElement).value)
                      }
                      value={statementSearch}
                      placeholder="Search for a statement"
                    />
                  </div>
                </div>
                <div id="all-statements">
                  <StatementsList
                    surveyUuid={survey.uuid}
                    targetUserUuid={targetUserUuid}
                    statements={statements
                      .slice(RANKED_STATEMENTS_LIMIT)
                      .filter(({ label }) =>
                        label.toLowerCase().includes(statementSearch.toLowerCase()),
                      )}
                    statementUuids={statementUuids}
                    setStatementUuids={setStatementUuids}
                  />
                </div>
              </>
            )}
          </>
        )}
      </ResponseHandler>
    </div>
  )
}

export default StatementsStep
