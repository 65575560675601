import React, { useState, ReactElement } from 'react'

import { Fade, makeStyles, InputAdornment } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: '100%',
  },
  showCopied: {
    color: palette.common.success,
  },
}))

type Props = {
  value: string
  CopyIconElement: ReactElement
  textDescription?: string
  positionIconAsAdornment?: boolean
}
const CopyTextInput: React.FC<Props> = ({
  value,
  CopyIconElement,
  textDescription = 'Share Link',
  positionIconAsAdornment = true,
}) => {
  const classes = useStyles()
  const [showCopied, setShowCopied] = useState(false)

  const handleOnCopy = () => {
    setShowCopied(true)
    setTimeout(() => {
      setShowCopied(false)
    }, 2000)
  }
  const CopyElement = (
    <CopyToClipboard text={value} onCopy={handleOnCopy}>
      {CopyIconElement}
    </CopyToClipboard>
  )
  return (
    <ValidatorForm onSubmit={() => {}} className={classes.container}>
      <TextValidator
        name="shareUrl"
        type="text"
        autoComplete="off"
        disabled
        fullWidth
        value={value}
        label={
          <>
            {textDescription}
            <Fade in={showCopied}>
              <span className={classes.showCopied}> (Copied!)</span>
            </Fade>
          </>
        }
        placeholder=""
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{positionIconAsAdornment && CopyElement}</InputAdornment>
          ),
        }}
      />
      {!positionIconAsAdornment && CopyElement}
    </ValidatorForm>
  )
}

export default CopyTextInput
