import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'

import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  ChartTimeRange,
  timeRangeDescription,
  getDeltaAndText,
} from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import StandardTooltip from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import {
  Chart as TTChart,
  ChartType as TTChartType,
} from 'components/Insights/TimeTrending/TTIndexScoreResponseCard'
import { getOverallScoreGroups } from 'components/Insights/TimeTrending/utils'
import { BenchmarkCodeType, useInsightsOverallIndexQuery } from 'generated/graphql'
import DynamicTimeframeQuery from 'HOC/DynamicTimeframeQuery'
import { colors } from 'shared/theme'
import { formatTooltipScore } from 'utils'
import { getFormattedSurveyDate } from 'utils/dateUtils'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
  },
  chart: {
    marginTop: spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoPercentageText: {
    '& >span': {
      marginLeft: -14,
    },
  },
}))

type Props = {
  survey: InsightsSurvey
  filters: string[]
  timeRanges: ChartTimeRange[]
}
const TimeframeRecommendationScoreCard: React.FC<Props> = ({ survey, filters, timeRanges }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <div id="timeframe-recommendation-snap">
      <SnapshotChartHeader
        title="Overall Recommendation Score Over Time"
        tooltip="See how your recommendation score compares by time period. On the left you can see the change from the first to last time period."
        snapId="timeframe-recommendation-snap"
        useBottomPadding={false}
        description={timeRangeDescription(timeRanges)}
      />
      <DynamicTimeframeQuery
        timeRanges={timeRanges}
        variables={{
          filters,
          surveyUuid: survey.uuid,
          statementCodes: [BenchmarkCodeType.CORE_Q1_RECOMMEND],
        }}
        queryHook={useInsightsOverallIndexQuery}
      >
        {data => {
          const scoreTypeGroups = getOverallScoreGroups(
            data.map(d => ({
              ...d,
              overallIndex: d.insightsOverallIndex,
            })),
          )
          const { scoreDelta, deltaText } = getDeltaAndText(scoreTypeGroups[0])
          return (
            <div className={classes.container}>
              <div className={classes.timeTrendingInfoBox}>
                <div className={classes.infoPercentageText}>
                  <ScoreChangeArrow delta={scoreDelta} fontSize="3.6rem" />
                </div>
                <Typography variant="body2" color="textSecondary">
                  Your overall recommendation score for{' '}
                  {getFormattedSurveyDate({
                    ...timeRanges[timeRanges.length - 1],
                    includeDay: true,
                    isTimeRange: true,
                  })}{' '}
                  is {deltaText} your score for{' '}
                  {getFormattedSurveyDate({
                    ...timeRanges[0],
                    includeDay: true,
                    isTimeRange: true,
                  })}
                  .
                </Typography>
              </div>
              <TTChart
                scoreTypeGroups={scoreTypeGroups}
                xAxisLabelData={timeRanges}
                chartType={TTChartType.INDEX_SCORE}
                tooltipFormatter={series => {
                  let delta = null
                  const currentPositive = scoreTypeGroups[0][series.dataIndex][1]
                  const currentInconsistent = scoreTypeGroups[1][series.dataIndex][1]
                  const currentNegative = scoreTypeGroups[2][series.dataIndex][1]
                  if (series.dataIndex > 0) {
                    delta = currentPositive! - scoreTypeGroups[0][series.dataIndex - 1][1]!
                  }
                  return renderEchartTooltip(
                    <StandardTooltip
                      title={getFormattedSurveyDate({
                        ...timeRanges[series.dataIndex],
                        includeDay: true,
                        isTimeRange: true,
                      })}
                      rows={[
                        {
                          label: 'Recommendation Score:',
                          value: formatTooltipScore(currentPositive, survey.minShowableResults),
                          detail: <ScoreChangeArrow isPercentage={false} delta={delta} />,
                        },
                        {
                          label: 'Positive:',
                          value: formatTooltipScore(currentPositive, survey.minShowableResults),
                          color: colors.success,
                          detail: <div />, // Use empty div to insert equal spacing as the score change above.
                        },
                        {
                          label: 'Inconsistent:',
                          value: formatTooltipScore(currentInconsistent, survey.minShowableResults),
                          color: colors.warning,
                          detail: <div />,
                        },
                        {
                          label: 'Negative:',
                          value: formatTooltipScore(currentNegative, survey.minShowableResults),
                          color: colors.danger,
                          detail: <div />,
                        },
                      ]}
                    />,
                  )
                }}
              />
            </div>
          )
        }}
      </DynamicTimeframeQuery>
    </div>
  )
}

export default TimeframeRecommendationScoreCard
