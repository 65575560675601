import React, { ReactElement } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import cn from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { NavLink } from 'react-router-dom'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
  LabelFormatter,
} from 'recharts'

import BarChartSortButton from 'components/Insights/Blocks/BarChartSortButton'
import LegendItem from 'components/Insights/Blocks/StatementsLegendItem'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import {
  InsightsBenchmark,
  InsightsSurvey,
  InsightsTabProps,
} from 'components/Insights/InsightsTypes'
import PrintableRechart from 'components/Insights/Printable/PrintableRechart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  StatementScores,
  StatementData,
  ResponseTypeKey,
} from 'components/Insights/Statements/StatementsContainer'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import {
  InsightsModulesEnum,
  InsightsStatementScoreFragment,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { getViridisColors } from 'utils'
import { SORT_OPTIONS, URLS } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

type BarColor = ReturnType<typeof getViridisColors>

const useStyles = makeStyles(({ palette, spacing }) => ({
  headerButtons: {
    position: 'absolute',
    display: 'flex',
    right: 0,
    top: spacing(),
  },
  statementsBarLabelContrast: {
    fill: palette.common.navy,
  },
  statementsNoMatchSearch: {
    width: '100%',
    fontSize: '1.6rem',
    color: palette.common.navy65,
    textAlign: 'center',
  },
  statementsLegend: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  statementsSortControls: {
    position: 'absolute',
    right: spacing(3),
    backgroundColor: palette.common.navy15,
    borderRadius: 4,
    '@media print': {
      display: 'none',
    },
  },
  statementsLabelLink: {
    color: palette.common.navy,
  },
  statementsFocus: {
    color: palette.common.navy65,
    fontSize: '1.5rem',
  },
  statementsChart: {
    width: '78%',
  },
  statementsBarLabel: {
    fill: '#FFF',
    fontSize: 14,
  },
}))

const mask = (value: string | null, percentage: boolean) =>
  value === null ? '-' : value + (percentage ? '%' : '')

const StatementTooltip: React.FC<{
  minShowableResults: number
  payload: Array<{ payload: StatementData }>
}> = ({ minShowableResults, payload }) => {
  if (!payload.length) {
    return <div />
  }
  const [first] = payload
  const {
    stmt,
    'selected-filters-positive': selectedFiltersPositive,
    'selected-filters-inconsistent': selectedFiltersInconsistent,
    'selected-filters-negative': selectedFiltersNegative,
  } = first.payload
  const labelStyle: CSSProperties = {
    color: colors.navy65,
    width: 120,
    textAlign: 'right',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  }
  const numberStyle: CSSProperties = {
    display: 'inline-block',
    width: 25,
    textAlign: 'right',
    position: 'relative',
    top: -6,
  }
  return (
    <div style={{ backgroundColor: '#FFF', maxWidth: 300, padding: 8 }}>
      <Typography variant="body2">
        <span style={labelStyle}>Benchmark:</span>{' '}
        <span style={{ ...numberStyle, color: colors.success }}>
          {mask(String(Math.round(stmt.benchmarkPositive || 0)), true)}
        </span>{' '}
        <span style={{ ...numberStyle, color: colors.warning }}>
          {mask(String(Math.round(stmt.benchmarkInconsistent || 0)), true)}
        </span>{' '}
        <span style={{ ...numberStyle, color: colors.danger }}>
          {mask(String(Math.round(stmt.benchmarkNegative || 0)), true)}
        </span>
      </Typography>
      <Typography variant="body2">
        <span style={labelStyle}>Company:</span>{' '}
        <span style={{ ...numberStyle, color: colors.success }}>{Math.round(stmt.positive)}%</span>{' '}
        <span style={{ ...numberStyle, color: colors.warning }}>
          {Math.round(stmt.inconsistent)}%
        </span>{' '}
        <span style={{ ...numberStyle, color: colors.danger }}>{Math.round(stmt.negative)}%</span>
      </Typography>
      {selectedFiltersPositive && (
        <Typography variant="body2">
          <span style={labelStyle}>Selected Filters:</span>{' '}
          <span style={{ ...numberStyle, color: colors.success }}>
            {Math.round(Number(selectedFiltersPositive))}%
          </span>{' '}
          <span style={{ ...numberStyle, color: colors.warning }}>
            {Math.round(Number(selectedFiltersInconsistent))}%
          </span>{' '}
          <span style={{ ...numberStyle, color: colors.danger }}>
            {Math.round(Number(selectedFiltersNegative))}%
          </span>
        </Typography>
      )}
      {stmt.children.map(c => (
        <Typography key={c.label} variant="body2">
          <span style={labelStyle}>{c.label}:</span>{' '}
          <span style={{ ...numberStyle, color: colors.success }}>
            {c.count >= minShowableResults
              ? `${Math.round(c.positive)}%`
              : `<${minShowableResults}`}
          </span>{' '}
          <span style={{ ...numberStyle, color: colors.warning }}>
            {c.count >= minShowableResults
              ? `${Math.round(c.inconsistent)}%`
              : `<${minShowableResults}`}
          </span>{' '}
          <span style={{ ...numberStyle, color: colors.danger }}>
            {c.count >= minShowableResults
              ? `${Math.round(c.negative)}%`
              : `<${minShowableResults}`}
          </span>
        </Typography>
      ))}
    </div>
  )
}

// When all three response types are selected, only show positive.
// With a single type, show a single type
// With multiple, combine them with a dash.
export const getResponseTypeKey = (responseTypes: string[]) => {
  return responseTypes.length === 3 ? 'positive' : responseTypes.join('-')
}

export const sortStatements = (
  statements: StatementScores,
  sort: string,
  responseTypes: string[],
  hasSurveyFilters: boolean,
) => {
  const prefix = hasSurveyFilters ? 'selected-filters-' : 'company-'
  const key: ResponseTypeKey = prefix + getResponseTypeKey(responseTypes)

  const barTotal = (stmt: StatementScores[0]) => stmt.data[0][key] as number

  if (sort === SORT_OPTIONS.A_TO_Z) {
    statements.sort((a, b) => a.statement.label.localeCompare(b.statement.label))
  } else if (sort === SORT_OPTIONS.LOW_TO_HIGH) {
    statements.sort((a, b) => barTotal(a) - barTotal(b))
  } else if (sort === SORT_OPTIONS.HIGH_TO_LOW) {
    statements.sort((a, b) => barTotal(b) - barTotal(a))
  }
}

export const prepareLineData = (
  statement: StatementScores[0]['statement'],
  numComparisons: number,
  numSurveyFilters: number,
  otherColors: ReturnType<typeof getViridisColors>,
  responseTypes: string[],
  benchmarkName?: string | null,
  filteredStatement?: InsightsStatementScoreFragment,
) => {
  const showCompanyAsBenchmark = Boolean(numComparisons) || Boolean(numSurveyFilters)
  const showSurveyFiltersAsBenchmark = Boolean(numComparisons) && Boolean(numSurveyFilters)
  const children = cloneDeep(statement.children)
  const lineColors = cloneDeep(otherColors)
  const responseTypeKey = getResponseTypeKey(responseTypes)
  const barLineData = []
  const benchmarkLineData = [
    {
      label: benchmarkName,
      fillColor: benchmarkColor,
      // Not all benchmarks contain inconsistent/negative, so we only show positive.
      dataKey: 'benchmark-positive',
    },
  ]
  if (showCompanyAsBenchmark) {
    benchmarkLineData.push({
      label: 'Company Overall',
      fillColor: companyPurple.p,
      dataKey: `company-${responseTypeKey}`,
    })
  } else {
    // Show company overall as bar line.
    barLineData.push({ ...statement, isCompanyOverall: true })
  }
  if (showSurveyFiltersAsBenchmark) {
    benchmarkLineData.push({
      label: 'Selected Filters',
      // Pull one off the comparison colors
      fillColor: lineColors.shift()?.p || companyPurple.p,
      dataKey: `selected-filters-${responseTypeKey}`,
    })
  } else if (filteredStatement) {
    // Show selected filters as bar line.
    barLineData.push({ ...filteredStatement, label: 'Selected Filters', isSelectedFilters: true })
  }
  barLineData.push(...children)
  return { barLineData, benchmarkLineData, lineColors }
}

const benchmarkColor = '#4caf50'

const companyPurple = getViridisColors(0)[0]
const barLabelFormatter = (val: string | number, minShowableResults: number) => {
  if (val === null || val < minShowableResults) {
    return ''
  }
  return `${Math.round(Number(val))}%`
}

export type Props = {
  surveyProductType: SurveyProductTypeEnum
  survey: InsightsSurvey
  statements: StatementScores
  filterValueLabels: string[]
  responseTypes: string[]
  filters: string[]
  benchmark: InsightsBenchmark
  insightsModules: string[]
  showFocus?: boolean
  currentSort?: SORT_OPTIONS
  onSortChange?(newSort: SORT_OPTIONS): void
  timeTrendingChartKey?: TimeTrendingChartKey
  title: string
  description?: string
  legendAlign?: string
  tooltip?: ReactElement
  padRows?: boolean
  overallLegendName?: string
  timeTrendingType?: InsightsTabProps['timeTrendingType']
}
const StatementsCard: React.FC<Props> = ({
  surveyProductType,
  survey,
  filterValueLabels,
  benchmark,
  statements,
  responseTypes,
  showFocus,
  insightsModules,
  onSortChange,
  currentSort,
  timeTrendingChartKey,
  title,
  description,
  legendAlign = 'left',
  tooltip,
  padRows = false,
  overallLegendName = 'Company Overall',
  timeTrendingType,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }

  const barSize = 32
  const barGap = 9
  const skinnyBarSize = 4
  // Derive number of comparisons from the data, so that we change our render when the data is updated
  // instead when the user interacts.
  let numComparisons = 0
  if (statements.length) {
    numComparisons = statements[0].statement.children.length
  }

  const getExtraLegends = () => {
    if (!statements.length) {
      return []
    }
    const extraLegends = []
    if (filterValueLabels.length) {
      extraLegends.push('Selected Filters')
    }
    extraLegends.push(...statements[0].statement.children.map(c => c.label))
    return extraLegends
  }

  const calculateGraphHeight = (numBarLines: number, numBenchmarkLines: number) => {
    const fatBarHeight = barSize + barGap
    const skinnyBarHeight = skinnyBarSize + barGap
    let height = barSize
    height += fatBarHeight * numBarLines
    height += skinnyBarHeight * numBenchmarkLines
    return height
  }

  const renderBarLines = (
    barLineData: ReturnType<typeof prepareLineData>['barLineData'],
    otherColors: BarColor,
  ) => {
    const colorBarMapping: ['p', 'i', 'n'] = ['p', 'i', 'n']
    return responseTypes.map((barType, barIndex) => {
      return barLineData.map((statementData, childIndex) => {
        const { count, label } = statementData
        const classNames = [classes.statementsBarLabel]
        if (barIndex >= 1) {
          // Only render the first bar for <minShowableResults responses
          if (count < survey.minShowableResults) {
            return <React.Fragment key={`${label}-${barType}`} />
          }
          classNames.push(classes.statementsBarLabelContrast)
        }
        let dataKey
        let color
        let formatter: LabelFormatter = val => barLabelFormatter(val, survey.minShowableResults)
        const colorKey = colorBarMapping[barIndex]
        if (count < survey.minShowableResults) {
          dataKey = 'less-than-five'
          color = colors.navy25
          formatter = () => `<${survey.minShowableResults}`
        } else if ('isCompanyOverall' in statementData && statementData.isCompanyOverall) {
          dataKey = `company-${barType}`
          color = companyPurple[colorKey]
        } else if ('isSelectedFilters' in statementData && statementData.isSelectedFilters) {
          dataKey = `selected-filters-${barType}`
          color = otherColors[childIndex][colorKey]
        } else {
          dataKey = `child-${childIndex}-${barType}`
          color = otherColors[childIndex][colorKey]
        }
        return (
          <Bar
            key={`${label}-${barType}`}
            barSize={barSize}
            stackId={label}
            dataKey={dataKey}
            fill={color}
          >
            <LabelList
              className={cn(classNames)}
              dataKey={dataKey}
              position="insideLeft"
              formatter={formatter}
            />
          </Bar>
        )
      })
    })
  }

  const renderBenchmarkLines = (
    benchmarkLineData: ReturnType<typeof prepareLineData>['benchmarkLineData'],
  ) => {
    return benchmarkLineData.map(({ label, fillColor, dataKey }, index) => {
      return (
        <Bar
          key={String(label) + index}
          barSize={skinnyBarSize}
          dataKey={dataKey}
          name={label || ''}
          fill={fillColor}
        >
          <LabelList
            dataKey={dataKey}
            position="right"
            formatter={val => barLabelFormatter(val, survey.minShowableResults)}
          />
        </Bar>
      )
    })
  }

  const numSurveyFilters = filterValueLabels.length
  let numLines = numComparisons
  if (numSurveyFilters) {
    // Filter values are combined into one extra comparison.
    numLines += 1
  }
  const otherColors = getViridisColors(numLines)
  const extraLegends = getExtraLegends()
  const statementsToShow = Boolean(statements?.length)
  const url = {
    [SurveyProductTypeEnum.EMPLOYEE]: URLS.EMPLOYEE_INSIGHTS.STATEMENTS,
    [SurveyProductTypeEnum.RESIDENT]: URLS.RESIDENT_INSIGHTS.STATEMENTS,
  }[surveyProductType]
  return (
    // Remove whitespace from id
    <div id={`statements${title}Snapshot`.replace(/\s/g, '')}>
      <BreadcrumbsItem to={url}>Statements</BreadcrumbsItem>
      <SnapshotChartHeader
        title={title}
        description={description || 'See how you scored on each statement on your survey.'}
        tooltip={tooltip}
        screenshotStrategy="html2canvas"
        snapId={`statements${title}Snapshot`.replace(/\s/g, '')}
        hasTimeTrending={Boolean(timeTrendingType)}
        timeTrendingChartKey={timeTrendingChartKey}
        timeTrendingSurveyUuid={timeTrendingType === 'timeframe' ? survey.uuid : undefined}
      />
      {statementsToShow && (
        <>
          <div
            className={classes.statementsLegend}
            style={legendAlign === 'left' ? { marginRight: '50%' } : { marginLeft: '50%' }}
          >
            <LegendItem label={benchmark.name} color={benchmarkColor} />
            <LegendItem label={overallLegendName} color={companyPurple.p} />
            {extraLegends.map((leg, idx) => (
              <LegendItem key={leg} label={leg} color={otherColors[idx].p} />
            ))}
          </div>
          {currentSort && onSortChange && (
            <div className={classes.statementsSortControls}>
              <BarChartSortButton currentSort={currentSort} handleChangeSort={onSortChange} />
            </div>
          )}
        </>
      )}
      {!statementsToShow && (
        <div className={classes.statementsNoMatchSearch}>
          No statements match your search query.
        </div>
      )}
      {statements.map(s => {
        const { barLineData, benchmarkLineData, lineColors } = prepareLineData(
          s.statement,
          numComparisons,
          numSurveyFilters,
          otherColors,
          responseTypes,
          benchmark?.name,
          s.filteredStatement,
        )
        return (
          <div
            key={s.statement.label}
            className={classes.statementsRow}
            style={padRows ? { marginTop: 30, marginBottom: 30 } : {}}
          >
            <div className={classes.statementsLeft}>
              <div className={classes.statementsLabel}>
                {insightsModules.includes(InsightsModulesEnum.COMPARISONS) ? (
                  <NavLink
                    className={classes.statementsLabelLink}
                    to={`${getInsightsPage(
                      survey.uuid,
                      InsightsModulesEnum.COMPARISONS,
                      surveyProductType,
                    )}?statement=${s.statement.code}`}
                  >
                    {s.statement.label}
                  </NavLink>
                ) : (
                  <div className={classes.statementsLabelLink}>{s.statement.label}</div>
                )}
              </div>
              {showFocus && <div className={classes.statementsFocus}>{s.statement.focus}</div>}
            </div>
            <div className={classes.statementsRight}>
              <div className={classes.statementsChart}>
                <PrintableRechart
                  screenWidth="100%"
                  printWidth="90%"
                  height={calculateGraphHeight(barLineData.length, benchmarkLineData.length)}
                >
                  <BarChart
                    data={s.data}
                    layout="vertical"
                    margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
                    barCategoryGap={0}
                    barGap={barGap}
                  >
                    <Tooltip
                      cursor={{ fill: colors.navy11 }}
                      content={(props: { payload: Array<{ payload: StatementData }> }) => (
                        <StatementTooltip
                          minShowableResults={survey.minShowableResults}
                          {...props}
                        />
                      )}
                    />
                    <CartesianGrid
                      horizontal={false}
                      stroke={colors.navy25}
                      strokeWidth="0.5"
                      shapeRendering="crispEdges"
                    />
                    <XAxis
                      orientation="top"
                      type="number"
                      domain={[0, 100]}
                      tickFormatter={() => ''}
                      height={0}
                      ticks={[0, 20, 40, 60, 80, 100]}
                      axisLine={false}
                    />
                    <YAxis
                      width={1}
                      type="category"
                      dataKey="label"
                      tickFormatter={() => ''}
                      tickLine
                      axisLine={false}
                    />
                    {renderBarLines(barLineData, lineColors)}
                    {renderBenchmarkLines(benchmarkLineData)}
                  </BarChart>
                </PrintableRechart>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default StatementsCard
