import { createMachine } from 'xstate'

import emitter from 'shared/authenticated/emitter'

type MachineActions = { type: 'FETCH_DOWNLOAD' } | { type: 'SUCCESS' } | { type: 'ERROR' }
type MachineStates = { value: 'idle'; context: {} } | { value: 'loading'; context: {} }
export const createDownloadMachine = (fetchDownload: () => void) =>
  createMachine<{}, MachineActions, MachineStates>({
    id: 'download',
    initial: 'idle',
    states: {
      idle: {
        on: {
          FETCH_DOWNLOAD: {
            target: 'loading',
            actions: () => fetchDownload(),
          },
        },
      },
      loading: {
        on: {
          SUCCESS: {
            target: 'idle',
            actions: () => {
              emitter.emit(
                'SUCCESS',
                'Your download is being generated! You’ll receive a download link here and via email in a few minutes when it is ready.',
              )
            },
          },
          ERROR: {
            target: 'idle',
            actions: () => {
              emitter.emit('ERROR', 'There was an unexpected error. Please try again later')
            },
          },
        },
      },
    },
  })
