export enum Type {
  CUSTOMER = 'Inform letters - Customer',
  EMPLOYEE = 'Inform letters - Employee',
}

export enum Notification {
  PHONE = 'Phone',
  DIGITAL = 'Digital',
  PHONE_AND_DIGITAL = 'Phone-and-Digital',
}

export enum DisplayInfo {
  LESS = 'Less',
  MORE = 'More',
}

export enum BooleanEnum {
  TRUE = 'true',
  FALSE = 'false',
}
