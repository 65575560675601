import React, { useState } from 'react'

import { Tooltip, FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import Button from 'components/Blocks/CustomButtons/Button'
import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Table from 'components/Blocks/Table'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import AddIntervalNotificationDialog, {
  getMenuLabel,
  notificationsDays,
} from 'components/Survey/Wizard/Steps/Notifications/AddIntervalNotificationDialog'
import {
  useStyles,
  getColumns,
  Notification,
  getNotificationRow,
  NotificationInput,
} from 'components/Survey/Wizard/Steps/Notifications/common'
import NewNotificationButton from 'components/Survey/Wizard/Steps/Notifications/NewNotificationButton'
import PhoneNotificationDialog from 'components/Survey/Wizard/Steps/Notifications/PhoneNotificationDialog'
import UpdateNotificationDialog from 'components/Survey/Wizard/Steps/Notifications/UpdateNotificationDialog'
import { gaEvent } from 'config/ga'
import {
  useSurveysSurveyNotificationsQuery,
  useSurveysResetIntervalNotificationMutation,
  useSurveysUpdateIntervalNotificationMutation,
  useSurveysSendIntervalTestEmailMutation,
  useSurveysSendIntervalTestSmsMutation,
  SurveysIntervalNotificationFragment,
  IntervalNotificationInput,
  SurveysSurveyQuery,
  SurveyInviteMethodEnum,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'
import { handleMutationResponse } from 'utils'
import { getReadableTimezoneString } from 'utils/dateUtils'

const getIntervalNotificationRow = (
  classes: ReturnType<typeof useStyles>,
  survey: SurveysSurveyQuery['survey'],
  notification: SurveysIntervalNotificationFragment,
  surveyMaxSmsNotifications: number,
  existingNotificationDays: number[],
  setNotificationToUpdate: (notification: Notification) => void,
  setNotificationToDeactivate: (notification: Notification) => void,
  onUpdate: (n: IntervalNotificationInput) => void,
  disabledNewSmsNotifications?: boolean,
) => {
  return [
    {
      name: 'when',
      value: (
        <>
          <FormControl>
            {notification.active ? (
              <Select
                id={`daysInterval${notification.uuid}`}
                className={classes.selectInput}
                disabled={!notification.active || survey.isRecurring}
                value={notification.days}
                onChange={e =>
                  onUpdate({
                    uuid: notification.uuid,
                    days: e.target.value as number,
                  })
                }
              >
                <MenuItem value="" disabled>
                  Schedule reminder for:
                </MenuItem>
                {notificationsDays.map(d => (
                  <MenuItem key={d} value={d} disabled={existingNotificationDays.includes(d)}>
                    {getMenuLabel(d)}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography color="textSecondary">{getMenuLabel(notification.days)}</Typography>
            )}
          </FormControl>
          {notification.errors && notification.active && (
            <Tooltip title={notification.errors}>
              <InfoIcon className={classes.tooltipIcon} />
            </Tooltip>
          )}
        </>
      ),
    },
    ...getNotificationRow(classes, {
      productType: survey.productType,
      notification,
      surveyMaxSmsNotifications,
      setNotificationToUpdate,
      setNotificationToDeactivate,
      handleSubmitInterval: onUpdate,
      disabledNewSmsNotifications,
      // We disable deactivating the first notification for recurring surveys since our backend contains logic to control when the first notification is sent based on the survey threshold.
      disableActivateNotification: survey.isRecurring && notification.days === 0 ? true : undefined,
    }),
  ]
}

type Props = {
  classes: ReturnType<typeof useStyles>
  survey: SurveysSurveyQuery['survey']
}

const IntervalNotifications: React.FC<Props> = ({ classes, survey }) => {
  const [showAddSmsOrEmailDialog, setShowAddSmsOrEmailDialog] = useState(false)
  const [showPhoneDialog, setShowPhoneDialog] = useState(false)
  const surveyNotificationsResult = useSurveysSurveyNotificationsQuery({
    variables: { surveyUuid: survey.uuid },
  })
  const [notificationToUpdate, setNotificationToUpdate] = useState<null | Notification>(null)
  const [notificationToDeactivate, setNotificationToDeactivate] = useState<null | Notification>(
    null,
  )

  const [updateIntervalNotification, { loading }] = useSurveysUpdateIntervalNotificationMutation()
  const [resetIntervalNotification] = useSurveysResetIntervalNotificationMutation()
  const [sendTestEmail] = useSurveysSendIntervalTestEmailMutation()
  const [sendTestSms] = useSurveysSendIntervalTestSmsMutation()

  const sendEmail = async (emailAddress: string) => {
    if (!notificationToUpdate) {
      return
    }
    const variables = {
      notificationUuid: notificationToUpdate.uuid,
      emailAddress,
    }
    await sendTestEmail({ variables })
    emitter.emit('SUCCESS', `Test email has been sent successfully.`)
  }

  const sendSms = async (smsNumber: string) => {
    if (!notificationToUpdate) {
      return
    }
    const variables = {
      notificationUuid: notificationToUpdate.uuid,
      smsNumber,
    }
    await sendTestSms({ variables })
    emitter.emit('SUCCESS', `Test SMS has been sent successfully.`)
  }

  const onUpdate = async (notification: NotificationInput) => {
    const result = await updateIntervalNotification({
      variables: {
        surveyUuid: survey.uuid,
        notificationInput: {
          uuid: notificationToUpdate?.uuid,
          ...notification,
        },
      },
    })
    handleMutationResponse(result.data?.updateIntervalNotification?.errors)
  }

  const onReset = async (emailOrSms: 'email' | 'sms') => {
    if (!notificationToUpdate) {
      return
    }
    const result = await resetIntervalNotification({
      variables: {
        notificationUuid: notificationToUpdate.uuid,
        emailOrSms,
      },
    })
    if (result.data?.resetIntervalNotification) {
      setNotificationToUpdate(result.data.resetIntervalNotification.notification)
    }
  }
  let phoneHelpText: string
  let smsOrEmailHelpText: string

  if (survey.inviteMethod === SurveyInviteMethodEnum.SMS_EMAIL_PHONE) {
    phoneHelpText =
      'If there’s no response after multiple attempts, we will call participants by phone. Participants will be called according to our best practices and standard protocols. Phone call schedule cannot be adjusted.'
    smsOrEmailHelpText =
      "Schedule notifications to go out based on when participants are added to the survey. If added before the survey launches, they'll receive the first notification when the survey goes live. Initially we will send participants a survey link via SMS or email."
  } else if (survey.inviteMethod === SurveyInviteMethodEnum.PHONE) {
    phoneHelpText =
      'Participants will be called according to our best practices and standard protocols. Phone call schedule cannot be adjusted.'
  } else if (survey.inviteMethod === SurveyInviteMethodEnum.SMS_EMAIL) {
    smsOrEmailHelpText =
      "Schedule notifications to go out based on when participants are added to the survey. If added before the survey launches, they'll receive the first notification when the survey goes live."
  }

  return (
    <ResponseHandler {...surveyNotificationsResult}>
      {({ survey: { intervalNotifications } }) => {
        const existingNotificationDays = intervalNotifications.map(n => n.days)
        const disabledNewNotifications =
          existingNotificationDays.length >= notificationsDays.length || survey.isRecurring
        const disabledNewSmsNotifications =
          intervalNotifications.filter(n => n.active && n.sms).length === survey.maxSmsNotifications
        return (
          <>
            {smsOrEmailHelpText && (
              <FormPanel
                expandIcon={EXPAND_ICON.ARROW}
                headerEnd
                gutterHeader={false}
                gutterBottom
                title={
                  phoneHelpText ? (
                    <TitleWithInfoTooltip
                      title="Digital Schedule Notifications"
                      tooltip={
                        <Typography>
                          We recommend not scheduling any digital notifications within a week of a
                          survey close date so there is enough time for participants who do not
                          answer to get phone surveys
                        </Typography>
                      }
                    />
                  ) : (
                    'Digital Schedule Notifications'
                  )
                }
                helpText={
                  <>
                    <span>{smsOrEmailHelpText}</span>
                    <i> All times are in {getReadableTimezoneString()}.</i>
                  </>
                }
                actions={
                  <NewNotificationButton
                    disabled={disabledNewNotifications}
                    onClick={() => setShowAddSmsOrEmailDialog(true)}
                  />
                }
              >
                <Table
                  bicolor
                  withCellsBorder={false}
                  usePagination={false}
                  columns={getColumns(survey.productType).map(c =>
                    c === 'status'
                      ? { label: c, helpText: 'first notification sent out' }
                      : { label: c },
                  )}
                  totalRows={intervalNotifications.length}
                  rows={intervalNotifications.map(notification =>
                    getIntervalNotificationRow(
                      classes,
                      survey,
                      notification,
                      survey.maxSmsNotifications,
                      existingNotificationDays,
                      setNotificationToUpdate,
                      setNotificationToDeactivate,
                      onUpdate,
                      disabledNewSmsNotifications,
                    ),
                  )}
                />
                {notificationToDeactivate && (
                  <ActionDialog
                    title="Deactivate Notification?"
                    content="Are you sure you want to deactivate notification? This reminder will no longer be sent out."
                    submitButtonText="Deactivate"
                    onClose={() => setNotificationToDeactivate(null)}
                    onSubmit={() => {
                      const attrs = { uuid: notificationToDeactivate?.uuid, active: false }
                      onUpdate(attrs)
                      setNotificationToDeactivate(null)
                    }}
                  />
                )}
                {showAddSmsOrEmailDialog && (
                  <AddIntervalNotificationDialog
                    surveyUuid={survey.uuid}
                    productType={survey.productType}
                    onClose={() => setShowAddSmsOrEmailDialog(false)}
                    existingNotificationDays={existingNotificationDays}
                    disabledNewSmsNotifications={disabledNewSmsNotifications}
                    surveyMaxSmsNotifications={survey.maxSmsNotifications}
                  />
                )}
                {notificationToUpdate && (
                  <UpdateNotificationDialog
                    survey={survey}
                    notificationToUpdate={notificationToUpdate}
                    isSubmitting={loading}
                    onClose={() => setNotificationToUpdate(null)}
                    onUpdate={args => {
                      gaEvent({
                        action: 'editIntervalNotificationText',
                        category: 'Surveys',
                      })
                      onUpdate(args)
                    }}
                    sendTestEmail={args => {
                      gaEvent({
                        action: 'sendTestEmailIntervalNotification',
                        category: 'Surveys',
                      })
                      sendEmail(args)
                    }}
                    sendTestSms={args => {
                      gaEvent({
                        action: 'sendTestSmsIntervalNotification',
                        category: 'Surveys',
                      })
                      sendSms(args)
                    }}
                    onReset={onReset}
                  />
                )}
              </FormPanel>
            )}
            {phoneHelpText && (
              <>
                <FormPanel
                  expanded
                  headerEnd
                  gutterHeader={false}
                  gutterBottom
                  title="Phone Schedule Notifications"
                  helpText={phoneHelpText}
                  actions={
                    <Button color="secondaryNoBackground" onClick={() => setShowPhoneDialog(true)}>
                      <EditIcon />
                      &nbsp;&nbsp;Intro Phone Script
                    </Button>
                  }
                />
                {showPhoneDialog && (
                  <PhoneNotificationDialog
                    text={survey.phoneScript}
                    onClose={() => setShowPhoneDialog(false)}
                  />
                )}
              </>
            )}
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default IntervalNotifications
