import React, { useState } from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import querystring from 'query-string'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useLocation } from 'react-router-dom'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import Comments from 'components/Insights/Comments'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps, InsightsSurvey } from 'components/Insights/InsightsTypes'
import { gaEvent } from 'config/ga'
import {
  useInsightsOpenEndedQuestionsQuery,
  useInsightsCommentsQuery,
  useInsightsCommentsTopCommentsQuery,
  FrozenQuestionType,
  InsightsModulesEnum,
  BenchmarkCodeType,
  NpsGroupsEnum,
} from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import { useTimeframeSettings } from 'utils/customHooks'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(theme => ({
  commentsQuestion: {
    margin: `0 150px 0 ${theme.spacing(3)}px`,
    lineHeight: '1.3',
    fontSize: '1.6rem',
  },
}))

interface QCCProps {
  survey: InsightsSurvey
  filters: string[]
  enhancedComments: boolean
  question: Pick<FrozenQuestionType, 'uuid' | 'code' | 'text'>
  searchQuery: string
}

const QuestionCommentsContainer: React.FC<QCCProps> = ({
  enhancedComments,
  question,
  filters,
  survey,
  searchQuery,
}) => {
  const { startDate, endDate } = useTimeframeSettings(survey)
  const pageSize = 40
  const [page, setPage] = useState(0)
  const [searchWords, setSearchWords] = useState<string[]>([])
  const [sortBy, setSortBy] = useState<string | null>(enhancedComments ? '-score' : null)
  const [filterBy, setFilterBy] = useState<null | NpsGroupsEnum>(null)
  const commonVars = {
    surveyUuid: survey.uuid,
    questionCode: question.uuid,
    pageSize,
    page,
    filters,
    startDate,
    endDate,
    // Include the manual search query in addition to words the user selects off the word cloud chart.
    searchWords: [...searchWords, searchQuery],
    sortBy,
    filterBy,
  }
  const result = useInsightsCommentsQuery({ variables: { ...commonVars, page, pageSize } })
  const topResult = useInsightsCommentsTopCommentsQuery({
    variables: {
      ...commonVars,
      topCommentsCount: 200, // The top number of comments by score that are used to form the Word Cloud
    },
  })
  return (
    <ResponseHandler {...topResult}>
      {({ insightsComments: topComments }) => {
        if (!topComments) return <div />
        return (
          <ResponseHandler {...result}>
            {({ insightsComments: comments }) => {
              if (!comments) return <div />
              return (
                <Comments
                  minShowableResults={survey.minShowableResults}
                  comments={comments.comments}
                  page={page}
                  onPageChange={setPage}
                  pageSize={pageSize}
                  enhancedComments={enhancedComments}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  topComments={topComments.comments}
                  totalComments={topComments.totalComments}
                  searchWords={searchWords}
                  isNpsQuestion={question.code === BenchmarkCodeType.NPS_RECOMMEND_FOLLOWUP}
                  filterBy={filterBy}
                  setFilterBy={newFilterBy => {
                    setPage(0)
                    setFilterBy(newFilterBy)
                  }}
                  onSearchWord={word => {
                    gaEvent({
                      action: 'clickWordCloud',
                      category: 'Insights',
                    })
                    setPage(0)
                    setSearchWords([...searchWords, word.toLowerCase()])
                  }}
                  removeSearchWord={word => {
                    setPage(0)
                    setSearchWords(searchWords.filter(w => w !== word.toLowerCase()))
                  }}
                  clearSearchWords={() => {
                    setPage(0)
                    setSearchWords([])
                  }}
                />
              )
            }}
          </ResponseHandler>
        )
      }}
    </ResponseHandler>
  )
}

interface QCCPProps {
  survey: InsightsSurvey
  filters: string[]
  enhancedComments: boolean
  questionIndex: number
  question: Pick<FrozenQuestionType, 'uuid' | 'code' | 'text'>
  searchQuery: string
  defaultExpanded: boolean
}

const QuestionCommentsContainerPanel: React.FC<QCCPProps> = ({
  question,
  questionIndex,
  defaultExpanded,
  ...rest
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }
  const [expanded, setExpanded] = useState(defaultExpanded)
  return (
    <FormPanel
      expanded={expanded}
      gutterBottom={false}
      expandIcon={EXPAND_ICON.EXPAND}
      onExpandedEvent={() => setExpanded(true)}
      summary={
        <Typography className={classes.commentsQuestion} color="secondary">
          {questionIndex}. {question.text}
        </Typography>
      }
    >
      {expanded && <QuestionCommentsContainer question={question} {...rest} />}
    </FormPanel>
  )
}

const CommentsContainer: React.FC<InsightsTabProps> = ({
  survey,
  filters,
  solution,
  searchQuery,
}) => {
  const result = useInsightsOpenEndedQuestionsQuery({
    variables: { surveyUuid: survey.uuid, filters },
  })
  const { search } = useLocation()
  const { questionCode } = querystring.parse(search)
  return (
    <>
      <BreadcrumbsItem
        to={getInsightsPage(survey.uuid, InsightsModulesEnum.COMMENTS, survey.productType)}
      >
        Comments
      </BreadcrumbsItem>
      <ResponseHandler {...result}>
        {({ questions }) => {
          let allQuestions = questions
          if (!allQuestions.length) {
            return (
              <EmptyState
                title="No data"
                description="No data to display."
                Icon={ChartUpwardIcon}
              />
            )
          }
          // When comments is opened with a specific question code, place that question
          // at the top of the list.
          if (questionCode) {
            allQuestions = [
              ...questions.filter(q => q?.code === questionCode),
              ...questions.filter(q => q?.code !== questionCode),
            ]
          }
          return (
            <>
              {allQuestions.map((question, idx) => {
                if (!question) return <div key={idx} />
                return (
                  <QuestionCommentsContainerPanel
                    key={question.uuid}
                    question={question}
                    questionIndex={idx + 1}
                    survey={survey}
                    filters={filters}
                    enhancedComments={solution.insightsEnhancedComments}
                    searchQuery={searchQuery}
                    // Expand the first question by default
                    defaultExpanded={idx === 0}
                  />
                )
              })}
            </>
          )
        }}
      </ResponseHandler>
    </>
  )
}

export default withErrorHandler(CommentsContainer)
