import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  controls: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.common.navy25}`,
    padding: theme.spacing(1),
  },
  responseTypeSelect: {
    width: 150,
  },
  compareButton: {
    color: theme.palette.common.secondary,
  },
  selectionHeader: {
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 20,
    borderTop: `1px solid ${theme.palette.common.navy25}`,
  },
  selectionTitle: {
    textTransform: 'uppercase',
    fontSize: '1.5rem',
  },
  selectionSubtitle: {
    fontSize: '1.2rem',
  },
  selectionDeleteBadge: {
    backgroundColor: theme.palette.common.warning,
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: -15,
    width: 32,
    borderRadius: 18,
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.0rem',
    cursor: 'pointer',
    color: '#FFF',
    '&:hover': {
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    },
  },
}))
