import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'

import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import {
  InsightsGroupedScoreFragment,
  InsightsOverallScoreFragment,
  InsightsStatementScoreFragment,
} from 'generated/graphql'
import { formatScore } from 'utils'
import { CUSTOM_SURVEY_TYPES, ORDER_TYPES, PULSE_SURVEY_TYPES, SORT_OPTIONS } from 'utils/constants'
import { TimeTrendXAxisLabelData } from 'utils/echartsHelpers'
import { SurveyNode } from 'utils/types'

export type ScoreBySurvey = { [uuid: string]: number | null }
export type SurveyScoreByGroup = { [groupLabel: string]: ScoreBySurvey }

export const getValidTimeTrendingSurveys = (
  surveys: SurveyNode[],
  excludePulse: undefined | boolean = false,
) => {
  return surveys.filter(
    survey =>
      !survey.hasLessThanMinShowableResults &&
      !CUSTOM_SURVEY_TYPES.includes(survey.type) &&
      (!excludePulse || !PULSE_SURVEY_TYPES.includes(survey.type)),
  )
}

/**
 * overallScoreGroups returns a nested array with the structure:
 * [
 *  [[0, survey0.positiveScore], [1, survey1.positiveScore]...]
 *  [[0, survey0.inconsitentScore], [1, survey1.inconsitentScore]...]
 *  [[0, survey0.negativeScore], [1, survey1.negativeScore]...]
 * ]
 */
export type EchartsScore = [number, number | null]
export type ScoreTypeGroups = EchartsScore[][]
type OverallScore = Omit<InsightsOverallScoreFragment, '__typename'>
type TTOverallIndexData = Array<{ uuid: string } & { overallIndex: OverallScore }>
export const getOverallScoreGroups = <QueryData extends TTOverallIndexData, _>(
  data: QueryData,
  useRounding: boolean = true,
) => {
  const scoreTypeGroups: ScoreTypeGroups = []
  data.forEach(({ overallIndex: { positive, inconsistent, negative } }, surveyIndex) =>
    [positive, inconsistent, negative].forEach((score, typeIndex) => {
      if (!scoreTypeGroups[typeIndex]) {
        scoreTypeGroups[typeIndex] = []
      }
      scoreTypeGroups[typeIndex].push([surveyIndex, formatScore(score, useRounding)])
    }),
  )
  return scoreTypeGroups
}

export const getBenchmarkScore = (overallIndex: OverallScore) => ({
  positive: Math.round(overallIndex.benchmarkPositive!),
  inconsistent: Math.round(overallIndex.benchmarkInconsistent!),
  negative: Math.round(overallIndex.benchmarkNegative!),
})

export const getOrderedGroupNames = (
  groupNames: string[],
  surveyScoresByGroup: SurveyScoreByGroup,
  sort: SORT_OPTIONS,
  currentUuid: string,
) => {
  if (sort === SORT_OPTIONS.A_TO_Z) {
    return groupNames.sort()
  }
  const order = sort === SORT_OPTIONS.LOW_TO_HIGH ? ORDER_TYPES.ASCENDING : ORDER_TYPES.DESCENDING
  // Sort by group scores on the current survey. Count <minShowableResults responses at the bottom by marking as 0
  return orderBy(groupNames, name => surveyScoresByGroup[name][currentUuid] || 0, order)
}

type TTGroupedScoreData<QueryName extends string, _> = Array<
  { uuid: string } & { [queryName in QueryName]: InsightsGroupedScoreFragment[] }
>
export const groupScoresByLabel = <QueryName extends string, _>(
  data: TTGroupedScoreData<QueryName, _>,
  queryName: QueryName,
): SurveyScoreByGroup => {
  const resultMap: SurveyScoreByGroup = {}
  data.forEach(groupedScores => {
    groupedScores[queryName].forEach(({ label, positive }) => {
      if (!label) return
      if (!resultMap[label]) {
        resultMap[label] = {} as ScoreBySurvey
      }
      resultMap[label][groupedScores.uuid] = formatScore(positive, false)
    })
  })
  return resultMap
}

/**
 * Returns score groups of the format: [
 *  [[survey0, location1.score], [survey0, location2.score]]
 *  [[survey1, location1.score], [survey1, location2.score]]
 * ]
 */
export const transformGroupedScoresToEcharts = (
  orderedGroupNames: string[],
  surveyScoresByGroup: SurveyScoreByGroup,
  uuids: string[],
): ScoreTypeGroups => {
  return orderedGroupNames.map(filterValue => {
    // Loop over every survey so we can add the "null" value for surveys without the filterValue
    return uuids.map((uuid, surveyIndex) => {
      const value = surveyScoresByGroup[filterValue][uuid]
      return [surveyIndex, isNil(value) ? null : Math.round(value)]
    })
  })
}

type TTStatementData = Array<{ uuid: string } & { statements: InsightsStatementScoreFragment[] }>
export type StatementData = {
  companyOverallScores: EchartsScore[] // positive
  inconsistentScores: EchartsScore[]
  negativeScores: EchartsScore[]
  benchmarkPositive?: number | null
  label: string
  focus: string
  code: string
  delta: number | null
}
type StatementByCode = { [code: string]: StatementData }

export const getDelta = (scoreGroup: StatementData) => {
  // Ignore scores with <minShowableResults responses.
  const scoreValues = scoreGroup.companyOverallScores
    .filter(score => score[1] !== null)
    .map(score => score[1] as number)
  return scoreValues[scoreValues.length - 1] - scoreValues[0] || 0
}

// Find the relevant statements across surveys in the results.
export const getStatementsList = (
  results: TTStatementData,
  selectedSurveys: Array<SurveyNode | InsightsSurvey>,
  searchQuery?: string,
) => {
  let filteredResults = results
  const pulseSurveyUuids = selectedSurveys
    .filter(s => PULSE_SURVEY_TYPES.includes(s.type))
    .map(s => s.uuid)
  if (pulseSurveyUuids.length) {
    // When time trending across pulse surveys, only show the statements included on the pulse surveys.
    filteredResults = results.filter(r => pulseSurveyUuids.includes(r.uuid))
  }
  // It is possible for surveys to have different statements.
  // Maintain a list of all the unique statements that appear.
  const statements = uniqBy(
    filteredResults.flatMap(r => r.statements),
    'code',
  )
  if (searchQuery) {
    return statements.filter(s => s.label.toUpperCase().includes(searchQuery.toUpperCase()))
  }
  return statements
}

/**
 * Function to build a list of score data across surveys for each statement provided.
 * Example:
 *   statements: [{ code: s1 }]
 *   results: [{ survey1, { code: s1, score: 1 }, { survey2, { code: s1, score: 3 }}]
 *   returns => [{ companyOverallScores: [[0, 1], [1, 3]], delta: 2 }]
 */
export const transformTimeTrendingStatementsToEchartScores = ({
  results,
  statements,
  order,
}: {
  results: TTStatementData
  statements: InsightsStatementScoreFragment[]
  order?: ORDER_TYPES
}) => {
  const statementByCode: StatementByCode = {}
  statements.forEach(({ code, label, focus, benchmarkPositive }, index) => {
    // Build a unique key since codes can repeat across statements that appear on multiple surveys
    const key = code + index
    statementByCode[key] = {
      companyOverallScores: [], // Positive
      inconsistentScores: [],
      negativeScores: [],
      benchmarkPositive: benchmarkPositive && Math.round(benchmarkPositive),
      label,
      focus: focus || 'OTHER',
      code,
      delta: null,
    }
    // Loop over every survey to add "null" for surveys without a particular statement.
    results.forEach((result, surveyIndex) => {
      const statement = result.statements.find(s => s.code === code)
      const positiveScore = formatScore(statement?.positive)
      statementByCode[key].companyOverallScores.push([surveyIndex, positiveScore])
      const inconsistentScore = formatScore(statement?.inconsistent)
      statementByCode[key].inconsistentScores.push([surveyIndex, inconsistentScore])
      const negativeScores = formatScore(statement?.negative)
      statementByCode[key].negativeScores.push([surveyIndex, negativeScores])
    })
    statementByCode[key].delta = getDelta(statementByCode[key])
  })
  return orderBy(Object.values(statementByCode), s => s.delta, order)
}

export const getTimeTrendingScoreDelta = (
  currentScoreUuid: string,
  scores: { [uuid: string]: number | null },
  xAxisLabelData: TimeTrendXAxisLabelData,
) => {
  const currentIndex = xAxisLabelData.findIndex(data => data.uuid === currentScoreUuid)
  if (currentIndex === 0) {
    return null
  }
  const previousScoreUuid = xAxisLabelData[currentIndex - 1].uuid
  if (isNil(scores[currentScoreUuid]) || isNil(scores[previousScoreUuid])) {
    return null
  }
  return scores[currentScoreUuid]! - scores[previousScoreUuid]!
}
