import React, { ReactElement } from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import Button from 'components/Blocks/CustomButtons/Button'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import ScatterPlot, {
  QuadrantLabel,
  ScatterQuadrants,
  ScatterTooltipArgs,
} from 'components/Insights/TimeTrending/ScatterPlot'
import { SurveyScoreByGroup } from 'components/Insights/TimeTrending/utils'
import {
  InsightsScoresByDataTypeHierarchyQuery,
  InsightsScoresByDataTypeQuery,
  OverallScoreType,
  SurveyCoreFragment,
  useInsightsScoresByDataTypeHierarchyQuery,
  useInsightsScoresByDataTypeQuery,
  FilterTypeFragment,
} from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import { formatScore, randomNumberInRange } from 'utils'

export enum CardViewType {
  SCATTER = 'Scatter Chart',
  TABLE = 'Table View',
}

const useStyles = makeStyles(({ spacing }) => ({
  emptyStateTableViewButton: {
    margin: 0,
    padding: 0,
    paddingBottom: 2,
  },
  quadrantTooltip: {
    padding: spacing(1),
  },
  quadrantTooltipRow: {
    display: 'flex',
    alignItems: 'center',
    '& >span': {
      width: 150,
    },
    paddingTop: spacing(2),
  },
}))

export const QuadrantTooltip: React.FC<{
  title: string
  quadrantTitles: ScatterQuadrants
  quadrantDescriptions: ScatterQuadrants
}> = ({ title, quadrantTitles, quadrantDescriptions }) => {
  const classes = useStyles()
  return (
    <div className={classes.quadrantTooltip}>
      <Typography variant="body2">{title}</Typography>
      <div className={classes.quadrantTooltipRow}>
        <QuadrantLabel quadrantTitles={quadrantTitles} activeQuadrant="TOP_RIGHT" />
        <Typography variant="body2" color="textSecondary">
          &nbsp;- {quadrantDescriptions.TOP_RIGHT}{' '}
        </Typography>
      </div>
      <div className={classes.quadrantTooltipRow}>
        <QuadrantLabel quadrantTitles={quadrantTitles} activeQuadrant="TOP_LEFT" />
        <Typography variant="body2" color="textSecondary">
          &nbsp;- {quadrantDescriptions.TOP_LEFT}{' '}
        </Typography>
      </div>
      <div className={classes.quadrantTooltipRow}>
        <QuadrantLabel quadrantTitles={quadrantTitles} activeQuadrant="BOTTOM_RIGHT" />
        <Typography variant="body2" color="textSecondary">
          &nbsp;- {quadrantDescriptions.BOTTOM_RIGHT}{' '}
        </Typography>
      </div>
      <div className={classes.quadrantTooltipRow}>
        <QuadrantLabel quadrantTitles={quadrantTitles} activeQuadrant="BOTTOM_LEFT" />
        <Typography variant="body2" color="textSecondary">
          &nbsp;- {quadrantDescriptions.BOTTOM_LEFT}{' '}
        </Typography>
      </div>
    </div>
  )
}

/**
 * In order to create even quadrants on the scatter plot that include all the data points,
 * we find the max difference from the center line in both directions and set that as min/max
 * limit on both sides for each axis. With a minimum distance of 5%.
 */
export const getMaxFromScores = (data: ScoresType, xAxisCenter: number, yAxisCenter: number) => {
  let maxXAxisScoreDiff = -Infinity
  let maxYAxisScoreDiff = -Infinity
  data.forEach(groupedData => {
    groupedData.data.forEach(({ value }) => {
      maxXAxisScoreDiff = Math.max(maxXAxisScoreDiff, Math.abs(xAxisCenter - value[0]))
      maxYAxisScoreDiff = Math.max(maxYAxisScoreDiff, Math.abs(yAxisCenter - value[1]))
    })
  })
  return {
    maxXAxisScoreDiff: Math.max(maxXAxisScoreDiff, 5),
    maxYAxisScoreDiff: Math.max(maxYAxisScoreDiff, 5),
  }
}

/**
 * When the data for scatter plots contains dots that are overlapping (same x, y value),
 * we want to subtly adjust the position of the points so that the user can see and hover
 * over all of the points.
 *
 * The points should
 * 1. Be far enough away from each that the user can hover over them.
 *    - Choose an increment to displace the point that's relative to the size of the graph.
 * 2. Be close enough to the original point that it doesn't significantly alter the graph.
 *    - Achieve this by limiting the displacement to 3 increments (+ / -) away from the original point.
 *      This gives a total of 7x7 possible locations (counting 0).
 */
export const jitterData = (
  data: ScoresType,
  maxXDiff: number,
  maxYDiff: number,
  maxIncrements: number | undefined = 3,
) => {
  // Keep track of positions we are displacing so we don't use the same coordinate
  const positionsMemo: { [coordinateHash: string]: [number, number] } = {}
  // `maxDiff` is half the length of the graph, so this calc gives an increment at 1/100 of the graph.
  const xIncrement = maxXDiff / 50
  const yIncrement = maxYDiff / 50
  const getJitteredVal = (val: number, increment: number) => {
    const displaceNum = randomNumberInRange(-maxIncrements, maxIncrements)
    return val + displaceNum * increment
  }
  return data.map(scores => {
    return {
      ...scores,
      data: scores.data.map(row => {
        let key = row.value.join('|')
        // To give an equal distribution, randomly displace values until we find one that hasn't been taken.
        // It's unlikely that we'll have too many collisions, but to prevent entering an infinite loop
        // if all the positions are occupied, just return the original value if we've tried 100 times.
        let displacedValue = row.value
        let attempts = 0
        while (positionsMemo[key]) {
          if (attempts === 100) {
            return row
          }
          attempts += 1
          const [x, y] = displacedValue
          displacedValue = [getJitteredVal(x, xIncrement), getJitteredVal(y, yIncrement)]
          key = displacedValue.join('|')
        }
        positionsMemo[key] = displacedValue
        return {
          ...row,
          value: displacedValue,
          // Maintain the original value so we can display it accurately on the tooltip.
          originalValue: row.value,
        }
      }),
    }
  })
}

export type HierarchyScoresByGroup = { [level1Key: string]: SurveyScoreByGroup }
/**
 * Transform hierarchy to structure
 * {
 *    [Location1]: {
 *      [Department1]: {
 *        [Survey1]: 40,
 *        [Survey2]: 50
 *       }
 *    }
 * }
 */
export const transformMultiIndexDataToGroupedSurveyScores = (
  data: Array<{ uuid: string } & InsightsScoresByDataTypeHierarchyQuery>,
  selectedSurveyUuids: string[],
): HierarchyScoresByGroup => {
  const resultMap: HierarchyScoresByGroup = {}
  data.forEach(({ insightsScoresByDataTypeHierarchy, uuid: surveyUuid }) => {
    const hierarchyScores = JSON.parse(insightsScoresByDataTypeHierarchy) as OverallScoreType[]
    hierarchyScores.forEach(({ groupHierarchy, positive }) => {
      if (!groupHierarchy) return
      const [level1DataType, level2DataType] = groupHierarchy
      if (!level1DataType || !level2DataType) return
      if (!resultMap[level1DataType]) {
        resultMap[level1DataType] = {} as SurveyScoreByGroup
      }
      if (!resultMap[level1DataType][level2DataType]) {
        const defaultScoreBySurvey = selectedSurveyUuids.reduce(
          (acc, uuid) => ({ ...acc, [uuid]: null }),
          {},
        )
        resultMap[level1DataType][level2DataType] = defaultScoreBySurvey
      }
      resultMap[level1DataType][level2DataType][surveyUuid] = formatScore(positive)
    })
  })
  return resultMap
}

export const transformSingleIndexDataToGroupedSurveyScores = (
  data: Array<{ uuid: string } & InsightsScoresByDataTypeQuery>,
  selectedSurveyUuids: string[],
): HierarchyScoresByGroup => {
  const resultMap: HierarchyScoresByGroup = {}
  data.forEach(({ insightsScoresByDataType, uuid: surveyUuid }) => {
    insightsScoresByDataType.forEach(({ positive, label }) => {
      if (!label) return
      // To match the multi index structure, just use the same filter value label for both levels.
      if (!resultMap[label]) {
        const defaultScoreBySurvey = selectedSurveyUuids.reduce(
          (acc, uuid) => ({ ...acc, [uuid]: null }),
          {},
        )
        resultMap[label] = { [label]: defaultScoreBySurvey }
      }
      resultMap[label][label][surveyUuid] = formatScore(positive)
    })
  })
  return resultMap
}

export type ChartData = {
  name: string
  value: [number, number]
}
export type ScoresType = Array<{
  name: string
  data: Array<ChartData>
}>

type HProps = {
  level1FilterType: FilterTypeFragment | null
  level2FilterType: FilterTypeFragment
  selectedSurveys: SurveyCoreFragment[]
  filters: string[]
  children: (data: { surveyScoresByGroup: HierarchyScoresByGroup }) => ReactElement
  pulseKeyStatementCode?: string
  includesPulse?: boolean
}
export const ScatterDataHandler: React.FC<HProps> = ({
  level1FilterType,
  level2FilterType,
  pulseKeyStatementCode,
  selectedSurveys,
  filters,
  children,
  includesPulse = false,
}) => {
  const statementCodes = includesPulse && pulseKeyStatementCode ? [pulseKeyStatementCode] : null
  const selectedSurveyUuids = selectedSurveys.map(s => s.uuid)
  if (!level1FilterType) {
    return (
      <DynamicSurveyQuery
        surveys={selectedSurveys}
        variables={{
          filters,
          dtCode: level2FilterType.dtCode,
          statementCodes,
        }}
        queryHook={useInsightsScoresByDataTypeQuery}
      >
        {data => {
          return children({
            surveyScoresByGroup: transformSingleIndexDataToGroupedSurveyScores(
              data,
              selectedSurveyUuids,
            ),
          })
        }}
      </DynamicSurveyQuery>
    )
  }
  return (
    <DynamicSurveyQuery
      surveys={selectedSurveys}
      variables={{
        filters,
        dtCodeHierarchy: [level1FilterType.dtCode, level2FilterType.dtCode],
        statementCodes,
      }}
      queryHook={useInsightsScoresByDataTypeHierarchyQuery}
    >
      {data => {
        return children({
          surveyScoresByGroup: transformMultiIndexDataToGroupedSurveyScores(
            data,
            selectedSurveyUuids,
          ),
        })
      }}
    </DynamicSurveyQuery>
  )
}

type Props = {
  surveyScoresByGroup: HierarchyScoresByGroup
  setSelectedViewType(arg: CardViewType): void
  scatterPlotTransformFn(surveyScoresByGroup: HierarchyScoresByGroup): ScoresType
  scatterPlotProps: {
    yAxisTitle: string
    xAxisTitle: string
    yAxisCenter: number
    xAxisCenter: number
    yAxisAllowLabelsOutOfBounds?: boolean
    showYAxisCenterLine?: boolean
    useAxisLineDetail?: boolean
    quadrantTitles: ScatterQuadrants
    renderTooltip(_: ScatterTooltipArgs): ReactElement
  }
}
const ScatterPlotContainer: React.FC<Props> = ({
  surveyScoresByGroup,
  setSelectedViewType,
  scatterPlotTransformFn,
  scatterPlotProps,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const chartScores = scatterPlotTransformFn(surveyScoresByGroup)
  const { maxXAxisScoreDiff, maxYAxisScoreDiff } = getMaxFromScores(
    chartScores,
    scatterPlotProps.xAxisCenter,
    scatterPlotProps.yAxisCenter,
  )
  const adjustedScores = jitterData(chartScores, maxXAxisScoreDiff, maxYAxisScoreDiff)
  if (!chartScores.some(scores => scores.data.length > 0)) {
    return (
      <EmptyState
        title="Oh Snap!"
        description={
          <>
            There isn’t enough data to show this chart. Use the{' '}
            <Button
              color="secondaryNoBackground"
              onClick={() => setSelectedViewType(CardViewType.TABLE)}
              className={classes.emptyStateTableViewButton}
            >
              Table View
            </Button>{' '}
            to see available information.
          </>
        }
        Icon={ChartUpwardIcon}
      />
    )
  }
  return (
    <ScatterPlot
      chartScores={adjustedScores}
      legendNames={chartScores.map(score => score.name)}
      maxXAxisScoreDiff={maxXAxisScoreDiff}
      maxYAxisScoreDiff={maxYAxisScoreDiff}
      {...scatterPlotProps}
    />
  )
}

export default ScatterPlotContainer
