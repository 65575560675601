import React from 'react'

import { makeStyles, Button as MUIBUtton, CircularProgress } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import cn from 'classnames'

const useStyles = makeStyles(({ palette, shadows, spacing }) => ({
  button: {
    margin: `0 ${spacing(1)}px`,
    whiteSpace: 'nowrap',
    boxShadow: shadows[2],
    minWidth: 'auto',
    minHeight: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  wd: {
    minWidth: 160,
  },
  lg: {
    fontSize: '1.8rem',
    padding: `${spacing(2)}px ${spacing(3)}px`,
  },
  md: {
    fontSize: '1.6rem',
    padding: `${spacing(1)}px ${spacing(2)}px`,
  },
  sm: {
    fontSize: '1.4rem',
    padding: `${spacing(0.5)}px ${spacing(1)}px`,
  },
  xs: {
    fontSize: '1rem',
  },
  primary: {
    backgroundColor: palette.common.primary,
    '&:hover': {
      backgroundColor: palette.common.primary,
    },
  },
  secondary: {
    backgroundColor: palette.common.secondary,
    color: palette.common.white,
    '&:hover': {
      backgroundColor: palette.common.secondary,
    },
  },
  success: {
    backgroundColor: palette.common.success,
    color: palette.common.white,
    '&:hover': {
      backgroundColor: palette.common.success,
    },
  },
  warning: {
    backgroundColor: palette.common.warning,
    color: palette.common.white,
    '&:hover': {
      backgroundColor: palette.common.warning,
    },
  },
  danger: {
    backgroundColor: palette.common.danger,
    color: palette.common.white,
    '&:hover,&:focus': {
      backgroundColor: palette.common.danger,
    },
    '&': {
      color: palette.common.white,
      backgroundColor: palette.common.danger,
    },
  },
  primaryNoBackground: {
    '&,&:hover,&:focus': {
      color: palette.common.navy,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  secondaryNoBackground: {
    '&,&:hover,&:focus': {
      color: palette.common.secondary,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  successNoBackground: {
    '&,&:hover,&:focus': {
      color: palette.common.success,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  warningNoBackground: {
    '&,&:hover,&:focus': {
      color: palette.common.warning,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  dangerNoBackground: {
    '&,&:hover,&:focus': {
      color: palette.common.danger,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  textSecondaryNoBackground: {
    '&,&:hover,&:focus': {
      color: palette.common.navy65,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  transparent: {
    '&,&:focus,&:hover': {
      color: 'inherit',
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  greyed: {
    color: '#CCC !important',
  },
  round: {
    borderRadius: 30,
  },
  disabled: {
    opacity: '0.65',
    pointerEvents: 'none',
  },
  right: {
    float: 'right',
  },
  noMargins: {
    margin: -12, // Use negative margin to maintain padding for the hover.
  },
}))

interface Props extends Pick<ButtonProps, Exclude<keyof ButtonProps, 'color' | 'size'>> {
  children: any
  color?: keyof ReturnType<typeof useStyles>
  round?: boolean
  fullWidth?: boolean
  disabled?: boolean
  right?: boolean
  size?: keyof ReturnType<typeof useStyles>
  wd?: boolean
  className?: string
  isSubmitting?: boolean
  autoFocus?: boolean
  noMargins?: boolean
}

const Button: React.FC<Props> = React.forwardRef(
  (
    {
      round = false,
      fullWidth = false,
      disabled = false,
      right = false,
      wd = false,
      className = '',
      color = 'primary',
      size = 'md',
      noMargins = false,
      children,
      isSubmitting,
      ...rest
    },
    ref,
  ) => {
    const classes = useStyles()
    const btnClasses = cn(classes.button, className, {
      [classes.primary]: color,
      [classes[size as keyof Pick<ReturnType<typeof useStyles>, 'xs' | 'sm' | 'md' | 'lg'>]]: size,
      [classes[
        color as keyof Pick<
          ReturnType<typeof useStyles>,
          | 'primary'
          | 'secondary'
          | 'success'
          | 'danger'
          | 'primaryNoBackground'
          | 'secondaryNoBackground'
          | 'successNoBackground'
          | 'dangerNoBackground'
          | 'textSecondaryNoBackground'
          | 'transparent'
        >
      ]]: color,
      [classes.round]: round,
      [classes.noMargins]: noMargins,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.right]: right,
      [classes.wd]: wd,
    })
    return (
      <MUIBUtton {...rest} disabled={disabled} innerRef={ref} className={btnClasses}>
        {isSubmitting ? <CircularProgress size={19} color="secondary" /> : children}
      </MUIBUtton>
    )
  },
)

export default Button
