import React from 'react'

import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import { colors } from 'shared/theme'
import { INSIGHTS_COLORS } from 'utils/constants'

const useStyles = makeStyles(({ spacing }) => ({
  chart: {
    '& >div': {
      top: '25%',
    },
    marginRight: spacing(2),
  },
}))
const OverallIndexGuage = ({
  height,
  width,
  lineWidth = 30,
  positive,
  inconsistent,
  empty,
  chartColors = INSIGHTS_COLORS,
}) => {
  const classes = useStyles()
  return (
    <PrintableEchart
      printWidth={150}
      printHeight={height}
      notMerge
      style={{
        height,
        width,
      }}
      className={classes.chart}
      option={{
        color: chartColors,
        series: [
          {
            type: 'gauge',
            name: 'Trust Index Score',
            radius: '100%',
            startAngle: 155,
            endAngle: 25,
            axisLine: {
              lineStyle: {
                width: lineWidth,
                color: [
                  [positive / 100, chartColors[0]],
                  [(positive + inconsistent) / 100, chartColors[1]],
                  [
                    1,
                    empty || (positive === 0 && inconsistent === 0)
                      ? colors.iceGrey
                      : chartColors[2],
                  ],
                ],
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: !empty,
              width: 3,
            },
            itemStyle: {
              color: 'rgba(15, 50, 70, 0.25)',
            },
            title: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [{ value: positive, name: 'Positive' }],
          },
        ],
      }}
    />
  )
}

OverallIndexGuage.defaultProps = {
  positive: null,
  inconsistent: null,
  empty: false,
}

OverallIndexGuage.propTypes = {
  positive: PropTypes.number,
  inconsistent: PropTypes.number,
  height: PropTypes.number.isRequired,
  empty: PropTypes.bool,
}

export default OverallIndexGuage
