import React, { useState } from 'react'

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  IconButton,
} from '@material-ui/core'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import cn from 'classnames'

import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import { gaEvent } from 'config/ga'
import { useShareScreenshotMutation } from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'
import saveChartToPNG from 'utils/saveChartToPNG'

const useStyles = makeStyles(theme => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  icon: {
    color: theme.palette.common.navy65,
  },
  snapImg: {
    maxWidth: 552,
    marginTop: theme.spacing(3),
  },
}))

type Props = {
  title?: string
  strategy?: 'canvas' | 'svg' | 'auto' | 'html2canvas'
  snapId: string
  extraClasses?: { root: string }
}
const Component: React.FC<Props> = ({ title, strategy = 'auto', snapId, extraClasses }) => {
  const [shareScreenshot] = useShareScreenshotMutation()
  const [email, setEmail] = useState('')
  const [note, setNote] = useState('')
  const [image, setImage] = useState<null | string>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const createSnapshot = async () => {
    setImage(await saveChartToPNG(strategy, snapId))
  }
  const handleSubmit = async () => {
    if (!image) return
    await shareScreenshot({
      variables: { email, note, image, currentUrl: window.location.href },
    })
    setEmail('')
    setNote('')
    setImage('')
    setDialogOpen(false)
    emitter.emit('SUCCESS', `Screenshot has been shared with ${email}`)
  }
  const openScreenshotDialog = async () => {
    await createSnapshot()
    setDialogOpen(true)
  }
  const classes = useStyles()
  return (
    <div className={cn(classes.root, extraClasses?.root)}>
      <IconButton
        className={classes.icon}
        id={`screenshot-${snapId}`}
        onClick={() => {
          gaEvent({
            action: `screenshot-${snapId}`,
            category: 'Global',
          })
          openScreenshotDialog()
        }}
        color="primary"
      >
        <CameraAltIcon />
        {title}
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Share Insights</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To share a snapshot of this chart simply provide an email address and an optional short
            note.
          </DialogContentText>
          <TextField
            autoFocus
            id="name"
            label="Email Address"
            type="email"
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            fullWidth
          />
          <TextField
            id="note"
            label="Add an optional short note"
            type="text"
            value={note}
            onChange={({ target: { value } }) => setNote(value)}
            fullWidth
          />
          {image && <img className={classes.snapImg} alt="screenshot" src={image} />}
        </DialogContent>
        <ActionDialogButtons
          onSubmit={handleSubmit}
          onClose={() => setDialogOpen(false)}
          submitDisabled={!email || !image}
          submitButtonText="Share Screenshot"
        />
      </Dialog>
    </div>
  )
}

export default Component
