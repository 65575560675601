import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import cn from 'classnames'

import DashedBlueLine from 'assets/img/echarts/dashed-blue-line.png'
import useInsightsStyles from 'components/Insights/InsightsStyle'

const useStyles = makeStyles(theme => ({
  legend: {
    height: 45,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    '& >p': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      '& >img': {
        marginRight: theme.spacing(1),
      },
    },
  },
  currentScoreDot: {
    backgroundColor: theme.palette.common.success,
  },
  growthScoreDot: {
    backgroundColor: theme.palette.common.tertiarySuccess,
  },
}))

const KeyFocusAreasLegend: React.FC = () => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <div className={classes.legend}>
      <Typography variant="body2" color="textSecondary">
        <span className={cn(classes.dot, classes.currentScoreDot)} /> Current Scores
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <span className={cn(classes.dot, classes.growthScoreDot)} /> Growth Potential
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <img width={20} height={15} src={DashedBlueLine} alt="- - -" /> Target Score
      </Typography>
    </div>
  )
}

export default KeyFocusAreasLegend
