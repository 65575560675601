import React from 'react'

import { ReactComponent as MinShowableResultsIcon } from 'assets/img/min-showable-results.svg'
import { ReactComponent as NoAccessibleInsightsIcon } from 'assets/img/no_closed_surveys.svg'
import { ReactComponent as ZeroResultsIcon } from 'assets/img/zero-results-icon.svg'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import { SurveyProductTypeEnum } from 'generated/graphql'
import { CONTACT_EMAIL, PRODUCT_TYPE_LABELS } from 'utils/constants'

type Props = {
  numParticipants: number
  isUsingFilters: boolean
  productType: SurveyProductTypeEnum
  minShowableResults: number
  hasConfidentialResults: boolean
}
const LessThanMinShowableResults: React.FC<Props> = ({
  numParticipants,
  isUsingFilters,
  productType,
  minShowableResults,
  hasConfidentialResults,
}) => {
  let title = ''
  let description
  let icon
  if (hasConfidentialResults) {
    icon = MinShowableResultsIcon
    if (isUsingFilters) {
      title = 'Please change your filters'
      description = `The applied filters show results for less than ${minShowableResults}
          ${PRODUCT_TYPE_LABELS[productType].toLowerCase()}s.
          We can't show their responses to protect confidentiality.
          Remove one of your filters to see results.`
    } else if (numParticipants === 0) {
      title = `Unlock ${PRODUCT_TYPE_LABELS[productType].toLowerCase()} survey insights!`
      description = `To learn more about obtaining access to
          ${PRODUCT_TYPE_LABELS[productType].toLowerCase()}
          survey insights, contact ${CONTACT_EMAIL}.`
      icon = NoAccessibleInsightsIcon
    } else {
      title = `This survey has less than ${minShowableResults} responses`
      description = `We can't show their responses to protect confidentiality.
          Please select a different survey.`
    }
  } else {
    icon = ZeroResultsIcon
    description = 'No responses received'
    if (isUsingFilters) {
      description += ' during this period from your selected filters'
    }
    description += '.'
  }
  return <EmptyState title={title} description={description} Icon={icon} />
}

export default LessThanMinShowableResults
