import React from 'react'

import Radio from '@material-ui/core/Radio'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'

import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'

const styles = {
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    '& >span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}

const PopoverRadioButtonGroup = ({
  classes,
  activeOption,
  items,
  getItemValue,
  getItemLabel,
  getItemDisabledMessage,
  groupId,
  onSelect,
}) => {
  return items.map((item, idx) => (
    <div key={`${groupId}${idx}`} style={{ display: 'flex' }}>
      <Tooltip key={getItemValue(item)} title={getItemDisabledMessage(item)}>
        <FormControlLabel
          className={classes.itemRow}
          value={getItemValue(item)}
          disabled={!!getItemDisabledMessage(item)}
          control={
            <Radio
              checked={getItemValue(activeOption) === getItemValue(item)}
              onChange={() => onSelect(item)}
            />
          }
          label={getItemLabel(item)}
        />
      </Tooltip>
    </div>
  ))
}

PopoverRadioButtonGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      code: PropTypes.string,
    }),
  ).isRequired,
  classes: PropTypes.object.isRequired,
  getItemValue: PropTypes.func.isRequired,
  getItemLabel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
}

export default withStyles(styles)(PopoverRadioButtonGroup)
