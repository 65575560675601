import { SUPPORT_EMAIL } from 'utils/constants'

export const ERROR_CODES: { [errorCode: string]: string } = {
  USER_DOES_NOT_EXIST_OR_WRONG_PASSWORD:
    'A user with this email does not exist or the password is wrong',
  USER_IS_IN_RESET_PROCESS:
    "You're in the process of resetting your password. Please follow the link in the email or reset your password again to receive a new password reset link.",
  USER_HAS_NOT_SET_INITIAL_PASSWORD:
    'Please first set your password from the invitation link you received via email.',
  USER_NOT_FOUND: 'User does not exist',
  USER_INACTIVE: 'This user is not active',
  USER_EMAIL_ALREADY_EXISTS:
    'A user with this email address already exists. Please use a new email address to create a new user.',
  USER_CAN_NOT_BE_AI_STAFF: "AI staff can't login to product.",
  USER_BY_EMAIL_NOT_FOUND: `Email not found. Try another email address or contact
    your account administrator for assistance.`,
  ORGANIZATION_CONFIG_ALREADY_EXISTS: 'Please make sure each custom label is unique.',
  QUESTION_INVALID_CHOICES: 'Please add at least 2 answer options.',
  LOCATION_NAME_ALREADY_EXISTS:
    'A location with this name already exists. Please enter a new location name.',
  SURVEY_NAME_ALREADY_EXISTS:
    'A survey with this name already exists. Please enter a new survey name.',
  SURVEY_REPORT_PASSWORD_REQUIRED: 'Please type a password',
  SURVEY_REPORT_PASSWORD_INCORRECT: 'Wrong password, please try again',
  DUPLICATE_SURVEY_CODE:
    'The survey code you provided is already associated with a participant on this survey.',
  ORGANIZATION_EXISTING_SLUG:
    'This unique survey URL has already been used. Please try another one',
  INVALID_GOOGLE_PLACE_ID: `
      Invalid Google Place ID. If you believe this is an error, please contact ${SUPPORT_EMAIL}`,
  INVALID_PHONE_NUMBER: `Invalid Phone Number. Please enter a valid phone number.`,
  FILTER_VALUE_ALREADY_EXISTS: 'A value with this name already exists',
  FILTER_VALUE_NAME_REQUIRED: 'A name is required in order to save this filter value.',
  FILTER_VALUE_STATE_REQUIRED: 'A state is required in order to save this filter value.',
  FILTER_VALUE_ZIPCODE_REQUIRED: 'A zipcode is required in order to save this filter value.',
  FILTER_VALUE_ZIPCODE_FORMAT: 'Must be a valid zipcode.',
}

export const SERVER_ERROR = 'There was an unexpected error. Please try again later'
