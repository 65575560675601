import React, { useState } from 'react'

import { Collapse, ListItem, ListItemText } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import IconButton from 'components/Blocks/CustomButtons/IconButton'

type Props = {
  text: string
}

const ExpandableListItem: React.FC<Props> = ({ text, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <ListItem onClick={() => setIsOpen(!isOpen)}>
        <ListItemText inset primary={text} />
        <IconButton color="secondaryHover">
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  )
}

export default ExpandableListItem
