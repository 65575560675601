import React from 'react'

import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import * as Sentry from '@sentry/react'

import Button from 'components/Blocks/CustomButtons/Button'
import { resetStore } from 'config/LocalStorage'
import { ERROR_MESSAGE, SUPPORT_EMAIL } from 'utils/constants'

const FallbackComponent: React.FC = () => {
  const refreshApp = () => {
    resetStore()
    window.location.reload()
  }

  return (
    <Typography variant="body2" style={{ padding: 20, display: 'flex', alignItems: 'center' }}>
      <ErrorIcon color="error" style={{ paddingRight: 4 }} />
      {ERROR_MESSAGE} If you need to access this page right away try&nbsp;
      <Button
        noMargins
        color="secondaryNoBackground"
        style={{ fontSize: '1.4rem', textTransform: 'lowercase' }}
        onClick={refreshApp}
      >
        refreshing
      </Button>
      &nbsp;or
      <a href={`mailto:${SUPPORT_EMAIL}`}>&nbsp;let us know.</a>
    </Typography>
  )
}

const fallBack = <FallbackComponent />

// https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
export default function withErrorHandler<Props extends Object, _>(
  Component: React.ComponentType<Props>,
): React.FC<Props> {
  function withHandler(props: Props) {
    return (
      <Sentry.ErrorBoundary fallback={fallBack}>
        <Component {...props} />
      </Sentry.ErrorBoundary>
    )
  }

  return withHandler
}
