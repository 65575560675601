import React from 'react'

import { useParams } from 'react-router-dom'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import WizardContainer from 'components/Survey/WizardContainer'
import { useSurveysSurveyQuery, SurveyTypeEnum } from 'generated/graphql'
import { SURVEY_TYPES_TO_PRODUCT_TYPE } from 'utils/constants'

type Props = {
  surveyType: SurveyTypeEnum
}
const WizardRouter: React.FC<Props> = ({ surveyType }) => {
  const surveyUuid = useParams<{ uuid: string }>().uuid
  const skip = !surveyUuid
  const result = useSurveysSurveyQuery({ variables: { uuid: surveyUuid }, skip })

  if (skip) {
    return (
      <WizardContainer
        survey={null}
        surveyType={surveyType}
        productType={SURVEY_TYPES_TO_PRODUCT_TYPE[surveyType]}
      />
    )
  }
  return (
    <ResponseHandler {...result}>
      {({ survey }) => {
        return (
          <WizardContainer
            survey={survey}
            surveyType={survey?.type || surveyType}
            productType={survey?.productType}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default WizardRouter
