import React, { useEffect, useState } from 'react'

import { Badge, Typography } from '@material-ui/core'
import flatten from 'lodash/flatten'

import Button from 'components/Blocks/CustomButtons/Button'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import GroupedSelect from 'components/Insights/Blocks/GroupedSelect'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import StatementsContainer from 'components/Insights/Statements/StatementsContainer'
import useStatementsStyles from 'components/Insights/Statements/StatementsStyle'
import { gaEvent } from 'config/ga'
import { useInsightsSurveysFilterValuesQuery } from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import { toggleListWithAllType } from 'utils'
import { RESPONSE_TYPES as RT } from 'utils/constants'

export const getGroupedFilters = (
  user: InsightsTabProps['currentUser'],
  filterValueNames: string[],
) => {
  return user.filters.flatMap(f =>
    f.filterValues
      .filter(v => filterValueNames.includes(v.name))
      .map(value => ({ group: f.name, ...value })),
  )
}

const StatementsControlsWrapper: React.FC<InsightsTabProps> = ({ searchParams, ...restProps }) => {
  const filterValuesResult = useInsightsSurveysFilterValuesQuery({
    variables: {
      surveyUuids: [restProps.survey.uuid],
      filters: restProps.filters,
    },
  })
  const classes = { ...useInsightsStyles(), ...useStatementsStyles() }
  const [selectedResponseTypes, setSelectedResponseTypes] = useState<RT[]>([])
  const [selectedComparisonFilters, setSelectedComparisonFilters] = useState<string[]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const maxComparisons = 8

  useEffect(() => {
    const responseTypes: RT[] = []
    if (searchParams.responseTypes && !Array.isArray(searchParams.responseTypes)) {
      ;(searchParams.responseTypes as string).split(',').forEach(l => responseTypes.push(l as RT))
    } else {
      responseTypes.push(RT.all)
    }
    setSelectedResponseTypes(responseTypes)
  }, [searchParams])

  const toggleShowResponseTypes = (vals: RT[]) => {
    setSelectedResponseTypes(
      toggleListWithAllType(selectedResponseTypes, vals, RT.all, Object.keys(RT).length - 1),
    )
  }

  const renderResponseTypeSelect = () => {
    const menuItems = Object.values(RT).map(value => ({
      value,
      isDivider: value === RT.all,
    }))
    return (
      <CheckboxDropdown
        withLeftMargin
        label="Show:"
        menuItems={menuItems}
        selectedItems={selectedResponseTypes}
        onChange={toggleShowResponseTypes}
      />
    )
  }

  const renderComparisonSelect = (filterValueNames: string[]) => {
    const groupSelectHeader = (
      <div className={classes.selectionHeader}>
        <Typography className={classes.selectionTitle}>Select Categories To Compare</Typography>
        <Typography color="textSecondary" className={classes.selectionSubtitle}>
          Choose up to {maxComparisons}
        </Typography>
      </div>
    )
    return (
      <>
        <Button
          color="secondaryNoBackground"
          aria-haspopup="true"
          onClick={e => {
            gaEvent({
              action: 'clickStatementsComparison',
              category: 'Insights',
            })
            setAnchorEl(e.target as HTMLElement)
          }}
        >
          <Typography variant="body2" className={classes.compareButton}>
            Compare +
          </Typography>
        </Button>
        {anchorEl !== null && (
          <GroupedSelect
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            items={getGroupedFilters(restProps.currentUser, filterValueNames)}
            selectedItems={selectedComparisonFilters}
            onMultiselectChange={vals => setSelectedComparisonFilters(vals.filter(Boolean))}
            getItemValue={item => item.uuid}
            getItemLabel={item => item.name}
            multiple
            header={groupSelectHeader}
            maxSelectedItems={maxComparisons}
          />
        )}
      </>
    )
  }
  return (
    <ResponseHandler {...filterValuesResult}>
      {({ insightsSurveysFilterValues }) => {
        return (
          <div>
            <div className={classes.controls}>
              {renderResponseTypeSelect()}
              {renderComparisonSelect(
                flatten(insightsSurveysFilterValues.map(fvData => fvData.groups)),
              )}
              {Boolean(selectedComparisonFilters.length) && (
                <Badge
                  className={classes.selectionDeleteBadge}
                  onClick={() => setSelectedComparisonFilters([])}
                >
                  {`${selectedComparisonFilters.length}  X`}
                </Badge>
              )}
            </div>
            <StatementsContainer
              {...restProps}
              comparisonFilters={selectedComparisonFilters}
              selectedResponseTypes={selectedResponseTypes}
            />
          </div>
        )
      }}
    </ResponseHandler>
  )
}

export default withErrorHandler(StatementsControlsWrapper)
