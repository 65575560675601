import React from 'react'

import { RadioGroup, Radio, Typography, DialogContent, Divider } from '@material-ui/core'

import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import { Group } from 'components/Settings/Team/Groups/GroupsTypes'

type RadioProps = {
  title: string
  group: Group
  field: keyof Group
  updateGroup: (arg: Partial<Group>) => void
}
const RadioButtonSection: React.FC<RadioProps> = ({ title, group, updateGroup, field }) => {
  return (
    <>
      <Divider />
      <DialogContent>
        <Typography color="textSecondary">{title}</Typography>
        <RadioGroup>
          <FormControlLabel
            control={
              <Radio
                checked={Boolean(group[field])}
                onChange={() => updateGroup({ [field]: true })}
                name="Yes"
              />
            }
            label="Yes"
          />
          <FormControlLabel
            control={
              <Radio
                checked={group[field] === false}
                onChange={() => updateGroup({ [field]: false })}
                name="No"
              />
            }
            label="No"
          />
        </RadioGroup>
      </DialogContent>
    </>
  )
}

export default RadioButtonSection
