import React from 'react'

import { makeStyles, Typography, Paper } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import * as TeaserImage from 'assets/img/overlay-report-teaser.png'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import Page from 'components/Blocks/Layout/Page'
import { useInsightsOverlayEmailSupportMutation } from 'generated/graphql'
import { handleMutationResponse } from 'utils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'flex',
    '& >div': {
      width: '50%',
    },
    padding: spacing(4),
  },
  infoBlock: {
    marginTop: spacing(3),
    marginBottom: spacing(2),
  },
  listItem: {
    paddingTop: spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& >svg': {
      color: palette.common.success,
      marginRight: spacing(2),
    },
  },
  learnMoreButton: {
    width: 180,
    height: 34,
    marginTop: spacing(6),
  },
}))

const OverlayTeaser: React.FC<{ teaserInfoText: string; isWaitingTeaser?: boolean }> = ({
  teaserInfoText,
  isWaitingTeaser = false,
}) => {
  const [emailSupport, { loading }] = useInsightsOverlayEmailSupportMutation()
  const classes = useStyles()
  return (
    <Page>
      <Paper>
        <div className={classes.container}>
          <div>
            <Typography variant="h4">
              {isWaitingTeaser
                ? 'Check back later.'
                : 'Learn more about the new Analytics feature!'}
            </Typography>
            <Typography className={classes.infoBlock} color="textSecondary">
              {teaserInfoText}
            </Typography>
            {[
              'Detailed comparative data',
              'Cross organization analysis',
              'Clear actionable steps',
              'Share findings across teams',
              'Utilize results for marketing',
            ].map(listText => {
              return (
                <div key={listText} className={classes.listItem}>
                  <CheckCircleIcon /> <Typography variant="h6">{listText}</Typography>
                </div>
              )
            })}
            <SubmitButton
              className={classes.learnMoreButton}
              isSubmitting={loading}
              buttonType="button"
              onClick={async () => {
                if (isWaitingTeaser) {
                  return window.open(
                    'https://activatedinsights.force.com/help/s/article/Overlay-Report',
                    '_newtab',
                  )
                }
                const result = await emailSupport()
                handleMutationResponse(
                  result?.data?.emailSupport?.errors,
                  'Thank you for your interest! Someone from our support team will be contacting you shortly, and can answer any questions you may have.',
                  8000,
                )
              }}
            >
              Learn More
            </SubmitButton>
          </div>
          <img width="50%" src={TeaserImage} alt="Analytics Teaser" />
        </div>
      </Paper>
    </Page>
  )
}

export default OverlayTeaser
