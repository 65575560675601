import React, { useState } from 'react'

import { IconButton, makeStyles, Paper, Tooltip, Grid, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import snakeCase from 'lodash/snakeCase'

import TablePanel from 'components/Blocks/Accordions/TablePanel'
import Button from 'components/Blocks/CustomButtons/Button'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import SimpleSearch from 'components/Blocks/Search/SimpleSearch'
import DownloadButton from 'components/Insights/Blocks/DownloadButton'
import ResidentUploadHeader from 'components/Survey/ResidentWizard/ResidentUploadHeader'
import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import DeleteParticipantContainer from 'components/Survey/Wizard/Steps/Participants/DeleteParticipantContainer'
import EligibleParticipantConfig from 'components/Survey/Wizard/Steps/Participants/EligibleParticipantConfig'
import UpdateParticipantContainer from 'components/Survey/Wizard/Steps/Participants/UpdateParticipantContainer'
import SurveyControls from 'components/Survey/Wizard/SurveyControls'
import { gaEvent } from 'config/ga'
import {
  SurveyStatusEnum,
  SurveysSurveyQuery,
  UserDownloadsEnum,
  useSurveysParticipantsBySurveyQuery,
} from 'generated/graphql'
import { CONTACT_EMAIL, SURVEY_TYPE_TO_DEFAULT_LABEL } from 'utils/constants'
import { Participants } from 'utils/types'

const useStyles = makeStyles(({ spacing }) => ({
  addButton: {
    marginLeft: 'auto',
  },
  navButtons: {
    marginTop: spacing(2),
  },
  tableSummary: {
    padding: spacing(3),
  },
}))

type Props = {
  survey: SurveysSurveyQuery['survey']
  goBack?(): void
  goNext?(uuid?: string): void
}

const ResidentParticipants: React.FC<Props> = ({ survey, goBack, goNext }) => {
  const { uuid: surveyUuid, status } = survey
  const pageSize = 20

  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [sortBy, setSortBy] = useState('survey_code')
  const [searchQuery, setSearchQuery] = useState('')
  const [sortDescending, setSortDescending] = useState(false)
  const [updateDialogIsOpen, setUpdateDialogIsOpen] = useState(false)
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)
  const [editParticipantId, setEditParticipantId] = useState<string | null>(null)

  const result = useSurveysParticipantsBySurveyQuery({
    variables: {
      surveyUuid,
      pageSize,
      page,
      sortDescending,
      sortBy,
      search: searchQuery,
    },
    // Don't use the cache in case an admin updates the participant file.
    // Additionally, queries are cached with variables on the backend
    fetchPolicy: 'network-only',
  })
  let preventParticipantDeletionMessage = ''
  let disableActionSamplePoolMessage = ''
  if ([SurveyStatusEnum.LIVE, SurveyStatusEnum.CLOSED].includes(status)) {
    preventParticipantDeletionMessage = `Participants can't be deleted when a survey is live or closed. Contact ${CONTACT_EMAIL} if you need assistance."`
  } else if (survey.usesSamplePoolDataSource) {
    disableActionSamplePoolMessage = `Participant cannot be modified from surveys that source data from a sample pool. Contact ${CONTACT_EMAIL} if you need assistance.`
    preventParticipantDeletionMessage = disableActionSamplePoolMessage
  }

  const getParticipantsData = (participants: Participants) =>
    participants.map((p, idx) => ({
      ...p,
      actions: (
        <div>
          <IconButton
            onClick={() => {
              setEditParticipantId(p.uuid)
              setUpdateDialogIsOpen(true)
            }}
          >
            <EditIcon id={`edit-participant-${idx}`} />
          </IconButton>
          {preventParticipantDeletionMessage ? (
            <Tooltip title={preventParticipantDeletionMessage}>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              onClick={() => {
                setEditParticipantId(p.uuid)
                setDeleteDialogIsOpen(true)
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      ),
    }))

  return (
    <ResponseHandler {...result}>
      {({ participantsBySurvey, survey: participantsSurvey }, { loading }) => {
        const representativesFileName = participantsSurvey.participantsUpload?.fileName
        const clientsFileName = participantsSurvey.clientsUpload?.fileName
        const participants = participantsBySurvey?.participants || []
        return (
          <>
            <SurveyControls survey={survey} showPreview showTranslations />
            {/** The first version of Monthly Engagement and SOS surveys require uploads directly into admin */}
            {survey.usesSamplePoolDataSource ? (
              <>
                <Paper className={classes.tableSummary}>
                  <Typography>
                    Participant Uploads for {SURVEY_TYPE_TO_DEFAULT_LABEL[survey.type]} Surveys are
                    handled directly by the Customer Team.
                  </Typography>
                  <br />
                  <DownloadButton
                    title="Download Participant Import Errors"
                    id="dowload-participant-import-errors"
                    downloadType={UserDownloadsEnum.PARTICIPANT_FAILURE_REPORT_EXPORT}
                    surveyUuid={surveyUuid}
                  />
                </Paper>
                <br />
                {survey.usesSamplePoolDataSource && <EligibleParticipantConfig survey={survey} />}
              </>
            ) : (
              <ResidentUploadHeader
                surveyUuid={surveyUuid}
                clientsFileName={clientsFileName}
                representativesFileName={representativesFileName}
                clientTemplateUrl={participantsSurvey.clientTemplateUrl}
                participantTemplateUrl={participantsSurvey.participantTemplateUrl}
                surveyType={survey.type}
              />
            )}
            {participants.length > 0 && (
              <TablePanel
                title="Survey Participants"
                actions={
                  // Participant cannot be added directly on sample-pool based surveys
                  !survey.usesSamplePoolDataSource ? (
                    <Button
                      id="add-more-participants"
                      className={classes.addButton}
                      onClick={() => {
                        setEditParticipantId(null)
                        setUpdateDialogIsOpen(true)
                      }}
                      color="secondaryNoBackground"
                    >
                      Add more Participants
                    </Button>
                  ) : (
                    <div />
                  )
                }
                helpText={
                  status === SurveyStatusEnum.LIVE
                    ? ''
                    : 'Please review the participants in the table below for accuracy.'
                }
                gutterBottom
                reactTableProps={{
                  columns: [
                    {
                      Header: 'Survey Code',
                      accessor: 'surveyCode',
                    },
                    {
                      Header: 'Personal Email',
                      accessor: 'personalEmail',
                    },
                    {
                      Header: 'Mobile Phone',
                      accessor: 'mobilePhone',
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                    },
                  ],
                  loading: Boolean(loading),
                  data: getParticipantsData(participants),
                  pageSize,
                  // Sorting is done on the backend by overriding the "onSortedChange"
                  // so we don't actually want the table to sort frontend.
                  // We should probably look at using a custom table when a table moves
                  // pagination backend..but for now it's easier to use this lib and keep design consistent.
                  manual: true,
                  resizable: false,
                  page,
                  pages: Math.ceil((participantsBySurvey?.total || 0) / pageSize),
                  onPageChange: (newPage: number) => setPage(newPage),
                  showPageJump: false,
                  onSortedChange: (sortList: { desc: boolean; id: string }[]) => {
                    gaEvent({
                      action: 'sortParticipantsTable',
                      category: 'Surveys',
                    })
                    setSortDescending(sortList[0].desc)
                    setSortBy(snakeCase(sortList[0].id))
                  },
                }}
              >
                <Paper>
                  <Grid container className={classes.tableSummary}>
                    <Grid item xs={3}>
                      <Typography variant="h6">Summary</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>{survey.responseRate.total} Participants</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <SimpleSearch
                        withSearchAdornment
                        query={searchQuery}
                        handleSearch={(val: string) => {
                          gaEvent({
                            action: 'searchParticipantsTable',
                            category: 'Surveys',
                          })
                          // Reset page before search query in case the search returns
                          // fewer total results than can support the page we're on.
                          setPage(0)
                          setSearchQuery(val)
                        }}
                        debounceTime={1000}
                        autoFocus={false}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </TablePanel>
            )}
            {updateDialogIsOpen && (
              <UpdateParticipantContainer
                submitDisabledText={disableActionSamplePoolMessage}
                missingPhonesErrorLevel={survey.missingPhonesErrorLevel}
                onClose={() => setUpdateDialogIsOpen(false)}
                surveyUuid={surveyUuid}
                surveyDataTypeOptions={participantsSurvey.dataTypeOptions}
                participant={
                  editParticipantId
                    ? participants.find(p => p.uuid === editParticipantId) || null
                    : null
                }
                productType={survey.productType}
              />
            )}
            {deleteDialogIsOpen && editParticipantId && (
              <DeleteParticipantContainer
                onClose={() => setDeleteDialogIsOpen(false)}
                participantUuid={editParticipantId}
              />
            )}
            <GridContainer direction="row-reverse">
              <ItemGrid sm={8}>
                <NavigationButtons
                  goBack={goBack}
                  goNext={goNext}
                  goNextLabel={surveyUuid ? 'Next' : 'Save and Continue'}
                />
              </ItemGrid>
            </GridContainer>
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default ResidentParticipants
