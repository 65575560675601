import React, { ReactElement } from 'react'

import { Typography, makeStyles, Tooltip, TooltipProps } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import cn from 'classnames'
import { kebabCase } from 'lodash'

import Button from 'components/Blocks/CustomButtons/Button'
import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import TimeTrendingIcon from 'components/Insights/Blocks/TimeTrendingIcon'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { TimeTrendingChartKey } from 'config/LocalStorage'

const useStyles = makeStyles(({ spacing }) => ({
  titleText: {
    textTransform: 'uppercase',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  buttons: {
    '& >button,& >div>button': {
      // Use a negative margin instead of padding so that the highlight on hover covers the full space
      marginTop: -spacing(2),
    },
    display: 'flex',
  },
  seeMoreButton: {
    margin: 0,
    padding: 0,
  },
  description: {
    '& >p': {
      display: 'inline',
      verticalAlign: 'middle',
    },
    lineHeight: '2.2rem',
  },
  emptySpace: {
    marginTop: spacing(),
  },
  bottomPadding: {
    paddingBottom: spacing(3),
  },
}))

type Props = {
  title: string
  description?: ReactElement | string
  tooltip?: ReactElement | string
  tooltipProps?: Partial<TooltipProps>
  onSeeMore?(): void
  snapId?: string
  screenshotStrategy?: 'canvas' | 'svg' | 'auto' | 'html2canvas'
  // Use an object so we can enforce that all props are present
  extraControls?: ReactElement // Other dropdowns, buttons grouped with the right-side buttons.
  useBottomPadding?: boolean
  hasTimeTrending?: boolean
  timeTrendingChartKey?: TimeTrendingChartKey
  // Supplied when timeframe is enabled
  timeTrendingSurveyUuid?: string
}
const SnapshotChartHeader: React.FC<Props> = ({
  title,
  description,
  tooltip,
  tooltipProps = {},
  onSeeMore,
  snapId,
  screenshotStrategy,
  extraControls,
  useBottomPadding = true,
  hasTimeTrending,
  timeTrendingChartKey,
  timeTrendingSurveyUuid,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <div className={cn({ [classes.bottomPadding]: useBottomPadding })}>
      <div className={classes.titleRow}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
          {tooltip && (
            <Tooltip title={tooltip} {...tooltipProps}>
              <InfoIcon />
            </Tooltip>
          )}
        </Typography>
        <div className={classes.buttons}>
          {extraControls}
          {hasTimeTrending && timeTrendingChartKey && (
            <TimeTrendingIcon surveyUuid={timeTrendingSurveyUuid} chartKey={timeTrendingChartKey} />
          )}
          {snapId && <ScreenshotButton snapId={snapId} strategy={screenshotStrategy} />}
        </div>
      </div>
      <div className={classes.description}>
        <Typography color="textSecondary">
          {description ? <>{description}&nbsp;</> : <span className={classes.emptySpace} />}
        </Typography>
        {onSeeMore && (
          <Button
            color="secondaryNoBackground"
            onClick={onSeeMore}
            id={`see-more-${kebabCase(title).toLowerCase()}`}
            className={classes.seeMoreButton}
          >
            See More
          </Button>
        )}
      </div>
    </div>
  )
}

export default SnapshotChartHeader
