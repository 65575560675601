import React from 'react'

import { makeStyles, IconButton, Typography, Tooltip } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import isNil from 'lodash/isNil'

import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import TablePanel from 'components/Blocks/Accordions/TablePanel'
import { HierarchyScoresByGroup } from 'components/Blocks/Charts/ScatterPlotContainer'
import ActionTooltip from 'components/Blocks/Tooltips/ActionTooltip'
import {
  ScatterQuadrants,
  getQuadrantFromPlotPoint,
} from 'components/Insights/TimeTrending/ScatterPlot'
import { FilterTypeFragment } from 'generated/graphql'
import { colors } from 'shared/theme'
import { generateXlsxDownload } from 'utils'
import { ScoreTitleEnum } from 'utils/constants'

const useStyles = makeStyles(({ palette, spacing }) => ({
  tablePanel: {
    marginTop: spacing(3),
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: spacing(2),
  },
  downloadIcon: {
    color: palette.common.navy65,
  },
}))

export const tableSortMethod = (a: any, b: any, _: any) => {
  // react-table needs to know how to string a column with numbers and strings. Place strings at the tail
  if (typeof a === 'string') return -1
  if (typeof b === 'string') return 1
  if (a > b) return 1
  if (b > a) return -1
  return 0
}

export const quadrantToColor: { [key in keyof ScatterQuadrants]: string } = {
  BOTTOM_LEFT: colors.danger,
  BOTTOM_RIGHT: colors.navy65,
  TOP_LEFT: colors.navy65,
  TOP_RIGHT: colors.success,
}

type Row = {
  level1FilterTypeName?: string
  level2FilterTypeName: string
  survey1Score: number | string
  survey2Score: number | string
  scoreChange: number | string
  quadrantTitle: string
}
type Props = {
  quadrantTitles?: ScatterQuadrants
  level1FilterType: FilterTypeFragment
  level2FilterType: FilterTypeFragment
  firstSurveyDate: string
  secondSurveyDate: string
  data: HierarchyScoresByGroup
  companyOverallPositive: number
  scoreName: ScoreTitleEnum | 'Key Statement Score' | 'Core Q' | 'Recommendation Score'
  minRows?: number
}
const Component: React.FC<Props> = ({
  quadrantTitles,
  level1FilterType,
  level2FilterType,
  firstSurveyDate,
  secondSurveyDate,
  data,
  companyOverallPositive,
  scoreName,
}) => {
  const isMultiIndex = level1FilterType !== level2FilterType
  const classes = useStyles()
  const scoreShorthand = {
    [ScoreTitleEnum.EMPLOYEE]: 'TIS',
    [ScoreTitleEnum.RESIDENT]: 'CES',
    'Key Statement Score': 'KS',
    'Core Q': '',
    'Recommendation Score': '',
  }[scoreName]
  const rowStyle = { height: 52, display: 'flex', alignItems: 'center' }
  // Include "name" property for the download.
  const columns = [
    {
      Header: `${level2FilterType.name}`,
      name: `${level2FilterType.name}`,
      accessor: 'level2FilterTypeName',
      style: rowStyle,
    },
    {
      Header: (
        <Tooltip title={`${firstSurveyDate} ${scoreName}`}>
          <span>
            {firstSurveyDate} {scoreShorthand}
          </span>
        </Tooltip>
      ),
      name: `${firstSurveyDate} ${scoreShorthand}`,
      accessor: 'survey1Score',
      style: rowStyle,
      sortMethod: tableSortMethod,
    },
    {
      Header: (
        <Tooltip title={`${secondSurveyDate} ${scoreName}`}>
          <span>
            {secondSurveyDate} {scoreShorthand}
          </span>
        </Tooltip>
      ),
      name: `${secondSurveyDate} ${scoreShorthand}`,
      accessor: 'survey2Score',
      style: rowStyle,
      sortMethod: tableSortMethod,
    },
    {
      Header: 'Score Change',
      name: 'Score Change',
      accessor: 'scoreChange',
      style: rowStyle,
      Cell: (row: { value: number | 'N/A' }) => {
        return row.value === 'N/A' ? row.value : <ScoreChangeArrow delta={row.value} />
      },
      sortMethod: tableSortMethod,
    },
    {
      Header: 'Status',
      name: 'Status',
      accessor: 'quadrantTitle',
      style: rowStyle,
      Cell: (row: { value: string }) =>
        quadrantTitles && (
          <Typography
            style={{
              color:
                quadrantToColor[
                  Object.keys(quadrantTitles).find(
                    key => quadrantTitles[key as keyof ScatterQuadrants] === row.value,
                  ) as keyof ScatterQuadrants
                ],
            }}
          >
            {row.value}
          </Typography>
        ),
    },
  ]
  if (!quadrantTitles) {
    columns.pop()
  }
  if (isMultiIndex) {
    columns.unshift({
      Header: `${level1FilterType.name} Name`,
      accessor: 'level1FilterTypeName',
      style: rowStyle,
      name: `${level1FilterType.name} Name`,
    })
  }
  const rows: Row[] = []
  Object.keys(data).forEach(level1FilterTypeName => {
    Object.keys(data[level1FilterTypeName]).forEach(level2FilterTypeName => {
      const surveyScores = Object.values(data[level1FilterTypeName][level2FilterTypeName])
      const survey1Score = isNil(surveyScores[0]) ? '–' : surveyScores[0]
      const survey2Score = isNil(surveyScores[1]) ? '–' : surveyScores[1]
      let scoreChange: number | string = 'N/A'
      let quadrantTitle: keyof ScatterQuadrants | string = 'N/A'
      if (!isNil(surveyScores[0]) && !isNil(surveyScores[1])) {
        scoreChange = (surveyScores[1] || 0) - (surveyScores[0] || 0)
        if (quadrantTitles) {
          quadrantTitle =
            quadrantTitles[
              getQuadrantFromPlotPoint(surveyScores[1], scoreChange, companyOverallPositive, 0)
            ]
        }
      }
      const row: Row = {
        level2FilterTypeName,
        survey1Score,
        survey2Score,
        scoreChange,
        // Use title instead of quadrant so that it's displayed properly in the download.
        quadrantTitle,
      }
      if (isMultiIndex) {
        row.level1FilterTypeName = level1FilterTypeName
      }
      rows.push(row)
    })
  })
  return (
    <div className={classes.tablePanel}>
      <TablePanel
        title={
          <div className={classes.titleRow}>
            {scoreName} by {level2FilterType.name}
            <ActionTooltip
              title={
                <>
                  <CloudDownloadIcon className={classes.downloadIcon} />
                  <Typography>Download to Excel</Typography>
                </>
              }
              placement="top"
            >
              <IconButton
                onClick={() =>
                  generateXlsxDownload(
                    columns,
                    rows,
                    'Time Trending Breakdown',
                    `Time-Trending-Breakdown-${firstSurveyDate}-${secondSurveyDate}.xlsx`,
                  )
                }
                id="tt-table-download"
              >
                <CloudDownloadIcon />
              </IconButton>
            </ActionTooltip>
          </div>
        }
        gutterBottom
        interactive={false}
        reactTableProps={{
          columns,
          data: rows,
          showPagination: false,
          // Avoid truncating results by specifying the number of rows that will appear.
          defaultPageSize: rows.length,
          minRows: Math.min(rows.length, 10),
        }}
      />
    </div>
  )
}

export default Component
