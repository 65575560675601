import React from 'react'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import SurveyDashboard from 'components/Survey/Dashboard/SurveyDashboard'
import { SurveyTypeEnum, useCurrentUserQuery, useSurveysSurveysQuery } from 'generated/graphql'

export const getSurveysSurveysQueryVars = (surveyType?: SurveyTypeEnum) => {
  return {
    surveyType,
  }
}
const SurveyDashboardContainer = () => {
  const currentUserResult = useCurrentUserQuery()

  const result = useSurveysSurveysQuery({
    variables: getSurveysSurveysQueryVars(),
  })
  return (
    <ResponseHandler {...currentUserResult}>
      {({ currentUser }) => {
        return (
          <ResponseHandler {...result}>
            {({ surveys }) => {
              if (!surveys) return <></>
              return (
                <SurveyDashboard
                  surveyTypesAllowedToAdd={currentUser.surveyTypesAllowedToAdd}
                  surveys={surveys.edges.map(e => e?.node)}
                  favoriteSurveyIds={currentUser.favoriteSurveyIds}
                />
              )
            }}
          </ResponseHandler>
        )
      }}
    </ResponseHandler>
  )
}

export default SurveyDashboardContainer
