// There's a bunch of issues using <span onClick/> prefer to replace that rather than appease the linter.
/* eslint-disable */

import React from 'react'

import { makeStyles, Grid, Popover } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 4,
    width: 'auto',
    '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
      /* IE10+ specific styles go here */
      minWidth: 220,
      minHeight: 280,
    },
  },
  emoji: {
    fontFamily: 'Segoe UI Emoji',
    fontSize: '2.4rem',
    cursor: 'pointer',
    padding: 4,
    '&:hover': {
      backgroundColor: theme.palette.common.iceGrey,
      borderRadius: '100%',
    },
  },
}))

interface Props {
  anchorEl: null | HTMLElement
  onClose(): void
  onSelectEmoji(emoji: string): void
}

const EmojiPicker: React.FC<Props> = ({ anchorEl, onClose, onSelectEmoji }) => {
  const classes = useStyles()
  const emojis = [
    ['😀', '😃', '😄', '😁', '😆', '😅'],
    ['🤣', '😂', '🙂', '😉', '😊', '😇'],
    ['😍', '😘', '😛', '😜', '🤔', '🤗'],
    ['😐', '🙄', '😬', '😴', '😷', '🤒'],
    ['🤢', '🤧', '😎', '😳', '😥', '😭'],
    ['☕', '🎉', '🎊', '✨', '🍻', '🍾'],
    ['😱', '😡', '👍', '👏', '🙏', '👀'],
    ['🤞', '🙌', '🤷', '🤷‍♂️', '🙋‍', '🙋‍♂️'],
  ]
  return (
    <Popover
      id="emojis"
      open
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div className={classes.root}>
        {emojis.map((row, idxRow) => (
          <Grid key={idxRow} container alignItems="center">
            {row.map((emoji, idx) => (
              <Grid item xs={2} key={`${idxRow}${idx}`}>
                <span className={classes.emoji} role="img" onClick={() => onSelectEmoji(emoji)}>
                  {emoji}
                </span>
              </Grid>
            ))}
          </Grid>
        ))}
      </div>
    </Popover>
  )
}

export default EmojiPicker
