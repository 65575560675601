import React, { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core'
import isEqual from 'lodash/isEqual'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Prompt } from 'react-router'

import Page from 'components/Blocks/Layout/Page'
import DepartmentsTab from 'components/Settings/General/DepartmentsTab'
import LevelsOfCareTab from 'components/Settings/General/LevelsOfCareTab'
import ServiceAreasTab from 'components/Settings/General/ServicesAreasTab'
import SetupTab from 'components/Settings/General/SetupTab'
import {
  CurrentUserDocument,
  CurrentUserQuery,
  OrganizationInput,
  useSettingsUpdateOrganizationMutation,
  BenchmarkCodeType,
  BenchmarkLevelOfCareType,
  BenchmarkDepartmentType,
  BenchmarkShiftType,
  BenchmarkConfigInput,
  OrganizationFragment,
  SettingsOrganizationServiceAreasDocument,
} from 'generated/graphql'
import { handleMutationResponse } from 'utils'
import { UNSAVED_CHANGES_MESSAGE, URLS } from 'utils/constants'

enum SettingsTab {
  INITIAL = 'Initial Set Up',
  LEVELS_OF_CARE = 'Levels of Care',
  DEPARTMENTS = 'Departments',
  SERVICES = 'Services',
}

const useStyles = makeStyles({
  appBar: {
    marginBottom: 15,
  },
  tabOverride: {
    whiteSpace: 'nowrap',
    minWidth: 0,
    fontSize: '1.4rem',
  },
})

export enum ConfigAttrEnum {
  LOC = 'benchmarkLevelsOfCare',
  DEPT = 'benchmarkDepartments',
  SHIFT = 'benchmarkShifts',
}

const getInputOrg = (org: OrganizationFragment) => ({
  name: org.name,
  seniorLiving: org.seniorLiving,
  atHome: org.atHome,
  isCcrc: org.isCcrc,
  multipleLocations: org.multipleLocations,
  // Strip unused properties from the inputs
  benchmarkLevelsOfCare: org.benchmarkLevelsOfCare.map(bm => ({
    checked: bm.checked,
    code: bm.code,
    customName: bm.customName,
  })),
  benchmarkDepartments: org.benchmarkDepartments.map(bm => ({
    checked: bm.checked,
    code: bm.code,
    customName: bm.customName,
  })),
  benchmarkShifts: org.benchmarkShifts.map(bm => ({
    checked: bm.checked,
    code: bm.code,
    customName: bm.customName,
  })),
})

const OrgSettingsContainer = () => {
  const classes = useStyles()
  const [currentTab, setTab] = useState<SettingsTab>(SettingsTab.INITIAL)
  // We need to store this is in state so that the services tab (which maintains its own state)
  // can alert the parent component when to block changing tabs.
  const [showUnsavedAlert, setShowUnsavedAlert] = useState(false)
  const [updateOrganization, { loading }] = useSettingsUpdateOrganizationMutation()
  const client = useApolloClient()
  const { organization } = client.readQuery({ query: CurrentUserDocument })
    .currentUser as NonNullable<CurrentUserQuery>['currentUser']

  const [inputOrg, setInputOrg] = useState<OrganizationInput>(getInputOrg(organization))

  const onSubmit = async () => {
    const result = await updateOrganization({
      variables: {
        organization: inputOrg,
      },
      refetchQueries: [
        {
          query: SettingsOrganizationServiceAreasDocument,
          variables: { departmentDtCode: BenchmarkCodeType.DEPARTMENT_NAME },
        },
      ],
    })
    handleMutationResponse(result?.data?.updateOrganization?.errors, 'Organization Updated')
  }

  const isVisibleLOC = (loc: BenchmarkLevelOfCareType) =>
    (organization.seniorLiving && loc.seniorLiving) ||
    (organization.atHome && loc.atHome) ||
    (organization.multipleLocations && loc.multipleLocations)

  const checkedLOCCodes = organization.benchmarkLevelsOfCare
    .filter(LOC => isVisibleLOC(LOC) && LOC.checked)
    .map(LOC => LOC.code)

  const isVisibleDept = (dept: BenchmarkDepartmentType) =>
    dept.locs.some(code => checkedLOCCodes.includes(code))

  const availableTabs: Array<SettingsTab> = [SettingsTab.INITIAL]
  if (organization.benchmarkLevelsOfCare.some(isVisibleLOC)) {
    availableTabs.push(SettingsTab.LEVELS_OF_CARE)
  }
  const showDepts = organization.solution && organization.benchmarkDepartments.some(isVisibleDept)
  const showShifts = organization.solution && (organization.seniorLiving || organization.atHome)
  if (showDepts || showShifts) {
    availableTabs.push(SettingsTab.DEPARTMENTS)
  }
  if (organization.residentSolution) {
    availableTabs.push(SettingsTab.SERVICES)
  }

  const updateBenchmarkConfig = (
    configAttr: ConfigAttrEnum,
    updatedConfig: BenchmarkConfigInput,
  ) => {
    setInputOrg({
      ...inputOrg,
      [configAttr]: (inputOrg[configAttr] as BenchmarkShiftType[]).map(config =>
        config.code === updatedConfig.code ? updatedConfig : config,
      ),
    })
  }

  const commonProps = {
    loading,
    inputOrg,
    organization,
    onSubmit,
  }
  const TabComponent = {
    [SettingsTab.INITIAL]: (
      <SetupTab {...commonProps} setInputOrg={setInputOrg} onSubmit={onSubmit} />
    ),
    [SettingsTab.LEVELS_OF_CARE]: (
      <LevelsOfCareTab
        {...commonProps}
        isConfigVisible={isVisibleLOC}
        updateBenchmarkConfig={updateBenchmarkConfig}
      />
    ),
    [SettingsTab.DEPARTMENTS]: (
      <DepartmentsTab
        {...commonProps}
        isDeptConfigVisible={isVisibleDept}
        updateBenchmarkConfig={updateBenchmarkConfig}
      />
    ),
    [SettingsTab.SERVICES]: <ServiceAreasTab onServicesChange={setShowUnsavedAlert} />,
  }[currentTab]
  return (
    <Page>
      <Prompt
        when={!isEqual(inputOrg, getInputOrg(organization))}
        message={UNSAVED_CHANGES_MESSAGE}
      />
      <BreadcrumbsItem to={URLS.ORG_SETTINGS.GENERAL}>General</BreadcrumbsItem>
      <AppBar className={classes.appBar} position="static" color="default">
        <Tabs
          value={currentTab}
          variant="fullWidth"
          scrollButtons="auto"
          onChange={(e, value) => {
            if (!isEqual(inputOrg, getInputOrg(organization)) || showUnsavedAlert) {
              // eslint-disable-next-line
              const stayOnTab = !confirm(UNSAVED_CHANGES_MESSAGE)
              if (stayOnTab) {
                return
              }
              // Reset state before changing tabs.
              setInputOrg(getInputOrg(organization))

              setShowUnsavedAlert(false)
            }
            setTab(value)
          }}
        >
          {availableTabs.map(tab => (
            <Tab
              id={tab.replace(/\s/g, '')}
              key={tab}
              classes={{ root: classes.tabOverride }}
              // Edge case since we show shifts and departments in the same tab
              label={tab === SettingsTab.DEPARTMENTS && !showDepts ? 'Shifts' : tab}
              value={tab}
            />
          ))}
        </Tabs>
      </AppBar>
      {TabComponent}
    </Page>
  )
}

export default OrgSettingsContainer
