import React from 'react'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'

import InsightsOverviewRightDetail from 'components/Insights/Blocks/InsightsOverviewRightDetail'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import OverallIndexGuageAndScore from 'components/Insights/Snapshot/OverallIndexGaugeAndScore'
import { InsightsOverallScoreFragment } from 'generated/graphql'
import { colors } from 'shared/theme'

const useStyles = makeStyles(() => ({
  gaugeAndScoreContainer: {
    width: '55%',
  },
}))

type Props = {
  title: string
  insightsOverallIndex: InsightsOverallScoreFragment
  benchmarkName: string
  extraDescription?: string
}
const OverallIndexCard: React.FC<Props> = ({
  title,
  benchmarkName,
  insightsOverallIndex,
  extraDescription,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const score = Math.round(insightsOverallIndex.positive)
  let scoreDescription
  let scoreColor = colors.success
  if (score >= 85) {
    scoreDescription = 'Incredible!'
  } else if (score >= 75) {
    scoreDescription = 'Very Good'
  } else if (score >= 68) {
    scoreColor = colors.warning
    scoreDescription = 'Good'
  } else if (score >= 50) {
    scoreColor = colors.warning
    scoreDescription = 'Okay'
  } else {
    scoreColor = colors.danger
    scoreDescription = 'Opportunity to Improve'
  }
  return (
    <div id="overall-score-snap" className={cn(classes.halfRoot, classes.printPadRight)}>
      <div className={cn(classes.halfLeft, classes.gaugeAndScoreContainer)}>
        <OverallIndexGuageAndScore
          overallIndexScore={insightsOverallIndex}
          title={title}
          inconsistentLabel="Neutral"
          benchmarkName={benchmarkName}
        />
      </div>
      <InsightsOverviewRightDetail
        title={scoreDescription}
        titleColor={scoreColor}
        score={`${score}%`}
        extraDescription={
          <Typography color="textSecondary" variant="body2">
            {extraDescription}
          </Typography>
        }
      />
    </div>
  )
}

export default OverallIndexCard
