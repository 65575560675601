import React from 'react'

import { useApolloClient } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles, withWidth, Step, StepButton, Stepper } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import Page from 'components/Blocks/Layout/Page'
import ConfigurableQuestionsContainer from 'components/Survey/ResidentWizard/ConfigurableQuestionsContainer'
import ResidentParticipants from 'components/Survey/ResidentWizard/ResidentParticipants'
import Design from 'components/Survey/Wizard/Steps/Design/Design'
import Notifications from 'components/Survey/Wizard/Steps/Notifications/Notifications'
import ResponseNotificationsContainer from 'components/Survey/Wizard/Steps/Notifications/ResponseNotificationsContainer'
import EmployeeParticipants from 'components/Survey/Wizard/Steps/Participants/EmployeeParticipants'
import CustomQuestions from 'components/Survey/Wizard/Steps/Questions/CustomQuestions'
import Summary from 'components/Survey/Wizard/Steps/Summary/Summary'
import QuestionsContainer from 'containers/Survey/Wizard/Steps/Questions/QuestionsContainer'
import {
  SurveysSurveyQuery,
  SurveyTypeEnum,
  CurrentUserDocument,
  CurrentUserQuery,
  SurveyDistributionTypeEnum,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { surveyPage, CUSTOM_SURVEY_TYPES, URLS, SURVEY_URLS } from 'utils/constants'

const useStyles = makeStyles(() => ({
  stepper: {
    boxOrdinalGroup: 1,
    backgroundColor: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
  },
  stepComponent: {
    boxOrdinalGroup: 2,
    display: 'normal',
  },
}))

export enum WizardStepsEnum {
  DESIGN = 'design',
  QUESTIONS = 'questions',
  PARTICIPANTS = 'participants',
  NOTIFICATIONS = 'notifications',
  RESPONSE_NOTIFICATIONS = 'response_notifications',
  SUMMARY = 'summary',
}

const getSteps = (
  isAdmin: boolean,
  survey: SurveysSurveyQuery['survey'] | null,
  surveyType: SurveyTypeEnum,
  productType: SurveyProductTypeEnum,
  isOpenLinkSurvey: boolean,
) => {
  // Design, Notifications and Summary are shared with between resident and employee wizard because the design is nearly identical.
  // If the design diverges significantly, we can migrate to the strategy with questions
  // where we use a Resident specific component and extract common UI to shared blocks
  const uuid = survey?.uuid
  let questionsComponent
  if ([SurveyTypeEnum.TI, SurveyTypeEnum.PULSE].includes(surveyType)) {
    questionsComponent = QuestionsContainer
  } else if (CUSTOM_SURVEY_TYPES.includes(surveyType)) {
    questionsComponent = CustomQuestions
  } else {
    questionsComponent = ConfigurableQuestionsContainer
  }
  const participantsComponent =
    productType === SurveyProductTypeEnum.RESIDENT ? ResidentParticipants : EmployeeParticipants
  const defaultUrl = URLS.SURVEYS.CREATE

  return {
    [WizardStepsEnum.DESIGN]: {
      path: uuid ? surveyPage(uuid, SURVEY_URLS.DESIGN) : defaultUrl,
      url: uuid ? URLS.SURVEYS.DESIGN : defaultUrl,
      component: Design,
      label: 'Design Survey',
      show: true,
    },
    [WizardStepsEnum.QUESTIONS]: {
      path: uuid ? surveyPage(uuid, SURVEY_URLS.QUESTIONS) : defaultUrl,
      url: URLS.SURVEYS.QUESTIONS,
      component: questionsComponent,
      label: 'Configure Questions',
      show: true,
    },
    [WizardStepsEnum.PARTICIPANTS]: {
      path: uuid ? surveyPage(uuid, SURVEY_URLS.PARTICIPANTS) : defaultUrl,
      url: URLS.SURVEYS.PARTICIPANTS,
      component: participantsComponent,
      label: 'Add Participants',
      show: !isOpenLinkSurvey,
    },
    [WizardStepsEnum.NOTIFICATIONS]: {
      path: uuid ? surveyPage(uuid, SURVEY_URLS.NOTIFICATIONS) : defaultUrl,
      url: URLS.SURVEYS.NOTIFICATIONS,
      component: Notifications,
      label: 'Participant Notifications',
      show: !isOpenLinkSurvey,
    },
    [WizardStepsEnum.RESPONSE_NOTIFICATIONS]: {
      path: uuid ? surveyPage(uuid, SURVEY_URLS.RESPONSE_NOTIFICATIONS) : defaultUrl,
      url: URLS.SURVEYS.RESPONSE_NOTIFICATIONS,
      component: ResponseNotificationsContainer,
      label: 'Response Notifications',
      show:
        isAdmin &&
        (survey?.hasSurveyResponseNotifications ||
          survey?.hasAverageScoreNotifications ||
          survey?.hasQuestionScoreNotifications),
    },
    [WizardStepsEnum.SUMMARY]: {
      path: uuid ? surveyPage(uuid, SURVEY_URLS.SUMMARY) : defaultUrl,
      url: URLS.SURVEYS.SUMMARY,
      component: Summary,
      label: 'Launch Survey',
      show: true,
    },
  }
}
type Props = {
  survey: SurveysSurveyQuery['survey'] | null
  surveyType: SurveyTypeEnum
  productType: SurveyProductTypeEnum
  width: string
}
const WizardContainer: React.FC<Props> = ({ width, survey, surveyType, productType }) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const client = useApolloClient()
  const { currentUser } = client.readQuery({ query: CurrentUserDocument }) as NonNullable<
    CurrentUserQuery
  >
  const isOpenLinkSurvey = survey?.distributionType === SurveyDistributionTypeEnum.OPEN

  const steps = getSteps(
    Boolean(currentUser?.isAdmin),
    survey,
    surveyType,
    productType,
    isOpenLinkSurvey,
  )
  const stepsList = Object.keys(steps).filter(
    step => steps[step as WizardStepsEnum].show,
  ) as WizardStepsEnum[]

  let activeStepName = WizardStepsEnum.DESIGN
  if (survey) {
    activeStepName =
      (Object.keys(steps).find(
        stepName => steps[stepName as WizardStepsEnum].path === location.pathname,
      ) as WizardStepsEnum) || WizardStepsEnum.DESIGN
  }
  const activeStep = steps[activeStepName]

  const goToStep = (stepName: WizardStepsEnum) => {
    history.push(steps[stepName].path)
  }

  const goNext = (surveyUuid?: string) => {
    // first step
    if (activeStepName === stepsList[0]) {
      if (surveyUuid) {
        // can't use goToStep here, because the survey object is null
        history.push(surveyPage(surveyUuid, SURVEY_URLS.QUESTIONS))
      }
    }
    // last step
    else if (activeStepName === stepsList.slice(-1)[0]) {
      history.push(URLS.SURVEYS.DASHBOARD)
      // intermediary step
    } else {
      goToStep(stepsList[stepsList.indexOf(activeStepName) + 1])
    }
  }

  const goBack = () => {
    // first step
    if (activeStepName === stepsList[0]) {
      history.push(URLS.SURVEYS.DASHBOARD)
    }
    // Last or intermediary step
    goToStep(stepsList[stepsList.indexOf(activeStepName) - 1])
  }

  const horizontal = !['xs'].includes(width)
  return (
    <Page>
      <BreadcrumbsItem to={URLS.SURVEYS.DESIGN}>
        {survey?.name || 'Create New Survey'}
      </BreadcrumbsItem>
      <Stepper
        className={classes.stepper}
        alternativeLabel={horizontal}
        activeStep={stepsList.findIndex(stepName => stepName === activeStepName)}
        nonLinear
        orientation={horizontal ? 'horizontal' : 'vertical'}
      >
        {stepsList.map(stepName => {
          const step = steps[stepName]
          let stepDisabled = !survey
          if (survey?.uuid && isOpenLinkSurvey) {
            // For open link surveys, we want to disable the wizard steps unless survey filter type settings are configured
            stepDisabled = stepDisabled || (isOpenLinkSurvey && !survey.filterTypeSettings.length)
          }
          return (
            <Step key={stepName}>
              <StepButton onClick={() => goToStep(stepName)} disabled={stepDisabled}>
                {step.label}
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
      <div className={classes.stepComponent}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Switch>
            <Route
              exact
              key={activeStep.label}
              path={activeStep.url}
              render={() => (
                <activeStep.component
                  survey={survey}
                  surveyType={surveyType}
                  currentUser={currentUser}
                  goToStep={goToStep}
                  goNext={goNext}
                  goBack={goBack}
                />
              )}
            />
          </Switch>
        </MuiPickersUtilsProvider>
      </div>
    </Page>
  )
}

export default withWidth()(WizardContainer)
