import React from 'react'

import { makeStyles, LinearProgress } from '@material-ui/core'

const useStyles = makeStyles(({ palette }) => ({
  progressRoot: {
    height: 6,
    width: '70%',
    background: palette.common.iceGrey,
    borderRadius: 2,
  },
  progressRootFat: {
    height: 10,
    background: palette.common.iceGrey,
    borderRadius: 5,
  },
  progressBar: {
    background: palette.common.navy,
  },
}))

type ProgressBarProps = {
  percentage: number
  fat?: Boolean
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage, fat = false }) => {
  const classes = useStyles()
  return (
    <LinearProgress
      variant="determinate"
      value={percentage}
      classes={{
        root: fat ? classes.progressRootFat : classes.progressRoot,
        bar: classes.progressBar,
      }}
    />
  )
}

export default ProgressBar
