import React from 'react'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import KeyStatement from 'components/Survey/Wizard/Steps/Summary/KeyStatement'
import { useInsightsKeyStatementQuery } from 'generated/graphql'

type Props = {
  lessThanMin: boolean
  seeMoreUrl?: string
  surveyUuid: string
  minShowableResults: number
  associatedSurveyUuid: string
  filters?: string[]
  keyStatementCode: string
}

const KeyStatementContainer: React.FC<Props> = ({
  lessThanMin,
  seeMoreUrl,
  surveyUuid,
  minShowableResults,
  associatedSurveyUuid,
  filters,
  keyStatementCode,
}) => {
  const response = useInsightsKeyStatementQuery({
    variables: {
      surveyUuid,
      associatedSurveyUuid,
      statementCodes: [keyStatementCode],
      filters,
    },
    fetchPolicy: 'network-only',
  })
  return (
    <ResponseHandler {...response}>
      {({ keyStatement, associatedSurveyKeyStatement }) => {
        const keyStatementData = keyStatement[0]
        // If an associated survey does not have the same filters as the current survey,
        // when filters are applied, the associated key statement may be null.
        const associatedKeyStatementData = associatedSurveyKeyStatement[0] as {
          positive: number
        } | null
        return (
          <KeyStatement
            {...keyStatementData}
            lessThanMin={lessThanMin}
            seeMoreUrl={seeMoreUrl}
            associatedSurveyPositive={associatedKeyStatementData?.positive}
            minShowableResults={minShowableResults}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default KeyStatementContainer
