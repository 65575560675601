import React, { useState, ReactElement } from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import Dialog from 'components/Blocks/Dialogs/Dialog'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    paddingBottom: spacing(2),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    maxWidth: '32%',
    backgroundColor: palette.common.lightGrey,
    display: 'flex',
    flex: '1 1 0px',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  image: {
    marginTop: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      maxWidth: 400,
    },
  },
}))

type ImageBannerProps = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  title: string
  subtitle: string
  dialogContent: ReactElement
  dialogImage: string
}

const ImageBanner: React.FC<ImageBannerProps> = ({
  Icon,
  title,
  subtitle,
  dialogContent,
  dialogImage,
}) => {
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)
  return (
    <div className={classes.root}>
      <Icon />
      <Typography variant="h6">{title}</Typography>
      <Typography color="textSecondary">{subtitle}</Typography>
      <Typography>
        <NavLink to="#" onClick={() => setShowDialog(true)}>
          Learn More
        </NavLink>
      </Typography>
      {showDialog && (
        <Dialog onClose={() => setShowDialog(false)}>
          <Typography variant="h6">{title}</Typography>
          <Typography color="textSecondary">{dialogContent}</Typography>
          <div className={classes.image}>
            <img src={dialogImage} alt="dialog" />
          </div>
        </Dialog>
      )}
    </div>
  )
}

export default ImageBanner
