import React, { useState } from 'react'

import { makeStyles, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import _ from 'lodash'

import Button from 'components/Blocks/CustomButtons/Button'
import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import Loader from 'components/Blocks/Loader'
import ConfirmGroup from 'components/Settings/Team/Groups/ConfirmGroup'
import GeneralSurveyModuleSettings from 'components/Settings/Team/Groups/GeneralSurveyModuleSettings'
import { Survey, Group } from 'components/Settings/Team/Groups/GroupsTypes'
import MemberSettings from 'components/Settings/Team/Groups/MemberSettings'
import NameSettings from 'components/Settings/Team/Groups/NameSettings'
import OverallSettings from 'components/Settings/Team/Groups/OverallSettings'
import SurveyProductModuleSettings from 'components/Settings/Team/Groups/SurveyProductModuleSettings'
import {
  SettingsGroupFragment,
  CurrentUserQuery,
  SurveyProductTypeEnum,
  SettingsGroupUserFragment,
} from 'generated/graphql'
import { getSolutionFromProductType } from 'utils'
import { PRODUCT_TYPE_USER_FIELDS, PRODUCT_TYPE_LABELS } from 'utils/constants'
import { CertificationTypeEnum } from 'utils/generatedEnums'

const useStyles = makeStyles(theme => ({
  container: {
    width: 500,
  },
  title: {
    textTransform: 'uppercase',
    '& >h2': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& >button': {
        padding: 0,
      },
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
}))

// Returns the emails of users with only one group so we can prevent deleting them during group edit
export const getProtectedUserEmails = (groups: (SettingsGroupFragment | Group)[]) => {
  const users: { [key: string]: boolean } = {} // { [email]: hasOnlyOneGroup }
  groups.forEach(group => {
    group.users.forEach(({ email }) => {
      users[email] = !users[email]
    })
  })
  return Object.keys(users).filter(email => users[email])
}

const getDefaultGroup = (group?: SettingsGroupFragment) => {
  return {
    uuid: group?.uuid,
    name: group?.name || '',
    accessToSurveyProduct: group?.accessToSurveyProduct || false,
    canInviteUsers: group?.canInviteUsers || false,
    canAccessDataStructure: group?.canAccessDataStructure || false,
    canCreateSurveys: group?.canCreateSurveys || false,
    canManageActionPlans: group?.canManageActionPlans || false,
    canAccessSurveySettings: group?.canAccessSurveySettings || false,
    canAccessGeneralSettings: group?.canAccessGeneralSettings || false,
    canManageCertifications: group?.canManageCertifications || false,
    canViewCertificationResults: group?.canViewCertificationResults || false,
    canUseActionPlans: group?.canUseActionPlans || false,
    canUseAnalytics: group?.canUseAnalytics || false,
    allSurveys: group?.allSurveys || false,
    surveys: group?.surveys || [],
    insightsModules: group?.insightsModules || [],
    users: group?.users || [],
    residentAccessToSurveyProduct: group?.residentAccessToSurveyProduct || false,
    residentCanCreateSurveys: group?.residentCanCreateSurveys || false,
    residentCanAccessSurveySettings: group?.residentCanAccessSurveySettings || false,
    canAccessLonelinessResults: group?.canAccessLonelinessResults || false,
    residentInsightsModules: group?.residentInsightsModules || [],
  }
}

type Props = {
  surveys: Survey[]
  onClose(): void
  currentUser: CurrentUserQuery['currentUser']
  users: SettingsGroupUserFragment[]
  updateGroup(arg: Partial<Group>): Promise<boolean>
  groupToEdit?: SettingsGroupFragment
  existingGroups: SettingsGroupFragment[]
}

const UpdateGroupDialog: React.FC<Props> = ({
  surveys,
  onClose,
  currentUser,
  users,
  updateGroup: saveGroupToServer,
  groupToEdit,
  existingGroups,
}) => {
  const classes = useStyles()
  const [isSaving, setIsSaving] = useState(false)
  const [showExitModal, setShowExitModal] = useState(false)
  const [group, setGroup] = useState<Group>(getDefaultGroup(groupToEdit))

  // Keep track of if the group has been modified.
  const groupIsEdited = !_.isEqual(getDefaultGroup(groupToEdit), group)

  // `step` dictates which item in the `steps` list we display to the user.
  const [step, setStep] = useState(0)
  const updateStep = (increment: 1 | -1) => {
    let nextStep = step + increment
    while (steps[nextStep].shouldSkip) {
      nextStep += increment
    }
    setStep(nextStep)
  }

  const saveChanges = async () => {
    setIsSaving(true)
    const success = await saveGroupToServer(group)
    setIsSaving(false)
    if (success) {
      setStep(0)
      onClose()
    }
  }

  const { solution, residentSolution } = currentUser.organization
  const hasActionPlans = Boolean(solution?.actionPlans || residentSolution?.actionPlans)
  const hasActionPlansManagement = Boolean(
    solution?.actionPlansManagement || residentSolution?.actionPlansManagement,
  )
  const updateGroup = (update: Partial<Group>) => setGroup({ ...group, ...update })
  const commonProps = { group, updateGroup, updateStep }
  // If we are creating a new group, consider it in part of the groups list for protected emails.
  const existingGroupsList = groupToEdit ? existingGroups : [...existingGroups, group]
  const PRODUCT_TYPE_TO_GROUP_MODULES = {
    [SurveyProductTypeEnum.EMPLOYEE]: group.insightsModules,
    [SurveyProductTypeEnum.RESIDENT]: group.residentInsightsModules,
  }
  const steps = [
    {
      title: 'Name',
      component: <NameSettings key="name" {...commonProps} />,
      shouldSkip: false,
      shouldDisableNext: !group.name,
    },
    {
      title: 'Overall Settings',
      component: (
        <OverallSettings
          key="overall"
          {...commonProps}
          orgHasEmployeeSolution={Boolean(solution)}
          orgHasResidentSolution={Boolean(residentSolution)}
        />
      ),
      shouldSkip: false,
      shouldDisableNext:
        (!group.accessToSurveyProduct && !group.residentAccessToSurveyProduct) ||
        // Can only use analytics when both products are allowed.
        (group.canUseAnalytics &&
          (!group.residentAccessToSurveyProduct || !group.accessToSurveyProduct)),
    },
    {
      title: 'General Survey Permissions',
      component: (
        <GeneralSurveyModuleSettings
          key="generalSurveys"
          surveys={surveys}
          group={group}
          updateGroup={updateGroup}
          hasActionPlans={hasActionPlans}
          hasActionPlansManagement={hasActionPlansManagement}
          hasCertifications={solution?.certificationType !== CertificationTypeEnum.NONE}
        />
      ),
    },
    ...[SurveyProductTypeEnum.EMPLOYEE, SurveyProductTypeEnum.RESIDENT]
      .filter(productType => getSolutionFromProductType(currentUser.organization, productType))
      .map(productType => {
        const groupInsightsModules = PRODUCT_TYPE_TO_GROUP_MODULES[productType]
        const { accessToSurveyProductField } = PRODUCT_TYPE_USER_FIELDS[productType]
        return {
          title: `${PRODUCT_TYPE_LABELS[productType]} Survey Permissions`,
          component: (
            <SurveyProductModuleSettings
              key={productType}
              group={group}
              updateGroup={updateGroup}
              currentUser={currentUser}
              productType={productType}
              groupInsightsModules={groupInsightsModules}
            />
          ),
          shouldSkip: !group[accessToSurveyProductField],
          shouldDisableNext: !groupInsightsModules.length,
        }
      }),
    {
      title: 'Add People',
      component: (
        <MemberSettings
          key="members"
          {...commonProps}
          users={users}
          protectedUserEmails={getProtectedUserEmails(existingGroupsList)}
        />
      ),
    },
    {
      title: 'Confirm',
      component: (
        <ConfirmGroup
          key="confirm"
          confirmType="group"
          {...commonProps}
          title="Team members in this group will be able to:"
          onClose={() => updateStep(-1)}
          onConfirm={saveChanges}
        />
      ),
      shouldSkip: false,
    },
  ]

  let nextButtonLabel = 'Next >'
  if (step >= steps.length - 2) {
    nextButtonLabel = group.uuid ? 'Save Group' : 'Create Group'
  }

  const handleCloseButtonClick = () => {
    if (groupIsEdited) {
      // If the group has been edited, show the exit modal.
      setShowExitModal(true)
    } else {
      // Otherwise, just close the modal.
      closeUpdateGroupDialog()
    }
  }

  const closeUpdateGroupDialog = () => {
    setShowExitModal(false)
    // Wipe progress
    setGroup(getDefaultGroup(undefined))
    setStep(0)
    onClose()
  }

  return (
    <Dialog
      open
      onClose={() => {
        /** Only allow closing the dialog with the cancel button */
      }}
    >
      <div className={classes.container}>
        <DialogTitle className={classes.title}>
          <Typography color="textSecondary">
            {groupToEdit ? 'Update Group' : 'Create a Group'} – {steps[step].title}
          </Typography>
          <IconButton onClick={handleCloseButtonClick}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <>{isSaving ? <Loader /> : steps[step].component}</>
        {step < steps.length - 1 && (
          <div className={classes.dialogActions}>
            {step > 0 ? (
              <Button onClick={() => updateStep(-1)} color="secondaryNoBackground">
                {'< Back'}
              </Button>
            ) : (
              <div />
            )}
            <Button
              disabled={steps[step].shouldDisableNext}
              onClick={() => updateStep(1)}
              color={step < steps.length - 2 ? 'secondaryNoBackground' : 'primary'}
              id="saveGroup"
            >
              {nextButtonLabel}
            </Button>
          </div>
        )}
      </div>
      {showExitModal &&
        (group.uuid ? (
          // On cancelling updating group.
          <ActionDialog
            title="You have unsaved changes"
            content="Do you want to save your information before leaving?"
            submitButtonText="Save"
            cancelButtonText="Exit"
            onClose={closeUpdateGroupDialog}
            onSubmit={() => {
              setShowExitModal(false)
              saveChanges()
            }}
          />
        ) : (
          // On cancelling group creation.
          <ActionDialog
            title="You have unsaved changes"
            content="All changes will be discarded."
            submitButtonText="Go back"
            cancelButtonText="Exit"
            onClose={closeUpdateGroupDialog}
            onSubmit={() => {
              setShowExitModal(false)
            }}
          />
        ))}
    </Dialog>
  )
}

export default UpdateGroupDialog
