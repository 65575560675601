import React, { useState, useEffect } from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import ScoreBasedNotifications, {
  scoreNotificationHasError,
  EmptyAverageScoreNotification,
} from 'components/Survey/Wizard/Steps/Notifications/ScoreBasedNotifications'
import SurveyResponseNotifications, {
  responseNotificationHasError,
} from 'components/Survey/Wizard/Steps/Notifications/SurveyResponseNotifications'
import {
  useSurveysSurveyResponseNotificationsGroupsQuery,
  useSurveysSurveyResponseNotificationsQuery,
  useSurveysUpdateGroupsAccessToSurveyMutation,
  useSurveysUpdateGroupsAverageScoreNotificationsMutation,
  useSurveysUpdateGroupsSurveyResponseNotificationsMutation,
  useSurveysUpdateGroupsQuestionScoreNotificationsMutation,
  GroupSurveyQuestionScoreNotificationInput,
  GroupSurveyAverageScoreNotificationInput,
  SurveysSurveyQuery,
  SurveysSurveyResponseNotificationsGroupsQuery,
  SurveysSurveyResponseNotificationsQuery,
  SurveyScoreNotificationTypeEnum,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'

export const useStyles = makeStyles(({ palette, spacing }) => ({
  groups: {
    display: 'block',
  },
  checked: {
    color: palette.common.secondary,
  },
  unchecked: {
    color: palette.common.navy65,
  },
  responseNotifications: {
    borderTop: `1px solid ${palette.common.navy25}`,
    paddingTop: spacing(4),
    marginTop: spacing(4),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: spacing(),
  },
  selectError: {
    color: palette.common.danger,
    textColor: palette.common.danger,
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    color: palette.common.danger,
    '& >svg': {
      color: palette.common.danger,
      marginRight: spacing(),
    },
  },
  scoresHeader: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  groupsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newNotificationButton: {
    marginLeft: 0,
  },
}))

const getDefaultAverageScoreNotifications = (
  notifications: SurveysSurveyResponseNotificationsQuery['survey']['groupsAverageScoreNotifications'],
) => {
  const scoreTypes = Object.keys(
    SurveyScoreNotificationTypeEnum,
  ) as SurveyScoreNotificationTypeEnum[]

  return scoreTypes.map(scoreType => {
    const notification = notifications.find(n => n.scoreType === scoreType)
    return notification
      ? {
          enabled: notification.enabled,
          scoreType: notification.scoreType,
          frequency: notification.frequency,
          groupUuids: notification.groups.map(g => g.uuid),
        }
      : {
          enabled: false,
          scoreType,
          frequency: null,
          groupUuids: [],
        }
  })
}

const getDefaultQuestionScoreNotifications = (
  notifications: SurveysSurveyResponseNotificationsQuery['survey']['groupsQuestionScoreNotifications'],
) => {
  return notifications.map(notification => ({
    notificationUuid: notification.uuid,
    notificationName: notification.notificationName,
    enabled: notification.enabled,
    scoreType: notification.scoreType,
    frequency: notification.frequency,
    minScore: notification.minScore,
    maxScore: notification.maxScore,
    statementUuid: notification.statement.uuid,
    statementText: notification.statement.text,
    openEndedQuestionUuid: notification.openEndedQuestion?.uuid || '',
    groupUuids: notification.groups.map(g => g.uuid),
  }))
}

type Props = {
  hasSurveyResponseNotifications: boolean
  hasAverageScoreNotifications: boolean
  hasQuestionScoreNotifications: boolean
  survey: SurveysSurveyResponseNotificationsQuery['survey']
  surveyUuid: string
  surveyIsEditable: boolean
  availableGroups: SurveysSurveyResponseNotificationsGroupsQuery['groups']
  goBack?(): void
  goNext?(uuid?: string): void
}

const ResponseNotifications: React.FC<Props> = ({
  hasSurveyResponseNotifications,
  hasAverageScoreNotifications,
  hasQuestionScoreNotifications,
  survey,
  surveyUuid,
  surveyIsEditable,
  availableGroups,
  goBack,
  goNext,
}) => {
  const classes = useStyles()
  const emptyErrors = {
    surveyResponses: '',
    scores: '',
  }
  const [errors, setErrors] = useState(emptyErrors)

  const [groups, setGroups] = useState(survey.groupsWithSurveyAccess)
  const [responseNotificationsInput, setResponseNotificationsInput] = useState(
    survey.groupsSurveyResponseNotifications.map(n => ({
      enabled: n.enabled,
      notificationType: n.notificationType,
      groupUuids: n.groups.map(g => g.uuid),
    })),
  )
  const [averageScoreNotificationsInput, setAverageScoreNotificationsInput] = useState<
    EmptyAverageScoreNotification[]
  >(getDefaultAverageScoreNotifications(survey.groupsAverageScoreNotifications))

  const [questionScoreNotificationsInput, setQuestionScoreNotificationsInput] = useState<
    GroupSurveyQuestionScoreNotificationInput[]
  >(getDefaultQuestionScoreNotifications(survey.groupsQuestionScoreNotifications))

  // This is so that the table is refreshed after Adding/Edditing a question score notification from the dialog
  useEffect(() => {
    setQuestionScoreNotificationsInput(
      getDefaultQuestionScoreNotifications(survey.groupsQuestionScoreNotifications),
    )
  }, [survey.groupsQuestionScoreNotifications])

  const [
    saveGroupsAccess,
    { loading: savingGroupsAccess },
  ] = useSurveysUpdateGroupsAccessToSurveyMutation()

  const [
    saveSurveyResponseNotifications,
    { loading: savingSurveyResponseNotifications },
  ] = useSurveysUpdateGroupsSurveyResponseNotificationsMutation()

  const [
    saveAverageScoreNotifications,
    { loading: savingAverageScoreNotifications },
  ] = useSurveysUpdateGroupsAverageScoreNotificationsMutation()

  const [
    saveQuestionScoreNotifications,
    { loading: savingQuestionScoreNotifications },
  ] = useSurveysUpdateGroupsQuestionScoreNotificationsMutation()

  const onSave = async () => {
    if (responseNotificationsInput.find(n => responseNotificationHasError(n))) {
      setErrors({
        ...errors,
        surveyResponses: 'Please select at least one group for each active notification type.',
      })
      return
    }
    if (
      averageScoreNotificationsInput.find(n => scoreNotificationHasError(n)) ||
      questionScoreNotificationsInput.find(n => scoreNotificationHasError(n))
    ) {
      setErrors({
        ...errors,
        scores:
          'Please select the frequency and at least one group for each active notification type.',
      })
      return
    }
    const result = await saveGroupsAccess({
      variables: {
        surveyUuid,
        groupsUuidsWithSurveyAccess: groups.map(g => g.uuid),
      },
    })

    if (result.data?.updateGroupsAccessToSurvey?.errors?.length) {
      emitter.emit('ERROR', result.data?.updateGroupsAccessToSurvey?.errors)
      return
    }

    const operations = []
    if (hasSurveyResponseNotifications) {
      operations.push(
        saveSurveyResponseNotifications({
          variables: {
            surveyUuid,
            groupsNotificationsInput: responseNotificationsInput,
          },
        }),
      )
    }
    if (hasAverageScoreNotifications) {
      operations.push(
        saveAverageScoreNotifications({
          variables: {
            surveyUuid,
            groupsNotificationsInput: averageScoreNotificationsInput as GroupSurveyAverageScoreNotificationInput[],
          },
        }),
      )
    }
    if (hasQuestionScoreNotifications) {
      operations.push(
        saveQuestionScoreNotifications({
          variables: {
            surveyUuid,
            groupsNotificationsInput: questionScoreNotificationsInput.map(notification => {
              // We are excluding statementText, that's why we are explicitly listing fields
              return {
                enabled: notification.enabled,
                notificationUuid: notification.notificationUuid,
                notificationName: notification.notificationName,
                scoreType: notification.scoreType,
                minScore: notification.minScore,
                maxScore: notification.maxScore,
                frequency: notification.frequency,
                groupUuids: notification.groupUuids,
                statementUuid: notification.statementUuid,
                openEndedQuestionUuid: notification.openEndedQuestionUuid,
              }
            }),
          },
        }),
      )
    }
    await Promise.all(operations)

    setErrors(emptyErrors)
    if (goNext) {
      goNext()
    }
  }

  return (
    <>
      <FormPanel
        expandIcon={EXPAND_ICON.ARROW}
        headerEnd
        gutterHeader={false}
        gutterBottom
        title="Survey Access"
        helpText={
          <>
            Select which groups can access this survey. Only group members with access will be able
            to receive email notifications related to this survey.
          </>
        }
      >
        <Typography variant="h6">Groups*</Typography>
        <CheckboxDropdown
          menuItems={availableGroups.map(group => {
            const isDisabled = group.allSurveys
            let tooltipMessage = ''
            if (isDisabled) {
              tooltipMessage =
                group.name === 'Admin'
                  ? 'The Admin group has already been granted access to this survey.'
                  : 'This group has already been granted access to all future surveys. To update permissions go to Group settings'
            }
            return {
              value: group.uuid,
              text: group.name,
              isDisabled,
              tooltipMessage,
            }
          })}
          selectedItems={groups.map(g => g.uuid)}
          onChange={(selected: string[]) => {
            setGroups(availableGroups.filter(g => selected.includes(g.uuid)))
          }}
          width={210}
          emptyLabel="Select Groups"
          height={43}
          containerClassName={classes.groups}
        />
        <Typography color="textSecondary" variant="body1">
          Note: Selections made here will also be saved in User Permissions.
        </Typography>
        {hasSurveyResponseNotifications && (
          <SurveyResponseNotifications
            survey={survey}
            notificationsInput={responseNotificationsInput}
            setNotificationsInput={setResponseNotificationsInput}
            groups={groups}
            error={errors.surveyResponses}
          />
        )}
        {(hasAverageScoreNotifications || hasQuestionScoreNotifications) && (
          <ScoreBasedNotifications
            survey={survey}
            averageScoreNotificationsInput={averageScoreNotificationsInput}
            setAverageScoreNotificationsInput={setAverageScoreNotificationsInput}
            questionScoreNotificationsInput={questionScoreNotificationsInput}
            setQuestionScoreNotificationsInput={setQuestionScoreNotificationsInput}
            groups={groups}
            error={errors.scores}
          />
        )}
      </FormPanel>
      <div className={classes.footer}>
        <Typography color="textSecondary" variant="body2">
          * Required
        </Typography>
        <NavigationButtons
          goBack={goBack}
          goNext={onSave}
          isSubmitting={
            savingAverageScoreNotifications ||
            savingGroupsAccess ||
            savingQuestionScoreNotifications ||
            savingSurveyResponseNotifications
          }
          goNextLabel={surveyIsEditable ? undefined : 'Save'}
        />
      </div>
    </>
  )
}

type ContainerProps = {
  survey: SurveysSurveyQuery['survey']
  goBack?(): void
  goNext?(uuid?: string): void
}

const ResponseNotificationsContainer: React.FC<ContainerProps> = ({
  survey: initialSurvey,
  goBack,
  goNext,
}) => {
  const surveyNotificationsResult = useSurveysSurveyResponseNotificationsQuery({
    variables: { surveyUuid: initialSurvey.uuid },
  })
  const groupsResult = useSurveysSurveyResponseNotificationsGroupsQuery()
  return (
    <ResponseHandler {...surveyNotificationsResult}>
      {({ survey }) => {
        return (
          <ResponseHandler {...groupsResult}>
            {({ groups }) => {
              return (
                <ResponseNotifications
                  hasSurveyResponseNotifications={initialSurvey.hasSurveyResponseNotifications}
                  hasAverageScoreNotifications={initialSurvey.hasAverageScoreNotifications}
                  hasQuestionScoreNotifications={initialSurvey.hasQuestionScoreNotifications}
                  survey={survey}
                  surveyIsEditable={initialSurvey.editable}
                  surveyUuid={initialSurvey.uuid}
                  availableGroups={groups}
                  goBack={goBack}
                  goNext={goNext}
                />
              )
            }}
          </ResponseHandler>
        )
      }}
    </ResponseHandler>
  )
}

export default ResponseNotificationsContainer
