import React from 'react'

import { useHistory, Redirect } from 'react-router-dom'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { useInsightsDownloadLinkQuery, CurrentUserQuery } from 'generated/graphql'
import { getDefaultLandingURL } from 'utils'

type Props = { currentUser: CurrentUserQuery['currentUser'] }

const DownloadContainer: React.FC<Props> = ({ currentUser }) => {
  /**
   * Component that requests a download link, downloads the URL and redirects to a default page.
   * We use this component in order to make sure requests for a download link are authenticated.
   */

  const history = useHistory()
  const urlParts = history.location.pathname.split('/')
  const downloadUuid = urlParts[urlParts.length - 1]
  const result = useInsightsDownloadLinkQuery({ variables: { uuid: downloadUuid } })

  return (
    <ResponseHandler {...result}>
      {({ downloadLink }) => {
        if (downloadLink) {
          window.location.href = downloadLink
        }
        return <Redirect to={getDefaultLandingURL(currentUser)} />
      }}
    </ResponseHandler>
  )
}

export default DownloadContainer
