import React, { ReactElement } from 'react'

import { Input, makeStyles } from '@material-ui/core'
import { SelectProps } from '@material-ui/core/Select'
import cn from 'classnames'

import Dropdown from 'components/Blocks/Dropdowns/Dropdown'

/** Dropdown styled to look like plain text (no background) with configurable colors */
const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
  },
  dropdownInput: {
    padding: 0,
  },
}))

export type Props = {
  label?: ReactElement
  dropdownTextClass?: string
} & SelectProps
const TextDropdown: React.FC<Props> = ({
  label,
  dropdownTextClass,
  renderValue,
  ...dropdownProps
}) => {
  const classes = useStyles()
  return (
    <div className={classes.row}>
      {label}
      <Dropdown
        {...dropdownProps}
        // Used so we render a text string instead of the MenuItem input
        renderValue={renderValue || ((val: any) => val)}
        input={
          <Input
            inputProps={{
              className: cn(classes.dropdownInput, dropdownTextClass),
            }}
            disableUnderline
          />
        }
      />
    </div>
  )
}

export default TextDropdown
