import React, { useState } from 'react'

import {
  Collapse,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import cn from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'

import logoMini from 'shared/authenticated/assets/ai-logo-mini.svg'
import logo from 'shared/authenticated/assets/ai-logo.svg'
import UserNotifications from 'shared/authenticated/UserNotifications/UserNotifications'
import { ORG_SETTINGS } from 'utils/constants'

const drawerWidth = 260
const drawerMiniWidth = 80
const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
}
const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    '@media print': {
      display: 'none',
    },
    border: 'none',
    position: 'fixed',
    top: '70px', // this is the height of the global nav bar
    bottom: '0',
    left: '0',
    zIndex: '1032',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: '#FFFFFF',
    ...boxShadow,
    width: drawerWidth,
    '&:after': {
      background: '#FFFFFF',
      opacity: '1',
    },
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: 0,
      left: 'auto',
      zIndex: 1032,
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: 3,
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: 0,
    },
  },
  drawerPaperMini: {
    width: `${drawerMiniWidth}px!important`,
  },
  logoWrapper: {
    padding: '15px 0px',
    margin: 0,
    display: 'block',
    position: 'relative',
    zIndex: 4,
    borderBottomWidth: '.5px',
    borderBottomColor: 'rgba(15, 50, 70, 0.25)',
    borderBottomStyle: 'solid !important',
  },
  logo: {
    transition: 'all 300ms linear',
    opacity: 1,
    float: 'left',
    textAlign: 'center',
    display: 'inline-block',
    marginLeft: 22,
    marginRight: 18,
    marginTop: 7,
    color: 'inherit',
    '& >img': {
      verticalAlign: 'middle',
      border: 0,
    },
  },
  background: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    display: 'block',
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 300ms linear',
  },
  divider: {
    backgroundColor: 'hsla(0,0%,100%,.3)',
  },
  linksList: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  item: {
    color: 'inherit',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: '0',
  },
  userItem: {
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  itemLink: {
    color: theme.palette.common.navy,
    paddingLeft: 5,
    paddingRight: 10,
    transition: 'all 300ms linear',
    margin: '10px 15px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    backgroundColor: 'transparent',
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  itemIcon: {
    color: theme.palette.common.navy,
    filter:
      'brightness(0) saturate(100%) invert(16%) sepia(12%) saturate(3798%) hue-rotate(162deg) brightness(89%) contrast(92%)',
    width: 30,
    height: 24,
    float: 'left',
    position: 'inherit',
    top: 3,
    marginRight: 5,
    minWidth: 0,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.8,
  },
  activeIcon: {
    filter:
      'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(321deg) brightness(105%) contrast(102%)',
    color: '#FFFFFF',
  },
  itemText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.common.navy,
    margin: '0',
    lineHeight: '30px',
    fontSize: '19px',
    transform: 'translate3d(0px, 0, 0)',
    opacity: 1,
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative',
    height: 'auto',
    whiteSpace: 'nowrap',
  },
  itemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: 0,
  },
  itemTextActive: {
    color: '#FFFFFF',
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  collapseList: {
    marginTop: 0,
  },
  collapseItem: {
    color: theme.palette.common.navy,
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '10px 0 0 0',
    padding: 0,
  },
  collapseActive: {
    outline: 'none',
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    boxShadow: 'none',
  },
  collapseItemLink: {
    transition: 'all 300ms linear',
    margin: '0 15px',
    borderRadius: 3,
    position: 'relative',
    display: 'block',
    padding: 10,
    backgroundColor: 'transparent',
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  collapseItemMini: {
    textTransform: 'uppercase',
    width: '30px',
    marginRight: '15px',
    textAlign: 'center',
    letterSpacing: '1px',
    position: 'relative',
    float: 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  },
  collapseItemMiniHide: {
    color: 'transparent',
  },
  collapseItemText: {
    color: 'inherit',
    margin: 0,
    position: 'relative',
    transform: 'translateX(0px)',
    opacity: 1,
    whiteSpace: 'nowrap',
    display: 'block',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: 14,
  },
  collapseItemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: 0,
  },
  caret: {
    color: theme.palette.common.navy,
    marginRight: 18,
    transition: 'all 150ms ease-in`',
    width: 0,
    height: 0,
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },
  caretActive: {
    transform: 'rotate(180deg)',
  },
  linkActive: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.common.secondary,
      boxShadow:
        '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
    },
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 150px)', // navbar and logo
    overflow: 'auto',
    width: 260,
    zIndex: 4,
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    paddingBottom: 30,
    backgroundColor: '#FFFFFF',
  },
  sidebarWrapperWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
  footer: {
    paddingBottom: '15px',
  },
}))

export const isActiveRoutePath = (currentPathname, routePath) => {
  const condition1 = currentPathname.startsWith(routePath)
  // Second condition is because if the currentPathname is /action-plans/uuid, we don't want to mark the /action-plan route as active
  const condition2 = currentPathname.substr(routePath.length, 1) === '/'
  return currentPathname === routePath || (condition1 && condition2)
}

const SidebarLinks = ({ user, miniActive, toggleCollapse, isCollapsed, routes, footerRoutes }) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const [showNotificationsDialog, setShowNotificationsDialog] = useState(false)
  const residentSolution = user.organization.residentSolution

  const renderLink = route => {
    if (route.redirect || !route.name) {
      return null
    }
    if (!route.collapse) {
      return (
        <ListItem key={route.path} className={classes.item}>
          <NavLink
            to={route.teaser ? '#' : route.path}
            disabled={!!route.teaser}
            className={cn(classes.itemLink, {
              [classes.linkActive]: isActiveRoutePath(pathname, route.path),
            })}
          >
            <ListItemIcon
              className={cn(classes.itemIcon, {
                [classes.activeIcon]: isActiveRoutePath(pathname, route.path),
              })}
            >
              <route.icon />
            </ListItemIcon>
            <Tooltip title={route.teaser || ''}>
              <ListItemText
                primary={route.name}
                disableTypography
                className={cn(classes.itemText, {
                  [classes.itemTextMini]: miniActive,
                  [classes.itemTextActive]: isActiveRoutePath(pathname, route.path),
                })}
              />
            </Tooltip>
          </NavLink>
        </ListItem>
      )
    }
    const collapseActive = !isCollapsed(route.name)
    return (
      <ListItem key={route.path} className={classes.item}>
        <NavLink
          to="#"
          className={cn(classes.itemLink, {
            [classes.collapseActive]: collapseActive,
          })}
          onClick={() => toggleCollapse(route.name)}
        >
          <ListItemIcon className={classes.itemIcon}>
            <route.icon />
          </ListItemIcon>
          <div className={classes.itemRow}>
            <ListItemText
              primary={route.name}
              disableTypography
              className={cn(classes.itemText, {
                [classes.itemTextMini]: miniActive,
              })}
            />
            <b className={cn(classes.caret, { [collapseActive]: classes.caretActive })} />
          </div>
        </NavLink>
        <Collapse in={collapseActive} unmountOnExit>
          <List className={cn(classes.list, classes.collapseList)}>
            {route.subroutes.map(subroute => {
              if (subroute.redirect) {
                return null
              }
              const subCollapseActive = !isCollapsed(subroute.name)
              return subroute.collapse ? (
                <ListItem key={subroute.path} className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={classes.collapseItemLink}
                    onClick={() => toggleCollapse(subroute.name)}
                  >
                    <span
                      className={cn(classes.collapseItemMini, {
                        [classes.collapseItemMiniHide]: !miniActive,
                      })}
                    >
                      {subroute.mini}
                    </span>
                    <div className={classes.itemRow}>
                      <ListItemText
                        primary={subroute.name}
                        disableTypography
                        className={cn(classes.collapseItemText, {
                          [classes.collapseItemTextMini]: miniActive,
                        })}
                      />
                      <b
                        className={cn(classes.caret, {
                          [subCollapseActive]: classes.caretActive,
                        })}
                      />
                    </div>
                  </NavLink>
                  <Collapse in={subCollapseActive} unmountOnExit>
                    <List className={classes.collapseList}>
                      {subroute.subroutes.map(innerRoute => {
                        return (
                          <ListItem key={innerRoute.path} className={classes.collapseItem}>
                            <NavLink
                              to={innerRoute.path}
                              className={cn(classes.collapseItemLink, {
                                [classes.linkActive]: isActiveRoutePath(pathname, innerRoute.path),
                              })}
                            >
                              <span
                                className={cn(classes.collapseItemMini, {
                                  [classes.collapseItemMiniHide]: !miniActive,
                                })}
                              >
                                {innerRoute.mini}
                              </span>
                              <ListItemText
                                primary={innerRoute.name}
                                disableTypography
                                className={cn(classes.collapseItemText, {
                                  [classes.collapseItemTextMini]: miniActive,
                                })}
                              />
                            </NavLink>
                          </ListItem>
                        )
                      })}
                    </List>
                  </Collapse>
                </ListItem>
              ) : (
                <Tooltip key={subroute.path} title={subroute.teaser || ''}>
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to={subroute.teaser ? '#' : subroute.path}
                      className={cn(classes.collapseItemLink, {
                        [classes.linkActive]: isActiveRoutePath(pathname, subroute.path),
                      })}
                    >
                      <span
                        className={cn(classes.collapseItemMini, {
                          [classes.collapseItemMiniHide]: !miniActive,
                        })}
                      >
                        {subroute.mini}
                      </span>
                      <ListItemText
                        primary={subroute.name}
                        disableTypography
                        className={cn(classes.collapseItemText, {
                          [classes.collapseItemTextMini]: miniActive,
                        })}
                      />
                    </NavLink>
                  </ListItem>
                </Tooltip>
              )
            })}
            {route.path === ORG_SETTINGS &&
              residentSolution &&
              (residentSolution.hasSurveyResponseNotifications ||
                residentSolution.hasScoreNotifications) && (
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    id="notifications"
                    to="#"
                    className={cn(classes.collapseItemLink)}
                    onClick={() => setShowNotificationsDialog(true)}
                  >
                    <span
                      className={cn(classes.collapseItemMini, {
                        [classes.collapseItemMiniHide]: !miniActive,
                      })}
                    >
                      N
                    </span>
                    <ListItemText
                      primary="Notifications"
                      disableTypography
                      className={cn(classes.collapseItemText, {
                        [classes.collapseItemTextMini]: miniActive,
                      })}
                    />
                  </NavLink>
                </ListItem>
              )}
          </List>
        </Collapse>
      </ListItem>
    )
  }
  return (
    <List className={classes.linksList}>
      <div>{routes.map(renderLink)}</div>
      <div className={classes.footer}>
        <Divider className={classes.divider} />
        {footerRoutes.map(renderLink)}
      </div>
      {showNotificationsDialog && (
        <div className={classes.notifications}>
          <UserNotifications onClose={() => setShowNotificationsDialog(false)} />
        </div>
      )}
    </List>
  )
}

const SidebarWrapper = ({ user, backgroundImage, miniActive, routes, footerRoutes }) => {
  const classes = useStyles()
  const [openItems, setOpenItems] = useState({})

  const toggleCollapse = name => {
    if (name in openItems) {
      openItems[name] = !openItems[name]
    } else {
      openItems[name] = false
    }
    setOpenItems(openItems)
  }

  const isCollapsed = name => {
    // Might not have been touched yet
    if (name in openItems) {
      return openItems[name]
    }
    return true
  }

  return (
    <>
      <div className={classes.logoWrapper}>
        <NavLink to="#" className={classes.logo}>
          <img src={miniActive ? logoMini : logo} alt="Activated Insights logo" />
        </NavLink>
      </div>
      <div
        className={cn(classes.sidebarWrapper, {
          [classes.drawerPaperMini]: miniActive,
          [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
        })}
      >
        <SidebarLinks
          miniActive={miniActive}
          toggleCollapse={toggleCollapse}
          isCollapsed={isCollapsed}
          routes={routes}
          footerRoutes={footerRoutes}
          user={user}
        />
      </div>
      <div className={classes.background} style={{ backgroundImage: `url(${backgroundImage})` }} />
    </>
  )
}

const Sidebar = ({ miniActive, open, onClose, ...restProps }) => {
  const classes = useStyles()
  return (
    <>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          classes={{
            paper: cn(classes.drawerPaper, {
              [classes.drawerPaperMini]: miniActive,
            }),
          }}
          onClose={onClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.navSpacer} />
          <SidebarWrapper miniActive={miniActive} {...restProps} />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: cn(classes.drawerPaper, {
              [classes.drawerPaperMini]: miniActive,
            }),
          }}
        >
          <SidebarWrapper miniActive={miniActive} {...restProps} />
        </Drawer>
      </Hidden>
    </>
  )
}

export default Sidebar
