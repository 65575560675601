import React, { useState } from 'react'

import { MenuItem, Typography, makeStyles } from '@material-ui/core'

import SingleBarBreakdownChart from 'components/Blocks/Charts/SingleBarBreakdownChart'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Table from 'components/Blocks/Table'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  BenchmarkCodeType,
  InsightsSurveyQuery,
  useInsightsCoreQResultsQuery,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { formatTooltipScore } from 'utils'
import { MIN_SHOWABLE_RESULTS_CODE } from 'utils/constants'

const useStyles = makeStyles(({ spacing }) => ({
  summaryScore: {
    marginLeft: spacing(5),
    marginRight: spacing(5),
    marginTop: spacing(3),
    marginBottom: spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& >div:nth-child(1)': {
      width: '40%',
      marginRight: '10%',
    },
    '& >div:nth-child(2)': {
      width: '60%',
    },
  },
  tableRow: {
    fontSize: '1.5rem',
    padding: spacing(1),
  },
  tooltip: {
    padding: spacing(2),
  },
}))

export const CoreQTooltip: React.FC<{ limitToCoreQEligible: boolean }> = ({
  limitToCoreQEligible,
}) => {
  const classes = useStyles()
  if (limitToCoreQEligible) {
    return (
      <div className={classes.tooltip}>
        <Typography>CoreQ Eligible Results Criteria</Typography>
        <Typography color="textSecondary">
          For your CoreQ Eligible Results, your organization/location must meet these specific
          qualifications, as listed in the CoreQ Satisfaction Questionnaire & User’s Manual:
        </Typography>
        <br />
        <Typography>MINIMUM SAMPLE SIZE</Typography>
        <Typography color="textSecondary">
          An organization/location must have a minimum of 20 valid responses (e.g. the denominator
          must be {'>'} 20); we will accept results with {'<'} 20 valid responses but will not
          include them in any aggregate calculations or analyses. A valid response is considered to
          be from a respondent who:
          <ul>
            <li>Has all items answered or only one question missing on the CoreQ; AND</li>
            <li>Does not meet any of the exclusion criteria; AND</li>
            <li>Responses received within two months of the person being sent the survey.</li>
          </ul>
        </Typography>
        <Typography>IMPUTING MISSING DATA</Typography>
        <Typography color="textSecondary">
          If information from one question is missing, we will impute missing data by taking the
          average of the other two (or three) questions. If there is missing information for two or
          more questions; the respondent’s information is excluded.
        </Typography>
        <br />
        <Typography>MINIMUM RESPONSE RATE</Typography>
        <Typography color="textSecondary">
          An organization/location must also have a minimum response rate of valid responses of at
          least 30 percent or greater. The response rate is calculated by counting all the valid
          responses (see above) divided by the number of people who were given the survey to
          complete. We will accept results with response rates less than 30 percent but will exclude
          those rates in any aggregate analyses or calculations.
        </Typography>
      </div>
    )
  }
  return (
    <div className={classes.tooltip}>
      <Typography>How is CoreQ calculated?</Typography>
      <Typography color="textSecondary">
        The CoreQ questions use a 5-point scale: Poor (1), Average (2), Good (3), Very Good (4),
        Excellent (5). To calculate the percent satisfied we take all the respondents with an
        average positive score of 3.0 or greater, and divide them by the total number of
        respondents.
      </Typography>
    </div>
  )
}

type Props = {
  survey: InsightsSurveyQuery['survey']
  filters: string[]
  startDate?: string
  endDate?: string
}
export enum CoreQDropdownOptions {
  ALL = 'All Responses',
  LIMITED = 'Core Q Eligible Results',
}
const CoreQResultsCard: React.FC<Props> = ({ survey, filters, startDate, endDate }) => {
  const classes = useStyles()
  const [dropdownOption, setDropdownOption] = useState(CoreQDropdownOptions.ALL)
  const limitToCoreQEligible = dropdownOption === CoreQDropdownOptions.LIMITED
  const result = useInsightsCoreQResultsQuery({
    variables: {
      surveyUuid: survey.uuid,
      limitToCoreQEligible,
      filters,
      startDate,
      endDate,
      statementCodes: [BenchmarkCodeType.CORE_Q_SUMMARY],
    },
  })
  const title = limitToCoreQEligible ? 'COREQ ELIGIBLE RESULTS SUMMARY' : 'COREQ RESULTS SUMMARY'
  return (
    <>
      <SnapshotChartHeader
        title={title}
        snapId="core-q-results-summary"
        screenshotStrategy="html2canvas"
        description={
          <>
            The positive answers to the CoreQ questions are averaged and combined to create a single
            percent, which represents your CoreQ Satisfaction Score, letting you know how many were
            satisfied with their experience. For a summary on how we calculate the results, select
            the info icon. To read the full details, please see the CoreQ Satisfaction Questionnaire
            and{' '}
            <ExternalNavLink
              to="http://www.coreq.org/CoreQ%20Satisfaction%20Questionnaire%20and%20User%20Manual.pdf"
              label="User’s Manual."
            />
          </>
        }
        tooltip={<CoreQTooltip limitToCoreQEligible={limitToCoreQEligible} />}
        extraControls={
          <TextDropdown
            id="core-q-results-dropdown"
            value={dropdownOption}
            renderValue={val => `Show: ${val}`}
            onChange={e => {
              setDropdownOption(e.target.value as CoreQDropdownOptions)
            }}
          >
            {Object.values(CoreQDropdownOptions).map(val => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              )
            })}
          </TextDropdown>
        }
      />
      <ResponseHandler {...result}>
        {({ insightsCoreQResults, insightsOverallIndex: summaryScore }) => {
          const columns = [{ label: 'CoreQ Questions' }, { label: 'Percent Satisfied' }]
          const rows = insightsCoreQResults.map(score => [
            {
              name: 'label',
              value: <Typography className={classes.tableRow}>{score.label}</Typography>,
            },
            {
              name: 'score',
              value: (
                <Typography className={classes.tableRow}>
                  {score.count > 0
                    ? formatTooltipScore(
                        score.positive,
                        survey.minShowableResults,
                        null,
                        score.positive === MIN_SHOWABLE_RESULTS_CODE,
                      )
                    : 'N/A'}
                </Typography>
              ),
            },
          ])
          const lessThanMin = summaryScore.positive === MIN_SHOWABLE_RESULTS_CODE
          let scores = [summaryScore.positive, summaryScore.negative]
          if (lessThanMin) {
            scores = [50]
          }
          let scoreText = 'No Matching Responses'
          let description = 'Please select different filters for better results.'
          const isLessThanMinResponses = summaryScore.positive === MIN_SHOWABLE_RESULTS_CODE
          if (summaryScore.count) {
            scoreText = String(
              formatTooltipScore(
                summaryScore.positive,
                survey.minShowableResults,
                null,
                isLessThanMinResponses,
              ),
            )
            description = 'Of respondents surveyed were satisfied with their experience.'

            if (isLessThanMinResponses) {
              description = `This survey has less than ${survey.minShowableResults} responses. We can't show their responses to protect confidentiality.`
            }
          }
          return (
            <div>
              <div className={classes.summaryScore}>
                <div>
                  <Typography>COREQ SATISFACTION SCORE</Typography>
                  <Typography variant="h4">{scoreText}</Typography>
                  <Typography color="textSecondary">{description}</Typography>
                </div>
                {summaryScore.count && summaryScore.count > 0 && (
                  <SingleBarBreakdownChart
                    lessThanMin={lessThanMin}
                    scores={scores}
                    minShowableResults={survey.minShowableResults}
                    barColors={[colors.success, colors.danger]}
                    barSize={28}
                    labelPosition="inside"
                    legends={
                      isLessThanMinResponses ? [] : ['Positive % (3 to 5)', 'Negative % (1 - 2)']
                    }
                  />
                )}
              </div>
              <Table totalRows={rows.length} columns={columns} rows={rows} bicolor />
            </div>
          )
        }}
      </ResponseHandler>
    </>
  )
}

export default CoreQResultsCard
