import React, { useState, useContext } from 'react'

import { makeStyles, Paper, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import { useHistory } from 'react-router-dom'

import Button from 'components/Blocks/CustomButtons/Button'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import StatusTag from 'components/Blocks/Tags/StatusTag'
import DeleteSurveyDialog from 'components/Survey/Blocks/DeleteSurveyDialog'
import SurveyPreview from 'components/Survey/Wizard/SurveyPreview'
import { getShowReportBy } from 'components/Survey/Wizard/SurveyResponseRateCard'
import { StoreContext } from 'config/LocalStorage'
import UpdateTranslationsButton from 'containers/Survey/Wizard/Steps/Questions/UpdateTranslationsButton'
import {
  SurveyStatusEnum,
  SurveysSurveyQuery,
  ResponseRateTypeEnum,
  SurveyDistributionTypeEnum,
} from 'generated/graphql'
import { URLS } from 'utils/constants'

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    marginTop: 0,
    marginBottom: spacing(2),
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    paddingLeft: spacing(3),
    '& button': {
      paddingLeft: spacing(),
      paddingRight: spacing(),
      float: 'right',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  controlsRow: {
    display: 'flex',
  },
}))

type Props = {
  survey: SurveysSurveyQuery['survey']
  showPreview?: boolean
  showTranslations?: boolean
  isOpenTranslationsDialog?: boolean
  setIsOpenTranslationsDialog?(isOpen: boolean): void
}

const SurveyControls: React.FC<Props> = ({
  survey,
  isOpenTranslationsDialog,
  setIsOpenTranslationsDialog,
  showPreview = false,
  showTranslations = false,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [isDeleteSurveyDialogOpen, setIsDeleteSurveyDialogOpen] = useState(false)
  const [isOpenPreview, setIsOpenPreview] = useState(false)

  const draftOrScheduled = [SurveyStatusEnum.DRAFT, SurveyStatusEnum.SCHEDULED].includes(
    survey.status,
  )

  const {
    store: { responseRateShowReportBy },
  } = useContext(StoreContext)

  const showReportBy =
    survey &&
    getShowReportBy(
      responseRateShowReportBy,
      survey.uuid,
      survey.isDefaultClientBasedResponseRateReport,
    )
  const responseRateByClient = showReportBy === ResponseRateTypeEnum.CLIENT
  return (
    <Paper className={classes.header}>
      {isDeleteSurveyDialogOpen && (
        <DeleteSurveyDialog
          onDelete={() => {
            setIsDeleteSurveyDialogOpen(false)
            history.push(URLS.SURVEYS.DASHBOARD)
          }}
          onClose={() => {
            setIsDeleteSurveyDialogOpen(false)
          }}
          surveyUuid={survey.uuid}
        />
      )}
      <GridContainer direction="row-reverse">
        {draftOrScheduled && (
          <ItemGrid className={classes.actions} sm={8}>
            {survey.status === SurveyStatusEnum.DRAFT && (
              <>
                {showTranslations && survey.translationsRequired && setIsOpenTranslationsDialog && (
                  <UpdateTranslationsButton
                    surveyUuid={survey.uuid}
                    surveyLanguages={survey.languages}
                    responseRateByClient={responseRateByClient}
                    openControl="button"
                    isOpenTranslationsDialog={isOpenTranslationsDialog}
                    setIsOpenTranslationsDialog={setIsOpenTranslationsDialog}
                  />
                )}
                <Button
                  color="textSecondaryNoBackground"
                  onClick={() => setIsDeleteSurveyDialogOpen(true)}
                >
                  Delete Draft&nbsp;
                  <DeleteIcon />
                </Button>
              </>
            )}
            {showPreview && (
              <>
                <Button color="textSecondaryNoBackground" onClick={() => setIsOpenPreview(true)}>
                  Preview&nbsp;
                  <RemoveRedEyeIcon />
                </Button>
                {isOpenPreview && (
                  <SurveyPreview survey={survey} onClose={() => setIsOpenPreview(false)} />
                )}
              </>
            )}
          </ItemGrid>
        )}
        <ItemGrid sm={draftOrScheduled ? 4 : 12}>
          <Typography variant="h5">{survey.name}</Typography>
          {survey.status && (
            <StatusTag
              status={survey.status}
              isOpenLinkSurvey={survey.distributionType === SurveyDistributionTypeEnum.OPEN}
            />
          )}
        </ItemGrid>
      </GridContainer>
    </Paper>
  )
}

export default SurveyControls
