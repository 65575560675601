import React from 'react'

import { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import StandardTooltip, {
  TooltipRow,
} from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import { EchartsScore, ScoreTypeGroups } from 'components/Insights/TimeTrending/utils'
import { colors } from 'shared/theme'
import { formatTooltipBenchmarkScore, formatTooltipScore } from 'utils'
import { getFormattedSurveyDate } from 'utils/dateUtils'
import {
  timetrendXAxis,
  surveysYAxis,
  gridLines,
  chartLabels,
  benchmarkLine,
  surveysTooltip,
  getBenchmarkLegendData,
  lineSeriesVars,
  TimeTrendXAxisLabelData,
} from 'utils/echartsHelpers'

const tooltipFormatter = (
  dataIndex: number,
  statementScores: EchartsScore[],
  xAxisLabelData: TimeTrendXAxisLabelData,
  companyLegendName: string,
  benchmarkName: string,
  benchmarkScore?: number | null,
  companyOverallScores?: ScoreTypeGroups[0],
) => {
  const xAxisData = xAxisLabelData[dataIndex]
  const statementScore = statementScores[dataIndex][1]
  const lastStatementScore = dataIndex > 0 ? statementScores[dataIndex - 1][1] : null
  const rows: TooltipRow[] = [
    {
      label: 'Statement Score:',
      value: formatTooltipScore(statementScore, xAxisData.minShowableResults),
      color: colors.success,
    },
  ]
  if (lastStatementScore !== null) {
    rows.push({
      label: `Score Change:`,
      value: formatTooltipScore(statementScore, xAxisData.minShowableResults, lastStatementScore),
    })
  }
  if (companyOverallScores) {
    rows.push({
      label: `${companyLegendName}:`,
      value: formatTooltipScore(companyOverallScores[dataIndex][1], xAxisData.minShowableResults),
    })
  }
  rows.push({
    label: `${benchmarkName}:`,
    value: formatTooltipBenchmarkScore(benchmarkScore),
  })
  return renderEchartTooltip(
    <StandardTooltip
      title={getFormattedSurveyDate({ ...xAxisData, includeDay: true })}
      description={`Survey: ${xAxisData.name}`}
      rows={rows}
    />,
  )
}

type Props = {
  xAxisLabelData: TimeTrendXAxisLabelData
  statementScores: EchartsScore[]
  companyOverallScores?: ScoreTypeGroups[0] // Only the positive scores.
  benchmarkName: string
  showLegend: boolean
  benchmarkScore?: number | null
  didSelectFilters: boolean
  gridWidth?: string
  gridDimensions?: {
    top: number
    bottom: number
    left: number
    right: number
  }
}
const TTBasicStatementChart: React.FC<Props> = ({
  xAxisLabelData,
  statementScores,
  companyOverallScores,
  benchmarkScore,
  benchmarkName,
  showLegend,
  didSelectFilters,
  gridWidth = '100%',
  gridDimensions = { top: 15, bottom: 50, left: 100, right: 25 },
}) => {
  if (statementScores.length === 0) {
    return null
  }
  const companyLegendName = didSelectFilters ? 'Selected Filters' : 'Company Overall'
  const legendData = getBenchmarkLegendData(companyLegendName, benchmarkName)
  const lastSurveyScore = statementScores[statementScores.length - 1]
    ? statementScores[statementScores.length - 1][1]
    : null
  return (
    <PrintableEchart
      notMerge
      printWidth={300}
      style={{ width: gridWidth, height: 120 }}
      opts={{ renderer: 'svg' }} // Useful for selecting DOM elements in UItests
      option={{
        grid: gridDimensions,
        textStyle: chartTextStyle,
        xAxis: timetrendXAxis({ axisLabelData: xAxisLabelData, useDateLabel: true }),
        yAxis: surveysYAxis({ splitNumber: 1 }),
        legend: {
          show: showLegend,
          data: legendData,
          align: 'right',
          top: 0,
          right: 0,
          textStyle: chartTextStyle,
          width: '100%',
          // up, right, down, left
          padding: [0, 20, 40, 0],
        },
        series: [
          {
            data: statementScores,
            name: legendData[0].name,
            ...lineSeriesVars,
            color: colors.success,
          },
          companyOverallScores && {
            data: companyOverallScores,
            ...lineSeriesVars,
            name: companyLegendName,
            color: colors.navy,
          },
          benchmarkLine({
            color: colors.navy,
            name: benchmarkName,
            value: benchmarkScore,
            type: 'dashed',
            showLabel: true,
          }),
          gridLines({ numCategories: xAxisLabelData.length }),
          chartLabels({
            labels: [
              { value: benchmarkScore, color: colors.navy },
              // Last survey score
              { value: lastSurveyScore, color: colors.navy },
            ],
            minLabelOverlap: 18,
          }),
        ],
        color: [colors.success, '#b3edc2'],
        tooltip: {
          ...surveysTooltip({}),
          formatter: (series: Array<{ dataIndex: number }>) => {
            return tooltipFormatter(
              series[0].dataIndex,
              statementScores,
              xAxisLabelData,
              companyLegendName,
              benchmarkName,
              benchmarkScore,
              companyOverallScores,
            )
          },
        },
      }}
    />
  )
}

export default TTBasicStatementChart
