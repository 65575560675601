import React from 'react'

import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Downshift from 'downshift'
import deburr from 'lodash/deburr'

// This file is mostly copy-pasted from the MUI example on Downshift.
// See: https://material-ui.com/components/autocomplete/

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
    },
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
    inputInput: {
      flexGrow: 1,
    },
    divider: {
      height: theme.spacing(2),
    },
  }),
)

interface Suggestion {
  label: string
  disabled?: boolean
}

type RenderInputProps = TextFieldProps & {
  classes: ReturnType<typeof useStyles>
  ref?: React.Ref<HTMLDivElement>
}

function renderInput(inputProps: RenderInputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  )
}

interface RenderSuggestionProps {
  highlightedIndex: number | null
  index: number
  itemProps: MenuItemProps<'div', { button?: never }>
  selectedItem: Suggestion['label']
  suggestion: Suggestion
}

function renderSuggestion(suggestionProps: RenderSuggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      disabled={suggestion.disabled}
    >
      {suggestion.label}
    </MenuItem>
  )
}

function getSuggestions(
  suggestions: Suggestion[],
  value: string,
  { showEmpty = false, showEmptyMessage = true } = {},
) {
  const inputValue = deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length
  let count = 0
  let results = suggestions.filter(suggestion => {
    const keep = count < 5 && suggestion.label.includes(inputValue)

    if (keep) {
      count += 1
    }

    return keep
  })
  if (!results.length && showEmptyMessage) {
    results = [{ label: 'No results found', disabled: true }]
  }
  return inputLength === 0 && !showEmpty ? [] : results
}

type Props = {
  placeholder?: string
  label: string
  suggestions: Suggestion[]
  fullWidth?: boolean
  width?: number
  value: string
  onChange(arg: string): void
  onSelect(arg: string): void
}
const IntegrationDownshift: React.FC<Props> = ({
  placeholder,
  label,
  suggestions,
  value,
  onChange,
  onSelect,
}) => {
  const classes = useStyles()

  return (
    <Downshift
      inputValue={value}
      onInputValueChange={inputValue => onChange(inputValue)}
      onSelect={val => onSelect(val)}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
      }) => {
        const { onBlur, onFocus, ...inputProps } = getInputProps({ placeholder })
        return (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              label,
              InputLabelProps: getLabelProps({ shrink: true } as any),
              InputProps: { onBlur, onFocus },
              inputProps,
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(suggestions, inputValue!).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.label }),
                      highlightedIndex,
                      selectedItem,
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )
      }}
    </Downshift>
  )
}

export default IntegrationDownshift
