import React, { useState } from 'react'

import { makeStyles, Typography, MenuItem } from '@material-ui/core'

import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { CoreQDropdownOptions } from 'components/Insights/Discharge/CoreQResultsCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  ChartTimeRange,
  getDeltaAndText,
} from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import StandardTooltip from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import {
  Chart as TTChart,
  ChartType as TTChartType,
} from 'components/Insights/TimeTrending/TTIndexScoreResponseCard'
import { getOverallScoreGroups } from 'components/Insights/TimeTrending/utils'
import { BenchmarkCodeType, useInsightsOverallIndexQuery } from 'generated/graphql'
import DynamicTimeframeQuery from 'HOC/DynamicTimeframeQuery'
import { formatTooltipScore } from 'utils'
import { getFormattedSurveyDate } from 'utils/dateUtils'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
  },
  chart: {
    marginTop: spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoPercentageText: {
    '& >span': {
      marginLeft: -14,
    },
  },
}))

type Props = {
  survey: InsightsSurvey
  filters: string[]
  timeRanges: ChartTimeRange[]
}
const TimeframeCoreQResultsCard: React.FC<Props> = ({ survey, filters, timeRanges }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [dropdownOption, setDropdownOption] = useState(CoreQDropdownOptions.ALL)
  const limitToCoreQEligible = dropdownOption === CoreQDropdownOptions.LIMITED
  // Fetch company overall without timeframe to use as a benchmark.
  const overallCoreQResult = useInsightsOverallIndexQuery({
    variables: {
      filters,
      surveyUuid: survey.uuid,
      limitToCoreQEligible,
      statementCodes: [BenchmarkCodeType.CORE_Q_SUMMARY],
    },
  })
  return (
    <div className={classes.fullRow} id="timeframe-coreq-snap">
      <SnapshotChartHeader
        title="CoreQ Satisfaction Over Time"
        snapId="timeframe-coreq-snap"
        screenshotStrategy="html2canvas"
        tooltip="See how your CoreQ satisfaction
        score has changed for each time period. On the left you can see
        the change from the first to the last time period in the selected time frame."
        extraControls={
          <TextDropdown
            id="core-q-results-dropdown"
            value={dropdownOption}
            renderValue={val => `Show: ${val}`}
            onChange={e => {
              setDropdownOption(e.target.value as CoreQDropdownOptions)
            }}
          >
            {Object.values(CoreQDropdownOptions).map(val => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              )
            })}
          </TextDropdown>
        }
      />
      <ResponseHandler {...overallCoreQResult}>
        {({ insightsOverallIndex: overallCoreQScore }) => {
          return (
            <DynamicTimeframeQuery
              timeRanges={timeRanges}
              variables={{
                filters,
                surveyUuid: survey.uuid,
                limitToCoreQEligible,
                statementCodes: [BenchmarkCodeType.CORE_Q_SUMMARY],
              }}
              queryHook={useInsightsOverallIndexQuery}
            >
              {data => {
                // Only need to show positive scores.
                const scoreTypeGroups = [
                  getOverallScoreGroups(
                    data.map(d => ({ uuid: d.uuid, overallIndex: d.insightsOverallIndex })),
                  )[0],
                ]
                const { scoreDelta, deltaText } = getDeltaAndText(scoreTypeGroups[0])
                return (
                  <div className={classes.container}>
                    <div className={classes.timeTrendingInfoBox}>
                      <div className={classes.infoPercentageText}>
                        <ScoreChangeArrow delta={scoreDelta} fontSize="3.6rem" />
                      </div>
                      <Typography variant="body2" color="textSecondary">
                        Your CoreQ Satisfaction score for{' '}
                        {getFormattedSurveyDate({
                          ...timeRanges[timeRanges.length - 1],
                          includeDay: true,
                          isTimeRange: true,
                        })}{' '}
                        is {deltaText} your score for{' '}
                        {getFormattedSurveyDate({
                          ...timeRanges[0],
                          includeDay: true,
                          isTimeRange: true,
                        })}
                        .
                      </Typography>
                    </div>
                    <TTChart
                      scoreTypeGroups={scoreTypeGroups}
                      xAxisLabelData={timeRanges}
                      chartType={TTChartType.INDEX_SCORE}
                      benchmarkName="Company Overall"
                      benchmarkScore={overallCoreQScore}
                      tooltipFormatter={series => {
                        let delta = null
                        const currentPositive = scoreTypeGroups[0][series.dataIndex][1]
                        if (series.dataIndex > 0) {
                          delta = currentPositive! - scoreTypeGroups[0][series.dataIndex - 1][1]!
                        }
                        return renderEchartTooltip(
                          <StandardTooltip
                            title={getFormattedSurveyDate({
                              ...timeRanges[series.dataIndex],
                              includeDay: true,
                              isTimeRange: true,
                            })}
                            rows={[
                              {
                                label: 'Core Q Score:',
                                value: formatTooltipScore(
                                  currentPositive,
                                  survey.minShowableResults,
                                ),
                                detail: <ScoreChangeArrow isPercentage={false} delta={delta} />,
                              },
                              {
                                label: 'Overall Score:',
                                value: formatTooltipScore(
                                  overallCoreQScore.positive,
                                  survey.minShowableResults,
                                ),
                                detail: <div />,
                              },
                            ]}
                          />,
                        )
                      }}
                    />
                  </div>
                )
              }}
            </DynamicTimeframeQuery>
          )
        }}
      </ResponseHandler>
    </div>
  )
}

export default TimeframeCoreQResultsCard
