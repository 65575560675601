import React, { useState } from 'react'

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import cn from 'classnames'

import BarChart from 'components/Icons/BarChart'
import BarChartHighToLow from 'components/Icons/BarChartHighToLow'
import BarChartLowToHigh from 'components/Icons/BarChartLowToHigh'
import { SORT_OPTIONS } from 'utils/constants'

const useStyles = makeStyles(theme => ({
  iconButton: {
    width: 'auto',
    height: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    fill: theme.palette.common.navy65,
    width: 24,
    height: 24,
  },
  disabled: {
    fill: theme.palette.common.navy15,
  },
}))

const DEFAULT_SORT_LABELS = {
  [SORT_OPTIONS.A_TO_Z]: 'A to Z',
  [SORT_OPTIONS.LOW_TO_HIGH]: 'Low to High',
  [SORT_OPTIONS.HIGH_TO_LOW]: 'High to Low',
}

type Props = {
  handleChangeSort: (arg: SORT_OPTIONS) => void
  currentSort: SORT_OPTIONS
  disabled?: boolean
  sortLabels?: { [key in SORT_OPTIONS]: string }
}
const BarChartSort: React.FC<Props> = ({
  handleChangeSort,
  currentSort,
  sortLabels = DEFAULT_SORT_LABELS,
  disabled = false,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const iconClasses = cn({
    [classes.icon]: true,
    [classes.disabled]: disabled,
  })
  const sortIcons = {
    [SORT_OPTIONS.A_TO_Z]: <BarChart className={iconClasses} />,
    [SORT_OPTIONS.LOW_TO_HIGH]: <BarChartLowToHigh className={iconClasses} />,
    [SORT_OPTIONS.HIGH_TO_LOW]: <BarChartHighToLow className={iconClasses} />,
  }
  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {Object.values(SORT_OPTIONS).map(
          sorting =>
            currentSort !== sorting && (
              <MenuItem
                key={sorting}
                onClick={() => {
                  setAnchorEl(null)
                  handleChangeSort(sorting)
                }}
              >
                <ListItemIcon style={{ marginRight: 0 }}>{sortIcons[sorting]}</ListItemIcon>
                <ListItemText inset primary={sortLabels[sorting]} />
              </MenuItem>
            ),
        )}
      </Menu>
      <IconButton
        disableRipple
        disabled={disabled}
        className={classes.iconButton}
        onClick={e => setAnchorEl(e.currentTarget as HTMLElement)}
      >
        {sortIcons[currentSort]}
      </IconButton>
    </div>
  )
}

export default BarChartSort
