import React, { useState, SyntheticEvent } from 'react'

import { makeStyles, Grid, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import ActionItem from 'components/ActionPlans/ActionItem'
import AddCustomStatementDialog from 'components/ActionPlans/AddCustomStatementDialog'
import AddStatementsDialog from 'components/ActionPlans/AddStatementsDialog'
import Button from 'components/Blocks/CustomButtons/Button'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import ActionPlansDownloadButton from 'components/Insights/Blocks/ActionPlansDownloadButton'
import {
  ActionPlansActionPlanFragment,
  ActionPlansActionItemFragment,
  ActionPlansActionItemTaskFragment,
  TaskStatusEnum,
} from 'generated/graphql'
import { CONTACT_EMAIL } from 'utils/constants'
import { SurveyNodeAP as SurveyNode } from 'utils/types'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(3),
  },
  buttonsBar: {
    marginTop: spacing(2),
  },
  banner: {
    marginTop: spacing(3),
    '& a, & span': {
      color: palette.common.white,
    },
  },
  addStatements: {
    float: 'right',
  },
  viewStatementsNow: {
    cursor: 'pointer',
  },
}))

const isEmptyActionItem = (actionItem: ActionPlansActionItemFragment) => {
  const isNewTask = (task: ActionPlansActionItemTaskFragment) => {
    return !task.task && task.status === TaskStatusEnum.NOT_STARTED && !task.owner && !task.dueDate
  }

  return (
    actionItem.actionItemTasks.length === 0 ||
    (actionItem.actionItemTasks.length === 1 && isNewTask(actionItem.actionItemTasks[0]))
  )
}

enum Dialogs {
  STATEMENTS = 'statements',
  CUSTOM_STATEMENT = 'customStatements',
}

type Props = {
  surveys: SurveyNode[]
  actionPlan: ActionPlansActionPlanFragment
  targetUserUuid?: string
  hasActionPlansCustomStatements: boolean
}

const ActionPlan: React.FC<Props> = ({
  surveys,
  actionPlan,
  targetUserUuid,
  hasActionPlansCustomStatements,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [openedDialogName, setOpenedDialogName] = useState<Dialogs | null>(null)
  const isEmptyActionPlan = actionPlan.actionItems.every(isEmptyActionItem)

  const closeDialog = () => {
    setAnchorEl(null)
    setOpenedDialogName(null)
  }

  return (
    <div className={classes.root}>
      <TitleWithInfoTooltip
        title="Welcome to your Action Plan"
        tooltip="Pick which statements you’ll work on and for each one, add your action items. Action items are the specific steps you’ll take to make changes to improve. You can edit, delete, or add new action items as needed. Add owners, due dates, and statuses to help track your progress. When you’re done working on a statement set its action items to complete or saved for later to mark the statement as complete. Then on the Completed Statements tab, see how your scores change for that statement."
      />
      <Grid container justify="space-between" className={classes.buttonsBar}>
        <Grid item sm={8}>
          <Typography color="textSecondary">
            {isEmptyActionPlan
              ? 'Add your action items! Action items are specific, practical steps you can take to accomplish your goals. Edit, delete or add new action items for each statement. For each item, add an owner, due date, and status to help track your progress as you work on your plan.'
              : 'Keep track of what you’re working on and track your progress as you go.'}
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <Grid container justify="flex-end">
            <Grid item className={classes.addStatements}>
              <ActionPlansDownloadButton
                title="Download"
                id="dowload-single-action-plan"
                targetUserUuid={targetUserUuid}
              />
              <Button
                color="primary"
                onClick={(e: SyntheticEvent) => setAnchorEl(e.currentTarget as HTMLElement)}
                startIcon={<AddIcon />}
              >
                Add New
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  value={Dialogs.STATEMENTS}
                  onClick={() => {
                    setOpenedDialogName(Dialogs.STATEMENTS)
                  }}
                >
                  Survey Statements
                </MenuItem>
                <Tooltip
                  title={
                    !hasActionPlansCustomStatements
                      ? `To learn more about adding custom items to your action plan, contact ${CONTACT_EMAIL}`
                      : ''
                  }
                >
                  <div>
                    <MenuItem
                      disabled={!hasActionPlansCustomStatements}
                      value={Dialogs.CUSTOM_STATEMENT}
                      onClick={() => {
                        setOpenedDialogName(Dialogs.CUSTOM_STATEMENT)
                      }}
                    >
                      Custom
                    </MenuItem>
                  </div>
                </Tooltip>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {actionPlan.actionItems.map(
        actionItem =>
          actionItem && (
            <ActionItem
              key={actionItem.uuid}
              targetUserUuid={targetUserUuid}
              actionItem={actionItem}
            />
          ),
      )}
      {openedDialogName === Dialogs.STATEMENTS && (
        <AddStatementsDialog
          surveys={surveys}
          targetUserUuid={targetUserUuid}
          onClose={closeDialog}
        />
      )}
      {openedDialogName === Dialogs.CUSTOM_STATEMENT && (
        <AddCustomStatementDialog targetUserUuid={targetUserUuid} onClose={closeDialog} />
      )}
    </div>
  )
}

export default ActionPlan
