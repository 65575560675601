import React from 'react'

import PropTypes from 'prop-types'
import { Vega } from 'react-vega'

import PrintableComponent from 'HOC/PrintableComponent'

/**
 * Vega charts are not responsive by default.
 * Manually update the chart SVG width on print events.
 */
export default class PrintableVegaChart extends React.PureComponent {
  beforePrint = () => {
    if (this.chartRef) {
      this.chartRef.vegaEmbed.current.containerRef.current.childNodes[0].width = this.props.printWidth
    }
  }

  afterPrint = () => {
    if (this.chartRef) {
      this.chartRef.vegaEmbed.current.containerRef.current.childNodes[0].width = this.props.screenWidth
    }
  }

  render() {
    return (
      <PrintableComponent beforePrint={this.beforePrint} afterPrint={this.afterPrint}>
        <Vega {...this.props} ref={e => (this.chartRef = e)} />
      </PrintableComponent>
    )
  }
}

PrintableVegaChart.propTypes = {
  printWidth: PropTypes.number.isRequired,
  screenWidth: PropTypes.number.isRequired,
}
