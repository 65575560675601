import React from 'react'

import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { colors } from 'shared/theme'
import { splitWordsByLine } from 'utils'

const LabelContent = props => {
  const { y, width, height, payload } = props
  const [, statement, focus] = payload.value.split('::')
  const lines = splitWordsByLine(statement, 45)
  return (
    <text
      x="0"
      y={y}
      width={width}
      height={height}
      style={{ fontSize: '1.4rem', fontFamily: 'YogaSansMedium', fill: colors.navy }}
    >
      {lines.map((line, idx) => (
        <tspan key={line} x="4" dy={idx > 0 ? '16px' : '-8px'}>
          {line}
        </tspan>
      ))}
      <tspan x="4" dy="16px" style={{ fill: colors.navy65 }}>
        {focus.toUpperCase()}
      </tspan>
    </text>
  )
}

const StatementLabel = props => {
  const history = useHistory()
  if (!props.linkToComparisons) {
    return (
      <svg>
        <LabelContent {...props} />
      </svg>
    )
  }
  const url = props.payload.value.split('::')[0]
  return (
    <a href={url} onClick={() => history.push(url)}>
      <LabelContent {...props} />
    </a>
  )
}
StatementLabel.propTypes = {
  linkToComparisons: PropTypes.bool.isRequired,
}
export default StatementLabel
