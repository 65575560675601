import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'

import Carousel from 'components/Blocks/Carousel'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey, InsightsBenchmark } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  ChartTimeRange,
  timeRangeDescription,
} from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import TTGroupedScoresChart from 'components/Insights/TimeTrending/TTGroupedScoresChart'
import {
  groupScoresByLabel,
  transformGroupedScoresToEcharts,
  getOverallScoreGroups,
  getBenchmarkScore,
} from 'components/Insights/TimeTrending/utils'
import {
  useInsightsTimeTrendingScoresByDataTypeQuery,
  useInsightsKeyDemographicsQuery,
  ImpactScoreStrategyEnum,
  BenchmarkCodeType,
} from 'generated/graphql'
import DynamicTimeframeQuery from 'HOC/DynamicTimeframeQuery'
import { DATA_TYPE_NAMES } from 'utils/constants'

const useStyles = makeStyles(({ spacing }) => ({
  carousel: {
    marginTop: spacing(2),
  },
}))

type Props = {
  survey: InsightsSurvey
  filters: string[]
  benchmark: InsightsBenchmark
  timeRanges: ChartTimeRange[]
}
const TimeframeKeyDemographicsCard: React.FC<Props> = ({
  survey,
  filters,
  benchmark,
  timeRanges,
}) => {
  // Duplicate state for the carousel step so we know which chart to use for the screenshot.
  const [step, setStep] = useState(0)
  const classes = { ...useStyles(), ...useInsightsStyles() }
  // Result should be cached from non-timeframe version.
  const result = useInsightsKeyDemographicsQuery({
    variables: {
      surveyUuid: survey.uuid,
      impactScoreStrategy: ImpactScoreStrategyEnum.POSITIVE_SCORE,
      statementCodes: [BenchmarkCodeType.CORE_Q1_RECOMMEND],
      filters,
    },
  })
  return (
    <div className={classes.fullRow}>
      <SnapshotChartHeader
        title="Recommendation SCORE BY DEMOGRAPHICS OVER TIME"
        tooltip="See how the recommendations core for each demographic group changed by time period."
        snapId={`timeframe-key-demographics-snap-${step}`}
        description={timeRangeDescription(timeRanges)}
        useBottomPadding={false}
      />
      <ResponseHandler {...result}>
        {({ insightsKeyDemographics }) => {
          if (!insightsKeyDemographics.length) return <div />
          const showCarousel = insightsKeyDemographics.length > 2
          return (
            <div className={classes.carousel}>
              <Carousel
                showCarousel={showCarousel}
                onStepChange={setStep}
                numSteps={insightsKeyDemographics.length}
              >
                {insightsKeyDemographics.map(({ dataType: dtCode }, idx) => {
                  return (
                    <div id={`timeframe-key-demographics-snap-${idx}`} key={dtCode}>
                      <DynamicTimeframeQuery
                        timeRanges={timeRanges}
                        variables={{
                          filters,
                          surveyUuid: survey.uuid,
                          dtCode,
                          statementCodes: [BenchmarkCodeType.CORE_Q1_RECOMMEND],
                        }}
                        queryHook={useInsightsTimeTrendingScoresByDataTypeQuery}
                      >
                        {(data, loading) => {
                          const surveyScoresByGroup = groupScoresByLabel(
                            data,
                            'insightsScoresByDataType',
                          )
                          const groupNames = Object.keys(surveyScoresByGroup)
                          const demographicScoreGroups = transformGroupedScoresToEcharts(
                            groupNames,
                            surveyScoresByGroup,
                            data.map(({ uuid }) => uuid),
                          )
                          const companyOverallScoreGroups = getOverallScoreGroups(data)
                          return (
                            <TTGroupedScoresChart
                              xAxisLabelData={timeRanges}
                              groupedScores={demographicScoreGroups}
                              benchmarkName={benchmark.name}
                              companyOverallScores={companyOverallScoreGroups[0]}
                              benchmarkScore={getBenchmarkScore(data[0].overallIndex)}
                              minShowableResults={survey.minShowableResults}
                              title={String(DATA_TYPE_NAMES[dtCode])}
                              didSelectFilters={!loading && filters.length > 0}
                              surveyScoresByGroup={surveyScoresByGroup}
                              orderedGroupNames={groupNames}
                              chartWidth="inherit"
                            />
                          )
                        }}
                      </DynamicTimeframeQuery>
                    </div>
                  )
                })}
              </Carousel>
            </div>
          )
        }}
      </ResponseHandler>
    </div>
  )
}

export default TimeframeKeyDemographicsCard
