import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'
import { Bar, BarChart, LabelList, XAxis, YAxis } from 'recharts'

import SingleBarBreakdownChart from 'components/Blocks/Charts/SingleBarBreakdownChart'
import { colors } from 'shared/theme'
import { formatTooltipScore } from 'utils'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    fontSize: 16,
  },
  keyStmtContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  keyStmtText: {
    maxWidth: '65%',
  },
  associatedSurveyLabel: {
    fill: theme.palette.common.success,
  },
  diff: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    height: 18,
    padding: 5,
    borderRadius: 5,
  },
  diffPositive: {
    backgroundColor: theme.palette.common.success,
  },
  diffNegative: {
    backgroundColor: theme.palette.common.danger,
  },
  associatedSurveyBar: {
    marginBottom: 10,
  },
}))

interface Props {
  label: string
  lessThanMin: boolean
  positive: number
  inconsistent: number
  negative: number
  minShowableResults: number
  associatedSurveyPositive?: number
  seeMoreUrl?: string
}

const KeyStatement: React.FC<Props> = ({
  label,
  lessThanMin,
  positive,
  inconsistent,
  negative,
  associatedSurveyPositive,
  seeMoreUrl,
  minShowableResults,
}) => {
  const classes = useStyles()
  const associatedSurveyKey = 'associated-survey-positive'
  let diff = 0
  if (associatedSurveyPositive) {
    diff = positive - associatedSurveyPositive
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6">Key Statement</Typography>
      <Typography className={classes.keyStmtText} color="textSecondary">
        {label}
      </Typography>
      <div className={classes.keyStmtContainer}>
        <SingleBarBreakdownChart
          scores={[positive, inconsistent, negative]}
          minShowableResults={minShowableResults}
          lessThanMin={lessThanMin}
        />
        {diff !== 0 && (
          <div className={cn(classes.diff, diff < 0 ? classes.diffNegative : classes.diffPositive)}>
            {diff < 0 ? '↓' : '↑'}
            {diff.toFixed(1)} pts
          </div>
        )}
      </div>
      {/** TODO: remove this in favor single bar breakdown and check printing */}
      {associatedSurveyPositive && (
        <BarChart
          className={classes.associatedSurveyBar}
          width={320}
          height={30}
          data={[
            {
              [associatedSurveyKey]: associatedSurveyPositive,
            },
          ]}
          layout="vertical"
          margin={{ top: 0, right: 40, left: 0, bottom: 0 }}
          barCategoryGap={0}
          barGap={9}
        >
          <XAxis
            orientation="top"
            type="number"
            domain={[0, 100]}
            tickFormatter={() => ''}
            height={0}
            ticks={[0, 20, 40, 60, 80, 100]}
            axisLine={false}
          />
          <YAxis type="category" axisLine={false} width={0} />
          <Bar
            barSize={4}
            dataKey={associatedSurveyKey}
            name="Associated Survey Positive"
            fill={colors.success}
          >
            <LabelList
              className={classes.associatedSurveyLabel}
              dataKey={associatedSurveyKey}
              position="right"
              formatter={(val: number | string) =>
                formatTooltipScore(Number(val), minShowableResults)
              }
            />
          </Bar>
        </BarChart>
      )}
      {seeMoreUrl && <NavLink to={seeMoreUrl}>See more details</NavLink>}
    </div>
  )
}

export default KeyStatement
