// Brute-force disable until we upgrade react-scripts and can disable JSX per line
/* eslint-disable */
import React from 'react'
import cn from 'classnames'

import { makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import * as PopulatedItemsImage from 'assets/img/action_plans/action-plans-populated-state.png'

const useStyles = makeStyles(({ palette, spacing }) => ({
  helper: {
    margin: spacing(3),
    marginBottom: spacing(2),
    marginTop: spacing(2),
  },
  states: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: spacing(4),
    marginBottom: spacing(4),
    '& >div': {
      maxWidth: '35%',
    },
  },
  state: {
    border: `3px solid ${palette.common.iceGrey}`,
    borderRadius: 3,
    backgroundColor: '#FAFAFA',
    '&:hover': {
      borderColor: palette.common.secondary,
    },
  },
  statesEmpty: {
    width: 290,
    height: 173,
    marginBottom: spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& >svg': {
      width: 90,
      height: 90,
    },
  },
  statesPopulated: {
    marginBottom: spacing(3),
  },
  selected: {
    borderColor: palette.common.secondary,
  },
  closeButton: {
    position: 'absolute',
    right: spacing(1),
    top: spacing(1),
    color: palette.common.navy65,
  },
  tip: {
    fontStyle: 'italic',
    marginTop: spacing(2),
  },
}))

type Props = {
  includeDefaultActionItems: null | boolean
  setIncludeDefaultActionItems(emptyState: null | boolean): void
}

const StatementsStateStep: React.FC<Props> = ({
  includeDefaultActionItems,
  setIncludeDefaultActionItems,
}) => {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.helper} variant="h6" color="textSecondary">
        Use action items to track exactly what you’re going to do to improve the statement score.
        Start from:
      </Typography>
      <div className={classes.states}>
        <div>
          <div
            className={cn(classes.state, classes.statesEmpty, {
              [classes.selected]: includeDefaultActionItems === false,
            })}
            onClick={() => setIncludeDefaultActionItems(false)}
          >
            <AddIcon color="secondary" />
          </div>
          <Typography>A Blank Slate</Typography>
          <Typography color="textSecondary">
            Start with an empty action items list. Add your own action items for each statement that
            you're working on.
          </Typography>
          <Typography className={classes.tip} color="textSecondary">
            Recommended for: You already know exactly what you want to do.
          </Typography>
        </div>
        <div>
          <div id="prepopulated-statements" onClick={() => setIncludeDefaultActionItems(true)}>
            <img
              width={290}
              className={cn(classes.state, classes.statesPopulated, {
                [classes.selected]: includeDefaultActionItems === true,
              })}
              src={PopulatedItemsImage}
              alt="Populated Items"
            />
          </div>
          <Typography>A Pre-Populated Template</Typography>
          <Typography color="textSecondary">
            With Action Items from our Best Practices Library. Edit, delete, and add custom items to
            create your perfect plan.
          </Typography>
          <Typography className={classes.tip} color="textSecondary">
            Recommended for: You'd like some inspiration to help you get started.
          </Typography>
        </div>
      </div>
    </>
  )
}

export default StatementsStateStep
