/* eslint-disable */
import 'whatwg-fetch' // fixes missing fetch on ie11
import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/fn/string/starts-with'
import 'core-js/fn/array/flat-map'
import 'core-js/fn/array/find-index'
;

(function() {
  if (
    typeof window.CustomEvent === 'function' ||
    (this &&
      // In Safari, typeof CustomEvent == 'object' but it otherwise works fine
      this.CustomEvent.toString().indexOf('CustomEventConstructor') > -1)
  ) {
    return
  }

  // fix custom event for IE9, IE10, IE11
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    var evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = CustomEvent
})()
