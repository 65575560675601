import React, { useState } from 'react'

import { makeStyles, Typography, FormControl, MenuItem, Select } from '@material-ui/core'

import { SortOrder } from 'components/Blocks/Charts/HorizontalBarChart'
import ModalChartContainer from 'components/Blocks/Dialogs/ModalChartContainer'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import {
  NpsBreakdownChart,
  NpsChartType,
  getNpsChartTypeLabel,
} from 'components/Insights/Discharge/NpsBreakdownCard'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import { DataTypeCode, FilterTypeFragment } from 'generated/graphql'
import { getNpsLabel } from 'utils'
import { SORT_OPTIONS } from 'utils/constants'

const useStyles = makeStyles(() => ({
  chartDropdown: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 12,
  },
}))

type Props = {
  survey: InsightsSurvey
  filters: string[]
  startDate?: string
  endDate?: string
  filterTypes: FilterTypeFragment[]
  onClose(): void
}
const NpsBreakdownModalCard: React.FC<Props> = props => {
  const { filterTypes, survey, onClose } = props
  const [dtCode, setDtCode] = useState<DataTypeCode>(filterTypes[0].dtCode)
  const [sortOrder, setSortOrder] = useState(SortOrder.HIGHEST)
  const [chartType, setChartType] = useState(NpsChartType.GROUPS_BREAKDOWN)
  // Transform sort to the type the chart will understand.
  const currentSort = {
    [SortOrder.HIGHEST]: SORT_OPTIONS.HIGH_TO_LOW,
    [SortOrder.LOWEST]: SORT_OPTIONS.LOW_TO_HIGH,
  }[sortOrder]
  const classes = useStyles()
  return (
    <ModalChartContainer
      id="nps-breakdown-modal-snap"
      onClose={onClose}
      surveyName={survey.name}
      dropdownRowComponent={
        <>
          <Typography color="textSecondary" variant="h6">
            See
          </Typography>
          <FormControl>
            <Select
              value={dtCode}
              onChange={e => setDtCode((e.target as HTMLInputElement).value as DataTypeCode)}
              displayEmpty={false}
              name="breakdownFilterType"
            >
              {filterTypes.map(ft => (
                <MenuItem key={ft.dtCode} value={ft.dtCode}>
                  {ft.namePlural}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography color="textSecondary" variant="h6">
            sorted by
          </Typography>
          <FormControl>
            <Select
              value={sortOrder}
              onChange={e => setSortOrder(e.target.value as SortOrder)}
              displayEmpty={false}
              name="breakdownSortOrder"
            >
              {Object.values(SortOrder).map(order => (
                <MenuItem key={order} value={order}>
                  {order}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6" color="textSecondary">
            {getNpsLabel(survey.productType)}
          </Typography>
        </>
      }
      chartComponent={
        <>
          <div className={classes.chartDropdown}>
            <TextDropdown
              value={chartType}
              renderValue={type =>
                `Show: ${getNpsChartTypeLabel(type as NpsChartType, survey.productType)}`
              }
              onChange={e => {
                setChartType(e.target.value as NpsChartType)
              }}
            >
              <MenuItem value={NpsChartType.NPS_SCORE}>
                {getNpsChartTypeLabel(NpsChartType.NPS_SCORE, survey.productType)}
              </MenuItem>
              <MenuItem value={NpsChartType.GROUPS_BREAKDOWN}>
                {getNpsChartTypeLabel(NpsChartType.GROUPS_BREAKDOWN, survey.productType)}
              </MenuItem>
            </TextDropdown>
          </div>
          <NpsBreakdownChart
            {...props}
            currentSort={currentSort}
            chartType={chartType}
            showHighlights={false}
            dtCode={dtCode}
          />
        </>
      }
    />
  )
}

export default NpsBreakdownModalCard
