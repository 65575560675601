import React, { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  makeStyles,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { ReactComponent as MinShowableResultsIcon } from 'assets/img/min-showable-results.svg'
import HorizontalBarChart, {
  formatChartData,
  TooltipSeries,
} from 'components/Blocks/Charts/HorizontalBarChart'
import HorizontalChartTooltip from 'components/Blocks/Charts/HorizontalChartTooltip'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import BarChartSortButton from 'components/Insights/Blocks/BarChartSortButton'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import { CoreQDropdownOptions, CoreQTooltip } from 'components/Insights/Discharge/CoreQResultsCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  FilterTypeFragment,
  RankByEnum,
  InsightsSurveyQuery,
  useInsightsCoreQBreakdownQuery,
  BenchmarkCodeType,
  DataTypeCode,
  InsightsDownloadDocument,
  UserDownloadsEnum,
} from 'generated/graphql'
import { runDownloadQuery } from 'utils'
import { SORT_OPTIONS } from 'utils/constants'
import { getSortedScores } from 'utils/insightsUtils'

const useStyles = makeStyles(({ spacing }) => ({
  chartContainer: {
    position: 'relative',
    marginLeft: '10%',
    marginRight: '10%',
  },
  tooltip: {
    padding: spacing(2),
  },
}))

type Props = {
  survey: InsightsSurveyQuery['survey']
  filters: string[]
  visibleFilterTypes: FilterTypeFragment[]
  addFilterFromChart(filterType: FilterTypeFragment, name: string): void
  startDate?: string
  endDate?: string
}

const CoreQBreakdownContainer: React.FC<Props> = ({
  survey,
  filters,
  addFilterFromChart,
  visibleFilterTypes,
  startDate,
  endDate,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const client = useApolloClient()
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(menuAnchorEl)
  const [currentSort, setCurrentSort] = useState<SORT_OPTIONS>(SORT_OPTIONS.A_TO_Z)
  const [dropdownOption, setDropdownOption] = useState(CoreQDropdownOptions.ALL)
  const limitToCoreQEligible = dropdownOption === CoreQDropdownOptions.LIMITED
  const relevantFilter = visibleFilterTypes.find(ft => ft.dtCode === DataTypeCode.AI_LOCATION)
  const variables = {
    surveyUuid: survey.uuid,
    dtCode: DataTypeCode.AI_LOCATION,
    filters,
    startDate,
    endDate,
    rankBy: RankByEnum.POSITIVE,
    limitToCoreQEligible,
    // For the overall score
    statementCodes: [BenchmarkCodeType.CORE_Q_SUMMARY],
  }
  const result = useInsightsCoreQBreakdownQuery({ variables })
  const title = limitToCoreQEligible
    ? `COREQ ELIGIBLE RESULTS BY Location`
    : `CoreQ Satisfaction Score By Location`
  return (
    <ResponseHandler {...result}>
      {({ insightsScoresByDataType, insightsOverallIndex }) => {
        const sortedScores = getSortedScores(insightsScoresByDataType, currentSort)
        const summaryScore = insightsOverallIndex.positive
        return (
          <div id="core-q-breakdown-snap" className={classes.fullRow}>
            <SnapshotChartHeader
              title={title}
              description={
                <>
                  The positive answers to the CoreQ questions are averaged and combined to create a
                  single percent, which represents your CoreQ Satisfaction Score for each location.
                  For a summary on how we calculate the results, select the info icon. To read the
                  full details, please see the CoreQ Satisfaction Questionnaire and&nbsp;
                  <ExternalNavLink
                    to="http://www.coreq.org/CoreQ%20Satisfaction%20Questionnaire%20and%20User%20Manual.pdf"
                    label="User’s Manual."
                  />
                  <br />
                </>
              }
              tooltip={<CoreQTooltip limitToCoreQEligible={limitToCoreQEligible} />}
              snapId="core-q-breakdown-snap"
              extraControls={
                <>
                  <TextDropdown
                    id="core-q-breakdown-dropdown"
                    value={dropdownOption}
                    renderValue={val => `Show: ${val}`}
                    onChange={e => {
                      setDropdownOption(e.target.value as CoreQDropdownOptions)
                    }}
                  >
                    {Object.values(CoreQDropdownOptions).map(val => {
                      return (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      )
                    })}
                  </TextDropdown>
                  <>
                    <IconButton
                      id="coreq-more-button"
                      aria-label="More"
                      aria-haspopup="true"
                      onClick={e => setMenuAnchorEl(e.currentTarget as HTMLElement)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="coreq-long-menu"
                      anchorEl={menuAnchorEl}
                      open={menuOpen}
                      onClick={() => setMenuAnchorEl(null)}
                      PaperProps={{
                        style: {
                          maxHeight: 48 * 4.5,
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setMenuAnchorEl(null)
                          runDownloadQuery(() =>
                            client.query({
                              query: InsightsDownloadDocument,
                              variables: {
                                surveyUuid: survey.uuid,
                                downloadType: UserDownloadsEnum.CORE_Q_SATISFACTION_SCORES_EXPORT,
                                filters,
                                startDate,
                                endDate,
                              },
                              fetchPolicy: 'network-only',
                            }),
                          )
                        }}
                      >
                        <ListItemIcon>
                          <CloudDownloadIcon />
                        </ListItemIcon>
                        <ListItemText style={{ paddingLeft: 0 }}>
                          Download Scores by Location
                        </ListItemText>
                      </MenuItem>
                    </Menu>
                  </>
                </>
              }
            />
            {sortedScores.length < 2 ? (
              <EmptyState
                title="Oh Snap!"
                description="Not enough responses. Check this chart after more participants have responded to the survey."
                Icon={MinShowableResultsIcon}
              />
            ) : (
              <div className={classes.chartContainer}>
                <HorizontalBarChart
                  chartData={formatChartData([sortedScores])}
                  benchmarkData={[{ name: 'Company Overall', score: summaryScore }]}
                  chartWidth="100%"
                  onBarClick={(_, name) => addFilterFromChart(relevantFilter!, name)}
                  useTopLegends
                  tooltipFormatter={(series: TooltipSeries) => {
                    return (
                      <HorizontalChartTooltip
                        scores={[series[0].data, { name: 'Company Overall', value: summaryScore }]}
                      />
                    )
                  }}
                />
                <div className={classes.chartSortButton2}>
                  <BarChartSortButton
                    currentSort={currentSort}
                    handleChangeSort={newSort => setCurrentSort(newSort)}
                  />
                </div>
              </div>
            )}
          </div>
        )
      }}
    </ResponseHandler>
  )
}

export default CoreQBreakdownContainer
