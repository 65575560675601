import React from 'react'

import { makeStyles, Dialog, Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'

const useStyles = makeStyles(theme => ({
  container: {
    height: 620,
    paddingRight: theme.spacing(3),
  },
  dropdownRow: {
    display: 'flex',
    '& >div>div>div': {
      fontSize: '2.0rem',
    },
    '& > *': {
      paddingRight: theme.spacing(2),
    },
    '& >h6': {
      // Level out with text in the dropdowns
      paddingBottom: 3,
      lineHeight: 1.0,
    },
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.common.navy15}`,
  },
  titleSection: {
    padding: theme.spacing(3),
  },
  surveyName: {
    paddingTop: theme.spacing(2),
    '& >span': {
      color: theme.palette.common.navy65,
    },
  },
  chart: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    '& >p': {
      marginBottom: theme.spacing(2),
    },
  },
  sideButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& >div': {
      marginBottom: theme.spacing(),
      '& >button': {
        paddingRight: 0,
      },
    },
    // To keep the hover size consistent, use negative margin rather than padding: 0
    '& >button': {
      marginRight: -12,
    },
  },
}))

type Props = {
  dropdownRowComponent: React.ReactChild
  chartComponent: React.ReactChild
  id: string
  surveyName: string
  onClose(): void
}
const ModalChartContainer: React.FC<Props> = ({
  dropdownRowComponent,
  chartComponent,
  id,
  surveyName,
  onClose,
}) => {
  const classes = useStyles()
  return (
    <Dialog fullWidth maxWidth="md" open id={id}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.titleSection}>
            <div className={classes.dropdownRow}>{dropdownRowComponent}</div>
            <Typography className={classes.surveyName} variant="body2">
              <span>Survey: </span>
              {surveyName}
            </Typography>
          </div>
          <div className={classes.sideButtons}>
            <IconButton onClick={onClose} id={`close-${id}`}>
              <CloseIcon />
            </IconButton>
            <ScreenshotButton snapId={id} />
          </div>
        </div>
        <div className={classes.chart}>{chartComponent}</div>
      </div>
    </Dialog>
  )
}

export default ModalChartContainer
