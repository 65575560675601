import React, { useState } from 'react'

import { useApolloClient } from '@apollo/client'

import SurveyPortal from 'components/Settings/SurveyPortal/SurveyPortal'
import { RESIDENT_SURVEY_HOST, SURVEY_HOST } from 'config/config'
import {
  useSettingsUpdateOrganizationSurveyPortalMutation,
  OrganizationSurveyPortalInput,
  SurveyProductTypeEnum,
  CurrentUserDocument,
  CurrentUserQuery,
} from 'generated/graphql'
import { handleMutationResponse } from 'utils'
import { URLS } from 'utils/constants'

type Props = { productType: SurveyProductTypeEnum }

type SurveyDetails = {
  surveyHost: string
  pageUrl: string
  pageTitle: string
  surveyCodeNameField: 'residentSurveyCodeName' | 'surveyCodeName'
  surveyCodeHelpTextField: 'residentSurveyCodeHelpText' | 'surveyCodeHelpText'
}

const SurveyPortalContainer: React.FC<Props> = ({ productType }) => {
  const [updateOrganization, { loading }] = useSettingsUpdateOrganizationSurveyPortalMutation()
  const client = useApolloClient()
  const { organization } = client.readQuery({ query: CurrentUserDocument })
    .currentUser as NonNullable<CurrentUserQuery>['currentUser']
  const [errors, setErrors] = useState<string[]>([])

  const map: { [key in SurveyProductTypeEnum]: SurveyDetails } = {
    [SurveyProductTypeEnum.RESIDENT]: {
      surveyHost: RESIDENT_SURVEY_HOST,
      pageUrl: URLS.ORG_SETTINGS.RESIDENT_SURVEY_PORTAL,
      pageTitle: 'Customer Survey Portal',
      surveyCodeNameField: 'residentSurveyCodeName',
      surveyCodeHelpTextField: 'residentSurveyCodeHelpText',
    },
    [SurveyProductTypeEnum.EMPLOYEE]: {
      surveyHost: SURVEY_HOST,
      pageUrl: URLS.ORG_SETTINGS.EMPLOYEE_SURVEY_PORTAL,
      pageTitle: 'Employee Survey Portal',
      surveyCodeNameField: 'surveyCodeName',
      surveyCodeHelpTextField: 'surveyCodeHelpText',
    },
  }
  const surveyDetails = map[productType]

  const handleSubmit = async (orgInput: OrganizationSurveyPortalInput) => {
    let orgUpdate = orgInput
    if (productType === SurveyProductTypeEnum.RESIDENT) {
      orgUpdate = {
        slug: orgInput.slug,
        residentSurveyCodeName: orgInput.surveyCodeName,
        residentSurveyCodeHelpText: orgInput.surveyCodeHelpText,
      }
    }
    const result = await updateOrganization({
      variables: {
        organization: {
          ...orgUpdate,
        },
      },
    })
    const responseErrors = result.data?.updateOrganizationSurveyPortal?.errors
    handleMutationResponse(responseErrors, 'Survey Portal Settings Updated')
    if (responseErrors) {
      setErrors(responseErrors)
    }
  }

  return (
    <SurveyPortal
      defaultSlug={organization.slug}
      defaultSurveyCodeName={organization[surveyDetails.surveyCodeNameField]}
      defaultSurveyCodeHelpText={organization[surveyDetails.surveyCodeHelpTextField]}
      surveyHost={surveyDetails.surveyHost}
      pageTitle={surveyDetails.pageTitle}
      pageUrl={surveyDetails.pageUrl}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
    />
  )
}

export default SurveyPortalContainer
