import React from 'react'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import {
  getGroupData,
  getImprovementDtCode,
} from 'components/Insights/Snapshot/EmployeeKeyFocusAreasCard'
import KeyFocusAreasChart from 'components/Insights/Snapshot/KeyFocusAreasChart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  FilterTypeFragment,
  InsightsStatementScoreFragment,
  useInsightsResidentFocusAreasQuery,
  RankByEnum,
  DataTypeCode,
  SurveyProductTypeEnum,
} from 'generated/graphql'

export const getTargetScore = (
  statement: InsightsStatementScoreFragment,
  targetScore?: number | null,
) => {
  if (targetScore && Math.round(targetScore) > Math.round(statement.positive)) {
    return targetScore
  }
  // If no target or score is already >= the target score, use the benchmark score.
  if (
    statement.benchmarkPositive &&
    Math.round(statement.benchmarkPositive) > Math.round(statement.positive)
  ) {
    return statement.benchmarkPositive
  }
  // If no benchmark or score is already >= the benchmark, use a 20% increase, capped at 100.
  return Math.min(100, Math.round(statement.positive * 1.2))
}

type Props = {
  visibleFilterTypes: FilterTypeFragment[]
  surveyUuid: string
  surveyProductType: SurveyProductTypeEnum
  filters: string[]
  bottomStatements: InsightsStatementScoreFragment[]
  allStatements: InsightsStatementScoreFragment[]
  overallIndexScore: number
  minShowableResults: number
  hasKeyFocusAreaCarousel: boolean
}
const ResidentKeyFocusAreasCard: React.FC<Props> = ({
  visibleFilterTypes,
  surveyUuid,
  surveyProductType,
  filters,
  bottomStatements,
  allStatements,
  overallIndexScore,
  minShowableResults,
  hasKeyFocusAreaCarousel,
}) => {
  const classes = useInsightsStyles()
  // Take the bottom 3 filters
  const bottomIndex = visibleFilterTypes.length >= 3 ? visibleFilterTypes.length - 3 : 0
  const relevantFilters = visibleFilterTypes.slice(bottomIndex, visibleFilterTypes.length)
  const improvementDtCode = getImprovementDtCode(visibleFilterTypes, filters, DataTypeCode.BUILDING)
  const [statement1, statement2, statement3] = bottomStatements
  const result = useInsightsResidentFocusAreasQuery({
    variables: {
      surveyUuid,
      dtCodes: relevantFilters.map(ft => ft.dtCode),
      filters,
      rankBy: RankByEnum.POSITIVE,
      targetPercentile: 75,
      statement1Codes: statement1 ? [statement1.code] : [],
      statement1Code: statement1 ? statement1.code : '',
      statement2Codes: statement2 ? [statement2.code] : [],
      statement2Code: statement2 ? statement2.code : '',
      statement3Codes: statement3 ? [statement3.code] : [],
      statement3Code: statement3 ? statement3.code : '',
      improvementDtCode,
    },
  })
  return (
    <div className={classes.fullRow} id="key-focus-areas-snap">
      <SnapshotChartHeader
        title="How to improve scores"
        description="Focus on the groups that scored lowest for these statements,
        make sure you understand what’s driving their scores, and then address the specific issues
        they are facing. Here’s where to focus for each statement:"
        tooltip={
          <p>
            Here are two ways to look at improving your resident experience and raising scores for
            your three Recommended Focus Area statements/questions (section above) with the highest
            percentage of negative responses.
            <br />
            <br />
            The chart shows the growth potential if you raised the scores for the lowest-scoring 20%
            of participants to match your organizational average score on the statement/question.
            <br />
            <br />
            The ‘Focus On’ section shows the groups with the lowest positive scores on each
            statement. Consider directing extra focus toward these groups in your action planning
            and initiatives to improve the respective statement/question scores.
          </p>
        }
      />
      <ResponseHandler {...result}>
        {({
          statement1Groups,
          statement1Target,
          statement2Groups,
          statement2Target,
          statement3Groups,
          statement3Target,
          insightsPotentialImprovementScore,
        }) => {
          const statementsScoresData = [
            { scoresByDataTypes: statement1Groups, targetScore: statement1Target },
            { scoresByDataTypes: statement2Groups, targetScore: statement2Target },
            { scoresByDataTypes: statement3Groups, targetScore: statement3Target },
          ]
            // Only use the number of statements we have available in bottom statements.
            .slice(0, bottomStatements.length)
            .map(({ scoresByDataTypes, targetScore }, index) => {
              const statement = bottomStatements[index]
              return {
                label: statement.label,
                positive: Math.round(statement.positive),
                target: Math.round(Number(getTargetScore(statement, targetScore))),
                groupData: getGroupData(scoresByDataTypes, relevantFilters),
              }
            })
          return (
            <KeyFocusAreasChart
              minShowableResults={minShowableResults}
              statementsScoresData={statementsScoresData}
              improvementScoreData={insightsPotentialImprovementScore}
              overallIndexScore={overallIndexScore}
              allStatements={allStatements}
              visibleFilterTypes={visibleFilterTypes}
              improvementFilterTypeName={
                visibleFilterTypes.find(ft => ft.dtCode === improvementDtCode)?.namePlural
              }
              hasKeyFocusAreaCarousel={hasKeyFocusAreaCarousel}
              surveyProductType={surveyProductType}
            />
          )
        }}
      </ResponseHandler>
    </div>
  )
}

export default ResidentKeyFocusAreasCard
