import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing, palette }) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: -spacing(2),
  },
  labelDropdownSelect: {
    color: palette.common.white,
    '& :hover': {
      backgroundColor: palette.common.white50,
    },
  },
}))

export default useStyles
