import React, { useState, ReactElement } from 'react'

import { Typography, makeStyles } from '@material-ui/core'

import HorizontalBarChart, {
  formatChartData,
  TooltipSeries,
} from 'components/Blocks/Charts/HorizontalBarChart'
import HorizontalChartTooltip from 'components/Blocks/Charts/HorizontalChartTooltip'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import BarChartSortButton from 'components/Insights/Blocks/BarChartSortButton'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsBenchmark } from 'components/Insights/InsightsTypes'
import RankedBreakdownChartModal from 'components/Insights/Snapshot/RankedBreakdownChartModal'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { gaEvent } from 'config/ga'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import {
  FilterTypeFragment,
  RankByEnum,
  InsightsSurveyQuery,
  useInsightsDetailedBreakdownQuery,
  InsightsDetailedBreakdownQuery,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { reverse } from 'utils'
import { MIN_SHOWABLE_RESULTS_CODE, SORT_OPTIONS } from 'utils/constants'
import { scoreToColor, getSortedScores } from 'utils/insightsUtils'
import { SurveyNode } from 'utils/types'

const useStyles = makeStyles(theme => ({
  chartContainer: {
    position: 'relative',
    marginLeft: '10%',
    marginRight: '10%',
  },
  highlights: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& >div': {
      minWidth: '25%',
      padding: '0 2%',
      '& >h6': {
        textTransform: 'uppercase',
      },
    },
  },
}))

type BreakdownProps = {
  level1Breakdown: InsightsDetailedBreakdownQuery['level1Breakdown']
  // Until https://github.com/dotansimha/graphql-code-generator/issues/3836 is solved,
  // we need to type the undefined case from our @skip variable explicitly
  level2Breakdown?: InsightsDetailedBreakdownQuery['level2Breakdown']
}

// Filters used in the detailed breakdown change based on the user-selected filters
// Note: increasing filterType.index, means the filter is "lower" in priority.
export const getRelevantFilters = (
  filterTypes: FilterTypeFragment[],
  selectedFilterUuids: string[],
) => {
  // If no filters are selected, return top 2
  if (!selectedFilterUuids?.length) {
    if (filterTypes.length === 1) {
      return [filterTypes[0]]
    }
    return [filterTypes[0], filterTypes[1]]
  }
  const userSelectedFilterTypes = filterTypes.filter(ft =>
    ft.filterValues.some(fv => selectedFilterUuids.includes(fv.uuid)),
  )
  const lowestFilterType = userSelectedFilterTypes[userSelectedFilterTypes.length - 1]
  // If the bottom filter is selected, return the 2nd to last
  if (lowestFilterType === filterTypes[filterTypes.length - 1]) {
    return [filterTypes[filterTypes.length - 2]]
  }
  // If the 2nd to last filter is selected, return the bottom
  if (lowestFilterType === filterTypes[filterTypes.length - 2]) {
    return [filterTypes[filterTypes.length - 1]]
  }
  // Otherwise, return the 2 highest filters "underneath" the current selected filter
  return filterTypes.filter(ft => ft.index > lowestFilterType.index).slice(0, 2)
}

const getHighlightData = ({
  overallPositiveScore,
  level1Breakdown,
  level2Breakdown,
}: { overallPositiveScore: number } & BreakdownProps) => {
  const breakdowns = [level1Breakdown]
  if (level2Breakdown) {
    breakdowns.push(level2Breakdown)
  }
  return breakdowns.map(scores => {
    const sortedScores = getSortedScores(
      scores.filter(s => s.positive !== MIN_SHOWABLE_RESULTS_CODE),
      SORT_OPTIONS.HIGH_TO_LOW,
    )
    const numScoresToShow = sortedScores.length < 20 ? 3 : 5
    const highestScores = sortedScores
      .slice(0, Math.round(sortedScores.length / 2))
      .slice(0, numScoresToShow)
      .filter(s => s.positive >= overallPositiveScore)
    const lowestScores = reverse(
      sortedScores.slice(-Math.floor(sortedScores.length / 2)).slice(-numScoresToShow),
    )
    return { highestScores, lowestScores }
  })
}

type HProps = {
  filterTypes: FilterTypeFragment[]
  overallPositiveScore: number
}
const Highlights: React.FC<HProps & BreakdownProps> = ({
  filterTypes,
  level1Breakdown,
  level2Breakdown,
  overallPositiveScore,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const highlightData = getHighlightData({ overallPositiveScore, level1Breakdown, level2Breakdown })
  return (
    <div className={classes.highlights}>
      {highlightData.map(({ highestScores, lowestScores }, idx) => {
        const filterType = filterTypes[idx]
        return (
          <React.Fragment key={filterType.filterTypeUuid}>
            {highestScores.length > 0 && (
              <div>
                <Typography variant="subtitle1" color="textSecondary">
                  Highest {filterType.name}
                </Typography>
                <Typography
                  className={classes.lgText}
                  style={{
                    color: scoreToColor(highestScores[0].positive),
                  }}
                >
                  {Math.round(highestScores[0].positive)}%
                </Typography>
                <Typography className="" variant="body2" color="textSecondary">
                  Highest {filterType.namePlural}: {highestScores.map(d => d.label).join(', ')}
                </Typography>
              </div>
            )}
            {lowestScores.length > 0 && (
              <div>
                <Typography variant="subtitle1" color="textSecondary">
                  Lowest {filterType.name}
                </Typography>
                <Typography
                  className={classes.lgText}
                  style={{
                    color: scoreToColor(lowestScores[0].positive),
                  }}
                >
                  {Math.round(lowestScores[0].positive)}%
                </Typography>
                <Typography className="" variant="body2" color="textSecondary">
                  Lowest {filterType.namePlural}: {lowestScores.map(d => d.label).join(', ')}
                </Typography>
              </div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

type Props = {
  survey: InsightsSurveyQuery['survey']
  availableSurveys: SurveyNode[]
  filters: string[]
  visibleFilterTypes: FilterTypeFragment[]
  overallPositiveScore: number
  addFilterFromChart(filterType: FilterTypeFragment, name: string): void
  benchmark: InsightsBenchmark
  benchmarkPositive: number
  hasTimeTrending: boolean
  statementCode?: string
  startDate?: string
  endDate?: string
  title: string
  description: string | ReactElement
  tooltip: string | ReactElement
  breakdownModalScoreTitle?: string
  useFilterNameSuffixInTitle?: boolean
}

const DetailedBreakdownContainer: React.FC<Props> = ({
  survey,
  filters,
  visibleFilterTypes,
  overallPositiveScore,
  addFilterFromChart,
  benchmark,
  benchmarkPositive,
  hasTimeTrending,
  statementCode,
  startDate,
  endDate,
  title,
  description,
  tooltip,
  breakdownModalScoreTitle,
  useFilterNameSuffixInTitle = false,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [breakdownModalOpen, setBreakdownModalOpen] = useState(false)
  const [currentSort, setCurrentSort] = useState<SORT_OPTIONS>(SORT_OPTIONS.A_TO_Z)
  // Separate the filters we use to query, from the ones we use in results, in order to make sure
  // we can render the old data with old filters, while we are waiting to receive new data from the new filters.
  const relevantFilters = getRelevantFilters(visibleFilterTypes, filters)
  const skipLevel2 = relevantFilters.length === 1
  const variables = {
    surveyUuid: survey.uuid,
    level1DtCode: relevantFilters[0].dtCode,
    level2DtCode: skipLevel2 ? '' : relevantFilters[1].dtCode,
    filters,
    startDate,
    endDate,
    statementCodes: statementCode ? [statementCode] : undefined,
    rankBy: RankByEnum.POSITIVE,
    skipLevel2,
  }
  const result = useInsightsDetailedBreakdownQuery({ variables })

  return (
    <ResponseHandler {...result}>
      {({ level1Breakdown, level2Breakdown }, { loading }) => {
        const level1SortedScores = getSortedScores(level1Breakdown, currentSort)
        if (level1SortedScores.length < 2) {
          return <div />
        }

        return (
          <div id="detailed-breakdown-snap" className={classes.fullRow}>
            <SnapshotChartHeader
              title={
                useFilterNameSuffixInTitle
                  ? `${title} ${getRelevantFilters(visibleFilterTypes, filters)[0].name}`
                  : title
              }
              description={description}
              tooltip={tooltip}
              snapId="detailed-breakdown-snap"
              hasTimeTrending={hasTimeTrending}
              timeTrendingChartKey={
                survey.productType === SurveyProductTypeEnum.EMPLOYEE
                  ? TimeTrendingChartKey.EMPLOYEE_DETAILED_BREAKDOWN
                  : TimeTrendingChartKey.RESIDENT_DETAILED_BREAKDOWN
              }
              onSeeMore={() => {
                gaEvent({
                  action: 'seeMoreDetailedBreakdown',
                  category: 'Snapshot',
                })
                setBreakdownModalOpen(true)
              }}
            />
            <div className={classes.chartContainer}>
              <HorizontalBarChart
                chartData={formatChartData([level1SortedScores])}
                benchmarkData={[
                  { name: benchmark.name, score: benchmarkPositive },
                  { name: 'Company Score Overall', score: overallPositiveScore },
                ]}
                chartWidth="100%"
                onBarClick={(_, name) => addFilterFromChart(relevantFilters[0], name)}
                useTopLegends
                tooltipFormatter={(series: TooltipSeries) => {
                  return (
                    <HorizontalChartTooltip
                      scores={[
                        series[0].data,
                        { name: benchmark.name, value: benchmarkPositive },
                        { name: 'Company Overall', value: overallPositiveScore },
                      ]}
                    />
                  )
                }}
              />
              <div className={classes.chartSortButton2}>
                <BarChartSortButton
                  currentSort={currentSort}
                  handleChangeSort={newSort => setCurrentSort(newSort)}
                />
              </div>
            </div>
            {/** Avoid rendering the highlights while we are loading because there are several states
             * when filters are out of sync with data that will cause a crash */}
            {!loading && (
              <Highlights
                filterTypes={relevantFilters}
                level1Breakdown={level1Breakdown}
                level2Breakdown={level2Breakdown}
                overallPositiveScore={overallPositiveScore}
              />
            )}
            {breakdownModalOpen && (
              <RankedBreakdownChartModal
                survey={survey}
                onClose={() => setBreakdownModalOpen(false)}
                selectedFilters={filters}
                visibleFilterTypes={visibleFilterTypes}
                overallPositiveScore={overallPositiveScore}
                benchmarkPositive={benchmarkPositive}
                benchmarkName={benchmark.name}
                statementCode={statementCode}
                scoreTitle={breakdownModalScoreTitle}
              />
            )}
          </div>
        )
      }}
    </ResponseHandler>
  )
}

export default DetailedBreakdownContainer
