import React, { useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  makeStyles,
  Checkbox,
  FilledInput,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import cn from 'classnames'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import { ReactComponent as DesktopIcon } from 'assets/img/invite-icon-digital.svg'
import { ReactComponent as PhoneIcon } from 'assets/img/invite-icon-phone.svg'
import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import DebouncedTextInput from 'components/Blocks/FormHelpers/DebouncedTextInput'
import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import StatusTag from 'components/Blocks/Tags/StatusTag'
import Danger from 'components/Blocks/Typography/Danger'
import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import DataSource from 'components/Survey/Wizard/Steps/Design/DataSource'
import WaysToShare from 'components/Survey/Wizard/Steps/Design/WaysToShare'
import { getSurveysSurveysQueryVars } from 'containers/Survey/SurveyDashboardContainer'
import {
  OrganizationLanguageFragment,
  SurveyDistributionTypeEnum,
  SurveyFilterTypeSettingType,
  SurveysSurveyNotificationsDocument,
  SurveysSurveyQuery,
  SurveysSurveysDocument,
  SurveyStatusEnum,
  SurveyTypeEnum,
  CurrentUserDocument,
  CurrentUserQuery,
  useSurveysSurveysQuery,
  useSurveysUpdateSurveyMutation,
  SurveyInviteMethodEnum,
  SurveyParticipantDataSourceEnum,
} from 'generated/graphql'
import { getSurveyTypeLabel } from 'utils'
import {
  CUSTOM_SURVEY_TYPES,
  SURVEY_NAME_MIN_LENGTH,
  SURVEY_PULSE_TYPE_TO_PARENT_TYPE,
  SURVEY_INVITE_METHOD_TO_LABEL,
} from 'utils/constants'
import {
  surveyDefaultStartDate,
  surveyDefaultEndDate,
  surveyMaxEndDate,
  getReadableTimezoneString,
} from 'utils/dateUtils'
import { SurveyTypesEndDateRelation } from 'utils/generatedEnums'

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  surveyNameContainer: {
    width: '100%',
  },
  surveyName: {
    width: '100%',
    '& input': {
      fontSize: '2.8rem',
    },
  },
  surveyDescription: {
    width: '100%',
    '& input': {
      fontSize: '1.4rem',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  endDate: {
    marginLeft: spacing(8),
  },
  inviteMethod: {
    marginLeft: spacing(4),
  },
  associatedSurveyDropdown: {
    display: 'flex',
    alignItems: 'center',
    width: '55%',
    '& >p': {
      paddingRight: 8,
      marginLeft: 20,
    },
    '& >div': {
      width: '100%',
    },
  },
  surveyCode: {
    width: '100%',
  },
  statusTagWrap: {
    width: '100%',
    marginTop: -10,
  },
  openSurveySwitch: {
    marginLeft: 100,
  },
  radioButtonSubtext: {
    marginLeft: 30,
    marginBottom: 10,
    marginTop: -10,
  },
  saveAndContinue: {
    marginTop: spacing(2),
  },
}))

const getDefaultSurvey = (
  survey: SurveysSurveyQuery['survey'],
  surveyType: SurveyTypeEnum,
  initialAssociatedSurveyUuid?: string,
) => {
  return survey
    ? {
        uuid: survey.uuid,
        type: survey.type,
        name: survey.name || '',
        description: survey.description || '',
        startDate: survey.startDate,
        endDate: survey.endDate ? survey.endDate : null,
        languages: survey.languages?.map(l => l.code) || [],
        associatedSurveyUuid: survey.associatedSurvey?.uuid,
        hasConfidentialResults: survey.hasConfidentialResults,
        distributionType: survey.distributionType,
        participantDataSource: survey.participantDataSource,
        filterTypeSettings: survey.filterTypeSettings,
      }
    : {
        uuid: null,
        type: surveyType,
        name: '',
        description: '',
        startDate: surveyDefaultStartDate().toISOString(),
        endDate: surveyDefaultEndDate().toISOString(),
        languages: [],
        associatedSurveyUuid: initialAssociatedSurveyUuid,
        hasConfidentialResults: null,
        distributionType: null,
        dataSource: null,
        participantDataSource: SurveyParticipantDataSourceEnum.DIRECT_ROSTER_IMPORT,
        filterTypeSettings: [],
      }
}

export type SurveyInput = {
  uuid: string | null
  type: SurveyTypeEnum
  name: string
  description?: string | null
  startDate: string
  endDate?: string | null
  languages: string[]
  associatedSurveyUuid?: string | null
  hasConfidentialResults?: boolean | null
  distributionType?: SurveyDistributionTypeEnum | null
  participantDataSource?: SurveyParticipantDataSourceEnum | null
  filterTypeSettings: SurveyFilterTypeSettingType[]
}

type Props = {
  survey: SurveysSurveyQuery['survey']
  goNext(surveyUuid: string): void
  goBack(): void
  surveyType: SurveyTypeEnum
  currentUser: CurrentUserQuery['currentUser']
  initialAssociatedSurveyUuid?: string
  availableParentSurveys: SurveysSurveyQuery['survey'][]
}

const Design: React.FC<Props> = ({
  survey: surveyToEdit,
  surveyType,
  goBack,
  goNext,
  currentUser,
  availableParentSurveys,
  initialAssociatedSurveyUuid,
}) => {
  const classes = useStyles()
  const client = useApolloClient()
  // We can cast here, we know it exists by this state in the component tree
  const { organization } = client.readQuery({ query: CurrentUserDocument })
    .currentUser as NonNullable<CurrentUserQuery>['currentUser']

  const isPulseSurvey = Object.keys(SURVEY_PULSE_TYPE_TO_PARENT_TYPE).includes(surveyType)
  const pulseParentSurveyType = SURVEY_PULSE_TYPE_TO_PARENT_TYPE[surveyType]

  const [survey, setSurvey] = useState<SurveyInput>(
    getDefaultSurvey(surveyToEdit, surveyType, initialAssociatedSurveyUuid),
  )
  const [openWaysToShareConfirmationDialog, setOpenWaysToShareConfirmationDialog] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  useEffect(() => {
    setSurvey(getDefaultSurvey(surveyToEdit, surveyType, initialAssociatedSurveyUuid))
  }, [initialAssociatedSurveyUuid, surveyToEdit, surveyType])

  const [updateSurveyMutation, { loading }] = useSurveysUpdateSurveyMutation()

  // If existing survey's type or take its type from the location state
  const isCustom = CUSTOM_SURVEY_TYPES.includes(surveyType)
  const hasMultipleLanguages = ![
    SurveyTypeEnum.MONTHLY,
    SurveyTypeEnum.END_OF_EMPLOYMENT,
    SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY,
    SurveyTypeEnum.RESIDENT_START_OF_SERVICE,
  ].includes(survey.type)
  const saveIsDisabled = !survey.name || (isPulseSurvey && !survey.associatedSurveyUuid)

  const saveSurvey = async (goToNext: boolean, surveyAttrs?: Partial<SurveyInput>) => {
    let refetchQueries
    if (surveyToEdit) {
      if (surveyAttrs?.startDate) {
        // Notifications should be refetched because they change with the survey's start date
        refetchQueries = [
          {
            query: SurveysSurveyNotificationsDocument,
            variables: { surveyUuid: surveyToEdit.uuid },
          },
        ]
      }
    } else {
      refetchQueries = [
        {
          query: SurveysSurveysDocument,
          variables: getSurveysSurveysQueryVars(pulseParentSurveyType),
        },
      ]
    }
    const surveyInput = {
      ...survey,
      ...surveyAttrs,
    }
    const result = await updateSurveyMutation({
      variables: {
        ...surveyInput,
        filterTypeSettings: surveyInput.filterTypeSettings.map(ftSetting => ({
          // Dropping the __typename from surveyFilterTypeSettings
          dtCode: ftSetting.dtCode,
          reportingType: ftSetting.reportingType,
        })),
      },
      refetchQueries,
    })
    if (goToNext && result?.data?.updateSurvey?.survey) {
      goNext(result.data.updateSurvey.survey.uuid)
    }
  }

  const updateSurvey = (surveyAttrs?: Partial<SurveyInput>) => {
    if (survey.uuid && surveyAttrs) {
      // if it's a real time update of a field on existing survey
      saveSurvey(false, surveyAttrs)
    } else if (!survey.uuid && !surveyAttrs) {
      // if it's a new survey that has been submitted via "Save and Continue"
      saveSurvey(true)
    } else if (!survey.uuid && surveyAttrs) {
      // if it's a new survey that hasn't been submitted yet (its fields are still getting updated)
      setSurvey({ ...survey, ...surveyAttrs })
    } else if (survey.uuid && !surveyAttrs) {
      // if it's an existing survey and the "Next" button was clicked
      goNext(survey.uuid)
    }
  }
  // In this component, we want custom survey type label to always be
  // "Custom" regardless of the product type.
  const surveyNamePrefix = getSurveyTypeLabel(surveyType, !isCustom)
  const allowNonConfidentialResults = [
    SurveyTypeEnum.MONTHLY,
    SurveyTypeEnum.END_OF_EMPLOYMENT,
    SurveyTypeEnum.RESIDENT_CUSTOM,
    SurveyTypeEnum.RESIDENT_END_OF_SERVICE,
    SurveyTypeEnum.RESIDENT_DISCHARGE,
    SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY,
    SurveyTypeEnum.RESIDENT_START_OF_SERVICE,
  ].includes(surveyType)

  const allowOpenLinkSurveys =
    [
      SurveyTypeEnum.RESIDENT_CUSTOM,
      SurveyTypeEnum.RESIDENT_END_OF_SERVICE,
      SurveyTypeEnum.RESIDENT_DISCHARGE,
    ].includes(surveyType) && organization.residentSolution?.openLinkSurveys

  let allowDataSourceSelection = false

  if (organization.integrationId) {
    // Includes all survey types that support the sample pool with the exception of recurring survey types.
    allowDataSourceSelection = [
      SurveyTypeEnum.RESIDENT_START_OF_SERVICE,
      SurveyTypeEnum.RESIDENT_END_OF_SERVICE,
      SurveyTypeEnum.RESIDENT_DISCHARGE,
    ].includes(surveyType)
  }

  const mustBeOpenEnded = JSON.parse(SurveyTypesEndDateRelation.ALWAYS_OPEN_ENDED).includes(
    surveyType,
  )
  const mustHaveAnEndDate = JSON.parse(SurveyTypesEndDateRelation.ALWAYS_WITH_AN_END_DATE).includes(
    surveyType,
  )
  const canBeOpenEnded = !mustBeOpenEnded && !mustHaveAnEndDate

  let startDateMessage =
    'Choose when you would like this survey to start. It will automatically be set to end two weeks later.'
  if (mustBeOpenEnded) {
    startDateMessage = 'This is an open survey. You can close it manually at anytime.'
  } else if (canBeOpenEnded) {
    startDateMessage =
      'To keep the survey open, don’t set an end date. You can close it manually anytime.'
  }

  const onSubmit = () => {
    const newErrors = []
    if (allowNonConfidentialResults && survey.hasConfidentialResults === null) {
      newErrors.push('Please make a confidentiality selection.')
    }
    if (allowOpenLinkSurveys) {
      if (survey.distributionType === null) {
        newErrors.push('Please select a way to send this survey.')
      }
      if (
        survey.distributionType === SurveyDistributionTypeEnum.OPEN &&
        survey.filterTypeSettings.length <= 1
      ) {
        newErrors.push('Please select more than one value in the Filter and Share section.')
      }
    }
    if (allowDataSourceSelection && survey.participantDataSource === null) {
      newErrors.push('Please select a data source.')
    }
    if (newErrors.length) {
      setErrors(newErrors)
    } else if (!survey.uuid && allowOpenLinkSurveys) {
      setOpenWaysToShareConfirmationDialog(true)
    } else {
      updateSurvey()
    }
  }

  const defaultInviteMethod = SurveyInviteMethodEnum.SMS_EMAIL
  const { InviteIcon, inviteInstructions } = {
    [SurveyInviteMethodEnum.SMS_EMAIL]: {
      InviteIcon: <DesktopIcon />,
    },
    [SurveyInviteMethodEnum.SMS_EMAIL_PHONE]: {
      InviteIcon: (
        <>
          <DesktopIcon />
          &nbsp; &nbsp;
          <PhoneIcon />
        </>
      ),
      inviteInstructions: (
        <Typography color="textSecondary">
          Initially we will send participants a survey link via SMS or email. If there is no
          response after multiple attempts, we will call participants by phone. To learn more{' '}
          <ExternalNavLink
            to="https://support.homecarepulse.com/knowledge/call-process"
            label="read our survey call guidelines here"
          />
          .
        </Typography>
      ),
    },
    [SurveyInviteMethodEnum.PHONE]: {
      InviteIcon: <PhoneIcon />,
      inviteInstructions: (
        <Typography color="textSecondary">
          We will call your clients to complete your survey sample each month, to learn more{' '}
          <ExternalNavLink
            to="https://support.homecarepulse.com/knowledge/call-process"
            label="read our survey call guidelines here"
          />
          .
        </Typography>
      ),
    },
  }[surveyToEdit ? surveyToEdit.inviteMethod : defaultInviteMethod]

  const getAssociatedSurveyDropdownComponent = () => {
    const component = (
      <div className={classes.associatedSurveyDropdown}>
        <Typography variant="body2" color="textSecondary">
          Survey:
        </Typography>
        <FormControl variant="filled">
          <Select
            value={survey.associatedSurveyUuid || initialAssociatedSurveyUuid}
            onChange={e => {
              updateSurvey({
                associatedSurveyUuid: (e.target as HTMLInputElement).value,
              })
            }}
            displayEmpty={false}
            variant="filled"
            input={<FilledInput />}
            name="survey"
            disabled={Boolean(surveyToEdit?.associatedSurvey)}
          >
            {availableParentSurveys.map(
              s =>
                s && (
                  <MenuItem key={s.uuid} value={s.uuid}>
                    {s.name}
                  </MenuItem>
                ),
            )}
          </Select>
        </FormControl>
      </div>
    )
    if (surveyToEdit?.associatedSurvey) {
      return (
        <Tooltip
          title="The trust index survey you are pulsing from
          cannot be changed at this point. If you want to use
          a different one, create a new pulse survey."
        >
          {component}
        </Tooltip>
      )
    }
    return component
  }

  return (
    <ValidatorForm instantValidate={false} onSubmit={onSubmit}>
      <FormPanel expandIcon={EXPAND_ICON.ARROW}>
        <div className={classes.header}>
          <div className={classes.surveyNameContainer}>
            <DebouncedTextInput
              InputComponent={TextValidator}
              id="surveyName"
              name="surveyName"
              helperText=""
              value={survey.name}
              label={`${surveyNamePrefix} Survey Name*`}
              margin="normal"
              placeholder="Survey Name"
              className={classes.surveyName}
              validators={['required', `minStringLength:${SURVEY_NAME_MIN_LENGTH}`]}
              errorMessages={[
                'Survey Name is required',
                `Survey Name must be longer than ${SURVEY_NAME_MIN_LENGTH} characters`,
              ]}
              onUpdate={name => updateSurvey({ name })}
            />
            {isCustom && (
              <DebouncedTextInput
                InputComponent={TextValidator}
                id="surveyDescription"
                name="surveyDescription"
                margin="normal"
                helperText=""
                value={survey.description || ''}
                placeholder="Survey Description"
                className={classes.surveyDescription}
                onUpdate={description => updateSurvey({ description })}
              />
            )}
            <div className={classes.statusTagWrap}>
              <StatusTag
                status={surveyToEdit?.status || SurveyStatusEnum.DRAFT}
                isOpenLinkSurvey={survey.distributionType === SurveyDistributionTypeEnum.OPEN}
              />
            </div>
          </div>
          {isPulseSurvey && getAssociatedSurveyDropdownComponent()}
        </div>
      </FormPanel>
      <FormPanel
        expandIcon={EXPAND_ICON.ARROW}
        title="Time Period"
        footNote={startDateMessage}
        gutterTop
      >
        <div className={classes.container}>
          <div>
            <DateTimePicker
              minDateMessage="Date and time cannot be before today."
              disablePast
              id="start"
              label="Survey Start*"
              value={survey.startDate}
              margin="normal"
              helperText={getReadableTimezoneString()}
              onChange={(date: Date | null) => {
                if (!date) return
                if (canBeOpenEnded && !survey.endDate) {
                  updateSurvey({ startDate: date.toISOString() })
                } else {
                  updateSurvey({
                    startDate: date.toISOString(),
                    endDate: surveyMaxEndDate(date).toISOString(),
                  })
                }
              }}
            />
          </div>
          {!mustBeOpenEnded && (
            <div className={cn(classes.container, classes.endDate)}>
              <DateTimePicker
                minDate={survey.startDate}
                maxDate={surveyMaxEndDate(survey.startDate)}
                disabled={canBeOpenEnded && !survey.endDate}
                value={survey.endDate}
                id="end"
                label={canBeOpenEnded ? 'Survey End' : 'Survey End*'}
                margin="normal"
                helperText={getReadableTimezoneString()}
                onChange={(date: Date | null) => {
                  if (!date) return
                  updateSurvey({ endDate: date.toISOString() })
                }}
              />
              {canBeOpenEnded && (
                <div className={classes.openSurveySwitch}>
                  <Typography color="textSecondary">Leave survey open</Typography>
                  <div className={classes.container}>
                    <Typography color="textPrimary">No</Typography>
                    <Switch
                      checked={!survey.endDate}
                      onChange={e => {
                        if (e.target.checked) {
                          updateSurvey({ endDate: null })
                        } else {
                          updateSurvey({ endDate: surveyDefaultEndDate().toISOString() })
                        }
                      }}
                    />
                    <Typography color="textPrimary">Yes</Typography>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className={cn(classes.container, classes.inviteMethod)}>
            {InviteIcon}
            <Typography>
              &nbsp;&nbsp;
              {
                SURVEY_INVITE_METHOD_TO_LABEL[
                  surveyToEdit ? surveyToEdit.inviteMethod : defaultInviteMethod
                ]
              }{' '}
              Survey
            </Typography>
          </div>
        </div>
        <br />
        {inviteInstructions}
      </FormPanel>
      {allowOpenLinkSurveys && (
        <WaysToShare survey={survey} updateSurvey={updateSurvey} currentUser={currentUser} />
      )}
      {allowDataSourceSelection && <DataSource survey={survey} updateSurvey={updateSurvey} />}
      {allowNonConfidentialResults && (
        <FormPanel
          title="Confidentiality"
          gutterTop
          gutterHeader={false}
          helpText="Would you like the results to be anonymous or non-anonymous?*"
        >
          <FormControl component="fieldset">
            <RadioGroup>
              <FormControlLabel
                control={
                  <Radio
                    checked={survey.hasConfidentialResults === true}
                    name="confidential-results-yes"
                    onChange={() => updateSurvey({ hasConfidentialResults: true })}
                  />
                }
                label={<Typography id="anonymous">Anonymous</Typography>}
              />
              <Typography color="textSecondary" className={classes.radioButtonSubtext}>
                Participants will remain anonymous, and only aggregated data will appear in results.
              </Typography>
              <FormControlLabel
                control={
                  <Radio
                    checked={survey.hasConfidentialResults === false}
                    name="confidential-results-no"
                    onChange={() => updateSurvey({ hasConfidentialResults: false })}
                  />
                }
                label={<Typography id="non-anonymous">Non-Anonymous</Typography>}
              />
              <Typography color="textSecondary" className={classes.radioButtonSubtext}>
                Your results will show participant names, individual responses, and aggregated data.
              </Typography>
            </RadioGroup>
          </FormControl>
        </FormPanel>
      )}
      {hasMultipleLanguages && (
        <FormPanel
          gutterTop
          title="Languages"
          expandIcon={EXPAND_ICON.ARROW}
          footNote="You'll need to provide your own translations for any custom text you add."
        >
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="languages" value="en" checked disabled />}
                label="English"
              />
              {organization.languages.map((lang: OrganizationLanguageFragment) => (
                <FormControlLabel
                  key={lang.code}
                  control={
                    <Checkbox
                      name={`language_${lang.code}`}
                      value={lang.code}
                      checked={survey.languages.includes(lang.code)}
                      onChange={e => {
                        let newLanguages = survey.languages.slice()
                        if (e.target.checked) {
                          newLanguages.push(e.target.value)
                        } else {
                          newLanguages = survey.languages.filter(
                            language => language !== e.target.value,
                          )
                        }
                        updateSurvey({ languages: newLanguages })
                      }}
                    />
                  }
                  label={lang.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        </FormPanel>
      )}
      {errors.map(error => (
        <Danger key={error}>{error}</Danger>
      ))}
      {/* Components contain the "Back" and "Next" buttons of the wizard, which now only need to be displayed if the survey is editable ( i.e. DRAFT) */}
      {(!surveyToEdit || surveyToEdit.editable) && (
        <GridContainer direction="row-reverse" className={classes.saveAndContinue}>
          <ItemGrid sm={8}>
            <NavigationButtons
              goBack={goBack}
              goNextDisabled={saveIsDisabled}
              isSubmitting={loading}
              goNextLabel={survey.uuid ? 'Next' : 'Save and Continue'}
            />
          </ItemGrid>
          <ItemGrid sm={4}>
            <Typography color="textSecondary">* Required</Typography>
          </ItemGrid>
        </GridContainer>
      )}
      {openWaysToShareConfirmationDialog && (
        <ActionDialog
          title="Please Confirm"
          cancelButtonText="Back to Edit"
          content="Please confirm all your information is correct. Once saved - this action is final, and you will not be able to make edits to the ‘Ways to Share’
          section again."
          submitButtonText="Confirm"
          onClose={() => setOpenWaysToShareConfirmationDialog(false)}
          onSubmit={() => {
            setOpenWaysToShareConfirmationDialog(false)
            updateSurvey()
          }}
        />
      )}
    </ValidatorForm>
  )
}

const DesignContainer: React.FC<Props> = ({ survey, surveyType, ...restProps }) => {
  const isPulseSurvey = Object.keys(SURVEY_PULSE_TYPE_TO_PARENT_TYPE).includes(surveyType)
  const pulseParentSurveyType = isPulseSurvey
    ? SURVEY_PULSE_TYPE_TO_PARENT_TYPE[surveyType]
    : undefined

  const surveysResult = useSurveysSurveysQuery({
    variables: {
      ...getSurveysSurveysQueryVars(pulseParentSurveyType),
    },
  })

  return (
    <ResponseHandler {...surveysResult}>
      {({ surveys }) => {
        const availableParentSurveys = surveys?.edges
          .map(e => e?.node)
          .filter(
            s => s && s.status === SurveyStatusEnum.CLOSED && !s.hasLessThanMinShowableResults,
          )
        let initialAssociatedSurveyUuid
        if (isPulseSurvey) {
          if (!availableParentSurveys.length)
            throw new Error(`There are no potential parent surveys for this pulse survey`)
          if (survey?.associatedSurvey?.uuid) {
            initialAssociatedSurveyUuid = availableParentSurveys.find(
              s => s && s.uuid === survey.associatedSurvey?.uuid,
            )?.uuid
          } else {
            // Choose the first parent survey
            initialAssociatedSurveyUuid = availableParentSurveys[0]?.uuid
          }
        }

        return (
          <Design
            survey={survey}
            surveyType={surveyType}
            availableParentSurveys={availableParentSurveys}
            initialAssociatedSurveyUuid={initialAssociatedSurveyUuid}
            {...restProps}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default DesignContainer
