import { Theme, makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsContainer: {
      paddingBottom: theme.spacing(2),
    },
    halfRoot: {
      display: 'flex',
    },
    halfLeft: {
      width: '35%',
      textAlign: 'center',
      marginTop: '10px',
      marginBottom: '-10px',
    },
    halfRight: {
      paddingLeft: theme.spacing(2),
    },
    lgText: {
      fontSize: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
    planProgress: {
      display: 'flex',
      alignItems: 'center',
    },
    progressRoot: {
      marginLeft: '10px',
      height: '6px',
      width: '184px',
      overflow: 'hidden',
      background: theme.palette.common.danger,
      borderRadius: '2px',
    },
    progressBar: {
      height: '6px',
      background: theme.palette.common.success,
    },
    smallUpArrow: {
      fontSize: `${theme.spacing(2)}px`,
    },
    greenArrow: {
      color: theme.palette.common.success,
    },
    redArrow: {
      color: theme.palette.common.danger,
    },
    disabledRow: {
      color: theme.palette.common.grey,
    },
    actionsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    selectWithLabel: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& >*': {
        marginLeft: theme.spacing(2),
      },
    },
    selectWithLabelFC: {
      height: 32,
      minWidth: 100,
      maxWidth: 300,
    },
    globalIncrement: {
      paddingLeft: theme.spacing(3),
    },
    emptyContainer: {
      borderTop: `1px solid ${theme.palette.common.navy25}`,
      textAlign: 'center',
      height: '368px',
      verticalAlign: 'middle',
    },
    emptyContent: {
      paddingTop: '130px',
    },
    iconButton: {
      color: theme.palette.common.navy65,
      '& >span>span': {
        paddingRight: theme.spacing(1),
      },
    },
    defaultCursor: {
      cursor: 'default',
    },
    progressTooltip: {
      backgroundColor: theme.palette.common.success,
      color: '#FFF',
    },
    filter: {
      '& >p': {
        marginLeft: theme.spacing(2),
        marginBottom: 5,
      },
    },
    // Dialogs
    dialogTitle: {
      borderBottom: `1.5px solid ${theme.palette.common.navy25}`,
      margin: 0,
      paddingLeft: theme.spacing(6),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    title: {
      width: '90%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.common.navy65,
    },
  }),
)

export default useStyles
