import React, { useState } from 'react'

import { makeStyles, Typography, Checkbox } from '@material-ui/core'
import { groupBy } from 'lodash'

import HorizontalBarChart, {
  CHILD_COLORS,
  formatChartData,
  SortOrder,
  TooltipSeries,
} from 'components/Blocks/Charts/HorizontalBarChart'
import HorizontalChartTooltip from 'components/Blocks/Charts/HorizontalChartTooltip'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import {
  useInsightsScoresByDataTypeCombinedQuery,
  DataTypeCode,
  RankByEnum,
  OverallScoreType,
} from 'generated/graphql'

const useStyles = makeStyles(theme => ({
  chart: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  participantTypeCheckbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
  },
}))

export const getMultilevelBreakdownScores = (scores: OverallScoreType[]) => {
  // Map over the groups so we can make sure there is a record for both client/rep if one is empty.
  const groupedScores = groupBy(scores, s => s.groupHierarchy && s.groupHierarchy[0])
  const clientScores = []
  const representativeScores = []
  for (const group of Object.keys(groupedScores)) {
    const groupScores = groupedScores[group]
    const clientScore = groupScores.find(
      s => s.groupHierarchy && s.groupHierarchy[1] === 'Resident',
    )
    const repScore = groupScores.find(s => s.groupHierarchy && s.groupHierarchy[1] === 'Contacts')
    clientScores.push({ label: group, positive: clientScore?.positive })
    representativeScores.push({ label: group, positive: repScore?.positive })
  }
  return [clientScores, representativeScores]
}

type Props = {
  surveyUuid: string
  dtCode: string
  selectedFilters: string[]
  statementCodes?: string[]
  overallPositiveScore: number
  sortOrder: SortOrder
  benchmarkPositive: number
  benchmarkName: string
}
/**
 * Chart that fetches a muti-level breakdown for resident vs. family scores.
 * Parent components should render the UI that determines fetch variables.
 */
const ResidentBreakdownChart: React.FC<Props> = ({
  surveyUuid,
  dtCode,
  selectedFilters,
  overallPositiveScore,
  statementCodes,
  sortOrder,
  benchmarkPositive,
  benchmarkName,
}) => {
  const classes = useStyles()
  const [showParticipantBreakdown, setShowParticipantBreakdown] = useState(false)
  const variables = {
    surveyUuid,
    dtCode,
    dtCodeHierarchy: [dtCode, DataTypeCode.PARTICIPANT_TYPE],
    filters: selectedFilters,
    statementCodes,
    rankBy: RankByEnum.POSITIVE,
  }
  const result = useInsightsScoresByDataTypeCombinedQuery({ variables })
  const participantLegends = [
    { name: 'Resident', color: CHILD_COLORS[0] },
    { name: 'Contacts', color: CHILD_COLORS[1] },
  ]
  return (
    <ResponseHandler {...result}>
      {data => {
        if (
          !data.insightsScoresByDataTypeHierarchy?.length ||
          !data.insightsScoresByDataType?.length
        )
          return <div />
        const chartData = showParticipantBreakdown
          ? getMultilevelBreakdownScores(
              JSON.parse(data.insightsScoresByDataTypeHierarchy) as OverallScoreType[],
            )
          : [data.insightsScoresByDataType]
        return (
          <div className={classes.chart}>
            <div className={classes.participantTypeCheckbox}>
              <Checkbox
                checked={showParticipantBreakdown}
                onChange={() => setShowParticipantBreakdown(!showParticipantBreakdown)}
                value="participantBreakdown"
              />
              <Typography color="textSecondary">Resident vs. Contacts</Typography>
            </div>
            <HorizontalBarChart
              chartWidth="85%"
              chartData={formatChartData(chartData, sortOrder)}
              benchmarkData={[
                { name: benchmarkName, score: benchmarkPositive },
                { name: 'Company Score Overall', score: overallPositiveScore },
              ]}
              extraLegends={showParticipantBreakdown ? participantLegends : []}
              tooltipFormatter={(series: TooltipSeries) => {
                let scores = []
                if (showParticipantBreakdown) {
                  // Show a tooltip score for both "resident" and "contacts"
                  scores = [
                    { name: `${series[0].data.name} – Resident`, value: series[0].data.value },
                    { name: `${series[1].data.name} – Contacts`, value: series[1].data.value },
                  ]
                } else {
                  scores = [series[0].data]
                }
                return (
                  <HorizontalChartTooltip
                    scores={[
                      ...scores,
                      { name: benchmarkName, value: benchmarkPositive },
                      { name: 'Company Overall', value: overallPositiveScore },
                    ]}
                  />
                )
              }}
            />
          </div>
        )
      }}
    </ResponseHandler>
  )
}

export default ResidentBreakdownChart
