import React, { useState } from 'react'

import { InputAdornment, Typography } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import LoginCard from 'components/Blocks/Cards/LoginCard'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import Errors from 'components/Blocks/FormHelpers/Errors'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import useStyles from 'components/Login/LoginStyle'

type Props = {
  errors: null | string[]
  isSubmitting: Boolean
  onSubmit(password: string): void
}

const PasswordOnlyLogin: React.FC<Props> = ({ errors, isSubmitting, onSubmit }) => {
  const [password, setPassword] = useState('')
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <ItemGrid xs={12} sm={6} md={4}>
            <ValidatorForm instantValidate={false} onSubmit={() => onSubmit(password)}>
              <LoginCard
                customCardClass={classes.card}
                headerColor="aqua"
                cardTitle="Enter Report Password"
                footerAlign="center"
                footer={
                  <SubmitButton
                    className={classes.button}
                    color="primary"
                    wd
                    size="lg"
                    isSubmitting={isSubmitting}
                  >
                    Log In
                  </SubmitButton>
                }
                content={
                  <>
                    <Typography color="textSecondary">
                      Please submit the password to view this report.
                    </Typography>
                    <Errors errors={errors} />
                    <TextValidator
                      className={classes.textField}
                      id="password"
                      name="password"
                      type="password"
                      value={password}
                      label="Password"
                      placeholder=""
                      fullWidth
                      autoComplete="password"
                      onChange={e => setPassword((e.target as HTMLInputElement).value)}
                      validators={['required']}
                      errorMessages={['Password is required']}
                      inputProps={{ autoFocus: true }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                }
              />
            </ValidatorForm>
          </ItemGrid>
        </GridContainer>
      </div>
    </div>
  )
}

export default PasswordOnlyLogin
