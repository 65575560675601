import React, { useState, useEffect } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { useMachine } from '@xstate/react'
import Lottie from 'react-lottie'
import { createMachine } from 'xstate'

import animationData from 'assets/img/action_plans/action-item-done.json'
import ActionItemTask from 'components/ActionPlans/ActionItemTask'
import Button from 'components/Blocks/CustomButtons/Button'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import { gaEvent } from 'config/ga'
import {
  ActionPlansActionItemFragment,
  TaskStatusEnum,
  useActionPlansUpdateActionItemTaskMutation,
  useActionPlansDeleteActionItemMutation,
} from 'generated/graphql'
import { actionItemIsDone } from 'utils/actionPlansUtils'
import { PRODUCT_TYPE_LABELS } from 'utils/constants'

const animationHeight = 200
const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    position: 'relative',
    width: '100%',
    marginTop: spacing(3),
    marginBottom: spacing(3),
    minHeight: 100,
    border: `1px solid ${palette.common.navy25}`,
    borderRadius: 3,
  },
  actionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: palette.common.iceGrey,
    padding: spacing(2),
    '& >div': {
      display: 'flex',
      justifyContent: 'space-between',
      '& >h5': {
        marginRight: spacing(),
      },
    },
  },
  navyIcon: {
    color: palette.common.navy65,
  },
  actionItemHeader: {
    paddingTop: spacing(),
    paddingBottom: spacing(),
    paddingLeft: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addActionItem: {
    display: 'flex',
    alignItems: 'center',
  },
  tasksContainer: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  lottieAnimation: {
    position: 'absolute',
    margin: 'auto',
    top: `calc(50% - ${animationHeight / 2}px)`,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },
}))

type MachineActions = { type: 'COMPLETE' } | { type: 'FINISH_GIF' }
type MachineStates =
  | { value: 'incomplete'; context: {} }
  | { value: 'showCompletionGif'; context: {} }
  | { value: 'completed'; context: {} }
const createActionItemMachine = (initial: MachineStates['value']) =>
  createMachine<{}, MachineActions, MachineStates>({
    id: 'actionItem',
    initial,
    context: {},
    states: {
      incomplete: {
        on: {
          COMPLETE: 'showCompletionGif',
        },
      },
      showCompletionGif: {
        on: {
          FINISH_GIF: 'completed',
        },
      },
      completed: {
        type: 'final',
      },
    },
  })

const getFocusLabel = (actionItem: ActionPlansActionItemFragment) => {
  if (actionItem.statement) {
    return PRODUCT_TYPE_LABELS[actionItem.statement?.survey.productType]
  }
  if (!actionItem.customStatementProductType) return 'Other'
  return PRODUCT_TYPE_LABELS[actionItem.customStatementProductType]
}

type Props = {
  actionItem: ActionPlansActionItemFragment
  targetUserUuid?: string
}

const ActionItem: React.FC<Props> = ({ actionItem, targetUserUuid }) => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteActionItem] = useActionPlansDeleteActionItemMutation()
  const [updateActionItemTask] = useActionPlansUpdateActionItemTaskMutation()
  const initialState = actionItemIsDone(actionItem) ? 'completed' : 'incomplete'
  const [state, send] = useMachine(createActionItemMachine(initialState))

  useEffect(() => {
    if (state.matches('incomplete') && actionItemIsDone(actionItem)) {
      send('COMPLETE')
      setTimeout(() => send('FINISH_GIF'), 1500)
    }
  }, [actionItem, send, state])
  if (state.matches('completed')) {
    return null
  }
  return (
    <div className={classes.root}>
      {state.matches('showCompletionGif') && (
        <div className={classes.lottieAnimation}>
          <Lottie
            height={animationHeight}
            width={200}
            options={{
              loop: false,
              autoplay: true,
              animationData,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
          />
        </div>
      )}
      <div className={classes.actionItem}>
        <div>
          <Typography variant="h6">
            {actionItem.statement?.text || actionItem.customStatement}
            <span>
              {' '}
              &mdash; <Typography component="span">{getFocusLabel(actionItem)}</Typography>
            </span>
          </Typography>
        </div>
        <div>
          {editMode && (
            <>
              <IconButton
                className={classes.navyIcon}
                color="dangerHover"
                onClick={() => {
                  setOpenDeleteDialog(true)
                }}
              >
                <DeleteIcon />
              </IconButton>
              {openDeleteDialog && (
                <ActionDialog
                  title="Delete Statement?"
                  content="When you delete a statement you also delete all associated action items. This data will be deleted forever and can’t be undone. Are you sure you want to delete this statement?"
                  submitButtonText="Delete"
                  onClose={() => setOpenDeleteDialog(false)}
                  onSubmit={() => {
                    gaEvent({
                      action: 'actionItemDelete',
                      category: 'ActionPlans',
                    })
                    deleteActionItem({
                      variables: {
                        actionItemUuid: actionItem.uuid,
                        userUuid: targetUserUuid,
                      },
                    })
                  }}
                />
              )}
            </>
          )}
          <IconButton
            className={classes.navyIcon}
            color="secondaryHover"
            onClick={() => {
              gaEvent({
                action: 'actionItemToggleEditMode',
                category: 'ActionPlans',
              })
              setEditMode(!editMode)
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.actionItemHeader}>
        <Typography>Action Items:</Typography>
        <Button
          color="secondaryNoBackground"
          onClick={async () => {
            gaEvent({
              action: 'addActionItemTask',
              category: 'ActionPlans',
            })
            await updateActionItemTask({
              variables: {
                actionItemUuid: actionItem.uuid,
                taskUuid: null,
                task: {
                  text: '',
                  status: TaskStatusEnum.NOT_STARTED,
                },
                userUuid: targetUserUuid,
              },
            })
          }}
        >
          <AddIcon />
          <Typography>Action Item</Typography>
        </Button>
      </div>
      <ul className={classes.tasksContainer}>
        {actionItem.actionItemTasks.map(task => (
          <li key={task.uuid}>
            <ActionItemTask
              editMode={editMode}
              task={task}
              actionItemUuid={actionItem.uuid}
              targetUserUuid={targetUserUuid}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ActionItem
