import React from 'react'

import ActionPlanWrapper from 'components/ActionPlans/ActionPlanWrapper'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import NoClosedSurveys, { PageEnum } from 'components/Insights/NoClosedSurveys'
import {
  useActionPlansSurveysQuery,
  SurveyTypeEnum,
  CurrentUserQuery,
  SurveyStatusEnum,
} from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import { SurveyNodeAP as SurveyNode } from 'utils/types'

type Props = { currentUser: CurrentUserQuery['currentUser']; targetUserUuid?: string }

const ActionPlanContainer: React.FC<Props> = ({ currentUser, targetUserUuid }) => {
  const result = useActionPlansSurveysQuery()
  const { organization } = currentUser

  if (!currentUser.canUseActionPlans && !currentUser.canManageActionPlans) {
    return <div>You don't have access to Action Plans</div>
  }
  return (
    <ResponseHandler requiredQueries={['surveys']} {...result}>
      {({ surveys: surveysNodes }) => {
        const surveyTypes: SurveyTypeEnum[] = []
        const solutionAttrToSurveyType: Array<{
          solutionAttr: 'solution' | 'residentSolution'
          surveyType: SurveyTypeEnum
        }> = [
          { solutionAttr: 'solution', surveyType: SurveyTypeEnum.TI },
          { solutionAttr: 'residentSolution', surveyType: SurveyTypeEnum.RESIDENT_ENGAGEMENT },
        ]
        solutionAttrToSurveyType.forEach(({ solutionAttr, surveyType }) => {
          if (organization[solutionAttr]?.actionPlans) {
            surveyTypes.push(surveyType)
          }
        })
        const surveys = surveysNodes.edges
          .map(e => e?.node)
          .filter(
            (survey): survey is SurveyNode =>
              survey !== null &&
              survey !== undefined &&
              surveyTypes.includes(survey?.type) &&
              survey?.status === SurveyStatusEnum.CLOSED,
          )
        if (!surveys.length) return <NoClosedSurveys page={PageEnum.ACTION_PLANS} />
        return <ActionPlanWrapper surveys={surveys} targetUserUuid={targetUserUuid} />
      }}
    </ResponseHandler>
  )
}

export default withErrorHandler(ActionPlanContainer)
