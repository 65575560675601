import { Theme, createStyles, makeStyles } from '@material-ui/core'

import muiTheme, { colors } from 'shared/theme'

export const chartTextStyle = {
  color: muiTheme.palette.common.navy65,
  fontFamily: muiTheme.typography.fontFamily,
  fontSize: 12,
}

const borderColor = colors.navy25
export const border = `1px solid ${borderColor}`

export const insightsStyle = (theme: Theme) => {
  const green = theme.palette.common.success
  // Not sure about yellow?
  const yellow = theme.palette.common.warning
  const orange = theme.palette.common.warning
  const red = theme.palette.common.danger

  return createStyles({
    appBar: {
      '@media print': {
        display: 'none',
      },

      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
    },
    italics: {
      fontStyle: 'italic',
    },
    tabOverrideRoot: {
      whiteSpace: 'nowrap',
      minWidth: 0,
      fontSize: '1.4rem',
    },
    selectWithLabel: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& >*': {
        marginLeft: theme.spacing(2),
      },
    },
    selectWithLabelFC: {
      height: 32,
    },
    checkboxWithLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      marginTop: theme.spacing(2),
      '@media print': {
        width: 650,
      },
    },
    wrapperControls: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    benchmarkIcon: {
      '& svg': {
        color: theme.palette.common.navy65,
      },
    },
    controls: {
      '@media print': {
        display: 'none',
      },
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(1),
      borderBottom: border,
    },
    filterControls: {
      width: '60%',
      display: 'flex',
      '& >*': {
        marginLeft: theme.spacing(2),
      },
      alignItems: 'center',
    },
    filterByText: {
      marginLeft: theme.spacing(3),
    },
    filter: {
      cursor: 'pointer',
      display: 'flex',
      whiteSpace: 'nowrap',
      '& svg': {
        width: 18,
        color: theme.palette.common.secondary,
        marginLeft: 4,
      },
      '&:hover': {
        color: theme.palette.common.secondaryDark,
        '& svg': {
          color: theme.palette.common.secondaryDark,
        },
      },
    },
    filterActive: {
      color: theme.palette.common.secondaryDark,
      '& svg': {
        color: theme.palette.common.secondaryDark,
      },
    },
    controlsExpand: {
      '@media print': {
        display: 'none',
      },
      backgroundColor: theme.palette.common.navy65,
      padding: `0 ${theme.spacing(7)}px`,
    },
    chipControls: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    filterLetters: {
      '& a, & span': {
        color: theme.palette.common.white75,
        marginRight: 6,
        fontSize: '1.4rem',
      },
      '& a:hover, & a:focus, & a:focus:hover': {
        color: theme.palette.common.secondary,
      },
      '& a:focus': {
        backgroundColor: 'transparent',
      },
      '& span': {
        color: theme.palette.common.white25,
      },
    },
    selectableChips: {
      marginTop: `${theme.spacing(5) / 2}px`,
      marginBottom: `${theme.spacing(5) / 2}px`,
      maxHeight: 200,
      overflowY: 'auto',
      scrollBehavior: 'smooth',
      display: 'block',
      justifyContent: 'left',
      flexWrap: 'wrap',
    },
    surveyDropdownControlsRow: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.common.navy,
      '& >p': {
        color: theme.palette.common.white,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
      },
    },
    customDropdownSelect: {
      margin: theme.spacing(1),
      backgroundColor: `${theme.palette.common.white25} !important`,
      color: theme.palette.common.white,
      '& :hover': {
        backgroundColor: theme.palette.common.white50,
      },
    },
    selectedCategories: {
      paddingTop: `${theme.spacing(5) / 2}px`,
      paddingBottom: `${theme.spacing(5) / 2}px`,
      display: 'flex',
      '& $chip, & $chip:focus': {
        backgroundColor: theme.palette.common.navy,
        border: '0',
        '& svg': {
          color: theme.palette.common.white50,
        },
      },
      '& $chip:hover svg': {
        opacity: '1.0',
      },
    },
    selectedCategoriesFiltersVisible: {
      borderTop: `1px solid rgb(256, 256, 256, .5)`,
    },
    selectedCategoriesLabel: {
      height: 32,
      lineHeight: '32px',
      margin: 4,
      color: theme.palette.common.white,
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      flexGrow: 0,
      whiteSpace: 'nowrap',
    },
    selectedChips: {
      flexGrow: 1,
    },
    clearAllFilters: {
      height: 32,
      margin: 4,
      color: theme.palette.common.secondary,
      fontSize: '1.2rem',
      flexGrow: 0,
      backgroundColor: 'transparent',
      border: '0',
      whiteSpace: 'nowrap',
    },
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: 'transparent',
      border: '2px solid #FFF',
      color: theme.palette.common.white,
      '&:hover,&:focus:hover': {
        backgroundColor: theme.palette.common.secondary,
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    chipLetterAnchor: {
      display: 'inline-flex',
      margin: theme.spacing(0.5),
      marginRight: (3 / 2) * theme.spacing(1),
      color: theme.palette.common.white50,
      fontSize: '1.4rem',
    },
    chipActive: {
      backgroundColor: theme.palette.common.navy,
      '&:active, &:focus, &:hover, &:focus:hover': {
        backgroundColor: theme.palette.common.navy,
      },
      '&:active': {},
    },
    borderRight: {
      borderRight: border,
    },
    horizontalChartTooltip: {
      zIndex: 3,
      margin: theme.spacing(3),
      '& >p': {
        maxWidth: 600,
        '& >span': {
          whiteSpace: 'normal',
        },
      },
    },
    halfRow: {
      display: 'flex',
      borderBottom: border,
      '& >*': {
        padding: theme.spacing(3),
        width: '50%',
      },
      '& >div:first-child': {
        borderRight: border,
      },
    },
    fullRow: {
      position: 'relative',
      borderBottom: border,
      padding: theme.spacing(3),
    },
    pageBreak: {
      'page-break-before': 'always',
    },
    title: {
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      '& svg': {
        height: '1.6rem',
        marginBottom: -3,
        color: theme.palette.common.navy65,
        '&:hover': {
          color: theme.palette.common.navy,
        },
      },
    },
    green: {
      color: green,
    },
    orange: {
      color: orange,
    },
    yellow: {
      color: yellow,
    },
    red: {
      color: red,
    },
    lgText: {
      fontSize: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
    halfRoot: {
      position: 'relative',
      display: 'flex',
    },
    halfLeft: {
      minWidth: '47%',
      textAlign: 'center',
    },
    halfRight: {
      paddingLeft: theme.spacing(2),
    },
    printPadRight: {
      '@media print': {
        paddingLeft: 0,
        paddingRight: 40,
      },
    },
    mainStat: {
      display: 'flex',
      alignItems: 'center',
      margin: `${theme.spacing(2)}px 0`,
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    relativeContainer: {
      position: 'relative',
    },
    chartSortButton1: {
      position: 'absolute',
      zIndex: 2,
      backgroundColor: theme.palette.common.navy15,
      borderRadius: 4,
      right: '1%',
      top: 0,
      '@media print': {
        display: 'none',
      },
    },
    chartSortButton2: {
      position: 'absolute',
      zIndex: 2,
      backgroundColor: theme.palette.common.navy15,
      borderRadius: 4,
      right: -30,
      top: 35,
      '@media print': {
        display: 'none',
      },
      width: 50,
    },
    // TimeTrending
    timeTrendingSortButton: {
      width: 50,
      height: 50,
      backgroundColor: theme.palette.common.navy15,
      borderRadius: 4,
      '@media print': {
        display: 'none',
      },
    },
    clearMargin: {
      margin: 0,
    },
    timeTrendingInfoBox: {
      marginTop: theme.spacing(5),
      width: '25%',
      '& >h6': {
        textTransform: 'uppercase',
        color: theme.palette.common.success,
      },
      '@media print': {
        position: 'absolute',
        right: 0,
        marginTop: -40,
        width: 130,
        '& >p': {
          fontSize: '1rem',
        },
      },
    },
    timeTrendingChartRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 50,
      marginRight: 50,
      '@media print': {
        marginLeft: 0,
      },
    },
    percentageText: {
      display: 'flex',
      alignItems: 'center',
      '& >p': {
        fontSize: '3.6rem',
      },
      '& >span': {
        fontSize: '3.2rem',
        marginLeft: -4,
        marginRight: 4,
      },
      '@media print': {
        '& >p': {
          fontSize: 30,
        },
      },
    },
    halfWidth: {
      width: '50%',
    },
    printWidthThird: {
      '@media print': {
        width: '33%',
      },
    },
    clippedTitle: {
      width: '40%',
    },
    // TrustIndexCard
    dot: {
      display: 'inline-block',
      width: 8,
      height: 8,
      borderRadius: 4,
      marginRight: theme.spacing(1),
    },
    dotPositive: {
      backgroundColor: green,
    },
    dotInconsistent: {
      backgroundColor: orange,
    },
    dotNegative: {
      backgroundColor: red,
    },
    benchmarkText: {
      fontSize: '1.2rem',
      fontStyle: 'italic',
      color: theme.palette.common.navy65,
      fontWeight: 'normal',
      fontStretch: 'normal',
    },
    checkedIcon: {
      color: theme.palette.common.secondary,
    },
    unCheckedIcon: {
      color: theme.palette.common.navy65,
    },
    eeWrapper: {
      width: '90%',
      margin: '24px auto 0 auto',
    },
    eeLeftColumn: {
      [theme.breakpoints.down('sm')]: {
        padding: '0 !important',
        marginBottom: theme.spacing(3),
      },
    },
    eeRightColumn: {
      position: 'relative',
      '@media print': {
        height: 350,
      },
    },
    eeQuadWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    eeHighTrust: {
      marginBottom: theme.spacing(4),
    },
    eeHeader: {
      fontSize: '2rem',
      '& svg': {
        fill: theme.palette.common.navy65,
        height: 18,
        marginLeft: 3,
        marginBottom: -3,
      },
    },
    eeDimensionBreakdown: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontSize: '1.6rem',
      color: theme.palette.common.navy65,
      lineHeight: '1.6',
    },
    // Statements
    statementsHeader: {
      minHeight: 100,
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    statementsHeaderSummary: {
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      justifyContent: 'flex-start !important',
    },
    statementsSection: {
      position: 'relative',
      padding: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.common.navy25}`,
    },
    statementsRow: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    statementsLeft: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    statementsRight: {
      width: '50%',
    },
    statementsLabel: {
      width: '90%',
      fontSize: '1.6rem',
      marginTop: 20,
    },
    statementsFooter: {
      minHeight: 50,
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      display: 'flex',
      justifyContent: 'center',
      '@media print': {
        display: 'none',
      },
    },
    // Comments
    defaultMenuOption: {
      fontSize: '1.4rem',
      color: theme.palette.common.navy65,
    },
    locationsChart: {
      marginBottom: 44,
    },
    smallInfoIcon: {
      '& >svg': {
        height: '1.6rem',
        marginBottom: -3,
        color: theme.palette.common.navy65,
        '&:hover': {
          color: theme.palette.common.navy,
        },
      },
    },
    // These only used in Heatmap.js
    heatmapHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 50,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
      borderBottom: border,
    },
    heatmapSelectFC: {
      margin: theme.spacing(1),
      minWidth: 120,
      height: 32,
    },
    heatmapSelect: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& >p': {
        color: theme.palette.common.white,
      },
    },
    //  Everything from here to "Unused" only used in HeatmapGrid.js
    heatmapCheckbox: { paddingRight: 7 },
    heatmapBody: {
      backgroundColor: theme.palette.common.navy05,
      paddingBottom: theme.spacing(10),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      position: 'relative',
      marginBottom: 35,
    },
    heatmapTitleBar: {
      paddingBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    heatmapBenchmarkTitle: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      '& >p>span': {
        color: theme.palette.common.navy65,
      },
    },
    heatmapGradientContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    heatmapGradientBar: {
      width: 100,
      height: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 1,
      backgroundImage: 'linear-gradient(to left, #d03d3f, #ef585a 16%, #ffffff 48%, #02ab78)',
    },
    heatmapYAxisLabel: {
      position: 'absolute',
      transform: 'rotate(-90deg) translateX(-100%)',
      'transform-origin': 'left',
      '-webkit-transform-origin': 'left',
      left: theme.spacing(3),
    },
    heatmapGridContainer: {
      position: 'relative',
      border,
      backgroundColor: '#FFF',
      marginBottom: 80,
    },
    heatmapDimensionsGrid: {
      display: 'flex',
      textAlign: 'center',
    },
    heatmapDimensionEllipsis: {
      position: 'relative',
      cursor: 'pointer',
      '& >p': {
        paddingRight: theme.spacing(1),
      },
    },
    heatmapGrid: {
      '& >div:last-child': {
        borderBottom: 'none',
      },
      maxHeight: 500,
      overflowY: 'scroll',
      overflowX: 'hidden',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:hover >div': {
        display: 'flex',
      },
      '&::-webkit-scrollbar': {
        width: 0,
      },
      '@media print': {
        overflowY: 'visible',
        maxHeight: 'none',
      },
    },
    heatmapDimensionsGridLine: {
      position: 'absolute',
      maxHeight: 500,
      width: 2,
      backgroundColor: theme.palette.common.navy,
      zIndex: 2,
      top: 21,
    },
    heatmapTooltip: {
      display: 'none',
      zIndex: 2,
      position: 'fixed',
      backgroundColor: theme.palette.common.navy,
      borderRadius: 2,
      '& >div': {
        padding: 12,
      },
      '& >div>p': {
        color: '#FFF',
      },
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    heatmapCellPercentage: {
      fontSize: '2.4rem',
    },
    heatmapCellComment: {
      fontSize: '1.2rem',
    },
    heatmapZoomControls: {
      position: 'absolute',
      bottom: 3,
      right: 3,
      zIndex: 3,
      '& >button': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 1,
        borderRadius: 2,
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.71)',
        border: 'solid 1px rgba(15, 50, 70, 0.25)',
        backgroundColor: '#FFF',
        '&:hover': {
          'box-shadow': '0 5px 15px 0 rgba(0, 0, 0, 0.71)',
        },
        '& >p': {
          fontSize: 16,
          width: theme.spacing(3),
          height: theme.spacing(3),
        },
      },
    },
    // A copy of material UI paragraph class so we can avoid using global styles in tooltips
    // see StandardTooltip for bug explanation.
    paragraph: {
      letterSpacing: '0.00938em',
      fontWeight: 400,
      color: theme.palette.common.navy,
      margin: 0,
      fontSize: '1.6rem',
      lineHeight: 1.5,
    },
    textSecondary: {
      color: theme.palette.common.navy65,
    },
    textPrimary: {
      color: theme.palette.common.navy,
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: 1.43,
    },
    highlights: {
      paddingTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-evenly',
      '& >div': {
        minWidth: '20%',
        padding: '0 2%',
        '& >h6, >h5, >p': {
          textTransform: 'uppercase',
          wordBreak: 'break-word',
        },
      },
    },
  })
}

export default makeStyles(insightsStyle)
