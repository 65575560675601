import React, { ReactElement } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import chunk from 'lodash/chunk'
import groupBy from 'lodash/groupBy'

const useStyles = makeStyles(({ spacing, palette }) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  letterLabel: {
    textTransform: 'uppercase',
  },
  letterList: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: spacing(2),
    textAlign: 'center',
  },
  letterKey: {
    textTransform: 'uppercase',
    color: palette.common.navy25,
    '&:hover': {
      cursor: 'pointer',
      color: palette.common.navy,
    },
  },
  itemsContainer: {
    overflowY: 'scroll',
    maxHeight: 300,
    width: '100%',
  },
}))

type Props<Item> = {
  items: Item[]
  getItemLabel(item: Item): string
  children(items: Item[]): ReactElement
  disabled: boolean
}

const AlphaScrollList = <Item, _>({ items, getItemLabel, children }: Props<Item>): ReactElement => {
  const classes = useStyles()

  let itemsContainerRef: HTMLDivElement | null = null
  const letterRefs: { [key: string]: HTMLDivElement | null } = {}
  const alphaGroups = groupBy(items, item => getItemLabel(item)[0])
  const letters = Object.keys(alphaGroups)
  const maxLetters = 15

  const scrollToLetter = (letter: string) => {
    const letterRef = letterRefs[letter]
    if (!itemsContainerRef || !letterRef) return
    const distanceFromTop = letterRef.offsetTop - itemsContainerRef.offsetTop
    itemsContainerRef.scrollTop = distanceFromTop
  }

  const renderLettersList = (lettersList: string[]) => {
    // If the number of letters exceeds the maximum we can display on the screen
    // display the letters as pairs and scroll to the first letter.
    const chunkSize = lettersList.length <= maxLetters ? 1 : 2
    const letterChunks = chunk(letters, chunkSize)
    return (
      <div className={classes.letterList}>
        {letterChunks.map(letterChunk => (
          <Typography
            className={classes.letterKey}
            key={`letter-list-${letterChunk[0]}`}
            variant="body2"
            onClick={() => scrollToLetter(letterChunk[0])}
          >
            {letterChunk.join('')}
          </Typography>
        ))}
      </div>
    )
  }

  return (
    <div className={classes.flexContainer}>
      <div className={classes.itemsContainer} ref={r => (itemsContainerRef = r)}>
        {Object.keys(alphaGroups).map(letter => {
          return (
            <div key={`${letter}-group`} ref={r => (letterRefs[letter] = r)}>
              <Typography color="textSecondary" variant="body1" className={classes.letterLabel}>
                {letter}
              </Typography>
              {children(alphaGroups[letter])}
            </div>
          )
        })}
      </div>
      {renderLettersList(letters)}
    </div>
  )
}

export default AlphaScrollList
