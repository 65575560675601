import React, { useRef } from 'react'

import { makeStyles, Tooltip, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import cn from 'classnames'
import { groupBy } from 'lodash'
import maxBy from 'lodash/maxBy'
import { Cell, Pie, PieChart, Label } from 'recharts'

import { ReactComponent as MinShowableResultsIcon } from 'assets/img/min-showable-results.svg'
import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import ComparisonTooltip from 'components/Insights/Comparisons/ComparisonTooltip'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import {
  InsightsDemographic,
  InsightsGroupedScoreFragment,
  useInsightsOverallIndexQuery,
  useInsightsScoresByDataTypeQuery,
  useInsightsScoresByDataTypeHierarchyQuery,
  OverallScoreType,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { INSIGHTS_COLORS, MIN_SHOWABLE_RESULTS_CODE } from 'utils/constants'

const useStyles = makeStyles(({ spacing, palette }) => ({
  comparisonDonutsSnapshot: {
    padding: spacing(4),
  },
  comparisonHeader: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between',
  },
  comparisonHeaderText: {
    flex: 1, // IE11
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: spacing(2),
  },
  comparisonLegend: {
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&>li': {
      marginLeft: spacing(4),
      color: palette.common.navy65,
      fontSize: '1.4rem',
    },
  },
  cardRoot: {
    position: 'relative',
    width: '100%',
    marginTop: spacing(3),
    marginBottom: spacing(3),
    minHeight: 100,
    border: `1px solid ${palette.common.navy25}`,
    borderRadius: 3,
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: palette.common.iceGrey,
    padding: spacing(2),
    '& >h6': {
      color: palette.common.navy65,
      marginLeft: spacing(1),
    },
  },
  comparisonOverviewDonut: {
    width: 224,
    margin: 'auto',
    marginTop: spacing(3),
    marginBottom: spacing(3),
  },
  comparisonOneDimension: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: spacing(3),
  },
  comparisonDonut: {
    margin: spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  comparisonDonutLabel: {
    textAlign: 'center',
    fontSize: '1.6rem',
    margin: 0,
    marginTop: 10,
  },
  comparisonChart: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
    msGridColumns: '150px 1fr',
    gridTemplateRows: 'auto 1fr',
    msGridRows: 'auto 1fr',
    maxHeight: 704,
  },
  comparisonGrid: {
    fontSize: '1.6rem',
    overflow: 'auto',
    maxHeight: 704,
    gridRow: 2,
    gridColumn: 2,
  },
  comparisonGridRow: {
    display: 'flex',
    // This is 0 because it is only shown in some cases, but can be turned on just by setting borderWidth inline
    borderBottom: `0px solid ${palette.common.navy25}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  comparisonGridCell: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    margin: spacing(1),
  },
  comparisonGridAxis: {
    overflow: 'hidden',
    color: `${palette.common.navy65}`,
    fontSize: '1.6rem',
  },
  comparisonGridXAxis: {
    minHeight: '50px',
    display: 'flex',
    marginTop: spacing(3),
    marginRight: spacing(1), // Adjust for scrollbar in chart area
    gridRow: 1,
    gridColumn: 2,
  },
  comparisonGridYAxis: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight: 704,
    marginLeft: spacing(3),
    paddingBottom: spacing(3), // Adjust for scrollbar in chart area
    gridRow: 2,
    gridColumn: 1,
  },
  comparisonGridXLabel: {
    textAlign: 'center',
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
    '&:last-child': {
      paddingRight: '20px',
    },
  },
  comparisonGridYLabel: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    marginRight: 0,
    marginLeft: 0,
    width: 'auto',
    '&:last-child': {
      paddingBottom: '20px',
    },
  },
}))

type RenderLabelProps = {
  radius: number
  positive: number
}

const RenderLabel: React.FC<RenderLabelProps> = props => {
  const { radius, positive } = props
  const minRadius = 15
  const maxRadius = 115
  const minFontSize = 15
  const maxFontSize = 42
  const sizeRatio = (radius - minRadius) / maxRadius
  const fontSize = sizeRatio * (maxFontSize - minFontSize) + minFontSize
  let label = ''
  if (radius * 2 >= 55) {
    label = `${Math.round(positive)}%`
  } else if (radius * 2 >= 45) {
    label = `${Math.round(positive)}`
  }
  return (
    <text
      x="50%"
      y="50%"
      dy={fontSize / 3}
      textAnchor="middle"
      style={{
        fontSize: `${fontSize}px`,
        fill: colors.navy65,
      }}
    >
      {label}
    </text>
  )
}

type DonutProps = {
  width: number
  maxCount: number
  minShowableResults: number
} & InsightsGroupedScoreFragment

const Donut: React.FC<DonutProps> = ({
  minShowableResults,
  width,
  count,
  maxCount,
  positive,
  inconsistent,
  negative,
}) => {
  if (!count || count < minShowableResults) return <></>
  const getDonutRadius = () => {
    const minimumRadius = 15
    const maximumRadius = width / 2
    const totalPercentile = count / maxCount
    const outer = (maximumRadius - minimumRadius) * totalPercentile + minimumRadius
    const inner = outer * 0.8 - 5
    return { outer, inner }
  }
  const radius = getDonutRadius()
  const data = [
    { name: 'positive', value: positive, total: count },
    { name: 'inconsistent', value: inconsistent, total: count },
    { name: 'negative', value: negative, total: count },
  ]
  return (
    <PieChart width={radius.outer * 2} height={radius.outer * 2}>
      <Pie
        cx="50%"
        cy="50%"
        data={data}
        dataKey="value"
        endAngle={-270}
        fill={colors.navy11}
        isAnimationActive={false}
        legendType="square"
        nameKey="name"
        innerRadius={radius.inner}
        outerRadius={radius.outer}
        paddingAngle={0}
        startAngle={90}
      >
        <Label
          position="center"
          content={<RenderLabel radius={radius.outer} positive={positive} />}
        />

        {data.map((entry, index) => (
          <Cell key={entry.name} fill={INSIGHTS_COLORS[index]} />
        ))}
      </Pie>
    </PieChart>
  )
}

type DonutChartProps = {
  surveyUuid: string
  minShowableResults: number
  filters: string[]
  statementCodes?: string[]
  surveyProductType: SurveyProductTypeEnum
}

type ComparisonDonutsProps = {
  surveyUuid: string
  minShowableResults: number
  filters: string[]
  selectedGroup: string
  statementLabel: string
  statementCodes?: string[]
  xAxis?: InsightsDemographic
  yAxis?: InsightsDemographic
  surveyProductType: SurveyProductTypeEnum
}

const ComparisonDonuts: React.FC<ComparisonDonutsProps> = ({
  surveyUuid,
  minShowableResults,
  filters,
  selectedGroup,
  statementLabel,
  statementCodes,
  yAxis: yAxisDemographic,
  xAxis: xAxisDemographic,
  surveyProductType,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  let xAxis = xAxisDemographic
  let yAxis = yAxisDemographic
  if (!xAxisDemographic || !yAxisDemographic) {
    yAxis = yAxisDemographic || xAxisDemographic
    xAxis = undefined
  }
  const childProps = {
    surveyUuid,
    filters,
    statementCodes,
    surveyProductType,
    classes,
  }

  const surveyTypeText =
    surveyProductType === SurveyProductTypeEnum.EMPLOYEE ? 'employee' : 'customer'
  const infoText = `See how scores compare across your organization for specific statements or your overall score.
  The bigger the circle, the more people are in that group. Look for the biggest circles with the most red and orange -
  these are the groups driving down your overall scores and the ones you should focus on to impove ${surveyTypeText} experience.
  Groups with <minShowableResults responses are not shown.`
  return (
    <>
      <div id="comparisonsSnapshot" className={classes.comparisonDonutsSnapshot}>
        <div className={classes.comparisonHeader}>
          <div className={classes.comparisonHeaderText}>
            <Typography className={classes.title} variant="subtitle1">
              COMPARISONS
              <Tooltip title={infoText}>
                <InfoIcon />
              </Tooltip>
            </Typography>
            <Typography color="textSecondary" className={classes.description}>
              Drill down to see exactly which groups are driving your scores. You can then target
              these specific groups to make sure you understand what’s going on and put together a
              plan to improve.
            </Typography>
          </div>
          <ScreenshotButton snapId="comparisonsSnapshot" strategy="html2canvas" />
        </div>

        <ul className={classes.comparisonLegend}>
          <li>
            <span className={cn(classes.dot, classes.dotPositive)} />
            Positive
          </li>
          <li>
            <span className={cn(classes.dot, classes.dotInconsistent)} />
            Inconsistent
          </li>
          <li>
            <span className={cn(classes.dot, classes.dotNegative)} />
            Negative
          </li>
        </ul>
        <div className={classes.cardRoot}>
          <div className={classes.cardHeader}>
            <Typography variant="h5">{selectedGroup}:</Typography>
            <Typography variant="h6">{statementLabel}</Typography>
          </div>
          {xAxis && yAxis && (
            <TwoDimensionDonuts
              minShowableResults={minShowableResults}
              {...childProps}
              xAxis={xAxis}
              yAxis={yAxis}
            />
          )}
          {yAxis && !xAxis && (
            <OneDimensionDonuts
              minShowableResults={minShowableResults}
              {...childProps}
              yAxis={yAxis}
            />
          )}
          {!yAxis && !xAxis && (
            <OverallDonut {...childProps} minShowableResults={minShowableResults} />
          )}
        </div>
      </div>
    </>
  )
}

const OverallDonut: React.FC<DonutChartProps> = ({
  surveyUuid,
  minShowableResults,
  filters,
  statementCodes,
  surveyProductType,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const result = useInsightsOverallIndexQuery({
    variables: {
      surveyUuid,
      filters,
      statementCodes,
      includeCustom: true,
    },
  })
  const width = 224
  return (
    <ResponseHandler {...result}>
      {({ insightsOverallIndex }) => {
        const data = insightsOverallIndex
        return (
          <Tooltip
            title={<ComparisonTooltip data={data} surveyProductType={surveyProductType} />}
            placement="top"
          >
            <div className={classes.comparisonOverviewDonut}>
              <Donut
                minShowableResults={minShowableResults}
                width={width}
                count={data.count!}
                maxCount={data.count!}
                positive={data.positive!}
                inconsistent={data.inconsistent!}
                negative={data.negative!}
              />
              <div className={classes.comparisonDonutLabel}>Overall</div>
            </div>
          </Tooltip>
        )
      }}
    </ResponseHandler>
  )
}

type OneDimensionProps = {
  yAxis: InsightsDemographic
  minShowableResults: number
} & DonutChartProps

const OneDimensionDonuts: React.FC<OneDimensionProps> = ({
  minShowableResults,
  surveyUuid,
  filters,
  statementCodes,
  surveyProductType,
  yAxis,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const result = useInsightsScoresByDataTypeQuery({
    variables: {
      surveyUuid,
      filters,
      statementCodes,
      dtCode: yAxis.code,
      includeAllSurveyChoices: true,
    },
  })

  return (
    <ResponseHandler {...result}>
      {({ insightsScoresByDataType }) => {
        // Filter out rows with all < Min values
        const filteredScores = insightsScoresByDataType.filter(
          cell =>
            cell?.totalResponses &&
            cell.totalResponses >= minShowableResults &&
            cell.positive !== MIN_SHOWABLE_RESULTS_CODE,
        )
        const maxCount = maxBy(filteredScores, 'totalResponses')?.totalResponses || 0
        if (maxCount < minShowableResults) {
          return (
            <EmptyState
              title="Please change your selections"
              description={`The applied filters show results for less than ${minShowableResults} employees.
                  We can't show their responses to protect confidentiality.
                  Remove one of your filters to see results.`}
              Icon={MinShowableResultsIcon}
            />
          )
        }
        return (
          <div className={classes.comparisonOneDimension}>
            {filteredScores.map(d => (
              <Tooltip
                key={String(d.label)}
                title={
                  <ComparisonTooltip
                    y={yAxis}
                    yVal={d.label}
                    data={d}
                    surveyProductType={surveyProductType}
                  />
                }
                placement="top"
              >
                <div className={classes.comparisonDonut}>
                  <Donut
                    minShowableResults={minShowableResults}
                    width={150}
                    count={d.totalResponses!}
                    maxCount={maxCount}
                    positive={d.positive}
                    inconsistent={d.inconsistent}
                    negative={d.negative}
                  />
                  <div className={classes.comparisonDonutLabel}>{d.label || 'No Response'}</div>
                </div>
              </Tooltip>
            ))}
          </div>
        )
      }}
    </ResponseHandler>
  )
}

type TwoDimensionProps = {
  yAxis: InsightsDemographic
  xAxis: InsightsDemographic
  minShowableResults: number
} & DonutChartProps

const TwoDimensionDonuts: React.FC<TwoDimensionProps> = ({
  surveyUuid,
  minShowableResults,
  filters,
  statementCodes,
  surveyProductType,
  yAxis,
  xAxis,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const comparisonGridRef = useRef<HTMLDivElement>(null)
  const xAxisRef = useRef<HTMLDivElement>(null)
  const yAxisRef = useRef<HTMLDivElement>(null)
  const result = useInsightsScoresByDataTypeHierarchyQuery({
    variables: {
      surveyUuid,
      filters,
      statementCodes,
      dtCodeHierarchy: [yAxis.code, xAxis.code],
      includeAllSurveyChoices: true,
    },
  })
  return (
    <ResponseHandler {...result}>
      {({ insightsScoresByDataTypeHierarchy }) => {
        const MIN_CELL_SIZE = 100
        const CONTAINER_WIDTH = 700
        const getCellSize = (rowLength: number): number => {
          if (CONTAINER_WIDTH / rowLength < MIN_CELL_SIZE) return 100
          return Math.min(200, CONTAINER_WIDTH / rowLength) - 16 // Adjust for margin between
        }
        const hierarchyScores = JSON.parse(insightsScoresByDataTypeHierarchy) as OverallScoreType[]
        const grid = Object.values(
          groupBy(hierarchyScores, score => score.groupHierarchy && score.groupHierarchy[0]),
        )

        // Filter out rows with all < Min values
        const filteredScores = grid.filter(row =>
          row.some(cell => cell?.totalResponses && cell.totalResponses > minShowableResults),
        )

        // Filter out columns with all < Min values
        const filteredCellsAsRows = filteredScores.map(row =>
          row.filter((_, idx) =>
            filteredScores.some(r => {
              const cell = r[idx]
              return cell?.totalResponses && cell.totalResponses > minShowableResults
            }),
          ),
        )
        // This gets the max count of all children of the returned comparisons data
        const maxCount = Math.max(
          ...filteredCellsAsRows.map(row => maxBy(row, 'totalResponses')?.totalResponses || 0),
        )
        if (maxCount < minShowableResults) {
          return (
            <EmptyState
              title="Please change your selections"
              description={`The applied filters show results for less than ${minShowableResults} employees.
                  We can't show their responses to protect confidentiality.
                  Remove one of your filters to see results.`}
              Icon={MinShowableResultsIcon}
            />
          )
        }
        const yKeys: string[] = filteredCellsAsRows.map(row =>
          String(
            (row.length && row[0].groupHierarchy && row[0].groupHierarchy[0]) || 'No Response',
          ),
        )
        const xKeys: string[] = filteredCellsAsRows[0].map(c => String(c.label || 'No Response'))

        const cellSize = getCellSize(xKeys.length)
        const showBorders = cellSize === MIN_CELL_SIZE
        const chartHeight = (cellSize + 16) * yKeys.length
        const chartWidth = (cellSize + 16) * xKeys.length

        const onScroll = () => {
          // Keep axis scrolling in sync with chart scroll
          if (!comparisonGridRef.current || !xAxisRef.current || !yAxisRef.current) return
          const { scrollLeft, scrollTop } = comparisonGridRef.current
          xAxisRef.current.scrollLeft = scrollLeft
          yAxisRef.current.scrollTop = scrollTop
        }

        return (
          <div className={classes.comparisonChart}>
            <div
              className={cn(classes.comparisonGridXAxis, classes.comparisonGridAxis)}
              ref={xAxisRef}
            >
              {xKeys.map(xKey => (
                <Tooltip key={xKey} title={xKey} placement="top">
                  <div
                    key={xKey}
                    className={cn(classes.comparisonGridXLabel, classes.comparisonGridCell)}
                    style={{ flexBasis: cellSize, width: chartWidth }}
                  >
                    {xKey}
                  </div>
                </Tooltip>
              ))}
            </div>
            <div
              className={cn(classes.comparisonGridYAxis, classes.comparisonGridAxis)}
              ref={yAxisRef}
            >
              {yKeys.map(yKey => (
                <Tooltip key={yKey} title={yKey} placement="top">
                  <div
                    key={yKey}
                    className={cn(classes.comparisonGridYLabel, classes.comparisonGridCell)}
                    style={{ flexBasis: cellSize, height: chartHeight }}
                  >
                    {yKey}
                  </div>
                </Tooltip>
              ))}
            </div>
            <div className={classes.comparisonGrid} ref={comparisonGridRef} onScroll={onScroll}>
              {filteredCellsAsRows.map((scoreRow, ind) => (
                <div
                  key={yKeys[ind]}
                  className={classes.comparisonGridRow}
                  style={{
                    borderWidth: showBorders ? '1px' : '0',
                    marginBottom: showBorders ? '-1px' : '0',
                    // Explicitly setting width of row so that border shows across scroll area. Otherwise it is cropped at the initial view size.
                    width: chartWidth,
                  }}
                >
                  {scoreRow.map(scoreCell => {
                    return (
                      <div
                        key={`${scoreCell.label}-${yKeys[ind]}`}
                        className={classes.comparisonGridCell}
                        style={{ flexBasis: cellSize, height: cellSize }}
                      >
                        {scoreCell && (
                          <Tooltip
                            title={
                              <ComparisonTooltip
                                y={yAxis}
                                yVal={yKeys[ind]}
                                x={xAxis}
                                xVal={scoreCell.label}
                                data={scoreCell}
                                surveyProductType={surveyProductType}
                              />
                            }
                            placement="top"
                          >
                            <div>
                              <Donut
                                minShowableResults={minShowableResults}
                                width={cellSize}
                                maxCount={maxCount}
                                count={scoreCell.totalResponses!}
                                positive={scoreCell.positive}
                                inconsistent={scoreCell.inconsistent}
                                negative={scoreCell.negative}
                              />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          </div>
        )
      }}
    </ResponseHandler>
  )
}
export default ComparisonDonuts
