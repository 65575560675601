import React from 'react'

import { ListItemText, Menu, MenuItem, Popover, Tooltip, makeStyles } from '@material-ui/core'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Loader from 'components/Blocks/Loader'
import GroupedSelect from 'components/Insights/Blocks/GroupedSelect'
import { useInsightsBenchmarksQuery, BenchmarkTypeEnum, BenchmarkNode } from 'generated/graphql'
import { BENCHMARK_TYPES } from 'utils/constants'

const useStyles = makeStyles(theme => ({
  selectLabel: {
    color: theme.palette.common.navy65,
    padding: theme.spacing(2),
    fontSize: '1.5rem',
  },
}))

interface Props {
  selectedBenchmark: BenchmarkNode
  benchmarkInternal: boolean
  selectedSurveyUuid: string
  setBenchmark(benchmark: BenchmarkNode): void
  onClose(): void
  anchorEl: Element
}

const BenchmarkSelect: React.FC<Props> = ({
  benchmarkInternal,
  selectedBenchmark,
  selectedSurveyUuid,
  setBenchmark,
  onClose,
  anchorEl,
}) => {
  const classes = useStyles()
  const variables = { surveyUuid: selectedSurveyUuid }
  const result = useInsightsBenchmarksQuery({ variables })
  const loaderNode = (
    <Popover
      open
      PaperProps={{
        style: {
          width: 200,
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <Loader />
    </Popover>
  )
  return (
    <ResponseHandler {...result} hideLoader loaderNode={loaderNode}>
      {({ benchmarks }) => {
        if (!benchmarks || !selectedBenchmark) return <></>
        const benchmarkOptions = benchmarks.map(b => ({
          ...b,
          group: BENCHMARK_TYPES[b.benchmarkType],
          subgroup: b.group || undefined,
        }))
        const selectedBenchmarkOption = {
          ...selectedBenchmark,
          group: BENCHMARK_TYPES[selectedBenchmark.benchmarkType],
          lessThanMin: false,
        }
        const surveyCount = benchmarks.filter(
          b => b.benchmarkType === BenchmarkTypeEnum.PAST_SURVEY,
        ).length
        const showGroups = benchmarkInternal || surveyCount > 3
        const getLessThanMinMessage = (minShowableResults: number) =>
          `This benchmark cannot be used because it has less than ${minShowableResults} responses.`
        if (showGroups) {
          return (
            <GroupedSelect
              anchorEl={anchorEl}
              items={benchmarkOptions}
              selected={selectedBenchmarkOption}
              onChange={benchmark => setBenchmark(benchmark)}
              getItemValue={item => item.benchmarkUuid}
              getItemLabel={item => item.name}
              getItemDisabledMessage={item =>
                item?.lessThanMin ? getLessThanMinMessage(item.minShowableResults) : ''
              }
              onClose={onClose}
            />
          )
        }
        return (
          <Menu
            id="benchmarksMenu"
            anchorEl={anchorEl}
            open
            onClose={onClose}
            PaperProps={{
              style: {
                minWidth: 200,
                width: 'auto',
              },
            }}
          >
            <div className={classes.selectLabel}>Benchmarks:</div>
            {benchmarks.map(b => (
              <MenuItem
                key={b.benchmarkUuid}
                disabled={b.lessThanMin}
                selected={selectedBenchmark?.benchmarkUuid === b.benchmarkUuid}
                onClick={() => setBenchmark(b)}
              >
                <Tooltip title={b.lessThanMin ? getLessThanMinMessage(b.minShowableResults) : ''}>
                  <ListItemText>{b.name}</ListItemText>
                </Tooltip>
              </MenuItem>
            ))}
          </Menu>
        )
      }}
    </ResponseHandler>
  )
}

export default BenchmarkSelect
