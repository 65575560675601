import React, { useState, useEffect, useCallback } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import FilterIcon from '@material-ui/icons/FilterList'
import SettingsIcon from '@material-ui/icons/Settings'
import sortBy from 'lodash/sortBy'
import { v4 as uuidv4 } from 'uuid'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import { AnalyticsSurveyNode } from 'components/Analytics/AnalyticsContainer'
import HorizontalBarChart, {
  formatChartData,
  TooltipSeries,
} from 'components/Blocks/Charts/HorizontalBarChart'
import Button from 'components/Blocks/CustomButtons/Button'
import Dialog from 'components/Blocks/Dialogs/Dialog'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import BarChartSortButton from 'components/Insights/Blocks/BarChartSortButton'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import GroupedSelect from 'components/Insights/Blocks/GroupedSelect'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import {
  getServiceScores,
  getStatementsByService,
  StatementsByService,
} from 'components/Insights/ResidentSnapshot/ServicesChart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import StandardTooltip from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import ServiceAreasTab from 'components/Settings/General/ServicesAreasTab'
import { gaEvent } from 'config/ga'
import {
  DataTypeCode,
  InsightsOverlayServicesSettingsQuery,
  QResidentFocus,
  ServiceAreaEnum,
  SurveyProductTypeEnum,
  useInsightsOverlayServicesDataQuery,
  useInsightsOverlayServicesSettingsQuery,
} from 'generated/graphql'
import { formatTooltipScore } from 'utils'
import {
  PRODUCT_TYPE_TO_OVERLAY_COLORS,
  PRODUCT_TYPE_TO_OVERLAY_TOOLTIP_COLORS,
  SORT_OPTIONS,
} from 'utils/constants'
import { WorkStatusEnum, DTypeEnum } from 'utils/generatedEnums'

const useStyles = makeStyles(({ spacing }) => ({
  chartContainer: {
    position: 'relative',
    marginLeft: '10%',
    marginRight: '10%',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& >div': {
      textTransform: 'uppercase',
      '& >button': {
        margin: 0,
        padding: 0,
      },
    },
  },
  resetFiltersButton: {
    position: 'absolute',
    top: '50%',
    bottom: spacing(3),
    margin: 0,
    padding: 0,
    paddingLeft: spacing(2),
  },
  filterSelectHeader: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    paddingLeft: spacing(2),
  },
  dialogContainer: {
    margin: 0,
  },
}))

const parseFilterKey = (key: string) => {
  const [serviceCode, dtCode, group] = key.split('|')
  return { serviceCode, dtCode, group }
}

const createFilterKey = (serviceCode: string, dtCode: string, group: string) => {
  return [serviceCode, dtCode, group].join('|')
}

type ServiceAreas = InsightsOverlayServicesSettingsQuery['currentUser']['organization']['serviceAreas']
type ChartProps = {
  statementsByService: StatementsByService
  employeeSurvey: AnalyticsSurveyNode
  residentSurvey: AnalyticsSurveyNode
  filters: string[]
  validServiceAreas: ServiceAreas
  validBenchmarkDepts: string[]
  validWorkStatuses: string[]
  refreshChart(): void
}
const OverlayServicesChart: React.FC<ChartProps> = ({
  employeeSurvey,
  residentSurvey,
  filters,
  statementsByService,
  validServiceAreas,
  validBenchmarkDepts,
  validWorkStatuses,
  refreshChart,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [currentSort, setCurrentSort] = useState(SORT_OPTIONS.A_TO_Z)
  const [showServicesModal, setShowServicesModal] = useState(false)
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<null | Element>(null)
  // In order to comply with <GroupedSelect /> behavior, which expects a unique code for every item in the list
  // we'll create a key with `{serviceAreaCode}|{dtCode}|{groupCode}`. See `createFilterKey` and `parseFilterKey`
  const getServiceFilterOptions = useCallback(
    (validServices: ServiceAreas) => {
      return validServices
        .map(sa => {
          const options: Array<[
            string,
            DataTypeCode.AI_DEPT | DataTypeCode.TI_WORK_STATUS,
            string,
          ]> = [
            [WorkStatusEnum.FULL_TIME, DataTypeCode.TI_WORK_STATUS, 'Full Time'],
            [WorkStatusEnum.PART_TIME, DataTypeCode.TI_WORK_STATUS, 'Part Time'],
            [WorkStatusEnum.PER_DIEM, DataTypeCode.TI_WORK_STATUS, 'Per diem'],
          ]
          if (sa.code === ServiceAreaEnum.DINING) {
            options.push([DTypeEnum.DINING_FRONT, DataTypeCode.AI_DEPT, 'Front of House'])
            options.push([DTypeEnum.DINING_KITCHEN, DataTypeCode.AI_DEPT, 'Kitchen'])
          }
          return (
            options
              // Only show filters that can be applied to the survey
              .filter(([group, dtCode]) => {
                return {
                  [DataTypeCode.TI_WORK_STATUS]: validWorkStatuses.includes(group),
                  [DataTypeCode.AI_DEPT]: validBenchmarkDepts.includes(group),
                }[dtCode]
              })
              .map(([group, dtCode, text]) => ({
                subgroup: sa.questionLabel,
                code: createFilterKey(sa.code, String(dtCode), group),
                text,
              }))
          )
        })
        .flat()
    },
    [validBenchmarkDepts, validWorkStatuses],
  )
  const serviceFilterOptions = getServiceFilterOptions(validServiceAreas)
  // Defaults to everything turned on.
  const [serviceFilters, setServiceFilters] = useState<string[]>(
    serviceFilterOptions.map(sa => sa.code),
  )
  useEffect(() => {
    setServiceFilters(getServiceFilterOptions(validServiceAreas).map(sa => sa.code))
  }, [getServiceFilterOptions, validServiceAreas])

  const servicesQueryVars = {
    employeeSurveyUuid: employeeSurvey.uuid,
    filters,
    dataTypeFilters: validServiceAreas.map(sa => {
      const userFilters = serviceFilters
        .map(parseFilterKey)
        .filter(({ serviceCode }) => serviceCode === sa.code)
      const dataTypeGroups = [
        {
          dtCode: DataTypeCode.DEPARTMENT_NAME,
          groups: sa.departments.map(d => d.name),
        },
        {
          dtCode: DataTypeCode.TI_WORK_STATUS,
          groups: userFilters
            .filter(({ dtCode }) => dtCode === DataTypeCode.TI_WORK_STATUS)
            .map(filter => filter.group),
        },
      ]
      // The Dining Service includes extra possible filters by benchmark department.
      if (sa.code === ServiceAreaEnum.DINING) {
        dataTypeGroups.push({
          dtCode: DataTypeCode.AI_DEPT,
          groups: userFilters
            .filter(({ dtCode }) => dtCode === DataTypeCode.AI_DEPT)
            .map(filter => filter.group),
        })
      }
      return { label: sa.questionLabel, dataTypeGroups }
    }),
  }
  const result = useInsightsOverlayServicesDataQuery({
    variables: servicesQueryVars,
  })
  let errorMessage = null
  // Since some services are always required, valid service areas is empty when there are no mapped departments.
  if (validServiceAreas.length === 0) {
    errorMessage = (
      <span>
        There isn’t enough data to show this chart. To help make your results more accurate, update
        information in{' '}
        <Button onClick={() => setShowServicesModal(true)} color="secondaryNoBackground">
          Settings Here
        </Button>
      </span>
    )
    // When statementsByService is empty, the customer survey did not have any service questions with enough responses.
  } else if (Object.keys(statementsByService).length === 0) {
    errorMessage = (
      <Typography>
        There isn’t enough data to show this chart. Update your filters to see better results.
      </Typography>
    )
  }
  return (
    <div id="overlay-services-snap" className={classes.fullRow}>
      <SnapshotChartHeader
        title="Compare Service Areas"
        description="For each service you offer, see how your customer scores compare to your employee scores. The services listed are from your selected Customer Engagement survey. Go to Settings to edit how your departments map to your services."
        snapId="overlay-services-snap"
        extraControls={
          <>
            <Button
              color="textSecondaryNoBackground"
              onClick={() => {
                gaEvent({
                  action: 'overlayOpenServiceSettings',
                  category: 'Analytics',
                })
                setShowServicesModal(true)
              }}
              id="overlay-services-settings"
            >
              Settings&nbsp;
              <SettingsIcon />
            </Button>
            {filtersAnchorEl && (
              <GroupedSelect
                anchorEl={filtersAnchorEl}
                multiple
                items={serviceFilterOptions}
                selectedItems={serviceFilters}
                onMultiselectChange={vals => {
                  gaEvent({
                    action: 'overlaySetServiceFilters',
                    category: 'Analytics',
                  })
                  setServiceFilters(vals)
                }}
                getItemValue={item => item.code}
                getItemLabel={item => item.text}
                onClose={() => setFiltersAnchorEl(null)}
                showAlphaList={false}
                maxSelectedItems={null}
                submitButtonText="Save"
                header={
                  <div className={classes.filterSelectHeader}>
                    <Typography>SHOW/HIDE FILTER</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Select groups within each department to include
                    </Typography>
                  </div>
                }
                additionalControls={
                  <Button
                    color="secondaryNoBackground"
                    className={classes.resetFiltersButton}
                    onClick={() => {
                      setServiceFilters(serviceFilterOptions.map(sa => sa.code))
                    }}
                  >
                    Reset Filters
                  </Button>
                }
              />
            )}
            <Button
              color="textSecondaryNoBackground"
              onClick={e => setFiltersAnchorEl(e.target as Element)}
              id="overlay-services-filter"
            >
              <span>Filter&nbsp;</span>
              <FilterIcon />
            </Button>
          </>
        }
      />
      {errorMessage ? (
        <EmptyState title="Oh Snap!" description={errorMessage} Icon={ChartUpwardIcon} />
      ) : (
        <ResponseHandler {...result}>
          {({ overallIndexScoreByFilters }) => {
            // This function returns scores for benchmarks and services. We only need to display
            // services but it's easier to pull out the data we need than modify the function.
            const serviceScores = getServiceScores(
              statementsByService,
              currentSort,
              PRODUCT_TYPE_TO_OVERLAY_COLORS[SurveyProductTypeEnum.RESIDENT],
            )[0]
            const sortedServiceLabels = serviceScores.map(s => s.label)
            let departmentScores = overallIndexScoreByFilters.map(score => ({
              positive: score.positive,
              color: PRODUCT_TYPE_TO_OVERLAY_COLORS[SurveyProductTypeEnum.EMPLOYEE],
              label: score.label,
            }))
            // Keep department scores in the same sort order as services.
            departmentScores = sortBy(departmentScores, s => {
              return sortedServiceLabels.indexOf(String(s.label))
            })
            return (
              <div className={classes.chartContainer}>
                <HorizontalBarChart
                  chartData={formatChartData([departmentScores, serviceScores])}
                  chartWidth="100%"
                  extraLegends={[
                    {
                      name: 'Employee Trust Index Score',
                      color: PRODUCT_TYPE_TO_OVERLAY_COLORS[SurveyProductTypeEnum.EMPLOYEE],
                    },
                    {
                      name: 'Customer Engagement Score',
                      color: PRODUCT_TYPE_TO_OVERLAY_COLORS[SurveyProductTypeEnum.RESIDENT],
                    },
                  ]}
                  useTopLegends
                  tooltipFormatter={(series: TooltipSeries) => {
                    const serviceLabel = series[0].axisValue
                    const departmentsList = validServiceAreas
                      .find(sa => sa.questionLabel === serviceLabel)
                      ?.departments?.map(d => d.name)
                    const service = validServiceAreas.find(sa => sa.questionLabel === serviceLabel)
                    const selectedFilterCodes = serviceFilters
                      .map(parseFilterKey)
                      .filter(filter => filter.serviceCode === service?.code)
                      .map(filter => filter.group)
                    const excludedFilters = serviceFilterOptions
                      .filter(option => option.subgroup === serviceLabel)
                      .map(option => ({
                        text: option.text,
                        code: parseFilterKey(option.code).group,
                      }))
                      .filter(option => !selectedFilterCodes.includes(option.code))
                      .map(option => option.text)
                    return (
                      <StandardTooltip
                        title={serviceLabel}
                        rows={[
                          {
                            label: 'Employee Score:',
                            value: formatTooltipScore(
                              series[0].data.value,
                              employeeSurvey.minShowableResults,
                              null,
                              series[0].data.lessThanMin,
                            ),
                            color:
                              PRODUCT_TYPE_TO_OVERLAY_TOOLTIP_COLORS[
                                SurveyProductTypeEnum.EMPLOYEE
                              ],
                          },
                          {
                            label: 'Customer Score:',
                            value: formatTooltipScore(
                              series[1].data.value,
                              residentSurvey.minShowableResults,
                              null,
                              series[1].data.lessThanMin,
                            ),
                            color:
                              PRODUCT_TYPE_TO_OVERLAY_TOOLTIP_COLORS[
                                SurveyProductTypeEnum.RESIDENT
                              ],
                          },
                        ]}
                        description={
                          <>
                            Departments included: {departmentsList?.join(', ')}
                            <br />
                            {excludedFilters.length > 0 && (
                              <>(Excluding Groups: {excludedFilters.join(', ')})</>
                            )}
                          </>
                        }
                      />
                    )
                  }}
                />
                <div className={classes.chartSortButton1}>
                  <BarChartSortButton
                    currentSort={currentSort}
                    handleChangeSort={newSort => setCurrentSort(newSort)}
                  />
                </div>
              </div>
            )
          }}
        </ResponseHandler>
      )}
      {showServicesModal && (
        <Dialog
          onClose={() => setShowServicesModal(false)}
          classes={{ paper: classes.dialogContainer }}
        >
          <ServiceAreasTab
            onSave={async () => {
              setShowServicesModal(false)
              refreshChart()
            }}
          />
        </Dialog>
      )}
    </div>
  )
}

type Props = {
  residentSurvey: AnalyticsSurveyNode
  employeeSurvey: AnalyticsSurveyNode
  filters: string[]
}
const ServiceChartContainer: React.FC<Props & { refreshChart(): void }> = ({
  refreshChart,
  residentSurvey,
  employeeSurvey,
  filters,
}) => {
  // Fetch resident survey data before the chart because 1) it doesn't change based on user filters and
  // 2) we use it to determine which services were used at the time of the survey.
  const result = useInsightsOverlayServicesSettingsQuery({
    variables: {
      residentSurveyUuid: residentSurvey.uuid,
      filters,
      employeeSurveyUuid: employeeSurvey.uuid,
      benchmarkDeptCode: DataTypeCode.AI_DEPT,
      workStatusCode: DataTypeCode.TI_WORK_STATUS,
    },
  })
  return (
    <ResponseHandler {...result} hideStaleData>
      {({ currentUser, insightsStatements, validBenchmarkDepts, validWorkStatuses }) => {
        const activeServiceAreas = currentUser.organization.serviceAreas.filter(
          sa => sa.departments.length,
        )
        const statementsByService = getStatementsByService(
          insightsStatements.filter(stmt => stmt.residentFocus === QResidentFocus.SERVICE_AREAS),
          activeServiceAreas,
          true,
        )
        return (
          <OverlayServicesChart
            filters={filters}
            employeeSurvey={employeeSurvey}
            residentSurvey={residentSurvey}
            statementsByService={statementsByService}
            // Only include services that were used at the time of the survey
            validServiceAreas={sortBy(
              activeServiceAreas.filter(sa => statementsByService[sa.questionLabel]),
              'questionLabel',
            )}
            validBenchmarkDepts={validBenchmarkDepts}
            validWorkStatuses={validWorkStatuses}
            refreshChart={refreshChart}
          />
        )
      }}
    </ResponseHandler>
  )
}

const ServiceChartWrapper: React.FC<Props> = props => {
  // Rather than worry about refetching multiple queries => propogating results when services
  // are updated from the model, just refresh the chart component by giving it a new key.
  const [chartKey, setChartKey] = useState(uuidv4())
  return (
    <div key={chartKey}>
      <ServiceChartContainer {...props} refreshChart={() => setChartKey(uuidv4())} />
    </div>
  )
}
export default ServiceChartWrapper
