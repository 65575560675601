import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import cn from 'classnames'
import orderBy from 'lodash/orderBy'

import Carousel from 'components/Blocks/Carousel'
import KeyFocusAreasLegend from 'components/Insights/Blocks/KeyFocusAreasLegend'
import {
  FocusAreaPotentialImprovementChart,
  FocusAreaImprovedChart,
} from 'components/Insights/Snapshot/FocusAreaImprovementCharts'
import KeyFocusAreaDonut from 'components/Insights/Snapshot/KeyFocusAreaDonut'
import {
  GroupedScoresFragment,
  InsightsStatementScoreFragment,
  SurveyProductTypeEnum,
  FilterTypeFragment,
} from 'generated/graphql'
import { joinWords } from 'utils'
import { ScoreTitleEnum, ORDER_TYPES } from 'utils/constants'

const useStyles = makeStyles(({ palette, spacing }) => ({
  chartContainer: {
    display: 'flex',
    background: palette.common.iceGrey50,
    height: 460,
  },
  donutChartsContainer: {
    paddingTop: spacing(3),
    marginBottom: spacing(2),
  },
  chartColumn: {
    padding: spacing(3),
    paddingTop: 0,
    width: `${100 / 3}%`,
  },
  statement: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  scoreTitle: {
    fontSize: '1.5rem',
    paddingBottom: 4,
  },
  description: {
    marginTop: spacing(),
    '& >span': {
      color: palette.common.success,
    },
  },
  groupText: {
    fontSize: '1.5rem',
    '& >span': {
      color: palette.common.navy65,
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  growthExplanationContainer: {
    // Add margin to keep the height consistent when the legend from the other chart disappears
    marginTop: 45,
    padding: spacing(5),
    paddingTop: 0,
    '& >:nth-child(1)': {
      borderRight: `1px solid ${palette.common.navy25}`,
      paddingRight: spacing(5),
    },
    '& >:nth-child(2)': {
      paddingLeft: spacing(5),
    },
    '& >div': {
      width: '50%',
    },
  },
  centeredChart: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

type GProps = {
  overallIndexScore: number
  improvementScore: number
  improvedGroups?: [{ score: number; name: string }] | null
  scoresRange: number
  filterTypeNames: string
  improvementFilterTypeName?: string | null
  surveyProductType: SurveyProductTypeEnum
}
const GrowthExplanationBlock: React.FC<GProps> = ({
  overallIndexScore: rawOverallScore,
  improvementScore: rawImprovementScore,
  improvedGroups,
  scoresRange,
  filterTypeNames,
  improvementFilterTypeName,
  surveyProductType,
}) => {
  const classes = useStyles()
  const overallIndexScore = Math.round(rawOverallScore)
  const improvementScore = Math.round(rawImprovementScore)
  let descriptionInitialText = 'If you brought your lowest scoring statements'
  if (improvedGroups) {
    descriptionInitialText = `
      If you brought your ${improvedGroups.length} lowest ${improvementFilterTypeName} –
      ${joinWords(improvedGroups.map(({ score, name }) => `${name} (${score}%)`))} –
    `
  }
  return (
    <>
      <div className={cn(classes.chartContainer, classes.growthExplanationContainer)}>
        <div>
          <div className={classes.centeredChart}>
            <FocusAreaPotentialImprovementChart
              currentScore={overallIndexScore}
              improvementScore={improvementScore}
            />
          </div>
          <Typography color="textSecondary" className={cn(classes.scoreTitle, classes.uppercase)}>
            Current Score
          </Typography>
          <Typography variant="h4">{overallIndexScore}%</Typography>
          <Typography color="textSecondary" className={classes.description}>
            By statement, your scores ranged by over {scoresRange} pts. Across your organization,
            scores varied by {filterTypeNames}.
          </Typography>
        </div>
        <div>
          <div className={classes.centeredChart}>
            <FocusAreaImprovedChart score={improvementScore} />
          </div>
          <Typography color="textSecondary" className={cn(classes.scoreTitle, classes.uppercase)}>
            Potential New Score
          </Typography>
          <Typography variant="h4">{improvementScore}%</Typography>
          <Typography color="textSecondary" className={classes.description}>
            {descriptionInitialText} to your organization's average score of {overallIndexScore}%
            your overall {ScoreTitleEnum[surveyProductType]} would increase by
            <span> {Math.round(improvementScore - overallIndexScore)}%</span>
          </Typography>
        </div>
      </div>
    </>
  )
}

type StatementsScoresData = Array<{
  label: string
  positive: number
  target: number
  groupData: Array<{
    name: string
    groups: GroupedScoresFragment['scores']
  }>
}>
type DProps = { statementsScoresData: StatementsScoresData; minShowableResults: number }
const DonutChartBlock: React.FC<DProps> = ({ statementsScoresData, minShowableResults }) => {
  const classes = useStyles()
  return (
    <>
      <KeyFocusAreasLegend />
      <div className={cn(classes.chartContainer, classes.donutChartsContainer)}>
        {statementsScoresData.map(({ label, positive, target, groupData }, index) => {
          if (!groupData.length) {
            return (
              <div className={classes.chartColumn} key={label}>
                <KeyFocusAreaDonut positive={positive} target={target} />
                <Typography color="textSecondary" className={classes.description}>
                  The applied filters show results for less than {minShowableResults} participants.
                  Remove one of your filters to see results.
                </Typography>
              </div>
            )
          }
          return (
            <div className={classes.chartColumn} key={label}>
              <KeyFocusAreaDonut positive={positive} target={target} />
              <Typography className={classes.statement}>
                Statement {index + 1}: {label}
              </Typography>
              <Typography color="textSecondary" className={classes.description}>
                Focus On:
              </Typography>
              {groupData.map(({ name, groups }) => {
                const [group1, group2] = groups
                return (
                  <Typography key={name} className={classes.groupText}>
                    {name}:{' '}
                    <span>
                      {`${group1.label} (${Math.round(group1.positive)}%)`}
                      {group2 && (
                        <span>{` and ${group2.label} (${Math.round(group2.positive)}%)`}</span>
                      )}
                    </span>
                  </Typography>
                )
              })}
            </div>
          )
        })}
      </div>
    </>
  )
}

type Props = {
  statementsScoresData: StatementsScoresData
  improvementScoreData: {
    improvementScore: number
    improvedGroups?: [{ score: number; name: string }] | null
  }
  overallIndexScore: number
  allStatements: InsightsStatementScoreFragment[]
  visibleFilterTypes: FilterTypeFragment[]
  improvementFilterTypeName?: string | null
  hasKeyFocusAreaCarousel: boolean
  surveyProductType: SurveyProductTypeEnum
  minShowableResults: number
}
const KeyFocusAreasChart: React.FC<Props> = ({
  statementsScoresData,
  improvementScoreData,
  overallIndexScore,
  allStatements,
  visibleFilterTypes,
  improvementFilterTypeName,
  hasKeyFocusAreaCarousel,
  surveyProductType,
  minShowableResults,
}) => {
  if (!hasKeyFocusAreaCarousel) {
    return (
      <DonutChartBlock
        statementsScoresData={statementsScoresData}
        minShowableResults={minShowableResults}
      />
    )
  }
  const orederedStatements = orderBy(allStatements, ['positive', 'label'], ORDER_TYPES.DESCENDING)
  return (
    <Carousel numSteps={2}>
      <DonutChartBlock
        statementsScoresData={statementsScoresData}
        minShowableResults={minShowableResults}
      />
      <GrowthExplanationBlock
        overallIndexScore={overallIndexScore}
        scoresRange={Math.floor(
          orederedStatements[0].positive -
            orederedStatements[orederedStatements.length - 1].positive,
        )}
        filterTypeNames={joinWords(visibleFilterTypes.map(ft => ft.name))}
        improvementFilterTypeName={improvementFilterTypeName}
        surveyProductType={surveyProductType}
        {...improvementScoreData}
      />
    </Carousel>
  )
}

export default KeyFocusAreasChart
