import React, { ReactElement, useContext } from 'react'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import TimeTrendingSurveyDropdown from 'components/Insights/TimeTrendingSurveyDropdown'
import { TimeTrendingSurveysKey, StoreContext } from 'config/LocalStorage'
import { reverse } from 'utils'
import { SurveyNode } from 'utils/types'

type Props = {
  chartSurveysKey: TimeTrendingSurveysKey
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  children({ selectedSurveys }: { selectedSurveys: SurveyNode[] }): ReactElement
  excludePulse?: boolean
}
const TimeTrendingContainer: React.FC<Props> = ({
  survey,
  availableSurveys,
  chartSurveysKey,
  children,
  excludePulse = false,
}) => {
  const {
    store: { [chartSurveysKey]: surveyIds },
  } = useContext(StoreContext)
  return (
    <>
      <TimeTrendingSurveyDropdown
        controlsSurvey={survey}
        chartSurveysKey={chartSurveysKey}
        surveys={availableSurveys}
        excludePulse={excludePulse}
      />
      {surveyIds.length < 2 ? (
        <EmptyState
          title="Oh Snap!"
          description="Please select at least two surveys to see how results have changed over time."
          Icon={ChartUpwardIcon}
        />
      ) : (
        // Time trending displays survey results sorted by least recent.
        children({
          selectedSurveys: reverse(availableSurveys.filter(s => surveyIds.includes(s.uuid))),
        })
      )}
    </>
  )
}

export default TimeTrendingContainer
