import React, { useEffect } from 'react'

import { makeStyles, Snackbar, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import cn from 'classnames'

import { ERROR_CODES } from 'utils/apiErrorCodes'
import { GlobalNotification } from 'utils/types'

const useStyles = makeStyles(({ palette, spacing }) => ({
  // Overwriting MaterialUI styling so we can stack notifications.
  container: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-end',
    zIndex: 10000,
  },
  snackbarNotification: {
    bottom: 'auto',
    right: 'auto',
    left: 'auto',
    paddingBottom: spacing(3),
    paddingLeft: spacing(3),
    position: 'relative',
  },
  messageWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& >span>a': {
      color: palette.common.navy,
    },
  },
  messageIcon: {
    marginRight: spacing(),
  },
  snackbarRoot: {
    padding: '20 15',
    backgroundColor: 'white',
    color: palette.common.darkGrey,
    borderRadius: '3',
    boxShadow:
      '0 12 20 -10 rgba(255, 255, 255, 0.28), 0 4 20 0 rgba(0, 0, 0, 0.12), 0 7 8 -5 rgba(255, 255, 255, 0.2)',
  },
  message: {
    padding: 0,
    display: 'block',
    maxWidth: '89%',
  },
  success: {
    backgroundColor: '#5cb860',
    color: '#ffffff',
    boxShadow:
      '0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)',
  },
  warning: {
    backgroundColor: '#ffa21a',
    color: '#ffffff',
    boxShadow:
      '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)',
  },
  danger: {
    backgroundColor: '#f55a4e',
    color: '#ffffff',
    boxShadow:
      '0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)',
  },
}))

type Props = {
  notification: GlobalNotification
  closeNotification(id: string): void
}

const SnackbarNotification: React.FC<Props> = ({ notification, closeNotification }) => {
  const classes = useStyles()

  useEffect(() => {
    if (notification.color !== 'danger') {
      setTimeout(() => {
        closeNotification(notification.id)
      }, notification.timeToClose || 3000)
    }
  })
  const Icon = {
    success: CheckIcon,
    warning: ErrorIcon,
    danger: ErrorIcon,
  }[notification.color]

  const action = [
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      id="close-notification"
      onClick={() => closeNotification(notification.id)}
    >
      <CloseIcon />
    </IconButton>,
  ]

  return (
    <Snackbar
      key={notification.id}
      open
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      message={
        <div className={classes.messageWrapper}>
          <Icon className={classes.messageIcon} />
          <Typography>{ERROR_CODES[notification.message] || notification.message}</Typography>
        </div>
      }
      className={classes.snackbarNotification}
      action={action}
      ContentProps={{
        classes: {
          root: cn(classes.snackbarRoot, {
            [classes.success]: notification.color === 'success',
            [classes.warning]: notification.color === 'warning',
            [classes.danger]: notification.color === 'danger',
          }),
          message: classes.message,
        },
      }}
    />
  )
}

type ContainerProps = {
  notifications: GlobalNotification[]
  closeNotification(id: string): void
}
const NotificationsContainer: React.FC<ContainerProps> = ({ notifications, closeNotification }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {notifications.map(notification => {
        return (
          <SnackbarNotification
            key={notification.id}
            notification={notification}
            closeNotification={closeNotification}
          />
        )
      })}
    </div>
  )
}

export default NotificationsContainer
