import React from 'react'

import { Typography } from '@material-ui/core'

import AddCustomQuestionButton from 'components/Survey/Blocks/AddCustomQuestionButton'
import AddOrRemoveOptionalQuestionsContainer from 'containers/Survey/Wizard/Steps/Questions/AddOrRemoveOptionalQuestionsContainer'
import {
  QCategory,
  SurveyTypeEnum,
  SurveysSurveyQuery,
  SurveysQuestionsQuery,
} from 'generated/graphql'
import { PULSE_SURVEY_TYPES } from 'utils/constants'

const MAX_QUESTIONS = 20
const MAX_PULSE_QUESTIONS = 25
const MAX_RESIDENT_ENGAGEMENT_QUESTIONS = 200 // just a ridiculously high number meaning "no limitation"
const MAX_RESIDENT_DISCHARGE_QUESTIONS = 12

export const getMaxQuestions = (surveyType: SurveyTypeEnum, questionCategory: QCategory) => {
  let maxQuestions = MAX_QUESTIONS
  if (PULSE_SURVEY_TYPES.includes(surveyType)) {
    maxQuestions = MAX_PULSE_QUESTIONS
  } else if (surveyType === SurveyTypeEnum.RESIDENT_ENGAGEMENT) {
    maxQuestions = MAX_RESIDENT_ENGAGEMENT_QUESTIONS
  } else if (
    [SurveyTypeEnum.RESIDENT_END_OF_SERVICE, SurveyTypeEnum.RESIDENT_DISCHARGE].includes(
      surveyType,
    ) &&
    questionCategory !== QCategory.STATEMENTS
  ) {
    maxQuestions = MAX_RESIDENT_DISCHARGE_QUESTIONS
  }

  return maxQuestions
}

type Props = {
  survey: SurveysSurveyQuery['survey']
  questionCategory: QCategory
  selectedQuestions: NonNullable<SurveysQuestionsQuery['questions']>
}

const AddOptionalAndCustomButtons: React.FC<Props> = ({
  survey,
  selectedQuestions,
  questionCategory,
}) => {
  const customSelectedQuestionsCount = selectedQuestions.filter(q => q.isCustom).length

  const maxQuestions = getMaxQuestions(survey.type, questionCategory)

  const isDisabledAddCustomQuestion = customSelectedQuestionsCount >= maxQuestions

  let maxCustomQuestionsReachedTooltip
  if (isDisabledAddCustomQuestion) {
    maxCustomQuestionsReachedTooltip = `You have reached the maximum (${maxQuestions}) number of allowed custom
          ${questionCategory === QCategory.STATEMENTS ? 'statements' : 'questions'}`
  }

  return (
    <Typography>
      <AddOrRemoveOptionalQuestionsContainer
        survey={survey}
        questionCategory={questionCategory}
        selectedQuestions={selectedQuestions}
        limit={getMaxQuestions(survey.type, questionCategory)}
        buttonLabelPrefix={<>Add an&nbsp;</>}
        buttonText={
          questionCategory === QCategory.STATEMENTS ? 'optional statement' : 'optional question'
        }
      />
      <AddCustomQuestionButton
        survey={survey}
        questionCategory={questionCategory}
        maxCustomQuestionsReachedTooltip={maxCustomQuestionsReachedTooltip}
        buttonLabelPrefix={<>&nbsp;or a&nbsp;</>}
        buttonLabel={
          questionCategory === QCategory.STATEMENTS ? 'custom statement' : 'custom question'
        }
      />
    </Typography>
  )
}

export default AddOptionalAndCustomButtons
