import React, { ReactElement } from 'react'

import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import useInsightsStyles from 'components/Insights/InsightsStyle'
import { colors } from 'shared/theme'

export const ECHARTS_TOOLTIP_WIDTH = 300
const MIN_WIDTH = 250
const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: ECHARTS_TOOLTIP_WIDTH,
    minWidth: MIN_WIDTH,
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(2),
    fontVariantNumeric: 'lining-nums',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.44)',
  },
  rowsBlock: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  leftBlock: {
    width: '65%',
    display: 'inline',
    '& >p': {
      wordBreak: 'break-word',
    },
  },
  rightBlock: {
    width: '35%',
    display: 'flex',
    justifyContent: 'space-between',
    '& >p': {
      textAlign: 'right',
    },
  },
}))

export type TooltipRow = {
  label: string
  value: string | ReactElement
  color?: string
  preDetail?: ReactElement // Element that comes before the label
  detail?: string | ReactElement | boolean | null
}
type Props = {
  title: string
  description?: ReactElement | string
  rows: TooltipRow[]
}
// TODO: consolidate this component with echartTooltipBuilder after a design overview.
const StandardTooltip: React.FC<Props> = ({ title, description, rows }) => {
  // IMPORTANT NOTE! Do not use Material UI components when rendering echarts tooltips.
  // There is a very strange bug that when echarts renders a tooltip component with global style (e.g. Typography)
  // It modifies the style on the entire page.
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <div className={classes.container}>
      {/** Using manual style attribute for color to override the text styles set on the top level tooltip. */}
      <p className={classes.paragraph} style={{ color: colors.navy }}>
        {title}
      </p>
      <div className={classes.rowsBlock}>
        {rows.map(({ preDetail, label, value, color = colors.navy, detail }) => {
          return (
            <div className={classes.row} key={label}>
              <div className={classes.leftBlock}>
                {preDetail}
                <p className={cn(classes.paragraph, classes.textSecondary)}>{label}</p>
              </div>
              {/** There's no easy way to write CSS that handles both the 2 column and 3 column case. */}
              {detail ? (
                <div className={classes.rightBlock}>
                  <p className={classes.paragraph} style={{ color }}>
                    {value}
                  </p>
                  <p className={classes.paragraph} style={{ color, textAlign: 'right' }}>
                    {detail}
                  </p>
                </div>
              ) : (
                <p className={classes.paragraph} style={{ color, textAlign: 'right' }}>
                  {value}
                </p>
              )}
            </div>
          )
        })}
      </div>
      <p
        className={cn(classes.paragraph, classes.textSecondary, classes.body2)}
        style={{ textOverflow: 'wrap', width: MIN_WIDTH }}
      >
        {description}
      </p>
    </div>
  )
}

export default StandardTooltip
