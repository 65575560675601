import React, { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { makeStyles, AppBar, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import cn from 'classnames'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import { ReactComponent as GraphGreyIcon } from 'assets/img/action_plans/graph-icon-grey.svg'
import { ReactComponent as GraphWhiteIcon } from 'assets/img/action_plans/graph-icon-white.svg'
import * as MoreInfoIcon from 'assets/img/action_plans/more_info.png'
import { ReactComponent as TrophyIcon } from 'assets/img/action_plans/trophy.svg'
import ActionPlan from 'components/ActionPlans/ActionPlan'
import CompletedActionItems from 'components/ActionPlans/CompletedActionItems'
import { taskStatuses, ActionPlanTabs, PIE_COLORS } from 'components/ActionPlans/constants'
import EmptyActionPlan from 'components/ActionPlans/EmptyActionPlan'
import NoCompletedItems from 'components/ActionPlans/NoCompletedItems'
import ProgressPie from 'components/ActionPlans/ProgressPie'
import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import SurveySelector from 'components/ActionPlans/SurveySelector'
import Page from 'components/Blocks/Layout/Page'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import {
  StatementData,
  transformTimeTrendingStatementsToEchartScores,
} from 'components/Insights/TimeTrending/utils'
import { gaEvent } from 'config/ga'
import {
  ActionPlansActionPlanFragment,
  ActionPlanSurveyType,
  FrozenQuestionType,
  InsightsRankedStatementsQuery,
  SurveyTypeEnum,
  TaskStatusEnum,
  CurrentUserDocument,
  useActionPlansActionPlanQuery,
  useInsightsRankedStatementsQuery,
  SolutionFieldEnum,
} from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import { getSurveyTypeLabel, reverse } from 'utils'
import { isEmptyActionPlan, actionItemIsDone, getHelperMessage } from 'utils/actionPlansUtils'
import { CONTACT_EMAIL, URLS } from 'utils/constants'
import { orgHasEnabledSolutionField } from 'utils/solution'
import { SurveyNodeAP as SurveyNode } from 'utils/types'

const useStyles = makeStyles(({ spacing, palette }) => ({
  appBar: {
    paddingLeft: spacing(3),
  },
  content: {
    marginTop: spacing(2),
  },
  tabOverrideRoot: {
    pointerEvents: 'auto',
    fontSize: '1.4rem',
  },
  noClosedSurveys: {
    textAlign: 'center',
  },
  box: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: 30,
    paddingRight: 30,
    borderBottom: `1px solid ${palette.common.navy25}`,
    minHeight: 200,
  },
  boxTitle: {
    marginBottom: 20,
  },
  completedStatementsNoClosedSurveys: {
    textAlign: 'center',
  },
  borderRight: {
    borderRight: `1px solid ${palette.common.navy25}`,
  },
  topResidentStatements: {
    marginTop: 50,
  },
  progressPieWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& >div:first-child': {
      marginRight: spacing(3),
    },
  },
  progressItemsWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& >svg': {
      paddingRight: spacing(4),
    },
  },
  progressLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  completedProgressLabels: {
    '& >p': {
      marginBottom: spacing(),
    },
  },
  statusDot: {
    width: 10,
    height: 10,
    marginRight: spacing(),
    borderRadius: '50%',
  },
  statusDotNotStarted: {
    backgroundColor: PIE_COLORS.NOT_STARTED,
  },
  statusDotInProgress: {
    backgroundColor: PIE_COLORS.IN_PROGRESS,
  },
  statusDotOnHold: {
    backgroundColor: PIE_COLORS.ON_HOLD,
  },
  statusDotComplete: {
    backgroundColor: PIE_COLORS.COMPLETE,
  },
  statusDotIncomplete: {
    backgroundColor: PIE_COLORS.INCOMPLETE,
  },
  boxGrey: {
    backgroundColor: palette.common.iceGrey,
  },
  boxHidden: {
    display: 'none',
  },
  boxDoubleWidth: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    '& >svg': {
      marginRight: spacing(2),
    },
  },
  moreInfoIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >img': {
      marginBottom: -spacing(2) - 12,
      marginRight: -39,
      marginLeft: spacing(),
    },
  },
}))

const getTabs = () => {
  return [
    {
      label: 'Current Action Plan',
      value: ActionPlanTabs.AP,
    },
    {
      label: 'Completed Statements',
      value: ActionPlanTabs.COMPLETED_STATEMENTS,
    },
  ]
}

const TopStatements: React.FC<{
  title: string
  statementsData: StatementData[]
}> = ({ title, statementsData }) => {
  return (
    <>
      <Typography>{title} Statements:</Typography>
      {/* since same statement can appear twice in the AP, we only want to show it once here */}
      {uniqBy(statementsData, 'code').map(({ delta, label }, index) => (
        <Typography key={index} color="textSecondary">
          {label}
          <ScoreChangeArrow delta={delta} />
        </Typography>
      ))}
    </>
  )
}

const MoreInformationBox: React.FC<{
  hasEmployeeActionPlans: boolean
}> = ({ hasEmployeeActionPlans }) => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h6" className={classes.boxTitle}>
        Want to work on improving {!hasEmployeeActionPlans ? 'employee' : 'customer'} engagement?
      </Typography>
      <Typography color="textSecondary">
        {!hasEmployeeActionPlans
          ? 'Improve your workplace culture and employee experience with employee surveys.'
          : 'Build resident and family relationships with our customer surveys designed for senior care.'}
      </Typography>
      <div className={classes.moreInfoIcon}>
        <a href={`mailto:${CONTACT_EMAIL}`}>Get More Information</a>
        <img width={160} src={MoreInfoIcon} alt="moreInfoIcon" />
      </div>
    </>
  )
}

const WaitForSecondSurveyTeaser: React.FC<{ isEmployeeMissing: boolean }> = ({
  isEmployeeMissing,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.completedStatementsNoClosedSurveys}>
      <GraphWhiteIcon />
      <Typography color="textSecondary">
        <i>
          After your second{' '}
          {getSurveyTypeLabel(
            isEmployeeMissing ? SurveyTypeEnum.TI : SurveyTypeEnum.RESIDENT_ENGAGEMENT,
          )}{' '}
          survey closes, see which statements improved the most.
        </i>
      </Typography>
    </div>
  )
}

// See scenarios here: https://docs.google.com/spreadsheets/d/1aRbIyfgbvKmRltuffZp5pGxXkkIyfqsI3O0P8YjD7wo/edit#gid=0
const FirstBox: React.FC<{
  emptyActionPlan: boolean
  isApTab: boolean
  actionPlan: ActionPlansActionPlanFragment
}> = ({ emptyActionPlan, isApTab, actionPlan }) => {
  const classes = useStyles()
  const counts = {
    [TaskStatusEnum.NOT_STARTED]: 0,
    [TaskStatusEnum.IN_PROGRESS]: 0,
    [TaskStatusEnum.ON_HOLD]: 0,
    [TaskStatusEnum.COMPLETE]: 0,
    [TaskStatusEnum.INCOMPLETE]: 0,
  }
  if (!emptyActionPlan) {
    actionPlan.actionItems
      .filter(actionItem =>
        isApTab ? !actionItemIsDone(actionItem) : actionItemIsDone(actionItem),
      )
      .forEach(item =>
        item.actionItemTasks.forEach(task => {
          counts[task.status] += 1
        }),
      )
  }
  return (
    <Grid id="first-box" item xs className={cn(classes.box, classes.borderRight)}>
      <Typography variant="h6" className={classes.boxTitle}>
        {isApTab ? 'Your Progress' : 'Completed'}
      </Typography>
      {isApTab ? (
        <div className={classes.progressPieWrapper}>
          <ProgressPie counts={counts} />
          {emptyActionPlan ? (
            <Typography color="textSecondary">
              <i>Once you get started you’ll see your progress on your plan here.</i>
            </Typography>
          ) : (
            <div>
              {taskStatuses.map(({ text, value }) => (
                <Typography
                  id={value}
                  component="div"
                  key={value}
                  className={classes.progressLabel}
                >
                  <div
                    className={cn(classes.statusDot, {
                      [classes.statusDotNotStarted]: value === TaskStatusEnum.NOT_STARTED,
                      [classes.statusDotInProgress]: value === TaskStatusEnum.IN_PROGRESS,
                      [classes.statusDotOnHold]: value === TaskStatusEnum.ON_HOLD,
                      [classes.statusDotComplete]: value === TaskStatusEnum.COMPLETE,
                      [classes.statusDotIncomplete]: value === TaskStatusEnum.INCOMPLETE,
                    })}
                  />
                  <Typography variant="body2">&nbsp;{text}:</Typography>
                  <Typography color="textSecondary">
                    &nbsp;
                    {counts[value as TaskStatusEnum]}
                  </Typography>
                </Typography>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className={classes.progressItemsWrapper}>
          <TrophyIcon />
          <div className={classes.completedProgressLabels}>
            <Typography className={classes.progressLabel} id="done-action-items">
              Action Items:&nbsp;
              <Typography component="span" color="textSecondary">
                {counts[TaskStatusEnum.INCOMPLETE] + counts[TaskStatusEnum.COMPLETE]}
              </Typography>
            </Typography>
            <Typography className={classes.progressLabel} id="done-statements">
              Statements:&nbsp;
              <Typography component="span" color="textSecondary">
                {actionPlan.actionItems.filter(actionItemIsDone).length}
              </Typography>
            </Typography>
            <Typography className={classes.progressLabel} id="done-later">
              Saved for Later:&nbsp;
              <Typography component="span" color="textSecondary">
                {counts[TaskStatusEnum.INCOMPLETE]}
              </Typography>
            </Typography>
          </div>
        </div>
      )}
    </Grid>
  )
}

// See scenarios here: https://docs.google.com/spreadsheets/d/1aRbIyfgbvKmRltuffZp5pGxXkkIyfqsI3O0P8YjD7wo/edit#gid=0
const SecondBox: React.FC<{
  employeeStatementsData: StatementData[]
  residentStatementsData: StatementData[]
  employeeSurveys: SurveyNode[]
  residentSurveys: SurveyNode[]
  isApTab: boolean
  actionPlan: ActionPlansActionPlanFragment
  hasEmployeeActionPlans: boolean
  hasResidentActionPlans: boolean
}> = ({
  employeeStatementsData,
  residentStatementsData,
  employeeSurveys,
  residentSurveys,
  isApTab,
  actionPlan,
  hasEmployeeActionPlans,
  hasResidentActionPlans,
}) => {
  const classes = useStyles()
  const hasOneSolution = !hasEmployeeActionPlans || !hasResidentActionPlans
  const hasBothSolutions = !hasOneSolution
  const hasEmployeeSurveys = employeeSurveys.length > 0
  const hasResidentSurveys = residentSurveys.length > 0
  // the >1 check is intentional, see https://docs.google.com/spreadsheets/d/1aRbIyfgbvKmRltuffZp5pGxXkkIyfqsI3O0P8YjD7wo/edit#gid=0
  const hasEmployeeStatementsData = employeeStatementsData.length > 1
  const hasResidentStatementsData = residentStatementsData.length > 1
  const showSecondSurveyTeaser =
    hasOneSolution && !hasEmployeeStatementsData && !hasResidentStatementsData
  return (
    <Grid
      id="second-box"
      item
      xs={
        !isApTab && hasBothSolutions && !hasEmployeeStatementsData && !hasResidentStatementsData
          ? 8
          : 4
      }
      className={cn(classes.box, {
        [classes.boxGrey]: !isApTab && showSecondSurveyTeaser,
        [classes.borderRight]: isApTab || (!isApTab && showSecondSurveyTeaser),
      })}
    >
      {isApTab && (
        <>
          <Typography variant="h6" className={classes.boxTitle}>
            Latest Survey Score
          </Typography>
          {hasEmployeeSurveys && (
            <SurveySelector
              key={ActionPlanSurveyType.TI}
              surveys={employeeSurveys}
              surveyUuid={actionPlan.surveyTi?.uuid}
              surveyType={ActionPlanSurveyType.TI}
            />
          )}
          {hasResidentSurveys && (
            <SurveySelector
              key={ActionPlanSurveyType.RESIDENT_ENGAGEMENT}
              surveys={residentSurveys}
              surveyUuid={actionPlan.surveyResidentEngagement?.uuid}
              surveyType={ActionPlanSurveyType.RESIDENT_ENGAGEMENT}
            />
          )}
        </>
      )}
      {!isApTab &&
        (showSecondSurveyTeaser ? (
          <WaitForSecondSurveyTeaser isEmployeeMissing={!hasEmployeeActionPlans} />
        ) : (
          <>
            <Typography variant="h6" className={classes.boxTitle}>
              Most Improved Scores
            </Typography>
            {(hasEmployeeStatementsData || hasResidentStatementsData) && (
              <div>
                <TopStatements
                  title={hasEmployeeActionPlans ? 'Employee' : 'Customer'}
                  statementsData={
                    hasEmployeeActionPlans ? employeeStatementsData : residentStatementsData
                  }
                />
              </div>
            )}
            {hasBothSolutions && !hasEmployeeStatementsData && !hasResidentStatementsData && (
              // see mock in docs/action_plans/box_full_width.png
              <div className={classes.boxDoubleWidth}>
                <GraphGreyIcon />
                <Typography color="textSecondary">
                  <i>
                    Come back later to see which statements from your action plan have improved the
                    most!
                  </i>
                </Typography>
              </div>
            )}
          </>
        ))}
    </Grid>
  )
}

// See scenarios here: https://docs.google.com/spreadsheets/d/1aRbIyfgbvKmRltuffZp5pGxXkkIyfqsI3O0P8YjD7wo/edit#gid=0
const ThirdBox: React.FC<{
  employeeStatementsData: StatementData[]
  residentStatementsData: StatementData[]
  isApTab: boolean
  actionPlan: ActionPlansActionPlanFragment
  hasEmployeeActionPlans: boolean
  hasResidentActionPlans: boolean
}> = ({
  employeeStatementsData,
  residentStatementsData,
  isApTab,
  actionPlan,
  hasEmployeeActionPlans,
  hasResidentActionPlans,
}) => {
  const classes = useStyles()
  const isEmpty = isEmptyActionPlan(actionPlan)
  const helperMessage = getHelperMessage(actionPlan)
  // the >1 check is intentional, see https://docs.google.com/spreadsheets/d/1aRbIyfgbvKmRltuffZp5pGxXkkIyfqsI3O0P8YjD7wo/edit#gid=0
  const hasEmployeeStatementsData = employeeStatementsData.length > 1
  const hasResidentStatementsData = residentStatementsData.length > 1
  const hasOneSolution = !hasEmployeeActionPlans || !hasResidentActionPlans
  const hasBothSolutions = !hasOneSolution
  const hasBothSolutionsWithMissingSurveyTypeData =
    hasBothSolutions && (!hasEmployeeStatementsData || !hasResidentStatementsData)
  return (
    <Grid
      id="third-box"
      item
      xs
      className={cn(classes.box, {
        [classes.boxGrey]:
          !isApTab && (hasOneSolution || hasBothSolutionsWithMissingSurveyTypeData),
        // we want to hide the box because the second box will handle this case and will span acrros the third box too
        [classes.boxHidden]:
          !isApTab && hasBothSolutions && !hasEmployeeStatementsData && !hasResidentStatementsData,
      })}
    >
      {isApTab && (
        <>
          <Typography variant="h6" className={classes.boxTitle}>
            What's Up Next
          </Typography>
          <Typography color="textSecondary">
            {isEmpty ? <i>{helperMessage}</i> : helperMessage}
          </Typography>
        </>
      )}
      {/* if only one of the solutions is enabled, we're showing the teaser for the other one */}
      {/* see mock in docs/action_plans/more_information_box.png */}
      {!isApTab && hasOneSolution && (
        <MoreInformationBox hasEmployeeActionPlans={hasEmployeeActionPlans} />
      )}
      {/* if both solutions are enabled and we have surveys of both kinds */}
      {/* see mock in docs/action_plans/both_solutions_both_surveys.png */}
      {!isApTab && hasBothSolutions && hasResidentStatementsData && (
        <div className={classes.topResidentStatements}>
          <TopStatements title="Customer" statementsData={residentStatementsData} />
        </div>
      )}
      {/* if both solutions are enabled but we only have surveys from employee or residents */}
      {/* see mock in docs/action_plans/third_box_no_closed_surveys.png */}
      {!isApTab && hasBothSolutionsWithMissingSurveyTypeData && (
        <WaitForSecondSurveyTeaser isEmployeeMissing={!hasEmployeeStatementsData} />
      )}
    </Grid>
  )
}

type WrapperProps = {
  surveys: SurveyNode[]
  targetUserUuid?: string
}

const ActionPlanWrapper: React.FC<WrapperProps> = ({ surveys, targetUserUuid }) => {
  const classes = useStyles()
  const result = useActionPlansActionPlanQuery({ variables: { targetUserUuid } })
  const client = useApolloClient()
  const { organization } = client.readQuery({ query: CurrentUserDocument }).currentUser

  const hasEmployeeActionPlans = organization.solution?.actionPlans
  const hasResidentActionPlans = organization.residentSolution?.actionPlans
  const hasActionPlansCustomStatements = orgHasEnabledSolutionField(
    organization,
    SolutionFieldEnum.ACTION_PLANS,
  )
  const [tabValue, setTabValue] = useState<ActionPlanTabs>(ActionPlanTabs.AP)

  const commonProps = {
    targetUserUuid,
    surveys,
  }
  const employeeSurveys = surveys.filter(s => s.type === SurveyTypeEnum.TI)
  const employeeSurveyUuids = employeeSurveys.map(({ uuid }) => uuid)
  const residentSurveys = surveys.filter(s => s.type === SurveyTypeEnum.RESIDENT_ENGAGEMENT)
  const residentSurveyUuids = residentSurveys.map(({ uuid }) => uuid)
  return (
    <ResponseHandler {...result}>
      {({ actionPlan }) => {
        const emptyActionPlan = isEmptyActionPlan(actionPlan)
        const isApTab = tabValue === ActionPlanTabs.AP
        const completedActionItems = actionPlan.actionItems.filter(item => actionItemIsDone(item))
        const completedStatements = completedActionItems.map(({ statement }) => statement)
        return (
          <Page>
            <BreadcrumbsItem to={URLS.ACTION_PLAN}>Action Plan</BreadcrumbsItem>
            <AppBar className={classes.appBar} position="static" color="default">
              <Grid container>
                <Grid item sm={6}>
                  <Tabs
                    value={tabValue}
                    variant="fullWidth"
                    scrollButtons="auto"
                    onChange={(e, value) => {
                      gaEvent({
                        action: `actionPlanViewTab-${value}`,
                        category: 'ActionPlans',
                      })
                      setTabValue(value)
                    }}
                  >
                    {getTabs().map(tab => (
                      <Tab
                        id={tab.value}
                        key={tab.value}
                        classes={{ root: classes.tabOverrideRoot }}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                </Grid>
              </Grid>
            </AppBar>
            {!isApTab && !completedStatements.length ? (
              <NoCompletedItems />
            ) : (
              <DynamicSurveyQuery
                skip={isApTab}
                queryHook={useInsightsRankedStatementsQuery}
                variables={{
                  statementCodes: completedStatements
                    // filter out custom action items
                    .filter(statement => statement !== null)
                    .map(statement => (statement as FrozenQuestionType).code),
                  includeCustom: true,
                }}
                // surveys should be in ascending order by endDate
                surveys={reverse(surveys)}
              >
                {results => {
                  const codesCounts: { [code: string]: number } = {}
                  completedStatements
                    // filter out custom action items
                    .filter(statement => statement !== null)
                    .map(statement => (statement as FrozenQuestionType).code)
                    .forEach(code => {
                      codesCounts[code] = codesCounts[code] + 1 || 1
                    })
                  // if we have statements that were included multiple times in the Action Plan,
                  // we need to duplicate them as the backend only responds with one result per statement code
                  // even if the code was included multiple times
                  let allStatements = results
                  if (Object.values(codesCounts).some(c => c > 1)) {
                    allStatements = results.map(stmtResults => {
                      return {
                        statements: (stmtResults as InsightsRankedStatementsQuery).statements
                          .map(s => {
                            const count = codesCounts[s.code]
                            return count === 1 ? s : new Array(count).fill(s, 0, count)
                          })
                          .flat(),
                        uuid: stmtResults.uuid,
                      }
                    })
                  }
                  const employeeResults = allStatements.filter(({ uuid }) =>
                    employeeSurveyUuids.includes(uuid),
                  )
                  // grab each statement from each survey. Since each result is per survey, each statement will appear
                  // n times where n is the number of surveys
                  const employeeStatements = orderBy(
                    employeeResults.flatMap(r => (r as InsightsRankedStatementsQuery).statements),
                    'code',
                  ).filter((el, index) => index % employeeSurveys.length === 0)
                  const employeeStatementsData = transformTimeTrendingStatementsToEchartScores({
                    statements: employeeStatements,
                    results: employeeResults,
                  })

                  const residentResults = allStatements.filter(({ uuid }) =>
                    residentSurveyUuids.includes(uuid),
                  )
                  const residentStatements = orderBy(
                    residentResults.flatMap(r => (r as InsightsRankedStatementsQuery).statements),
                    'code',
                  ).filter((el, index) => index % residentSurveys.length === 0)
                  const residentStatementsData = transformTimeTrendingStatementsToEchartScores({
                    statements: residentStatements,
                    results: residentResults,
                  })

                  return (
                    <Paper className={classes.content}>
                      <Grid container>
                        <FirstBox
                          isApTab={isApTab}
                          actionPlan={actionPlan}
                          emptyActionPlan={emptyActionPlan}
                        />
                        <SecondBox
                          isApTab={isApTab}
                          actionPlan={actionPlan}
                          employeeSurveys={employeeSurveys}
                          residentSurveys={residentSurveys}
                          employeeStatementsData={employeeStatementsData.slice(0, 2)}
                          residentStatementsData={residentStatementsData.slice(0, 2)}
                          hasEmployeeActionPlans={hasEmployeeActionPlans}
                          hasResidentActionPlans={hasResidentActionPlans}
                        />
                        <ThirdBox
                          isApTab={isApTab}
                          actionPlan={actionPlan}
                          employeeStatementsData={employeeStatementsData.slice(0, 2)}
                          residentStatementsData={residentStatementsData.slice(0, 2)}
                          hasEmployeeActionPlans={hasEmployeeActionPlans}
                          hasResidentActionPlans={hasResidentActionPlans}
                        />
                      </Grid>
                      {isApTab &&
                        (emptyActionPlan ? (
                          <EmptyActionPlan {...commonProps} />
                        ) : (
                          <ActionPlan
                            actionPlan={actionPlan}
                            hasActionPlansCustomStatements={hasActionPlansCustomStatements}
                            {...commonProps}
                          />
                        ))}
                      {!isApTab && (
                        <CompletedActionItems
                          actionItems={completedActionItems}
                          // surveys should be in ascending order by endDate
                          employeeSurveys={reverse(employeeSurveys)}
                          residentSurveys={reverse(residentSurveys)}
                          employeeStatementsData={employeeStatementsData}
                          residentStatementsData={residentStatementsData}
                          targetUserUuid={targetUserUuid}
                        />
                      )}
                    </Paper>
                  )
                }}
              </DynamicSurveyQuery>
            )}
          </Page>
        )
      }}
    </ResponseHandler>
  )
}

export default ActionPlanWrapper
