import React from 'react'

import { makeStyles } from '@material-ui/core'

import TTBasicStatementsCard from 'components/Insights/Blocks/TTBasicStatementsCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey, InsightsBenchmark } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  ChartTimeRange,
  timeRangeDescription,
} from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { transformTimeTrendingStatementsToEchartScores } from 'components/Insights/TimeTrending/utils'
import {
  useInsightsTimeTrendingStatementsQuery,
  InsightsStatementScoreFragment,
} from 'generated/graphql'
import DynamicTimeframeQuery from 'HOC/DynamicTimeframeQuery'
import { ORDER_TYPES } from 'utils/constants'

const useStyles = makeStyles(({ spacing }) => ({
  description: {
    paddingBottom: spacing(),
  },
}))

type Props = {
  survey: InsightsSurvey
  filters: string[]
  statements: InsightsStatementScoreFragment[]
  benchmark: InsightsBenchmark
  timeRanges: ChartTimeRange[]
}
const TimeframeStatementsCard: React.FC<Props> = ({
  survey,
  filters,
  statements,
  benchmark,
  timeRanges,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <div className={classes.fullRow} id="timeframe-statements-snap">
      <SnapshotChartHeader
        title="Greatest Positive Change"
        snapId="timeframe-statements-snap"
        useBottomPadding={false}
        description={
          <>
            <span className={classes.description}>
              See how the scores have changed over time to monitor which areas are improving, or
              need some help.
            </span>
            <br />
            {timeRangeDescription(timeRanges)}
          </>
        }
      />
      <DynamicTimeframeQuery
        timeRanges={timeRanges}
        variables={{
          filters,
          surveyUuid: survey.uuid,
        }}
        queryHook={useInsightsTimeTrendingStatementsQuery}
      >
        {(data, loading) => {
          return (
            <>
              <TTBasicStatementsCard
                statementsData={transformTimeTrendingStatementsToEchartScores({
                  statements,
                  results: data,
                  order: ORDER_TYPES.DESCENDING,
                }).slice(0, 2)}
                xAxisLabelData={timeRanges}
                benchmarkName={benchmark.name}
                didSelectFilters={!loading && filters.length > 0}
              />
            </>
          )
        }}
      </DynamicTimeframeQuery>
    </div>
  )
}

export default TimeframeStatementsCard
