import React, { useState } from 'react'

import {
  FormControl,
  MenuItem,
  Select,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import findLast from 'lodash/findLast'

import Button from 'components/Blocks/CustomButtons/Button'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import EditableTagsText from 'components/Blocks/EditableTagsText'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import Errors from 'components/Blocks/FormHelpers/Errors'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import { MERGE_TAGS_URL } from 'utils/documentationConstants'
import { getAvailableTagsForQuestion } from 'utils/templateTags'

const MAX_QUESTION_CHOICES_NUMBER = 20

const useStyles = makeStyles(({ spacing }) => ({
  choiceItem: {
    marginTop: spacing(1),
  },
  choiceDelete: {
    marginTop: spacing(1),
    paddingTop: `${spacing(1)}px !important`,
  },
  dropdown: {
    marginTop: spacing(2),
  },
}))

const ChoiceItem = ({ index, value, onChangeChoice, onAddChoice, isLast }) => {
  const v = value.uuid ? value.text : value
  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      onAddChoice(e.target.value)
    }
  }
  return (
    <TextField
      name={`answer-${index}`}
      type="text"
      fullWidth
      value={v}
      placeholder=""
      onChange={e => onChangeChoice(e.target.value, index)}
      onKeyDown={onKeyDown}
      inputProps={{ autoFocus: value === '' && isLast }}
    />
  )
}

const ChoiceList = ({ choices, onAddChoice, onDeleteChoice, onChangeChoice }) => {
  const classes = useStyles()
  return (
    <GridContainer justify="flex-end">
      <ItemGrid xs={11}>
        <Typography style={{ marginTop: 20 }} variant="subtitle1">
          Answer Choices
        </Typography>
      </ItemGrid>
      {choices.map((choice, index) => (
        <React.Fragment key={index}>
          <ItemGrid className={classes.choiceItem} xs={9}>
            <ChoiceItem
              index={index}
              value={choice.text}
              onAddChoice={onAddChoice}
              isLast={index !== 0 && index + 1 === choices.length}
              onChangeChoice={onChangeChoice}
            />
          </ItemGrid>
          <ItemGrid className={classes.choiceDelete} xs={2}>
            {choices.length > 1 && (
              <IconButton color="dangerHover" onClick={() => onDeleteChoice(index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </ItemGrid>
        </React.Fragment>
      ))}

      {choices.length < MAX_QUESTION_CHOICES_NUMBER && (
        <ItemGrid xs={11}>
          <Button onClick={onAddChoice} color="secondaryNoBackground">
            Add Choice&nbsp;&nbsp;
            <AddCircleIcon />
          </Button>
        </ItemGrid>
      )}
    </GridContainer>
  )
}

const UpdateQuestion = ({
  question,
  questionHasEditableChoices,
  onChangeQuestion,
  onUpdateQuestion,
  onAddChoice,
  onChangeChoice,
  onDeleteChoice,
  onClose,
  isSubmitting,
  errors,
  existingQuestions, // Determines whether we require an index for the custom question
}) => {
  const classes = useStyles()

  let initialPrecedingQuestion = null
  if (question.uuid && existingQuestions) {
    initialPrecedingQuestion = findLast(
      existingQuestions,
      existingQ => existingQ.index < question.index,
    )
  }
  // Some custom questions (e.g. in resident surveys) can specify their index.
  // Determine this by choosing the question that comes *before* the custom question
  const [precedingQuestion, setPrecedingQuestion] = useState(initialPrecedingQuestion)
  return (
    <Dialog open fullWidth onClose={onClose} aria-labelledby="form-dialog-title">
      <div>
        <DialogTitle id="form-dialog-title">
          {question.uuid ? 'Edit Question' : 'Add New Question'}
        </DialogTitle>
        <DialogContent>
          <Errors errors={errors} />
          <EditableTagsText
            id="newCustomQuestionText"
            placeholder="Enter your survey question here"
            availableTags={getAvailableTagsForQuestion()}
            value={question.text}
            setValue={onChangeQuestion}
            variant="variant2"
          />
          <Typography variant="body2" color="textSecondary">
            {'Need help understanding {{tags}}?'}
            <ExternalNavLink to={MERGE_TAGS_URL} label=" Learn more >" />
          </Typography>
          {questionHasEditableChoices && (
            <ChoiceList
              choices={question.choices}
              onAddChoice={onAddChoice}
              onDeleteChoice={onDeleteChoice}
              onChangeChoice={onChangeChoice}
            />
          )}
          {existingQuestions && (
            <FormControl className={classes.dropdown}>
              <Typography variant="body2" color="textSecondary">
                Ask this question after:
              </Typography>
              <Select
                id="askQuestionAfter"
                value={precedingQuestion || ''}
                onChange={e => setPrecedingQuestion(e.target.value)}
                renderValue={val => val?.text}
              >
                {existingQuestions.map(q => (
                  <MenuItem key={q.uuid} value={q}>
                    {q.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondaryNoBackground">
            Cancel
          </Button>
          <SubmitButton
            id="saveCustomQuestion"
            isSubmitting={isSubmitting}
            disabled={!question.text || (existingQuestions && !precedingQuestion)}
            onClick={() => onUpdateQuestion(precedingQuestion && precedingQuestion.index + 1)}
            color="primary"
          >
            Save
          </SubmitButton>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default UpdateQuestion
