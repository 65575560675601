import React from 'react'

import cn from 'classnames'

import useInsightsStyles from 'components/Insights/InsightsStyle'
import { MIN_SHOWABLE_RESULTS } from 'utils/constants'

type Props = {
  scores: Array<{ value?: number | null; name?: string | null; lessThanMin?: boolean }>
  title?: string
}
// IMPORTANT NOTE! Do not use Material UI components when rendering echarts tooltips.
// There is a very strange bug that when echarts renders a tooltip component with global style (e.g. Typography)
// It modifies the style on the entire page.
const HorizontalChartTooltip: React.FC<Props> = ({ title, scores }) => {
  const classes = useInsightsStyles()
  return (
    <div className={classes.horizontalChartTooltip}>
      {title && <p className={cn(classes.paragraph, classes.body2)}>{title}</p>}
      {scores.map(({ value, name, lessThanMin }) => {
        if (!name || typeof value !== 'number') return null
        return (
          <p className={cn(classes.paragraph, classes.body2)} key={name}>
            <span className={classes.textSecondary}>{name}: </span>
            <span className={classes.textPrimary}>
              {lessThanMin ? `<${MIN_SHOWABLE_RESULTS} Responses` : `${Math.round(value)}%`}
            </span>
          </p>
        )
      })}
    </div>
  )
}

export default HorizontalChartTooltip
