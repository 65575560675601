import React, { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import cn from 'classnames'

import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import {
  useCurrentUserSurveyResponseNotificationsQuery,
  useUserUpdateUserHasSurveyResponseNotificationsMutation,
  useUserUpdateAverageScoreNotificationsMutation,
  useUserUpdateUserSurveyResponseNotificationsMutation,
  useUserUpdateQuestionScoreNotificationsMutation,
  CurrentUserSurveyResponseNotificationsQuery,
} from 'generated/graphql'
import UserNotificationsActionPlans from 'shared/authenticated/UserNotifications/UserNotificationsActionPlans'
import UserNotificationsSurveyResponses from 'shared/authenticated/UserNotifications/UserNotificationsSurveyResponses'

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    borderTop: `1px solid ${palette.common.navy25}`,
    borderBottom: `1px solid ${palette.common.navy25}`,
    paddingBottom: spacing(),
  },
  activeTab: {
    backgroundColor: palette.common.secondary20,
  },
  notificationsTabs: {
    display: 'flex',
    '& >div:first-child': {
      width: '35%',
    },
    '& >div:last-child': {
      marginLeft: spacing(4),
      marginTop: spacing(2),
      width: '100%',
    },
  },
}))

enum NOTIFICATIONS_TABS {
  ACTION_PLANS = 'Action Plans',
  SURVEY_RESPONSES = 'Survey Responses',
}

type Props = {
  onClose(): void
  hasSurveyResponseNotifications: boolean
  userResultNotifications: CurrentUserSurveyResponseNotificationsQuery['currentUser']['userResultNotifications']
}

const UserNotifications: React.FC<Props> = ({
  onClose,
  hasSurveyResponseNotifications: initialHasSurveyResponseNotifications,
  userResultNotifications,
}) => {
  const classes = useStyles()

  const [tabIndexToShow, setTabIndexToShow] = useState(0)

  const allTabs = {
    [NOTIFICATIONS_TABS.ACTION_PLANS]: false,
    [NOTIFICATIONS_TABS.SURVEY_RESPONSES]: userResultNotifications.length > 0,
  }
  const tabs = Object.keys(allTabs).filter(tabName => allTabs[tabName as NOTIFICATIONS_TABS])

  const [userHasSurveyResponseNotifications, setUserHasSurveyResponseNotifications] = useState(
    initialHasSurveyResponseNotifications,
  )

  const [responseNotificationsInput, setResponseNotificationsInput] = useState(
    userResultNotifications
      .filter(n => n.survey.hasSurveyResponseNotifications)
      .map(n => ({
        surveyUuid: n.survey.uuid,
        notificationTypes: n.surveyResponseNotificationTypes || [],
      })),
  )

  const [averageScoreNotificationsInput, setAverageScoreNotificationsInput] = useState(
    userResultNotifications
      .filter(n => n.survey.hasAverageScoreNotifications)
      .map(n => ({
        surveyUuid: n.survey.uuid,
        averageScoreNotifications: n.averageScoreNotifications || [],
      })),
  )

  const [questionScoreNotificationsInput, setQuestionScoreNotificationsInput] = useState(
    userResultNotifications
      .filter(n => n.survey.hasQuestionScoreNotifications)
      .map(n => ({
        surveyUuid: n.survey.uuid,
        questionScoreNotifications: n.questionScoreNotifications || [],
      })),
  )

  const [
    updateHasNotifications,
    { loading: savingHasNotifications },
  ] = useUserUpdateUserHasSurveyResponseNotificationsMutation()

  const [
    saveResponseNotifications,
    { loading: savingResponseNotifications },
  ] = useUserUpdateUserSurveyResponseNotificationsMutation()

  const [
    saveAverageScoreNotifications,
    { loading: savingAverageScoreNotifications },
  ] = useUserUpdateAverageScoreNotificationsMutation()

  const [
    saveQuestionScoreNotifications,
    { loading: savingQuestionScoreNotifications },
  ] = useUserUpdateQuestionScoreNotificationsMutation()

  const onSave = async () => {
    const operations = [
      updateHasNotifications({
        variables: { hasSurveyResponseNotifications: userHasSurveyResponseNotifications },
      }),
    ]
    if (responseNotificationsInput.length) {
      operations.push(
        saveResponseNotifications({
          variables: {
            userNotificationsInput: responseNotificationsInput.map(n => ({
              surveyUuid: n.surveyUuid,
              notificationTypes: n.notificationTypes,
            })),
          },
        }),
      )
    }
    if (averageScoreNotificationsInput.length) {
      operations.push(
        saveAverageScoreNotifications({
          variables: {
            userNotificationsInput: averageScoreNotificationsInput.map(nList => ({
              surveyUuid: nList.surveyUuid,
              averageScoreNotifications: nList.averageScoreNotifications.map(n => ({
                enabled: n.enabled,
                frequency: n.frequency,
                scoreType: n.scoreType,
              })),
            })),
          },
        }),
      )
    }
    if (questionScoreNotificationsInput.length) {
      operations.push(
        saveQuestionScoreNotifications({
          variables: {
            userNotificationsInput: questionScoreNotificationsInput.map(nList => ({
              surveyUuid: nList.surveyUuid,
              questionScoreNotifications: nList.questionScoreNotifications.map(n => ({
                userNotificationUuid: n.uuid,
                groupNotificationUuid: n.groupNotification.uuid,
                enabled: n.enabled,
                frequency: n.frequency,
              })),
            })),
          },
        }),
      )
    }
    await Promise.all(operations)
    onClose()
  }

  return (
    <>
      <DialogContent className={classes.content} id="dialog">
        <div className={classes.notificationsTabs}>
          <Tabs orientation="vertical" value={tabIndexToShow} aria-label="Notifications tabs">
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={tab}
                  label={tab}
                  id={`tab-${index}`}
                  className={cn({ [classes.activeTab]: tabIndexToShow === index })}
                  onClick={() => setTabIndexToShow(index)}
                />
              )
            })}
          </Tabs>
          <div>
            {tabs.map((tab, index) => {
              return (
                <div key={tab} hidden={tabIndexToShow !== index} aria-labelledby={`tab-${index}`}>
                  {tab === NOTIFICATIONS_TABS.SURVEY_RESPONSES && (
                    <UserNotificationsSurveyResponses
                      surveys={userResultNotifications.map(n => n.survey)}
                      userHasSurveyResponseNotifications={userHasSurveyResponseNotifications}
                      setUserHasSurveyResponseNotifications={setUserHasSurveyResponseNotifications}
                      responseNotificationsInput={responseNotificationsInput}
                      setResponseNotificationsInput={setResponseNotificationsInput}
                      averageScoreNotificationsInput={averageScoreNotificationsInput}
                      setAverageScoreNotificationsInput={setAverageScoreNotificationsInput}
                      questionScoreNotificationsInput={questionScoreNotificationsInput}
                      setQuestionScoreNotificationsInput={setQuestionScoreNotificationsInput}
                    />
                  )}
                  {tab === NOTIFICATIONS_TABS.ACTION_PLANS && <UserNotificationsActionPlans />}
                </div>
              )
            })}
          </div>
        </div>
      </DialogContent>
      <ActionDialogButtons
        onClose={onClose}
        submitButtonText="Save"
        isSubmitting={
          savingHasNotifications ||
          savingResponseNotifications ||
          savingAverageScoreNotifications ||
          savingQuestionScoreNotifications
        }
        onSubmit={onSave}
      />
    </>
  )
}

type ContainerProps = {
  onClose(): void
}

const UserNotificationsContainer: React.FC<ContainerProps> = ({ onClose }) => {
  const classes = useStyles()
  const result = useCurrentUserSurveyResponseNotificationsQuery()

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle id="dialog-title">
        <div className={classes.title}>
          <div>Your Notification Preferences</div>
          <IconButton aria-label="Close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <ResponseHandler {...result}>
        {({ currentUser: { hasSurveyResponseNotifications, userResultNotifications } }) => {
          return (
            <UserNotifications
              hasSurveyResponseNotifications={hasSurveyResponseNotifications}
              userResultNotifications={userResultNotifications}
              onClose={onClose}
            />
          )
        }}
      </ResponseHandler>
    </Dialog>
  )
}

export default UserNotificationsContainer
