import React, { useState, ReactNode } from 'react'

import { makeStyles, IconButton } from '@material-ui/core'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import ArrowRight from '@material-ui/icons/KeyboardArrowRight'
import cn from 'classnames'
import SwipeableViews from 'react-swipeable-views'

const useStyles = makeStyles(() => ({
  outerCarouselContainer: {
    display: 'flex',
  },
  innerCarouselContainer: {
    display: 'flex',
    '& >div': {
      width: '100%',
    },
    width: '100%',
  },
  carouselWidth: {
    width: '90%',
  },
  arrowButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    '& >button': {
      height: 48,
    },
  },
}))

type Props = {
  showCarousel?: boolean
  children: ReactNode
  onStepChange?(step: number): void
  numSteps: number
}
const Component: React.FC<Props> = ({ showCarousel = true, onStepChange, children, numSteps }) => {
  const classes = useStyles()
  const [step, setStep] = useState(0)
  const updateStep = (nextStep: number) => {
    if (onStepChange) {
      onStepChange(nextStep)
    }
    setStep(nextStep)
  }
  return (
    <div className={classes.outerCarouselContainer}>
      {showCarousel && (
        <div className={classes.arrowButtonContainer}>
          <IconButton disabled={step === 0} onClick={() => updateStep(step - 1)} id="carousel-left">
            <ArrowLeft />
          </IconButton>
        </div>
      )}
      <div
        className={cn(
          {
            [classes.carouselWidth]: showCarousel,
          },
          classes.innerCarouselContainer,
        )}
      >
        <SwipeableViews index={step} onChangeIndex={update => updateStep(update)} enableMouseEvents>
          {children}
        </SwipeableViews>
      </div>
      {showCarousel && (
        <div className={classes.arrowButtonContainer}>
          <IconButton
            disabled={step === numSteps - 1}
            onClick={() => updateStep(step + 1)}
            id="carousel-right"
          >
            <ArrowRight />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default Component
