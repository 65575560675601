import { taskStatuses } from 'components/ActionPlans/constants'
import { ActionItemsCountsType } from 'generated/graphql'

// Counts the number of done items, total items and percentage as done/total
export const getCountsSummary = (counts: ActionItemsCountsType) => {
  const done = counts.complete + counts.incomplete
  const total = taskStatuses
    .map(({ countsAttr }) => countsAttr)
    .reduce((acc, val) => acc + (counts[val as keyof ActionItemsCountsType] as number), 0)
  const percentage = total && Math.floor((done / total) * 100)
  return { done, total, percentage }
}
