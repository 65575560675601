import React from 'react'

import { IconButton, makeStyles } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'

import emitter from 'shared/authenticated/emitter'

const useStyles = makeStyles(({ palette }) => ({
  printIcon: {
    color: palette.common.navy65,
  },
}))

const PrintButton: React.FC = () => {
  const classes = useStyles()

  return (
    <IconButton
      className={classes.printIcon}
      id="print-button"
      onClick={() => {
        // When printing, we need to manually resize some components in the app.
        emitter.emit('BEFORE_PRINT')
        window.print()
      }}
    >
      <PrintIcon />
    </IconButton>
  )
}

export default PrintButton
