import {
  ActionPlansActionItemFragment,
  ActionPlansActionPlanFragment,
  TaskStatusEnum,
} from 'generated/graphql'
import { pluralize } from 'utils'
import { addDays } from 'utils/dateUtils'

export const doneTaskStatues = [TaskStatusEnum.COMPLETE, TaskStatusEnum.INCOMPLETE]

export const isEmptyActionPlan = (actionPlan: ActionPlansActionPlanFragment) =>
  actionPlan.actionItems.length === 0

export const actionItemIsDone = (actionItem: ActionPlansActionItemFragment) => {
  return (
    actionItem.actionItemTasks.length > 0 &&
    actionItem.actionItemTasks.every(task => doneTaskStatues.includes(task.status))
  )
}

export const getCompletedDate = (actionItem: ActionPlansActionItemFragment) => {
  return new Date(
    Math.max(...actionItem.actionItemTasks.map(({ updated }) => new Date(updated).getTime())),
  )
}

// TODO: Tanya will provide with more fun fact messages!
export const goalMessages = [
  'You’re 40% more likely to achieve your goals if you write them down and share them with someone.',
  'People who vividly describe their goals are anywhere from 1.2 to 1.4 times more likely to successfully accomplish them.',
]

const getTasksDueDates = (actionPlan: ActionPlansActionPlanFragment) => {
  const dueDates: string[] = []
  actionPlan.actionItems.forEach(
    actionItem =>
      actionItem.actionItemTasks.length > 0 &&
      dueDates.push(
        ...actionItem.actionItemTasks
          .filter(({ status }) => !doneTaskStatues.includes(status))
          .map(({ dueDate }) => dueDate)
          .filter((dueDate): dueDate is string => Boolean(dueDate)),
      ),
  )
  return dueDates.filter(ap => ap !== null)
}

export const getHelperMessage = (actionPlan: ActionPlansActionPlanFragment) => {
  if (isEmptyActionPlan(actionPlan)) {
    return 'You can see helpful reminders here, once you’ve created your action plan.'
  }
  const dueDates = getTasksDueDates(actionPlan)
  if (dueDates.length) {
    const today = new Date()
    const pastDueDateTasks = dueDates.filter(dueDate => new Date(dueDate) < today)
    if (pastDueDateTasks.length) {
      return `You have ${pastDueDateTasks.length} past due ${pluralize(
        'action item',
        pastDueDateTasks.length,
      )} that ${pastDueDateTasks.length === 1 ? 'needs' : 'need'} your attention.`
    }
    const dueThisWeek = dueDates.filter(dueDate => new Date(dueDate) < addDays(today, 7))
    if (dueThisWeek.length) {
      return `You have ${dueThisWeek.length} ${pluralize(
        'action item',
        dueThisWeek.length,
      )} due this week.`
    }
    return `You don’t have any action items due soon. Nice work!`
  }
  // return a random goal message from the list
  return goalMessages[Math.floor(Math.random() * goalMessages.length)]
}
