import React from 'react'

import { makeStyles } from '@material-ui/core'
import FilterIcon from '@material-ui/icons/FilterList'

import Button from 'components/Blocks/CustomButtons/Button'
import { AppliedFilters } from 'utils/types'

const useStyles = makeStyles(theme => ({
  iconButton: {
    color: theme.palette.common.navy65,
    '& >span>span': {
      paddingRight: theme.spacing(1),
    },
  },
  badge: {
    backgroundColor: theme.palette.common.warning,
    width: 20,
    height: 20,
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
    fontSize: '1.0rem',
  },
}))

type Props = {
  filters: AppliedFilters
  toggleFilters(): void
}
const FilterButton: React.FC<Props> = ({ filters, toggleFilters }) => {
  const classes = useStyles()
  const numSelectedFilters = Object.values(filters).reduce((sum, vals) => sum + vals.length, 0)
  return (
    <Button
      id="filters-button"
      color="secondaryNoBackground"
      onClick={toggleFilters}
      className={classes.iconButton}
    >
      <span>Filter</span>
      {numSelectedFilters > 0 ? (
        <div className={classes.badge}>{numSelectedFilters}</div>
      ) : (
        <FilterIcon />
      )}
    </Button>
  )
}

export default FilterButton
