import React, { useState } from 'react'

import { makeStyles, Grid, Typography, Card, IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import orderBy from 'lodash/orderBy'
import { useHistory } from 'react-router-dom'

import { ReactComponent as BuildingIcon } from 'assets/img/building-icon.svg'
import * as LocationOnlyGptwBadge from 'assets/img/certification_hub/location-only-gptw-badge.png'
import * as NotCertifiedBadge from 'assets/img/certification_hub/not-certified-badge.png'
import { ReactComponent as CertifiedCheckIcon } from 'assets/img/certified-check.svg'
import { ReactComponent as WarningIcon } from 'assets/img/warning-icon.svg'
import { ReactComponent as XIcon } from 'assets/img/x-icon.svg'
import {
  CurrentCertificationQuery,
  InsightsModulesEnum,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { ORDER_TYPES, URLS } from 'utils/constants'
import { getFormattedSurveyDate } from 'utils/dateUtils'
import { CertificationStatusEnum, CultureBriefStatusEnum } from 'utils/generatedEnums'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    marginTop: 12,
    padding: spacing(3),
    '& >div': {
      display: 'flex',
    },
  },
  cardContainer: {
    padding: spacing(2),
    minHeight: 224,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  gridRow: {
    display: 'flex',
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >div>p': {
      paddingTop: spacing(),
      display: 'flex',
      alignItems: 'center',
      lineHeight: '2.8rem',
      '& >svg': {
        marginRight: spacing(),
      },
    },
  },
  title: {
    textTransform: 'uppercase',
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
  neutralIcon: {
    '& >path': {
      fill: palette.common.navy65,
    },
  },
  fadedImage: {
    opacity: 0.4,
    maxWidth: 150,
    fontSize: 12,
  },
  expiredBadge: {
    alignSelf: 'flex-end',
    backgroundImage: `linear-gradient(to bottom right, ${palette.common.navy05}, ${palette.common.white})`,
    borderRadius: 250,
    width: 140,
    height: 140,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  expiredText: {
    color: palette.common.danger,
    '& >svg>path': {
      fill: palette.common.danger,
    },
  },
}))

type DCProps = {
  numCertifiedLocations: number
  viewLimitedTo?: 'organization' | 'locations'
} & CurrentCertificationQuery['currentUser']['organization']['certifications'][0]
const DetailsCard: React.FC<DCProps> = ({
  organizationName,
  locationCertifications,
  numCertifiedLocations,
  certifiedStartDate,
  certifiedEndDate,
  uuid,
  survey,
  viewLimitedTo,
  badgeImageUrl,
  status,
  cultureBrief,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const formattedCertifiedEndDate = getFormattedSurveyDate({
    endDate: certifiedEndDate,
    includeDay: true,
    excludeComma: false,
  })
  const numLocations = locationCertifications.length
  const wasCertified =
    status === CertificationStatusEnum.CERTIFIED ||
    status === CertificationStatusEnum.CERTIFIED_EXPIRED
  const hasExpired =
    status === CertificationStatusEnum.CERTIFIED_EXPIRED ||
    status === CertificationStatusEnum.NOT_CERTIFIED_EXPIRED
  let nameToDisplay = organizationName
  let orgCertifiedRow
  let certificationBadge
  if (viewLimitedTo !== 'locations') {
    if (wasCertified) {
      orgCertifiedRow = (
        <Typography color="textSecondary">
          <CertifiedCheckIcon className={classes.neutralIcon} />
          Organization Certified
        </Typography>
      )
      certificationBadge = (
        <div className={classes.expiredBadge}>
          <img
            className={classes.fadedImage}
            height={80}
            src={badgeImageUrl ?? undefined}
            alt="GPTW Certification Badge"
          />
        </div>
      )
    } else {
      orgCertifiedRow = (
        <Typography color="textSecondary">
          <XIcon />
          Organization Not Certified
        </Typography>
      )
      certificationBadge = (
        <div className={numCertifiedLocations > 0 ? '' : classes.expiredBadge}>
          <img
            className={classes.fadedImage}
            height={numCertifiedLocations > 0 ? 140 : 80}
            src={numCertifiedLocations > 0 ? LocationOnlyGptwBadge : NotCertifiedBadge}
            alt={
              numCertifiedLocations > 0
                ? 'GPTW Location Certification Badge'
                : 'Not Certified Badge'
            }
          />
        </div>
      )
    }
  } else if (numLocations === 1) {
    nameToDisplay = locationCertifications[0].name
    if (numCertifiedLocations > 0) {
      orgCertifiedRow = (
        <Typography color="textSecondary">
          <CertifiedCheckIcon className={classes.neutralIcon} />
          Location Certified
        </Typography>
      )
      certificationBadge = (
        <div>
          <img
            className={classes.fadedImage}
            height={140}
            src={LocationOnlyGptwBadge}
            alt="GPTW Location Certification Badge"
          />
        </div>
      )
    } else {
      orgCertifiedRow = (
        <Typography color="textSecondary">
          <XIcon />
          Location Not Certified
        </Typography>
      )
      certificationBadge = (
        <div className={classes.expiredBadge}>
          <img height={80} src={NotCertifiedBadge} alt="Not Certified Badge" />
        </div>
      )
    }
  } else {
    certificationBadge = (
      <div className={numCertifiedLocations > 0 ? classes.fadedImage : classes.expiredBadge}>
        <img
          height={numCertifiedLocations > 0 ? 140 : 80}
          src={numCertifiedLocations > 0 ? LocationOnlyGptwBadge : NotCertifiedBadge}
          alt={
            numCertifiedLocations > 0 ? 'GPTW Location Certification Badge' : 'Not Certified Badge'
          }
        />
      </div>
    )
  }
  let locationCertifiedRow
  if (viewLimitedTo !== 'organization' && numLocations > 1) {
    const numCertifiedText = `${Math.round(
      (numCertifiedLocations / numLocations) * 100,
    )}% of Locations Certified`
    locationCertifiedRow =
      numCertifiedLocations === 0 ? (
        <Typography color="textSecondary">
          <XIcon />
          {numCertifiedText}
        </Typography>
      ) : (
        <Typography color="textSecondary">
          <CertifiedCheckIcon className={classes.neutralIcon} />
          {numCertifiedText}
        </Typography>
      )
  }
  return (
    <Card className={classes.cardContainer}>
      <div className={classes.contentRow}>
        <Typography>
          {getFormattedSurveyDate({
            endDate: certifiedStartDate,
            includeDay: false,
          })}{' '}
          -{' '}
          {getFormattedSurveyDate({
            endDate: certifiedEndDate,
            includeDay: false,
          })}{' '}
          Certification
        </Typography>
        <IconButton
          id="more-button"
          aria-label="More"
          aria-haspopup="true"
          onClick={e => setMenuAnchorEl(e.currentTarget as HTMLElement)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClick={() => setMenuAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setMenuAnchorEl(null)
              history.push(
                getInsightsPage(
                  survey.uuid,
                  InsightsModulesEnum.SNAPSHOTS,
                  SurveyProductTypeEnum.EMPLOYEE,
                ),
              )
            }}
          >
            View Trust Index Survey Results
          </MenuItem>
          {cultureBrief.status !== CultureBriefStatusEnum.IMPORTED_EMPTY && (
            <MenuItem
              onClick={() => {
                setMenuAnchorEl(null)
                history.push(`${URLS.CERTIFICATION_HUB.CULTURE_BRIEF}/${uuid}`)
              }}
            >
              View Culture Brief
            </MenuItem>
          )}
        </Menu>
      </div>
      <div className={classes.contentRow}>
        <div>
          <Typography color="textSecondary">
            <BuildingIcon /> {nameToDisplay}
          </Typography>
          {orgCertifiedRow}
          {locationCertifiedRow}
          {hasExpired && (
            <Typography className={classes.expiredText}>
              <WarningIcon />
              Expired {formattedCertifiedEndDate}
            </Typography>
          )}
        </div>
        {certificationBadge}
      </div>
    </Card>
  )
}

type Props = {
  expiredCertifications: CurrentCertificationQuery['currentUser']['organization']['certifications']
  viewLimitedTo?: 'organization' | 'locations'
}
const PastCertificationsView: React.FC<Props> = ({ expiredCertifications, viewLimitedTo }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Past Certifications</Typography>
      <Grid container spacing={2}>
        {orderBy(
          expiredCertifications,
          ['certifiedStartDate', 'certifiedEndDate'],
          [ORDER_TYPES.DESCENDING, ORDER_TYPES.DESCENDING],
        ).map(certification => {
          const { locationCertifications, uuid } = certification
          return (
            <Grid item sm={6} key={uuid} className={classes.gridRow}>
              <DetailsCard
                locationCertifications={locationCertifications}
                numCertifiedLocations={locationCertifications.filter(l => l.isCertified).length}
                {...certification}
                viewLimitedTo={viewLimitedTo}
              />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default PastCertificationsView
