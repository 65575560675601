import React from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { format } from 'date-fns'

import Button from 'components/Blocks/CustomButtons/Button'
import { getMenuLabel } from 'components/Survey/Wizard/Steps/Notifications/AddIntervalNotificationDialog'
import Section, { SectionsEnum } from 'components/Survey/Wizard/Steps/Summary/Section'
import useStyles from 'components/Survey/Wizard/Steps/Summary/styles'
import { WizardStepsEnum } from 'components/Survey/WizardContainer'
import { NotificationsSectionType, NotificationType } from 'generated/graphql'
import { pluralize } from 'utils'
import { displayDateTime } from 'utils/dateUtils'

type Props = {
  editable: boolean
  surveyPortalUrl: string
  goToStep(stepName: WizardStepsEnum): void
  notifications: NotificationsSectionType
  showConfirmNoNotifications: boolean
  setShowConfirmNoNotifications(show: boolean): void
  toggleSurveyStatus(): void
  errors: { [key: string]: boolean }
  warnings: { [key: string]: boolean }
}

const NotificationsSection: React.FC<Props> = ({
  editable,
  goToStep,
  notifications,
  showConfirmNoNotifications,
  setShowConfirmNoNotifications,
  surveyPortalUrl,
  toggleSurveyStatus,
  errors,
  warnings,
}) => {
  const classes = useStyles()

  const notificationVia = (n: NotificationType) => {
    const methods: string[] = []
    if (n.workEmail) methods.push('work email')
    if (n.personalEmail) methods.push('personal email')
    if (n.sms) methods.push('SMS')
    if (!methods.length) return ''
    return `via ${methods.join(', ')}`
  }

  return (
    <Section
      editable={editable}
      goToStep={() => goToStep(WizardStepsEnum.NOTIFICATIONS)}
      title={SectionsEnum.NOTIFICATIONS}
      ready={notifications.ready}
      warningsNumber={notifications.warnings.length}
    >
      <ul>
        {(warnings.NOTIFICATIONS_PERSONAL_EMAIL ||
          warnings.NOTIFICATIONS_WORK_EMAIL ||
          warnings.NOTIFICATIONS_SMS) && (
          <li className={classes.warning}>
            {"You don't have "}
            {[
              warnings.NOTIFICATIONS_WORK_EMAIL && 'work emails',
              warnings.NOTIFICATIONS_PERSONAL_EMAIL && 'personal emails',
              warnings.NOTIFICATIONS_SMS && 'mobile phone numbers',
            ]
              .filter(n => n !== undefined)
              .join(', ')}
            {
              ' uploaded for all your participants. Change to another method to make sure your notifications are received. '
            }
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.NOTIFICATIONS)}
              color="secondaryNoBackground"
            >
              Edit Notifications
            </Button>
          </li>
        )}
        {warnings.NO_NOTIFICATIONS && (
          <li className={classes.warning}>
            Surveys that send notifications typically have a much higher response rate.{' '}
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.NOTIFICATIONS)}
              color="secondaryNoBackground"
            >
              Add Notifications
            </Button>
          </li>
        )}
        <li>
          {notifications.total}{' '}
          <span className={classes.mute}>
            {pluralize('notification', notifications.notifications.length)} scheduled
          </span>
        </li>
        {errors.NOTIFICATIONS_ERROR && (
          <li className={classes.error}>
            There is a problem with one or more of your notifications.{' '}
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.NOTIFICATIONS)}
              color="secondaryNoBackground"
            >
              Go to Notifications
            </Button>
          </li>
        )}
        {notifications.notifications.map(n => (
          <li key={n.uuid}>
            {n.when ? displayDateTime(n.when) : (n.days || n.days === 0) && getMenuLabel(n.days)}{' '}
            {notificationVia(n)} (
            {n.sentTimestamp
              ? `Sent on ${format(new Date(n.sentTimestamp), 'MMM d h:mm a')}`
              : 'Scheduled'}
            )
          </li>
        ))}
      </ul>
      <Dialog open={showConfirmNoNotifications} fullWidth>
        <DialogTitle>Continue without notifications?</DialogTitle>
        <DialogContent>
          <Typography component="p">
            You have no notifications scheduled for your survey. You will need to tell participants
            to go to {surveyPortalUrl} and enter their survey code in to take the survey.
          </Typography>
          <Typography component="p">
            Are you sure you want to continue without scheduling any notifications?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            noMargins
            onClick={() => goToStep(WizardStepsEnum.NOTIFICATIONS)}
            color="secondaryNoBackground"
          >
            No, schedule notifications
          </Button>
          <Button
            onClick={() => {
              setShowConfirmNoNotifications(false)
              toggleSurveyStatus()
            }}
            variant="contained"
            color="warning"
          >
            Yes, continue without notifications
          </Button>
        </DialogActions>
      </Dialog>
    </Section>
  )
}

export default NotificationsSection
