import React from 'react'

import { Redirect, Route, Switch } from 'react-router-dom'

import CertificationHubContainer from 'components/CertificationHub/CertificationHubContainer'
import CultureBriefContainer from 'components/CertificationHub/CultureBriefContainer'
import withErrorHandler from 'HOC/withErrorHandler'
import { URLS, CULTURE_BRIEF } from 'utils/constants'

const CertificationHubRoutes = () => {
  return (
    <Switch>
      <Route exact path={URLS.CERTIFICATION_HUB.HUB} component={CertificationHubContainer} />
      <Route path={CULTURE_BRIEF} component={CultureBriefContainer} />
      <Redirect to={URLS.CERTIFICATION_HUB.HUB} />
    </Switch>
  )
}

export default withErrorHandler(CertificationHubRoutes)
