import React from 'react'

import { InputAdornment, IconButton, Input } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import cn from 'classnames'
import PropTypes from 'prop-types'

import DebouncedTextInput from 'components/Blocks/FormHelpers/DebouncedTextInput'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchInput: {
    width: 240,
  },
  searchResultsText: {
    fontSize: '1.6rem',
    whiteSpace: 'nowrap',
  },
  rootDark: {
    '& svg': {
      // color: theme.palette.common.navy65,
      fill: theme.palette.common.navy65,
    },
  },
  rootLight: {
    '& svg': {
      // color: theme.palette.common.white,
      fill: theme.palette.common.white,
    },
    '& input': {
      color: theme.palette.common.white,
    },
  },
  focusedDark: {
    '& div svg': {
      fill: theme.palette.common.secondary,
    },
  },
  focusedLight: {
    '& div svg': {
      fill: theme.palette.common.secondary,
    },
  },
  underlineDark: {
    '&:after': {
      // borderBottom: '2px solid red'
    },
    '&:before': {
      // borderBottom: `1px solid green`
    },
  },
  disabled: {},
  focused: {},
  error: {},
  underlineLight: {
    '&:after': {
      // borderColor: theme.palette.common.white
    },
    '&:before': {
      // borderBottom: `1px solid green`,
      borderColor: theme.palette.common.white,
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.common.white} !important`,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        borderBottom: `1px solid ${theme.palette.common.white} !important`,
      },
    },
  },
  searchResultsLight: {
    color: theme.palette.common.white50,
  },
  searchResultsDark: {
    color: theme.palette.common.navy65,
  },
})

class SimpleSearch extends React.Component {
  handleClose = () => {
    this.props.handleSearch('')
    this.props.handleClose()
  }

  render() {
    const {
      classes,
      query,
      handleClose,
      withSearchAdornment,
      disableUnderline,
      placeholder,
      theme,
      numResults,
      debounceTime,
      handleSearch,
      autoFocus,
    } = this.props
    const themeClass = theme[0].toUpperCase() + theme.substring(1, 10)
    let startAdornment = <></>
    let endAdornment = <></>
    if (withSearchAdornment) {
      startAdornment = (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      )
    }
    if (handleClose !== null) {
      endAdornment = (
        <>
          {numResults !== null && query && (
            <div className={cn(classes.searchResultsText, classes[`searchResults${themeClass}`])}>
              {numResults} |
            </div>
          )}
          <IconButton onClick={this.handleClose} id="close-search">
            <CloseIcon />
          </IconButton>
        </>
      )
    }
    const inputProps = {
      className: classes.searchInput,
      classes: {
        root: classes[`root${themeClass}`],
        focused: classes[`focused${themeClass}`],
        underline: classes[`underline${themeClass}`],
      },
      value: query,
      placeholder,
      inputProps: { autoFocus, spellCheck: 'false' },
      startAdornment,
      endAdornment,
      disableUnderline,
    }
    return (
      <div className={cn(classes.root, `root${themeClass}`)}>
        {debounceTime ? (
          <DebouncedTextInput
            {...inputProps}
            delayTime={debounceTime}
            onUpdate={handleSearch}
            InputComponent={Input}
          />
        ) : (
          <Input {...inputProps} onChange={evt => handleSearch(evt.target.value)} />
        )}
      </div>
    )
  }
}

SimpleSearch.defaultProps = {
  handleClose: null,
  withSearchAdornment: false,
  disableUnderline: false,
  placeholder: '',
  theme: 'dark',
  numResults: null,
  autoFocus: true,
  debounceTime: null,
}
SimpleSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleClose: PropTypes.any,
  withSearchAdornment: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  numResults: PropTypes.number,
  autoFocus: PropTypes.bool,
  debounceTime: PropTypes.number,
}

export default withStyles(styles)(SimpleSearch)
