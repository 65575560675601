import React from 'react'

import { Redirect, Route } from 'react-router-dom'

import InformLettersContainer from 'components/InformLetters/InformLettersContainer'
import withErrorHandler from 'HOC/withErrorHandler'
import { URLS } from 'utils/constants'

const InformLettersRoutes = () => {
  return (
    <>
      <Route exact path={URLS.INFORM_LETTERS} component={InformLettersContainer} />
      <Redirect to={URLS.INFORM_LETTERS} />
    </>
  )
}

export default withErrorHandler(InformLettersRoutes)
