import React from 'react'

import ReactEcharts from 'echarts-for-react'

import HorizontalChartTooltip from 'components/Blocks/Charts/HorizontalChartTooltip'
import { chartTextStyle } from 'components/Insights/InsightsStyle'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import { colors } from 'shared/theme'
import { surveysTooltip } from 'utils/echartsHelpers'

type Props = { positive: number; target: number }
const KeyFocusAreaDonut: React.FC<Props> = ({ positive, target }) => {
  return (
    <ReactEcharts
      notMerge
      style={{
        height: 150,
      }}
      option={{
        tooltip: {
          confine: true,
          ...surveysTooltip({}),
          trigger: 'item',
          formatter: () => {
            return renderEchartTooltip(
              <HorizontalChartTooltip
                scores={[
                  { value: positive, name: 'Current Score' },
                  { value: target - positive, name: 'Growth Potential' },
                  { value: target, name: 'Target Score' },
                ]}
              />,
            )
          },
        },
        title: [
          {
            text: `+${target - positive}%`,
            x: 'center',
            y: 'center',
            textStyle: {
              ...chartTextStyle,
              fontSize: 23,
              fontWeight: 'normal',
              color: colors.success,
            },
          },
        ],
        // Third color is the target line
        color: [colors.success, colors.tertiarySuccess, colors.navy, colors.grey],
        series: [
          {
            type: 'pie',
            // Use an aspect ratio of 8:10 but maximize the circle at 60% so we have room to display
            // the label at the top of the graph if the target score is near 100%.
            radius: ['48%', '60%'],
            label: {
              show: false,
            },
            data: [
              { value: positive },
              { value: target - positive },
              // Use an extra data point to draw the "target" line
              {
                value: 0,
                name: `${target}%`,
                label: {
                  show: true,
                  ...chartTextStyle,
                },
                labelLine: {
                  show: true,
                  lineStyle: {
                    type: 'dashed',
                  },
                },
              },
              // Fill the rest of the cricle with grey
              { value: 100 - target },
            ],
          },
        ],
      }}
    />
  )
}

export default KeyFocusAreaDonut
