import React, { useContext, useEffect, useState } from 'react'

import ListIcon from '@material-ui/icons/List'
import QAIcon from '@material-ui/icons/QuestionAnswer'
import SettingsIcon from '@material-ui/icons/Settings'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import LogRocket from 'logrocket'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { ThroughProvider } from 'react-through'

import { ReactComponent as ActionPlanIcon } from 'assets/img/action_plans/action-plan-icon.svg'
import { ReactComponent as AnalyticsIcon } from 'assets/img/action_plans/analytics-icon.svg'
import { ReactComponent as CertificationHubIcon } from 'assets/img/certification-nav-icon.svg'
import { ReactComponent as EnvelopeIcon } from 'assets/img/envelope.svg'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import ReactGA from 'config/ga'
import { defaultStore, StoreContext } from 'config/LocalStorage'
import {
  SurveyProductTypeEnum,
  SolutionFieldEnum,
  CurrentUserQuery,
  useCurrentUserQuery,
} from 'generated/graphql'
import AuthenticatedLayout, { RouteType } from 'shared/authenticated'
import { getTeaserMessage, getDefaultLandingURL, getSolutionFromProductType } from 'utils'
import {
  CONTACT_EMAIL,
  URLS,
  INSIGHTS,
  PRODUCT_TYPE_CONSTANTS,
  PRODUCT_TYPE_LABELS,
  PRODUCT_TYPE_USER_FIELDS,
  CERTIFICATION_HUB,
  ORG_SETTINGS,
  filterValuesPage,
} from 'utils/constants'
import IS_ENABLED_LOGROCKET from 'utils/logrocket'
import { orgHasEnabledSolutionField } from 'utils/solution'

const makeSurveyRoutes = (user: CurrentUserQuery['currentUser']) => {
  return [
    {
      path: URLS.SURVEYS.DASHBOARD,
      name: 'Surveys',
      icon: QAIcon,
      hasAccess: user.canCreateSurveys || user.residentCanCreateSurveys,
    },
  ]
}

const makeInsightsRoutes = (user: CurrentUserQuery['currentUser']) => {
  let hasAccess = false
  const subroutes = Object.values(SurveyProductTypeEnum).map(productType => {
    const solution = getSolutionFromProductType(user.organization, productType)
    const subrouteHasAccess = user[PRODUCT_TYPE_USER_FIELDS[productType].accessToSurveyProductField]
    hasAccess = hasAccess || subrouteHasAccess
    return {
      path: PRODUCT_TYPE_CONSTANTS[productType].insightsUrls.DASHBOARD,
      name: PRODUCT_TYPE_LABELS[productType],
      mini: productType === SurveyProductTypeEnum.EMPLOYEE ? 'EMP' : 'CMP',
      hasAccess: subrouteHasAccess,
      teaser: solution ? '' : getTeaserMessage(PRODUCT_TYPE_LABELS[productType]),
    }
  })
  return [
    {
      collapse: true,
      path: INSIGHTS,
      name: 'Insights',
      icon: ShowChartIcon,
      hasAccess,
      subroutes,
    },
  ]
}

const makeAnalyticsRoutes = (user: CurrentUserQuery['currentUser']) => {
  return [
    {
      path: URLS.ANALYTICS,
      name: 'Analytics',
      icon: AnalyticsIcon,
      hasAccess: user.canUseAnalytics,
    },
  ]
}

const makeCertificationHubRoutes = (user: CurrentUserQuery['currentUser']) => {
  return [
    {
      path: CERTIFICATION_HUB,
      name: 'Certification Hub',
      icon: CertificationHubIcon,
      hasAccess: user.canViewCertificationResults || user.canManageCertifications,
    },
  ]
}

const makeInformLettersRoutes = () => {
  return [
    {
      path: URLS.INFORM_LETTERS,
      name: 'Inform Letters',
      icon: EnvelopeIcon,
      hasAccess: true,
    },
  ]
}

const makeActionPlansRoutes = (user: CurrentUserQuery['currentUser']) => {
  return [
    {
      path: URLS.ACTION_PLAN,
      name: 'Action Plan',
      icon: ActionPlanIcon,
      hasAccess: user.canUseActionPlans,
      teaser:
        !orgHasEnabledSolutionField(user.organization, SolutionFieldEnum.ACTION_PLANS) &&
        `To learn more about action plans, contact ${CONTACT_EMAIL}.`,
    },
    {
      path: URLS.ACTION_PLANS_MANAGEMENT,
      name: 'Manage Action Plans',
      icon: ListIcon,
      hasAccess: user.canManageActionPlans,
      teaser:
        !orgHasEnabledSolutionField(user.organization, SolutionFieldEnum.ACTION_PLANS_MANAGEMENT) &&
        `To learn more about how to manage action plans, contact ${CONTACT_EMAIL}.`,
    },
  ]
}

const makeSurveySettingsRoutes = (user: CurrentUserQuery['currentUser']) => {
  let hasAccess = false
  const subroutes = Object.values(SurveyProductTypeEnum).map(productType => {
    const solution = getSolutionFromProductType(user.organization, productType)
    const subrouteHasAccess = user[PRODUCT_TYPE_USER_FIELDS[productType].surveySettingsField]
    hasAccess = hasAccess || subrouteHasAccess
    return {
      path: PRODUCT_TYPE_CONSTANTS[productType].settingsUrls,
      name: `${PRODUCT_TYPE_LABELS[productType]} Survey Portal`,
      mini: productType === SurveyProductTypeEnum.EMPLOYEE ? 'ESP' : 'CSP',
      hasAccess: subrouteHasAccess,
      teaser: !solution && getTeaserMessage(PRODUCT_TYPE_LABELS[productType]),
    }
  })
  return [
    {
      collapse: true,
      // Only used for a key, subroutes contains the routes.
      path: 'SurveySettings',
      name: 'Survey Settings',
      mini: 'SS',
      hasAccess,
      subroutes,
    },
  ]
}

const makeOrgSettingsRoutes = (user: CurrentUserQuery['currentUser']) => {
  const subroutes = [
    {
      path: URLS.ORG_SETTINGS.GENERAL,
      name: 'General',
      mini: 'G',
      hasAccess: user.canAccessGeneralSettings,
    },
    {
      path: URLS.ORG_SETTINGS.CONTACTS_UPLOAD,
      name: 'Contacts (Upload)',
      mini: 'CU',
      hasAccess: user.canUploadCombinedContacts || user.canUploadEmployeeContacts,
    },
    ...makeSurveySettingsRoutes(user),
    {
      path: URLS.ORG_SETTINGS.USERS,
      name: 'User Permissions',
      mini: 'UP',
      hasAccess: user.canInviteUsers,
    },
    {
      collapse: true,
      path: URLS.ORG_SETTINGS.DATA_STRUCTURE,
      name: 'Data Structure',
      mini: 'DS',
      hasAccess: user.canAccessDataStructure,
      subroutes: user.filters.map(filterType => ({
        path: filterValuesPage(filterType.filterTypeUuid),
        name: filterType.namePlural,
        mini: filterType.name[0],
        // TODO: limit per user filters
        hasAccess: user.canAccessDataStructure,
      })),
    },
  ]
  return [
    {
      collapse: true,
      path: ORG_SETTINGS,
      name: 'Settings',
      icon: SettingsIcon,
      // Only allow if they have permission to view one of the org settings tabs.
      hasAccess: subroutes.some(v => v.hasAccess),
      subroutes,
    },
  ]
}

const makeDownloadRoutes = () => {
  return [{ path: URLS.DOWNLOAD, hasAccess: true }]
}

const getAllowedRoutes = (routes: RouteType[]) => {
  return routes
    .filter(r => r.hasAccess || r.teaser)
    .map(r => ({ ...r, subroutes: r.subroutes?.filter(v => v.hasAccess) }))
}

const makeRoutes = (user: CurrentUserQuery['currentUser']) => {
  const routes = [
    ...makeSurveyRoutes(user),
    ...makeInsightsRoutes(user),
    ...makeAnalyticsRoutes(user),
    ...makeActionPlansRoutes(user),
    ...makeCertificationHubRoutes(user),
    ...makeInformLettersRoutes(),
    ...makeDownloadRoutes(),
  ]
  const footerRoutes = [...makeOrgSettingsRoutes(user)]

  return {
    routes: getAllowedRoutes(routes),
    footerRoutes: getAllowedRoutes(footerRoutes),
  }
}

type AuthenticatedRouteProps = {
  currentUser: CurrentUserQuery['currentUser']
  WrappedComponent: React.ElementType
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  currentUser,
  WrappedComponent,
  ...restProps
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<{ to: string; title: string }[]>([])
  const { pathname } = useLocation()
  const { store, updateStore } = useContext(StoreContext)
  useEffect(() => {
    // this block ensures that local storage is reset during impersonation
    if (
      currentUser &&
      (currentUser.id !== store.currentUserId ||
        // Checking organization for the global-login, because the user is the same
        currentUser.organization.uuid !== store.currentOrganizationUuid)
    ) {
      updateStore({
        ...defaultStore,
        currentUserId: currentUser.id,
        currentOrganizationUuid: currentUser.organization.uuid,
      })
    }
  }, [currentUser, store.currentOrganizationUuid, store.currentUserId, updateStore])

  useEffect(() => {
    if (IS_ENABLED_LOGROCKET) {
      // https://docs.logrocket.com/reference/identify
      LogRocket.identify(currentUser.id, {
        name: currentUser.name,
        email: currentUser.email,
      })
    }
  }, [currentUser])

  // In the case that user switches microfront ends from a nested route,
  // multiple `delete` calls will happen concurrently and we must ensure state is updated atomatically
  const deleteBreadcrumb = (to: string) => {
    setBreadcrumbs(breadcrumbs.filter(b => b.to !== to))
  }

  const addBreadcrumb = (to: string, title: string) => {
    setBreadcrumbs([...breadcrumbs, { to, title }])
  }

  const { routes, footerRoutes } = makeRoutes(currentUser)
  const pathExists = [...routes, ...footerRoutes].find(route => pathname.startsWith(route.path))
  if (ReactGA) {
    ReactGA.set({
      userId: currentUser.id,
      dimension1: currentUser.organization.name, // organization
      dimension2: currentUser.organization.lastTrustIndexDate, // last_TI_date
      dimension3: JSON.stringify(currentUser.isStaff), // is_staff
      dimension4: JSON.stringify(currentUser.isAdmin), // is_admin
      dimension5: currentUser.organization.solution?.name, // solution
      dimension6: JSON.stringify(currentUser.organization.isTestAccount), // is_test
      dimension7: currentUser.organization.residentSolution?.name, // resident_solution
    })
  }
  return pathExists ? (
    <ThroughProvider>
      <AuthenticatedLayout
        routes={routes}
        footerRoutes={footerRoutes}
        user={currentUser}
        {...restProps}
      >
        {breadcrumbs.map(br => (
          <BreadcrumbsItem key={br.to} to={br.to}>
            {br.title}
          </BreadcrumbsItem>
        ))}
        <WrappedComponent
          addBreadcrumb={addBreadcrumb}
          deleteBreadcrumb={deleteBreadcrumb}
          currentUser={currentUser}
        />
      </AuthenticatedLayout>
    </ThroughProvider>
  ) : (
    <Redirect to={{ pathname: getDefaultLandingURL(currentUser) }} />
  )
}

type Props = {
  component: React.ElementType
  path: string
}

const AuthenticatedRouteContainer: React.FC<Props> = ({ component, ...restProps }) => {
  const currentUserResult = useCurrentUserQuery()

  return (
    <Route
      {...restProps}
      render={() => (
        <ResponseHandler {...currentUserResult}>
          {({ currentUser }, { networkStatus }) => {
            if (networkStatus === 8) {
              return <div>Site is currently down. We will get it back up as soon as we can!</div>
            }
            return (
              <AuthenticatedRoute
                currentUser={currentUser}
                WrappedComponent={component}
                {...restProps}
              />
            )
          }}
        </ResponseHandler>
      )}
    />
  )
}

export default React.memo(AuthenticatedRouteContainer)
