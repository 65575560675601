import React from 'react'

import ReactEcharts from 'echarts-for-react'
import PropTypes from 'prop-types'

import PrintableComponent from 'HOC/PrintableComponent'

/**
 * Echarts are not responsive by default.
 * Convert the chart to an image and resize on print events.
 */
export default class PrintableEchart extends React.Component {
  state = { dataUrl: null }

  beforePrint = () => {
    if (this.chartInstance) {
      // Convert the chart to an image.
      this.setState({ dataUrl: this.chartInstance.getDataURL() })
    }
  }

  afterPrint = () => {
    if (this.state.dataUrl) {
      this.setState({ dataUrl: null })
    }
  }

  renderChart = () => {
    if (this.state.dataUrl) {
      return (
        <img
          src={this.state.dataUrl}
          width={this.props.printWidth}
          height={this.props.printHeight}
          alt=""
        />
      )
    }
    return (
      <ReactEcharts
        ref={chartRef => {
          if (chartRef && !this.state.dataUrl) {
            this.chartInstance = chartRef.getEchartsInstance()
          }
        }}
        {...this.props}
      />
    )
  }

  render() {
    return (
      <PrintableComponent beforePrint={this.beforePrint} afterPrint={this.afterPrint}>
        {this.renderChart()}
      </PrintableComponent>
    )
  }
}

PrintableEchart.defaultProps = { printHeight: null }
PrintableEchart.propTypes = {
  printWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  printHeight: PropTypes.number,
}
