import { SurveyTypeEnum } from 'generated/graphql'

export const getAvailableTagsForWelcomeMessage = (surveyType?: SurveyTypeEnum) => {
  // These must stay in sync with the backend, see TemplateTagsService
  const tags = [
    'org_abbr',
    'org_name',
    'first_name',
    'location_name',
    'location_address',
    'month',
    'year',
  ]
  if (surveyType === SurveyTypeEnum.TI) {
    tags.push('privacy_link')
  }
  return tags
}

export const getAvailableTagsForQuestion = () => {
  // These must stay in sync with the backend, see TemplateTagsService
  const tags = ['org_abbr', 'org_name', 'location_name', 'location_address']
  return tags
}
