import React, { useState } from 'react'

import { IconButton, makeStyles, Popover, Typography, Grid, Collapse } from '@material-ui/core'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown'
import ArrowUp from '@material-ui/icons/KeyboardArrowUp'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import * as MarketingImage1 from 'assets/img/certification_hub/marketing-toolkit-1.png'
import * as MarketingImage2 from 'assets/img/certification_hub/marketing-toolkit-2.png'
import * as MarketingImage3 from 'assets/img/certification_hub/marketing-toolkit-3.png'
import * as MarketingImage4 from 'assets/img/certification_hub/marketing-toolkit-4.png'
import * as MarketingImage5 from 'assets/img/certification_hub/marketing-toolkit-5.png'
import * as MarketingImage6 from 'assets/img/certification_hub/marketing-toolkit-6.png'
import { ReactComponent as ShareIcon } from 'assets/img/share-icon.svg'
import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import { gaEvent } from 'config/ga'
import { useShareMarketingToolkitMutation } from 'generated/graphql'
import { usePublicConstants } from 'utils/customHooks'

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    '& >div': {
      padding: spacing(5),
      '&:nth-of-type(odd)': {
        backgroundColor: palette.common.navy05,
      },
      '& >div': {
        '& >h4': {
          fontSize: '3.0rem',
        },
        '& >button': {
          marginLeft: 0,
          paddingLeft: 0,
        },
      },
    },
  },
  textBoxContainer: {
    width: '100%',
    '& >p': {
      width: '100%',
      background: palette.common.navy05,
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      marginTop: spacing(3),
    },
  },
  submitButton: {
    marginLeft: 0,
    marginTop: spacing(3),
  },
  header: {
    marginTop: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  buttonLabel: {
    paddingRight: spacing(2),
  },
  popoverContainer: {
    width: 400,
    padding: spacing(2),
    '& >button': {
      marginLeft: 0,
      marginTop: spacing(3),
    },
  },
  leftPadding: {
    paddingLeft: spacing(4),
  },
}))

type ShareProps = {
  title: string
  description: string
  includeBadgeAssets: boolean
  buttonLabel?: string
  locationUuid?: string // Email will include badge assets for location
}
export const SharePopover: React.FC<ShareProps> = ({
  title,
  description,
  buttonLabel,
  includeBadgeAssets,
  locationUuid,
}) => {
  const classes = useStyles()
  const [shareToolkit] = useShareMarketingToolkitMutation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [email, setEmail] = useState('')
  const [emailMessage, setEmailMessage] = useState('')
  return (
    <>
      <IconButton
        onClick={e => setAnchorEl(e.target as HTMLElement)}
        id={`share-${locationUuid ? 'location' : 'org'}`}
      >
        {buttonLabel && (
          <Typography color="textSecondary" className={classes.buttonLabel}>
            {buttonLabel}
          </Typography>
        )}
        <ShareIcon />
      </IconButton>
      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <ValidatorForm
          onSubmit={() => {
            shareToolkit({
              variables: { email, note: emailMessage, locationUuid, includeBadgeAssets },
            })
            setAnchorEl(null)
          }}
          className={classes.popoverContainer}
        >
          <Typography variant="h6">{title}</Typography>
          <br />
          <Typography color="textSecondary">{description}</Typography>
          <br />
          <TextValidator
            autoFocus
            label="Enter Email"
            name="email"
            type="email"
            id="share-email"
            value={email}
            onChange={({ target }) => setEmail((target as HTMLInputElement).value)}
            fullWidth
            validators={['required', 'isEmail']}
            errorMessages={['Email is required', 'Valid email address required']}
          />
          <br />
          <TextValidator
            label="Add Message"
            name="emailMessage"
            id="share-message"
            value={emailMessage}
            onChange={({ target }) => setEmailMessage((target as HTMLInputElement).value)}
            fullWidth
          />
          <br />
          <SubmitButton>Send</SubmitButton>
        </ValidatorForm>
      </Popover>
    </>
  )
}

type Props = {}
const MarketingToolkitView: React.FC<Props> = () => {
  const classes = useStyles()
  const { certificationMarketingToolkitUrl } = usePublicConstants()
  const [emailTextExpanded, setEmailTextExpanded] = useState(false)
  return (
    <div>
      <div className={classes.header}>
        <SharePopover
          title="Share Marketing Toolkit"
          description="Want to send the Marketing Toolkit as a PDF to someone on your team? Enter their email
            and we’ll send them the details."
          buttonLabel="Share"
          includeBadgeAssets={false}
        />
      </div>
      <div className={classes.container}>
        <Grid container>
          <Grid sm={6}>
            <Typography variant="h4">Time to Celebrate Your Great Workplace!</Typography>
            <br />
            <Typography color="textSecondary">
              Congratulations on becoming Great Place to Work-Certified™! Now’s the time to
              celebrate and show off your accomplishment. Making time to celebrate your
              Certification helps build pride among employees. But it also helps you attract new
              talent and even new customers. Watch this 2 min video on the step by step process to
              promote and get results from your certification.
            </Typography>
          </Grid>
          <Grid sm={6} className={classes.leftPadding}>
            <iframe
              title="Certification Video"
              src="https://player.vimeo.com/video/307096604"
              width="100%"
              height="200"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid sm={6}>
            <img src={MarketingImage4} alt="Marketing" width={360} height={240} />
          </Grid>
          <Grid sm={6} className={classes.leftPadding}>
            <Typography variant="h4">Certification Usage Guideline</Typography>
            <br />
            <Typography color="textSecondary">
              Certified locations and companies earned the right to display the Great Place to Work
              Certification badge proudly, royalty-free for the next 12 months. When using the
              badge, you agree to our{' '}
              <ExternalNavLink
                label="Terms & Conditions."
                to="https://activatedinsights.com/terms"
              />
              &nbsp;Anytime the badge is used, there must be a consistent amount of space around the
              badge at all times to ensure readability and recognition of the logo.
            </Typography>
            <br />
            <ExternalNavLink
              label="Certification Usage Guidelines"
              to="https://www.greatplacetowork.com/certification-brand-guide"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid sm={6}>
            <Typography variant="h4">Update Your New Status on Social Media</Typography>
            <br />
            <Typography color="textSecondary">
              You may use the text below as social post examples to get you started, copy and paste
              them into any social post. Visit the Great Place to Work usage guidelines page to
              learn more about how to use your badge, and find more social media examples.
            </Typography>
          </Grid>
          <Grid sm={6} className={classes.leftPadding}>
            <img src={MarketingImage1} alt="Marketing" width={360} height={240} />
          </Grid>
          <div className={classes.textBoxContainer}>
            <Typography color="textSecondary">
              It’s official! We’re proud to be named a Great Place to Work Certified company
              @GPTW_US #gptw [Insert link to GPTW review site]
            </Typography>
            <Typography color="textSecondary">
              So proud to be named a Great Place to Work Certified company! @GPTW_US #gptw [Insert
              link to GPTW review site]
            </Typography>
            <Typography color="textSecondary">
              {' '}
              We just got certified as a Great Place to Work! Couldn’t be more proud of our team –
              we put our people first. @GPTW_US #gptw [Insert link to GPTW review site]
            </Typography>
          </div>
        </Grid>
        <Grid container>
          <Grid sm={6}>
            <Typography variant="h4">Promote Your Certification</Typography>
            <br />
            <Typography color="textSecondary">
              You may order custom products to promote your achievement. Many certified companies
              choose to order decals and buy Great Place to Work swag from their online store. All
              swag can be co-branded for your team. Or you can use your certification logo (from
              your Program Manager) and create your own items!
            </Typography>
            <br />
            <ExternalNavLink label="Visit the Online Store" to="https://f4c.store/gptw/" />
          </Grid>
          <Grid sm={6} className={classes.leftPadding}>
            <img src={MarketingImage6} alt="Marketing" width={360} height={240} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid sm={6}>
            <img src={MarketingImage2} alt="Marketing" width={360} height={240} />
          </Grid>
          <Grid sm={6} className={classes.leftPadding}>
            <Typography variant="h4">Send Out a Staff Email</Typography>
            <br />
            <Typography color="textSecondary">
              Encourage your employees to share pride in their workplace. Send a quick email asking
              staff to share your new Online Profile on their social media. Copy the text below to
              get started.
            </Typography>
            <br />
            <Button
              color="secondaryNoBackground"
              onClick={() => setEmailTextExpanded(!emailTextExpanded)}
            >
              View Email Copy {emailTextExpanded ? <ArrowUp /> : <ArrowDown />}
            </Button>
          </Grid>
          <Collapse in={emailTextExpanded} unmountOnExit>
            <div className={classes.textBoxContainer}>
              <Typography color="textSecondary">
                We're Certified! Email Announcement
                <br />
                Subject line: We're Great Place to Work-Certified™!
                <br />
                <br />
                Hi Team, Congratulations all around! We're now officially a Great Place to Work
                Certified company! Thank you for completing the Trust Index Survey, as sent by
                Activated Insights. Your hard work and dedication are critical to our getting
                recognized for our culture. We're also always looking to get better so please keep
                sharing your ideas on how to improve.
                <br />
                <br />
                Now it's time to share the news. If you'd like to tell your family and friends about
                our new status, you can share our Great Place to Work Review on your social
                channels.
                <br />
                <br />
                Visit Our Online Profile: [INSERT LINK]
                <br />
                Thank you again for everything you do to make this a great place to work!
                <br />
                <br />
                Best,
                <br />
                Sender
              </Typography>
            </div>
          </Collapse>
        </Grid>
        <Grid container>
          <Grid sm={6}>
            <Typography variant="h4">Change Your Email Signature</Typography>
            <br />
            <Typography color="textSecondary">
              Why not show off your Certification across all employee communications? Ask employees
              to add the Certification badge to their email signatures. Click on the link for step
              by step instructions. Gmail Instructions
            </Typography>
            <br />
            <ExternalNavLink
              label="Outlook Instructions"
              to="https://support.microsoft.com/en-us/office/create-and-add-a-signature-to-messages-8ee5d4f4-68fd-464a-a1c1-0e1c80bb27f2?ui=en-us&rs=en-us&ad=us"
            />
            <br />
            <br />
            <ExternalNavLink
              label="Gmail Instructions"
              to="https://support.google.com/mail/answer/8395"
            />
          </Grid>
          <Grid sm={6} className={classes.leftPadding}>
            <img src={MarketingImage5} alt="Marketing" width={360} height={240} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid sm={6}>
            <img src={MarketingImage3} alt="Marketing" width={360} height={240} />
          </Grid>
          <Grid sm={6} className={classes.leftPadding}>
            <Typography variant="h4">Publish a Press Release</Typography>
            <br />
            <Typography color="textSecondary">
              A press release is a great way to share your team’s success with a wider audience.
              It’s also a valuable way to attract prospective employees. You may use our template to
              get started, feel free to use the included quote verbatim.
            </Typography>
            <br />
            <Button
              color="secondaryNoBackground"
              onClick={() => {
                gaEvent({
                  action: 'downloadPressReleaseTemplate',
                  category: 'CertificationHub',
                })
                window.open(
                  'https://storage.googleapis.com/activated-production/templates/ActivatedInsights-MarketingToolkit-PressReleaseTemplate.docx',
                  '_blank',
                )
              }}
            >
              Download the Press Release Template (Word)
            </Button>
          </Grid>
        </Grid>
        <div>
          <Typography variant="h4">Download the Marketing Toolkit </Typography>
          <Button
            className={classes.submitButton}
            onClick={() => {
              gaEvent({
                action: 'downloadMarketingToolkitPdf',
                category: 'CertificationHub',
              })
              window.open(certificationMarketingToolkitUrl, '_blank')
            }}
          >
            Download Marketing Toolkit PDF
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MarketingToolkitView
