import React from 'react'

import Button from 'components/Blocks/CustomButtons/Button'
import Loader from 'components/Blocks/Loader'

const SubmitButton = props => {
  const { isSubmitting, disabled, children, buttonType, ...rest } = props
  return (
    <Button type={buttonType} disabled={disabled || isSubmitting} {...rest}>
      {isSubmitting ? <Loader size={19} /> : children}
    </Button>
  )
}
SubmitButton.defaultProps = {
  buttonType: 'submit',
}

export default SubmitButton
