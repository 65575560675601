import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import cn from 'classnames'

import { SurveyStatusEnum } from 'generated/graphql'

const useStyles = makeStyles(() => ({
  box: {
    width: 95,
    height: 80,
    display: 'grid',
    textAlign: 'center',
    alignItems: 'center',
  },
  draftSurvey: {
    backgroundColor: '#EEEFF1',
  },
  scheduledSurvey: {
    backgroundColor: '#FFF8EE',
  },
  liveSurvey: {
    backgroundColor: '#E4F3E5',
  },
  closedSurvey: {
    backgroundColor: '#B7C2C8',
  },
}))

type Props = {
  surveyStatus: SurveyStatusEnum
  numCompletedResponses: number
}
const CompletionRateBox: React.FC<Props> = ({ surveyStatus, numCompletedResponses }) => {
  const classes = useStyles()
  return (
    <div
      className={cn(classes.box, {
        [classes.draftSurvey]: surveyStatus === SurveyStatusEnum.DRAFT,
        [classes.scheduledSurvey]: surveyStatus === SurveyStatusEnum.SCHEDULED,
        [classes.liveSurvey]: surveyStatus === SurveyStatusEnum.LIVE,
        [classes.closedSurvey]: surveyStatus === SurveyStatusEnum.CLOSED,
      })}
    >
      <Typography variant="h6">{numCompletedResponses}</Typography>
    </div>
  )
}

export default CompletionRateBox
