import React, { useState } from 'react'

import { makeStyles, Typography, Paper } from '@material-ui/core'
import chunk from 'lodash/chunk'

import Carousel from 'components/Blocks/Carousel'
import Button from 'components/Blocks/CustomButtons/Button'
import RankedResponseRateModal from 'components/Survey/Wizard/Steps/Monitor/RankedResponseRateModal'
import { gaEvent } from 'config/ga'
import { InsightsFilter } from 'config/LocalStorage'
import { FilterTypeFragment, InsightsResponseRateReportQuery } from 'generated/graphql'
import { colors } from 'shared/theme'

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${palette.common.navy25}`,
  },
  highlightsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '95%',
  },
  seeMore: {
    padding: 0,
    margin: 0,
  },
  fullWidth: {
    width: '100%',
  },
  highlightBlock: {
    padding: spacing(3),
    '&>p': {
      '&:first-child': {
        textTransform: 'uppercase',
      },
      '&:nth-child(2)': {
        fontSize: '3.6rem',
      },
    },
  },
}))

type Props = {
  highlights: NonNullable<InsightsResponseRateReportQuery['responseRateReport']>['highlights']
  byClient: boolean
  eligibilityRate: number
  surveyName: string
  surveyUuid: string
  filters: FilterTypeFragment[]
  selectedFilters: Array<InsightsFilter>
  showSeeMore: boolean
}

const MonitorResponseHighlights: React.FC<Props> = ({
  highlights,
  byClient,
  eligibilityRate,
  surveyName,
  surveyUuid,
  filters,
  selectedFilters,
  showSeeMore,
}) => {
  const classes = useStyles()
  const pageSize = 4
  const [responseRateModalFilter, setResponseRateModalFilter] = useState<null | string>(null)
  const showCarousel = highlights.length > pageSize
  const highlightChunks = chunk(highlights, pageSize)
  const getResponseScoreColor = (rate: number) => {
    // Any group of responses that has a response rate >= the necessary survey response rate
    // for certification will be colored green.
    return rate >= eligibilityRate ? colors.success : colors.warning
  }
  return (
    <Paper square elevation={0} className={classes.container}>
      <div className={classes.fullWidth}>
        <Carousel showCarousel={showCarousel} numSteps={highlightChunks.length}>
          {highlightChunks.map((highlightChunk, chunkIdx) => {
            return (
              <div
                className={classes.highlightsContainer}
                key={
                  `chunk-${chunkIdx}` /** Index key is fine here since chunks are only separated by their index */
                }
              >
                {highlightChunk.map(
                  ({
                    filterTypeUuid,
                    filterTypeName,
                    topFilterValueNames,
                    topRate,
                    highlightType,
                  }) => {
                    return (
                      <div className={classes.highlightBlock} key={filterTypeName + highlightType}>
                        <Typography color="textSecondary" variant="body2">
                          {`${highlightType} response rate by ${filterTypeName}`}
                        </Typography>
                        <Typography style={{ color: getResponseScoreColor(Math.round(topRate)) }}>
                          {`${Math.round(topRate)}%`}
                        </Typography>
                        <span>{topFilterValueNames.join(', ')}</span>
                        <br />
                        {showSeeMore && (
                          <Button
                            className={classes.seeMore}
                            color="secondaryNoBackground"
                            onClick={() => {
                              gaEvent({
                                action: 'seeMoreResponseRate',
                                category: 'Surveys',
                              })
                              setResponseRateModalFilter(filterTypeUuid)
                            }}
                          >
                            See More
                          </Button>
                        )}
                      </div>
                    )
                  },
                )}
              </div>
            )
          })}
        </Carousel>
      </div>
      {Boolean(responseRateModalFilter) && (
        <RankedResponseRateModal
          onClose={() => setResponseRateModalFilter(null)}
          filterTypeUuid={responseRateModalFilter}
          filters={filters}
          byClient={byClient}
          selectedFilters={selectedFilters}
          surveyUuid={surveyUuid}
          surveyName={surveyName}
        />
      )}
    </Paper>
  )
}

export default MonitorResponseHighlights
