import React, { useState } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'

import CompletedActionItem from 'components/ActionPlans/CompletedActionItem'
import SortButton from 'components/Blocks/CustomButtons/SortButton'
import { StatementData } from 'components/Insights/TimeTrending/utils'
import { ActionPlansActionItemFragment, SurveyTypeEnum } from 'generated/graphql'
import { getCompletedDate } from 'utils/actionPlansUtils'
import { ORDER_TYPES } from 'utils/constants'
import { SurveyNodeAP as SurveyNode } from 'utils/types'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const getCombinedStatements = (
  actionItems: ActionPlansActionItemFragment[],
  employeeStatementsData: StatementData[],
  residentStatementsData: StatementData[],
) => {
  return [
    { data: uniqBy(employeeStatementsData, 'code'), surveyType: SurveyTypeEnum.TI },
    {
      data: uniqBy(residentStatementsData, 'code'),
      surveyType: SurveyTypeEnum.RESIDENT_ENGAGEMENT,
    },
  ]
    .map(({ data, surveyType }) =>
      data
        .map(({ companyOverallScores, inconsistentScores, negativeScores, code, delta }) => {
          return (
            actionItems
              // A statement can appear multiple times on the AP and so we have to return an Action Item for each
              .filter(actionItem => actionItem.statement?.code === code)
              .map(actionItem => {
                return {
                  surveyType,
                  actionItem,
                  completedDate: actionItem && getCompletedDate(actionItem),
                  positiveScores: companyOverallScores,
                  inconsistentScores,
                  negativeScores,
                  delta,
                }
              })
          )
        })
        .flat(),
    )
    .flat()
}

type Props = {
  actionItems: ActionPlansActionItemFragment[]
  employeeSurveys: SurveyNode[]
  residentSurveys: SurveyNode[]
  employeeStatementsData: StatementData[]
  residentStatementsData: StatementData[]
  targetUserUuid?: string
}

const CompletedActionItems: React.FC<Props> = ({
  actionItems,
  employeeSurveys,
  residentSurveys,
  employeeStatementsData,
  residentStatementsData,
  targetUserUuid,
}) => {
  const standardActionItems = actionItems.filter(({ statement }) => statement !== null)
  const customActionItems = actionItems.filter(({ statement }) => statement === null)
  const classes = useStyles()
  const availableSorts = ['newest', 'oldest']
  const [currentSort, setCurrentSort] = useState('newest')
  const statementsData = orderBy(
    getCombinedStatements(standardActionItems, employeeStatementsData, residentStatementsData),
    'completedDate',
    currentSort === 'newest' ? ORDER_TYPES.DESCENDING : ORDER_TYPES.ASCENDING,
  )
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <Typography variant="h5">Completed Statements</Typography>
        </div>
        <div>
          <SortButton
            availableOptions={availableSorts}
            currentOption={currentSort}
            onChange={sort => setCurrentSort(sort)}
            labelsMap={{
              newest: 'Newest to Oldest',
              oldest: 'Oldest to Newest',
            }}
          />
        </div>
      </div>
      {statementsData.map(
        (
          {
            surveyType,
            actionItem,
            completedDate,
            positiveScores,
            inconsistentScores,
            negativeScores,
            delta,
          },
          index,
        ) =>
          actionItem &&
          completedDate && (
            <CompletedActionItem
              key={index}
              actionItem={actionItem}
              completedDate={completedDate}
              surveys={surveyType === SurveyTypeEnum.TI ? employeeSurveys : residentSurveys}
              positiveScores={positiveScores}
              inconsistentScores={inconsistentScores}
              negativeScores={negativeScores}
              delta={delta}
              targetUserUuid={targetUserUuid}
            />
          ),
      )}
      {customActionItems.map((actionItem, index) => (
        <CompletedActionItem
          key={`custom${index}`}
          actionItem={actionItem}
          completedDate={getCompletedDate(actionItem)}
          surveys={[]}
          positiveScores={[]}
          inconsistentScores={[]}
          negativeScores={[]}
          delta={0}
          targetUserUuid={targetUserUuid}
        />
      ))}
    </div>
  )
}

export default CompletedActionItems
