import React from 'react'

const Barchart = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path d="M5 19V9h3v10zm5 0V5h3v14zm5 0v-6h3v6z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
)

export default Barchart
