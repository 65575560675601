import React from 'react'

import { Tooltip } from '@material-ui/core'
import ReactEcharts from 'echarts-for-react'
import { NavLink } from 'react-router-dom'

import { ResponseRateTypeEnum } from 'generated/graphql'
import { colors } from 'shared/theme'
import { surveyPage, SURVEY_URLS } from 'utils/constants'

interface Props {
  lessThanMin?: boolean
  includeLink?: boolean
  surveyUuid?: string
  rate: number
  height?: number
  width?: number
  showScore?: boolean
  chartColor?: string
  thin?: boolean
  includeLeftMargin?: boolean
  showReportBy?: ResponseRateTypeEnum
  minShowableResults: number
}

const ResponseRatePie: React.FC<Props> = ({
  lessThanMin,
  includeLink,
  surveyUuid,
  rate,
  height = 90,
  width = 90,
  showScore = true,
  thin = false,
  includeLeftMargin = false,
  chartColor,
  showReportBy,
  minShowableResults,
}) => {
  let text = ''
  if (showScore) {
    text = lessThanMin ? `<${minShowableResults}` : `${Math.round(rate)}%`
  }
  return (
    <NavLink to={includeLink && surveyUuid ? surveyPage(surveyUuid, SURVEY_URLS.MONITOR) : ''}>
      <Tooltip
        title={
          showReportBy === ResponseRateTypeEnum.CLIENT
            ? '% of all residents represented in the survey results'
            : '% of total participants surveyed'
        }
      >
        <div>
          <ReactEcharts
            notMerge
            style={{
              height,
              width,
              margin: includeLeftMargin ? 10 : 0,
            }}
            option={{
              title: [
                {
                  text,
                  target: 'self',
                  x: 'center',
                  y: 'center',
                  textStyle: {
                    color: lessThanMin ? colors.navy65 : colors.navy,
                    fontSize: 23,
                    fontWeight: 'normal',
                    fontFamily: 'YogaSansMedium',
                  },
                },
              ],
              color: [
                chartColor ||
                  (showReportBy === ResponseRateTypeEnum.CLIENT ? colors.secondary : colors.navy),
                colors.iceGrey,
              ],
              series: [
                {
                  name: 'Completion Rate',
                  type: 'pie',
                  silent: true,
                  radius: [thin ? '75%' : '65%', '90%'],
                  label: {
                    show: false,
                  },
                  data: [
                    { value: lessThanMin ? 0 : rate, name: 'Completed' },
                    { value: lessThanMin ? 100 : 100 - rate, name: 'Uncompleted' },
                  ],
                },
              ],
            }}
          />
        </div>
      </Tooltip>
    </NavLink>
  )
}

export default ResponseRatePie
