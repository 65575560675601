import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import cn from 'classnames'

import useInsightsStyles from 'components/Insights/InsightsStyle'
import OverallIndexGuage from 'components/Insights/Snapshot/OverallIndexGauge'
import { InsightsOverallScoreFragment } from 'generated/graphql'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    fontSize: '1.6rem',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  gaugeAndScore: {
    display: 'flex',
    alignItems: 'center',
  },
  breakdownList: {
    textAlign: 'left',
    padding: 0,
    listStyleType: 'none',
    fontSize: '1.2rem',
    '& li': {
      marginBottom: spacing(1),
    },
    '& li:last-child': {
      marginBottom: 0,
    },
  },
}))
type Props = {
  overallIndexScore: InsightsOverallScoreFragment
  title?: string
  inconsistentLabel?: string
  benchmarkName?: string
}
const OverallIndexGuageAndScore: React.FC<Props> = ({
  overallIndexScore,
  title = 'Trust Index Score',
  inconsistentLabel = 'Inconsistent',
  benchmarkName,
}) => {
  const { positive, inconsistent, negative, benchmarkPositive } = overallIndexScore
  const classes = { ...useInsightsStyles(), ...useStyles() }
  return (
    <>
      <Typography className={classes.title} variant="subtitle1">
        {title}
      </Typography>
      <div className={classes.gaugeAndScore}>
        <OverallIndexGuage
          height={125}
          width={155}
          positive={positive}
          inconsistent={inconsistent}
        />
        <div>
          <ul className={classes.breakdownList}>
            <li>
              <span className={cn(classes.dot, classes.dotPositive)} />
              Positive {Math.round(positive)}%{' '}
            </li>
            <li>
              <span className={cn(classes.dot, classes.dotInconsistent)} />
              {inconsistentLabel} {Math.round(inconsistent)}%{' '}
            </li>
            <li>
              <span className={cn(classes.dot, classes.dotNegative)} />
              Negative {Math.round(negative)}%{' '}
            </li>
            {benchmarkPositive && (
              <li>
                <span style={{ fontStyle: 'none', fontFamily: 'none' }}>- - </span>
                {benchmarkName} {Math.round(benchmarkPositive)}%{' '}
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  )
}

export default OverallIndexGuageAndScore
