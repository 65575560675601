import React, { useState } from 'react'

import { Typography, FormControl, Select, MenuItem, makeStyles } from '@material-ui/core'
import orderBy from 'lodash/orderBy'

import HorizontalBarChart, {
  CHILD_COLORS,
  formatChartData,
  SortOrder,
  TooltipSeries,
} from 'components/Blocks/Charts/HorizontalBarChart'
import HorizontalChartTooltip from 'components/Blocks/Charts/HorizontalChartTooltip'
import ModalChartContainer from 'components/Blocks/Dialogs/ModalChartContainer'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { InsightsBenchmark } from 'components/Insights/InsightsTypes'
import {
  useInsightsStatementsByDataTypesQuery,
  InsightsStatementsByDataTypesQuery,
  InsightsSurveyQuery,
  DataTypeCode,
} from 'generated/graphql'
import { truncateWithEllipsis } from 'utils'
import { DATA_TYPE_NAMES, ORDER_TYPES } from 'utils/constants'

const useStyles = makeStyles(({ spacing }) => ({
  chartTitle: {
    width: 600,
  },
  dropdownContainer: {
    display: 'flex',
    alignItems: 'center',
    '& >h6': {
      fontSize: '1.8rem',
    },
    '& >div': {
      marginLeft: spacing(1),
      marginRight: spacing(1),
      marginTop: 4,
    },
  },
}))
const STATEMENTS_LIMIT = 10
const getStatementsData = (
  data: InsightsStatementsByDataTypesQuery['insightsStatementsByDataTypes'],
  group: string,
  sortOrder: SortOrder,
) => {
  // Returns [[statementPostiveScores], [benchmarkPositiveScores]]
  const positiveScores = []
  const benchmarkPositiveScores = []
  const statementsList = data.find(d => d.group === group)?.statements
  if (!statementsList?.length) return [[], []]
  const order = sortOrder === SortOrder.HIGHEST ? ORDER_TYPES.DESCENDING : ORDER_TYPES.ASCENDING
  const orderedStatements = orderBy(statementsList, 'positive', order).slice(0, STATEMENTS_LIMIT)
  for (const stmt of orderedStatements) {
    positiveScores.push({ label: stmt.label, positive: stmt.positive })
    benchmarkPositiveScores.push({ label: stmt.label, positive: stmt.benchmarkPositive })
  }
  return [positiveScores, benchmarkPositiveScores]
}

type Props = {
  onClose(): void
  surveyName: string
  data: InsightsStatementsByDataTypesQuery['insightsStatementsByDataTypes']
  dtCode: DataTypeCode
  benchmarkName: string
  bottomDemographicGroup?: string
  dropdownTexts?: string[]
}
const charLimit = 30
const KeyDemographicsModalChart: React.FC<Props> = ({
  onClose,
  surveyName,
  data,
  dtCode,
  benchmarkName,
  bottomDemographicGroup,
  dropdownTexts = ['for'],
}) => {
  const classes = useStyles()
  const [sortOrder, setSortOrder] = useState(SortOrder.LOWEST)
  const [group, setGroup] = useState(bottomDemographicGroup || data[0].group)
  return (
    <ModalChartContainer
      id="key-demographics-modal-snap"
      onClose={onClose}
      surveyName={surveyName}
      dropdownRowComponent={
        <div className={classes.dropdownContainer}>
          <Typography color="textSecondary" variant="h6">
            See
          </Typography>
          <FormControl>
            <Select
              value={sortOrder}
              onChange={e => setSortOrder(e.target.value as SortOrder)}
              displayEmpty={false}
            >
              {Object.values(SortOrder).map(order => (
                <MenuItem key={order} value={order}>
                  {order} Statements
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography color="textSecondary" variant="h6">
            {dropdownTexts[0]}
          </Typography>
          <FormControl>
            <Select
              value={group}
              onChange={e => setGroup((e.target as HTMLInputElement).value)}
              displayEmpty={false}
              renderValue={selected => truncateWithEllipsis(selected as string, charLimit)}
            >
              {data
                .filter(groupScores => groupScores.statements.length)
                .map(groupScores => (
                  <MenuItem key={groupScores.group} value={groupScores.group}>
                    {groupScores.group}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {dropdownTexts.length > 1 && (
            <Typography color="textSecondary" variant="h6">
              {dropdownTexts[1]}
            </Typography>
          )}
        </div>
      }
      chartComponent={
        <>
          <Typography className={classes.chartTitle}>
            {DATA_TYPE_NAMES[dtCode]}: {group}
          </Typography>
          <HorizontalBarChart
            chartData={formatChartData(getStatementsData(data, group, sortOrder))}
            extraLegends={[
              {
                name: `${DATA_TYPE_NAMES[dtCode]}: ${truncateWithEllipsis(group, charLimit)}`,
                color: CHILD_COLORS[0],
              },
              { name: benchmarkName, color: CHILD_COLORS[1] },
            ]}
            pageSize={10 /** This number is halved since our data has 2 bars per row. */}
            yAxisLabelLength={38}
            chartWidth="100%"
            tooltipFormatter={(series: TooltipSeries) => {
              return (
                <HorizontalChartTooltip
                  title={series[0].data.name}
                  scores={[
                    {
                      name: `${DATA_TYPE_NAMES[dtCode]}: ${group}`,
                      value: series[0].data.value,
                    },
                    {
                      name: benchmarkName,
                      value: series[1].data.value,
                      lessThanMin: series[1].data.lessThanMin,
                    },
                  ]}
                />
              )
            }}
          />
        </>
      }
    />
  )
}

type ContainerProps = {
  onClose(): void
  survey: InsightsSurveyQuery['survey']
  dtCode: DataTypeCode
  filters: string[]
  benchmark: InsightsBenchmark
  bottomDemographicGroup?: string
  isMultiselectCode?: boolean
  dropdownTexts?: string[]
}
const DemographicModalChartContainer: React.FC<ContainerProps> = ({
  survey,
  filters,
  dtCode,
  isMultiselectCode,
  onClose,
  benchmark,
  bottomDemographicGroup,
  dropdownTexts,
}) => {
  const result = useInsightsStatementsByDataTypesQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      dataTypes: [{ dtCode, isMultiselectCode }],
      benchmark,
      includeCustom: true,
    },
  })
  return (
    <ResponseHandler {...result}>
      {({ insightsStatementsByDataTypes }) => {
        return (
          <KeyDemographicsModalChart
            onClose={onClose}
            surveyName={survey.name}
            data={insightsStatementsByDataTypes.filter(
              statementsByDataType => statementsByDataType.group,
            )}
            dtCode={dtCode}
            benchmarkName={benchmark.name}
            bottomDemographicGroup={bottomDemographicGroup}
            dropdownTexts={dropdownTexts}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default DemographicModalChartContainer
