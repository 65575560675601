import React from 'react'

import { makeStyles, Tooltip, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import QueryHandler from 'components/Blocks/Layout/QueryHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import { InsightsCultureDocument, InsightsModulesEnum } from 'generated/graphql'
import { colors } from 'shared/theme'
import { employeeExperienceStaticText } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const useCultureChartStyle = makeStyles(({ palette, spacing }) => ({
  dangerBox: {
    '& >rect': {
      fill: palette.common.danger,
    },
    '& >text': {
      fill: 'rgba(256, 256, 256, 0.76)',
    },
  },
  warningBox: {
    '& >rect': {
      fill: palette.common.navy,
    },
    '& >text': {
      fill: palette.common.white,
    },
  },
  successBox: {
    '& >rect': {
      fill: palette.common.success,
    },
    '& >text': {
      fill: palette.common.navy65,
    },
  },
  neutralBox: {
    '& >rect': {
      fill: palette.common.navy35,
    },
    '& >text': {
      fill: palette.common.navy65,
    },
  },
  highlights: {
    '& h3': {
      color: palette.common.navy,
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      color: palette.common.navy65,
    },
  },
  topGroups: {
    marginBottom: spacing(1),
  },
  topGroupsLabel: {
    color: palette.common.navy65,
    paddingRight: spacing(1),
  },
}))

const CultureBox = ({ title, score, benchmark, color, x, y }) => {
  const classes = useCultureChartStyle()

  return (
    <g className={classes[`${color}Box`]}>
      <rect x={x} y={y} width={170} height={170} />
      <text x={x + 16} y={y + 35} style={{ fontSize: 20 }}>
        {title}
      </text>
      <text x={x + 16} y={y + 115} style={{ fontSize: 36 }}>
        {score}%
      </text>
      {benchmark !== null && (
        <text x={x + 16} y={y + 150} style={{ fontSize: 16 }}>
          Benchmark: {benchmark}%
        </text>
      )}
    </g>
  )
}

CultureBox.defaultProps = {
  benchmark: null,
}

CultureBox.propTypes = {
  title: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  benchmark: PropTypes.number,
  color: PropTypes.oneOf(['danger', 'warning', 'success', 'neutral']).isRequired,
}

const TopBreakdown = ({ classes, top, url }) => {
  const groupedTop = {}
  top.map(t => {
    if (groupedTop[t.demographicCode]) {
      groupedTop[t.demographicCode].push(t)
    } else {
      groupedTop[t.demographicCode] = [t]
    }
    return t
  })
  if (!Object.keys(groupedTop).length) {
    return <></>
  }
  return (
    <div className={classes.eeDimensionBreakdown}>
      {Object.keys(groupedTop)
        .map(dCode => {
          const { demographic } = groupedTop[dCode][0]
          return (
            <span key={dCode}>
              <NavLink to={`${url}&y=${dCode}`}>{demographic}</NavLink>:{' '}
              {groupedTop[dCode]
                .map(seg => `${seg.value} (${Math.round(seg.score)}%)`)
                .join(' and ')}{' '}
            </span>
          )
        })
        .reduce((prev, curr) => [prev, ' | ', curr])}
    </div>
  )
}

const EmployeeExperienceCard = ({ survey, data, hasTimeTrending }) => {
  const text = employeeExperienceStaticText.default
  const wantComparisonUrl = `${getInsightsPage(
    survey.uuid,
    InsightsModulesEnum.COMPARISONS,
    survey.productType,
  )}?statement=ti_48&`
  const classes = useInsightsStyles()
  return (
    <div>
      <SnapshotChartHeader
        title={`Employees' Experience`}
        tooltip="Looking at whether employees want to stay long-term and are invested in your company culture is one of the best ways to gauge employees engagement. The bottom two quadrants show employees at risk of leaving your organization."
        hasTimeTrending={hasTimeTrending}
        timeTrendingChartKey={TimeTrendingChartKey.EMPLOYEE_CULTURE}
      />
      <GridContainer className={classes.eeWrapper}>
        <ItemGrid className={classes.eeLeftColumn} xs={12} sm={6}>
          <div className={classes.eeHighTrust}>
            <Typography color="textSecondary" variant="h6">
              {100 - Math.round(data.highTrust.score)}% of your team members experience a{' '}
              <i>lower-trust</i> workplace environment
            </Typography>
          </div>

          <Typography className={classes.eeHeader}>
            {Math.round(data.checkedOut.score)}% Checked Out
            <Tooltip title={text.checkedOut}>
              <InfoIcon />
            </Tooltip>
          </Typography>
          <TopBreakdown classes={classes} top={data.checkedOut.top} url={wantComparisonUrl} />

          <Typography className={classes.eeHeader}>
            {Math.round(data.wantsMore.score)}% Wants More
            <Tooltip title={text.wantsMore}>
              <InfoIcon />
            </Tooltip>
          </Typography>
          <TopBreakdown classes={classes} top={data.wantsMore.top} url={wantComparisonUrl} />

          <Typography className={classes.eeHeader}>
            {Math.round(data.highRisk.score)}% High Risk
            <Tooltip title={text.highRisk}>
              <InfoIcon />
            </Tooltip>
          </Typography>
          <TopBreakdown classes={classes} top={data.highRisk.top} url={wantComparisonUrl} />
        </ItemGrid>
        <ItemGrid className={classes.eeRightColumn} xs={12} sm={6}>
          <div className={classes.eeQuadWrapper}>
            <svg viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <marker
                  id="head"
                  orient="auto"
                  markerWidth="6"
                  markerHeight="4"
                  refX="0.1"
                  refY="2"
                >
                  <path d="M0,0 V4 L6,2 Z" fill={colors.navy65} />
                </marker>
              </defs>
              <line
                x1="15"
                y1="345"
                x2="15"
                y2="11"
                style={{ stroke: colors.navy65, strokeWidth: 2 }}
                markerEnd="url(#head)"
              />
              <text
                x="8"
                y="345"
                transform="rotate(-90, 8, 345)"
                style={{ fontSize: 12, fill: colors.navy65 }}
              >
                I want to work here for a long time.
              </text>
              <line
                x1="30"
                y1="365"
                x2="369"
                y2="365"
                style={{ stroke: colors.navy65, strokeWidth: 2 }}
                markerEnd="url(#head)"
              />
              <text x="30" y="380" style={{ fontSize: 12, fill: colors.navy65, width: 360 }}>
                Taking everything into account, I would say this is
              </text>
              <text x="30" y="395" style={{ fontSize: 12, fill: colors.navy65, width: 360 }}>
                a great place to work.
              </text>
              <CultureBox
                x={30}
                y={0}
                title="Checked Out"
                score={Math.round(data.checkedOut.score)}
                benchmark={data.checkedOutBenchmark}
                color="neutral"
              />
              <CultureBox
                x={210}
                y={0}
                title="High Trust"
                score={Math.round(data.highTrust.score)}
                benchmark={data.highTrustBenchmark}
                color="warning"
              />
              <CultureBox
                x={30}
                y={180}
                title="High Risk"
                score={Math.round(data.highRisk.score)}
                benchmark={data.highRiskBenchmark}
                color="danger"
              />
              <CultureBox
                x={210}
                y={180}
                title="Wants More"
                score={Math.round(data.wantsMore.score)}
                benchmark={data.wantsMoreBenchmark}
                color="neutral"
              />
            </svg>
          </div>
        </ItemGrid>
      </GridContainer>
    </div>
  )
}
EmployeeExperienceCard.propTypes = {
  survey: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  benchmark: PropTypes.object.isRequired,
  hasTimeTrending: PropTypes.bool.isRequired,
}

const EmployeeExperienceContainer = ({ survey, filters, benchmark, hasTimeTrending }) => {
  return (
    <QueryHandler
      query={InsightsCultureDocument}
      variables={{ surveyUuid: survey.uuid, filters, benchmark }}
    >
      {data => {
        return (
          <EmployeeExperienceCard
            survey={survey}
            data={data.culture}
            benchmark={benchmark}
            hasTimeTrending={hasTimeTrending}
          />
        )
      }}
    </QueryHandler>
  )
}

EmployeeExperienceContainer.propTypes = {
  survey: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  benchmark: PropTypes.object.isRequired,
  hasTimeTrending: PropTypes.bool.isRequired,
}

export default EmployeeExperienceContainer
