import React, { useState } from 'react'

import { List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import Accordion from 'components/Blocks/Accordion/Accordion'
import ExpandableListItem from 'components/Blocks/Accordions/ExpandableListItem'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import UpdatePersonalInfoQuestion from 'components/Survey/Wizard/Steps/Questions/UpdatePersonalInfoQuestion'
import UpdateQuestionContainer from 'containers/Survey/Wizard/Steps/Questions/UpdateQuestionContainer'
import { QCategory, QResidentFocus } from 'generated/graphql'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  questionInfoLabel: {
    color: palette.common.navy65,
    paddingLeft: spacing(),
  },
  questionList: {
    paddingLeft: spacing(8),
  },
  topLevelQuestionList: {
    listStyleType: 'decimal',
    paddingTop: 0,
    paddingBottom: 0,
    '& >li': {
      borderTop: `1px solid ${palette.common.navy25}`,
      paddingRight: spacing(10),
      '& >svg': {
        // List expand/collapse icon
        color: palette.common.secondary,
      },
    },
    '& >li:hover': {
      backgroundColor: palette.common.navy05,
    },
  },
  nonExpandableListItem: {
    paddingLeft: spacing(10),
    paddingRight: spacing(14),
  },
  questionRow: {
    marginRight: spacing(3),
  },
}))

export const QuestionText = ({
  index,
  question,
  category,
  handleOpenDeleteQuestion,
  surveyUuid,
  existingQuestions,
}) => {
  const classes = useStyles()
  const [questionToEdit, setQuestionToEdit] = useState(null)
  return (
    <div className={classes.root}>
      {questionToEdit && category !== QCategory.PERSONAL_INFO && (
        <UpdateQuestionContainer
          surveyUuid={surveyUuid}
          category={category}
          question={questionToEdit}
          onClose={() => setQuestionToEdit(null)}
          existingQuestions={existingQuestions}
        />
      )}
      {questionToEdit && category === QCategory.PERSONAL_INFO && (
        <UpdatePersonalInfoQuestion
          surveyUuid={surveyUuid}
          question={questionToEdit}
          onClose={() => setQuestionToEdit(null)}
        />
      )}
      <Typography component="span">
        {index && `${index}. `}
        {question.text}
        {question.infoLabel && <i className={classes.questionInfoLabel}>({question.infoLabel})</i>}
      </Typography>
      {question.isCustom && (
        <IconButton onClick={() => setQuestionToEdit(question)} color="secondaryHover">
          <EditIcon />
        </IconButton>
      )}
      {/* The second condition is required because of a glitch in the UI which will make the trash icons appear when updating a question's text */}
      {!question.required && question.category !== QCategory.PERSONAL_INFO && (
        <IconButton onClick={() => handleOpenDeleteQuestion(question.uuid)} color="dangerHover">
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  )
}

export const QuestionsAccordion = ({
  gq,
  handleOpenDeleteQuestion,
  surveyUuid,
  existingQuestions,
}) => {
  const classes = useStyles()

  return (
    <List className={classes.topLevelQuestionList} component="ol">
      {gq.questionsByFocus
        .flatMap(group => group.questions)
        .map((q, index) => (
          <React.Fragment key={q.uuid}>
            {q.choices && q.choices.length ? (
              <ExpandableListItem
                text={
                  <QuestionText
                    index={index + 1}
                    question={q}
                    category={gq.category}
                    surveyUuid={surveyUuid}
                    handleOpenDeleteQuestion={handleOpenDeleteQuestion}
                  />
                }
              >
                <List className={classes.questionList}>
                  {q.choices.map(c => (
                    <ListItem key={c.uuid}>{c.text}</ListItem>
                  ))}
                </List>
              </ExpandableListItem>
            ) : (
              <ListItem className={classes.nonExpandableListItem}>
                <ListItemText>
                  <QuestionText
                    index={index + 1}
                    existingQuestions={existingQuestions}
                    question={q}
                    category={gq.category}
                    surveyUuid={surveyUuid}
                    handleOpenDeleteQuestion={handleOpenDeleteQuestion}
                  />
                </ListItemText>
              </ListItem>
            )}
          </React.Fragment>
        ))}
    </List>
  )
}

export const QuestionsByFocusAccordion = ({
  handleOpenDeleteQuestion,
  category,
  questionsByFocus,
  focusTitleMap,
  surveyUuid,
  existingQuestions,
}) => {
  const classes = useStyles()

  return (
    <Accordion
      collapses={questionsByFocus.map(({ focus, questions, footer = null }) => {
        let title = 'Uncategorized'
        if (focus) {
          if (category === QCategory.STATEMENTS && focus === QResidentFocus.DEMOGRAPHICS) {
            title = 'Other'
          } else {
            title = focusTitleMap[focus]
          }
        }
        return {
          title,
          content: (
            <>
              {questions.map((question, index) => {
                return (
                  <ListItem key={question.uuid}>
                    <ListItemText className={classes.questionRow}>
                      <QuestionText
                        index={questions.length > 1 ? index + 1 : undefined}
                        surveyUuid={surveyUuid}
                        question={question}
                        category={category}
                        handleOpenDeleteQuestion={handleOpenDeleteQuestion}
                        existingQuestions={existingQuestions}
                      />
                    </ListItemText>
                  </ListItem>
                )
              })}
              {footer}
            </>
          ),
        }
      })}
    />
  )
}
