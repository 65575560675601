import React from 'react'

import { Typography } from '@material-ui/core'

import { CustomQuestionCardProps } from './types'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import echartTooltipBuilder from 'components/Insights/CustomSurvey/Cards/echartTooltipBuilder'
import useStyles from 'components/Insights/CustomSurvey/styles'
import useInsightsStyles, { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import { getViridisColors } from 'utils'

const MultipleChoiceCard: React.FC<CustomQuestionCardProps> = ({
  id,
  question,
  data: initialData,
  dataIsFiltered,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }

  // We don't want to display options without a value
  const data = initialData.filter(d => d.percent !== null)

  return (
    <FormPanel
      id={id}
      expanded
      gutterBottom={false}
      expandIcon={EXPAND_ICON.EXPAND}
      summary={<Typography>{question.text}</Typography>}
    >
      <div id={question.uuid} className={classes.pageBreak}>
        <div className={classes.actions}>
          <ScreenshotButton snapId={question.uuid} />
        </div>
        <PrintableEchart
          notMerge
          printWidth={300}
          option={{
            grid: { bottom: 100, top: 10, left: 150, right: 150 },
            textStyle: chartTextStyle,
            series: [
              {
                data,
                type: 'pie',
                hoverAnimation: false,
                name: dataIsFiltered ? 'Selected Filters' : 'Company Average',
                label: {
                  normal: {
                    position: 'inner',
                    formatter: (value: { data: CustomQuestionCardProps['data'][0] }) =>
                      `${value.data.percent}%`,
                    color: '#FFF',
                  },
                },
                stillShowZeroSum: false,
              },
            ],
            color: getViridisColors(data.length).map(c => c.p),
            radius: '65%',
            selectedMode: 'single',
            legend: {
              bottom: 0,
              left: 'center',
            },
            tooltip: {
              trigger: 'item',
              formatter: echartTooltipBuilder,
            },
          }}
        />
      </div>
    </FormPanel>
  )
}

export default MultipleChoiceCard
