import React, { useState } from 'react'

import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import EditableTagsText from 'components/Blocks/EditableTagsText'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import {
  useSurveysUpdateWelcomeMessagesMutation,
  SurveyTypeEnum,
  SurveysSurveyDocument,
  SurveysSurveyQuery,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { SURVEY_TEXT_KEYS } from 'utils/constants'
import { MERGE_TAGS_URL } from 'utils/documentationConstants'
import { getAvailableTagsForWelcomeMessage } from 'utils/templateTags'

const useStyles = makeStyles(({ palette, spacing }) => ({
  border: {
    border: `1px solid ${palette.common.navy25}`,
    borderRadius: 3,
  },
  inputRow: {
    marginTop: spacing(4),
  },
  helper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: spacing(),
  },
}))

interface Props {
  surveyUuid: string
  surveyType: SurveyTypeEnum
  productType: SurveyProductTypeEnum
  welcomeMessagesToUpdate: SurveysSurveyQuery['survey']['welcomeMessages']
  defaultWelcomeMessages: SurveysSurveyQuery['survey']['welcomeMessages']
  onClose(): void
}

const MAX_MESSAGE_LENGTH = 200

const UpdateWelcomeMessageDialog: React.FC<Props> = ({
  surveyUuid,
  surveyType,
  productType,
  welcomeMessagesToUpdate,
  defaultWelcomeMessages,
  onClose,
}) => {
  const availableTags = getAvailableTagsForWelcomeMessage(surveyType)

  const classes = useStyles()
  const [welcomeMessages, setWelcomeMessages] = useState(
    welcomeMessagesToUpdate.length > 0 ? welcomeMessagesToUpdate : defaultWelcomeMessages,
  )
  const [updateWelcomeMessages, { loading }] = useSurveysUpdateWelcomeMessagesMutation()
  const welcomeHeader = welcomeMessages.find(wm => wm.key === SURVEY_TEXT_KEYS.WELCOME_HEADER)
  const welcomeSubheader = welcomeMessages.find(wm => wm.key === SURVEY_TEXT_KEYS.WELCOME_SUBHEADER)
  const welcomeText = welcomeMessages.find(wm => wm.key === SURVEY_TEXT_KEYS.WELCOME_TEXT)
  if (!welcomeHeader || !welcomeText) return <></>

  const onChange = (key: string, text: string) => {
    setWelcomeMessages(
      welcomeMessages.map(wm =>
        wm.key === key
          ? {
              ...wm,
              text,
            }
          : wm,
      ),
    )
  }

  const resetToDefault = () => {
    setWelcomeMessages(
      welcomeMessages.map(wm => ({
        ...wm,
        text: defaultWelcomeMessages.find(({ key }) => key === wm.key)?.text || '',
      })),
    )
  }

  const saveWelcomeMessages = async () => {
    await updateWelcomeMessages({
      variables: {
        surveyUuid,
        welcomeMessages: welcomeMessages.map(wm => ({
          key: wm.key,
          text: wm.text,
        })),
      },
      refetchQueries: [
        {
          query: SurveysSurveyDocument,
          variables: { uuid: surveyUuid },
        },
      ],
    })
  }

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby="update-welcome-message-dialog"
    >
      <DialogTitle id="update-welcome-message-dialog">Edit Welcome Message</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item sm={3}>
            <Typography color="textSecondary">Header: </Typography>
          </Grid>
          <Grid item sm={9} className={classes.border}>
            <EditableTagsText
              key={welcomeHeader.key}
              availableTags={availableTags}
              value={welcomeHeader.text}
              setValue={(text: string) => onChange(welcomeHeader.key, text)}
            />
          </Grid>
        </Grid>
        {productType === SurveyProductTypeEnum.RESIDENT && welcomeSubheader && (
          <Grid container className={classes.inputRow}>
            <Grid item sm={3}>
              <Typography color="textSecondary">Subheader: </Typography>
            </Grid>
            <Grid item sm={9} className={classes.border}>
              <EditableTagsText
                key={welcomeSubheader.key}
                availableTags={availableTags}
                value={welcomeSubheader.text}
                setValue={(text: string) => onChange(welcomeSubheader.key, text)}
              />
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.inputRow}>
          <Grid item sm={3}>
            <Typography color="textSecondary">Welcome Text: </Typography>
          </Grid>
          <Grid item sm={9} className={classes.border}>
            <EditableTagsText
              key={welcomeText.key}
              availableTags={availableTags}
              value={welcomeText.text}
              setValue={(text: string) => onChange(welcomeText.key, text)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={3} />
          <Grid item sm={9}>
            {productType === SurveyProductTypeEnum.RESIDENT && (
              <Typography color="textSecondary" variant="body2">
                {MAX_MESSAGE_LENGTH - welcomeText.text.length} characters available.
              </Typography>
            )}
            <div className={classes.helper}>
              <Typography variant="body2" color="textSecondary">
                {'Need help understanding {{tags}}?'}
                <ExternalNavLink to={MERGE_TAGS_URL} label=" Learn more >" />
              </Typography>
              <NavLink to="#" onClick={resetToDefault}>
                <Typography variant="body2">Reset to default</Typography>
              </NavLink>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setWelcomeMessages(welcomeMessagesToUpdate)
            onClose()
          }}
          color="secondaryNoBackground"
        >
          Cancel
        </Button>
        <SubmitButton
          buttonType="button"
          isSubmitting={loading}
          disabled={
            productType === SurveyProductTypeEnum.RESIDENT &&
            welcomeText.text.length > MAX_MESSAGE_LENGTH
          }
          color="primary"
          onClick={() => {
            saveWelcomeMessages()
            onClose()
          }}
        >
          Save
        </SubmitButton>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateWelcomeMessageDialog
