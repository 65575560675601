import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import ReactDOMServer from 'react-dom/server'

import { colors } from 'shared/theme'

const styles = theme => ({
  container: {
    display: 'block',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& >div': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    fontVariantNumeric: 'lining-nums',
  },
  leftColumn: {
    display: 'inline-block',
  },
  rightColumn: {
    display: 'inline-block',
    paddingLeft: 12,
  },
  divider: {
    borderBottom: `1px solid ${colors.navy25}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

/**
 * Renders groups of rows for an EchartsTooltip
 * Row groups are separated by a divider
 * Left column contains list of [label]
 * Right column contains a list [[value, color]]
 */
const EchartTooltip = ({ rowGroups, classes }) => (
  <div className={classes.container}>
    {rowGroups.map(({ leftColumn, rightColumn }, index) => (
      // Use grouped rows to insert a divider in between.
      <React.Fragment key={index}>
        <div className={classes.leftColumn}>
          {leftColumn.map(value => (
            <div key={value}>{value}: </div>
          ))}
        </div>
        <div className={classes.rightColumn}>
          {rightColumn.map(([value, color]) => (
            <div key={value} style={{ color }}>
              {value}
            </div>
          ))}
        </div>
        {index < rowGroups.length - 1 && <div className={classes.divider} />}
      </React.Fragment>
    ))}
  </div>
)

EchartTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  rowGroups: PropTypes.arrayOf(
    PropTypes.shape({
      leftColumn: PropTypes.arrayOf(PropTypes.string),
      rightColumn: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    }),
  ).isRequired,
}

const StyledTooltip = withStyles(styles)(EchartTooltip)

export const renderEchartTooltip = component => {
  // The argument for Echart tooltips is a static HTML string.
  return ReactDOMServer.renderToStaticMarkup(component)
}

const tooltipBuilder = rowGroups => {
  return renderEchartTooltip(<StyledTooltip rowGroups={rowGroups} />)
}

export default tooltipBuilder
