import React, { ReactElement } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    textAlign: 'center',
    padding: 60,
  },
  containerBackground: {
    backgroundColor: palette.common.iceGrey50,
  },
  title: {
    fontSize: 28,
  },
  description: {
    marginLeft: '32%',
    marginRight: '32%',
  },
  largeDescription: {
    marginLeft: '15%',
    marginRight: '15%',
  },
  icon: {
    margin: spacing(3),
  },
}))

interface Props {
  title: string
  description: string | ReactElement
  Icon: Function
  iconClassName?: string
  withBackground?: boolean
  withLargeDescription?: boolean
}

const EmptyState: React.FC<Props> = ({
  title,
  description,
  Icon,
  iconClassName,
  withBackground = false,
  withLargeDescription = false,
}) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.container, { [classes.containerBackground]: withBackground })}>
      <Typography className={classes.title}>{title}</Typography>
      <Icon className={cn(classes.icon, iconClassName)} />
      <Typography
        color="textSecondary"
        className={withLargeDescription ? classes.largeDescription : classes.description}
      >
        {description}
      </Typography>
    </div>
  )
}

export default EmptyState
