import React from 'react'

import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  grid: {
    padding: '0 15px !important',
  },
}

function ItemGrid({ ...props }) {
  const { classes, children, className, ...rest } = props
  return (
    <Grid item {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </Grid>
  )
}

ItemGrid.defaultProps = {
  className: '',
}

export default withStyles(styles)(ItemGrid)
