import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import { format } from 'date-fns'

import StatusTag from 'components/Blocks/Tags/StatusTag'
import InfoCard from 'components/Insights/Blocks/InfoCard'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import { SurveyDistributionTypeEnum } from 'generated/graphql'

const useStyles = makeStyles(({ spacing }) => ({
  surveyName: {
    display: 'flex',
    alignItems: 'center',
    '& >div': {
      marginLeft: spacing(2),
    },
  },
}))

type Props = {
  survey: InsightsSurvey
}
const SummaryCard: React.FC<Props> = ({ survey }) => {
  const classes = useStyles()
  // TODO: check open survey links and add link to survey when available
  const summaryInfoRows = [
    {
      label: 'Survey Name:',
      value: (
        <div className={classes.surveyName}>
          <Typography className={classes.surveyName}>{survey.name}</Typography>
          <StatusTag
            status={survey.status}
            isOpenLinkSurvey={survey.distributionType === SurveyDistributionTypeEnum.OPEN}
          />
        </div>
      ),
    },
    { label: 'Survey Start:', value: format(new Date(survey.startDate), 'LLL dd, yyyy h:mm a') },
    {
      label: 'Survey End:',
      value: survey.endDate
        ? format(new Date(survey.endDate), 'LLL dd, yyyy h:mm a')
        : 'Open Ended',
    },
  ]
  return <InfoCard title="SUMMARY" rows={summaryInfoRows} />
}

export default SummaryCard
