import React, { useState } from 'react'

import { makeStyles, Typography, FormControl, Select, MenuItem } from '@material-ui/core'

import HorizontalBarChart, {
  formatChartData,
  SortOrder,
} from 'components/Blocks/Charts/HorizontalBarChart'
import ModalChartContainer from 'components/Blocks/Dialogs/ModalChartContainer'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsFilter } from 'config/LocalStorage'
import {
  useInsightsResponseRateByFilterTypeQuery,
  RankByEnum,
  FilterTypeFragment,
} from 'generated/graphql'

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    height: 540,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${palette.common.navy15}`,
  },
  titleSection: {
    padding: spacing(3),
  },
  select: {
    '& >div': {
      fontSize: '2.4rem',
    },
  },
  surveyName: {
    paddingTop: spacing(2),
    '& >span': {
      color: palette.common.navy65,
    },
  },
  chart: {
    marginLeft: spacing(3),
    marginTop: spacing(2),
  },
  sideButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& >div': {
      marginBottom: spacing(),
    },
  },
}))

type ScoreData = { name: string; rate: number }
type Props = {
  onClose(): void
  surveyUuid: string
  surveyName: string
  selectedFilters: Array<InsightsFilter>
  filters: FilterTypeFragment[]
  filterTypeUuid: string | null
  byClient: boolean
}

const RankedResponseRateModal: React.FC<Props> = ({
  onClose,
  filters,
  surveyUuid,
  surveyName,
  selectedFilters,
  filterTypeUuid: initialFilterType,
  byClient,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [filterTypeUuid, setFilterTypeUuid] = useState<string>(
    initialFilterType || filters[0].filterTypeUuid,
  )
  const [sortOrder, setSortOrder] = useState(SortOrder.HIGHEST)
  const variables = {
    surveyUuid,
    filterTypeUuid,
    filters: selectedFilters.map(f => f.valueUuid),
    rankBy: RankByEnum.POSITIVE,
    byClient,
  }
  const result = useInsightsResponseRateByFilterTypeQuery({ variables })
  return (
    <ResponseHandler {...result}>
      {data => {
        if (!data.responseRateByFilterType) return <div />
        const chartData = data.responseRateByFilterType
          .filter((d): d is ScoreData => d !== null)
          .map(d => ({
            label: d.name,
            positive: d.rate,
          }))
        return (
          <ModalChartContainer
            id="ranked-response-rate-modal"
            surveyName={surveyName}
            onClose={onClose}
            dropdownRowComponent={
              <>
                <Typography color="textSecondary" variant="h6">
                  See
                </Typography>
                <FormControl>
                  <Select
                    className={classes.select}
                    value={filterTypeUuid}
                    onChange={e => setFilterTypeUuid((e.target as HTMLInputElement).value)}
                    displayEmpty={false}
                    name="responseRateFilterType"
                  >
                    {filters.map(ft => (
                      <MenuItem key={ft.filterTypeUuid} value={ft.filterTypeUuid}>
                        {ft.namePlural}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography color="textSecondary" variant="h6">
                  sorted by
                </Typography>
                <FormControl>
                  <Select
                    className={classes.select}
                    value={sortOrder}
                    onChange={e => setSortOrder(e.target.value as SortOrder)}
                    displayEmpty={false}
                    name="responseRateSortOrder"
                  >
                    {Object.values(SortOrder).map(order => (
                      <MenuItem key={order} value={order}>
                        {order}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="h6">Response Rate</Typography>
              </>
            }
            chartComponent={
              <HorizontalBarChart chartData={formatChartData([chartData], sortOrder)} />
            }
          />
        )
      }}
    </ResponseHandler>
  )
}

export default RankedResponseRateModal
