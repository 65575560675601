import React from 'react'

import { Typography, Tooltip, makeStyles } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import {
  InsightsModulesEnum,
  InsightsSurveyQuery,
  OrganizationSolutionFragment,
  OrganizationResidentSolutionFragment,
} from 'generated/graphql'
import { CONTACT_EMAIL, RESPONSE_TYPES } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(theme => ({
  seeMore: {
    position: 'absolute',
    bottom: theme.spacing(4),
    left: '10%',
    fontSize: '1.4rem',
  },
}))
type Props = {
  solution: OrganizationSolutionFragment | OrganizationResidentSolutionFragment
  survey: InsightsSurveyQuery['survey']
  responseType: RESPONSE_TYPES
}
const SeeMore: React.FC<Props> = ({ solution, survey, responseType }) => {
  const classes = useStyles()
  return solution.insightsStatements ? (
    <NavLink
      className={classes.seeMore}
      to={`${getInsightsPage(
        survey.uuid,
        InsightsModulesEnum.STATEMENTS,
        survey.productType,
      )}?initialSort=highToLow&responseTypes=${responseType}`}
    >
      View All
    </NavLink>
  ) : (
    <Tooltip
      title={`To learn more about how to see results for all statements contact ${CONTACT_EMAIL}`}
    >
      <Typography className={classes.seeMore}>View All</Typography>
    </Tooltip>
  )
}

export default SeeMore
