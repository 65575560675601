import React from 'react'

import { Typography, Tooltip } from '@material-ui/core'

import Accordion from 'components/Blocks/Accordion/Accordion'
import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import Checkbox from 'components/Blocks/Checkboxes/Checkbox'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import {
  BenchmarkCodeType,
  SurveysSurveyQuery,
  SurveysQuestionsQuery,
  useSurveysAvailableSurveyQuestionsQuery,
  useSurveysBulkSetQuestionMutation,
  SurveysQuestionsDocument,
} from 'generated/graphql'
import { handleMutationResponse } from 'utils'
import { CONTACT_EMAIL } from 'utils/constants'

const MARKETING_ADDON_QUESTIONS_CODES = [
  BenchmarkCodeType.TESTIMONIALS_APPROVAL,
  // Don't include next codes since they are a children of first and we don't want to display them
  // BenchmarkCodeType.TESTIMONIALS_PRIVACY,
  // BenchmarkCodeType.TESTIMONIALS_REVIEW,
  BenchmarkCodeType.ONLINE_REVIEW,
]

type Props = {
  survey: SurveysSurveyQuery['survey']
  surveyQuestions: SurveysQuestionsQuery['questions']
}

const MarketingAddons: React.FC<Props> = ({ survey, surveyQuestions }) => {
  const [bulkSetQuestions] = useSurveysBulkSetQuestionMutation()
  const result = useSurveysAvailableSurveyQuestionsQuery({
    variables: {
      surveyUuid: survey.uuid,
    },
  })

  const getTextByCode = (code: BenchmarkCodeType, includeForUsNews: boolean) => {
    if (code === BenchmarkCodeType.TESTIMONIALS_APPROVAL) {
      let disabledText = `To learn more about collecting marketing testimonials contact ${CONTACT_EMAIL}`
      if (includeForUsNews) {
        disabledText = "This question can't be removed."
      }
      return {
        header: 'Marketing Testimonials',
        confirmation: 'Yes, add Marketing Testimonial question',
        disabledText,
      }
    }
    if (code === BenchmarkCodeType.ONLINE_REVIEW) {
      return {
        header: 'Online Reviews',
        confirmation: 'Yes, add Online Review question',
        disabledText: `To learn more about online reviews contact ${CONTACT_EMAIL}`,
      }
    }
    return {
      header: '',
      confirmation: '',
      disabledText: '',
    }
  }

  const onSubmit = async (selectedQuestionUuids: string[], unselectedQuestionUuids: string[]) => {
    const setResult = await bulkSetQuestions({
      variables: {
        surveyUuid: survey.uuid,
        selectedQuestionUuids,
        unselectedQuestionUuids,
      },
      refetchQueries: [
        {
          query: SurveysQuestionsDocument,
          variables: {
            surveyUuid: survey.uuid,
          },
        },
      ],
    })
    handleMutationResponse(setResult.data?.bulkSetSurveyQuestion?.errors)
  }

  return (
    <ResponseHandler {...result}>
      {({ availableSurveyQuestions }) => {
        const surveyQuestionsUuids = surveyQuestions.map(q => q.uuid)
        const availableMarketingAddonQuestions = availableSurveyQuestions.filter(
          question =>
            question.benchmarkCode &&
            MARKETING_ADDON_QUESTIONS_CODES.includes(question.benchmarkCode),
        )
        if (availableMarketingAddonQuestions.length === 0) return <div />
        return (
          <FormPanel
            removeDetailsPadding
            expandIcon={EXPAND_ICON.ARROW}
            gutterBottom
            summary={
              <GridContainer>
                <ItemGrid sm={4}>
                  <Typography variant="h6">Marketing Add-ons</Typography>
                </ItemGrid>
                <ItemGrid sm={8}>
                  <Typography color="textSecondary">
                    You can use this survey to support your marketing efforts. Ask participants to
                    leave a testimonial while they are filling out the survey. You can also prompt
                    them to leave an online review when they're done.
                  </Typography>
                </ItemGrid>
              </GridContainer>
            }
          >
            <Accordion
              collapses={availableMarketingAddonQuestions.map(question => {
                if (!question.benchmarkCode) return { title: '', content: null }
                const checked = surveyQuestionsUuids.includes(question.uuid)
                const { disabledText, header, confirmation } = getTextByCode(
                  question.benchmarkCode,
                  survey.includeForUsNews,
                )
                return {
                  title: header,
                  content: (
                    <>
                      <Typography>{question.text}</Typography>
                      <Tooltip
                        title={question.isDisabled ? disabledText : ''}
                        placement="top-start"
                      >
                        <div>
                          <Checkbox
                            disabled={question.isDisabled}
                            checked={checked}
                            onChange={() =>
                              onSubmit(
                                checked ? [] : [question.uuid],
                                checked ? [question.uuid] : [],
                              )
                            }
                            label={confirmation}
                          />
                        </div>
                      </Tooltip>
                    </>
                  ),
                }
              })}
            />
          </FormPanel>
        )
      }}
    </ResponseHandler>
  )
}

export default MarketingAddons
