import React, { useState } from 'react'

import { makeStyles, Typography, Paper, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import cn from 'classnames'

import SimpleSearch from 'components/Blocks/Search/SimpleSearch'

const useStyles = makeStyles(({ spacing }) => ({
  searchPaperWrapper: {
    height: 50,
    padding: `0 ${spacing(2)}px`,
    position: 'absolute',
    bottom: -45,
    right: 0,
    zIndex: 3,
  },
  searchButton: {
    display: 'flex',
    alignItems: 'center',
    '& >svg': {
      marginLeft: 10,
    },
  },
}))
// TODO: convert SimpleSearch to tsx so we can reuse props here

type Props = {
  handleSearch(query: string): void
  searchQuery: string
  className?: string
  debounceTime?: number
  showButtonText?: boolean
}
const SearchPopup: React.FC<Props> = ({
  handleSearch,
  searchQuery,
  className,
  debounceTime,
  showButtonText = true,
}) => {
  const [searchOpen, setSearchOpen] = useState(false)
  const classes = useStyles()
  return (
    <>
      <IconButton
        id="search-button"
        onClick={() => {
          setSearchOpen(!searchOpen)
        }}
      >
        {showButtonText ? (
          <Typography className={cn(classes.searchButton, className)}>
            Search
            <SearchIcon />
          </Typography>
        ) : (
          <SearchIcon />
        )}
      </IconButton>
      {searchOpen && (
        <Paper className={classes.searchPaperWrapper} id="search-input">
          <SimpleSearch
            withSearchAdornment
            disableUnderline
            query={searchQuery}
            handleClose={() => setSearchOpen(false)}
            handleSearch={handleSearch}
            debounceTime={debounceTime}
          />
        </Paper>
      )}
    </>
  )
}

export default SearchPopup
