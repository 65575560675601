import React, { ReactElement } from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import cn from 'classnames'

import useInsightsStyles from 'components/Insights/InsightsStyle'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: spacing(3),
    paddingLeft: spacing(3),
  },
  score: {
    paddingBottom: spacing(1),
  },
  description: {
    '& >p': {
      lineHeight: 1.25,
    },
  },
}))

type Props = {
  title: string
  titleColor: string
  score: string
  extraDescription?: ReactElement
}
const InsightsOverviewRightDetail: React.FC<Props> = ({
  title,
  titleColor,
  score,
  extraDescription,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <div className={cn(classes.halfRight, classes.container)}>
      <Typography style={{ color: titleColor }} variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="h4" className={classes.score}>
        {score}
      </Typography>
      {extraDescription && <div className={classes.description}>{extraDescription}</div>}
    </div>
  )
}

export default InsightsOverviewRightDetail
