import React, { useState } from 'react'

import { Typography, FormControl, Select, MenuItem } from '@material-ui/core'

import { SortOrder } from 'components/Blocks/Charts/HorizontalBarChart'
import ModalChartContainer from 'components/Blocks/Dialogs/ModalChartContainer'
import ResidentBreakdownChart from 'components/Insights/ResidentSnapshot/ResidentBreakdownChart'
import { StatementsByService } from 'components/Insights/ResidentSnapshot/ServicesChart'
import { FilterTypeFragment } from 'generated/graphql'

type Props = {
  onClose(): void
  surveyUuid: string
  surveyName: string
  selectedFilters: string[]
  visibleFilterTypes: FilterTypeFragment[]
  statementsByService: StatementsByService
  sortedServiceLabels: string[] // Maintain the order of services in the dropdown as was used in the snapshot chart.
  openedToService: string | null
  overallPositiveScore: number
  benchmarkScores: { label: string; positive?: number }[]
  benchmarkName: string
}
const ServicesBreakdownChartModal: React.FC<Props> = props => {
  const {
    surveyName,
    visibleFilterTypes: filterTypes,
    statementsByService,
    sortedServiceLabels,
    openedToService,
    onClose,
    benchmarkScores,
    ...restProps
  } = props
  const [dtCode, setDtCode] = useState<string>(filterTypes[0].dtCode)
  const [selectedService, setSelectedService] = useState<string>(
    openedToService || sortedServiceLabels[0],
  )
  const [sortOrder, setSortOrder] = useState(SortOrder.HIGHEST)
  return (
    <ModalChartContainer
      id="services-breakdown-snap"
      surveyName={surveyName}
      onClose={onClose}
      dropdownRowComponent={
        <>
          <Typography color="textSecondary" variant="h6">
            See
          </Typography>
          <FormControl>
            <Select
              value={selectedService}
              onChange={e => setSelectedService((e.target as HTMLInputElement).value)}
              displayEmpty={false}
              name="service"
            >
              {sortedServiceLabels.map(serviceCode => (
                <MenuItem key={serviceCode} value={serviceCode}>
                  {serviceCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography color="textSecondary" variant="h6">
            by
          </Typography>
          <FormControl>
            <Select
              value={dtCode}
              onChange={e => setDtCode((e.target as HTMLInputElement).value)}
              displayEmpty={false}
              name="breakdownFilterType"
            >
              {filterTypes.map(ft => (
                <MenuItem key={ft.dtCode} value={ft.dtCode}>
                  {ft.namePlural}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography color="textSecondary" variant="h6">
            sorted by
          </Typography>
          <FormControl>
            <Select
              value={sortOrder}
              onChange={e => setSortOrder(e.target.value as SortOrder)}
              displayEmpty={false}
              name="breakdownSortOrder"
            >
              {Object.values(SortOrder).map(order => (
                <MenuItem key={order} value={order}>
                  {order}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6">Engagement Score</Typography>
        </>
      }
      chartComponent={
        <>
          <ResidentBreakdownChart
            dtCode={dtCode}
            statementCodes={statementsByService[selectedService].map(s => s.code)}
            sortOrder={sortOrder}
            benchmarkPositive={
              benchmarkScores.find(score => score.label === selectedService)?.positive || 0
            }
            {...restProps}
          />
        </>
      }
    />
  )
}

export default ServicesBreakdownChartModal
