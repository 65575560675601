import React, { useState, useEffect } from 'react'

import { useApolloClient } from '@apollo/client'
import { makeStyles, Typography } from '@material-ui/core'
import { QRCodeCanvas } from 'qrcode.react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import InformLettersControl from './InformLettersControl'
import { Type, Notification, DisplayInfo, BooleanEnum } from './InformLettersEnum'

import { ReactComponent as PulseIcon } from 'assets/img/hcp-pinnacle-ai-strip.svg'
import smilingOnPhone2 from 'assets/img/smiling-on-phone-2.png'
import smilingOnPhone from 'assets/img/smiling-on-phone.png'
import Page from 'components/Blocks/Layout/Page'
import { CurrentUserDocument, CurrentUserQuery, FilterValueType } from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import { URLS, SUPPORT_EMAIL } from 'utils/constants'

const useStyles = makeStyles(({ palette, spacing }) => ({
  '@global': {
    '@media print': {
      'body *': {
        visibility: 'hidden',
      },
      '.printable, .printable *': {
        visibility: 'visible',
      },
      '.printable': {
        position: 'absolute',
        top: 0,
        left: -10,
        width: '100%',
      },
    },
  },
  container: {
    padding: spacing(4),
    backgroundColor: palette.background.paper,
  },
  locationDetails: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
  },
  logo: {
    marginRight: spacing(2),
    height: '60px',
  },
  content: {
    textAlign: 'center',
    margin: '20px 0',
    color: palette.text.secondary,
  },
  htmlContent: {
    position: 'relative',
    padding: spacing(4),
  },
  topLeftCorner: {
    textAlign: 'left',
    marginBottom: spacing(2),
  },
  moreInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacing(2),
  },
  moreInfoBlock: {
    width: '30%',
  },
  qrCodeSection: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: spacing(4),
    padding: spacing(2),
    backgroundColor: 'transparent',
  },
  qrCodeItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '30%',
    position: 'relative',
  },
  qrCodeItem: {
    width: '100%',
    height: '230px',
    backgroundColor: palette.secondary.main,
    padding: spacing(2),
    borderRadius: '8px',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  stepNumber: {
    position: 'absolute',
    top: '-35px',
    left: '10px',
    fontSize: '1.5em',
    fontWeight: 'bold',
    color: palette.secondary.main,
  },
  qrCode: {
    backgroundColor: 'white',
    padding: spacing(1),
    borderRadius: '8px',
  },
  qrCodeAddContact: {
    color: 'white',
    fontWeight: 'bold',
  },
  contactDetails: {
    backgroundColor: 'white',
    padding: spacing(-1),
    borderRadius: '8px',
    width: '90%',
    textAlign: 'center',
    color: palette.secondary.main,
  },
  saveButton: {
    backgroundColor: palette.secondary.main,
    color: 'white',
    borderRadius: '15px',
    padding: spacing(1, 4),
    marginTop: spacing(1),
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: 'bold',
    border: `2px solid ${palette.common.white}`,
  },
  avatarCircle: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: palette.secondary.main,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '1.5em',
    border: `2px solid ${palette.common.white}`,
    marginBottom: spacing(1),
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
  printable: {
    visibility: 'visible',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    margin: '20px 0',
    position: 'absolute',
    bottom: spacing(-3),
    right: spacing(5),
  },
  image: {
    width: '150px',
    margin: '20px 0',
  },
  contactInfo: {
    margin: '20px auto',
    padding: '20px',
    border: `2px solid ${palette.secondary.main}`,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '65%',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: spacing(1),
  },
}))

type Props = {}

const InformLettersContainer: React.FC<Props> = () => {
  const classes = useStyles()
  const client = useApolloClient()
  const [showContent, setShowContent] = useState(false)
  const [type, setType] = useState<Type>(Type.CUSTOMER)
  const [location, setLocation] = useState<string>('')
  const [logo, setLogo] = useState<BooleanEnum>(BooleanEnum.FALSE)
  const [showAddress, setShowAddress] = useState<BooleanEnum>(BooleanEnum.TRUE)
  const [notificationType, setNotificationType] = useState<Notification>(Notification.DIGITAL)
  const [displayMore, setDisplayMore] = useState<DisplayInfo>(DisplayInfo.LESS)
  const [useQrCode, setUseQrCode] = useState<BooleanEnum>(BooleanEnum.FALSE)
  const [selectedLocationDetails, setSelectedLocationDetails] = useState<FilterValueType | null>(
    null,
  )
  const { currentUser } = client.readQuery({ query: CurrentUserDocument }) as CurrentUserQuery

  const email = SUPPORT_EMAIL
  const orgPhone = currentUser.organization?.vicidialDid || ''

  const vCardData = `
BEGIN:VCARD
VERSION:3.0
FN:Activated Insights
ORG:Activated Insights
TEL;TYPE=WORK,VOICE:${orgPhone}
EMAIL;TYPE=PREF,INTERNET:${email}
END:VCARD
`.trim()

  const defaultValues = {
    type,
    location,
    logo,
    showAddress,
    notificationType,
    displayMore,
    useQrCode,
  }

  useEffect(() => {
    if (location) {
      setShowContent(true)
    }
  }, [location])

  const getNotificationMessage = () => {
    if (notificationType === Notification.DIGITAL) {
      return 'You will receive an email from'
    }
    if (notificationType === Notification.PHONE) {
      return 'You will receive a call from'
    }
    return 'You will receive a call or email from'
  }

  const getTextNotificationMessage = () => {
    if (
      notificationType === Notification.DIGITAL ||
      notificationType === Notification.PHONE_AND_DIGITAL
    ) {
      return 'You may also receive a text message from our network'
    }
    return ''
  }

  return (
    <Page>
      <BreadcrumbsItem to={URLS.INFORM_LETTERS}>Inform Letters</BreadcrumbsItem>
      <div className={classes.container}>
        <div className={classes.noPrint}>
          <InformLettersControl
            onTypeChange={setType}
            onLocationChange={setLocation}
            onLogoChange={setLogo}
            onShowAddressChange={setShowAddress}
            onNotificationTypeChange={setNotificationType}
            onDisplayMoreChange={setDisplayMore}
            onUseQrCodeChange={setUseQrCode}
            setSelectedLocationDetails={setSelectedLocationDetails}
            defaultValues={defaultValues}
          />
        </div>
        {showContent && (
          <div className="printable">
            <div className={classes.htmlContent}>
              <div className={classes.topLeftCorner}>
                <div className={classes.locationDetails}>
                  {logo === BooleanEnum.TRUE && selectedLocationDetails?.logo && (
                    <img
                      src={`${selectedLocationDetails.logo}`}
                      alt="Location Logo"
                      className={classes.logo}
                    />
                  )}
                  {showAddress === BooleanEnum.TRUE && (
                    <div>
                      <p>
                        <strong>{selectedLocationDetails?.name || 'Location Name'}</strong>
                        <br />
                        <div>{selectedLocationDetails?.addressLine1}</div>
                        <div>{selectedLocationDetails?.addressLine2}</div>
                        <div>{`${selectedLocationDetails?.city}, ${selectedLocationDetails?.state} ${selectedLocationDetails?.postalCode}`}</div>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.content}>
                <h2>We Want Your Feedback</h2>
                {type === Type.CUSTOMER ? (
                  <Typography variant="h6" color="secondary">
                    To help us provide you with the best care possible
                  </Typography>
                ) : (
                  <Typography variant="h6" color="secondary">
                    To help us improve Employee Satisfaction
                  </Typography>
                )}
                <p>
                  We have partnered with a leading satisfaction research firm, Activated Insights,
                  to gain your valuable feedback.
                </p>
                <div className={classes.contactInfo}>
                  <Typography variant="h6">{getNotificationMessage()}</Typography>
                  {notificationType !== Notification.DIGITAL && (
                    <div className={classes.contactItem}>
                      <span role="img" aria-label="Phone" className={classes.icon}>
                        📞
                      </span>
                      <Typography variant="h6" color="secondary">
                        {orgPhone}
                      </Typography>
                    </div>
                  )}
                  {notificationType !== Notification.PHONE && (
                    <div className={classes.contactItem}>
                      <span role="img" aria-label="Email" className={classes.icon}>
                        📧
                      </span>
                      <Typography variant="h6" color="secondary">
                        {email}
                      </Typography>
                    </div>
                  )}
                  <Typography variant="h6">to ask for your feedback</Typography>
                  <Typography variant="body1">{getTextNotificationMessage()}</Typography>
                </div>
                {displayMore === DisplayInfo.LESS && useQrCode === BooleanEnum.FALSE && (
                  <img
                    src={type === Type.CUSTOMER ? smilingOnPhone : smilingOnPhone2}
                    className={classes.image}
                    alt=""
                  />
                )}
              </div>
              {useQrCode === BooleanEnum.TRUE && (
                <div className={classes.qrCodeSection}>
                  <div className={classes.qrCodeItemContainer}>
                    <Typography variant="h6" className={classes.stepNumber} color="secondary">
                      1. Scan Code
                    </Typography>
                    <div className={classes.qrCodeItem}>
                      <div className={classes.qrCode}>
                        <QRCodeCanvas value={vCardData} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.qrCodeItemContainer}>
                    <Typography variant="h6" className={classes.stepNumber} color="secondary">
                      2. Add Contact
                    </Typography>
                    <div className={classes.qrCodeItem}>
                      <Typography variant="h6" className={classes.qrCodeAddContact}>
                        + Add Contact
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.qrCodeItemContainer}>
                    <Typography variant="h6" className={classes.stepNumber} color="secondary">
                      3. Save
                    </Typography>
                    <div className={classes.qrCodeItem}>
                      <div className={classes.avatarCircle}>A</div>
                      <div className={classes.contactDetails}>
                        <Typography color="secondary">Activated Insights</Typography>
                      </div>
                      <div className={classes.contactDetails}>
                        <Typography color="secondary">{orgPhone}</Typography>
                      </div>
                      <div className={classes.saveButton}>Save</div>
                    </div>
                  </div>
                </div>
              )}
              {displayMore === DisplayInfo.MORE && (
                <div className={classes.moreInfo}>
                  <div className={classes.moreInfoBlock}>
                    <h3>Who is Activated Insights?</h3>
                    <p>
                      Activated Insights is our trusted partner specializing in client satisfaction
                      research. They adhere to strict confidentiality laws and will not request
                      personal financial or health information.
                    </p>
                  </div>
                  <div className={classes.moreInfoBlock}>
                    <h3>How is my feedback used?</h3>
                    <p>
                      Your responses are shared exclusively with our team and are integral in
                      shaping our care services. You have the option to remain anonymous in your
                      feedback.
                    </p>
                  </div>
                  <div className={classes.moreInfoBlock}>
                    <h3>How often will I be surveyed?</h3>
                    <p>
                      After providing your feedback, you will not be contacted again for at least
                      six months.
                    </p>
                  </div>
                </div>
              )}
              <div className={classes.footer}>
                <PulseIcon className={classes.logo} />
              </div>
            </div>
          </div>
        )}
      </div>
    </Page>
  )
}

export default withErrorHandler(InformLettersContainer)
