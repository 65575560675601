import ReactGA from 'react-ga'

import { GA_CODE } from './config'

import { SurveyProductTypeEnum } from 'generated/graphql'
import { RESIDENT_INSIGHTS, EMPLOYEE_INSIGHTS } from 'utils/constants'

const getClient = () => {
  if (GA_CODE) {
    ReactGA.initialize(GA_CODE, {
      debug: false,
    })
    return ReactGA
  }
  return null
}

const client = getClient()

export default client

export function gaEvent(args: ReactGA.EventArgs) {
  if (!client) return
  // If the event is from insights, add a product type label.
  let label
  if (window.location.pathname.includes(EMPLOYEE_INSIGHTS)) {
    label = SurveyProductTypeEnum.EMPLOYEE
  } else if (window.location.pathname.includes(RESIDENT_INSIGHTS)) {
    label = SurveyProductTypeEnum.RESIDENT
  }
  client.event({ ...args, label })
}
