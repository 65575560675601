import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'

const styles = theme => ({
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    '& >span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  disabled: {
    color: theme.palette.common.navy65,
  },
})

const PopoverCheckboxGroup = ({
  disabled,
  items,
  activeItems,
  getItemValue,
  getItemLabel,
  toggleActivatedItem,
  groupId,
  classes,
}) => {
  return items.map((item, idx) => {
    const checked = activeItems.includes(getItemValue(item))
    const className = disabled && !checked ? classes.disabled : null
    return (
      <div key={`${groupId}${idx}`} style={{ display: 'flex' }}>
        <div className={classes.itemRow} key={getItemValue(item)} role="option" aria-selected>
          <Checkbox
            checked={checked}
            onChange={() => toggleActivatedItem(!checked, getItemValue(item))}
            disabled={!checked && disabled}
            style={{ paddingLeft: 0, marginLeft: -3 }}
          />
          <span className={className}>{getItemLabel(item)}</span>
        </div>
      </div>
    )
  })
}

PopoverCheckboxGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      code: PropTypes.string,
    }),
  ).isRequired,
  classes: PropTypes.object.isRequired,
  activeItems: PropTypes.array.isRequired,
  getItemValue: PropTypes.func.isRequired,
  getItemLabel: PropTypes.func.isRequired,
  toggleActivatedItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
}

export default withStyles(styles)(PopoverCheckboxGroup)
