import React, { ReactElement } from 'react'

import { createStyles, makeStyles, Typography, Paper, Grid } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import compact from 'lodash/compact'
import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'
import { NavLink } from 'react-router-dom'

import { ImportErrorsEnum, ImportErrorType } from 'generated/graphql'
import { pluralize, joinWords } from 'utils'
import { SUPPORT_EMAIL } from 'utils/constants'
import { MaybeList } from 'utils/types'

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(3),
      background: palette.common.danger,
      '& >div>p, & >div>h6, & >svg': {
        color: palette.common.white,
      },
    },
    errorRow: {
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
      paddingRight: spacing(3),
      '& >div>div>h6': {
        padding: spacing(1),
        textAlign: 'center',
      },
    },
    instruction: {
      paddingTop: spacing(1),
      color: palette.common.danger,
      fontStyle: 'italic',
      fontSize: '1.6rem',
    },
    templateLink: {
      padding: 0,
      margin: 0,
      textTransform: 'lowercase',
    },
    footer: {
      marginTop: spacing(2),
      marginBottom: spacing(2),
      backgroundColor: palette.common.navy,
      padding: spacing(2),
      '& >p': {
        color: palette.common.white,
      },
    },
    supportLink: {
      color: palette.common.navy,
    },
  }),
)

const getCellsMessage = (cells?: MaybeList) => {
  if (!cells) return ''
  const maxLength = 5
  let truncated = cells
  if (cells.length > maxLength) {
    truncated = cells.slice(0, maxLength)
  }
  return `Check ${pluralize('cell', truncated.length)}: ${truncated.join(', ')}
    ${cells.length > maxLength ? ' and more' : ''}`
}

type GetErrorFunctionType = (arg: {
  dtCodes?: MaybeList
  cells?: MaybeList
  dtNames?: MaybeList
}) => ReactElement | string
type Props = {
  templateUrl: string
  errors: ImportErrorType[]
}
const ParticipantUploadErrors: React.FC<Props> = ({ templateUrl, errors }) => {
  const classes = useStyles()
  const validationInstructionMapping = {
    personal_email: 'email addresses follow the standard format',
    work_email: 'email addresses follow the standard format',
    date_of_birth: 'phone numbers are formatted as (123) 456-789 or 123456789',
    mobile_phone: 'phone numbers are formatted as (123) 456-789 or 123456789',
    hire_date: 'dates are formatted as mm/dd/yyyy',
    ti_work_postal_code: 'zipcodes follow the standard format',
  }
  const errorMessageMapping: {
    [key in ImportErrorsEnum]: {
      title: string
      getError: GetErrorFunctionType
      getInstruction: GetErrorFunctionType
    }
  } = {
    [ImportErrorsEnum.INVALID_EXCEL_FORMAT]: {
      title: 'Invalid Excel Format',
      getError: () => `Some cells registered as a number or an invalid type.`,
      getInstruction: () => 'Reformat your cells so that each cell is in plain text.',
    },
    [ImportErrorsEnum.BLANK_COLUMN]: {
      title: 'Blank Column',
      getError: () => `Your file has a blank column that is preventing us from importing the rest
        of the data in the file. Please delete the column and re-upload.`,
      getInstruction: () =>
        `Make sure there aren't any blank columns before the last column with data in it.`,
    },
    [ImportErrorsEnum.INVALID_COLUMN]: {
      title: 'Invalid Column',
      getError: ({ dtNames }) => `${dtNames} is an invalid column on participant uploads.`,
      getInstruction: ({ dtNames }) =>
        `Please use the client upload to create or update ${dtNames}.`,
    },
    [ImportErrorsEnum.DUPLICATE_COLUMN]: {
      title: 'Duplicate Columns',
      getError: () => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          Make sure that each column header is unique and matches the options in the&nbsp;
          <NavLink to={templateUrl} className={classes.templateLink} color="secondaryNoBackground">
            roster template
          </NavLink>
        </span>
      ),
      getInstruction: () => 'Rename your column headers or delete any columns that you don’t need.',
    },
    [ImportErrorsEnum.MISSING_REQUIRED_COLUMN]: {
      title: 'Missing Required Columns',
      getError: ({ dtNames }) => `Your file is missing a required column.
        Make sure you include ${joinWords(dtNames)}.`,
      getInstruction: () => 'Either rename your column headers or add the missing columns.',
    },
    [ImportErrorsEnum.FAILED_COLUMN_MAPPING]: {
      title: 'Mapping',
      getError: ({ dtNames }) => `Some of your column headers couldn't be mapped
        to a standard column type. Check ${joinWords(dtNames)}.`,
      getInstruction: () => `Rename your column headers or delete any columns you don't need.`,
    },
    [ImportErrorsEnum.MISSING_REQUIRED_VALUE]: {
      title: 'Missing Data',
      getError: ({ dtNames }) => `You’re missing required values for some participants.
        All participants must have ${joinWords(dtNames)}.`,
      getInstruction: ({ cells, dtNames }) =>
        `Add ${joinWords(dtNames)} for all participants. ${getCellsMessage(cells)}`,
    },
    [ImportErrorsEnum.DUPLICATE_VALUE]: {
      title: 'Duplicate Values',
      getError: ({
        dtNames,
      }) => `You have duplicates in your data. Make sure each participant has a unique
        value for ${joinWords(dtNames)}.`,
      getInstruction: ({ cells }) =>
        `Remove any duplicates in your file. ${getCellsMessage(cells)}`,
    },
    [ImportErrorsEnum.EXISTING_DUPLICATE_VALUE]: {
      title: 'Duplicate value with existing survey Participants.',
      getError: ({
        dtNames,
      }) => `Your data was uploaded with fields that already exist. Make sure each participant has a unique
        value for ${dtNames}.`,
      getInstruction: () => `Remove any duplicates in your file.`,
    },
    [ImportErrorsEnum.INVALID_VALUE]: {
      title: 'Invalid Data',
      getError: ({ dtNames }) => `You have invalid data in: ${joinWords(dtNames)}.`,
      getInstruction: ({ dtCodes, cells }) => {
        if (!dtCodes) return <></>
        const instructions = uniq(
          dtCodes.map(
            code => validationInstructionMapping[code as keyof typeof validationInstructionMapping],
          ),
        )
        return (
          <>
            Make sure that:
            <ul>
              {instructions.map(instruction => (
                <li key={instruction}>{instruction}</li>
              ))}
            </ul>
            {getCellsMessage(cells)}
          </>
        )
      },
    },
    [ImportErrorsEnum.INVALID_STANDARDS_MAPPING]: {
      title: 'Precoding',
      getError: ({ dtNames }) => `
        Some cells have values we don’t recognize. To precode demographics, the data in your file
        must match the options in the roster template for: ${dtNames && dtNames.join(', ')}`,
      getInstruction: ({ cells }) =>
        `Change these cells to match our standard options or delete them.
        For Trust Index surveys employees will be asked to self-report required demographics
        that aren’t pre-coded. ${getCellsMessage(cells)}`,
    },
    [ImportErrorsEnum.INVALID_LEVEL_OF_CARE]: {
      title: 'Settings',
      getError: () => `The levels of care options in your file don’t match your settings.`,
      getInstruction: ({ cells }) =>
        `Either update your settings to match what’s in your file, change the data in your file,
        or remove the data so it isn’t precoded. ${getCellsMessage(cells)}`,
    },
  }
  // If the file is missing required columns, we will also receive "missing_required_value" errors
  // for each row where the column is missing. Don't bother displaying those errors.
  const missingColumnDtCodes = new Set(
    errors.map(e => (e.code === ImportErrorsEnum.MISSING_REQUIRED_COLUMN ? e.dtCode : null)),
  )
  const filteredErrors = compact(
    errors.filter(e => {
      if (
        e.code === ImportErrorsEnum.MISSING_REQUIRED_VALUE &&
        missingColumnDtCodes.has(e.dtCode)
      ) {
        return null
      }
      return e
    }),
  )
  // Group errors of the same type so we can display them under the same section
  // (e.g.) validation errors — you have problems with: email, phone number, address.
  const groupedErrors = Object.values(groupBy(filteredErrors, 'code')).map(errs => {
    return {
      ...errs[0],
      dtCodes: uniq(errors.map(e => e.dtCode)),
      dtNames: uniq(errors.filter(e => e.dtName).map(e => e.dtName)),
      cells: uniq(errors.map(e => e.cell)).sort(),
    }
  })
  return (
    <>
      <div className={classes.header}>
        <div>
          <Typography variant="h6">Errors Found</Typography>
          <Typography>
            We found some issues with the data you uploaded. Please fix the errors below in your
            file and then reupload it.
            <br />
            If the errors don't make sense or you need more help uploading your data&nbsp;
            <a className={classes.supportLink} href={`mailto:${SUPPORT_EMAIL}`}>
              just let us know!
            </a>
          </Typography>
        </div>
        <WarningIcon />
      </div>
      <>
        {groupedErrors.map((error, idx) => {
          if (!error.code || !errorMessageMapping[error.code]) return null
          const { title, getError, getInstruction } = errorMessageMapping[error.code]
          return (
            <Paper className={classes.errorRow} key={error.code + idx}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Typography variant="h6">{title}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{getError(error)}</Typography>
                  <div className={classes.instruction}>{getInstruction(error)}</div>
                </Grid>
              </Grid>
            </Paper>
          )
        })}
      </>
      <Paper className={classes.footer}>
        <Typography>
          If you require any assistance please contact your Program Manager or email {SUPPORT_EMAIL}
          .
        </Typography>
      </Paper>
    </>
  )
}

export default ParticipantUploadErrors
