import React from 'react'

import {
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ArrowIcon from '@material-ui/icons/ArrowDropDownCircle'
import cn from 'classnames'

const useStyles = makeStyles(({ spacing, palette }) => ({
  panelRoot: {
    margin: 0,
  },
  details: {
    flexWrap: 'wrap',
  },
  subtitle: {
    flex: '0 0 100%',
    marginBottom: spacing(2),
  },
  icon: {
    color: palette.common.secondary,
  },
  focus: {
    borderLeft: `4px solid ${palette.common.secondary}`,
    zIndex: 1,
  },
  expanded: {
    borderLeft: `4px solid ${palette.common.secondary}`,
    '&:first-child': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}))

type Props = {
  id?: string
  expanded?: boolean
  disabled?: boolean
  tooltip?: string
  title?: string
  subtitle?: string
  elevation?: number
  onExpandChange(): void
}

const Panel: React.FC<Props> = ({
  expanded = true,
  disabled = false,
  tooltip = '',
  title = '',
  subtitle = '',
  id,
  onExpandChange,
  elevation = 1,
  children,
}) => {
  const classes = useStyles()

  return (
    <Tooltip title={tooltip}>
      <Accordion
        className={cn({
          [classes.expanded]: expanded,
        })}
        elevation={elevation}
        expanded={expanded}
        disabled={disabled}
        onChange={onExpandChange}
        classes={{ root: classes.panelRoot }}
      >
        <AccordionSummary id={id} expandIcon={<ArrowIcon className={classes.icon} />}>
          <Typography variant="h6">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {subtitle && (
            <Typography color="textSecondary" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
          {children}
        </AccordionDetails>
      </Accordion>
    </Tooltip>
  )
}

export default Panel
