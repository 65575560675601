import React from 'react'

import { useHistory } from 'react-router-dom'

import Button from 'components/Blocks/CustomButtons/Button'
import Section, { SectionsEnum } from 'components/Survey/Wizard/Steps/Summary/Section'
import useStyles from 'components/Survey/Wizard/Steps/Summary/styles'
import { WizardStepsEnum } from 'components/Survey/WizardContainer'
import {
  SurveysSurveyQuery,
  ParticipantsSectionType,
  SurveyInviteMethodEnum,
  DataTypeCode,
  CurrentUserQuery,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { pluralize } from 'utils'
import { filterValuesPage } from 'utils/constants'

type Props = {
  survey: SurveysSurveyQuery['survey']
  currentUser: CurrentUserQuery['currentUser']
  editable: boolean
  goToStep(stepName: WizardStepsEnum): void
  participants: ParticipantsSectionType
  numLocationsMissingRequiredData?: number | null
  errors: { [key: string]: boolean }
  warnings: { [key: string]: boolean }
}

const ParticipantsSection: React.FC<Props> = ({
  survey,
  currentUser,
  participants,
  numLocationsMissingRequiredData,
  editable,
  goToStep,
  errors,
  warnings,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const showEmails = survey.inviteMethod !== SurveyInviteMethodEnum.PHONE

  return (
    <Section
      editable={editable}
      goToStep={() => goToStep(WizardStepsEnum.PARTICIPANTS)}
      title={SectionsEnum.PARTICIPANTS}
      ready={participants.ready}
      warningsNumber={participants.warnings.length}
    >
      <ul>
        {errors.EXCESSIVE_DUPLICATE_CONTACT_INFORMATION && (
          <li className={classes.error}>
            Survey has multiple participants with duplicate phone numbers and/or email addresses.{' '}
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.PARTICIPANTS)}
              color="secondaryNoBackground"
            >
              Update Participants
            </Button>
          </li>
        )}
        {errors.NO_PARTICIPANTS ? (
          <li className={classes.error}>
            You need to add participants.{' '}
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.PARTICIPANTS)}
              color="secondaryNoBackground"
            >
              Add Participants
            </Button>
          </li>
        ) : (
          <>
            <li>
              {participants.total}{' '}
              <span className={classes.mute}>
                survey {pluralize('participant', participants.total)}
              </span>
            </li>
            {survey.productType === SurveyProductTypeEnum.RESIDENT &&
              warnings.CLIENTS_WITHOUT_PARTICIPANT && (
                <li className={classes.warning}>
                  {participants.clientsWithoutParticipantCount}{' '}
                  {pluralize('senior', participants.clientsWithoutParticipantCount)}{' '}
                  {pluralize('does', participants.clientsWithoutParticipantCount, 'do')} not have
                  any survey participant.{' '}
                  <Button
                    noMargins
                    onClick={() => goToStep(WizardStepsEnum.PARTICIPANTS)}
                    color="secondaryNoBackground"
                  >
                    Add Participants
                  </Button>
                </li>
              )}
            {warnings.MISSING_CLIENT_NAME_FOR_CONTACTS && (
              <li className={classes.warning}>
                This survey is missing resident names for participants who are contacts. Reupload a
                participants file with the column “Resident Name” to add the missing data.
              </li>
            )}
            {showEmails && survey.productType === SurveyProductTypeEnum.RESIDENT && (
              <li>
                {participants.workEmails}{' '}
                <span className={classes.mute}>
                  work email {pluralize('address', participants.workEmails, 'es')}
                </span>
              </li>
            )}
            {showEmails && (
              <li>
                {participants.personalEmails}{' '}
                <span className={classes.mute}>
                  personal email {pluralize('address', participants.personalEmails, 'es')}
                </span>
              </li>
            )}
            <li>
              {participants.mobilePhones}{' '}
              <span className={classes.mute}>
                valid mobile phone {pluralize('number', participants.mobilePhones)}
              </span>
            </li>
            {warnings.MISSING_MOBILE_PHONE && (
              <li className={classes.warning}>
                There are participants with missing phone numbers.
              </li>
            )}
          </>
        )}
        {errors.LOCATIONS_MISSING_REQUIRED_DATA && (
          <li className={classes.error}>
            {`${numLocationsMissingRequiredData} ${pluralize(
              'location',
              numLocationsMissingRequiredData as number,
            )} missing required data.`}
            <Button
              noMargins
              onClick={() =>
                history.push(
                  filterValuesPage(
                    currentUser.filters.find(
                      filterType => filterType.dtCode === DataTypeCode.AI_LOCATION,
                    )?.filterTypeUuid || '',
                  ),
                )
              }
              color="secondaryNoBackground"
            >
              Add Location Data
            </Button>
          </li>
        )}
      </ul>
    </Section>
  )
}

export default ParticipantsSection
