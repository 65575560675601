import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import Button from 'components/Blocks/CustomButtons/Button'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import { AppliedFilters } from 'utils/types'

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    paddingTop: spacing(),
    marginBottom: spacing(2),
    borderTop: `2px solid ${palette.common.navy15}`,
    width: '100%',
    display: 'flex',
  },
  header: {
    marginLeft: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: spacing(3),
    '& >button': {
      margin: 0,
      padding: 0,
      '& >span': {
        fontSize: '1.2rem',
      },
    },
  },
  filterItem: {
    order: 1,
  },
}))

type DropdownProps = {
  name: string
  options: { name: string; uuid: string }[]
  onChange(arg: string[]): void
  selectedItems: string[]
}
const FilterDropdown: React.FC<DropdownProps> = ({ name, options, selectedItems, onChange }) => {
  const classes = useStyles()
  return (
    <div id={name} className={classes.filterItem}>
      <div className={classes.header}>
        <Typography color="textSecondary" variant="body2">
          {name}
        </Typography>
        {selectedItems.length ? (
          <Button color="secondaryNoBackground" onClick={() => onChange([])}>
            Clear
          </Button>
        ) : null}
      </div>
      <CheckboxDropdown
        withLeftMargin
        width={140}
        menuItems={options.map(o => ({ value: o.uuid, text: o.name }))}
        selectedItems={selectedItems}
        onChange={(selected: string[]) => onChange(selected)}
        emptyLabel="Select categories"
      />
    </div>
  )
}

type Props = {
  filters: {
    name: string
    filterTypeUuid: string
    filterValues: {
      name: string
      uuid: string
    }[]
  }[]
  updateSelectedFilters(arg1: string, arg2: string[]): void
  selectedFilters: AppliedFilters
}
// Pass children if you want to add additional filters within the container
const FilterList: React.FC<Props> = ({
  filters,
  selectedFilters,
  updateSelectedFilters,
  children,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {filters.map(({ name, filterTypeUuid, filterValues }) => (
        <FilterDropdown
          key={name}
          name={name}
          options={filterValues}
          selectedItems={selectedFilters[filterTypeUuid] || []}
          onChange={selected => updateSelectedFilters(filterTypeUuid, selected)}
        />
      ))}
      {children}
    </div>
  )
}

export default FilterList
