import React, { SyntheticEvent } from 'react'

import { makeStyles, TextField, Typography, DialogContent } from '@material-ui/core'

import { Group } from 'components/Settings/Team/Groups/GroupsTypes'

type Props = {
  group: Group
  updateGroup: (arg: Partial<Group>) => void
}

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: theme.spacing(1),
  },
  textInput: {
    marginTop: 15,
    marginRight: theme.spacing(2),
  },
}))

const NameSettings: React.FC<Props> = ({ group, updateGroup }) => {
  const classes = useStyles()
  return (
    <DialogContent dividers>
      <Typography className={classes.label}>Enter a name for your group:</Typography>
      <TextField
        fullWidth
        name="groupName"
        className={classes.textInput}
        value={group.name}
        onChange={(e: SyntheticEvent) => {
          updateGroup({ name: (e.target as HTMLInputElement).value })
        }}
      />
    </DialogContent>
  )
}

export default NameSettings
