import React, { SyntheticEvent } from 'react'

import { DialogActions, Tooltip } from '@material-ui/core'

import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'

type Props = {
  submitDisabled?: boolean
  submitButtonText: string
  submitDisabledText?: string
  submitButtonColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'primaryNoBackground'
    | 'secondaryNoBackground'
    | 'successNoBackground'
    | 'warningNoBackground'
    | 'dangerNoBackground'
    | 'textSecondaryNoBackground'
  cancelButtonText?: string
  onClose?(): void
  onSubmit?: (event: SyntheticEvent) => void
  autoFocusSubmit?: boolean
  submitButtonType?: 'submit'
  isSubmitting?: boolean
}

const ActionDialogButtons: React.FC<Props> = ({
  onClose,
  onSubmit,
  submitButtonType,
  isSubmitting = false,
  autoFocusSubmit = false,
  submitDisabledText = '',
  cancelButtonText = 'Cancel',
  submitDisabled = false,
  submitButtonText = 'Save',
  submitButtonColor = 'primary',
}) => {
  return (
    <DialogActions id="dialog-actions">
      {onClose && (
        <Button onClick={onClose} color="textSecondaryNoBackground">
          {cancelButtonText}
        </Button>
      )}
      <Tooltip title={submitDisabledText}>
        <span>
          {submitButtonType === 'submit' ? (
            <SubmitButton
              disabled={submitDisabled}
              isSubmitting={isSubmitting}
              autoFocus={autoFocusSubmit}
            >
              {submitButtonText}
            </SubmitButton>
          ) : (
            <Button
              onClick={onSubmit}
              disabled={submitDisabled}
              color={submitButtonColor}
              isSubmitting={isSubmitting}
              autoFocus={autoFocusSubmit}
            >
              {submitButtonText}
            </Button>
          )}
        </span>
      </Tooltip>
    </DialogActions>
  )
}

export default ActionDialogButtons
