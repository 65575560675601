import React, { useState } from 'react'

import { Typography, FormControl, Select, MenuItem } from '@material-ui/core'

import HorizontalBarChart, {
  formatChartData,
  SortOrder,
  TooltipSeries,
} from 'components/Blocks/Charts/HorizontalBarChart'
import HorizontalChartTooltip from 'components/Blocks/Charts/HorizontalChartTooltip'
import ModalChartContainer from 'components/Blocks/Dialogs/ModalChartContainer'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import ResidentBreakdownChart from 'components/Insights/ResidentSnapshot/ResidentBreakdownChart'
import {
  useInsightsScoresByDataTypeQuery,
  RankByEnum,
  FilterTypeFragment,
  InsightsSurveyQuery,
  SurveyProductTypeEnum,
  InsightsScoresByDataTypeQueryVariables,
} from 'generated/graphql'

type SProps = InsightsScoresByDataTypeQueryVariables & {
  sortOrder: SortOrder
  overallPositiveScore: number
  benchmarkPositive: number
  benchmarkName: string
  selectedFilters: string[]
}
const SingleLevelBreakdownChart: React.FC<SProps> = props => {
  const {
    sortOrder,
    benchmarkName,
    benchmarkPositive,
    overallPositiveScore,
    selectedFilters,
    ...queryVars
  } = props
  const result = useInsightsScoresByDataTypeQuery({
    variables: { ...queryVars, filters: selectedFilters, rankBy: RankByEnum.POSITIVE },
  })
  return (
    <ResponseHandler {...result}>
      {data => {
        return (
          <HorizontalBarChart
            chartData={formatChartData([data.insightsScoresByDataType], sortOrder)}
            benchmarkData={[
              { name: benchmarkName, score: benchmarkPositive },
              { name: 'Company Score Overall', score: overallPositiveScore },
            ]}
            tooltipFormatter={(series: TooltipSeries) => {
              return (
                <HorizontalChartTooltip
                  scores={[
                    series[0].data,
                    { name: benchmarkName, value: benchmarkPositive },
                    { name: 'Company Overall', value: overallPositiveScore },
                  ]}
                />
              )
            }}
          />
        )
      }}
    </ResponseHandler>
  )
}

type Props = {
  onClose(): void
  survey: InsightsSurveyQuery['survey']
  selectedFilters: string[]
  visibleFilterTypes: FilterTypeFragment[]
  overallPositiveScore: number
  statementCode?: string
  benchmarkPositive: number
  benchmarkName: string
  scoreTitle?: string
}
const RankedBreakdownChartModal: React.FC<Props> = ({
  onClose,
  visibleFilterTypes: filterTypes,
  survey,
  selectedFilters,
  statementCode,
  overallPositiveScore,
  benchmarkPositive,
  benchmarkName,
  scoreTitle: initialScoreTitle,
}) => {
  const [dtCode, setDtCode] = useState<string>(filterTypes[0].dtCode)
  const [sortOrder, setSortOrder] = useState(SortOrder.HIGHEST)
  let scoreTitle = initialScoreTitle
  if (!scoreTitle) {
    if (statementCode) {
      scoreTitle = 'Key Statement Score'
    } else if (survey.productType === SurveyProductTypeEnum.EMPLOYEE) {
      scoreTitle = 'Trust Index Score'
    } else {
      scoreTitle = 'Engagment Score'
    }
  }
  const commonProps = {
    dtCode,
    sortOrder,
    surveyUuid: survey.uuid,
    selectedFilters,
    overallPositiveScore,
    benchmarkPositive,
    benchmarkName,
    statementCodes: statementCode ? [statementCode] : undefined,
  }
  return (
    <ModalChartContainer
      id="ranked-breakdown-snap"
      onClose={onClose}
      surveyName={survey.name}
      dropdownRowComponent={
        <>
          <Typography color="textSecondary" variant="h6">
            See
          </Typography>
          <FormControl>
            <Select
              value={dtCode}
              onChange={e => setDtCode((e.target as HTMLInputElement).value)}
              displayEmpty={false}
              name="breakdownFilterType"
            >
              {filterTypes.map(ft => (
                <MenuItem key={ft.dtCode} value={ft.dtCode}>
                  {ft.namePlural}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography color="textSecondary" variant="h6">
            sorted by
          </Typography>
          <FormControl>
            <Select
              value={sortOrder}
              onChange={e => setSortOrder(e.target.value as SortOrder)}
              displayEmpty={false}
              name="breakdownSortOrder"
            >
              {Object.values(SortOrder).map(order => (
                <MenuItem key={order} value={order}>
                  {order}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6">{scoreTitle}</Typography>
        </>
      }
      chartComponent={
        survey.productType === SurveyProductTypeEnum.RESIDENT ? (
          <ResidentBreakdownChart {...commonProps} />
        ) : (
          <SingleLevelBreakdownChart {...commonProps} />
        )
      }
    />
  )
}

export default RankedBreakdownChartModal
