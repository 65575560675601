import React, { useState } from 'react'

import Section, { SectionsEnum } from 'components/Survey/Wizard/Steps/Summary/Section'
import useStyles from 'components/Survey/Wizard/Steps/Summary/styles'
import { WizardStepsEnum } from 'components/Survey/WizardContainer'
import UpdateTranslationsButton from 'containers/Survey/Wizard/Steps/Questions/UpdateTranslationsButton'
import { SurveysSurveyQuery, TranslationsSectionType } from 'generated/graphql'
import { pluralize } from 'utils'

type Props = {
  survey: SurveysSurveyQuery['survey']
  translations: TranslationsSectionType
  editable: boolean
  goToStep(stepName: WizardStepsEnum): void
  responseRateByClient: boolean
  errors: { [key: string]: boolean }
}

const TranslationsSection: React.FC<Props> = ({
  survey,
  translations,
  editable,
  goToStep,
  responseRateByClient,
  errors,
}) => {
  const classes = useStyles()

  const [isOpenTranslationsDialog, setIsOpenTranslationsDialog] = useState(false)

  return (
    <Section
      editable={editable}
      goToStep={() => goToStep(WizardStepsEnum.QUESTIONS)}
      title={SectionsEnum.TRANSLATIONS}
      ready={translations.ready}
      warningsNumber={translations.warnings.length}
    >
      <ul>
        <>
          {errors.MISSING_TRANSLATIONS && (
            <li className={classes.error}>
              {`${translations.missing} ${pluralize(
                'translation',
                translations.missing,
              )} missing. `}
              <UpdateTranslationsButton
                surveyUuid={survey.uuid}
                surveyLanguages={survey.languages}
                responseRateByClient={responseRateByClient}
                openControl="link"
                isOpenTranslationsDialog={isOpenTranslationsDialog}
                setIsOpenTranslationsDialog={setIsOpenTranslationsDialog}
              />
            </li>
          )}
          {!errors.MISSING_TRANSLATIONS && (
            <li>
              {translations.translated} <span className={classes.mute}>translations provided.</span>
            </li>
          )}
        </>
      </ul>
    </Section>
  )
}

export default TranslationsSection
