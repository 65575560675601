import React from 'react'

import { makeStyles, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import cn from 'classnames'
import PropTypes from 'prop-types'

import useInsightsStyles, { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import echartTooltipBuilder from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import StatementChangeLabel from 'components/Insights/TimeTrending/Blocks/StatementChangeLabel'
import { colors } from 'shared/theme'
import { formatTooltipScore } from 'utils'
import { employeeExperienceStaticText } from 'utils/constants'
import {
  timetrendXAxis,
  surveysYAxis,
  gridLines,
  surveysTooltip,
  chartLabels,
  surveysBottomLegend,
} from 'utils/echartsHelpers'

const useStyles = makeStyles(theme => ({
  highlightHeader: {
    color: theme.palette.common.secondary,
  },
}))

const tooltipFormatter = ({ dataIndex, scoreGroups, surveys }) => {
  const survey = surveys[dataIndex]
  const rowGroups = [
    {
      leftColumn: ['High Trust', 'Checked Out', 'Wants More', 'High Risk'],
      rightColumn: scoreGroups.map(({ scores }) => {
        const score = scores[dataIndex][1]
        const lastScore = dataIndex > 0 && scores[dataIndex - 1][1]
        return [formatTooltipScore(score, survey.minShowableResults, lastScore)]
      }),
    },
    {
      leftColumn: ['Survey name', 'Date'],
      rightColumn: [[survey.name], [new Date(survey.endDate).toLocaleDateString()]],
    },
  ]
  return echartTooltipBuilder(rowGroups)
}

const Chart = ({ surveys, scoreGroups }) => {
  const chartColors = [colors.navy, '#686c6d', '#bfc8cd', colors.danger]
  return (
    <PrintableEchart
      notMerge
      printHeight={400}
      printWidth={400}
      style={{ width: '50%' }}
      opts={{ renderer: 'svg' }} // Useful for selecting DOM elements in UItests
      option={{
        grid: { top: 40, left: 40, bottom: 80 },
        textStyle: chartTextStyle,
        legend: {
          data: scoreGroups.map(({ label }) => ({ name: label, icon: 'rect' })),
          ...surveysBottomLegend,
          // up, right, down, left
          padding: [0, 150, 0, 50],
        },
        xAxis: timetrendXAxis({ axisLabelData: surveys, useDateLabel: true }),
        yAxis: surveysYAxis({ splitNumber: 2 }),
        series: [
          ...scoreGroups.map(cultureScores => ({
            data: cultureScores.scores,
            type: 'line',
            name: cultureScores.label,
            lineStyle: { width: 1 },
          })),
          gridLines({ numCategories: surveys.length }),
          chartLabels({
            labels: scoreGroups.map(({ scores }, index) => ({
              value: scores[scores.length - 1][1],
              color: chartColors[index],
            })),
          }),
        ],
        color: chartColors,
        tooltip: {
          ...surveysTooltip({}),
          formatter: series =>
            tooltipFormatter({ dataIndex: series[0].dataIndex, scoreGroups, surveys }),
        },
      }}
    />
  )
}

const Highlights = ({ highlightsMap, firstHighTrustScore }) => {
  const classes = { ...useInsightsStyles(), ...useStyles }
  const highlightSubtitle = demographics => {
    if (!Object.keys(demographics).length) {
      return ''
    }
    return (
      Object.keys(demographics)
        .map(name => (
          <span key={name}>
            <span className={classes.highlightHeader}>{name}</span>
            {': '}
            {demographics[name]
              .map(({ change, value }) => {
                const arrowIcon =
                  change >= 0 ? (
                    <span style={{ color: colors.danger }}> ↑ </span>
                  ) : (
                    <span style={{ color: colors.success }}> ↓ </span>
                  )
                return (
                  <span key={value}>
                    {value}
                    {arrowIcon}
                    {`${Math.abs(Math.round(change))}%`}
                  </span>
                )
              })
              // Join with 'and'
              .reduce((prev, curr) => [prev, ' and ', curr])}
          </span>
        ))
        // Join with '|'
        .reduce((prev, curr) => [prev, ' | ', curr])
    )
  }
  return (
    <div className={cn(classes.halfWidth, classes.printWidthThird)}>
      {Object.keys(highlightsMap).map(key => {
        const { label, delta, demographics } = highlightsMap[key]
        let title = `${delta}% ${label}`
        // For "Checked Out", "Wants More", and "High Risk", an increasing delta is negative feedback.
        let arrowColors = { up: colors.danger, down: colors.success }
        if (label === 'High Trust') {
          // For "High Trust", an increasing delta is positive feedback
          arrowColors = { up: colors.success, down: colors.danger }
          if (delta === 0) {
            title = `Employees are continuing to experience a
                ${firstHighTrustScore >= 50 ? 'high' : 'lower'} trust work environment`
          } else {
            title = `More employees experience a ${delta >= 0 ? 'high' : 'lower'} trust
            workplace environment`
          }
        }
        const tooltipText = employeeExperienceStaticText.default[key]
        const TitleComponent = (
          <>
            {title}
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <InfoIcon />
              </Tooltip>
            )}
          </>
        )
        return (
          <StatementChangeLabel
            key={label}
            scoreDelta={delta}
            arrowColors={arrowColors}
            title={TitleComponent}
            subtitle={highlightSubtitle(demographics)}
          />
        )
      })}
    </div>
  )
}

const TTEmployeeExperienceCard = ({
  selectedSurveys,
  scoreGroups,
  highlightsMap,
  firstHighTrustScore,
}) => {
  const classes = useInsightsStyles()
  return (
    <div className={classes.timeTrendingChartRow}>
      <Highlights highlightsMap={highlightsMap} firstHighTrustScore={firstHighTrustScore} />
      <Chart scoreGroups={scoreGroups} surveys={selectedSurveys} />
    </div>
  )
}

const highlightPropType = PropTypes.shape({
  label: PropTypes.string,
  delta: PropTypes.number,
  demographics: PropTypes.object,
})

TTEmployeeExperienceCard.propTypes = {
  selectedSurveys: PropTypes.arrayOf(PropTypes.object).isRequired,
  scoreGroups: PropTypes.arrayOf(
    PropTypes.shape({
      scores: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      label: PropTypes.string,
    }),
  ).isRequired,
  highlightsMap: PropTypes.shape({
    highRisk: highlightPropType,
    checkedOut: highlightPropType,
    wantsMore: highlightPropType,
    highTrust: highlightPropType,
  }).isRequired,
  firstHighTrustScore: PropTypes.number.isRequired,
}

export default TTEmployeeExperienceCard
