import React, { useState } from 'react'

import { MenuItem, Typography } from '@material-ui/core'

import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import TTBasicStatementsCard from 'components/Insights/Blocks/TTBasicStatementsCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsBenchmark, InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import TimeTrendingContainer from 'components/Insights/TimeTrending/TimeTrendingContainer'
import {
  transformTimeTrendingStatementsToEchartScores,
  getStatementsList,
} from 'components/Insights/TimeTrending/utils'
import { TimeTrendingChartKey, TimeTrendingSurveysKey } from 'config/LocalStorage'
import { SurveyProductTypeEnum, useInsightsStatementsQuery } from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import { usePulseInTimeTrending } from 'utils/customHooks'
import { SurveyNode } from 'utils/types'

enum ORDER_TYPES {
  DESCENDING = 'desc',
  ASCENDING = 'asc',
}
const MAX_STATEMENTS = 5

type Props = {
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  filters: string[]
  benchmark: InsightsBenchmark
}
const TTSnapshotStatementsCard: React.FC<Props> = ({
  survey,
  availableSurveys,
  filters,
  benchmark,
}) => {
  const classes = useInsightsStyles()
  const [selectedOrder, setSelectedOrder] = useState<ORDER_TYPES>(ORDER_TYPES.DESCENDING)
  const orderLabel = {
    [ORDER_TYPES.DESCENDING]: 'Increased',
    [ORDER_TYPES.ASCENDING]: 'Decreased',
  }
  const { chartKey, surveysKey } = {
    [SurveyProductTypeEnum.EMPLOYEE]: {
      chartKey: TimeTrendingChartKey.EMPLOYEE_SNAPSHOT_STATEMENTS,
      surveysKey: TimeTrendingSurveysKey.EMPLOYEE_SNAPSHOT_STATEMENTS,
    },
    [SurveyProductTypeEnum.RESIDENT]: {
      chartKey: TimeTrendingChartKey.RESIDENT_SNAPSHOT_STATEMENTS,
      surveysKey: TimeTrendingSurveysKey.RESIDENT_SNAPSHOT_STATEMENTS,
    },
  }[survey.productType]
  const includesPulse = usePulseInTimeTrending(availableSurveys, surveysKey)
  let tooltip =
    'These are the statements that increased or decreased on the survey you have selected. We’re comparing those scores to the most recent survey before it. To change which surveys are being compared, change the surveys you have selected below.'
  if (includesPulse) {
    tooltip += ' Only statements included in all selected surveys are included.'
  }
  return (
    <div className={classes.fullRow} id="tt-statements-snapshot-snap">
      <SnapshotChartHeader
        title={`Statement scores that ${orderLabel[selectedOrder]} MOST OVER TIME`}
        tooltip={tooltip}
        hasTimeTrending
        timeTrendingChartKey={chartKey}
        snapId="tt-statements-snapshot-snap"
        screenshotStrategy="html2canvas"
        useBottomPadding={false}
        extraControls={
          <TextDropdown
            displayEmpty
            value={selectedOrder}
            renderValue={order => `Most ${orderLabel[order as ORDER_TYPES]}`}
            onChange={e => setSelectedOrder(e.target.value as ORDER_TYPES)}
            label={
              <Typography variant="body2" color="textSecondary">
                Show:&nbsp;
              </Typography>
            }
          >
            {Object.values(ORDER_TYPES).map(order => {
              return (
                <MenuItem key={order} value={order}>
                  <Typography>Most {orderLabel[order as ORDER_TYPES]}</Typography>
                </MenuItem>
              )
            })}
          </TextDropdown>
        }
      />
      <TimeTrendingContainer
        chartSurveysKey={surveysKey}
        availableSurveys={availableSurveys}
        survey={survey}
      >
        {({ selectedSurveys }) => (
          <DynamicSurveyQuery
            surveys={selectedSurveys}
            variables={{ filters, benchmark }}
            queryHook={useInsightsStatementsQuery}
          >
            {(data, loading) => {
              const statements = getStatementsList(data, selectedSurveys)
              return (
                <TTBasicStatementsCard
                  statementsData={transformTimeTrendingStatementsToEchartScores({
                    statements,
                    results: data,
                    order: selectedOrder,
                  }).slice(0, MAX_STATEMENTS)}
                  xAxisLabelData={selectedSurveys}
                  benchmarkName={benchmark.name}
                  didSelectFilters={!loading && filters.length > 0}
                />
              )
            }}
          </DynamicSurveyQuery>
        )}
      </TimeTrendingContainer>
    </div>
  )
}

export default TTSnapshotStatementsCard
