import React from 'react'

import { makeStyles, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import EditIcon from '@material-ui/icons/Edit'
import FlagIcon from '@material-ui/icons/Flag'
import WarningIcon from '@material-ui/icons/Warning'
import cn from 'classnames'

import IconButton from 'components/Blocks/CustomButtons/IconButton'

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  section: {
    padding: `${spacing(2)}px ${spacing(3)}px`,
    alignItems: 'flex-start',
    backgroundColor: palette.common.white,
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  sectionNotReady: {
    '& $section': {
      backgroundColor: palette.common.navy05,
    },
  },
  sectionHasWarnings: {
    '& $sectionIcon': {
      color: palette.common.warning,
    },
  },
  sectionTitle: {
    flex: '0 0 40%',
    '& span': {
      fontSize: '2rem',
    },
  },
  sectionDetails: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.6rem',
    fontWeight: 'normal',
    '& ul': {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
    },
  },
  success: {
    color: palette.common.success,
  },
  error: {
    color: palette.error.main,
  },
  warning: {
    color: palette.common.warning,
  },
}))

export enum SectionsEnum {
  DESIGN = 'Survey Design',
  PARTICIPANTS = 'Participants',
  QUESTIONS = 'Questions',
  TRANSLATIONS = 'Translations',
  FILTER_VALUES = 'Filter Values',
  NOTIFICATIONS = 'Notifications',
}

type Props = {
  editable: boolean
  goToStep(): void
  title: string
  ready: boolean
  warningsNumber: number
  locked?: boolean
}

const Section: React.FC<Props> = ({
  children,
  editable,
  goToStep,
  title,
  ready,
  warningsNumber,
  locked = false,
}) => {
  const classes = useStyles()
  return (
    <ListItem
      divider={title !== SectionsEnum.NOTIFICATIONS}
      className={classes.section}
      ContainerProps={{
        className: cn({
          [classes.sectionNotReady]: editable && !ready,
          [classes.sectionHasWarnings]: ready && warningsNumber,
        }),
      }}
    >
      <ListItemText
        className={classes.sectionTitle}
        primary={
          <>
            {title}
            {editable && !locked && (
              <IconButton onClick={goToStep} color="secondaryHover">
                <EditIcon />
              </IconButton>
            )}
          </>
        }
      />
      <div className={classes.sectionDetails}>
        <ListItemText primary={children} />
        {editable && (
          <ListItemIcon
            className={cn({
              [classes.success]: ready && !warningsNumber,
              [classes.warning]: ready && warningsNumber,
              [classes.error]: !ready,
            })}
          >
            {!ready && <FlagIcon />}
            {ready && warningsNumber > 0 && <WarningIcon />}
            {ready && !warningsNumber && <CheckIcon />}
          </ListItemIcon>
        )}
      </div>
    </ListItem>
  )
}

export default Section
