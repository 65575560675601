import React, { Component } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import bgImage from './assets/login.jpg'
import Footer from './Footer'
import Header from './Header'

const layoutStyle = theme => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      minHeight: '100%',
      backgroundAttachment: 'fixed',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: `url(${bgImage})`,
    },
  },

  wrapper: {
    height: 'auto',
    minHeight: '100vh',
    position: 'relative',
    top: '0',
    overflow: 'hidden',
  },
  fullPage: {
    '&:before': {
      backgroundColor: theme.palette.common.navy,
      opacity: '.8',
    },
    '&:before,&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: '-1',
    },
  },
})

class AnonymousLayout extends Component {
  render() {
    const { classes, children, ...rest } = this.props
    return (
      <div className={classes.wrapper}>
        <div className={classes.fullPage}>
          <Header {...rest} />
          {children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default withStyles(layoutStyle)(AnonymousLayout)
