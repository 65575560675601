import React from 'react'

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Redirect, Route, Switch } from 'react-router-dom'

import WizardRouter from 'components/Survey/WizardRouter'
import SurveyDashboardContainer from 'containers/Survey/SurveyDashboardContainer'
import SurveyContainer from 'containers/Survey/Wizard/SurveyContainer'
import withErrorHandler from 'HOC/withErrorHandler'
import { SURVEY_DETAILS, URLS } from 'utils/constants'

const SurveyRoutes = () => {
  return (
    <>
      <BreadcrumbsItem to={URLS.SURVEYS.DASHBOARD}>Surveys</BreadcrumbsItem>
      <Switch>
        <Route exact path={URLS.SURVEYS.DASHBOARD} component={SurveyDashboardContainer} />
        <Route
          exact
          path={URLS.SURVEYS.CREATE}
          render={props => {
            return <WizardRouter surveyType={props.location?.state?.type} />
          }}
        />
        <Route path={SURVEY_DETAILS} render={props => <SurveyContainer {...props} />} />
        <Redirect to={URLS.SURVEYS.DASHBOARD} />
      </Switch>
    </>
  )
}

export default withErrorHandler(SurveyRoutes)
