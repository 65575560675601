import React from 'react'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'

import { ERROR_CODES } from 'utils/apiErrorCodes'

const useStyles = makeStyles(() => ({
  errors: {
    fontSize: '1.6rem',
  },
}))

const Error = ({ error }) => {
  const classes = useStyles()
  return (
    <Typography color="error" className={classes.errors}>
      {ERROR_CODES[error] || error}
    </Typography>
  )
}

const Errors = ({ errors }) => {
  if (!errors) return <></>
  if (typeof errors === 'string') return <Error error={errors} key={1} />
  return errors.map((error, key) => <Error error={error} key={key} />)
}

export default Errors
