import React, { useState, useEffect, ReactElement, useCallback } from 'react'

import {
  Badge,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Input,
  makeStyles,
  Popover,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { ExpandMore, Search } from '@material-ui/icons'
import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'

import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import PopoverCheckboxGroup from 'components/Blocks/Popups/PopoverCheckboxGroup'
import PopoverRadioButtonGroup from 'components/Blocks/Popups/PopoverRadioButtonGroup'
import AlphaScrollList from 'components/Insights/Blocks/AlphaScrollList'
import { colors } from 'shared/theme'

const useStyles = makeStyles(({ spacing, palette }) => ({
  titleBar: {
    justifyContent: 'space-between',
    '& >p': {
      fontSize: '2.0rem',
    },
  },
  searchBar: {
    display: 'flex',
    paddingBottom: spacing(2),
    width: '100%',
    alignItems: 'center',
  },
  searchIcon: {
    height: '2rem',
    marginBottom: -3,
    color: palette.common.navy65,
  },
  searchInput: {
    width: '100%',
  },
  tab: {
    fontSize: '1.4rem',
  },
  cardsContainer: {
    borderBottom: `1px solid ${colors.navy25}`,
  },
  cardContent: {
    borderTop: `1px solid ${colors.navy25}`,
  },
  cardHeader: {
    borderBottom: `1px solid ${colors.navy25}`,
    marginBottom: -1,
  },
  itemGroup: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  badge: {
    top: '50%',
    right: -12,
    height: 11,
    width: 11,
    minWidth: 11,
    fontSize: 8,
    color: '#FFF',
    backgroundColor: colors.warning,
  },
  dialogButtons: {
    position: 'relative',
  },
}))

type Props<Item extends DefaultItem> = {
  selected?: Item
  selectedItems?: string[]
  multiple?: boolean
  maxSelectedItems?: number | null
  header?: ReactElement | null
  items: Item[]
  onChange?(selected: Item): void
  onMultiselectChange?(selected: string[]): void
  getItemValue(item: Item): string
  getItemLabel(item: Item): string
  getItemDisabledMessage?(item: Item): string
  showAlphaList?: boolean
  anchorEl: Element
  onClose(): void
  additionalControls?: ReactElement
  submitButtonText?: string
}

type DefaultItem = {
  [key: string]: any
  group?: string
  subgroup?: string
}

/**
 * Selection tool that renders groups of options
 * If multiple = false, render a group of radio buttons and allow for a single selection.
 * If multiple = true, render a group of checkboxes and allow for a group of selections.
 */
const GroupedSelect = <Item extends DefaultItem, _>({
  selected,
  selectedItems: defaultSelectedItems = [],
  multiple = false,
  maxSelectedItems = 8,
  header = null,
  items,
  onChange = () => {},
  onMultiselectChange = () => {},
  getItemValue,
  getItemLabel,
  getItemDisabledMessage = () => '',
  showAlphaList = true,
  anchorEl,
  onClose,
  additionalControls,
  submitButtonText = 'Add',
}: Props<Item>): ReactElement => {
  const classes = useStyles()

  const tabNames = uniq(items.map(i => i.group))
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems)
  // Join default items object in a hash so that the `useEffect` comparison passes shallow equality.
  const defaultSelectedItemsHash = defaultSelectedItems.join('||')
  useEffect(() => {
    setSelectedItems(defaultSelectedItemsHash.split('||'))
  }, [defaultSelectedItemsHash])

  const getActiveTab = () => {
    const getItemById = (id: string) => {
      return items.find(i => i.uuid === id)
    }
    if (selected?.group) {
      return selected.group
    }
    if (selectedItems.length) {
      return getItemById(selectedItems[0])?.group
    }
    return tabNames && tabNames[0]
  }

  const [activeTabName, setActiveTabName] = useState(getActiveTab())
  const tabItems = items.filter(i => i.group === activeTabName || undefined)
  const subgroups: { [key: string]: Item[] } = groupBy(tabItems, i => i.subgroup || i.group)
  const subgroupNames = Object.keys(subgroups)

  const getActiveSubGroupName = useCallback(() => {
    if (selected?.subgroup && subgroupNames.includes(selected.subgroup)) {
      return selected.subgroup
    }
    return subgroupNames.length ? subgroupNames[0] : ''
  }, [selected, subgroupNames])

  const [activeSubgroupName, setActiveSubgroupName] = useState(getActiveSubGroupName())
  useEffect(() => {
    if (!activeSubgroupName || !subgroupNames.includes(activeSubgroupName)) {
      setActiveSubgroupName(getActiveSubGroupName())
    }
  }, [activeSubgroupName, getActiveSubGroupName, subgroupNames])

  const maxWidth = 540
  const minWidth = 400
  const popoverWidth = tabNames.length > 3 ? maxWidth : minWidth

  useEffect(() => {
    setTimeout(() => {
      // This is due to a bug with material-ui that causes the selected tab indicator
      // to render incorrectly on first load:
      // https://github.com/mui-org/material-ui/issues/9337
      window.dispatchEvent(new CustomEvent('resize'))
    }, 0)
  }, [])

  // If multiple selection and the current tab has active items, render a badge number.
  const getTabLabel = (tabName: string) => {
    if (!multiple) return tabName
    const tabActiveItems = items
      .filter(i => selectedItems.includes(getItemValue(i)))
      .filter(i => i.group === tabName)
    if (!tabActiveItems.length) return tabName
    return (
      // Use `classes` prop so the children avoid inheriting the class.
      <Badge badgeContent={tabActiveItems.length} classes={{ badge: classes.badge }}>
        {tabName}
      </Badge>
    )
  }

  const toggleActivatedItem = (didCheck: boolean, value: string) => {
    const nextActiveItems = didCheck
      ? [...selectedItems, value]
      : selectedItems.filter(f => f !== value)
    setSelectedItems(nextActiveItems)
  }

  const renderItemGroup = (groupItems: Item[], title: string, scroll = false) => (
    <div className={`${scroll && classes.itemGroup}`}>
      <ItemGroup
        title={title}
        items={groupItems}
        selected={selected}
        selectedItems={selectedItems}
        onChangeSingleSelect={onChangeSingleSelect}
        toggleActivatedItem={toggleActivatedItem}
        getItemValue={getItemValue}
        getItemLabel={getItemLabel}
        maxSelectedItems={maxSelectedItems}
        multiple={multiple}
        getItemDisabledMessage={getItemDisabledMessage}
      />
    </div>
  )

  const onChangeSingleSelect = (newSelected: Item) => {
    onChange(newSelected)
    onClose()
  }

  return (
    <>
      <Popover
        open
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
      >
        {header}
        {tabNames.length > 1 ? (
          <Tabs
            value={tabNames.indexOf(activeTabName)}
            onChange={(e, value) => setActiveTabName(tabNames[value])}
          >
            {tabNames.map(tabName => {
              if (!tabName) return <></>
              return (
                <Tab
                  key={tabName}
                  label={getTabLabel(tabName)}
                  className={classes.tab}
                  style={{
                    width: popoverWidth / tabNames.length,
                    minWidth: popoverWidth / tabNames.length,
                  }}
                />
              )
            })}
          </Tabs>
        ) : null}
        <div className={classes.cardsContainer} style={{ width: popoverWidth }}>
          {subgroupNames.map(subgroupName => {
            if (!subgroupName) return <></>
            const showSubgroup = !activeSubgroupName || activeSubgroupName === subgroupName
            return (
              <SubgroupCardContent
                key={subgroupName}
                show={showSubgroup}
                items={subgroups[subgroupName]}
                selectedItems={selectedItems}
                setActiveSubgroupName={setActiveSubgroupName}
                subgroupName={subgroupName}
                title={subgroupName}
                getItemLabel={getItemLabel}
                maxSelectedItems={maxSelectedItems}
                showAlphaList={showAlphaList}
                renderItemGroup={renderItemGroup}
              />
            )
          })}
        </div>
        <div className={classes.dialogButtons}>
          {additionalControls}
          {multiple && (
            <ActionDialogButtons
              onClose={onClose}
              onSubmit={() => {
                onMultiselectChange(selectedItems)
                onClose()
              }}
              cancelButtonText="Close"
              submitButtonText={submitButtonText}
            />
          )}
        </div>
      </Popover>
    </>
  )
}

type SubgroupProps<Item> = {
  show: boolean
  items: Item[]
  selectedItems: string[]
  setActiveSubgroupName(name: string): void
  subgroupName: string
  title: string
  getItemLabel(item: Item): string
  maxSelectedItems: number | null
  showAlphaList: boolean
  renderItemGroup(items: Item[], title: string, scroll?: boolean): JSX.Element
}

const SubgroupCardContent = <Item extends DefaultItem>({
  show,
  items,
  selectedItems,
  setActiveSubgroupName,
  subgroupName,
  title,
  getItemLabel,
  maxSelectedItems,
  showAlphaList,
  renderItemGroup,
}: SubgroupProps<Item>): ReactElement => {
  const classes = useStyles()

  const [searchQuery, setSearchQuery] = useState('')

  const maxItemListLength = 10
  const TitleComponent = (
    <div className={classes.titleBar}>
      <Typography>{title}</Typography>
    </div>
  )

  const filteredItems = items.filter(item =>
    getItemLabel(item)
      .toUpperCase()
      .includes(searchQuery.toUpperCase()),
  )

  return (
    <div className={classes.cardContent} key={title}>
      <CardHeader
        className={classes.cardHeader}
        action={
          !show ? (
            <IconButton onClick={() => setActiveSubgroupName(subgroupName)}>
              <ExpandMore />
            </IconButton>
          ) : null
        }
        title={TitleComponent}
      />
      <Collapse in={show} timeout="auto" unmountOnExit>
        <CardContent>
          {items.length > maxItemListLength ? (
            <>
              {/** Allow searching when the item list is large and currently displayed. */}
              <div className={classes.searchBar}>
                <Search className={classes.searchIcon} />
                <Input
                  className={classes.searchInput}
                  placeholder={`Search ${title}`}
                  onChange={e => setSearchQuery(e.target.value)}
                />
              </div>
              {showAlphaList ? (
                <AlphaScrollList
                  disabled={maxSelectedItems !== null && selectedItems.length >= maxSelectedItems}
                  getItemLabel={getItemLabel}
                  items={filteredItems}
                >
                  {(groupItems: Item[]) => renderItemGroup(groupItems, title)}
                </AlphaScrollList>
              ) : (
                renderItemGroup(filteredItems, title, true)
              )}
            </>
          ) : (
            renderItemGroup(filteredItems, title, true)
          )}
        </CardContent>
      </Collapse>
    </div>
  )
}

type ItemGroupProps<Item> = {
  title: string
  items: Item[]
  selected?: Item
  selectedItems: string[]
  onChangeSingleSelect(selected: Item): void
  toggleActivatedItem(didCheck: boolean, value: string): void
  getItemValue(item: Item): string
  getItemLabel(item: Item): string
  maxSelectedItems: number | null
  multiple: boolean
  getItemDisabledMessage?(item: Item): string
}

const ItemGroup = <Item extends DefaultItem>({
  title,
  items,
  selected,
  selectedItems,
  onChangeSingleSelect,
  toggleActivatedItem,
  getItemValue,
  getItemLabel,
  maxSelectedItems,
  multiple,
  getItemDisabledMessage,
}: ItemGroupProps<Item>): ReactElement => {
  const [disabled, setDisabled] = useState(false)
  useEffect(() => {
    setDisabled(maxSelectedItems !== null && selectedItems.length >= maxSelectedItems)
  }, [maxSelectedItems, selectedItems.length])
  if (multiple) {
    return (
      <PopoverCheckboxGroup
        items={items}
        activeItems={selectedItems}
        getItemValue={getItemValue}
        getItemLabel={getItemLabel}
        disabled={disabled}
        toggleActivatedItem={toggleActivatedItem}
        groupId={title}
      />
    )
  }
  return (
    <PopoverRadioButtonGroup
      items={items}
      onSelect={onChangeSingleSelect}
      activeOption={selected!}
      getItemValue={getItemValue}
      getItemLabel={getItemLabel}
      getItemDisabledMessage={getItemDisabledMessage}
      groupId={title}
    />
  )
}

export default GroupedSelect
