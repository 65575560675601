import React, { Component, PureComponent } from 'react'

import { ButtonBase, Checkbox, Typography, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import countBy from 'lodash/countBy'
import PropTypes from 'prop-types'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import { ReactComponent as MinShowableResultsIcon } from 'assets/img/min-showable-results.svg'
import QueryHandler from 'components/Blocks/Layout/QueryHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import HeatmapInfoTooltip from 'components/Insights/Heatmap/HeatmapInfoTooltip'
import { gaEvent } from 'config/ga'
import { InsightsHeatmapDocument, SurveyProductTypeEnum } from 'generated/graphql'
import { colors } from 'shared/theme'
import {
  HEATMAP_GRADIENT_VALUES,
  RESIDENT_RESULTS_GROUP_LABELS,
  MIN_SHOWABLE_RESULTS_CODE,
  MIN_SHOWABLE_RESULTS,
} from 'utils/constants'

const commonPropTypes = {
  classes: PropTypes.object,
  cells: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        score: PropTypes.number,
      }),
    ),
  ),
  cellWidth: PropTypes.number,
  cellHeight: PropTypes.number,
  categories: PropTypes.arrayOf(PropTypes.string),
  statements: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      focus: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  benchmarkData: PropTypes.shape({
    name: PropTypes.string,
    scores: PropTypes.arrayOf(PropTypes.number),
  }),
  dimensionCounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      size: PropTypes.number,
    }),
  ),
}

/**
 * Grid implemented with HTML Canvas and a single tooltip repositioned on mousemove to optimize performance.
 */
class PureHeatmapGrid extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      hoverColumnIndex: 0,
      hoverRowIndex: 0,
      tooltipX: 0,
      tooltipY: 0,
    }
    this.heatmapTooltipWidth = 242
  }

  componentDidMount() {
    if (this.canvas) {
      this.calculateCellScoreRange(this.props.cells, this.props.benchmarkData)
      this.drawGrid()
    }
  }

  componentDidUpdate(prevProps) {
    // Rerender the grid if the benchmark or category changed.
    if (
      (this.canvas &&
        (prevProps.benchmarkData !== this.props.benchmarkData ||
          prevProps.categories !== this.props.categories)) ||
      prevProps.cellHeight !== this.props.cellHeight
    ) {
      this.calculateCellScoreRange(this.props.cells, this.props.benchmarkData)
      this.drawGrid()
    }
  }

  onMouseMove = e => {
    if (!this.canvas) {
      return
    }
    const canvasRect = this.canvas.getBoundingClientRect()
    const { cellWidth, cellHeight } = this.props
    // Find the mouse position within the grid by taking the mouse viewport coordinates and subtracting
    // the grid offset and half of the cell width (the position defaults to the center of the square).
    const columnIndex = Math.abs(
      Math.round((e.clientX - canvasRect.left - cellWidth / 2) / cellWidth),
    )
    const rowIndex = Math.abs(
      Math.round((e.clientY - canvasRect.top - cellHeight / 2) / cellHeight),
    )
    // If mouse enters a new rectangle, update the hover and tooltip position.
    if (columnIndex !== this.state.hoverColumnIndex || rowIndex !== this.state.hoverRowIndex) {
      this.setState({
        hoverColumnIndex: columnIndex,
        hoverRowIndex: rowIndex,
        tooltipX: e.clientX,
        tooltipY: e.clientY,
      })
    }
  }

  getScoreComment = (scoreDifferential, benchmarkData, cellColor, columnIndex) => {
    const percentage = Math.abs(Math.round(scoreDifferential * 100))
    let comment = ''
    if (!benchmarkData || !benchmarkData.scores[columnIndex]) {
      comment = 'No benchmark score for this statement'
    } else if (percentage === 0) {
      comment = `Same score as ${benchmarkData.name}`
    } else {
      comment = scoreDifferential < 0 ? '↓' : '↑'
      comment += ` ${percentage} pt`
      if (scoreDifferential > 0.01) {
        comment += 's'
      }
      comment += scoreDifferential < 0 ? ' less ' : ' greater '
      comment += ` than ${benchmarkData.name}`
    }
    return (
      <Typography style={{ color: cellColor }} className={this.props.classes.heatmapCellComment}>
        {comment}
      </Typography>
    )
  }

  getScoreDifferential = (cell, benchmarkData, columnIndex) => {
    if (columnIndex >= benchmarkData.scores.length || !cell) {
      return 0
    }
    const benchmarkScore = benchmarkData.scores[columnIndex]
    if ([cell.score, benchmarkScore].includes(MIN_SHOWABLE_RESULTS_CODE)) {
      // On cells with <minShowableResults responses, keep the score differential the same so we can render these cells grey.
      return MIN_SHOWABLE_RESULTS_CODE
    }
    const scoreDifferential = (cell.score - benchmarkScore) / 100
    // Ignore nulls and artificially low and high scores.
    if (!benchmarkScore || !cell.score || scoreDifferential > 0.5 || scoreDifferential < -0.7) {
      return 0
    }
    return scoreDifferential
  }

  getCellColor = scoreDifferential => {
    if (scoreDifferential === MIN_SHOWABLE_RESULTS_CODE) {
      return colors.navy65
    }
    let differential = scoreDifferential
    if (scoreDifferential < 0) {
      differential = -differential / this.minScoreDifferential
    } else {
      differential /= this.maxScoreDifferential
    }
    // Convert scores between -1 and 1 to be between 0 and 1
    const colorScore = ((differential + 1) / 2).toFixed(2)
    return HEATMAP_GRADIENT_VALUES[colorScore]
  }

  calculateCellScoreRange = (cells, benchmarkData) => {
    this.minScoreDifferential = 1
    this.maxScoreDifferential = -1
    cells.forEach(cellRow =>
      cellRow.forEach((cell, columnIndex) => {
        if (cell.score === MIN_SHOWABLE_RESULTS_CODE) {
          // These would throw range way off
          return
        }
        const scoreDifferential = this.getScoreDifferential(cell, benchmarkData, columnIndex)
        if (scoreDifferential === 0) {
          return
        }
        this.minScoreDifferential = Math.min(this.minScoreDifferential, scoreDifferential)
        this.maxScoreDifferential = Math.max(this.maxScoreDifferential, scoreDifferential)
      }),
    )
    // If all of the scores are near the benchmark, move the min score closer to an industry average.
    if (this.minScoreDifferential > -0.02) {
      this.minScoreDifferential = -0.1
    }
  }

  drawGrid = () => {
    const { cells, cellWidth, cellHeight, benchmarkData } = this.props
    const ctx = this.canvas.getContext('2d')
    let columnOffset = 0
    let rowOffset = 0
    cells.forEach(cellRow => {
      columnOffset = 0
      cellRow.forEach((cell, columnIndex) => {
        const scoreDifferential = this.getScoreDifferential(cell, benchmarkData, columnIndex)
        ctx.fillStyle = this.getCellColor(scoreDifferential)
        // Subtract 1 from the width and height to simulate a white border.
        ctx.fillRect(columnOffset, rowOffset, cellWidth - 1, cellHeight - 1)
        columnOffset += cellWidth
      })
      rowOffset += cellHeight
    })
  }

  renderTooltip = () => {
    const {
      survey,
      categories,
      classes,
      cells,
      cellHeight,
      cellWidth,
      statements,
      benchmarkData,
    } = this.props
    const { hoverColumnIndex, hoverRowIndex } = this.state
    if (hoverRowIndex >= cells.length || hoverColumnIndex >= cells[0].length) {
      return null
    }
    const statement = statements[hoverColumnIndex]
    const cell = cells[hoverRowIndex][hoverColumnIndex]
    const scoreDifferential = this.getScoreDifferential(cell, benchmarkData, hoverColumnIndex)
    const cellColor = this.getCellColor(scoreDifferential)
    const benchmarkScore = benchmarkData.scores[hoverColumnIndex]

    const tooltipStyle = {
      top: this.state.tooltipY + cellHeight,
      left: this.state.tooltipX + cellWidth,
      width: this.heatmapTooltipWidth,
    }
    // Flip the hover to the left side when hovering on the right side of the screen.
    if (hoverColumnIndex > statements.length / 2) {
      tooltipStyle.left -= this.heatmapTooltipWidth + cellWidth
    }
    let statementText = statement.text
    // Trim period at the end of statement.
    if (statementText[statementText.length - 1] === '.') {
      statementText = statementText.slice(0, -1)
    }
    return (
      <div style={tooltipStyle} className={classes.heatmapTooltip}>
        <div className={classes.heatmapGridTooltip}>
          <Typography variant="body1">{categories[hoverRowIndex] || 'No Response'}</Typography>
          <Typography variant="body2">{`${statementText} — ${statement.focus}`}</Typography>
        </div>
        <div>
          {[cell.score, benchmarkScore].includes(MIN_SHOWABLE_RESULTS_CODE) ? (
            <Typography>{`<${survey.minShowableResults} Responses`}</Typography>
          ) : (
            <>
              <Typography
                style={{ color: cellColor }}
                className={classes.heatmapCellPercentage}
              >{`${Math.round(cell.score.toFixed(2))}%`}</Typography>
              {this.getScoreComment(scoreDifferential, benchmarkData, cellColor, hoverColumnIndex)}
            </>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { classes, cells, cellHeight, gridWidth } = this.props
    const gridHeight = cellHeight * cells.length - 1
    return (
      <div
        className={classes.heatmapGrid}
        onMouseMove={this.onMouseMove}
        style={{ height: gridHeight }}
      >
        {this.renderTooltip()}
        <canvas
          id="heatmap-grid"
          style={{ display: 'block' }}
          width={gridWidth}
          height={gridHeight}
          ref={e => (this.canvas = e)}
        />
      </div>
    )
  }
}

PureHeatmapGrid.propTypes = {
  classes: commonPropTypes.classes.isRequired,
  cells: commonPropTypes.cells.isRequired,
  categories: commonPropTypes.categories.isRequired,
  statements: commonPropTypes.statements.isRequired,
  benchmarkData: commonPropTypes.benchmarkData.isRequired,
  cellWidth: PropTypes.number.isRequired,
  cellHeight: PropTypes.number.isRequired,
}

class HeatmapGrid extends Component {
  constructor(props) {
    super(props)
    this.cellHeightZoomFactor = 2
    this.minCellHeight = 7
    this.maxCellHeight = 21
    this.minZoomRows = 30
    this.yAxisLabelCharLimit = 25
    // Grid width should be approximately 850px. Divide and round by the cellWidth
    // to make sure the grid is an exact multiple.
    this.gridWidth = Math.round(850 / props.cells[0].length) * props.cells[0].length
    this.state = {
      cellHeight: 13,
      selectedDemographic: props.defaultDemographic,
      isShowingDimensions: false,
    }
  }

  onCheckDimensions = () => {
    gaEvent({
      action: 'heatmapToggleDimensions',
      category: 'Insights',
    })
    this.setState(prevState => ({ isShowingDimensions: !prevState.isShowingDimensions }))
  }

  modifyCellHeight = shouldZoomIn => {
    const { cellHeight } = this.state
    if (shouldZoomIn) {
      this.setState({ cellHeight: cellHeight + this.cellHeightZoomFactor })
    } else {
      this.setState({ cellHeight: cellHeight - this.cellHeightZoomFactor })
    }
  }

  // Render the dimensions grid as an overlay to avoid rerendering the heatmap.
  renderDimensionsGrid = (cellWidth, cellHeight) => {
    if (!this.state.isShowingDimensions) {
      return null
    }
    const { dimensionCounts, classes } = this.props
    // Keep track of the dimension grid positions, accounting for the 1px left border.
    let leftOffset = -1
    return (
      <>
        <div className={this.props.classes.heatmapDimensionsGrid}>
          {dimensionCounts.map(({ name: dimension, size }, dimensionIndex) => {
            const name = dimension === 'null' ? 'Other Statements' : dimension
            // Handle dimension names that overflow the container.
            // Estimate 7px per character, better to be conservative and truncate than render too much text
            const characterLimit = (cellWidth * size) / 7
            const nameExceedsLimit = name.length > characterLimit
            let truncatedName = name
            if (nameExceedsLimit) {
              truncatedName = `${name.slice(0, characterLimit - 2)}...`
            }
            const dimensionTitleStyle = nameExceedsLimit ? classes.heatmapDimensionEllipsis : null
            const sectionWidth = size * cellWidth
            const sectionHeight = cellHeight * this.props.categories.length - 1
            leftOffset += sectionWidth
            const shouldShowDimensionGridLine = dimensionIndex < dimensionCounts.length - 1
            const Label = (
              <Typography variant="body2" style={{ width: sectionWidth }}>
                {truncatedName}
              </Typography>
            )
            return (
              <React.Fragment key={name}>
                <div className={dimensionTitleStyle}>
                  {nameExceedsLimit ? <Tooltip title={name}>{Label}</Tooltip> : Label}
                </div>
                {shouldShowDimensionGridLine && (
                  <div
                    className={classes.heatmapDimensionsGridLine}
                    style={{ left: leftOffset, height: sectionHeight }}
                  />
                )}
              </React.Fragment>
            )
          })}
        </div>
      </>
    )
  }

  renderZoomControls = cellHeight => {
    const { classes, categories } = this.props
    if (categories.length < this.minZoomRows) {
      return null
    }
    return (
      <div className={classes.heatmapZoomControls}>
        <ButtonBase
          onClick={() => this.modifyCellHeight(true)}
          disabled={cellHeight === this.maxCellHeight}
        >
          <Typography style={cellHeight === this.maxCellHeight ? { color: 'grey' } : null}>
            +
          </Typography>
        </ButtonBase>
        <ButtonBase
          onClick={() => this.modifyCellHeight(false)}
          disabled={cellHeight === this.minCellHeight}
        >
          <Typography style={cellHeight === this.minCellHeight ? { color: 'grey' } : null}>
            –
          </Typography>
        </ButtonBase>
      </div>
    )
  }

  renderYAxisLabel() {
    const { classes, categories, selectedCategory } = this.props
    const labelText = `${categories.length} ${selectedCategory.pluralText}`
    if (labelText.length < this.yAxisLabelCharLimit) {
      return (
        <Typography variant="body2" color="textSecondary" className={classes.heatmapYAxisLabel}>
          {labelText}
        </Typography>
      )
    }
    const truncatedText = `${labelText.slice(0, this.yAxisLabelCharLimit)}...`
    return (
      <Tooltip title={labelText}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.heatmapYAxisLabel}
          style={{ cursor: 'pointer' }}
        >
          {truncatedText}
        </Typography>
      </Tooltip>
    )
  }

  render() {
    const { classes, cells, statements, benchmark } = this.props
    if (!cells || !cells.length) {
      return null
    }
    const { isShowingDimensions, cellHeight } = this.state
    const cellWidth = Math.round(this.gridWidth / cells[0].length)
    return (
      <div className={classes.heatmapBody}>
        <div className={classes.heatmapBenchmarkTitle}>
          <Typography>
            <span>Benchmark:</span>&nbsp;{benchmark.name}
          </Typography>
          <Typography color="textSecondary">
            See how each group’s score compares to your selected benchmark.
          </Typography>
        </div>
        <div className={classes.heatmapTitleBar} style={{ maxWidth: this.gridWidth }}>
          <Typography variant="body2" color="textSecondary">
            {`${statements.length} Statements`}
          </Typography>
          <div className={classes.heatmapGradientContainer}>
            <Typography variant="body2" color="textSecondary">
              Overperforming
            </Typography>
            <div className={classes.heatmapGradientBar} />
            <Typography variant="body2" color="textSecondary">
              Underperforming
            </Typography>
            <div className={classes.smallInfoIcon}>
              <Tooltip title={<HeatmapInfoTooltip />}>
                <InfoIcon />
              </Tooltip>
            </div>
          </div>
          <div className={classes.checkboxWithLabel}>
            <Checkbox
              className={classes.heatmapCheckbox}
              checked={isShowingDimensions}
              onChange={this.onCheckDimensions}
            />
            <Typography variant="body2" color="textSecondary">
              Dimensions
            </Typography>
          </div>
        </div>
        {this.renderYAxisLabel()}
        <div className={classes.heatmapGridContainer} style={{ maxWidth: this.gridWidth }}>
          {this.renderDimensionsGrid(cellWidth, cellHeight)}
          <PureHeatmapGrid
            {...this.props}
            cellWidth={cellWidth}
            cellHeight={cellHeight}
            gridWidth={this.gridWidth}
          />
          {this.renderZoomControls(cellHeight)}
        </div>
      </div>
    )
  }
}

HeatmapGrid.propTypes = {
  classes: commonPropTypes.classes.isRequired,
  cells: commonPropTypes.cells.isRequired,
  categories: commonPropTypes.categories.isRequired,
  statements: commonPropTypes.statements.isRequired,
  dimensionCounts: commonPropTypes.dimensionCounts.isRequired,
  benchmark: PropTypes.object.isRequired,
  benchmarkData: commonPropTypes.benchmarkData.isRequired,
}

const hasTooFewResponses = cells => {
  return cells.every(cellRow => cellRow.every(cell => cell.score === MIN_SHOWABLE_RESULTS_CODE))
}

const HeatmapGridContainer = props => {
  return (
    <QueryHandler
      query={InsightsHeatmapDocument}
      variables={{
        surveyUuid: props.survey.uuid,
        filters: props.filters,
        y: props.selectedCategory.code,
      }}
      requiredQueries={['insightsHeatmap']}
    >
      {({ insightsHeatmap }) => {
        const { categories, cells, statements } = insightsHeatmap
        if (!statements.length) {
          return (
            <EmptyState title="No data" description="No data to display." Icon={ChartUpwardIcon} />
          )
        }
        if (hasTooFewResponses(cells)) {
          return (
            <EmptyState
              title={`This survey has less than ${MIN_SHOWABLE_RESULTS} responses`}
              Icon={MinShowableResultsIcon}
              description={`Survey ${props.survey.name} was answered by fewer than ${MIN_SHOWABLE_RESULTS} participants.
                We can't show their responses to protect confidentiality.
                Please select a different Survey.`}
            />
          )
        }
        const dimensionOrder = { Credibility: 5, Respect: 4, Fairness: 3, Pride: 2, Camaraderie: 1 }
        const isResident = props.survey.productType === SurveyProductTypeEnum.RESIDENT
        const dimensionsField = isResident ? 'residentResultsGroup' : 'focus'
        const dimensionCountsMap = countBy(statements, dimensionsField)
        const dimensionCounts = Object.keys(dimensionCountsMap).map(name => ({
          name: isResident ? RESIDENT_RESULTS_GROUP_LABELS[name] : name,
          size: dimensionCountsMap[name],
        }))
        dimensionCounts.sort((a, b) => dimensionOrder[a.focus] > dimensionOrder[b.focus])
        const formattedStatements = statements.map(s => {
          let focus = s[dimensionsField]
          if (isResident) {
            focus = RESIDENT_RESULTS_GROUP_LABELS[focus].toUpperCase()
          }
          return { ...s, focus }
        })
        return (
          <HeatmapGrid
            cells={cells}
            categories={categories}
            statements={formattedStatements}
            dimensionCounts={dimensionCounts}
            {...props}
          />
        )
      }}
    </QueryHandler>
  )
}

HeatmapGridContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  selectedCategory: PropTypes.shape({
    code: PropTypes.string,
    text: PropTypes.string,
    plural_text: PropTypes.string,
  }).isRequired,
  benchmark: PropTypes.object.isRequired,
  benchmarkData: PropTypes.object.isRequired,
  defaultDemographic: PropTypes.object.isRequired,
}
export default HeatmapGridContainer
