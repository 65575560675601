import React from 'react'

import {
  Checkbox,
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  makeStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import cn from 'classnames'

import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import { SurveyInput } from 'components/Survey/Wizard/Steps/Design/Design'
import {
  FilterTypeReportingTypeEnum,
  SurveyFilterTypeSettingType,
  OrganizationFragment,
  DataTypeCode,
} from 'generated/graphql'
import { reverse } from 'utils'

const useFilterValueQuestionsStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    cursor: 'pointer',
    marginTop: spacing(5),
    // Create a negative margin so that we can use a full-width border but padding on the children.
    marginLeft: -spacing(3),
    marginRight: -spacing(3),
    '& >div:nth-child(2)': {
      borderBottom: `1px solid ${palette.common.navy25}`,
      borderTop: `1px solid ${palette.common.navy25}`,
    },
  },
  disabledSection: {
    '& >p, >h6, >div>h6': {
      color: palette.common.navy65,
    },
  },
  offsetMargin: {
    marginLeft: spacing(3),
    marginRight: spacing(3),
  },
  accordionsHeader: {
    borderTop: `2px solid ${palette.common.navy25}`,
    marginTop: spacing(4),
    paddingBottom: spacing(3),
    paddingTop: spacing(3),
  },
  accordionDetails: {
    borderTop: `1px solid ${palette.common.navy25}`,
    borderBottom: `1px solid ${palette.common.navy25}`,
    backgroundColor: palette.common.navy05,
  },
  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
    '& >h6': {
      marginRight: spacing(),
    },
  },
  reportingTypeSection: {
    paddingLeft: 30,
    '>p': {
      paddingBottom: 2,
      paddingTop: 2,
    },
  },
  reportingTypeLabel: {
    '& >span': {
      color: palette.common.navy65,
    },
  },
}))

// This is kept in sync with "REQUIRED_FT_CODES" on the backend, in "add_resident_questions.py"
export const REQUIRED_FT_CODES = [
  DataTypeCode.AI_LOC,
  DataTypeCode.RESIDENT_RESPONDENT_TYPE,
  DataTypeCode.AI_LOCATION,
]

type FilterValueQuestionsProps = {
  availableFilterTypes: OrganizationFragment['activeFilterTypes']
  surveyFilterTypeSettings: Omit<SurveyFilterTypeSettingType, '__typename'>[]
  updateSurvey(surveyAttrs?: Partial<SurveyInput>): void
  disableSection: boolean
}

const FilterValueQuestions: React.FC<FilterValueQuestionsProps> = ({
  availableFilterTypes,
  surveyFilterTypeSettings,
  updateSurvey,
  disableSection,
}) => {
  const classes = useFilterValueQuestionsStyles()

  const availableFilterTypeCodes = availableFilterTypes.map(filterType => filterType.dtCode)

  // availableFilterTypes is already ordered by index, so the lowest filter type will be the last one in availableFilterTypes that matches something among
  // surveyFilterTypeSettings
  const lowestFilterType = reverse(availableFilterTypes).find(({ dtCode }) =>
    surveyFilterTypeSettings.map(ftSetting => ftSetting.dtCode).includes(dtCode),
  )

  return (
    <div className={classes.root}>
      <div>
        <div
          className={cn({
            [classes.offsetMargin]: true,
            [classes.disabledSection]: disableSection,
          })}
        >
          <div
            className={cn({
              [classes.verticalAlign]: true,
              [classes.disabledSection]: disableSection,
            })}
          >
            <TitleWithInfoTooltip
              title="Filter and Share Results"
              tooltip={
                <>
                  <Typography>Filter and Share Results:</Typography>
                  <Typography color="textSecondary">
                    Use filters to see and share results by location or other groups. Select which
                    filters apply to this survey. Then choose if survey participants should
                    self-report each one or if you can map them in advance. Ex: Map regions to
                    locations and when a participant self-reports their location, their responses
                    will also be associated with their region.
                  </Typography>
                </>
              }
              padIcon={false}
            />
          </div>
          <Typography color="textSecondary" variant="body2">
            Select the filter types you want to include in the survey, and which ones you’d like
            participants to self-report. If “no” is selected, admin will need to map this data in
            advance. If “yes” is selected, participants will be asked about this in the survey. (Ex:
            if Location is to be self-reported, participants will be asked, “Please tell us the name
            of the community you are sharing feedback about:”).
          </Typography>
          <br />
          <Typography variant="body2">Select two or more:</Typography>
        </div>
        <div className={classes.accordionsHeader}>
          <Typography color="textSecondary" className={classes.offsetMargin}>
            FILTER TYPES
          </Typography>
        </div>
      </div>
      <div>
        {availableFilterTypes.map(filterType => {
          const selectedFilterTypeSetting = surveyFilterTypeSettings.find(
            ftSetting => ftSetting.dtCode === filterType.dtCode,
          )
          // The lowest filter type should always be self reported
          const isLast =
            lowestFilterType && selectedFilterTypeSetting?.dtCode === lowestFilterType.dtCode
          const isRequiredFilterType = REQUIRED_FT_CODES.includes(filterType.dtCode)
          // We want the RRT to always be self-reported
          const isRRT = filterType.dtCode === DataTypeCode.RESIDENT_RESPONDENT_TYPE
          const filterTypeDisabled = isRequiredFilterType || disableSection
          const selfReportRequired = Boolean(isRRT || isLast)
          // Build accordion props dynamically so that we allow the expandable section to be
          // uncontrolled as a default, and always set expanded true when the full component is disabled.
          const accordionProps: { expanded?: boolean } = {}
          if (disableSection) {
            accordionProps.expanded = true
          }
          return (
            <Accordion key={filterType.dtCode} elevation={0} {...accordionProps}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={filterType.dtCode}>
                <Tooltip title={filterTypeDisabled ? 'Required, cannot disable' : ''}>
                  <FormControlLabel
                    id={filterType.dtCode}
                    control={
                      <Checkbox
                        name={filterType.dtCode}
                        value={filterType.dtCode}
                        checked={isRequiredFilterType || Boolean(selectedFilterTypeSetting)}
                        disabled={filterTypeDisabled}
                        onChange={e => {
                          let newSurveyFilterTypeSettings = [...surveyFilterTypeSettings]
                          if (e.target.checked) {
                            newSurveyFilterTypeSettings.push({
                              dtCode: filterType.dtCode,
                              reportingType: FilterTypeReportingTypeEnum.ADMIN_MAPPING,
                            })
                          } else {
                            newSurveyFilterTypeSettings = surveyFilterTypeSettings.filter(
                              ftSetting => ftSetting.dtCode !== filterType.dtCode,
                            )
                          }

                          // Sort from highest filter type to lowest
                          newSurveyFilterTypeSettings.sort(
                            (ft1, ft2) =>
                              availableFilterTypeCodes.indexOf(ft1.dtCode) -
                              availableFilterTypeCodes.indexOf(ft2.dtCode),
                          )

                          // The lowest filter type should always be self reported
                          if (newSurveyFilterTypeSettings.length) {
                            const lastFilterCode =
                              newSurveyFilterTypeSettings[newSurveyFilterTypeSettings.length - 1]
                                .dtCode
                            newSurveyFilterTypeSettings = [
                              ...newSurveyFilterTypeSettings.slice(0, -1),
                              {
                                reportingType: FilterTypeReportingTypeEnum.SELF_REPORTED,
                                dtCode: lastFilterCode,
                              },
                            ]
                          }
                          updateSurvey({ filterTypeSettings: newSurveyFilterTypeSettings })
                        }}
                      />
                    }
                    label={filterType.name}
                  />
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <div className={classes.reportingTypeSection}>
                  <Typography color="textSecondary">Self reporting?</Typography>
                  <RadioGroup>
                    {[
                      {
                        reportingType: FilterTypeReportingTypeEnum.ADMIN_MAPPING,
                        label: 'No, Admin will map data',
                        id: `${filterType.dtCode}-admin-mapping`,
                      },
                      {
                        reportingType: FilterTypeReportingTypeEnum.SELF_REPORTED,
                        label: (
                          <Typography className={classes.reportingTypeLabel}>
                            Yes, Participants will self report &nbsp;
                            <span>
                              {selfReportRequired && '(Required, cannot change selection)'}
                            </span>
                          </Typography>
                        ),
                        id: `${filterType.dtCode}-self-reported`,
                      },
                    ]
                      // If self reporting is required, don't show the admin map option.
                      .filter(({ reportingType }) => {
                        return (
                          !selfReportRequired ||
                          reportingType !== FilterTypeReportingTypeEnum.ADMIN_MAPPING
                        )
                      })
                      .map(({ reportingType, label, id }) => (
                        <FormControlLabel
                          id={id}
                          key={reportingType + filterType.dtCode}
                          value={reportingType}
                          control={
                            <Radio
                              disabled={disableSection}
                              checked={
                                selectedFilterTypeSetting?.reportingType === reportingType ||
                                (!selectedFilterTypeSetting &&
                                  isRequiredFilterType &&
                                  reportingType === FilterTypeReportingTypeEnum.SELF_REPORTED)
                              }
                              onChange={() => {
                                const newFilterTypeSettings = surveyFilterTypeSettings.map(
                                  ftSetting => ({
                                    ...ftSetting,
                                    reportingType:
                                      ftSetting.dtCode === selectedFilterTypeSetting?.dtCode
                                        ? reportingType
                                        : ftSetting.reportingType,
                                  }),
                                )
                                return updateSurvey({
                                  filterTypeSettings: newFilterTypeSettings,
                                })
                              }}
                            />
                          }
                          label={label}
                        />
                      ))}
                  </RadioGroup>
                </div>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default FilterValueQuestions
