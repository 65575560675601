import React, { ReactElement } from 'react'

import { useApolloClient } from '@apollo/client'
import { makeStyles, Checkbox, Grid, Typography } from '@material-ui/core'
import cn from 'classnames'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import { EMPLOYEE_HEADERS, RESIDENT_HEADERS, getEmployeeSteps, getResidentSteps } from './constants'

import { ReactComponent as Image1 } from 'assets/img/action_plans/image1.svg'
import { ReactComponent as Image2 } from 'assets/img/action_plans/image2.svg'
import { ReactComponent as Image3 } from 'assets/img/action_plans/image3.svg'
import * as TakeAction1 from 'assets/img/action_plans/take-action-1.png'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import { gaEvent } from 'config/ga'
import {
  useInsightsTakeActionStepsQuery,
  useInsightsUpdateTakeActionStepsMutation,
  SurveyTypeEnum,
  TakeActionStepsEnum,
  CurrentUserDocument,
  CurrentUserQuery,
} from 'generated/graphql'
import { URLS } from 'utils/constants'

const useStyles = makeStyles(({ spacing, palette }) => ({
  banner1: {
    backgroundColor: palette.common.navy,
    color: palette.common.white,
    paddingLeft: spacing(10),
    paddingRight: spacing(5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  banner2: {
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& >div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& svg': {
      marginBottom: spacing(2),
    },
  },
  stepSection: {
    paddingTop: spacing(10),
    paddingLeft: spacing(10),
    paddingRight: spacing(8),
    paddingBottom: spacing(8),
    '& >h5': {
      marginBottom: spacing(2),
    },
  },
  oddStep: {
    backgroundColor: palette.common.lightGrey,
  },
  steps: {
    marginBottom: spacing(4),
    '& >div': {
      marginBottom: spacing(2),
    },
  },
  takingActionSubtitle: {
    marginBottom: spacing(2),
  },
  formControlRoot: {
    alignItems: 'start',
  },
  lineBreaker: {
    whiteSpace: 'pre-wrap',
  },
  checkboxRoot: {
    paddingTop: 3,
  },
  unchecked: {
    color: palette.common.navy65,
  },
}))

type StepsProps = {
  surveyUuid: string
  steps: [TakeActionStepsEnum, string | ReactElement][]
  checkedSteps: Array<string | null>
}

const Steps: React.FC<StepsProps> = ({ surveyUuid, steps, checkedSteps }) => {
  const classes = useStyles()
  const [updateSteps] = useInsightsUpdateTakeActionStepsMutation()
  return (
    <Grid container spacing={3} className={classes.steps}>
      {steps.map(([step, label]) => (
        <Grid item sm={6} key={step}>
          <FormControlLabel
            classes={{
              root: classes.formControlRoot,
              label: classes.lineBreaker,
            }}
            control={
              <Checkbox
                checked={checkedSteps.includes(step)}
                onChange={() => {
                  gaEvent({
                    action: 'actionPlanCheckBox',
                    category: 'Insights',
                  })
                  updateSteps({
                    variables: {
                      surveyUuid,
                      step,
                    },
                  })
                }}
                classes={{
                  root: classes.checkboxRoot,
                }}
                className={classes.unchecked}
              />
            }
            label={<Typography color="textSecondary">{label}</Typography>}
          />
        </Grid>
      ))}
    </Grid>
  )
}

type StepsTitleProps = {
  title: string
  subtitle: string
}

const StepsTitle: React.FC<StepsTitleProps> = ({ title, subtitle }) => {
  return (
    <Typography variant="h4">
      {title}{' '}
      <Typography component="span" variant="h5">
        {subtitle}
      </Typography>
    </Typography>
  )
}

type ImageBannerProps = {
  index: number
  title: string
  subtitle: string
}

const ImageBanner: React.FC<ImageBannerProps> = ({ index, title, subtitle }) => {
  return (
    <div>
      {index === 1 && <Image1 />}
      {index === 2 && <Image2 />}
      {index === 3 && <Image3 />}
      <Typography variant="h6">
        {index}. {title}
      </Typography>
      <Typography color="textSecondary">{subtitle}</Typography>
    </div>
  )
}

const TakeAction: React.FC<InsightsTabProps> = ({ survey }) => {
  const classes = useStyles()
  const client = useApolloClient()
  const { organization } = client.readQuery({ query: CurrentUserDocument })
    .currentUser as NonNullable<CurrentUserQuery>['currentUser']
  const result = useInsightsTakeActionStepsQuery({
    variables: {
      surveyUuid: survey.uuid,
    },
  })
  const isEmployeeTI = survey.type === SurveyTypeEnum.TI
  const steps = isEmployeeTI
    ? organization.solution &&
      getEmployeeSteps(survey.uuid, survey.type, survey.includesTestimonials, organization.solution)
    : organization.residentSolution &&
      getResidentSteps(
        survey.uuid,
        survey.type,
        survey.includesTestimonials,
        organization.residentSolution,
      )
  if (!steps) return <></>
  const headers = isEmployeeTI ? EMPLOYEE_HEADERS : RESIDENT_HEADERS
  return (
    <>
      <BreadcrumbsItem to={URLS.EMPLOYEE_INSIGHTS.TAKE_ACTION}>Take Action</BreadcrumbsItem>
      <div className={classes.banner1}>
        <div>
          <Typography variant="h4">
            Congratulations on capturing the voices of your{' '}
            {isEmployeeTI ? 'employees' : 'customers'}!
          </Typography>
          <br />
          <Typography variant="h5">
            Next, we recommend a 3-step process for you to become an even Greater Place to{' '}
            {isEmployeeTI ? 'Work' : 'Live'}:
          </Typography>
        </div>
        <div>
          <img width={400} src={TakeAction1} alt="banner1" />
        </div>
      </div>
      <div className={classes.banner2}>
        <ImageBanner index={1} title="Understand" subtitle="what is driving your results" />
        <ImageBanner index={2} title="Communicate" subtitle="across your organization" />
        <ImageBanner index={3} title="Improve" subtitle="by taking action" />
      </div>
      <ResponseHandler {...result}>
        {({ survey: apSurvey }) => {
          return (
            <>
              <div className={cn(classes.stepSection, classes.oddStep)}>
                <StepsTitle title="Understand" subtitle="what is driving your results" />
                <Typography color="textSecondary" className={classes.lineBreaker}>
                  {headers.UNDERSTAND}
                </Typography>
                <br />
                <Typography variant="h5">To Do List:</Typography>
                <Steps
                  surveyUuid={apSurvey.uuid}
                  steps={steps.UNDERSTAND}
                  checkedSteps={apSurvey.takeActionCheckedSteps}
                />
                <Typography>
                  Learn more about how to understand your results{' '}
                  <ExternalNavLink
                    to={
                      isEmployeeTI
                        ? 'https://activatedinsights.force.com/help/s/article/snapshot'
                        : 'https://activatedinsights.force.com/help/s/article/understand-your-Customer-Survey-results'
                    }
                    label="here"
                  />
                </Typography>
              </div>
              <div className={classes.stepSection}>
                <StepsTitle title="Communicate" subtitle="results across your organization" />
                <Typography color="textSecondary">{headers.COMMUNICATE}</Typography>
                <br />
                <Typography variant="h5">To Do List:</Typography>
                <Steps
                  surveyUuid={apSurvey.uuid}
                  steps={steps.COMMUNICATE}
                  checkedSteps={apSurvey.takeActionCheckedSteps}
                />
                <Typography>
                  Learn more about how to communicate results to your leadership team{' '}
                  <ExternalNavLink
                    to="https://activatedinsights.force.com/help/s/article/How-to-Communicate-Results-to-your-Leadership-Team"
                    label="here"
                  />{' '}
                  and to {isEmployeeTI ? 'employees' : 'customers'}{' '}
                  <ExternalNavLink
                    to={
                      isEmployeeTI
                        ? 'https://activatedinsights.force.com/help/s/article/communicate-results-to-your-Employees'
                        : 'https://activatedinsights.force.com/help/s/article/communicate-results-to-your-customers'
                    }
                    label="here"
                  />
                </Typography>
              </div>
              <div className={cn(classes.stepSection, classes.oddStep)}>
                <StepsTitle title="Improve" subtitle="by taking action" />
                <Typography color="textSecondary">{headers.COMMUNICATE}</Typography>
                <br />
                <Typography variant="h5">To Do List:</Typography>
                <Typography className={classes.takingActionSubtitle}>
                  Decide what to focus on:
                </Typography>
                <Steps
                  surveyUuid={apSurvey.uuid}
                  steps={steps.IMPROVE.slice(0, 4)}
                  checkedSteps={apSurvey.takeActionCheckedSteps}
                />
                <Typography className={classes.takingActionSubtitle}>Create a plan:</Typography>
                <Steps
                  surveyUuid={apSurvey.uuid}
                  steps={steps.IMPROVE.slice(5, 8)}
                  checkedSteps={apSurvey.takeActionCheckedSteps}
                />
                <Typography className={classes.takingActionSubtitle}>Check back in:</Typography>
                <Steps
                  surveyUuid={apSurvey.uuid}
                  steps={steps.IMPROVE.slice(9, 14)}
                  checkedSteps={apSurvey.takeActionCheckedSteps}
                />
                <Typography>
                  Learn more about action planning{' '}
                  <ExternalNavLink
                    to="https://activatedinsights.force.com/help/s/article/actionplans"
                    label="here"
                  />
                </Typography>
              </div>
            </>
          )
        }}
      </ResponseHandler>
    </>
  )
}

export default TakeAction
