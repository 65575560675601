import React, { ReactElement } from 'react'

import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    textTransform: 'uppercase',
    paddingBottom: spacing(2),
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& >p': {
      display: 'flex',
      alignItems: 'center',
      '& >div': {
        marginLeft: spacing(2),
      },
    },
  },
}))

type Props = {
  title?: string
  rows: Array<{ label: string; value: string | ReactElement }>
  labelWidth?: string
}
const InfoCard: React.FC<Props> = ({ title, rows, labelWidth = '30%' }) => {
  const classes = useStyles()
  return (
    <div>
      <Typography className={classes.title}>{title}</Typography>
      {rows.map(({ label, value }) => {
        let valueComponent = value
        if (typeof value === 'string') {
          valueComponent = <Typography>{value}</Typography>
        }
        return (
          <div className={classes.infoRow} key={label}>
            <Typography color="textSecondary" style={{ width: labelWidth }}>
              {label}
            </Typography>
            {valueComponent}
          </div>
        )
      })}
    </div>
  )
}

export default InfoCard
