import React from 'react'

import { Query } from '@apollo/client/react/components'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'

/**
 * Wrapper component for the generic Apollo Query that handles results in a predictable way.
 */
const QueryHandler = ({ children, hideLoader, requiredQueries, skip, ...queryVars }) => {
  if (skip) {
    // Empty object as data so destructuring would work where QueryHandler is used
    return children({})
  }
  return (
    <Query {...queryVars}>
      {result =>
        ResponseHandler({
          ...result,
          children,
          hideLoader,
          requiredQueries,
        })
      }
    </Query>
  )
}

export default QueryHandler
