import React from 'react'

import { makeStyles } from '@material-ui/core'

import {
  InsightsDemographic,
  InsightsOverallIndexQuery,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { ScoreTitleEnum } from 'utils/constants'

const useStyles = makeStyles(({ spacing, palette }) => ({
  tipContainer: {
    display: 'flex',
    padding: spacing(1),
    margin: 0,
  },
  tipLabels: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: spacing(1),
    textAlign: 'right',
  },
  tipValues: {
    display: 'flex',
    flexDirection: 'column',
    color: palette.common.navy65,
    paddingLeft: 0,
  },
}))

type Props = {
  y?: InsightsDemographic
  x?: InsightsDemographic
  xVal?: string | null
  yVal?: string | null
  data?: InsightsOverallIndexQuery['insightsOverallIndex']
  surveyProductType: SurveyProductTypeEnum
}

const ComparisonTooltip: React.FC<Props> = ({ y, yVal, x, xVal, data, surveyProductType }) => {
  const classes = useStyles()
  const count = data?.totalResponses
  if (!count || !data) return <></>
  const { positive, inconsistent, negative } = data
  const totalNonEmpty = positive + inconsistent + negative
  const statementScore = Math.round((positive / totalNonEmpty) * 100)

  const calculateDisplayValue = (total: number, score: number): string => {
    const scoreCount = (score / 100) * total
    return Math.round(scoreCount).toString()
  }

  const overallScoreLabel = ScoreTitleEnum[surveyProductType]
  return (
    <div className={classes.tipContainer}>
      <div className={classes.tipLabels}>
        <div>{y?.pluralText && <>{y?.pluralText}:</>}</div>
        <div>{x?.pluralText && <>{x?.pluralText}:</>}</div>
        <div>Score:</div>
        <div>Counts:</div>
      </div>
      <div className={classes.tipValues}>
        <div>{y?.text && <>{yVal || 'No Response'}</>}</div>
        <div>{x?.text && <>{xVal || 'No Response'}</>}</div>
        <div>
          {overallScoreLabel}:&nbsp;
          <span style={{ color: colors.navy }}>{statementScore}%</span>
        </div>
        <div>Total:&nbsp;{count}</div>
        <div>
          Positive:&nbsp;
          <span style={{ color: colors.success }}>
            {calculateDisplayValue(count, Math.round(data.positive))}
          </span>
        </div>
        <div>
          Inconsistent:&nbsp;
          <span style={{ color: colors.warning }}>
            {calculateDisplayValue(count, Math.round(data.inconsistent))}
          </span>
        </div>
        <div>
          Negative:&nbsp;
          <span style={{ color: colors.danger }}>
            {calculateDisplayValue(count, Math.round(data.negative))}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ComparisonTooltip
