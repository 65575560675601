import React, { useState } from 'react'

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import orderBy from 'lodash/orderBy'

import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import Danger from 'components/Blocks/Typography/Danger'
import { useSurveysUpdatePersonalInfoQuestionMutation, QuestionFragment } from 'generated/graphql'

const useStyles = makeStyles(({ spacing }) => ({
  choices: {
    paddingTop: spacing(4),
  },
  choicesDropdown: {
    marginTop: spacing(),
    justifyContent: 'flex-start',
  },
}))

const ALL_CHOICE = {
  uuid: 'ALL_CHOICES',
  text: 'All',
}

const CHOICES_COUNT_TO_SHOW_DROPDOWN = 6

const getQuestion = (initialQuestion: QuestionFragment) => {
  // When the dropdown is used, we want to check all options in the UI by default if no choices have been customised yet
  if (
    initialQuestion.choices.length === 0 &&
    initialQuestion.availableChoices.length > CHOICES_COUNT_TO_SHOW_DROPDOWN
  ) {
    return { ...initialQuestion, choices: [ALL_CHOICE, ...initialQuestion.availableChoices] }
  }
  return initialQuestion
}

type Props = {
  surveyUuid: string
  question: QuestionFragment
  onClose(): void
  updateChoicesOnly?: boolean
}

const UpdatePersonalInfoQuestion: React.FC<Props> = ({
  surveyUuid,
  onClose,
  question: initialQuestion,
  updateChoicesOnly = false,
}) => {
  const classes = useStyles()
  const [question, setQuestion] = useState(getQuestion(initialQuestion))

  const [error, setError] = useState('')
  const [updatePersonalInfoQuestion, { loading }] = useSurveysUpdatePersonalInfoQuestionMutation()

  const onChangeQuestion = (text: string) => setQuestion({ ...question, text })

  const onUpdateQuestion = async () => {
    await updatePersonalInfoQuestion({
      variables: {
        surveyUuid,
        questionInput: {
          questionUuid: question.uuid,
          text: question.text,
          choiceUuids: question.choices.map(c => c.uuid),
        },
      },
    })
    onClose()
  }
  const requiredErrorMessage = 'Select at least one answer option'

  return (
    <Dialog open fullWidth onClose={onClose} aria-labelledby="form-dialog-title">
      <div>
        <DialogTitle id="form-dialog-title">Edit Question</DialogTitle>
        <DialogContent>
          <TextField
            id="newCustomQuestionText"
            type="text"
            fullWidth
            disabled={updateChoicesOnly}
            value={question.text}
            placeholder="Enter your survey question here"
            onChange={e => onChangeQuestion(e.target.value)}
            inputProps={{ autoFocus: !question.text }}
          />
          {question.availableChoices.length > 0 && (
            <div className={classes.choices}>
              {error && <Danger key={error}>{error}</Danger>}
              <Typography color="textSecondary" variant="body2">
                Answer Options:
              </Typography>
              {question.availableChoices.length <= CHOICES_COUNT_TO_SHOW_DROPDOWN ? (
                <Grid container>
                  {orderBy(question.availableChoices, 'text').map(availableChoice => {
                    const hasNoChoices = question.choices.length === 0
                    const checked = Boolean(
                      question.choices.find(c => c.uuid === availableChoice.uuid),
                    )
                    const userUncheckedEverything = Boolean(error)
                    return (
                      <Grid item xs={6} key={availableChoice.uuid}>
                        <span key={availableChoice.uuid}>
                          <Checkbox
                            checked={(checked || hasNoChoices) && !userUncheckedEverything}
                            onChange={() => {
                              // If the last choice is going to be unchecked
                              if (checked && question.choices.length === 1) {
                                setError(requiredErrorMessage)
                              } else {
                                setError('')
                              }
                              let newChoices = []
                              if (hasNoChoices && !userUncheckedEverything) {
                                newChoices = question.availableChoices.filter(
                                  c => c.uuid !== availableChoice.uuid,
                                )
                              } else {
                                newChoices = checked
                                  ? question.choices.filter(c => c.uuid !== availableChoice.uuid)
                                  : [...question.choices, availableChoice]
                              }
                              setQuestion({
                                ...question,
                                choices: newChoices,
                              })
                            }}
                          />
                          {availableChoice.text}
                        </span>
                      </Grid>
                    )
                  })}
                </Grid>
              ) : (
                <CheckboxDropdown
                  containerClassName={classes.choicesDropdown}
                  menuItems={[
                    {
                      value: ALL_CHOICE.uuid,
                      text: ALL_CHOICE.text,
                    },
                    ...orderBy(question.availableChoices, 'text').map(c => ({
                      value: c.uuid,
                      text: c.text,
                    })),
                  ]}
                  selectedItems={question.choices.map(c => c.uuid)}
                  onChange={(selected: string[]) => {
                    if (selected.length === 0) {
                      setError(requiredErrorMessage)
                    } else {
                      setError('')
                    }
                    // If "All" is selected, we need to select all choices
                    const allIsChecked = Boolean(
                      question.choices.find(c => c.uuid === ALL_CHOICE.uuid),
                    )
                    const allGotChecked = !allIsChecked && selected.includes(ALL_CHOICE.uuid)
                    const allGotUnchecked = allIsChecked && !selected.includes(ALL_CHOICE.uuid)

                    if (allGotChecked) {
                      setQuestion({
                        ...question,
                        choices: [ALL_CHOICE, ...question.availableChoices],
                      })
                      return
                    }
                    if (allGotUnchecked) {
                      return
                    }
                    setQuestion({
                      ...question,
                      choices: question.availableChoices.filter(c => selected.includes(c.uuid)),
                    })
                  }}
                  width={200}
                />
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondaryNoBackground">
            Cancel
          </Button>
          <SubmitButton
            id="savePersonalInfoQuestion"
            isSubmitting={loading}
            disabled={!question.text || Boolean(error)}
            onClick={onUpdateQuestion}
            color="primary"
          >
            Save
          </SubmitButton>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default UpdatePersonalInfoQuestion
