import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import useInsightsStyles from 'components/Insights/InsightsStyle'
import ResponseRatePie from 'components/Insights/Snapshot/ResponseRatePie'
import { ResponseRateTypeEnum } from 'generated/graphql'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  leftSide: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    paddingTop: spacing(3),
    '& >p': {
      paddingLeft: spacing(3),
    },
  },
  percentage: {
    paddingLeft: spacing(2),
  },
}))

export const ResponseRateTitleAndPie: React.FC<{
  finished: number
  total: number
  surveyUuid: string
  showReportBy?: ResponseRateTypeEnum
  minShowableResults: number
}> = ({ finished, total, surveyUuid, showReportBy, minShowableResults }) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }

  const rate = Math.round((finished / total) * 100)

  return (
    <div className={classes.halfLeft}>
      <Typography className={classes.title} variant="subtitle1">
        Response Rate
      </Typography>
      <div className={classes.leftSide}>
        <ResponseRatePie
          minShowableResults={minShowableResults}
          lessThanMin={finished < minShowableResults}
          surveyUuid={surveyUuid}
          rate={rate}
          showScore={false}
          showReportBy={showReportBy}
          includeLink
          includeLeftMargin
        />
        <Typography variant="h4" className={classes.percentage}>
          {rate}%
        </Typography>
      </div>
    </div>
  )
}

export default ResponseRateTitleAndPie
