import React from 'react'

import { Redirect, useParams } from 'react-router-dom'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import LockedSurvey from 'components/Survey/Wizard/LockedSurvey'
import WizardRouter from 'components/Survey/WizardRouter'
import { useSurveysSurveyQuery } from 'generated/graphql'
import { URLS } from 'utils/constants'

const SurveyContainer = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const result = useSurveysSurveyQuery({ variables: { uuid } })
  return (
    <ResponseHandler {...result}>
      {({ survey }) => {
        // survey creation doesn't come this path, it comes from SurveyRoutes via URLS.SURVEYS.CREATE
        if (!survey) return <Redirect to={URLS.SURVEYS.DASHBOARD} />
        // If survey is editable
        return !survey.uuid || survey.editable ? (
          // DRAFT
          <WizardRouter surveyType={survey.type} />
        ) : (
          // SCHEDULED or LIVE or CLOSED
          <LockedSurvey survey={survey} />
        )
      }}
    </ResponseHandler>
  )
}

export default SurveyContainer
