import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    backgroundColor: palette.error.main,
    paddingTop: spacing(),
    paddingBottom: spacing(),
    '& svg': { color: palette.common.white },
  },
  headerPrimary: {
    color: palette.common.white,
    fontSize: '2.2rem',
    lineHeight: 1.6,
  },
  headerSecondary: {
    marginTop: spacing(1),
    color: palette.common.white,
  },
  surveyReadyToLaunch: {
    '& $header': {
      backgroundColor: palette.common.success,
    },
  },
  success: {
    color: palette.common.success,
  },
  error: {
    color: palette.error.main,
  },
  warning: {
    color: palette.common.warning,
  },
  mute: {
    color: palette.text.secondary,
  },
  statementScore: {
    padding: spacing(2),
    textAlign: 'center',
  },
  lockedSurveyList: {
    paddingTop: 0,
    borderTop: `1px solid ${palette.common.navy25}`,
  },
  servicesModalButton: {
    margin: 0,
    padding: 0,
  },
  qrcode: {
    display: 'none',
  },
}))

export default useStyles
