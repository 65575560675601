import React, { useState } from 'react'

import { FilledInput, FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import HeatmapGrid from 'components/Insights/Heatmap/HeatmapGrid'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import { gaEvent } from 'config/ga'
import {
  useInsightsHeatmapControlsQuery,
  useInsightsHeatmapBenchmarkScoresQuery,
  InsightsDemographic,
} from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import { URLS } from 'utils/constants'

const HeatmapContainer: React.FC<InsightsTabProps> = ({ survey, filters, benchmark }) => {
  const classes = useInsightsStyles()
  const controlsResult = useInsightsHeatmapControlsQuery({
    variables: { surveyUuid: survey.uuid, filters },
  })
  const scoresResult = useInsightsHeatmapBenchmarkScoresQuery({
    variables: { surveyUuid: survey.uuid, benchmark },
  })

  const [selectedCategory, setSelectedCategory] = useState<null | InsightsDemographic>(null)

  return (
    <ResponseHandler {...controlsResult}>
      {({ controls }) => {
        const defaultCategory = controls.demographics[0]
        const updateSelectedCategory = (selectedCode: string) => {
          const category = controls.demographics.find(d => d.code === selectedCode)
          gaEvent({
            action: 'heatmapSelectDemographic',
            category: 'Insights',
          })
          if (category) {
            setSelectedCategory(category)
          }
        }

        return (
          <>
            <BreadcrumbsItem to={URLS.EMPLOYEE_INSIGHTS.HEATMAP}>Heatmap</BreadcrumbsItem>
            <div className={classes.heatmapHeader}>
              <Typography variant="body2">SURVEY HEATMAP</Typography>
              <div>
                <div className={classes.selectWithLabel}>
                  <Typography variant="body2" color="textSecondary">
                    y-axis:
                  </Typography>
                  <FormControl variant="filled" className={classes.heatmapSelectFC}>
                    <Select
                      className={classes.heatmapSelect}
                      variant="filled"
                      value={(selectedCategory || defaultCategory).code}
                      input={<FilledInput />}
                      onChange={e => updateSelectedCategory((e.target as HTMLInputElement).value)}
                      id="select-y-axis"
                    >
                      {controls.demographics.map(d => (
                        <MenuItem key={d.code} value={d.code}>
                          {d.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <ResponseHandler {...scoresResult}>
              {({ scores }) => {
                return (
                  <HeatmapGrid
                    classes={classes}
                    benchmark={benchmark}
                    survey={survey}
                    filters={filters}
                    defaultDemographic={defaultCategory}
                    selectedCategory={selectedCategory || defaultCategory}
                    benchmarkData={scores}
                  />
                )
              }}
            </ResponseHandler>
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default withErrorHandler(HeatmapContainer)
