import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import BarChartSortButton from 'components/Insights/Blocks/BarChartSortButton'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsBenchmark, InsightsSurvey } from 'components/Insights/InsightsTypes'
import { getStatementsByService } from 'components/Insights/ResidentSnapshot/ServicesChart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import TimeTrendingContainer from 'components/Insights/TimeTrending/TimeTrendingContainer'
import TTGroupedScoresChart from 'components/Insights/TimeTrending/TTGroupedScoresChart'
import {
  getOrderedGroupNames,
  getOverallScoreGroups,
  ScoreBySurvey,
  SurveyScoreByGroup,
  transformGroupedScoresToEcharts,
  getBenchmarkScore,
} from 'components/Insights/TimeTrending/utils'
import { TimeTrendingChartKey, TimeTrendingSurveysKey } from 'config/LocalStorage'
import {
  InsightsStatementScoreFragment,
  useInsightsOrganizationServicesQuery,
  useInsightsTimeTrendingStatementsQuery,
  InsightsTimeTrendingStatementsQuery,
  InsightsOrganizationServicesQuery,
} from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import { SORT_OPTIONS } from 'utils/constants'
import { SurveyNode } from 'utils/types'

const useStyles = makeStyles(({ spacing }) => ({
  chart: {
    marginTop: spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

type SurveysData = Array<{ uuid: string } & InsightsTimeTrendingStatementsQuery>
type StatementsByService = {
  [serviceCode: string]: InsightsStatementScoreFragment[]
}
type ServiceStatementsBySurvey = { [uuid: string]: StatementsByService }
export const transformServiceStatementsToGroupedScores = (
  data: SurveysData,
  serviceAreas: InsightsOrganizationServicesQuery['currentUser']['organization']['serviceAreas'],
) => {
  const serviceStatementsBySurvey = {} as ServiceStatementsBySurvey
  data.forEach(({ uuid, statements }) => {
    serviceStatementsBySurvey[uuid] = getStatementsByService(statements, serviceAreas)
  })
  const scoreByService = {} as SurveyScoreByGroup
  serviceAreas.forEach(service => {
    scoreByService[service.questionLabel] = {} as ScoreBySurvey
    Object.entries(serviceStatementsBySurvey).forEach(([uuid, statementsByService]) => {
      const statements = (statementsByService as StatementsByService)[service.questionLabel]
      // Survey didn't include this service, mark score as null.
      if (!statements) {
        scoreByService[service.questionLabel][uuid] = null
        return
      }
      scoreByService[service.questionLabel][uuid] =
        // Average the service statements
        statements.reduce((sum, stmt) => sum + stmt.positive, 0) / statements.length
    })
  })
  return scoreByService
}

type Props = {
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  filters: string[]
  benchmark: InsightsBenchmark
}
const TTServicesCard: React.FC<Props> = ({ survey, availableSurveys, filters, benchmark }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [currentSort, setCurrentSort] = useState(SORT_OPTIONS.A_TO_Z)
  const orgResult = useInsightsOrganizationServicesQuery()
  return (
    <div className={classes.fullRow} id="tt-services-breakdown-snap">
      <SnapshotChartHeader
        title="Compare Services Over Time"
        tooltip="See how scores for each service have changed over time. If the service wasn’t included on the survey, it will be blank on the chart."
        hasTimeTrending
        timeTrendingChartKey={TimeTrendingChartKey.RESIDENT_SERVICES}
        snapId="tt-services-breakdown-snap"
        useBottomPadding={false}
      />
      <TimeTrendingContainer
        chartSurveysKey={TimeTrendingSurveysKey.RESIDENT_SERVICES}
        availableSurveys={availableSurveys}
        survey={survey}
      >
        {({ selectedSurveys }) => (
          <ResponseHandler {...orgResult}>
            {({ currentUser }) => (
              <DynamicSurveyQuery
                surveys={selectedSurveys}
                variables={{ filters, benchmark }}
                queryHook={useInsightsTimeTrendingStatementsQuery}
              >
                {(data, loading) => {
                  const scoreByService = transformServiceStatementsToGroupedScores(
                    data,
                    currentUser.organization.serviceAreas,
                  )
                  const orderedGroupNames = getOrderedGroupNames(
                    Object.keys(scoreByService),
                    scoreByService,
                    currentSort,
                    survey.uuid,
                  )
                  const groupedScores = transformGroupedScoresToEcharts(
                    orderedGroupNames,
                    scoreByService,
                    data.map(s => s.uuid),
                  )
                  const companyOverallScoreGroups = getOverallScoreGroups(data)
                  return (
                    <div className={classes.chart}>
                      <TTGroupedScoresChart
                        xAxisLabelData={selectedSurveys}
                        groupedScores={groupedScores}
                        benchmarkName={benchmark.name}
                        companyOverallScores={companyOverallScoreGroups[0]}
                        benchmarkScore={getBenchmarkScore(data[0].overallIndex)}
                        minShowableResults={selectedSurveys[0].minShowableResults}
                        title="Services"
                        didSelectFilters={!loading && filters.length > 0}
                        surveyScoresByGroup={scoreByService}
                        orderedGroupNames={orderedGroupNames}
                      />
                      <div className={classes.timeTrendingSortButton}>
                        <BarChartSortButton
                          currentSort={currentSort}
                          handleChangeSort={newSort => setCurrentSort(newSort)}
                        />
                      </div>
                    </div>
                  )
                }}
              </DynamicSurveyQuery>
            )}
          </ResponseHandler>
        )}
      </TimeTrendingContainer>
    </div>
  )
}

export default TTServicesCard
