import React from 'react'

import { makeStyles } from '@material-ui/core'
import cn from 'classnames'
import ReactTable, { TableProps } from 'react-table'
import 'react-table/react-table.css'

import FormPanel, { Props as FormPanelProps } from 'components/Blocks/Accordions/FormPanel'

const useStyles = makeStyles(() => ({
  alignTable: {
    '& > div > div.rt-tbody > div.rt-tr-group > div': {
      alignItems: 'center',
    },
  },
}))

type Props = {
  // Seperate reactTableProps, so that the FormPanel can receive props at the top level.
  reactTableProps: Partial<TableProps> & { extraClassname?: string }
  alignTable?: boolean
  clickable?: boolean
} & FormPanelProps
const TablePanel: React.FC<Props> = ({
  alignTable = false,
  reactTableProps,
  clickable = false,
  children = <></>,
  ...rest
}) => {
  const classes = useStyles()
  const {
    columns,
    data = [],
    loading = false,
    pageSize = null,
    defaultPageSize = 100,
    className = cn('-striped', '-highlight', { clickable, [classes.alignTable]: alignTable }),
    extraClassname = '',
    showPagination = true,
    ...restTableProps
  } = reactTableProps
  let actualPageSize
  if (pageSize) {
    actualPageSize = Math.min(pageSize, data.length)
  } else if (showPagination) {
    actualPageSize = data.length > defaultPageSize ? defaultPageSize : data.length
  } else {
    // if the pagination (showPagination) is not enabled, we need to set the actualPageSize to equal the size of the data so that react-table sorting will continue to work
    actualPageSize = data.length
  }
  return (
    <FormPanel removeDetailsPadding {...rest}>
      {children}
      <ReactTable
        columns={columns}
        data={data}
        {...restTableProps}
        loading={loading}
        showPagination={showPagination}
        pageSize={actualPageSize}
        defaultPageSize={data.length}
        showPageSizeOptions={false}
        showPaginationTop={false}
        showPaginationBottom
        noDataText=""
        NoDataComponent={() => <></>}
        className={cn(className, extraClassname)}
      />
    </FormPanel>
  )
}

export default TablePanel
