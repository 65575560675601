import React, { useCallback, useContext, useEffect, useState } from 'react'

import { makeStyles, IconButton, Paper, Tooltip, Typography } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { format } from 'date-fns'
import flatten from 'lodash/flatten'
import querystring from 'query-string'
import { useHistory } from 'react-router-dom'

import ActionPlanContainer from 'components/ActionPlans/ActionPlanContainer'
import ProgressBar from 'components/ActionPlansMgmt/ProgressBar'
import { getCountsSummary } from 'components/ActionPlansMgmt/utils'
import Dialog from 'components/Blocks/Dialogs/Dialog'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import FilterButton from 'components/Blocks/Filters/FilterButton'
import FilterList from 'components/Blocks/Filters/FilterList'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Loader from 'components/Blocks/Loader'
import SearchPopup from 'components/Blocks/Search/SearchPopup'
import Table from 'components/Blocks/Table'
import ActionPlansDownloadButton from 'components/Insights/Blocks/ActionPlansDownloadButton'
import userData from 'components/Settings/Team/Users/UserData'
import { gaEvent } from 'config/ga'
import { StoreContext } from 'config/LocalStorage'
import {
  ActionPlansMgmtActionPlansDocument,
  useActionPlansMgmtActionPlansQuery,
  useUserToggleFollowActionPlanMutation,
  CurrentUserQuery,
  ActionPlansSortByEnum,
  FilterTypeType,
} from 'generated/graphql'
import { FOLLOW_STATUS, MIN_SHOWABLE_RESULTS_CODE } from 'utils/constants'
import { toggleListWithAllType } from 'utils/index'
import { ActionPlanNode, AppliedFilters } from 'utils/types'

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    padding: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  filtersList: {
    padingBottom: spacing(),
  },
  filter: {
    '& >p': {
      marginLeft: spacing(2),
      marginBottom: 5,
    },
  },
  progress: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inlineData: {
    display: 'flex',
    '& >p:first-child': {
      marginRight: spacing(),
    },
  },
  tooltip: {
    padding: spacing(),
  },
  launchIcon: {
    width: 22,
    height: 22,
  },
}))

const columns = [
  {
    label: 'follow',
    sortId: ActionPlansSortByEnum.FOLLOW,
  },
  {
    label: 'last updated',
    sortId: ActionPlansSortByEnum.UPDATED,
  },
  {
    label: 'owner',
    sortId: ActionPlansSortByEnum.OWNER,
  },
  {
    label: 'progress',
  },
  {
    label: 'ces',
    tooltip: 'Customer Engagement Score',
  },
  {
    label: 'tis',
    tooltip: 'Trust Index Score',
  },
  {
    label: 'see plan',
  },
]

const getScoreCell = (
  classes: ReturnType<typeof useStyles>,
  surveyData: ActionPlanNode['lastEmployeeSurveyData'] | ActionPlanNode['lastResidentSurveyData'],
  title: 'Customer' | 'Employee',
) =>
  surveyData ? (
    <Tooltip
      title={
        <div className={classes.tooltip}>
          <div className={classes.inlineData}>
            <Typography>Survey:</Typography>
            <Typography color="textSecondary">{surveyData.survey.name}</Typography>
          </div>
          <Typography color="textSecondary">{title}</Typography>
          <div className={classes.inlineData}>
            <Typography>Date:</Typography>
            <Typography color="textSecondary">
              {format(new Date(surveyData.survey.endDate!), 'MMMM yyyy')}
            </Typography>
          </div>
        </div>
      }
    >
      <div>
        {surveyData.score.positive === MIN_SHOWABLE_RESULTS_CODE
          ? 'N/A'
          : `${Math.round(surveyData.score.positive)}%`}
      </div>
    </Tooltip>
  ) : (
    'N/A'
  )

const getRow = (
  classes: ReturnType<typeof useStyles>,
  actionPlan: ActionPlanNode,
  followedActionPlanIds: string[],
  updateFollowStatus: (planId: string) => void,
  setTargetUserUuid: (userUuid: string) => void,
) => {
  const {
    uuid,
    owner,
    lastUpdated,
    progress,
    lastEmployeeSurveyData,
    lastResidentSurveyData,
  } = actionPlan

  let doneTasksCount = 0
  let totalTasksCount = 0
  const counts = [progress.employee, progress.resident]
  counts.forEach(c => {
    if (!c) {
      return
    }
    const { done, total } = getCountsSummary(c)
    doneTasksCount += done
    totalTasksCount += total
  })
  const progressAsPercentage =
    totalTasksCount && Math.floor((doneTasksCount / totalTasksCount) * 100)
  const userAccessData = userData(
    Boolean(owner.isAdmin),
    owner.filterPermissions as FilterTypeType[],
  )
  const isFollowing = followedActionPlanIds.includes(uuid)
  return [
    {
      name: 'follow',
      value: (
        <Tooltip title={`Click to ${isFollowing ? 'unfollow' : 'follow'} this action plan`}>
          <IconButton onClick={() => updateFollowStatus(uuid)}>
            <VisibilityIcon color={isFollowing ? 'secondary' : 'inherit'} />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      name: 'lastUpdated',
      value: `${format(new Date(lastUpdated), 'MMM dd')} at ${format(
        new Date(lastUpdated),
        'h:mm aa',
      )}`,
    },
    {
      name: 'owner',
      value: (
        <Tooltip title={userAccessData.tooltip}>
          <div>{owner.name}</div>
        </Tooltip>
      ),
    },
    {
      name: 'progress',
      value: (
        <div className={classes.progress}>
          <ProgressBar percentage={progressAsPercentage} />
          <Typography variant="body2">{progressAsPercentage}%</Typography>
        </div>
      ),
    },
    {
      name: 'ces',
      value: getScoreCell(classes, lastResidentSurveyData, 'Customer'),
    },
    {
      name: 'tis',
      value: getScoreCell(classes, lastEmployeeSurveyData, 'Employee'),
    },
    {
      name: 'seePlan',
      value: (
        <IconButton onClick={() => setTargetUserUuid(owner.id)}>
          <LaunchIcon className={classes.launchIcon} />
        </IconButton>
      ),
    },
  ]
}

type Props = {
  currentUser: CurrentUserQuery['currentUser']
  filters: AppliedFilters
  setFilters(filters: AppliedFilters): void
}

const ActionPlansMgmtTable: React.FC<Props> = ({ currentUser, filters, setFilters }) => {
  const classes = useStyles()
  const {
    store: {
      selectedFollowFilters,
      actionPlansSortBy,
      actionPlansSortAscending,
      actionPlansSearchQuery,
    },
    updateStore,
  } = useContext(StoreContext)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [showFilters, setShowFilters] = useState(false)
  const history = useHistory()
  const hashParams = querystring.parse(history.location.hash)
  const [targetUserUuid, setTargetUserUuid] = useState<null | string>(
    typeof hashParams.user_uuid === 'string' ? hashParams.user_uuid : null,
  )
  const updateSelectedFollowFilters = useCallback(
    (followFilters: string[], selectedFilters: string[]) => {
      const newFollowFilters = toggleListWithAllType(
        selectedFilters,
        followFilters,
        FOLLOW_STATUS.all,
        Object.keys(FOLLOW_STATUS).length - 1,
      )
      updateStore({ selectedFollowFilters: newFollowFilters })
    },
    [updateStore],
  )
  const [toggleFollowActionPlan] = useUserToggleFollowActionPlanMutation()

  // When coming from the link in the AP Manager followed action plans email, we filter to followed plans
  useEffect(() => {
    if (hashParams.followed) {
      updateSelectedFollowFilters([FOLLOW_STATUS.follow], [])
    }
  }, [hashParams.followed, updateSelectedFollowFilters])

  useEffect(() => {
    setPage(0)
  }, [filters])

  const pageSize = 10
  const variables = {
    selectedFollowFilters,
    pageSize,
    filterValueUuids: flatten(Object.values(filters)),
    sortBy: actionPlansSortBy,
    sortAscending: actionPlansSortAscending,
    searchQuery: actionPlansSearchQuery,
  }
  const result = useActionPlansMgmtActionPlansQuery({
    variables,
    // this attribute is so that a loading is set to true on fetchMore
    notifyOnNetworkStatusChange: true,
    // Debounce filter updates to avoid refetching this expensive query before the user is ready.
    context: {
      debounceKey: Object.keys(filters).length ? 'actionsPlansTable' : null,
      debounceTimeout: 3000,
    },
  })

  const renderFollowFilterSelect = () => {
    const menuItems = Object.values(FOLLOW_STATUS).map(value => ({
      value,
      isDivider: value === FOLLOW_STATUS.all,
    }))
    return (
      <div id="followStatusFilter" className={classes.filter}>
        <Typography color="textSecondary" variant="body2">
          Show
        </Typography>
        <CheckboxDropdown
          withLeftMargin
          id="followStatus"
          menuItems={menuItems}
          selectedItems={selectedFollowFilters}
          onChange={(newFilters: string[]) =>
            updateSelectedFollowFilters(newFilters, selectedFollowFilters)
          }
          width={120}
        />
      </div>
    )
  }

  if (targetUserUuid) {
    return (
      <Dialog onClose={() => setTargetUserUuid(null)}>
        <ActionPlanContainer currentUser={currentUser} targetUserUuid={targetUserUuid} />
      </Dialog>
    )
  }

  return (
    <Paper>
      <div className={classes.header}>
        <div>
          <Typography variant="h5">Action Plan Management</Typography>
          <Typography color="textSecondary">
            Track progress on action plans across your organization.
          </Typography>
        </div>
        <div>
          <ActionPlansDownloadButton title="Download" id="dowload-action-plans" />
          <SearchPopup
            searchQuery={actionPlansSearchQuery}
            handleSearch={val => updateStore({ actionPlansSearchQuery: val })}
            debounceTime={1000}
          />
          <FilterButton filters={filters} toggleFilters={() => setShowFilters(!showFilters)} />
        </div>
      </div>
      {showFilters && (
        <div className={classes.filtersList}>
          <FilterList
            filters={currentUser.filters}
            selectedFilters={filters}
            updateSelectedFilters={(uuid, filterVals) => {
              setFilters({ ...filters, [uuid]: filterVals })
            }}
          >
            {renderFollowFilterSelect()}
          </FilterList>
        </div>
      )}
      <ResponseHandler {...result}>
        {({ actionPlanNodes, currentUser: { followedActionPlanIds } }) => {
          const updateFollowStatus = (planId: string) => {
            let plansIds
            if (followedActionPlanIds.includes(planId)) {
              plansIds = followedActionPlanIds.filter(i => i !== planId)
            } else {
              gaEvent({
                action: 'toggleFollowPlan',
                category: 'ManageActionPlan',
              })
              plansIds = [...followedActionPlanIds, planId]
            }
            toggleFollowActionPlan({
              variables: { planUuid: planId },
              optimisticResponse: {
                __typename: 'Mutation',
                toggleFollowActionPlan: {
                  __typename: 'ToggleFollowActionPlan',
                  user: { ...currentUser, followedActionPlanIds: plansIds },
                },
              },
            })
          }

          const onLoadMore = async () => {
            setLoading(true)
            await result.fetchMore({
              query: ActionPlansMgmtActionPlansDocument,
              variables: {
                ...variables,
                cursor: actionPlanNodes?.pageInfo.endCursor,
              },
            })
            setLoading(false)
          }

          const actionPlans = actionPlanNodes?.edges
            .map(e => e?.node)
            .filter((actionPlan): actionPlan is ActionPlanNode => actionPlan !== null)
          if (!actionPlans) return <></>
          const totalRecords = actionPlanNodes?.totalCount || 0
          const rows = actionPlans.map(ap =>
            getRow(classes, ap, followedActionPlanIds, updateFollowStatus, setTargetUserUuid),
          )
          if (loading) {
            return <Loader />
          }

          return (
            <Table
              bicolor
              sortBy={actionPlansSortBy}
              setSortBy={newSortBy => updateStore({ actionPlansSortBy: newSortBy })}
              sortAscending={actionPlansSortAscending}
              setSortAscending={newSortAscending => {
                updateStore({ actionPlansSortAscending: newSortAscending })
              }}
              page={page}
              pageSize={pageSize}
              setPage={newPage => {
                if (actionPlanNodes?.pageInfo.hasNextPage) {
                  onLoadMore()
                }
                setPage(newPage)
              }}
              columns={columns}
              totalRows={totalRecords}
              rows={rows}
            />
          )
        }}
      </ResponseHandler>
    </Paper>
  )
}

export default ActionPlansMgmtTable
