import React from 'react'

import { Tooltip } from '@material-ui/core'
import { QRCodeCanvas } from 'qrcode.react'
import { NavLink } from 'react-router-dom'

import Button from 'components/Blocks/CustomButtons/Button'
import Section, { SectionsEnum } from 'components/Survey/Wizard/Steps/Summary/Section'
import useStyles from 'components/Survey/Wizard/Steps/Summary/styles'
import { WizardStepsEnum } from 'components/Survey/WizardContainer'
import {
  SurveysSurveyQuery,
  SettingsSectionType,
  DesignSectionType,
  SurveyTypeEnum,
} from 'generated/graphql'
import { downloadQR } from 'utils'
import {
  CUSTOM_SURVEY_TYPES,
  URLS,
  SURVEY_DEFAULT_MAX_PERIOD,
  SURVEY_INVITE_METHOD_TO_LABEL,
  SUPPORT_EMAIL,
} from 'utils/constants'
import { displayDateTime } from 'utils/dateUtils'

type Props = {
  survey: SurveysSurveyQuery['survey']
  surveyPortalUrl: string
  design: DesignSectionType
  settings?: SettingsSectionType | null
  editable: boolean
  goToStep(stepName: WizardStepsEnum): void
  errors: { [key: string]: boolean }
}

const DesignSection: React.FC<Props> = ({
  survey,
  surveyPortalUrl,
  design,
  settings,
  editable,
  goToStep,
  errors,
}) => {
  const classes = useStyles()

  return (
    <Section
      editable={editable}
      goToStep={() => goToStep(WizardStepsEnum.DESIGN)}
      title={SectionsEnum.DESIGN}
      ready={design.ready}
      warningsNumber={design.warnings.length}
    >
      <ul>
        <li>
          <span className={classes.mute}>Survey Name:</span> {design.name}
        </li>
        <li>
          <span className={classes.mute}>Delivery Method:</span>{' '}
          {SURVEY_INVITE_METHOD_TO_LABEL[survey.inviteMethod]}
        </li>
        {errors.START_DATE_IN_PAST && (
          <li className={classes.error}>
            Start date cannot be in the past.{' '}
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.DESIGN)}
              color="secondaryNoBackground"
            >
              Change Date
            </Button>
          </li>
        )}
        <li>
          <span className={classes.mute}>Survey Start:</span> {displayDateTime(design.startDate)}
        </li>
        {errors.END_DATE_IN_FUTURE && (
          <li className={classes.error}>
            End date cannot be more than {SURVEY_DEFAULT_MAX_PERIOD} days past start date.{' '}
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.DESIGN)}
              color="secondaryNoBackground"
            >
              Change Date
            </Button>
          </li>
        )}
        {survey.type !== SurveyTypeEnum.RESIDENT_DISCHARGE && (
          <li>
            <span className={classes.mute}>Survey End:</span> {displayDateTime(design.endDate)}
          </li>
        )}
        <li>
          <span className={classes.mute}>Languages:</span> English
          {design.languages.map(l => `, ${l.name}`)}
        </li>
        <li>
          <span className={classes.mute}>Survey Portal Link:</span>{' '}
          <a target="_blank" rel="noopener noreferrer" href={surveyPortalUrl}>
            {surveyPortalUrl}
          </a>
        </li>
        {settings && (
          <>
            <li>
              <span className={classes.mute}>Survey Code Name:</span> {settings.surveyCodeName}
            </li>
            <li>
              <span className={classes.mute}>Survey Code Help Text: </span>
              {settings.surveyCodeHelpText && (
                <Tooltip title={settings.surveyCodeHelpText}>
                  <span>
                    {settings.surveyCodeHelpText.substring(0, 20)}
                    {settings.surveyCodeHelpText.length > 20 && '...'}
                  </span>
                </Tooltip>
              )}
              {!settings.surveyCodeHelpText && (
                <span>
                  Not set{'  '}
                  <NavLink
                    to={
                      CUSTOM_SURVEY_TYPES.includes(survey.type)
                        ? URLS.ORG_SETTINGS.RESIDENT_SURVEY_PORTAL
                        : URLS.ORG_SETTINGS.EMPLOYEE_SURVEY_PORTAL
                    }
                  >
                    Add Help Text
                  </NavLink>
                </span>
              )}
            </li>
          </>
        )}
        <li>
          <span className={classes.mute}>QR Code:</span> Generated&nbsp;
          <NavLink to="#" onClick={downloadQR}>
            Download QR Code
          </NavLink>
          <div className={classes.qrcode}>
            <QRCodeCanvas id="qrcode" value={surveyPortalUrl} size={290} level="H" includeMargin />
          </div>
        </li>
        {errors.MISSING_EXTERNAL_ID && (
          <li className={classes.error}>
            Survey organization is missing an external id. Please reach out to{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.
          </li>
        )}
      </ul>
    </Section>
  )
}

export default DesignSection
