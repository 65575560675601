import React from 'react'

import { Typography } from '@material-ui/core'

import { CustomQuestionCardProps } from './types'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import echartTooltipBuilder from 'components/Insights/CustomSurvey/Cards/echartTooltipBuilder'
import useStyles from 'components/Insights/CustomSurvey/styles'
import useInsightsStyles, { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'

const LinearCard: React.FC<CustomQuestionCardProps> = ({ id, question, data, dataIsFiltered }) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }
  return (
    <FormPanel
      id={id}
      expanded
      expandIcon={EXPAND_ICON.EXPAND}
      gutterBottom={false}
      summary={<Typography>{question.text}</Typography>}
    >
      <div id={question.uuid} className={classes.pageBreak}>
        <div className={classes.actions}>
          <ScreenshotButton snapId={question.uuid} />
        </div>
        <PrintableEchart
          notMerge
          printWidth={300}
          option={{
            grid: { bottom: 50, top: 50, left: 150, right: 150 },
            textStyle: chartTextStyle,
            xAxis: {
              type: 'category',
              data: data.map(d => (d.name ? `${d.scale}\n(${d.name})` : `${d.scale}`)),
              axisTick: { show: false },
              axisLine: { show: false },
              axisLabel: {
                interval: 0,
              },
            },
            yAxis: {
              type: 'value',
              min: 0,
              axisTick: { show: false },
              axisLine: { show: false },
            },
            series: [
              {
                data,
                name: dataIsFiltered ? 'Selected Filters' : 'Company Average',
                type: 'bar',
                color: '#FAE655',
              },
            ],
            tooltip: {
              formatter: echartTooltipBuilder,
            },
          }}
        />
      </div>
    </FormPanel>
  )
}

export default LinearCard
