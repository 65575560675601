import React from 'react'

import PropTypes from 'prop-types'
import { ResponsiveContainer } from 'recharts'

import PrintableComponent from 'HOC/PrintableComponent'

export default class PrintableRechart extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isPrinting: false }
  }

  render() {
    const { isPrinting } = this.state
    const { printWidth, screenWidth, screenMargin, printMargin, children } = this.props
    const chartStyle = isPrinting
      ? { width: printWidth, margin: printMargin }
      : { width: screenWidth, margin: screenMargin }
    return (
      <div style={chartStyle}>
        <PrintableComponent
          beforePrint={() => this.setState({ isPrinting: true })}
          afterPrint={() => this.setState({ isPrinting: false })}
        >
          <ResponsiveContainer {...this.props}>{children}</ResponsiveContainer>
        </PrintableComponent>
      </div>
    )
  }
}

PrintableRechart.defaultProps = {
  screenWidth: '85%',
  screenMargin: '0 auto',
  printMargin: '0 0',
}

PrintableRechart.propTypes = {
  printWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  screenWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
