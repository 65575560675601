import React, { ReactElement } from 'react'

import { makeStyles } from '@material-ui/core'
import { Bar, BarChart, Legend, LabelList, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

import PrintableRechart from 'components/Insights/Printable/PrintableRechart'
import { colors } from 'shared/theme'
import { formatTooltipScore, getFirstViridisColors } from 'utils'

const useStyles = makeStyles(() => ({
  barLabel: {
    fill: '#fff',
  },
  tooltip: {
    background: '#fff',
  },
}))

type Props = {
  scores: number[]
  minShowableResults: number
  legends?: string[]
  lessThanMin?: boolean
  useGrid?: boolean
  barColors?: string[]
  barSize?: number
  labelPosition?: LabelList['props']['position'] | null
  tooltip?: ReactElement
  chartWidth?: number
  minWidth?: number
}
const SingleBarBreakdownChart: React.FC<Props> = ({
  scores,
  minShowableResults,
  legends,
  lessThanMin,
  useGrid,
  barColors = getFirstViridisColors(),
  barSize = 32,
  labelPosition = 'insideLeft',
  tooltip,
  chartWidth = 330,
  minWidth = 8,
}) => {
  const classes = useStyles()
  // In order to make sure that score text is visible on the chart when the value is small,
  // we give an artifical min width. Make sure to use the original score value below in the formatter.
  const adjustedValues = scores.map(s => (Math.round(s) >= 0 && s < minWidth ? minWidth : s))
  const formattedData = [
    adjustedValues.reduce(
      (acc, score, idx) => ({ ...acc, [idx]: score, 'less-than-min-showable-results': score }),
      {},
    ),
  ]
  return (
    <PrintableRechart screenWidth={chartWidth} printWidth={200} height={50} screenMargin="0 0">
      <BarChart
        width={chartWidth}
        height={50}
        data={formattedData}
        layout="vertical"
        margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
        barCategoryGap={0}
        barGap={9}
      >
        <XAxis
          orientation="top"
          type="number"
          domain={[0, 100]}
          tickFormatter={() => ''}
          height={0}
          ticks={[0, 20, 40, 60, 80, 100]}
          axisLine={false}
        />
        <YAxis type="category" axisLine={false} width={0} />
        {legends?.length && <Legend align="left" wrapperStyle={{ top: 50 }} />}
        {tooltip && (
          <Tooltip
            wrapperStyle={{ zIndex: 1000 }}
            cursor={{ fill: colors.navy11 }}
            content={() => <div className={classes.tooltip}>{tooltip}</div>}
          />
        )}
        {useGrid && (
          <CartesianGrid
            horizontal={false}
            stroke={colors.navy25}
            strokeWidth="0.5"
            shapeRendering="crispEdges"
          />
        )}
        {barColors.map((barColor, index) => {
          let dataKey
          let formatter
          let color
          if (lessThanMin) {
            dataKey = 'less-than-min-showable-results'
            color = colors.navy25
            formatter = () => `<${minShowableResults}`
          } else {
            dataKey = index
            color = barColor
            // Make sure to use the original score values, as the values in the chart have been
            // adjusted for display purposes.
            formatter = (_: number | string) =>
              formatTooltipScore(scores[index], minShowableResults)
          }
          return (
            <Bar
              key={dataKey}
              barSize={barSize}
              stackId="SingleBarBreakdown"
              dataKey={dataKey}
              fill={color}
              name={legends ? legends[index] : ''}
            >
              {labelPosition && (
                <LabelList
                  className={classes.barLabel}
                  dataKey={dataKey}
                  position={labelPosition}
                  formatter={formatter}
                />
              )}
            </Bar>
          )
        })}
      </BarChart>
    </PrintableRechart>
  )
}

export default SingleBarBreakdownChart
