import React from 'react'

import { FormControl, Select, createStyles, makeStyles } from '@material-ui/core'
import { SelectProps } from '@material-ui/core/Select'

const useStyles = makeStyles(theme =>
  createStyles({
    dropdownSelect: {
      margin: theme.spacing(1),
      backgroundColor: `${theme.palette.common.white25} !important`,
      color: theme.palette.common.white,
      '& :hover': {
        backgroundColor: theme.palette.common.white50,
      },
      minWidth: 120,
      maxWidth: 200,
      height: 32,
    },
  }),
)

/**
 * Basic MaterialUI Dropdown component with the option to style for dark backgrounds.
 */
type Props = {
  useDarkStyle?: boolean
  formControlClass?: string
  variant?: 'filled' | 'outlined' | 'standard'
} & SelectProps
const Dropdown: React.FC<Props> = props => {
  const { useDarkStyle, children, formControlClass, variant, ...selectProps } = props
  const classes = useStyles()
  const selectClass = useDarkStyle ? classes.dropdownSelect : ''
  return (
    <FormControl variant={variant || 'filled'} className={formControlClass}>
      <Select {...selectProps} className={selectClass}>
        {children}
      </Select>
    </FormControl>
  )
}

export default Dropdown
