import React, { useState } from 'react'

import { Checkbox, Typography, makeStyles, Switch } from '@material-ui/core'

import Panel from 'components/Blocks/Accordions/Panel'
import { INSIGHTS_MODULE_TO_TAB_NAME } from 'components/Insights/insightsTabs'
import { Group } from 'components/Settings/Team/Groups/GroupsTypes'
import { CurrentUserQuery, SurveyProductTypeEnum, InsightsModulesEnum } from 'generated/graphql'
import { PRODUCT_TYPE_LABELS, PRODUCT_TYPE_USER_FIELDS } from 'utils/constants'

const useStyles = makeStyles(() => ({
  switchesSection: {
    '& >span': {
      display: 'flex',
      alignItems: 'center',
      '& >p': {
        width: 200,
      },
    },
  },
  insightsSection: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxHeight: 240,
    overflow: 'auto',
    '& >span': {
      width: 140,
      display: 'flex',
      alignItems: 'center',
    },
  },
}))

type UpdateGroup = (arg: Partial<Group>) => void
type SProps = {
  group: Group
  field: keyof Group
  label: string
  updateGroup: UpdateGroup
}
const SwitchRow: React.FC<SProps> = ({ group, field, label, updateGroup }) => {
  return (
    <span>
      <Typography>{label}</Typography>
      <Switch
        checked={Boolean(group[field])}
        onChange={() => updateGroup({ [field]: !group[field] })}
      />
      {group[field] ? 'Yes' : 'No'}
    </span>
  )
}

type IProps = {
  canUseActionPlans: boolean
  userInsightsModules: InsightsModulesEnum[]
  groupInsightsModules: InsightsModulesEnum[]
  updateGroup: UpdateGroup
  insightsModulesField: 'insightsModules' | 'residentInsightsModules'
}
const InsightsSection: React.FC<IProps> = ({
  canUseActionPlans,
  userInsightsModules,
  groupInsightsModules,
  updateGroup,
  insightsModulesField,
}) => {
  const classes = useStyles()
  const updateInsight = (checked: boolean, insightsModule: InsightsModulesEnum) => {
    if (checked) {
      updateGroup({
        [insightsModulesField]: groupInsightsModules.filter(i => i !== insightsModule),
      })
    } else {
      updateGroup({ [insightsModulesField]: [...groupInsightsModules, insightsModule] })
    }
  }
  return (
    <div className={classes.insightsSection}>
      {userInsightsModules.map(insightsModule => {
        const checked = groupInsightsModules.includes(insightsModule)
        if (insightsModule === InsightsModulesEnum.ACTION_PLANS && !canUseActionPlans) {
          return null
        }
        const tabName = INSIGHTS_MODULE_TO_TAB_NAME[insightsModule]
        return (
          <span key={insightsModule}>
            <Checkbox
              name={tabName}
              checked={checked}
              onChange={() => updateInsight(checked, insightsModule)}
            />
            {tabName}
          </span>
        )
      })}
    </div>
  )
}

enum PanelEnum {
  GENERAL = 'general',
  INSIGHTS = 'insights',
}

type Props = {
  group: Group
  updateGroup: UpdateGroup
  currentUser: CurrentUserQuery['currentUser']
  productType: SurveyProductTypeEnum
  groupInsightsModules: InsightsModulesEnum[]
}
const SurveyProductModuleSettings: React.FC<Props> = ({
  group,
  updateGroup,
  currentUser,
  productType,
  groupInsightsModules,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(PanelEnum.GENERAL)
  const {
    createSurveysField,
    surveySettingsField,
    insightsModulesField,
    lonelinessResultsField,
  } = PRODUCT_TYPE_USER_FIELDS[productType]

  const switchProps = { group, updateGroup }
  return (
    <>
      <Panel
        expanded={expanded === PanelEnum.GENERAL}
        onExpandChange={() => setExpanded(PanelEnum.GENERAL)}
        title="General Permissions"
        subtitle="People in this group will have access to:"
      >
        <div className={classes.switchesSection}>
          <SwitchRow
            label={`Create ${PRODUCT_TYPE_LABELS[productType]} Surveys`}
            field={createSurveysField}
            {...switchProps}
          />
          <SwitchRow
            label={`Access ${PRODUCT_TYPE_LABELS[productType]} Survey Settings`}
            field={surveySettingsField}
            {...switchProps}
          />
          {lonelinessResultsField && (
            <SwitchRow
              label="See Loneliness Results"
              field={lonelinessResultsField}
              {...switchProps}
            />
          )}
        </div>
      </Panel>
      <Panel
        id="InsightsPanel"
        expanded={expanded === PanelEnum.INSIGHTS}
        onExpandChange={() => setExpanded(PanelEnum.INSIGHTS)}
        title="Insights Tabs Access"
        subtitle="Select the tabs in insights visible for this group:"
      >
        <InsightsSection
          canUseActionPlans={group.canUseActionPlans}
          insightsModulesField={insightsModulesField}
          userInsightsModules={currentUser[insightsModulesField]}
          updateGroup={updateGroup}
          groupInsightsModules={groupInsightsModules}
        />
      </Panel>
    </>
  )
}

export default SurveyProductModuleSettings
