import React from 'react'

import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Typography,
  Tooltip,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import MenuIcon from '@material-ui/icons/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import RefreshIcon from '@material-ui/icons/Refresh'
import ViewListIcon from '@material-ui/icons/ViewList'
import { Breadcrumbs } from 'react-breadcrumbs-dynamic'
import { NavLink } from 'react-router-dom'

import { gaEvent } from 'config/ga'
import { resetStore } from 'config/LocalStorage'

const useStyles = makeStyles(({ palette }) => ({
  appBarContainer: {
    minHeight: 66, // appBar height + padding
    '@media print': {
      display: 'none',
    },
  },
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: 0,
    marginBottom: 0,
    position: 'absolute',
    top: '70px', // global nav is 70px
    width: '100%',
    zIndex: 1029,
    color: palette.text.primary,
    border: 0,
    borderRadius: 3,
    padding: '8px 0',
    transition: 'all 150ms ease 0s',
    minHeight: 50,
    display: 'block',
  },
  tooltip: {
    backgroundColor: palette.common.navy,
    color: '#FFF',
  },
  container: {
    minHeight: 50,
    paddingRight: 15,
    paddingLeft: 15,
    marginRight: 'auto',
    marginLeft: 'auto',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
  flex: {
    flex: 1,
  },
  breadcrumbsItem: {
    color: palette.common.navy,
  },
  sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15',
    display: 'block',
    color: palette.common.darkGrey,
    '& >button': {
      background: palette.common.white,
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
      marginRight: 25,
    },
  },
  sidebarMiniIcon: {
    width: 20,
    height: 17,
  },
  getHelp: {
    '& svg': {
      verticalAlign: 'middle',
    },
  },
}))

type Props = {
  handleDrawerToggle(): void
  sidebarMinimize(): void
  miniActive?: boolean
}

const Header: React.FC<Props> = ({ handleDrawerToggle, sidebarMinimize, miniActive = false }) => {
  const classes = useStyles()

  const MinimizeIcon = miniActive ? ViewListIcon : MoreVertIcon

  return (
    <div className={classes.appBarContainer}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.container}>
          <Hidden smDown>
            <div className={classes.sidebarMinimize}>
              <IconButton onClick={sidebarMinimize}>
                <MinimizeIcon className={classes.sidebarMiniIcon} />
              </IconButton>
            </div>
          </Hidden>
          <div className={classes.flex}>
            <Typography variant="h5">
              <Breadcrumbs
                separator=" > "
                item={NavLink}
                finalItem="span"
                finalProps={{
                  className: classes.breadcrumbsItem,
                }}
              />
            </Typography>
          </div>
          <a
            href="https://activatedinsights.force.com/help/s/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.getHelp}
            onClick={() => {
              gaEvent({
                action: 'getHelp',
                category: 'Global',
              })
            }}
          >
            <Typography color="textSecondary">
              Get Help <HelpIcon />
            </Typography>
          </a>
          <IconButton
            id="page-refresh-button"
            onClick={() => {
              gaEvent({
                action: 'appRefresh',
                category: 'Global',
              })
              resetStore()
              window.location.reload()
            }}
          >
            <Tooltip title="Reset Page">
              <RefreshIcon />
            </Tooltip>
          </IconButton>
          <Hidden mdUp>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  )
}
export default Header
