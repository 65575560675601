import React from 'react'

import { Checkbox, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  checkbox: {
    marginTop: spacing(),
    alignItems: 'center',
    display: 'flex',
    '& >span:first-child': {
      marginLeft: -12,
    },
  },
}))

type Props = {
  checked: boolean
  onChange(): void
  label: string
  disabled?: boolean
}

// This is a checkbox version that vertically aligns with some text above/below by setting a negative left margin
const CustomCheckbox: React.FC<Props> = ({ checked, onChange, label, disabled = false }) => {
  const classes = useStyles()

  return (
    <div className={classes.checkbox}>
      <Checkbox checked={checked} onChange={onChange} disabled={disabled} />
      <Typography color={disabled ? 'textSecondary' : 'textPrimary'}>{label}</Typography>
    </div>
  )
}

export default CustomCheckbox
