import React from 'react'

import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import { ReactComponent as OpenLinkSurveyIcon } from 'assets/img/open-link-survey-icon.svg'
import Tag from 'components/Blocks/Tags/Tag'
import { SurveyStatusEnum } from 'generated/graphql'

const useStyles = makeStyles(({ palette, spacing }) => ({
  icon: {
    marginLeft: spacing(),
  },
  iconDraft: {
    color: palette.common.navy,
  },
  draft: {
    color: palette.common.navy,
    backgroundColor: palette.common.navy25,
  },
  scheduled: {
    color: palette.common.white,
    backgroundColor: '#E29842',
  },
  live: {
    color: palette.common.white,
    backgroundColor: '#4CAF50',
  },
  closed: {
    color: palette.common.white,
    backgroundColor: palette.common.navy,
  },
}))

type Props = {
  status: SurveyStatusEnum
  isOpenLinkSurvey?: boolean
}

const StatusTag: React.FC<Props> = ({ status, isOpenLinkSurvey = false }) => {
  const classes = useStyles()
  return (
    <Tag
      className={cn({
        [classes.draft]: status === SurveyStatusEnum.DRAFT,
        [classes.scheduled]: status === SurveyStatusEnum.SCHEDULED,
        [classes.live]: status === SurveyStatusEnum.LIVE,
        [classes.closed]: status === SurveyStatusEnum.CLOSED,
      })}
    >
      {status}{' '}
      {isOpenLinkSurvey && (
        <OpenLinkSurveyIcon
          className={cn(classes.icon, { [classes.iconDraft]: status === SurveyStatusEnum.DRAFT })}
        />
      )}
    </Tag>
  )
}

export default StatusTag
