import React, { ReactNode } from 'react'

import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'inline-block',
  },
  tag: {
    height: 17,
    letterSpacing: 0.2,
    borderRadius: 10,
    padding: '5px 10px',
    fontSize: '1rem',
    marginTop: spacing(),
    marginLeft: 0,
    marginRight: spacing(),
    marginBottom: spacing(),
    display: 'flex',
    alignItems: 'center',
    color: palette.common.navy,
    backgroundColor: palette.common.navy25,
    textTransform: 'uppercase',
  },
}))

interface Props {
  children: ReactNode
  className?: string | null
}

const Tag: React.FC<Props> = ({ children, className }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={cn(classes.tag, className)}>{children}</div>
    </div>
  )
}

export default Tag
