import React, { useState, SyntheticEvent } from 'react'

import { makeStyles, MenuItem, FilledInput, Typography } from '@material-ui/core'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import Dropdown from 'components/Blocks/Dropdowns/Dropdown'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import GroupedSelect from 'components/Insights/Blocks/GroupedSelect'
import TimeFrameDropdown from 'components/Insights/Blocks/TimeFrameDropdown'
import ComparisonDonuts from 'components/Insights/Comparisons/ComparisonDonuts'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import { gaEvent } from 'config/ga'
import {
  useInsightsComparisonControlsQuery,
  BenchmarkCodeType,
  InsightsComparisonControlsQuery,
  InsightsModulesEnum,
  SurveyProductTypeEnum,
  QFocus,
  QResidentResultsGroup,
} from 'generated/graphql'
import {
  TimeframeDropDownLocation,
  QUESTION_FOCUSES,
  RESIDENT_RESULTS_GROUP_LABELS,
} from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(({ spacing, palette }) => ({
  comparisonControls: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: spacing(3),
    borderBottom: `1px solid ${palette.common.navy25}`,
    background: palette.common.navy,
    '& >p': {
      color: palette.common.white,
    },
  },
  comparisonPiesContainer: {
    position: 'relative',
  },
  comparisonSelect: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timeframeDropdown: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(3),
    '& >div>div': {
      height: 32,
    },
    '& >p': {
      color: palette.common.white,
      fontSize: '1.4rem',
    },
  },
}))

const ComparisonsContainer: React.FC<InsightsTabProps> = props => {
  const { survey, filters } = props
  const result = useInsightsComparisonControlsQuery({
    variables: { surveyUuid: survey.uuid, filters },
  })
  return (
    <ResponseHandler {...result}>
      {({ controls }) => {
        if (!controls.statements.length) {
          return (
            <EmptyState title="No data" description="No data to display." Icon={ChartUpwardIcon} />
          )
        }
        return (
          <Comparisons
            statements={controls.statements}
            demographics={controls.demographics}
            {...props}
          />
        )
      }}
    </ResponseHandler>
  )
}

type InsightsStatements = InsightsComparisonControlsQuery['controls']['statements']
type InsightsDemographics = InsightsComparisonControlsQuery['controls']['demographics']
type ComparisonsProps = {
  statements: InsightsStatements
  demographics: InsightsDemographics
} & InsightsTabProps

const Comparisons: React.FC<ComparisonsProps> = ({
  survey,
  statements,
  demographics,
  searchParams,
  filters,
  timeframeDropdownLocation,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }

  const getMatchingObject = <
    ComparisonObject extends InsightsStatements[0] | InsightsDemographics[0]
  >(
    arr: ComparisonObject[],
    val: string,
  ) => {
    return arr.find(i => i.code === val)
  }
  const [y, setY] = useState(
    getMatchingObject<InsightsDemographics[0]>(demographics, String(searchParams.y)),
  )
  const [x, setX] = useState(
    getMatchingObject<InsightsDemographics[0]>(demographics, String(searchParams.x)),
  )
  const isEmployee = survey.productType === SurveyProductTypeEnum.EMPLOYEE
  const isResident = survey.productType === SurveyProductTypeEnum.RESIDENT

  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const statementsByFocus = groupBy(statements, s => s.focus || s.residentResultsGroup)
  const focusMap: { [key in QFocus | QResidentResultsGroup]?: string } = isEmployee
    ? QUESTION_FOCUSES
    : RESIDENT_RESULTS_GROUP_LABELS
  const OTHER_FOCUS = 'Other'
  let focusStatements = Object.keys(statementsByFocus).map(focus => ({
    text: focusMap[focus as QFocus | QResidentResultsGroup] || OTHER_FOCUS,
    subgroup: isEmployee ? 'Dimensions of Trust' : 'Areas',
    code: statementsByFocus[focus].map(s => s.code).join(','),
  }))

  const focusStatementsOrdering = isEmployee
    ? Object.values(QUESTION_FOCUSES).concat(OTHER_FOCUS)
    : Object.values(RESIDENT_RESULTS_GROUP_LABELS).concat(OTHER_FOCUS)
  focusStatements = sortBy(focusStatements, focus => focusStatementsOrdering.indexOf(focus.text))

  const overallStatement = {
    text: isEmployee ? 'Trust Index Score' : 'Customer Engagement Score',
    subgroup: isEmployee ? 'Dimensions of Trust' : 'Areas',
    code: 'OVERALL',
  }

  const statementOptions = [
    ...statements.map(s => ({
      ...s,
      subgroup: 'Statements',
    })),
    overallStatement,
    ...focusStatements,
  ]

  let defaultStatementCode: undefined | BenchmarkCodeType
  if (isEmployee) {
    defaultStatementCode = BenchmarkCodeType.TI_60
  }
  if (isResident) {
    defaultStatementCode = BenchmarkCodeType.CORE_Q1_RECOMMEND
  }

  defaultStatementCode = (statements.find(
    // Some surveys don't necessarily have the default statement code. In this case, pick the first statement available
    statement => statement.code === defaultStatementCode,
  )?.code || statements[0].code) as BenchmarkCodeType

  const [statement, setStatement] = useState(
    defaultStatementCode &&
      getMatchingObject<typeof statementOptions[0]>(
        statementOptions,
        searchParams.statement ? String(searchParams.statement) : defaultStatementCode, // Using ternary because String([]) is truthy
      ),
  )

  if (!statement) return <></>
  return (
    <>
      <BreadcrumbsItem
        to={getInsightsPage(survey.uuid, InsightsModulesEnum.COMMENTS, survey.productType)}
      >
        Comparisons
      </BreadcrumbsItem>
      <div className={classes.comparisonControls}>
        <Typography variant="body2" color="textSecondary">
          See:
        </Typography>
        {/* This Dropdown is disabled because we are using custom click behavior */}
        <Dropdown
          style={{ color: 'white' }}
          value={statement?.code}
          useDarkStyle
          disabled
          onClick={(e: SyntheticEvent) => {
            setAnchorEl(e.currentTarget)
          }}
        >
          <MenuItem value={statement?.code} className={classes.defaultMenuOption}>
            {statement?.text}
          </MenuItem>
        </Dropdown>
        {anchorEl !== null && (
          <GroupedSelect
            anchorEl={anchorEl}
            items={statementOptions}
            selected={statement}
            onChange={(s: typeof statementOptions[0]) => {
              gaEvent({
                action: 'comparisonsSelectStatement',
                category: 'Insights',
              })
              setStatement(s)
            }}
            getItemValue={item => item.code}
            getItemLabel={item => item.text}
            onClose={() => setAnchorEl(null)}
            showAlphaList={false}
          />
        )}
        <Typography variant="body2" color="textSecondary" style={{ marginLeft: 24 }}>
          Compare:
        </Typography>
        <Dropdown
          value={y?.code || ''}
          onChange={e => {
            gaEvent({
              action: 'comparisonsSelectDemographic',
              category: 'Insights',
            })
            setY(getMatchingObject<InsightsDemographics[0]>(demographics, String(e.target.value)))
          }}
          displayEmpty
          useDarkStyle
        >
          <MenuItem value="" className={classes.defaultMenuOption}>
            Select One
          </MenuItem>

          {demographics
            .filter(d => d.code !== x?.code)
            .map(d => {
              if (!d) return null
              return (
                <MenuItem key={d.code} value={d.code}>
                  {d.text}
                </MenuItem>
              )
            })}
        </Dropdown>
        <Typography variant="body2" color="textSecondary" style={{ marginLeft: 24 }}>
          Compare:
        </Typography>
        <Dropdown
          className={classes.comparisonSelect}
          variant="filled"
          value={x?.code || ''}
          onChange={e => {
            gaEvent({
              action: 'comparisonsSelectDemographic',
              category: 'Insights',
            })
            setX(getMatchingObject<InsightsDemographics[0]>(demographics, String(e.target.value)))
          }}
          input={<FilledInput />}
          displayEmpty
          useDarkStyle
        >
          <MenuItem value="" className={classes.defaultMenuOption}>
            Select One
          </MenuItem>

          {demographics
            .filter(d => d.code !== y?.code)
            .map(d => (
              <MenuItem key={d.code} value={d.code}>
                {d.text}
              </MenuItem>
            ))}
        </Dropdown>
        {timeframeDropdownLocation === TimeframeDropDownLocation.COMPONENT && (
          <div className={classes.timeframeDropdown}>
            <TimeFrameDropdown survey={survey} />
          </div>
        )}
      </div>
      <div className={classes.comparisonPiesContainer}>
        {statement && (
          <ComparisonDonuts
            surveyUuid={survey.uuid}
            minShowableResults={survey.minShowableResults}
            filters={filters}
            selectedGroup={statement.subgroup}
            statementLabel={statement.text}
            statementCodes={
              statement.code !== overallStatement.code ? statement.code.split(',') : undefined
            }
            xAxis={x}
            yAxis={y}
            surveyProductType={survey.productType}
          />
        )}
      </div>
    </>
  )
}

export default ComparisonsContainer
