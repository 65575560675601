import React, { Dispatch, FC, SetStateAction } from 'react'

import { Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'

import { CultureBriefImageType } from '../../generated/graphql'
import IconButton from '../Blocks/CustomButtons/IconButton'

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      size: 30,
    },
  },
  imageContainer: {
    width: 'auto',
    height: 'auto',
    '& >img': {
      maxWidth: '100%',
      maxHeight: '82%',
      margin: 'auto',
    },
  },
  closeButton: {
    float: 'right',
  },
}))
const ImagePreviewer: FC<{
  images: Array<CultureBriefImageType>
  previewIndex?: number
  setPreviewIndex: Dispatch<SetStateAction<number | undefined>>
}> = ({ images, previewIndex, setPreviewIndex }) => {
  const classes = useStyles()
  const index = previewIndex === undefined ? 0 : previewIndex

  return (
    <Dialog
      open={previewIndex !== undefined}
      fullWidth
      maxWidth="md"
      onClose={() => setPreviewIndex(undefined)}
      aria-describedby="preview-dialog"
      aria-labelledby="preview-dialog-title"
    >
      <DialogTitle id="preview-dialog-title">
        <div className={classes.dialogTitle}>
          <Typography>Preview</Typography>
          <IconButton
            color="secondaryHover"
            aria-label="Close"
            className={classes.closeButton}
            onClick={() => setPreviewIndex(undefined)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent id="preview-dialog">
        <div className={classes.previewContainer}>
          {images.length > 1 && (
            <IconButton
              color="secondaryHover"
              onClick={() => setPreviewIndex(index === 0 ? images.length - 1 : index - 1)}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          <div className={classes.imageContainer}>
            <img alt="" src={images[index]?.url} />
          </div>
          {images.length > 1 && (
            <IconButton
              color="secondaryHover"
              onClick={() => setPreviewIndex(index === images.length - 1 ? 0 : index + 1)}
            >
              <ChevronRightIcon />
            </IconButton>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ImagePreviewer
