import React, { ReactElement } from 'react'

import { QueryHookOptions, QueryResult, FetchPolicy } from '@apollo/client'
import get from 'lodash/get'

import Loader from 'components/Blocks/Loader'
import { TimeRange } from 'components/Insights/Blocks/TimeTrendingTimeframeDropdown'

type TimeframeQueryVars<QVars> = QVars & TimeRange
type Props<QDoc, QVars> = {
  timeRanges: TimeRange[]
  queryHook(options: QueryHookOptions<QDoc, QVars>): QueryResult<QDoc, QVars>
  variables: QVars
  // // `uuid` here refers to an `id` set in `getTimeframes`.
  children(results: Array<{ uuid: string } & QDoc>, loading: boolean): ReactElement
  skip?: boolean
  fetchPolicy?: FetchPolicy
}
/**
 * DynamicTimeframeQuery component renders an Apollo <Query> component
 * for each supplied timeframe and returns the composed results.
 */
const DynamicTimeframeQuery = <QueryDocument, QueryVars>({
  timeRanges,
  queryHook,
  variables,
  children,
  fetchPolicy,
  skip = false,
  ...restQueryVars
}: Props<QueryDocument, QueryVars>): ReactElement => {
  if (skip) return children([], false)
  const responses = timeRanges.map(timeRange =>
    queryHook({
      ...restQueryVars,
      variables: { ...variables, ...timeRange } as TimeframeQueryVars<QueryVars>,
      fetchPolicy,
    }),
  )
  const errorResponse = responses.find(r => r.error)
  if (errorResponse) {
    const errorMessage: string = get(
      errorResponse.error?.graphQLErrors,
      '0.message',
      errorResponse.error?.message,
    )
    console.error(errorMessage)
    return <span>{`Error: ${errorMessage}`}</span>
  }
  /**
   * TODO: fix this with previousData
   * IMPORTANT NOTE: we do not use LoadingOverlay here because the overlay renders children during loading,
   * which can result in an error state where one timeframe query finishes and others have not.
   * This can eventually be solved by saving state and making sure we don't render new children in the overlay
   * until all queries are loaded. For now, just rerender the entire child component.
   */
  if (responses.some(r => r.loading || !r.data)) {
    return <Loader />
  }
  const results = responses.map(response => ({
    ...(response.data as NonNullable<typeof response.data>),
    // Typescript can't infer that we added `id` to variables at the beginning of the function.
    uuid: (response.variables as TimeframeQueryVars<QueryVars>).uuid,
  }))
  const loading = responses.some(r => r.loading)
  return children(results, loading)
}
/**
 * Per https://reactjs.org/docs/hooks-rules.html running hooks in a loop is discouraged
 * because React doesn't know whether to rerender your component. One way to circumvent this
 * is to guarantee the component is rerendered when the number of hooks would change.
 * In this case, generate a key from the parameter that controls the number of hooks.
 */
const DynamicTimeframeQueryContainer: typeof DynamicTimeframeQuery = props => {
  return (
    <div key={props.timeRanges.map(timeRange => timeRange.uuid).join()}>
      <DynamicTimeframeQuery {...props} />
    </div>
  )
}
export default DynamicTimeframeQueryContainer
