import React from 'react'

import uniqBy from 'lodash/uniqBy'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import ConfigurableQuestions from 'components/Survey/ResidentWizard/ConfigurableQuestions'
import ResidentPulseQuestions from 'components/Survey/ResidentWizard/ResidentPulseQuestions'
import {
  useSurveysQuestionsQuery,
  useSurveysDeleteQuestionMutation,
  SurveysQuestionsDocument,
  SurveysSurveyQuery,
  SurveyTypeEnum,
} from 'generated/graphql'

type Props = {
  survey: SurveysSurveyQuery['survey']
  goBack(): void
  goNext(uuid?: string): void
}
const ConfigurableQuestionsContainer: React.FC<Props> = ({ survey, goBack, goNext }) => {
  const questionsVars = { surveyUuid: survey.uuid }
  const result = useSurveysQuestionsQuery({ variables: questionsVars })
  const [deleteQuestion] = useSurveysDeleteQuestionMutation()
  const handleDeleteQuestion = (questionUuid: string) => {
    deleteQuestion({
      variables: {
        surveyUuid: survey.uuid,
        questionUuid,
      },
      refetchQueries: [
        {
          query: SurveysQuestionsDocument,
          variables: {
            surveyUuid: survey.uuid,
          },
        },
      ],
    })
  }
  return (
    <ResponseHandler {...result}>
      {data => {
        // Ignore duplicate questions (on the survey app we choose one based on ppt type)
        const questions = uniqBy(
          data.questions.filter(
            question =>
              // we don't want to show the child questions
              !question.dependsOn,
          ),
          'text',
        )
        const commonProps = {
          survey,
          goBack,
          goNext,
          handleDeleteQuestion,
          questions,
          refetchQuestions: () => result.refetch(questionsVars),
        }
        const isPulse = survey.type === SurveyTypeEnum.RESIDENT_PULSE
        return isPulse ? (
          <ResidentPulseQuestions {...commonProps} />
        ) : (
          <ConfigurableQuestions {...commonProps} />
        )
      }}
    </ResponseHandler>
  )
}

export default ConfigurableQuestionsContainer
