import React, { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import chunk from 'lodash/chunk'
import mapValues from 'lodash/mapValues'
import { ValidatorForm } from 'react-material-ui-form-validator'

import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import {
  DataTypeOptionType,
  SurveyProductTypeEnum,
  ParticipantInput,
  BenchmarkCodeType,
  SurveyPhoneStatusErrorLevelEnum,
} from 'generated/graphql'
import { ResidentRespondentTypeEnum } from 'utils/generatedEnums'
import { Participants } from 'utils/types'

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    width: 500,
  },
  body: {
    paddingBottom: 40,
  },
  row: {
    display: 'flex',
    '& >div': {
      width: '45%',
      marginTop: 10,
      marginRight: '5%',
      '& >div': {
        width: '100%',
      },
    },
  },
  confirmButton: {
    background: palette.common.primary,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24)',
  },
  error: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
  },
  subtitle: {
    paddingTop: spacing(4),
    paddingBottom: spacing(2),
    '& >span': {
      color: palette.common.navy50,
      fontStyle: 'italic',
    },
  },
}))

type SProps = {
  options: NonNullable<DataTypeOptionType['standards']>
  name: string
  value: string
  onChange: (arg: string) => void
  disabled: boolean
}

const SelectField: React.FC<SProps> = ({ options, name, value, disabled, onChange }) => {
  return (
    <FormControl>
      <InputLabel>{name}</InputLabel>
      <Select
        disabled={disabled}
        value={value}
        onChange={e => onChange((e.target as HTMLInputElement).value)}
        name={name}
      >
        {options.map(({ text, value: optionVal }) => (
          <MenuItem key={optionVal} value={optionVal}>
            <span>{text}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

type Props = {
  missingPhonesErrorLevel: SurveyPhoneStatusErrorLevelEnum
  submitDisabledText?: string
  surveyDataTypeOptions: DataTypeOptionType[]
  onClose(): void
  onSubmit(args: ParticipantInput): Promise<string | undefined>
  participant: null | Participants[0]
  productType: SurveyProductTypeEnum
  loading: boolean
}

type DTValues = Partial<{ [key in BenchmarkCodeType]: string }>

const getDefaultDTVals = (participant: null | Participants[0]) => {
  const defaultValues: DTValues = {}
  if (!participant) return defaultValues
  for (const dt of participant.dataTypeValues) {
    defaultValues[dt.dtCode] = dt.value
  }
  return defaultValues
}

// To help disambiguate resident data types from representatives.
const RESIDENT_DT_CODE_TO_DISPLAY_NAME: Partial<{ [key in BenchmarkCodeType]: string }> = {
  [BenchmarkCodeType.TI_GENDER]: 'Resident Gender',
  [BenchmarkCodeType.SENIOR_DATE_OF_BIRTH]: 'Resident Date of Birth',
  [BenchmarkCodeType.TI_RACE]: 'Resident Ethnicity',
  [BenchmarkCodeType.MARITAL_STATUS]: 'Resident Marital Status',
  [BenchmarkCodeType.R_DATE_OF_BIRTH]: 'Family/Fiduciary Date of Birth',
  [BenchmarkCodeType.R_GENDER]: 'Family/Fiduciary Gender',
}

const UpdateParticipant: React.FC<Props> = ({
  missingPhonesErrorLevel,
  submitDisabledText,
  surveyDataTypeOptions,
  participant,
  onSubmit,
  onClose,
  productType,
  loading,
}) => {
  const requiredDataTypes = surveyDataTypeOptions.filter(dt => dt.required)
  const classes = useStyles()
  const [error, setError] = useState('')
  // Fields we ask for explicitly, that may not come precoded
  const [surveyCode, setSurveyCode] = useState(participant?.surveyCode || '')
  const [firstName, setFirstName] = useState(participant?.firstName || '')
  const [workEmail, setWorkEmail] = useState(participant?.workEmail || '')
  const [personalEmail, setPersonalEmail] = useState(participant?.personalEmail || '')
  const [mobilePhone, setMobilePhone] = useState(participant?.mobilePhone || '')
  // All other data types
  const [dataTypeVals, setDataTypeVals] = useState<DTValues>(getDefaultDTVals(participant))
  const [showMissingPhoneDialog, setShowMissingPhoneDialog] = useState(false)

  const isMobilePhoneRequired = missingPhonesErrorLevel === SurveyPhoneStatusErrorLevelEnum.ERROR

  const residentValues = [
    ResidentRespondentTypeEnum.RESIDENT,
    ResidentRespondentTypeEnum.PAST_RESIDENT,
  ]
  const isRepresentative = Object.keys(dataTypeVals).some(
    key =>
      key === BenchmarkCodeType.RESIDENT_RESPONDENT_TYPE &&
      !residentValues.includes(dataTypeVals[key] as ResidentRespondentTypeEnum),
  )
  const dtCodesSyncedWithClient = surveyDataTypeOptions
    .filter(dt => dt.mustSyncWithClient)
    .map(dt => dt.code)
  const getDTInput = (dataType: DataTypeOptionType, isRequired: undefined | boolean = false) => {
    const isDisabled = isRepresentative && dtCodesSyncedWithClient.includes(dataType.code)
    const componentLabel = `${dataType.visibleName}${isRequired ? ' *' : ''}`
    return {
      key: dataType.visibleName,
      component: (
        <Tooltip
          // If the user select's a respondent type that maps to a "representative",
          // we want to disable the selection of other filter values in order to prevent
          // choosing values that conflict with the client.
          title={
            isDisabled
              ? `${dataType.visibleName} will be auto-populated from the associated Resident.`
              : ''
          }
        >
          <div>
            {// Show a dropdown if the data type has a list of standards to choose from
            dataType.standards && dataType.standards.length ? (
              <SelectField
                disabled={isDisabled}
                name={componentLabel}
                options={dataType.standards}
                value={dataTypeVals[dataType.code] || ''}
                onChange={(val: string) => {
                  let dtvToUpdate = {
                    ...dataTypeVals,
                    [dataType.code]: val,
                  }
                  // When the user selects a respondent type that maps to a "representative",
                  // reset the fields that must stay in sync with client.
                  if (
                    dataType.code === BenchmarkCodeType.RESIDENT_RESPONDENT_TYPE &&
                    !residentValues.includes(val as ResidentRespondentTypeEnum)
                  ) {
                    dtvToUpdate = mapValues(dtvToUpdate, (value, key) =>
                      dtCodesSyncedWithClient.includes(key as BenchmarkCodeType) ? '' : value,
                    )
                  }
                  setDataTypeVals(dtvToUpdate)
                }}
              />
            ) : (
              <TextField
                name={componentLabel}
                disabled={isDisabled}
                value={dataTypeVals[dataType.code] || ''}
                onChange={e =>
                  setDataTypeVals({
                    ...dataTypeVals,
                    [dataType.code]: (e.target as HTMLInputElement).value,
                  })
                }
                label={componentLabel}
              />
            )}
          </div>
        </Tooltip>
      ),
    }
  }
  const filteredFields: string[] = {
    [SurveyProductTypeEnum.RESIDENT]: ['workEmail'],
    // We only use first names in resident email notifications.
    [SurveyProductTypeEnum.EMPLOYEE]: ['firstName'],
  }[productType]
  const inputFields = [
    // Add the explicit fields
    {
      key: 'surveyCode',
      component: (
        <TextField
          name="SurveyCode"
          value={surveyCode}
          onChange={e => setSurveyCode((e.target as HTMLInputElement).value)}
          label="Survey Code*"
        />
      ),
    },
    ...requiredDataTypes.map(dt => getDTInput(dt, true)),
    {
      key: 'workEmail',
      component: (
        <TextField
          name="WorkEmail"
          value={workEmail}
          onChange={e => setWorkEmail((e.target as HTMLInputElement).value)}
          label="Work Email"
        />
      ),
    },
    {
      key: 'personalEmail',
      component: (
        <TextField
          name="PersonalEmail"
          value={personalEmail}
          onChange={e => setPersonalEmail((e.target as HTMLInputElement).value)}
          label="Personal Email"
        />
      ),
    },
    {
      key: 'mobilePhone',
      component: (
        <TextField
          name="MobilePhone"
          value={mobilePhone}
          onChange={e => setMobilePhone((e.target as HTMLInputElement).value)}
          label={`Mobile Phone ${isMobilePhoneRequired ? ' *' : ''}`}
        />
      ),
    },
    {
      key: 'firstName',
      component: (
        <TextField
          name="FirstName"
          value={firstName}
          onChange={e => setFirstName((e.target as HTMLInputElement).value)}
          label="First Name (for notifications)"
        />
      ),
    },
  ].filter(field => !filteredFields.includes(field.key))
  const chunkedInputFields = chunk(inputFields, 2)
  const optionalInputFields = surveyDataTypeOptions
    .filter(dt => !dt.required)
    .map(dt => ({
      ...dt,
      visibleName:
        productType === SurveyProductTypeEnum.RESIDENT
          ? RESIDENT_DT_CODE_TO_DISPLAY_NAME[dt.code] || dt.visibleName
          : dt.visibleName,
    }))
    .map(dt => getDTInput(dt))
    .filter(field => !filteredFields.includes(field.key))
  const chunkedOptionalInputFields = chunk(optionalInputFields, 2)
  const clearFields = () => {
    setSurveyCode('')
    setFirstName('')
    setWorkEmail('')
    setPersonalEmail('')
    setMobilePhone('')
    setDataTypeVals({})
  }
  const requiredDataTypesToSubmit = requiredDataTypes.filter(
    dt => !isRepresentative || !dtCodesSyncedWithClient.includes(dt.code),
  )

  const onUpdateParticipant = async () => {
    const errorMessage = await onSubmit({
      surveyCode,
      firstName,
      personalEmail,
      workEmail,
      mobilePhone,
      dataTypes: Object.keys(dataTypeVals).map(code => ({
        code: code as BenchmarkCodeType,
        value: String(dataTypeVals[code as BenchmarkCodeType]),
      })),
    })
    if (errorMessage) {
      setError(errorMessage)
    } else {
      clearFields()
    }
  }

  return (
    <>
      <Dialog open>
        <DialogTitle id="form-dialog-title">
          {participant ? 'Update Participant' : 'Add Participants'}
        </DialogTitle>
        <ValidatorForm onSubmit={() => {}} instantValidate={false} className={classes.container}>
          <DialogContent dividers className={classes.body}>
            <Typography variant="body2" color="textSecondary">
              *Field is required
            </Typography>
            {chunkedInputFields.map((fields, index) => (
              <div className={classes.row} key={`${fields[0].key}${index}`}>
                {fields.map(field => (
                  <React.Fragment key={field.key}>{field.component}</React.Fragment>
                ))}
              </div>
            ))}
            <Typography variant="h6" className={classes.subtitle}>
              Additional Information <span>Optional</span>
            </Typography>
            {chunkedOptionalInputFields.map((fields, index) => (
              <div className={classes.row} key={`${fields[0].key}${index}`}>
                {fields.map(field => (
                  <React.Fragment key={field.key}>{field.component}</React.Fragment>
                ))}
              </div>
            ))}
          </DialogContent>
          {error && (
            <Typography className={classes.error} color="error">
              {error}
            </Typography>
          )}
          <ActionDialogButtons
            isSubmitting={loading}
            submitDisabled={
              !surveyCode ||
              !requiredDataTypesToSubmit.every(dt => dataTypeVals[dt.code]) ||
              (isMobilePhoneRequired && !mobilePhone) ||
              Boolean(submitDisabledText)
            }
            submitDisabledText={submitDisabledText}
            onClose={() => {
              clearFields()
              onClose()
            }}
            submitButtonText={participant ? 'Update' : 'Add'}
            onSubmit={() =>
              missingPhonesErrorLevel === SurveyPhoneStatusErrorLevelEnum.WARNING && !mobilePhone
                ? setShowMissingPhoneDialog(true)
                : onUpdateParticipant()
            }
          />
        </ValidatorForm>
      </Dialog>
      {showMissingPhoneDialog && (
        <ActionDialog
          title={`${participant ? 'Update' : 'Add'} Participant?`}
          cancelButtonText="Back to Edit"
          content="Would you still like to add this participant even though mobile phone number is missing?"
          submitButtonText="Confirm"
          onClose={() => setShowMissingPhoneDialog(false)}
          onSubmit={() => {
            setShowMissingPhoneDialog(false)
            onUpdateParticipant()
          }}
        />
      )}
    </>
  )
}

export default UpdateParticipant
