import { DateTimeString } from 'utils/types';
import { DateString } from 'utils/types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: DateTimeString;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: DateString;
};

export type ActionItemNode = {
  __typename?: 'ActionItemNode';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  actionPlan: ActionPlanNode;
  statement?: Maybe<FrozenQuestionType>;
  /** A Custom Statement is determined by plain text and is not linked to an actual FrozenQuestion. */
  customStatement: Scalars['String'];
  customStatementProductType?: Maybe<SurveyProductTypeEnum>;
  positive: Scalars['Int'];
  inconsistent: Scalars['Int'];
  negative: Scalars['Int'];
  actionItemTasks: Array<ActionItemTaskNode>;
};

export type ActionItemsCountsType = {
  __typename?: 'ActionItemsCountsType';
  activeStatements: Scalars['Int'];
  notStarted: Scalars['Int'];
  inProgress: Scalars['Int'];
  onHold: Scalars['Int'];
  complete: Scalars['Int'];
  incomplete: Scalars['Int'];
};

export type ActionItemTaskCommentInput = {
  uuid?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  mentions?: Maybe<Array<UserCommentMentionInput>>;
};

export type ActionItemTaskCommentNode = Node & {
  __typename?: 'ActionItemTaskCommentNode';
  uuid: Scalars['ID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  text: Scalars['String'];
  user: UserNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  mentions: Array<UserCommentMentionType>;
};

export type ActionItemTaskCommentNodeConnection = {
  __typename?: 'ActionItemTaskCommentNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActionItemTaskCommentNodeEdge>>;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ActionItemTaskCommentNode` and its cursor. */
export type ActionItemTaskCommentNodeEdge = {
  __typename?: 'ActionItemTaskCommentNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ActionItemTaskCommentNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ActionItemTaskInput = {
  text: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  status?: Maybe<TaskStatusEnum>;
  dueDate?: Maybe<Scalars['Date']>;
};

export type ActionItemTaskNode = {
  __typename?: 'ActionItemTaskNode';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  task: Scalars['String'];
  status: TaskStatusEnum;
  dueDate?: Maybe<Scalars['Date']>;
  /** Free text to add the owner of a task. */
  owner: Scalars['String'];
  actionItem: ActionItemNode;
  actionPlan?: Maybe<ActionPlanNode>;
  comments: ActionItemTaskCommentNodeConnection;
};


export type ActionItemTaskNodeCommentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ActionPlanActivityType = {
  __typename?: 'ActionPlanActivityType';
  pastDays: Scalars['Int'];
  activityCount: Scalars['Int'];
};

export type ActionPlanCountsType = {
  __typename?: 'ActionPlanCountsType';
  employee?: Maybe<ActionItemsCountsType>;
  resident?: Maybe<ActionItemsCountsType>;
  custom?: Maybe<ActionItemsCountsType>;
};

export type ActionPlanNode = Node & {
  __typename?: 'ActionPlanNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  owner: UserNode;
  organization: OrganizationType;
  actionItems: Array<ActionItemNode>;
  actionItemTasks: Array<ActionItemTaskNode>;
  created: Scalars['DateTime'];
  usersWithAccess: Array<UserNode>;
  surveyTi?: Maybe<SurveyNode>;
  surveyResidentEngagement?: Maybe<SurveyNode>;
  progress: ActionPlanCountsType;
  lastUpdated: Scalars['DateTime'];
  lastSurveyData?: Maybe<LastSurveyData>;
};


export type ActionPlanNodeLastSurveyDataArgs = {
  surveyType?: Maybe<SurveyTypeEnum>;
};

export type ActionPlanNodeConnection = {
  __typename?: 'ActionPlanNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActionPlanNodeEdge>>;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ActionPlanNode` and its cursor. */
export type ActionPlanNodeEdge = {
  __typename?: 'ActionPlanNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ActionPlanNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum ActionPlansSortByEnum {
  OWNER = 'OWNER',
  FOLLOW = 'FOLLOW',
  UPDATED = 'UPDATED'
}

export type ActionPlanSurveyInput = {
  surveyUuid: Scalars['String'];
  surveyType: ActionPlanSurveyType;
};

export enum ActionPlanSurveyType {
  TI = 'TI',
  RESIDENT_ENGAGEMENT = 'RESIDENT_ENGAGEMENT'
}

export type AddActionPlanCustomStatement = {
  __typename?: 'AddActionPlanCustomStatement';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

/** Add a new Action Plan Item (represented by a statement actually) */
export type AddActionPlanStatements = {
  __typename?: 'AddActionPlanStatements';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

export type AddContactsUpload = {
  __typename?: 'AddContactsUpload';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type AddCultureBriefImages = {
  __typename?: 'AddCultureBriefImages';
  errors?: Maybe<Array<Scalars['String']>>;
  cultureBrief?: Maybe<CultureBriefType>;
};

export type AddParticipantsUpload = {
  __typename?: 'AddParticipantsUpload';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type AgentInstructionType = {
  __typename?: 'AgentInstructionType';
  text: Scalars['String'];
  kind?: Maybe<QKind>;
  question?: Maybe<QuestionType>;
};

export type AnalyticsIdentifiersType = {
  __typename?: 'AnalyticsIdentifiersType';
  isTestAccount: Scalars['String'];
  orgName: Scalars['String'];
  residentSolutionName?: Maybe<Scalars['String']>;
  solutionName?: Maybe<Scalars['String']>;
  birthYearRange?: Maybe<Scalars['String']>;
  levelOfCare?: Maybe<Scalars['String']>;
  residentRespondentType?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum BenchmarkCodeType {
  AI_DEPT = 'ai_dept',
  AI_IMPACT = 'ai_impact',
  AI_LOC = 'ai_loc',
  AI_LOCATION = 'ai_location',
  AI_RESIDENT_IMPACT = 'ai_resident_impact',
  AI_SHIFT = 'ai_shift',
  AI_WHERE_WORK = 'ai_where_work',
  APPLICATION_DATE = 'application_date',
  ARRIVAL_CLEANLINESS = 'arrival_cleanliness',
  ASSISTANCE = 'assistance',
  ASSISTANCE_TYPE = 'assistance_type',
  BEST_THING = 'best_thing',
  BUILDING = 'building',
  BUSINESS_SEGMENT = 'business_segment',
  CAMPUS = 'campus',
  CARE_QUESTIONS = 'care_questions',
  CARE_TERM_ID = 'care_term_id',
  CAREGIVER_TIMELINESS = 'caregiver_timeliness',
  CAREGIVING_1 = 'caregiving_1',
  CAREGIVING_2 = 'caregiving_2',
  CAREGIVING_COMMENTS = 'caregiving_comments',
  CLEANLINESS = 'cleanliness',
  CLIENT_EXTERNAL_ID = 'client_external_id',
  CLIENT_NAME = 'client_name',
  CLIENTS_MATCHING = 'clients_matching',
  COMMUNICATION = 'communication',
  COMPANIONSHIP = 'companionship',
  CORE_Q1_RECOMMEND = 'core_q1_recommend',
  CORE_Q2_STAFF = 'core_q2_staff',
  CORE_Q3_CARE = 'core_q3_care',
  CORE_Q4_FOOD = 'core_q4_food',
  CORE_Q5_DISCHARGE = 'core_q5_discharge',
  CORE_Q_SUMMARY = 'core_q_summary',
  DATE_OF_BIRTH = 'date_of_birth',
  DEPARTMENT_ID = 'department_id',
  DEPARTMENT_NAME = 'department_name',
  DIGNITY = 'dignity',
  DINING_1 = 'dining_1',
  DINING_2 = 'dining_2',
  DINING_3 = 'dining_3',
  DINING_4 = 'dining_4',
  DINING_COMMENTS = 'dining_comments',
  DINING_EXPERIENCE = 'dining_experience',
  DISCHARGE_REASON = 'discharge_reason',
  DISTANCE = 'distance',
  DIVISION_ID = 'division_id',
  DIVISION_NAME = 'division_name',
  DRIVE_FREQUENCY = 'drive_frequency',
  EMPLOYEE_LEFT_REASON = 'employee_left_reason',
  EMPLOYEE_OTHERS_LEFT_REASON = 'employee_others_left_reason',
  EMPLOYEES_TO_RECOGNIZE = 'employees_to_recognize',
  EMPLYOMENT_TERM_ID = 'emplyoment_term_id',
  FIRST_NAME = 'first_name',
  FLOOR = 'floor',
  FRANCHISE = 'franchise',
  FRIENDLINESS_AND_PROFESSIONALISM = 'friendliness_and_professionalism',
  GROUP = 'group',
  HEALTH = 'health',
  HIRE_DATE = 'hire_date',
  HOME_LIKE = 'home_like',
  HOUSEHOLD_SIZE = 'household_size',
  IGNORE = 'ignore',
  IMPROVEMENTS = 'improvements',
  INDIVIDUAL_NEEDS = 'individual_needs',
  IS_PARTICIPANT = 'is_participant',
  ISOLATED = 'isolated',
  JOB_TITLE = 'job_title',
  LAST_NAME = 'last_name',
  LEADERSHIP = 'leadership',
  LEADERSHIP_COMMENTS = 'leadership_comments',
  LEADERSHIP_RESPONSIVE = 'leadership_responsive',
  LEFT_COMMENTS = 'left_comments',
  LEFT_OUT = 'left_out',
  LEFT_REASON = 'left_reason',
  LENGTH_OF_STAY = 'length_of_stay',
  LICENSED_NURSING_1 = 'licensed_nursing_1',
  LICENSED_NURSING_2 = 'licensed_nursing_2',
  LICENSED_NURSING_COMMENTS = 'licensed_nursing_comments',
  LOCATION_ID = 'location_id',
  LONELINESS_FOLLOWUP = 'loneliness_followup',
  MARITAL_STATUS = 'marital_status',
  MEDICAL_NEEDS = 'medical_needs',
  MOBILE_PHONE = 'mobile_phone',
  MOD_LENGTH_OF_STAY = 'mod_length_of_stay',
  MOVE_IN = 'move_in',
  MOVE_OUT = 'move_out',
  NAME = 'name',
  NEXT_PLACE = 'next_place',
  NPS_RECOMMEND = 'nps_recommend',
  NPS_RECOMMEND_FOLLOWUP = 'nps_recommend_followup',
  ONE_CHANGE = 'one_change',
  ONE_CHANGE_JOB = 'one_change_job',
  ONE_CHANGE_SERVICES = 'one_change_services',
  ONE_IMPROVEMENT = 'one_improvement',
  ONLINE_REVIEW = 'online_review',
  OWNER = 'owner',
  PARTICIPANT_TYPE = 'participant_type',
  PAST_GOOD_FRIENDS = 'past_good_friends',
  PAST_LIFE_BALANCE = 'past_life_balance',
  PAST_RECOGNITION = 'past_recognition',
  PAST_SUPERVISOR_RELATIONSHIP = 'past_supervisor_relationship',
  PAST_TOP_APPRECIATION = 'past_top_appreciation',
  PAYOR_TYPE = 'payor_type',
  PERSONAL_EMAIL = 'personal_email',
  R_BIRTH_YEAR = 'r_birth_year',
  R_DATE_OF_BIRTH = 'r_date_of_birth',
  R_FIRST_NAME = 'r_first_name',
  R_GENDER = 'r_gender',
  R_LAST_NAME = 'r_last_name',
  R_MOBILE_PHONE = 'r_mobile_phone',
  R_PERSONAL_EMAIL = 'r_personal_email',
  R_RELATIONSHIP = 'r_relationship',
  R_SAMPLE_RECORD_ID = 'r_sample_record_id',
  R_SURVEY_CODE = 'r_survey_code',
  RATE_ACCOUNT_BILLING = 'rate_account_billing',
  RATE_ADMISSIONS = 'rate_admissions',
  RATE_BUILDING = 'rate_building',
  RATE_CLINIC = 'rate_clinic',
  RATE_COMMUNICATION_AND_SUPPORT = 'rate_communication_and_support',
  RATE_COMMUNITY = 'rate_community',
  RATE_DAY_CARE_ADULT = 'rate_day_care_adult',
  RATE_DAY_CARE_CHILDREN = 'rate_day_care_children',
  RATE_DENTIST_SPECIALISTS = 'rate_dentist_specialists',
  RATE_ENRICHMENT_ACTIVITIES = 'rate_enrichment_activities',
  RATE_ENRICHMENT_INTEREST = 'rate_enrichment_interest',
  RATE_ENRICHMENT_SCHEDULE = 'rate_enrichment_schedule',
  RATE_ENVIRONMENT_SERVICES = 'rate_environment_services',
  RATE_FOUNDATION_PHILANTHROPY = 'rate_foundation_philanthropy',
  RATE_FRONT_DESK = 'rate_front_desk',
  RATE_HOME_CARE = 'rate_home_care',
  RATE_HOME_HEALTH = 'rate_home_health',
  RATE_HOSPICE = 'rate_hospice',
  RATE_HOUSEKEEPING = 'rate_housekeeping',
  RATE_IT_INFORMATION_SYSTEMS = 'rate_it_information_systems',
  RATE_LANDSCAPING_GROUNDS = 'rate_landscaping_grounds',
  RATE_LAUNDRY = 'rate_laundry',
  RATE_MAINTENANCE = 'rate_maintenance',
  RATE_MARKET_STORE = 'rate_market_store',
  RATE_MARKETING_OR_ADMISSIONS = 'rate_marketing_or_admissions',
  RATE_MEDICAL = 'rate_medical',
  RATE_MEDICATION_TECHNICIANS = 'rate_medication_technicians',
  RATE_OCCUPATIONAL_THERAPY = 'rate_occupational_therapy',
  RATE_PARKING = 'rate_parking',
  RATE_PHYSICAL_THERAPY = 'rate_physical_therapy',
  RATE_RECOGNITION = 'rate_recognition',
  RATE_SAFETY = 'rate_safety',
  RATE_SALON = 'rate_salon',
  RATE_SERVICE_COORDINATORS = 'rate_service_coordinators',
  RATE_SOCIAL_WORK = 'rate_social_work',
  RATE_SPEECH_LANGUAGE_PATHOLOGY = 'rate_speech_language_pathology',
  RATE_SPIRITUAL_SERVICES = 'rate_spiritual_services',
  RATE_TRAINING = 'rate_training',
  RATE_TRANSPORTATION = 'rate_transportation',
  RATE_UNIT = 'rate_unit',
  RATE_WELLNESS_FITNESS = 'rate_wellness_fitness',
  READINESS = 'readiness',
  REGION_ID = 'region_id',
  REGION_NAME = 'region_name',
  RESIDENT_GROUP = 'resident_group',
  RESIDENT_RESPONDENT_TYPE = 'resident_respondent_type',
  SAFE = 'safe',
  SAFE_COMMENTS = 'safe_comments',
  SAMPLE_RECORD_ID = 'sample_record_id',
  SENIOR_BIRTH_YEAR = 'senior_birth_year',
  SENIOR_DATE_OF_BIRTH = 'senior_date_of_birth',
  SERVICE_AREA_COMMENTS = 'service_area_comments',
  STAFF_CONTACT = 'staff_contact',
  STATE = 'state',
  SUBTEAM = 'subteam',
  SUPERVISOR_ID = 'supervisor_id',
  SUPERVISOR_NAME = 'supervisor_name',
  SURVEY_CODE = 'survey_code',
  TEAM = 'team',
  TERMINATION_DATE = 'termination_date',
  TERMINATION_REASON = 'termination_reason',
  TERRITORY_CODE = 'territory_code',
  TESTIMONIALS_APPROVAL = 'testimonials_approval',
  TESTIMONIALS_PRIVACY = 'testimonials_privacy',
  TESTIMONIALS_REVIEW = 'testimonials_review',
  TI_1 = 'ti_1',
  TI_10 = 'ti_10',
  TI_11 = 'ti_11',
  TI_12 = 'ti_12',
  TI_13 = 'ti_13',
  TI_14 = 'ti_14',
  TI_15 = 'ti_15',
  TI_16 = 'ti_16',
  TI_17 = 'ti_17',
  TI_18 = 'ti_18',
  TI_19 = 'ti_19',
  TI_2 = 'ti_2',
  TI_20 = 'ti_20',
  TI_21 = 'ti_21',
  TI_22 = 'ti_22',
  TI_23 = 'ti_23',
  TI_24 = 'ti_24',
  TI_25 = 'ti_25',
  TI_26 = 'ti_26',
  TI_27 = 'ti_27',
  TI_28 = 'ti_28',
  TI_29 = 'ti_29',
  TI_3 = 'ti_3',
  TI_30 = 'ti_30',
  TI_31 = 'ti_31',
  TI_32 = 'ti_32',
  TI_33 = 'ti_33',
  TI_34 = 'ti_34',
  TI_35 = 'ti_35',
  TI_36 = 'ti_36',
  TI_37 = 'ti_37',
  TI_38 = 'ti_38',
  TI_39 = 'ti_39',
  TI_4 = 'ti_4',
  TI_40 = 'ti_40',
  TI_41 = 'ti_41',
  TI_42 = 'ti_42',
  TI_43 = 'ti_43',
  TI_44 = 'ti_44',
  TI_45 = 'ti_45',
  TI_46 = 'ti_46',
  TI_47 = 'ti_47',
  TI_48 = 'ti_48',
  TI_49 = 'ti_49',
  TI_5 = 'ti_5',
  TI_50 = 'ti_50',
  TI_51 = 'ti_51',
  TI_52 = 'ti_52',
  TI_53 = 'ti_53',
  TI_54 = 'ti_54',
  TI_55 = 'ti_55',
  TI_56 = 'ti_56',
  TI_57 = 'ti_57',
  TI_58 = 'ti_58',
  TI_59 = 'ti_59',
  TI_6 = 'ti_6',
  TI_60 = 'ti_60',
  TI_7 = 'ti_7',
  TI_8 = 'ti_8',
  TI_9 = 'ti_9',
  TI_BIRTH_YEAR = 'ti_birth_year',
  TI_CHANGE = 'ti_change',
  TI_DISABILITIES = 'ti_disabilities',
  TI_EXECUTIVE_TEAM = 'ti_executive_team',
  TI_GENDER = 'ti_gender',
  TI_LGBT = 'ti_lgbt',
  TI_MANAGERIAL_LEVEL = 'ti_managerial_level',
  TI_OPPORTUNITIES = 'ti_opportunities',
  TI_PAY_TYPE = 'ti_pay_type',
  TI_RACE = 'ti_race',
  TI_RESPONSIBILITY = 'ti_responsibility',
  TI_TENURE = 'ti_tenure',
  TI_UNIQUE = 'ti_unique',
  TI_WORK_POSTAL_CODE = 'ti_work_postal_code',
  TI_WORK_STATUS = 'ti_work_status',
  UNIT = 'unit',
  USER_ACTIVE = 'user_active',
  VALUE = 'value',
  WHY_LOCATION = 'why_location',
  WORK_EMAIL = 'work_email'
}

export type BenchmarkConfigInput = {
  checked: Scalars['Boolean'];
  code: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
};

export type BenchmarkDepartmentType = {
  __typename?: 'BenchmarkDepartmentType';
  checked: Scalars['Boolean'];
  code: Scalars['String'];
  name: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
  locs: Array<Scalars['String']>;
};

export type BenchmarkLevelOfCareType = {
  __typename?: 'BenchmarkLevelOfCareType';
  checked: Scalars['Boolean'];
  code: Scalars['String'];
  name: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
  seniorLiving: Scalars['Boolean'];
  atHome: Scalars['Boolean'];
  multipleLocations: Scalars['Boolean'];
  /** Whether the level of care can be disabled */
  required: Scalars['Boolean'];
  /** Whether the level of care can be enabled */
  disabled: Scalars['Boolean'];
};

/**
 * A GraphQL type representing a generic benchmark, not necessarily backed
 * by a Django model.
 */
export type BenchmarkNode = {
  __typename?: 'BenchmarkNode';
  benchmarkType: BenchmarkTypeEnum;
  benchmarkUuid: Scalars['String'];
  lessThanMin: Scalars['Boolean'];
  minShowableResults: Scalars['Int'];
  name: Scalars['String'];
  group?: Maybe<Scalars['String']>;
};

/**
 * A GraphQL type representing a generic benchmark input, not necessarily backed
 * by a Django model.
 */
export type BenchmarkNodeInput = {
  benchmarkType?: Maybe<BenchmarkTypeEnum>;
  benchmarkUuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type BenchmarkShiftType = {
  __typename?: 'BenchmarkShiftType';
  checked: Scalars['Boolean'];
  code: Scalars['String'];
  name: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum BenchmarkTypeEnum {
  INDUSTRY = 'INDUSTRY',
  INTERNAL = 'INTERNAL',
  PAST_SURVEY = 'PAST_SURVEY'
}

/** Delete a list of users, given their UUID. */
export type BulkDeleteUsers = {
  __typename?: 'BulkDeleteUsers';
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Send a password reset email to a group of users based on list of uuid input */
export type BulkResetPassword = {
  __typename?: 'BulkResetPassword';
  errors?: Maybe<Array<Scalars['String']>>;
  users?: Maybe<Array<Maybe<UserNode>>>;
};

export type BulkSetIsActive = {
  __typename?: 'BulkSetIsActive';
  errors?: Maybe<Array<Scalars['String']>>;
  users?: Maybe<Array<Maybe<UserNode>>>;
};

export type BulkSetSurveyQuestion = {
  __typename?: 'BulkSetSurveyQuestion';
  errors?: Maybe<Array<Scalars['String']>>;
  questions?: Maybe<Array<QuestionType>>;
};

/** Update a list of user's permissions. Currently limited to data access. */
export type BulkUpdateUserPermissions = {
  __typename?: 'BulkUpdateUserPermissions';
  errors?: Maybe<Array<Scalars['String']>>;
  users?: Maybe<Array<Maybe<UserNode>>>;
};

export type CertificationType = {
  __typename?: 'CertificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  organization: OrganizationType;
  survey: SurveyNode;
  /** Date when access to apply for certification ends. */
  accessEndDate: Scalars['DateTime'];
  /** Date when survey certified status begins. */
  certifiedStartDate?: Maybe<Scalars['DateTime']>;
  /** Date when survey certified status end. Defaults to a year from start date */
  certifiedEndDate?: Maybe<Scalars['DateTime']>;
  cultureBrief: CultureBriefType;
  passesScoreCriteria?: Maybe<Scalars['Boolean']>;
  passesResponseRateCriteria?: Maybe<Scalars['Boolean']>;
  bestWorkplacesRanking?: Maybe<Scalars['Int']>;
  locationCertifications: Array<LocationCertificationType>;
  status: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  organizationSlug?: Maybe<Scalars['String']>;
  badgeImageUrl?: Maybe<Scalars['String']>;
  badgeZipUrl?: Maybe<Scalars['String']>;
};

export type CodeLookup = {
  __typename?: 'CodeLookup';
  errors?: Maybe<Array<Scalars['String']>>;
  surveys?: Maybe<Array<PublicSurveyType>>;
};

export type ComparisonToolControls = {
  __typename?: 'ComparisonToolControls';
  statements: Array<FrozenQuestionType>;
  demographics: Array<InsightsDemographic>;
};

export type CompletionRateReportDataType = {
  __typename?: 'CompletionRateReportDataType';
  completionRate: CompletionRateReportType;
  rows: Array<CompletionRateRowType>;
};

export type CompletionRateReportType = {
  __typename?: 'CompletionRateReportType';
  started: Scalars['Int'];
  submitted: Scalars['Int'];
  totalFilter1: Scalars['Int'];
};

export type CompletionRateRowType = {
  __typename?: 'CompletionRateRowType';
  started: Scalars['Int'];
  submitted: Scalars['Int'];
  filterValue1: Scalars['String'];
  filterValue2: Scalars['String'];
};

export type CompletionRateType = {
  __typename?: 'CompletionRateType';
  started: Scalars['Int'];
  submitted: Scalars['Int'];
};

export type ContentObject = {
  __typename?: 'ContentObject';
  contentType: Scalars['String'];
  uuid: Scalars['String'];
};

export type ContentObjectInput = {
  contentType: Scalars['String'];
  uuid: Scalars['String'];
};

export type CultureBriefImageInput = {
  uuid?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  imageType: CultureBriefImageTypeEnum;
  caption: Scalars['String'];
};

export type CultureBriefImageType = {
  __typename?: 'CultureBriefImageType';
  uuid: Scalars['String'];
  imageType: CultureBriefImageTypeEnum;
  caption: Scalars['String'];
  url: Scalars['String'];
};

/** An enumeration. */
export enum CultureBriefImageTypeEnum {
  LOGO = 'LOGO',
  CEO = 'CEO',
  COMPANY = 'COMPANY'
}

export type CultureBriefType = {
  __typename?: 'CultureBriefType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['DateTime']>;
  numberOfLocations?: Maybe<Scalars['Int']>;
  isSubsidiary?: Maybe<Scalars['Boolean']>;
  organizationType?: Maybe<Scalars['String']>;
  industrySubVertical?: Maybe<Scalars['String']>;
  mainTypeOfCare?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  website: Scalars['String'];
  facebookUrl: Scalars['String'];
  twitterUrl: Scalars['String'];
  instagramUrl: Scalars['String'];
  linkedinUrl: Scalars['String'];
  indeedUrl: Scalars['String'];
  description: Scalars['String'];
  totalNumberOfEmployees?: Maybe<Scalars['Int']>;
  pastTotalNumberOfEmployees?: Maybe<Scalars['Int']>;
  totalApplicants?: Maybe<Scalars['Int']>;
  totalOpenRoles?: Maybe<Scalars['Int']>;
  recommendationsPercentage?: Maybe<Scalars['Int']>;
  averageNumberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfEmployeesLeft?: Maybe<Scalars['Int']>;
  carbonEmissionsReductionPercentage?: Maybe<Scalars['Int']>;
  numberOfEmployeesEligibleForHealthInsurance?: Maybe<Scalars['Int']>;
  numberOfEmployeesWithHealthInsurance?: Maybe<Scalars['Int']>;
  employeeBenefits?: Maybe<Scalars['String']>;
  additionalEmployeeBenefits: Scalars['String'];
  percentageOfEmployeesWithBigWages?: Maybe<Scalars['Int']>;
  percentageOfImmigrantEmployees?: Maybe<Scalars['Int']>;
  totalBoardComposition?: Maybe<Scalars['Int']>;
  totalBoardWomen?: Maybe<Scalars['Int']>;
  totalBoardMinoritiesRace?: Maybe<Scalars['Int']>;
  totalBoardMinoritiesLgbt?: Maybe<Scalars['Int']>;
  totalCeoReportsComposition?: Maybe<Scalars['Int']>;
  totalCeoReportsWomen?: Maybe<Scalars['Int']>;
  totalCeoReportsMinoritiesRace?: Maybe<Scalars['Int']>;
  totalCeoReportsMinoritiesLgbt?: Maybe<Scalars['Int']>;
  hasDiversityEquity?: Maybe<Scalars['Boolean']>;
  diversityEquityDescription: Scalars['String'];
  reasonsToWorkForCompany: Scalars['String'];
  companyMission: Scalars['String'];
  careersPageLink: Scalars['String'];
  ceoFirstName: Scalars['String'];
  ceoLastName: Scalars['String'];
  ceoTitle: Scalars['String'];
  ceoPhoneNumber: Scalars['String'];
  ceoEmail: Scalars['String'];
  mainContactFirstName: Scalars['String'];
  mainContactLastName: Scalars['String'];
  mainContactTitle: Scalars['String'];
  mainContactPhoneNumber: Scalars['String'];
  mainContactEmail: Scalars['String'];
  marketingContactFirstName: Scalars['String'];
  marketingContactLastName: Scalars['String'];
  marketingContactTitle: Scalars['String'];
  marketingContactPhoneNumber: Scalars['String'];
  marketingContactEmail: Scalars['String'];
  certification?: Maybe<CertificationType>;
  images: Array<CultureBriefImageType>;
};

export type CultureChangeOverviewType = {
  __typename?: 'CultureChangeOverviewType';
  checkedOut?: Maybe<Array<Maybe<CultureChangeType>>>;
  highTrust?: Maybe<Array<Maybe<CultureChangeType>>>;
  highRisk?: Maybe<Array<Maybe<CultureChangeType>>>;
  wantsMore?: Maybe<Array<Maybe<CultureChangeType>>>;
};

export type CultureChangeType = {
  __typename?: 'CultureChangeType';
  demographicCode?: Maybe<Scalars['String']>;
  demographic?: Maybe<Scalars['String']>;
  valueCode?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  change?: Maybe<Scalars['Float']>;
};

export type CultureHighlightType = {
  __typename?: 'CultureHighlightType';
  demographicCode?: Maybe<Scalars['String']>;
  demographic?: Maybe<Scalars['String']>;
  valueCode?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
};

export type CultureOverviewType = {
  __typename?: 'CultureOverviewType';
  checkedOut?: Maybe<CultureType>;
  checkedOutBenchmark?: Maybe<Scalars['Float']>;
  highTrust?: Maybe<CultureType>;
  highTrustBenchmark?: Maybe<Scalars['Float']>;
  highRisk?: Maybe<CultureType>;
  highRiskBenchmark?: Maybe<Scalars['Float']>;
  wantsMore?: Maybe<CultureType>;
  wantsMoreBenchmark?: Maybe<Scalars['Float']>;
};

export type CultureType = {
  __typename?: 'CultureType';
  label?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  top?: Maybe<Array<Maybe<CultureHighlightType>>>;
};

/** An enumeration. */
export enum DataTypeCode {
  AI_DEPT = 'ai_dept',
  AI_LOC = 'ai_loc',
  AI_LOCATION = 'ai_location',
  AI_SHIFT = 'ai_shift',
  APPLICATION_DATE = 'application_date',
  BUILDING = 'building',
  BUSINESS_SEGMENT = 'business_segment',
  CAMPUS = 'campus',
  CARE_TERM_ID = 'care_term_id',
  CLIENT_EXTERNAL_ID = 'client_external_id',
  CLIENT_NAME = 'client_name',
  DATE_OF_BIRTH = 'date_of_birth',
  DEPARTMENT_ID = 'department_id',
  DEPARTMENT_NAME = 'department_name',
  DISCHARGE_REASON = 'discharge_reason',
  DIVISION_ID = 'division_id',
  DIVISION_NAME = 'division_name',
  EMPLOYEE_LEFT_REASON = 'employee_left_reason',
  EMPLYOMENT_TERM_ID = 'emplyoment_term_id',
  FIRST_NAME = 'first_name',
  FLOOR = 'floor',
  FRANCHISE = 'franchise',
  GROUP = 'group',
  HEALTH = 'health',
  HIRE_DATE = 'hire_date',
  HOUSEHOLD_SIZE = 'household_size',
  IGNORE = 'ignore',
  IS_PARTICIPANT = 'is_participant',
  JOB_TITLE = 'job_title',
  LAST_NAME = 'last_name',
  LENGTH_OF_STAY = 'length_of_stay',
  LOCATION_ID = 'location_id',
  LONELINESS_GROUP = 'loneliness_group',
  MARITAL_STATUS = 'marital_status',
  MEDICAL_NEEDS = 'medical_needs',
  MOBILE_PHONE = 'mobile_phone',
  MOD_LENGTH_OF_STAY = 'mod_length_of_stay',
  MOVE_IN = 'move_in',
  MOVE_OUT = 'move_out',
  NEXT_PLACE = 'next_place',
  OWNER = 'owner',
  PARTICIPANT_TYPE = 'participant_type',
  PAYOR_TYPE = 'payor_type',
  PERSONAL_EMAIL = 'personal_email',
  R_BIRTH_YEAR = 'r_birth_year',
  R_DATE_OF_BIRTH = 'r_date_of_birth',
  R_FIRST_NAME = 'r_first_name',
  R_GENDER = 'r_gender',
  R_LAST_NAME = 'r_last_name',
  R_MOBILE_PHONE = 'r_mobile_phone',
  R_PERSONAL_EMAIL = 'r_personal_email',
  R_RELATIONSHIP = 'r_relationship',
  R_SAMPLE_RECORD_ID = 'r_sample_record_id',
  R_SURVEY_CODE = 'r_survey_code',
  REGION_ID = 'region_id',
  REGION_NAME = 'region_name',
  RESIDENT_GROUP = 'resident_group',
  RESIDENT_RESPONDENT_TYPE = 'resident_respondent_type',
  SAMPLE_RECORD_ID = 'sample_record_id',
  SENIOR_BIRTH_YEAR = 'senior_birth_year',
  SENIOR_DATE_OF_BIRTH = 'senior_date_of_birth',
  STATE = 'state',
  SUBTEAM = 'subteam',
  SUPERVISOR_ID = 'supervisor_id',
  SUPERVISOR_NAME = 'supervisor_name',
  SURVEY_CODE = 'survey_code',
  TEAM = 'team',
  TERMINATION_DATE = 'termination_date',
  TERMINATION_REASON = 'termination_reason',
  TERRITORY_CODE = 'territory_code',
  TI_BIRTH_YEAR = 'ti_birth_year',
  TI_DISABILITIES = 'ti_disabilities',
  TI_EXECUTIVE_TEAM = 'ti_executive_team',
  TI_GENDER = 'ti_gender',
  TI_LGBT = 'ti_lgbt',
  TI_MANAGERIAL_LEVEL = 'ti_managerial_level',
  TI_OPPORTUNITIES = 'ti_opportunities',
  TI_PAY_TYPE = 'ti_pay_type',
  TI_RACE = 'ti_race',
  TI_RESPONSIBILITY = 'ti_responsibility',
  TI_TENURE = 'ti_tenure',
  TI_WORK_POSTAL_CODE = 'ti_work_postal_code',
  TI_WORK_STATUS = 'ti_work_status',
  UNIT = 'unit',
  USER_ACTIVE = 'user_active',
  WORK_EMAIL = 'work_email'
}

export type DataTypeGroupsInputType = {
  dtCode: Scalars['String'];
  /** Limit to groups within the data type. e.g. specific locations within the filter type */
  groups?: Maybe<Array<Scalars['String']>>;
  isMultiselectCode?: Maybe<Scalars['Boolean']>;
};

export type DataTypeGroupsType = {
  __typename?: 'DataTypeGroupsType';
  dtCode: Scalars['String'];
  groups: Array<Scalars['String']>;
};

export type DataTypeInput = {
  code: BenchmarkCodeType;
  value: Scalars['String'];
};

export type DataTypeOptionType = {
  __typename?: 'DataTypeOptionType';
  code: BenchmarkCodeType;
  visibleName: Scalars['String'];
  standards?: Maybe<Array<StandardType>>;
  required: Scalars['Boolean'];
  /**
   * Data types that, when provided for a representative, must be the same as their client.
   * On the frontend, we clear those values and make sure to auto-populate on the backend during update.
   */
  mustSyncWithClient?: Maybe<Scalars['Boolean']>;
};

export type DataTypeValueType = {
  __typename?: 'DataTypeValueType';
  value: Scalars['String'];
  dtCode: BenchmarkCodeType;
};



/** Delete Statement of an Action Plan */
export type DeleteActionItem = {
  __typename?: 'DeleteActionItem';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

export type DeleteActionItemTask = {
  __typename?: 'DeleteActionItemTask';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

export type DeleteActionItemTaskComment = {
  __typename?: 'DeleteActionItemTaskComment';
  errors?: Maybe<Array<Scalars['String']>>;
  actionItemTask?: Maybe<ActionItemTaskNode>;
};

export type DeleteCultureBriefImages = {
  __typename?: 'DeleteCultureBriefImages';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DeleteGroupsQuestionScoreNotification = {
  __typename?: 'DeleteGroupsQuestionScoreNotification';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DeleteIntervalNotification = {
  __typename?: 'DeleteIntervalNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type DeleteParticipant = {
  __typename?: 'DeleteParticipant';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DeleteQuestion = {
  __typename?: 'DeleteQuestion';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DeleteScheduledNotification = {
  __typename?: 'DeleteScheduledNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type DeleteSurvey = {
  __typename?: 'DeleteSurvey';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DesignSectionType = {
  __typename?: 'DesignSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  languages: Array<LanguageType>;
};

export type DownloadNode = {
  __typename?: 'DownloadNode';
  title: Scalars['String'];
};

export type EligibleParticipantSurveyConfigInput = {
  participantTypeOptions: Scalars['String'];
  levelOfCare: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type EligibleParticipantSurveyConfigType = {
  __typename?: 'EligibleParticipantSurveyConfigType';
  uuid: Scalars['String'];
  participantTypeOptions: Scalars['String'];
  /** Whether config is enabled for this level of care */
  enabled: Scalars['Boolean'];
  levelOfCare: Scalars['String'];
};

export type EmailSupport = {
  __typename?: 'EmailSupport';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type EmployeeEligibilityRate = {
  __typename?: 'EmployeeEligibilityRate';
  certificationEligible: Scalars['Int'];
  listEligible: Scalars['Int'];
};

export type Errors = {
  __typename?: 'Errors';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

/** An enumeration. */
export enum FileImportImportType {
  /** Participants */
  PARTICIPANTS = 'PARTICIPANTS',
  /** Users */
  USERS = 'USERS',
  /** Clients */
  CLIENTS = 'CLIENTS',
  /** Bulk Delete Participants */
  DELETE_PARTICIPANTS = 'DELETE_PARTICIPANTS',
  /** Clients And Representatives */
  CLIENTS_AND_REPS = 'CLIENTS_AND_REPS'
}

export type FileImportType = {
  __typename?: 'FileImportType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  organization: OrganizationType;
  survey?: Maybe<InsightsSurveyNode>;
  /** Inactive imports have been disassociated from a survey, but not deleted */
  active: Scalars['Boolean'];
  copiedFrom?: Maybe<FileImportType>;
  /** Optionally provide a name for future reference */
  name: Scalars['String'];
  importType: FileImportImportType;
  uploadedFile: Scalars['String'];
  columnErrors: Scalars['JSONString'];
  ingestErrors: Scalars['JSONString'];
  summary: ImportSummaryType;
  lastErrorMessage?: Maybe<Scalars['String']>;
  createdUsers: UserNodeConnection;
  fileimportSet: Array<FileImportType>;
  createdParticipants: Array<ParticipantType>;
  fileName?: Maybe<Scalars['String']>;
  errors: Array<ImportErrorType>;
  numColumns: Scalars['Int'];
  numRows: Scalars['Int'];
};


export type FileImportTypeCreatedUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum FilterProductTypeEnum {
  EMPLOYEE = 'EMPLOYEE',
  RESIDENT = 'RESIDENT',
  ALL = 'ALL'
}

/** An enumeration. */
export enum FilterProductTypePermissionEnum {
  EMPLOYEE = 'EMPLOYEE',
  RESIDENT = 'RESIDENT',
  ALL = 'ALL',
  NONE = 'NONE'
}

export type FilterTypePermissionInput = {
  uuid: Scalars['String'];
  accessToAll: Scalars['Boolean'];
  values: Array<Maybe<FilterValuePermissionInput>>;
};

/** An enumeration. */
export enum FilterTypeReportingTypeEnum {
  SELF_REPORTED = 'SELF_REPORTED',
  ADMIN_MAPPING = 'ADMIN_MAPPING'
}

export type FilterTypeResponse = {
  __typename?: 'FilterTypeResponse';
  name: Scalars['String'];
  rate: Scalars['Float'];
};

export type FilterTypeType = {
  __typename?: 'FilterTypeType';
  filterTypeUuid: Scalars['String'];
  index: Scalars['Int'];
  name: Scalars['String'];
  namePlural: Scalars['String'];
  dtCode: DataTypeCode;
  accessToAll?: Maybe<Scalars['Boolean']>;
  isLocation?: Maybe<Scalars['Boolean']>;
  filterValues: Array<FilterValueType>;
  insightsProductType: FilterProductTypePermissionEnum;
  accessControlProductType: FilterProductTypePermissionEnum;
  surveyProductType: FilterProductTypeEnum;
};

export type FilterValueInput = {
  uuid?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<UsStatesEnum>;
  postalCode?: Maybe<Scalars['String']>;
  onlineReviewSites?: Maybe<Array<FilterValueReviewSiteInputType>>;
  website?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  hasEntranceFee?: Maybe<Scalars['Boolean']>;
  isRental?: Maybe<Scalars['Boolean']>;
  usNewsLocationId?: Maybe<Scalars['String']>;
  usNewsLocationName?: Maybe<Scalars['String']>;
  ncalIdNumber?: Maybe<Scalars['String']>;
  medicareProviderNumber?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

export type FilterValuePermissionInput = {
  uuid?: Maybe<Scalars['String']>;
};

export type FilterValueReviewSiteInputType = {
  externalId: Scalars['String'];
  reviewSiteName: OnlineReviewSiteEnum;
};

export type FilterValueReviewSiteType = {
  __typename?: 'FilterValueReviewSiteType';
  externalId: Scalars['String'];
  reviewSiteName: OnlineReviewSiteEnum;
};

export type FilterValuesSectionType = {
  __typename?: 'FilterValuesSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  mappedFilterValuesCount: Scalars['Int'];
  totalFilterValuesCount: Scalars['Int'];
};

export type FilterValueSummaryType = {
  __typename?: 'FilterValueSummaryType';
  filterTypeName?: Maybe<Scalars['String']>;
  filterValueNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterValueType = {
  __typename?: 'FilterValueType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  archived: Scalars['Boolean'];
  dataTypeValue?: Maybe<DataTypeValueType>;
  /** Standard value for data types based on standards (e.g. LevelOfCare) */
  standard?: Maybe<Scalars['String']>;
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  state?: Maybe<UsStatesEnum>;
  postalCode: Scalars['String'];
  country: Scalars['String'];
  phoneNumber: Scalars['String'];
  website: Scalars['String'];
  /** Entrance fee Life Plan Community/CCRC */
  hasEntranceFee: Scalars['Boolean'];
  /** Rental Life Plan Community/CCRC */
  isRental: Scalars['Boolean'];
  logo: Scalars['String'];
  /** Shown to applicants on the screener as an explanation of 'why work here' */
  description: Scalars['String'];
  /** Short name to use in messaging. In Hiring product, defaults to Organization Name. Elsewhere, defaults to location.name */
  nickname: Scalars['String'];
  onlineReviewSites?: Maybe<Array<FilterValueReviewSiteType>>;
  usNewsLocationId: Scalars['String'];
  usNewsLocationName: Scalars['String'];
  ncalIdNumber: Scalars['String'];
  medicareProviderNumber: Scalars['String'];
  /** Employee Departments connected to Resident Service Areas */
  serviceAreas: Array<ServiceAreaType>;
  /**
   * The 'Personal Info Questions' section in open link surveys can be personalised
   * such that the options for a filter type question can only be a subset of all
   * the available filter value choices.
   */
  surveySet: Array<InsightsSurveyNode>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  dtCode: BenchmarkCodeType;
  hasMissingRequiredValues: Scalars['Boolean'];
};

export type FrozenQuestionType = {
  __typename?: 'FrozenQuestionType';
  uuid: Scalars['String'];
  text: Scalars['String'];
  /** A version of question's text that replaces template code, such as {{dining_service}}, with readable labels. */
  textDisplay?: Maybe<Scalars['String']>;
  kind?: Maybe<QKind>;
  focus?: Maybe<QFocus>;
  code: Scalars['String'];
  residentResultsGroup?: Maybe<QResidentResultsGroup>;
  survey: SurveyNode;
  isOpenEnded: Scalars['Boolean'];
};

export type GenerateSignedUploadUrl = {
  __typename?: 'GenerateSignedUploadUrl';
  signedUrl?: Maybe<SignedUrl>;
};

export type GroupCountsType = {
  __typename?: 'GroupCountsType';
  label: Scalars['String'];
  count: Scalars['Int'];
};

export type GroupedScoresType = {
  __typename?: 'GroupedScoresType';
  dataType: DataTypeCode;
  scores: Array<OverallScoreType>;
  label?: Maybe<Scalars['String']>;
};

export type GroupedStatementScoresType = {
  __typename?: 'GroupedStatementScoresType';
  /** Data type the group is a part of. */
  dtCode: Scalars['String'];
  /** Name of individual groups within data type to limit calculations to. */
  group: Scalars['String'];
  statements: Array<StatementScoreType>;
};

export type GroupInput = {
  accessToSurveyProduct?: Maybe<Scalars['Boolean']>;
  canCreateSurveys?: Maybe<Scalars['Boolean']>;
  canAccessSurveySettings?: Maybe<Scalars['Boolean']>;
  canManageCertifications?: Maybe<Scalars['Boolean']>;
  canViewCertificationResults?: Maybe<Scalars['Boolean']>;
  residentAccessToSurveyProduct?: Maybe<Scalars['Boolean']>;
  residentCanCreateSurveys?: Maybe<Scalars['Boolean']>;
  residentCanAccessSurveySettings?: Maybe<Scalars['Boolean']>;
  canAccessLonelinessResults?: Maybe<Scalars['Boolean']>;
  canUseAnalytics?: Maybe<Scalars['Boolean']>;
  canInviteUsers?: Maybe<Scalars['Boolean']>;
  canResetUsersPasswords?: Maybe<Scalars['Boolean']>;
  canAccessDataStructure?: Maybe<Scalars['Boolean']>;
  canAccessGeneralSettings?: Maybe<Scalars['Boolean']>;
  canUseActionPlans?: Maybe<Scalars['Boolean']>;
  canManageActionPlans?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  users?: Maybe<Array<Maybe<Scalars['String']>>>;
  uuid?: Maybe<Scalars['String']>;
  allSurveys?: Maybe<Scalars['Boolean']>;
  surveys?: Maybe<Array<Maybe<Scalars['String']>>>;
  insightsModules?: Maybe<Array<InsightsModulesEnum>>;
  residentInsightsModules?: Maybe<Array<InsightsModulesEnum>>;
};

export type GroupNode = {
  __typename?: 'GroupNode';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  organization: OrganizationType;
  createdBy?: Maybe<UserNode>;
  /** Is the organization's admin group? One per org, should contain all valid permissions, and not be allowed deleted. */
  isAdmin: Scalars['Boolean'];
  groupssurveyresponsenotificationSet: Array<GroupsSurveyResponseNotificationType>;
  groupsaveragescoresurveyresponsenotificationSet: Array<GroupsSurveyAverageScoreNotificationType>;
  groupsquestionscoresurveyresponsenotificationSet: Array<GroupsSurveyQuestionScoreNotificationType>;
  accessToSurveyProduct: Scalars['Boolean'];
  canCreateSurveys: Scalars['Boolean'];
  canAccessSurveySettings: Scalars['Boolean'];
  canManageCertifications: Scalars['Boolean'];
  canViewCertificationResults: Scalars['Boolean'];
  residentAccessToSurveyProduct: Scalars['Boolean'];
  residentCanCreateSurveys: Scalars['Boolean'];
  residentCanAccessSurveySettings: Scalars['Boolean'];
  canAccessLonelinessResults: Scalars['Boolean'];
  canUseAnalytics: Scalars['Boolean'];
  canInviteUsers: Scalars['Boolean'];
  canResetUsersPasswords: Scalars['Boolean'];
  canAccessDataStructure: Scalars['Boolean'];
  canAccessGeneralSettings: Scalars['Boolean'];
  canUseActionPlans: Scalars['Boolean'];
  canManageActionPlans: Scalars['Boolean'];
  users: Array<UserNode>;
  insightsModules: Array<InsightsModulesEnum>;
  residentInsightsModules: Array<InsightsModulesEnum>;
  surveys: Array<SurveyNode>;
  allSurveys: Scalars['Boolean'];
};

export type GroupsSurveyAverageScoreNotificationType = {
  __typename?: 'GroupsSurveyAverageScoreNotificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  groups: Array<GroupNode>;
  survey: InsightsSurveyNode;
  scoreType: SurveyScoreNotificationTypeEnum;
  frequency: SurveyScoreNotificationFrequencyEnum;
};

export type GroupsSurveyQuestionScoreNotificationType = {
  __typename?: 'GroupsSurveyQuestionScoreNotificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  groups: Array<GroupNode>;
  survey: InsightsSurveyNode;
  notificationName: Scalars['String'];
  scoreType: SurveyScoreNotificationTypeEnum;
  frequency: SurveyScoreNotificationFrequencyEnum;
  minScore: Scalars['Int'];
  maxScore: Scalars['Int'];
  statement: QuestionType;
  openEndedQuestion?: Maybe<QuestionType>;
};

export type GroupsSurveyResponseNotificationType = {
  __typename?: 'GroupsSurveyResponseNotificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  groups: Array<GroupNode>;
  survey: InsightsSurveyNode;
  notificationType: SurveyResponseNotificationTypeEnum;
};

export type GroupSurveyAverageScoreNotificationInput = {
  scoreType: SurveyScoreNotificationTypeEnum;
  enabled: Scalars['Boolean'];
  frequency?: Maybe<SurveyScoreNotificationFrequencyEnum>;
  groupUuids: Array<Scalars['String']>;
};

export type GroupSurveyQuestionScoreNotificationInput = {
  notificationUuid?: Maybe<Scalars['String']>;
  notificationName: Scalars['String'];
  scoreType: SurveyScoreNotificationTypeEnum;
  enabled: Scalars['Boolean'];
  frequency: SurveyScoreNotificationFrequencyEnum;
  groupUuids: Array<Scalars['String']>;
  minScore: Scalars['Int'];
  maxScore: Scalars['Int'];
  statementUuid: Scalars['String'];
  openEndedQuestionUuid?: Maybe<Scalars['String']>;
};

export type GroupSurveyResponseNotificationInput = {
  notificationType: SurveyResponseNotificationTypeEnum;
  enabled: Scalars['Boolean'];
  groupUuids: Array<Maybe<Scalars['String']>>;
};

export type HeatmapBenchmarkScores = {
  __typename?: 'HeatmapBenchmarkScores';
  name: Scalars['String'];
  scores: Array<Maybe<Scalars['Float']>>;
};

export type HeatmapCellType = {
  __typename?: 'HeatmapCellType';
  score: Scalars['Float'];
};

export type HeatmapControls = {
  __typename?: 'HeatmapControls';
  demographics: Array<InsightsDemographic>;
};

export type HeatmapType = {
  __typename?: 'HeatmapType';
  statements?: Maybe<Array<Maybe<FrozenQuestionType>>>;
  categories: Array<Maybe<Scalars['String']>>;
  cells: Array<Maybe<Array<Maybe<HeatmapCellType>>>>;
};

/** An enumeration. */
export enum ImpactScoreStrategyEnum {
  POSITIVE_SCORE = 'POSITIVE_SCORE',
  NPS_SCORE = 'NPS_SCORE',
  NUM_RESPONSES = 'NUM_RESPONSES'
}

/** An enumeration. */
export enum ImportContactTypesEnum {
  EMPLOYEES = 'EMPLOYEES',
  COMBINED_CONTACTS = 'COMBINED_CONTACTS'
}

/** An enumeration. */
export enum ImportErrorsEnum {
  BLANK_COLUMN = 'BLANK_COLUMN',
  DUPLICATE_COLUMN = 'DUPLICATE_COLUMN',
  DUPLICATE_VALUE = 'DUPLICATE_VALUE',
  EXISTING_DUPLICATE_VALUE = 'EXISTING_DUPLICATE_VALUE',
  FAILED_COLUMN_MAPPING = 'FAILED_COLUMN_MAPPING',
  INVALID_COLUMN = 'INVALID_COLUMN',
  INVALID_EXCEL_FORMAT = 'INVALID_EXCEL_FORMAT',
  INVALID_LEVEL_OF_CARE = 'INVALID_LEVEL_OF_CARE',
  INVALID_STANDARDS_MAPPING = 'INVALID_STANDARDS_MAPPING',
  INVALID_VALUE = 'INVALID_VALUE',
  MISSING_REQUIRED_COLUMN = 'MISSING_REQUIRED_COLUMN',
  MISSING_REQUIRED_VALUE = 'MISSING_REQUIRED_VALUE'
}

export type ImportErrorType = {
  __typename?: 'ImportErrorType';
  code?: Maybe<ImportErrorsEnum>;
  dtCode?: Maybe<Scalars['String']>;
  dtName?: Maybe<Scalars['String']>;
  cell?: Maybe<Scalars['String']>;
};

export type ImportSummaryType = {
  __typename?: 'ImportSummaryType';
  processed?: Maybe<Scalars['Boolean']>;
  added?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Int']>;
  filters?: Maybe<Array<Maybe<FilterValueSummaryType>>>;
};

/** An enumeration. */
export enum ImportTypesEnum {
  PARTICIPANTS = 'PARTICIPANTS',
  USERS = 'USERS',
  CLIENTS = 'CLIENTS',
  BULK_DELETE_PARTICIPANTS = 'BULK_DELETE_PARTICIPANTS',
  CLIENTS_AND_REPRESENTATIVES = 'CLIENTS_AND_REPRESENTATIVES'
}

export type ImprovedGroupType = {
  __typename?: 'ImprovedGroupType';
  score: Scalars['Int'];
  name: Scalars['String'];
};

export type ImprovementScoreType = {
  __typename?: 'ImprovementScoreType';
  improvementScore: Scalars['Float'];
  improvedGroups?: Maybe<Array<ImprovedGroupType>>;
};

export type IndividualResponseType = {
  __typename?: 'IndividualResponseType';
  answer?: Maybe<Scalars['String']>;
  /** Provided for multiselect questions. */
  answers?: Maybe<Array<Scalars['String']>>;
  questionCode: Scalars['String'];
};

export type IndividualResultType = {
  __typename?: 'IndividualResultType';
  participantUuid: Scalars['String'];
  locationName: Scalars['String'];
  levelOfCare?: Maybe<Scalars['String']>;
  startedTimestamp: Scalars['DateTime'];
  submittedTimestamp?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  responses: Array<IndividualResponseType>;
  /**
   * Total number of results from the query. Kept at the individual
   *         result level to allow for simpler pagination.
   */
  totalResults: Scalars['Int'];
  questions: Array<ParticipantQuestion>;
  source?: Maybe<ParticipantSourceEnum>;
  /** The datetime at which the participant was called */
  callTime?: Maybe<Scalars['DateTime']>;
  /** The duration fo the call in seconds */
  callLength?: Maybe<Scalars['Int']>;
  lastSentNotificationTimestamp?: Maybe<Scalars['DateTime']>;
  participantType?: Maybe<ParticipantTypeEnum>;
  dischargeDate?: Maybe<Scalars['DateTime']>;
  /** For representatives, the name of the client the survey is taken on behalf of. */
  clientName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
};

export type IngestParticipantsUpload = {
  __typename?: 'IngestParticipantsUpload';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type InsightsComment = {
  __typename?: 'InsightsComment';
  text: Scalars['String'];
  score?: Maybe<Scalars['Int']>;
  author?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['DateTime']>;
  filterValues?: Maybe<Array<Scalars['String']>>;
};

export type InsightsComments = {
  __typename?: 'InsightsComments';
  comments: Array<InsightsComment>;
  question?: Maybe<FrozenQuestionType>;
  totalComments: Scalars['Int'];
};

export type InsightsCustomSurveyAnswer = {
  __typename?: 'InsightsCustomSurveyAnswer';
  value: Scalars['String'];
  count: Scalars['Int'];
  text: Scalars['String'];
  score: Scalars['Float'];
};

export type InsightsCustomSurveyQuestion = {
  __typename?: 'InsightsCustomSurveyQuestion';
  question: FrozenQuestionType;
  answers: Array<InsightsCustomSurveyAnswer>;
  selectedFiltersAnswers: Array<InsightsCustomSurveyAnswer>;
};

export type InsightsDemographic = {
  __typename?: 'InsightsDemographic';
  code: Scalars['String'];
  text: Scalars['String'];
  pluralText: Scalars['String'];
};

/** An enumeration. */
export enum InsightsModulesEnum {
  SNAPSHOTS = 'SNAPSHOTS',
  STATEMENTS = 'STATEMENTS',
  COMMENTS = 'COMMENTS',
  COMPARISONS = 'COMPARISONS',
  HEATMAP = 'HEATMAP',
  ACTION_PLANS = 'ACTION_PLANS',
  TESTIMONIALS = 'TESTIMONIALS',
  INDIVIDUAL_RESULTS = 'INDIVIDUAL_RESULTS'
}

export type InsightsSurveyNode = {
  __typename?: 'InsightsSurveyNode';
  uuid: Scalars['String'];
  responseRate: ResponseRateType;
  completionRate: CompletionRateType;
  overallIndexScore?: Maybe<OverallScoreType>;
};


export type InsightsSurveyNodeResponseRateArgs = {
  byClient?: Maybe<Scalars['Boolean']>;
  surveyResponseRateTypes?: Maybe<Array<SurveysResponseRateTypeInput>>;
};

export type IntervalNotificationInput = {
  uuid?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['Boolean']>;
  personalEmail?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  smsOptional?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTextOptional1?: Maybe<Scalars['String']>;
  emailTextOptional2?: Maybe<Scalars['String']>;
  days?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum IntervalNotificationNotificationType {
  /** Starting */
  SURVEY_STARTING = 'SURVEY_STARTING',
  /** Reminder */
  REMINDER = 'REMINDER',
  /** Ending */
  SURVEY_ENDING = 'SURVEY_ENDING',
  /** Other */
  OTHER = 'OTHER'
}

export type IntervalNotificationType = {
  __typename?: 'IntervalNotificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  /** The part of the SMS that can be customized. */
  smsOptional: Scalars['String'];
  /** The required part of the SMS. */
  smsRequired: Scalars['String'];
  /** This is NOT customizable. */
  emailSubject: Scalars['String'];
  /** The first part of the email that can be customized. */
  emailTextOptional1: Scalars['String'];
  /** The required part of the email. */
  emailTextRequired: Scalars['String'];
  /** The second part of the email that can be customized. */
  emailTextOptional2: Scalars['String'];
  notificationType: IntervalNotificationNotificationType;
  sms: Scalars['Boolean'];
  personalEmail: Scalars['Boolean'];
  workEmail: Scalars['Boolean'];
  /** Is this notification set as active/usable? */
  active: Scalars['Boolean'];
  /** How many days after participant was added, should the notification go out? */
  days: Scalars['Int'];
  survey: InsightsSurveyNode;
  errors?: Maybe<Scalars['String']>;
  firstSentTimestamp?: Maybe<Scalars['DateTime']>;
};


export type LanguageType = {
  __typename?: 'LanguageType';
  /** For example "es" for Spanish */
  code: Scalars['String'];
  /** For example "Spanish" */
  name: Scalars['String'];
  /** For example "Español" */
  nameTranslated: Scalars['String'];
};

export type LastSurveyData = {
  __typename?: 'LastSurveyData';
  survey: SurveyNode;
  score: OverallScoreType;
};

export type LocationCertificationType = {
  __typename?: 'LocationCertificationType';
  name: Scalars['String'];
  isCertified: Scalars['Boolean'];
  locationUuid: Scalars['String'];
  uuid: Scalars['String'];
  badgeImageUrl?: Maybe<Scalars['String']>;
};

/** Authentication mutation, deletes the session. */
export type LogoutUser = {
  __typename?: 'LogoutUser';
  success?: Maybe<Scalars['Boolean']>;
};

export type LonelinessScoresType = {
  __typename?: 'LonelinessScoresType';
  numLonely: Scalars['Int'];
  numSomewhatLonely: Scalars['Int'];
  numNotLonely: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Log the user out. */
  logoutUser?: Maybe<LogoutUser>;
  /** Send a password reset link to a group of users, given by their UUID. */
  bulkResetPassword?: Maybe<BulkResetPassword>;
  /** Delete a list of users, given their UUID. */
  bulkDeleteUsers?: Maybe<BulkDeleteUsers>;
  /** Activate/Deactivate user. */
  toggleIsActive?: Maybe<ToggleIsActive>;
  /** Bulk set active status for users */
  bulkSetIsActive?: Maybe<BulkSetIsActive>;
  /** If a uuid is given, it updates the user. Else, it sends an invitation email to the given email. */
  updateUser?: Maybe<UpdateUser>;
  /** Update a list of user permissions - limited to filter type permissions */
  bulkUpdateUserPermissions?: Maybe<BulkUpdateUserPermissions>;
  /** Add or remove an action plan that the user follows */
  toggleFollowActionPlan?: Maybe<ToggleFollowActionPlan>;
  /** Add or remove a survey from the user's favorites list. */
  toggleFavoriteSurvey?: Maybe<ToggleFavoriteSurvey>;
  /** Send AI support an email requesting info for a user. */
  emailSupport?: Maybe<EmailSupport>;
  /** Create user app message or mark existing message as seen for the current user. */
  updateUserAppMessage?: Maybe<UpdateUserAppMessage>;
  updateTranslations?: Maybe<UpdateTranslations>;
  /** Mutation class to create or update an existing Survey instance */
  updateSurvey?: Maybe<UpdateSurvey>;
  /** Mutation class to update the Survey status. Basically called when launching a survey or unscheduling it */
  updateSurveyStatus?: Maybe<UpdateSurveyStatus>;
  /** Mutation class to create or update SurveyText instances (welcome message, welcome header etc) */
  updateWelcomeMessages?: Maybe<UpdateWelcomeMessages>;
  generateUploadUrl?: Maybe<GenerateSignedUploadUrl>;
  deleteSurvey?: Maybe<DeleteSurvey>;
  updateCustomQuestion?: Maybe<UpdateCustomQuestion>;
  updatePersonalInfoQuestion?: Maybe<UpdatePersonalInfoQuestion>;
  deleteQuestion?: Maybe<DeleteQuestion>;
  bulkSetSurveyQuestion?: Maybe<BulkSetSurveyQuestion>;
  updateEligibleParticipantSurveyConfigs?: Maybe<UpdateEligibleParticipantSurveyConfigs>;
  codeLookup?: Maybe<CodeLookup>;
  saveResponses?: Maybe<SaveResponses>;
  updateResidentParticipant?: Maybe<UpdateResidentParticipant>;
  /** Save responses to the Personal Info questions. */
  savePersonalInfoResponses?: Maybe<SavePersonalInfoResponses>;
  updateParticipant?: Maybe<UpdateParticipant>;
  deleteParticipant?: Maybe<DeleteParticipant>;
  addParticipantsUpload?: Maybe<AddParticipantsUpload>;
  removeParticipantsUpload?: Maybe<RemoveParticipantsUpload>;
  ingestParticipantsUpload?: Maybe<IngestParticipantsUpload>;
  /** Updates Organization basic info */
  updateOrganization?: Maybe<UpdateOrganization>;
  /** Updates Organization info related to surveys */
  updateOrganizationSurveyPortal?: Maybe<UpdateOrganizationSurveyPortal>;
  /** Updates services areas on an organization */
  updateOrganizationServiceAreas?: Maybe<UpdateOrganizationServiceAreas>;
  /** Creates a new filter value or updates an existing one */
  updateFilterValue?: Maybe<UpdateFilterValue>;
  /** Toggles archived state of a filter value */
  updateFilterValueArchived?: Maybe<UpdateFilterValueArchived>;
  /** Uploads a file of contacts for an organization */
  addContactsUpload?: Maybe<AddContactsUpload>;
  /** Update or create a notification */
  updateScheduledNotification?: Maybe<UpdateScheduledNotification>;
  /** Delete a notification */
  deleteScheduledNotification?: Maybe<DeleteScheduledNotification>;
  /** Send a sample test email */
  sendScheduledTestEmail?: Maybe<SendScheduledTestEmail>;
  /** Send a sample test SMS */
  sendScheduledTestSms?: Maybe<SendScheduledTestSms>;
  /** Reset the notification to the default version */
  resetScheduledNotification?: Maybe<ResetScheduledNotification>;
  /** Update or create a notification */
  updateIntervalNotification?: Maybe<UpdateIntervalNotification>;
  /** Delete a notification */
  deleteIntervalNotification?: Maybe<DeleteIntervalNotification>;
  /** Send a sample test email */
  sendIntervalTestEmail?: Maybe<SendIntervalTestEmail>;
  /** Send a sample test SMS */
  sendIntervalTestSms?: Maybe<SendIntervalTestSms>;
  /** Reset the notification to the default version */
  resetIntervalNotification?: Maybe<ResetIntervalNotification>;
  /** Update or create survey response notifications for groups */
  updateGroupsSurveyResponseNotifications?: Maybe<UpdateGroupsSurveyResponseNotifications>;
  /** Update or create survey average score notifications for groups */
  updateGroupsSurveyAverageScoreNotifications?: Maybe<UpdateGroupsAverageScoreNotifications>;
  /** Update or create survey question score notifications for groups */
  updateGroupsSurveyQuestionScoreNotifications?: Maybe<UpdateGroupsQuestionScoreNotifications>;
  /** Delete a question score notification */
  deleteGroupsSurveyQuestionScoreNotification?: Maybe<DeleteGroupsQuestionScoreNotification>;
  /** Toggle user has survey response notifications */
  updateUserHasSurveyResponseNotifications?: Maybe<UpdateUserHasSurveyResponseNotifications>;
  /** Update or create survey response notifications for a user */
  updateUserSurveyResponseNotifications?: Maybe<UpdateUserSurveyResponseNotifications>;
  /** Update or create survey average score notifications for a user */
  updateUserAverageScoreNotifications?: Maybe<UpdateUserAverageScoreNotifications>;
  /** Update or create survey question score notifications for a user */
  updateUserQuestionScoreNotifications?: Maybe<UpdateUserQuestionScoreNotifications>;
  updateReport?: Maybe<UpdateReport>;
  shareScreenshot?: Maybe<ShareScreenshot>;
  updateTakeActionSteps?: Maybe<UpdateTakeActionStepsMutation>;
  /** Create or update a group. */
  updateGroup?: Maybe<UpdateGroup>;
  /** Update which groups can access the given survey */
  updateGroupsAccessToSurvey?: Maybe<UpdateGroupsAccessToSurvey>;
  updateCultureBrief?: Maybe<UpdateCultureBriefPayload>;
  addCultureBriefImages?: Maybe<AddCultureBriefImages>;
  deleteCultureBriefImages?: Maybe<DeleteCultureBriefImages>;
  updateCultureBriefImages?: Maybe<UpdateCultureBriefImages>;
  shareMarketingToolkit?: Maybe<ShareMarketingToolkit>;
  /** Set the employee/resident survey on the Action Plan */
  updateActionPlanSurvey?: Maybe<UpdateActionPlanSurvey>;
  /** Delete Statement of an Action Plan */
  deleteActionItem?: Maybe<DeleteActionItem>;
  /** Add a new Action Plan Item (represented by a statement actually) */
  addActionPlanStatements?: Maybe<AddActionPlanStatements>;
  addActionPlanCustomStatement?: Maybe<AddActionPlanCustomStatement>;
  updateActionItemTask?: Maybe<UpdateActionItemTask>;
  deleteActionItemTask?: Maybe<DeleteActionItemTask>;
  updateActionItemTaskComment?: Maybe<UpdateActionItemTaskComment>;
  deleteActionItemTaskComment?: Maybe<DeleteActionItemTaskComment>;
};


export type MutationBulkResetPasswordArgs = {
  uuidList?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationBulkDeleteUsersArgs = {
  userUuids: Array<Scalars['String']>;
};


export type MutationToggleIsActiveArgs = {
  uuid: Scalars['String'];
};


export type MutationBulkSetIsActiveArgs = {
  isActive: Scalars['Boolean'];
  uuidList: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateUserArgs = {
  email?: Maybe<Scalars['String']>;
  filterTypes: Array<FilterTypePermissionInput>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationBulkUpdateUserPermissionsArgs = {
  filterTypes: Array<FilterTypePermissionInput>;
  uuidList: Array<Maybe<Scalars['String']>>;
};


export type MutationToggleFollowActionPlanArgs = {
  planUuid?: Maybe<Scalars['String']>;
};


export type MutationToggleFavoriteSurveyArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserAppMessageArgs = {
  contentObjects?: Maybe<Array<Maybe<ContentObjectInput>>>;
  kind: Scalars['String'];
};


export type MutationUpdateTranslationsArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<TranslationInput>>>;
};


export type MutationUpdateSurveyArgs = {
  survey?: Maybe<SurveyInput>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateSurveyStatusArgs = {
  status?: Maybe<SurveyStatusEnum>;
  surveyUuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateWelcomeMessagesArgs = {
  surveyUuid: Scalars['String'];
  welcomeMessages: Array<SurveyTextInput>;
};


export type MutationGenerateUploadUrlArgs = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};


export type MutationDeleteSurveyArgs = {
  uuid: Scalars['String'];
};


export type MutationUpdateCustomQuestionArgs = {
  questionInput: QuestionInput;
  surveyUuid: Scalars['String'];
};


export type MutationUpdatePersonalInfoQuestionArgs = {
  questionInput: PersonalInfoQuestionInput;
  surveyUuid: Scalars['String'];
};


export type MutationDeleteQuestionArgs = {
  questionUuid: Scalars['String'];
  surveyUuid: Scalars['String'];
};


export type MutationBulkSetSurveyQuestionArgs = {
  selectedQuestionUuids: Array<Scalars['String']>;
  surveyUuid: Scalars['String'];
  unselectedQuestionUuids: Array<Scalars['String']>;
};


export type MutationUpdateEligibleParticipantSurveyConfigsArgs = {
  configs: Array<EligibleParticipantSurveyConfigInput>;
  surveyUuid: Scalars['String'];
};


export type MutationCodeLookupArgs = {
  code: Scalars['String'];
  organizationUuid: Scalars['String'];
  surveyProductType: SurveyProductTypeEnum;
};


export type MutationSaveResponsesArgs = {
  fingerprint?: Maybe<Scalars['String']>;
  participantId: Scalars['String'];
  responses?: Maybe<Array<Maybe<QuestionResponse>>>;
  selectedLanguage?: Maybe<Scalars['String']>;
  source?: Maybe<ParticipantSourceTypeInput>;
  submitted?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateResidentParticipantArgs = {
  fingerprint?: Maybe<Scalars['String']>;
  participantId: Scalars['String'];
  questionCode?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  source?: Maybe<ParticipantSourceTypeInput>;
  submitted?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};


export type MutationSavePersonalInfoResponsesArgs = {
  clientFirstName?: Maybe<Scalars['String']>;
  clientLastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  responses: Array<QuestionResponse>;
  selectedLanguage?: Maybe<Scalars['String']>;
  surveyHashId: Scalars['String'];
};


export type MutationUpdateParticipantArgs = {
  participant: ParticipantInput;
  participantUuid?: Maybe<Scalars['String']>;
  surveyUuid: Scalars['String'];
};


export type MutationDeleteParticipantArgs = {
  participantUuid: Scalars['String'];
};


export type MutationAddParticipantsUploadArgs = {
  importType: ImportTypesEnum;
  objectPath: Scalars['String'];
  surveyUuid: Scalars['String'];
};


export type MutationRemoveParticipantsUploadArgs = {
  importType: ImportTypesEnum;
  surveyUuid: Scalars['String'];
};


export type MutationIngestParticipantsUploadArgs = {
  importType: ImportTypesEnum;
  surveyUuid: Scalars['String'];
};


export type MutationUpdateOrganizationArgs = {
  organization?: Maybe<OrganizationInput>;
};


export type MutationUpdateOrganizationSurveyPortalArgs = {
  organization?: Maybe<OrganizationSurveyPortalInput>;
};


export type MutationUpdateOrganizationServiceAreasArgs = {
  serviceAreas: Array<ServiceAreaInputType>;
};


export type MutationUpdateFilterValueArgs = {
  filterTypeUuid: Scalars['String'];
  filterValue: FilterValueInput;
};


export type MutationUpdateFilterValueArchivedArgs = {
  uuid: Scalars['String'];
};


export type MutationAddContactsUploadArgs = {
  importType: ImportContactTypesEnum;
  objectPath: Scalars['String'];
};


export type MutationUpdateScheduledNotificationArgs = {
  notificationInput: ScheduledNotificationInput;
  surveyUuid: Scalars['String'];
};


export type MutationDeleteScheduledNotificationArgs = {
  notificationUuid: Scalars['String'];
};


export type MutationSendScheduledTestEmailArgs = {
  emailAddress: Scalars['String'];
  notificationUuid: Scalars['String'];
};


export type MutationSendScheduledTestSmsArgs = {
  notificationUuid: Scalars['String'];
  smsNumber: Scalars['String'];
};


export type MutationResetScheduledNotificationArgs = {
  emailOrSms: Scalars['String'];
  notificationUuid: Scalars['String'];
};


export type MutationUpdateIntervalNotificationArgs = {
  notificationInput: IntervalNotificationInput;
  surveyUuid: Scalars['String'];
};


export type MutationDeleteIntervalNotificationArgs = {
  notificationUuid: Scalars['String'];
};


export type MutationSendIntervalTestEmailArgs = {
  emailAddress: Scalars['String'];
  notificationUuid: Scalars['String'];
};


export type MutationSendIntervalTestSmsArgs = {
  notificationUuid: Scalars['String'];
  smsNumber: Scalars['String'];
};


export type MutationResetIntervalNotificationArgs = {
  emailOrSms: Scalars['String'];
  notificationUuid: Scalars['String'];
};


export type MutationUpdateGroupsSurveyResponseNotificationsArgs = {
  groupsNotificationsInput: Array<GroupSurveyResponseNotificationInput>;
  surveyUuid: Scalars['String'];
};


export type MutationUpdateGroupsSurveyAverageScoreNotificationsArgs = {
  groupsNotificationsInput: Array<GroupSurveyAverageScoreNotificationInput>;
  surveyUuid: Scalars['String'];
};


export type MutationUpdateGroupsSurveyQuestionScoreNotificationsArgs = {
  groupsNotificationsInput: Array<GroupSurveyQuestionScoreNotificationInput>;
  surveyUuid: Scalars['String'];
};


export type MutationDeleteGroupsSurveyQuestionScoreNotificationArgs = {
  notificationUuid: Scalars['String'];
};


export type MutationUpdateUserHasSurveyResponseNotificationsArgs = {
  hasSurveyResponseNotifications: Scalars['Boolean'];
};


export type MutationUpdateUserSurveyResponseNotificationsArgs = {
  userNotificationsInput: Array<UserSurveyResponseNotificationInput>;
};


export type MutationUpdateUserAverageScoreNotificationsArgs = {
  userNotificationsInput: Array<UserAverageScoreNotificationsInput>;
};


export type MutationUpdateUserQuestionScoreNotificationsArgs = {
  userNotificationsInput: Array<UserQuestionScoreNotificationsInput>;
};


export type MutationUpdateReportArgs = {
  password?: Maybe<Scalars['String']>;
  surveyUuid: Scalars['String'];
};


export type MutationShareScreenshotArgs = {
  currentUrl: Scalars['String'];
  email: Scalars['String'];
  image: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};


export type MutationUpdateTakeActionStepsArgs = {
  step: TakeActionStepsEnum;
  surveyUuid: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  groupInput?: Maybe<GroupInput>;
};


export type MutationUpdateGroupsAccessToSurveyArgs = {
  groupsUuidsWithSurveyAccess: Array<Scalars['String']>;
  surveyUuid: Scalars['String'];
};


export type MutationUpdateCultureBriefArgs = {
  input: UpdateCultureBriefInput;
};


export type MutationAddCultureBriefImagesArgs = {
  cultureBriefUuid: Scalars['String'];
  imagesData: Array<Maybe<CultureBriefImageInput>>;
};


export type MutationDeleteCultureBriefImagesArgs = {
  cultureBriefUuid: Scalars['String'];
  uuids: Array<Scalars['String']>;
};


export type MutationUpdateCultureBriefImagesArgs = {
  cultureBriefUuid: Scalars['String'];
  imagesData: Array<Maybe<CultureBriefImageInput>>;
};


export type MutationShareMarketingToolkitArgs = {
  email: Scalars['String'];
  includeBadgeAssets: Scalars['Boolean'];
  locationUuid?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};


export type MutationUpdateActionPlanSurveyArgs = {
  actionPlanInput?: Maybe<ActionPlanSurveyInput>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationDeleteActionItemArgs = {
  actionItemUuid: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationAddActionPlanStatementsArgs = {
  includeDefaultActionItems: Scalars['Boolean'];
  statementUuids: Array<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationAddActionPlanCustomStatementArgs = {
  customStatement: Scalars['String'];
  customStatementProductType?: Maybe<SurveyProductTypeEnum>;
  tasks: Array<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateActionItemTaskArgs = {
  actionItemUuid: Scalars['String'];
  task: ActionItemTaskInput;
  taskUuid?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationDeleteActionItemTaskArgs = {
  taskId: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateActionItemTaskCommentArgs = {
  actionItemTaskUuid: Scalars['String'];
  commentInput?: Maybe<ActionItemTaskCommentInput>;
  userTimezone?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationDeleteActionItemTaskCommentArgs = {
  actionItemTaskCommentUuid: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
};

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type NotificationsSectionType = {
  __typename?: 'NotificationsSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  total: Scalars['Int'];
  notifications: Array<NotificationType>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  uuid: Scalars['String'];
  workEmail: Scalars['Boolean'];
  personalEmail: Scalars['Boolean'];
  sms: Scalars['Boolean'];
  when?: Maybe<Scalars['DateTime']>;
  days?: Maybe<Scalars['Int']>;
  sentTimestamp?: Maybe<Scalars['DateTime']>;
};

export type NpsGroupScoresType = {
  __typename?: 'NpsGroupScoresType';
  promoters: Scalars['Float'];
  passives: Scalars['Float'];
  detractors: Scalars['Float'];
  label?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
};

/** An enumeration. */
export enum NpsGroupsEnum {
  PROMOTERS = 'PROMOTERS',
  PASSIVES = 'PASSIVES',
  DETRACTORS = 'DETRACTORS'
}

/** Options for online review sites customers can link to from a survey. */
export enum OnlineReviewSiteEnum {
  GOOGLE = 'GOOGLE'
}

export type OrganizationInput = {
  name: Scalars['String'];
  seniorLiving: Scalars['Boolean'];
  atHome: Scalars['Boolean'];
  isCcrc: Scalars['Boolean'];
  multipleLocations: Scalars['Boolean'];
  benchmarkLevelsOfCare: Array<BenchmarkConfigInput>;
  benchmarkDepartments: Array<BenchmarkConfigInput>;
  benchmarkShifts: Array<BenchmarkConfigInput>;
};

export type OrganizationSurveyPortalInput = {
  slug: Scalars['String'];
  surveyCodeName?: Maybe<Scalars['String']>;
  surveyCodeHelpText?: Maybe<Scalars['String']>;
  residentSurveyCodeName?: Maybe<Scalars['String']>;
  residentSurveyCodeHelpText?: Maybe<Scalars['String']>;
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  uuid: Scalars['String'];
  /**
   * Customers can use different names for their survey_code such as 'Employee Id'.
   * Eg: Please submit ________ to take your survey
   */
  surveyCodeName: Scalars['String'];
  /** Any additional instructions to display on the portal page */
  surveyCodeHelpText: Scalars['String'];
  /** Please submit ________ to take your survey */
  residentSurveyCodeName: Scalars['String'];
  /** Any additional instructions to display on the portal page */
  residentSurveyCodeHelpText: Scalars['String'];
  name: Scalars['String'];
  /** Be careful about changing this since it will change the Survey Portal URL. Do not change it while a survey is active. */
  slug: Scalars['String'];
  /** List of languages that organization admins can enable for their surveys.<br/> */
  languages: Array<LanguageType>;
  /** Controls available employee features for this organization */
  solution?: Maybe<SolutionType>;
  /** Controls available resident features for this organization */
  residentSolution?: Maybe<ResidentSolutionType>;
  /** Participates in US News? */
  participatesInUsNews: Scalars['Boolean'];
  /** Check this if this organization is not a real customer. */
  isTestAccount: Scalars['Boolean'];
  /** Does this organization offer senior living facilities? */
  seniorLiving: Scalars['Boolean'];
  /** Does this organization offer care at home? */
  atHome: Scalars['Boolean'];
  /** Is this organization considered to be a CCRC? */
  isCcrc: Scalars['Boolean'];
  /** Does this organization have more than one location? */
  multipleLocations: Scalars['Boolean'];
  /** Give org access to the score improvement carousel charts for key focus areas. */
  hasKeyFocusAreaCarousel: Scalars['Boolean'];
  /** This field used being used as "security_phrase" when passing leads to Vicidial. */
  vicidialDid: Scalars['String'];
  /** Long-Term Care Tracker Report: Collect Medicare and NCAL #s */
  collectCmsAndNcalNumbers: Scalars['Boolean'];
  /** The integration ID of the organization that is stored within the integrations platform. */
  integrationId?: Maybe<Scalars['String']>;
  certifications: Array<CertificationType>;
  isGptwEnabled: Scalars['Boolean'];
  benchmarkLevelsOfCare: Array<BenchmarkLevelOfCareType>;
  benchmarkDepartments: Array<BenchmarkDepartmentType>;
  benchmarkShifts: Array<BenchmarkShiftType>;
  lastTrustIndexDate?: Maybe<Scalars['Date']>;
  serviceAreas: Array<ServiceAreaType>;
  enabledSolutionFields: Array<SolutionFieldAccessType>;
  activeFilterTypes: Array<FilterTypeType>;
  combinedContactsTemplateUrl?: Maybe<Scalars['String']>;
  employeeContactsTemplateUrl?: Maybe<Scalars['String']>;
};


export type OrganizationTypeActiveFilterTypesArgs = {
  surveyProductType?: Maybe<FilterProductTypeEnum>;
};

export type OverallScoreByFiltersInputType = {
  /** For frontend to associate which filters were applied to which top level groups. */
  label: Scalars['String'];
  dataTypeGroups: Array<DataTypeGroupsInputType>;
};

export type OverallScoreType = {
  __typename?: 'OverallScoreType';
  positive: Scalars['Float'];
  benchmarkPositive?: Maybe<Scalars['Float']>;
  inconsistent: Scalars['Float'];
  benchmarkInconsistent?: Maybe<Scalars['Float']>;
  negative: Scalars['Float'];
  benchmarkNegative?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  groupHierarchy?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Number of participants who responded for the score */
  totalResponses?: Maybe<Scalars['Int']>;
  /** Number of finished participants in the group */
  count?: Maybe<Scalars['Float']>;
  /** Number of invited participants in the group */
  invitedCount?: Maybe<Scalars['Float']>;
  children: Array<OverallScoreType>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type ParticipantInfo = {
  __typename?: 'ParticipantInfo';
  participantId: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  residentRespondentType?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
};

export type ParticipantInput = {
  surveyCode: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  dataTypes: Array<DataTypeInput>;
};

export type ParticipantQuestion = {
  __typename?: 'ParticipantQuestion';
  benchmarkCode?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  text: Scalars['String'];
  category: Scalars['String'];
  isOpenEnded: Scalars['Boolean'];
  isStatement: Scalars['Boolean'];
  choices: Array<ParticipantQuestionChoice>;
};

export type ParticipantQuestionChoice = {
  __typename?: 'ParticipantQuestionChoice';
  benchmarkCode?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  text: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ParticipantSourceEnum {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  CODE = 'CODE',
  PORTAL = 'PORTAL'
}

/** An enumeration. */
export enum ParticipantSourceTypeInput {
  C = 'c',
  E = 'e',
  L = 'l',
  P = 'p',
  S = 's'
}

export type ParticipantsPageType = {
  __typename?: 'ParticipantsPageType';
  participants: Array<ParticipantType>;
  total: Scalars['Int'];
};

export type ParticipantsSectionType = {
  __typename?: 'ParticipantsSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  total: Scalars['Int'];
  clientsWithoutParticipantCount: Scalars['Int'];
  workEmails: Scalars['Int'];
  personalEmails: Scalars['Int'];
  mobilePhones: Scalars['Int'];
};

export type ParticipantType = {
  __typename?: 'ParticipantType';
  uuid: Scalars['String'];
  survey: InsightsSurveyNode;
  surveyCode: Scalars['String'];
  personalEmail: Scalars['String'];
  workEmail: Scalars['String'];
  firstName: Scalars['String'];
  mobilePhone?: Maybe<Scalars['String']>;
  dataTypeValues: Array<DataTypeValueType>;
};

/** An enumeration. */
export enum ParticipantTypeEnum {
  CLIENT = 'CLIENT',
  REPRESENTATIVE = 'REPRESENTATIVE'
}

export type PersonalInfoQuestionInput = {
  questionUuid: Scalars['String'];
  text: Scalars['String'];
  choiceUuids: Array<Maybe<Scalars['String']>>;
};

export type PersonalInfoQuestionType = {
  __typename?: 'PersonalInfoQuestionType';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type PublicChoice = {
  __typename?: 'PublicChoice';
  code: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  benchmarkCode?: Maybe<Scalars['String']>;
  question: PublicQuestion;
  dependsOn?: Maybe<Array<Maybe<PublicChoice>>>;
  children?: Maybe<Array<Maybe<PublicChoice>>>;
  childrenQuestions?: Maybe<Array<PublicQuestion>>;
};

export type PublicConstants = {
  __typename?: 'PublicConstants';
  languages: Array<LanguageType>;
  standardResidentFilterValueChoices: Array<StandardFilterValueChoicesByDataTypeType>;
  fortuneListSubmissionDate: Scalars['Date'];
  trustIndexCertificationMinimumScore: Scalars['Int'];
  cultureBriefMaxImages: Scalars['Int'];
  cultureBriefDisabledFieldsWhenCompleted: Array<Scalars['String']>;
  cultureBriefRequiredFields: Array<Scalars['String']>;
  certificationMarketingToolkitUrl: Scalars['String'];
  questionKindInstructions: Array<AgentInstructionType>;
  questionInstructions: Array<AgentInstructionType>;
};

export type PublicOrganization = {
  __typename?: 'PublicOrganization';
  uuid: Scalars['String'];
  /**
   * Customers can use different names for their survey_code such as 'Employee Id'.
   * Eg: Please submit ________ to take your survey
   */
  surveyCodeName: Scalars['String'];
  /** Any additional instructions to display on the portal page */
  surveyCodeHelpText: Scalars['String'];
  /** Please submit ________ to take your survey */
  residentSurveyCodeName: Scalars['String'];
  /** Any additional instructions to display on the portal page */
  residentSurveyCodeHelpText: Scalars['String'];
  hasLiveSurvey?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<LanguageType>>>;
  locationFilterTypeUuid?: Maybe<Scalars['String']>;
};

export type PublicParticipant = {
  __typename?: 'PublicParticipant';
  isExpired: Scalars['Boolean'];
  selectedLanguage: Scalars['String'];
  surveyStarted?: Maybe<Scalars['DateTime']>;
  surveySubmitted?: Maybe<Scalars['DateTime']>;
  levelOfCare: Scalars['String'];
  templates?: Maybe<Scalars['JSONString']>;
  location: FilterValueType;
  /**
   * When the survey code refers to a participant with duplicated contact info,
   * return a list of possible participants for the user to choose from.
   */
  duplicatedContactInfo: Array<ParticipantInfo>;
  questions: Array<PublicQuestion>;
  responses: Array<PublicResponse>;
};


export type PublicParticipantQuestionsArgs = {
  personalInfoQuestionsOnly?: Maybe<Scalars['Boolean']>;
};

export type PublicQuestion = {
  __typename?: 'PublicQuestion';
  code: Scalars['String'];
  benchmarkCode?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  kind: QKind;
  category: QCategory;
  visible?: Maybe<Scalars['Boolean']>;
  choices?: Maybe<Array<PublicChoice>>;
  dependsOn?: Maybe<PublicQuestion>;
  children?: Maybe<Array<Maybe<PublicQuestion>>>;
  residentFocus?: Maybe<QResidentFocus>;
  dependsOnChoices?: Maybe<Array<PublicChoice>>;
};

export type PublicResponse = {
  __typename?: 'PublicResponse';
  code: Scalars['String'];
  response?: Maybe<Scalars['String']>;
};

export type PublicSurvey = {
  __typename?: 'PublicSurvey';
  status: SurveyStatusEnum;
  name: Scalars['String'];
  /** Survey start date and time in UTC */
  startDate: Scalars['DateTime'];
  /** Survey end date and time in UTC */
  endDate?: Maybe<Scalars['DateTime']>;
  /**
   * Keep the survey results confidential? If False,
   * it will show results with less than 5 responses and
   * enable the Individual Results tab in Insights.
   */
  hasConfidentialResults: Scalars['Boolean'];
  languages: Array<LanguageType>;
  type: SurveyTypeEnum;
  productType: SurveyProductTypeEnum;
  isOpenLinkSurvey: Scalars['Boolean'];
  welcomeMessage: WelcomeMessage;
  translations: Array<PublicTranslation>;
  isCustom: Scalars['Boolean'];
  organization: PublicOrganization;
  participant: PublicParticipant;
};

export type PublicSurveyType = {
  __typename?: 'PublicSurveyType';
  surveyName: Scalars['String'];
  participantId: Scalars['String'];
  surveySubmitted: Scalars['Boolean'];
  participantIsExpired: Scalars['Boolean'];
};

export type PublicTranslation = {
  __typename?: 'PublicTranslation';
  text: Scalars['String'];
  englishText: Scalars['String'];
  language: LanguageType;
};

export enum QCategory {
  STATEMENTS = 'STATEMENTS',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  SENIOR_CARE_DEMOGRAPHICS = 'SENIOR_CARE_DEMOGRAPHICS',
  OPEN_ENDED = 'OPEN_ENDED',
  OTHER = 'OTHER',
  PERSONAL_INFO = 'PERSONAL_INFO'
}

export enum QFocus {
  CUSTOM = 'CUSTOM',
  CREDIBILITY = 'CREDIBILITY',
  RESPECT = 'RESPECT',
  FAIRNESS = 'FAIRNESS',
  PRIDE = 'PRIDE',
  CAMARADERIE = 'CAMARADERIE'
}

export enum QKind {
  STATEMENT = 'STATEMENT',
  SHORT_ANSWER = 'SHORT_ANSWER',
  OPEN_ENDED = 'OPEN_ENDED',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  POSTAL_CODE = 'POSTAL_CODE',
  MULTISELECT = 'MULTISELECT',
  LINEAR = 'LINEAR',
  STATEMENT_EXCELLENCE = 'STATEMENT_EXCELLENCE'
}

/** `MANAGEMENT` questions are now registered under `SERVICE_AREAS`. */
export enum QResidentFocus {
  CUSTOM = 'CUSTOM',
  OVERALL_SATISFACTION = 'OVERALL_SATISFACTION',
  MOVE_IN = 'MOVE_IN',
  MANAGEMENT = 'MANAGEMENT',
  SERVICE_AREAS = 'SERVICE_AREAS',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  OPEN_ENDED = 'OPEN_ENDED',
  ONLINE_REVIEW = 'ONLINE_REVIEW',
  TESTIMONIALS = 'TESTIMONIALS'
}

export enum QResidentResultsGroup {
  DINING = 'DINING',
  PERSONAL_CARE = 'PERSONAL_CARE',
  LICENSED_NURSING = 'LICENSED_NURSING',
  OVERALL_SATISFACTION = 'OVERALL_SATISFACTION',
  OTHER_SERVICES = 'OTHER_SERVICES',
  MANAGEMENT = 'MANAGEMENT',
  CUSTOM = 'CUSTOM'
}

export type Query = {
  __typename?: 'Query';
  currentUser: UserNode;
  /** List of groups the current user is allowed to invite other users to */
  validGroups?: Maybe<Array<GroupNode>>;
  downloads?: Maybe<Array<Maybe<DownloadNode>>>;
  downloadLink?: Maybe<Scalars['String']>;
  /** List the users belonging to the logged in user's org */
  users: UserNodeConnection;
  checkEmail?: Maybe<Scalars['Boolean']>;
  translations: Array<TranslationType>;
  currentCertification?: Maybe<CertificationType>;
  certification: CertificationType;
  certifiedLocationsExport?: Maybe<Scalars['Boolean']>;
  /**
   * Endpoint for returning the surveys list. Some endpoints require the surveys to
   * return the response rates calculations by client, instead of by participant.
   * The 'survey_response_rate_types' input parameter dictates which type each
   * survey should return.
   */
  surveys: SurveyNodeConnection;
  survey: SurveyNode;
  surveyQuestions: Array<QuestionType>;
  questionsByCategory?: Maybe<Array<Maybe<QuestionsByCategory>>>;
  question?: Maybe<QuestionType>;
  /** Fetch a list of questions that can be attached to a survey. */
  availableSurveyQuestions: Array<QuestionType>;
  surveySummary: SurveySummaryType;
  publicConstants: PublicConstants;
  organizationBySlug?: Maybe<PublicOrganization>;
  /** Retrieve survey by uuid or hashid */
  surveyById?: Maybe<PublicSurvey>;
  surveyByParticipantId?: Maybe<PublicSurvey>;
  /** Idenifiers associated with an organization used to group frontend analytics events */
  organizationAnalyticsIdentifiers?: Maybe<AnalyticsIdentifiersType>;
  /** Idenifiers associated with a participant used to group frontend analytics events */
  participantAnalyticsIdentifiers?: Maybe<AnalyticsIdentifiersType>;
  participantsBySurvey: ParticipantsPageType;
  organization?: Maybe<OrganizationType>;
  chartData?: Maybe<Scalars['JSONString']>;
  filterValues: Array<FilterValueType>;
  insightsTestimonialsMediaUrl: Scalars['String'];
  /** A list of scores for each statement on a survey. */
  insightsStatements: Array<StatementScoreType>;
  /** A list of scores for each statement on a survey for each group within a list of data types. */
  insightsStatementsByDataTypes: Array<GroupedStatementScoresType>;
  /** Returns a list of overall index score filtered for each provided set of data type filters. */
  overallIndexScoreByFilters: Array<OverallScoreType>;
  insightsLonelinessSummary: LonelinessScoresType;
  insightsDownload: Scalars['Boolean'];
  actionPlansDownload?: Maybe<Scalars['Boolean']>;
  /**
   * Data that's fetched before a response rate report so that public and logged in
   * reports can fetch the report with the same default data
   */
  responseRateReportMetadata?: Maybe<ResponseRateReportMetadata>;
  responseRateReport?: Maybe<ResponseRateReportDataType>;
  completionRateReport?: Maybe<CompletionRateReportDataType>;
  responsesByDate: Array<ResponsesByDateType>;
  responseRateSurvey: ResponseRateSurveyType;
  responseRateByFilterType?: Maybe<Array<Maybe<FilterTypeResponse>>>;
  insightsOverallIndex: OverallScoreType;
  insightsEmployeeEligibilityResponseRate: EmployeeEligibilityRate;
  insightsDimensionsOverview: Array<OverallScoreType>;
  insightsCulture?: Maybe<CultureOverviewType>;
  insightsCultureChange?: Maybe<CultureChangeOverviewType>;
  /** Positive statement score for the location closest to the given percentile. */
  insightsTargetScore?: Maybe<Scalars['Float']>;
  /** The potential overall score if some of the lowest scores were improved to average. */
  insightsPotentialImprovementScore: ImprovementScoreType;
  /**
   * Endpoint to determine demographics with the highest impact, as measured by
   *             size of demographic groups and their difference in score.
   */
  insightsKeyDemographics: Array<GroupedScoresType>;
  insightsHeatmap?: Maybe<HeatmapType>;
  insightsHeatmapControls: HeatmapControls;
  insightsHeatmapBenchmarkScores: HeatmapBenchmarkScores;
  insightsNpsGroupScores?: Maybe<NpsGroupScoresType>;
  /** Returns a list of NPS group scores, grouped by a data type. */
  insightsNpsGroupScoresByDataType?: Maybe<Array<NpsGroupScoresType>>;
  /** Return a list of counts summed from multiselect answers. */
  insightsMultiselectGroupCounts: Array<GroupCountsType>;
  /** Return a list of voluntary/involuntary counts per provided data type. */
  insightsVoluntaryDischargeCountsBy: Array<VoluntaryDischargeGroupCountsType>;
  /** Return summary of positive (3-5) responses to the Core Q questions and an overall CoreQ score. */
  insightsCoreQResults: Array<StatementScoreType>;
  insightsCustomSurvey: Array<InsightsCustomSurveyQuestion>;
  insightsComparisonControls: ComparisonToolControls;
  insightsOpenEndedQuestions: Array<Maybe<FrozenQuestionType>>;
  insightsComments: InsightsComments;
  insightsIndividualResults: Array<IndividualResultType>;
  insightsBenchmarks: Array<BenchmarkNode>;
  /**
   * Returns a list of the groups available for a particular data type in the provided surveys.
   * When `for_dt_code` is not supplied, assumes that we are looking at all the survey filter types.
   * When multiple surveys are provided, it returns the intersection of values used in both surveys.
   */
  insightsSurveysFilterValues: Array<DataTypeGroupsType>;
  /** Returns a list of the group names available for a particular data type */
  insightsGroupsForDtCode: Array<Scalars['String']>;
  /** Return a list of scores grouped by a data type. */
  insightsScoresByDataType: Array<OverallScoreType>;
  /** Return a list of scores grouped by a multi-level data type hierarchy (e.g. ['ai_location', 'ai_dept']). */
  insightsScoresByDataTypeHierarchy: Scalars['String'];
  /** A list of `insights_scores_by_data_type` for each data type */
  insightsScoresByDataTypes: Array<GroupedScoresType>;
  /** Group of users in the organization associated with a set of permissions */
  groups: Array<GroupNode>;
  /** List the actions plans for the Action Plans Management page */
  actionPlans?: Maybe<ActionPlanNodeConnection>;
  /** Get a summary count of all action plans and tasks */
  actionPlansCounts: ActionPlanCountsType;
  /** Get a summary of all action plans activity */
  actionPlansActivity: Array<ActionPlanActivityType>;
  actionPlan: ActionPlanNode;
};


export type QueryDownloadLinkArgs = {
  uuid: Scalars['String'];
};


export type QueryUsersArgs = {
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy?: Maybe<Scalars['String']>;
  sortDescending?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCheckEmailArgs = {
  email: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
};


export type QueryTranslationsArgs = {
  surveyUuid: Scalars['String'];
};


export type QueryCertificationArgs = {
  certificationUuid: Scalars['String'];
};


export type QueryCertifiedLocationsExportArgs = {
  certificationUuid: Scalars['String'];
};


export type QuerySurveysArgs = {
  surveyProductType?: Maybe<SurveyProductTypeEnum>;
  surveyType?: Maybe<SurveyTypeEnum>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySurveyArgs = {
  surveyUuid: Scalars['String'];
};


export type QuerySurveyQuestionsArgs = {
  surveyUuid: Scalars['String'];
};


export type QueryQuestionsByCategoryArgs = {
  surveyUuid: Scalars['String'];
};


export type QueryQuestionArgs = {
  surveyUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type QueryAvailableSurveyQuestionsArgs = {
  surveyUuid: Scalars['String'];
};


export type QuerySurveySummaryArgs = {
  responseRateByClient: Scalars['Boolean'];
  surveyUuid: Scalars['String'];
};


export type QueryOrganizationBySlugArgs = {
  slug: Scalars['String'];
  surveyProductType: SurveyProductTypeEnum;
};


export type QuerySurveyByIdArgs = {
  surveyId: Scalars['String'];
};


export type QuerySurveyByParticipantIdArgs = {
  participantId: Scalars['String'];
  source?: Maybe<ParticipantSourceTypeInput>;
};


export type QueryOrganizationAnalyticsIdentifiersArgs = {
  slug: Scalars['String'];
};


export type QueryParticipantAnalyticsIdentifiersArgs = {
  participantId: Scalars['String'];
};


export type QueryParticipantsBySurveyArgs = {
  surveyUuid: Scalars['String'];
  pageSize: Scalars['Int'];
  page: Scalars['Int'];
  sortBy?: Maybe<Scalars['String']>;
  sortDescending?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryChartDataArgs = {
  chartType: Scalars['String'];
  surveyId: Scalars['Int'];
};


export type QueryFilterValuesArgs = {
  dtCode: Scalars['String'];
};


export type QueryInsightsStatementsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  limit?: Maybe<Scalars['Int']>;
  includeCustom?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsStatementsByDataTypesArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dataTypes: Array<DataTypeGroupsInputType>;
  rankBy?: Maybe<RankByEnum>;
  limit?: Maybe<Scalars['Int']>;
  includeCustom?: Maybe<Scalars['Boolean']>;
};


export type QueryOverallIndexScoreByFiltersArgs = {
  surveyUuid: Scalars['String'];
  dataTypeFilters: Array<Maybe<OverallScoreByFiltersInputType>>;
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsLonelinessSummaryArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsDownloadArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  downloadType: UserDownloadsEnum;
  certificationReportLocationUuid?: Maybe<Scalars['String']>;
  filterType1Code?: Maybe<Scalars['String']>;
  filterType2Code?: Maybe<Scalars['String']>;
  byClient?: Maybe<Scalars['Boolean']>;
};


export type QueryActionPlansDownloadArgs = {
  targetUserUuid?: Maybe<Scalars['String']>;
};


export type QueryResponseRateReportMetadataArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type QueryResponseRateReportArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  groupByFilterType1Code: Scalars['String'];
  groupByFilterType2Code: Scalars['String'];
  byClient?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryCompletionRateReportArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  groupByFilterType1Code: Scalars['String'];
  groupByFilterType2Code: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryResponsesByDateArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  byClient?: Maybe<Scalars['Boolean']>;
};


export type QueryResponseRateSurveyArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type QueryResponseRateByFilterTypeArgs = {
  surveyUuid: Scalars['String'];
  filterTypeUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  byClient?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsOverallIndexArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  includeCustom?: Maybe<Scalars['Boolean']>;
  limitToCoreQEligible?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsEmployeeEligibilityResponseRateArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsDimensionsOverviewArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsCultureArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  demographics?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryInsightsCultureChangeArgs = {
  surveyUuid1: Scalars['String'];
  surveyUuid2: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsTargetScoreArgs = {
  surveyUuid: Scalars['String'];
  statementCode: Scalars['String'];
  targetPercentile: Scalars['Int'];
};


export type QueryInsightsPotentialImprovementScoreArgs = {
  surveyUuid: Scalars['String'];
  dtCode?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsKeyDemographicsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  impactScoreStrategy: ImpactScoreStrategyEnum;
};


export type QueryInsightsHeatmapArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  y: Scalars['String'];
};


export type QueryInsightsHeatmapControlsArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsHeatmapBenchmarkScoresArgs = {
  surveyUuid: Scalars['String'];
  benchmark: BenchmarkNodeInput;
};


export type QueryInsightsNpsGroupScoresArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
};


export type QueryInsightsNpsGroupScoresByDataTypeArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dtCode: Scalars['String'];
};


export type QueryInsightsMultiselectGroupCountsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  code: Scalars['String'];
};


export type QueryInsightsVoluntaryDischargeCountsByArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dtCode: Scalars['String'];
};


export type QueryInsightsCoreQResultsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  limitToCoreQEligible: Scalars['Boolean'];
};


export type QueryInsightsCustomSurveyArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
};


export type QueryInsightsComparisonControlsArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsOpenEndedQuestionsArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsCommentsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  questionCode: Scalars['String'];
  pageSize: Scalars['Int'];
  page: Scalars['Int'];
  searchWords?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy?: Maybe<Scalars['String']>;
  filterBy?: Maybe<NpsGroupsEnum>;
};


export type QueryInsightsIndividualResultsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryInsightsBenchmarksArgs = {
  surveyUuid: Scalars['String'];
};


export type QueryInsightsSurveysFilterValuesArgs = {
  surveyUuids: Array<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  forDtCode?: Maybe<DataTypeCode>;
  isFilterType?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsGroupsForDtCodeArgs = {
  surveyUuid: Scalars['String'];
  dtCode: DataTypeCode;
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsScoresByDataTypeArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dtCode: Scalars['String'];
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  includeAllSurveyChoices?: Maybe<Scalars['Boolean']>;
  limitToCoreQEligible?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsScoresByDataTypeHierarchyArgs = {
  surveyUuid: Scalars['String'];
  dtCodeHierarchy: Array<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  includeAllSurveyChoices?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsScoresByDataTypesArgs = {
  surveyUuid: Scalars['String'];
  dtCodes: Array<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
};


export type QueryGroupsArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryActionPlansArgs = {
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  sortBy: ActionPlansSortByEnum;
  sortAscending: Scalars['Boolean'];
  selectedFollowFilters: Array<Maybe<Scalars['String']>>;
  searchQuery?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryActionPlansCountsArgs = {
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  selectedFollowFilters: Array<Maybe<Scalars['String']>>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryActionPlansActivityArgs = {
  pastDays: Array<Scalars['Int']>;
  selectedFollowFilters: Array<Maybe<Scalars['String']>>;
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryActionPlanArgs = {
  userUuid?: Maybe<Scalars['String']>;
};

export type QuestionChoiceInput = {
  uuid?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type QuestionChoiceType = {
  __typename?: 'QuestionChoiceType';
  uuid: Scalars['String'];
  text: Scalars['String'];
  benchmarkCode: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type QuestionFiltersType = {
  __typename?: 'QuestionFiltersType';
  levelsOfCare?: Maybe<Array<Maybe<Scalars['String']>>>;
  participantTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QuestionInput = {
  questionUuid?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  category?: Maybe<QCategory>;
  kind?: Maybe<QKind>;
  index?: Maybe<Scalars['Int']>;
  choices: Array<QuestionChoiceInput>;
};

export type QuestionResponse = {
  code: Scalars['String'];
  response?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type QuestionsByCategory = {
  __typename?: 'QuestionsByCategory';
  category?: Maybe<QCategory>;
  canAddCustom?: Maybe<Scalars['Boolean']>;
  questionsByFocus?: Maybe<Array<Maybe<QuestionsByFocusType>>>;
};

export type QuestionsByFocusType = {
  __typename?: 'QuestionsByFocusType';
  focus?: Maybe<QFocus>;
  canAddCustom?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<Maybe<QuestionType>>>;
};

export type QuestionsSectionType = {
  __typename?: 'QuestionsSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  trustIndex?: Maybe<Scalars['Int']>;
  demographic?: Maybe<Scalars['Int']>;
  openEnded?: Maybe<Scalars['Int']>;
  npsQuestions?: Maybe<Scalars['Int']>;
  custom?: Maybe<Scalars['Int']>;
  shortAnswer?: Maybe<Scalars['Int']>;
  longAnswer?: Maybe<Scalars['Int']>;
  multipleChoice?: Maybe<Scalars['Int']>;
  multiselect?: Maybe<Scalars['Int']>;
  linearScale?: Maybe<Scalars['Int']>;
  statements?: Maybe<Scalars['Int']>;
  locationsCount?: Maybe<Scalars['Int']>;
  locationsWithOnlineReviewSitesCount?: Maybe<Scalars['Int']>;
  hasOnlineReviewQuestion?: Maybe<Scalars['Boolean']>;
  personalInfoQuestions?: Maybe<Array<PersonalInfoQuestionType>>;
};

export type QuestionType = {
  __typename?: 'QuestionType';
  uuid: Scalars['String'];
  benchmarkCode?: Maybe<BenchmarkCodeType>;
  kind: QKind;
  focus?: Maybe<QFocus>;
  residentFocus?: Maybe<QResidentFocus>;
  category?: Maybe<QCategory>;
  dependsOn?: Maybe<QuestionType>;
  filters?: Maybe<QuestionFiltersType>;
  isCustom: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  required?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  choices: Array<QuestionChoiceType>;
  availableChoices: Array<QuestionChoiceType>;
  infoLabel?: Maybe<Scalars['String']>;
  isOpenEnded: Scalars['Boolean'];
  isStatement: Scalars['Boolean'];
  text: Scalars['String'];
};

/** An enumeration. */
export enum RankByEnum {
  POSITIVE = 'POSITIVE',
  INCONSISTENT = 'INCONSISTENT',
  NEGATIVE = 'NEGATIVE',
  OVERALL_POSITIVE_CORRELATION = 'OVERALL_POSITIVE_CORRELATION'
}

export type ReasonForLeaving = {
  __typename?: 'ReasonForLeaving';
  value: Scalars['String'];
  isVoluntary?: Maybe<Scalars['Boolean']>;
};

export type RemoveParticipantsUpload = {
  __typename?: 'RemoveParticipantsUpload';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type ResetIntervalNotification = {
  __typename?: 'ResetIntervalNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  notification: IntervalNotificationType;
};

export type ResetScheduledNotification = {
  __typename?: 'ResetScheduledNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  notification: ScheduledNotificationType;
};

/** An enumeration. */
export enum ResidentSolutionBenchmarkPastSurveys {
  /** None */
  NONE = 'NONE',
  /** Previous Survey(s) */
  PREVIOUS = 'PREVIOUS',
  /** All */
  ALL = 'ALL'
}

/** An enumeration. */
export enum ResidentSolutionDataAccessLevels {
  /** No ability to set access permissions */
  NONE = 'NONE',
  /** Can use all filter types for setting permissions */
  ALL = 'ALL',
  /** Can use one filter type that we specify */
  ONE = 'ONE',
  /** Users can pick one out of all available filters */
  CHOOSE_ONE = 'CHOOSE_ONE'
}

/** An enumeration. */
export enum ResidentSolutionFilters {
  /** No ability to use filter types to filter data */
  NONE = 'NONE',
  /** Can use all filter types */
  ALL = 'ALL',
  /** Can use one filter type that we specify */
  ONE = 'ONE'
}

export type ResidentSolutionType = Node & {
  __typename?: 'ResidentSolutionType';
  name: Scalars['String'];
  /** If this is checked, admins can only invite other admins. */
  adminInvitesOnly: Scalars['Boolean'];
  /** How many levels of data access can admins assign? */
  dataAccessLevels: ResidentSolutionDataAccessLevels;
  /** How many filters are available in Insights */
  filters: ResidentSolutionFilters;
  /** Does this solution allow access to open link surveys? */
  openLinkSurveys: Scalars['Boolean'];
  /** Display tabs not included in solution with sales promo tooltip */
  showUnavailableTabs: Scalars['Boolean'];
  /** Whether the organization has access to insights or not */
  insights: Scalars['Boolean'];
  /** Give access to extended snapshots? */
  insightsSnapshots: Scalars['Boolean'];
  /** Give access to key demographics within snapshots? */
  insightsKeyDemographics: Scalars['Boolean'];
  /** Access to Statements Tab? */
  insightsStatements: Scalars['Boolean'];
  /** Access to Comments Tab? */
  insightsComments: Scalars['Boolean'];
  /** Show additional features on comments page such as word cloud and sorting. */
  insightsEnhancedComments: Scalars['Boolean'];
  /** Remove the Second Filter column from the comments download */
  insightsHideSecondFilter: Scalars['Boolean'];
  /** Access to Comparisons Tab? */
  insightsComparisons: Scalars['Boolean'];
  /** Access to the grid view on the Comparisons Tab? */
  insightsComparisonsGrid: Scalars['Boolean'];
  /** Access to Heatmap Tab? */
  insightsHeatmap: Scalars['Boolean'];
  /** Access to Time Trending? */
  insightsTimeTrending: Scalars['Boolean'];
  /** Access to download a static PDF for Action Plans? */
  insightsActionPlanPdf: Scalars['Boolean'];
  /** Allow downloading statements results excel */
  statementsExcelDownload: Scalars['Boolean'];
  /** Allow downloading comments results excel */
  commentsExcelDownload: Scalars['Boolean'];
  /** Access to Action Plans? */
  actionPlans: Scalars['Boolean'];
  /** Allow Custom Statements on Action Plans? */
  actionPlansCustomStatements: Scalars['Boolean'];
  /** Enable Action Plans management? */
  actionPlansManagement: Scalars['Boolean'];
  /** Which surveys to show in benchmarks menu? */
  benchmarkPastSurveys: ResidentSolutionBenchmarkPastSurveys;
  /** Allow user to benchmark against their structure data */
  benchmarkInternal: Scalars['Boolean'];
  /** Access to engagement overlay? */
  engagementOverlay: Scalars['Boolean'];
  /** The maximum number of notifications allowed per survey. */
  maxSurveyNotifications: Scalars['Int'];
  /** Allow users to setup interval based notifications? */
  hasIntervalBasedNotifications: Scalars['Boolean'];
  /** Should users be able to configure and receive notifications when there are new survey responses? */
  hasSurveyResponseNotifications: Scalars['Boolean'];
  /** Should users be able to configure and receive score based notifications? */
  hasScoreNotifications: Scalars['Boolean'];
  /** Access to Insights Testimonials? */
  insightsTestimonials: Scalars['Boolean'];
  /** Allow downloading NPS results? */
  npsDownload: Scalars['Boolean'];
  /** Orgs can only enable standard services (mgmt, dining, caregiving, etc.). See confluence for full list. */
  standardServicesOnly: Scalars['Boolean'];
  /** Allow downloading the summary results report? */
  resultsSummaryReportDownload: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  onlineReviewSites?: Maybe<Array<OnlineReviewSiteEnum>>;
};

export type ResponseRateFilterType = {
  __typename?: 'ResponseRateFilterType';
  filterType: FilterTypeType;
  /**
   * Some filter types should not be used for response rate by client
   * because the values can differ between client and representative and we wouldn't
   * be able to consolidate them into a single response.
   */
  isValidByClient: Scalars['Boolean'];
};

export type ResponseRateHighlightType = {
  __typename?: 'ResponseRateHighlightType';
  filterTypeName: Scalars['String'];
  filterTypeUuid: Scalars['String'];
  topFilterValueNames: Array<Maybe<Scalars['String']>>;
  topRate: Scalars['Float'];
  highlightType: Scalars['String'];
};

export type ResponseRateReportDataType = {
  __typename?: 'ResponseRateReportDataType';
  responseRate: ResponseRateReportType;
  rows: Array<ResponseRateRowType>;
  highlights: Array<ResponseRateHighlightType>;
};

export type ResponseRateReportMetadata = {
  __typename?: 'ResponseRateReportMetadata';
  surveyUuid: Scalars['String'];
  isDefaultClientBasedResponseRateReport: Scalars['Boolean'];
  filterTypes: Array<ResponseRateFilterType>;
  minShowableResults: Scalars['Int'];
  distributionType: SurveyDistributionTypeEnum;
};

export type ResponseRateReportType = {
  __typename?: 'ResponseRateReportType';
  finished: Scalars['Int'];
  total: Scalars['Int'];
  rate: Scalars['Float'];
  totalFilter1: Scalars['Int'];
};

export type ResponseRateRowType = {
  __typename?: 'ResponseRateRowType';
  total: Scalars['Int'];
  finished: Scalars['Int'];
  rate: Scalars['Float'];
  filterValue1: Scalars['String'];
  filterValue2: Scalars['String'];
};

export type ResponseRateSurveyType = {
  __typename?: 'ResponseRateSurveyType';
  uuid: Scalars['String'];
  status: SurveyStatusEnum;
  name: Scalars['String'];
  /** Survey start date and time in UTC */
  startDate: Scalars['DateTime'];
  /** Survey end date and time in UTC */
  endDate?: Maybe<Scalars['DateTime']>;
  targetResponsesNo?: Maybe<Scalars['Int']>;
  reportUuid: Scalars['String'];
  type: SurveyTypeEnum;
  reportShareUrl: Scalars['String'];
  certificationEligibilityRate: Scalars['Float'];
  isDefaultClientBasedResponseRateReport: Scalars['Boolean'];
  minShowableResults: Scalars['Int'];
  distributionType?: Maybe<SurveyDistributionTypeEnum>;
};

export type ResponseRateType = {
  __typename?: 'ResponseRateType';
  finished: Scalars['Int'];
  total: Scalars['Int'];
  rate: Scalars['Float'];
};

/** An enumeration. */
export enum ResponseRateTypeEnum {
  CLIENT = 'CLIENT',
  PARTICIPANT = 'PARTICIPANT'
}

export type ResponsesByDateType = {
  __typename?: 'ResponsesByDateType';
  date: Scalars['Date'];
  responses: Scalars['Int'];
};

export type ResultsSurveyNode = {
  __typename?: 'ResultsSurveyNode';
  uuid: Scalars['String'];
  name: Scalars['String'];
  /** Survey end date and time in UTC */
  endDate?: Maybe<Scalars['DateTime']>;
};

export type SavePersonalInfoResponses = {
  __typename?: 'SavePersonalInfoResponses';
  errors?: Maybe<Array<Scalars['String']>>;
  participantHashId?: Maybe<Scalars['String']>;
};

export type SaveResponses = {
  __typename?: 'SaveResponses';
  errors?: Maybe<Array<Scalars['String']>>;
  responses?: Maybe<Scalars['JSONString']>;
};

export type ScheduledNotificationInput = {
  uuid?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['Boolean']>;
  personalEmail?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  smsOptional?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTextOptional1?: Maybe<Scalars['String']>;
  emailTextOptional2?: Maybe<Scalars['String']>;
  when?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum ScheduledNotificationNotificationType {
  /** Starting */
  SURVEY_STARTING = 'SURVEY_STARTING',
  /** Reminder */
  REMINDER = 'REMINDER',
  /** Ending */
  SURVEY_ENDING = 'SURVEY_ENDING',
  /** Other */
  OTHER = 'OTHER'
}

export type ScheduledNotificationType = {
  __typename?: 'ScheduledNotificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  /** The part of the SMS that can be customized. */
  smsOptional: Scalars['String'];
  /** The required part of the SMS. */
  smsRequired: Scalars['String'];
  /** This is NOT customizable. */
  emailSubject: Scalars['String'];
  /** The first part of the email that can be customized. */
  emailTextOptional1: Scalars['String'];
  /** The required part of the email. */
  emailTextRequired: Scalars['String'];
  /** The second part of the email that can be customized. */
  emailTextOptional2: Scalars['String'];
  notificationType: ScheduledNotificationNotificationType;
  sms: Scalars['Boolean'];
  personalEmail: Scalars['Boolean'];
  workEmail: Scalars['Boolean'];
  /** Is this notification set as active/usable? */
  active: Scalars['Boolean'];
  /** Date and time in UTC. */
  when: Scalars['DateTime'];
  survey: ResultsSurveyNode;
  errors?: Maybe<Scalars['String']>;
  firstSentTimestamp?: Maybe<Scalars['DateTime']>;
};

export type SendIntervalTestEmail = {
  __typename?: 'SendIntervalTestEmail';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type SendIntervalTestSms = {
  __typename?: 'SendIntervalTestSMS';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type SendScheduledTestEmail = {
  __typename?: 'SendScheduledTestEmail';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type SendScheduledTestSms = {
  __typename?: 'SendScheduledTestSMS';
  errors?: Maybe<Array<Scalars['String']>>;
};

/**
 * An enum of service areas that can be associated with an organization. The ServiceArea model on the
 * organization determines which service-area related questions show up on a resident survey.
 * Some services include multiple questions (e.g. dining_1, dining_2). The majority are "generic_choices"
 * that include a single related question: "Are you satisfied with {{service_area}}".
 */
export enum ServiceAreaEnum {
  ACCOUNT_BILLING = 'ACCOUNT_BILLING',
  ADMISSIONS = 'ADMISSIONS',
  BUILDING = 'BUILDING',
  CAREGIVING = 'CAREGIVING',
  CLINIC = 'CLINIC',
  COMMUNITY = 'COMMUNITY',
  DAY_CARE_ADULT = 'DAY_CARE_ADULT',
  DAY_CARE_CHILDREN = 'DAY_CARE_CHILDREN',
  DENTIST_SPECIALISTS = 'DENTIST_SPECIALISTS',
  DINING = 'DINING',
  ENRICHMENT_ACTIVITIES = 'ENRICHMENT_ACTIVITIES',
  ENVIRONMENT_SERVICES = 'ENVIRONMENT_SERVICES',
  FOUNDATION_PHILANTHROPY = 'FOUNDATION_PHILANTHROPY',
  FRONT_DESK = 'FRONT_DESK',
  HOME_CARE = 'HOME_CARE',
  HOME_HEALTH = 'HOME_HEALTH',
  HOSPICE = 'HOSPICE',
  HOUSEKEEPING = 'HOUSEKEEPING',
  IT_INFORMATION_SYSTEMS = 'IT_INFORMATION_SYSTEMS',
  LANDSCAPING_GROUNDS = 'LANDSCAPING_GROUNDS',
  LAUNDRY = 'LAUNDRY',
  LICENSED_NURSING = 'LICENSED_NURSING',
  MAINTENANCE = 'MAINTENANCE',
  MANAGEMENT = 'MANAGEMENT',
  MARKETING_OR_ADMISSIONS = 'MARKETING_OR_ADMISSIONS',
  MARKET_STORE = 'MARKET_STORE',
  MEDICAL = 'MEDICAL',
  MEDICATION_TECHNICIANS = 'MEDICATION_TECHNICIANS',
  OCCUPATIONAL_THERAPY = 'OCCUPATIONAL_THERAPY',
  PARKING = 'PARKING',
  PHYSICAL_THERAPY = 'PHYSICAL_THERAPY',
  SAFETY = 'SAFETY',
  SALON = 'SALON',
  SERVICE_COORDINATORS = 'SERVICE_COORDINATORS',
  SOCIAL_WORK = 'SOCIAL_WORK',
  SPEECH_LANGUAGE_PATHOLOGY = 'SPEECH_LANGUAGE_PATHOLOGY',
  SPIRITUAL_SERVICES = 'SPIRITUAL_SERVICES',
  TRANSPORTATION = 'TRANSPORTATION',
  UNIT = 'UNIT',
  WELLNESS_FITNESS = 'WELLNESS_FITNESS'
}

export type ServiceAreaInputType = {
  code: ServiceAreaEnum;
  customLabel: Scalars['String'];
  levelOfCareCodes: Array<Scalars['String']>;
  isActive: Scalars['Boolean'];
  departmentUuids: Array<Scalars['String']>;
};

export type ServiceAreaType = {
  __typename?: 'ServiceAreaType';
  uuid: Scalars['String'];
  code: ServiceAreaEnum;
  /** Optional label to override default enum name */
  customLabel: Scalars['String'];
  /** Include service area in resident survey questions */
  isActive: Scalars['Boolean'];
  questionCodes: Array<Scalars['String']>;
  /** How the service area appears on a question. */
  questionLabel: Scalars['String'];
  /** Default enum label */
  label: Scalars['String'];
  departments: Array<FilterValueType>;
  levelsOfCare: Array<ServiceLevelOfCareType>;
  /** Whether the service can be disabled */
  required: Scalars['Boolean'];
  /** Whether the service can be enabled */
  disabled: Scalars['Boolean'];
};

export type ServiceLevelOfCareType = {
  __typename?: 'ServiceLevelOfCareType';
  code: Scalars['String'];
  name: Scalars['String'];
  /** Whether the level of care can be disabled */
  required: Scalars['Boolean'];
};

export type SettingsSectionType = {
  __typename?: 'SettingsSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  surveyCodeName: Scalars['String'];
  surveyCodeHelpText: Scalars['String'];
  locs?: Maybe<Array<Scalars['String']>>;
  depts?: Maybe<Array<Scalars['String']>>;
  missingServices: Array<Scalars['String']>;
};

export type ShareMarketingToolkit = {
  __typename?: 'ShareMarketingToolkit';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type ShareScreenshot = {
  __typename?: 'ShareScreenshot';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type SignedUrl = {
  __typename?: 'SignedUrl';
  url: Scalars['String'];
  path: Scalars['String'];
};

/** An enumeration. */
export enum SolutionBenchmarkPastSurveys {
  /** None */
  NONE = 'NONE',
  /** Previous Survey(s) */
  PREVIOUS = 'PREVIOUS',
  /** All */
  ALL = 'ALL'
}

/** An enumeration. */
export enum SolutionDataAccessLevels {
  /** No ability to set access permissions */
  NONE = 'NONE',
  /** Can use all filter types for setting permissions */
  ALL = 'ALL',
  /** Can use one filter type that we specify */
  ONE = 'ONE',
  /** Users can pick one out of all available filters */
  CHOOSE_ONE = 'CHOOSE_ONE'
}

export type SolutionFieldAccessType = {
  __typename?: 'SolutionFieldAccessType';
  field: SolutionFieldEnum;
  enabled: Scalars['Boolean'];
};

/** An enumeration. */
export enum SolutionFieldEnum {
  SURVEYS = 'SURVEYS',
  PULSE_SURVEYS = 'PULSE_SURVEYS',
  MAX_PULSE_SURVEYS = 'MAX_PULSE_SURVEYS',
  CUSTOM_SURVEYS = 'CUSTOM_SURVEYS',
  MAX_CUSTOM_SURVEYS = 'MAX_CUSTOM_SURVEYS',
  HAS_MONTHLY_SURVEYS = 'HAS_MONTHLY_SURVEYS',
  HAS_END_OF_EMPLOYMENT_SURVEYS = 'HAS_END_OF_EMPLOYMENT_SURVEYS',
  HAS_ENGAGEMENT_MONTHLY_SURVEYS = 'HAS_ENGAGEMENT_MONTHLY_SURVEYS',
  HAS_START_OF_SERVICE_SURVEYS = 'HAS_START_OF_SERVICE_SURVEYS',
  HAS_END_OF_SERVICE_SURVEYS = 'HAS_END_OF_SERVICE_SURVEYS',
  HAS_DISCHARGE_SURVEYS = 'HAS_DISCHARGE_SURVEYS',
  MAX_DISCHARGE_SURVEYS = 'MAX_DISCHARGE_SURVEYS',
  MAX_START_OF_SERVICE = 'MAX_START_OF_SERVICE',
  MAX_END_OF_SERVICE = 'MAX_END_OF_SERVICE',
  ACTION_PLANS_CUSTOM_STATEMENTS = 'ACTION_PLANS_CUSTOM_STATEMENTS',
  ACTION_PLANS = 'ACTION_PLANS',
  ACTION_PLANS_MANAGEMENT = 'ACTION_PLANS_MANAGEMENT',
  ADMIN_INVITES_ONLY = 'ADMIN_INVITES_ONLY',
  INSIGHTS_ACTION_PLAN_PDF = 'INSIGHTS_ACTION_PLAN_PDF',
  ENGAGEMENT_OVERLAY = 'ENGAGEMENT_OVERLAY',
  STANDARD_SERVICES_ONLY = 'STANDARD_SERVICES_ONLY',
  HAS_SURVEY_RESPONSE_NOTIFICATIONS = 'HAS_SURVEY_RESPONSE_NOTIFICATIONS',
  HAS_SCORE_BASED_NOTIFICATIONS = 'HAS_SCORE_BASED_NOTIFICATIONS'
}

/** An enumeration. */
export enum SolutionFilters {
  /** No ability to use filter types to filter data */
  NONE = 'NONE',
  /** Can use all filter types */
  ALL = 'ALL',
  /** Can use one filter type that we specify */
  ONE = 'ONE'
}

export type SolutionType = Node & {
  __typename?: 'SolutionType';
  name: Scalars['String'];
  /** If this is checked, admins can only invite other admins. */
  adminInvitesOnly: Scalars['Boolean'];
  /** How many levels of data access can admins assign? */
  dataAccessLevels: SolutionDataAccessLevels;
  /** How many filters are available in Insights */
  filters: SolutionFilters;
  /** Display tabs not included in solution with sales promo tooltip */
  showUnavailableTabs: Scalars['Boolean'];
  /** Whether the organization has access to insights or not */
  insights: Scalars['Boolean'];
  /** Give access to extended snapshots? */
  insightsSnapshots: Scalars['Boolean'];
  /** Give access to key demographics within snapshots? */
  insightsKeyDemographics: Scalars['Boolean'];
  /** Access to Statements Tab? */
  insightsStatements: Scalars['Boolean'];
  /** Access to Comments Tab? */
  insightsComments: Scalars['Boolean'];
  /** Show additional features on comments page such as word cloud and sorting. */
  insightsEnhancedComments: Scalars['Boolean'];
  /** Remove the Second Filter column from the comments download */
  insightsHideSecondFilter: Scalars['Boolean'];
  /** Access to Comparisons Tab? */
  insightsComparisons: Scalars['Boolean'];
  /** Access to the grid view on the Comparisons Tab? */
  insightsComparisonsGrid: Scalars['Boolean'];
  /** Access to Heatmap Tab? */
  insightsHeatmap: Scalars['Boolean'];
  /** Access to Time Trending? */
  insightsTimeTrending: Scalars['Boolean'];
  /** Access to download a static PDF for Action Plans? */
  insightsActionPlanPdf: Scalars['Boolean'];
  /** Allow downloading statements results excel */
  statementsExcelDownload: Scalars['Boolean'];
  /** Allow downloading comments results excel */
  commentsExcelDownload: Scalars['Boolean'];
  /** Access to Action Plans? */
  actionPlans: Scalars['Boolean'];
  /** Allow Custom Statements on Action Plans? */
  actionPlansCustomStatements: Scalars['Boolean'];
  /** Enable Action Plans management? */
  actionPlansManagement: Scalars['Boolean'];
  /** Which surveys to show in benchmarks menu? */
  benchmarkPastSurveys: SolutionBenchmarkPastSurveys;
  /** Allow user to benchmark against their structure data */
  benchmarkInternal: Scalars['Boolean'];
  /** Access to engagement overlay? */
  engagementOverlay: Scalars['Boolean'];
  /** The maximum number of notifications allowed per survey. */
  maxSurveyNotifications: Scalars['Int'];
  /** Allow users to setup interval based notifications? */
  hasIntervalBasedNotifications: Scalars['Boolean'];
  /** Access to Certification Report PDF? */
  certificationReport: Scalars['Boolean'];
  /** Access to Engagement Report PDF? */
  engagementReport: Scalars['Boolean'];
  certificationType?: Maybe<Scalars['String']>;
  /** Allow downloading eNPS results? */
  npsDownload: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type StandardFilterValueChoicesByDataTypeType = {
  __typename?: 'StandardFilterValueChoicesByDataTypeType';
  dtCode: DataTypeCode;
  standardChoices: Array<Scalars['String']>;
};

export type StandardType = {
  __typename?: 'StandardType';
  text: Scalars['String'];
  value: Scalars['String'];
};

export type StatementScoreType = {
  __typename?: 'StatementScoreType';
  uuid: Scalars['String'];
  code: Scalars['String'];
  label: Scalars['String'];
  positive: Scalars['Float'];
  benchmarkPositive?: Maybe<Scalars['Float']>;
  inconsistent: Scalars['Float'];
  benchmarkInconsistent?: Maybe<Scalars['Float']>;
  negative: Scalars['Float'];
  benchmarkNegative?: Maybe<Scalars['Float']>;
  count: Scalars['Float'];
  focus?: Maybe<QFocus>;
  residentFocus?: Maybe<QResidentFocus>;
  residentResultsGroup?: Maybe<QResidentResultsGroup>;
  children: Array<StatementScoreType>;
  /** Correlation coefficient to the overall positive index score */
  overallPositiveCorrelation?: Maybe<Scalars['Float']>;
};

/** An enumeration. */
export enum SurveyDistributionTypeEnum {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

export type SurveyFilterTypeSettingInput = {
  dtCode: DataTypeCode;
  reportingType: FilterTypeReportingTypeEnum;
};

export type SurveyFilterTypeSettingType = {
  __typename?: 'SurveyFilterTypeSettingType';
  dtCode: DataTypeCode;
  reportingType: FilterTypeReportingTypeEnum;
};

export type SurveyInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: SurveyTypeEnum;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  languages?: Maybe<Array<Scalars['String']>>;
  associatedSurveyUuid?: Maybe<Scalars['String']>;
  distributionType?: Maybe<SurveyDistributionTypeEnum>;
  participantDataSource?: Maybe<SurveyParticipantDataSourceEnum>;
  hasConfidentialResults?: Maybe<Scalars['Boolean']>;
  filterTypeSettings?: Maybe<Array<Maybe<SurveyFilterTypeSettingInput>>>;
};

/** An enumeration. */
export enum SurveyInviteMethodEnum {
  SMS_EMAIL = 'SMS_EMAIL',
  SMS_EMAIL_PHONE = 'SMS_EMAIL_PHONE',
  PHONE = 'PHONE'
}

export type SurveyNode = Node & {
  __typename?: 'SurveyNode';
  uuid: Scalars['String'];
  status: SurveyStatusEnum;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Survey start date and time in UTC */
  startDate: Scalars['DateTime'];
  /** Survey end date and time in UTC */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Should a password be required to view the response rate report? */
  reportPassword: Scalars['String'];
  /** How many SMS notifications can be sent per survey? */
  maxSmsNotifications: Scalars['Int'];
  /**
   * Keep the survey results confidential? If False,
   * it will show results with less than 5 responses and
   * enable the Individual Results tab in Insights.
   */
  hasConfidentialResults: Scalars['Boolean'];
  /** Include for US News? */
  includeForUsNews: Scalars['Boolean'];
  inviteMethod: SurveyInviteMethodEnum;
  scheduledNotifications: Array<ScheduledNotificationType>;
  /** The ID of the object. */
  id: Scalars['ID'];
  insightsSurvey: InsightsSurveyNode;
  reportShareUrl: Scalars['String'];
  type: SurveyTypeEnum;
  productType: SurveyProductTypeEnum;
  minShowableResults: Scalars['Int'];
  /** Only used for Pulse Survey. */
  associatedSurvey?: Maybe<SurveyNode>;
  editable: Scalars['Boolean'];
  isRecurring: Scalars['Boolean'];
  allowedCategoriesForCustomQuestions: Array<QCategory>;
  allowedCategoriesForOptionalQuestions: Array<QCategory>;
  translationsRequired?: Maybe<Scalars['Boolean']>;
  numberOfMaxScheduledNotifications: Scalars['Int'];
  maxScheduledNotificationDate?: Maybe<Scalars['DateTime']>;
  languages: Array<LanguageType>;
  mustShowTakeActionNotification?: Maybe<Scalars['Boolean']>;
  welcomeMessages: Array<SurveyTextType>;
  defaultWelcomeMessages: Array<SurveyTextType>;
  distributionType?: Maybe<SurveyDistributionTypeEnum>;
  participantDataSource?: Maybe<SurveyParticipantDataSourceEnum>;
  dataTypeOptions: Array<DataTypeOptionType>;
  /** List of filter type uuids associated with participants on the survey */
  filterTypeUuids: Array<Scalars['String']>;
  filterTypeSettings: Array<SurveyFilterTypeSettingType>;
  participantsUpload?: Maybe<FileImportType>;
  clientsUpload?: Maybe<FileImportType>;
  participantTemplateUrl: Scalars['String'];
  clientTemplateUrl?: Maybe<Scalars['String']>;
  responseRate: ResponseRateType;
  previewUrl: Scalars['String'];
  intervalNotifications: Array<IntervalNotificationType>;
  groupsWithSurveyAccess: Array<GroupNode>;
  groupsSurveyResponseNotifications: Array<GroupsSurveyResponseNotificationType>;
  groupsAverageScoreNotifications: Array<GroupsSurveyAverageScoreNotificationType>;
  groupsQuestionScoreNotifications: Array<GroupsSurveyQuestionScoreNotificationType>;
  isDefaultClientBasedResponseRateReport: Scalars['Boolean'];
  takeActionCheckedSteps: Array<Maybe<Scalars['String']>>;
  insightsActionPlanPdfUrl?: Maybe<Scalars['String']>;
  defaultBenchmark?: Maybe<BenchmarkNode>;
  /** Does this survey include the testimonials question? */
  includesTestimonials: Scalars['Boolean'];
  /** Lookup whether this survey included all 3 loneliness questions and can be used to calculate a loneliness score. */
  includesLonelinessQuestions: Scalars['Boolean'];
  includesNpsQuestion: Scalars['Boolean'];
  includesLeftReasonQuestion: Scalars['Boolean'];
  hasCoreQRecommendResponses: Scalars['Boolean'];
  hasMissingTranslations: Scalars['Boolean'];
  /**
   * Does this survey have less than the minimum number of responses required to
   * show results? Available as a property for efficiency on bulk survey requests.
   */
  hasLessThanMinShowableResults: Scalars['Boolean'];
  surveyPortalUrl: Scalars['String'];
  hasIntervalNotifications: Scalars['Boolean'];
  hasSurveyResponseNotifications: Scalars['Boolean'];
  hasAverageScoreNotifications: Scalars['Boolean'];
  hasQuestionScoreNotifications: Scalars['Boolean'];
  numberOfLocationFilterValuesMissingRequiredData: Scalars['Int'];
  phoneScript?: Maybe<Scalars['String']>;
  missingPhonesErrorLevel: SurveyPhoneStatusErrorLevelEnum;
  hasIngestFailureReports: Scalars['Boolean'];
  eligibleParticipantSurveyConfigs: Array<EligibleParticipantSurveyConfigType>;
  usesSamplePoolDataSource: Scalars['Boolean'];
  reasonsForLeaving: Array<ReasonForLeaving>;
};


export type SurveyNodeInsightsSurveyArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  filters?: Maybe<Array<Scalars['String']>>;
};


export type SurveyNodeResponseRateArgs = {
  byClient?: Maybe<Scalars['Boolean']>;
};

export type SurveyNodeConnection = {
  __typename?: 'SurveyNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SurveyNodeEdge>>;
};

/** A Relay edge containing a `SurveyNode` and its cursor. */
export type SurveyNodeEdge = {
  __typename?: 'SurveyNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SurveyNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum SurveyParticipantDataSourceEnum {
  DIRECT_ROSTER_IMPORT = 'DIRECT_ROSTER_IMPORT',
  SAMPLE_POOL = 'SAMPLE_POOL'
}

/** An enumeration. */
export enum SurveyPhoneStatusErrorLevelEnum {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  VALID = 'VALID'
}

/** An enumeration. */
export enum SurveyProductTypeEnum {
  EMPLOYEE = 'EMPLOYEE',
  RESIDENT = 'RESIDENT'
}

/** An enumeration. */
export enum SurveyResponseNotificationTypeEnum {
  FIRST_RESPONSE = 'FIRST_RESPONSE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY'
}

/** An enumeration. */
export enum SurveyScoreNotificationFrequencyEnum {
  DAILY = 'DAILY',
  WEEKLY_MONDAYS = 'WEEKLY_MONDAYS',
  WEEKLY_TUESDAYS = 'WEEKLY_TUESDAYS',
  WEEKLY_WEDNESDAYS = 'WEEKLY_WEDNESDAYS',
  WEEKLY_THURSDAYS = 'WEEKLY_THURSDAYS',
  WEEKLY_FRIDAYS = 'WEEKLY_FRIDAYS',
  WEEKLY_SATURDAYS = 'WEEKLY_SATURDAYS',
  WEEKLY_SUNDAYS = 'WEEKLY_SUNDAYS'
}

/** An enumeration. */
export enum SurveyScoreNotificationTypeEnum {
  LOW = 'LOW',
  HIGH = 'HIGH'
}

export type SurveysResponseRateTypeInput = {
  surveyUuid: Scalars['String'];
  responseRateType: ResponseRateTypeEnum;
};

/** An enumeration. */
export enum SurveyStatusEnum {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  LIVE = 'LIVE',
  CLOSED = 'CLOSED'
}

export type SurveySummaryType = {
  __typename?: 'SurveySummaryType';
  surveyPortalUrl: Scalars['String'];
  canSchedule: Scalars['Boolean'];
  canUnschedule: Scalars['Boolean'];
  numFinishedResponses?: Maybe<Scalars['Int']>;
  numTotalResponses?: Maybe<Scalars['Int']>;
  numLocationsMissingRequiredData?: Maybe<Scalars['Int']>;
  overallIndexScore?: Maybe<OverallScoreType>;
  design: DesignSectionType;
  questions: QuestionsSectionType;
  translations?: Maybe<TranslationsSectionType>;
  participants?: Maybe<ParticipantsSectionType>;
  notifications?: Maybe<NotificationsSectionType>;
  filterValues?: Maybe<FilterValuesSectionType>;
  settings?: Maybe<SettingsSectionType>;
};

export type SurveyTextInput = {
  key: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type SurveyTextType = {
  __typename?: 'SurveyTextType';
  uuid: Scalars['String'];
  text: Scalars['String'];
  key: Scalars['String'];
};

/** An enumeration. */
export enum SurveyTypeEnum {
  TI = 'TI',
  PULSE = 'PULSE',
  CUSTOM = 'CUSTOM',
  MONTHLY = 'MONTHLY',
  END_OF_EMPLOYMENT = 'END_OF_EMPLOYMENT',
  RESIDENT_ENGAGEMENT = 'RESIDENT_ENGAGEMENT',
  RESIDENT_PULSE = 'RESIDENT_PULSE',
  RESIDENT_CUSTOM = 'RESIDENT_CUSTOM',
  RESIDENT_END_OF_SERVICE = 'RESIDENT_END_OF_SERVICE',
  RESIDENT_DISCHARGE = 'RESIDENT_DISCHARGE',
  RESIDENT_ENGAGEMENT_MONTHLY = 'RESIDENT_ENGAGEMENT_MONTHLY',
  RESIDENT_START_OF_SERVICE = 'RESIDENT_START_OF_SERVICE'
}

/** An enumeration. */
export enum TakeActionStepsEnum {
  UNDERSTAND_1 = 'UNDERSTAND_1',
  UNDERSTAND_2 = 'UNDERSTAND_2',
  UNDERSTAND_3 = 'UNDERSTAND_3',
  UNDERSTAND_4 = 'UNDERSTAND_4',
  UNDERSTAND_5 = 'UNDERSTAND_5',
  COMMUNICATE_1 = 'COMMUNICATE_1',
  COMMUNICATE_2 = 'COMMUNICATE_2',
  COMMUNICATE_3 = 'COMMUNICATE_3',
  COMMUNICATE_4 = 'COMMUNICATE_4',
  COMMUNICATE_5 = 'COMMUNICATE_5',
  IMPROVE_1 = 'IMPROVE_1',
  IMPROVE_2 = 'IMPROVE_2',
  IMPROVE_3 = 'IMPROVE_3',
  IMPROVE_4 = 'IMPROVE_4',
  IMPROVE_5 = 'IMPROVE_5',
  IMPROVE_6 = 'IMPROVE_6',
  IMPROVE_7 = 'IMPROVE_7',
  IMPROVE_8 = 'IMPROVE_8',
  IMPROVE_9 = 'IMPROVE_9',
  IMPROVE_10 = 'IMPROVE_10',
  IMPROVE_11 = 'IMPROVE_11',
  IMPROVE_12 = 'IMPROVE_12',
  IMPROVE_13 = 'IMPROVE_13',
  IMPROVE_14 = 'IMPROVE_14'
}

/** An enumeration. */
export enum TaskStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE'
}

export type ToggleFavoriteSurvey = {
  __typename?: 'ToggleFavoriteSurvey';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

/** Update the list of action plans a user is following */
export type ToggleFollowActionPlan = {
  __typename?: 'ToggleFollowActionPlan';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type ToggleIsActive = {
  __typename?: 'ToggleIsActive';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

/** An enumeration. */
export enum TranslatedObjectTypeEnum {
  QUESTION = 'QUESTION',
  QUESTION_CHOICE = 'QUESTION_CHOICE',
  BENCHMARK_CONFIG = 'BENCHMARK_CONFIG',
  SURVEY_TEXT = 'SURVEY_TEXT',
  SERVICE_AREA = 'SERVICE_AREA'
}

export type TranslationInput = {
  uuid: Scalars['String'];
  objectUuid: Scalars['String'];
  objectType: TranslatedObjectTypeEnum;
  languageCode: Scalars['String'];
  text: Scalars['String'];
};

export type TranslationsSectionType = {
  __typename?: 'TranslationsSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  missing: Scalars['Int'];
  translated: Scalars['Int'];
};

export type TranslationType = {
  __typename?: 'TranslationType';
  uuid: Scalars['String'];
  /** The translated text in the language of the object */
  text: Scalars['String'];
  language: LanguageType;
  objectUuid: Scalars['String'];
  objectType: TranslatedObjectTypeEnum;
  englishText: Scalars['String'];
};

export type UpdateActionItemTask = {
  __typename?: 'UpdateActionItemTask';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

export type UpdateActionItemTaskComment = {
  __typename?: 'UpdateActionItemTaskComment';
  errors?: Maybe<Array<Scalars['String']>>;
  actionItemTask?: Maybe<ActionItemTaskNode>;
};

export type UpdateActionPlanSurvey = {
  __typename?: 'UpdateActionPlanSurvey';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

export type UpdateCultureBriefImages = {
  __typename?: 'UpdateCultureBriefImages';
  errors?: Maybe<Array<Scalars['String']>>;
  cultureBrief?: Maybe<CultureBriefType>;
};

export type UpdateCultureBriefInput = {
  status: Scalars['String'];
  yearFounded?: Maybe<Scalars['DateTime']>;
  numberOfLocations?: Maybe<Scalars['Int']>;
  isSubsidiary?: Maybe<Scalars['Boolean']>;
  organizationName?: Maybe<Scalars['String']>;
  industrySubVertical?: Maybe<Scalars['String']>;
  organizationType?: Maybe<Scalars['String']>;
  mainTypeOfCare?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebookUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  indeedUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  totalNumberOfEmployees?: Maybe<Scalars['Int']>;
  pastTotalNumberOfEmployees?: Maybe<Scalars['Int']>;
  totalApplicants?: Maybe<Scalars['Int']>;
  totalOpenRoles?: Maybe<Scalars['Int']>;
  recommendationsPercentage?: Maybe<Scalars['Int']>;
  averageNumberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfEmployeesLeft?: Maybe<Scalars['Int']>;
  carbonEmissionsReductionPercentage?: Maybe<Scalars['Int']>;
  numberOfEmployeesWithHealthInsurance?: Maybe<Scalars['Int']>;
  numberOfEmployeesEligibleForHealthInsurance?: Maybe<Scalars['Int']>;
  employeeBenefits?: Maybe<Scalars['String']>;
  additionalEmployeeBenefits?: Maybe<Scalars['String']>;
  percentageOfEmployeesWithBigWages?: Maybe<Scalars['Int']>;
  percentageOfImmigrantEmployees?: Maybe<Scalars['Int']>;
  totalBoardComposition?: Maybe<Scalars['Int']>;
  totalBoardWomen?: Maybe<Scalars['Int']>;
  totalBoardMinoritiesRace?: Maybe<Scalars['Int']>;
  totalBoardMinoritiesLgbt?: Maybe<Scalars['Int']>;
  totalCeoReportsComposition?: Maybe<Scalars['Int']>;
  totalCeoReportsWomen?: Maybe<Scalars['Int']>;
  totalCeoReportsMinoritiesRace?: Maybe<Scalars['Int']>;
  totalCeoReportsMinoritiesLgbt?: Maybe<Scalars['Int']>;
  hasDiversityEquity?: Maybe<Scalars['Boolean']>;
  diversityEquityDescription?: Maybe<Scalars['String']>;
  reasonsToWorkForCompany?: Maybe<Scalars['String']>;
  companyMission?: Maybe<Scalars['String']>;
  careersPageLink?: Maybe<Scalars['String']>;
  ceoFirstName?: Maybe<Scalars['String']>;
  ceoLastName?: Maybe<Scalars['String']>;
  ceoTitle?: Maybe<Scalars['String']>;
  ceoPhoneNumber?: Maybe<Scalars['String']>;
  ceoEmail?: Maybe<Scalars['String']>;
  mainContactFirstName?: Maybe<Scalars['String']>;
  mainContactLastName?: Maybe<Scalars['String']>;
  mainContactTitle?: Maybe<Scalars['String']>;
  mainContactPhoneNumber?: Maybe<Scalars['String']>;
  mainContactEmail?: Maybe<Scalars['String']>;
  marketingContactFirstName?: Maybe<Scalars['String']>;
  marketingContactLastName?: Maybe<Scalars['String']>;
  marketingContactTitle?: Maybe<Scalars['String']>;
  marketingContactPhoneNumber?: Maybe<Scalars['String']>;
  marketingContactEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCultureBriefPayload = {
  __typename?: 'UpdateCultureBriefPayload';
  cultureBrief?: Maybe<CultureBriefType>;
  errors?: Maybe<Array<Maybe<Errors>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomQuestion = {
  __typename?: 'UpdateCustomQuestion';
  errors?: Maybe<Array<Scalars['String']>>;
  question?: Maybe<QuestionType>;
};

export type UpdateEligibleParticipantSurveyConfigs = {
  __typename?: 'UpdateEligibleParticipantSurveyConfigs';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateFilterValue = {
  __typename?: 'UpdateFilterValue';
  errors?: Maybe<Array<Scalars['String']>>;
  filterValue?: Maybe<FilterValueType>;
};

export type UpdateFilterValueArchived = {
  __typename?: 'UpdateFilterValueArchived';
  errors?: Maybe<Array<Scalars['String']>>;
  filterValue?: Maybe<FilterValueType>;
};

export type UpdateGroup = {
  __typename?: 'UpdateGroup';
  errors?: Maybe<Array<Scalars['String']>>;
  group?: Maybe<GroupNode>;
};

export type UpdateGroupsAccessToSurvey = {
  __typename?: 'UpdateGroupsAccessToSurvey';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateGroupsAverageScoreNotifications = {
  __typename?: 'UpdateGroupsAverageScoreNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateGroupsQuestionScoreNotifications = {
  __typename?: 'UpdateGroupsQuestionScoreNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateGroupsSurveyResponseNotifications = {
  __typename?: 'UpdateGroupsSurveyResponseNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateIntervalNotification = {
  __typename?: 'UpdateIntervalNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateOrganization = {
  __typename?: 'UpdateOrganization';
  errors?: Maybe<Array<Scalars['String']>>;
  organization?: Maybe<OrganizationType>;
};

export type UpdateOrganizationServiceAreas = {
  __typename?: 'UpdateOrganizationServiceAreas';
  errors?: Maybe<Array<Scalars['String']>>;
  organization?: Maybe<OrganizationType>;
};

export type UpdateOrganizationSurveyPortal = {
  __typename?: 'UpdateOrganizationSurveyPortal';
  errors?: Maybe<Array<Scalars['String']>>;
  organization?: Maybe<OrganizationType>;
};

export type UpdateParticipant = {
  __typename?: 'UpdateParticipant';
  errors?: Maybe<Array<Scalars['String']>>;
  participant?: Maybe<ParticipantType>;
};

export type UpdatePersonalInfoQuestion = {
  __typename?: 'UpdatePersonalInfoQuestion';
  errors?: Maybe<Array<Scalars['String']>>;
  question?: Maybe<QuestionType>;
};

export type UpdateReport = {
  __typename?: 'UpdateReport';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateResidentParticipant = {
  __typename?: 'UpdateResidentParticipant';
  errors?: Maybe<Array<Scalars['String']>>;
  response?: Maybe<PublicResponse>;
};

export type UpdateScheduledNotification = {
  __typename?: 'UpdateScheduledNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

/** Mutation class to create or update an existing Survey instance */
export type UpdateSurvey = {
  __typename?: 'UpdateSurvey';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

/** Mutation class to update the Survey status. Basically called when launching a survey or unscheduling it */
export type UpdateSurveyStatus = {
  __typename?: 'UpdateSurveyStatus';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateTakeActionStepsMutation = {
  __typename?: 'UpdateTakeActionStepsMutation';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateTranslations = {
  __typename?: 'UpdateTranslations';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type UpdateUser = {
  __typename?: 'UpdateUser';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type UpdateUserAppMessage = {
  __typename?: 'UpdateUserAppMessage';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type UpdateUserAverageScoreNotifications = {
  __typename?: 'UpdateUserAverageScoreNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type UpdateUserHasSurveyResponseNotifications = {
  __typename?: 'UpdateUserHasSurveyResponseNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type UpdateUserQuestionScoreNotifications = {
  __typename?: 'UpdateUserQuestionScoreNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type UpdateUserSurveyResponseNotifications = {
  __typename?: 'UpdateUserSurveyResponseNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

/** Mutation class to create or update SurveyText instances (welcome message, welcome header etc) */
export type UpdateWelcomeMessages = {
  __typename?: 'UpdateWelcomeMessages';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UserAppMessage = {
  __typename?: 'UserAppMessage';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  user: UserNode;
  kind: Scalars['String'];
  seen?: Maybe<Scalars['DateTime']>;
  contentObjects: Array<ContentObject>;
};

export type UserAverageScoreNotificationInput = {
  scoreType: SurveyScoreNotificationTypeEnum;
  enabled: Scalars['Boolean'];
  frequency: SurveyScoreNotificationFrequencyEnum;
};

export type UserAverageScoreNotificationsInput = {
  surveyUuid: Scalars['String'];
  averageScoreNotifications: Array<UserAverageScoreNotificationInput>;
};

export type UserAverageScoreNotificationType = {
  __typename?: 'UserAverageScoreNotificationType';
  enabled: Scalars['Boolean'];
  frequency: SurveyScoreNotificationFrequencyEnum;
  scoreType: SurveyScoreNotificationTypeEnum;
};

export type UserCommentMentionInput = {
  uuid: Scalars['String'];
  index: Scalars['Int'];
};

export type UserCommentMentionType = {
  __typename?: 'UserCommentMentionType';
  id: Scalars['ID'];
  /** Position in the comment at which the mention starts */
  index: Scalars['Int'];
  user: UserNode;
  comment: ActionItemTaskCommentNode;
};

/** An enumeration. */
export enum UserDownloadsEnum {
  RESPONSE_RATE_REPORT = 'RESPONSE_RATE_REPORT',
  STATEMENTS_EXPORT = 'STATEMENTS_EXPORT',
  NPS_EXPORT = 'NPS_EXPORT',
  PARTICIPANT_FAILURE_REPORT_EXPORT = 'PARTICIPANT_FAILURE_REPORT_EXPORT',
  INDIVIDUAL_RESULTS_EXPORT = 'INDIVIDUAL_RESULTS_EXPORT',
  CORE_Q_SATISFACTION_SCORES_EXPORT = 'CORE_Q_SATISFACTION_SCORES_EXPORT',
  COMMENTS_EXPORT = 'COMMENTS_EXPORT',
  TESTIMONIALS_EXPORT = 'TESTIMONIALS_EXPORT',
  CERTIFICATION_REPORT = 'CERTIFICATION_REPORT',
  CERTIFIED_LOCATIONS_EXPORT = 'CERTIFIED_LOCATIONS_EXPORT',
  ENGAGEMENT_REPORT = 'ENGAGEMENT_REPORT',
  PARTICIPANT_UPLOAD_TEMPLATE = 'PARTICIPANT_UPLOAD_TEMPLATE',
  CUSTOM_SURVEY_PARTICIPANT_UPLOAD_TEMPLATE = 'CUSTOM_SURVEY_PARTICIPANT_UPLOAD_TEMPLATE',
  RESIDENT_PARTICIPANT_UPLOAD_TEMPLATE = 'RESIDENT_PARTICIPANT_UPLOAD_TEMPLATE',
  RESIDENT_CUSTOM_SURVEY_PARTICIPANT_UPLOAD_TEMPLATE = 'RESIDENT_CUSTOM_SURVEY_PARTICIPANT_UPLOAD_TEMPLATE',
  RESIDENT_CLIENT_UPLOAD_TEMPLATE = 'RESIDENT_CLIENT_UPLOAD_TEMPLATE',
  CUSTOM_SURVEY = 'CUSTOM_SURVEY',
  RESIDENT_CUSTOM_SURVEY = 'RESIDENT_CUSTOM_SURVEY',
  LONELINESS_REPORT = 'LONELINESS_REPORT',
  US_NEWS_SUMMARY_RESULTS = 'US_NEWS_SUMMARY_RESULTS',
  ADMIN_US_NEWS_RESULTS = 'ADMIN_US_NEWS_RESULTS',
  ADMIN_US_NEWS_LOCATIONS_AND_TESTIMONIALS = 'ADMIN_US_NEWS_LOCATIONS_AND_TESTIMONIALS',
  LTC_TREND_TRACKER = 'LTC_TREND_TRACKER',
  COMBINED_CONTACTS_TEMPLATE = 'COMBINED_CONTACTS_TEMPLATE',
  EMPLOYEE_CONTACTS_TEMPLATE = 'EMPLOYEE_CONTACTS_TEMPLATE',
  ACTION_PLANS = 'ACTION_PLANS'
}

export type UserNode = Node & {
  __typename?: 'UserNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  /** Timestamp of when the user was last active on the AI platform. */
  lastActivity?: Maybe<Scalars['DateTime']>;
  /** Will this user receive survey response notifications? */
  hasSurveyResponseNotifications: Scalars['Boolean'];
  appMessages: Array<UserAppMessage>;
  accessToSurveyProduct: Scalars['Boolean'];
  canCreateSurveys: Scalars['Boolean'];
  canAccessSurveySettings: Scalars['Boolean'];
  canManageCertifications: Scalars['Boolean'];
  canViewCertificationResults: Scalars['Boolean'];
  residentAccessToSurveyProduct: Scalars['Boolean'];
  residentCanCreateSurveys: Scalars['Boolean'];
  residentCanAccessSurveySettings: Scalars['Boolean'];
  canAccessLonelinessResults: Scalars['Boolean'];
  canUseAnalytics: Scalars['Boolean'];
  canInviteUsers: Scalars['Boolean'];
  canResetUsersPasswords: Scalars['Boolean'];
  canAccessDataStructure: Scalars['Boolean'];
  canAccessGeneralSettings: Scalars['Boolean'];
  canUseActionPlans: Scalars['Boolean'];
  canManageActionPlans: Scalars['Boolean'];
  uuid: Scalars['ID'];
  name: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  organization: OrganizationType;
  filterPermissions: Array<Maybe<FilterTypeType>>;
  filters: Array<FilterTypeType>;
  insightsModules: Array<InsightsModulesEnum>;
  residentInsightsModules: Array<InsightsModulesEnum>;
  groups: Array<GroupNode>;
  surveyTypesAllowedToAdd: Array<SurveyTypeEnum>;
  followedActionPlanIds: Array<Scalars['String']>;
  favoriteSurveyIds: Array<Scalars['String']>;
  userResultNotifications: Array<UserResultNotificationsType>;
  canUploadCombinedContacts: Scalars['Boolean'];
  canUploadEmployeeContacts: Scalars['Boolean'];
};

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type UserQuestionScoreNotificationInput = {
  userNotificationUuid?: Maybe<Scalars['String']>;
  groupNotificationUuid: Scalars['String'];
  enabled: Scalars['Boolean'];
  frequency: SurveyScoreNotificationFrequencyEnum;
};

export type UserQuestionScoreNotificationsInput = {
  surveyUuid: Scalars['String'];
  questionScoreNotifications: Array<UserQuestionScoreNotificationInput>;
};

export type UserQuestionScoreNotificationType = {
  __typename?: 'UserQuestionScoreNotificationType';
  uuid?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  frequency: SurveyScoreNotificationFrequencyEnum;
  groupNotification: GroupsSurveyQuestionScoreNotificationType;
};

export type UserResultNotificationsType = {
  __typename?: 'UserResultNotificationsType';
  survey: SurveyNode;
  surveyResponseNotificationTypes?: Maybe<Array<SurveyResponseNotificationTypeEnum>>;
  averageScoreNotifications?: Maybe<Array<UserAverageScoreNotificationType>>;
  questionScoreNotifications?: Maybe<Array<UserQuestionScoreNotificationType>>;
};

export type UserSurveyResponseNotificationInput = {
  surveyUuid: Scalars['String'];
  notificationTypes: Array<SurveyResponseNotificationTypeEnum>;
};

/** An enumeration. */
export enum UsStatesEnum {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FM = 'FM',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY'
}

export type VoluntaryDischargeGroupCountsType = {
  __typename?: 'VoluntaryDischargeGroupCountsType';
  label: Scalars['String'];
  voluntaryCount: Scalars['Int'];
  involuntaryCount: Scalars['Int'];
};

export type WelcomeMessage = {
  __typename?: 'WelcomeMessage';
  header: Scalars['String'];
  subheader: Scalars['String'];
  text: Scalars['String'];
};

export type ActionPlansActionItemFragment = (
  { __typename?: 'ActionItemNode' }
  & Pick<ActionItemNode, 'uuid' | 'created' | 'customStatement' | 'customStatementProductType'>
  & { statement?: Maybe<(
    { __typename?: 'FrozenQuestionType' }
    & Pick<FrozenQuestionType, 'uuid' | 'code' | 'text' | 'focus' | 'residentResultsGroup'>
    & { survey: (
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid' | 'type' | 'productType'>
    ) }
  )>, actionItemTasks: Array<(
    { __typename?: 'ActionItemTaskNode' }
    & ActionPlansActionItemTaskFragment
  )> }
);

export type ActionPlansActionItemTaskFragment = (
  { __typename?: 'ActionItemTaskNode' }
  & Pick<ActionItemTaskNode, 'uuid' | 'task' | 'updated' | 'dueDate' | 'status' | 'owner'>
  & { comments: (
    { __typename?: 'ActionItemTaskCommentNodeConnection' }
    & ActionPlansActionItemTaskCommentNodeConnectionFragment
  ) }
);

export type ActionPlansActionItemTaskCommentFragment = (
  { __typename?: 'ActionItemTaskCommentNode' }
  & Pick<ActionItemTaskCommentNode, 'id' | 'uuid' | 'created' | 'updated' | 'text'>
  & { user: (
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'id' | 'name'>
  ), mentions: Array<(
    { __typename?: 'UserCommentMentionType' }
    & Pick<UserCommentMentionType, 'index'>
    & { user: (
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id' | 'name'>
    ) }
  )> }
);

export type ActionPlansActionItemTaskCommentNodeConnectionFragment = (
  { __typename?: 'ActionItemTaskCommentNodeConnection' }
  & Pick<ActionItemTaskCommentNodeConnection, 'totalCount'>
  & { pageInfo: (
    { __typename?: 'PageInfo' }
    & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
  ), edges: Array<Maybe<(
    { __typename?: 'ActionItemTaskCommentNodeEdge' }
    & { node?: Maybe<(
      { __typename?: 'ActionItemTaskCommentNode' }
      & ActionPlansActionItemTaskCommentFragment
    )> }
  )>> }
);

export type ActionPlansActionPlanFragment = (
  { __typename?: 'ActionPlanNode' }
  & Pick<ActionPlanNode, 'uuid' | 'created'>
  & { surveyTi?: Maybe<(
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'uuid'>
  )>, surveyResidentEngagement?: Maybe<(
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'uuid'>
  )>, actionItems: Array<(
    { __typename?: 'ActionItemNode' }
    & ActionPlansActionItemFragment
  )>, usersWithAccess: Array<(
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'id' | 'name'>
  )> }
);

export type ActionPlansMgmtCountsFragment = (
  { __typename?: 'ActionItemsCountsType' }
  & Pick<ActionItemsCountsType, 'activeStatements' | 'notStarted' | 'inProgress' | 'onHold' | 'complete' | 'incomplete'>
);

export type AnalyticsSurveysQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsSurveysQuery = (
  { __typename?: 'Query' }
  & { surveys: (
    { __typename?: 'SurveyNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'SurveyNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'SurveyNode' }
        & Pick<SurveyNode, 'filterTypeUuids' | 'isDefaultClientBasedResponseRateReport'>
        & { responseRate: (
          { __typename?: 'ResponseRateType' }
          & ResponseRateFragment
        ), defaultBenchmark?: Maybe<(
          { __typename?: 'BenchmarkNode' }
          & BenchmarkFragment
        )> }
        & SurveyCoreFragment
      )> }
    )>> }
  ) }
);

export type BenchmarkFragment = (
  { __typename?: 'BenchmarkNode' }
  & Pick<BenchmarkNode, 'benchmarkType' | 'benchmarkUuid' | 'name' | 'group' | 'lessThanMin' | 'minShowableResults'>
);

export type CultureBriefFragment = (
  { __typename?: 'CultureBriefType' }
  & Pick<CultureBriefType, 'uuid' | 'updated' | 'status' | 'numberOfLocations' | 'yearFounded' | 'organizationName' | 'industrySubVertical' | 'isSubsidiary' | 'organizationType' | 'mainTypeOfCare' | 'city' | 'state' | 'website' | 'facebookUrl' | 'twitterUrl' | 'instagramUrl' | 'linkedinUrl' | 'indeedUrl' | 'description' | 'totalNumberOfEmployees' | 'pastTotalNumberOfEmployees' | 'totalApplicants' | 'totalOpenRoles' | 'recommendationsPercentage' | 'averageNumberOfEmployees' | 'numberOfEmployeesLeft' | 'carbonEmissionsReductionPercentage' | 'numberOfEmployeesWithHealthInsurance' | 'numberOfEmployeesEligibleForHealthInsurance' | 'employeeBenefits' | 'additionalEmployeeBenefits' | 'percentageOfEmployeesWithBigWages' | 'percentageOfImmigrantEmployees' | 'totalBoardComposition' | 'totalBoardWomen' | 'totalBoardMinoritiesRace' | 'totalBoardMinoritiesLgbt' | 'totalCeoReportsComposition' | 'totalCeoReportsWomen' | 'totalCeoReportsMinoritiesRace' | 'totalCeoReportsMinoritiesLgbt' | 'hasDiversityEquity' | 'diversityEquityDescription' | 'reasonsToWorkForCompany' | 'companyMission' | 'careersPageLink' | 'ceoFirstName' | 'ceoLastName' | 'ceoTitle' | 'ceoPhoneNumber' | 'ceoEmail' | 'mainContactFirstName' | 'mainContactLastName' | 'mainContactTitle' | 'mainContactPhoneNumber' | 'mainContactEmail' | 'marketingContactFirstName' | 'marketingContactLastName' | 'marketingContactTitle' | 'marketingContactPhoneNumber' | 'marketingContactEmail'>
);

export type CultureBriefImagesFragment = (
  { __typename?: 'CultureBriefType' }
  & { images: Array<(
    { __typename?: 'CultureBriefImageType' }
    & Pick<CultureBriefImageType, 'uuid' | 'url' | 'imageType' | 'caption'>
  )> }
);

export type FilterTypeFragment = (
  { __typename?: 'FilterTypeType' }
  & Pick<FilterTypeType, 'filterTypeUuid' | 'dtCode' | 'name' | 'namePlural' | 'index' | 'accessToAll' | 'isLocation' | 'insightsProductType' | 'accessControlProductType' | 'surveyProductType'>
  & { filterValues: Array<(
    { __typename?: 'FilterValueType' }
    & FilterValueFragment
  )> }
);

export type FilterValueFragment = (
  { __typename?: 'FilterValueType' }
  & Pick<FilterValueType, 'id' | 'uuid' | 'name'>
);

export type InsightsCultureFragment = (
  { __typename?: 'CultureType' }
  & Pick<CultureType, 'label' | 'score'>
  & { top?: Maybe<Array<Maybe<(
    { __typename?: 'CultureHighlightType' }
    & Pick<CultureHighlightType, 'demographicCode' | 'demographic' | 'valueCode' | 'value' | 'score'>
  )>>> }
);

export type InsightsCultureChangeFragment = (
  { __typename?: 'CultureChangeType' }
  & Pick<CultureChangeType, 'demographicCode' | 'demographic' | 'valueCode' | 'value' | 'change'>
);

export type InsightsGroupedScoreFragment = (
  { __typename?: 'OverallScoreType' }
  & Pick<OverallScoreType, 'positive' | 'benchmarkPositive' | 'inconsistent' | 'benchmarkInconsistent' | 'negative' | 'benchmarkNegative' | 'label' | 'count'>
);

export type GroupedScoresFragment = (
  { __typename?: 'GroupedScoresType' }
  & Pick<GroupedScoresType, 'dataType'>
  & { scores: Array<(
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'label' | 'count' | 'positive'>
  )> }
);

export type InsightsOverallScoreFragment = (
  { __typename?: 'OverallScoreType' }
  & Pick<OverallScoreType, 'positive' | 'benchmarkPositive' | 'inconsistent' | 'benchmarkInconsistent' | 'negative' | 'benchmarkNegative'>
);

export type InsightsStatementScoreFragment = (
  { __typename?: 'StatementScoreType' }
  & Pick<StatementScoreType, 'code' | 'label' | 'focus' | 'residentFocus' | 'count' | 'positive' | 'benchmarkPositive' | 'inconsistent' | 'benchmarkInconsistent' | 'negative' | 'benchmarkNegative' | 'residentResultsGroup'>
);

export type OrganizationFragment = (
  { __typename?: 'OrganizationType' }
  & Pick<OrganizationType, 'uuid' | 'integrationId' | 'name' | 'slug' | 'seniorLiving' | 'atHome' | 'isCcrc' | 'multipleLocations' | 'surveyCodeName' | 'surveyCodeHelpText' | 'residentSurveyCodeName' | 'residentSurveyCodeHelpText' | 'lastTrustIndexDate' | 'participatesInUsNews' | 'isTestAccount' | 'hasKeyFocusAreaCarousel' | 'isGptwEnabled' | 'collectCmsAndNcalNumbers' | 'vicidialDid' | 'combinedContactsTemplateUrl' | 'employeeContactsTemplateUrl'>
  & { benchmarkLevelsOfCare: Array<(
    { __typename?: 'BenchmarkLevelOfCareType' }
    & OrganizationBenchmarkLocFragment
  )>, benchmarkDepartments: Array<(
    { __typename?: 'BenchmarkDepartmentType' }
    & OrganizationBenchmarkDepartmentFragment
  )>, benchmarkShifts: Array<(
    { __typename?: 'BenchmarkShiftType' }
    & OrganizationBenchmarkShiftFragment
  )>, solution?: Maybe<(
    { __typename?: 'SolutionType' }
    & OrganizationSolutionFragment
  )>, residentSolution?: Maybe<(
    { __typename?: 'ResidentSolutionType' }
    & OrganizationResidentSolutionFragment
  )>, languages: Array<(
    { __typename?: 'LanguageType' }
    & OrganizationLanguageFragment
  )>, enabledSolutionFields: Array<(
    { __typename?: 'SolutionFieldAccessType' }
    & Pick<SolutionFieldAccessType, 'field' | 'enabled'>
  )>, activeFilterTypes: Array<(
    { __typename?: 'FilterTypeType' }
    & Pick<FilterTypeType, 'dtCode' | 'name' | 'index'>
  )> }
);

export type OrganizationBenchmarkDepartmentFragment = (
  { __typename?: 'BenchmarkDepartmentType' }
  & Pick<BenchmarkDepartmentType, 'checked' | 'code' | 'name' | 'customName' | 'locs'>
);

export type OrganizationBenchmarkLocFragment = (
  { __typename?: 'BenchmarkLevelOfCareType' }
  & Pick<BenchmarkLevelOfCareType, 'checked' | 'required' | 'disabled' | 'code' | 'name' | 'customName' | 'seniorLiving' | 'atHome' | 'multipleLocations'>
);

export type OrganizationBenchmarkShiftFragment = (
  { __typename?: 'BenchmarkShiftType' }
  & Pick<BenchmarkShiftType, 'checked' | 'code' | 'name' | 'customName'>
);

export type OrganizationFilterValueFragment = (
  { __typename?: 'FilterValueType' }
  & Pick<FilterValueType, 'uuid' | 'name' | 'nickname' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'postalCode' | 'website' | 'phoneNumber' | 'hasEntranceFee' | 'isRental' | 'archived' | 'usNewsLocationId' | 'usNewsLocationName' | 'medicareProviderNumber' | 'ncalIdNumber' | 'hasMissingRequiredValues' | 'logo'>
  & { onlineReviewSites?: Maybe<Array<(
    { __typename?: 'FilterValueReviewSiteType' }
    & Pick<FilterValueReviewSiteType, 'reviewSiteName' | 'externalId'>
  )>> }
);

export type OrganizationLanguageFragment = (
  { __typename?: 'LanguageType' }
  & Pick<LanguageType, 'code' | 'name'>
);

export type OrganizationResidentSolutionFragment = (
  { __typename?: 'ResidentSolutionType' }
  & Pick<ResidentSolutionType, 'adminInvitesOnly' | 'dataAccessLevels' | 'insights' | 'insightsSnapshots' | 'insightsKeyDemographics' | 'insightsStatements' | 'insightsComments' | 'insightsEnhancedComments' | 'insightsComparisons' | 'insightsComparisonsGrid' | 'insightsHeatmap' | 'insightsTimeTrending' | 'insightsTestimonials' | 'showUnavailableTabs' | 'actionPlans' | 'actionPlansCustomStatements' | 'actionPlansManagement' | 'filters' | 'name' | 'statementsExcelDownload' | 'npsDownload' | 'commentsExcelDownload' | 'resultsSummaryReportDownload' | 'benchmarkPastSurveys' | 'benchmarkInternal' | 'onlineReviewSites' | 'hasSurveyResponseNotifications' | 'hasScoreNotifications' | 'maxSurveyNotifications' | 'engagementOverlay' | 'standardServicesOnly' | 'openLinkSurveys'>
);

export type OrganizationServiceAreasFragment = (
  { __typename?: 'ServiceAreaType' }
  & Pick<ServiceAreaType, 'customLabel' | 'label' | 'code' | 'isActive' | 'required' | 'disabled'>
  & { levelsOfCare: Array<(
    { __typename?: 'ServiceLevelOfCareType' }
    & Pick<ServiceLevelOfCareType, 'code' | 'name' | 'required'>
  )>, departments: Array<(
    { __typename?: 'FilterValueType' }
    & Pick<FilterValueType, 'uuid' | 'name'>
  )> }
);

export type OrganizationSolutionFragment = (
  { __typename?: 'SolutionType' }
  & Pick<SolutionType, 'adminInvitesOnly' | 'dataAccessLevels' | 'insights' | 'insightsSnapshots' | 'insightsKeyDemographics' | 'insightsStatements' | 'insightsComments' | 'insightsEnhancedComments' | 'insightsComparisons' | 'insightsComparisonsGrid' | 'insightsHeatmap' | 'insightsTimeTrending' | 'certificationType' | 'certificationReport' | 'engagementReport' | 'showUnavailableTabs' | 'actionPlans' | 'actionPlansCustomStatements' | 'actionPlansManagement' | 'filters' | 'name' | 'benchmarkPastSurveys' | 'benchmarkInternal' | 'statementsExcelDownload' | 'commentsExcelDownload' | 'engagementOverlay' | 'maxSurveyNotifications' | 'npsDownload'>
);

export type ResponseRateFragment = (
  { __typename?: 'ResponseRateType' }
  & Pick<ResponseRateType, 'finished' | 'total' | 'rate'>
);

export type SettingsGroupFragment = (
  { __typename?: 'GroupNode' }
  & Pick<GroupNode, 'uuid' | 'name' | 'isAdmin' | 'accessToSurveyProduct' | 'canAccessDataStructure' | 'canCreateSurveys' | 'canManageActionPlans' | 'canUseActionPlans' | 'canUseAnalytics' | 'canAccessSurveySettings' | 'canAccessGeneralSettings' | 'canManageCertifications' | 'canViewCertificationResults' | 'canInviteUsers' | 'allSurveys' | 'insightsModules' | 'residentInsightsModules' | 'residentAccessToSurveyProduct' | 'residentCanCreateSurveys' | 'residentCanAccessSurveySettings' | 'canAccessLonelinessResults'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'firstName'>
  )>, surveys: Array<(
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'uuid' | 'name'>
  )>, users: Array<(
    { __typename?: 'UserNode' }
    & SettingsGroupUserFragment
  )> }
);

export type SettingsGroupUserFragment = (
  { __typename?: 'UserNode' }
  & Pick<UserNode, 'id' | 'name' | 'email' | 'isAdmin'>
);

export type SettingsUserFragment = (
  { __typename?: 'UserNode' }
  & Pick<UserNode, 'isActive' | 'status' | 'lastActivity'>
  & { filterPermissions: Array<Maybe<(
    { __typename?: 'FilterTypeType' }
    & FilterTypeFragment
  )>>, groups: Array<(
    { __typename?: 'GroupNode' }
    & Pick<GroupNode, 'uuid' | 'name' | 'residentAccessToSurveyProduct' | 'accessToSurveyProduct' | 'isAdmin'>
  )> }
  & UserCoreFragment
);

export type SurveyCoreFragment = (
  { __typename?: 'SurveyNode' }
  & Pick<SurveyNode, 'uuid' | 'status' | 'type' | 'productType' | 'minShowableResults' | 'name' | 'startDate' | 'endDate' | 'hasConfidentialResults' | 'distributionType'>
);

export type EligibleParticipantSurveyConfigFragment = (
  { __typename?: 'EligibleParticipantSurveyConfigType' }
  & Pick<EligibleParticipantSurveyConfigType, 'participantTypeOptions' | 'levelOfCare' | 'enabled'>
);

export type SurveysIntervalNotificationFragment = (
  { __typename?: 'IntervalNotificationType' }
  & Pick<IntervalNotificationType, 'uuid' | 'smsOptional' | 'smsRequired' | 'emailSubject' | 'emailTextOptional1' | 'emailTextRequired' | 'emailTextOptional2' | 'days' | 'sms' | 'personalEmail' | 'workEmail' | 'active' | 'errors' | 'firstSentTimestamp'>
);

export type QuestionFragment = (
  { __typename?: 'QuestionType' }
  & Pick<QuestionType, 'uuid' | 'text' | 'benchmarkCode' | 'isCustom' | 'kind' | 'focus' | 'residentFocus' | 'category' | 'required' | 'index' | 'infoLabel' | 'isStatement' | 'isOpenEnded'>
  & { dependsOn?: Maybe<(
    { __typename?: 'QuestionType' }
    & Pick<QuestionType, 'benchmarkCode'>
  )>, choices: Array<(
    { __typename?: 'QuestionChoiceType' }
    & Pick<QuestionChoiceType, 'uuid' | 'text' | 'value'>
  )>, availableChoices: Array<(
    { __typename?: 'QuestionChoiceType' }
    & Pick<QuestionChoiceType, 'uuid' | 'text'>
  )> }
);

export type SurveysScheduledNotificationFragment = (
  { __typename?: 'ScheduledNotificationType' }
  & Pick<ScheduledNotificationType, 'uuid' | 'smsOptional' | 'smsRequired' | 'emailSubject' | 'emailTextOptional1' | 'emailTextRequired' | 'emailTextOptional2' | 'when' | 'sms' | 'personalEmail' | 'workEmail' | 'active' | 'errors' | 'firstSentTimestamp'>
);

export type SurveysSurveyFragment = (
  { __typename?: 'SurveyNode' }
  & Pick<SurveyNode, 'editable' | 'description' | 'isDefaultClientBasedResponseRateReport' | 'filterTypeUuids' | 'previewUrl' | 'reportPassword' | 'distributionType' | 'participantDataSource'>
  & { associatedSurvey?: Maybe<(
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'uuid' | 'status' | 'name' | 'startDate' | 'endDate' | 'type'>
  )>, filterTypeSettings: Array<(
    { __typename?: 'SurveyFilterTypeSettingType' }
    & Pick<SurveyFilterTypeSettingType, 'dtCode' | 'reportingType'>
  )>, eligibleParticipantSurveyConfigs: Array<(
    { __typename?: 'EligibleParticipantSurveyConfigType' }
    & EligibleParticipantSurveyConfigFragment
  )> }
  & SurveyCoreFragment
);

export type UserCoreFragment = (
  { __typename?: 'UserNode' }
  & Pick<UserNode, 'id' | 'isAdmin' | 'firstName' | 'lastName' | 'name' | 'email' | 'title' | 'accessToSurveyProduct' | 'canCreateSurveys' | 'residentAccessToSurveyProduct' | 'residentCanCreateSurveys' | 'canUseAnalytics' | 'canUploadCombinedContacts' | 'canUploadEmployeeContacts'>
);

export type ActionPlansAddActionPlanCustomStatementMutationVariables = Exact<{
  customStatement: Scalars['String'];
  customStatementProductType?: Maybe<SurveyProductTypeEnum>;
  tasks: Array<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
}>;


export type ActionPlansAddActionPlanCustomStatementMutation = (
  { __typename?: 'Mutation' }
  & { addActionPlanCustomStatement?: Maybe<(
    { __typename?: 'AddActionPlanCustomStatement' }
    & Pick<AddActionPlanCustomStatement, 'errors'>
    & { actionPlan?: Maybe<(
      { __typename?: 'ActionPlanNode' }
      & ActionPlansActionPlanFragment
    )> }
  )> }
);

export type ActionPlansAddActionPlanStatementsMutationVariables = Exact<{
  statementUuids: Array<Scalars['String']>;
  includeDefaultActionItems: Scalars['Boolean'];
  userUuid?: Maybe<Scalars['String']>;
}>;


export type ActionPlansAddActionPlanStatementsMutation = (
  { __typename?: 'Mutation' }
  & { addActionPlanStatements?: Maybe<(
    { __typename?: 'AddActionPlanStatements' }
    & Pick<AddActionPlanStatements, 'errors'>
    & { actionPlan?: Maybe<(
      { __typename?: 'ActionPlanNode' }
      & ActionPlansActionPlanFragment
    )> }
  )> }
);

export type ActionPlansDeleteActionItemMutationVariables = Exact<{
  actionItemUuid: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
}>;


export type ActionPlansDeleteActionItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteActionItem?: Maybe<(
    { __typename?: 'DeleteActionItem' }
    & Pick<DeleteActionItem, 'errors'>
    & { actionPlan?: Maybe<(
      { __typename?: 'ActionPlanNode' }
      & ActionPlansActionPlanFragment
    )> }
  )> }
);

export type ActionPlansDeleteActionItemTaskMutationVariables = Exact<{
  taskId: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
}>;


export type ActionPlansDeleteActionItemTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteActionItemTask?: Maybe<(
    { __typename?: 'DeleteActionItemTask' }
    & { actionPlan?: Maybe<(
      { __typename?: 'ActionPlanNode' }
      & ActionPlansActionPlanFragment
    )> }
  )> }
);

export type ActionPlansDeleteActionItemTaskCommentMutationVariables = Exact<{
  actionItemTaskCommentUuid: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
}>;


export type ActionPlansDeleteActionItemTaskCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteActionItemTaskComment?: Maybe<(
    { __typename?: 'DeleteActionItemTaskComment' }
    & { actionItemTask?: Maybe<(
      { __typename?: 'ActionItemTaskNode' }
      & ActionPlansActionItemTaskFragment
    )> }
  )> }
);

export type ActionPlansUpdateActionItemTaskMutationVariables = Exact<{
  actionItemUuid: Scalars['String'];
  taskUuid?: Maybe<Scalars['String']>;
  task: ActionItemTaskInput;
  userUuid?: Maybe<Scalars['String']>;
}>;


export type ActionPlansUpdateActionItemTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateActionItemTask?: Maybe<(
    { __typename?: 'UpdateActionItemTask' }
    & Pick<UpdateActionItemTask, 'errors'>
    & { actionPlan?: Maybe<(
      { __typename?: 'ActionPlanNode' }
      & ActionPlansActionPlanFragment
    )> }
  )> }
);

export type ActionPlansUpdateActionItemTaskCommentMutationVariables = Exact<{
  actionItemTaskUuid: Scalars['String'];
  commentInput: ActionItemTaskCommentInput;
  userUuid?: Maybe<Scalars['String']>;
  userTimezone?: Maybe<Scalars['String']>;
}>;


export type ActionPlansUpdateActionItemTaskCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateActionItemTaskComment?: Maybe<(
    { __typename?: 'UpdateActionItemTaskComment' }
    & { actionItemTask?: Maybe<(
      { __typename?: 'ActionItemTaskNode' }
      & ActionPlansActionItemTaskFragment
    )> }
  )> }
);

export type ActionPlanUpdateActionPlanSurveyMutationVariables = Exact<{
  actionPlanInput: ActionPlanSurveyInput;
  userUuid?: Maybe<Scalars['String']>;
}>;


export type ActionPlanUpdateActionPlanSurveyMutation = (
  { __typename?: 'Mutation' }
  & { updateActionPlanSurvey?: Maybe<(
    { __typename?: 'UpdateActionPlanSurvey' }
    & Pick<UpdateActionPlanSurvey, 'errors'>
    & { actionPlan?: Maybe<(
      { __typename?: 'ActionPlanNode' }
      & ActionPlansActionPlanFragment
    )> }
  )> }
);

export type DeleteCultureBriefImagesMutationVariables = Exact<{
  cultureBriefUuid: Scalars['String'];
  uuids: Array<Scalars['String']>;
}>;


export type DeleteCultureBriefImagesMutation = (
  { __typename?: 'Mutation' }
  & { deleteCultureBriefImages?: Maybe<(
    { __typename?: 'DeleteCultureBriefImages' }
    & Pick<DeleteCultureBriefImages, 'errors'>
  )> }
);

export type ShareMarketingToolkitMutationVariables = Exact<{
  email: Scalars['String'];
  includeBadgeAssets: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  locationUuid?: Maybe<Scalars['String']>;
}>;


export type ShareMarketingToolkitMutation = (
  { __typename?: 'Mutation' }
  & { shareMarketingToolkit?: Maybe<(
    { __typename?: 'ShareMarketingToolkit' }
    & Pick<ShareMarketingToolkit, 'errors'>
  )> }
);

export type UpdateCultureBriefMutationVariables = Exact<{
  input: UpdateCultureBriefInput;
}>;


export type UpdateCultureBriefMutation = (
  { __typename?: 'Mutation' }
  & { updateCultureBrief?: Maybe<(
    { __typename?: 'UpdateCultureBriefPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Errors' }
      & Pick<Errors, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateCultureBriefImagesMutationVariables = Exact<{
  cultureBriefUuid: Scalars['String'];
  imagesData: Array<CultureBriefImageInput>;
}>;


export type UpdateCultureBriefImagesMutation = (
  { __typename?: 'Mutation' }
  & { updateCultureBriefImages?: Maybe<(
    { __typename?: 'UpdateCultureBriefImages' }
    & { cultureBrief?: Maybe<(
      { __typename?: 'CultureBriefType' }
      & Pick<CultureBriefType, 'uuid'>
      & CultureBriefImagesFragment
    )> }
  )> }
);

export type AddCultureBriefImagesMutationVariables = Exact<{
  cultureBriefUuid: Scalars['String'];
  imagesData: Array<CultureBriefImageInput>;
}>;


export type AddCultureBriefImagesMutation = (
  { __typename?: 'Mutation' }
  & { addCultureBriefImages?: Maybe<(
    { __typename?: 'AddCultureBriefImages' }
    & { cultureBrief?: Maybe<(
      { __typename?: 'CultureBriefType' }
      & Pick<CultureBriefType, 'uuid'>
      & CultureBriefImagesFragment
    )> }
  )> }
);

export type InsightsOverlayEmailSupportMutationVariables = Exact<{ [key: string]: never; }>;


export type InsightsOverlayEmailSupportMutation = (
  { __typename?: 'Mutation' }
  & { emailSupport?: Maybe<(
    { __typename?: 'EmailSupport' }
    & Pick<EmailSupport, 'errors'>
  )> }
);

export type InsightsUpdateTakeActionStepsMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  step: TakeActionStepsEnum;
}>;


export type InsightsUpdateTakeActionStepsMutation = (
  { __typename?: 'Mutation' }
  & { updateTakeActionSteps?: Maybe<(
    { __typename?: 'UpdateTakeActionStepsMutation' }
    & Pick<UpdateTakeActionStepsMutation, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid' | 'takeActionCheckedSteps'>
    )> }
  )> }
);

export type OrganizationAddContactsUploadMutationVariables = Exact<{
  objectPath: Scalars['String'];
  importType: ImportContactTypesEnum;
}>;


export type OrganizationAddContactsUploadMutation = (
  { __typename?: 'Mutation' }
  & { addContactsUpload?: Maybe<(
    { __typename?: 'AddContactsUpload' }
    & Pick<AddContactsUpload, 'errors'>
  )> }
);

export type OrganizationUpdateFilterValueMutationVariables = Exact<{
  filterTypeUuid: Scalars['String'];
  filterValue: FilterValueInput;
}>;


export type OrganizationUpdateFilterValueMutation = (
  { __typename?: 'Mutation' }
  & { updateFilterValue?: Maybe<(
    { __typename?: 'UpdateFilterValue' }
    & Pick<UpdateFilterValue, 'errors'>
    & { filterValue?: Maybe<(
      { __typename?: 'FilterValueType' }
      & OrganizationFilterValueFragment
    )> }
  )> }
);

export type OrganizationUpdateFilterValueArchivedMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type OrganizationUpdateFilterValueArchivedMutation = (
  { __typename?: 'Mutation' }
  & { updateFilterValueArchived?: Maybe<(
    { __typename?: 'UpdateFilterValueArchived' }
    & Pick<UpdateFilterValueArchived, 'errors'>
    & { filterValue?: Maybe<(
      { __typename?: 'FilterValueType' }
      & OrganizationFilterValueFragment
    )> }
  )> }
);

export type SettingsBulkDeleteUsersMutationVariables = Exact<{
  userUuids: Array<Scalars['String']>;
}>;


export type SettingsBulkDeleteUsersMutation = (
  { __typename?: 'Mutation' }
  & { bulkDeleteUsers?: Maybe<(
    { __typename?: 'BulkDeleteUsers' }
    & Pick<BulkDeleteUsers, 'errors'>
  )> }
);

export type SettingsBulkResetPasswordMutationVariables = Exact<{
  uuidList?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type SettingsBulkResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { bulkResetPassword?: Maybe<(
    { __typename?: 'BulkResetPassword' }
    & Pick<BulkResetPassword, 'errors'>
    & { users?: Maybe<Array<Maybe<(
      { __typename?: 'UserNode' }
      & UserCoreFragment
    )>>> }
  )> }
);

export type SettingsBulkSetIsActiveMutationVariables = Exact<{
  isActive: Scalars['Boolean'];
  uuidList: Array<Maybe<Scalars['String']>>;
}>;


export type SettingsBulkSetIsActiveMutation = (
  { __typename?: 'Mutation' }
  & { bulkSetIsActive?: Maybe<(
    { __typename?: 'BulkSetIsActive' }
    & Pick<BulkSetIsActive, 'errors'>
    & { users?: Maybe<Array<Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id' | 'status'>
    )>>> }
  )> }
);

export type SettingsBulkUpdateUserPermissionsMutationVariables = Exact<{
  uuidList: Array<Maybe<Scalars['String']>>;
  filterTypes: Array<FilterTypePermissionInput>;
}>;


export type SettingsBulkUpdateUserPermissionsMutation = (
  { __typename?: 'Mutation' }
  & { bulkUpdateUserPermissions?: Maybe<(
    { __typename?: 'BulkUpdateUserPermissions' }
    & Pick<BulkUpdateUserPermissions, 'errors'>
    & { users?: Maybe<Array<Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id'>
      & { filterPermissions: Array<Maybe<(
        { __typename?: 'FilterTypeType' }
        & FilterTypeFragment
      )>> }
    )>>> }
  )> }
);

export type SettingsToggleIsActiveMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type SettingsToggleIsActiveMutation = (
  { __typename?: 'Mutation' }
  & { toggleIsActive?: Maybe<(
    { __typename?: 'ToggleIsActive' }
    & Pick<ToggleIsActive, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id' | 'status' | 'isActive'>
    )> }
  )> }
);

export type SettingsUpdateGroupMutationVariables = Exact<{
  group: GroupInput;
}>;


export type SettingsUpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup?: Maybe<(
    { __typename?: 'UpdateGroup' }
    & Pick<UpdateGroup, 'errors'>
    & { group?: Maybe<(
      { __typename?: 'GroupNode' }
      & SettingsGroupFragment
    )> }
  )> }
);

export type SettingsUpdateOrganizationMutationVariables = Exact<{
  organization: OrganizationInput;
}>;


export type SettingsUpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganization?: Maybe<(
    { __typename?: 'UpdateOrganization' }
    & Pick<UpdateOrganization, 'errors'>
    & { organization?: Maybe<(
      { __typename?: 'OrganizationType' }
      & OrganizationFragment
    )> }
  )> }
);

export type SettingsUpdateOrganizationServiceAreasMutationVariables = Exact<{
  serviceAreas: Array<ServiceAreaInputType>;
}>;


export type SettingsUpdateOrganizationServiceAreasMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationServiceAreas?: Maybe<(
    { __typename?: 'UpdateOrganizationServiceAreas' }
    & Pick<UpdateOrganizationServiceAreas, 'errors'>
    & { organization?: Maybe<(
      { __typename?: 'OrganizationType' }
      & Pick<OrganizationType, 'uuid'>
      & { serviceAreas: Array<(
        { __typename?: 'ServiceAreaType' }
        & OrganizationServiceAreasFragment
      )> }
    )> }
  )> }
);

export type SettingsUpdateOrganizationSurveyPortalMutationVariables = Exact<{
  organization: OrganizationSurveyPortalInput;
}>;


export type SettingsUpdateOrganizationSurveyPortalMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationSurveyPortal?: Maybe<(
    { __typename?: 'UpdateOrganizationSurveyPortal' }
    & Pick<UpdateOrganizationSurveyPortal, 'errors'>
    & { organization?: Maybe<(
      { __typename?: 'OrganizationType' }
      & OrganizationFragment
    )> }
  )> }
);

export type SettingsUpdateUserMutationVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterTypes: Array<FilterTypePermissionInput>;
}>;


export type SettingsUpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUser' }
    & Pick<UpdateUser, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & SettingsUserFragment
    )> }
  )> }
);

export type ShareScreenshotMutationVariables = Exact<{
  email: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  currentUrl: Scalars['String'];
}>;


export type ShareScreenshotMutation = (
  { __typename?: 'Mutation' }
  & { shareScreenshot?: Maybe<(
    { __typename?: 'ShareScreenshot' }
    & Pick<ShareScreenshot, 'errors'>
  )> }
);

export type SurveysAddParticipantsUploadMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  objectPath: Scalars['String'];
  importType: ImportTypesEnum;
}>;


export type SurveysAddParticipantsUploadMutation = (
  { __typename?: 'Mutation' }
  & { addParticipantsUpload?: Maybe<(
    { __typename?: 'AddParticipantsUpload' }
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { clientsUpload?: Maybe<(
        { __typename?: 'FileImportType' }
        & Pick<FileImportType, 'fileName'>
      )>, participantsUpload?: Maybe<(
        { __typename?: 'FileImportType' }
        & Pick<FileImportType, 'fileName'>
      )> }
    )> }
  )> }
);

export type SurveysBulkSetQuestionMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  selectedQuestionUuids: Array<Scalars['String']>;
  unselectedQuestionUuids: Array<Scalars['String']>;
}>;


export type SurveysBulkSetQuestionMutation = (
  { __typename?: 'Mutation' }
  & { bulkSetSurveyQuestion?: Maybe<(
    { __typename?: 'BulkSetSurveyQuestion' }
    & Pick<BulkSetSurveyQuestion, 'errors'>
    & { questions?: Maybe<Array<(
      { __typename?: 'QuestionType' }
      & Pick<QuestionType, 'uuid' | 'text'>
    )>> }
  )> }
);

export type SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutationVariables = Exact<{
  notificationUuid: Scalars['String'];
}>;


export type SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroupsSurveyQuestionScoreNotification?: Maybe<(
    { __typename?: 'DeleteGroupsQuestionScoreNotification' }
    & Pick<DeleteGroupsQuestionScoreNotification, 'errors'>
  )> }
);

export type SurveysDeleteIntervalNotificationMutationVariables = Exact<{
  notificationUuid: Scalars['String'];
}>;


export type SurveysDeleteIntervalNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteIntervalNotification?: Maybe<(
    { __typename?: 'DeleteIntervalNotification' }
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { intervalNotifications: Array<(
        { __typename?: 'IntervalNotificationType' }
        & SurveysIntervalNotificationFragment
      )> }
    )> }
  )> }
);

export type SurveysDeleteParticipantMutationVariables = Exact<{
  participantUuid: Scalars['String'];
}>;


export type SurveysDeleteParticipantMutation = (
  { __typename?: 'Mutation' }
  & { deleteParticipant?: Maybe<(
    { __typename?: 'DeleteParticipant' }
    & Pick<DeleteParticipant, 'errors'>
  )> }
);

export type SurveysDeleteQuestionMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  questionUuid: Scalars['String'];
}>;


export type SurveysDeleteQuestionMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuestion?: Maybe<(
    { __typename?: 'DeleteQuestion' }
    & Pick<DeleteQuestion, 'errors'>
  )> }
);

export type SurveysDeleteScheduledNotificationMutationVariables = Exact<{
  notificationUuid: Scalars['String'];
}>;


export type SurveysDeleteScheduledNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteScheduledNotification?: Maybe<(
    { __typename?: 'DeleteScheduledNotification' }
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { scheduledNotifications: Array<(
        { __typename?: 'ScheduledNotificationType' }
        & SurveysScheduledNotificationFragment
      )> }
    )> }
  )> }
);

export type SurveysDeleteSurveyMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type SurveysDeleteSurveyMutation = (
  { __typename?: 'Mutation' }
  & { deleteSurvey?: Maybe<(
    { __typename?: 'DeleteSurvey' }
    & Pick<DeleteSurvey, 'errors'>
  )> }
);

export type SurveysGenerateUploadUrlMutationVariables = Exact<{
  fileName: Scalars['String'];
  contentType: Scalars['String'];
}>;


export type SurveysGenerateUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { generateUploadUrl?: Maybe<(
    { __typename?: 'GenerateSignedUploadUrl' }
    & { signedUrl?: Maybe<(
      { __typename?: 'SignedUrl' }
      & Pick<SignedUrl, 'url' | 'path'>
    )> }
  )> }
);

export type SurveysIngestParticipantsUploadMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  importType: ImportTypesEnum;
}>;


export type SurveysIngestParticipantsUploadMutation = (
  { __typename?: 'Mutation' }
  & { ingestParticipantsUpload?: Maybe<(
    { __typename?: 'IngestParticipantsUpload' }
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
    )> }
  )> }
);

export type SurveysRemoveParticipantsUploadMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  importType: ImportTypesEnum;
}>;


export type SurveysRemoveParticipantsUploadMutation = (
  { __typename?: 'Mutation' }
  & { removeParticipantsUpload?: Maybe<(
    { __typename?: 'RemoveParticipantsUpload' }
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { participantsUpload?: Maybe<(
        { __typename?: 'FileImportType' }
        & Pick<FileImportType, 'fileName' | 'updated'>
        & { errors: Array<(
          { __typename?: 'ImportErrorType' }
          & Pick<ImportErrorType, 'code' | 'dtCode' | 'dtName' | 'cell'>
        )>, summary: (
          { __typename?: 'ImportSummaryType' }
          & Pick<ImportSummaryType, 'processed' | 'deleted' | 'added' | 'updated'>
          & { filters?: Maybe<Array<Maybe<(
            { __typename?: 'FilterValueSummaryType' }
            & Pick<FilterValueSummaryType, 'filterTypeName' | 'filterValueNames'>
          )>>> }
        ) }
      )>, clientsUpload?: Maybe<(
        { __typename?: 'FileImportType' }
        & Pick<FileImportType, 'fileName'>
      )> }
    )> }
  )> }
);

export type SurveysResetIntervalNotificationMutationVariables = Exact<{
  notificationUuid: Scalars['String'];
  emailOrSms: Scalars['String'];
}>;


export type SurveysResetIntervalNotificationMutation = (
  { __typename?: 'Mutation' }
  & { resetIntervalNotification?: Maybe<(
    { __typename?: 'ResetIntervalNotification' }
    & { notification: (
      { __typename?: 'IntervalNotificationType' }
      & SurveysIntervalNotificationFragment
    ) }
  )> }
);

export type SurveysResetScheduledNotificationMutationVariables = Exact<{
  notificationUuid: Scalars['String'];
  emailOrSms: Scalars['String'];
}>;


export type SurveysResetScheduledNotificationMutation = (
  { __typename?: 'Mutation' }
  & { resetScheduledNotification?: Maybe<(
    { __typename?: 'ResetScheduledNotification' }
    & { notification: (
      { __typename?: 'ScheduledNotificationType' }
      & SurveysScheduledNotificationFragment
    ) }
  )> }
);

export type SurveysSendIntervalTestEmailMutationVariables = Exact<{
  notificationUuid: Scalars['String'];
  emailAddress: Scalars['String'];
}>;


export type SurveysSendIntervalTestEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendIntervalTestEmail?: Maybe<(
    { __typename?: 'SendIntervalTestEmail' }
    & Pick<SendIntervalTestEmail, 'errors'>
  )> }
);

export type SurveysSendIntervalTestSmsMutationVariables = Exact<{
  notificationUuid: Scalars['String'];
  smsNumber: Scalars['String'];
}>;


export type SurveysSendIntervalTestSmsMutation = (
  { __typename?: 'Mutation' }
  & { sendIntervalTestSms?: Maybe<(
    { __typename?: 'SendIntervalTestSMS' }
    & Pick<SendIntervalTestSms, 'errors'>
  )> }
);

export type SurveysSendScheduledTestEmailMutationVariables = Exact<{
  notificationUuid: Scalars['String'];
  emailAddress: Scalars['String'];
}>;


export type SurveysSendScheduledTestEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendScheduledTestEmail?: Maybe<(
    { __typename?: 'SendScheduledTestEmail' }
    & Pick<SendScheduledTestEmail, 'errors'>
  )> }
);

export type SurveysSendScheduledTestSmsMutationVariables = Exact<{
  notificationUuid: Scalars['String'];
  smsNumber: Scalars['String'];
}>;


export type SurveysSendScheduledTestSmsMutation = (
  { __typename?: 'Mutation' }
  & { sendScheduledTestSms?: Maybe<(
    { __typename?: 'SendScheduledTestSMS' }
    & Pick<SendScheduledTestSms, 'errors'>
  )> }
);

export type SurveysUpdateCustomQuestionMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  question: QuestionInput;
}>;


export type SurveysUpdateCustomQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomQuestion?: Maybe<(
    { __typename?: 'UpdateCustomQuestion' }
    & Pick<UpdateCustomQuestion, 'errors'>
    & { question?: Maybe<(
      { __typename?: 'QuestionType' }
      & QuestionFragment
    )> }
  )> }
);

export type SurveysUpdateEligibleParticipantSurveyConfigsMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  configs: Array<EligibleParticipantSurveyConfigInput>;
}>;


export type SurveysUpdateEligibleParticipantSurveyConfigsMutation = (
  { __typename?: 'Mutation' }
  & { updateEligibleParticipantSurveyConfigs?: Maybe<(
    { __typename?: 'UpdateEligibleParticipantSurveyConfigs' }
    & Pick<UpdateEligibleParticipantSurveyConfigs, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { eligibleParticipantSurveyConfigs: Array<(
        { __typename?: 'EligibleParticipantSurveyConfigType' }
        & EligibleParticipantSurveyConfigFragment
      )> }
    )> }
  )> }
);

export type SurveysUpdateGroupsAccessToSurveyMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  groupsUuidsWithSurveyAccess: Array<Scalars['String']>;
}>;


export type SurveysUpdateGroupsAccessToSurveyMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupsAccessToSurvey?: Maybe<(
    { __typename?: 'UpdateGroupsAccessToSurvey' }
    & Pick<UpdateGroupsAccessToSurvey, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { groupsWithSurveyAccess: Array<(
        { __typename?: 'GroupNode' }
        & Pick<GroupNode, 'uuid' | 'name'>
      )> }
    )> }
  )> }
);

export type SurveysUpdateGroupsAverageScoreNotificationsMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  groupsNotificationsInput: Array<GroupSurveyAverageScoreNotificationInput>;
}>;


export type SurveysUpdateGroupsAverageScoreNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupsSurveyAverageScoreNotifications?: Maybe<(
    { __typename?: 'UpdateGroupsAverageScoreNotifications' }
    & Pick<UpdateGroupsAverageScoreNotifications, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { groupsAverageScoreNotifications: Array<(
        { __typename?: 'GroupsSurveyAverageScoreNotificationType' }
        & Pick<GroupsSurveyAverageScoreNotificationType, 'enabled' | 'frequency'>
        & { groups: Array<(
          { __typename?: 'GroupNode' }
          & Pick<GroupNode, 'uuid' | 'name' | 'allSurveys'>
        )> }
      )> }
    )> }
  )> }
);

export type SurveysUpdateGroupsQuestionScoreNotificationsMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  groupsNotificationsInput: Array<GroupSurveyQuestionScoreNotificationInput>;
}>;


export type SurveysUpdateGroupsQuestionScoreNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupsSurveyQuestionScoreNotifications?: Maybe<(
    { __typename?: 'UpdateGroupsQuestionScoreNotifications' }
    & Pick<UpdateGroupsQuestionScoreNotifications, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { groupsQuestionScoreNotifications: Array<(
        { __typename?: 'GroupsSurveyQuestionScoreNotificationType' }
        & Pick<GroupsSurveyQuestionScoreNotificationType, 'uuid' | 'notificationName' | 'enabled' | 'scoreType' | 'frequency' | 'minScore' | 'maxScore'>
        & { statement: (
          { __typename?: 'QuestionType' }
          & Pick<QuestionType, 'uuid' | 'text'>
        ), openEndedQuestion?: Maybe<(
          { __typename?: 'QuestionType' }
          & Pick<QuestionType, 'uuid' | 'text'>
        )>, groups: Array<(
          { __typename?: 'GroupNode' }
          & Pick<GroupNode, 'uuid' | 'name' | 'allSurveys'>
        )> }
      )> }
    )> }
  )> }
);

export type SurveysUpdateGroupsSurveyResponseNotificationsMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  groupsNotificationsInput: Array<GroupSurveyResponseNotificationInput>;
}>;


export type SurveysUpdateGroupsSurveyResponseNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupsSurveyResponseNotifications?: Maybe<(
    { __typename?: 'UpdateGroupsSurveyResponseNotifications' }
    & Pick<UpdateGroupsSurveyResponseNotifications, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { groupsSurveyResponseNotifications: Array<(
        { __typename?: 'GroupsSurveyResponseNotificationType' }
        & Pick<GroupsSurveyResponseNotificationType, 'enabled' | 'notificationType'>
        & { groups: Array<(
          { __typename?: 'GroupNode' }
          & Pick<GroupNode, 'uuid' | 'name' | 'allSurveys'>
        )> }
      )> }
    )> }
  )> }
);

export type SurveysUpdateIntervalNotificationMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  notificationInput: IntervalNotificationInput;
}>;


export type SurveysUpdateIntervalNotificationMutation = (
  { __typename?: 'Mutation' }
  & { updateIntervalNotification?: Maybe<(
    { __typename?: 'UpdateIntervalNotification' }
    & Pick<UpdateIntervalNotification, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { intervalNotifications: Array<(
        { __typename?: 'IntervalNotificationType' }
        & SurveysIntervalNotificationFragment
      )> }
    )> }
  )> }
);

export type SurveysUpdateParticipantMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  participantUuid?: Maybe<Scalars['String']>;
  participant: ParticipantInput;
}>;


export type SurveysUpdateParticipantMutation = (
  { __typename?: 'Mutation' }
  & { updateParticipant?: Maybe<(
    { __typename?: 'UpdateParticipant' }
    & Pick<UpdateParticipant, 'errors'>
    & { participant?: Maybe<(
      { __typename?: 'ParticipantType' }
      & Pick<ParticipantType, 'uuid' | 'firstName' | 'surveyCode' | 'personalEmail' | 'workEmail' | 'mobilePhone'>
    )> }
  )> }
);

export type SurveysUpdatePersonalInfoQuestionMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  questionInput: PersonalInfoQuestionInput;
}>;


export type SurveysUpdatePersonalInfoQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updatePersonalInfoQuestion?: Maybe<(
    { __typename?: 'UpdatePersonalInfoQuestion' }
    & Pick<UpdatePersonalInfoQuestion, 'errors'>
    & { question?: Maybe<(
      { __typename?: 'QuestionType' }
      & QuestionFragment
    )> }
  )> }
);

export type SurveysUpdateReportMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  password?: Maybe<Scalars['String']>;
}>;


export type SurveysUpdateReportMutation = (
  { __typename?: 'Mutation' }
  & { updateReport?: Maybe<(
    { __typename?: 'UpdateReport' }
    & Pick<UpdateReport, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid' | 'reportPassword'>
    )> }
  )> }
);

export type SurveysUpdateScheduledNotificationMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  notificationInput: ScheduledNotificationInput;
}>;


export type SurveysUpdateScheduledNotificationMutation = (
  { __typename?: 'Mutation' }
  & { updateScheduledNotification?: Maybe<(
    { __typename?: 'UpdateScheduledNotification' }
    & Pick<UpdateScheduledNotification, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid'>
      & { scheduledNotifications: Array<(
        { __typename?: 'ScheduledNotificationType' }
        & SurveysScheduledNotificationFragment
      )> }
    )> }
  )> }
);

export type SurveysUpdateSurveyMutationVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: SurveyTypeEnum;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  languages?: Maybe<Array<Scalars['String']>>;
  associatedSurveyUuid?: Maybe<Scalars['String']>;
  distributionType?: Maybe<SurveyDistributionTypeEnum>;
  participantDataSource?: Maybe<SurveyParticipantDataSourceEnum>;
  hasConfidentialResults?: Maybe<Scalars['Boolean']>;
  filterTypeSettings?: Maybe<Array<SurveyFilterTypeSettingInput>>;
}>;


export type SurveysUpdateSurveyMutation = (
  { __typename?: 'Mutation' }
  & { updateSurvey?: Maybe<(
    { __typename?: 'UpdateSurvey' }
    & Pick<UpdateSurvey, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'translationsRequired'>
      & { languages: Array<(
        { __typename?: 'LanguageType' }
        & OrganizationLanguageFragment
      )> }
      & SurveysSurveyFragment
    )> }
  )> }
);

export type SurveysUpdateSurveyStatusMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  status: SurveyStatusEnum;
}>;


export type SurveysUpdateSurveyStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveyStatus?: Maybe<(
    { __typename?: 'UpdateSurveyStatus' }
    & Pick<UpdateSurveyStatus, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid' | 'status'>
    )> }
  )> }
);

export type SurveysUpdateTranslationsMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  translations?: Maybe<Array<Maybe<TranslationInput>>>;
}>;


export type SurveysUpdateTranslationsMutation = (
  { __typename?: 'Mutation' }
  & { updateTranslations?: Maybe<(
    { __typename?: 'UpdateTranslations' }
    & Pick<UpdateTranslations, 'errors'>
  )> }
);

export type SurveysUpdateWelcomeMessagesMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
  welcomeMessages: Array<SurveyTextInput>;
}>;


export type SurveysUpdateWelcomeMessagesMutation = (
  { __typename?: 'Mutation' }
  & { updateWelcomeMessages?: Maybe<(
    { __typename?: 'UpdateWelcomeMessages' }
    & Pick<UpdateWelcomeMessages, 'errors'>
    & { survey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & { welcomeMessages: Array<(
        { __typename?: 'SurveyTextType' }
        & Pick<SurveyTextType, 'uuid' | 'key' | 'text'>
      )> }
    )> }
  )> }
);

export type UserToggleFavoriteSurveyMutationVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type UserToggleFavoriteSurveyMutation = (
  { __typename?: 'Mutation' }
  & { toggleFavoriteSurvey?: Maybe<(
    { __typename?: 'ToggleFavoriteSurvey' }
    & Pick<ToggleFavoriteSurvey, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id' | 'favoriteSurveyIds'>
    )> }
  )> }
);

export type UserToggleFollowActionPlanMutationVariables = Exact<{
  planUuid: Scalars['String'];
}>;


export type UserToggleFollowActionPlanMutation = (
  { __typename?: 'Mutation' }
  & { toggleFollowActionPlan?: Maybe<(
    { __typename?: 'ToggleFollowActionPlan' }
    & Pick<ToggleFollowActionPlan, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id' | 'followedActionPlanIds'>
    )> }
  )> }
);

export type UserUpdateAverageScoreNotificationsMutationVariables = Exact<{
  userNotificationsInput: Array<UserAverageScoreNotificationsInput>;
}>;


export type UserUpdateAverageScoreNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAverageScoreNotifications?: Maybe<(
    { __typename?: 'UpdateUserAverageScoreNotifications' }
    & Pick<UpdateUserAverageScoreNotifications, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'uuid'>
      & { userResultNotifications: Array<(
        { __typename?: 'UserResultNotificationsType' }
        & { averageScoreNotifications?: Maybe<Array<(
          { __typename?: 'UserAverageScoreNotificationType' }
          & Pick<UserAverageScoreNotificationType, 'enabled' | 'scoreType' | 'frequency'>
        )>> }
      )> }
    )> }
  )> }
);

export type UserUpdateQuestionScoreNotificationsMutationVariables = Exact<{
  userNotificationsInput: Array<UserQuestionScoreNotificationsInput>;
}>;


export type UserUpdateQuestionScoreNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserQuestionScoreNotifications?: Maybe<(
    { __typename?: 'UpdateUserQuestionScoreNotifications' }
    & Pick<UpdateUserQuestionScoreNotifications, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'uuid'>
      & { userResultNotifications: Array<(
        { __typename?: 'UserResultNotificationsType' }
        & { questionScoreNotifications?: Maybe<Array<(
          { __typename?: 'UserQuestionScoreNotificationType' }
          & Pick<UserQuestionScoreNotificationType, 'uuid' | 'enabled' | 'frequency'>
        )>> }
      )> }
    )> }
  )> }
);

export type UserUpdateUserAppMessageMutationVariables = Exact<{
  kind: Scalars['String'];
  contentObjects: Array<ContentObjectInput>;
}>;


export type UserUpdateUserAppMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAppMessage?: Maybe<(
    { __typename?: 'UpdateUserAppMessage' }
    & Pick<UpdateUserAppMessage, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id'>
      & { appMessages: Array<(
        { __typename?: 'UserAppMessage' }
        & Pick<UserAppMessage, 'kind' | 'seen'>
        & { contentObjects: Array<(
          { __typename?: 'ContentObject' }
          & Pick<ContentObject, 'uuid' | 'contentType'>
        )> }
      )> }
    )> }
  )> }
);

export type UserUpdateUserHasSurveyResponseNotificationsMutationVariables = Exact<{
  hasSurveyResponseNotifications: Scalars['Boolean'];
}>;


export type UserUpdateUserHasSurveyResponseNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserHasSurveyResponseNotifications?: Maybe<(
    { __typename?: 'UpdateUserHasSurveyResponseNotifications' }
    & Pick<UpdateUserHasSurveyResponseNotifications, 'errors'>
  )> }
);

export type UserUpdateUserSurveyResponseNotificationsMutationVariables = Exact<{
  userNotificationsInput: Array<UserSurveyResponseNotificationInput>;
}>;


export type UserUpdateUserSurveyResponseNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSurveyResponseNotifications?: Maybe<(
    { __typename?: 'UpdateUserSurveyResponseNotifications' }
    & Pick<UpdateUserSurveyResponseNotifications, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'uuid'>
      & { userResultNotifications: Array<(
        { __typename?: 'UserResultNotificationsType' }
        & Pick<UserResultNotificationsType, 'surveyResponseNotificationTypes'>
      )> }
    )> }
  )> }
);

export type ActionPlansActionPlanQueryVariables = Exact<{
  targetUserUuid?: Maybe<Scalars['String']>;
}>;


export type ActionPlansActionPlanQuery = (
  { __typename?: 'Query' }
  & { actionPlan: (
    { __typename?: 'ActionPlanNode' }
    & Pick<ActionPlanNode, 'uuid'>
    & ActionPlansActionPlanFragment
  ) }
);

export type ActionPlansMgmtActionPlansQueryVariables = Exact<{
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  pageSize?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  sortBy: ActionPlansSortByEnum;
  searchQuery?: Maybe<Scalars['String']>;
  sortAscending: Scalars['Boolean'];
  selectedFollowFilters: Array<Scalars['String']>;
}>;


export type ActionPlansMgmtActionPlansQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'id' | 'followedActionPlanIds'>
  ), actionPlanNodes?: Maybe<(
    { __typename?: 'ActionPlanNodeConnection' }
    & Pick<ActionPlanNodeConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'ActionPlanNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'ActionPlanNode' }
        & Pick<ActionPlanNode, 'uuid' | 'lastUpdated'>
        & { owner: (
          { __typename?: 'UserNode' }
          & Pick<UserNode, 'id' | 'name' | 'isAdmin'>
          & { filterPermissions: Array<Maybe<(
            { __typename?: 'FilterTypeType' }
            & FilterTypeFragment
          )>> }
        ), progress: (
          { __typename?: 'ActionPlanCountsType' }
          & { employee?: Maybe<(
            { __typename?: 'ActionItemsCountsType' }
            & ActionPlansMgmtCountsFragment
          )>, resident?: Maybe<(
            { __typename?: 'ActionItemsCountsType' }
            & ActionPlansMgmtCountsFragment
          )> }
        ), lastEmployeeSurveyData?: Maybe<(
          { __typename?: 'LastSurveyData' }
          & { survey: (
            { __typename?: 'SurveyNode' }
            & Pick<SurveyNode, 'name' | 'endDate'>
          ), score: (
            { __typename?: 'OverallScoreType' }
            & Pick<OverallScoreType, 'positive'>
          ) }
        )>, lastResidentSurveyData?: Maybe<(
          { __typename?: 'LastSurveyData' }
          & { survey: (
            { __typename?: 'SurveyNode' }
            & Pick<SurveyNode, 'name' | 'endDate'>
          ), score: (
            { __typename?: 'OverallScoreType' }
            & Pick<OverallScoreType, 'positive'>
          ) }
        )> }
      )> }
    )>> }
  )> }
);

export type ActionPlansMgmtActivityQueryVariables = Exact<{
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  pastDays: Array<Scalars['Int']>;
  selectedFollowFilters: Array<Scalars['String']>;
  searchQuery: Scalars['String'];
}>;


export type ActionPlansMgmtActivityQuery = (
  { __typename?: 'Query' }
  & { actionPlansActivity: Array<(
    { __typename?: 'ActionPlanActivityType' }
    & Pick<ActionPlanActivityType, 'pastDays' | 'activityCount'>
  )> }
);

export type ActionPlansMgmtCountsQueryVariables = Exact<{
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  selectedFollowFilters: Array<Scalars['String']>;
  searchQuery?: Maybe<Scalars['String']>;
}>;


export type ActionPlansMgmtCountsQuery = (
  { __typename?: 'Query' }
  & { actionPlansCounts: (
    { __typename?: 'ActionPlanCountsType' }
    & { employee?: Maybe<(
      { __typename?: 'ActionItemsCountsType' }
      & ActionPlansMgmtCountsFragment
    )>, resident?: Maybe<(
      { __typename?: 'ActionItemsCountsType' }
      & ActionPlansMgmtCountsFragment
    )>, custom?: Maybe<(
      { __typename?: 'ActionItemsCountsType' }
      & ActionPlansMgmtCountsFragment
    )> }
  ) }
);

export type ActionPlansStatementsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  includeCustom?: Maybe<Scalars['Boolean']>;
}>;


export type ActionPlansStatementsQuery = (
  { __typename?: 'Query' }
  & { statements: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'uuid'>
    & InsightsStatementScoreFragment
  )> }
);

export type ActionPlansSurveysQueryVariables = Exact<{ [key: string]: never; }>;


export type ActionPlansSurveysQuery = (
  { __typename?: 'Query' }
  & { surveys: (
    { __typename?: 'SurveyNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'SurveyNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'SurveyNode' }
        & Pick<SurveyNode, 'description' | 'filterTypeUuids'>
        & { responseRate: (
          { __typename?: 'ResponseRateType' }
          & ResponseRateFragment
        ) }
        & SurveyCoreFragment
      )> }
    )>> }
  ) }
);

export type AnalyticsOverviewQueryVariables = Exact<{
  employeeSurveyUuid: Scalars['String'];
  residentSurveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  employeeBenchmark?: Maybe<BenchmarkNodeInput>;
  residentBenchmark?: Maybe<BenchmarkNodeInput>;
  residentResponseRateByClient: Scalars['Boolean'];
}>;


export type AnalyticsOverviewQuery = (
  { __typename?: 'Query' }
  & { employeeIndexScore: (
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'positive' | 'benchmarkPositive'>
  ), residentIndexScore: (
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'positive' | 'benchmarkPositive'>
  ), employeeSurvey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'uuid'>
    & { insightsSurvey: (
      { __typename?: 'InsightsSurveyNode' }
      & { responseRate: (
        { __typename?: 'ResponseRateType' }
        & ResponseRateFragment
      ) }
    ) }
  ), residentSurvey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'uuid'>
    & { insightsSurvey: (
      { __typename?: 'InsightsSurveyNode' }
      & { responseRate: (
        { __typename?: 'ResponseRateType' }
        & ResponseRateFragment
      ), responseRateByParticipant: (
        { __typename?: 'ResponseRateType' }
        & ResponseRateFragment
      ) }
    ) }
  ) }
);

export type CertificationCertifiedLocationsExportDownloadQueryVariables = Exact<{
  certificationUuid: Scalars['String'];
}>;


export type CertificationCertifiedLocationsExportDownloadQuery = (
  { __typename?: 'Query' }
  & { success: Query['certifiedLocationsExport'] }
);

export type CurrentCertificationQueryVariables = Exact<{
  skipCultureBrief: Scalars['Boolean'];
}>;


export type CurrentCertificationQuery = (
  { __typename?: 'Query' }
  & { certification?: Maybe<(
    { __typename?: 'CertificationType' }
    & Pick<CertificationType, 'status' | 'uuid' | 'accessEndDate' | 'certifiedEndDate' | 'organizationName' | 'organizationSlug' | 'passesScoreCriteria' | 'passesResponseRateCriteria' | 'badgeImageUrl' | 'badgeZipUrl'>
    & { survey: (
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'status' | 'editable' | 'uuid' | 'name' | 'numberOfLocationFilterValuesMissingRequiredData'>
      & { insightsSurvey: (
        { __typename?: 'InsightsSurveyNode' }
        & { overallIndexScore?: Maybe<(
          { __typename?: 'OverallScoreType' }
          & Pick<OverallScoreType, 'positive'>
        )>, responseRate: (
          { __typename?: 'ResponseRateType' }
          & Pick<ResponseRateType, 'finished' | 'total' | 'rate'>
        ) }
      ) }
    ), cultureBrief: (
      { __typename?: 'CultureBriefType' }
      & Pick<CultureBriefType, 'uuid' | 'status'>
    ), locationCertifications: Array<(
      { __typename?: 'LocationCertificationType' }
      & Pick<LocationCertificationType, 'name' | 'isCertified' | 'locationUuid' | 'uuid' | 'badgeImageUrl'>
    )> }
  )>, currentUser: (
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'uuid'>
    & { organization: (
      { __typename?: 'OrganizationType' }
      & Pick<OrganizationType, 'uuid'>
      & { certifications: Array<(
        { __typename?: 'CertificationType' }
        & Pick<CertificationType, 'status' | 'uuid' | 'accessEndDate' | 'certifiedStartDate' | 'certifiedEndDate' | 'organizationName' | 'badgeImageUrl'>
        & { locationCertifications: Array<(
          { __typename?: 'LocationCertificationType' }
          & Pick<LocationCertificationType, 'name' | 'isCertified' | 'locationUuid' | 'uuid'>
        )>, survey: (
          { __typename?: 'SurveyNode' }
          & Pick<SurveyNode, 'uuid'>
        ), cultureBrief: (
          { __typename?: 'CultureBriefType' }
          & Pick<CultureBriefType, 'uuid' | 'status'>
        ) }
      )> }
    ) }
  ) }
);

export type CertificationCultureBriefQueryVariables = Exact<{
  certificationUuid: Scalars['String'];
}>;


export type CertificationCultureBriefQuery = (
  { __typename?: 'Query' }
  & { certification: (
    { __typename?: 'CertificationType' }
    & Pick<CertificationType, 'uuid' | 'status'>
    & { cultureBrief: (
      { __typename?: 'CultureBriefType' }
      & CultureBriefFragment
      & CultureBriefImagesFragment
    ) }
  ) }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'status' | 'isStaff' | 'canInviteUsers' | 'canResetUsersPasswords' | 'canAccessDataStructure' | 'canAccessSurveySettings' | 'canAccessGeneralSettings' | 'canManageActionPlans' | 'canUseActionPlans' | 'canManageCertifications' | 'canViewCertificationResults' | 'insightsModules' | 'residentCanAccessSurveySettings' | 'canAccessLonelinessResults' | 'residentInsightsModules' | 'surveyTypesAllowedToAdd' | 'favoriteSurveyIds'>
    & { organization: (
      { __typename?: 'OrganizationType' }
      & OrganizationFragment
    ), filters: Array<(
      { __typename?: 'FilterTypeType' }
      & FilterTypeFragment
    )>, appMessages: Array<(
      { __typename?: 'UserAppMessage' }
      & Pick<UserAppMessage, 'kind' | 'seen'>
      & { contentObjects: Array<(
        { __typename?: 'ContentObject' }
        & Pick<ContentObject, 'contentType' | 'uuid'>
      )> }
    )> }
    & UserCoreFragment
  ) }
);

export type CurrentUserSurveyResponseNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserSurveyResponseNotificationsQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'uuid' | 'hasSurveyResponseNotifications'>
    & { userResultNotifications: Array<(
      { __typename?: 'UserResultNotificationsType' }
      & Pick<UserResultNotificationsType, 'surveyResponseNotificationTypes'>
      & { survey: (
        { __typename?: 'SurveyNode' }
        & Pick<SurveyNode, 'uuid' | 'name' | 'hasSurveyResponseNotifications' | 'hasAverageScoreNotifications' | 'hasQuestionScoreNotifications'>
      ), averageScoreNotifications?: Maybe<Array<(
        { __typename?: 'UserAverageScoreNotificationType' }
        & Pick<UserAverageScoreNotificationType, 'enabled' | 'frequency' | 'scoreType'>
      )>>, questionScoreNotifications?: Maybe<Array<(
        { __typename?: 'UserQuestionScoreNotificationType' }
        & Pick<UserQuestionScoreNotificationType, 'uuid' | 'enabled' | 'frequency'>
        & { groupNotification: (
          { __typename?: 'GroupsSurveyQuestionScoreNotificationType' }
          & Pick<GroupsSurveyQuestionScoreNotificationType, 'uuid' | 'notificationName' | 'enabled' | 'scoreType' | 'frequency' | 'minScore' | 'maxScore'>
          & { statement: (
            { __typename?: 'QuestionType' }
            & Pick<QuestionType, 'text'>
          ) }
        ) }
      )>> }
    )> }
  ) }
);

export type ActionPlansDownloadQueryVariables = Exact<{
  targetUserUuid?: Maybe<Scalars['String']>;
}>;


export type ActionPlansDownloadQuery = (
  { __typename?: 'Query' }
  & { success: Query['actionPlansDownload'] }
);

export type InsightsBenchmarksQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type InsightsBenchmarksQuery = (
  { __typename?: 'Query' }
  & { benchmarks: Array<(
    { __typename?: 'BenchmarkNode' }
    & BenchmarkFragment
  )> }
);

export type InsightsCommentsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  questionCode: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  pageSize: Scalars['Int'];
  page: Scalars['Int'];
  searchWords?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy?: Maybe<Scalars['String']>;
  filterBy?: Maybe<NpsGroupsEnum>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsCommentsQuery = (
  { __typename?: 'Query' }
  & { insightsComments: (
    { __typename?: 'InsightsComments' }
    & Pick<InsightsComments, 'totalComments'>
    & { comments: Array<(
      { __typename?: 'InsightsComment' }
      & Pick<InsightsComment, 'text' | 'author' | 'submitted' | 'filterValues'>
    )> }
  ) }
);

export type InsightsCommentsTopCommentsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  questionCode: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  searchWords?: Maybe<Array<Maybe<Scalars['String']>>>;
  topCommentsCount: Scalars['Int'];
  sortBy?: Maybe<Scalars['String']>;
  filterBy?: Maybe<NpsGroupsEnum>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsCommentsTopCommentsQuery = (
  { __typename?: 'Query' }
  & { insightsComments: (
    { __typename?: 'InsightsComments' }
    & Pick<InsightsComments, 'totalComments'>
    & { comments: Array<(
      { __typename?: 'InsightsComment' }
      & Pick<InsightsComment, 'text'>
    )> }
  ) }
);

export type InsightsComparisonControlsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
}>;


export type InsightsComparisonControlsQuery = (
  { __typename?: 'Query' }
  & { controls: (
    { __typename?: 'ComparisonToolControls' }
    & { statements: Array<(
      { __typename?: 'FrozenQuestionType' }
      & Pick<FrozenQuestionType, 'code' | 'focus' | 'residentResultsGroup' | 'text'>
    )>, demographics: Array<(
      { __typename?: 'InsightsDemographic' }
      & Pick<InsightsDemographic, 'code' | 'text' | 'pluralText'>
    )> }
  ) }
);

export type InsightsCoreQBreakdownQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  dtCode: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  limitToCoreQEligible: Scalars['Boolean'];
  statementCodes: Array<Scalars['String']>;
}>;


export type InsightsCoreQBreakdownQuery = (
  { __typename?: 'Query' }
  & { insightsScoresByDataType: Array<(
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'label' | 'positive'>
  )>, insightsOverallIndex: (
    { __typename?: 'OverallScoreType' }
    & InsightsOverallScoreFragment
  ) }
);

export type InsightsCoreQResultsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  limitToCoreQEligible: Scalars['Boolean'];
  filters?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  statementCodes: Array<Scalars['String']>;
}>;


export type InsightsCoreQResultsQuery = (
  { __typename?: 'Query' }
  & { insightsCoreQResults: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'code' | 'label' | 'positive' | 'negative' | 'count'>
  )>, insightsOverallIndex: (
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'positive' | 'negative' | 'inconsistent' | 'count'>
  ) }
);

export type InsightsCultureQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  demographics?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type InsightsCultureQuery = (
  { __typename?: 'Query' }
  & { culture?: Maybe<(
    { __typename?: 'CultureOverviewType' }
    & Pick<CultureOverviewType, 'highTrustBenchmark' | 'checkedOutBenchmark' | 'highRiskBenchmark' | 'wantsMoreBenchmark'>
    & { highTrust?: Maybe<(
      { __typename?: 'CultureType' }
      & InsightsCultureFragment
    )>, checkedOut?: Maybe<(
      { __typename?: 'CultureType' }
      & InsightsCultureFragment
    )>, highRisk?: Maybe<(
      { __typename?: 'CultureType' }
      & InsightsCultureFragment
    )>, wantsMore?: Maybe<(
      { __typename?: 'CultureType' }
      & InsightsCultureFragment
    )> }
  )> }
);

export type InsightsCultureChangeQueryVariables = Exact<{
  surveyUuid1: Scalars['String'];
  surveyUuid2: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
}>;


export type InsightsCultureChangeQuery = (
  { __typename?: 'Query' }
  & { cultureChange?: Maybe<(
    { __typename?: 'CultureChangeOverviewType' }
    & { checkedOut?: Maybe<Array<Maybe<(
      { __typename?: 'CultureChangeType' }
      & InsightsCultureChangeFragment
    )>>>, highRisk?: Maybe<Array<Maybe<(
      { __typename?: 'CultureChangeType' }
      & InsightsCultureChangeFragment
    )>>>, wantsMore?: Maybe<Array<Maybe<(
      { __typename?: 'CultureChangeType' }
      & Pick<CultureChangeType, 'demographic' | 'value'>
      & InsightsCultureChangeFragment
    )>>> }
  )> }
);

export type InsightsCustomSurveySnapshotQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  skipNpsScore: Scalars['Boolean'];
}>;


export type InsightsCustomSurveySnapshotQuery = (
  { __typename?: 'Query' }
  & { insightsCustomSurvey: Array<(
    { __typename?: 'InsightsCustomSurveyQuestion' }
    & { question: (
      { __typename?: 'FrozenQuestionType' }
      & Pick<FrozenQuestionType, 'uuid' | 'text' | 'kind' | 'code' | 'isOpenEnded'>
    ), answers: Array<(
      { __typename?: 'InsightsCustomSurveyAnswer' }
      & Pick<InsightsCustomSurveyAnswer, 'count' | 'value' | 'text' | 'score'>
    )>, selectedFiltersAnswers: Array<(
      { __typename?: 'InsightsCustomSurveyAnswer' }
      & Pick<InsightsCustomSurveyAnswer, 'count' | 'value' | 'text' | 'score'>
    )> }
  )>, insightsNpsGroupScores?: Maybe<(
    { __typename?: 'NpsGroupScoresType' }
    & Pick<NpsGroupScoresType, 'promoters' | 'passives' | 'detractors' | 'label' | 'count'>
  )> }
);

export type InsightsDetailedBreakdownQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  level1DtCode: Scalars['String'];
  level2DtCode: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  skipLevel2: Scalars['Boolean'];
  limitToCoreQEligible?: Maybe<Scalars['Boolean']>;
}>;


export type InsightsDetailedBreakdownQuery = (
  { __typename?: 'Query' }
  & { level1Breakdown: Array<(
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'label' | 'positive'>
  )>, level2Breakdown: Array<(
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'label' | 'positive'>
  )> }
);

export type InsightsDimensionsOverviewQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
}>;


export type InsightsDimensionsOverviewQuery = (
  { __typename?: 'Query' }
  & { insightsDimensionsOverview: Array<(
    { __typename?: 'OverallScoreType' }
    & InsightsGroupedScoreFragment
  )> }
);

export type InsightsDischargeOverviewQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  skipNpsScore: Scalars['Boolean'];
  benchmark?: Maybe<BenchmarkNodeInput>;
}>;


export type InsightsDischargeOverviewQuery = (
  { __typename?: 'Query' }
  & { insightsStatements: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'uuid'>
    & InsightsStatementScoreFragment
  )>, insightsNpsGroupScores?: Maybe<(
    { __typename?: 'NpsGroupScoresType' }
    & Pick<NpsGroupScoresType, 'promoters' | 'passives' | 'detractors' | 'label' | 'count'>
  )> }
);

export type InsightsDownloadQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  downloadType: UserDownloadsEnum;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  certificationReportLocationUuid?: Maybe<Scalars['String']>;
  filterType1Code?: Maybe<Scalars['String']>;
  filterType2Code?: Maybe<Scalars['String']>;
  byClient?: Maybe<Scalars['Boolean']>;
}>;


export type InsightsDownloadQuery = (
  { __typename?: 'Query' }
  & { success: Query['insightsDownload'] }
);

export type InsightsDownloadLinkQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type InsightsDownloadLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'downloadLink'>
);

export type InsightsEmployeeEligibilityResponseRateQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
}>;


export type InsightsEmployeeEligibilityResponseRateQuery = (
  { __typename?: 'Query' }
  & { insightsEmployeeEligibilityResponseRate: (
    { __typename?: 'EmployeeEligibilityRate' }
    & Pick<EmployeeEligibilityRate, 'certificationEligible' | 'listEligible'>
  ) }
);

export type InsightsEmployeeFocusAreasQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  dtCodes: Array<Scalars['String']>;
  statement1Codes?: Maybe<Array<Scalars['String']>>;
  statement2Codes?: Maybe<Array<Scalars['String']>>;
  statement3Codes?: Maybe<Array<Scalars['String']>>;
  improvementDtCode?: Maybe<Scalars['String']>;
}>;


export type InsightsEmployeeFocusAreasQuery = (
  { __typename?: 'Query' }
  & { statement1Groups: Array<(
    { __typename?: 'GroupedScoresType' }
    & GroupedScoresFragment
  )>, statement2Groups: Array<(
    { __typename?: 'GroupedScoresType' }
    & GroupedScoresFragment
  )>, statement3Groups: Array<(
    { __typename?: 'GroupedScoresType' }
    & GroupedScoresFragment
  )>, insightsPotentialImprovementScore: (
    { __typename?: 'ImprovementScoreType' }
    & Pick<ImprovementScoreType, 'improvementScore'>
    & { improvedGroups?: Maybe<Array<(
      { __typename?: 'ImprovedGroupType' }
      & Pick<ImprovedGroupType, 'score' | 'name'>
    )>> }
  ) }
);

export type InsightsHeatmapQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  y: Scalars['String'];
  filters: Array<Scalars['String']>;
}>;


export type InsightsHeatmapQuery = (
  { __typename?: 'Query' }
  & { insightsHeatmap?: Maybe<(
    { __typename?: 'HeatmapType' }
    & Pick<HeatmapType, 'categories'>
    & { statements?: Maybe<Array<Maybe<(
      { __typename?: 'FrozenQuestionType' }
      & Pick<FrozenQuestionType, 'code' | 'focus' | 'residentResultsGroup' | 'text'>
    )>>>, cells: Array<Maybe<Array<Maybe<(
      { __typename?: 'HeatmapCellType' }
      & Pick<HeatmapCellType, 'score'>
    )>>>> }
  )> }
);

export type InsightsHeatmapBenchmarkScoresQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  benchmark: BenchmarkNodeInput;
}>;


export type InsightsHeatmapBenchmarkScoresQuery = (
  { __typename?: 'Query' }
  & { scores: (
    { __typename?: 'HeatmapBenchmarkScores' }
    & Pick<HeatmapBenchmarkScores, 'name' | 'scores'>
  ) }
);

export type InsightsHeatmapControlsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
}>;


export type InsightsHeatmapControlsQuery = (
  { __typename?: 'Query' }
  & { controls: (
    { __typename?: 'HeatmapControls' }
    & { demographics: Array<(
      { __typename?: 'InsightsDemographic' }
      & Pick<InsightsDemographic, 'code' | 'text' | 'pluralText'>
    )> }
  ) }
);

export type InsightsIndividualResultsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  searchQuery?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsIndividualResultsQuery = (
  { __typename?: 'Query' }
  & { insightsIndividualResults: Array<(
    { __typename?: 'IndividualResultType' }
    & Pick<IndividualResultType, 'totalResults' | 'name' | 'clientName' | 'participantType' | 'participantUuid' | 'locationName' | 'levelOfCare' | 'startedTimestamp' | 'submittedTimestamp' | 'jobTitle' | 'source' | 'callTime' | 'callLength' | 'dischargeDate' | 'lastSentNotificationTimestamp'>
    & { responses: Array<(
      { __typename?: 'IndividualResponseType' }
      & Pick<IndividualResponseType, 'answer' | 'answers' | 'questionCode'>
    )>, questions: Array<(
      { __typename?: 'ParticipantQuestion' }
      & Pick<ParticipantQuestion, 'benchmarkCode' | 'code' | 'text' | 'category' | 'isOpenEnded' | 'isStatement'>
      & { choices: Array<(
        { __typename?: 'ParticipantQuestionChoice' }
        & Pick<ParticipantQuestionChoice, 'benchmarkCode' | 'code' | 'text' | 'value'>
      )> }
    )> }
  )> }
);

export type InsightsKeyDemographicsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  impactScoreStrategy: ImpactScoreStrategyEnum;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsKeyDemographicsQuery = (
  { __typename?: 'Query' }
  & { insightsKeyDemographics: Array<(
    { __typename?: 'GroupedScoresType' }
    & Pick<GroupedScoresType, 'dataType' | 'label'>
    & { scores: Array<(
      { __typename?: 'OverallScoreType' }
      & Pick<OverallScoreType, 'benchmarkPositive' | 'positive' | 'inconsistent' | 'negative' | 'label' | 'count'>
    )> }
  )> }
);

export type InsightsKeyStatementQueryVariables = Exact<{
  associatedSurveyUuid: Scalars['String'];
  surveyUuid: Scalars['String'];
  statementCodes?: Maybe<Array<Scalars['String']>>;
  filters?: Maybe<Array<Scalars['String']>>;
}>;


export type InsightsKeyStatementQuery = (
  { __typename?: 'Query' }
  & { keyStatement: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'label' | 'positive' | 'inconsistent' | 'negative'>
  )>, associatedSurveyKeyStatement: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'positive'>
  )> }
);

export type InsightsLonelinessSummaryQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
}>;


export type InsightsLonelinessSummaryQuery = (
  { __typename?: 'Query' }
  & { insightsLonelinessSummary: (
    { __typename?: 'LonelinessScoresType' }
    & Pick<LonelinessScoresType, 'numLonely' | 'numSomewhatLonely' | 'numNotLonely'>
  ) }
);

export type InsightsMoveOutOverviewQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  statementCodes: Array<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsMoveOutOverviewQuery = (
  { __typename?: 'Query' }
  & { insightsNpsGroupScores?: Maybe<(
    { __typename?: 'NpsGroupScoresType' }
    & Pick<NpsGroupScoresType, 'promoters' | 'passives' | 'detractors' | 'label' | 'count'>
  )>, recommendStatementData: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'uuid'>
    & InsightsStatementScoreFragment
  )> }
);

export type InsightsMultiselectGroupCountsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  multiselectCode: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsMultiselectGroupCountsQuery = (
  { __typename?: 'Query' }
  & { insightsMultiselectGroupCounts: Array<(
    { __typename?: 'GroupCountsType' }
    & Pick<GroupCountsType, 'label' | 'count'>
  )> }
);

export type InsightsNpsGroupScoresByDataTypeQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  dtCode: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsNpsGroupScoresByDataTypeQuery = (
  { __typename?: 'Query' }
  & { insightsNpsGroupScoresByDataType?: Maybe<Array<(
    { __typename?: 'NpsGroupScoresType' }
    & Pick<NpsGroupScoresType, 'promoters' | 'passives' | 'detractors' | 'label'>
  )>> }
);

export type InsightsOpenEndedQuestionsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
}>;


export type InsightsOpenEndedQuestionsQuery = (
  { __typename?: 'Query' }
  & { questions: Array<Maybe<(
    { __typename?: 'FrozenQuestionType' }
    & Pick<FrozenQuestionType, 'uuid' | 'code' | 'text'>
  )>> }
);

export type InsightsOrganizationServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type InsightsOrganizationServicesQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'id'>
    & { organization: (
      { __typename?: 'OrganizationType' }
      & Pick<OrganizationType, 'uuid'>
      & { serviceAreas: Array<(
        { __typename?: 'ServiceAreaType' }
        & Pick<ServiceAreaType, 'label' | 'questionLabel' | 'questionCodes' | 'code'>
      )> }
    ) }
  ) }
);

export type InsightsOverallIndexQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  includeCustom?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  limitToCoreQEligible?: Maybe<Scalars['Boolean']>;
}>;


export type InsightsOverallIndexQuery = (
  { __typename?: 'Query' }
  & { insightsOverallIndex: (
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'positive' | 'negative' | 'inconsistent' | 'count' | 'totalResponses'>
  ) }
);

export type InsightsOverlayDrivingStatementsQueryVariables = Exact<{
  employeeSurveyUuid: Scalars['String'];
  residentSurveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  rankByPositive?: Maybe<RankByEnum>;
}>;


export type InsightsOverlayDrivingStatementsQuery = (
  { __typename?: 'Query' }
  & { positiveEmployeeDrivingStatements: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'overallPositiveCorrelation'>
    & InsightsStatementScoreFragment
  )>, positiveResidentDrivingStatements: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'overallPositiveCorrelation'>
    & InsightsStatementScoreFragment
  )> }
);

export type InsightsOverlayServicesDataQueryVariables = Exact<{
  employeeSurveyUuid: Scalars['String'];
  filters: Array<Scalars['String']>;
  dataTypeFilters: Array<OverallScoreByFiltersInputType>;
}>;


export type InsightsOverlayServicesDataQuery = (
  { __typename?: 'Query' }
  & { overallIndexScoreByFilters: Array<(
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'positive' | 'label'>
  )> }
);

export type InsightsOverlayServicesSettingsQueryVariables = Exact<{
  residentSurveyUuid: Scalars['String'];
  filters: Array<Scalars['String']>;
  employeeSurveyUuid: Scalars['String'];
  workStatusCode: DataTypeCode;
  benchmarkDeptCode: DataTypeCode;
}>;


export type InsightsOverlayServicesSettingsQuery = (
  { __typename?: 'Query' }
  & { validWorkStatuses: Query['insightsGroupsForDtCode'], validBenchmarkDepts: Query['insightsGroupsForDtCode'] }
  & { currentUser: (
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'id'>
    & { organization: (
      { __typename?: 'OrganizationType' }
      & Pick<OrganizationType, 'uuid'>
      & { serviceAreas: Array<(
        { __typename?: 'ServiceAreaType' }
        & Pick<ServiceAreaType, 'questionLabel' | 'questionCodes'>
        & OrganizationServiceAreasFragment
      )> }
    ) }
  ), insightsStatements: Array<(
    { __typename?: 'StatementScoreType' }
    & InsightsStatementScoreFragment
  )> }
);

export type InsightsRankedStatementsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  rankBy?: Maybe<RankByEnum>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  includeCustom?: Maybe<Scalars['Boolean']>;
}>;


export type InsightsRankedStatementsQuery = (
  { __typename?: 'Query' }
  & { statements: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'uuid'>
    & InsightsStatementScoreFragment
  )> }
);

export type InsightsReasonsForLeavingQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  multiselectCode: Scalars['String'];
  dtCode: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsReasonsForLeavingQuery = (
  { __typename?: 'Query' }
  & { insightsMultiselectGroupCounts: Array<(
    { __typename?: 'GroupCountsType' }
    & Pick<GroupCountsType, 'label' | 'count'>
  )>, insightsVoluntaryDischargeCountsBy: Array<(
    { __typename?: 'VoluntaryDischargeGroupCountsType' }
    & Pick<VoluntaryDischargeGroupCountsType, 'label' | 'voluntaryCount' | 'involuntaryCount'>
  )> }
);

export type InsightsResidentFocusAreasQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  dtCodes: Array<Scalars['String']>;
  targetPercentile: Scalars['Int'];
  statement1Codes?: Maybe<Array<Scalars['String']>>;
  statement1Code: Scalars['String'];
  statement2Codes?: Maybe<Array<Scalars['String']>>;
  statement2Code: Scalars['String'];
  statement3Codes?: Maybe<Array<Scalars['String']>>;
  statement3Code: Scalars['String'];
  improvementDtCode?: Maybe<Scalars['String']>;
}>;


export type InsightsResidentFocusAreasQuery = (
  { __typename?: 'Query' }
  & { statement1Target: Query['insightsTargetScore'], statement2Target: Query['insightsTargetScore'], statement3Target: Query['insightsTargetScore'] }
  & { statement1Groups: Array<(
    { __typename?: 'GroupedScoresType' }
    & GroupedScoresFragment
  )>, statement2Groups: Array<(
    { __typename?: 'GroupedScoresType' }
    & GroupedScoresFragment
  )>, statement3Groups: Array<(
    { __typename?: 'GroupedScoresType' }
    & GroupedScoresFragment
  )>, insightsPotentialImprovementScore: (
    { __typename?: 'ImprovementScoreType' }
    & Pick<ImprovementScoreType, 'improvementScore'>
    & { improvedGroups?: Maybe<Array<(
      { __typename?: 'ImprovedGroupType' }
      & Pick<ImprovedGroupType, 'score' | 'name'>
    )>> }
  ) }
);

export type InsightsResponseRateQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  byClient: Scalars['Boolean'];
  filters?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsResponseRateQuery = (
  { __typename?: 'Query' }
  & { survey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'minShowableResults'>
    & { insightsSurvey: (
      { __typename?: 'InsightsSurveyNode' }
      & { responseRate: (
        { __typename?: 'ResponseRateType' }
        & Pick<ResponseRateType, 'finished' | 'total'>
      ), completionRate: (
        { __typename?: 'CompletionRateType' }
        & Pick<CompletionRateType, 'started' | 'submitted'>
      ) }
    ) }
  ) }
);

export type InsightsResponseRateByFilterTypeQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filterTypeUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  byClient: Scalars['Boolean'];
}>;


export type InsightsResponseRateByFilterTypeQuery = (
  { __typename?: 'Query' }
  & { responseRateByFilterType?: Maybe<Array<Maybe<(
    { __typename?: 'FilterTypeResponse' }
    & Pick<FilterTypeResponse, 'name' | 'rate'>
  )>>> }
);

export type InsightsResponseRateReportQueryVariables = Exact<{
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  groupByFilterType1Code: Scalars['String'];
  groupByFilterType2Code: Scalars['String'];
  byClient: Scalars['Boolean'];
  skipCompletionRate: Scalars['Boolean'];
  skipResponseRate: Scalars['Boolean'];
}>;


export type InsightsResponseRateReportQuery = (
  { __typename?: 'Query' }
  & { responseRateReport?: Maybe<(
    { __typename?: 'ResponseRateReportDataType' }
    & { responseRate: (
      { __typename?: 'ResponseRateReportType' }
      & Pick<ResponseRateReportType, 'total' | 'finished' | 'rate' | 'totalFilter1'>
    ), rows: Array<(
      { __typename?: 'ResponseRateRowType' }
      & Pick<ResponseRateRowType, 'total' | 'finished' | 'rate' | 'filterValue1' | 'filterValue2'>
    )>, highlights: Array<(
      { __typename?: 'ResponseRateHighlightType' }
      & Pick<ResponseRateHighlightType, 'filterTypeName' | 'filterTypeUuid' | 'topFilterValueNames' | 'topRate' | 'highlightType'>
    )> }
  )>, completionRateReport?: Maybe<(
    { __typename?: 'CompletionRateReportDataType' }
    & { completionRate: (
      { __typename?: 'CompletionRateReportType' }
      & Pick<CompletionRateReportType, 'started' | 'submitted' | 'totalFilter1'>
    ), rows: Array<(
      { __typename?: 'CompletionRateRowType' }
      & Pick<CompletionRateRowType, 'started' | 'submitted' | 'filterValue1' | 'filterValue2'>
    )> }
  )>, responsesByDate: Array<(
    { __typename?: 'ResponsesByDateType' }
    & Pick<ResponsesByDateType, 'date' | 'responses'>
  )>, survey: (
    { __typename?: 'ResponseRateSurveyType' }
    & Pick<ResponseRateSurveyType, 'uuid' | 'startDate' | 'endDate' | 'name' | 'type' | 'status' | 'reportShareUrl' | 'reportUuid' | 'targetResponsesNo' | 'isDefaultClientBasedResponseRateReport' | 'certificationEligibilityRate' | 'distributionType'>
  ) }
);

export type InsightsResponseRateReportMetadataQueryVariables = Exact<{
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type InsightsResponseRateReportMetadataQuery = (
  { __typename?: 'Query' }
  & { responseRateReportMetadata?: Maybe<(
    { __typename?: 'ResponseRateReportMetadata' }
    & Pick<ResponseRateReportMetadata, 'surveyUuid' | 'isDefaultClientBasedResponseRateReport' | 'minShowableResults' | 'distributionType'>
    & { filterTypes: Array<(
      { __typename?: 'ResponseRateFilterType' }
      & Pick<ResponseRateFilterType, 'isValidByClient'>
      & { filterType: (
        { __typename?: 'FilterTypeType' }
        & FilterTypeFragment
      ) }
    )> }
  )> }
);

export type InsightsScoresByDataTypeQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  dtCode: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  includeAllSurveyChoices?: Maybe<Scalars['Boolean']>;
}>;


export type InsightsScoresByDataTypeQuery = (
  { __typename?: 'Query' }
  & { insightsScoresByDataType: Array<(
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'label' | 'positive' | 'inconsistent' | 'negative' | 'count' | 'totalResponses'>
  )> }
);

export type InsightsScoresByDataTypeCombinedQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  dtCode: Scalars['String'];
  dtCodeHierarchy: Array<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  includeAllSurveyChoices?: Maybe<Scalars['Boolean']>;
}>;


export type InsightsScoresByDataTypeCombinedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'insightsScoresByDataTypeHierarchy'>
  & { insightsScoresByDataType: Array<(
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'label' | 'positive' | 'inconsistent' | 'negative' | 'count'>
  )> }
);

export type InsightsScoresByDataTypeHierarchyQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  dtCodeHierarchy: Array<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  includeAllSurveyChoices?: Maybe<Scalars['Boolean']>;
}>;


export type InsightsScoresByDataTypeHierarchyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'insightsScoresByDataTypeHierarchy'>
);

export type InsightsSnapshotOverviewQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  skipNpsScore: Scalars['Boolean'];
}>;


export type InsightsSnapshotOverviewQuery = (
  { __typename?: 'Query' }
  & { insightsOverallIndex: (
    { __typename?: 'OverallScoreType' }
    & InsightsOverallScoreFragment
  ), insightsStatements: Array<(
    { __typename?: 'StatementScoreType' }
    & InsightsStatementScoreFragment
  )>, insightsNpsGroupScores?: Maybe<(
    { __typename?: 'NpsGroupScoresType' }
    & Pick<NpsGroupScoresType, 'promoters' | 'passives' | 'detractors' | 'label' | 'count'>
  )> }
);

export type InsightsStatementsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  limit?: Maybe<Scalars['Int']>;
  includeCustom?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsStatementsQuery = (
  { __typename?: 'Query' }
  & { statements: Array<(
    { __typename?: 'StatementScoreType' }
    & InsightsStatementScoreFragment
  )> }
);

export type InsightsStatementsByDataTypesQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dataTypes: Array<DataTypeGroupsInputType>;
  includeCustom?: Maybe<Scalars['Boolean']>;
}>;


export type InsightsStatementsByDataTypesQuery = (
  { __typename?: 'Query' }
  & { insightsStatementsByDataTypes: Array<(
    { __typename?: 'GroupedStatementScoresType' }
    & Pick<GroupedStatementScoresType, 'group'>
    & { statements: Array<(
      { __typename?: 'StatementScoreType' }
      & InsightsStatementScoreFragment
    )> }
  )> }
);

export type InsightsStatementsCommentsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  questionCode: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  pageSize: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type InsightsStatementsCommentsQuery = (
  { __typename?: 'Query' }
  & { comments: (
    { __typename?: 'InsightsComments' }
    & Pick<InsightsComments, 'totalComments'>
    & { question?: Maybe<(
      { __typename?: 'FrozenQuestionType' }
      & Pick<FrozenQuestionType, 'textDisplay'>
    )>, comments: Array<(
      { __typename?: 'InsightsComment' }
      & Pick<InsightsComment, 'text'>
    )> }
  ) }
);

export type InsightsStatementsWithComparisonsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  comparisonDataTypes: Array<DataTypeGroupsInputType>;
  skipFilteredStatements: Scalars['Boolean'];
  skipComparisonStatements: Scalars['Boolean'];
  includeCustom?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsStatementsWithComparisonsQuery = (
  { __typename?: 'Query' }
  & { statements: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'uuid'>
    & InsightsStatementScoreFragment
  )>, filteredStatements: Array<(
    { __typename?: 'StatementScoreType' }
    & InsightsStatementScoreFragment
  )>, comparisonStatements: Array<(
    { __typename?: 'GroupedStatementScoresType' }
    & Pick<GroupedStatementScoresType, 'group'>
    & { statements: Array<(
      { __typename?: 'StatementScoreType' }
      & InsightsStatementScoreFragment
    )> }
  )> }
);

export type InsightsStatementsWithFiltersQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  skipFilteredStatements: Scalars['Boolean'];
  includeCustom?: Maybe<Scalars['Boolean']>;
}>;


export type InsightsStatementsWithFiltersQuery = (
  { __typename?: 'Query' }
  & { statements: Array<(
    { __typename?: 'StatementScoreType' }
    & Pick<StatementScoreType, 'uuid'>
    & InsightsStatementScoreFragment
  )>, filteredStatements: Array<(
    { __typename?: 'StatementScoreType' }
    & InsightsStatementScoreFragment
  )> }
);

export type InsightsSurveyQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type InsightsSurveyQuery = (
  { __typename?: 'Query' }
  & { survey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'description' | 'isDefaultClientBasedResponseRateReport' | 'insightsActionPlanPdfUrl' | 'includesTestimonials' | 'includesLonelinessQuestions' | 'includesNpsQuestion' | 'includesLeftReasonQuestion' | 'hasCoreQRecommendResponses' | 'startDate' | 'reportShareUrl' | 'filterTypeUuids' | 'includeForUsNews' | 'distributionType' | 'isRecurring'>
    & { defaultBenchmark?: Maybe<(
      { __typename?: 'BenchmarkNode' }
      & BenchmarkFragment
    )>, associatedSurvey?: Maybe<(
      { __typename?: 'SurveyNode' }
      & Pick<SurveyNode, 'uuid' | 'name' | 'endDate'>
    )>, reasonsForLeaving: Array<(
      { __typename?: 'ReasonForLeaving' }
      & Pick<ReasonForLeaving, 'value' | 'isVoluntary'>
    )> }
    & SurveyCoreFragment
  ) }
);

export type InsightsSurveysFilterValuesQueryVariables = Exact<{
  surveyUuids: Array<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  forDtCode?: Maybe<DataTypeCode>;
}>;


export type InsightsSurveysFilterValuesQuery = (
  { __typename?: 'Query' }
  & { insightsSurveysFilterValues: Array<(
    { __typename?: 'DataTypeGroupsType' }
    & Pick<DataTypeGroupsType, 'dtCode' | 'groups'>
  )> }
);

export type InsightsSurveysQueryVariables = Exact<{
  surveyProductType?: Maybe<SurveyProductTypeEnum>;
}>;


export type InsightsSurveysQuery = (
  { __typename?: 'Query' }
  & { surveys: (
    { __typename?: 'SurveyNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'SurveyNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'SurveyNode' }
        & Pick<SurveyNode, 'hasLessThanMinShowableResults' | 'includesLonelinessQuestions'>
        & SurveyCoreFragment
      )> }
    )>> }
  ) }
);

export type InsightsTakeActionStepsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type InsightsTakeActionStepsQuery = (
  { __typename?: 'Query' }
  & { survey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'uuid' | 'takeActionCheckedSteps'>
  ) }
);

export type InsightsTestimonialsMediaUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type InsightsTestimonialsMediaUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'insightsTestimonialsMediaUrl'>
);

export type InsightsTimeTrendingCompletionRateQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsTimeTrendingCompletionRateQuery = (
  { __typename?: 'Query' }
  & { survey: (
    { __typename?: 'SurveyNode' }
    & { insightsSurvey: (
      { __typename?: 'InsightsSurveyNode' }
      & { completionRate: (
        { __typename?: 'CompletionRateType' }
        & Pick<CompletionRateType, 'started' | 'submitted'>
      ) }
    ) }
  ) }
);

export type InsightsTimeTrendingDimensionsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
}>;


export type InsightsTimeTrendingDimensionsQuery = (
  { __typename?: 'Query' }
  & { overallIndex: (
    { __typename?: 'OverallScoreType' }
    & InsightsOverallScoreFragment
  ), dimensions: Array<(
    { __typename?: 'OverallScoreType' }
    & InsightsGroupedScoreFragment
  )> }
);

export type InsightsTimeTrendingScoresByDataTypeQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  dtCode: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsTimeTrendingScoresByDataTypeQuery = (
  { __typename?: 'Query' }
  & { insightsScoresByDataType: Array<(
    { __typename?: 'OverallScoreType' }
    & Pick<OverallScoreType, 'label' | 'positive' | 'inconsistent' | 'negative' | 'count' | 'invitedCount'>
  )>, overallIndex: (
    { __typename?: 'OverallScoreType' }
    & InsightsOverallScoreFragment
  ) }
);

export type InsightsTimeTrendingSnapshotOverviewQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
}>;


export type InsightsTimeTrendingSnapshotOverviewQuery = (
  { __typename?: 'Query' }
  & { overallIndex: (
    { __typename?: 'OverallScoreType' }
    & InsightsOverallScoreFragment
  ), survey: (
    { __typename?: 'SurveyNode' }
    & { responseRate: (
      { __typename?: 'ResponseRateType' }
      & ResponseRateFragment
    ) }
  ) }
);

export type InsightsTimeTrendingStatementsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InsightsTimeTrendingStatementsQuery = (
  { __typename?: 'Query' }
  & { overallIndex: (
    { __typename?: 'OverallScoreType' }
    & InsightsOverallScoreFragment
  ), statements: Array<(
    { __typename?: 'StatementScoreType' }
    & InsightsStatementScoreFragment
  )> }
);

export type OrganizationFilterValuesQueryVariables = Exact<{
  dtCode: Scalars['String'];
}>;


export type OrganizationFilterValuesQuery = (
  { __typename?: 'Query' }
  & { filterValues: Array<(
    { __typename?: 'FilterValueType' }
    & OrganizationFilterValueFragment
  )> }
);

export type PublicConstantsQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicConstantsQuery = (
  { __typename?: 'Query' }
  & { publicConstants: (
    { __typename?: 'PublicConstants' }
    & Pick<PublicConstants, 'trustIndexCertificationMinimumScore' | 'fortuneListSubmissionDate' | 'certificationMarketingToolkitUrl' | 'cultureBriefDisabledFieldsWhenCompleted' | 'cultureBriefRequiredFields' | 'cultureBriefMaxImages'>
    & { standardResidentFilterValueChoices: Array<(
      { __typename?: 'StandardFilterValueChoicesByDataTypeType' }
      & Pick<StandardFilterValueChoicesByDataTypeType, 'dtCode' | 'standardChoices'>
    )> }
  ) }
);

export type SettingsGroupsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type SettingsGroupsQuery = (
  { __typename?: 'Query' }
  & { groups: Array<(
    { __typename?: 'GroupNode' }
    & SettingsGroupFragment
  )>, users: (
    { __typename?: 'UserNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'UserNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'UserNode' }
        & SettingsGroupUserFragment
      )> }
    )>> }
  ), surveys: (
    { __typename?: 'SurveyNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'SurveyNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'SurveyNode' }
        & Pick<SurveyNode, 'uuid' | 'name'>
      )> }
    )>> }
  ) }
);

export type SettingsOrganizationServiceAreasQueryVariables = Exact<{
  departmentDtCode: Scalars['String'];
}>;


export type SettingsOrganizationServiceAreasQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'id'>
    & { organization: (
      { __typename?: 'OrganizationType' }
      & Pick<OrganizationType, 'uuid'>
      & { serviceAreas: Array<(
        { __typename?: 'ServiceAreaType' }
        & OrganizationServiceAreasFragment
      )> }
    ) }
  ), departments: Array<(
    { __typename?: 'FilterValueType' }
    & Pick<FilterValueType, 'uuid' | 'name'>
  )> }
);

export type SettingsUsersQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy?: Maybe<Scalars['String']>;
  sortDescending?: Maybe<Scalars['Boolean']>;
}>;


export type SettingsUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserNodeConnection' }
    & Pick<UserNodeConnection, 'totalCount'>
    & { edges: Array<Maybe<(
      { __typename?: 'UserNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'UserNode' }
        & SettingsUserFragment
      )> }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type SettingsValidGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsValidGroupsQuery = (
  { __typename?: 'Query' }
  & { validGroups?: Maybe<Array<(
    { __typename?: 'GroupNode' }
    & SettingsGroupFragment
  )>> }
);

export type SurveysAvailableSurveyQuestionsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type SurveysAvailableSurveyQuestionsQuery = (
  { __typename?: 'Query' }
  & { availableSurveyQuestions: Array<(
    { __typename?: 'QuestionType' }
    & Pick<QuestionType, 'uuid' | 'text' | 'benchmarkCode' | 'isDisabled' | 'required' | 'category' | 'residentFocus'>
    & { dependsOn?: Maybe<(
      { __typename?: 'QuestionType' }
      & Pick<QuestionType, 'benchmarkCode'>
    )>, filters?: Maybe<(
      { __typename?: 'QuestionFiltersType' }
      & Pick<QuestionFiltersType, 'levelsOfCare' | 'participantTypes'>
    )> }
  )> }
);

export type SurveysParticipantsBySurveyQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  pageSize: Scalars['Int'];
  page: Scalars['Int'];
  sortBy?: Maybe<Scalars['String']>;
  sortDescending?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
}>;


export type SurveysParticipantsBySurveyQuery = (
  { __typename?: 'Query' }
  & { participantsBySurvey: (
    { __typename?: 'ParticipantsPageType' }
    & Pick<ParticipantsPageType, 'total'>
    & { participants: Array<(
      { __typename?: 'ParticipantType' }
      & Pick<ParticipantType, 'uuid' | 'surveyCode' | 'firstName' | 'personalEmail' | 'workEmail' | 'mobilePhone'>
      & { dataTypeValues: Array<(
        { __typename?: 'DataTypeValueType' }
        & Pick<DataTypeValueType, 'dtCode' | 'value'>
      )> }
    )> }
  ), survey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'participantTemplateUrl' | 'clientTemplateUrl' | 'uuid'>
    & { participantsUpload?: Maybe<(
      { __typename?: 'FileImportType' }
      & Pick<FileImportType, 'fileName'>
    )>, clientsUpload?: Maybe<(
      { __typename?: 'FileImportType' }
      & Pick<FileImportType, 'fileName'>
    )>, responseRate: (
      { __typename?: 'ResponseRateType' }
      & ResponseRateFragment
    ), dataTypeOptions: Array<(
      { __typename?: 'DataTypeOptionType' }
      & Pick<DataTypeOptionType, 'code' | 'visibleName' | 'required' | 'mustSyncWithClient'>
      & { standards?: Maybe<Array<(
        { __typename?: 'StandardType' }
        & Pick<StandardType, 'text' | 'value'>
      )>> }
    )> }
  ) }
);

export type SurveysParticipantsUploadQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type SurveysParticipantsUploadQuery = (
  { __typename?: 'Query' }
  & { survey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'uuid'>
    & { participantsUpload?: Maybe<(
      { __typename?: 'FileImportType' }
      & Pick<FileImportType, 'fileName' | 'updated'>
      & { errors: Array<(
        { __typename?: 'ImportErrorType' }
        & Pick<ImportErrorType, 'code' | 'dtCode' | 'dtName' | 'cell'>
      )>, summary: (
        { __typename?: 'ImportSummaryType' }
        & Pick<ImportSummaryType, 'processed' | 'deleted' | 'added' | 'updated'>
        & { filters?: Maybe<Array<Maybe<(
          { __typename?: 'FilterValueSummaryType' }
          & Pick<FilterValueSummaryType, 'filterTypeName' | 'filterValueNames'>
        )>>> }
      ) }
    )> }
  ) }
);

export type SurveysQuestionQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type SurveysQuestionQuery = (
  { __typename?: 'Query' }
  & { question?: Maybe<(
    { __typename?: 'QuestionType' }
    & QuestionFragment
  )> }
);

export type SurveysQuestionsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type SurveysQuestionsQuery = (
  { __typename?: 'Query' }
  & { questions: Array<(
    { __typename?: 'QuestionType' }
    & QuestionFragment
  )> }
);

export type SurveysQuestionsByCategoryQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type SurveysQuestionsByCategoryQuery = (
  { __typename?: 'Query' }
  & { questionsByCategory?: Maybe<Array<Maybe<(
    { __typename?: 'QuestionsByCategory' }
    & Pick<QuestionsByCategory, 'canAddCustom' | 'category'>
    & { questionsByFocus?: Maybe<Array<Maybe<(
      { __typename?: 'QuestionsByFocusType' }
      & Pick<QuestionsByFocusType, 'canAddCustom' | 'focus'>
      & { questions?: Maybe<Array<Maybe<(
        { __typename?: 'QuestionType' }
        & QuestionFragment
      )>>> }
    )>>> }
  )>>> }
);

export type SurveysSurveyQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type SurveysSurveyQuery = (
  { __typename?: 'Query' }
  & { survey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'maxSmsNotifications' | 'numberOfMaxScheduledNotifications' | 'maxScheduledNotificationDate' | 'translationsRequired' | 'allowedCategoriesForCustomQuestions' | 'allowedCategoriesForOptionalQuestions' | 'hasMissingTranslations' | 'hasIntervalNotifications' | 'hasSurveyResponseNotifications' | 'hasAverageScoreNotifications' | 'hasQuestionScoreNotifications' | 'hasIngestFailureReports' | 'surveyPortalUrl' | 'includeForUsNews' | 'inviteMethod' | 'phoneScript' | 'missingPhonesErrorLevel' | 'usesSamplePoolDataSource' | 'isRecurring'>
    & { responseRate: (
      { __typename?: 'ResponseRateType' }
      & ResponseRateFragment
    ), languages: Array<(
      { __typename?: 'LanguageType' }
      & OrganizationLanguageFragment
    )>, welcomeMessages: Array<(
      { __typename?: 'SurveyTextType' }
      & Pick<SurveyTextType, 'uuid' | 'key' | 'text'>
    )>, defaultWelcomeMessages: Array<(
      { __typename?: 'SurveyTextType' }
      & Pick<SurveyTextType, 'uuid' | 'key' | 'text'>
    )> }
    & SurveysSurveyFragment
  ) }
);

export type SurveysSurveyNotificationsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type SurveysSurveyNotificationsQuery = (
  { __typename?: 'Query' }
  & { survey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'uuid'>
    & { scheduledNotifications: Array<(
      { __typename?: 'ScheduledNotificationType' }
      & SurveysScheduledNotificationFragment
    )>, intervalNotifications: Array<(
      { __typename?: 'IntervalNotificationType' }
      & SurveysIntervalNotificationFragment
    )> }
  ) }
);

export type SurveysSurveyResponseNotificationsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type SurveysSurveyResponseNotificationsQuery = (
  { __typename?: 'Query' }
  & { survey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'uuid' | 'type'>
    & { groupsWithSurveyAccess: Array<(
      { __typename?: 'GroupNode' }
      & Pick<GroupNode, 'uuid' | 'name'>
    )>, groupsSurveyResponseNotifications: Array<(
      { __typename?: 'GroupsSurveyResponseNotificationType' }
      & Pick<GroupsSurveyResponseNotificationType, 'enabled' | 'notificationType'>
      & { groups: Array<(
        { __typename?: 'GroupNode' }
        & Pick<GroupNode, 'uuid' | 'name' | 'allSurveys'>
      )> }
    )>, groupsAverageScoreNotifications: Array<(
      { __typename?: 'GroupsSurveyAverageScoreNotificationType' }
      & Pick<GroupsSurveyAverageScoreNotificationType, 'enabled' | 'scoreType' | 'frequency'>
      & { groups: Array<(
        { __typename?: 'GroupNode' }
        & Pick<GroupNode, 'uuid' | 'name' | 'allSurveys'>
      )> }
    )>, groupsQuestionScoreNotifications: Array<(
      { __typename?: 'GroupsSurveyQuestionScoreNotificationType' }
      & Pick<GroupsSurveyQuestionScoreNotificationType, 'uuid' | 'notificationName' | 'enabled' | 'scoreType' | 'frequency' | 'minScore' | 'maxScore'>
      & { statement: (
        { __typename?: 'QuestionType' }
        & Pick<QuestionType, 'uuid' | 'text'>
      ), openEndedQuestion?: Maybe<(
        { __typename?: 'QuestionType' }
        & Pick<QuestionType, 'uuid' | 'text'>
      )>, groups: Array<(
        { __typename?: 'GroupNode' }
        & Pick<GroupNode, 'uuid' | 'name' | 'allSurveys'>
      )> }
    )> }
  ) }
);

export type SurveysSurveyResponseNotificationsGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type SurveysSurveyResponseNotificationsGroupsQuery = (
  { __typename?: 'Query' }
  & { groups: Array<(
    { __typename?: 'GroupNode' }
    & SettingsGroupFragment
  )> }
);

export type SurveysSurveyResponseRateQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  byClient: Scalars['Boolean'];
}>;


export type SurveysSurveyResponseRateQuery = (
  { __typename?: 'Query' }
  & { survey: (
    { __typename?: 'SurveyNode' }
    & Pick<SurveyNode, 'hasLessThanMinShowableResults'>
    & { insightsSurvey: (
      { __typename?: 'InsightsSurveyNode' }
      & { responseRate: (
        { __typename?: 'ResponseRateType' }
        & ResponseRateFragment
      ), completionRate: (
        { __typename?: 'CompletionRateType' }
        & Pick<CompletionRateType, 'started'>
      ) }
    ) }
  ) }
);

export type SurveysSurveySummaryQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
  responseRateByClient: Scalars['Boolean'];
}>;


export type SurveysSurveySummaryQuery = (
  { __typename?: 'Query' }
  & { surveySummary: (
    { __typename?: 'SurveySummaryType' }
    & Pick<SurveySummaryType, 'surveyPortalUrl' | 'canSchedule' | 'canUnschedule' | 'numFinishedResponses' | 'numTotalResponses' | 'numLocationsMissingRequiredData'>
    & { overallIndexScore?: Maybe<(
      { __typename?: 'OverallScoreType' }
      & Pick<OverallScoreType, 'positive' | 'inconsistent' | 'negative'>
    )>, design: (
      { __typename?: 'DesignSectionType' }
      & Pick<DesignSectionType, 'ready' | 'data' | 'warnings' | 'errors' | 'name' | 'startDate' | 'endDate'>
      & { languages: Array<(
        { __typename?: 'LanguageType' }
        & Pick<LanguageType, 'nameTranslated'>
        & OrganizationLanguageFragment
      )> }
    ), questions: (
      { __typename?: 'QuestionsSectionType' }
      & Pick<QuestionsSectionType, 'ready' | 'data' | 'warnings' | 'errors' | 'trustIndex' | 'demographic' | 'openEnded' | 'npsQuestions' | 'custom' | 'shortAnswer' | 'longAnswer' | 'multipleChoice' | 'multiselect' | 'linearScale' | 'statements' | 'hasOnlineReviewQuestion' | 'locationsCount' | 'locationsWithOnlineReviewSitesCount'>
      & { personalInfoQuestions?: Maybe<Array<(
        { __typename?: 'PersonalInfoQuestionType' }
        & Pick<PersonalInfoQuestionType, 'code' | 'name'>
      )>> }
    ), translations?: Maybe<(
      { __typename?: 'TranslationsSectionType' }
      & Pick<TranslationsSectionType, 'ready' | 'data' | 'warnings' | 'errors' | 'missing' | 'translated'>
    )>, participants?: Maybe<(
      { __typename?: 'ParticipantsSectionType' }
      & Pick<ParticipantsSectionType, 'ready' | 'data' | 'warnings' | 'errors' | 'total' | 'clientsWithoutParticipantCount' | 'workEmails' | 'personalEmails' | 'mobilePhones'>
    )>, notifications?: Maybe<(
      { __typename?: 'NotificationsSectionType' }
      & Pick<NotificationsSectionType, 'ready' | 'data' | 'warnings' | 'errors' | 'total'>
      & { notifications: Array<(
        { __typename?: 'NotificationType' }
        & Pick<NotificationType, 'uuid' | 'workEmail' | 'personalEmail' | 'sms' | 'when' | 'days' | 'sentTimestamp'>
      )> }
    )>, filterValues?: Maybe<(
      { __typename?: 'FilterValuesSectionType' }
      & Pick<FilterValuesSectionType, 'ready' | 'errors' | 'warnings' | 'data' | 'mappedFilterValuesCount' | 'totalFilterValuesCount'>
    )>, settings?: Maybe<(
      { __typename?: 'SettingsSectionType' }
      & Pick<SettingsSectionType, 'ready' | 'data' | 'warnings' | 'errors' | 'surveyCodeName' | 'surveyCodeHelpText' | 'locs' | 'depts' | 'missingServices'>
    )> }
  ) }
);

export type SurveysSurveysQueryVariables = Exact<{
  surveyType?: Maybe<SurveyTypeEnum>;
}>;


export type SurveysSurveysQuery = (
  { __typename?: 'Query' }
  & { surveys: (
    { __typename?: 'SurveyNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'SurveyNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'SurveyNode' }
        & Pick<SurveyNode, 'isDefaultClientBasedResponseRateReport' | 'hasLessThanMinShowableResults' | 'editable'>
        & SurveyCoreFragment
      )> }
    )>> }
  ) }
);

export type SurveysTranslationsQueryVariables = Exact<{
  surveyUuid: Scalars['String'];
}>;


export type SurveysTranslationsQuery = (
  { __typename?: 'Query' }
  & { translations: Array<(
    { __typename?: 'TranslationType' }
    & Pick<TranslationType, 'uuid' | 'objectUuid' | 'objectType' | 'englishText' | 'text'>
    & { language: (
      { __typename?: 'LanguageType' }
      & Pick<LanguageType, 'code' | 'name'>
    ) }
  )> }
);

export const ActionPlansActionItemTaskCommentFragmentDoc = gql`
    fragment ActionPlansActionItemTaskComment on ActionItemTaskCommentNode {
  id
  uuid
  created
  updated
  text
  user {
    id
    name
  }
  mentions {
    index
    user {
      id
      name
    }
  }
}
    `;
export const ActionPlansActionItemTaskCommentNodeConnectionFragmentDoc = gql`
    fragment ActionPlansActionItemTaskCommentNodeConnection on ActionItemTaskCommentNodeConnection {
  totalCount
  pageInfo {
    endCursor
    hasNextPage
  }
  edges {
    node {
      ...ActionPlansActionItemTaskComment
    }
  }
}
    ${ActionPlansActionItemTaskCommentFragmentDoc}`;
export const ActionPlansActionItemTaskFragmentDoc = gql`
    fragment ActionPlansActionItemTask on ActionItemTaskNode {
  uuid
  task
  updated
  dueDate
  status
  owner
  comments {
    ...ActionPlansActionItemTaskCommentNodeConnection
  }
}
    ${ActionPlansActionItemTaskCommentNodeConnectionFragmentDoc}`;
export const ActionPlansActionItemFragmentDoc = gql`
    fragment ActionPlansActionItem on ActionItemNode {
  uuid
  created
  statement {
    uuid
    code
    text
    focus
    residentResultsGroup
    survey {
      uuid
      type
      productType
    }
  }
  customStatement
  customStatementProductType
  actionItemTasks {
    ...ActionPlansActionItemTask
  }
}
    ${ActionPlansActionItemTaskFragmentDoc}`;
export const ActionPlansActionPlanFragmentDoc = gql`
    fragment ActionPlansActionPlan on ActionPlanNode {
  uuid
  created
  surveyTi {
    uuid
  }
  surveyResidentEngagement {
    uuid
  }
  actionItems {
    ...ActionPlansActionItem
  }
  usersWithAccess {
    id
    name
  }
}
    ${ActionPlansActionItemFragmentDoc}`;
export const ActionPlansMgmtCountsFragmentDoc = gql`
    fragment ActionPlansMgmtCounts on ActionItemsCountsType {
  activeStatements
  notStarted
  inProgress
  onHold
  complete
  incomplete
}
    `;
export const BenchmarkFragmentDoc = gql`
    fragment Benchmark on BenchmarkNode {
  benchmarkType
  benchmarkUuid
  name
  group
  lessThanMin
  minShowableResults
}
    `;
export const CultureBriefFragmentDoc = gql`
    fragment CultureBrief on CultureBriefType {
  uuid
  updated
  status
  numberOfLocations
  yearFounded
  organizationName
  industrySubVertical
  isSubsidiary
  organizationType
  mainTypeOfCare
  city
  state
  website
  facebookUrl
  twitterUrl
  instagramUrl
  linkedinUrl
  indeedUrl
  description
  totalNumberOfEmployees
  pastTotalNumberOfEmployees
  totalApplicants
  totalOpenRoles
  recommendationsPercentage
  averageNumberOfEmployees
  numberOfEmployeesLeft
  carbonEmissionsReductionPercentage
  numberOfEmployeesWithHealthInsurance
  numberOfEmployeesEligibleForHealthInsurance
  employeeBenefits
  additionalEmployeeBenefits
  percentageOfEmployeesWithBigWages
  percentageOfImmigrantEmployees
  totalBoardComposition
  totalBoardWomen
  totalBoardMinoritiesRace
  totalBoardMinoritiesLgbt
  totalCeoReportsComposition
  totalCeoReportsWomen
  totalCeoReportsMinoritiesRace
  totalCeoReportsMinoritiesLgbt
  hasDiversityEquity
  diversityEquityDescription
  reasonsToWorkForCompany
  companyMission
  careersPageLink
  ceoFirstName
  ceoLastName
  ceoTitle
  ceoPhoneNumber
  ceoEmail
  mainContactFirstName
  mainContactLastName
  mainContactTitle
  mainContactPhoneNumber
  mainContactEmail
  marketingContactFirstName
  marketingContactLastName
  marketingContactTitle
  marketingContactPhoneNumber
  marketingContactEmail
}
    `;
export const CultureBriefImagesFragmentDoc = gql`
    fragment CultureBriefImages on CultureBriefType {
  images {
    uuid
    url
    imageType
    caption
  }
}
    `;
export const InsightsCultureFragmentDoc = gql`
    fragment InsightsCulture on CultureType {
  label
  score
  top {
    demographicCode
    demographic
    valueCode
    value
    score
  }
}
    `;
export const InsightsCultureChangeFragmentDoc = gql`
    fragment InsightsCultureChange on CultureChangeType {
  demographicCode
  demographic
  valueCode
  value
  change
}
    `;
export const InsightsGroupedScoreFragmentDoc = gql`
    fragment InsightsGroupedScore on OverallScoreType {
  positive
  benchmarkPositive
  inconsistent
  benchmarkInconsistent
  negative
  benchmarkNegative
  label
  count
}
    `;
export const GroupedScoresFragmentDoc = gql`
    fragment GroupedScores on GroupedScoresType {
  dataType
  scores {
    label
    count
    positive
  }
}
    `;
export const InsightsOverallScoreFragmentDoc = gql`
    fragment InsightsOverallScore on OverallScoreType {
  positive
  benchmarkPositive
  inconsistent
  benchmarkInconsistent
  negative
  benchmarkNegative
}
    `;
export const InsightsStatementScoreFragmentDoc = gql`
    fragment InsightsStatementScore on StatementScoreType {
  code
  label
  focus
  residentFocus
  count
  positive
  benchmarkPositive
  inconsistent
  benchmarkInconsistent
  negative
  benchmarkNegative
  residentResultsGroup
}
    `;
export const OrganizationBenchmarkLocFragmentDoc = gql`
    fragment OrganizationBenchmarkLOC on BenchmarkLevelOfCareType {
  checked
  required
  disabled
  code
  name
  customName
  seniorLiving
  atHome
  multipleLocations
}
    `;
export const OrganizationBenchmarkDepartmentFragmentDoc = gql`
    fragment OrganizationBenchmarkDepartment on BenchmarkDepartmentType {
  checked
  code
  name
  customName
  locs
}
    `;
export const OrganizationBenchmarkShiftFragmentDoc = gql`
    fragment OrganizationBenchmarkShift on BenchmarkShiftType {
  checked
  code
  name
  customName
}
    `;
export const OrganizationSolutionFragmentDoc = gql`
    fragment OrganizationSolution on SolutionType {
  adminInvitesOnly
  dataAccessLevels
  insights
  insightsSnapshots
  insightsKeyDemographics
  insightsStatements
  insightsComments
  insightsEnhancedComments
  insightsComparisons
  insightsComparisonsGrid
  insightsHeatmap
  insightsTimeTrending
  certificationType
  certificationReport
  engagementReport
  showUnavailableTabs
  actionPlans
  actionPlansCustomStatements
  actionPlansManagement
  filters
  name
  benchmarkPastSurveys
  benchmarkInternal
  statementsExcelDownload
  commentsExcelDownload
  engagementOverlay
  maxSurveyNotifications
  npsDownload
}
    `;
export const OrganizationResidentSolutionFragmentDoc = gql`
    fragment OrganizationResidentSolution on ResidentSolutionType {
  adminInvitesOnly
  dataAccessLevels
  insights
  insightsSnapshots
  insightsKeyDemographics
  insightsStatements
  insightsComments
  insightsEnhancedComments
  insightsComparisons
  insightsComparisonsGrid
  insightsHeatmap
  insightsTimeTrending
  insightsTestimonials
  showUnavailableTabs
  actionPlans
  actionPlansCustomStatements
  actionPlansManagement
  filters
  name
  statementsExcelDownload
  npsDownload
  commentsExcelDownload
  resultsSummaryReportDownload
  benchmarkPastSurveys
  benchmarkInternal
  onlineReviewSites
  hasSurveyResponseNotifications
  hasScoreNotifications
  maxSurveyNotifications
  engagementOverlay
  standardServicesOnly
  openLinkSurveys
}
    `;
export const OrganizationLanguageFragmentDoc = gql`
    fragment OrganizationLanguage on LanguageType {
  code
  name
}
    `;
export const OrganizationFragmentDoc = gql`
    fragment Organization on OrganizationType {
  uuid
  integrationId
  name
  slug
  seniorLiving
  atHome
  isCcrc
  multipleLocations
  surveyCodeName
  surveyCodeHelpText
  residentSurveyCodeName
  residentSurveyCodeHelpText
  lastTrustIndexDate
  participatesInUsNews
  isTestAccount
  hasKeyFocusAreaCarousel
  isGptwEnabled
  collectCmsAndNcalNumbers
  vicidialDid
  benchmarkLevelsOfCare {
    ...OrganizationBenchmarkLOC
  }
  benchmarkDepartments {
    ...OrganizationBenchmarkDepartment
  }
  benchmarkShifts {
    ...OrganizationBenchmarkShift
  }
  solution {
    ...OrganizationSolution
  }
  residentSolution {
    ...OrganizationResidentSolution
  }
  languages {
    ...OrganizationLanguage
  }
  enabledSolutionFields {
    field
    enabled
  }
  activeFilterTypes(surveyProductType: RESIDENT) {
    dtCode
    name
    index
  }
  combinedContactsTemplateUrl
  employeeContactsTemplateUrl
}
    ${OrganizationBenchmarkLocFragmentDoc}
${OrganizationBenchmarkDepartmentFragmentDoc}
${OrganizationBenchmarkShiftFragmentDoc}
${OrganizationSolutionFragmentDoc}
${OrganizationResidentSolutionFragmentDoc}
${OrganizationLanguageFragmentDoc}`;
export const OrganizationFilterValueFragmentDoc = gql`
    fragment OrganizationFilterValue on FilterValueType {
  uuid
  name
  nickname
  addressLine1
  addressLine2
  city
  state
  postalCode
  website
  phoneNumber
  hasEntranceFee
  isRental
  archived
  onlineReviewSites {
    reviewSiteName
    externalId
  }
  usNewsLocationId
  usNewsLocationName
  medicareProviderNumber
  ncalIdNumber
  hasMissingRequiredValues
  logo
}
    `;
export const OrganizationServiceAreasFragmentDoc = gql`
    fragment OrganizationServiceAreas on ServiceAreaType {
  customLabel
  label
  code
  isActive
  required
  disabled
  levelsOfCare {
    code
    name
    required
  }
  departments {
    uuid
    name
  }
}
    `;
export const ResponseRateFragmentDoc = gql`
    fragment ResponseRate on ResponseRateType {
  finished
  total
  rate
}
    `;
export const SettingsGroupUserFragmentDoc = gql`
    fragment SettingsGroupUser on UserNode {
  id
  name
  email
  isAdmin
}
    `;
export const SettingsGroupFragmentDoc = gql`
    fragment SettingsGroup on GroupNode {
  uuid
  name
  createdBy {
    firstName
  }
  isAdmin
  accessToSurveyProduct
  canAccessDataStructure
  canCreateSurveys
  canManageActionPlans
  canUseActionPlans
  canUseAnalytics
  canAccessSurveySettings
  canAccessGeneralSettings
  canManageCertifications
  canViewCertificationResults
  canInviteUsers
  allSurveys
  insightsModules
  residentInsightsModules
  residentAccessToSurveyProduct
  residentCanCreateSurveys
  residentCanAccessSurveySettings
  canAccessLonelinessResults
  surveys {
    uuid
    name
  }
  users {
    ...SettingsGroupUser
  }
}
    ${SettingsGroupUserFragmentDoc}`;
export const UserCoreFragmentDoc = gql`
    fragment UserCore on UserNode {
  id
  isAdmin
  firstName
  lastName
  name
  email
  title
  accessToSurveyProduct
  canCreateSurveys
  residentAccessToSurveyProduct
  residentCanCreateSurveys
  canUseAnalytics
  canUploadCombinedContacts
  canUploadEmployeeContacts
}
    `;
export const FilterValueFragmentDoc = gql`
    fragment FilterValue on FilterValueType {
  id
  uuid
  name
}
    `;
export const FilterTypeFragmentDoc = gql`
    fragment FilterType on FilterTypeType {
  filterTypeUuid
  dtCode
  name
  namePlural
  index
  accessToAll
  isLocation
  insightsProductType
  accessControlProductType
  surveyProductType
  filterValues {
    ...FilterValue
  }
}
    ${FilterValueFragmentDoc}`;
export const SettingsUserFragmentDoc = gql`
    fragment SettingsUser on UserNode {
  ...UserCore
  isActive
  status
  lastActivity
  filterPermissions {
    ...FilterType
  }
  groups {
    uuid
    name
    residentAccessToSurveyProduct
    accessToSurveyProduct
    isAdmin
  }
}
    ${UserCoreFragmentDoc}
${FilterTypeFragmentDoc}`;
export const SurveysIntervalNotificationFragmentDoc = gql`
    fragment SurveysIntervalNotification on IntervalNotificationType {
  uuid
  smsOptional
  smsRequired
  emailSubject
  emailTextOptional1
  emailTextRequired
  emailTextOptional2
  days
  sms
  personalEmail
  workEmail
  active
  errors
  firstSentTimestamp
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on QuestionType {
  uuid
  text
  benchmarkCode
  isCustom
  kind
  focus
  residentFocus
  category
  required
  index
  infoLabel
  isStatement
  isOpenEnded
  dependsOn {
    benchmarkCode
  }
  choices {
    uuid
    text
    value
  }
  availableChoices {
    uuid
    text
  }
}
    `;
export const SurveysScheduledNotificationFragmentDoc = gql`
    fragment SurveysScheduledNotification on ScheduledNotificationType {
  uuid
  smsOptional
  smsRequired
  emailSubject
  emailTextOptional1
  emailTextRequired
  emailTextOptional2
  when
  sms
  personalEmail
  workEmail
  active
  errors
  firstSentTimestamp
}
    `;
export const SurveyCoreFragmentDoc = gql`
    fragment SurveyCore on SurveyNode {
  uuid
  status
  type
  productType
  minShowableResults
  name
  startDate
  endDate
  hasConfidentialResults
  distributionType
}
    `;
export const EligibleParticipantSurveyConfigFragmentDoc = gql`
    fragment EligibleParticipantSurveyConfig on EligibleParticipantSurveyConfigType {
  participantTypeOptions
  levelOfCare
  enabled
}
    `;
export const SurveysSurveyFragmentDoc = gql`
    fragment SurveysSurvey on SurveyNode {
  ...SurveyCore
  editable
  description
  isDefaultClientBasedResponseRateReport
  filterTypeUuids
  previewUrl
  reportPassword
  associatedSurvey {
    uuid
    status
    name
    startDate
    endDate
    type
  }
  distributionType
  participantDataSource
  filterTypeSettings {
    dtCode
    reportingType
  }
  eligibleParticipantSurveyConfigs {
    ...EligibleParticipantSurveyConfig
  }
}
    ${SurveyCoreFragmentDoc}
${EligibleParticipantSurveyConfigFragmentDoc}`;
export const AnalyticsSurveysDocument = gql`
    query AnalyticsSurveys {
  surveys {
    edges {
      node {
        ...SurveyCore
        responseRate {
          ...ResponseRate
        }
        filterTypeUuids
        defaultBenchmark {
          ...Benchmark
        }
        isDefaultClientBasedResponseRateReport
      }
    }
  }
}
    ${SurveyCoreFragmentDoc}
${ResponseRateFragmentDoc}
${BenchmarkFragmentDoc}`;

/**
 * __useAnalyticsSurveysQuery__
 *
 * To run a query within a React component, call `useAnalyticsSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsSurveysQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsSurveysQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsSurveysQuery, AnalyticsSurveysQueryVariables>) {
        return Apollo.useQuery<AnalyticsSurveysQuery, AnalyticsSurveysQueryVariables>(AnalyticsSurveysDocument, baseOptions);
      }
export function useAnalyticsSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsSurveysQuery, AnalyticsSurveysQueryVariables>) {
          return Apollo.useLazyQuery<AnalyticsSurveysQuery, AnalyticsSurveysQueryVariables>(AnalyticsSurveysDocument, baseOptions);
        }
export type AnalyticsSurveysQueryHookResult = ReturnType<typeof useAnalyticsSurveysQuery>;
export type AnalyticsSurveysLazyQueryHookResult = ReturnType<typeof useAnalyticsSurveysLazyQuery>;
export type AnalyticsSurveysQueryResult = Apollo.QueryResult<AnalyticsSurveysQuery, AnalyticsSurveysQueryVariables>;
export const ActionPlansAddActionPlanCustomStatementDocument = gql`
    mutation ActionPlansAddActionPlanCustomStatement($customStatement: String!, $customStatementProductType: SurveyProductTypeEnum, $tasks: [String!]!, $userUuid: String) {
  addActionPlanCustomStatement(customStatement: $customStatement, customStatementProductType: $customStatementProductType, tasks: $tasks, userUuid: $userUuid) {
    errors
    actionPlan {
      ...ActionPlansActionPlan
    }
  }
}
    ${ActionPlansActionPlanFragmentDoc}`;
export type ActionPlansAddActionPlanCustomStatementMutationFn = Apollo.MutationFunction<ActionPlansAddActionPlanCustomStatementMutation, ActionPlansAddActionPlanCustomStatementMutationVariables>;

/**
 * __useActionPlansAddActionPlanCustomStatementMutation__
 *
 * To run a mutation, you first call `useActionPlansAddActionPlanCustomStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionPlansAddActionPlanCustomStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionPlansAddActionPlanCustomStatementMutation, { data, loading, error }] = useActionPlansAddActionPlanCustomStatementMutation({
 *   variables: {
 *      customStatement: // value for 'customStatement'
 *      customStatementProductType: // value for 'customStatementProductType'
 *      tasks: // value for 'tasks'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useActionPlansAddActionPlanCustomStatementMutation(baseOptions?: Apollo.MutationHookOptions<ActionPlansAddActionPlanCustomStatementMutation, ActionPlansAddActionPlanCustomStatementMutationVariables>) {
        return Apollo.useMutation<ActionPlansAddActionPlanCustomStatementMutation, ActionPlansAddActionPlanCustomStatementMutationVariables>(ActionPlansAddActionPlanCustomStatementDocument, baseOptions);
      }
export type ActionPlansAddActionPlanCustomStatementMutationHookResult = ReturnType<typeof useActionPlansAddActionPlanCustomStatementMutation>;
export type ActionPlansAddActionPlanCustomStatementMutationResult = Apollo.MutationResult<ActionPlansAddActionPlanCustomStatementMutation>;
export type ActionPlansAddActionPlanCustomStatementMutationOptions = Apollo.BaseMutationOptions<ActionPlansAddActionPlanCustomStatementMutation, ActionPlansAddActionPlanCustomStatementMutationVariables>;
export const ActionPlansAddActionPlanStatementsDocument = gql`
    mutation ActionPlansAddActionPlanStatements($statementUuids: [String!]!, $includeDefaultActionItems: Boolean!, $userUuid: String) {
  addActionPlanStatements(statementUuids: $statementUuids, includeDefaultActionItems: $includeDefaultActionItems, userUuid: $userUuid) {
    errors
    actionPlan {
      ...ActionPlansActionPlan
    }
  }
}
    ${ActionPlansActionPlanFragmentDoc}`;
export type ActionPlansAddActionPlanStatementsMutationFn = Apollo.MutationFunction<ActionPlansAddActionPlanStatementsMutation, ActionPlansAddActionPlanStatementsMutationVariables>;

/**
 * __useActionPlansAddActionPlanStatementsMutation__
 *
 * To run a mutation, you first call `useActionPlansAddActionPlanStatementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionPlansAddActionPlanStatementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionPlansAddActionPlanStatementsMutation, { data, loading, error }] = useActionPlansAddActionPlanStatementsMutation({
 *   variables: {
 *      statementUuids: // value for 'statementUuids'
 *      includeDefaultActionItems: // value for 'includeDefaultActionItems'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useActionPlansAddActionPlanStatementsMutation(baseOptions?: Apollo.MutationHookOptions<ActionPlansAddActionPlanStatementsMutation, ActionPlansAddActionPlanStatementsMutationVariables>) {
        return Apollo.useMutation<ActionPlansAddActionPlanStatementsMutation, ActionPlansAddActionPlanStatementsMutationVariables>(ActionPlansAddActionPlanStatementsDocument, baseOptions);
      }
export type ActionPlansAddActionPlanStatementsMutationHookResult = ReturnType<typeof useActionPlansAddActionPlanStatementsMutation>;
export type ActionPlansAddActionPlanStatementsMutationResult = Apollo.MutationResult<ActionPlansAddActionPlanStatementsMutation>;
export type ActionPlansAddActionPlanStatementsMutationOptions = Apollo.BaseMutationOptions<ActionPlansAddActionPlanStatementsMutation, ActionPlansAddActionPlanStatementsMutationVariables>;
export const ActionPlansDeleteActionItemDocument = gql`
    mutation ActionPlansDeleteActionItem($actionItemUuid: String!, $userUuid: String) {
  deleteActionItem(actionItemUuid: $actionItemUuid, userUuid: $userUuid) {
    errors
    actionPlan {
      ...ActionPlansActionPlan
    }
  }
}
    ${ActionPlansActionPlanFragmentDoc}`;
export type ActionPlansDeleteActionItemMutationFn = Apollo.MutationFunction<ActionPlansDeleteActionItemMutation, ActionPlansDeleteActionItemMutationVariables>;

/**
 * __useActionPlansDeleteActionItemMutation__
 *
 * To run a mutation, you first call `useActionPlansDeleteActionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionPlansDeleteActionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionPlansDeleteActionItemMutation, { data, loading, error }] = useActionPlansDeleteActionItemMutation({
 *   variables: {
 *      actionItemUuid: // value for 'actionItemUuid'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useActionPlansDeleteActionItemMutation(baseOptions?: Apollo.MutationHookOptions<ActionPlansDeleteActionItemMutation, ActionPlansDeleteActionItemMutationVariables>) {
        return Apollo.useMutation<ActionPlansDeleteActionItemMutation, ActionPlansDeleteActionItemMutationVariables>(ActionPlansDeleteActionItemDocument, baseOptions);
      }
export type ActionPlansDeleteActionItemMutationHookResult = ReturnType<typeof useActionPlansDeleteActionItemMutation>;
export type ActionPlansDeleteActionItemMutationResult = Apollo.MutationResult<ActionPlansDeleteActionItemMutation>;
export type ActionPlansDeleteActionItemMutationOptions = Apollo.BaseMutationOptions<ActionPlansDeleteActionItemMutation, ActionPlansDeleteActionItemMutationVariables>;
export const ActionPlansDeleteActionItemTaskDocument = gql`
    mutation ActionPlansDeleteActionItemTask($taskId: String!, $userUuid: String) {
  deleteActionItemTask(taskId: $taskId, userUuid: $userUuid) {
    actionPlan {
      ...ActionPlansActionPlan
    }
  }
}
    ${ActionPlansActionPlanFragmentDoc}`;
export type ActionPlansDeleteActionItemTaskMutationFn = Apollo.MutationFunction<ActionPlansDeleteActionItemTaskMutation, ActionPlansDeleteActionItemTaskMutationVariables>;

/**
 * __useActionPlansDeleteActionItemTaskMutation__
 *
 * To run a mutation, you first call `useActionPlansDeleteActionItemTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionPlansDeleteActionItemTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionPlansDeleteActionItemTaskMutation, { data, loading, error }] = useActionPlansDeleteActionItemTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useActionPlansDeleteActionItemTaskMutation(baseOptions?: Apollo.MutationHookOptions<ActionPlansDeleteActionItemTaskMutation, ActionPlansDeleteActionItemTaskMutationVariables>) {
        return Apollo.useMutation<ActionPlansDeleteActionItemTaskMutation, ActionPlansDeleteActionItemTaskMutationVariables>(ActionPlansDeleteActionItemTaskDocument, baseOptions);
      }
export type ActionPlansDeleteActionItemTaskMutationHookResult = ReturnType<typeof useActionPlansDeleteActionItemTaskMutation>;
export type ActionPlansDeleteActionItemTaskMutationResult = Apollo.MutationResult<ActionPlansDeleteActionItemTaskMutation>;
export type ActionPlansDeleteActionItemTaskMutationOptions = Apollo.BaseMutationOptions<ActionPlansDeleteActionItemTaskMutation, ActionPlansDeleteActionItemTaskMutationVariables>;
export const ActionPlansDeleteActionItemTaskCommentDocument = gql`
    mutation ActionPlansDeleteActionItemTaskComment($actionItemTaskCommentUuid: String!, $userUuid: String) {
  deleteActionItemTaskComment(actionItemTaskCommentUuid: $actionItemTaskCommentUuid, userUuid: $userUuid) {
    actionItemTask {
      ...ActionPlansActionItemTask
    }
  }
}
    ${ActionPlansActionItemTaskFragmentDoc}`;
export type ActionPlansDeleteActionItemTaskCommentMutationFn = Apollo.MutationFunction<ActionPlansDeleteActionItemTaskCommentMutation, ActionPlansDeleteActionItemTaskCommentMutationVariables>;

/**
 * __useActionPlansDeleteActionItemTaskCommentMutation__
 *
 * To run a mutation, you first call `useActionPlansDeleteActionItemTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionPlansDeleteActionItemTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionPlansDeleteActionItemTaskCommentMutation, { data, loading, error }] = useActionPlansDeleteActionItemTaskCommentMutation({
 *   variables: {
 *      actionItemTaskCommentUuid: // value for 'actionItemTaskCommentUuid'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useActionPlansDeleteActionItemTaskCommentMutation(baseOptions?: Apollo.MutationHookOptions<ActionPlansDeleteActionItemTaskCommentMutation, ActionPlansDeleteActionItemTaskCommentMutationVariables>) {
        return Apollo.useMutation<ActionPlansDeleteActionItemTaskCommentMutation, ActionPlansDeleteActionItemTaskCommentMutationVariables>(ActionPlansDeleteActionItemTaskCommentDocument, baseOptions);
      }
export type ActionPlansDeleteActionItemTaskCommentMutationHookResult = ReturnType<typeof useActionPlansDeleteActionItemTaskCommentMutation>;
export type ActionPlansDeleteActionItemTaskCommentMutationResult = Apollo.MutationResult<ActionPlansDeleteActionItemTaskCommentMutation>;
export type ActionPlansDeleteActionItemTaskCommentMutationOptions = Apollo.BaseMutationOptions<ActionPlansDeleteActionItemTaskCommentMutation, ActionPlansDeleteActionItemTaskCommentMutationVariables>;
export const ActionPlansUpdateActionItemTaskDocument = gql`
    mutation ActionPlansUpdateActionItemTask($actionItemUuid: String!, $taskUuid: String, $task: ActionItemTaskInput!, $userUuid: String) {
  updateActionItemTask(actionItemUuid: $actionItemUuid, taskUuid: $taskUuid, task: $task, userUuid: $userUuid) {
    actionPlan {
      ...ActionPlansActionPlan
    }
    errors
  }
}
    ${ActionPlansActionPlanFragmentDoc}`;
export type ActionPlansUpdateActionItemTaskMutationFn = Apollo.MutationFunction<ActionPlansUpdateActionItemTaskMutation, ActionPlansUpdateActionItemTaskMutationVariables>;

/**
 * __useActionPlansUpdateActionItemTaskMutation__
 *
 * To run a mutation, you first call `useActionPlansUpdateActionItemTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionPlansUpdateActionItemTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionPlansUpdateActionItemTaskMutation, { data, loading, error }] = useActionPlansUpdateActionItemTaskMutation({
 *   variables: {
 *      actionItemUuid: // value for 'actionItemUuid'
 *      taskUuid: // value for 'taskUuid'
 *      task: // value for 'task'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useActionPlansUpdateActionItemTaskMutation(baseOptions?: Apollo.MutationHookOptions<ActionPlansUpdateActionItemTaskMutation, ActionPlansUpdateActionItemTaskMutationVariables>) {
        return Apollo.useMutation<ActionPlansUpdateActionItemTaskMutation, ActionPlansUpdateActionItemTaskMutationVariables>(ActionPlansUpdateActionItemTaskDocument, baseOptions);
      }
export type ActionPlansUpdateActionItemTaskMutationHookResult = ReturnType<typeof useActionPlansUpdateActionItemTaskMutation>;
export type ActionPlansUpdateActionItemTaskMutationResult = Apollo.MutationResult<ActionPlansUpdateActionItemTaskMutation>;
export type ActionPlansUpdateActionItemTaskMutationOptions = Apollo.BaseMutationOptions<ActionPlansUpdateActionItemTaskMutation, ActionPlansUpdateActionItemTaskMutationVariables>;
export const ActionPlansUpdateActionItemTaskCommentDocument = gql`
    mutation ActionPlansUpdateActionItemTaskComment($actionItemTaskUuid: String!, $commentInput: ActionItemTaskCommentInput!, $userUuid: String, $userTimezone: String) {
  updateActionItemTaskComment(actionItemTaskUuid: $actionItemTaskUuid, commentInput: $commentInput, userUuid: $userUuid, userTimezone: $userTimezone) {
    actionItemTask {
      ...ActionPlansActionItemTask
    }
  }
}
    ${ActionPlansActionItemTaskFragmentDoc}`;
export type ActionPlansUpdateActionItemTaskCommentMutationFn = Apollo.MutationFunction<ActionPlansUpdateActionItemTaskCommentMutation, ActionPlansUpdateActionItemTaskCommentMutationVariables>;

/**
 * __useActionPlansUpdateActionItemTaskCommentMutation__
 *
 * To run a mutation, you first call `useActionPlansUpdateActionItemTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionPlansUpdateActionItemTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionPlansUpdateActionItemTaskCommentMutation, { data, loading, error }] = useActionPlansUpdateActionItemTaskCommentMutation({
 *   variables: {
 *      actionItemTaskUuid: // value for 'actionItemTaskUuid'
 *      commentInput: // value for 'commentInput'
 *      userUuid: // value for 'userUuid'
 *      userTimezone: // value for 'userTimezone'
 *   },
 * });
 */
export function useActionPlansUpdateActionItemTaskCommentMutation(baseOptions?: Apollo.MutationHookOptions<ActionPlansUpdateActionItemTaskCommentMutation, ActionPlansUpdateActionItemTaskCommentMutationVariables>) {
        return Apollo.useMutation<ActionPlansUpdateActionItemTaskCommentMutation, ActionPlansUpdateActionItemTaskCommentMutationVariables>(ActionPlansUpdateActionItemTaskCommentDocument, baseOptions);
      }
export type ActionPlansUpdateActionItemTaskCommentMutationHookResult = ReturnType<typeof useActionPlansUpdateActionItemTaskCommentMutation>;
export type ActionPlansUpdateActionItemTaskCommentMutationResult = Apollo.MutationResult<ActionPlansUpdateActionItemTaskCommentMutation>;
export type ActionPlansUpdateActionItemTaskCommentMutationOptions = Apollo.BaseMutationOptions<ActionPlansUpdateActionItemTaskCommentMutation, ActionPlansUpdateActionItemTaskCommentMutationVariables>;
export const ActionPlanUpdateActionPlanSurveyDocument = gql`
    mutation ActionPlanUpdateActionPlanSurvey($actionPlanInput: ActionPlanSurveyInput!, $userUuid: String) {
  updateActionPlanSurvey(actionPlanInput: $actionPlanInput, userUuid: $userUuid) {
    errors
    actionPlan {
      ...ActionPlansActionPlan
    }
  }
}
    ${ActionPlansActionPlanFragmentDoc}`;
export type ActionPlanUpdateActionPlanSurveyMutationFn = Apollo.MutationFunction<ActionPlanUpdateActionPlanSurveyMutation, ActionPlanUpdateActionPlanSurveyMutationVariables>;

/**
 * __useActionPlanUpdateActionPlanSurveyMutation__
 *
 * To run a mutation, you first call `useActionPlanUpdateActionPlanSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionPlanUpdateActionPlanSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionPlanUpdateActionPlanSurveyMutation, { data, loading, error }] = useActionPlanUpdateActionPlanSurveyMutation({
 *   variables: {
 *      actionPlanInput: // value for 'actionPlanInput'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useActionPlanUpdateActionPlanSurveyMutation(baseOptions?: Apollo.MutationHookOptions<ActionPlanUpdateActionPlanSurveyMutation, ActionPlanUpdateActionPlanSurveyMutationVariables>) {
        return Apollo.useMutation<ActionPlanUpdateActionPlanSurveyMutation, ActionPlanUpdateActionPlanSurveyMutationVariables>(ActionPlanUpdateActionPlanSurveyDocument, baseOptions);
      }
export type ActionPlanUpdateActionPlanSurveyMutationHookResult = ReturnType<typeof useActionPlanUpdateActionPlanSurveyMutation>;
export type ActionPlanUpdateActionPlanSurveyMutationResult = Apollo.MutationResult<ActionPlanUpdateActionPlanSurveyMutation>;
export type ActionPlanUpdateActionPlanSurveyMutationOptions = Apollo.BaseMutationOptions<ActionPlanUpdateActionPlanSurveyMutation, ActionPlanUpdateActionPlanSurveyMutationVariables>;
export const DeleteCultureBriefImagesDocument = gql`
    mutation DeleteCultureBriefImages($cultureBriefUuid: String!, $uuids: [String!]!) {
  deleteCultureBriefImages(cultureBriefUuid: $cultureBriefUuid, uuids: $uuids) {
    errors
  }
}
    `;
export type DeleteCultureBriefImagesMutationFn = Apollo.MutationFunction<DeleteCultureBriefImagesMutation, DeleteCultureBriefImagesMutationVariables>;

/**
 * __useDeleteCultureBriefImagesMutation__
 *
 * To run a mutation, you first call `useDeleteCultureBriefImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCultureBriefImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCultureBriefImagesMutation, { data, loading, error }] = useDeleteCultureBriefImagesMutation({
 *   variables: {
 *      cultureBriefUuid: // value for 'cultureBriefUuid'
 *      uuids: // value for 'uuids'
 *   },
 * });
 */
export function useDeleteCultureBriefImagesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCultureBriefImagesMutation, DeleteCultureBriefImagesMutationVariables>) {
        return Apollo.useMutation<DeleteCultureBriefImagesMutation, DeleteCultureBriefImagesMutationVariables>(DeleteCultureBriefImagesDocument, baseOptions);
      }
export type DeleteCultureBriefImagesMutationHookResult = ReturnType<typeof useDeleteCultureBriefImagesMutation>;
export type DeleteCultureBriefImagesMutationResult = Apollo.MutationResult<DeleteCultureBriefImagesMutation>;
export type DeleteCultureBriefImagesMutationOptions = Apollo.BaseMutationOptions<DeleteCultureBriefImagesMutation, DeleteCultureBriefImagesMutationVariables>;
export const ShareMarketingToolkitDocument = gql`
    mutation ShareMarketingToolkit($email: String!, $includeBadgeAssets: Boolean!, $note: String, $locationUuid: String) {
  shareMarketingToolkit(email: $email, note: $note, locationUuid: $locationUuid, includeBadgeAssets: $includeBadgeAssets) {
    errors
  }
}
    `;
export type ShareMarketingToolkitMutationFn = Apollo.MutationFunction<ShareMarketingToolkitMutation, ShareMarketingToolkitMutationVariables>;

/**
 * __useShareMarketingToolkitMutation__
 *
 * To run a mutation, you first call `useShareMarketingToolkitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareMarketingToolkitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareMarketingToolkitMutation, { data, loading, error }] = useShareMarketingToolkitMutation({
 *   variables: {
 *      email: // value for 'email'
 *      includeBadgeAssets: // value for 'includeBadgeAssets'
 *      note: // value for 'note'
 *      locationUuid: // value for 'locationUuid'
 *   },
 * });
 */
export function useShareMarketingToolkitMutation(baseOptions?: Apollo.MutationHookOptions<ShareMarketingToolkitMutation, ShareMarketingToolkitMutationVariables>) {
        return Apollo.useMutation<ShareMarketingToolkitMutation, ShareMarketingToolkitMutationVariables>(ShareMarketingToolkitDocument, baseOptions);
      }
export type ShareMarketingToolkitMutationHookResult = ReturnType<typeof useShareMarketingToolkitMutation>;
export type ShareMarketingToolkitMutationResult = Apollo.MutationResult<ShareMarketingToolkitMutation>;
export type ShareMarketingToolkitMutationOptions = Apollo.BaseMutationOptions<ShareMarketingToolkitMutation, ShareMarketingToolkitMutationVariables>;
export const UpdateCultureBriefDocument = gql`
    mutation UpdateCultureBrief($input: UpdateCultureBriefInput!) {
  updateCultureBrief(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateCultureBriefMutationFn = Apollo.MutationFunction<UpdateCultureBriefMutation, UpdateCultureBriefMutationVariables>;

/**
 * __useUpdateCultureBriefMutation__
 *
 * To run a mutation, you first call `useUpdateCultureBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCultureBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCultureBriefMutation, { data, loading, error }] = useUpdateCultureBriefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCultureBriefMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCultureBriefMutation, UpdateCultureBriefMutationVariables>) {
        return Apollo.useMutation<UpdateCultureBriefMutation, UpdateCultureBriefMutationVariables>(UpdateCultureBriefDocument, baseOptions);
      }
export type UpdateCultureBriefMutationHookResult = ReturnType<typeof useUpdateCultureBriefMutation>;
export type UpdateCultureBriefMutationResult = Apollo.MutationResult<UpdateCultureBriefMutation>;
export type UpdateCultureBriefMutationOptions = Apollo.BaseMutationOptions<UpdateCultureBriefMutation, UpdateCultureBriefMutationVariables>;
export const UpdateCultureBriefImagesDocument = gql`
    mutation UpdateCultureBriefImages($cultureBriefUuid: String!, $imagesData: [CultureBriefImageInput!]!) {
  updateCultureBriefImages(cultureBriefUuid: $cultureBriefUuid, imagesData: $imagesData) {
    cultureBrief {
      uuid
      ...CultureBriefImages
    }
  }
}
    ${CultureBriefImagesFragmentDoc}`;
export type UpdateCultureBriefImagesMutationFn = Apollo.MutationFunction<UpdateCultureBriefImagesMutation, UpdateCultureBriefImagesMutationVariables>;

/**
 * __useUpdateCultureBriefImagesMutation__
 *
 * To run a mutation, you first call `useUpdateCultureBriefImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCultureBriefImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCultureBriefImagesMutation, { data, loading, error }] = useUpdateCultureBriefImagesMutation({
 *   variables: {
 *      cultureBriefUuid: // value for 'cultureBriefUuid'
 *      imagesData: // value for 'imagesData'
 *   },
 * });
 */
export function useUpdateCultureBriefImagesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCultureBriefImagesMutation, UpdateCultureBriefImagesMutationVariables>) {
        return Apollo.useMutation<UpdateCultureBriefImagesMutation, UpdateCultureBriefImagesMutationVariables>(UpdateCultureBriefImagesDocument, baseOptions);
      }
export type UpdateCultureBriefImagesMutationHookResult = ReturnType<typeof useUpdateCultureBriefImagesMutation>;
export type UpdateCultureBriefImagesMutationResult = Apollo.MutationResult<UpdateCultureBriefImagesMutation>;
export type UpdateCultureBriefImagesMutationOptions = Apollo.BaseMutationOptions<UpdateCultureBriefImagesMutation, UpdateCultureBriefImagesMutationVariables>;
export const AddCultureBriefImagesDocument = gql`
    mutation AddCultureBriefImages($cultureBriefUuid: String!, $imagesData: [CultureBriefImageInput!]!) {
  addCultureBriefImages(cultureBriefUuid: $cultureBriefUuid, imagesData: $imagesData) {
    cultureBrief {
      uuid
      ...CultureBriefImages
    }
  }
}
    ${CultureBriefImagesFragmentDoc}`;
export type AddCultureBriefImagesMutationFn = Apollo.MutationFunction<AddCultureBriefImagesMutation, AddCultureBriefImagesMutationVariables>;

/**
 * __useAddCultureBriefImagesMutation__
 *
 * To run a mutation, you first call `useAddCultureBriefImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCultureBriefImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCultureBriefImagesMutation, { data, loading, error }] = useAddCultureBriefImagesMutation({
 *   variables: {
 *      cultureBriefUuid: // value for 'cultureBriefUuid'
 *      imagesData: // value for 'imagesData'
 *   },
 * });
 */
export function useAddCultureBriefImagesMutation(baseOptions?: Apollo.MutationHookOptions<AddCultureBriefImagesMutation, AddCultureBriefImagesMutationVariables>) {
        return Apollo.useMutation<AddCultureBriefImagesMutation, AddCultureBriefImagesMutationVariables>(AddCultureBriefImagesDocument, baseOptions);
      }
export type AddCultureBriefImagesMutationHookResult = ReturnType<typeof useAddCultureBriefImagesMutation>;
export type AddCultureBriefImagesMutationResult = Apollo.MutationResult<AddCultureBriefImagesMutation>;
export type AddCultureBriefImagesMutationOptions = Apollo.BaseMutationOptions<AddCultureBriefImagesMutation, AddCultureBriefImagesMutationVariables>;
export const InsightsOverlayEmailSupportDocument = gql`
    mutation InsightsOverlayEmailSupport {
  emailSupport {
    errors
  }
}
    `;
export type InsightsOverlayEmailSupportMutationFn = Apollo.MutationFunction<InsightsOverlayEmailSupportMutation, InsightsOverlayEmailSupportMutationVariables>;

/**
 * __useInsightsOverlayEmailSupportMutation__
 *
 * To run a mutation, you first call `useInsightsOverlayEmailSupportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsightsOverlayEmailSupportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insightsOverlayEmailSupportMutation, { data, loading, error }] = useInsightsOverlayEmailSupportMutation({
 *   variables: {
 *   },
 * });
 */
export function useInsightsOverlayEmailSupportMutation(baseOptions?: Apollo.MutationHookOptions<InsightsOverlayEmailSupportMutation, InsightsOverlayEmailSupportMutationVariables>) {
        return Apollo.useMutation<InsightsOverlayEmailSupportMutation, InsightsOverlayEmailSupportMutationVariables>(InsightsOverlayEmailSupportDocument, baseOptions);
      }
export type InsightsOverlayEmailSupportMutationHookResult = ReturnType<typeof useInsightsOverlayEmailSupportMutation>;
export type InsightsOverlayEmailSupportMutationResult = Apollo.MutationResult<InsightsOverlayEmailSupportMutation>;
export type InsightsOverlayEmailSupportMutationOptions = Apollo.BaseMutationOptions<InsightsOverlayEmailSupportMutation, InsightsOverlayEmailSupportMutationVariables>;
export const InsightsUpdateTakeActionStepsDocument = gql`
    mutation InsightsUpdateTakeActionSteps($surveyUuid: String!, $step: TakeActionStepsEnum!) {
  updateTakeActionSteps(surveyUuid: $surveyUuid, step: $step) {
    errors
    survey {
      uuid
      takeActionCheckedSteps
    }
  }
}
    `;
export type InsightsUpdateTakeActionStepsMutationFn = Apollo.MutationFunction<InsightsUpdateTakeActionStepsMutation, InsightsUpdateTakeActionStepsMutationVariables>;

/**
 * __useInsightsUpdateTakeActionStepsMutation__
 *
 * To run a mutation, you first call `useInsightsUpdateTakeActionStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsightsUpdateTakeActionStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insightsUpdateTakeActionStepsMutation, { data, loading, error }] = useInsightsUpdateTakeActionStepsMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useInsightsUpdateTakeActionStepsMutation(baseOptions?: Apollo.MutationHookOptions<InsightsUpdateTakeActionStepsMutation, InsightsUpdateTakeActionStepsMutationVariables>) {
        return Apollo.useMutation<InsightsUpdateTakeActionStepsMutation, InsightsUpdateTakeActionStepsMutationVariables>(InsightsUpdateTakeActionStepsDocument, baseOptions);
      }
export type InsightsUpdateTakeActionStepsMutationHookResult = ReturnType<typeof useInsightsUpdateTakeActionStepsMutation>;
export type InsightsUpdateTakeActionStepsMutationResult = Apollo.MutationResult<InsightsUpdateTakeActionStepsMutation>;
export type InsightsUpdateTakeActionStepsMutationOptions = Apollo.BaseMutationOptions<InsightsUpdateTakeActionStepsMutation, InsightsUpdateTakeActionStepsMutationVariables>;
export const OrganizationAddContactsUploadDocument = gql`
    mutation OrganizationAddContactsUpload($objectPath: String!, $importType: ImportContactTypesEnum!) {
  addContactsUpload(objectPath: $objectPath, importType: $importType) {
    errors
  }
}
    `;
export type OrganizationAddContactsUploadMutationFn = Apollo.MutationFunction<OrganizationAddContactsUploadMutation, OrganizationAddContactsUploadMutationVariables>;

/**
 * __useOrganizationAddContactsUploadMutation__
 *
 * To run a mutation, you first call `useOrganizationAddContactsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAddContactsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationAddContactsUploadMutation, { data, loading, error }] = useOrganizationAddContactsUploadMutation({
 *   variables: {
 *      objectPath: // value for 'objectPath'
 *      importType: // value for 'importType'
 *   },
 * });
 */
export function useOrganizationAddContactsUploadMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationAddContactsUploadMutation, OrganizationAddContactsUploadMutationVariables>) {
        return Apollo.useMutation<OrganizationAddContactsUploadMutation, OrganizationAddContactsUploadMutationVariables>(OrganizationAddContactsUploadDocument, baseOptions);
      }
export type OrganizationAddContactsUploadMutationHookResult = ReturnType<typeof useOrganizationAddContactsUploadMutation>;
export type OrganizationAddContactsUploadMutationResult = Apollo.MutationResult<OrganizationAddContactsUploadMutation>;
export type OrganizationAddContactsUploadMutationOptions = Apollo.BaseMutationOptions<OrganizationAddContactsUploadMutation, OrganizationAddContactsUploadMutationVariables>;
export const OrganizationUpdateFilterValueDocument = gql`
    mutation OrganizationUpdateFilterValue($filterTypeUuid: String!, $filterValue: FilterValueInput!) {
  updateFilterValue(filterTypeUuid: $filterTypeUuid, filterValue: $filterValue) {
    errors
    filterValue {
      ...OrganizationFilterValue
    }
  }
}
    ${OrganizationFilterValueFragmentDoc}`;
export type OrganizationUpdateFilterValueMutationFn = Apollo.MutationFunction<OrganizationUpdateFilterValueMutation, OrganizationUpdateFilterValueMutationVariables>;

/**
 * __useOrganizationUpdateFilterValueMutation__
 *
 * To run a mutation, you first call `useOrganizationUpdateFilterValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUpdateFilterValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUpdateFilterValueMutation, { data, loading, error }] = useOrganizationUpdateFilterValueMutation({
 *   variables: {
 *      filterTypeUuid: // value for 'filterTypeUuid'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useOrganizationUpdateFilterValueMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationUpdateFilterValueMutation, OrganizationUpdateFilterValueMutationVariables>) {
        return Apollo.useMutation<OrganizationUpdateFilterValueMutation, OrganizationUpdateFilterValueMutationVariables>(OrganizationUpdateFilterValueDocument, baseOptions);
      }
export type OrganizationUpdateFilterValueMutationHookResult = ReturnType<typeof useOrganizationUpdateFilterValueMutation>;
export type OrganizationUpdateFilterValueMutationResult = Apollo.MutationResult<OrganizationUpdateFilterValueMutation>;
export type OrganizationUpdateFilterValueMutationOptions = Apollo.BaseMutationOptions<OrganizationUpdateFilterValueMutation, OrganizationUpdateFilterValueMutationVariables>;
export const OrganizationUpdateFilterValueArchivedDocument = gql`
    mutation OrganizationUpdateFilterValueArchived($uuid: String!) {
  updateFilterValueArchived(uuid: $uuid) {
    errors
    filterValue {
      ...OrganizationFilterValue
    }
  }
}
    ${OrganizationFilterValueFragmentDoc}`;
export type OrganizationUpdateFilterValueArchivedMutationFn = Apollo.MutationFunction<OrganizationUpdateFilterValueArchivedMutation, OrganizationUpdateFilterValueArchivedMutationVariables>;

/**
 * __useOrganizationUpdateFilterValueArchivedMutation__
 *
 * To run a mutation, you first call `useOrganizationUpdateFilterValueArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUpdateFilterValueArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUpdateFilterValueArchivedMutation, { data, loading, error }] = useOrganizationUpdateFilterValueArchivedMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useOrganizationUpdateFilterValueArchivedMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationUpdateFilterValueArchivedMutation, OrganizationUpdateFilterValueArchivedMutationVariables>) {
        return Apollo.useMutation<OrganizationUpdateFilterValueArchivedMutation, OrganizationUpdateFilterValueArchivedMutationVariables>(OrganizationUpdateFilterValueArchivedDocument, baseOptions);
      }
export type OrganizationUpdateFilterValueArchivedMutationHookResult = ReturnType<typeof useOrganizationUpdateFilterValueArchivedMutation>;
export type OrganizationUpdateFilterValueArchivedMutationResult = Apollo.MutationResult<OrganizationUpdateFilterValueArchivedMutation>;
export type OrganizationUpdateFilterValueArchivedMutationOptions = Apollo.BaseMutationOptions<OrganizationUpdateFilterValueArchivedMutation, OrganizationUpdateFilterValueArchivedMutationVariables>;
export const SettingsBulkDeleteUsersDocument = gql`
    mutation SettingsBulkDeleteUsers($userUuids: [String!]!) {
  bulkDeleteUsers(userUuids: $userUuids) {
    errors
  }
}
    `;
export type SettingsBulkDeleteUsersMutationFn = Apollo.MutationFunction<SettingsBulkDeleteUsersMutation, SettingsBulkDeleteUsersMutationVariables>;

/**
 * __useSettingsBulkDeleteUsersMutation__
 *
 * To run a mutation, you first call `useSettingsBulkDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsBulkDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsBulkDeleteUsersMutation, { data, loading, error }] = useSettingsBulkDeleteUsersMutation({
 *   variables: {
 *      userUuids: // value for 'userUuids'
 *   },
 * });
 */
export function useSettingsBulkDeleteUsersMutation(baseOptions?: Apollo.MutationHookOptions<SettingsBulkDeleteUsersMutation, SettingsBulkDeleteUsersMutationVariables>) {
        return Apollo.useMutation<SettingsBulkDeleteUsersMutation, SettingsBulkDeleteUsersMutationVariables>(SettingsBulkDeleteUsersDocument, baseOptions);
      }
export type SettingsBulkDeleteUsersMutationHookResult = ReturnType<typeof useSettingsBulkDeleteUsersMutation>;
export type SettingsBulkDeleteUsersMutationResult = Apollo.MutationResult<SettingsBulkDeleteUsersMutation>;
export type SettingsBulkDeleteUsersMutationOptions = Apollo.BaseMutationOptions<SettingsBulkDeleteUsersMutation, SettingsBulkDeleteUsersMutationVariables>;
export const SettingsBulkResetPasswordDocument = gql`
    mutation SettingsBulkResetPassword($uuidList: [String]) {
  bulkResetPassword(uuidList: $uuidList) {
    errors
    users {
      ...UserCore
    }
  }
}
    ${UserCoreFragmentDoc}`;
export type SettingsBulkResetPasswordMutationFn = Apollo.MutationFunction<SettingsBulkResetPasswordMutation, SettingsBulkResetPasswordMutationVariables>;

/**
 * __useSettingsBulkResetPasswordMutation__
 *
 * To run a mutation, you first call `useSettingsBulkResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsBulkResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsBulkResetPasswordMutation, { data, loading, error }] = useSettingsBulkResetPasswordMutation({
 *   variables: {
 *      uuidList: // value for 'uuidList'
 *   },
 * });
 */
export function useSettingsBulkResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SettingsBulkResetPasswordMutation, SettingsBulkResetPasswordMutationVariables>) {
        return Apollo.useMutation<SettingsBulkResetPasswordMutation, SettingsBulkResetPasswordMutationVariables>(SettingsBulkResetPasswordDocument, baseOptions);
      }
export type SettingsBulkResetPasswordMutationHookResult = ReturnType<typeof useSettingsBulkResetPasswordMutation>;
export type SettingsBulkResetPasswordMutationResult = Apollo.MutationResult<SettingsBulkResetPasswordMutation>;
export type SettingsBulkResetPasswordMutationOptions = Apollo.BaseMutationOptions<SettingsBulkResetPasswordMutation, SettingsBulkResetPasswordMutationVariables>;
export const SettingsBulkSetIsActiveDocument = gql`
    mutation SettingsBulkSetIsActive($isActive: Boolean!, $uuidList: [String]!) {
  bulkSetIsActive(isActive: $isActive, uuidList: $uuidList) {
    errors
    users {
      id
      status
    }
  }
}
    `;
export type SettingsBulkSetIsActiveMutationFn = Apollo.MutationFunction<SettingsBulkSetIsActiveMutation, SettingsBulkSetIsActiveMutationVariables>;

/**
 * __useSettingsBulkSetIsActiveMutation__
 *
 * To run a mutation, you first call `useSettingsBulkSetIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsBulkSetIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsBulkSetIsActiveMutation, { data, loading, error }] = useSettingsBulkSetIsActiveMutation({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      uuidList: // value for 'uuidList'
 *   },
 * });
 */
export function useSettingsBulkSetIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<SettingsBulkSetIsActiveMutation, SettingsBulkSetIsActiveMutationVariables>) {
        return Apollo.useMutation<SettingsBulkSetIsActiveMutation, SettingsBulkSetIsActiveMutationVariables>(SettingsBulkSetIsActiveDocument, baseOptions);
      }
export type SettingsBulkSetIsActiveMutationHookResult = ReturnType<typeof useSettingsBulkSetIsActiveMutation>;
export type SettingsBulkSetIsActiveMutationResult = Apollo.MutationResult<SettingsBulkSetIsActiveMutation>;
export type SettingsBulkSetIsActiveMutationOptions = Apollo.BaseMutationOptions<SettingsBulkSetIsActiveMutation, SettingsBulkSetIsActiveMutationVariables>;
export const SettingsBulkUpdateUserPermissionsDocument = gql`
    mutation SettingsBulkUpdateUserPermissions($uuidList: [String]!, $filterTypes: [FilterTypePermissionInput!]!) {
  bulkUpdateUserPermissions(uuidList: $uuidList, filterTypes: $filterTypes) {
    errors
    users {
      id
      filterPermissions {
        ...FilterType
      }
    }
  }
}
    ${FilterTypeFragmentDoc}`;
export type SettingsBulkUpdateUserPermissionsMutationFn = Apollo.MutationFunction<SettingsBulkUpdateUserPermissionsMutation, SettingsBulkUpdateUserPermissionsMutationVariables>;

/**
 * __useSettingsBulkUpdateUserPermissionsMutation__
 *
 * To run a mutation, you first call `useSettingsBulkUpdateUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsBulkUpdateUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsBulkUpdateUserPermissionsMutation, { data, loading, error }] = useSettingsBulkUpdateUserPermissionsMutation({
 *   variables: {
 *      uuidList: // value for 'uuidList'
 *      filterTypes: // value for 'filterTypes'
 *   },
 * });
 */
export function useSettingsBulkUpdateUserPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<SettingsBulkUpdateUserPermissionsMutation, SettingsBulkUpdateUserPermissionsMutationVariables>) {
        return Apollo.useMutation<SettingsBulkUpdateUserPermissionsMutation, SettingsBulkUpdateUserPermissionsMutationVariables>(SettingsBulkUpdateUserPermissionsDocument, baseOptions);
      }
export type SettingsBulkUpdateUserPermissionsMutationHookResult = ReturnType<typeof useSettingsBulkUpdateUserPermissionsMutation>;
export type SettingsBulkUpdateUserPermissionsMutationResult = Apollo.MutationResult<SettingsBulkUpdateUserPermissionsMutation>;
export type SettingsBulkUpdateUserPermissionsMutationOptions = Apollo.BaseMutationOptions<SettingsBulkUpdateUserPermissionsMutation, SettingsBulkUpdateUserPermissionsMutationVariables>;
export const SettingsToggleIsActiveDocument = gql`
    mutation SettingsToggleIsActive($uuid: String!) {
  toggleIsActive(uuid: $uuid) {
    errors
    user {
      id
      status
      isActive
    }
  }
}
    `;
export type SettingsToggleIsActiveMutationFn = Apollo.MutationFunction<SettingsToggleIsActiveMutation, SettingsToggleIsActiveMutationVariables>;

/**
 * __useSettingsToggleIsActiveMutation__
 *
 * To run a mutation, you first call `useSettingsToggleIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsToggleIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsToggleIsActiveMutation, { data, loading, error }] = useSettingsToggleIsActiveMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSettingsToggleIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<SettingsToggleIsActiveMutation, SettingsToggleIsActiveMutationVariables>) {
        return Apollo.useMutation<SettingsToggleIsActiveMutation, SettingsToggleIsActiveMutationVariables>(SettingsToggleIsActiveDocument, baseOptions);
      }
export type SettingsToggleIsActiveMutationHookResult = ReturnType<typeof useSettingsToggleIsActiveMutation>;
export type SettingsToggleIsActiveMutationResult = Apollo.MutationResult<SettingsToggleIsActiveMutation>;
export type SettingsToggleIsActiveMutationOptions = Apollo.BaseMutationOptions<SettingsToggleIsActiveMutation, SettingsToggleIsActiveMutationVariables>;
export const SettingsUpdateGroupDocument = gql`
    mutation SettingsUpdateGroup($group: GroupInput!) {
  updateGroup(groupInput: $group) {
    errors
    group {
      ...SettingsGroup
    }
  }
}
    ${SettingsGroupFragmentDoc}`;
export type SettingsUpdateGroupMutationFn = Apollo.MutationFunction<SettingsUpdateGroupMutation, SettingsUpdateGroupMutationVariables>;

/**
 * __useSettingsUpdateGroupMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateGroupMutation, { data, loading, error }] = useSettingsUpdateGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useSettingsUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<SettingsUpdateGroupMutation, SettingsUpdateGroupMutationVariables>) {
        return Apollo.useMutation<SettingsUpdateGroupMutation, SettingsUpdateGroupMutationVariables>(SettingsUpdateGroupDocument, baseOptions);
      }
export type SettingsUpdateGroupMutationHookResult = ReturnType<typeof useSettingsUpdateGroupMutation>;
export type SettingsUpdateGroupMutationResult = Apollo.MutationResult<SettingsUpdateGroupMutation>;
export type SettingsUpdateGroupMutationOptions = Apollo.BaseMutationOptions<SettingsUpdateGroupMutation, SettingsUpdateGroupMutationVariables>;
export const SettingsUpdateOrganizationDocument = gql`
    mutation SettingsUpdateOrganization($organization: OrganizationInput!) {
  updateOrganization(organization: $organization) {
    errors
    organization {
      ...Organization
    }
  }
}
    ${OrganizationFragmentDoc}`;
export type SettingsUpdateOrganizationMutationFn = Apollo.MutationFunction<SettingsUpdateOrganizationMutation, SettingsUpdateOrganizationMutationVariables>;

/**
 * __useSettingsUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateOrganizationMutation, { data, loading, error }] = useSettingsUpdateOrganizationMutation({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useSettingsUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<SettingsUpdateOrganizationMutation, SettingsUpdateOrganizationMutationVariables>) {
        return Apollo.useMutation<SettingsUpdateOrganizationMutation, SettingsUpdateOrganizationMutationVariables>(SettingsUpdateOrganizationDocument, baseOptions);
      }
export type SettingsUpdateOrganizationMutationHookResult = ReturnType<typeof useSettingsUpdateOrganizationMutation>;
export type SettingsUpdateOrganizationMutationResult = Apollo.MutationResult<SettingsUpdateOrganizationMutation>;
export type SettingsUpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<SettingsUpdateOrganizationMutation, SettingsUpdateOrganizationMutationVariables>;
export const SettingsUpdateOrganizationServiceAreasDocument = gql`
    mutation SettingsUpdateOrganizationServiceAreas($serviceAreas: [ServiceAreaInputType!]!) {
  updateOrganizationServiceAreas(serviceAreas: $serviceAreas) {
    errors
    organization {
      uuid
      serviceAreas {
        ...OrganizationServiceAreas
      }
    }
  }
}
    ${OrganizationServiceAreasFragmentDoc}`;
export type SettingsUpdateOrganizationServiceAreasMutationFn = Apollo.MutationFunction<SettingsUpdateOrganizationServiceAreasMutation, SettingsUpdateOrganizationServiceAreasMutationVariables>;

/**
 * __useSettingsUpdateOrganizationServiceAreasMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateOrganizationServiceAreasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateOrganizationServiceAreasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateOrganizationServiceAreasMutation, { data, loading, error }] = useSettingsUpdateOrganizationServiceAreasMutation({
 *   variables: {
 *      serviceAreas: // value for 'serviceAreas'
 *   },
 * });
 */
export function useSettingsUpdateOrganizationServiceAreasMutation(baseOptions?: Apollo.MutationHookOptions<SettingsUpdateOrganizationServiceAreasMutation, SettingsUpdateOrganizationServiceAreasMutationVariables>) {
        return Apollo.useMutation<SettingsUpdateOrganizationServiceAreasMutation, SettingsUpdateOrganizationServiceAreasMutationVariables>(SettingsUpdateOrganizationServiceAreasDocument, baseOptions);
      }
export type SettingsUpdateOrganizationServiceAreasMutationHookResult = ReturnType<typeof useSettingsUpdateOrganizationServiceAreasMutation>;
export type SettingsUpdateOrganizationServiceAreasMutationResult = Apollo.MutationResult<SettingsUpdateOrganizationServiceAreasMutation>;
export type SettingsUpdateOrganizationServiceAreasMutationOptions = Apollo.BaseMutationOptions<SettingsUpdateOrganizationServiceAreasMutation, SettingsUpdateOrganizationServiceAreasMutationVariables>;
export const SettingsUpdateOrganizationSurveyPortalDocument = gql`
    mutation SettingsUpdateOrganizationSurveyPortal($organization: OrganizationSurveyPortalInput!) {
  updateOrganizationSurveyPortal(organization: $organization) {
    errors
    organization {
      ...Organization
    }
  }
}
    ${OrganizationFragmentDoc}`;
export type SettingsUpdateOrganizationSurveyPortalMutationFn = Apollo.MutationFunction<SettingsUpdateOrganizationSurveyPortalMutation, SettingsUpdateOrganizationSurveyPortalMutationVariables>;

/**
 * __useSettingsUpdateOrganizationSurveyPortalMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateOrganizationSurveyPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateOrganizationSurveyPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateOrganizationSurveyPortalMutation, { data, loading, error }] = useSettingsUpdateOrganizationSurveyPortalMutation({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useSettingsUpdateOrganizationSurveyPortalMutation(baseOptions?: Apollo.MutationHookOptions<SettingsUpdateOrganizationSurveyPortalMutation, SettingsUpdateOrganizationSurveyPortalMutationVariables>) {
        return Apollo.useMutation<SettingsUpdateOrganizationSurveyPortalMutation, SettingsUpdateOrganizationSurveyPortalMutationVariables>(SettingsUpdateOrganizationSurveyPortalDocument, baseOptions);
      }
export type SettingsUpdateOrganizationSurveyPortalMutationHookResult = ReturnType<typeof useSettingsUpdateOrganizationSurveyPortalMutation>;
export type SettingsUpdateOrganizationSurveyPortalMutationResult = Apollo.MutationResult<SettingsUpdateOrganizationSurveyPortalMutation>;
export type SettingsUpdateOrganizationSurveyPortalMutationOptions = Apollo.BaseMutationOptions<SettingsUpdateOrganizationSurveyPortalMutation, SettingsUpdateOrganizationSurveyPortalMutationVariables>;
export const SettingsUpdateUserDocument = gql`
    mutation SettingsUpdateUser($uuid: String, $firstName: String, $lastName: String, $email: String, $title: String, $groups: [String], $filterTypes: [FilterTypePermissionInput!]!) {
  updateUser(uuid: $uuid, firstName: $firstName, lastName: $lastName, email: $email, title: $title, groups: $groups, filterTypes: $filterTypes) {
    errors
    user {
      ...SettingsUser
    }
  }
}
    ${SettingsUserFragmentDoc}`;
export type SettingsUpdateUserMutationFn = Apollo.MutationFunction<SettingsUpdateUserMutation, SettingsUpdateUserMutationVariables>;

/**
 * __useSettingsUpdateUserMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateUserMutation, { data, loading, error }] = useSettingsUpdateUserMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      title: // value for 'title'
 *      groups: // value for 'groups'
 *      filterTypes: // value for 'filterTypes'
 *   },
 * });
 */
export function useSettingsUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<SettingsUpdateUserMutation, SettingsUpdateUserMutationVariables>) {
        return Apollo.useMutation<SettingsUpdateUserMutation, SettingsUpdateUserMutationVariables>(SettingsUpdateUserDocument, baseOptions);
      }
export type SettingsUpdateUserMutationHookResult = ReturnType<typeof useSettingsUpdateUserMutation>;
export type SettingsUpdateUserMutationResult = Apollo.MutationResult<SettingsUpdateUserMutation>;
export type SettingsUpdateUserMutationOptions = Apollo.BaseMutationOptions<SettingsUpdateUserMutation, SettingsUpdateUserMutationVariables>;
export const ShareScreenshotDocument = gql`
    mutation ShareScreenshot($email: String!, $note: String, $image: String!, $currentUrl: String!) {
  shareScreenshot(email: $email, note: $note, image: $image, currentUrl: $currentUrl) {
    errors
  }
}
    `;
export type ShareScreenshotMutationFn = Apollo.MutationFunction<ShareScreenshotMutation, ShareScreenshotMutationVariables>;

/**
 * __useShareScreenshotMutation__
 *
 * To run a mutation, you first call `useShareScreenshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareScreenshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareScreenshotMutation, { data, loading, error }] = useShareScreenshotMutation({
 *   variables: {
 *      email: // value for 'email'
 *      note: // value for 'note'
 *      image: // value for 'image'
 *      currentUrl: // value for 'currentUrl'
 *   },
 * });
 */
export function useShareScreenshotMutation(baseOptions?: Apollo.MutationHookOptions<ShareScreenshotMutation, ShareScreenshotMutationVariables>) {
        return Apollo.useMutation<ShareScreenshotMutation, ShareScreenshotMutationVariables>(ShareScreenshotDocument, baseOptions);
      }
export type ShareScreenshotMutationHookResult = ReturnType<typeof useShareScreenshotMutation>;
export type ShareScreenshotMutationResult = Apollo.MutationResult<ShareScreenshotMutation>;
export type ShareScreenshotMutationOptions = Apollo.BaseMutationOptions<ShareScreenshotMutation, ShareScreenshotMutationVariables>;
export const SurveysAddParticipantsUploadDocument = gql`
    mutation SurveysAddParticipantsUpload($surveyUuid: String!, $objectPath: String!, $importType: ImportTypesEnum!) {
  addParticipantsUpload(surveyUuid: $surveyUuid, objectPath: $objectPath, importType: $importType) {
    survey {
      uuid
      clientsUpload {
        fileName
      }
      participantsUpload {
        fileName
      }
    }
  }
}
    `;
export type SurveysAddParticipantsUploadMutationFn = Apollo.MutationFunction<SurveysAddParticipantsUploadMutation, SurveysAddParticipantsUploadMutationVariables>;

/**
 * __useSurveysAddParticipantsUploadMutation__
 *
 * To run a mutation, you first call `useSurveysAddParticipantsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysAddParticipantsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysAddParticipantsUploadMutation, { data, loading, error }] = useSurveysAddParticipantsUploadMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      objectPath: // value for 'objectPath'
 *      importType: // value for 'importType'
 *   },
 * });
 */
export function useSurveysAddParticipantsUploadMutation(baseOptions?: Apollo.MutationHookOptions<SurveysAddParticipantsUploadMutation, SurveysAddParticipantsUploadMutationVariables>) {
        return Apollo.useMutation<SurveysAddParticipantsUploadMutation, SurveysAddParticipantsUploadMutationVariables>(SurveysAddParticipantsUploadDocument, baseOptions);
      }
export type SurveysAddParticipantsUploadMutationHookResult = ReturnType<typeof useSurveysAddParticipantsUploadMutation>;
export type SurveysAddParticipantsUploadMutationResult = Apollo.MutationResult<SurveysAddParticipantsUploadMutation>;
export type SurveysAddParticipantsUploadMutationOptions = Apollo.BaseMutationOptions<SurveysAddParticipantsUploadMutation, SurveysAddParticipantsUploadMutationVariables>;
export const SurveysBulkSetQuestionDocument = gql`
    mutation SurveysBulkSetQuestion($surveyUuid: String!, $selectedQuestionUuids: [String!]!, $unselectedQuestionUuids: [String!]!) {
  bulkSetSurveyQuestion(surveyUuid: $surveyUuid, selectedQuestionUuids: $selectedQuestionUuids, unselectedQuestionUuids: $unselectedQuestionUuids) {
    errors
    questions {
      uuid
      text
    }
  }
}
    `;
export type SurveysBulkSetQuestionMutationFn = Apollo.MutationFunction<SurveysBulkSetQuestionMutation, SurveysBulkSetQuestionMutationVariables>;

/**
 * __useSurveysBulkSetQuestionMutation__
 *
 * To run a mutation, you first call `useSurveysBulkSetQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysBulkSetQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysBulkSetQuestionMutation, { data, loading, error }] = useSurveysBulkSetQuestionMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      selectedQuestionUuids: // value for 'selectedQuestionUuids'
 *      unselectedQuestionUuids: // value for 'unselectedQuestionUuids'
 *   },
 * });
 */
export function useSurveysBulkSetQuestionMutation(baseOptions?: Apollo.MutationHookOptions<SurveysBulkSetQuestionMutation, SurveysBulkSetQuestionMutationVariables>) {
        return Apollo.useMutation<SurveysBulkSetQuestionMutation, SurveysBulkSetQuestionMutationVariables>(SurveysBulkSetQuestionDocument, baseOptions);
      }
export type SurveysBulkSetQuestionMutationHookResult = ReturnType<typeof useSurveysBulkSetQuestionMutation>;
export type SurveysBulkSetQuestionMutationResult = Apollo.MutationResult<SurveysBulkSetQuestionMutation>;
export type SurveysBulkSetQuestionMutationOptions = Apollo.BaseMutationOptions<SurveysBulkSetQuestionMutation, SurveysBulkSetQuestionMutationVariables>;
export const SurveysDeleteGroupsSurveyQuestionScoreNotificationsDocument = gql`
    mutation SurveysDeleteGroupsSurveyQuestionScoreNotifications($notificationUuid: String!) {
  deleteGroupsSurveyQuestionScoreNotification(notificationUuid: $notificationUuid) {
    errors
  }
}
    `;
export type SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutationFn = Apollo.MutationFunction<SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation, SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutationVariables>;

/**
 * __useSurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation__
 *
 * To run a mutation, you first call `useSurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysDeleteGroupsSurveyQuestionScoreNotificationsMutation, { data, loading, error }] = useSurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation({
 *   variables: {
 *      notificationUuid: // value for 'notificationUuid'
 *   },
 * });
 */
export function useSurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation, SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutationVariables>) {
        return Apollo.useMutation<SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation, SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutationVariables>(SurveysDeleteGroupsSurveyQuestionScoreNotificationsDocument, baseOptions);
      }
export type SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutationHookResult = ReturnType<typeof useSurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation>;
export type SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutationResult = Apollo.MutationResult<SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation>;
export type SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutationOptions = Apollo.BaseMutationOptions<SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutation, SurveysDeleteGroupsSurveyQuestionScoreNotificationsMutationVariables>;
export const SurveysDeleteIntervalNotificationDocument = gql`
    mutation SurveysDeleteIntervalNotification($notificationUuid: String!) {
  deleteIntervalNotification(notificationUuid: $notificationUuid) {
    survey {
      uuid
      intervalNotifications {
        ...SurveysIntervalNotification
      }
    }
  }
}
    ${SurveysIntervalNotificationFragmentDoc}`;
export type SurveysDeleteIntervalNotificationMutationFn = Apollo.MutationFunction<SurveysDeleteIntervalNotificationMutation, SurveysDeleteIntervalNotificationMutationVariables>;

/**
 * __useSurveysDeleteIntervalNotificationMutation__
 *
 * To run a mutation, you first call `useSurveysDeleteIntervalNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysDeleteIntervalNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysDeleteIntervalNotificationMutation, { data, loading, error }] = useSurveysDeleteIntervalNotificationMutation({
 *   variables: {
 *      notificationUuid: // value for 'notificationUuid'
 *   },
 * });
 */
export function useSurveysDeleteIntervalNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SurveysDeleteIntervalNotificationMutation, SurveysDeleteIntervalNotificationMutationVariables>) {
        return Apollo.useMutation<SurveysDeleteIntervalNotificationMutation, SurveysDeleteIntervalNotificationMutationVariables>(SurveysDeleteIntervalNotificationDocument, baseOptions);
      }
export type SurveysDeleteIntervalNotificationMutationHookResult = ReturnType<typeof useSurveysDeleteIntervalNotificationMutation>;
export type SurveysDeleteIntervalNotificationMutationResult = Apollo.MutationResult<SurveysDeleteIntervalNotificationMutation>;
export type SurveysDeleteIntervalNotificationMutationOptions = Apollo.BaseMutationOptions<SurveysDeleteIntervalNotificationMutation, SurveysDeleteIntervalNotificationMutationVariables>;
export const SurveysDeleteParticipantDocument = gql`
    mutation SurveysDeleteParticipant($participantUuid: String!) {
  deleteParticipant(participantUuid: $participantUuid) {
    errors
  }
}
    `;
export type SurveysDeleteParticipantMutationFn = Apollo.MutationFunction<SurveysDeleteParticipantMutation, SurveysDeleteParticipantMutationVariables>;

/**
 * __useSurveysDeleteParticipantMutation__
 *
 * To run a mutation, you first call `useSurveysDeleteParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysDeleteParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysDeleteParticipantMutation, { data, loading, error }] = useSurveysDeleteParticipantMutation({
 *   variables: {
 *      participantUuid: // value for 'participantUuid'
 *   },
 * });
 */
export function useSurveysDeleteParticipantMutation(baseOptions?: Apollo.MutationHookOptions<SurveysDeleteParticipantMutation, SurveysDeleteParticipantMutationVariables>) {
        return Apollo.useMutation<SurveysDeleteParticipantMutation, SurveysDeleteParticipantMutationVariables>(SurveysDeleteParticipantDocument, baseOptions);
      }
export type SurveysDeleteParticipantMutationHookResult = ReturnType<typeof useSurveysDeleteParticipantMutation>;
export type SurveysDeleteParticipantMutationResult = Apollo.MutationResult<SurveysDeleteParticipantMutation>;
export type SurveysDeleteParticipantMutationOptions = Apollo.BaseMutationOptions<SurveysDeleteParticipantMutation, SurveysDeleteParticipantMutationVariables>;
export const SurveysDeleteQuestionDocument = gql`
    mutation SurveysDeleteQuestion($surveyUuid: String!, $questionUuid: String!) {
  deleteQuestion(surveyUuid: $surveyUuid, questionUuid: $questionUuid) {
    errors
  }
}
    `;
export type SurveysDeleteQuestionMutationFn = Apollo.MutationFunction<SurveysDeleteQuestionMutation, SurveysDeleteQuestionMutationVariables>;

/**
 * __useSurveysDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useSurveysDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysDeleteQuestionMutation, { data, loading, error }] = useSurveysDeleteQuestionMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      questionUuid: // value for 'questionUuid'
 *   },
 * });
 */
export function useSurveysDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<SurveysDeleteQuestionMutation, SurveysDeleteQuestionMutationVariables>) {
        return Apollo.useMutation<SurveysDeleteQuestionMutation, SurveysDeleteQuestionMutationVariables>(SurveysDeleteQuestionDocument, baseOptions);
      }
export type SurveysDeleteQuestionMutationHookResult = ReturnType<typeof useSurveysDeleteQuestionMutation>;
export type SurveysDeleteQuestionMutationResult = Apollo.MutationResult<SurveysDeleteQuestionMutation>;
export type SurveysDeleteQuestionMutationOptions = Apollo.BaseMutationOptions<SurveysDeleteQuestionMutation, SurveysDeleteQuestionMutationVariables>;
export const SurveysDeleteScheduledNotificationDocument = gql`
    mutation SurveysDeleteScheduledNotification($notificationUuid: String!) {
  deleteScheduledNotification(notificationUuid: $notificationUuid) {
    survey {
      uuid
      scheduledNotifications {
        ...SurveysScheduledNotification
      }
    }
  }
}
    ${SurveysScheduledNotificationFragmentDoc}`;
export type SurveysDeleteScheduledNotificationMutationFn = Apollo.MutationFunction<SurveysDeleteScheduledNotificationMutation, SurveysDeleteScheduledNotificationMutationVariables>;

/**
 * __useSurveysDeleteScheduledNotificationMutation__
 *
 * To run a mutation, you first call `useSurveysDeleteScheduledNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysDeleteScheduledNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysDeleteScheduledNotificationMutation, { data, loading, error }] = useSurveysDeleteScheduledNotificationMutation({
 *   variables: {
 *      notificationUuid: // value for 'notificationUuid'
 *   },
 * });
 */
export function useSurveysDeleteScheduledNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SurveysDeleteScheduledNotificationMutation, SurveysDeleteScheduledNotificationMutationVariables>) {
        return Apollo.useMutation<SurveysDeleteScheduledNotificationMutation, SurveysDeleteScheduledNotificationMutationVariables>(SurveysDeleteScheduledNotificationDocument, baseOptions);
      }
export type SurveysDeleteScheduledNotificationMutationHookResult = ReturnType<typeof useSurveysDeleteScheduledNotificationMutation>;
export type SurveysDeleteScheduledNotificationMutationResult = Apollo.MutationResult<SurveysDeleteScheduledNotificationMutation>;
export type SurveysDeleteScheduledNotificationMutationOptions = Apollo.BaseMutationOptions<SurveysDeleteScheduledNotificationMutation, SurveysDeleteScheduledNotificationMutationVariables>;
export const SurveysDeleteSurveyDocument = gql`
    mutation SurveysDeleteSurvey($uuid: String!) {
  deleteSurvey(uuid: $uuid) {
    errors
  }
}
    `;
export type SurveysDeleteSurveyMutationFn = Apollo.MutationFunction<SurveysDeleteSurveyMutation, SurveysDeleteSurveyMutationVariables>;

/**
 * __useSurveysDeleteSurveyMutation__
 *
 * To run a mutation, you first call `useSurveysDeleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysDeleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysDeleteSurveyMutation, { data, loading, error }] = useSurveysDeleteSurveyMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSurveysDeleteSurveyMutation(baseOptions?: Apollo.MutationHookOptions<SurveysDeleteSurveyMutation, SurveysDeleteSurveyMutationVariables>) {
        return Apollo.useMutation<SurveysDeleteSurveyMutation, SurveysDeleteSurveyMutationVariables>(SurveysDeleteSurveyDocument, baseOptions);
      }
export type SurveysDeleteSurveyMutationHookResult = ReturnType<typeof useSurveysDeleteSurveyMutation>;
export type SurveysDeleteSurveyMutationResult = Apollo.MutationResult<SurveysDeleteSurveyMutation>;
export type SurveysDeleteSurveyMutationOptions = Apollo.BaseMutationOptions<SurveysDeleteSurveyMutation, SurveysDeleteSurveyMutationVariables>;
export const SurveysGenerateUploadUrlDocument = gql`
    mutation SurveysGenerateUploadUrl($fileName: String!, $contentType: String!) {
  generateUploadUrl(fileName: $fileName, contentType: $contentType) {
    signedUrl {
      url
      path
    }
  }
}
    `;
export type SurveysGenerateUploadUrlMutationFn = Apollo.MutationFunction<SurveysGenerateUploadUrlMutation, SurveysGenerateUploadUrlMutationVariables>;

/**
 * __useSurveysGenerateUploadUrlMutation__
 *
 * To run a mutation, you first call `useSurveysGenerateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysGenerateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysGenerateUploadUrlMutation, { data, loading, error }] = useSurveysGenerateUploadUrlMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useSurveysGenerateUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<SurveysGenerateUploadUrlMutation, SurveysGenerateUploadUrlMutationVariables>) {
        return Apollo.useMutation<SurveysGenerateUploadUrlMutation, SurveysGenerateUploadUrlMutationVariables>(SurveysGenerateUploadUrlDocument, baseOptions);
      }
export type SurveysGenerateUploadUrlMutationHookResult = ReturnType<typeof useSurveysGenerateUploadUrlMutation>;
export type SurveysGenerateUploadUrlMutationResult = Apollo.MutationResult<SurveysGenerateUploadUrlMutation>;
export type SurveysGenerateUploadUrlMutationOptions = Apollo.BaseMutationOptions<SurveysGenerateUploadUrlMutation, SurveysGenerateUploadUrlMutationVariables>;
export const SurveysIngestParticipantsUploadDocument = gql`
    mutation SurveysIngestParticipantsUpload($surveyUuid: String!, $importType: ImportTypesEnum!) {
  ingestParticipantsUpload(surveyUuid: $surveyUuid, importType: $importType) {
    survey {
      uuid
    }
  }
}
    `;
export type SurveysIngestParticipantsUploadMutationFn = Apollo.MutationFunction<SurveysIngestParticipantsUploadMutation, SurveysIngestParticipantsUploadMutationVariables>;

/**
 * __useSurveysIngestParticipantsUploadMutation__
 *
 * To run a mutation, you first call `useSurveysIngestParticipantsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysIngestParticipantsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysIngestParticipantsUploadMutation, { data, loading, error }] = useSurveysIngestParticipantsUploadMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      importType: // value for 'importType'
 *   },
 * });
 */
export function useSurveysIngestParticipantsUploadMutation(baseOptions?: Apollo.MutationHookOptions<SurveysIngestParticipantsUploadMutation, SurveysIngestParticipantsUploadMutationVariables>) {
        return Apollo.useMutation<SurveysIngestParticipantsUploadMutation, SurveysIngestParticipantsUploadMutationVariables>(SurveysIngestParticipantsUploadDocument, baseOptions);
      }
export type SurveysIngestParticipantsUploadMutationHookResult = ReturnType<typeof useSurveysIngestParticipantsUploadMutation>;
export type SurveysIngestParticipantsUploadMutationResult = Apollo.MutationResult<SurveysIngestParticipantsUploadMutation>;
export type SurveysIngestParticipantsUploadMutationOptions = Apollo.BaseMutationOptions<SurveysIngestParticipantsUploadMutation, SurveysIngestParticipantsUploadMutationVariables>;
export const SurveysRemoveParticipantsUploadDocument = gql`
    mutation SurveysRemoveParticipantsUpload($surveyUuid: String!, $importType: ImportTypesEnum!) {
  removeParticipantsUpload(surveyUuid: $surveyUuid, importType: $importType) {
    survey {
      uuid
      participantsUpload {
        fileName
        updated
        errors {
          code
          dtCode
          dtName
          cell
        }
        summary {
          processed
          deleted
          added
          updated
          filters {
            filterTypeName
            filterValueNames
          }
        }
      }
      clientsUpload {
        fileName
      }
    }
  }
}
    `;
export type SurveysRemoveParticipantsUploadMutationFn = Apollo.MutationFunction<SurveysRemoveParticipantsUploadMutation, SurveysRemoveParticipantsUploadMutationVariables>;

/**
 * __useSurveysRemoveParticipantsUploadMutation__
 *
 * To run a mutation, you first call `useSurveysRemoveParticipantsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysRemoveParticipantsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysRemoveParticipantsUploadMutation, { data, loading, error }] = useSurveysRemoveParticipantsUploadMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      importType: // value for 'importType'
 *   },
 * });
 */
export function useSurveysRemoveParticipantsUploadMutation(baseOptions?: Apollo.MutationHookOptions<SurveysRemoveParticipantsUploadMutation, SurveysRemoveParticipantsUploadMutationVariables>) {
        return Apollo.useMutation<SurveysRemoveParticipantsUploadMutation, SurveysRemoveParticipantsUploadMutationVariables>(SurveysRemoveParticipantsUploadDocument, baseOptions);
      }
export type SurveysRemoveParticipantsUploadMutationHookResult = ReturnType<typeof useSurveysRemoveParticipantsUploadMutation>;
export type SurveysRemoveParticipantsUploadMutationResult = Apollo.MutationResult<SurveysRemoveParticipantsUploadMutation>;
export type SurveysRemoveParticipantsUploadMutationOptions = Apollo.BaseMutationOptions<SurveysRemoveParticipantsUploadMutation, SurveysRemoveParticipantsUploadMutationVariables>;
export const SurveysResetIntervalNotificationDocument = gql`
    mutation SurveysResetIntervalNotification($notificationUuid: String!, $emailOrSms: String!) {
  resetIntervalNotification(notificationUuid: $notificationUuid, emailOrSms: $emailOrSms) {
    notification {
      ...SurveysIntervalNotification
    }
  }
}
    ${SurveysIntervalNotificationFragmentDoc}`;
export type SurveysResetIntervalNotificationMutationFn = Apollo.MutationFunction<SurveysResetIntervalNotificationMutation, SurveysResetIntervalNotificationMutationVariables>;

/**
 * __useSurveysResetIntervalNotificationMutation__
 *
 * To run a mutation, you first call `useSurveysResetIntervalNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysResetIntervalNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysResetIntervalNotificationMutation, { data, loading, error }] = useSurveysResetIntervalNotificationMutation({
 *   variables: {
 *      notificationUuid: // value for 'notificationUuid'
 *      emailOrSms: // value for 'emailOrSms'
 *   },
 * });
 */
export function useSurveysResetIntervalNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SurveysResetIntervalNotificationMutation, SurveysResetIntervalNotificationMutationVariables>) {
        return Apollo.useMutation<SurveysResetIntervalNotificationMutation, SurveysResetIntervalNotificationMutationVariables>(SurveysResetIntervalNotificationDocument, baseOptions);
      }
export type SurveysResetIntervalNotificationMutationHookResult = ReturnType<typeof useSurveysResetIntervalNotificationMutation>;
export type SurveysResetIntervalNotificationMutationResult = Apollo.MutationResult<SurveysResetIntervalNotificationMutation>;
export type SurveysResetIntervalNotificationMutationOptions = Apollo.BaseMutationOptions<SurveysResetIntervalNotificationMutation, SurveysResetIntervalNotificationMutationVariables>;
export const SurveysResetScheduledNotificationDocument = gql`
    mutation SurveysResetScheduledNotification($notificationUuid: String!, $emailOrSms: String!) {
  resetScheduledNotification(notificationUuid: $notificationUuid, emailOrSms: $emailOrSms) {
    notification {
      ...SurveysScheduledNotification
    }
  }
}
    ${SurveysScheduledNotificationFragmentDoc}`;
export type SurveysResetScheduledNotificationMutationFn = Apollo.MutationFunction<SurveysResetScheduledNotificationMutation, SurveysResetScheduledNotificationMutationVariables>;

/**
 * __useSurveysResetScheduledNotificationMutation__
 *
 * To run a mutation, you first call `useSurveysResetScheduledNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysResetScheduledNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysResetScheduledNotificationMutation, { data, loading, error }] = useSurveysResetScheduledNotificationMutation({
 *   variables: {
 *      notificationUuid: // value for 'notificationUuid'
 *      emailOrSms: // value for 'emailOrSms'
 *   },
 * });
 */
export function useSurveysResetScheduledNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SurveysResetScheduledNotificationMutation, SurveysResetScheduledNotificationMutationVariables>) {
        return Apollo.useMutation<SurveysResetScheduledNotificationMutation, SurveysResetScheduledNotificationMutationVariables>(SurveysResetScheduledNotificationDocument, baseOptions);
      }
export type SurveysResetScheduledNotificationMutationHookResult = ReturnType<typeof useSurveysResetScheduledNotificationMutation>;
export type SurveysResetScheduledNotificationMutationResult = Apollo.MutationResult<SurveysResetScheduledNotificationMutation>;
export type SurveysResetScheduledNotificationMutationOptions = Apollo.BaseMutationOptions<SurveysResetScheduledNotificationMutation, SurveysResetScheduledNotificationMutationVariables>;
export const SurveysSendIntervalTestEmailDocument = gql`
    mutation SurveysSendIntervalTestEmail($notificationUuid: String!, $emailAddress: String!) {
  sendIntervalTestEmail(notificationUuid: $notificationUuid, emailAddress: $emailAddress) {
    errors
  }
}
    `;
export type SurveysSendIntervalTestEmailMutationFn = Apollo.MutationFunction<SurveysSendIntervalTestEmailMutation, SurveysSendIntervalTestEmailMutationVariables>;

/**
 * __useSurveysSendIntervalTestEmailMutation__
 *
 * To run a mutation, you first call `useSurveysSendIntervalTestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysSendIntervalTestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysSendIntervalTestEmailMutation, { data, loading, error }] = useSurveysSendIntervalTestEmailMutation({
 *   variables: {
 *      notificationUuid: // value for 'notificationUuid'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useSurveysSendIntervalTestEmailMutation(baseOptions?: Apollo.MutationHookOptions<SurveysSendIntervalTestEmailMutation, SurveysSendIntervalTestEmailMutationVariables>) {
        return Apollo.useMutation<SurveysSendIntervalTestEmailMutation, SurveysSendIntervalTestEmailMutationVariables>(SurveysSendIntervalTestEmailDocument, baseOptions);
      }
export type SurveysSendIntervalTestEmailMutationHookResult = ReturnType<typeof useSurveysSendIntervalTestEmailMutation>;
export type SurveysSendIntervalTestEmailMutationResult = Apollo.MutationResult<SurveysSendIntervalTestEmailMutation>;
export type SurveysSendIntervalTestEmailMutationOptions = Apollo.BaseMutationOptions<SurveysSendIntervalTestEmailMutation, SurveysSendIntervalTestEmailMutationVariables>;
export const SurveysSendIntervalTestSmsDocument = gql`
    mutation SurveysSendIntervalTestSms($notificationUuid: String!, $smsNumber: String!) {
  sendIntervalTestSms(notificationUuid: $notificationUuid, smsNumber: $smsNumber) {
    errors
  }
}
    `;
export type SurveysSendIntervalTestSmsMutationFn = Apollo.MutationFunction<SurveysSendIntervalTestSmsMutation, SurveysSendIntervalTestSmsMutationVariables>;

/**
 * __useSurveysSendIntervalTestSmsMutation__
 *
 * To run a mutation, you first call `useSurveysSendIntervalTestSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysSendIntervalTestSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysSendIntervalTestSmsMutation, { data, loading, error }] = useSurveysSendIntervalTestSmsMutation({
 *   variables: {
 *      notificationUuid: // value for 'notificationUuid'
 *      smsNumber: // value for 'smsNumber'
 *   },
 * });
 */
export function useSurveysSendIntervalTestSmsMutation(baseOptions?: Apollo.MutationHookOptions<SurveysSendIntervalTestSmsMutation, SurveysSendIntervalTestSmsMutationVariables>) {
        return Apollo.useMutation<SurveysSendIntervalTestSmsMutation, SurveysSendIntervalTestSmsMutationVariables>(SurveysSendIntervalTestSmsDocument, baseOptions);
      }
export type SurveysSendIntervalTestSmsMutationHookResult = ReturnType<typeof useSurveysSendIntervalTestSmsMutation>;
export type SurveysSendIntervalTestSmsMutationResult = Apollo.MutationResult<SurveysSendIntervalTestSmsMutation>;
export type SurveysSendIntervalTestSmsMutationOptions = Apollo.BaseMutationOptions<SurveysSendIntervalTestSmsMutation, SurveysSendIntervalTestSmsMutationVariables>;
export const SurveysSendScheduledTestEmailDocument = gql`
    mutation SurveysSendScheduledTestEmail($notificationUuid: String!, $emailAddress: String!) {
  sendScheduledTestEmail(notificationUuid: $notificationUuid, emailAddress: $emailAddress) {
    errors
  }
}
    `;
export type SurveysSendScheduledTestEmailMutationFn = Apollo.MutationFunction<SurveysSendScheduledTestEmailMutation, SurveysSendScheduledTestEmailMutationVariables>;

/**
 * __useSurveysSendScheduledTestEmailMutation__
 *
 * To run a mutation, you first call `useSurveysSendScheduledTestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysSendScheduledTestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysSendScheduledTestEmailMutation, { data, loading, error }] = useSurveysSendScheduledTestEmailMutation({
 *   variables: {
 *      notificationUuid: // value for 'notificationUuid'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useSurveysSendScheduledTestEmailMutation(baseOptions?: Apollo.MutationHookOptions<SurveysSendScheduledTestEmailMutation, SurveysSendScheduledTestEmailMutationVariables>) {
        return Apollo.useMutation<SurveysSendScheduledTestEmailMutation, SurveysSendScheduledTestEmailMutationVariables>(SurveysSendScheduledTestEmailDocument, baseOptions);
      }
export type SurveysSendScheduledTestEmailMutationHookResult = ReturnType<typeof useSurveysSendScheduledTestEmailMutation>;
export type SurveysSendScheduledTestEmailMutationResult = Apollo.MutationResult<SurveysSendScheduledTestEmailMutation>;
export type SurveysSendScheduledTestEmailMutationOptions = Apollo.BaseMutationOptions<SurveysSendScheduledTestEmailMutation, SurveysSendScheduledTestEmailMutationVariables>;
export const SurveysSendScheduledTestSmsDocument = gql`
    mutation SurveysSendScheduledTestSms($notificationUuid: String!, $smsNumber: String!) {
  sendScheduledTestSms(notificationUuid: $notificationUuid, smsNumber: $smsNumber) {
    errors
  }
}
    `;
export type SurveysSendScheduledTestSmsMutationFn = Apollo.MutationFunction<SurveysSendScheduledTestSmsMutation, SurveysSendScheduledTestSmsMutationVariables>;

/**
 * __useSurveysSendScheduledTestSmsMutation__
 *
 * To run a mutation, you first call `useSurveysSendScheduledTestSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysSendScheduledTestSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysSendScheduledTestSmsMutation, { data, loading, error }] = useSurveysSendScheduledTestSmsMutation({
 *   variables: {
 *      notificationUuid: // value for 'notificationUuid'
 *      smsNumber: // value for 'smsNumber'
 *   },
 * });
 */
export function useSurveysSendScheduledTestSmsMutation(baseOptions?: Apollo.MutationHookOptions<SurveysSendScheduledTestSmsMutation, SurveysSendScheduledTestSmsMutationVariables>) {
        return Apollo.useMutation<SurveysSendScheduledTestSmsMutation, SurveysSendScheduledTestSmsMutationVariables>(SurveysSendScheduledTestSmsDocument, baseOptions);
      }
export type SurveysSendScheduledTestSmsMutationHookResult = ReturnType<typeof useSurveysSendScheduledTestSmsMutation>;
export type SurveysSendScheduledTestSmsMutationResult = Apollo.MutationResult<SurveysSendScheduledTestSmsMutation>;
export type SurveysSendScheduledTestSmsMutationOptions = Apollo.BaseMutationOptions<SurveysSendScheduledTestSmsMutation, SurveysSendScheduledTestSmsMutationVariables>;
export const SurveysUpdateCustomQuestionDocument = gql`
    mutation SurveysUpdateCustomQuestion($surveyUuid: String!, $question: QuestionInput!) {
  updateCustomQuestion(surveyUuid: $surveyUuid, questionInput: $question) {
    errors
    question {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}`;
export type SurveysUpdateCustomQuestionMutationFn = Apollo.MutationFunction<SurveysUpdateCustomQuestionMutation, SurveysUpdateCustomQuestionMutationVariables>;

/**
 * __useSurveysUpdateCustomQuestionMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateCustomQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateCustomQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateCustomQuestionMutation, { data, loading, error }] = useSurveysUpdateCustomQuestionMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useSurveysUpdateCustomQuestionMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateCustomQuestionMutation, SurveysUpdateCustomQuestionMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateCustomQuestionMutation, SurveysUpdateCustomQuestionMutationVariables>(SurveysUpdateCustomQuestionDocument, baseOptions);
      }
export type SurveysUpdateCustomQuestionMutationHookResult = ReturnType<typeof useSurveysUpdateCustomQuestionMutation>;
export type SurveysUpdateCustomQuestionMutationResult = Apollo.MutationResult<SurveysUpdateCustomQuestionMutation>;
export type SurveysUpdateCustomQuestionMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateCustomQuestionMutation, SurveysUpdateCustomQuestionMutationVariables>;
export const SurveysUpdateEligibleParticipantSurveyConfigsDocument = gql`
    mutation SurveysUpdateEligibleParticipantSurveyConfigs($surveyUuid: String!, $configs: [EligibleParticipantSurveyConfigInput!]!) {
  updateEligibleParticipantSurveyConfigs(surveyUuid: $surveyUuid, configs: $configs) {
    errors
    survey {
      uuid
      eligibleParticipantSurveyConfigs {
        ...EligibleParticipantSurveyConfig
      }
    }
  }
}
    ${EligibleParticipantSurveyConfigFragmentDoc}`;
export type SurveysUpdateEligibleParticipantSurveyConfigsMutationFn = Apollo.MutationFunction<SurveysUpdateEligibleParticipantSurveyConfigsMutation, SurveysUpdateEligibleParticipantSurveyConfigsMutationVariables>;

/**
 * __useSurveysUpdateEligibleParticipantSurveyConfigsMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateEligibleParticipantSurveyConfigsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateEligibleParticipantSurveyConfigsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateEligibleParticipantSurveyConfigsMutation, { data, loading, error }] = useSurveysUpdateEligibleParticipantSurveyConfigsMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      configs: // value for 'configs'
 *   },
 * });
 */
export function useSurveysUpdateEligibleParticipantSurveyConfigsMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateEligibleParticipantSurveyConfigsMutation, SurveysUpdateEligibleParticipantSurveyConfigsMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateEligibleParticipantSurveyConfigsMutation, SurveysUpdateEligibleParticipantSurveyConfigsMutationVariables>(SurveysUpdateEligibleParticipantSurveyConfigsDocument, baseOptions);
      }
export type SurveysUpdateEligibleParticipantSurveyConfigsMutationHookResult = ReturnType<typeof useSurveysUpdateEligibleParticipantSurveyConfigsMutation>;
export type SurveysUpdateEligibleParticipantSurveyConfigsMutationResult = Apollo.MutationResult<SurveysUpdateEligibleParticipantSurveyConfigsMutation>;
export type SurveysUpdateEligibleParticipantSurveyConfigsMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateEligibleParticipantSurveyConfigsMutation, SurveysUpdateEligibleParticipantSurveyConfigsMutationVariables>;
export const SurveysUpdateGroupsAccessToSurveyDocument = gql`
    mutation SurveysUpdateGroupsAccessToSurvey($surveyUuid: String!, $groupsUuidsWithSurveyAccess: [String!]!) {
  updateGroupsAccessToSurvey(surveyUuid: $surveyUuid, groupsUuidsWithSurveyAccess: $groupsUuidsWithSurveyAccess) {
    errors
    survey {
      uuid
      groupsWithSurveyAccess {
        uuid
        name
      }
    }
  }
}
    `;
export type SurveysUpdateGroupsAccessToSurveyMutationFn = Apollo.MutationFunction<SurveysUpdateGroupsAccessToSurveyMutation, SurveysUpdateGroupsAccessToSurveyMutationVariables>;

/**
 * __useSurveysUpdateGroupsAccessToSurveyMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateGroupsAccessToSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateGroupsAccessToSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateGroupsAccessToSurveyMutation, { data, loading, error }] = useSurveysUpdateGroupsAccessToSurveyMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      groupsUuidsWithSurveyAccess: // value for 'groupsUuidsWithSurveyAccess'
 *   },
 * });
 */
export function useSurveysUpdateGroupsAccessToSurveyMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateGroupsAccessToSurveyMutation, SurveysUpdateGroupsAccessToSurveyMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateGroupsAccessToSurveyMutation, SurveysUpdateGroupsAccessToSurveyMutationVariables>(SurveysUpdateGroupsAccessToSurveyDocument, baseOptions);
      }
export type SurveysUpdateGroupsAccessToSurveyMutationHookResult = ReturnType<typeof useSurveysUpdateGroupsAccessToSurveyMutation>;
export type SurveysUpdateGroupsAccessToSurveyMutationResult = Apollo.MutationResult<SurveysUpdateGroupsAccessToSurveyMutation>;
export type SurveysUpdateGroupsAccessToSurveyMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateGroupsAccessToSurveyMutation, SurveysUpdateGroupsAccessToSurveyMutationVariables>;
export const SurveysUpdateGroupsAverageScoreNotificationsDocument = gql`
    mutation SurveysUpdateGroupsAverageScoreNotifications($surveyUuid: String!, $groupsNotificationsInput: [GroupSurveyAverageScoreNotificationInput!]!) {
  updateGroupsSurveyAverageScoreNotifications(surveyUuid: $surveyUuid, groupsNotificationsInput: $groupsNotificationsInput) {
    errors
    survey {
      uuid
      groupsAverageScoreNotifications {
        enabled
        frequency
        groups {
          uuid
          name
          allSurveys
        }
      }
    }
  }
}
    `;
export type SurveysUpdateGroupsAverageScoreNotificationsMutationFn = Apollo.MutationFunction<SurveysUpdateGroupsAverageScoreNotificationsMutation, SurveysUpdateGroupsAverageScoreNotificationsMutationVariables>;

/**
 * __useSurveysUpdateGroupsAverageScoreNotificationsMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateGroupsAverageScoreNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateGroupsAverageScoreNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateGroupsAverageScoreNotificationsMutation, { data, loading, error }] = useSurveysUpdateGroupsAverageScoreNotificationsMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      groupsNotificationsInput: // value for 'groupsNotificationsInput'
 *   },
 * });
 */
export function useSurveysUpdateGroupsAverageScoreNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateGroupsAverageScoreNotificationsMutation, SurveysUpdateGroupsAverageScoreNotificationsMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateGroupsAverageScoreNotificationsMutation, SurveysUpdateGroupsAverageScoreNotificationsMutationVariables>(SurveysUpdateGroupsAverageScoreNotificationsDocument, baseOptions);
      }
export type SurveysUpdateGroupsAverageScoreNotificationsMutationHookResult = ReturnType<typeof useSurveysUpdateGroupsAverageScoreNotificationsMutation>;
export type SurveysUpdateGroupsAverageScoreNotificationsMutationResult = Apollo.MutationResult<SurveysUpdateGroupsAverageScoreNotificationsMutation>;
export type SurveysUpdateGroupsAverageScoreNotificationsMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateGroupsAverageScoreNotificationsMutation, SurveysUpdateGroupsAverageScoreNotificationsMutationVariables>;
export const SurveysUpdateGroupsQuestionScoreNotificationsDocument = gql`
    mutation SurveysUpdateGroupsQuestionScoreNotifications($surveyUuid: String!, $groupsNotificationsInput: [GroupSurveyQuestionScoreNotificationInput!]!) {
  updateGroupsSurveyQuestionScoreNotifications(surveyUuid: $surveyUuid, groupsNotificationsInput: $groupsNotificationsInput) {
    errors
    survey {
      uuid
      groupsQuestionScoreNotifications {
        uuid
        notificationName
        enabled
        scoreType
        frequency
        minScore
        maxScore
        statement {
          uuid
          text
        }
        openEndedQuestion {
          uuid
          text
        }
        groups {
          uuid
          name
          allSurveys
        }
      }
    }
  }
}
    `;
export type SurveysUpdateGroupsQuestionScoreNotificationsMutationFn = Apollo.MutationFunction<SurveysUpdateGroupsQuestionScoreNotificationsMutation, SurveysUpdateGroupsQuestionScoreNotificationsMutationVariables>;

/**
 * __useSurveysUpdateGroupsQuestionScoreNotificationsMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateGroupsQuestionScoreNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateGroupsQuestionScoreNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateGroupsQuestionScoreNotificationsMutation, { data, loading, error }] = useSurveysUpdateGroupsQuestionScoreNotificationsMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      groupsNotificationsInput: // value for 'groupsNotificationsInput'
 *   },
 * });
 */
export function useSurveysUpdateGroupsQuestionScoreNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateGroupsQuestionScoreNotificationsMutation, SurveysUpdateGroupsQuestionScoreNotificationsMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateGroupsQuestionScoreNotificationsMutation, SurveysUpdateGroupsQuestionScoreNotificationsMutationVariables>(SurveysUpdateGroupsQuestionScoreNotificationsDocument, baseOptions);
      }
export type SurveysUpdateGroupsQuestionScoreNotificationsMutationHookResult = ReturnType<typeof useSurveysUpdateGroupsQuestionScoreNotificationsMutation>;
export type SurveysUpdateGroupsQuestionScoreNotificationsMutationResult = Apollo.MutationResult<SurveysUpdateGroupsQuestionScoreNotificationsMutation>;
export type SurveysUpdateGroupsQuestionScoreNotificationsMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateGroupsQuestionScoreNotificationsMutation, SurveysUpdateGroupsQuestionScoreNotificationsMutationVariables>;
export const SurveysUpdateGroupsSurveyResponseNotificationsDocument = gql`
    mutation SurveysUpdateGroupsSurveyResponseNotifications($surveyUuid: String!, $groupsNotificationsInput: [GroupSurveyResponseNotificationInput!]!) {
  updateGroupsSurveyResponseNotifications(surveyUuid: $surveyUuid, groupsNotificationsInput: $groupsNotificationsInput) {
    errors
    survey {
      uuid
      groupsSurveyResponseNotifications {
        enabled
        notificationType
        groups {
          uuid
          name
          allSurveys
        }
      }
    }
  }
}
    `;
export type SurveysUpdateGroupsSurveyResponseNotificationsMutationFn = Apollo.MutationFunction<SurveysUpdateGroupsSurveyResponseNotificationsMutation, SurveysUpdateGroupsSurveyResponseNotificationsMutationVariables>;

/**
 * __useSurveysUpdateGroupsSurveyResponseNotificationsMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateGroupsSurveyResponseNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateGroupsSurveyResponseNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateGroupsSurveyResponseNotificationsMutation, { data, loading, error }] = useSurveysUpdateGroupsSurveyResponseNotificationsMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      groupsNotificationsInput: // value for 'groupsNotificationsInput'
 *   },
 * });
 */
export function useSurveysUpdateGroupsSurveyResponseNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateGroupsSurveyResponseNotificationsMutation, SurveysUpdateGroupsSurveyResponseNotificationsMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateGroupsSurveyResponseNotificationsMutation, SurveysUpdateGroupsSurveyResponseNotificationsMutationVariables>(SurveysUpdateGroupsSurveyResponseNotificationsDocument, baseOptions);
      }
export type SurveysUpdateGroupsSurveyResponseNotificationsMutationHookResult = ReturnType<typeof useSurveysUpdateGroupsSurveyResponseNotificationsMutation>;
export type SurveysUpdateGroupsSurveyResponseNotificationsMutationResult = Apollo.MutationResult<SurveysUpdateGroupsSurveyResponseNotificationsMutation>;
export type SurveysUpdateGroupsSurveyResponseNotificationsMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateGroupsSurveyResponseNotificationsMutation, SurveysUpdateGroupsSurveyResponseNotificationsMutationVariables>;
export const SurveysUpdateIntervalNotificationDocument = gql`
    mutation SurveysUpdateIntervalNotification($surveyUuid: String!, $notificationInput: IntervalNotificationInput!) {
  updateIntervalNotification(surveyUuid: $surveyUuid, notificationInput: $notificationInput) {
    errors
    survey {
      uuid
      intervalNotifications {
        ...SurveysIntervalNotification
      }
    }
  }
}
    ${SurveysIntervalNotificationFragmentDoc}`;
export type SurveysUpdateIntervalNotificationMutationFn = Apollo.MutationFunction<SurveysUpdateIntervalNotificationMutation, SurveysUpdateIntervalNotificationMutationVariables>;

/**
 * __useSurveysUpdateIntervalNotificationMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateIntervalNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateIntervalNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateIntervalNotificationMutation, { data, loading, error }] = useSurveysUpdateIntervalNotificationMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      notificationInput: // value for 'notificationInput'
 *   },
 * });
 */
export function useSurveysUpdateIntervalNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateIntervalNotificationMutation, SurveysUpdateIntervalNotificationMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateIntervalNotificationMutation, SurveysUpdateIntervalNotificationMutationVariables>(SurveysUpdateIntervalNotificationDocument, baseOptions);
      }
export type SurveysUpdateIntervalNotificationMutationHookResult = ReturnType<typeof useSurveysUpdateIntervalNotificationMutation>;
export type SurveysUpdateIntervalNotificationMutationResult = Apollo.MutationResult<SurveysUpdateIntervalNotificationMutation>;
export type SurveysUpdateIntervalNotificationMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateIntervalNotificationMutation, SurveysUpdateIntervalNotificationMutationVariables>;
export const SurveysUpdateParticipantDocument = gql`
    mutation SurveysUpdateParticipant($surveyUuid: String!, $participantUuid: String, $participant: ParticipantInput!) {
  updateParticipant(surveyUuid: $surveyUuid, participantUuid: $participantUuid, participant: $participant) {
    participant {
      uuid
      firstName
      surveyCode
      personalEmail
      workEmail
      mobilePhone
    }
    errors
  }
}
    `;
export type SurveysUpdateParticipantMutationFn = Apollo.MutationFunction<SurveysUpdateParticipantMutation, SurveysUpdateParticipantMutationVariables>;

/**
 * __useSurveysUpdateParticipantMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateParticipantMutation, { data, loading, error }] = useSurveysUpdateParticipantMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      participantUuid: // value for 'participantUuid'
 *      participant: // value for 'participant'
 *   },
 * });
 */
export function useSurveysUpdateParticipantMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateParticipantMutation, SurveysUpdateParticipantMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateParticipantMutation, SurveysUpdateParticipantMutationVariables>(SurveysUpdateParticipantDocument, baseOptions);
      }
export type SurveysUpdateParticipantMutationHookResult = ReturnType<typeof useSurveysUpdateParticipantMutation>;
export type SurveysUpdateParticipantMutationResult = Apollo.MutationResult<SurveysUpdateParticipantMutation>;
export type SurveysUpdateParticipantMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateParticipantMutation, SurveysUpdateParticipantMutationVariables>;
export const SurveysUpdatePersonalInfoQuestionDocument = gql`
    mutation SurveysUpdatePersonalInfoQuestion($surveyUuid: String!, $questionInput: PersonalInfoQuestionInput!) {
  updatePersonalInfoQuestion(surveyUuid: $surveyUuid, questionInput: $questionInput) {
    errors
    question {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}`;
export type SurveysUpdatePersonalInfoQuestionMutationFn = Apollo.MutationFunction<SurveysUpdatePersonalInfoQuestionMutation, SurveysUpdatePersonalInfoQuestionMutationVariables>;

/**
 * __useSurveysUpdatePersonalInfoQuestionMutation__
 *
 * To run a mutation, you first call `useSurveysUpdatePersonalInfoQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdatePersonalInfoQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdatePersonalInfoQuestionMutation, { data, loading, error }] = useSurveysUpdatePersonalInfoQuestionMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      questionInput: // value for 'questionInput'
 *   },
 * });
 */
export function useSurveysUpdatePersonalInfoQuestionMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdatePersonalInfoQuestionMutation, SurveysUpdatePersonalInfoQuestionMutationVariables>) {
        return Apollo.useMutation<SurveysUpdatePersonalInfoQuestionMutation, SurveysUpdatePersonalInfoQuestionMutationVariables>(SurveysUpdatePersonalInfoQuestionDocument, baseOptions);
      }
export type SurveysUpdatePersonalInfoQuestionMutationHookResult = ReturnType<typeof useSurveysUpdatePersonalInfoQuestionMutation>;
export type SurveysUpdatePersonalInfoQuestionMutationResult = Apollo.MutationResult<SurveysUpdatePersonalInfoQuestionMutation>;
export type SurveysUpdatePersonalInfoQuestionMutationOptions = Apollo.BaseMutationOptions<SurveysUpdatePersonalInfoQuestionMutation, SurveysUpdatePersonalInfoQuestionMutationVariables>;
export const SurveysUpdateReportDocument = gql`
    mutation SurveysUpdateReport($surveyUuid: String!, $password: String) {
  updateReport(surveyUuid: $surveyUuid, password: $password) {
    errors
    survey {
      uuid
      reportPassword
    }
  }
}
    `;
export type SurveysUpdateReportMutationFn = Apollo.MutationFunction<SurveysUpdateReportMutation, SurveysUpdateReportMutationVariables>;

/**
 * __useSurveysUpdateReportMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateReportMutation, { data, loading, error }] = useSurveysUpdateReportMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSurveysUpdateReportMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateReportMutation, SurveysUpdateReportMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateReportMutation, SurveysUpdateReportMutationVariables>(SurveysUpdateReportDocument, baseOptions);
      }
export type SurveysUpdateReportMutationHookResult = ReturnType<typeof useSurveysUpdateReportMutation>;
export type SurveysUpdateReportMutationResult = Apollo.MutationResult<SurveysUpdateReportMutation>;
export type SurveysUpdateReportMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateReportMutation, SurveysUpdateReportMutationVariables>;
export const SurveysUpdateScheduledNotificationDocument = gql`
    mutation SurveysUpdateScheduledNotification($surveyUuid: String!, $notificationInput: ScheduledNotificationInput!) {
  updateScheduledNotification(surveyUuid: $surveyUuid, notificationInput: $notificationInput) {
    errors
    survey {
      uuid
      scheduledNotifications {
        ...SurveysScheduledNotification
      }
    }
  }
}
    ${SurveysScheduledNotificationFragmentDoc}`;
export type SurveysUpdateScheduledNotificationMutationFn = Apollo.MutationFunction<SurveysUpdateScheduledNotificationMutation, SurveysUpdateScheduledNotificationMutationVariables>;

/**
 * __useSurveysUpdateScheduledNotificationMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateScheduledNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateScheduledNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateScheduledNotificationMutation, { data, loading, error }] = useSurveysUpdateScheduledNotificationMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      notificationInput: // value for 'notificationInput'
 *   },
 * });
 */
export function useSurveysUpdateScheduledNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateScheduledNotificationMutation, SurveysUpdateScheduledNotificationMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateScheduledNotificationMutation, SurveysUpdateScheduledNotificationMutationVariables>(SurveysUpdateScheduledNotificationDocument, baseOptions);
      }
export type SurveysUpdateScheduledNotificationMutationHookResult = ReturnType<typeof useSurveysUpdateScheduledNotificationMutation>;
export type SurveysUpdateScheduledNotificationMutationResult = Apollo.MutationResult<SurveysUpdateScheduledNotificationMutation>;
export type SurveysUpdateScheduledNotificationMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateScheduledNotificationMutation, SurveysUpdateScheduledNotificationMutationVariables>;
export const SurveysUpdateSurveyDocument = gql`
    mutation SurveysUpdateSurvey($uuid: String, $name: String!, $description: String, $type: SurveyTypeEnum!, $startDate: DateTime!, $endDate: DateTime, $languages: [String!], $associatedSurveyUuid: String, $distributionType: SurveyDistributionTypeEnum, $participantDataSource: SurveyParticipantDataSourceEnum, $hasConfidentialResults: Boolean, $filterTypeSettings: [SurveyFilterTypeSettingInput!]) {
  updateSurvey(uuid: $uuid, survey: {name: $name, description: $description, type: $type, startDate: $startDate, endDate: $endDate, languages: $languages, associatedSurveyUuid: $associatedSurveyUuid, distributionType: $distributionType, participantDataSource: $participantDataSource, hasConfidentialResults: $hasConfidentialResults, filterTypeSettings: $filterTypeSettings}) {
    errors
    survey {
      ...SurveysSurvey
      languages {
        ...OrganizationLanguage
      }
      translationsRequired
    }
  }
}
    ${SurveysSurveyFragmentDoc}
${OrganizationLanguageFragmentDoc}`;
export type SurveysUpdateSurveyMutationFn = Apollo.MutationFunction<SurveysUpdateSurveyMutation, SurveysUpdateSurveyMutationVariables>;

/**
 * __useSurveysUpdateSurveyMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateSurveyMutation, { data, loading, error }] = useSurveysUpdateSurveyMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      type: // value for 'type'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      languages: // value for 'languages'
 *      associatedSurveyUuid: // value for 'associatedSurveyUuid'
 *      distributionType: // value for 'distributionType'
 *      participantDataSource: // value for 'participantDataSource'
 *      hasConfidentialResults: // value for 'hasConfidentialResults'
 *      filterTypeSettings: // value for 'filterTypeSettings'
 *   },
 * });
 */
export function useSurveysUpdateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateSurveyMutation, SurveysUpdateSurveyMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateSurveyMutation, SurveysUpdateSurveyMutationVariables>(SurveysUpdateSurveyDocument, baseOptions);
      }
export type SurveysUpdateSurveyMutationHookResult = ReturnType<typeof useSurveysUpdateSurveyMutation>;
export type SurveysUpdateSurveyMutationResult = Apollo.MutationResult<SurveysUpdateSurveyMutation>;
export type SurveysUpdateSurveyMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateSurveyMutation, SurveysUpdateSurveyMutationVariables>;
export const SurveysUpdateSurveyStatusDocument = gql`
    mutation SurveysUpdateSurveyStatus($surveyUuid: String!, $status: SurveyStatusEnum!) {
  updateSurveyStatus(surveyUuid: $surveyUuid, status: $status) {
    errors
    survey {
      uuid
      status
    }
  }
}
    `;
export type SurveysUpdateSurveyStatusMutationFn = Apollo.MutationFunction<SurveysUpdateSurveyStatusMutation, SurveysUpdateSurveyStatusMutationVariables>;

/**
 * __useSurveysUpdateSurveyStatusMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateSurveyStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateSurveyStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateSurveyStatusMutation, { data, loading, error }] = useSurveysUpdateSurveyStatusMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSurveysUpdateSurveyStatusMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateSurveyStatusMutation, SurveysUpdateSurveyStatusMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateSurveyStatusMutation, SurveysUpdateSurveyStatusMutationVariables>(SurveysUpdateSurveyStatusDocument, baseOptions);
      }
export type SurveysUpdateSurveyStatusMutationHookResult = ReturnType<typeof useSurveysUpdateSurveyStatusMutation>;
export type SurveysUpdateSurveyStatusMutationResult = Apollo.MutationResult<SurveysUpdateSurveyStatusMutation>;
export type SurveysUpdateSurveyStatusMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateSurveyStatusMutation, SurveysUpdateSurveyStatusMutationVariables>;
export const SurveysUpdateTranslationsDocument = gql`
    mutation SurveysUpdateTranslations($surveyUuid: String!, $translations: [TranslationInput]) {
  updateTranslations(surveyUuid: $surveyUuid, translations: $translations) {
    errors
  }
}
    `;
export type SurveysUpdateTranslationsMutationFn = Apollo.MutationFunction<SurveysUpdateTranslationsMutation, SurveysUpdateTranslationsMutationVariables>;

/**
 * __useSurveysUpdateTranslationsMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateTranslationsMutation, { data, loading, error }] = useSurveysUpdateTranslationsMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      translations: // value for 'translations'
 *   },
 * });
 */
export function useSurveysUpdateTranslationsMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateTranslationsMutation, SurveysUpdateTranslationsMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateTranslationsMutation, SurveysUpdateTranslationsMutationVariables>(SurveysUpdateTranslationsDocument, baseOptions);
      }
export type SurveysUpdateTranslationsMutationHookResult = ReturnType<typeof useSurveysUpdateTranslationsMutation>;
export type SurveysUpdateTranslationsMutationResult = Apollo.MutationResult<SurveysUpdateTranslationsMutation>;
export type SurveysUpdateTranslationsMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateTranslationsMutation, SurveysUpdateTranslationsMutationVariables>;
export const SurveysUpdateWelcomeMessagesDocument = gql`
    mutation SurveysUpdateWelcomeMessages($surveyUuid: String!, $welcomeMessages: [SurveyTextInput!]!) {
  updateWelcomeMessages(surveyUuid: $surveyUuid, welcomeMessages: $welcomeMessages) {
    errors
    survey {
      welcomeMessages {
        uuid
        key
        text
      }
    }
  }
}
    `;
export type SurveysUpdateWelcomeMessagesMutationFn = Apollo.MutationFunction<SurveysUpdateWelcomeMessagesMutation, SurveysUpdateWelcomeMessagesMutationVariables>;

/**
 * __useSurveysUpdateWelcomeMessagesMutation__
 *
 * To run a mutation, you first call `useSurveysUpdateWelcomeMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveysUpdateWelcomeMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveysUpdateWelcomeMessagesMutation, { data, loading, error }] = useSurveysUpdateWelcomeMessagesMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      welcomeMessages: // value for 'welcomeMessages'
 *   },
 * });
 */
export function useSurveysUpdateWelcomeMessagesMutation(baseOptions?: Apollo.MutationHookOptions<SurveysUpdateWelcomeMessagesMutation, SurveysUpdateWelcomeMessagesMutationVariables>) {
        return Apollo.useMutation<SurveysUpdateWelcomeMessagesMutation, SurveysUpdateWelcomeMessagesMutationVariables>(SurveysUpdateWelcomeMessagesDocument, baseOptions);
      }
export type SurveysUpdateWelcomeMessagesMutationHookResult = ReturnType<typeof useSurveysUpdateWelcomeMessagesMutation>;
export type SurveysUpdateWelcomeMessagesMutationResult = Apollo.MutationResult<SurveysUpdateWelcomeMessagesMutation>;
export type SurveysUpdateWelcomeMessagesMutationOptions = Apollo.BaseMutationOptions<SurveysUpdateWelcomeMessagesMutation, SurveysUpdateWelcomeMessagesMutationVariables>;
export const UserToggleFavoriteSurveyDocument = gql`
    mutation UserToggleFavoriteSurvey($surveyUuid: String!) {
  toggleFavoriteSurvey(surveyUuid: $surveyUuid) {
    errors
    user {
      id
      favoriteSurveyIds
    }
  }
}
    `;
export type UserToggleFavoriteSurveyMutationFn = Apollo.MutationFunction<UserToggleFavoriteSurveyMutation, UserToggleFavoriteSurveyMutationVariables>;

/**
 * __useUserToggleFavoriteSurveyMutation__
 *
 * To run a mutation, you first call `useUserToggleFavoriteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToggleFavoriteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToggleFavoriteSurveyMutation, { data, loading, error }] = useUserToggleFavoriteSurveyMutation({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useUserToggleFavoriteSurveyMutation(baseOptions?: Apollo.MutationHookOptions<UserToggleFavoriteSurveyMutation, UserToggleFavoriteSurveyMutationVariables>) {
        return Apollo.useMutation<UserToggleFavoriteSurveyMutation, UserToggleFavoriteSurveyMutationVariables>(UserToggleFavoriteSurveyDocument, baseOptions);
      }
export type UserToggleFavoriteSurveyMutationHookResult = ReturnType<typeof useUserToggleFavoriteSurveyMutation>;
export type UserToggleFavoriteSurveyMutationResult = Apollo.MutationResult<UserToggleFavoriteSurveyMutation>;
export type UserToggleFavoriteSurveyMutationOptions = Apollo.BaseMutationOptions<UserToggleFavoriteSurveyMutation, UserToggleFavoriteSurveyMutationVariables>;
export const UserToggleFollowActionPlanDocument = gql`
    mutation UserToggleFollowActionPlan($planUuid: String!) {
  toggleFollowActionPlan(planUuid: $planUuid) {
    errors
    user {
      id
      followedActionPlanIds
    }
  }
}
    `;
export type UserToggleFollowActionPlanMutationFn = Apollo.MutationFunction<UserToggleFollowActionPlanMutation, UserToggleFollowActionPlanMutationVariables>;

/**
 * __useUserToggleFollowActionPlanMutation__
 *
 * To run a mutation, you first call `useUserToggleFollowActionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToggleFollowActionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToggleFollowActionPlanMutation, { data, loading, error }] = useUserToggleFollowActionPlanMutation({
 *   variables: {
 *      planUuid: // value for 'planUuid'
 *   },
 * });
 */
export function useUserToggleFollowActionPlanMutation(baseOptions?: Apollo.MutationHookOptions<UserToggleFollowActionPlanMutation, UserToggleFollowActionPlanMutationVariables>) {
        return Apollo.useMutation<UserToggleFollowActionPlanMutation, UserToggleFollowActionPlanMutationVariables>(UserToggleFollowActionPlanDocument, baseOptions);
      }
export type UserToggleFollowActionPlanMutationHookResult = ReturnType<typeof useUserToggleFollowActionPlanMutation>;
export type UserToggleFollowActionPlanMutationResult = Apollo.MutationResult<UserToggleFollowActionPlanMutation>;
export type UserToggleFollowActionPlanMutationOptions = Apollo.BaseMutationOptions<UserToggleFollowActionPlanMutation, UserToggleFollowActionPlanMutationVariables>;
export const UserUpdateAverageScoreNotificationsDocument = gql`
    mutation UserUpdateAverageScoreNotifications($userNotificationsInput: [UserAverageScoreNotificationsInput!]!) {
  updateUserAverageScoreNotifications(userNotificationsInput: $userNotificationsInput) {
    errors
    user {
      uuid
      userResultNotifications {
        averageScoreNotifications {
          enabled
          scoreType
          frequency
        }
      }
    }
  }
}
    `;
export type UserUpdateAverageScoreNotificationsMutationFn = Apollo.MutationFunction<UserUpdateAverageScoreNotificationsMutation, UserUpdateAverageScoreNotificationsMutationVariables>;

/**
 * __useUserUpdateAverageScoreNotificationsMutation__
 *
 * To run a mutation, you first call `useUserUpdateAverageScoreNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateAverageScoreNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateAverageScoreNotificationsMutation, { data, loading, error }] = useUserUpdateAverageScoreNotificationsMutation({
 *   variables: {
 *      userNotificationsInput: // value for 'userNotificationsInput'
 *   },
 * });
 */
export function useUserUpdateAverageScoreNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateAverageScoreNotificationsMutation, UserUpdateAverageScoreNotificationsMutationVariables>) {
        return Apollo.useMutation<UserUpdateAverageScoreNotificationsMutation, UserUpdateAverageScoreNotificationsMutationVariables>(UserUpdateAverageScoreNotificationsDocument, baseOptions);
      }
export type UserUpdateAverageScoreNotificationsMutationHookResult = ReturnType<typeof useUserUpdateAverageScoreNotificationsMutation>;
export type UserUpdateAverageScoreNotificationsMutationResult = Apollo.MutationResult<UserUpdateAverageScoreNotificationsMutation>;
export type UserUpdateAverageScoreNotificationsMutationOptions = Apollo.BaseMutationOptions<UserUpdateAverageScoreNotificationsMutation, UserUpdateAverageScoreNotificationsMutationVariables>;
export const UserUpdateQuestionScoreNotificationsDocument = gql`
    mutation UserUpdateQuestionScoreNotifications($userNotificationsInput: [UserQuestionScoreNotificationsInput!]!) {
  updateUserQuestionScoreNotifications(userNotificationsInput: $userNotificationsInput) {
    errors
    user {
      uuid
      userResultNotifications {
        questionScoreNotifications {
          uuid
          enabled
          frequency
        }
      }
    }
  }
}
    `;
export type UserUpdateQuestionScoreNotificationsMutationFn = Apollo.MutationFunction<UserUpdateQuestionScoreNotificationsMutation, UserUpdateQuestionScoreNotificationsMutationVariables>;

/**
 * __useUserUpdateQuestionScoreNotificationsMutation__
 *
 * To run a mutation, you first call `useUserUpdateQuestionScoreNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateQuestionScoreNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateQuestionScoreNotificationsMutation, { data, loading, error }] = useUserUpdateQuestionScoreNotificationsMutation({
 *   variables: {
 *      userNotificationsInput: // value for 'userNotificationsInput'
 *   },
 * });
 */
export function useUserUpdateQuestionScoreNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateQuestionScoreNotificationsMutation, UserUpdateQuestionScoreNotificationsMutationVariables>) {
        return Apollo.useMutation<UserUpdateQuestionScoreNotificationsMutation, UserUpdateQuestionScoreNotificationsMutationVariables>(UserUpdateQuestionScoreNotificationsDocument, baseOptions);
      }
export type UserUpdateQuestionScoreNotificationsMutationHookResult = ReturnType<typeof useUserUpdateQuestionScoreNotificationsMutation>;
export type UserUpdateQuestionScoreNotificationsMutationResult = Apollo.MutationResult<UserUpdateQuestionScoreNotificationsMutation>;
export type UserUpdateQuestionScoreNotificationsMutationOptions = Apollo.BaseMutationOptions<UserUpdateQuestionScoreNotificationsMutation, UserUpdateQuestionScoreNotificationsMutationVariables>;
export const UserUpdateUserAppMessageDocument = gql`
    mutation UserUpdateUserAppMessage($kind: String!, $contentObjects: [ContentObjectInput!]!) {
  updateUserAppMessage(kind: $kind, contentObjects: $contentObjects) {
    errors
    user {
      id
      appMessages {
        kind
        seen
        contentObjects {
          uuid
          contentType
        }
      }
    }
  }
}
    `;
export type UserUpdateUserAppMessageMutationFn = Apollo.MutationFunction<UserUpdateUserAppMessageMutation, UserUpdateUserAppMessageMutationVariables>;

/**
 * __useUserUpdateUserAppMessageMutation__
 *
 * To run a mutation, you first call `useUserUpdateUserAppMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateUserAppMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateUserAppMessageMutation, { data, loading, error }] = useUserUpdateUserAppMessageMutation({
 *   variables: {
 *      kind: // value for 'kind'
 *      contentObjects: // value for 'contentObjects'
 *   },
 * });
 */
export function useUserUpdateUserAppMessageMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateUserAppMessageMutation, UserUpdateUserAppMessageMutationVariables>) {
        return Apollo.useMutation<UserUpdateUserAppMessageMutation, UserUpdateUserAppMessageMutationVariables>(UserUpdateUserAppMessageDocument, baseOptions);
      }
export type UserUpdateUserAppMessageMutationHookResult = ReturnType<typeof useUserUpdateUserAppMessageMutation>;
export type UserUpdateUserAppMessageMutationResult = Apollo.MutationResult<UserUpdateUserAppMessageMutation>;
export type UserUpdateUserAppMessageMutationOptions = Apollo.BaseMutationOptions<UserUpdateUserAppMessageMutation, UserUpdateUserAppMessageMutationVariables>;
export const UserUpdateUserHasSurveyResponseNotificationsDocument = gql`
    mutation UserUpdateUserHasSurveyResponseNotifications($hasSurveyResponseNotifications: Boolean!) {
  updateUserHasSurveyResponseNotifications(hasSurveyResponseNotifications: $hasSurveyResponseNotifications) {
    errors
  }
}
    `;
export type UserUpdateUserHasSurveyResponseNotificationsMutationFn = Apollo.MutationFunction<UserUpdateUserHasSurveyResponseNotificationsMutation, UserUpdateUserHasSurveyResponseNotificationsMutationVariables>;

/**
 * __useUserUpdateUserHasSurveyResponseNotificationsMutation__
 *
 * To run a mutation, you first call `useUserUpdateUserHasSurveyResponseNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateUserHasSurveyResponseNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateUserHasSurveyResponseNotificationsMutation, { data, loading, error }] = useUserUpdateUserHasSurveyResponseNotificationsMutation({
 *   variables: {
 *      hasSurveyResponseNotifications: // value for 'hasSurveyResponseNotifications'
 *   },
 * });
 */
export function useUserUpdateUserHasSurveyResponseNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateUserHasSurveyResponseNotificationsMutation, UserUpdateUserHasSurveyResponseNotificationsMutationVariables>) {
        return Apollo.useMutation<UserUpdateUserHasSurveyResponseNotificationsMutation, UserUpdateUserHasSurveyResponseNotificationsMutationVariables>(UserUpdateUserHasSurveyResponseNotificationsDocument, baseOptions);
      }
export type UserUpdateUserHasSurveyResponseNotificationsMutationHookResult = ReturnType<typeof useUserUpdateUserHasSurveyResponseNotificationsMutation>;
export type UserUpdateUserHasSurveyResponseNotificationsMutationResult = Apollo.MutationResult<UserUpdateUserHasSurveyResponseNotificationsMutation>;
export type UserUpdateUserHasSurveyResponseNotificationsMutationOptions = Apollo.BaseMutationOptions<UserUpdateUserHasSurveyResponseNotificationsMutation, UserUpdateUserHasSurveyResponseNotificationsMutationVariables>;
export const UserUpdateUserSurveyResponseNotificationsDocument = gql`
    mutation UserUpdateUserSurveyResponseNotifications($userNotificationsInput: [UserSurveyResponseNotificationInput!]!) {
  updateUserSurveyResponseNotifications(userNotificationsInput: $userNotificationsInput) {
    errors
    user {
      uuid
      userResultNotifications {
        surveyResponseNotificationTypes
      }
    }
  }
}
    `;
export type UserUpdateUserSurveyResponseNotificationsMutationFn = Apollo.MutationFunction<UserUpdateUserSurveyResponseNotificationsMutation, UserUpdateUserSurveyResponseNotificationsMutationVariables>;

/**
 * __useUserUpdateUserSurveyResponseNotificationsMutation__
 *
 * To run a mutation, you first call `useUserUpdateUserSurveyResponseNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateUserSurveyResponseNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateUserSurveyResponseNotificationsMutation, { data, loading, error }] = useUserUpdateUserSurveyResponseNotificationsMutation({
 *   variables: {
 *      userNotificationsInput: // value for 'userNotificationsInput'
 *   },
 * });
 */
export function useUserUpdateUserSurveyResponseNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateUserSurveyResponseNotificationsMutation, UserUpdateUserSurveyResponseNotificationsMutationVariables>) {
        return Apollo.useMutation<UserUpdateUserSurveyResponseNotificationsMutation, UserUpdateUserSurveyResponseNotificationsMutationVariables>(UserUpdateUserSurveyResponseNotificationsDocument, baseOptions);
      }
export type UserUpdateUserSurveyResponseNotificationsMutationHookResult = ReturnType<typeof useUserUpdateUserSurveyResponseNotificationsMutation>;
export type UserUpdateUserSurveyResponseNotificationsMutationResult = Apollo.MutationResult<UserUpdateUserSurveyResponseNotificationsMutation>;
export type UserUpdateUserSurveyResponseNotificationsMutationOptions = Apollo.BaseMutationOptions<UserUpdateUserSurveyResponseNotificationsMutation, UserUpdateUserSurveyResponseNotificationsMutationVariables>;
export const ActionPlansActionPlanDocument = gql`
    query ActionPlansActionPlan($targetUserUuid: String) {
  actionPlan(userUuid: $targetUserUuid) {
    uuid
    ...ActionPlansActionPlan
  }
}
    ${ActionPlansActionPlanFragmentDoc}`;

/**
 * __useActionPlansActionPlanQuery__
 *
 * To run a query within a React component, call `useActionPlansActionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionPlansActionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionPlansActionPlanQuery({
 *   variables: {
 *      targetUserUuid: // value for 'targetUserUuid'
 *   },
 * });
 */
export function useActionPlansActionPlanQuery(baseOptions?: Apollo.QueryHookOptions<ActionPlansActionPlanQuery, ActionPlansActionPlanQueryVariables>) {
        return Apollo.useQuery<ActionPlansActionPlanQuery, ActionPlansActionPlanQueryVariables>(ActionPlansActionPlanDocument, baseOptions);
      }
export function useActionPlansActionPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionPlansActionPlanQuery, ActionPlansActionPlanQueryVariables>) {
          return Apollo.useLazyQuery<ActionPlansActionPlanQuery, ActionPlansActionPlanQueryVariables>(ActionPlansActionPlanDocument, baseOptions);
        }
export type ActionPlansActionPlanQueryHookResult = ReturnType<typeof useActionPlansActionPlanQuery>;
export type ActionPlansActionPlanLazyQueryHookResult = ReturnType<typeof useActionPlansActionPlanLazyQuery>;
export type ActionPlansActionPlanQueryResult = Apollo.QueryResult<ActionPlansActionPlanQuery, ActionPlansActionPlanQueryVariables>;
export const ActionPlansMgmtActionPlansDocument = gql`
    query ActionPlansMgmtActionPlans($filterValueUuids: [String!], $pageSize: Int, $cursor: String, $sortBy: ActionPlansSortByEnum!, $searchQuery: String, $sortAscending: Boolean!, $selectedFollowFilters: [String!]!) {
  currentUser {
    id
    followedActionPlanIds
  }
  actionPlanNodes: actionPlans(filterValueUuids: $filterValueUuids, first: $pageSize, after: $cursor, sortBy: $sortBy, searchQuery: $searchQuery, sortAscending: $sortAscending, selectedFollowFilters: $selectedFollowFilters) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        uuid
        owner {
          id
          name
          isAdmin
          filterPermissions {
            ...FilterType
          }
        }
        lastUpdated
        progress {
          employee {
            ...ActionPlansMgmtCounts
          }
          resident {
            ...ActionPlansMgmtCounts
          }
        }
        lastEmployeeSurveyData: lastSurveyData(surveyType: TI) {
          survey {
            name
            endDate
          }
          score {
            positive
          }
        }
        lastResidentSurveyData: lastSurveyData(surveyType: RESIDENT_ENGAGEMENT) {
          survey {
            name
            endDate
          }
          score {
            positive
          }
        }
      }
    }
  }
}
    ${FilterTypeFragmentDoc}
${ActionPlansMgmtCountsFragmentDoc}`;

/**
 * __useActionPlansMgmtActionPlansQuery__
 *
 * To run a query within a React component, call `useActionPlansMgmtActionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionPlansMgmtActionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionPlansMgmtActionPlansQuery({
 *   variables: {
 *      filterValueUuids: // value for 'filterValueUuids'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *      sortBy: // value for 'sortBy'
 *      searchQuery: // value for 'searchQuery'
 *      sortAscending: // value for 'sortAscending'
 *      selectedFollowFilters: // value for 'selectedFollowFilters'
 *   },
 * });
 */
export function useActionPlansMgmtActionPlansQuery(baseOptions: Apollo.QueryHookOptions<ActionPlansMgmtActionPlansQuery, ActionPlansMgmtActionPlansQueryVariables>) {
        return Apollo.useQuery<ActionPlansMgmtActionPlansQuery, ActionPlansMgmtActionPlansQueryVariables>(ActionPlansMgmtActionPlansDocument, baseOptions);
      }
export function useActionPlansMgmtActionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionPlansMgmtActionPlansQuery, ActionPlansMgmtActionPlansQueryVariables>) {
          return Apollo.useLazyQuery<ActionPlansMgmtActionPlansQuery, ActionPlansMgmtActionPlansQueryVariables>(ActionPlansMgmtActionPlansDocument, baseOptions);
        }
export type ActionPlansMgmtActionPlansQueryHookResult = ReturnType<typeof useActionPlansMgmtActionPlansQuery>;
export type ActionPlansMgmtActionPlansLazyQueryHookResult = ReturnType<typeof useActionPlansMgmtActionPlansLazyQuery>;
export type ActionPlansMgmtActionPlansQueryResult = Apollo.QueryResult<ActionPlansMgmtActionPlansQuery, ActionPlansMgmtActionPlansQueryVariables>;
export const ActionPlansMgmtActivityDocument = gql`
    query ActionPlansMgmtActivity($filterValueUuids: [String!], $pastDays: [Int!]!, $selectedFollowFilters: [String!]!, $searchQuery: String!) {
  actionPlansActivity(filterValueUuids: $filterValueUuids, selectedFollowFilters: $selectedFollowFilters, searchQuery: $searchQuery, pastDays: $pastDays) {
    pastDays
    activityCount
  }
}
    `;

/**
 * __useActionPlansMgmtActivityQuery__
 *
 * To run a query within a React component, call `useActionPlansMgmtActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionPlansMgmtActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionPlansMgmtActivityQuery({
 *   variables: {
 *      filterValueUuids: // value for 'filterValueUuids'
 *      pastDays: // value for 'pastDays'
 *      selectedFollowFilters: // value for 'selectedFollowFilters'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useActionPlansMgmtActivityQuery(baseOptions: Apollo.QueryHookOptions<ActionPlansMgmtActivityQuery, ActionPlansMgmtActivityQueryVariables>) {
        return Apollo.useQuery<ActionPlansMgmtActivityQuery, ActionPlansMgmtActivityQueryVariables>(ActionPlansMgmtActivityDocument, baseOptions);
      }
export function useActionPlansMgmtActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionPlansMgmtActivityQuery, ActionPlansMgmtActivityQueryVariables>) {
          return Apollo.useLazyQuery<ActionPlansMgmtActivityQuery, ActionPlansMgmtActivityQueryVariables>(ActionPlansMgmtActivityDocument, baseOptions);
        }
export type ActionPlansMgmtActivityQueryHookResult = ReturnType<typeof useActionPlansMgmtActivityQuery>;
export type ActionPlansMgmtActivityLazyQueryHookResult = ReturnType<typeof useActionPlansMgmtActivityLazyQuery>;
export type ActionPlansMgmtActivityQueryResult = Apollo.QueryResult<ActionPlansMgmtActivityQuery, ActionPlansMgmtActivityQueryVariables>;
export const ActionPlansMgmtCountsDocument = gql`
    query ActionPlansMgmtCounts($filterValueUuids: [String!], $selectedFollowFilters: [String!]!, $searchQuery: String) {
  actionPlansCounts(filterValueUuids: $filterValueUuids, selectedFollowFilters: $selectedFollowFilters, searchQuery: $searchQuery) {
    employee {
      ...ActionPlansMgmtCounts
    }
    resident {
      ...ActionPlansMgmtCounts
    }
    custom {
      ...ActionPlansMgmtCounts
    }
  }
}
    ${ActionPlansMgmtCountsFragmentDoc}`;

/**
 * __useActionPlansMgmtCountsQuery__
 *
 * To run a query within a React component, call `useActionPlansMgmtCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionPlansMgmtCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionPlansMgmtCountsQuery({
 *   variables: {
 *      filterValueUuids: // value for 'filterValueUuids'
 *      selectedFollowFilters: // value for 'selectedFollowFilters'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useActionPlansMgmtCountsQuery(baseOptions: Apollo.QueryHookOptions<ActionPlansMgmtCountsQuery, ActionPlansMgmtCountsQueryVariables>) {
        return Apollo.useQuery<ActionPlansMgmtCountsQuery, ActionPlansMgmtCountsQueryVariables>(ActionPlansMgmtCountsDocument, baseOptions);
      }
export function useActionPlansMgmtCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionPlansMgmtCountsQuery, ActionPlansMgmtCountsQueryVariables>) {
          return Apollo.useLazyQuery<ActionPlansMgmtCountsQuery, ActionPlansMgmtCountsQueryVariables>(ActionPlansMgmtCountsDocument, baseOptions);
        }
export type ActionPlansMgmtCountsQueryHookResult = ReturnType<typeof useActionPlansMgmtCountsQuery>;
export type ActionPlansMgmtCountsLazyQueryHookResult = ReturnType<typeof useActionPlansMgmtCountsLazyQuery>;
export type ActionPlansMgmtCountsQueryResult = Apollo.QueryResult<ActionPlansMgmtCountsQuery, ActionPlansMgmtCountsQueryVariables>;
export const ActionPlansStatementsDocument = gql`
    query ActionPlansStatements($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $includeCustom: Boolean) {
  statements: insightsStatements(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark, includeCustom: $includeCustom) {
    uuid
    ...InsightsStatementScore
  }
}
    ${InsightsStatementScoreFragmentDoc}`;

/**
 * __useActionPlansStatementsQuery__
 *
 * To run a query within a React component, call `useActionPlansStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionPlansStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionPlansStatementsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      includeCustom: // value for 'includeCustom'
 *   },
 * });
 */
export function useActionPlansStatementsQuery(baseOptions: Apollo.QueryHookOptions<ActionPlansStatementsQuery, ActionPlansStatementsQueryVariables>) {
        return Apollo.useQuery<ActionPlansStatementsQuery, ActionPlansStatementsQueryVariables>(ActionPlansStatementsDocument, baseOptions);
      }
export function useActionPlansStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionPlansStatementsQuery, ActionPlansStatementsQueryVariables>) {
          return Apollo.useLazyQuery<ActionPlansStatementsQuery, ActionPlansStatementsQueryVariables>(ActionPlansStatementsDocument, baseOptions);
        }
export type ActionPlansStatementsQueryHookResult = ReturnType<typeof useActionPlansStatementsQuery>;
export type ActionPlansStatementsLazyQueryHookResult = ReturnType<typeof useActionPlansStatementsLazyQuery>;
export type ActionPlansStatementsQueryResult = Apollo.QueryResult<ActionPlansStatementsQuery, ActionPlansStatementsQueryVariables>;
export const ActionPlansSurveysDocument = gql`
    query ActionPlansSurveys {
  surveys {
    edges {
      node {
        ...SurveyCore
        description
        filterTypeUuids
        responseRate {
          ...ResponseRate
        }
      }
    }
  }
}
    ${SurveyCoreFragmentDoc}
${ResponseRateFragmentDoc}`;

/**
 * __useActionPlansSurveysQuery__
 *
 * To run a query within a React component, call `useActionPlansSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionPlansSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionPlansSurveysQuery({
 *   variables: {
 *   },
 * });
 */
export function useActionPlansSurveysQuery(baseOptions?: Apollo.QueryHookOptions<ActionPlansSurveysQuery, ActionPlansSurveysQueryVariables>) {
        return Apollo.useQuery<ActionPlansSurveysQuery, ActionPlansSurveysQueryVariables>(ActionPlansSurveysDocument, baseOptions);
      }
export function useActionPlansSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionPlansSurveysQuery, ActionPlansSurveysQueryVariables>) {
          return Apollo.useLazyQuery<ActionPlansSurveysQuery, ActionPlansSurveysQueryVariables>(ActionPlansSurveysDocument, baseOptions);
        }
export type ActionPlansSurveysQueryHookResult = ReturnType<typeof useActionPlansSurveysQuery>;
export type ActionPlansSurveysLazyQueryHookResult = ReturnType<typeof useActionPlansSurveysLazyQuery>;
export type ActionPlansSurveysQueryResult = Apollo.QueryResult<ActionPlansSurveysQuery, ActionPlansSurveysQueryVariables>;
export const AnalyticsOverviewDocument = gql`
    query AnalyticsOverview($employeeSurveyUuid: String!, $residentSurveyUuid: String!, $filters: [String!], $employeeBenchmark: BenchmarkNodeInput, $residentBenchmark: BenchmarkNodeInput, $residentResponseRateByClient: Boolean!) {
  employeeIndexScore: insightsOverallIndex(surveyUuid: $employeeSurveyUuid, filters: $filters, benchmark: $employeeBenchmark) {
    positive
    benchmarkPositive
  }
  residentIndexScore: insightsOverallIndex(surveyUuid: $residentSurveyUuid, filters: $filters, benchmark: $residentBenchmark) {
    positive
    benchmarkPositive
  }
  employeeSurvey: survey(surveyUuid: $employeeSurveyUuid) {
    uuid
    insightsSurvey(filters: $filters) {
      responseRate(byClient: false) {
        ...ResponseRate
      }
    }
  }
  residentSurvey: survey(surveyUuid: $residentSurveyUuid) {
    uuid
    insightsSurvey(filters: $filters) {
      responseRate(byClient: $residentResponseRateByClient) {
        ...ResponseRate
      }
      responseRateByParticipant: responseRate(byClient: false) {
        ...ResponseRate
      }
    }
  }
}
    ${ResponseRateFragmentDoc}`;

/**
 * __useAnalyticsOverviewQuery__
 *
 * To run a query within a React component, call `useAnalyticsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsOverviewQuery({
 *   variables: {
 *      employeeSurveyUuid: // value for 'employeeSurveyUuid'
 *      residentSurveyUuid: // value for 'residentSurveyUuid'
 *      filters: // value for 'filters'
 *      employeeBenchmark: // value for 'employeeBenchmark'
 *      residentBenchmark: // value for 'residentBenchmark'
 *      residentResponseRateByClient: // value for 'residentResponseRateByClient'
 *   },
 * });
 */
export function useAnalyticsOverviewQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsOverviewQuery, AnalyticsOverviewQueryVariables>) {
        return Apollo.useQuery<AnalyticsOverviewQuery, AnalyticsOverviewQueryVariables>(AnalyticsOverviewDocument, baseOptions);
      }
export function useAnalyticsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsOverviewQuery, AnalyticsOverviewQueryVariables>) {
          return Apollo.useLazyQuery<AnalyticsOverviewQuery, AnalyticsOverviewQueryVariables>(AnalyticsOverviewDocument, baseOptions);
        }
export type AnalyticsOverviewQueryHookResult = ReturnType<typeof useAnalyticsOverviewQuery>;
export type AnalyticsOverviewLazyQueryHookResult = ReturnType<typeof useAnalyticsOverviewLazyQuery>;
export type AnalyticsOverviewQueryResult = Apollo.QueryResult<AnalyticsOverviewQuery, AnalyticsOverviewQueryVariables>;
export const CertificationCertifiedLocationsExportDownloadDocument = gql`
    query CertificationCertifiedLocationsExportDownload($certificationUuid: String!) {
  success: certifiedLocationsExport(certificationUuid: $certificationUuid)
}
    `;

/**
 * __useCertificationCertifiedLocationsExportDownloadQuery__
 *
 * To run a query within a React component, call `useCertificationCertifiedLocationsExportDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificationCertifiedLocationsExportDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificationCertifiedLocationsExportDownloadQuery({
 *   variables: {
 *      certificationUuid: // value for 'certificationUuid'
 *   },
 * });
 */
export function useCertificationCertifiedLocationsExportDownloadQuery(baseOptions: Apollo.QueryHookOptions<CertificationCertifiedLocationsExportDownloadQuery, CertificationCertifiedLocationsExportDownloadQueryVariables>) {
        return Apollo.useQuery<CertificationCertifiedLocationsExportDownloadQuery, CertificationCertifiedLocationsExportDownloadQueryVariables>(CertificationCertifiedLocationsExportDownloadDocument, baseOptions);
      }
export function useCertificationCertifiedLocationsExportDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertificationCertifiedLocationsExportDownloadQuery, CertificationCertifiedLocationsExportDownloadQueryVariables>) {
          return Apollo.useLazyQuery<CertificationCertifiedLocationsExportDownloadQuery, CertificationCertifiedLocationsExportDownloadQueryVariables>(CertificationCertifiedLocationsExportDownloadDocument, baseOptions);
        }
export type CertificationCertifiedLocationsExportDownloadQueryHookResult = ReturnType<typeof useCertificationCertifiedLocationsExportDownloadQuery>;
export type CertificationCertifiedLocationsExportDownloadLazyQueryHookResult = ReturnType<typeof useCertificationCertifiedLocationsExportDownloadLazyQuery>;
export type CertificationCertifiedLocationsExportDownloadQueryResult = Apollo.QueryResult<CertificationCertifiedLocationsExportDownloadQuery, CertificationCertifiedLocationsExportDownloadQueryVariables>;
export const CurrentCertificationDocument = gql`
    query CurrentCertification($skipCultureBrief: Boolean!) {
  certification: currentCertification {
    status
    uuid
    accessEndDate
    certifiedEndDate
    organizationName
    organizationSlug
    passesScoreCriteria
    passesResponseRateCriteria
    badgeImageUrl
    badgeZipUrl
    survey {
      status
      editable
      uuid
      name
      numberOfLocationFilterValuesMissingRequiredData
      insightsSurvey {
        overallIndexScore {
          positive
        }
        responseRate(byClient: false) {
          finished
          total
          rate
        }
      }
    }
    cultureBrief @skip(if: $skipCultureBrief) {
      uuid
      status
    }
    locationCertifications {
      name
      isCertified
      locationUuid
      uuid
      badgeImageUrl
    }
  }
  currentUser {
    uuid
    organization {
      uuid
      certifications {
        status
        uuid
        accessEndDate
        certifiedStartDate
        certifiedEndDate
        organizationName
        badgeImageUrl
        locationCertifications {
          name
          isCertified
          locationUuid
          uuid
        }
        survey {
          uuid
        }
        cultureBrief {
          uuid
          status
        }
      }
    }
  }
}
    `;

/**
 * __useCurrentCertificationQuery__
 *
 * To run a query within a React component, call `useCurrentCertificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCertificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCertificationQuery({
 *   variables: {
 *      skipCultureBrief: // value for 'skipCultureBrief'
 *   },
 * });
 */
export function useCurrentCertificationQuery(baseOptions: Apollo.QueryHookOptions<CurrentCertificationQuery, CurrentCertificationQueryVariables>) {
        return Apollo.useQuery<CurrentCertificationQuery, CurrentCertificationQueryVariables>(CurrentCertificationDocument, baseOptions);
      }
export function useCurrentCertificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentCertificationQuery, CurrentCertificationQueryVariables>) {
          return Apollo.useLazyQuery<CurrentCertificationQuery, CurrentCertificationQueryVariables>(CurrentCertificationDocument, baseOptions);
        }
export type CurrentCertificationQueryHookResult = ReturnType<typeof useCurrentCertificationQuery>;
export type CurrentCertificationLazyQueryHookResult = ReturnType<typeof useCurrentCertificationLazyQuery>;
export type CurrentCertificationQueryResult = Apollo.QueryResult<CurrentCertificationQuery, CurrentCertificationQueryVariables>;
export const CertificationCultureBriefDocument = gql`
    query CertificationCultureBrief($certificationUuid: String!) {
  certification(certificationUuid: $certificationUuid) {
    uuid
    status
    cultureBrief {
      ...CultureBrief
      ...CultureBriefImages
    }
  }
}
    ${CultureBriefFragmentDoc}
${CultureBriefImagesFragmentDoc}`;

/**
 * __useCertificationCultureBriefQuery__
 *
 * To run a query within a React component, call `useCertificationCultureBriefQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificationCultureBriefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificationCultureBriefQuery({
 *   variables: {
 *      certificationUuid: // value for 'certificationUuid'
 *   },
 * });
 */
export function useCertificationCultureBriefQuery(baseOptions: Apollo.QueryHookOptions<CertificationCultureBriefQuery, CertificationCultureBriefQueryVariables>) {
        return Apollo.useQuery<CertificationCultureBriefQuery, CertificationCultureBriefQueryVariables>(CertificationCultureBriefDocument, baseOptions);
      }
export function useCertificationCultureBriefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertificationCultureBriefQuery, CertificationCultureBriefQueryVariables>) {
          return Apollo.useLazyQuery<CertificationCultureBriefQuery, CertificationCultureBriefQueryVariables>(CertificationCultureBriefDocument, baseOptions);
        }
export type CertificationCultureBriefQueryHookResult = ReturnType<typeof useCertificationCultureBriefQuery>;
export type CertificationCultureBriefLazyQueryHookResult = ReturnType<typeof useCertificationCultureBriefLazyQuery>;
export type CertificationCultureBriefQueryResult = Apollo.QueryResult<CertificationCultureBriefQuery, CertificationCultureBriefQueryVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...UserCore
    status
    isStaff
    canInviteUsers
    canResetUsersPasswords
    canAccessDataStructure
    canAccessSurveySettings
    canAccessGeneralSettings
    canManageActionPlans
    canUseActionPlans
    canManageCertifications
    canViewCertificationResults
    insightsModules
    residentCanAccessSurveySettings
    canAccessLonelinessResults
    residentInsightsModules
    surveyTypesAllowedToAdd
    organization {
      ...Organization
    }
    filters {
      ...FilterType
    }
    favoriteSurveyIds
    appMessages {
      kind
      seen
      contentObjects {
        contentType
        uuid
      }
    }
  }
}
    ${UserCoreFragmentDoc}
${OrganizationFragmentDoc}
${FilterTypeFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const CurrentUserSurveyResponseNotificationsDocument = gql`
    query CurrentUserSurveyResponseNotifications {
  currentUser {
    uuid
    hasSurveyResponseNotifications
    userResultNotifications {
      survey {
        uuid
        name
        hasSurveyResponseNotifications
        hasAverageScoreNotifications
        hasQuestionScoreNotifications
      }
      surveyResponseNotificationTypes
      averageScoreNotifications {
        enabled
        frequency
        scoreType
      }
      questionScoreNotifications {
        uuid
        enabled
        frequency
        groupNotification {
          uuid
          notificationName
          enabled
          scoreType
          frequency
          minScore
          maxScore
          statement {
            text
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCurrentUserSurveyResponseNotificationsQuery__
 *
 * To run a query within a React component, call `useCurrentUserSurveyResponseNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSurveyResponseNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSurveyResponseNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserSurveyResponseNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserSurveyResponseNotificationsQuery, CurrentUserSurveyResponseNotificationsQueryVariables>) {
        return Apollo.useQuery<CurrentUserSurveyResponseNotificationsQuery, CurrentUserSurveyResponseNotificationsQueryVariables>(CurrentUserSurveyResponseNotificationsDocument, baseOptions);
      }
export function useCurrentUserSurveyResponseNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserSurveyResponseNotificationsQuery, CurrentUserSurveyResponseNotificationsQueryVariables>) {
          return Apollo.useLazyQuery<CurrentUserSurveyResponseNotificationsQuery, CurrentUserSurveyResponseNotificationsQueryVariables>(CurrentUserSurveyResponseNotificationsDocument, baseOptions);
        }
export type CurrentUserSurveyResponseNotificationsQueryHookResult = ReturnType<typeof useCurrentUserSurveyResponseNotificationsQuery>;
export type CurrentUserSurveyResponseNotificationsLazyQueryHookResult = ReturnType<typeof useCurrentUserSurveyResponseNotificationsLazyQuery>;
export type CurrentUserSurveyResponseNotificationsQueryResult = Apollo.QueryResult<CurrentUserSurveyResponseNotificationsQuery, CurrentUserSurveyResponseNotificationsQueryVariables>;
export const ActionPlansDownloadDocument = gql`
    query actionPlansDownload($targetUserUuid: String) {
  success: actionPlansDownload(targetUserUuid: $targetUserUuid)
}
    `;

/**
 * __useActionPlansDownloadQuery__
 *
 * To run a query within a React component, call `useActionPlansDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionPlansDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionPlansDownloadQuery({
 *   variables: {
 *      targetUserUuid: // value for 'targetUserUuid'
 *   },
 * });
 */
export function useActionPlansDownloadQuery(baseOptions?: Apollo.QueryHookOptions<ActionPlansDownloadQuery, ActionPlansDownloadQueryVariables>) {
        return Apollo.useQuery<ActionPlansDownloadQuery, ActionPlansDownloadQueryVariables>(ActionPlansDownloadDocument, baseOptions);
      }
export function useActionPlansDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionPlansDownloadQuery, ActionPlansDownloadQueryVariables>) {
          return Apollo.useLazyQuery<ActionPlansDownloadQuery, ActionPlansDownloadQueryVariables>(ActionPlansDownloadDocument, baseOptions);
        }
export type ActionPlansDownloadQueryHookResult = ReturnType<typeof useActionPlansDownloadQuery>;
export type ActionPlansDownloadLazyQueryHookResult = ReturnType<typeof useActionPlansDownloadLazyQuery>;
export type ActionPlansDownloadQueryResult = Apollo.QueryResult<ActionPlansDownloadQuery, ActionPlansDownloadQueryVariables>;
export const InsightsBenchmarksDocument = gql`
    query InsightsBenchmarks($surveyUuid: String!) {
  benchmarks: insightsBenchmarks(surveyUuid: $surveyUuid) {
    ...Benchmark
  }
}
    ${BenchmarkFragmentDoc}`;

/**
 * __useInsightsBenchmarksQuery__
 *
 * To run a query within a React component, call `useInsightsBenchmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsBenchmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsBenchmarksQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useInsightsBenchmarksQuery(baseOptions: Apollo.QueryHookOptions<InsightsBenchmarksQuery, InsightsBenchmarksQueryVariables>) {
        return Apollo.useQuery<InsightsBenchmarksQuery, InsightsBenchmarksQueryVariables>(InsightsBenchmarksDocument, baseOptions);
      }
export function useInsightsBenchmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsBenchmarksQuery, InsightsBenchmarksQueryVariables>) {
          return Apollo.useLazyQuery<InsightsBenchmarksQuery, InsightsBenchmarksQueryVariables>(InsightsBenchmarksDocument, baseOptions);
        }
export type InsightsBenchmarksQueryHookResult = ReturnType<typeof useInsightsBenchmarksQuery>;
export type InsightsBenchmarksLazyQueryHookResult = ReturnType<typeof useInsightsBenchmarksLazyQuery>;
export type InsightsBenchmarksQueryResult = Apollo.QueryResult<InsightsBenchmarksQuery, InsightsBenchmarksQueryVariables>;
export const InsightsCommentsDocument = gql`
    query InsightsComments($surveyUuid: String!, $questionCode: String!, $filters: [String!], $pageSize: Int!, $page: Int!, $searchWords: [String], $sortBy: String, $filterBy: NpsGroupsEnum, $startDate: DateTime, $endDate: DateTime) {
  insightsComments(surveyUuid: $surveyUuid, questionCode: $questionCode, filters: $filters, pageSize: $pageSize, page: $page, searchWords: $searchWords, sortBy: $sortBy, filterBy: $filterBy, startDate: $startDate, endDate: $endDate) {
    totalComments
    comments {
      text
      author
      submitted
      filterValues
    }
  }
}
    `;

/**
 * __useInsightsCommentsQuery__
 *
 * To run a query within a React component, call `useInsightsCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCommentsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      questionCode: // value for 'questionCode'
 *      filters: // value for 'filters'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      searchWords: // value for 'searchWords'
 *      sortBy: // value for 'sortBy'
 *      filterBy: // value for 'filterBy'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsCommentsQuery(baseOptions: Apollo.QueryHookOptions<InsightsCommentsQuery, InsightsCommentsQueryVariables>) {
        return Apollo.useQuery<InsightsCommentsQuery, InsightsCommentsQueryVariables>(InsightsCommentsDocument, baseOptions);
      }
export function useInsightsCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCommentsQuery, InsightsCommentsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsCommentsQuery, InsightsCommentsQueryVariables>(InsightsCommentsDocument, baseOptions);
        }
export type InsightsCommentsQueryHookResult = ReturnType<typeof useInsightsCommentsQuery>;
export type InsightsCommentsLazyQueryHookResult = ReturnType<typeof useInsightsCommentsLazyQuery>;
export type InsightsCommentsQueryResult = Apollo.QueryResult<InsightsCommentsQuery, InsightsCommentsQueryVariables>;
export const InsightsCommentsTopCommentsDocument = gql`
    query InsightsCommentsTopComments($surveyUuid: String!, $questionCode: String!, $filters: [String!], $searchWords: [String], $topCommentsCount: Int!, $sortBy: String, $filterBy: NpsGroupsEnum, $startDate: DateTime, $endDate: DateTime) {
  insightsComments(surveyUuid: $surveyUuid, questionCode: $questionCode, filters: $filters, searchWords: $searchWords, page: 0, pageSize: $topCommentsCount, sortBy: $sortBy, filterBy: $filterBy, startDate: $startDate, endDate: $endDate) {
    totalComments
    comments {
      text
    }
  }
}
    `;

/**
 * __useInsightsCommentsTopCommentsQuery__
 *
 * To run a query within a React component, call `useInsightsCommentsTopCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCommentsTopCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCommentsTopCommentsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      questionCode: // value for 'questionCode'
 *      filters: // value for 'filters'
 *      searchWords: // value for 'searchWords'
 *      topCommentsCount: // value for 'topCommentsCount'
 *      sortBy: // value for 'sortBy'
 *      filterBy: // value for 'filterBy'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsCommentsTopCommentsQuery(baseOptions: Apollo.QueryHookOptions<InsightsCommentsTopCommentsQuery, InsightsCommentsTopCommentsQueryVariables>) {
        return Apollo.useQuery<InsightsCommentsTopCommentsQuery, InsightsCommentsTopCommentsQueryVariables>(InsightsCommentsTopCommentsDocument, baseOptions);
      }
export function useInsightsCommentsTopCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCommentsTopCommentsQuery, InsightsCommentsTopCommentsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsCommentsTopCommentsQuery, InsightsCommentsTopCommentsQueryVariables>(InsightsCommentsTopCommentsDocument, baseOptions);
        }
export type InsightsCommentsTopCommentsQueryHookResult = ReturnType<typeof useInsightsCommentsTopCommentsQuery>;
export type InsightsCommentsTopCommentsLazyQueryHookResult = ReturnType<typeof useInsightsCommentsTopCommentsLazyQuery>;
export type InsightsCommentsTopCommentsQueryResult = Apollo.QueryResult<InsightsCommentsTopCommentsQuery, InsightsCommentsTopCommentsQueryVariables>;
export const InsightsComparisonControlsDocument = gql`
    query InsightsComparisonControls($surveyUuid: String!, $filters: [String!]) {
  controls: insightsComparisonControls(surveyUuid: $surveyUuid, filters: $filters) {
    statements {
      code
      focus
      residentResultsGroup
      text
    }
    demographics {
      code
      text
      pluralText
    }
  }
}
    `;

/**
 * __useInsightsComparisonControlsQuery__
 *
 * To run a query within a React component, call `useInsightsComparisonControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsComparisonControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsComparisonControlsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInsightsComparisonControlsQuery(baseOptions: Apollo.QueryHookOptions<InsightsComparisonControlsQuery, InsightsComparisonControlsQueryVariables>) {
        return Apollo.useQuery<InsightsComparisonControlsQuery, InsightsComparisonControlsQueryVariables>(InsightsComparisonControlsDocument, baseOptions);
      }
export function useInsightsComparisonControlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsComparisonControlsQuery, InsightsComparisonControlsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsComparisonControlsQuery, InsightsComparisonControlsQueryVariables>(InsightsComparisonControlsDocument, baseOptions);
        }
export type InsightsComparisonControlsQueryHookResult = ReturnType<typeof useInsightsComparisonControlsQuery>;
export type InsightsComparisonControlsLazyQueryHookResult = ReturnType<typeof useInsightsComparisonControlsLazyQuery>;
export type InsightsComparisonControlsQueryResult = Apollo.QueryResult<InsightsComparisonControlsQuery, InsightsComparisonControlsQueryVariables>;
export const InsightsCoreQBreakdownDocument = gql`
    query InsightsCoreQBreakdown($surveyUuid: String!, $dtCode: String!, $filters: [String!], $rankBy: RankByEnum, $startDate: DateTime, $endDate: DateTime, $limitToCoreQEligible: Boolean!, $statementCodes: [String!]!) {
  insightsScoresByDataType(surveyUuid: $surveyUuid, dtCode: $dtCode, filters: $filters, startDate: $startDate, endDate: $endDate, rankBy: $rankBy, limitToCoreQEligible: $limitToCoreQEligible) {
    label
    positive
  }
  insightsOverallIndex(surveyUuid: $surveyUuid, filters: $filters, limitToCoreQEligible: $limitToCoreQEligible, statementCodes: $statementCodes) {
    ...InsightsOverallScore
  }
}
    ${InsightsOverallScoreFragmentDoc}`;

/**
 * __useInsightsCoreQBreakdownQuery__
 *
 * To run a query within a React component, call `useInsightsCoreQBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCoreQBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCoreQBreakdownQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      dtCode: // value for 'dtCode'
 *      filters: // value for 'filters'
 *      rankBy: // value for 'rankBy'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      limitToCoreQEligible: // value for 'limitToCoreQEligible'
 *      statementCodes: // value for 'statementCodes'
 *   },
 * });
 */
export function useInsightsCoreQBreakdownQuery(baseOptions: Apollo.QueryHookOptions<InsightsCoreQBreakdownQuery, InsightsCoreQBreakdownQueryVariables>) {
        return Apollo.useQuery<InsightsCoreQBreakdownQuery, InsightsCoreQBreakdownQueryVariables>(InsightsCoreQBreakdownDocument, baseOptions);
      }
export function useInsightsCoreQBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCoreQBreakdownQuery, InsightsCoreQBreakdownQueryVariables>) {
          return Apollo.useLazyQuery<InsightsCoreQBreakdownQuery, InsightsCoreQBreakdownQueryVariables>(InsightsCoreQBreakdownDocument, baseOptions);
        }
export type InsightsCoreQBreakdownQueryHookResult = ReturnType<typeof useInsightsCoreQBreakdownQuery>;
export type InsightsCoreQBreakdownLazyQueryHookResult = ReturnType<typeof useInsightsCoreQBreakdownLazyQuery>;
export type InsightsCoreQBreakdownQueryResult = Apollo.QueryResult<InsightsCoreQBreakdownQuery, InsightsCoreQBreakdownQueryVariables>;
export const InsightsCoreQResultsDocument = gql`
    query InsightsCoreQResults($surveyUuid: String!, $limitToCoreQEligible: Boolean!, $filters: [String!], $startDate: DateTime, $endDate: DateTime, $statementCodes: [String!]!) {
  insightsCoreQResults(surveyUuid: $surveyUuid, limitToCoreQEligible: $limitToCoreQEligible, filters: $filters, startDate: $startDate, endDate: $endDate) {
    code
    label
    positive
    negative
    count
  }
  insightsOverallIndex(surveyUuid: $surveyUuid, filters: $filters, startDate: $startDate, endDate: $endDate, statementCodes: $statementCodes, limitToCoreQEligible: $limitToCoreQEligible) {
    positive
    negative
    inconsistent
    count
  }
}
    `;

/**
 * __useInsightsCoreQResultsQuery__
 *
 * To run a query within a React component, call `useInsightsCoreQResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCoreQResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCoreQResultsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      limitToCoreQEligible: // value for 'limitToCoreQEligible'
 *      filters: // value for 'filters'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      statementCodes: // value for 'statementCodes'
 *   },
 * });
 */
export function useInsightsCoreQResultsQuery(baseOptions: Apollo.QueryHookOptions<InsightsCoreQResultsQuery, InsightsCoreQResultsQueryVariables>) {
        return Apollo.useQuery<InsightsCoreQResultsQuery, InsightsCoreQResultsQueryVariables>(InsightsCoreQResultsDocument, baseOptions);
      }
export function useInsightsCoreQResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCoreQResultsQuery, InsightsCoreQResultsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsCoreQResultsQuery, InsightsCoreQResultsQueryVariables>(InsightsCoreQResultsDocument, baseOptions);
        }
export type InsightsCoreQResultsQueryHookResult = ReturnType<typeof useInsightsCoreQResultsQuery>;
export type InsightsCoreQResultsLazyQueryHookResult = ReturnType<typeof useInsightsCoreQResultsLazyQuery>;
export type InsightsCoreQResultsQueryResult = Apollo.QueryResult<InsightsCoreQResultsQuery, InsightsCoreQResultsQueryVariables>;
export const InsightsCultureDocument = gql`
    query InsightsCulture($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $demographics: [String]) {
  culture: insightsCulture(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark, demographics: $demographics) {
    highTrust {
      ...InsightsCulture
    }
    highTrustBenchmark
    checkedOut {
      ...InsightsCulture
    }
    checkedOutBenchmark
    highRisk {
      ...InsightsCulture
    }
    highRiskBenchmark
    wantsMore {
      ...InsightsCulture
    }
    wantsMoreBenchmark
  }
}
    ${InsightsCultureFragmentDoc}`;

/**
 * __useInsightsCultureQuery__
 *
 * To run a query within a React component, call `useInsightsCultureQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCultureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCultureQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      demographics: // value for 'demographics'
 *   },
 * });
 */
export function useInsightsCultureQuery(baseOptions: Apollo.QueryHookOptions<InsightsCultureQuery, InsightsCultureQueryVariables>) {
        return Apollo.useQuery<InsightsCultureQuery, InsightsCultureQueryVariables>(InsightsCultureDocument, baseOptions);
      }
export function useInsightsCultureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCultureQuery, InsightsCultureQueryVariables>) {
          return Apollo.useLazyQuery<InsightsCultureQuery, InsightsCultureQueryVariables>(InsightsCultureDocument, baseOptions);
        }
export type InsightsCultureQueryHookResult = ReturnType<typeof useInsightsCultureQuery>;
export type InsightsCultureLazyQueryHookResult = ReturnType<typeof useInsightsCultureLazyQuery>;
export type InsightsCultureQueryResult = Apollo.QueryResult<InsightsCultureQuery, InsightsCultureQueryVariables>;
export const InsightsCultureChangeDocument = gql`
    query InsightsCultureChange($surveyUuid1: String!, $surveyUuid2: String!, $filters: [String!]) {
  cultureChange: insightsCultureChange(surveyUuid1: $surveyUuid1, surveyUuid2: $surveyUuid2, filters: $filters) {
    checkedOut {
      ...InsightsCultureChange
    }
    highRisk {
      ...InsightsCultureChange
    }
    wantsMore {
      demographic
      value
      ...InsightsCultureChange
    }
  }
}
    ${InsightsCultureChangeFragmentDoc}`;

/**
 * __useInsightsCultureChangeQuery__
 *
 * To run a query within a React component, call `useInsightsCultureChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCultureChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCultureChangeQuery({
 *   variables: {
 *      surveyUuid1: // value for 'surveyUuid1'
 *      surveyUuid2: // value for 'surveyUuid2'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInsightsCultureChangeQuery(baseOptions: Apollo.QueryHookOptions<InsightsCultureChangeQuery, InsightsCultureChangeQueryVariables>) {
        return Apollo.useQuery<InsightsCultureChangeQuery, InsightsCultureChangeQueryVariables>(InsightsCultureChangeDocument, baseOptions);
      }
export function useInsightsCultureChangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCultureChangeQuery, InsightsCultureChangeQueryVariables>) {
          return Apollo.useLazyQuery<InsightsCultureChangeQuery, InsightsCultureChangeQueryVariables>(InsightsCultureChangeDocument, baseOptions);
        }
export type InsightsCultureChangeQueryHookResult = ReturnType<typeof useInsightsCultureChangeQuery>;
export type InsightsCultureChangeLazyQueryHookResult = ReturnType<typeof useInsightsCultureChangeLazyQuery>;
export type InsightsCultureChangeQueryResult = Apollo.QueryResult<InsightsCultureChangeQuery, InsightsCultureChangeQueryVariables>;
export const InsightsCustomSurveySnapshotDocument = gql`
    query InsightsCustomSurveySnapshot($surveyUuid: String!, $filters: [String!], $startDate: DateTime, $endDate: DateTime, $skipNpsScore: Boolean!) {
  insightsCustomSurvey(surveyUuid: $surveyUuid, filters: $filters, startDate: $startDate, endDate: $endDate) {
    question {
      uuid
      text
      kind
      code
      isOpenEnded
    }
    answers {
      count
      value
      text
      score
    }
    selectedFiltersAnswers {
      count
      value
      text
      score
    }
  }
  insightsNpsGroupScores(surveyUuid: $surveyUuid, filters: $filters, startDate: $startDate, endDate: $endDate) @skip(if: $skipNpsScore) {
    promoters
    passives
    detractors
    label
    count
  }
}
    `;

/**
 * __useInsightsCustomSurveySnapshotQuery__
 *
 * To run a query within a React component, call `useInsightsCustomSurveySnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCustomSurveySnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCustomSurveySnapshotQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      skipNpsScore: // value for 'skipNpsScore'
 *   },
 * });
 */
export function useInsightsCustomSurveySnapshotQuery(baseOptions: Apollo.QueryHookOptions<InsightsCustomSurveySnapshotQuery, InsightsCustomSurveySnapshotQueryVariables>) {
        return Apollo.useQuery<InsightsCustomSurveySnapshotQuery, InsightsCustomSurveySnapshotQueryVariables>(InsightsCustomSurveySnapshotDocument, baseOptions);
      }
export function useInsightsCustomSurveySnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCustomSurveySnapshotQuery, InsightsCustomSurveySnapshotQueryVariables>) {
          return Apollo.useLazyQuery<InsightsCustomSurveySnapshotQuery, InsightsCustomSurveySnapshotQueryVariables>(InsightsCustomSurveySnapshotDocument, baseOptions);
        }
export type InsightsCustomSurveySnapshotQueryHookResult = ReturnType<typeof useInsightsCustomSurveySnapshotQuery>;
export type InsightsCustomSurveySnapshotLazyQueryHookResult = ReturnType<typeof useInsightsCustomSurveySnapshotLazyQuery>;
export type InsightsCustomSurveySnapshotQueryResult = Apollo.QueryResult<InsightsCustomSurveySnapshotQuery, InsightsCustomSurveySnapshotQueryVariables>;
export const InsightsDetailedBreakdownDocument = gql`
    query InsightsDetailedBreakdown($surveyUuid: String!, $level1DtCode: String!, $level2DtCode: String!, $filters: [String!], $statementCodes: [String!], $rankBy: RankByEnum, $startDate: DateTime, $endDate: DateTime, $skipLevel2: Boolean!, $limitToCoreQEligible: Boolean) {
  level1Breakdown: insightsScoresByDataType(surveyUuid: $surveyUuid, dtCode: $level1DtCode, filters: $filters, startDate: $startDate, endDate: $endDate, statementCodes: $statementCodes, rankBy: $rankBy, limitToCoreQEligible: $limitToCoreQEligible) {
    label
    positive
  }
  level2Breakdown: insightsScoresByDataType(surveyUuid: $surveyUuid, dtCode: $level2DtCode, filters: $filters, startDate: $startDate, endDate: $endDate, statementCodes: $statementCodes, rankBy: $rankBy, limitToCoreQEligible: $limitToCoreQEligible) @skip(if: $skipLevel2) {
    label
    positive
  }
}
    `;

/**
 * __useInsightsDetailedBreakdownQuery__
 *
 * To run a query within a React component, call `useInsightsDetailedBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsDetailedBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsDetailedBreakdownQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      level1DtCode: // value for 'level1DtCode'
 *      level2DtCode: // value for 'level2DtCode'
 *      filters: // value for 'filters'
 *      statementCodes: // value for 'statementCodes'
 *      rankBy: // value for 'rankBy'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      skipLevel2: // value for 'skipLevel2'
 *      limitToCoreQEligible: // value for 'limitToCoreQEligible'
 *   },
 * });
 */
export function useInsightsDetailedBreakdownQuery(baseOptions: Apollo.QueryHookOptions<InsightsDetailedBreakdownQuery, InsightsDetailedBreakdownQueryVariables>) {
        return Apollo.useQuery<InsightsDetailedBreakdownQuery, InsightsDetailedBreakdownQueryVariables>(InsightsDetailedBreakdownDocument, baseOptions);
      }
export function useInsightsDetailedBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsDetailedBreakdownQuery, InsightsDetailedBreakdownQueryVariables>) {
          return Apollo.useLazyQuery<InsightsDetailedBreakdownQuery, InsightsDetailedBreakdownQueryVariables>(InsightsDetailedBreakdownDocument, baseOptions);
        }
export type InsightsDetailedBreakdownQueryHookResult = ReturnType<typeof useInsightsDetailedBreakdownQuery>;
export type InsightsDetailedBreakdownLazyQueryHookResult = ReturnType<typeof useInsightsDetailedBreakdownLazyQuery>;
export type InsightsDetailedBreakdownQueryResult = Apollo.QueryResult<InsightsDetailedBreakdownQuery, InsightsDetailedBreakdownQueryVariables>;
export const InsightsDimensionsOverviewDocument = gql`
    query InsightsDimensionsOverview($surveyUuid: String!, $filters: [String!]) {
  insightsDimensionsOverview(surveyUuid: $surveyUuid, filters: $filters) {
    ...InsightsGroupedScore
  }
}
    ${InsightsGroupedScoreFragmentDoc}`;

/**
 * __useInsightsDimensionsOverviewQuery__
 *
 * To run a query within a React component, call `useInsightsDimensionsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsDimensionsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsDimensionsOverviewQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInsightsDimensionsOverviewQuery(baseOptions: Apollo.QueryHookOptions<InsightsDimensionsOverviewQuery, InsightsDimensionsOverviewQueryVariables>) {
        return Apollo.useQuery<InsightsDimensionsOverviewQuery, InsightsDimensionsOverviewQueryVariables>(InsightsDimensionsOverviewDocument, baseOptions);
      }
export function useInsightsDimensionsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsDimensionsOverviewQuery, InsightsDimensionsOverviewQueryVariables>) {
          return Apollo.useLazyQuery<InsightsDimensionsOverviewQuery, InsightsDimensionsOverviewQueryVariables>(InsightsDimensionsOverviewDocument, baseOptions);
        }
export type InsightsDimensionsOverviewQueryHookResult = ReturnType<typeof useInsightsDimensionsOverviewQuery>;
export type InsightsDimensionsOverviewLazyQueryHookResult = ReturnType<typeof useInsightsDimensionsOverviewLazyQuery>;
export type InsightsDimensionsOverviewQueryResult = Apollo.QueryResult<InsightsDimensionsOverviewQuery, InsightsDimensionsOverviewQueryVariables>;
export const InsightsDischargeOverviewDocument = gql`
    query InsightsDischargeOverview($surveyUuid: String!, $filters: [String!], $skipNpsScore: Boolean!, $benchmark: BenchmarkNodeInput) {
  insightsStatements(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark) {
    uuid
    ...InsightsStatementScore
  }
  insightsNpsGroupScores(surveyUuid: $surveyUuid, filters: $filters) @skip(if: $skipNpsScore) {
    promoters
    passives
    detractors
    label
    count
  }
}
    ${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsDischargeOverviewQuery__
 *
 * To run a query within a React component, call `useInsightsDischargeOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsDischargeOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsDischargeOverviewQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      skipNpsScore: // value for 'skipNpsScore'
 *      benchmark: // value for 'benchmark'
 *   },
 * });
 */
export function useInsightsDischargeOverviewQuery(baseOptions: Apollo.QueryHookOptions<InsightsDischargeOverviewQuery, InsightsDischargeOverviewQueryVariables>) {
        return Apollo.useQuery<InsightsDischargeOverviewQuery, InsightsDischargeOverviewQueryVariables>(InsightsDischargeOverviewDocument, baseOptions);
      }
export function useInsightsDischargeOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsDischargeOverviewQuery, InsightsDischargeOverviewQueryVariables>) {
          return Apollo.useLazyQuery<InsightsDischargeOverviewQuery, InsightsDischargeOverviewQueryVariables>(InsightsDischargeOverviewDocument, baseOptions);
        }
export type InsightsDischargeOverviewQueryHookResult = ReturnType<typeof useInsightsDischargeOverviewQuery>;
export type InsightsDischargeOverviewLazyQueryHookResult = ReturnType<typeof useInsightsDischargeOverviewLazyQuery>;
export type InsightsDischargeOverviewQueryResult = Apollo.QueryResult<InsightsDischargeOverviewQuery, InsightsDischargeOverviewQueryVariables>;
export const InsightsDownloadDocument = gql`
    query InsightsDownload($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $downloadType: UserDownloadsEnum!, $startDate: DateTime, $endDate: DateTime, $certificationReportLocationUuid: String, $filterType1Code: String, $filterType2Code: String, $byClient: Boolean) {
  success: insightsDownload(surveyUuid: $surveyUuid, filters: $filters, downloadType: $downloadType, startDate: $startDate, endDate: $endDate, certificationReportLocationUuid: $certificationReportLocationUuid, filterType1Code: $filterType1Code, filterType2Code: $filterType2Code, byClient: $byClient, benchmark: $benchmark)
}
    `;

/**
 * __useInsightsDownloadQuery__
 *
 * To run a query within a React component, call `useInsightsDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsDownloadQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      downloadType: // value for 'downloadType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      certificationReportLocationUuid: // value for 'certificationReportLocationUuid'
 *      filterType1Code: // value for 'filterType1Code'
 *      filterType2Code: // value for 'filterType2Code'
 *      byClient: // value for 'byClient'
 *   },
 * });
 */
export function useInsightsDownloadQuery(baseOptions: Apollo.QueryHookOptions<InsightsDownloadQuery, InsightsDownloadQueryVariables>) {
        return Apollo.useQuery<InsightsDownloadQuery, InsightsDownloadQueryVariables>(InsightsDownloadDocument, baseOptions);
      }
export function useInsightsDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsDownloadQuery, InsightsDownloadQueryVariables>) {
          return Apollo.useLazyQuery<InsightsDownloadQuery, InsightsDownloadQueryVariables>(InsightsDownloadDocument, baseOptions);
        }
export type InsightsDownloadQueryHookResult = ReturnType<typeof useInsightsDownloadQuery>;
export type InsightsDownloadLazyQueryHookResult = ReturnType<typeof useInsightsDownloadLazyQuery>;
export type InsightsDownloadQueryResult = Apollo.QueryResult<InsightsDownloadQuery, InsightsDownloadQueryVariables>;
export const InsightsDownloadLinkDocument = gql`
    query InsightsDownloadLink($uuid: String!) {
  downloadLink(uuid: $uuid)
}
    `;

/**
 * __useInsightsDownloadLinkQuery__
 *
 * To run a query within a React component, call `useInsightsDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsDownloadLinkQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useInsightsDownloadLinkQuery(baseOptions: Apollo.QueryHookOptions<InsightsDownloadLinkQuery, InsightsDownloadLinkQueryVariables>) {
        return Apollo.useQuery<InsightsDownloadLinkQuery, InsightsDownloadLinkQueryVariables>(InsightsDownloadLinkDocument, baseOptions);
      }
export function useInsightsDownloadLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsDownloadLinkQuery, InsightsDownloadLinkQueryVariables>) {
          return Apollo.useLazyQuery<InsightsDownloadLinkQuery, InsightsDownloadLinkQueryVariables>(InsightsDownloadLinkDocument, baseOptions);
        }
export type InsightsDownloadLinkQueryHookResult = ReturnType<typeof useInsightsDownloadLinkQuery>;
export type InsightsDownloadLinkLazyQueryHookResult = ReturnType<typeof useInsightsDownloadLinkLazyQuery>;
export type InsightsDownloadLinkQueryResult = Apollo.QueryResult<InsightsDownloadLinkQuery, InsightsDownloadLinkQueryVariables>;
export const InsightsEmployeeEligibilityResponseRateDocument = gql`
    query InsightsEmployeeEligibilityResponseRate($surveyUuid: String!, $filters: [String!]) {
  insightsEmployeeEligibilityResponseRate(surveyUuid: $surveyUuid, filters: $filters) {
    certificationEligible
    listEligible
  }
}
    `;

/**
 * __useInsightsEmployeeEligibilityResponseRateQuery__
 *
 * To run a query within a React component, call `useInsightsEmployeeEligibilityResponseRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsEmployeeEligibilityResponseRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsEmployeeEligibilityResponseRateQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInsightsEmployeeEligibilityResponseRateQuery(baseOptions: Apollo.QueryHookOptions<InsightsEmployeeEligibilityResponseRateQuery, InsightsEmployeeEligibilityResponseRateQueryVariables>) {
        return Apollo.useQuery<InsightsEmployeeEligibilityResponseRateQuery, InsightsEmployeeEligibilityResponseRateQueryVariables>(InsightsEmployeeEligibilityResponseRateDocument, baseOptions);
      }
export function useInsightsEmployeeEligibilityResponseRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsEmployeeEligibilityResponseRateQuery, InsightsEmployeeEligibilityResponseRateQueryVariables>) {
          return Apollo.useLazyQuery<InsightsEmployeeEligibilityResponseRateQuery, InsightsEmployeeEligibilityResponseRateQueryVariables>(InsightsEmployeeEligibilityResponseRateDocument, baseOptions);
        }
export type InsightsEmployeeEligibilityResponseRateQueryHookResult = ReturnType<typeof useInsightsEmployeeEligibilityResponseRateQuery>;
export type InsightsEmployeeEligibilityResponseRateLazyQueryHookResult = ReturnType<typeof useInsightsEmployeeEligibilityResponseRateLazyQuery>;
export type InsightsEmployeeEligibilityResponseRateQueryResult = Apollo.QueryResult<InsightsEmployeeEligibilityResponseRateQuery, InsightsEmployeeEligibilityResponseRateQueryVariables>;
export const InsightsEmployeeFocusAreasDocument = gql`
    query InsightsEmployeeFocusAreas($surveyUuid: String!, $filters: [String!], $rankBy: RankByEnum, $dtCodes: [String!]!, $statement1Codes: [String!], $statement2Codes: [String!], $statement3Codes: [String!], $improvementDtCode: String) {
  statement1Groups: insightsScoresByDataTypes(surveyUuid: $surveyUuid, dtCodes: $dtCodes, filters: $filters, rankBy: $rankBy, statementCodes: $statement1Codes) {
    ...GroupedScores
  }
  statement2Groups: insightsScoresByDataTypes(surveyUuid: $surveyUuid, dtCodes: $dtCodes, filters: $filters, rankBy: $rankBy, statementCodes: $statement2Codes) {
    ...GroupedScores
  }
  statement3Groups: insightsScoresByDataTypes(surveyUuid: $surveyUuid, dtCodes: $dtCodes, filters: $filters, rankBy: $rankBy, statementCodes: $statement3Codes) {
    ...GroupedScores
  }
  insightsPotentialImprovementScore(surveyUuid: $surveyUuid, dtCode: $improvementDtCode, filters: $filters) {
    improvementScore
    improvedGroups {
      score
      name
    }
  }
}
    ${GroupedScoresFragmentDoc}`;

/**
 * __useInsightsEmployeeFocusAreasQuery__
 *
 * To run a query within a React component, call `useInsightsEmployeeFocusAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsEmployeeFocusAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsEmployeeFocusAreasQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      rankBy: // value for 'rankBy'
 *      dtCodes: // value for 'dtCodes'
 *      statement1Codes: // value for 'statement1Codes'
 *      statement2Codes: // value for 'statement2Codes'
 *      statement3Codes: // value for 'statement3Codes'
 *      improvementDtCode: // value for 'improvementDtCode'
 *   },
 * });
 */
export function useInsightsEmployeeFocusAreasQuery(baseOptions: Apollo.QueryHookOptions<InsightsEmployeeFocusAreasQuery, InsightsEmployeeFocusAreasQueryVariables>) {
        return Apollo.useQuery<InsightsEmployeeFocusAreasQuery, InsightsEmployeeFocusAreasQueryVariables>(InsightsEmployeeFocusAreasDocument, baseOptions);
      }
export function useInsightsEmployeeFocusAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsEmployeeFocusAreasQuery, InsightsEmployeeFocusAreasQueryVariables>) {
          return Apollo.useLazyQuery<InsightsEmployeeFocusAreasQuery, InsightsEmployeeFocusAreasQueryVariables>(InsightsEmployeeFocusAreasDocument, baseOptions);
        }
export type InsightsEmployeeFocusAreasQueryHookResult = ReturnType<typeof useInsightsEmployeeFocusAreasQuery>;
export type InsightsEmployeeFocusAreasLazyQueryHookResult = ReturnType<typeof useInsightsEmployeeFocusAreasLazyQuery>;
export type InsightsEmployeeFocusAreasQueryResult = Apollo.QueryResult<InsightsEmployeeFocusAreasQuery, InsightsEmployeeFocusAreasQueryVariables>;
export const InsightsHeatmapDocument = gql`
    query InsightsHeatmap($surveyUuid: String!, $y: String!, $filters: [String!]!) {
  insightsHeatmap(surveyUuid: $surveyUuid, filters: $filters, y: $y) {
    categories
    statements {
      code
      focus
      residentResultsGroup
      text
    }
    cells {
      score
    }
  }
}
    `;

/**
 * __useInsightsHeatmapQuery__
 *
 * To run a query within a React component, call `useInsightsHeatmapQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsHeatmapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsHeatmapQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      y: // value for 'y'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInsightsHeatmapQuery(baseOptions: Apollo.QueryHookOptions<InsightsHeatmapQuery, InsightsHeatmapQueryVariables>) {
        return Apollo.useQuery<InsightsHeatmapQuery, InsightsHeatmapQueryVariables>(InsightsHeatmapDocument, baseOptions);
      }
export function useInsightsHeatmapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsHeatmapQuery, InsightsHeatmapQueryVariables>) {
          return Apollo.useLazyQuery<InsightsHeatmapQuery, InsightsHeatmapQueryVariables>(InsightsHeatmapDocument, baseOptions);
        }
export type InsightsHeatmapQueryHookResult = ReturnType<typeof useInsightsHeatmapQuery>;
export type InsightsHeatmapLazyQueryHookResult = ReturnType<typeof useInsightsHeatmapLazyQuery>;
export type InsightsHeatmapQueryResult = Apollo.QueryResult<InsightsHeatmapQuery, InsightsHeatmapQueryVariables>;
export const InsightsHeatmapBenchmarkScoresDocument = gql`
    query InsightsHeatmapBenchmarkScores($surveyUuid: String!, $benchmark: BenchmarkNodeInput!) {
  scores: insightsHeatmapBenchmarkScores(surveyUuid: $surveyUuid, benchmark: $benchmark) {
    name
    scores
  }
}
    `;

/**
 * __useInsightsHeatmapBenchmarkScoresQuery__
 *
 * To run a query within a React component, call `useInsightsHeatmapBenchmarkScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsHeatmapBenchmarkScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsHeatmapBenchmarkScoresQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      benchmark: // value for 'benchmark'
 *   },
 * });
 */
export function useInsightsHeatmapBenchmarkScoresQuery(baseOptions: Apollo.QueryHookOptions<InsightsHeatmapBenchmarkScoresQuery, InsightsHeatmapBenchmarkScoresQueryVariables>) {
        return Apollo.useQuery<InsightsHeatmapBenchmarkScoresQuery, InsightsHeatmapBenchmarkScoresQueryVariables>(InsightsHeatmapBenchmarkScoresDocument, baseOptions);
      }
export function useInsightsHeatmapBenchmarkScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsHeatmapBenchmarkScoresQuery, InsightsHeatmapBenchmarkScoresQueryVariables>) {
          return Apollo.useLazyQuery<InsightsHeatmapBenchmarkScoresQuery, InsightsHeatmapBenchmarkScoresQueryVariables>(InsightsHeatmapBenchmarkScoresDocument, baseOptions);
        }
export type InsightsHeatmapBenchmarkScoresQueryHookResult = ReturnType<typeof useInsightsHeatmapBenchmarkScoresQuery>;
export type InsightsHeatmapBenchmarkScoresLazyQueryHookResult = ReturnType<typeof useInsightsHeatmapBenchmarkScoresLazyQuery>;
export type InsightsHeatmapBenchmarkScoresQueryResult = Apollo.QueryResult<InsightsHeatmapBenchmarkScoresQuery, InsightsHeatmapBenchmarkScoresQueryVariables>;
export const InsightsHeatmapControlsDocument = gql`
    query InsightsHeatmapControls($surveyUuid: String!, $filters: [String!]) {
  controls: insightsHeatmapControls(surveyUuid: $surveyUuid, filters: $filters) {
    demographics {
      code
      text
      pluralText
    }
  }
}
    `;

/**
 * __useInsightsHeatmapControlsQuery__
 *
 * To run a query within a React component, call `useInsightsHeatmapControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsHeatmapControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsHeatmapControlsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInsightsHeatmapControlsQuery(baseOptions: Apollo.QueryHookOptions<InsightsHeatmapControlsQuery, InsightsHeatmapControlsQueryVariables>) {
        return Apollo.useQuery<InsightsHeatmapControlsQuery, InsightsHeatmapControlsQueryVariables>(InsightsHeatmapControlsDocument, baseOptions);
      }
export function useInsightsHeatmapControlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsHeatmapControlsQuery, InsightsHeatmapControlsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsHeatmapControlsQuery, InsightsHeatmapControlsQueryVariables>(InsightsHeatmapControlsDocument, baseOptions);
        }
export type InsightsHeatmapControlsQueryHookResult = ReturnType<typeof useInsightsHeatmapControlsQuery>;
export type InsightsHeatmapControlsLazyQueryHookResult = ReturnType<typeof useInsightsHeatmapControlsLazyQuery>;
export type InsightsHeatmapControlsQueryResult = Apollo.QueryResult<InsightsHeatmapControlsQuery, InsightsHeatmapControlsQueryVariables>;
export const InsightsIndividualResultsDocument = gql`
    query InsightsIndividualResults($surveyUuid: String!, $filters: [String!], $offset: Int!, $limit: Int!, $searchQuery: String, $startDate: DateTime, $endDate: DateTime) {
  insightsIndividualResults(surveyUuid: $surveyUuid, filters: $filters, offset: $offset, limit: $limit, searchQuery: $searchQuery, startDate: $startDate, endDate: $endDate) {
    totalResults
    name
    clientName
    participantType
    participantUuid
    locationName
    levelOfCare
    startedTimestamp
    submittedTimestamp
    responses {
      answer
      answers
      questionCode
    }
    questions {
      benchmarkCode
      code
      text
      category
      isOpenEnded
      isStatement
      choices {
        benchmarkCode
        code
        text
        value
      }
    }
    jobTitle
    source
    callTime
    callLength
    dischargeDate
    lastSentNotificationTimestamp
  }
}
    `;

/**
 * __useInsightsIndividualResultsQuery__
 *
 * To run a query within a React component, call `useInsightsIndividualResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsIndividualResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsIndividualResultsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      searchQuery: // value for 'searchQuery'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsIndividualResultsQuery(baseOptions: Apollo.QueryHookOptions<InsightsIndividualResultsQuery, InsightsIndividualResultsQueryVariables>) {
        return Apollo.useQuery<InsightsIndividualResultsQuery, InsightsIndividualResultsQueryVariables>(InsightsIndividualResultsDocument, baseOptions);
      }
export function useInsightsIndividualResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsIndividualResultsQuery, InsightsIndividualResultsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsIndividualResultsQuery, InsightsIndividualResultsQueryVariables>(InsightsIndividualResultsDocument, baseOptions);
        }
export type InsightsIndividualResultsQueryHookResult = ReturnType<typeof useInsightsIndividualResultsQuery>;
export type InsightsIndividualResultsLazyQueryHookResult = ReturnType<typeof useInsightsIndividualResultsLazyQuery>;
export type InsightsIndividualResultsQueryResult = Apollo.QueryResult<InsightsIndividualResultsQuery, InsightsIndividualResultsQueryVariables>;
export const InsightsKeyDemographicsDocument = gql`
    query InsightsKeyDemographics($surveyUuid: String!, $filters: [String!], $impactScoreStrategy: ImpactScoreStrategyEnum!, $statementCodes: [String!], $startDate: DateTime, $endDate: DateTime) {
  insightsKeyDemographics(surveyUuid: $surveyUuid, filters: $filters, impactScoreStrategy: $impactScoreStrategy, statementCodes: $statementCodes, startDate: $startDate, endDate: $endDate) {
    dataType
    label
    scores {
      benchmarkPositive
      positive
      inconsistent
      negative
      label
      count
    }
  }
}
    `;

/**
 * __useInsightsKeyDemographicsQuery__
 *
 * To run a query within a React component, call `useInsightsKeyDemographicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsKeyDemographicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsKeyDemographicsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      impactScoreStrategy: // value for 'impactScoreStrategy'
 *      statementCodes: // value for 'statementCodes'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsKeyDemographicsQuery(baseOptions: Apollo.QueryHookOptions<InsightsKeyDemographicsQuery, InsightsKeyDemographicsQueryVariables>) {
        return Apollo.useQuery<InsightsKeyDemographicsQuery, InsightsKeyDemographicsQueryVariables>(InsightsKeyDemographicsDocument, baseOptions);
      }
export function useInsightsKeyDemographicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsKeyDemographicsQuery, InsightsKeyDemographicsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsKeyDemographicsQuery, InsightsKeyDemographicsQueryVariables>(InsightsKeyDemographicsDocument, baseOptions);
        }
export type InsightsKeyDemographicsQueryHookResult = ReturnType<typeof useInsightsKeyDemographicsQuery>;
export type InsightsKeyDemographicsLazyQueryHookResult = ReturnType<typeof useInsightsKeyDemographicsLazyQuery>;
export type InsightsKeyDemographicsQueryResult = Apollo.QueryResult<InsightsKeyDemographicsQuery, InsightsKeyDemographicsQueryVariables>;
export const InsightsKeyStatementDocument = gql`
    query InsightsKeyStatement($associatedSurveyUuid: String!, $surveyUuid: String!, $statementCodes: [String!], $filters: [String!]) {
  keyStatement: insightsStatements(surveyUuid: $surveyUuid, statementCodes: $statementCodes, filters: $filters) {
    label
    positive
    inconsistent
    negative
  }
  associatedSurveyKeyStatement: insightsStatements(surveyUuid: $associatedSurveyUuid, statementCodes: $statementCodes, filters: $filters) {
    positive
  }
}
    `;

/**
 * __useInsightsKeyStatementQuery__
 *
 * To run a query within a React component, call `useInsightsKeyStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsKeyStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsKeyStatementQuery({
 *   variables: {
 *      associatedSurveyUuid: // value for 'associatedSurveyUuid'
 *      surveyUuid: // value for 'surveyUuid'
 *      statementCodes: // value for 'statementCodes'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInsightsKeyStatementQuery(baseOptions: Apollo.QueryHookOptions<InsightsKeyStatementQuery, InsightsKeyStatementQueryVariables>) {
        return Apollo.useQuery<InsightsKeyStatementQuery, InsightsKeyStatementQueryVariables>(InsightsKeyStatementDocument, baseOptions);
      }
export function useInsightsKeyStatementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsKeyStatementQuery, InsightsKeyStatementQueryVariables>) {
          return Apollo.useLazyQuery<InsightsKeyStatementQuery, InsightsKeyStatementQueryVariables>(InsightsKeyStatementDocument, baseOptions);
        }
export type InsightsKeyStatementQueryHookResult = ReturnType<typeof useInsightsKeyStatementQuery>;
export type InsightsKeyStatementLazyQueryHookResult = ReturnType<typeof useInsightsKeyStatementLazyQuery>;
export type InsightsKeyStatementQueryResult = Apollo.QueryResult<InsightsKeyStatementQuery, InsightsKeyStatementQueryVariables>;
export const InsightsLonelinessSummaryDocument = gql`
    query InsightsLonelinessSummary($surveyUuid: String!, $filters: [String!]) {
  insightsLonelinessSummary(surveyUuid: $surveyUuid, filters: $filters) {
    numLonely
    numSomewhatLonely
    numNotLonely
  }
}
    `;

/**
 * __useInsightsLonelinessSummaryQuery__
 *
 * To run a query within a React component, call `useInsightsLonelinessSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsLonelinessSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsLonelinessSummaryQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInsightsLonelinessSummaryQuery(baseOptions: Apollo.QueryHookOptions<InsightsLonelinessSummaryQuery, InsightsLonelinessSummaryQueryVariables>) {
        return Apollo.useQuery<InsightsLonelinessSummaryQuery, InsightsLonelinessSummaryQueryVariables>(InsightsLonelinessSummaryDocument, baseOptions);
      }
export function useInsightsLonelinessSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsLonelinessSummaryQuery, InsightsLonelinessSummaryQueryVariables>) {
          return Apollo.useLazyQuery<InsightsLonelinessSummaryQuery, InsightsLonelinessSummaryQueryVariables>(InsightsLonelinessSummaryDocument, baseOptions);
        }
export type InsightsLonelinessSummaryQueryHookResult = ReturnType<typeof useInsightsLonelinessSummaryQuery>;
export type InsightsLonelinessSummaryLazyQueryHookResult = ReturnType<typeof useInsightsLonelinessSummaryLazyQuery>;
export type InsightsLonelinessSummaryQueryResult = Apollo.QueryResult<InsightsLonelinessSummaryQuery, InsightsLonelinessSummaryQueryVariables>;
export const InsightsMoveOutOverviewDocument = gql`
    query InsightsMoveOutOverview($surveyUuid: String!, $statementCodes: [String!]!, $filters: [String!], $startDate: DateTime, $endDate: DateTime) {
  insightsNpsGroupScores(surveyUuid: $surveyUuid, filters: $filters, startDate: $startDate, endDate: $endDate) {
    promoters
    passives
    detractors
    label
    count
  }
  recommendStatementData: insightsStatements(surveyUuid: $surveyUuid, statementCodes: $statementCodes, filters: $filters, startDate: $startDate, endDate: $endDate) {
    uuid
    ...InsightsStatementScore
  }
}
    ${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsMoveOutOverviewQuery__
 *
 * To run a query within a React component, call `useInsightsMoveOutOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsMoveOutOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsMoveOutOverviewQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      statementCodes: // value for 'statementCodes'
 *      filters: // value for 'filters'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsMoveOutOverviewQuery(baseOptions: Apollo.QueryHookOptions<InsightsMoveOutOverviewQuery, InsightsMoveOutOverviewQueryVariables>) {
        return Apollo.useQuery<InsightsMoveOutOverviewQuery, InsightsMoveOutOverviewQueryVariables>(InsightsMoveOutOverviewDocument, baseOptions);
      }
export function useInsightsMoveOutOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsMoveOutOverviewQuery, InsightsMoveOutOverviewQueryVariables>) {
          return Apollo.useLazyQuery<InsightsMoveOutOverviewQuery, InsightsMoveOutOverviewQueryVariables>(InsightsMoveOutOverviewDocument, baseOptions);
        }
export type InsightsMoveOutOverviewQueryHookResult = ReturnType<typeof useInsightsMoveOutOverviewQuery>;
export type InsightsMoveOutOverviewLazyQueryHookResult = ReturnType<typeof useInsightsMoveOutOverviewLazyQuery>;
export type InsightsMoveOutOverviewQueryResult = Apollo.QueryResult<InsightsMoveOutOverviewQuery, InsightsMoveOutOverviewQueryVariables>;
export const InsightsMultiselectGroupCountsDocument = gql`
    query InsightsMultiselectGroupCounts($surveyUuid: String!, $multiselectCode: String!, $filters: [String!], $startDate: DateTime, $endDate: DateTime) {
  insightsMultiselectGroupCounts(surveyUuid: $surveyUuid, code: $multiselectCode, filters: $filters, startDate: $startDate, endDate: $endDate) {
    label
    count
  }
}
    `;

/**
 * __useInsightsMultiselectGroupCountsQuery__
 *
 * To run a query within a React component, call `useInsightsMultiselectGroupCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsMultiselectGroupCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsMultiselectGroupCountsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      multiselectCode: // value for 'multiselectCode'
 *      filters: // value for 'filters'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsMultiselectGroupCountsQuery(baseOptions: Apollo.QueryHookOptions<InsightsMultiselectGroupCountsQuery, InsightsMultiselectGroupCountsQueryVariables>) {
        return Apollo.useQuery<InsightsMultiselectGroupCountsQuery, InsightsMultiselectGroupCountsQueryVariables>(InsightsMultiselectGroupCountsDocument, baseOptions);
      }
export function useInsightsMultiselectGroupCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsMultiselectGroupCountsQuery, InsightsMultiselectGroupCountsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsMultiselectGroupCountsQuery, InsightsMultiselectGroupCountsQueryVariables>(InsightsMultiselectGroupCountsDocument, baseOptions);
        }
export type InsightsMultiselectGroupCountsQueryHookResult = ReturnType<typeof useInsightsMultiselectGroupCountsQuery>;
export type InsightsMultiselectGroupCountsLazyQueryHookResult = ReturnType<typeof useInsightsMultiselectGroupCountsLazyQuery>;
export type InsightsMultiselectGroupCountsQueryResult = Apollo.QueryResult<InsightsMultiselectGroupCountsQuery, InsightsMultiselectGroupCountsQueryVariables>;
export const InsightsNpsGroupScoresByDataTypeDocument = gql`
    query InsightsNpsGroupScoresByDataType($surveyUuid: String!, $dtCode: String!, $filters: [String!], $startDate: DateTime, $endDate: DateTime) {
  insightsNpsGroupScoresByDataType(surveyUuid: $surveyUuid, dtCode: $dtCode, filters: $filters, startDate: $startDate, endDate: $endDate) {
    promoters
    passives
    detractors
    label
  }
}
    `;

/**
 * __useInsightsNpsGroupScoresByDataTypeQuery__
 *
 * To run a query within a React component, call `useInsightsNpsGroupScoresByDataTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsNpsGroupScoresByDataTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsNpsGroupScoresByDataTypeQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      dtCode: // value for 'dtCode'
 *      filters: // value for 'filters'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsNpsGroupScoresByDataTypeQuery(baseOptions: Apollo.QueryHookOptions<InsightsNpsGroupScoresByDataTypeQuery, InsightsNpsGroupScoresByDataTypeQueryVariables>) {
        return Apollo.useQuery<InsightsNpsGroupScoresByDataTypeQuery, InsightsNpsGroupScoresByDataTypeQueryVariables>(InsightsNpsGroupScoresByDataTypeDocument, baseOptions);
      }
export function useInsightsNpsGroupScoresByDataTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsNpsGroupScoresByDataTypeQuery, InsightsNpsGroupScoresByDataTypeQueryVariables>) {
          return Apollo.useLazyQuery<InsightsNpsGroupScoresByDataTypeQuery, InsightsNpsGroupScoresByDataTypeQueryVariables>(InsightsNpsGroupScoresByDataTypeDocument, baseOptions);
        }
export type InsightsNpsGroupScoresByDataTypeQueryHookResult = ReturnType<typeof useInsightsNpsGroupScoresByDataTypeQuery>;
export type InsightsNpsGroupScoresByDataTypeLazyQueryHookResult = ReturnType<typeof useInsightsNpsGroupScoresByDataTypeLazyQuery>;
export type InsightsNpsGroupScoresByDataTypeQueryResult = Apollo.QueryResult<InsightsNpsGroupScoresByDataTypeQuery, InsightsNpsGroupScoresByDataTypeQueryVariables>;
export const InsightsOpenEndedQuestionsDocument = gql`
    query InsightsOpenEndedQuestions($surveyUuid: String!, $filters: [String!]) {
  questions: insightsOpenEndedQuestions(surveyUuid: $surveyUuid, filters: $filters) {
    uuid
    code
    text
  }
}
    `;

/**
 * __useInsightsOpenEndedQuestionsQuery__
 *
 * To run a query within a React component, call `useInsightsOpenEndedQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsOpenEndedQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsOpenEndedQuestionsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInsightsOpenEndedQuestionsQuery(baseOptions: Apollo.QueryHookOptions<InsightsOpenEndedQuestionsQuery, InsightsOpenEndedQuestionsQueryVariables>) {
        return Apollo.useQuery<InsightsOpenEndedQuestionsQuery, InsightsOpenEndedQuestionsQueryVariables>(InsightsOpenEndedQuestionsDocument, baseOptions);
      }
export function useInsightsOpenEndedQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsOpenEndedQuestionsQuery, InsightsOpenEndedQuestionsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsOpenEndedQuestionsQuery, InsightsOpenEndedQuestionsQueryVariables>(InsightsOpenEndedQuestionsDocument, baseOptions);
        }
export type InsightsOpenEndedQuestionsQueryHookResult = ReturnType<typeof useInsightsOpenEndedQuestionsQuery>;
export type InsightsOpenEndedQuestionsLazyQueryHookResult = ReturnType<typeof useInsightsOpenEndedQuestionsLazyQuery>;
export type InsightsOpenEndedQuestionsQueryResult = Apollo.QueryResult<InsightsOpenEndedQuestionsQuery, InsightsOpenEndedQuestionsQueryVariables>;
export const InsightsOrganizationServicesDocument = gql`
    query InsightsOrganizationServices {
  currentUser {
    id
    organization {
      uuid
      serviceAreas {
        label
        questionLabel
        questionCodes
        code
      }
    }
  }
}
    `;

/**
 * __useInsightsOrganizationServicesQuery__
 *
 * To run a query within a React component, call `useInsightsOrganizationServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsOrganizationServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsOrganizationServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsightsOrganizationServicesQuery(baseOptions?: Apollo.QueryHookOptions<InsightsOrganizationServicesQuery, InsightsOrganizationServicesQueryVariables>) {
        return Apollo.useQuery<InsightsOrganizationServicesQuery, InsightsOrganizationServicesQueryVariables>(InsightsOrganizationServicesDocument, baseOptions);
      }
export function useInsightsOrganizationServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsOrganizationServicesQuery, InsightsOrganizationServicesQueryVariables>) {
          return Apollo.useLazyQuery<InsightsOrganizationServicesQuery, InsightsOrganizationServicesQueryVariables>(InsightsOrganizationServicesDocument, baseOptions);
        }
export type InsightsOrganizationServicesQueryHookResult = ReturnType<typeof useInsightsOrganizationServicesQuery>;
export type InsightsOrganizationServicesLazyQueryHookResult = ReturnType<typeof useInsightsOrganizationServicesLazyQuery>;
export type InsightsOrganizationServicesQueryResult = Apollo.QueryResult<InsightsOrganizationServicesQuery, InsightsOrganizationServicesQueryVariables>;
export const InsightsOverallIndexDocument = gql`
    query InsightsOverallIndex($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $statementCodes: [String!], $includeCustom: Boolean, $startDate: DateTime, $endDate: DateTime, $limitToCoreQEligible: Boolean) {
  insightsOverallIndex(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark, startDate: $startDate, endDate: $endDate, statementCodes: $statementCodes, includeCustom: $includeCustom, limitToCoreQEligible: $limitToCoreQEligible) {
    positive
    negative
    inconsistent
    count
    totalResponses
  }
}
    `;

/**
 * __useInsightsOverallIndexQuery__
 *
 * To run a query within a React component, call `useInsightsOverallIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsOverallIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsOverallIndexQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      statementCodes: // value for 'statementCodes'
 *      includeCustom: // value for 'includeCustom'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      limitToCoreQEligible: // value for 'limitToCoreQEligible'
 *   },
 * });
 */
export function useInsightsOverallIndexQuery(baseOptions: Apollo.QueryHookOptions<InsightsOverallIndexQuery, InsightsOverallIndexQueryVariables>) {
        return Apollo.useQuery<InsightsOverallIndexQuery, InsightsOverallIndexQueryVariables>(InsightsOverallIndexDocument, baseOptions);
      }
export function useInsightsOverallIndexLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsOverallIndexQuery, InsightsOverallIndexQueryVariables>) {
          return Apollo.useLazyQuery<InsightsOverallIndexQuery, InsightsOverallIndexQueryVariables>(InsightsOverallIndexDocument, baseOptions);
        }
export type InsightsOverallIndexQueryHookResult = ReturnType<typeof useInsightsOverallIndexQuery>;
export type InsightsOverallIndexLazyQueryHookResult = ReturnType<typeof useInsightsOverallIndexLazyQuery>;
export type InsightsOverallIndexQueryResult = Apollo.QueryResult<InsightsOverallIndexQuery, InsightsOverallIndexQueryVariables>;
export const InsightsOverlayDrivingStatementsDocument = gql`
    query InsightsOverlayDrivingStatements($employeeSurveyUuid: String!, $residentSurveyUuid: String!, $filters: [String!], $rankByPositive: RankByEnum) {
  positiveEmployeeDrivingStatements: insightsStatements(surveyUuid: $employeeSurveyUuid, filters: $filters, rankBy: $rankByPositive) {
    ...InsightsStatementScore
    overallPositiveCorrelation
  }
  positiveResidentDrivingStatements: insightsStatements(surveyUuid: $residentSurveyUuid, filters: $filters, rankBy: $rankByPositive) {
    ...InsightsStatementScore
    overallPositiveCorrelation
  }
}
    ${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsOverlayDrivingStatementsQuery__
 *
 * To run a query within a React component, call `useInsightsOverlayDrivingStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsOverlayDrivingStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsOverlayDrivingStatementsQuery({
 *   variables: {
 *      employeeSurveyUuid: // value for 'employeeSurveyUuid'
 *      residentSurveyUuid: // value for 'residentSurveyUuid'
 *      filters: // value for 'filters'
 *      rankByPositive: // value for 'rankByPositive'
 *   },
 * });
 */
export function useInsightsOverlayDrivingStatementsQuery(baseOptions: Apollo.QueryHookOptions<InsightsOverlayDrivingStatementsQuery, InsightsOverlayDrivingStatementsQueryVariables>) {
        return Apollo.useQuery<InsightsOverlayDrivingStatementsQuery, InsightsOverlayDrivingStatementsQueryVariables>(InsightsOverlayDrivingStatementsDocument, baseOptions);
      }
export function useInsightsOverlayDrivingStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsOverlayDrivingStatementsQuery, InsightsOverlayDrivingStatementsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsOverlayDrivingStatementsQuery, InsightsOverlayDrivingStatementsQueryVariables>(InsightsOverlayDrivingStatementsDocument, baseOptions);
        }
export type InsightsOverlayDrivingStatementsQueryHookResult = ReturnType<typeof useInsightsOverlayDrivingStatementsQuery>;
export type InsightsOverlayDrivingStatementsLazyQueryHookResult = ReturnType<typeof useInsightsOverlayDrivingStatementsLazyQuery>;
export type InsightsOverlayDrivingStatementsQueryResult = Apollo.QueryResult<InsightsOverlayDrivingStatementsQuery, InsightsOverlayDrivingStatementsQueryVariables>;
export const InsightsOverlayServicesDataDocument = gql`
    query InsightsOverlayServicesData($employeeSurveyUuid: String!, $filters: [String!]!, $dataTypeFilters: [OverallScoreByFiltersInputType!]!) {
  overallIndexScoreByFilters(surveyUuid: $employeeSurveyUuid, dataTypeFilters: $dataTypeFilters, filters: $filters) {
    positive
    label
  }
}
    `;

/**
 * __useInsightsOverlayServicesDataQuery__
 *
 * To run a query within a React component, call `useInsightsOverlayServicesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsOverlayServicesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsOverlayServicesDataQuery({
 *   variables: {
 *      employeeSurveyUuid: // value for 'employeeSurveyUuid'
 *      filters: // value for 'filters'
 *      dataTypeFilters: // value for 'dataTypeFilters'
 *   },
 * });
 */
export function useInsightsOverlayServicesDataQuery(baseOptions: Apollo.QueryHookOptions<InsightsOverlayServicesDataQuery, InsightsOverlayServicesDataQueryVariables>) {
        return Apollo.useQuery<InsightsOverlayServicesDataQuery, InsightsOverlayServicesDataQueryVariables>(InsightsOverlayServicesDataDocument, baseOptions);
      }
export function useInsightsOverlayServicesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsOverlayServicesDataQuery, InsightsOverlayServicesDataQueryVariables>) {
          return Apollo.useLazyQuery<InsightsOverlayServicesDataQuery, InsightsOverlayServicesDataQueryVariables>(InsightsOverlayServicesDataDocument, baseOptions);
        }
export type InsightsOverlayServicesDataQueryHookResult = ReturnType<typeof useInsightsOverlayServicesDataQuery>;
export type InsightsOverlayServicesDataLazyQueryHookResult = ReturnType<typeof useInsightsOverlayServicesDataLazyQuery>;
export type InsightsOverlayServicesDataQueryResult = Apollo.QueryResult<InsightsOverlayServicesDataQuery, InsightsOverlayServicesDataQueryVariables>;
export const InsightsOverlayServicesSettingsDocument = gql`
    query InsightsOverlayServicesSettings($residentSurveyUuid: String!, $filters: [String!]!, $employeeSurveyUuid: String!, $workStatusCode: DataTypeCode!, $benchmarkDeptCode: DataTypeCode!) {
  currentUser {
    id
    organization {
      uuid
      serviceAreas {
        ...OrganizationServiceAreas
        questionLabel
        questionCodes
      }
    }
  }
  insightsStatements(surveyUuid: $residentSurveyUuid, filters: $filters) {
    ...InsightsStatementScore
  }
  validWorkStatuses: insightsGroupsForDtCode(surveyUuid: $employeeSurveyUuid, filters: $filters, dtCode: $workStatusCode)
  validBenchmarkDepts: insightsGroupsForDtCode(surveyUuid: $employeeSurveyUuid, filters: $filters, dtCode: $benchmarkDeptCode)
}
    ${OrganizationServiceAreasFragmentDoc}
${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsOverlayServicesSettingsQuery__
 *
 * To run a query within a React component, call `useInsightsOverlayServicesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsOverlayServicesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsOverlayServicesSettingsQuery({
 *   variables: {
 *      residentSurveyUuid: // value for 'residentSurveyUuid'
 *      filters: // value for 'filters'
 *      employeeSurveyUuid: // value for 'employeeSurveyUuid'
 *      workStatusCode: // value for 'workStatusCode'
 *      benchmarkDeptCode: // value for 'benchmarkDeptCode'
 *   },
 * });
 */
export function useInsightsOverlayServicesSettingsQuery(baseOptions: Apollo.QueryHookOptions<InsightsOverlayServicesSettingsQuery, InsightsOverlayServicesSettingsQueryVariables>) {
        return Apollo.useQuery<InsightsOverlayServicesSettingsQuery, InsightsOverlayServicesSettingsQueryVariables>(InsightsOverlayServicesSettingsDocument, baseOptions);
      }
export function useInsightsOverlayServicesSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsOverlayServicesSettingsQuery, InsightsOverlayServicesSettingsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsOverlayServicesSettingsQuery, InsightsOverlayServicesSettingsQueryVariables>(InsightsOverlayServicesSettingsDocument, baseOptions);
        }
export type InsightsOverlayServicesSettingsQueryHookResult = ReturnType<typeof useInsightsOverlayServicesSettingsQuery>;
export type InsightsOverlayServicesSettingsLazyQueryHookResult = ReturnType<typeof useInsightsOverlayServicesSettingsLazyQuery>;
export type InsightsOverlayServicesSettingsQueryResult = Apollo.QueryResult<InsightsOverlayServicesSettingsQuery, InsightsOverlayServicesSettingsQueryVariables>;
export const InsightsRankedStatementsDocument = gql`
    query InsightsRankedStatements($surveyUuid: String!, $rankBy: RankByEnum, $statementCodes: [String!], $includeCustom: Boolean) {
  statements: insightsStatements(surveyUuid: $surveyUuid, rankBy: $rankBy, statementCodes: $statementCodes, includeCustom: $includeCustom) {
    uuid
    ...InsightsStatementScore
  }
}
    ${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsRankedStatementsQuery__
 *
 * To run a query within a React component, call `useInsightsRankedStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsRankedStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsRankedStatementsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      rankBy: // value for 'rankBy'
 *      statementCodes: // value for 'statementCodes'
 *      includeCustom: // value for 'includeCustom'
 *   },
 * });
 */
export function useInsightsRankedStatementsQuery(baseOptions: Apollo.QueryHookOptions<InsightsRankedStatementsQuery, InsightsRankedStatementsQueryVariables>) {
        return Apollo.useQuery<InsightsRankedStatementsQuery, InsightsRankedStatementsQueryVariables>(InsightsRankedStatementsDocument, baseOptions);
      }
export function useInsightsRankedStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsRankedStatementsQuery, InsightsRankedStatementsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsRankedStatementsQuery, InsightsRankedStatementsQueryVariables>(InsightsRankedStatementsDocument, baseOptions);
        }
export type InsightsRankedStatementsQueryHookResult = ReturnType<typeof useInsightsRankedStatementsQuery>;
export type InsightsRankedStatementsLazyQueryHookResult = ReturnType<typeof useInsightsRankedStatementsLazyQuery>;
export type InsightsRankedStatementsQueryResult = Apollo.QueryResult<InsightsRankedStatementsQuery, InsightsRankedStatementsQueryVariables>;
export const InsightsReasonsForLeavingDocument = gql`
    query InsightsReasonsForLeaving($surveyUuid: String!, $multiselectCode: String!, $dtCode: String!, $filters: [String!], $startDate: DateTime, $endDate: DateTime) {
  insightsMultiselectGroupCounts(surveyUuid: $surveyUuid, code: $multiselectCode, filters: $filters, startDate: $startDate, endDate: $endDate) {
    label
    count
  }
  insightsVoluntaryDischargeCountsBy(surveyUuid: $surveyUuid, dtCode: $dtCode, filters: $filters, startDate: $startDate, endDate: $endDate) {
    label
    voluntaryCount
    involuntaryCount
  }
}
    `;

/**
 * __useInsightsReasonsForLeavingQuery__
 *
 * To run a query within a React component, call `useInsightsReasonsForLeavingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsReasonsForLeavingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsReasonsForLeavingQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      multiselectCode: // value for 'multiselectCode'
 *      dtCode: // value for 'dtCode'
 *      filters: // value for 'filters'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsReasonsForLeavingQuery(baseOptions: Apollo.QueryHookOptions<InsightsReasonsForLeavingQuery, InsightsReasonsForLeavingQueryVariables>) {
        return Apollo.useQuery<InsightsReasonsForLeavingQuery, InsightsReasonsForLeavingQueryVariables>(InsightsReasonsForLeavingDocument, baseOptions);
      }
export function useInsightsReasonsForLeavingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsReasonsForLeavingQuery, InsightsReasonsForLeavingQueryVariables>) {
          return Apollo.useLazyQuery<InsightsReasonsForLeavingQuery, InsightsReasonsForLeavingQueryVariables>(InsightsReasonsForLeavingDocument, baseOptions);
        }
export type InsightsReasonsForLeavingQueryHookResult = ReturnType<typeof useInsightsReasonsForLeavingQuery>;
export type InsightsReasonsForLeavingLazyQueryHookResult = ReturnType<typeof useInsightsReasonsForLeavingLazyQuery>;
export type InsightsReasonsForLeavingQueryResult = Apollo.QueryResult<InsightsReasonsForLeavingQuery, InsightsReasonsForLeavingQueryVariables>;
export const InsightsResidentFocusAreasDocument = gql`
    query InsightsResidentFocusAreas($surveyUuid: String!, $filters: [String!], $rankBy: RankByEnum, $dtCodes: [String!]!, $targetPercentile: Int!, $statement1Codes: [String!], $statement1Code: String!, $statement2Codes: [String!], $statement2Code: String!, $statement3Codes: [String!], $statement3Code: String!, $improvementDtCode: String) {
  statement1Groups: insightsScoresByDataTypes(surveyUuid: $surveyUuid, dtCodes: $dtCodes, filters: $filters, rankBy: $rankBy, statementCodes: $statement1Codes) {
    ...GroupedScores
  }
  statement1Target: insightsTargetScore(surveyUuid: $surveyUuid, statementCode: $statement1Code, targetPercentile: $targetPercentile)
  statement2Groups: insightsScoresByDataTypes(surveyUuid: $surveyUuid, dtCodes: $dtCodes, filters: $filters, rankBy: $rankBy, statementCodes: $statement2Codes) {
    ...GroupedScores
  }
  statement2Target: insightsTargetScore(surveyUuid: $surveyUuid, statementCode: $statement2Code, targetPercentile: $targetPercentile)
  statement3Groups: insightsScoresByDataTypes(surveyUuid: $surveyUuid, dtCodes: $dtCodes, filters: $filters, rankBy: $rankBy, statementCodes: $statement3Codes) {
    ...GroupedScores
  }
  statement3Target: insightsTargetScore(surveyUuid: $surveyUuid, statementCode: $statement3Code, targetPercentile: $targetPercentile)
  insightsPotentialImprovementScore(surveyUuid: $surveyUuid, dtCode: $improvementDtCode, filters: $filters) {
    improvementScore
    improvedGroups {
      score
      name
    }
  }
}
    ${GroupedScoresFragmentDoc}`;

/**
 * __useInsightsResidentFocusAreasQuery__
 *
 * To run a query within a React component, call `useInsightsResidentFocusAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsResidentFocusAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsResidentFocusAreasQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      rankBy: // value for 'rankBy'
 *      dtCodes: // value for 'dtCodes'
 *      targetPercentile: // value for 'targetPercentile'
 *      statement1Codes: // value for 'statement1Codes'
 *      statement1Code: // value for 'statement1Code'
 *      statement2Codes: // value for 'statement2Codes'
 *      statement2Code: // value for 'statement2Code'
 *      statement3Codes: // value for 'statement3Codes'
 *      statement3Code: // value for 'statement3Code'
 *      improvementDtCode: // value for 'improvementDtCode'
 *   },
 * });
 */
export function useInsightsResidentFocusAreasQuery(baseOptions: Apollo.QueryHookOptions<InsightsResidentFocusAreasQuery, InsightsResidentFocusAreasQueryVariables>) {
        return Apollo.useQuery<InsightsResidentFocusAreasQuery, InsightsResidentFocusAreasQueryVariables>(InsightsResidentFocusAreasDocument, baseOptions);
      }
export function useInsightsResidentFocusAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsResidentFocusAreasQuery, InsightsResidentFocusAreasQueryVariables>) {
          return Apollo.useLazyQuery<InsightsResidentFocusAreasQuery, InsightsResidentFocusAreasQueryVariables>(InsightsResidentFocusAreasDocument, baseOptions);
        }
export type InsightsResidentFocusAreasQueryHookResult = ReturnType<typeof useInsightsResidentFocusAreasQuery>;
export type InsightsResidentFocusAreasLazyQueryHookResult = ReturnType<typeof useInsightsResidentFocusAreasLazyQuery>;
export type InsightsResidentFocusAreasQueryResult = Apollo.QueryResult<InsightsResidentFocusAreasQuery, InsightsResidentFocusAreasQueryVariables>;
export const InsightsResponseRateDocument = gql`
    query InsightsResponseRate($surveyUuid: String!, $byClient: Boolean!, $filters: [String!], $startDate: DateTime, $endDate: DateTime) {
  survey(surveyUuid: $surveyUuid) {
    minShowableResults
    insightsSurvey(filters: $filters, startDate: $startDate, endDate: $endDate) {
      responseRate(byClient: $byClient) {
        finished
        total
      }
      completionRate {
        started
        submitted
      }
    }
  }
}
    `;

/**
 * __useInsightsResponseRateQuery__
 *
 * To run a query within a React component, call `useInsightsResponseRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsResponseRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsResponseRateQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      byClient: // value for 'byClient'
 *      filters: // value for 'filters'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsResponseRateQuery(baseOptions: Apollo.QueryHookOptions<InsightsResponseRateQuery, InsightsResponseRateQueryVariables>) {
        return Apollo.useQuery<InsightsResponseRateQuery, InsightsResponseRateQueryVariables>(InsightsResponseRateDocument, baseOptions);
      }
export function useInsightsResponseRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsResponseRateQuery, InsightsResponseRateQueryVariables>) {
          return Apollo.useLazyQuery<InsightsResponseRateQuery, InsightsResponseRateQueryVariables>(InsightsResponseRateDocument, baseOptions);
        }
export type InsightsResponseRateQueryHookResult = ReturnType<typeof useInsightsResponseRateQuery>;
export type InsightsResponseRateLazyQueryHookResult = ReturnType<typeof useInsightsResponseRateLazyQuery>;
export type InsightsResponseRateQueryResult = Apollo.QueryResult<InsightsResponseRateQuery, InsightsResponseRateQueryVariables>;
export const InsightsResponseRateByFilterTypeDocument = gql`
    query InsightsResponseRateByFilterType($surveyUuid: String!, $filterTypeUuid: String!, $filters: [String!], $byClient: Boolean!) {
  responseRateByFilterType(surveyUuid: $surveyUuid, filterTypeUuid: $filterTypeUuid, filters: $filters, byClient: $byClient) {
    name
    rate
  }
}
    `;

/**
 * __useInsightsResponseRateByFilterTypeQuery__
 *
 * To run a query within a React component, call `useInsightsResponseRateByFilterTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsResponseRateByFilterTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsResponseRateByFilterTypeQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filterTypeUuid: // value for 'filterTypeUuid'
 *      filters: // value for 'filters'
 *      byClient: // value for 'byClient'
 *   },
 * });
 */
export function useInsightsResponseRateByFilterTypeQuery(baseOptions: Apollo.QueryHookOptions<InsightsResponseRateByFilterTypeQuery, InsightsResponseRateByFilterTypeQueryVariables>) {
        return Apollo.useQuery<InsightsResponseRateByFilterTypeQuery, InsightsResponseRateByFilterTypeQueryVariables>(InsightsResponseRateByFilterTypeDocument, baseOptions);
      }
export function useInsightsResponseRateByFilterTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsResponseRateByFilterTypeQuery, InsightsResponseRateByFilterTypeQueryVariables>) {
          return Apollo.useLazyQuery<InsightsResponseRateByFilterTypeQuery, InsightsResponseRateByFilterTypeQueryVariables>(InsightsResponseRateByFilterTypeDocument, baseOptions);
        }
export type InsightsResponseRateByFilterTypeQueryHookResult = ReturnType<typeof useInsightsResponseRateByFilterTypeQuery>;
export type InsightsResponseRateByFilterTypeLazyQueryHookResult = ReturnType<typeof useInsightsResponseRateByFilterTypeLazyQuery>;
export type InsightsResponseRateByFilterTypeQueryResult = Apollo.QueryResult<InsightsResponseRateByFilterTypeQuery, InsightsResponseRateByFilterTypeQueryVariables>;
export const InsightsResponseRateReportDocument = gql`
    query InsightsResponseRateReport($surveyUuid: String, $reportUuid: String, $password: String, $filters: [String!], $groupByFilterType1Code: String!, $groupByFilterType2Code: String!, $byClient: Boolean!, $skipCompletionRate: Boolean!, $skipResponseRate: Boolean!) {
  responseRateReport(surveyUuid: $surveyUuid, reportUuid: $reportUuid, password: $password, filters: $filters, groupByFilterType1Code: $groupByFilterType1Code, groupByFilterType2Code: $groupByFilterType2Code, byClient: $byClient) @skip(if: $skipResponseRate) {
    responseRate {
      total
      finished
      rate
      totalFilter1
    }
    rows {
      total
      finished
      rate
      filterValue1
      filterValue2
    }
    highlights {
      filterTypeName
      filterTypeUuid
      topFilterValueNames
      topRate
      highlightType
    }
  }
  completionRateReport(surveyUuid: $surveyUuid, reportUuid: $reportUuid, password: $password, filters: $filters, groupByFilterType1Code: $groupByFilterType1Code, groupByFilterType2Code: $groupByFilterType2Code) @skip(if: $skipCompletionRate) {
    completionRate {
      started
      submitted
      totalFilter1
    }
    rows {
      started
      submitted
      filterValue1
      filterValue2
    }
  }
  responsesByDate(surveyUuid: $surveyUuid, reportUuid: $reportUuid, password: $password, filters: $filters, byClient: $byClient) {
    date
    responses
  }
  survey: responseRateSurvey(surveyUuid: $surveyUuid, reportUuid: $reportUuid, password: $password) {
    uuid
    startDate
    endDate
    name
    type
    status
    reportShareUrl
    reportUuid
    targetResponsesNo
    isDefaultClientBasedResponseRateReport
    certificationEligibilityRate
    distributionType
  }
}
    `;

/**
 * __useInsightsResponseRateReportQuery__
 *
 * To run a query within a React component, call `useInsightsResponseRateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsResponseRateReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsResponseRateReportQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      reportUuid: // value for 'reportUuid'
 *      password: // value for 'password'
 *      filters: // value for 'filters'
 *      groupByFilterType1Code: // value for 'groupByFilterType1Code'
 *      groupByFilterType2Code: // value for 'groupByFilterType2Code'
 *      byClient: // value for 'byClient'
 *      skipCompletionRate: // value for 'skipCompletionRate'
 *      skipResponseRate: // value for 'skipResponseRate'
 *   },
 * });
 */
export function useInsightsResponseRateReportQuery(baseOptions: Apollo.QueryHookOptions<InsightsResponseRateReportQuery, InsightsResponseRateReportQueryVariables>) {
        return Apollo.useQuery<InsightsResponseRateReportQuery, InsightsResponseRateReportQueryVariables>(InsightsResponseRateReportDocument, baseOptions);
      }
export function useInsightsResponseRateReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsResponseRateReportQuery, InsightsResponseRateReportQueryVariables>) {
          return Apollo.useLazyQuery<InsightsResponseRateReportQuery, InsightsResponseRateReportQueryVariables>(InsightsResponseRateReportDocument, baseOptions);
        }
export type InsightsResponseRateReportQueryHookResult = ReturnType<typeof useInsightsResponseRateReportQuery>;
export type InsightsResponseRateReportLazyQueryHookResult = ReturnType<typeof useInsightsResponseRateReportLazyQuery>;
export type InsightsResponseRateReportQueryResult = Apollo.QueryResult<InsightsResponseRateReportQuery, InsightsResponseRateReportQueryVariables>;
export const InsightsResponseRateReportMetadataDocument = gql`
    query InsightsResponseRateReportMetadata($surveyUuid: String, $reportUuid: String, $password: String) {
  responseRateReportMetadata(surveyUuid: $surveyUuid, reportUuid: $reportUuid, password: $password) {
    surveyUuid
    isDefaultClientBasedResponseRateReport
    filterTypes {
      isValidByClient
      filterType {
        ...FilterType
      }
    }
    minShowableResults
    distributionType
  }
}
    ${FilterTypeFragmentDoc}`;

/**
 * __useInsightsResponseRateReportMetadataQuery__
 *
 * To run a query within a React component, call `useInsightsResponseRateReportMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsResponseRateReportMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsResponseRateReportMetadataQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      reportUuid: // value for 'reportUuid'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useInsightsResponseRateReportMetadataQuery(baseOptions?: Apollo.QueryHookOptions<InsightsResponseRateReportMetadataQuery, InsightsResponseRateReportMetadataQueryVariables>) {
        return Apollo.useQuery<InsightsResponseRateReportMetadataQuery, InsightsResponseRateReportMetadataQueryVariables>(InsightsResponseRateReportMetadataDocument, baseOptions);
      }
export function useInsightsResponseRateReportMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsResponseRateReportMetadataQuery, InsightsResponseRateReportMetadataQueryVariables>) {
          return Apollo.useLazyQuery<InsightsResponseRateReportMetadataQuery, InsightsResponseRateReportMetadataQueryVariables>(InsightsResponseRateReportMetadataDocument, baseOptions);
        }
export type InsightsResponseRateReportMetadataQueryHookResult = ReturnType<typeof useInsightsResponseRateReportMetadataQuery>;
export type InsightsResponseRateReportMetadataLazyQueryHookResult = ReturnType<typeof useInsightsResponseRateReportMetadataLazyQuery>;
export type InsightsResponseRateReportMetadataQueryResult = Apollo.QueryResult<InsightsResponseRateReportMetadataQuery, InsightsResponseRateReportMetadataQueryVariables>;
export const InsightsScoresByDataTypeDocument = gql`
    query InsightsScoresByDataType($surveyUuid: String!, $dtCode: String!, $filters: [String!], $statementCodes: [String!], $rankBy: RankByEnum, $includeAllSurveyChoices: Boolean) {
  insightsScoresByDataType(surveyUuid: $surveyUuid, dtCode: $dtCode, filters: $filters, statementCodes: $statementCodes, rankBy: $rankBy, includeAllSurveyChoices: $includeAllSurveyChoices) {
    label
    positive
    inconsistent
    negative
    count
    totalResponses
  }
}
    `;

/**
 * __useInsightsScoresByDataTypeQuery__
 *
 * To run a query within a React component, call `useInsightsScoresByDataTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsScoresByDataTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsScoresByDataTypeQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      dtCode: // value for 'dtCode'
 *      filters: // value for 'filters'
 *      statementCodes: // value for 'statementCodes'
 *      rankBy: // value for 'rankBy'
 *      includeAllSurveyChoices: // value for 'includeAllSurveyChoices'
 *   },
 * });
 */
export function useInsightsScoresByDataTypeQuery(baseOptions: Apollo.QueryHookOptions<InsightsScoresByDataTypeQuery, InsightsScoresByDataTypeQueryVariables>) {
        return Apollo.useQuery<InsightsScoresByDataTypeQuery, InsightsScoresByDataTypeQueryVariables>(InsightsScoresByDataTypeDocument, baseOptions);
      }
export function useInsightsScoresByDataTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsScoresByDataTypeQuery, InsightsScoresByDataTypeQueryVariables>) {
          return Apollo.useLazyQuery<InsightsScoresByDataTypeQuery, InsightsScoresByDataTypeQueryVariables>(InsightsScoresByDataTypeDocument, baseOptions);
        }
export type InsightsScoresByDataTypeQueryHookResult = ReturnType<typeof useInsightsScoresByDataTypeQuery>;
export type InsightsScoresByDataTypeLazyQueryHookResult = ReturnType<typeof useInsightsScoresByDataTypeLazyQuery>;
export type InsightsScoresByDataTypeQueryResult = Apollo.QueryResult<InsightsScoresByDataTypeQuery, InsightsScoresByDataTypeQueryVariables>;
export const InsightsScoresByDataTypeCombinedDocument = gql`
    query InsightsScoresByDataTypeCombined($surveyUuid: String!, $dtCode: String!, $dtCodeHierarchy: [String!]!, $filters: [String!], $statementCodes: [String!], $rankBy: RankByEnum, $includeAllSurveyChoices: Boolean) {
  insightsScoresByDataType(surveyUuid: $surveyUuid, dtCode: $dtCode, filters: $filters, statementCodes: $statementCodes, rankBy: $rankBy, includeAllSurveyChoices: $includeAllSurveyChoices) {
    label
    positive
    inconsistent
    negative
    count
  }
  insightsScoresByDataTypeHierarchy(surveyUuid: $surveyUuid, dtCodeHierarchy: $dtCodeHierarchy, filters: $filters, statementCodes: $statementCodes, rankBy: $rankBy, includeAllSurveyChoices: $includeAllSurveyChoices)
}
    `;

/**
 * __useInsightsScoresByDataTypeCombinedQuery__
 *
 * To run a query within a React component, call `useInsightsScoresByDataTypeCombinedQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsScoresByDataTypeCombinedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsScoresByDataTypeCombinedQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      dtCode: // value for 'dtCode'
 *      dtCodeHierarchy: // value for 'dtCodeHierarchy'
 *      filters: // value for 'filters'
 *      statementCodes: // value for 'statementCodes'
 *      rankBy: // value for 'rankBy'
 *      includeAllSurveyChoices: // value for 'includeAllSurveyChoices'
 *   },
 * });
 */
export function useInsightsScoresByDataTypeCombinedQuery(baseOptions: Apollo.QueryHookOptions<InsightsScoresByDataTypeCombinedQuery, InsightsScoresByDataTypeCombinedQueryVariables>) {
        return Apollo.useQuery<InsightsScoresByDataTypeCombinedQuery, InsightsScoresByDataTypeCombinedQueryVariables>(InsightsScoresByDataTypeCombinedDocument, baseOptions);
      }
export function useInsightsScoresByDataTypeCombinedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsScoresByDataTypeCombinedQuery, InsightsScoresByDataTypeCombinedQueryVariables>) {
          return Apollo.useLazyQuery<InsightsScoresByDataTypeCombinedQuery, InsightsScoresByDataTypeCombinedQueryVariables>(InsightsScoresByDataTypeCombinedDocument, baseOptions);
        }
export type InsightsScoresByDataTypeCombinedQueryHookResult = ReturnType<typeof useInsightsScoresByDataTypeCombinedQuery>;
export type InsightsScoresByDataTypeCombinedLazyQueryHookResult = ReturnType<typeof useInsightsScoresByDataTypeCombinedLazyQuery>;
export type InsightsScoresByDataTypeCombinedQueryResult = Apollo.QueryResult<InsightsScoresByDataTypeCombinedQuery, InsightsScoresByDataTypeCombinedQueryVariables>;
export const InsightsScoresByDataTypeHierarchyDocument = gql`
    query InsightsScoresByDataTypeHierarchy($surveyUuid: String!, $dtCodeHierarchy: [String!]!, $filters: [String!], $statementCodes: [String!], $rankBy: RankByEnum, $includeAllSurveyChoices: Boolean) {
  insightsScoresByDataTypeHierarchy(surveyUuid: $surveyUuid, dtCodeHierarchy: $dtCodeHierarchy, filters: $filters, statementCodes: $statementCodes, rankBy: $rankBy, includeAllSurveyChoices: $includeAllSurveyChoices)
}
    `;

/**
 * __useInsightsScoresByDataTypeHierarchyQuery__
 *
 * To run a query within a React component, call `useInsightsScoresByDataTypeHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsScoresByDataTypeHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsScoresByDataTypeHierarchyQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      dtCodeHierarchy: // value for 'dtCodeHierarchy'
 *      filters: // value for 'filters'
 *      statementCodes: // value for 'statementCodes'
 *      rankBy: // value for 'rankBy'
 *      includeAllSurveyChoices: // value for 'includeAllSurveyChoices'
 *   },
 * });
 */
export function useInsightsScoresByDataTypeHierarchyQuery(baseOptions: Apollo.QueryHookOptions<InsightsScoresByDataTypeHierarchyQuery, InsightsScoresByDataTypeHierarchyQueryVariables>) {
        return Apollo.useQuery<InsightsScoresByDataTypeHierarchyQuery, InsightsScoresByDataTypeHierarchyQueryVariables>(InsightsScoresByDataTypeHierarchyDocument, baseOptions);
      }
export function useInsightsScoresByDataTypeHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsScoresByDataTypeHierarchyQuery, InsightsScoresByDataTypeHierarchyQueryVariables>) {
          return Apollo.useLazyQuery<InsightsScoresByDataTypeHierarchyQuery, InsightsScoresByDataTypeHierarchyQueryVariables>(InsightsScoresByDataTypeHierarchyDocument, baseOptions);
        }
export type InsightsScoresByDataTypeHierarchyQueryHookResult = ReturnType<typeof useInsightsScoresByDataTypeHierarchyQuery>;
export type InsightsScoresByDataTypeHierarchyLazyQueryHookResult = ReturnType<typeof useInsightsScoresByDataTypeHierarchyLazyQuery>;
export type InsightsScoresByDataTypeHierarchyQueryResult = Apollo.QueryResult<InsightsScoresByDataTypeHierarchyQuery, InsightsScoresByDataTypeHierarchyQueryVariables>;
export const InsightsSnapshotOverviewDocument = gql`
    query InsightsSnapshotOverview($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $skipNpsScore: Boolean!) {
  insightsOverallIndex(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark) {
    ...InsightsOverallScore
  }
  insightsStatements(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark) {
    ...InsightsStatementScore
  }
  insightsNpsGroupScores(surveyUuid: $surveyUuid, filters: $filters) @skip(if: $skipNpsScore) {
    promoters
    passives
    detractors
    label
    count
  }
}
    ${InsightsOverallScoreFragmentDoc}
${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsSnapshotOverviewQuery__
 *
 * To run a query within a React component, call `useInsightsSnapshotOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsSnapshotOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsSnapshotOverviewQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      skipNpsScore: // value for 'skipNpsScore'
 *   },
 * });
 */
export function useInsightsSnapshotOverviewQuery(baseOptions: Apollo.QueryHookOptions<InsightsSnapshotOverviewQuery, InsightsSnapshotOverviewQueryVariables>) {
        return Apollo.useQuery<InsightsSnapshotOverviewQuery, InsightsSnapshotOverviewQueryVariables>(InsightsSnapshotOverviewDocument, baseOptions);
      }
export function useInsightsSnapshotOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsSnapshotOverviewQuery, InsightsSnapshotOverviewQueryVariables>) {
          return Apollo.useLazyQuery<InsightsSnapshotOverviewQuery, InsightsSnapshotOverviewQueryVariables>(InsightsSnapshotOverviewDocument, baseOptions);
        }
export type InsightsSnapshotOverviewQueryHookResult = ReturnType<typeof useInsightsSnapshotOverviewQuery>;
export type InsightsSnapshotOverviewLazyQueryHookResult = ReturnType<typeof useInsightsSnapshotOverviewLazyQuery>;
export type InsightsSnapshotOverviewQueryResult = Apollo.QueryResult<InsightsSnapshotOverviewQuery, InsightsSnapshotOverviewQueryVariables>;
export const InsightsStatementsDocument = gql`
    query InsightsStatements($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $statementCodes: [String!], $rankBy: RankByEnum, $limit: Int, $includeCustom: Boolean, $startDate: DateTime, $endDate: DateTime) {
  statements: insightsStatements(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark, statementCodes: $statementCodes, rankBy: $rankBy, limit: $limit, includeCustom: $includeCustom, startDate: $startDate, endDate: $endDate) {
    ...InsightsStatementScore
  }
}
    ${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsStatementsQuery__
 *
 * To run a query within a React component, call `useInsightsStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsStatementsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      statementCodes: // value for 'statementCodes'
 *      rankBy: // value for 'rankBy'
 *      limit: // value for 'limit'
 *      includeCustom: // value for 'includeCustom'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsStatementsQuery(baseOptions: Apollo.QueryHookOptions<InsightsStatementsQuery, InsightsStatementsQueryVariables>) {
        return Apollo.useQuery<InsightsStatementsQuery, InsightsStatementsQueryVariables>(InsightsStatementsDocument, baseOptions);
      }
export function useInsightsStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsStatementsQuery, InsightsStatementsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsStatementsQuery, InsightsStatementsQueryVariables>(InsightsStatementsDocument, baseOptions);
        }
export type InsightsStatementsQueryHookResult = ReturnType<typeof useInsightsStatementsQuery>;
export type InsightsStatementsLazyQueryHookResult = ReturnType<typeof useInsightsStatementsLazyQuery>;
export type InsightsStatementsQueryResult = Apollo.QueryResult<InsightsStatementsQuery, InsightsStatementsQueryVariables>;
export const InsightsStatementsByDataTypesDocument = gql`
    query InsightsStatementsByDataTypes($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $dataTypes: [DataTypeGroupsInputType!]!, $includeCustom: Boolean) {
  insightsStatementsByDataTypes(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark, dataTypes: $dataTypes, includeCustom: $includeCustom) {
    statements {
      ...InsightsStatementScore
    }
    group
  }
}
    ${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsStatementsByDataTypesQuery__
 *
 * To run a query within a React component, call `useInsightsStatementsByDataTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsStatementsByDataTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsStatementsByDataTypesQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      dataTypes: // value for 'dataTypes'
 *      includeCustom: // value for 'includeCustom'
 *   },
 * });
 */
export function useInsightsStatementsByDataTypesQuery(baseOptions: Apollo.QueryHookOptions<InsightsStatementsByDataTypesQuery, InsightsStatementsByDataTypesQueryVariables>) {
        return Apollo.useQuery<InsightsStatementsByDataTypesQuery, InsightsStatementsByDataTypesQueryVariables>(InsightsStatementsByDataTypesDocument, baseOptions);
      }
export function useInsightsStatementsByDataTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsStatementsByDataTypesQuery, InsightsStatementsByDataTypesQueryVariables>) {
          return Apollo.useLazyQuery<InsightsStatementsByDataTypesQuery, InsightsStatementsByDataTypesQueryVariables>(InsightsStatementsByDataTypesDocument, baseOptions);
        }
export type InsightsStatementsByDataTypesQueryHookResult = ReturnType<typeof useInsightsStatementsByDataTypesQuery>;
export type InsightsStatementsByDataTypesLazyQueryHookResult = ReturnType<typeof useInsightsStatementsByDataTypesLazyQuery>;
export type InsightsStatementsByDataTypesQueryResult = Apollo.QueryResult<InsightsStatementsByDataTypesQuery, InsightsStatementsByDataTypesQueryVariables>;
export const InsightsStatementsCommentsDocument = gql`
    query InsightsStatementsComments($surveyUuid: String!, $questionCode: String!, $filters: [String!], $pageSize: Int!, $page: Int!) {
  comments: insightsComments(surveyUuid: $surveyUuid, questionCode: $questionCode, filters: $filters, pageSize: $pageSize, page: $page) {
    totalComments
    question {
      textDisplay
    }
    comments {
      text
    }
  }
}
    `;

/**
 * __useInsightsStatementsCommentsQuery__
 *
 * To run a query within a React component, call `useInsightsStatementsCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsStatementsCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsStatementsCommentsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      questionCode: // value for 'questionCode'
 *      filters: // value for 'filters'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useInsightsStatementsCommentsQuery(baseOptions: Apollo.QueryHookOptions<InsightsStatementsCommentsQuery, InsightsStatementsCommentsQueryVariables>) {
        return Apollo.useQuery<InsightsStatementsCommentsQuery, InsightsStatementsCommentsQueryVariables>(InsightsStatementsCommentsDocument, baseOptions);
      }
export function useInsightsStatementsCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsStatementsCommentsQuery, InsightsStatementsCommentsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsStatementsCommentsQuery, InsightsStatementsCommentsQueryVariables>(InsightsStatementsCommentsDocument, baseOptions);
        }
export type InsightsStatementsCommentsQueryHookResult = ReturnType<typeof useInsightsStatementsCommentsQuery>;
export type InsightsStatementsCommentsLazyQueryHookResult = ReturnType<typeof useInsightsStatementsCommentsLazyQuery>;
export type InsightsStatementsCommentsQueryResult = Apollo.QueryResult<InsightsStatementsCommentsQuery, InsightsStatementsCommentsQueryVariables>;
export const InsightsStatementsWithComparisonsDocument = gql`
    query InsightsStatementsWithComparisons($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $comparisonDataTypes: [DataTypeGroupsInputType!]!, $skipFilteredStatements: Boolean!, $skipComparisonStatements: Boolean!, $includeCustom: Boolean, $startDate: DateTime, $endDate: DateTime) {
  statements: insightsStatements(surveyUuid: $surveyUuid, benchmark: $benchmark, includeCustom: $includeCustom, startDate: $startDate, endDate: $endDate) {
    uuid
    ...InsightsStatementScore
  }
  filteredStatements: insightsStatements(surveyUuid: $surveyUuid, filters: $filters, includeCustom: $includeCustom, startDate: $startDate, endDate: $endDate) @skip(if: $skipFilteredStatements) {
    ...InsightsStatementScore
  }
  comparisonStatements: insightsStatementsByDataTypes(surveyUuid: $surveyUuid, filters: $filters, dataTypes: $comparisonDataTypes, includeCustom: $includeCustom, startDate: $startDate, endDate: $endDate) @skip(if: $skipComparisonStatements) {
    group
    statements {
      ...InsightsStatementScore
    }
  }
}
    ${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsStatementsWithComparisonsQuery__
 *
 * To run a query within a React component, call `useInsightsStatementsWithComparisonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsStatementsWithComparisonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsStatementsWithComparisonsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      comparisonDataTypes: // value for 'comparisonDataTypes'
 *      skipFilteredStatements: // value for 'skipFilteredStatements'
 *      skipComparisonStatements: // value for 'skipComparisonStatements'
 *      includeCustom: // value for 'includeCustom'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsStatementsWithComparisonsQuery(baseOptions: Apollo.QueryHookOptions<InsightsStatementsWithComparisonsQuery, InsightsStatementsWithComparisonsQueryVariables>) {
        return Apollo.useQuery<InsightsStatementsWithComparisonsQuery, InsightsStatementsWithComparisonsQueryVariables>(InsightsStatementsWithComparisonsDocument, baseOptions);
      }
export function useInsightsStatementsWithComparisonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsStatementsWithComparisonsQuery, InsightsStatementsWithComparisonsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsStatementsWithComparisonsQuery, InsightsStatementsWithComparisonsQueryVariables>(InsightsStatementsWithComparisonsDocument, baseOptions);
        }
export type InsightsStatementsWithComparisonsQueryHookResult = ReturnType<typeof useInsightsStatementsWithComparisonsQuery>;
export type InsightsStatementsWithComparisonsLazyQueryHookResult = ReturnType<typeof useInsightsStatementsWithComparisonsLazyQuery>;
export type InsightsStatementsWithComparisonsQueryResult = Apollo.QueryResult<InsightsStatementsWithComparisonsQuery, InsightsStatementsWithComparisonsQueryVariables>;
export const InsightsStatementsWithFiltersDocument = gql`
    query InsightsStatementsWithFilters($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $skipFilteredStatements: Boolean!, $includeCustom: Boolean) {
  statements: insightsStatements(surveyUuid: $surveyUuid, benchmark: $benchmark, includeCustom: $includeCustom) {
    uuid
    ...InsightsStatementScore
  }
  filteredStatements: insightsStatements(surveyUuid: $surveyUuid, filters: $filters, includeCustom: $includeCustom) @skip(if: $skipFilteredStatements) {
    ...InsightsStatementScore
  }
}
    ${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsStatementsWithFiltersQuery__
 *
 * To run a query within a React component, call `useInsightsStatementsWithFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsStatementsWithFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsStatementsWithFiltersQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      skipFilteredStatements: // value for 'skipFilteredStatements'
 *      includeCustom: // value for 'includeCustom'
 *   },
 * });
 */
export function useInsightsStatementsWithFiltersQuery(baseOptions: Apollo.QueryHookOptions<InsightsStatementsWithFiltersQuery, InsightsStatementsWithFiltersQueryVariables>) {
        return Apollo.useQuery<InsightsStatementsWithFiltersQuery, InsightsStatementsWithFiltersQueryVariables>(InsightsStatementsWithFiltersDocument, baseOptions);
      }
export function useInsightsStatementsWithFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsStatementsWithFiltersQuery, InsightsStatementsWithFiltersQueryVariables>) {
          return Apollo.useLazyQuery<InsightsStatementsWithFiltersQuery, InsightsStatementsWithFiltersQueryVariables>(InsightsStatementsWithFiltersDocument, baseOptions);
        }
export type InsightsStatementsWithFiltersQueryHookResult = ReturnType<typeof useInsightsStatementsWithFiltersQuery>;
export type InsightsStatementsWithFiltersLazyQueryHookResult = ReturnType<typeof useInsightsStatementsWithFiltersLazyQuery>;
export type InsightsStatementsWithFiltersQueryResult = Apollo.QueryResult<InsightsStatementsWithFiltersQuery, InsightsStatementsWithFiltersQueryVariables>;
export const InsightsSurveyDocument = gql`
    query InsightsSurvey($surveyUuid: String!) {
  survey(surveyUuid: $surveyUuid) {
    ...SurveyCore
    description
    isDefaultClientBasedResponseRateReport
    defaultBenchmark {
      ...Benchmark
    }
    insightsActionPlanPdfUrl
    includesTestimonials
    includesLonelinessQuestions
    includesNpsQuestion
    includesLeftReasonQuestion
    hasCoreQRecommendResponses
    startDate
    reportShareUrl
    associatedSurvey {
      uuid
      name
      endDate
    }
    filterTypeUuids
    includeForUsNews
    distributionType
    isRecurring
    reasonsForLeaving {
      value
      isVoluntary
    }
  }
}
    ${SurveyCoreFragmentDoc}
${BenchmarkFragmentDoc}`;

/**
 * __useInsightsSurveyQuery__
 *
 * To run a query within a React component, call `useInsightsSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsSurveyQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useInsightsSurveyQuery(baseOptions: Apollo.QueryHookOptions<InsightsSurveyQuery, InsightsSurveyQueryVariables>) {
        return Apollo.useQuery<InsightsSurveyQuery, InsightsSurveyQueryVariables>(InsightsSurveyDocument, baseOptions);
      }
export function useInsightsSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsSurveyQuery, InsightsSurveyQueryVariables>) {
          return Apollo.useLazyQuery<InsightsSurveyQuery, InsightsSurveyQueryVariables>(InsightsSurveyDocument, baseOptions);
        }
export type InsightsSurveyQueryHookResult = ReturnType<typeof useInsightsSurveyQuery>;
export type InsightsSurveyLazyQueryHookResult = ReturnType<typeof useInsightsSurveyLazyQuery>;
export type InsightsSurveyQueryResult = Apollo.QueryResult<InsightsSurveyQuery, InsightsSurveyQueryVariables>;
export const InsightsSurveysFilterValuesDocument = gql`
    query insightsSurveysFilterValues($surveyUuids: [String!]!, $filters: [String!], $forDtCode: DataTypeCode) {
  insightsSurveysFilterValues(surveyUuids: $surveyUuids, filters: $filters, forDtCode: $forDtCode) {
    dtCode
    groups
  }
}
    `;

/**
 * __useInsightsSurveysFilterValuesQuery__
 *
 * To run a query within a React component, call `useInsightsSurveysFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsSurveysFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsSurveysFilterValuesQuery({
 *   variables: {
 *      surveyUuids: // value for 'surveyUuids'
 *      filters: // value for 'filters'
 *      forDtCode: // value for 'forDtCode'
 *   },
 * });
 */
export function useInsightsSurveysFilterValuesQuery(baseOptions: Apollo.QueryHookOptions<InsightsSurveysFilterValuesQuery, InsightsSurveysFilterValuesQueryVariables>) {
        return Apollo.useQuery<InsightsSurveysFilterValuesQuery, InsightsSurveysFilterValuesQueryVariables>(InsightsSurveysFilterValuesDocument, baseOptions);
      }
export function useInsightsSurveysFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsSurveysFilterValuesQuery, InsightsSurveysFilterValuesQueryVariables>) {
          return Apollo.useLazyQuery<InsightsSurveysFilterValuesQuery, InsightsSurveysFilterValuesQueryVariables>(InsightsSurveysFilterValuesDocument, baseOptions);
        }
export type InsightsSurveysFilterValuesQueryHookResult = ReturnType<typeof useInsightsSurveysFilterValuesQuery>;
export type InsightsSurveysFilterValuesLazyQueryHookResult = ReturnType<typeof useInsightsSurveysFilterValuesLazyQuery>;
export type InsightsSurveysFilterValuesQueryResult = Apollo.QueryResult<InsightsSurveysFilterValuesQuery, InsightsSurveysFilterValuesQueryVariables>;
export const InsightsSurveysDocument = gql`
    query InsightsSurveys($surveyProductType: SurveyProductTypeEnum) {
  surveys(surveyProductType: $surveyProductType) {
    edges {
      node {
        ...SurveyCore
        hasLessThanMinShowableResults
        includesLonelinessQuestions
      }
    }
  }
}
    ${SurveyCoreFragmentDoc}`;

/**
 * __useInsightsSurveysQuery__
 *
 * To run a query within a React component, call `useInsightsSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsSurveysQuery({
 *   variables: {
 *      surveyProductType: // value for 'surveyProductType'
 *   },
 * });
 */
export function useInsightsSurveysQuery(baseOptions?: Apollo.QueryHookOptions<InsightsSurveysQuery, InsightsSurveysQueryVariables>) {
        return Apollo.useQuery<InsightsSurveysQuery, InsightsSurveysQueryVariables>(InsightsSurveysDocument, baseOptions);
      }
export function useInsightsSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsSurveysQuery, InsightsSurveysQueryVariables>) {
          return Apollo.useLazyQuery<InsightsSurveysQuery, InsightsSurveysQueryVariables>(InsightsSurveysDocument, baseOptions);
        }
export type InsightsSurveysQueryHookResult = ReturnType<typeof useInsightsSurveysQuery>;
export type InsightsSurveysLazyQueryHookResult = ReturnType<typeof useInsightsSurveysLazyQuery>;
export type InsightsSurveysQueryResult = Apollo.QueryResult<InsightsSurveysQuery, InsightsSurveysQueryVariables>;
export const InsightsTakeActionStepsDocument = gql`
    query InsightsTakeActionSteps($surveyUuid: String!) {
  survey(surveyUuid: $surveyUuid) {
    uuid
    takeActionCheckedSteps
  }
}
    `;

/**
 * __useInsightsTakeActionStepsQuery__
 *
 * To run a query within a React component, call `useInsightsTakeActionStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsTakeActionStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsTakeActionStepsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useInsightsTakeActionStepsQuery(baseOptions: Apollo.QueryHookOptions<InsightsTakeActionStepsQuery, InsightsTakeActionStepsQueryVariables>) {
        return Apollo.useQuery<InsightsTakeActionStepsQuery, InsightsTakeActionStepsQueryVariables>(InsightsTakeActionStepsDocument, baseOptions);
      }
export function useInsightsTakeActionStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsTakeActionStepsQuery, InsightsTakeActionStepsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsTakeActionStepsQuery, InsightsTakeActionStepsQueryVariables>(InsightsTakeActionStepsDocument, baseOptions);
        }
export type InsightsTakeActionStepsQueryHookResult = ReturnType<typeof useInsightsTakeActionStepsQuery>;
export type InsightsTakeActionStepsLazyQueryHookResult = ReturnType<typeof useInsightsTakeActionStepsLazyQuery>;
export type InsightsTakeActionStepsQueryResult = Apollo.QueryResult<InsightsTakeActionStepsQuery, InsightsTakeActionStepsQueryVariables>;
export const InsightsTestimonialsMediaUrlDocument = gql`
    query InsightsTestimonialsMediaUrl {
  insightsTestimonialsMediaUrl
}
    `;

/**
 * __useInsightsTestimonialsMediaUrlQuery__
 *
 * To run a query within a React component, call `useInsightsTestimonialsMediaUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsTestimonialsMediaUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsTestimonialsMediaUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsightsTestimonialsMediaUrlQuery(baseOptions?: Apollo.QueryHookOptions<InsightsTestimonialsMediaUrlQuery, InsightsTestimonialsMediaUrlQueryVariables>) {
        return Apollo.useQuery<InsightsTestimonialsMediaUrlQuery, InsightsTestimonialsMediaUrlQueryVariables>(InsightsTestimonialsMediaUrlDocument, baseOptions);
      }
export function useInsightsTestimonialsMediaUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsTestimonialsMediaUrlQuery, InsightsTestimonialsMediaUrlQueryVariables>) {
          return Apollo.useLazyQuery<InsightsTestimonialsMediaUrlQuery, InsightsTestimonialsMediaUrlQueryVariables>(InsightsTestimonialsMediaUrlDocument, baseOptions);
        }
export type InsightsTestimonialsMediaUrlQueryHookResult = ReturnType<typeof useInsightsTestimonialsMediaUrlQuery>;
export type InsightsTestimonialsMediaUrlLazyQueryHookResult = ReturnType<typeof useInsightsTestimonialsMediaUrlLazyQuery>;
export type InsightsTestimonialsMediaUrlQueryResult = Apollo.QueryResult<InsightsTestimonialsMediaUrlQuery, InsightsTestimonialsMediaUrlQueryVariables>;
export const InsightsTimeTrendingCompletionRateDocument = gql`
    query InsightsTimeTrendingCompletionRate($surveyUuid: String!, $filters: [String!], $startDate: DateTime, $endDate: DateTime) {
  survey(surveyUuid: $surveyUuid) {
    insightsSurvey(filters: $filters, startDate: $startDate, endDate: $endDate) {
      completionRate {
        started
        submitted
      }
    }
  }
}
    `;

/**
 * __useInsightsTimeTrendingCompletionRateQuery__
 *
 * To run a query within a React component, call `useInsightsTimeTrendingCompletionRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsTimeTrendingCompletionRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsTimeTrendingCompletionRateQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsTimeTrendingCompletionRateQuery(baseOptions: Apollo.QueryHookOptions<InsightsTimeTrendingCompletionRateQuery, InsightsTimeTrendingCompletionRateQueryVariables>) {
        return Apollo.useQuery<InsightsTimeTrendingCompletionRateQuery, InsightsTimeTrendingCompletionRateQueryVariables>(InsightsTimeTrendingCompletionRateDocument, baseOptions);
      }
export function useInsightsTimeTrendingCompletionRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsTimeTrendingCompletionRateQuery, InsightsTimeTrendingCompletionRateQueryVariables>) {
          return Apollo.useLazyQuery<InsightsTimeTrendingCompletionRateQuery, InsightsTimeTrendingCompletionRateQueryVariables>(InsightsTimeTrendingCompletionRateDocument, baseOptions);
        }
export type InsightsTimeTrendingCompletionRateQueryHookResult = ReturnType<typeof useInsightsTimeTrendingCompletionRateQuery>;
export type InsightsTimeTrendingCompletionRateLazyQueryHookResult = ReturnType<typeof useInsightsTimeTrendingCompletionRateLazyQuery>;
export type InsightsTimeTrendingCompletionRateQueryResult = Apollo.QueryResult<InsightsTimeTrendingCompletionRateQuery, InsightsTimeTrendingCompletionRateQueryVariables>;
export const InsightsTimeTrendingDimensionsDocument = gql`
    query InsightsTimeTrendingDimensions($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput) {
  overallIndex: insightsOverallIndex(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark) {
    ...InsightsOverallScore
  }
  dimensions: insightsDimensionsOverview(surveyUuid: $surveyUuid, filters: $filters) {
    ...InsightsGroupedScore
  }
}
    ${InsightsOverallScoreFragmentDoc}
${InsightsGroupedScoreFragmentDoc}`;

/**
 * __useInsightsTimeTrendingDimensionsQuery__
 *
 * To run a query within a React component, call `useInsightsTimeTrendingDimensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsTimeTrendingDimensionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsTimeTrendingDimensionsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *   },
 * });
 */
export function useInsightsTimeTrendingDimensionsQuery(baseOptions: Apollo.QueryHookOptions<InsightsTimeTrendingDimensionsQuery, InsightsTimeTrendingDimensionsQueryVariables>) {
        return Apollo.useQuery<InsightsTimeTrendingDimensionsQuery, InsightsTimeTrendingDimensionsQueryVariables>(InsightsTimeTrendingDimensionsDocument, baseOptions);
      }
export function useInsightsTimeTrendingDimensionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsTimeTrendingDimensionsQuery, InsightsTimeTrendingDimensionsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsTimeTrendingDimensionsQuery, InsightsTimeTrendingDimensionsQueryVariables>(InsightsTimeTrendingDimensionsDocument, baseOptions);
        }
export type InsightsTimeTrendingDimensionsQueryHookResult = ReturnType<typeof useInsightsTimeTrendingDimensionsQuery>;
export type InsightsTimeTrendingDimensionsLazyQueryHookResult = ReturnType<typeof useInsightsTimeTrendingDimensionsLazyQuery>;
export type InsightsTimeTrendingDimensionsQueryResult = Apollo.QueryResult<InsightsTimeTrendingDimensionsQuery, InsightsTimeTrendingDimensionsQueryVariables>;
export const InsightsTimeTrendingScoresByDataTypeDocument = gql`
    query InsightsTimeTrendingScoresByDataType($surveyUuid: String!, $dtCode: String!, $filters: [String!], $statementCodes: [String!], $benchmark: BenchmarkNodeInput, $startDate: DateTime, $endDate: DateTime) {
  insightsScoresByDataType(surveyUuid: $surveyUuid, dtCode: $dtCode, filters: $filters, statementCodes: $statementCodes, startDate: $startDate, endDate: $endDate) {
    label
    positive
    inconsistent
    negative
    count
    invitedCount
  }
  overallIndex: insightsOverallIndex(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark, statementCodes: $statementCodes, startDate: $startDate, endDate: $endDate) {
    ...InsightsOverallScore
  }
}
    ${InsightsOverallScoreFragmentDoc}`;

/**
 * __useInsightsTimeTrendingScoresByDataTypeQuery__
 *
 * To run a query within a React component, call `useInsightsTimeTrendingScoresByDataTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsTimeTrendingScoresByDataTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsTimeTrendingScoresByDataTypeQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      dtCode: // value for 'dtCode'
 *      filters: // value for 'filters'
 *      statementCodes: // value for 'statementCodes'
 *      benchmark: // value for 'benchmark'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsTimeTrendingScoresByDataTypeQuery(baseOptions: Apollo.QueryHookOptions<InsightsTimeTrendingScoresByDataTypeQuery, InsightsTimeTrendingScoresByDataTypeQueryVariables>) {
        return Apollo.useQuery<InsightsTimeTrendingScoresByDataTypeQuery, InsightsTimeTrendingScoresByDataTypeQueryVariables>(InsightsTimeTrendingScoresByDataTypeDocument, baseOptions);
      }
export function useInsightsTimeTrendingScoresByDataTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsTimeTrendingScoresByDataTypeQuery, InsightsTimeTrendingScoresByDataTypeQueryVariables>) {
          return Apollo.useLazyQuery<InsightsTimeTrendingScoresByDataTypeQuery, InsightsTimeTrendingScoresByDataTypeQueryVariables>(InsightsTimeTrendingScoresByDataTypeDocument, baseOptions);
        }
export type InsightsTimeTrendingScoresByDataTypeQueryHookResult = ReturnType<typeof useInsightsTimeTrendingScoresByDataTypeQuery>;
export type InsightsTimeTrendingScoresByDataTypeLazyQueryHookResult = ReturnType<typeof useInsightsTimeTrendingScoresByDataTypeLazyQuery>;
export type InsightsTimeTrendingScoresByDataTypeQueryResult = Apollo.QueryResult<InsightsTimeTrendingScoresByDataTypeQuery, InsightsTimeTrendingScoresByDataTypeQueryVariables>;
export const InsightsTimeTrendingSnapshotOverviewDocument = gql`
    query InsightsTimeTrendingSnapshotOverview($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $statementCodes: [String!]) {
  overallIndex: insightsOverallIndex(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark, statementCodes: $statementCodes) {
    ...InsightsOverallScore
  }
  survey(surveyUuid: $surveyUuid) {
    responseRate {
      ...ResponseRate
    }
  }
}
    ${InsightsOverallScoreFragmentDoc}
${ResponseRateFragmentDoc}`;

/**
 * __useInsightsTimeTrendingSnapshotOverviewQuery__
 *
 * To run a query within a React component, call `useInsightsTimeTrendingSnapshotOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsTimeTrendingSnapshotOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsTimeTrendingSnapshotOverviewQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      statementCodes: // value for 'statementCodes'
 *   },
 * });
 */
export function useInsightsTimeTrendingSnapshotOverviewQuery(baseOptions: Apollo.QueryHookOptions<InsightsTimeTrendingSnapshotOverviewQuery, InsightsTimeTrendingSnapshotOverviewQueryVariables>) {
        return Apollo.useQuery<InsightsTimeTrendingSnapshotOverviewQuery, InsightsTimeTrendingSnapshotOverviewQueryVariables>(InsightsTimeTrendingSnapshotOverviewDocument, baseOptions);
      }
export function useInsightsTimeTrendingSnapshotOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsTimeTrendingSnapshotOverviewQuery, InsightsTimeTrendingSnapshotOverviewQueryVariables>) {
          return Apollo.useLazyQuery<InsightsTimeTrendingSnapshotOverviewQuery, InsightsTimeTrendingSnapshotOverviewQueryVariables>(InsightsTimeTrendingSnapshotOverviewDocument, baseOptions);
        }
export type InsightsTimeTrendingSnapshotOverviewQueryHookResult = ReturnType<typeof useInsightsTimeTrendingSnapshotOverviewQuery>;
export type InsightsTimeTrendingSnapshotOverviewLazyQueryHookResult = ReturnType<typeof useInsightsTimeTrendingSnapshotOverviewLazyQuery>;
export type InsightsTimeTrendingSnapshotOverviewQueryResult = Apollo.QueryResult<InsightsTimeTrendingSnapshotOverviewQuery, InsightsTimeTrendingSnapshotOverviewQueryVariables>;
export const InsightsTimeTrendingStatementsDocument = gql`
    query InsightsTimeTrendingStatements($surveyUuid: String!, $filters: [String!], $benchmark: BenchmarkNodeInput, $startDate: DateTime, $endDate: DateTime) {
  overallIndex: insightsOverallIndex(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark, startDate: $startDate, endDate: $endDate) {
    ...InsightsOverallScore
  }
  statements: insightsStatements(surveyUuid: $surveyUuid, filters: $filters, benchmark: $benchmark, startDate: $startDate, endDate: $endDate) {
    ...InsightsStatementScore
  }
}
    ${InsightsOverallScoreFragmentDoc}
${InsightsStatementScoreFragmentDoc}`;

/**
 * __useInsightsTimeTrendingStatementsQuery__
 *
 * To run a query within a React component, call `useInsightsTimeTrendingStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsTimeTrendingStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsTimeTrendingStatementsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      filters: // value for 'filters'
 *      benchmark: // value for 'benchmark'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInsightsTimeTrendingStatementsQuery(baseOptions: Apollo.QueryHookOptions<InsightsTimeTrendingStatementsQuery, InsightsTimeTrendingStatementsQueryVariables>) {
        return Apollo.useQuery<InsightsTimeTrendingStatementsQuery, InsightsTimeTrendingStatementsQueryVariables>(InsightsTimeTrendingStatementsDocument, baseOptions);
      }
export function useInsightsTimeTrendingStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsTimeTrendingStatementsQuery, InsightsTimeTrendingStatementsQueryVariables>) {
          return Apollo.useLazyQuery<InsightsTimeTrendingStatementsQuery, InsightsTimeTrendingStatementsQueryVariables>(InsightsTimeTrendingStatementsDocument, baseOptions);
        }
export type InsightsTimeTrendingStatementsQueryHookResult = ReturnType<typeof useInsightsTimeTrendingStatementsQuery>;
export type InsightsTimeTrendingStatementsLazyQueryHookResult = ReturnType<typeof useInsightsTimeTrendingStatementsLazyQuery>;
export type InsightsTimeTrendingStatementsQueryResult = Apollo.QueryResult<InsightsTimeTrendingStatementsQuery, InsightsTimeTrendingStatementsQueryVariables>;
export const OrganizationFilterValuesDocument = gql`
    query OrganizationFilterValues($dtCode: String!) {
  filterValues(dtCode: $dtCode) {
    ...OrganizationFilterValue
  }
}
    ${OrganizationFilterValueFragmentDoc}`;

/**
 * __useOrganizationFilterValuesQuery__
 *
 * To run a query within a React component, call `useOrganizationFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationFilterValuesQuery({
 *   variables: {
 *      dtCode: // value for 'dtCode'
 *   },
 * });
 */
export function useOrganizationFilterValuesQuery(baseOptions: Apollo.QueryHookOptions<OrganizationFilterValuesQuery, OrganizationFilterValuesQueryVariables>) {
        return Apollo.useQuery<OrganizationFilterValuesQuery, OrganizationFilterValuesQueryVariables>(OrganizationFilterValuesDocument, baseOptions);
      }
export function useOrganizationFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationFilterValuesQuery, OrganizationFilterValuesQueryVariables>) {
          return Apollo.useLazyQuery<OrganizationFilterValuesQuery, OrganizationFilterValuesQueryVariables>(OrganizationFilterValuesDocument, baseOptions);
        }
export type OrganizationFilterValuesQueryHookResult = ReturnType<typeof useOrganizationFilterValuesQuery>;
export type OrganizationFilterValuesLazyQueryHookResult = ReturnType<typeof useOrganizationFilterValuesLazyQuery>;
export type OrganizationFilterValuesQueryResult = Apollo.QueryResult<OrganizationFilterValuesQuery, OrganizationFilterValuesQueryVariables>;
export const PublicConstantsDocument = gql`
    query PublicConstants {
  publicConstants {
    standardResidentFilterValueChoices {
      dtCode
      standardChoices
    }
    trustIndexCertificationMinimumScore
    fortuneListSubmissionDate
    certificationMarketingToolkitUrl
    cultureBriefDisabledFieldsWhenCompleted
    cultureBriefRequiredFields
    cultureBriefMaxImages
  }
}
    `;

/**
 * __usePublicConstantsQuery__
 *
 * To run a query within a React component, call `usePublicConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicConstantsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicConstantsQuery(baseOptions?: Apollo.QueryHookOptions<PublicConstantsQuery, PublicConstantsQueryVariables>) {
        return Apollo.useQuery<PublicConstantsQuery, PublicConstantsQueryVariables>(PublicConstantsDocument, baseOptions);
      }
export function usePublicConstantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicConstantsQuery, PublicConstantsQueryVariables>) {
          return Apollo.useLazyQuery<PublicConstantsQuery, PublicConstantsQueryVariables>(PublicConstantsDocument, baseOptions);
        }
export type PublicConstantsQueryHookResult = ReturnType<typeof usePublicConstantsQuery>;
export type PublicConstantsLazyQueryHookResult = ReturnType<typeof usePublicConstantsLazyQuery>;
export type PublicConstantsQueryResult = Apollo.QueryResult<PublicConstantsQuery, PublicConstantsQueryVariables>;
export const SettingsGroupsDocument = gql`
    query SettingsGroups($search: String) {
  groups(search: $search) {
    ...SettingsGroup
  }
  users {
    edges {
      node {
        ...SettingsGroupUser
      }
    }
  }
  surveys {
    edges {
      node {
        uuid
        name
      }
    }
  }
}
    ${SettingsGroupFragmentDoc}
${SettingsGroupUserFragmentDoc}`;

/**
 * __useSettingsGroupsQuery__
 *
 * To run a query within a React component, call `useSettingsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsGroupsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSettingsGroupsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsGroupsQuery, SettingsGroupsQueryVariables>) {
        return Apollo.useQuery<SettingsGroupsQuery, SettingsGroupsQueryVariables>(SettingsGroupsDocument, baseOptions);
      }
export function useSettingsGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsGroupsQuery, SettingsGroupsQueryVariables>) {
          return Apollo.useLazyQuery<SettingsGroupsQuery, SettingsGroupsQueryVariables>(SettingsGroupsDocument, baseOptions);
        }
export type SettingsGroupsQueryHookResult = ReturnType<typeof useSettingsGroupsQuery>;
export type SettingsGroupsLazyQueryHookResult = ReturnType<typeof useSettingsGroupsLazyQuery>;
export type SettingsGroupsQueryResult = Apollo.QueryResult<SettingsGroupsQuery, SettingsGroupsQueryVariables>;
export const SettingsOrganizationServiceAreasDocument = gql`
    query SettingsOrganizationServiceAreas($departmentDtCode: String!) {
  currentUser {
    id
    organization {
      uuid
      serviceAreas {
        ...OrganizationServiceAreas
      }
    }
  }
  departments: filterValues(dtCode: $departmentDtCode) {
    uuid
    name
  }
}
    ${OrganizationServiceAreasFragmentDoc}`;

/**
 * __useSettingsOrganizationServiceAreasQuery__
 *
 * To run a query within a React component, call `useSettingsOrganizationServiceAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsOrganizationServiceAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsOrganizationServiceAreasQuery({
 *   variables: {
 *      departmentDtCode: // value for 'departmentDtCode'
 *   },
 * });
 */
export function useSettingsOrganizationServiceAreasQuery(baseOptions: Apollo.QueryHookOptions<SettingsOrganizationServiceAreasQuery, SettingsOrganizationServiceAreasQueryVariables>) {
        return Apollo.useQuery<SettingsOrganizationServiceAreasQuery, SettingsOrganizationServiceAreasQueryVariables>(SettingsOrganizationServiceAreasDocument, baseOptions);
      }
export function useSettingsOrganizationServiceAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsOrganizationServiceAreasQuery, SettingsOrganizationServiceAreasQueryVariables>) {
          return Apollo.useLazyQuery<SettingsOrganizationServiceAreasQuery, SettingsOrganizationServiceAreasQueryVariables>(SettingsOrganizationServiceAreasDocument, baseOptions);
        }
export type SettingsOrganizationServiceAreasQueryHookResult = ReturnType<typeof useSettingsOrganizationServiceAreasQuery>;
export type SettingsOrganizationServiceAreasLazyQueryHookResult = ReturnType<typeof useSettingsOrganizationServiceAreasLazyQuery>;
export type SettingsOrganizationServiceAreasQueryResult = Apollo.QueryResult<SettingsOrganizationServiceAreasQuery, SettingsOrganizationServiceAreasQueryVariables>;
export const SettingsUsersDocument = gql`
    query SettingsUsers($pageSize: Int, $cursor: String, $offset: Int, $filterValueUuids: [String!], $search: String, $statuses: [String], $sortBy: String, $sortDescending: Boolean) {
  users(first: $pageSize, after: $cursor, offset: $offset, filterValueUuids: $filterValueUuids, search: $search, statuses: $statuses, sortBy: $sortBy, sortDescending: $sortDescending) {
    totalCount
    edges {
      node {
        ...SettingsUser
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${SettingsUserFragmentDoc}`;

/**
 * __useSettingsUsersQuery__
 *
 * To run a query within a React component, call `useSettingsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsUsersQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *      offset: // value for 'offset'
 *      filterValueUuids: // value for 'filterValueUuids'
 *      search: // value for 'search'
 *      statuses: // value for 'statuses'
 *      sortBy: // value for 'sortBy'
 *      sortDescending: // value for 'sortDescending'
 *   },
 * });
 */
export function useSettingsUsersQuery(baseOptions?: Apollo.QueryHookOptions<SettingsUsersQuery, SettingsUsersQueryVariables>) {
        return Apollo.useQuery<SettingsUsersQuery, SettingsUsersQueryVariables>(SettingsUsersDocument, baseOptions);
      }
export function useSettingsUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsUsersQuery, SettingsUsersQueryVariables>) {
          return Apollo.useLazyQuery<SettingsUsersQuery, SettingsUsersQueryVariables>(SettingsUsersDocument, baseOptions);
        }
export type SettingsUsersQueryHookResult = ReturnType<typeof useSettingsUsersQuery>;
export type SettingsUsersLazyQueryHookResult = ReturnType<typeof useSettingsUsersLazyQuery>;
export type SettingsUsersQueryResult = Apollo.QueryResult<SettingsUsersQuery, SettingsUsersQueryVariables>;
export const SettingsValidGroupsDocument = gql`
    query SettingsValidGroups {
  validGroups {
    ...SettingsGroup
  }
}
    ${SettingsGroupFragmentDoc}`;

/**
 * __useSettingsValidGroupsQuery__
 *
 * To run a query within a React component, call `useSettingsValidGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsValidGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsValidGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsValidGroupsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsValidGroupsQuery, SettingsValidGroupsQueryVariables>) {
        return Apollo.useQuery<SettingsValidGroupsQuery, SettingsValidGroupsQueryVariables>(SettingsValidGroupsDocument, baseOptions);
      }
export function useSettingsValidGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsValidGroupsQuery, SettingsValidGroupsQueryVariables>) {
          return Apollo.useLazyQuery<SettingsValidGroupsQuery, SettingsValidGroupsQueryVariables>(SettingsValidGroupsDocument, baseOptions);
        }
export type SettingsValidGroupsQueryHookResult = ReturnType<typeof useSettingsValidGroupsQuery>;
export type SettingsValidGroupsLazyQueryHookResult = ReturnType<typeof useSettingsValidGroupsLazyQuery>;
export type SettingsValidGroupsQueryResult = Apollo.QueryResult<SettingsValidGroupsQuery, SettingsValidGroupsQueryVariables>;
export const SurveysAvailableSurveyQuestionsDocument = gql`
    query SurveysAvailableSurveyQuestions($surveyUuid: String!) {
  availableSurveyQuestions(surveyUuid: $surveyUuid) {
    uuid
    text
    benchmarkCode
    isDisabled
    required
    category
    residentFocus
    dependsOn {
      benchmarkCode
    }
    filters {
      levelsOfCare
      participantTypes
    }
  }
}
    `;

/**
 * __useSurveysAvailableSurveyQuestionsQuery__
 *
 * To run a query within a React component, call `useSurveysAvailableSurveyQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysAvailableSurveyQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysAvailableSurveyQuestionsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useSurveysAvailableSurveyQuestionsQuery(baseOptions: Apollo.QueryHookOptions<SurveysAvailableSurveyQuestionsQuery, SurveysAvailableSurveyQuestionsQueryVariables>) {
        return Apollo.useQuery<SurveysAvailableSurveyQuestionsQuery, SurveysAvailableSurveyQuestionsQueryVariables>(SurveysAvailableSurveyQuestionsDocument, baseOptions);
      }
export function useSurveysAvailableSurveyQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysAvailableSurveyQuestionsQuery, SurveysAvailableSurveyQuestionsQueryVariables>) {
          return Apollo.useLazyQuery<SurveysAvailableSurveyQuestionsQuery, SurveysAvailableSurveyQuestionsQueryVariables>(SurveysAvailableSurveyQuestionsDocument, baseOptions);
        }
export type SurveysAvailableSurveyQuestionsQueryHookResult = ReturnType<typeof useSurveysAvailableSurveyQuestionsQuery>;
export type SurveysAvailableSurveyQuestionsLazyQueryHookResult = ReturnType<typeof useSurveysAvailableSurveyQuestionsLazyQuery>;
export type SurveysAvailableSurveyQuestionsQueryResult = Apollo.QueryResult<SurveysAvailableSurveyQuestionsQuery, SurveysAvailableSurveyQuestionsQueryVariables>;
export const SurveysParticipantsBySurveyDocument = gql`
    query SurveysParticipantsBySurvey($surveyUuid: String!, $pageSize: Int!, $page: Int!, $sortBy: String, $sortDescending: Boolean, $search: String) {
  participantsBySurvey(surveyUuid: $surveyUuid, pageSize: $pageSize, page: $page, sortBy: $sortBy, sortDescending: $sortDescending, search: $search) {
    participants {
      uuid
      surveyCode
      firstName
      personalEmail
      workEmail
      mobilePhone
      dataTypeValues {
        dtCode
        value
      }
    }
    total
  }
  survey(surveyUuid: $surveyUuid) {
    participantsUpload {
      fileName
    }
    clientsUpload {
      fileName
    }
    participantTemplateUrl
    clientTemplateUrl
    uuid
    responseRate {
      ...ResponseRate
    }
    dataTypeOptions {
      code
      visibleName
      required
      mustSyncWithClient
      standards {
        text
        value
      }
    }
  }
}
    ${ResponseRateFragmentDoc}`;

/**
 * __useSurveysParticipantsBySurveyQuery__
 *
 * To run a query within a React component, call `useSurveysParticipantsBySurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysParticipantsBySurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysParticipantsBySurveyQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *      sortDescending: // value for 'sortDescending'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSurveysParticipantsBySurveyQuery(baseOptions: Apollo.QueryHookOptions<SurveysParticipantsBySurveyQuery, SurveysParticipantsBySurveyQueryVariables>) {
        return Apollo.useQuery<SurveysParticipantsBySurveyQuery, SurveysParticipantsBySurveyQueryVariables>(SurveysParticipantsBySurveyDocument, baseOptions);
      }
export function useSurveysParticipantsBySurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysParticipantsBySurveyQuery, SurveysParticipantsBySurveyQueryVariables>) {
          return Apollo.useLazyQuery<SurveysParticipantsBySurveyQuery, SurveysParticipantsBySurveyQueryVariables>(SurveysParticipantsBySurveyDocument, baseOptions);
        }
export type SurveysParticipantsBySurveyQueryHookResult = ReturnType<typeof useSurveysParticipantsBySurveyQuery>;
export type SurveysParticipantsBySurveyLazyQueryHookResult = ReturnType<typeof useSurveysParticipantsBySurveyLazyQuery>;
export type SurveysParticipantsBySurveyQueryResult = Apollo.QueryResult<SurveysParticipantsBySurveyQuery, SurveysParticipantsBySurveyQueryVariables>;
export const SurveysParticipantsUploadDocument = gql`
    query SurveysParticipantsUpload($surveyUuid: String!) {
  survey(surveyUuid: $surveyUuid) {
    uuid
    participantsUpload {
      fileName
      updated
      errors {
        code
        dtCode
        dtName
        cell
      }
      summary {
        processed
        deleted
        added
        updated
        filters {
          filterTypeName
          filterValueNames
        }
      }
    }
  }
}
    `;

/**
 * __useSurveysParticipantsUploadQuery__
 *
 * To run a query within a React component, call `useSurveysParticipantsUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysParticipantsUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysParticipantsUploadQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useSurveysParticipantsUploadQuery(baseOptions: Apollo.QueryHookOptions<SurveysParticipantsUploadQuery, SurveysParticipantsUploadQueryVariables>) {
        return Apollo.useQuery<SurveysParticipantsUploadQuery, SurveysParticipantsUploadQueryVariables>(SurveysParticipantsUploadDocument, baseOptions);
      }
export function useSurveysParticipantsUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysParticipantsUploadQuery, SurveysParticipantsUploadQueryVariables>) {
          return Apollo.useLazyQuery<SurveysParticipantsUploadQuery, SurveysParticipantsUploadQueryVariables>(SurveysParticipantsUploadDocument, baseOptions);
        }
export type SurveysParticipantsUploadQueryHookResult = ReturnType<typeof useSurveysParticipantsUploadQuery>;
export type SurveysParticipantsUploadLazyQueryHookResult = ReturnType<typeof useSurveysParticipantsUploadLazyQuery>;
export type SurveysParticipantsUploadQueryResult = Apollo.QueryResult<SurveysParticipantsUploadQuery, SurveysParticipantsUploadQueryVariables>;
export const SurveysQuestionDocument = gql`
    query SurveysQuestion($surveyUuid: String!, $uuid: String!) {
  question(surveyUuid: $surveyUuid, uuid: $uuid) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useSurveysQuestionQuery__
 *
 * To run a query within a React component, call `useSurveysQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysQuestionQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSurveysQuestionQuery(baseOptions: Apollo.QueryHookOptions<SurveysQuestionQuery, SurveysQuestionQueryVariables>) {
        return Apollo.useQuery<SurveysQuestionQuery, SurveysQuestionQueryVariables>(SurveysQuestionDocument, baseOptions);
      }
export function useSurveysQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysQuestionQuery, SurveysQuestionQueryVariables>) {
          return Apollo.useLazyQuery<SurveysQuestionQuery, SurveysQuestionQueryVariables>(SurveysQuestionDocument, baseOptions);
        }
export type SurveysQuestionQueryHookResult = ReturnType<typeof useSurveysQuestionQuery>;
export type SurveysQuestionLazyQueryHookResult = ReturnType<typeof useSurveysQuestionLazyQuery>;
export type SurveysQuestionQueryResult = Apollo.QueryResult<SurveysQuestionQuery, SurveysQuestionQueryVariables>;
export const SurveysQuestionsDocument = gql`
    query SurveysQuestions($surveyUuid: String!) {
  questions: surveyQuestions(surveyUuid: $surveyUuid) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useSurveysQuestionsQuery__
 *
 * To run a query within a React component, call `useSurveysQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysQuestionsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useSurveysQuestionsQuery(baseOptions: Apollo.QueryHookOptions<SurveysQuestionsQuery, SurveysQuestionsQueryVariables>) {
        return Apollo.useQuery<SurveysQuestionsQuery, SurveysQuestionsQueryVariables>(SurveysQuestionsDocument, baseOptions);
      }
export function useSurveysQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysQuestionsQuery, SurveysQuestionsQueryVariables>) {
          return Apollo.useLazyQuery<SurveysQuestionsQuery, SurveysQuestionsQueryVariables>(SurveysQuestionsDocument, baseOptions);
        }
export type SurveysQuestionsQueryHookResult = ReturnType<typeof useSurveysQuestionsQuery>;
export type SurveysQuestionsLazyQueryHookResult = ReturnType<typeof useSurveysQuestionsLazyQuery>;
export type SurveysQuestionsQueryResult = Apollo.QueryResult<SurveysQuestionsQuery, SurveysQuestionsQueryVariables>;
export const SurveysQuestionsByCategoryDocument = gql`
    query SurveysQuestionsByCategory($surveyUuid: String!) {
  questionsByCategory(surveyUuid: $surveyUuid) {
    canAddCustom
    category
    questionsByFocus {
      canAddCustom
      focus
      questions {
        ...Question
      }
    }
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useSurveysQuestionsByCategoryQuery__
 *
 * To run a query within a React component, call `useSurveysQuestionsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysQuestionsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysQuestionsByCategoryQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useSurveysQuestionsByCategoryQuery(baseOptions: Apollo.QueryHookOptions<SurveysQuestionsByCategoryQuery, SurveysQuestionsByCategoryQueryVariables>) {
        return Apollo.useQuery<SurveysQuestionsByCategoryQuery, SurveysQuestionsByCategoryQueryVariables>(SurveysQuestionsByCategoryDocument, baseOptions);
      }
export function useSurveysQuestionsByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysQuestionsByCategoryQuery, SurveysQuestionsByCategoryQueryVariables>) {
          return Apollo.useLazyQuery<SurveysQuestionsByCategoryQuery, SurveysQuestionsByCategoryQueryVariables>(SurveysQuestionsByCategoryDocument, baseOptions);
        }
export type SurveysQuestionsByCategoryQueryHookResult = ReturnType<typeof useSurveysQuestionsByCategoryQuery>;
export type SurveysQuestionsByCategoryLazyQueryHookResult = ReturnType<typeof useSurveysQuestionsByCategoryLazyQuery>;
export type SurveysQuestionsByCategoryQueryResult = Apollo.QueryResult<SurveysQuestionsByCategoryQuery, SurveysQuestionsByCategoryQueryVariables>;
export const SurveysSurveyDocument = gql`
    query SurveysSurvey($uuid: String!) {
  survey(surveyUuid: $uuid) {
    ...SurveysSurvey
    maxSmsNotifications
    numberOfMaxScheduledNotifications
    maxScheduledNotificationDate
    translationsRequired
    allowedCategoriesForCustomQuestions
    allowedCategoriesForOptionalQuestions
    responseRate {
      ...ResponseRate
    }
    languages {
      ...OrganizationLanguage
    }
    welcomeMessages {
      uuid
      key
      text
    }
    defaultWelcomeMessages {
      uuid
      key
      text
    }
    hasMissingTranslations
    hasIntervalNotifications
    hasSurveyResponseNotifications
    hasAverageScoreNotifications
    hasQuestionScoreNotifications
    hasIngestFailureReports
    surveyPortalUrl
    includeForUsNews
    inviteMethod
    phoneScript
    missingPhonesErrorLevel
    usesSamplePoolDataSource
    isRecurring
  }
}
    ${SurveysSurveyFragmentDoc}
${ResponseRateFragmentDoc}
${OrganizationLanguageFragmentDoc}`;

/**
 * __useSurveysSurveyQuery__
 *
 * To run a query within a React component, call `useSurveysSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysSurveyQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSurveysSurveyQuery(baseOptions: Apollo.QueryHookOptions<SurveysSurveyQuery, SurveysSurveyQueryVariables>) {
        return Apollo.useQuery<SurveysSurveyQuery, SurveysSurveyQueryVariables>(SurveysSurveyDocument, baseOptions);
      }
export function useSurveysSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysSurveyQuery, SurveysSurveyQueryVariables>) {
          return Apollo.useLazyQuery<SurveysSurveyQuery, SurveysSurveyQueryVariables>(SurveysSurveyDocument, baseOptions);
        }
export type SurveysSurveyQueryHookResult = ReturnType<typeof useSurveysSurveyQuery>;
export type SurveysSurveyLazyQueryHookResult = ReturnType<typeof useSurveysSurveyLazyQuery>;
export type SurveysSurveyQueryResult = Apollo.QueryResult<SurveysSurveyQuery, SurveysSurveyQueryVariables>;
export const SurveysSurveyNotificationsDocument = gql`
    query SurveysSurveyNotifications($surveyUuid: String!) {
  survey(surveyUuid: $surveyUuid) {
    uuid
    scheduledNotifications {
      ...SurveysScheduledNotification
    }
    intervalNotifications {
      ...SurveysIntervalNotification
    }
  }
}
    ${SurveysScheduledNotificationFragmentDoc}
${SurveysIntervalNotificationFragmentDoc}`;

/**
 * __useSurveysSurveyNotificationsQuery__
 *
 * To run a query within a React component, call `useSurveysSurveyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysSurveyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysSurveyNotificationsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useSurveysSurveyNotificationsQuery(baseOptions: Apollo.QueryHookOptions<SurveysSurveyNotificationsQuery, SurveysSurveyNotificationsQueryVariables>) {
        return Apollo.useQuery<SurveysSurveyNotificationsQuery, SurveysSurveyNotificationsQueryVariables>(SurveysSurveyNotificationsDocument, baseOptions);
      }
export function useSurveysSurveyNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysSurveyNotificationsQuery, SurveysSurveyNotificationsQueryVariables>) {
          return Apollo.useLazyQuery<SurveysSurveyNotificationsQuery, SurveysSurveyNotificationsQueryVariables>(SurveysSurveyNotificationsDocument, baseOptions);
        }
export type SurveysSurveyNotificationsQueryHookResult = ReturnType<typeof useSurveysSurveyNotificationsQuery>;
export type SurveysSurveyNotificationsLazyQueryHookResult = ReturnType<typeof useSurveysSurveyNotificationsLazyQuery>;
export type SurveysSurveyNotificationsQueryResult = Apollo.QueryResult<SurveysSurveyNotificationsQuery, SurveysSurveyNotificationsQueryVariables>;
export const SurveysSurveyResponseNotificationsDocument = gql`
    query SurveysSurveyResponseNotifications($surveyUuid: String!) {
  survey(surveyUuid: $surveyUuid) {
    uuid
    type
    groupsWithSurveyAccess {
      uuid
      name
    }
    groupsSurveyResponseNotifications {
      enabled
      notificationType
      groups {
        uuid
        name
        allSurveys
      }
    }
    groupsAverageScoreNotifications {
      enabled
      scoreType
      frequency
      groups {
        uuid
        name
        allSurveys
      }
    }
    groupsQuestionScoreNotifications {
      uuid
      notificationName
      enabled
      scoreType
      frequency
      minScore
      maxScore
      statement {
        uuid
        text
      }
      openEndedQuestion {
        uuid
        text
      }
      groups {
        uuid
        name
        allSurveys
      }
    }
  }
}
    `;

/**
 * __useSurveysSurveyResponseNotificationsQuery__
 *
 * To run a query within a React component, call `useSurveysSurveyResponseNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysSurveyResponseNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysSurveyResponseNotificationsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useSurveysSurveyResponseNotificationsQuery(baseOptions: Apollo.QueryHookOptions<SurveysSurveyResponseNotificationsQuery, SurveysSurveyResponseNotificationsQueryVariables>) {
        return Apollo.useQuery<SurveysSurveyResponseNotificationsQuery, SurveysSurveyResponseNotificationsQueryVariables>(SurveysSurveyResponseNotificationsDocument, baseOptions);
      }
export function useSurveysSurveyResponseNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysSurveyResponseNotificationsQuery, SurveysSurveyResponseNotificationsQueryVariables>) {
          return Apollo.useLazyQuery<SurveysSurveyResponseNotificationsQuery, SurveysSurveyResponseNotificationsQueryVariables>(SurveysSurveyResponseNotificationsDocument, baseOptions);
        }
export type SurveysSurveyResponseNotificationsQueryHookResult = ReturnType<typeof useSurveysSurveyResponseNotificationsQuery>;
export type SurveysSurveyResponseNotificationsLazyQueryHookResult = ReturnType<typeof useSurveysSurveyResponseNotificationsLazyQuery>;
export type SurveysSurveyResponseNotificationsQueryResult = Apollo.QueryResult<SurveysSurveyResponseNotificationsQuery, SurveysSurveyResponseNotificationsQueryVariables>;
export const SurveysSurveyResponseNotificationsGroupsDocument = gql`
    query SurveysSurveyResponseNotificationsGroups {
  groups {
    ...SettingsGroup
  }
}
    ${SettingsGroupFragmentDoc}`;

/**
 * __useSurveysSurveyResponseNotificationsGroupsQuery__
 *
 * To run a query within a React component, call `useSurveysSurveyResponseNotificationsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysSurveyResponseNotificationsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysSurveyResponseNotificationsGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSurveysSurveyResponseNotificationsGroupsQuery(baseOptions?: Apollo.QueryHookOptions<SurveysSurveyResponseNotificationsGroupsQuery, SurveysSurveyResponseNotificationsGroupsQueryVariables>) {
        return Apollo.useQuery<SurveysSurveyResponseNotificationsGroupsQuery, SurveysSurveyResponseNotificationsGroupsQueryVariables>(SurveysSurveyResponseNotificationsGroupsDocument, baseOptions);
      }
export function useSurveysSurveyResponseNotificationsGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysSurveyResponseNotificationsGroupsQuery, SurveysSurveyResponseNotificationsGroupsQueryVariables>) {
          return Apollo.useLazyQuery<SurveysSurveyResponseNotificationsGroupsQuery, SurveysSurveyResponseNotificationsGroupsQueryVariables>(SurveysSurveyResponseNotificationsGroupsDocument, baseOptions);
        }
export type SurveysSurveyResponseNotificationsGroupsQueryHookResult = ReturnType<typeof useSurveysSurveyResponseNotificationsGroupsQuery>;
export type SurveysSurveyResponseNotificationsGroupsLazyQueryHookResult = ReturnType<typeof useSurveysSurveyResponseNotificationsGroupsLazyQuery>;
export type SurveysSurveyResponseNotificationsGroupsQueryResult = Apollo.QueryResult<SurveysSurveyResponseNotificationsGroupsQuery, SurveysSurveyResponseNotificationsGroupsQueryVariables>;
export const SurveysSurveyResponseRateDocument = gql`
    query SurveysSurveyResponseRate($surveyUuid: String!, $byClient: Boolean!) {
  survey(surveyUuid: $surveyUuid) {
    hasLessThanMinShowableResults
    insightsSurvey {
      responseRate(byClient: $byClient) {
        ...ResponseRate
      }
      completionRate {
        started
      }
    }
  }
}
    ${ResponseRateFragmentDoc}`;

/**
 * __useSurveysSurveyResponseRateQuery__
 *
 * To run a query within a React component, call `useSurveysSurveyResponseRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysSurveyResponseRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysSurveyResponseRateQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      byClient: // value for 'byClient'
 *   },
 * });
 */
export function useSurveysSurveyResponseRateQuery(baseOptions: Apollo.QueryHookOptions<SurveysSurveyResponseRateQuery, SurveysSurveyResponseRateQueryVariables>) {
        return Apollo.useQuery<SurveysSurveyResponseRateQuery, SurveysSurveyResponseRateQueryVariables>(SurveysSurveyResponseRateDocument, baseOptions);
      }
export function useSurveysSurveyResponseRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysSurveyResponseRateQuery, SurveysSurveyResponseRateQueryVariables>) {
          return Apollo.useLazyQuery<SurveysSurveyResponseRateQuery, SurveysSurveyResponseRateQueryVariables>(SurveysSurveyResponseRateDocument, baseOptions);
        }
export type SurveysSurveyResponseRateQueryHookResult = ReturnType<typeof useSurveysSurveyResponseRateQuery>;
export type SurveysSurveyResponseRateLazyQueryHookResult = ReturnType<typeof useSurveysSurveyResponseRateLazyQuery>;
export type SurveysSurveyResponseRateQueryResult = Apollo.QueryResult<SurveysSurveyResponseRateQuery, SurveysSurveyResponseRateQueryVariables>;
export const SurveysSurveySummaryDocument = gql`
    query SurveysSurveySummary($surveyUuid: String!, $responseRateByClient: Boolean!) {
  surveySummary(surveyUuid: $surveyUuid, responseRateByClient: $responseRateByClient) {
    surveyPortalUrl
    canSchedule
    canUnschedule
    numFinishedResponses
    numTotalResponses
    numLocationsMissingRequiredData
    overallIndexScore {
      positive
      inconsistent
      negative
    }
    design {
      ready
      data
      warnings
      errors
      name
      startDate
      endDate
      languages {
        ...OrganizationLanguage
        nameTranslated
      }
    }
    questions {
      ready
      data
      warnings
      errors
      trustIndex
      demographic
      openEnded
      npsQuestions
      custom
      shortAnswer
      longAnswer
      multipleChoice
      multiselect
      linearScale
      statements
      hasOnlineReviewQuestion
      locationsCount
      locationsWithOnlineReviewSitesCount
      personalInfoQuestions {
        code
        name
      }
    }
    translations {
      ready
      data
      warnings
      errors
      missing
      translated
    }
    participants {
      ready
      data
      warnings
      errors
      total
      clientsWithoutParticipantCount
      workEmails
      personalEmails
      mobilePhones
    }
    notifications {
      ready
      data
      warnings
      errors
      total
      notifications {
        uuid
        workEmail
        personalEmail
        sms
        when
        days
        sentTimestamp
      }
    }
    filterValues {
      ready
      errors
      warnings
      data
      mappedFilterValuesCount
      totalFilterValuesCount
    }
    settings {
      ready
      data
      warnings
      errors
      surveyCodeName
      surveyCodeHelpText
      locs
      depts
      missingServices
    }
  }
}
    ${OrganizationLanguageFragmentDoc}`;

/**
 * __useSurveysSurveySummaryQuery__
 *
 * To run a query within a React component, call `useSurveysSurveySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysSurveySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysSurveySummaryQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *      responseRateByClient: // value for 'responseRateByClient'
 *   },
 * });
 */
export function useSurveysSurveySummaryQuery(baseOptions: Apollo.QueryHookOptions<SurveysSurveySummaryQuery, SurveysSurveySummaryQueryVariables>) {
        return Apollo.useQuery<SurveysSurveySummaryQuery, SurveysSurveySummaryQueryVariables>(SurveysSurveySummaryDocument, baseOptions);
      }
export function useSurveysSurveySummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysSurveySummaryQuery, SurveysSurveySummaryQueryVariables>) {
          return Apollo.useLazyQuery<SurveysSurveySummaryQuery, SurveysSurveySummaryQueryVariables>(SurveysSurveySummaryDocument, baseOptions);
        }
export type SurveysSurveySummaryQueryHookResult = ReturnType<typeof useSurveysSurveySummaryQuery>;
export type SurveysSurveySummaryLazyQueryHookResult = ReturnType<typeof useSurveysSurveySummaryLazyQuery>;
export type SurveysSurveySummaryQueryResult = Apollo.QueryResult<SurveysSurveySummaryQuery, SurveysSurveySummaryQueryVariables>;
export const SurveysSurveysDocument = gql`
    query SurveysSurveys($surveyType: SurveyTypeEnum) {
  surveys(surveyType: $surveyType) {
    edges {
      node {
        ...SurveyCore
        isDefaultClientBasedResponseRateReport
        hasLessThanMinShowableResults
        editable
      }
    }
  }
}
    ${SurveyCoreFragmentDoc}`;

/**
 * __useSurveysSurveysQuery__
 *
 * To run a query within a React component, call `useSurveysSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysSurveysQuery({
 *   variables: {
 *      surveyType: // value for 'surveyType'
 *   },
 * });
 */
export function useSurveysSurveysQuery(baseOptions?: Apollo.QueryHookOptions<SurveysSurveysQuery, SurveysSurveysQueryVariables>) {
        return Apollo.useQuery<SurveysSurveysQuery, SurveysSurveysQueryVariables>(SurveysSurveysDocument, baseOptions);
      }
export function useSurveysSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysSurveysQuery, SurveysSurveysQueryVariables>) {
          return Apollo.useLazyQuery<SurveysSurveysQuery, SurveysSurveysQueryVariables>(SurveysSurveysDocument, baseOptions);
        }
export type SurveysSurveysQueryHookResult = ReturnType<typeof useSurveysSurveysQuery>;
export type SurveysSurveysLazyQueryHookResult = ReturnType<typeof useSurveysSurveysLazyQuery>;
export type SurveysSurveysQueryResult = Apollo.QueryResult<SurveysSurveysQuery, SurveysSurveysQueryVariables>;
export const SurveysTranslationsDocument = gql`
    query SurveysTranslations($surveyUuid: String!) {
  translations(surveyUuid: $surveyUuid) {
    uuid
    objectUuid
    objectType
    englishText
    text
    language {
      code
      name
    }
  }
}
    `;

/**
 * __useSurveysTranslationsQuery__
 *
 * To run a query within a React component, call `useSurveysTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysTranslationsQuery({
 *   variables: {
 *      surveyUuid: // value for 'surveyUuid'
 *   },
 * });
 */
export function useSurveysTranslationsQuery(baseOptions: Apollo.QueryHookOptions<SurveysTranslationsQuery, SurveysTranslationsQueryVariables>) {
        return Apollo.useQuery<SurveysTranslationsQuery, SurveysTranslationsQueryVariables>(SurveysTranslationsDocument, baseOptions);
      }
export function useSurveysTranslationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysTranslationsQuery, SurveysTranslationsQueryVariables>) {
          return Apollo.useLazyQuery<SurveysTranslationsQuery, SurveysTranslationsQueryVariables>(SurveysTranslationsDocument, baseOptions);
        }
export type SurveysTranslationsQueryHookResult = ReturnType<typeof useSurveysTranslationsQuery>;
export type SurveysTranslationsLazyQueryHookResult = ReturnType<typeof useSurveysTranslationsLazyQuery>;
export type SurveysTranslationsQueryResult = Apollo.QueryResult<SurveysTranslationsQuery, SurveysTranslationsQueryVariables>;