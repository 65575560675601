import React from 'react'

import { makeStyles, IconButton } from '@material-ui/core'
import { IconButtonProps } from '@material-ui/core/IconButton'
import cn from 'classnames'

const useStyles = makeStyles(({ palette, shadows }) => ({
  button: {
    color: palette.common.grey,
    minWidth: 22,
    borderRadius: '50%',
    fontSize: '1rem',
    padding: '0',
    lineHeight: '1rem',
    margin: '0 8px',
    boxShadow: 'none',
    overflow: 'hidden',
    position: 'relative',
    border: 'none',
    fontWeight: 400,
    letterSpacing: '0',
    willChange: 'box-shadow, transform',
    transition:
      'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    display: 'inline-block',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    backgroundImage: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  condensed: {
    width: 22,
    height: 30,
  },
  primary: {
    color: palette.common.primary,
  },
  white: {
    boxShadow: shadows[2],
    backgroundColor: palette.common.white,
    color: palette.common.grey,
    '&:hover': {
      backgroundColor: palette.common.grey,
      color: palette.common.white,
    },
  },
  success: {
    color: palette.common.success,
  },
  warning: {
    color: palette.common.warning,
  },
  danger: {
    color: palette.common.danger,
  },
  primaryHover: {
    '&:hover': {
      color: palette.common.primary,
    },
  },
  secondaryHover: {
    '&:hover': {
      color: palette.common.secondary,
    },
  },
  successHover: {
    '&:hover': {
      color: palette.common.success,
    },
  },
  warningHover: {
    '&,&:hover': {
      color: palette.common.warning,
    },
  },
  dangerHover: {
    '&:hover': {
      color: palette.common.danger,
    },
  },
}))

interface Props extends Pick<IconButtonProps, Exclude<keyof IconButtonProps, 'color'>> {
  children: any
  color?: keyof ReturnType<typeof useStyles>
  className?: string
  fullWidth?: boolean
}

const CustomIconButton: React.FC<Props> = React.forwardRef(
  ({ children, className = '', color = 'primary', fullWidth = false, ...rest }, ref) => {
    const classes = useStyles()

    return (
      <IconButton
        {...rest}
        ref={ref}
        disableRipple
        className={cn(classes.button, className, {
          [classes[
            color as keyof Pick<
              ReturnType<typeof useStyles>,
              | 'primary'
              | 'white'
              | 'success'
              | 'warning'
              | 'danger'
              | 'primaryHover'
              | 'secondaryHover'
              | 'successHover'
              | 'warningHover'
              | 'dangerHover'
            >
          ]]: color,
          [classes.condensed]: !fullWidth,
        })}
      >
        {children}
      </IconButton>
    )
  },
)

export default CustomIconButton
