import React, { useState } from 'react'

import { makeStyles, Typography, MenuItem, Popover, IconButton } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'

import CopyTextInput from 'components/Blocks/CopyTextInput'
import Button from 'components/Blocks/CustomButtons/Button'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import Table, { Rows } from 'components/Blocks/Table'
import { SharePopover } from 'components/CertificationHub/MarketingToolkitView'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { gaEvent } from 'config/ga'
import {
  CurrentCertificationQuery,
  useInsightsDownloadLazyQuery,
  UserDownloadsEnum,
} from 'generated/graphql'
import { useDownloadMachine } from 'utils/customHooks'

const useStyles = makeStyles(({ spacing }) => ({
  popoverContainer: {
    maxWidth: 400,
    padding: spacing(2),
  },
  copyButton: {
    marginLeft: 0,
    marginTop: spacing(4),
  },
  row: {
    padding: spacing(1),
  },
  resourcesCell: {
    '& >button:nth-child(1)': {
      marginLeft: 0,
      padding: 0,
    },
  },
}))

const EmbedBadgeCell: React.FC<{ url: string }> = ({ url }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  if (!url) {
    return <div />
  }
  return (
    <>
      <IconButton
        id="embed-certification"
        onClick={e => {
          gaEvent({
            action: 'openEmbedPopup',
            category: 'CertificationHub',
          })
          setAnchorEl(e.target as HTMLElement)
        }}
      >
        <LinkIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <div className={classes.popoverContainer}>
          <Typography>Embed Badge</Typography>
          <br />
          <Typography color="textSecondary" variant="body2">
            You can embed your badge in any web property you own using the code below. Your badge
            will automatically update when you renew your Certification.
          </Typography>
          <br />
          <CopyTextInput
            textDescription=""
            value={`<img src="${url}"  alt="Certification Badge"/>`}
            CopyIconElement={<Button className={classes.copyButton}>Copy to Clipboard</Button>}
            positionIconAsAdornment={false}
          />
        </div>
      </Popover>
    </>
  )
}

type DCBProps = {
  surveyUuid: string
  locationUuid?: string
}
const DownloadCertificationButton: React.FC<DCBProps> = ({ surveyUuid, locationUuid }) => {
  const [downloadCertifiedLocations, { data, error }] = useInsightsDownloadLazyQuery({
    variables: {
      surveyUuid,
      certificationReportLocationUuid: locationUuid,
      downloadType: UserDownloadsEnum.CERTIFICATION_REPORT,
    },
    fetchPolicy: 'network-only',
  })
  const { state, send } = useDownloadMachine(
    downloadCertifiedLocations,
    Boolean(data?.success),
    error,
  )
  return (
    <Button
      color="secondaryNoBackground"
      id="download-certification-report"
      onClick={() => {
        gaEvent({
          action: `downloadReportFor${locationUuid ? 'location' : 'org'}`,
          category: 'CertificationHub',
        })
        send('FETCH_DOWNLOAD')
      }}
      disabled={state.matches('loading')}
    >
      Certification Report
    </Button>
  )
}

enum DropdownLevel {
  ORGANIZATION = 'Organization',
  CERTIFIED_LOCATIONS = 'Certified Locations',
  NOT_CERTIFIED_LOCATIONS = 'Not Certified Locations',
}

type Props = {
  organizationName?: string | null
  orgBadgeImageUrl?: string | null
  orgBadgeZipUrl?: string | null
  locationCertifications: NonNullable<
    CurrentCertificationQuery['certification']
  >['locationCertifications']
  viewLimitedTo?: 'locations' | 'organization'
  orgIsCertified: boolean
  surveyUuid: string
}
const CertificationsTable: React.FC<Props> = ({
  organizationName,
  locationCertifications,
  orgBadgeImageUrl,
  orgBadgeZipUrl,
  viewLimitedTo,
  orgIsCertified,
  surveyUuid,
}) => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const certifiedLocations = locationCertifications.filter(l => l.isCertified)
  const notCertifiedLocations = locationCertifications.filter(l => !l.isCertified)
  // If org is not certified, limit the table view to locations.
  const dropdownOptions: DropdownLevel[] = []
  if (viewLimitedTo === 'organization') {
    dropdownOptions.push(DropdownLevel.ORGANIZATION)
  } else {
    if (viewLimitedTo !== 'locations') {
      dropdownOptions.push(DropdownLevel.ORGANIZATION)
    }
    if (certifiedLocations.length) {
      dropdownOptions.push(DropdownLevel.CERTIFIED_LOCATIONS)
    }
    if (notCertifiedLocations.length) {
      dropdownOptions.push(DropdownLevel.NOT_CERTIFIED_LOCATIONS)
    }
  }
  const [dropdownLevel, setDropdownLevel] = useState<DropdownLevel>(dropdownOptions[0])
  const columns = [
    {
      label: dropdownLevel,
    },
    {
      label: 'Resources',
    },
  ]
  if (
    (dropdownLevel === DropdownLevel.ORGANIZATION && orgIsCertified) ||
    dropdownLevel === DropdownLevel.CERTIFIED_LOCATIONS
  ) {
    columns.push(
      {
        label: 'Share',
      },
      {
        label: 'Embed',
      },
    )
  }
  const getResourcesCell = (
    locationUuid?: string,
    badgeZipUrl?: string | null,
    badgeImageUrl?: string | null,
  ) => {
    // Locations only use a single image, org uses a full zip
    if (locationUuid && !badgeImageUrl) return <></>
    const text = `Badge ${locationUuid ? 'Image' : 'Zip'} File`
    const url = badgeZipUrl || badgeImageUrl
    return (
      <div className={classes.resourcesCell}>
        <Button
          color="secondaryNoBackground"
          onClick={() => {
            gaEvent({
              action: `downloadBadgeFor${locationUuid ? 'location' : 'org'}`,
              category: 'CertificationHub',
            })
            window.open(url!)
          }}
        >
          {text}
        </Button>
        <DownloadCertificationButton surveyUuid={surveyUuid} locationUuid={locationUuid} />
      </div>
    )
  }
  const getRow = ({
    name,
    badgeImageUrl,
    badgeZipUrl,
    locationUuid,
    isCertified,
  }: {
    name: string
    badgeImageUrl?: string | null
    badgeZipUrl?: string | null
    locationUuid?: string
    isCertified: boolean
  }) => {
    const row: Rows[0] = [
      {
        name: 'name',
        value: <Typography className={classes.row}>{name}</Typography>,
      },
    ]
    if (isCertified) {
      row.push(
        {
          name: 'resources',
          value: getResourcesCell(locationUuid, badgeZipUrl, badgeImageUrl),
        },
        {
          name: 'share',
          value: badgeImageUrl ? (
            <SharePopover
              title="Share Assets"
              description="Want to send your badge and Marketing Toolkit with someone on your team? Enter their email and we’ll send them the details."
              locationUuid={locationUuid}
              includeBadgeAssets
            />
          ) : null,
        },
        {
          name: 'embed',
          value: badgeImageUrl ? (
            <EmbedBadgeCell url={badgeImageUrl} />
          ) : (
            <Typography color="textSecondary">
              Badge Generation in Progress. Check back in a few minutes..
            </Typography>
          ),
        },
      )
    } else {
      row.push({
        name: 'resources',
        value: (
          <div className={classes.resourcesCell}>
            <DownloadCertificationButton surveyUuid={surveyUuid} locationUuid={locationUuid} />
          </div>
        ),
      })
    }
    return row
  }
  let rows: Rows
  if (dropdownLevel === DropdownLevel.ORGANIZATION) {
    rows = [
      getRow({
        name: organizationName!,
        badgeImageUrl: orgBadgeImageUrl,
        badgeZipUrl: orgBadgeZipUrl,
        isCertified: orgIsCertified,
      }),
    ]
  } else if (dropdownLevel === DropdownLevel.CERTIFIED_LOCATIONS) {
    rows = certifiedLocations.map(location =>
      getRow({
        name: location.name,
        badgeImageUrl: location.badgeImageUrl,
        locationUuid: location.locationUuid,
        isCertified: true,
      }),
    )
  } else {
    rows = notCertifiedLocations.map(location =>
      getRow({ name: location.name, locationUuid: location.locationUuid, isCertified: false }),
    )
  }
  let description = (
    <span>
      Download your most recent Certification badge files.{' '}
      {orgIsCertified ? 'You’ve' : 'Certified Locations have'} earned the right to display the Great
      Place to Work Certification badge royalty-free for 12 months. When using the badge you’re
      agreeing to the&nbsp;
      <ExternalNavLink label="terms & conditions" to="https://activatedinsights.com/terms" />
      &nbsp;and&nbsp;
      <ExternalNavLink
        label="usage guidelines"
        to="https://www.greatplacetowork.com/certification-brand-guide"
      />
      .
    </span>
  )
  if (
    (dropdownLevel === DropdownLevel.ORGANIZATION && !orgIsCertified) ||
    dropdownLevel === DropdownLevel.NOT_CERTIFIED_LOCATIONS
  ) {
    description = (
      <span>
        You can download a report for your entire Organization
        {locationCertifications.length > 0 && ', or filter by location'}. Use this report to help
        you understand strengths and areas of opportunity to improve based on your results.
      </span>
    )
  }
  return (
    <>
      <SnapshotChartHeader
        title="Resources"
        description={description}
        extraControls={
          dropdownOptions.length < 1 ? (
            <div />
          ) : (
            <TextDropdown
              label={
                <Typography variant="body2" color="textSecondary">
                  Show resources for:&nbsp;
                </Typography>
              }
              value={dropdownLevel}
              onChange={e => setDropdownLevel(e.target.value as DropdownLevel)}
              id="certification-table-select"
            >
              {dropdownOptions.map(dropdownOption => {
                return (
                  <MenuItem key={dropdownOption} value={dropdownOption}>
                    {dropdownOption}
                  </MenuItem>
                )
              })}
            </TextDropdown>
          )
        }
      />
      <Table
        bicolor
        columns={columns}
        rows={rows}
        page={page}
        setPage={setPage}
        totalRows={rows.length}
      />
    </>
  )
}

export default CertificationsTable
