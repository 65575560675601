import React from 'react'

import { Button, makeStyles } from '@material-ui/core'
import cn from 'classnames'
import range from 'lodash/range'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    '& span': {
      color: theme.palette.common.navy65,
      textTransform: 'uppercase',
      fontSize: '1.5rem',
    },
  },
  page: {
    display: 'inline-block',
    cursor: 'pointer',
    paddingTop: 7,
    paddingRight: 13,
    paddingBottom: 7,
    paddingLeft: 13,
    margin: 2,
    borderRadius: 16,
    color: theme.palette.common.navy65,
    fontSize: 15,
    textAlign: 'center',
    '&:before': {
      content: '" "',
      display: 'inline-block',
      height: '100%',
      verticalAlign: 'middle',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  pageActive: {
    backgroundColor: theme.palette.common.secondary,
    color: theme.palette.common.white,
  },
}))

type Props = {
  totalPages: number
  currentPage: number
  onPageChange(newPage: number): void
  displayPages?: number
}
const Pagination: React.FC<Props> = ({
  totalPages,
  currentPage,
  onPageChange,
  displayPages = 10,
}) => {
  const classes = useStyles()
  let end = totalPages
  let start = 0
  if (displayPages < end) {
    // rounded to the nearest integer smaller
    let beforeNumber = Math.floor(displayPages / 2)
    const afterNumber = beforeNumber
    if (displayPages % 2 === 0) {
      beforeNumber -= 1
    }

    if (currentPage <= beforeNumber + 1) {
      end = displayPages
    } else if (currentPage >= totalPages - afterNumber) {
      start = totalPages - displayPages + 1
    } else {
      start = currentPage - beforeNumber
      end = currentPage + afterNumber
    }
  }

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 0}
        id="previous-page"
      >
        Previous
      </Button>
      {range(start, end).map(page => (
        <span
          id={`page${page}`}
          key={page}
          role="menuitem"
          tabIndex={page}
          className={cn(classes.page, currentPage === page ? classes.pageActive : '')}
          onKeyPress={() => onPageChange(page)}
          onClick={() => onPageChange(page)}
        >
          {page + 1} {currentPage === page}
        </span>
      ))}
      <Button
        className={classes.button}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages - 1}
        id="next-page"
      >
        Next
      </Button>
    </div>
  )
}

export default Pagination
