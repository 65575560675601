import { createStyles, makeStyles } from '@material-ui/core'

import { container } from 'assets/jss/main'

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    content: {
      paddingTop: '15vh',
      minHeight: 'calc(100vh - 80px)',
      position: 'relative',
      zIndex: 4,
    },
    container,
    card: {
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
    },
    inputAdornmentIcon: {
      color: '#555',
    },
    textField: {
      marginTop: spacing(3),
    },
    button: {
      marginTop: spacing(4),
      marginBottom: spacing(3),
    },
    forgotPassword: {
      float: 'right',
      marginRight: 0,
      paddingRight: 0,
      fontSize: '1.4rem',
    },
  }),
)

export default useStyles
