import React, { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  Card,
  Checkbox,
  Input,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import LockIcon from '@material-ui/icons/LockOpen'
import cn from 'classnames'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import { Prompt } from 'react-router'

import * as UpgradePromoImage from 'assets/img/action_plans/services-upgrade-promo.png'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import Errors from 'components/Blocks/FormHelpers/Errors'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useSettingsStyles from 'components/Settings/General/OrgSettingsStyles'
import {
  BenchmarkCodeType,
  DataTypeCode,
  useSettingsOrganizationServiceAreasQuery,
  useSettingsUpdateOrganizationServiceAreasMutation,
  SettingsOrganizationServiceAreasQuery,
  ServiceAreaInputType,
  CurrentUserQuery,
  CurrentUserDocument,
  OrganizationFragment,
  OrganizationServiceAreasFragment,
} from 'generated/graphql'
import { handleMutationResponse } from 'utils'
import { UNSAVED_CHANGES_MESSAGE } from 'utils/constants'
import { usePublicConstants } from 'utils/customHooks'

const useStyles = makeStyles(({ palette, spacing }) => ({
  label: {
    width: 30,
    textOverflow: 'ellipsis',
  },
  header: {
    '& >button': {
      height: 34,
    },
  },
  tableContainer: {
    maxHeight: 350,
    overflow: 'scroll',
  },
  explanationBlock: {
    fontSize: '1.6rem',
    color: palette.common.navy65,
    '& >ol>li': {
      paddingTop: 4,
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  serviceCellLabel: {
    width: 150,
  },
  customLabelCell: {
    width: 200,
  },
  disabledCheckboxMessage: {
    '& >span': {
      color: palette.common.navy65,
      paddingTop: spacing(2),
    },
  },
  upgradeInfo: {
    display: 'flex',
    alignItems: 'center',
    '& >svg': {
      margin: 0,
      marginRight: spacing(2),
    },
  },
  upgradePromo: {
    background: 'rgba(214,239,238,0.4)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h6': {
      paddingTop: spacing(1),
      paddingBottom: spacing(2),
      display: 'flex',
      alignItems: 'center',
      '& >button>span>svg': {
        fill: palette.common.secondary,
      },
    },
    '& >img': {
      width: '90%',
    },
  },
}))

const getInputErrors = (serviceAreaInputs: ServiceAreaInputType[]) => {
  if (serviceAreaInputs.some(sa => sa.isActive && sa.levelOfCareCodes.length === 0)) {
    return ['Set at least one level of care for each selected service.']
  }
}

const getServiceAreaInputs = (serviceAreas: OrganizationServiceAreasFragment[]) => {
  return sortBy(serviceAreas, 'code').map(sa => ({
    code: sa.code,
    customLabel: sa.customLabel,
    levelOfCareCodes: sa.levelsOfCare.map(loc => loc.code),
    departmentUuids: sa.departments.map(dept => dept.uuid),
    isActive: sa.isActive,
  }))
}

type SProps = {
  data: SettingsOrganizationServiceAreasQuery
  organization: OrganizationFragment
  onSave?(): void
  onServicesChange?(servicesContainChanges: boolean): void
}
const ServicesCard: React.FC<SProps> = ({ data, organization, onSave, onServicesChange }) => {
  const classes = { ...useStyles(), ...useSettingsStyles() }
  const [errors, setErrors] = useState<null | string[]>(null)
  const [updateServiceAreas, { loading }] = useSettingsUpdateOrganizationServiceAreasMutation()
  const { standardResidentFilterValueChoices } = usePublicConstants()
  const validResidentLevelsOfCare = standardResidentFilterValueChoices.find(
    fvc => fvc.dtCode === DataTypeCode.AI_LOC,
  )?.standardChoices
  const locOptions = organization.benchmarkLevelsOfCare.filter(loc =>
    validResidentLevelsOfCare?.some(orgLOC => orgLOC === loc.code),
  )
  // Disabled services are removed from the list so they can be shown in a promo image.
  const serviceAreas = data.currentUser.organization.serviceAreas.filter(sa => !sa.disabled)
  const [serviceAreaInputs, setServiceAreaInputs] = useState<ServiceAreaInputType[]>(
    getServiceAreaInputs(serviceAreas),
  )
  const setServiceArea = (saInput: ServiceAreaInputType) => {
    setServiceAreaInputs(serviceAreaInputs.map(sa => (sa.code === saInput.code ? saInput : sa)))
    if (onServicesChange) {
      onServicesChange(!isEqual(saInput, getServiceAreaInputs(serviceAreas)))
    }
  }
  const getLOCTooltipMessage = (isDisabled: boolean, isRequired: boolean) => {
    if (isRequired) {
      return 'Required, cannot disable'
    }
    if (isDisabled) {
      return (
        <div>
          <Typography className={classes.disabledCheckboxMessage}>
            Level of Care Unavailable:
            <br />
            <span>
              In order to include this option, go to the Levels of Care page and select the level of
              care you provide.
            </span>
          </Typography>
        </div>
      )
    }
    return ''
  }
  const orgHasDepartments = data.departments.length > 0
  return (
    <>
      <Prompt
        when={!isEqual(serviceAreaInputs, getServiceAreaInputs(serviceAreas))}
        message={UNSAVED_CHANGES_MESSAGE}
      />
      <Card>
        <div className={cn(classes.helpText, classes.header)}>
          <Typography variant="h6" className={classes.title}>
            Service Areas
          </Typography>
          <br />
          <div className={classes.explanationBlock}>
            Set up the services that you’d like to include on your customer surveys. Services added
            here will be automatically added to your surveys.
            <ol>
              <li>
                Custom label: the name for the service that will be shown to survey participants.
              </li>
              <li>
                Level of Care: determines which survey participants are asked about this service.
              </li>
              <li>
                Department: map the service to a department to compare employee and customer
                engagement scores for the service. E.g. Map Dietary to your Dining department to see
                how customer scores for dining compare to your employee engagement scores for that
                department.
              </li>
            </ol>
            Each service will be asked about on your survey using the following question-statement
            format:
            <br />
            <span className={classes.italic}>I am satisfied with [service custom label].</span>
            <br />
            <br />
            {organization.residentSolution?.standardServicesOnly && (
              <div className={classes.upgradeInfo}>
                <LockIcon />
                Upgrade your current plan to unlock all available services&nbsp;&nbsp;
                <ExternalNavLink
                  to="https://activatedinsights.force.com/help/s/article/Resident-Survey-Services"
                  label="Learn More >"
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow className={cn(classes.tableCellBorders, classes.tableHeader)}>
                <TableCell />
                <TableCell>Service</TableCell>
                <TableCell>Custom Label</TableCell>
                <TableCell className={classes.dropdownColumn}>Levels of Care</TableCell>
                {orgHasDepartments && (
                  <TableCell className={classes.dropdownColumn}>Departments</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceAreaInputs.map(serviceAreaInput => {
                const serviceArea = serviceAreas.find(sa => sa.code === serviceAreaInput.code)!
                const isRequiredService = serviceArea.required
                const requiredLevelOfCareCodes = serviceArea.levelsOfCare
                  .filter(loc => loc.required)
                  .map(loc => loc.code)
                return (
                  <TableRow key={serviceAreaInput.code} hover className={classes.tableCellBorders}>
                    <TableCell>
                      <Tooltip
                        title={isRequiredService ? 'Required, cannot disable' : ''}
                        placement="top"
                      >
                        <div>
                          <Checkbox
                            checked={serviceAreaInput.isActive}
                            // Don't allow deselecting required codes
                            disabled={isRequiredService}
                            onChange={() => {
                              setServiceArea({
                                ...serviceAreaInput,
                                isActive: !serviceAreaInput.isActive,
                              })
                            }}
                            value={serviceAreaInput.code}
                          />
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.serviceCellLabel}>
                      {serviceAreas.find(sa => sa.code === serviceAreaInput.code)?.label}
                    </TableCell>
                    <TableCell className={classes.customLabelCell}>
                      <Input
                        fullWidth
                        onChange={e => {
                          setServiceArea({
                            ...serviceAreaInput,
                            customLabel: e.target.value as string,
                          })
                        }}
                        value={serviceAreaInput.customLabel}
                      />
                    </TableCell>
                    <TableCell className={classes.dropdownColumn}>
                      <CheckboxDropdown
                        menuItems={locOptions.map(loc => ({
                          value: loc.code,
                          text: loc.name,
                          isRequired: requiredLevelOfCareCodes.includes(loc.code),
                          isDisabled: !loc.checked,
                          tooltipMessage: getLOCTooltipMessage(
                            !loc.checked,
                            requiredLevelOfCareCodes.includes(loc.code),
                          ),
                        }))}
                        selectedItems={serviceAreaInput.levelOfCareCodes}
                        onChange={(selected: string[]) => {
                          setServiceArea({ ...serviceAreaInput, levelOfCareCodes: selected })
                        }}
                        width={210}
                        emptyLabel="Select Levels of Care"
                        useTextDropdown
                        id={`LOC${serviceAreaInput.code}`}
                        height={43}
                      />
                    </TableCell>
                    {orgHasDepartments && (
                      <TableCell className={classes.dropdownColumn}>
                        <CheckboxDropdown
                          menuItems={data.departments.map(dept => ({
                            value: dept.uuid,
                            text: dept.name,
                          }))}
                          selectedItems={serviceAreaInput.departmentUuids}
                          onChange={(selected: string[]) => {
                            setServiceArea({ ...serviceAreaInput, departmentUuids: selected })
                          }}
                          width={210}
                          emptyLabel="Select Departments"
                          useTextDropdown
                          height={43}
                          id={`Dept${serviceAreaInput.code}`}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {organization.residentSolution?.standardServicesOnly && (
            <div className={classes.upgradePromo}>
              <Typography variant="h6">
                <LockIcon />
                Upgrade your current plan to unlock all available services
              </Typography>
              <img src={UpgradePromoImage} alt="Services when you upgrade" />
            </div>
          )}
        </div>
      </Card>
      <Errors errors={errors} />
      <SubmitButton
        className={classes.saveButton}
        isSubmitting={loading}
        right
        buttonType="button"
        onClick={async () => {
          const inputErrors = getInputErrors(serviceAreaInputs)
          if (inputErrors) {
            setErrors(inputErrors)
            return
          }
          setErrors(null)

          const result = await updateServiceAreas({
            variables: { serviceAreas: serviceAreaInputs },
          })
          const success = handleMutationResponse(
            result.data?.updateOrganizationServiceAreas?.errors,
            'Organization Updated',
          )
          if (success) {
            onServicesChange && onServicesChange(false)
            onSave && onSave()
          }
        }}
        color="primary"
      >
        Save
      </SubmitButton>
    </>
  )
}

type Props = {
  onSave?(): void
  // Used to notify the parent component of changes so we show an alert before the user switches tabs.
  onServicesChange?(servicesContainChanges: boolean): void
}
const ServiceAreasTab: React.FC<Props> = ({ onSave, onServicesChange }) => {
  const client = useApolloClient()
  const {
    currentUser: { organization },
  } = client.readQuery({
    query: CurrentUserDocument,
  }) as NonNullable<CurrentUserQuery>
  const result = useSettingsOrganizationServiceAreasQuery({
    variables: {
      departmentDtCode: BenchmarkCodeType.DEPARTMENT_NAME,
    },
  })
  return (
    <ResponseHandler {...result}>
      {data => {
        return (
          <ServicesCard
            onSave={onSave}
            onServicesChange={onServicesChange}
            data={data}
            organization={organization}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default ServiceAreasTab
