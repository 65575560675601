import React, { memo, useState, useEffect } from 'react'

import { makeStyles, IconButton } from '@material-ui/core'
import ArrowDown from '@material-ui/icons/ArrowDropDown'
import ArrowUp from '@material-ui/icons/ArrowDropUp'
import ReactEcharts from 'echarts-for-react'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'

import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import { chartTextStyle } from 'components/Insights/InsightsStyle'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import StandardTooltip, {
  TooltipRow,
} from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import {
  getTimeTrendingScoreDelta,
  ScoreTypeGroups,
  SurveyScoreByGroup,
} from 'components/Insights/TimeTrending/utils'
import { colors } from 'shared/theme'
import { formatTooltipScore } from 'utils'
import { TIME_TRENDING_LEGEND_COLORS } from 'utils/constants'
import { getFormattedSurveyDate } from 'utils/dateUtils'
import {
  timetrendXAxis,
  surveysYAxis,
  gridLines,
  surveysTooltip,
  benchmarkLine,
  lineSeriesVars,
  getBenchmarkLegendData,
  TimeTrendXAxisLabelData,
} from 'utils/echartsHelpers'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    marginLeft: '5%',
  },
  pagination: {
    /** Use absolute positioning so we can fake that the pagination is on the chart */
    position: 'absolute',
    bottom: 34,
    width: '25%',
    left: '-3%',
    borderTop: `1px solid ${theme.palette.common.navy15}`,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& >button': {
      padding: 0,
      color: theme.palette.common.secondary,
    },
  },
}))

const tooltipFormatter = ({
  orderedGroupNames,
  surveyScoresByGroup,
  companyOverallScores,
  xAxisIndex,
  xAxisLabelData,
  benchmarkName,
  benchmarkScore,
  isBenchmark,
  includesPulse,
  minShowableResults,
  colorList,
}: {
  orderedGroupNames: string[]
  surveyScoresByGroup: SurveyScoreByGroup
  companyOverallScores?: ScoreTypeGroups[0]
  xAxisIndex: number
  xAxisLabelData: TimeTrendXAxisLabelData
  benchmarkName: string
  benchmarkScore: { positive: number; inconsistent: number; negative: number }
  isBenchmark: boolean
  includesPulse: boolean
  minShowableResults: number
  colorList: string[]
}) => {
  if (isBenchmark) {
    return renderEchartTooltip(
      <StandardTooltip
        title="Benchmark Score"
        description={`Selected Benchmark: ${benchmarkName}`}
        rows={[
          {
            label: 'Positive:',
            value: formatTooltipScore(benchmarkScore.positive, minShowableResults),
            color: colors.success,
          },
          {
            label: 'Neutral:',
            value: formatTooltipScore(benchmarkScore.inconsistent, minShowableResults),
            color: colors.warning,
          },
          {
            label: 'Negative:',
            value: formatTooltipScore(benchmarkScore.negative, minShowableResults),
            color: colors.danger,
          },
        ]}
      />,
    )
  }
  const xAxisData = xAxisLabelData[xAxisIndex]
  const groupedScoresInfo = orderedGroupNames.map((filterValue, idx) => ({
    label: `${filterValue}:`,
    value: formatTooltipScore(
      surveyScoresByGroup[filterValue][xAxisData.uuid],
      xAxisData.minShowableResults,
    ),
    color: colorList[idx % TIME_TRENDING_LEGEND_COLORS.length],
    detail: xAxisIndex > 0 && (
      <ScoreChangeArrow
        delta={getTimeTrendingScoreDelta(
          xAxisData.uuid,
          surveyScoresByGroup[filterValue],
          xAxisLabelData,
        )}
      />
    ),
  }))
  let title = getFormattedSurveyDate({ ...xAxisData, includeDay: true })
  if (includesPulse) {
    title += ' — Key Statement Score'
  }
  const rows: TooltipRow[] = groupedScoresInfo
  if (companyOverallScores) {
    rows.push({
      label: 'Company Overall:',
      value: formatTooltipScore(companyOverallScores[xAxisIndex][1], xAxisData.minShowableResults),
      detail: xAxisIndex > 0 && (
        <ScoreChangeArrow
          delta={companyOverallScores[xAxisIndex][1]! - companyOverallScores[xAxisIndex - 1][1]!}
        />
      ),
    })
  }
  return renderEchartTooltip(
    <StandardTooltip title={title} description={`Survey: ${xAxisData.name}`} rows={rows} />,
  )
}

const indexIsVisible = (index: number, scrollDataIndex: number, pageSize: number) => {
  return index >= scrollDataIndex && index < scrollDataIndex + pageSize
}

export type SelectedLegends = { [legend: string]: boolean }
type Props = {
  xAxisLabelData: TimeTrendXAxisLabelData
  groupedScores: ScoreTypeGroups
  // Separating company scores from grouped scores so that we fix company scores while group scores scroll.
  companyOverallScores?: ScoreTypeGroups[0] // Only the positive scores.
  benchmarkName: string
  benchmarkScore?: { positive: number; inconsistent: number; negative: number }
  title: string
  didSelectFilters: boolean
  orderedGroupNames: string[]
  surveyScoresByGroup: SurveyScoreByGroup
  minShowableResults: number
  chartWidth?: string
  includesPulse?: boolean
  colorList?: string[]
  maxLabelLength?: number
  tooltipColorList?: string[]
}
const TTGroupedScoresChart: React.FC<Props> = ({
  xAxisLabelData,
  groupedScores,
  companyOverallScores,
  benchmarkName,
  benchmarkScore,
  title,
  didSelectFilters,
  orderedGroupNames,
  surveyScoresByGroup,
  minShowableResults,
  chartWidth = '100%',
  includesPulse = false,
  colorList = TIME_TRENDING_LEGEND_COLORS,
  maxLabelLength = 18,
  tooltipColorList,
}) => {
  const classes = useStyles()
  const [scrollDataIndex, setScrollDataIndex] = useState(0)
  // Keep legend selection in state so that we make sure to prevent resetting them when the sort changes.
  const [selectedLegends, setSelectedLegends] = useState<SelectedLegends>({})
  // Only reset legend selection when the content of groups is changed (not their order)
  // Make sure not to mutate the order when generating this hash
  const namesHash = [...orderedGroupNames].sort().join()
  useEffect(() => {
    setSelectedLegends({})
  }, [namesHash])

  const groupLegendData = orderedGroupNames.map((label, index) => ({
    name: label.length > maxLabelLength ? `${label.slice(0, maxLabelLength)}...` : label,
    icon: 'circle',
    color: colorList[index % colorList.length],
  }))
  const pageSize = 8
  const sliceSize = Math.min(orderedGroupNames.length, scrollDataIndex + pageSize)

  const companyLegendName = didSelectFilters ? 'Selected Filters' : 'Company Overall'
  const benchmarkLegendData = getBenchmarkLegendData(companyLegendName, benchmarkName)
  const pageUp = () => {
    // If we would be scrolling below 0, set the index to 0.
    if (scrollDataIndex - pageSize < 0) {
      setScrollDataIndex(0)
    } else {
      setScrollDataIndex(scrollDataIndex - pageSize)
    }
  }
  const pageDown = () => {
    // If the next page would extend beyond the length of data,
    // set the index to the position that shows the last full page of data.
    if (groupLegendData.length < scrollDataIndex + pageSize * 2) {
      setScrollDataIndex(groupLegendData.length - pageSize)
    } else {
      setScrollDataIndex(scrollDataIndex + pageSize)
    }
  }
  const chartHeight = 375
  return (
    // 18 is the exact height displacement to align the pagination with the bottom of the chart.
    <div className={classes.container} style={{ width: chartWidth }}>
      <ReactEcharts
        notMerge
        opts={{ renderer: 'svg' }} // Useful for selecting DOM elements in UItests
        style={{
          width: '100%',
          height: chartHeight,
        }}
        onEvents={{
          legendselectchanged: ({ selected }: { selected: SelectedLegends }) => {
            setSelectedLegends(selected)
          },
        }}
        option={{
          grid: { top: 40, left: 210, right: 40 },
          title: {
            text: title,
            textStyle: {
              ...chartTextStyle,
              fontWeight: 'normal',
              fontSize: 16,
            },
          },
          textStyle: chartTextStyle,
          legend: [
            {
              animation: false,
              selected: selectedLegends,
              type: 'scroll',
              orient: 'vertical',
              data: groupLegendData,
              left: 0,
              top: 0,
              textStyle: chartTextStyle,
              itemGap: 20,
              itemHeight: 12,
              // up, right, down, left
              padding: [50, 0, 40, 20],
              scrollDataIndex,
              // Hide the echarts pagination so we can render our own
              pageIconSize: 0,
              pageFormatter: () => '',
            },
            {
              data: benchmarkLegendData,
              align: 'right',
              top: 0,
              right: '10%',
              width: '100%',
              textStyle: chartTextStyle,
            },
          ],
          xAxis: timetrendXAxis({
            axisLabelData: xAxisLabelData,
            labelDisplayStrategy: 'every',
            useDateLabel: true,
            margin: 16,
            maxLabelLineLength: 15,
          }),
          yAxis: surveysYAxis({ max: null }),
          series: [
            ...groupedScores.map((scores, groupIndex) => {
              // Hide line segments for labels not visible in the legend view.
              return {
                lineStyle: {
                  width: 2.5,
                  opacity: indexIsVisible(groupIndex, scrollDataIndex, pageSize) ? 1 : 0,
                },
                symbolSize: 7,
                data: scores,
                type: 'line',
                name: groupLegendData[groupIndex].name,
              }
            }),
            companyOverallScores && {
              data: companyOverallScores,
              ...lineSeriesVars,
              name: companyLegendName,
              color: colors.navy,
            },
            gridLines({ numCategories: xAxisLabelData.length }),
            benchmarkScore &&
              benchmarkLine({
                color: colors.navy,
                name: benchmarkLegendData[1].name,
                value: benchmarkScore.positive,
                type: 'dashed',
                showLabel: true,
              }),
          ],
          color: colorList,
          tooltip: {
            // Use 'item' trigger so that we can render a tooltip for the benchmark line/label.
            ...surveysTooltip({ trigger: 'item', fixedPosition: true }),
            axisPointer: {
              type: 'cross',
            },
            formatter: (series: { dataIndex: number; seriesName: string }) => {
              // Ignore when the user hovers over the x-axis
              if (series.seriesName !== benchmarkName && isNil(series.dataIndex)) {
                return null
              }
              return tooltipFormatter({
                // Limit data on the tooltip to the current page
                orderedGroupNames: orderedGroupNames.slice(
                  scrollDataIndex,
                  scrollDataIndex + pageSize,
                ),
                surveyScoresByGroup,
                companyOverallScores,
                xAxisIndex: series.dataIndex,
                xAxisLabelData,
                benchmarkName,
                benchmarkScore: benchmarkScore!,
                isBenchmark: series.seriesName === benchmarkName,
                includesPulse,
                minShowableResults,
                colorList: tooltipColorList || colorList,
              })
            },
          },
        }}
      />
      {groupLegendData.length > pageSize && (
        <div className={classes.pagination}>
          <IconButton disabled={scrollDataIndex === 0} onClick={pageUp}>
            <ArrowUp />
          </IconButton>
          <span>{`${scrollDataIndex + 1}-${sliceSize} of ${groupLegendData.length}`}</span>
          <IconButton
            disabled={scrollDataIndex + pageSize >= groupLegendData.length}
            onClick={pageDown}
          >
            <ArrowDown />
          </IconButton>
        </div>
      )}
    </div>
  )
}

// Prevent rerendering the chart when props haven't changed.
// The default shallow comparison will rerender when the surveys object is regenerated by other components.
const arePropsEqual = (prevProps: Props, nextProps: Props) => {
  // Only look at the relevant properties that could rerender the chart data.
  return (
    isEqual(prevProps.surveyScoresByGroup, nextProps.surveyScoresByGroup) &&
    isEqual(prevProps.orderedGroupNames, nextProps.orderedGroupNames)
  )
}

export default memo(TTGroupedScoresChart, arePropsEqual)
