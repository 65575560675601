import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import ReactEcharts from 'echarts-for-react'
import isNil from 'lodash/isNil'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import DashedBlueLine from 'assets/img/echarts/dashed-blue-line.png'
import LonelyChartIcon from 'assets/img/lonely-chart-icon.svg'
import NotLonelyChartIcon from 'assets/img/not-lonely-chart-icon.svg'
import SomewhatLonelyChartIcon from 'assets/img/somewhat-lonely-chart-icon.svg'
import ScoreChangeArrow from 'components/ActionPlans/ScoreChangeArrow'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import useInsightsStyles, { chartTextStyle } from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import { LonelinessTooltip } from 'components/Insights/ResidentSnapshot/LonelinessCard'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import StandardTooltip from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import TimeTrendingContainer from 'components/Insights/TimeTrending/TimeTrendingContainer'
import { ScoreTypeGroups } from 'components/Insights/TimeTrending/utils'
import { TimeTrendingChartKey, TimeTrendingSurveysKey } from 'config/LocalStorage'
import {
  InsightsLonelinessSummaryQuery,
  useInsightsLonelinessSummaryQuery,
} from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import { colors } from 'shared/theme'
import { formatScore, formatTooltipScore, getFirstViridisColors } from 'utils'
import { MIN_SHOWABLE_RESULTS } from 'utils/constants'
import { getFormattedSurveyDate } from 'utils/dateUtils'
import {
  timetrendXAxis,
  surveysYAxis,
  gridLines,
  surveysTooltip,
  benchmarkLine,
  lineSeriesVars,
  surveysTopLegend,
} from 'utils/echartsHelpers'
import { SurveyNode } from 'utils/types'

const useStyles = makeStyles(() => ({
  chartRow: {
    display: 'flex',
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '25%',
  },
  tooltipBody: {
    minWidth: 900,
    borderBottom: 0,
  },
}))

type SurveysData = Array<{ uuid: string } & InsightsLonelinessSummaryQuery>
const transformLonelinessDataToEcharts = (data: SurveysData) => {
  const scoreTypeGroups: ScoreTypeGroups = []
  data.forEach(
    (
      { insightsLonelinessSummary: { numLonely, numSomewhatLonely, numNotLonely } },
      surveyIndex,
    ) => {
      const total = numLonely + numNotLonely + numSomewhatLonely
      ;[numLonely, numSomewhatLonely, numNotLonely].forEach((score, typeIndex) => {
        if (!scoreTypeGroups[typeIndex]) {
          scoreTypeGroups[typeIndex] = []
        }
        const rate = total < MIN_SHOWABLE_RESULTS ? null : formatScore((score / total) * 100)
        scoreTypeGroups[typeIndex].push([surveyIndex, rate])
      })
    },
  )
  return scoreTypeGroups
}

type ChartProps = {
  surveys: SurveyNode[]
  scoreTypeGroups: ScoreTypeGroups
  benchmarkScore: number
}
const Chart: React.FC<ChartProps> = ({ surveys, scoreTypeGroups, benchmarkScore }) => {
  const legendColors = getFirstViridisColors()
  const benchmarkName = 'National Benchmark'
  const legendData = [
    { name: 'Lonely', icon: `image://${LonelyChartIcon}` },
    { name: 'Somewhat lonely', icon: `image://${SomewhatLonelyChartIcon}` },
    { name: 'Not lonely', icon: `image://${NotLonelyChartIcon}` },
    { name: benchmarkName, icon: `image://${DashedBlueLine}` },
  ]
  return (
    <ReactEcharts
      opts={{ renderer: 'svg' }} // Useful for selecting DOM elements in UItests
      notMerge
      style={{
        width: '100%',
        height: 260,
      }}
      option={{
        grid: { bottom: 35, top: 40, left: 40, right: 40 },
        textStyle: chartTextStyle,
        xAxis: {
          ...timetrendXAxis({
            axisLabelData: surveys,
            labelDisplayStrategy: 'every',
            maxLabelLineLength: 15,
            useDateLabel: true,
            margin: 16,
          }),
        },
        yAxis: { ...surveysYAxis({ max: null }), interval: 25 },
        series: [
          ...scoreTypeGroups.map((scores, idx) => ({
            data: scores,
            ...lineSeriesVars,
            name: legendData[idx].name,
          })),
          benchmarkLine({
            value: benchmarkScore,
            color: colors.navy,
            type: 'dashed',
            name: benchmarkName,
            showLabel: true,
          }),
          gridLines({ numCategories: surveys.length }),
        ],
        color: legendColors,
        tooltip: {
          // Use 'item' trigger so that we can render a tooltip for the benchmark line/label.
          ...surveysTooltip({ trigger: 'item', fixedPosition: true }),
          axisPointer: {
            type: 'cross',
          },
          formatter: (series: { dataIndex: number; seriesName: string }) => {
            // Ignore when the user hovers over the x-axis
            if (series.seriesName !== 'National' && isNil(series.dataIndex)) {
              return null
            }
            const { dataIndex } = series
            const survey = surveys[dataIndex]
            return renderEchartTooltip(
              <StandardTooltip
                title={getFormattedSurveyDate({ endDate: survey.endDate!, includeDay: true })}
                description={`Survey: ${survey.name}`}
                rows={[
                  {
                    label: 'Lonely:',
                    value: formatTooltipScore(
                      scoreTypeGroups[0][dataIndex][1],
                      survey.minShowableResults,
                    ),
                    color: legendColors[0],
                  },
                  {
                    label: 'Somewhat Lonely:',
                    value: formatTooltipScore(
                      scoreTypeGroups[1][dataIndex][1],
                      survey.minShowableResults,
                    ),
                    color: legendColors[1],
                  },
                  {
                    label: 'Not Lonely:',
                    value: formatTooltipScore(
                      scoreTypeGroups[2][dataIndex][1],
                      survey.minShowableResults,
                    ),
                    color: legendColors[2],
                  },
                  {
                    label: benchmarkName,
                    value: formatTooltipScore(benchmarkScore, MIN_SHOWABLE_RESULTS),
                  },
                ]}
              />,
            )
          },
        },
        legend: [{ ...surveysTopLegend, data: legendData }],
      }}
    />
  )
}

type Props = {
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  filters: string[]
}
const TTLonelinessCard: React.FC<Props> = ({ survey, availableSurveys, filters }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <div className={classes.fullRow} id="tt-loneliness-snap">
      <SnapshotChartHeader
        title="Understanding Loneliness Over Time"
        tooltip={<LonelinessTooltip />}
        tooltipProps={{ classes: { tooltip: classes.tooltipBody } }}
        hasTimeTrending
        timeTrendingChartKey={TimeTrendingChartKey.RESIDENT_LONELINESS}
        snapId="tt-loneliness-snap"
        screenshotStrategy="svg"
        useBottomPadding={false}
      />
      <TimeTrendingContainer
        chartSurveysKey={TimeTrendingSurveysKey.RESIDENT_LONELINESS}
        availableSurveys={availableSurveys.filter(s => s.includesLonelinessQuestions)}
        survey={survey}
      >
        {({ selectedSurveys }) => (
          <DynamicSurveyQuery
            surveys={selectedSurveys}
            variables={{ filters }}
            queryHook={useInsightsLonelinessSummaryQuery}
          >
            {data => {
              const scoreTypeGroups = transformLonelinessDataToEcharts(data)
              // For some calculations, ignore surveys with <minShowableResults responses.
              const validScoreGroups = scoreTypeGroups[0].filter(
                scoreGroup => scoreGroup[1] !== null,
              )
              if (validScoreGroups.length < 2) {
                return (
                  <EmptyState
                    title="Oh Snap!"
                    description={`One of the surveys you selected has fewer than ${MIN_SHOWABLE_RESULTS} responses. Please select another survey or change your filters.`}
                    Icon={ChartUpwardIcon}
                  />
                )
              }
              const delta =
                Number(validScoreGroups[validScoreGroups.length - 1][1]) -
                Number(validScoreGroups[0][1])
              let deltaText = 'stayed the same'
              if (delta > 0) {
                deltaText = `increased ${delta}%`
              } else if (delta < 0) {
                deltaText = `decreased ${Math.abs(delta)}%`
              }
              return (
                <div className={classes.chartRow}>
                  <div className={classes.infoBox}>
                    <ScoreChangeArrow delta={delta} fontSize="3.6rem" reverseColors />
                    <Typography variant="body2" color="textSecondary">
                      Over time your average loneliness score has {deltaText}.
                    </Typography>
                  </div>

                  <Chart
                    scoreTypeGroups={scoreTypeGroups}
                    surveys={selectedSurveys}
                    benchmarkScore={26.6}
                  />
                </div>
              )
            }}
          </DynamicSurveyQuery>
        )}
      </TimeTrendingContainer>
    </div>
  )
}

export default TTLonelinessCard
