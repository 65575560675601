import React from 'react'

import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import {
  useSurveysDeleteParticipantMutation,
  SurveysParticipantsBySurveyQuery,
} from 'generated/graphql'

type Props = {
  participantUuid: string
  onClose(): void
}

const DeleteParticipantContainer: React.FC<Props> = ({ participantUuid, onClose }) => {
  const [deleteParticipant] = useSurveysDeleteParticipantMutation()
  return (
    <ActionDialog
      title="Delete Participant?"
      content={"Once this participant is deleted, they won't be able to take the survey."}
      submitButtonText="Delete"
      onClose={onClose}
      onSubmit={() => {
        deleteParticipant({
          variables: { participantUuid },
          update(cache) {
            cache.modify({
              fields: {
                participantsBySurvey(
                  result: SurveysParticipantsBySurveyQuery['participantsBySurvey'],
                  { readField },
                ) {
                  return {
                    ...result,
                    participants: result.participants.filter(
                      p => readField('uuid', p) !== participantUuid,
                    ),
                  }
                },
              },
            })
          },
        })
        onClose()
      }}
    />
  )
}

export default DeleteParticipantContainer
