import React, { useState, ReactNode } from 'react'

import {
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    flexGrow: 1,
  },
  accordion: {
    boxShadow: 'none',
    '&:before': {
      display: 'none !important',
    },
  },
  accordionExpanded: {
    margin: 0,
  },
  accordionSummary: {
    borderTop: `1px solid ${palette.common.navy25}`,
    '&:hover': {
      backgroundColor: palette.common.navy05,
    },
  },
  accordionSummaryContent: {
    // This class is applied twice so opacity stacks up
    backgroundColor: 'transparent !important',
  },
  accordionSummaryExpanded: {
    backgroundColor: palette.common.navy05,
  },
  accordionSummaryExpandIcon: {
    // This class is applied twice so opacity stacks up
    backgroundColor: 'transparent !important',
    color: palette.common.secondary,
    right: spacing(8),
  },
  accordionDetails: {
    backgroundColor: palette.common.navy05,
    borderTop: `1px solid ${palette.common.navy25}`,
  },
  title: {
    color: palette.common.secondary,
    marginLeft: spacing(8),
  },
  content: {
    marginLeft: spacing(4),
    '& >*': {
      marginLeft: spacing(4),
      padding: 0,
    },
  },
}))

type Props = {
  id?: string
  collapses: { title: string; content: ReactNode }[]
}

const AccordionComponent: React.FC<Props> = ({ id, collapses }) => {
  const classes = useStyles()
  const [active, setActive] = useState(-1)

  return (
    <div className={classes.root} id={id}>
      {collapses.map((group, index) => (
        <Accordion
          id={group.title}
          key={index}
          expanded={active === index}
          onChange={(e, expanded) => {
            setActive(expanded ? index : -1)
          }}
          classes={{
            root: classes.accordion,
            expanded: classes.accordionExpanded,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              root: classes.accordionSummary,
              expanded: classes.accordionSummaryExpanded,
              content: classes.accordionSummaryContent,
              expandIcon: classes.accordionSummaryExpandIcon,
            }}
          >
            <Typography className={classes.title} variant="subtitle1">
              {group.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <div key={index} className={classes.content}>
              {group.content}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default AccordionComponent
