import React, { useState } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import orderBy from 'lodash/orderBy'
import zip from 'lodash/zip'

import HorizontalBarChart, {
  formatChartData,
  TooltipSeries,
  DARK_PURPLE,
} from 'components/Blocks/Charts/HorizontalBarChart'
import HorizontalChartTooltip from 'components/Blocks/Charts/HorizontalChartTooltip'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import BarChartSortButton from 'components/Insights/Blocks/BarChartSortButton'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsBenchmark } from 'components/Insights/InsightsTypes'
import ServicesBreakdownChartModal from 'components/Insights/ResidentSnapshot/ServicesBreakdownChartModal'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { gaEvent } from 'config/ga'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import {
  InsightsStatementsQuery,
  useInsightsOrganizationServicesQuery,
  QResidentFocus,
  InsightsStatementScoreFragment,
  InsightsSurveyQuery,
  FilterTypeFragment,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { MIN_SHOWABLE_RESULTS_CODE, ORDER_TYPES, SORT_OPTIONS } from 'utils/constants'

const useStyles = makeStyles(() => ({
  chartContainer: {
    position: 'relative',
    marginLeft: '10%',
    marginRight: '10%',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& >div': {
      textTransform: 'uppercase',
      '& >button': {
        margin: 0,
        padding: 0,
      },
    },
  },
}))
type Props = {
  statements: (InsightsStatementsQuery['statements'][0] | InsightsStatementScoreFragment)[]
  survey: InsightsSurveyQuery['survey']
  visibleFilterTypes: FilterTypeFragment[]
  filters: string[]
  overallPositiveScore: number
  benchmark: InsightsBenchmark
  hasTimeTrending: boolean
}
export type StatementsByService = { [key: string]: Props['statements'] }

type Score = { positive?: number; label: string }
export const getServiceScores = (
  statementsByService: StatementsByService,
  currentSort: SORT_OPTIONS,
  color?: string,
): Score[][] => {
  // Returns [[ServiceScores, ServiceBenchmarkScores]]
  let scores = Object.keys(statementsByService).map(label => {
    const stmts = statementsByService[label]
    const greaterThanMinStmts = stmts.filter(stmt => stmt.positive !== MIN_SHOWABLE_RESULTS_CODE)
    let averageScore = MIN_SHOWABLE_RESULTS_CODE
    // If there are no statements with greater than min responses, default score to show min results code.
    // Otherwise, calculate the average score by filtering out the statements with less than min.
    if (greaterThanMinStmts.length > 0) {
      const totalScore = greaterThanMinStmts.reduce((acc, stmt) => acc + stmt.positive, 0)
      averageScore = Math.round(totalScore / greaterThanMinStmts.length)
    }
    // Only include statements used in the benchmark for calculating the average.
    const benchmarkStatements = stmts.filter(stmt => {
      return stmt.benchmarkPositive !== null && stmt.benchmarkPositive !== MIN_SHOWABLE_RESULTS_CODE
    })
    const totalBenchmark = benchmarkStatements.reduce(
      (acc, stmt) => acc + (stmt.benchmarkPositive || 0),
      0,
    )
    const averageBenchmark = Math.round(totalBenchmark / benchmarkStatements.length)
    return [
      { positive: averageScore, label, color },
      { positive: averageBenchmark, label, color: colors.purple },
    ]
  })
  if (currentSort === SORT_OPTIONS.A_TO_Z) {
    scores = orderBy(scores, ([serviceScore]) => serviceScore.label)
  } else if (currentSort === SORT_OPTIONS.LOW_TO_HIGH) {
    scores = orderBy(scores, ([serviceScore]) => serviceScore.positive)
  } else {
    scores = orderBy(scores, ([serviceScore]) => serviceScore.positive, ORDER_TYPES.DESCENDING)
  }
  // Per https://github.com/DefinitelyTyped/DefinitelyTyped/pull/23305
  // zipped arrays can have undefined values if their lengths are of a different size.
  // We know that they are not different lengths, so cast to the appropriate type
  // after asserting the correct type is returned from the zip.
  const zippedScores: Array<Array<Score | undefined>> = zip(...scores)
  return zippedScores as Score[][]
}

export const getStatementsByService = (
  statements: Props['statements'],
  serviceAreas: Array<{
    label: string
    questionLabel: string
    questionCodes: string[]
    code: string
  }>,
  filterLessThanMins = false,
): StatementsByService => {
  const statementsByService: StatementsByService = {}
  statements
    .filter(
      stmt =>
        stmt.residentFocus === QResidentFocus.SERVICE_AREAS &&
        (!filterLessThanMins || stmt.positive !== MIN_SHOWABLE_RESULTS_CODE),
    )
    .forEach(stmt => {
      const serviceArea = serviceAreas.find(sa => sa.questionCodes?.includes(stmt.code))
      const label = serviceArea?.questionLabel
      if (!label) return
      if (!statementsByService[label]) {
        statementsByService[label] = [stmt]
      } else {
        statementsByService[label].push(stmt)
      }
    })
  return statementsByService
}

const ServicesChart: React.FC<Props> = ({
  statements,
  survey,
  visibleFilterTypes,
  filters,
  overallPositiveScore,
  benchmark,
  hasTimeTrending,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [currentSort, setCurrentSort] = useState(SORT_OPTIONS.A_TO_Z)
  const [selectedService, setSelectedService] = useState<string | null>(null)
  const [breakdownModalOpen, setBreakdownModalOpen] = useState(false)
  const orgResult = useInsightsOrganizationServicesQuery()
  return (
    <ResponseHandler {...orgResult}>
      {({ currentUser }) => {
        const statementsByService = getStatementsByService(
          statements,
          currentUser.organization.serviceAreas,
        )
        const serviceScores = getServiceScores(statementsByService, currentSort)
        if (serviceScores.length === 0) return <div />
        const sortedServiceLabels = serviceScores[0].map(s => s.label)
        return (
          <div id="compare-by-services-snap" className={classes.fullRow}>
            <SnapshotChartHeader
              title="Compare by Services"
              description="See where to focus your efforts to improve and which services to promote."
              tooltip={
                <div>
                  <Typography>Service Score:</Typography>
                  <Typography color="textSecondary">
                    Respondents are given a linear scale rating (1 to 5) to answer the service
                    questions on the survey. The scores let you know who is satisfied, neutral, or
                    unhappy with each service area: Positive responses (4-5), Inconsistent responses
                    (3), and Negative responses (1-2). The Service Score is the percentage of
                    satisfied customers, who gave a positive response to the question. Some
                    services, like Dining, have multiple associated questions. In that case, the
                    service score is the composite positive score across all of the associated
                    statements.
                  </Typography>
                  <br />
                  <Typography>How we calculate the Service Score:</Typography>
                  <Typography color="textSecondary">
                    Number of positive answers ÷ Total number of answers overall x 100 = Service
                    Score %
                  </Typography>
                </div>
              }
              onSeeMore={() => {
                gaEvent({
                  action: 'seeMoreServices',
                  category: 'Snapshot',
                })
                setBreakdownModalOpen(true)
              }}
              snapId="compare-by-services-snap"
              hasTimeTrending={hasTimeTrending}
              timeTrendingChartKey={TimeTrendingChartKey.RESIDENT_SERVICES}
            />
            <div className={classes.chartContainer}>
              <HorizontalBarChart
                chartData={formatChartData(serviceScores)}
                chartWidth="100%"
                onBarClick={(_, name) => {
                  setSelectedService(name)
                  setBreakdownModalOpen(true)
                  gaEvent({
                    action: 'seeMoreServices',
                    category: 'Snapshot',
                  })
                }}
                benchmarkData={[
                  {
                    name: filters.length ? 'Applied Filters CES' : 'Company Overall CES',
                    score: overallPositiveScore,
                  },
                ]}
                extraLegends={[
                  {
                    name: filters.length
                      ? 'Applied Filters - Service Score'
                      : 'Company Overall - Service Score',
                    color: DARK_PURPLE,
                  },
                  { name: `${benchmark.name} - Service Score`, color: colors.purple },
                ]}
                useTopLegends
                tooltipFormatter={(series: TooltipSeries) => {
                  return (
                    <HorizontalChartTooltip
                      scores={[
                        { ...series[0].data, name: `${series[0].data.name} Score` },
                        {
                          name: `${benchmark.name} Service Score`,
                          value: series[1].data.value,
                          lessThanMin: series[1].data.lessThanMin,
                        },
                        {
                          name: filters.length ? 'Applied Filters CES' : 'Company Overall CES',
                          value: overallPositiveScore,
                        },
                      ]}
                    />
                  )
                }}
              />
              <div className={classes.chartSortButton2}>
                <BarChartSortButton
                  currentSort={currentSort}
                  handleChangeSort={newSort => setCurrentSort(newSort)}
                />
              </div>
            </div>
            {breakdownModalOpen && (
              <ServicesBreakdownChartModal
                onClose={() => {
                  setSelectedService(null)
                  setBreakdownModalOpen(false)
                }}
                statementsByService={statementsByService}
                surveyUuid={survey.uuid}
                surveyName={survey.name}
                visibleFilterTypes={visibleFilterTypes}
                selectedFilters={filters}
                sortedServiceLabels={sortedServiceLabels}
                openedToService={selectedService}
                overallPositiveScore={overallPositiveScore}
                benchmarkScores={serviceScores[1]}
                benchmarkName={benchmark.name}
              />
            )}
          </div>
        )
      }}
    </ResponseHandler>
  )
}
export default ServicesChart
