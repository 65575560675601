import React from 'react'

import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import { useSurveysDeleteSurveyMutation, SurveysSurveysQuery } from 'generated/graphql'

type Props = {
  onClose(): void
  onDelete(): void
  surveyUuid: string
}
const DeleteSurveyDialog: React.FC<Props> = ({ onClose, onDelete, surveyUuid }) => {
  const [deleteSurvey] = useSurveysDeleteSurveyMutation()
  return (
    <ActionDialog
      title="Delete Draft?"
      content="Are you sure you want to delete your draft? This action cannot be undone."
      submitButtonText="Delete"
      onClose={onClose}
      onSubmit={() => {
        deleteSurvey({
          variables: { uuid: surveyUuid },
          update(cache) {
            cache.modify({
              fields: {
                surveys(result: SurveysSurveysQuery['surveys'], { readField }) {
                  return {
                    ...result,
                    edges: result.edges.filter(
                      s => s && readField('uuid', s?.node!) !== surveyUuid,
                    ),
                  }
                },
              },
            })
          },
        })
        onDelete()
      }}
    />
  )
}

export default DeleteSurveyDialog
