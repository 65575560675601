import React from 'react'

import { makeStyles, Grid, Typography, Card } from '@material-ui/core'
import cn from 'classnames'
import { subMonths } from 'date-fns'
import { NavLink } from 'react-router-dom'

import { ReactComponent as BuildingIcon } from 'assets/img/building-icon.svg'
import * as LocationOnlyGptwBadge from 'assets/img/certification_hub/location-only-gptw-badge.png'
import * as NotCertifiedBadge from 'assets/img/certification_hub/not-certified-badge.png'
import * as UpgradePromoIcon from 'assets/img/certification_hub/upgrade-promo-icon.png'
import { ReactComponent as CertifiedCheckIcon } from 'assets/img/certified-check.svg'
import { ReactComponent as WarningIcon } from 'assets/img/warning-icon.svg'
import { ReactComponent as XIcon } from 'assets/img/x-icon.svg'
import SingleBarBreakdownChart from 'components/Blocks/Charts/SingleBarBreakdownChart'
import Button from 'components/Blocks/CustomButtons/Button'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import CertificationHubBanner from 'components/CertificationHub/CertificationHubBanner'
import { CertificationHubTab } from 'components/CertificationHub/CertificationHubContainer'
import CertificationsTable from 'components/CertificationHub/CertificationsTable'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { gaEvent } from 'config/ga'
import {
  CurrentCertificationQuery,
  InsightsModulesEnum,
  LocationCertificationType,
  SurveyProductTypeEnum,
  useCertificationCertifiedLocationsExportDownloadLazyQuery,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { pluralize } from 'utils'
import { URLS } from 'utils/constants'
import { useDownloadMachine } from 'utils/customHooks'
import { getFormattedSurveyDate } from 'utils/dateUtils'
import { CertificationStatusEnum } from 'utils/generatedEnums'
import { getInsightsPage } from 'utils/insightsUtils'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  title: {
    paddingBottom: spacing(2),
  },
  mainBlock: {
    paddingLeft: spacing(),
    paddingRight: spacing(),
    paddingTop: spacing(2),
    '& >p': {
      paddingTop: spacing(),
      lineHeight: '2.8rem',
      display: 'flex',
      alignItems: 'center',
      '& >svg': {
        marginRight: spacing(),
      },
    },
    '& >span': {
      color: palette.common.navy65,
      fontSize: '1.6rem',
    },
  },
  section: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    borderTop: `1px solid ${palette.common.navy25}`,
  },
  card: {
    maxHeight: 260,
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    paddingTop: spacing(1),
    paddingBottom: spacing(3),
    '&:nth-child(1)': {
      marginRight: spacing(3),
    },
  },
  badge: {
    backgroundImage: `linear-gradient(to bottom right, ${palette.common.navy07}, ${palette.common.white})`,
    borderRadius: 250,
    width: 200,
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  locationBadge: {
    width: 200,
    height: 200,
  },
  certifiedText: {
    color: palette.common.success,
  },
  locationsCard: {
    marginLeft: spacing(12),
    marginRight: spacing(12),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    paddingTop: spacing(2),
    paddingBottom: spacing(5),
    backgroundColor: palette.common.navy05,
  },
  barRow: {
    display: 'flex',
    alignItems: 'center',
  },
  detailsBlock: {
    height: 260,
  },
  linksBlock: {
    paddingTop: spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: spacing(2),
    '& >p': {
      fontSize: '1.8rem',
    },
    '& >a, >a>span': {
      display: 'block',
      fontSize: '1.6rem',
    },
    '& >button': {
      display: 'flex',
      justifyContent: 'left',
      margin: 0,
      padding: 0,
    },
  },
}))

type DCProps = {
  organizationName?: string | null
  status: string
  locationCertifications: Array<LocationCertificationType>
  numCertifiedLocations: number
  certifiedEndDate?: string | null
  viewLimitedTo?: 'locations' | 'organization'
  badgeImageUrl?: string | null
}
export const CertificationDetailsCard: React.FC<DCProps> = ({
  organizationName,
  status,
  locationCertifications,
  numCertifiedLocations,
  certifiedEndDate,
  viewLimitedTo,
  badgeImageUrl,
}) => {
  const numLocations = locationCertifications.length
  const showOrgCertification =
    viewLimitedTo !== 'locations' && status === CertificationStatusEnum.CERTIFIED
  const classes = useStyles()

  let subtitle = 'You are Great Place to Work-Certified™'
  if (!showOrgCertification) {
    subtitle = `${numCertifiedLocations} of your locations are Great Place to Work certified™`
  }
  const formattedCertifiedEndDate = getFormattedSurveyDate({
    endDate: certifiedEndDate,
    includeDay: true,
    excludeComma: false,
  })
  let nameToDisplay = organizationName
  let orgCertifiedRow
  let certificationBadge
  if (viewLimitedTo !== 'locations') {
    if (showOrgCertification) {
      orgCertifiedRow = (
        <Typography className={classes.certifiedText}>
          <CertifiedCheckIcon />
          Organization Certified
        </Typography>
      )
      certificationBadge = (
        <div className={classes.badge}>
          {badgeImageUrl && <img height={140} src={badgeImageUrl} alt="GPTW Certification Badge" />}
        </div>
      )
    } else {
      orgCertifiedRow = (
        <Typography color="textSecondary">
          <XIcon />
          Organization Not Certified
        </Typography>
      )
      certificationBadge = (
        <div className={classes.locationBadge}>
          <img height={200} src={LocationOnlyGptwBadge} alt="GPTW Location Certification Badge" />
        </div>
      )
    }
  } else if (numLocations === 1) {
    nameToDisplay = locationCertifications[0].name
    if (numCertifiedLocations > 0) {
      orgCertifiedRow = (
        <Typography className={classes.certifiedText}>
          <CertifiedCheckIcon />
          Location Certified
        </Typography>
      )
      certificationBadge = (
        <div className={classes.locationBadge}>
          <img height={200} src={LocationOnlyGptwBadge} alt="GPTW Location Certification Badge" />
        </div>
      )
    } else {
      orgCertifiedRow = (
        <Typography color="textSecondary">
          <XIcon />
          Location Not Certified
        </Typography>
      )
      certificationBadge = (
        <div className={classes.locationBadge}>
          <img height={140} src={NotCertifiedBadge} alt="Not Certified Badge" />
        </div>
      )
    }
  } else {
    certificationBadge = (
      <div className={numCertifiedLocations > 0 ? classes.locationBadge : classes.badge}>
        <img
          height={numCertifiedLocations > 0 ? 200 : 140}
          src={numCertifiedLocations > 0 ? LocationOnlyGptwBadge : NotCertifiedBadge}
          alt={
            numCertifiedLocations > 0 ? 'GPTW Location Certification Badge' : 'Not Certified Badge'
          }
        />
      </div>
    )
  }
  let locationCertifiedRow
  if (viewLimitedTo === 'organization') {
    locationCertifiedRow = (
      <Typography color="textSecondary">
        <XIcon />
        No Location Level Data Available
      </Typography>
    )
  } else if (numLocations > 1) {
    const numCertifiedText = `${Math.round(
      (numCertifiedLocations / numLocations) * 100,
    )}% of Locations Certified`
    locationCertifiedRow =
      numCertifiedLocations === 0 ? (
        <Typography color="textSecondary">
          <XIcon />
          {numCertifiedText}
        </Typography>
      ) : (
        <Typography className={classes.certifiedText}>
          <CertifiedCheckIcon />
          {numCertifiedText}
        </Typography>
      )
  }
  return (
    <Card className={classes.card}>
      <div className={cn(classes.mainBlock, classes.detailsBlock)}>
        <Typography>Congratulations!</Typography>
        <span color="textSecondary">{subtitle}</span>
        <Typography color="textSecondary">
          <BuildingIcon /> {nameToDisplay}
        </Typography>
        {orgCertifiedRow}
        {locationCertifiedRow}
        <Typography color="textSecondary">
          <WarningIcon />
          Expires {formattedCertifiedEndDate}
        </Typography>
      </div>
      {certificationBadge}
    </Card>
  )
}

type Props = {
  certification: NonNullable<CurrentCertificationQuery['certification']>
  viewLimitedTo?: 'locations' | 'organization'
  onUpdateTab(tab: CertificationHubTab): void
}
const CertifiedView: React.FC<Props> = ({
  certification: {
    status,
    locationCertifications,
    certifiedEndDate,
    organizationName,
    organizationSlug,
    cultureBrief,
    survey,
    uuid: certificationUuid,
    badgeImageUrl,
    badgeZipUrl,
  },
  viewLimitedTo,
  onUpdateTab,
}) => {
  const classes = useStyles()
  const numLocations = locationCertifications.length
  const numCertified = locationCertifications.filter(l => l.isCertified).length
  const numNotCertified = numLocations - numCertified
  const now = new Date()
  const formattedCertifiedEndDate = getFormattedSurveyDate({
    endDate: certifiedEndDate,
    includeDay: true,
    excludeComma: false,
  })
  const [
    downloadCertifiedLocations,
    { data, error },
  ] = useCertificationCertifiedLocationsExportDownloadLazyQuery({
    variables: { certificationUuid },
    fetchPolicy: 'network-only',
  })
  const { state, send } = useDownloadMachine(
    downloadCertifiedLocations,
    Boolean(data?.success),
    error,
  )
  const baseUrl = 'https://activatedinsights.com'
  return (
    <>
      {certifiedEndDate && now >= subMonths(new Date(certifiedEndDate), 1) && (
        <CertificationHubBanner
          title="Your Current Great Place to Work Certification Expires Soon!"
          description={`Sign Up for Next Year’s Certification. Get certified by Great Place to Work today, and get
       game-changing insights to improve employee, resident and family engagement.`}
          imageText={`Expires on ${formattedCertifiedEndDate}`}
          buttonLabel="Sign Up Today"
          backgroundColor={colors.secondaryWarning}
          url={`${baseUrl}/product/certify/`}
        />
      )}
      <div className={classes.section}>
        <Typography className={classes.title}>CURRENT CERTIFICATION</Typography>
        <Grid container>
          <Grid item sm={8}>
            <CertificationDetailsCard
              organizationName={organizationName}
              status={status}
              numCertifiedLocations={numCertified}
              locationCertifications={locationCertifications}
              certifiedEndDate={certifiedEndDate}
              viewLimitedTo={viewLimitedTo}
              badgeImageUrl={badgeImageUrl}
            />
          </Grid>
          <Grid item sm={4}>
            <Card className={classes.card}>
              <div className={cn(classes.linksBlock, classes.detailsBlock)}>
                <Typography>Quick Links</Typography>
                {status === CertificationStatusEnum.CERTIFIED && (
                  <ExternalNavLink
                    label="Profile Page"
                    to={`${baseUrl}/certifications/${organizationSlug}`}
                  />
                )}
                <Button
                  color="secondaryNoBackground"
                  noMargins
                  onClick={() => onUpdateTab(CertificationHubTab.MARKETING)}
                >
                  Marketing Tips
                </Button>
                <ExternalNavLink label="Store" to="https://f4c.store/gptw/" />
                <NavLink
                  to={getInsightsPage(
                    survey.uuid,
                    InsightsModulesEnum.SNAPSHOTS,
                    SurveyProductTypeEnum.EMPLOYEE,
                  )}
                >
                  Trust Index Survey Results
                </NavLink>
                {/** Users without permission don't fetch/view culture brief */}
                {cultureBrief && (
                  <NavLink to={`${URLS.CERTIFICATION_HUB.CULTURE_BRIEF}/${certificationUuid}`}>
                    View Your Culture Brief
                  </NavLink>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <CertificationsTable
          orgIsCertified={status === CertificationStatusEnum.CERTIFIED}
          organizationName={organizationName}
          orgBadgeImageUrl={badgeImageUrl}
          orgBadgeZipUrl={badgeZipUrl}
          locationCertifications={locationCertifications}
          // When org is single-site, limit to the org-only view on this table.
          viewLimitedTo={numLocations === 1 ? 'organization' : viewLimitedTo}
          surveyUuid={survey.uuid}
        />
      </div>
      {viewLimitedTo !== 'organization' && numLocations > 1 && (
        <div className={classes.section}>
          <SnapshotChartHeader
            title="Location Level Certification Breakdown"
            description={
              <>
                To see the full list of all your location scores with a detailed breakdown for each,
                download the excel document for all your data.
                <Button
                  color="secondaryNoBackground"
                  onClick={() => {
                    gaEvent({
                      action: 'downloadCertifiedLocationsBreakdown',
                      category: 'CertificationHub',
                    })
                    send('FETCH_DOWNLOAD')
                  }}
                  disabled={state.matches('loading')}
                  noMargins
                  id="download-location-breakdown"
                >
                  Download Location Breakdown (Excel)
                </Button>
              </>
            }
          />
          <Card className={classes.locationsCard}>
            <Typography>
              Congratulations! {numCertified} of your locations are Great Place to Work-Certified™.
            </Typography>
            {numNotCertified > 0 && (
              <Typography color="textSecondary">
                {numNotCertified} {pluralize('location', numNotCertified)} did not reach the
                required scores to become certified.
              </Typography>
            )}
            <br />
            <Typography className={classes.certifiedText}>Certified</Typography>
            <div className={classes.barRow}>
              <SingleBarBreakdownChart
                scores={[
                  (numCertified / numLocations) * 100,
                  (numNotCertified / numLocations) * 100,
                ]}
                minShowableResults={0}
                barColors={[colors.success, colors.navy25]}
                labelPosition={null}
                chartWidth={580}
                minWidth={0}
              />
              <Typography variant="h6">{numCertified}</Typography>
            </div>
            <br />
            <Typography color="textSecondary">Not Certified</Typography>
            <div className={classes.barRow}>
              <SingleBarBreakdownChart
                scores={[
                  (numNotCertified / numLocations) * 100,
                  (numCertified / numLocations) * 100,
                ]}
                minShowableResults={0}
                barColors={[colors.navy65, colors.navy25]}
                labelPosition={null}
                chartWidth={580}
                minWidth={0}
              />
              <Typography variant="h6">{numNotCertified}</Typography>
            </div>
          </Card>
        </div>
      )}
      {viewLimitedTo === 'organization' && (
        <>
          <CertificationHubBanner
            title="Want to see your location’s data? Upgrade today."
            description="Upgrade today to receive location-specific insights, game-changing data, location-level badges, and more."
            buttonLabel="Learn More"
            image={<img src={UpgradePromoIcon} alt="Locations Certified" height={200} />}
            backgroundColor={colors.iceGrey50}
            url={`${baseUrl}/employee-surveys/`}
          />
          <br />
        </>
      )}
    </>
  )
}

export default CertifiedView
