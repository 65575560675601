import React, { useRef } from 'react'

import colormap from 'colormap'

import PrintableVegaChart from 'components/Insights/Printable/PrintableVegaChart'
import { InsightsCommentsTopCommentsQuery } from 'generated/graphql'

type Payload = { text: string } | null
export type handleWordClickType = (word: string) => void

type Comments = NonNullable<InsightsCommentsTopCommentsQuery['insightsComments']>['comments']

type Props = {
  comments: Comments
  handleWordClick: handleWordClickType
  searchWords: string[]
}

const WordCloudChart: React.FC<Props> = ({
  comments: insightsComments,
  handleWordClick,
  searchWords,
}) => {
  const chartRef = useRef<null | PrintableVegaChart>(null)
  const getSpec = (comments: Comments) => {
    const sample = comments?.map(c => c?.text || '')
    const width = 820
    const height = 500
    const spec = {
      $schema: 'https://vega.github.io/schema/vega/v5.json',
      width,
      height,
      padding: { left: -100, right: 0, top: 0, bottom: 0 },
      signals: [
        {
          name: 'wordClick',
          description: 'A signal that updates when a word in the cloud is clicked.',
          on: [
            {
              events: 'text:click',
              type: 'click',
              update: 'datum',
            },
          ],
        },
      ],
      data: [
        {
          name: 'table',
          values: sample,
          transform: [
            {
              type: 'countpattern',
              field: 'data',
              case: 'upper',
              pattern: "[\\w']{3,}",
              stopwords:
                "(i|me|my|myself|we|us|our|ours|ourselves|you|your|yours|yourself|yourselves|he|him|his|himself|she|her|hers|herself|it|its|itself|they|them|their|theirs|themselves|what|which|who|whom|whose|this|that|these|those|am|is|are|was|were|be|been|being|have|has|had|having|do|does|did|doing|will|would|should|can|could|ought|i'm|you're|he's|she's|it's|we're|they're|i've|you've|we've|they've|i'd|you'd|he'd|she'd|we'd|they'd|i'll|you'll|he'll|she'll|we'll|they'll|isn't|aren't|wasn't|weren't|hasn't|haven't|hadn't|doesn't|don't|didn't|won't|wouldn't|shan't|shouldn't|can't|cannot|couldn't|mustn't|let's|that's|who's|what's|here's|there's|when's|where's|why's|how's|a|an|the|and|but|if|or|because|as|until|while|of|at|by|for|with|about|against|between|into|through|during|before|after|above|below|to|from|up|upon|down|in|out|on|off|over|under|again|further|then|once|here|there|when|where|why|how|all|any|both|each|few|more|most|other|some|such|no|nor|not|only|own|same|so|than|too|very|say|says|said|shall|etc|everyone|also|within|always|every|seem|around|since|yes)",
            },
            {
              type: 'formula',
              as: 'angle',
              expr: '[-45, 0, 45][~~(random() * 3)]',
            },
            {
              type: 'formula',
              as: 'weight',
              expr: '300',
            },
          ],
        },
      ],

      scales: [
        {
          name: 'color',
          type: 'ordinal',
          range: colormap({
            colormap: 'viridis',
            nshades: 10,
            format: 'hex',
            alpha: 1,
          }),
        },
      ],

      marks: [
        {
          type: 'text',
          from: { data: 'table' },
          encode: {
            enter: {
              text: { field: 'text' },
              align: { value: 'center' },
              baseline: { value: 'alphabetic' },
              fill: { scale: 'color', field: 'text' },
              cursor: { value: 'pointer' },
            },
            update: {
              fontWeight: { value: 200 },
            },
            hover: {
              fontWeight: { value: 400 },
            },
          },
          transform: [
            {
              type: 'wordcloud',
              size: [width, height],
              text: { field: 'text' },
              rotate: { field: 'datum.angle' },
              font: 'Helvetica Neue, Arial',
              fontSize: { field: 'datum.count' },
              fontWeight: { field: 'datum.weight' },
              fontSizeRange: [12, 56],
              padding: 2,
            },
          ],
        },
      ],
    }

    return spec
  }

  return (
    <PrintableVegaChart
      printWidth={550}
      screenWidth={820}
      style={{ width: '100%' }}
      spec={getSpec(insightsComments)}
      signalListeners={{
        wordClick: (signal: string, payload: Payload) => {
          const word = payload?.text.toLowerCase()
          if (word && !searchWords.includes(word)) {
            handleWordClick(word)
          }
        },
      }}
      logLevel={0}
      ref={chartRef}
      actions={false}
    />
  )
}

export default WordCloudChart
