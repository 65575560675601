import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'

import Carousel from 'components/Blocks/Carousel'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsBenchmark, InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import TimeTrendingContainer from 'components/Insights/TimeTrending/TimeTrendingContainer'
import TTGroupedScoresChart from 'components/Insights/TimeTrending/TTGroupedScoresChart'
import {
  getBenchmarkScore,
  getOverallScoreGroups,
  transformGroupedScoresToEcharts,
  groupScoresByLabel,
} from 'components/Insights/TimeTrending/utils'
import { TimeTrendingChartKey, TimeTrendingSurveysKey } from 'config/LocalStorage'
import {
  ImpactScoreStrategyEnum,
  SurveyProductTypeEnum,
  useInsightsKeyDemographicsQuery,
  useInsightsTimeTrendingScoresByDataTypeQuery,
} from 'generated/graphql'
import DynamicSurveyQuery from 'HOC/DynamicSurveyQuery'
import {
  DATA_TYPE_NAMES,
  PRODUCT_TYPE_TO_PULSE_TYPE,
  LongScoreTitleEnum,
  SURVEY_TYPE_TO_KEY_STATEMENT,
} from 'utils/constants'
import { usePulseInTimeTrending } from 'utils/customHooks'
import { SurveyNode } from 'utils/types'

const useStyles = makeStyles(theme => ({
  carousel: {
    marginTop: theme.spacing(2),
  },
}))

type Props = {
  survey: InsightsSurvey
  availableSurveys: SurveyNode[]
  filters: string[]
  benchmark: InsightsBenchmark
}
const TTKeyDemographicsCard: React.FC<Props> = ({
  survey,
  availableSurveys,
  filters,
  benchmark,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  // Duplicate state for the carousel step so we know which chart to use for the screenshot.
  const [step, setStep] = useState(0)
  // Fetch key demographics for the controls survey. Show a group breakdown chart for each of those demographics.
  // Note: this performs some unnecessary calculations for the first query. We can reduce the calculations if we run into scaling
  // issues. Although, in most cases, this first query will be cached.
  const result = useInsightsKeyDemographicsQuery({
    variables: {
      surveyUuid: survey.uuid,
      impactScoreStrategy: ImpactScoreStrategyEnum.POSITIVE_SCORE,
      filters,
    },
  })
  const { chartKey, surveysKey } = {
    [SurveyProductTypeEnum.EMPLOYEE]: {
      chartKey: TimeTrendingChartKey.EMPLOYEE_KEY_DEMOGRAPHICS,
      surveysKey: TimeTrendingSurveysKey.EMPLOYEE_KEY_DEMOGRAPHICS,
    },
    [SurveyProductTypeEnum.RESIDENT]: {
      chartKey: TimeTrendingChartKey.RESIDENT_KEY_DEMOGRAPHICS,
      surveysKey: TimeTrendingSurveysKey.RESIDENT_KEY_DEMOGRAPHICS,
    },
  }[survey.productType]
  const includesPulse = usePulseInTimeTrending(availableSurveys, surveysKey)
  const scoreName = includesPulse ? 'Key Statement Score' : LongScoreTitleEnum[survey.productType]
  return (
    <div className={classes.fullRow} id="tt-key-demographics">
      <SnapshotChartHeader
        title="Key Demographics Over Time"
        description={`Values indicate the average ${scoreName} for each demographic group.`}
        tooltip="Compare survey start dates to see how scores for each demographic group have changed over time."
        hasTimeTrending
        timeTrendingChartKey={chartKey}
        snapId={`tt-key-demographics-snap-${step}`}
      />
      <TimeTrendingContainer
        chartSurveysKey={surveysKey}
        availableSurveys={availableSurveys}
        survey={survey}
      >
        {({ selectedSurveys }) => (
          <ResponseHandler {...result}>
            {({ insightsKeyDemographics }) => {
              if (!insightsKeyDemographics.length) return <div />
              const showCarousel = insightsKeyDemographics.length > 2
              return (
                <div className={classes.carousel}>
                  <Carousel
                    showCarousel={showCarousel}
                    onStepChange={setStep}
                    numSteps={insightsKeyDemographics.length}
                  >
                    {insightsKeyDemographics.map(({ dataType: dtCode }, idx) => {
                      const pulseKeyStatementCode =
                        SURVEY_TYPE_TO_KEY_STATEMENT[PRODUCT_TYPE_TO_PULSE_TYPE[survey.productType]]
                      return (
                        <div id={`tt-key-demographics-snap-${idx}`} key={dtCode}>
                          <DynamicSurveyQuery
                            surveys={selectedSurveys}
                            variables={{
                              filters,
                              dtCode,
                              statementCodes: includesPulse ? [pulseKeyStatementCode] : null,
                            }}
                            queryHook={useInsightsTimeTrendingScoresByDataTypeQuery}
                          >
                            {(data, loading) => {
                              const surveyScoresByGroup = groupScoresByLabel(
                                data,
                                'insightsScoresByDataType',
                              )
                              const groupNames = Object.keys(surveyScoresByGroup)
                              const demographicScoreGroups = transformGroupedScoresToEcharts(
                                groupNames,
                                surveyScoresByGroup,
                                data.map(({ uuid }) => uuid),
                              )
                              const companyOverallScoreGroups = getOverallScoreGroups(data)
                              return (
                                <TTGroupedScoresChart
                                  xAxisLabelData={selectedSurveys}
                                  groupedScores={demographicScoreGroups}
                                  benchmarkName={benchmark.name}
                                  companyOverallScores={companyOverallScoreGroups[0]}
                                  benchmarkScore={getBenchmarkScore(data[0].overallIndex)}
                                  minShowableResults={selectedSurveys[0].minShowableResults}
                                  title={String(DATA_TYPE_NAMES[dtCode])}
                                  didSelectFilters={!loading && filters.length > 0}
                                  surveyScoresByGroup={surveyScoresByGroup}
                                  orderedGroupNames={groupNames}
                                  chartWidth="inherit"
                                  includesPulse={includesPulse}
                                />
                              )
                            }}
                          </DynamicSurveyQuery>
                        </div>
                      )
                    })}
                  </Carousel>
                </div>
              )
            }}
          </ResponseHandler>
        )}
      </TimeTrendingContainer>
    </div>
  )
}

export default TTKeyDemographicsCard
