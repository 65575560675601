import React, { useState, SyntheticEvent } from 'react'

import { makeStyles, InputAdornment, Typography } from '@material-ui/core'
import ArrowIcon from '@material-ui/icons/ArrowForward'
import EmailIcon from '@material-ui/icons/Email'
import cn from 'classnames'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import IconButton from 'components/Blocks/CustomButtons/IconButton'

export const useStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  firstTitle: {
    marginTop: spacing(4),
  },
  secondTitle: {
    width: '80%',
    marginTop: spacing(3),
    marginBottom: spacing(2),
  },
  sendMessage: {
    marginBottom: spacing(3),
    [breakpoints.down('sm')]: {
      marginLeft: spacing(3),
    },
  },
  adornment: {
    color: palette.common.grey,
  },
  submitButton: {
    color: palette.common.grey,
  },
  submitButtonWhenValid: {
    color: palette.common.secondary,
  },
}))

interface Props {
  sendTestEmail(emailAddress: string): void
}

const SendTestEmail: React.FC<Props> = ({ sendTestEmail }) => {
  const classes = useStyles()
  const [isValid, setIsValid] = useState(false)
  const [value, setValue] = useState('')

  return (
    <>
      <Typography variant="body2" color="textSecondary" className={classes.firstTitle}>
        SEND OUT A TEST*
      </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.secondTitle}>
        We recommend you send yourself a test email to make sure the notification is correct.{' '}
        <i>*(Optional)</i>
      </Typography>
      <ValidatorForm
        instantValidate
        className={classes.sendMessage}
        onSubmit={() => sendTestEmail(value)}
      >
        <TextValidator
          id="sendTestEmail"
          name="sendTestEmail"
          helperText=""
          value={value}
          label="Email Address"
          placeholder="email@company.com"
          onChange={(e: SyntheticEvent) => setValue((e.target as HTMLInputElement).value)}
          margin="normal"
          validators={['required', 'isEmail']}
          errorMessages={['Value Required', 'Enter valid email address']}
          validatorListener={isValidVal => setIsValid(isValidVal)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon className={classes.adornment} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  type="submit"
                  color="secondaryHover"
                  className={cn(classes.submitButton, {
                    [classes.submitButtonWhenValid]: isValid,
                  })}
                >
                  <ArrowIcon />
                </IconButton>
              </InputAdornment>
            ),
            inputComponent: 'input',
          }}
        />
      </ValidatorForm>
    </>
  )
}

export default SendTestEmail
