import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import typographyStyle from './TypographyStyle'

function Danger({ ...props }) {
  const { classes, children } = props
  return <div className={`${classes.defaultFontStyle} ${classes.dangerText}`}>{children}</div>
}

export default withStyles(typographyStyle)(Danger)
