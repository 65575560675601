import React from 'react'

import { FilterTypeType, UserNode } from 'generated/graphql'

const userData = (
  uIsAdmin: boolean,
  filterPermissions: UserNode['filterPermissions'],
  className?: string,
): { summary: JSX.Element; tooltip: JSX.Element } => {
  let tooltip = <></>
  let summary = 'All'
  if (!uIsAdmin) {
    const values: string[] = []
    tooltip = (
      <ul>
        {(filterPermissions as FilterTypeType[]).map((ft: FilterTypeType) => (
          <li key={ft.filterTypeUuid}>
            {ft.accessToAll && values.push(`All ${ft.namePlural}`) === -999}
            {ft.accessToAll ? `All ${ft.namePlural}` : `${ft.namePlural}:`}
            <ul>
              {ft.filterValues.map(fv => (
                <li key={fv.uuid}>{values.push(fv.name) && fv.name}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    )
    summary = values.join(', ')
  }
  return {
    summary: <div className={className}>{summary}</div>,
    tooltip,
  }
}

export default userData
