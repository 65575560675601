import React from 'react'

import { Tooltip } from '@material-ui/core'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'

import Button from 'components/Blocks/CustomButtons/Button'

type Props = {
  disabled: boolean
  onClick(): void
}
const NewNotificationButton: React.FC<Props> = ({ disabled, onClick }) => {
  return (
    <Tooltip
      placement="top"
      title={disabled ? 'You have reached the limit for scheduling notifications.' : ''}
    >
      <span>
        <Button color="secondaryNoBackground" onClick={onClick} disabled={disabled}>
          <EventOutlinedIcon />
          &nbsp;&nbsp;Schedule New
        </Button>
      </span>
    </Tooltip>
  )
}

export default NewNotificationButton
