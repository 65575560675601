import React from 'react'

import { makeStyles, Typography, MenuItem } from '@material-ui/core'

import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import { FilterTypeFragment } from 'generated/graphql'

const useStyles = makeStyles(({ palette }) => ({
  emptyDropdownRow: {
    fontStyle: 'italic',
    color: palette.common.navy65,
  },
}))

type FProps = {
  dropdownFilterTypes: FilterTypeFragment[]
  filterType: FilterTypeFragment | null
  setVal: (ft: FilterTypeFragment | null) => void
  label: string
  allowEmptyLevel1Filter?: boolean
}

const FilterTypeDropdown: React.FC<FProps> = ({
  dropdownFilterTypes,
  setVal,
  filterType,
  label,
  allowEmptyLevel1Filter,
}) => {
  const classes = useStyles()

  return (
    <TextDropdown
      displayEmpty
      value={filterType?.dtCode || 'Choose One'}
      renderValue={filterCode => {
        if (filterCode === 'Choose One') return filterCode
        return dropdownFilterTypes.find(({ dtCode }) => dtCode === filterCode)?.name
      }}
      onChange={e => {
        const newFilter = dropdownFilterTypes.find(
          ({ dtCode }) => dtCode === (e.target.value as string),
        )
        setVal(newFilter || null)
      }}
      label={
        <Typography variant="body2" color="textSecondary">
          {label}&nbsp;
        </Typography>
      }
    >
      {allowEmptyLevel1Filter && (
        <MenuItem className={classes.emptyDropdownRow} value="">
          None
        </MenuItem>
      )}
      {dropdownFilterTypes.map(ft => {
        return (
          <MenuItem key={ft.dtCode} value={ft.dtCode}>
            <Typography>{ft.name}</Typography>
          </MenuItem>
        )
      })}
    </TextDropdown>
  )
}

type Props = {
  level1FilterType: FilterTypeFragment | null
  level2FilterType: FilterTypeFragment
  setLevel1FilterType(val: FilterTypeFragment | null): void
  setLevel2FilterType(val: FilterTypeFragment): void
  visibleFilterTypes: FilterTypeFragment[]
  allowEmptyLevel1Filter?: boolean
}
export const FilterGroupDropdowns: React.FC<Props> = ({
  level1FilterType,
  level2FilterType,
  setLevel1FilterType,
  setLevel2FilterType,
  visibleFilterTypes,
  allowEmptyLevel1Filter = true,
}) => {
  return (
    <>
      {/** Level 2 Dropdown */}
      <FilterTypeDropdown
        dropdownFilterTypes={visibleFilterTypes}
        filterType={level2FilterType}
        setVal={(selecetedFilterType: FilterTypeFragment | null) => {
          if (!selecetedFilterType) return
          // If we would showing the first level filter type, reset that filter type
          // to be above the selected one.
          if (selecetedFilterType.dtCode === level1FilterType?.dtCode) {
            const selectedIndex = visibleFilterTypes.findIndex(
              ft => ft.dtCode === selecetedFilterType.dtCode,
            )
            setLevel1FilterType(visibleFilterTypes[selectedIndex + 1])
          }
          setLevel2FilterType(selecetedFilterType)
        }}
        label="Show:"
      />
      {/** Level 1 Dropdown, only allow values that aren't selected in Level 2 */}
      <FilterTypeDropdown
        dropdownFilterTypes={visibleFilterTypes.filter(
          ft => ft.dtCode !== level2FilterType?.dtCode,
        )}
        filterType={level1FilterType}
        setVal={setLevel1FilterType}
        label="Grouped By:"
        allowEmptyLevel1Filter={allowEmptyLevel1Filter}
      />
    </>
  )
}

export default FilterGroupDropdowns
