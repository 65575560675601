import React from 'react'

import { makeStyles } from '@material-ui/core'

import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import { WizardStepsEnum } from 'components/Survey/WizardContainer'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: -spacing(),
  },
}))

type Props = {
  goBack?(): void
  goNext?(stepName?: WizardStepsEnum): void
  goNextLabel?: string
  goNextDisabled?: boolean
  isSubmitting?: boolean
}

const NavigationButtons: React.FC<Props> = ({
  goBack,
  goNext = null, // Some components use the default submit function, ignore the next in this case.
  goNextLabel = 'Next',
  goNextDisabled = false,
  isSubmitting = false,
}) => {
  const classes = useStyles()
  return (
    <div id="wizardNavButtons" className={classes.root}>
      {goBack && (
        <Button onClick={goBack} color="secondaryNoBackground">
          Back
        </Button>
      )}
      {/** onClick can be undefined as the button will resort to form submission */}
      <SubmitButton
        id="goToNextStep"
        onClick={goNext}
        disabled={goNextDisabled}
        color="primary"
        isSubmitting={isSubmitting}
      >
        {goNextLabel}
      </SubmitButton>
    </div>
  )
}

export default NavigationButtons
