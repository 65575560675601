import React, { useState } from 'react'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Groups from 'components/Settings/Team/Groups/Groups'
import { Survey } from 'components/Settings/Team/Groups/GroupsTypes'
import {
  CurrentUserQuery,
  SettingsGroupFragment,
  SettingsGroupsDocument,
  SettingsGroupsQuery,
  SettingsGroupUserFragment,
  useSettingsGroupsQuery,
  useSettingsUpdateGroupMutation,
} from 'generated/graphql'
import { handleMutationResponse } from 'utils'

type Props = {
  currentUser: CurrentUserQuery['currentUser']
}
const GroupsContainer: React.FC<Props> = ({ currentUser }) => {
  const [searchQuery, updateSearchQuery] = useState('')
  const [updateGroup] = useSettingsUpdateGroupMutation()
  const result = useSettingsGroupsQuery({ variables: { search: searchQuery } })
  const saveGroup = async (inputGroup: SettingsGroupFragment) => {
    const updateResult = await updateGroup({
      variables: {
        group: {
          ...inputGroup,
          users: inputGroup.users.map(u => u.id),
          surveys: inputGroup.surveys.map(s => s.uuid),
        },
      },
      update: (cache, { data }) => {
        if (!data?.updateGroup?.group) return
        const cachedData = cache.readQuery<SettingsGroupsQuery>({
          query: SettingsGroupsDocument,
          variables: { search: searchQuery },
        })
        if (!cachedData) return
        const groups = !inputGroup.uuid
          ? [...cachedData.groups, data.updateGroup.group]
          : [...cachedData.groups.filter(g => g.uuid !== inputGroup.uuid), data.updateGroup.group]
        cache.writeQuery({
          query: SettingsGroupsDocument,
          variables: { search: searchQuery },
          data: { ...cachedData, groups },
        })
      },
    })
    return handleMutationResponse(
      updateResult.data?.updateGroup?.errors,
      'Successfully saved group',
    )
  }
  return (
    <ResponseHandler {...result}>
      {({ surveys: surveyNodes, users: userNodes, groups }) => {
        const surveys = surveyNodes.edges
          .map(e => e?.node)
          .filter((survey): survey is Survey => Boolean(survey))
        const users = userNodes.edges
          .map(e => e?.node)
          .filter((user): user is SettingsGroupUserFragment => Boolean(user))
        return (
          <Groups
            groups={groups}
            surveys={surveys}
            currentUser={currentUser}
            users={users}
            updateGroup={saveGroup}
            searchQuery={searchQuery}
            updateSearchQuery={updateSearchQuery}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default GroupsContainer
