import React, { useState } from 'react'

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'

import useActionPlansStyles from './styles'

import Button from 'components/Blocks/CustomButtons/Button'
import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import { gaEvent } from 'config/ga'
import {
  useActionPlansAddActionPlanCustomStatementMutation,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'

const useStyles = makeStyles(({ palette, spacing }) => ({
  statement: {
    marginTop: spacing(),
    marginBottom: spacing(),
  },
  surveyProduct: {
    display: 'flex',
    alignItems: 'flex-end',
    '& >p:first-child': {
      marginRight: spacing(3),
    },
    '& >div': {
      minWidth: 100,
    },
  },
  task: {
    paddingTop: spacing(2),
    paddingLeft: spacing(4),
    '& input': {
      color: palette.common.navy65,
    },
  },
}))

type Props = {
  targetUserUuid?: string
  onClose(): void
}

const AddCustomStatementsDialog: React.FC<Props> = ({ targetUserUuid, onClose }) => {
  const classes = { ...useActionPlansStyles(), ...useStyles() }
  const [customStatement, setCustomStatement] = useState('')
  const [customStatementProductType, setCustomStatementProductType] = useState<
    SurveyProductTypeEnum | 'other' | null
  >(null)
  const [tasks, setTasks] = useState<string[]>([])
  const [addCustomStatement, { loading }] = useActionPlansAddActionPlanCustomStatementMutation()

  const handleAddCustomStatement = async () => {
    gaEvent({
      action: 'actionItemAddCustomStatement',
      category: 'ActionPlans',
    })
    const variables = {
      userUuid: targetUserUuid,
      customStatement,
      customStatementProductType:
        customStatementProductType === 'other' ? null : customStatementProductType,
      tasks: tasks.filter(t => t !== ''),
    }
    try {
      const response = await addCustomStatement({ variables })
      if (response.data?.addActionPlanCustomStatement?.errors) {
        emitter.emit('ERROR', response.data?.addActionPlanCustomStatement?.errors)
      } else {
        onClose()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Dialog
      open
      fullWidth
      onClose={onClose}
      maxWidth="md"
      aria-labelledby="custom-statement-dialog"
    >
      <DialogTitle id="custom-statement-dialog" className={classes.dialogTitle}>
        <div className={classes.title}>
          <Typography variant="h4">Add Custom</Typography>
        </div>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          className={classes.statement}
          type="text"
          name="statement"
          fullWidth
          value={customStatement}
          placeholder="Write Custom Statement here..."
          onChange={e => setCustomStatement((e.target as HTMLInputElement).value)}
          inputProps={{ autoFocus: true }}
        />
        {tasks.map((task, idx) => (
          <TextField
            key={idx}
            className={classes.task}
            fullWidth
            type="text"
            name={`task-${idx}`}
            value={task}
            placeholder="Write Action Item here..."
            onChange={e =>
              setTasks(
                tasks.map((taskVal, index) =>
                  index === idx ? (e.target as HTMLInputElement).value : taskVal,
                ),
              )
            }
          />
        ))}
        <Button onClick={() => setTasks([...tasks, ''])} color="secondaryNoBackground">
          <AddIcon />
          &nbsp;Action Item{' '}
          <Typography color="textSecondary" variant="body2">
            &nbsp;<i>(Optional)</i>
          </Typography>
        </Button>
        <div className={classes.surveyProduct}>
          <Typography color="textSecondary">What area are you working to improve?</Typography>
          <FormControl>
            <InputLabel>Select One</InputLabel>
            <Select
              displayEmpty
              value={customStatementProductType}
              onChange={e =>
                setCustomStatementProductType(
                  (e.target as HTMLInputElement).value as SurveyProductTypeEnum | 'other',
                )
              }
            >
              {/* Product requirement was not to limit these options based on enabled solutions */}
              <MenuItem value={SurveyProductTypeEnum.EMPLOYEE}>Employee Experience</MenuItem>
              <MenuItem value={SurveyProductTypeEnum.RESIDENT}>Customer Experience</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <ActionDialogButtons
        onClose={onClose}
        onSubmit={handleAddCustomStatement}
        submitDisabled={!customStatement || !customStatementProductType}
        submitButtonText="Save"
        isSubmitting={loading}
      />
    </Dialog>
  )
}

export default AddCustomStatementsDialog
