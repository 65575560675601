import React, { useState } from 'react'

import { Typography, Tooltip, makeStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import groupBy from 'lodash/groupBy'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import AddOptionalAndCustomButtons from 'components/Survey/Blocks/AddOptionalAndCustomButtons'
import { QuestionsAccordion, QuestionsByFocusAccordion } from 'components/Survey/Blocks/Questions'
import WelcomeMessagePanel from 'components/Survey/Blocks/WelcomeMessagePanel'
import {
  QuestionsByCategory,
  getQuestionsListForAddingCustomQuestion,
} from 'components/Survey/ResidentWizard/ConfigurableQuestions'
import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import MarketingAddons from 'components/Survey/Wizard/Steps/Questions/MarketingAddons'
import MissingTranslationsWarning from 'components/Survey/Wizard/Steps/Questions/MissingTranslationsWarning'
import NpsQuestions, {
  NPS_QUESTION_CODES,
} from 'components/Survey/Wizard/Steps/Questions/NpsQuestions'
import SurveyControls from 'components/Survey/Wizard/SurveyControls'
import {
  QCategory,
  QResidentFocus,
  SurveysQuestionsQuery,
  SurveysSurveyQuery,
} from 'generated/graphql'
import { MARKETING_ADDON_QUESTION_FOCUSES, RESIDENT_QUESTION_FOCUSES } from 'utils/constants'

const useStyles = makeStyles(({ spacing, palette }) => ({
  title: {
    display: 'flex',
    textTransform: 'uppercase',
    paddingBottom: spacing(1),
  },
  tooltipIcon: {
    color: palette.common.navy25,
    display: 'inline-block',
    marginLeft: spacing(1),
  },
  footer: {
    marginTop: spacing(1),
  },
  accordionRow: {
    paddingLeft: 22,
    paddingTop: 14,
    paddingBottom: 14,
    borderTop: `1px solid ${palette.common.navy25}`,
    '& >p': {
      display: 'flex',
      alignItems: 'center',
      color: palette.common.navy65,
    },
  },
  answerScale: {
    marginTop: spacing(4),
    fontStyle: 'italic',
    fontWeight: 100,
  },
  categoryPanel: {
    borderTop: `1px solid ${palette.common.navy25}`,
  },
}))

type SummaryProps = { category: QCategory | 'overview'; numQuestions: number }
const QuestionCategorySummary: React.FC<SummaryProps> = ({ category, numQuestions }) => {
  const classes = useStyles()
  let title
  let description
  let answerScale
  if (category === QCategory.STATEMENTS) {
    title = 'Statements'
    description = (
      <span>
        We recommend including 5-8 statements on each pulse survey. Here are the statements we
        suggest including:
        <br />
        <br />
        1. Recommended: Ask if your customers are satisfied with the value they receive for what
        they pay.
        <br />
        2. Required: This key statement is required on all pulse surveys and used for time trending.
        <br />
        3. Include any other statements you’d like to, either from our standard list or add a custom
        statement.
      </span>
    )
    answerScale = `Linear scale`
  } else if (category === QCategory.DEMOGRAPHICS) {
    title = 'Demographics'
    description = `Collect demographic data to better understand how consistent your customer experience is.`
    answerScale = 'Multiple-choice'
  } else if (category === QCategory.OPEN_ENDED) {
    title = 'Open Ended'
    description = `Ask your customers if they have noticed any changes since your last survey. Feel free to add additional open-ended questions or edit this one!`
    answerScale = 'Free form text'
  }
  return (
    <GridContainer>
      <ItemGrid sm={4}>
        <Typography variant="h6">{title}</Typography>
        <Typography color="textSecondary">{numQuestions} questions</Typography>
      </ItemGrid>
      <ItemGrid sm={8}>
        <Typography color="textSecondary">{description}</Typography>
        {answerScale && (
          <Typography className={classes.answerScale} color="textSecondary">
            Answer Scale: {answerScale}
          </Typography>
        )}
      </ItemGrid>
    </GridContainer>
  )
}

type Props = {
  survey: SurveysSurveyQuery['survey']
  questions: NonNullable<SurveysQuestionsQuery['questions']>
  goBack(): void
  goNext(uuid?: string): void
  handleDeleteQuestion(questionUuid?: string): void
}
const ResidentPulseQuestions: React.FC<Props> = ({
  survey,
  questions,
  goBack,
  goNext,
  handleDeleteQuestion,
}) => {
  const classes = useStyles()
  const [isOpenTranslationsDialog, setIsOpenTranslationsDialog] = useState(false)
  const questionsByCategory = groupBy(questions, 'category') as QuestionsByCategory

  const categoryKeys: QCategory[] = [
    QCategory.STATEMENTS,
    QCategory.OPEN_ENDED,
    QCategory.DEMOGRAPHICS,
  ]
  const existingQuestions = getQuestionsListForAddingCustomQuestion(questions, survey.type)
  return (
    <>
      <SurveyControls
        survey={survey}
        showPreview
        showTranslations
        isOpenTranslationsDialog={isOpenTranslationsDialog}
        setIsOpenTranslationsDialog={setIsOpenTranslationsDialog}
      />
      <div className={classes.title}>
        <Typography>Customer Pulse Survey</Typography>
        <Tooltip title="Pulse surveys can help you gauge how your customers are feeling about key areas without having to do a full Customer Engagement survey. They are shorter surveys and can be targeted to a subset of your customers (or your entire customer base).">
          <InfoIcon className={classes.tooltipIcon} />
        </Tooltip>
      </div>
      <WelcomeMessagePanel survey={survey} />
      {categoryKeys.map(category => {
        const questionsFocusMap = groupBy(questionsByCategory[category], 'residentFocus')
        // Formatting for usage by the common QuestionBlock components
        const questionsByFocus = Object.keys(questionsFocusMap)
          .filter(
            (key: string) => !MARKETING_ADDON_QUESTION_FOCUSES.includes(key as QResidentFocus),
          )
          .map((key: string) => ({
            focus: key,
            questions: questionsFocusMap[key].filter(
              q => !q.benchmarkCode || !NPS_QUESTION_CODES.includes(q.benchmarkCode),
            ),
          }))
          .filter(groupedQuestions => groupedQuestions.questions.length > 0)

        const numFocusQuestions = questionsByFocus.reduce(
          (sum, { questions: focusQuestions }) => sum + focusQuestions.length,
          0,
        )
        const questionGroup = { questionsByFocus, category }
        return (
          <div key={category}>
            <FormPanel
              square
              removeDetailsPadding
              expandIcon={EXPAND_ICON.ARROW}
              summary={
                <QuestionCategorySummary category={category} numQuestions={numFocusQuestions} />
              }
              extraClassName={classes.categoryPanel}
            >
              <>
                {questionsByFocus.length > 1 ? (
                  <QuestionsByFocusAccordion
                    existingQuestions={existingQuestions}
                    surveyUuid={survey.uuid}
                    handleOpenDeleteQuestion={handleDeleteQuestion}
                    category={category}
                    questionsByFocus={questionsByFocus}
                    focusTitleMap={RESIDENT_QUESTION_FOCUSES}
                  />
                ) : (
                  <QuestionsAccordion
                    existingQuestions={existingQuestions}
                    surveyUuid={survey.uuid}
                    handleOpenDeleteQuestion={handleDeleteQuestion}
                    gq={questionGroup}
                  />
                )}
                <div className={classes.accordionRow}>
                  <AddOptionalAndCustomButtons
                    survey={survey}
                    questionCategory={category}
                    selectedQuestions={Object.values(questionsByFocus).flatMap(
                      group => group.questions,
                    )}
                  />
                </div>
              </>
            </FormPanel>
            {category === QCategory.OPEN_ENDED && (
              <NpsQuestions survey={survey} surveyQuestions={questions} />
            )}
          </div>
        )
      })}
      <MarketingAddons
        survey={survey}
        surveyQuestions={questions.filter(question => !question.isCustom)}
      />
      <div className={classes.footer}>
        {survey.hasMissingTranslations ? (
          <MissingTranslationsWarning onOpen={() => setIsOpenTranslationsDialog(true)} />
        ) : (
          <div />
        )}
        <NavigationButtons
          goBack={goBack}
          goNext={goNext}
          goNextLabel={survey.uuid ? 'Next' : 'Save and Continue'}
        />
      </div>
    </>
  )
}

export default ResidentPulseQuestions
