import React from 'react'

import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import sum from 'lodash/sum'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import { getRelevantFilters } from 'components/Insights/Snapshot/DetailedBreakdownCard'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import {
  FilterTypeFragment,
  InsightsStatementsByDataTypesQuery,
  InsightsSurveyQuery,
  useInsightsStatementsByDataTypesQuery,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { MIN_SHOWABLE_RESULTS_CODE } from 'utils/constants'

const schema = [
  { name: 'Credibility', index: 0, text: 'Credibility' },
  { name: 'Respect', index: 1, text: 'Respect' },
  { name: 'Fairness', index: 2, text: 'Fairness' },
  { name: 'Pride', index: 3, text: 'Pride' },
  { name: 'Camaraderie', index: 4, text: 'Camaraderie' },
]

export const getChartValues = (
  statementsByDataType: InsightsStatementsByDataTypesQuery['insightsStatementsByDataTypes'],
  minShowableResults: number,
) => {
  const series = statementsByDataType
    .map(({ group, statements }) => {
      const focuses = schema.map(s => s.name.toUpperCase())
      const statementsByFocus = groupBy(
        // Ignore statements without a relevant focus (e.g. no focus or custom)
        statements.filter(stmt => stmt.focus && focuses.includes(stmt.focus)),
        'focus',
      )
      const sortedStatementsByFocus = sortBy(Object.values(statementsByFocus), stmts =>
        focuses.findIndex(focus => focus === stmts[0].focus),
      )
      return {
        name: group,
        type: 'parallel',
        lineStyle: {
          width: 2,
          opacity: 0.7,
        },
        data: [
          sortedStatementsByFocus.map(allStmts => {
            // Get rid of the values that have been labeled as `MASK_RESULT_FRONTEND`.
            const stmts = allStmts.filter(stmt => stmt.positive !== MIN_SHOWABLE_RESULTS_CODE)

            // We don't have a full group count, but a good enough approximation for
            // <min checks is to find the statement with the most respondents.
            const groupCount = Math.max(...stmts.map(stmt => stmt.count || 0))
            if (groupCount < minShowableResults) {
              return null
            }
            // Different statements may have had a different number of people respond.
            // Take the "average" of statements in the focus by weighting each stmt score
            // by the number of respondents divided by the total.
            const scoreSumWeightedByCount = sum(stmts.map(stmt => stmt.positive * stmt.count))
            const totalCount = sum(stmts.map(stmt => stmt.count))
            return Math.round(scoreSumWeightedByCount / totalCount)
          }),
        ],
      }
    })
    .filter(Boolean)

  const allValues = flatten(series.map(s => s?.data[0])).filter(
    (val): val is number => val !== null,
  )
  const dataMin = Math.min(...allValues)
  const dataMax = Math.max(...allValues)
  return { series, dataMin, dataMax }
}

type Props = {
  survey: InsightsSurveyQuery['survey']
  visibleFilterTypes: FilterTypeFragment[]
  filters: string[]
  hasTimeTrending: boolean
}
const DimensionsCard: React.FC<Props> = ({
  survey: { uuid, minShowableResults },
  visibleFilterTypes,
  filters,
  hasTimeTrending,
}) => {
  // Fetch statements for one data type.
  const relevantFilter = getRelevantFilters(visibleFilterTypes, filters)[0]
  const result = useInsightsStatementsByDataTypesQuery({
    variables: { surveyUuid: uuid, dataTypes: [{ dtCode: relevantFilter.dtCode }], filters },
  })
  return (
    <ResponseHandler {...result}>
      {({ insightsStatementsByDataTypes }) => {
        const { series, dataMin, dataMax } = getChartValues(
          insightsStatementsByDataTypes,
          minShowableResults,
        )
        return (
          <>
            <SnapshotChartHeader
              title={`Dimensions of Trust By ${relevantFilter.name}`}
              hasTimeTrending={hasTimeTrending}
              timeTrendingChartKey={TimeTrendingChartKey.EMPLOYEE_DIMENSIONS}
              snapId="dimensions-snap"
            />
            <PrintableEchart
              printWidth={650}
              notMerge
              style={{ height: 300 }}
              option={{
                legend: {
                  type: 'scroll',
                  left: '76%',
                  height: 270,
                  width: '24%',
                  orient: 'vertical',
                  data: series.map(s => ({ name: s?.name, icon: 'circle' })),
                  itemGap: 8,
                  textStyle: chartTextStyle,
                },
                tooltip: {
                  show: true,
                  trigger: 'item',
                },
                parallelAxis: schema.map((s, idx) => ({
                  dim: idx,
                  name: s.text,
                  min: dataMin,
                  max: dataMax,
                })),
                parallel: {
                  top: 10,
                  left: '10%',
                  right: '30%',
                  parallelAxisDefault: {
                    type: 'value',
                    name: 'Trust Index',
                    nameLocation: 'start',
                    nameGap: 20,
                    min: 'dataMin',
                    max: 'dataMax',
                    axisLine: {
                      lineStyle: {
                        color: colors.navy25,
                      },
                    },
                    axisTick: {
                      show: false,
                      lineStyle: {
                        color: '#777',
                      },
                    },
                    splitLine: {
                      show: false,
                    },
                    axisLabel: {
                      show: false,
                      color: colors.navy25,
                    },
                  },
                },
                textStyle: chartTextStyle,
                series,
              }}
            />
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default DimensionsCard
